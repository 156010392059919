import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import CallMadeRoundedIcon from '@material-ui/icons/CallMadeRounded';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { _peopleClients, _peopleMembers } from '@timed/app';
import { useAuth } from '@timed/auth';
import {
  Button,
  DateInput,
  formatPersonName,
  Link,
  roundNumber,
  Select,
  SelectMenuItem,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from '@timed/common';
import {
  OrderBy,
  useReportEventCheckerGetEventsLazyQuery,
  useReportEventCheckerGetMembersLazyQuery,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import clsx from 'clsx';
import {
  differenceInMinutes,
  format,
  isEqual,
  min,
  startOfWeek,
  subWeeks,
} from 'date-fns';
import addDays from 'date-fns/addDays';
import { subDays } from 'date-fns/esm';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
  members: string[];
  period: 'previous' | 'current' | 'custom' | undefined;
  from?: Date;
  to?: Date;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    tableWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.5),
    },
    tableTitle: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.paper3,
      fontWeight: theme.typography.fontWeightBold,
    },
    table: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(4),
    },
    cell: {
      fontSize: 12,
    },
    checkIcon: {
      color: theme.palette.success.main,
      fontSize: 16,
    },
    crossIcon: {
      color: theme.palette.error.main,
      fontSize: 16,
    },
    times: {
      display: 'grid',
      gridTemplateColumns: 'max-content auto',
      gap: theme.spacing(0.5),
      fontSize: 12,
    },
    miniColumn1: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper3,
    },
    miniColumn2: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper2,
    },
    errorText: {
      color: theme.palette.error.main,
    },
    actions: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    grid: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      gridTemplateColumns: 'auto auto',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(4),
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  }),
);

// type PayrollPeriods = "previous" | "current" | "custom" | undefined;

const ReportActivitySlips = () => {
  const classes = useStyles();

  const WEEK_STARTS_ON = 1; // MONDAY

  const { control, watch, setValue } = useForm<FormData>({
    defaultValues: { period: 'previous' },
  });

  const { branch } = useAuth();

  const period = watch('period');
  const from = watch('from');
  const to = watch('to');
  const members = watch('members');

  const periods = useMemo<SelectMenuItem[]>(
    () => [
      { label: 'Most recently ended payroll period', value: 'previous' },
      { label: 'Current payroll period', value: 'current' },
      { label: 'Custom date range', value: 'custom' },
    ],
    [],
  );

  const [getMembers, membersResposne] =
    useReportEventCheckerGetMembersLazyQuery({
      fetchPolicy: 'network-only',
    });

  const [getEvents, eventsResponse] = useReportEventCheckerGetEventsLazyQuery({
    fetchPolicy: 'network-only',
  });

  useLoadingEffect(eventsResponse.loading || membersResposne.loading);

  const clients = useMemo(
    () =>
      !!eventsResponse.data?.events.length
        ? [
            ...new Map(
              eventsResponse.data.events
                .map(({ client }) => client)
                .map(({ id, ...rest }) => [id, rest]),
            ),
          ]
        : [],
    [eventsResponse.data?.events],
  );

  useEffect(() => {
    if (period) {
      switch (period) {
        case 'current':
          setValue(
            'from',
            startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
          );
          setValue(
            'to',
            addDays(
              startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
              6,
            ),
          );
          break;
        case 'previous':
          setValue(
            'from',
            subWeeks(
              startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
              1,
            ),
          );
          setValue(
            'to',
            subDays(
              startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
              1,
            ),
          );
          break;
      }
    }
  }, [period, setValue]);

  useEffect(() => {
    if (!!from && !!to) {
      if (
        isEqual(
          from,
          startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
        ) &&
        isEqual(
          to,
          addDays(startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }), 6),
        )
      ) {
        setValue('period', 'current');
      } else if (
        isEqual(
          from,
          subWeeks(
            startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
            1,
          ),
        ) &&
        isEqual(
          to,
          subDays(startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }), 1),
        )
      ) {
        setValue('period', 'previous');
      } else {
        setValue('period', 'custom');
      }
    }
  }, [from, to, setValue]);

  /**
   * Fetch members
   */
  useEffect(() => {
    if (!!from && !!to)
      getMembers({
        variables: {
          input: {
            where: {
              events: { id: { _ne: null } },
              branchMembers: !!branch
                ? { branch: { id: { _eq: branch.id } } }
                : undefined,
            },
            orderBy: [
              { lastName: OrderBy.ASC_NULLS_FIRST },
              { firstName: OrderBy.ASC },
            ],
          },
        },
      });
  }, [branch, getMembers, from, to]);

  /**
   * Fetch events
   */
  useEffect(() => {
    if (!!from && !!to && !!members?.length)
      getEvents({
        variables: {
          input: {
            where: {
              startAt: { _lte: min([new Date(), addDays(to, 1)]) },
              endAt: { _gte: from },
              payable: { _eq: true },
              member: { id: { _in: members } },
            },
            orderBy: [{ startAt: OrderBy.ASC }, { duration: OrderBy.ASC }],
          },
        },
      });
  }, [getEvents, from, to, members]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.grid}>
        <Box className={classes.row}>
          <Select
            control={control}
            name="period"
            label="Reporting period"
            items={periods}
            formControlProps={{ variant: 'outlined', size: 'small' }}
          />
          {period && (
            <Box className={classes.row}>
              <DateInput
                disableToolbar
                autoOk
                control={control}
                name="from"
                label="From start of day"
                inputVariant="outlined"
                size="small"
              />
              <DateInput
                disableToolbar
                autoOk
                control={control}
                name="to"
                label="To end of day"
                inputVariant="outlined"
                size="small"
              />
            </Box>
          )}
        </Box>
        {!!period && !!membersResposne.data && (
          <>
            <Box className={classes.row}>
              <Select
                multiple
                control={control}
                defaultValue={[]}
                name="members"
                label="Employees"
                items={membersResposne.data.members.map((member) => ({
                  label: formatPersonName(member, { lastNameFirst: true }),
                  value: member.id,
                }))}
                formControlProps={{
                  variant: 'outlined',
                  size: 'small',
                  style: { width: 200 },
                }}
                renderValue={() => members.length + ' selected'}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setValue(
                    'members',
                    membersResposne.data?.members.map(({ id }) => id) || [],
                  );
                }}
              >
                Select All
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setValue('members', []);
                }}
              >
                Clear Selected
              </Button>
            </Box>
          </>
        )}
      </div>
      {!!members?.length && (
        <div className={classes.table}>
          {eventsResponse.data?.events.length
            ? clients.map(([clientId, client]) => (
                <Box className={classes.tableWrapper}>
                  <Typography className={classes.tableTitle}>
                    Participant:{' '}
                    <Link to={_peopleClients.path + '/' + clientId}>
                      {formatPersonName(client, {
                        capitaliseLastName: true,
                        lastNameFirst: true,
                      })}
                    </Link>
                  </Typography>
                  <Table showIndexColumn loading={eventsResponse.loading}>
                    <TableHeader>Support Worker</TableHeader>
                    <TableHeader>Times</TableHeader>
                    <TableHeader>Clocks</TableHeader>
                    <TableHeader style={{ width: 'auto' }}>Notes</TableHeader>
                    <TableHeader align="center">Travel Time</TableHeader>
                    <TableHeader align="center">KMs</TableHeader>
                    <TableHeader align="center">Payable</TableHeader>
                    <TableHeader align="center">Billable</TableHeader>
                    <TableHeader align="center">Cancelled</TableHeader>
                    <TableHeader>Actions</TableHeader>
                    {eventsResponse.data?.events
                      .filter(({ client: { id } }) => id === clientId)
                      .map((event, i) => (
                        <TableRow key={i}>
                          <TableCell>
                            <Link
                              to={_peopleMembers.path + '/' + event.member!.id}
                            >
                              {formatPersonName(event.member!, {
                                capitaliseLastName: true,
                                lastNameFirst: true,
                              })}
                            </Link>
                          </TableCell>
                          <TableCell>
                            <Typography className={classes.times}>
                              <span
                                className={clsx(
                                  classes.bold,
                                  classes.miniColumn1,
                                )}
                              >
                                Start
                              </span>
                              <span className={classes.miniColumn2}>
                                {format(
                                  new Date(event.startAt),
                                  'EEE dd/MM, HH:mm',
                                )}
                              </span>
                              <span
                                className={clsx(
                                  classes.bold,
                                  classes.miniColumn1,
                                )}
                              >
                                End
                              </span>
                              <span className={classes.miniColumn2}>
                                {format(
                                  new Date(event.endAt),
                                  'EEE dd/MM, HH:mm',
                                )}
                              </span>
                              <span
                                className={clsx(
                                  classes.bold,
                                  classes.miniColumn1,
                                )}
                              >
                                Hours
                              </span>
                              <span className={classes.miniColumn2}>
                                {roundNumber(
                                  differenceInMinutes(
                                    new Date(event.endAt),
                                    new Date(event.startAt),
                                  ) / 60,
                                  2,
                                )}
                              </span>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={classes.times}>
                              <span
                                className={clsx(
                                  classes.bold,
                                  classes.miniColumn1,
                                )}
                              >
                                On
                              </span>
                              <span
                                className={
                                  !event.clockedOnAt
                                    ? clsx(
                                        classes.miniColumn2,
                                        classes.errorText,
                                      )
                                    : classes.miniColumn2
                                }
                              >
                                {!!event.clockedOnAt ? (
                                  <>
                                    {format(
                                      new Date(event.clockedOnAt),
                                      'EEE dd/MM, HH:mm',
                                    )}{' '}
                                    {!!event.clockedOnLatitude &&
                                      !!event.clockedOnLatitude && (
                                        <Link
                                          to={
                                            'https://www.google.com/maps/place/' +
                                            event.clockedOnLatitude +
                                            ',' +
                                            event.clockedOnLongitude
                                          }
                                        >
                                          <CallMadeRoundedIcon
                                            style={{ width: 10, height: 10 }}
                                          />
                                        </Link>
                                      )}
                                  </>
                                ) : (
                                  'Did not clock on'
                                )}
                              </span>
                              <span
                                className={clsx(
                                  classes.bold,
                                  classes.miniColumn1,
                                )}
                              >
                                Off
                              </span>
                              <span
                                className={
                                  !event.clockedOffAt
                                    ? clsx(
                                        classes.miniColumn2,
                                        classes.errorText,
                                      )
                                    : classes.miniColumn2
                                }
                              >
                                {!!event.clockedOffAt ? (
                                  <>
                                    {format(
                                      new Date(event.clockedOffAt),
                                      'EEE dd/MM, HH:mm',
                                    )}{' '}
                                    {!!event.clockedOffLatitude &&
                                      !!event.clockedOffLatitude && (
                                        <Link
                                          to={
                                            'https://www.google.com/maps/place/' +
                                            event.clockedOffLatitude +
                                            ',' +
                                            event.clockedOffLongitude
                                          }
                                        >
                                          <CallMadeRoundedIcon
                                            style={{ width: 10, height: 10 }}
                                          />
                                        </Link>
                                      )}
                                  </>
                                ) : (
                                  'Did not clock off'
                                )}
                              </span>

                              <span
                                className={clsx(
                                  classes.bold,
                                  classes.miniColumn1,
                                )}
                              >
                                On By
                              </span>
                              <span
                                className={
                                  !event.clockedOnBy
                                    ? clsx(
                                        classes.miniColumn2,
                                        classes.errorText,
                                      )
                                    : classes.miniColumn2
                                }
                              >
                                {!!event.clockedOnBy ? (
                                  <Link
                                    to={
                                      _peopleMembers.path +
                                      '/' +
                                      event.clockedOnBy.id
                                    }
                                  >
                                    {formatPersonName(event.clockedOnBy, {
                                      capitaliseLastName: true,
                                      lastNameFirst: true,
                                    })}
                                  </Link>
                                ) : (
                                  'No one'
                                )}
                              </span>

                              <span
                                className={clsx(
                                  classes.bold,
                                  classes.miniColumn1,
                                )}
                              >
                                Off By
                              </span>
                              <span
                                className={
                                  !event.clockedOffBy
                                    ? clsx(
                                        classes.miniColumn2,
                                        classes.errorText,
                                      )
                                    : classes.miniColumn2
                                }
                              >
                                {!!event.clockedOffBy ? (
                                  <Link
                                    to={
                                      _peopleMembers.path +
                                      '/' +
                                      event.clockedOffBy.id
                                    }
                                  >
                                    {formatPersonName(event.clockedOffBy, {
                                      capitaliseLastName: true,
                                      lastNameFirst: true,
                                    })}
                                  </Link>
                                ) : (
                                  'No one'
                                )}
                              </span>
                            </Typography>
                          </TableCell>
                          <TableCell>{event.notes}</TableCell>
                          <TableCell>{event.travelTime}</TableCell>
                          <TableCell>
                            {!!event.travelDistance &&
                              event.travelDistance / 1000}
                          </TableCell>
                          <TableCell>
                            {event.payable ? (
                              <CheckIcon
                                fontSize="small"
                                className={classes.checkIcon}
                              />
                            ) : (
                              <ClearIcon
                                fontSize="small"
                                className={classes.crossIcon}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {event.billable ? (
                              <CheckIcon
                                fontSize="small"
                                className={classes.checkIcon}
                              />
                            ) : (
                              <ClearIcon
                                fontSize="small"
                                className={classes.crossIcon}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {!!event.cancel ? (
                              <CheckIcon
                                fontSize="small"
                                className={classes.checkIcon}
                              />
                            ) : (
                              <ClearIcon
                                fontSize="small"
                                className={classes.crossIcon}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <div className={classes.actions}>
                              <Button variant="contained">Approve</Button>
                              <Button variant="contained">Edit</Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </Table>
                </Box>
              ))
            : 'No results'}
        </div>
      )}
    </div>
  );
};

export default ReportActivitySlips;

import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Protected } from '@timed/auth';
import { PaginationLimitInput } from '@timed/common';
import { Permission } from '@timed/gql';
import {
  NotesContext,
  NotesControlAuthorInput,
  NotesControlClearButton,
  NotesControlReloadButton,
  NotesControlSearchInput,
  NotesControlStateInput,
  NotesControlTypeInput,
  NotesCreateNoteButton,
  NotesExportButton,
} from '@timed/notes';
import clsx from 'clsx';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
        padding: theme.spacing(1),
      },
    },
    group: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
    },
    controls: {
      [theme.breakpoints.down('sm')]: {
        flexGrow: 1,
      },
    },
  }),
);

const ClientNoteListControls = () => {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { limit, limits, setLimit } = useContext(NotesContext);

  return (
    <div className={classes.wrapper}>
      <div className={clsx(classes.group, classes.controls)}>
        <NotesControlReloadButton />
        {!smDown && <NotesControlClearButton />}
        <Protected permissions={Permission.MEMBER_READ}>
          <NotesControlAuthorInput />
        </Protected>
        <NotesControlSearchInput />
        <NotesControlTypeInput />
        <Protected admin>{!smDown && <NotesControlStateInput />}</Protected>
        <PaginationLimitInput
          limit={limit}
          limits={limits!}
          setLimit={setLimit!}
        />
        {smDown && (
          <Protected permissions={Permission.CLIENT_WRITE}>
            <NotesCreateNoteButton />
          </Protected>
        )}
      </div>
      {!smDown && (
        <div className={classes.group}>
          <Protected permissions={Permission.CLIENT_WRITE}>
            <NotesCreateNoteButton />
          </Protected>
          <Protected permissions={Permission.CLIENT_READ}>
            <NotesExportButton />
          </Protected>
        </div>
      )}
    </div>
  );
};

export default ClientNoteListControls;

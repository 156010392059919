import {
  differenceInDays,
  format,
  getHours,
  getMinutes,
  isSameDay,
} from 'date-fns';

type FormatTimeDurationOptions = {
  /**
   * Abreviate 12am-12am and 12-12am as "All day"
   */
  abv?: boolean;

  /**
   * Use short-form formatting, e.g: "1-5pm" instead of "1:00pm - 5:00pm"
   */
  short?: boolean;

  /**
   * State that the dates occur on differing days
   */
  stateSpan?: boolean;

  /**
   * Format the string in 24-hour format
   */
  militaryTime?: boolean;

  /**
   * Seperator to place between the times
   */
  seperator?: string;
};

type FormatTimeDurationFn = {
  /**
   * The start date
   */

  start: Date;
  /**
   * The end date
   */

  end: Date;
  /**
   * Options
   */

  options?: FormatTimeDurationOptions;
};

/**
 * Format two Date objects into a duration string.
 * E.g: 9-11am, 10:30am-2pm, 12:30-8pm, 10pm-12am
 */
export const formatTimeDuration = ({
  start,
  end,
  options,
}: FormatTimeDurationFn) => {
  if (options?.militaryTime)
    return (
      format(start, 'HH:mm') + (options.seperator || '-') + format(end, 'HH:mm')
    );

  const span =
    options?.stateSpan && !isSameDay(start, end)
      ? differenceInDays(start, end) === 0
        ? ' (overnight)'
        : ' (spanning ' + differenceInDays(end, start) + ' days)'
      : '';

  if (options?.short === false)
    return (
      format(start, 'h:mma').toLowerCase() +
      ' - ' +
      format(end, 'h:mma').toLowerCase() +
      span
    );

  // Set abv to true if options.abv is undefined
  const abv = options?.abv === undefined || options?.abv;

  const startMinutes = getMinutes(start);

  const endMinutes = getMinutes(end);

  const sameAMPM =
    (getHours(start) <= 12 && getHours(end) < 12) ||
    (getHours(start) >= 12 && getHours(end) > 12);

  const from =
    startMinutes === 0
      ? sameAMPM && isSameDay(start, end)
        ? format(start, 'h').toLowerCase()
        : format(start, 'ha').toLowerCase()
      : sameAMPM && isSameDay(start, end)
      ? format(start, 'h:mm').toLowerCase()
      : format(start, 'h:mma').toLowerCase();

  const to =
    endMinutes === 0
      ? sameAMPM && isSameDay(start, end)
        ? format(end, 'ha').toLowerCase()
        : format(end, 'ha').toLowerCase()
      : sameAMPM && isSameDay(start, end)
      ? format(end, 'h:mma').toLowerCase()
      : format(end, 'h:mma').toLowerCase();

  return abv && (from === '12am' || from === '12') && to === '12am'
    ? 'All day'
    : from + (options?.seperator || '-') + to + span;
};

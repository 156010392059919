import { Menu, MenuItem, useMediaQuery, useTheme } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { IconButton } from '@timed/common';
import { Branch, MemberBranchAllocation } from '@timed/gql';
import {
  MemberDeleteBranchAllocationDialog,
  MemberUpdateBranchAllocationFormModal,
} from '@timed/member';
import { useModal } from 'mui-modal-provider';
import { useState } from 'react';

type MemberBranchAllocationActionMenuButtonProps = {
  branchAllocation: Pick<MemberBranchAllocation, 'id' | 'date' | 'values'>;
  branches: Pick<Branch, 'id' | 'name'>[];
};

const MemberBranchAllocationActionMenuButton = ({
  branchAllocation,
  branches,
}: MemberBranchAllocationActionMenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { showModal } = useModal();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenDeleteDialog = () => {
    const modal: { hide: () => void } = showModal(
      MemberDeleteBranchAllocationDialog,
      {
        onClose: () => modal.hide(),
        branchAllocation,
      },
    );
  };

  const handleOpenUpdateModal = () => {
    const modal: { hide: () => void } = showModal(
      MemberUpdateBranchAllocationFormModal,
      {
        onClose: () => modal.hide(),
        branchAllocation,
        branches,
      },
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <span>
      <IconButton
        aria-label="menu"
        color="inherit"
        size={smDown ? 'small' : 'medium'}
        onClick={handleClick}
      >
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '30ch',
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleOpenUpdateModal();
            handleClose();
          }}
        >
          Update branch allocation
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenDeleteDialog();
            handleClose();
          }}
        >
          Delete branch allocation
        </MenuItem>
      </Menu>
    </span>
  );
};

export default MemberBranchAllocationActionMenuButton;

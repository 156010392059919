import { useAuth } from '@timed/auth';
import { Select, SelectProps } from '@timed/common';
import { ReportModule } from '@timed/report';

const ReportModuleInput = (props: Omit<SelectProps, 'items'>) => {
  const { permissible } = useAuth();
  return (
    <Select
      style={{ width: 300 }}
      label="Report Type"
      items={(permissible({ tester: true })
        ? Object.values(ReportModule)
        : [
            ReportModule.EVENT_TRAVEL,
            ReportModule.EVENT_CHECKER,
            ReportModule.ACTIVITY_SLIPS,
            ReportModule.EMPLOYEE_CLOCKED_SHIFTS,
            ReportModule.EMPLOYEE_PAY_SLIP,
            ReportModule.PARTICIPANT_QUOTE_GENERATOR,
          ]
      ).map((type) => {
        return {
          value: type,
          label: type[0] + type.toLowerCase().slice(1).replace(/_/g, ' '),
        };
      })}
      {...props}
    />
  );
};

export default ReportModuleInput;

import { useApolloClient } from '@apollo/client';
import { _invite, _start } from '@timed/app';
import { AuthContext, AuthContextType } from '@timed/auth';
import { isPermissible } from '@timed/auth/helpers';
import { useRouter, uuidRegex } from '@timed/common';
import {
  Branch,
  Member,
  ModuleType,
  OrderBy,
  Permission,
  useGetMeLazyQuery,
  useProcessMemberInvitationMutation,
  useSetUserActiveMemberMutation,
  useUpdateMemberDefaultBranchMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { ModuleContext } from '@timed/module';
import _ from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

const AuthProvider: React.FC = ({ children }) => {
  const { navigate, pathname, query, search, location } = useRouter();
  const client = useApolloClient();
  const { activeModule } = useContext(ModuleContext);

  const [branch, setBranch] = useState<Pick<Branch, 'id' | 'name'>>();

  const redirectToLogin =
    'sign-in' + (pathname === '/' ? '' : '?ref=' + pathname);

  const [updateBranch] = useUpdateMemberDefaultBranchMutation();

  const [SetActiveMember] = useSetUserActiveMemberMutation();

  const [getMe, { data, loading, refetch }] = useGetMeLazyQuery({
    variables: {
      branchesInput: {
        orderBy: [{ name: OrderBy.ASC }],
      },
      membersInput: {
        orderBy: [{ org: { preferredName: OrderBy.ASC_NULLS_LAST } }],
      },
    },
    onError: () => navigate(redirectToLogin), // Navigate to user login-in route
    onCompleted: (data) => {
      // If the user does not yet belong to any organisations, redirect to the
      // organisation-setup route.
      if (data.me.member) {
        const member = data.me.members.find(
          ({ id }) => id === data.me.member!.id,
        )!;

        // Navigate to user log-in route if the user is not authorised to access
        // their selected module.
        if (
          !member.superAdmin &&
          !member.admin &&
          ((activeModule === ModuleType.CS && !member.moduleAccessCS) ||
            (activeModule === ModuleType.SC && !member.moduleAccessSC))
        )
          navigate('sign-in');

        if (sessionStorage.getItem(`${member.org.id}.branch`))
          handleSetBranch(
            data.me.member.branches.find(
              ({ id }) =>
                id === sessionStorage.getItem(`${member.org.id}.branch`),
            ) ??
              (data.me.member.branches.length === 1
                ? data.me.member.branches[0]
                : undefined),
          );
        else
          handleSetBranch(
            data.me.member.branches.find(
              ({ id }) => id === data.me.member?.defaultBranch?.id,
            ) ??
              (data.me.member.branches.length === 1
                ? data.me.member.branches[0]
                : undefined),
          );
      }
    },
  });

  const [validateToken] = useProcessMemberInvitationMutation({
    onCompleted: () => {
      navigate(_start.path);
    },
    onError: () => {
      navigate('/');
    },
  });

  const inviteToken = useMemo<string | undefined>(() => {
    const pathParts = pathname.split('/');
    if (
      pathParts.length > 1 &&
      '/' + pathParts[1] === _invite.path &&
      query.hasOwnProperty('token') &&
      typeof query.token === 'string' &&
      uuidRegex.test(query.token)
    )
      return query.token;
  }, [pathname, query]);

  useEffect(() => {
    if (inviteToken) {
      validateToken({ variables: { input: { token: inviteToken } } });
    }
  }, [inviteToken, validateToken]);

  useEffect(() => {
    !inviteToken && localStorage.getItem('token')
      ? getMe()
      : navigate(redirectToLogin);
  }, [navigate, data, getMe, redirectToLogin, inviteToken]);

  useEffect(() => {
    if (!!data && !data?.me.member) navigate('/setup');
  }, [navigate, data]);

  const branches = useMemo(() => {
    return data?.me.member?.branches ?? [];
  }, [data?.me.member?.branches]);

  const orgs = useMemo(() => {
    return data?.me.members.map(({ org }) => org) ?? [];
  }, [data?.me.members]);

  const members = useMemo(() => {
    return data?.me.members.map(({ id, org }) => ({ id, org })) ?? [];
  }, [data?.me.members]);

  const [overriddenPermission, overridePermissions] =
    useState<Partial<Pick<Member, 'admin' | 'permissions'>>>();

  useLoadingEffect(loading);

  const permissible = useCallback(
    ({
      admin,
      permissions,
      tester,
    }: {
      tester?: boolean;
      admin?: boolean;
      permissions?: Permission | Permission[];
    }): boolean => {
      const currentMember = data?.me.members.find(
        (m) => m.id === data.me.member?.id,
      );

      if (tester) return !!currentMember?.superAdmin;

      if (admin) return !!currentMember?.superAdmin || !!currentMember?.admin;

      return isPermissible({
        member: {
          superAdmin: !!currentMember?.superAdmin,
          admin: overriddenPermission?.admin ?? !!currentMember?.admin,
          permissions: overriddenPermission?.permissions?.length
            ? overriddenPermission.permissions
            : data?.me.member?.permissions,
        },
        admin,
        superAdmin: tester,
        permissions,
      });
    },
    [
      data?.me.member?.id,
      data?.me.member?.permissions,
      data?.me.members,
      overriddenPermission,
    ],
  );

  const logout = useCallback(async (): Promise<void> => {
    localStorage.removeItem('token');
    await client.clearStore();
  }, [client]);

  const member: AuthContextType['member'] = useMemo(
    () =>
      data?.me.member &&
      !!data?.me.members.find(({ id }) => id === data.me.member?.id)
        ? {
            ..._.omit(data?.me.member, 'permissions'),
            ..._.omit(
              data?.me.members.find(({ id }) => id === data.me.member?.id),
              ['id', 'permissions', 'org'],
            ),
            superAdmin: !!data?.me.members.find(
              (m) => m.id === data.me.member?.id,
            )?.superAdmin,
            admin: !!data?.me.members.find((m) => m.id === data.me.member?.id)
              ?.admin,
            permissions: data?.me.member?.permissions ?? [],
          }
        : undefined,
    [data?.me.member, data?.me.members],
  );

  const moduleAccess = useMemo<ModuleType[]>(() => {
    if (!data?.me.member) return [];

    const member = data.me.members.find(({ id }) => id === data.me.member!.id);

    if (member?.superAdmin || member?.admin)
      return [ModuleType.CS, ModuleType.SC];

    const modules: ModuleType[] = [];

    if (member?.moduleAccessCS) modules.push(ModuleType.CS);
    if (member?.moduleAccessSC) modules.push(ModuleType.SC);

    return modules;
  }, [data?.me.member, data?.me.members]);

  /**
   * Handle setting branch.
   */
  const handleSetBranch = useCallback(
    (branch?: Pick<Branch, 'id' | 'name'>) => {
      if (!!data?.me.member) {
        const chosenBranch = data.me.member.branches.find(
          ({ id }) =>
            id === branch?.id ??
            sessionStorage.getItem(`${data.me.member!.org.id}.branch`),
        );

        // Set branch state
        if (branches.length === 1) setBranch(data.me.member.branches[0]);
        else if (!!chosenBranch) setBranch(chosenBranch);
        else setBranch(undefined);

        // Save/remove session storage
        if (
          branch?.id !==
          sessionStorage.getItem(`${data.me.member!.org.id}.branch`)
        ) {
          updateBranch({
            variables: {
              input: {
                patch: {
                  defaultBranch: !!branch ? { id: branch.id } : null,
                },
              },
            },
          });

          !!branch
            ? sessionStorage.setItem(
                `${data.me.member!.org.id}.branch`,
                branch.id,
              )
            : sessionStorage.removeItem(`${data.me.member!.org.id}.branch`);
        }
      }
    },
    [updateBranch, branches.length, data?.me.member],
  );

  /**
   * Handle setting org.
   */
  const handleSetOrg = useCallback(
    (member: Pick<Member, 'id'>) => {
      SetActiveMember({
        variables: {
          id: member.id,
        },
      });
    },
    [SetActiveMember],
  );

  return !data ? null : (
    <AuthContext.Provider
      value={{
        refetch,
        permissible,
        logout,
        overridePermissions,
        overriddenPermission,
        moduleAccess,
        member,
        members,
        branch,
        setBranch: handleSetBranch,
        branches,
        user: _.pick(data.me, [
          'id',
          'firstName',
          'middleName',
          'lastName',
          'preferredName',
        ]),
        org:
          !!data.me.member &&
          data.me.members.find((m) => m.id === data.me.member?.id)
            ? {
                ...data.me.member.org,
                ...data.me.members.find((m) => m.id === data.me.member?.id)!
                  .org,
              }
            : undefined,
        setOrg: handleSetOrg,
        orgs,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

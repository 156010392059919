import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  addServerErrors,
  DateInput,
  formatPersonName,
  FormModal,
  ModalProps,
  NumberInput,
  roundNumber,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  transformNumberToFixedFloat,
} from '@timed/common';
import {
  Branch,
  CreateMemberBranchAllocationDocument,
  CreateMemberBranchAllocationInput,
  PersonNamesPartialFragment,
  QueryByIdInput,
  useCreateMemberBranchAllocationMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type MemberCreateBranchAllocationFormModalProps = Omit<
  ModalProps,
  'children'
> & {
  onClose: () => void;
  member: QueryByIdInput & PersonNamesPartialFragment;
  branches: Pick<Branch, 'id' | 'name'>[];
};

type FormData = Omit<CreateMemberBranchAllocationInput, 'member' | 'values'> & {
  branches: { [k: string]: number };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputs: {
      flex: '1 0 auto',
      overflowY: 'auto',
      display: 'grid',
      gridAutoFlow: 'row',
      gridAutoRows: 'max-content',
      gap: theme.spacing(4),
    },
  }),
);

const MemberCreateBranchAllocationFormModal = ({
  member,
  branches,
  onClose,
  ...modalProps
}: MemberCreateBranchAllocationFormModalProps) => {
  const classes = useStyles();

  const [createEntity, response] = useCreateMemberBranchAllocationMutation();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<FormData>();

  useLoadingEffect(response.loading);

  useEffect(() => {
    if (response.error) addServerErrors(response.error, setError);
  }, [response.error, setError]);

  const onSubmit = ({ branches, ...values }: FormData) => {
    const input = {
      member: { id: member.id },
      ...values,
      values: Object.entries(branches)
        .filter(([_, v]) => !!v)
        .map(([k, v]) => ({
          branchId: k,
          value: roundNumber(v! * 100, 0),
        })),
    };

    createEntity({
      variables: { input },
    }).catch((e) => {});
  };

  const onSuccess = () => {
    const cache = response.client.cache;

    cache.modify({
      fields: {
        memberBranchAllocations(existing = []) {
          return [
            ...existing,
            cache.writeQuery({
              data: response.data,
              query: CreateMemberBranchAllocationDocument,
            }),
          ];
        },
      },
    });
  };

  /**
   * Display error messages from server response
   */
  useEffect(() => {
    if (response.error) addServerErrors(response.error, setError);
  }, [response.error, setError]);

  return (
    <FormModal
      modalProps={modalProps}
      title="Create Branch Allocation"
      description={`For ${formatPersonName(member, {
        lastNameFirst: true,
        capitaliseLastName: true,
      })}`}
      saveText="Save"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.inputs}>
        {response.error && (
          <Typography color="error">{response.error.message}</Typography>
        )}
        <DateInput
          required
          keyboard
          disableTime
          label="Effective-from date"
          name="date"
          control={control}
          inputVariant="outlined"
          size="small"
          error={!!errors.date}
          helperText={errors.date?.message}
        />
        <Table inline enableRowHighlighting>
          {branches.map(({ name }, i) => (
            <TableHeader key={i}>{name}</TableHeader>
          ))}
          <TableRow>
            {branches.map(({ id }, i) => (
              <TableCell key={i}>
                <NumberInput
                  name={`branches.${id}`}
                  variant="outlined"
                  size="small"
                  label="Percent"
                  control={control}
                  transform={transformNumberToFixedFloat(2, 0, 10000)}
                  step="0.01"
                  min={0}
                  max={10000}
                />
              </TableCell>
            ))}
          </TableRow>
        </Table>
      </Box>
    </FormModal>
  );
};

export default MemberCreateBranchAllocationFormModal;

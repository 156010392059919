import { PaginationContextType } from '@timed/common';
import {
  ClientNotesOrderByInput,
  EntityState,
  NotesContextGetAggregatedClientNotesQuery,
  NotesContextGetClientNoteKeywordsQuery,
  NotesContextGetFilesQuery,
  OrderBy,
} from '@timed/gql';
import { createContext, Dispatch, SetStateAction } from 'react';
import { Range } from 'react-date-range';

export type NotesContextNoteTypeInput = 'all' | 'shift' | 'other';

export type NotesContextSelectNotesAction = {
  type?: 'merge' | 'invert' | 'remove';
  ids: string[];
};

export type NotesContextInput = {
  /**
   * Filter by date range.
   */
  dateRange: Range;
  /**
   * Set date range.
   */
  setDateRange: Dispatch<SetStateAction<Range>>;
  /**
   * Filter by client.
   */
  client: string | null;
  /**
   * Set client.
   */
  setClient: Dispatch<SetStateAction<string | null>>;
  /**
   * Filter by commenter.
   */
  commentedBy: string | null;
  /**
   * Set commenter.
   */
  setCommentedBy: Dispatch<SetStateAction<string | null>>;
  /**
   * Filter by creator.
   */
  createdBy: string | null;
  /**
   * Set creator.
   */
  setCreatedBy: Dispatch<SetStateAction<string | null>>;
  /**
   * Filter by searchg.
   */
  search: string | null;
  /**
   * Set search.
   */
  setSearch: Dispatch<SetStateAction<string | null>>;
  /**
   * Filter by entity states.
   */
  type: NotesContextNoteTypeInput;
  /**
   * Set entity states.
   */
  setType: Dispatch<SetStateAction<NotesContextNoteTypeInput>>;
  /**
   * Filter by keyword-matches states.
   */
  matchesKeywords: boolean;
  /**
   * Set entity states.
   */
  setMatchesKeywords: Dispatch<SetStateAction<boolean>>;
  /**
   * Filter by entity states.
   */
  entityStates: EntityState[];
  /**
   * Set entity states.
   */
  setEntityStates: Dispatch<SetStateAction<EntityState[]>>;
  /**
   * Ordering options.
   */
  orderBy: ClientNotesOrderByInput[];
  /**
   * Set ordering options.
   */
  setOrderBy: Dispatch<SetStateAction<ClientNotesOrderByInput[]>>;
  /**
   * Default ordering.
   */
  defaultOrder: ClientNotesOrderByInput[];
  /**
   * Importer/Creator visibility state.
   */
  showImporter: boolean;
  /**
   * Set Importer/Creator visibility state.
   */
  setShowImporter: Dispatch<SetStateAction<boolean>>;
  /**
   * Highlight keywords in comments state.
   */
  highlightKeywords: boolean;
  /**
   * Set highlight keywords in comments state.
   */
  setHighlightKeywords: Dispatch<SetStateAction<boolean>>;
};

export type NotesContextType = PaginationContextType<
  NotesContextGetAggregatedClientNotesQuery['clientNotesAggregate']['nodes']
> & {
  /**
   * Reset filters to their defaults
   */
  reset: () => void;
  /**
   * Network loading state.
   */
  loading: boolean;
  /**
   * Results filter.
   */
  input: NotesContextInput;
  /**
   * Note files.
   */
  noteFiles?: NotesContextGetFilesQuery['clientNoteFiles'];
  /**
   * Event files.
   */
  eventFiles?: NotesContextGetFilesQuery['eventFiles'];
  /**
   * Keywords.
   */
  keywords?: NotesContextGetClientNoteKeywordsQuery['clientNoteKeywords'];
  /**
   * Select entities.
   */
  selectNotes: Dispatch<NotesContextSelectNotesAction>;
  /**
   * Selected entities.
   */
  selectedNotes: string[];
};

const NotesContext = createContext<NotesContextType>({
  fetch: () => {},
  reset: () => {},
  offset: 0,
  setOffset: () => 0,
  limit: 10,
  setLimit: () => {},
  limits: [10],
  loading: false,
  selectNotes: () => {},
  selectedNotes: [],
  input: {
    dateRange: {},
    setDateRange: () => {},
    client: null,
    setClient: () => {},
    commentedBy: null,
    setCommentedBy: () => {},
    createdBy: null,
    setCreatedBy: () => {},
    search: null,
    setSearch: () => {},
    type: 'all',
    setType: () => {},
    matchesKeywords: false,
    setMatchesKeywords: () => {},
    entityStates: [EntityState.NORMAL],
    setEntityStates: () => {},
    orderBy: [{ commentedAt: OrderBy.DESC }],
    setOrderBy: () => {},
    defaultOrder: [{ commentedAt: OrderBy.DESC }],
    showImporter: false,
    setShowImporter: () => {},
    highlightKeywords: false,
    setHighlightKeywords: () => {},
  },
});

export default NotesContext;

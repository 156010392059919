import { useApolloClient } from '@apollo/client';
import { Box, makeStyles } from '@material-ui/core';
// import CheckBoxOutlineBlankSharpIcon from '@material-ui/icons/CheckBoxOutlineBlankSharp';
// import CheckBoxSharpIcon from '@material-ui/icons/CheckBoxSharp';
import {
  addServerErrors,
  Button,
  ButtonAsync,
  EmailInput,
  Link,
  Snackbar,
} from '@timed/common';
import { ModuleType, useLoginUserMutation } from '@timed/gql';
import { ModuleContext } from '@timed/module';
import { UserPasswordInput } from '@timed/user';
import queryString from 'query-string';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

type FormData = {
  identifier: string;
  password: string;
};

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    width: '100%',
  },
  modules: {
    display: 'flex',
    gap: theme.spacing(4),
  },
  module: {
    flexBasis: '50%',
    fontSize: 12,
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      '& .MuiInputBase-root': {
        fontSize: 16,
      },
    },
  },
}));

const UserLoginForm = () => {
  const classes = useStyles();
  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [errorText, setErrorText] = useState<string>();
  const [loginUser, response] = useLoginUserMutation();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>();
  const parsed = queryString.parse(location.search);
  const redirectTo = (parsed.ref as string) ? (parsed.ref as string) : '/';

  const { activeModule, setActiveModule } = useContext(ModuleContext);

  useEffect(
    () =>
      response.error && addServerErrors(response.error, setError, setErrorText),
    [response.error, setError],
  );

  const onSubmit = async (values: FormData) => {
    localStorage.removeItem('token');
    await client.clearStore();
    loginUser({ variables: { input: { ...values, module: activeModule } } });
  };

  const onComplete = () => navigate(redirectTo);

  const onSuccess = () =>
    localStorage.setItem('token', response.data!.loginUser.token);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Snackbar
        open={!!errorText && response.error}
        severity="error"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {errorText}
      </Snackbar>
      <Box className={classes.modules}>
        <Button
          variant="contained"
          color={activeModule === ModuleType.CS ? 'primary' : 'default'}
          onClick={() => setActiveModule(ModuleType.CS)}
          className={classes.module}
        >
          Core Support
        </Button>
        <Button
          variant="contained"
          color={activeModule === ModuleType.SC ? 'primary' : 'default'}
          onClick={() => setActiveModule(ModuleType.SC)}
          className={classes.module}
        >
          Support Coordination
        </Button>
      </Box>
      <Box className={classes.inputs}>
        <EmailInput
          required
          autoFocus
          name="identifier"
          fullWidth
          autoComplete="email"
          variant="outlined"
          control={control}
          size="small"
          error={!!errors.identifier}
          helperText={errors.identifier?.message}
        />
        <UserPasswordInput
          required
          fullWidth
          name="password"
          autoComplete="current-password"
          variant="outlined"
          control={control}
          size="small"
          error={!!errors.password}
          helperText={errors.password?.message}
        />
        {/* <Checkbox
          control={control}
          name="rememberMe"
          label="Remember me on this device"
          color="default"
          icon={<CheckBoxOutlineBlankSharpIcon />}
          checkedIcon={<CheckBoxSharpIcon />}
        /> */}
        <ButtonAsync
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
          loading={response.loading}
          success={!!response.data}
          onComplete={onComplete}
          onSuccess={onSuccess}
        >
          Sign In
        </ButtonAsync>
        <Link
          to={'/join' + (redirectTo !== '/' ? '?ref=' + redirectTo : '')}
          variant="body1"
        >
          Don't have an account yet? Create an account
        </Link>
      </Box>
    </form>
  );
};

export default UserLoginForm;

import { Box } from '@material-ui/core';
import { ReactNode } from 'react';

type TabPanelsProps<T> = {
  name: string;
  currentTab: T;
  panels: {
    value: T;
    content: ReactNode;
  }[];
};

const TabPanels = <T extends unknown>({
  name,
  currentTab,
  panels,
}: TabPanelsProps<T>) => (
  <>
    {panels.map(({ value, content }) => (
      <Box hidden={currentTab !== value} id={name + '-tabpanel-' + value}>
        {content}
      </Box>
    ))}
  </>
);

export default TabPanels;

import { Box, useTheme } from '@material-ui/core';
import { ClientContext } from '@timed/client/context';
import { formatCurrency } from '@timed/common/utils/formatCurrency';
import { ModuleType } from '@timed/gql';
import { ModuleContext } from '@timed/module';
import { useContext } from 'react';

const ClientAvailableFundingBox = () => {
  const theme = useTheme();

  const { currentNdisPlan } = useContext(ClientContext);
  const { activeModule } = useContext(ModuleContext);

  let ammount: number | undefined;

  switch (activeModule) {
    case ModuleType.CS:
      ammount = currentNdisPlan?.coreSupportItem?.currentFunds;
      break;

    case ModuleType.SC:
      ammount = currentNdisPlan?.supportCoordinationItem?.currentFunds;
      break;
  }

  if (!ammount) return null;

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <span style={{ fontSize: 12 }}>AVAILABLE FUNDS</span>
      <span
        style={{ fontSize: 32, fontWeight: theme.typography.fontWeightMedium }}
      >
        {formatCurrency(ammount / 100)}
      </span>
    </Box>
  );
};

export default ClientAvailableFundingBox;

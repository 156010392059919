import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { addServerErrors, FormModal, ModalProps } from '@timed/common';
import {
  CreateClientNoteKeywordDocument,
  CreateClientNoteKeywordInput,
  useCreateClientNoteKeywordMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { NotesKeywordTextInput } from '@timed/notes';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type NotesCreateKeywordFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
};

type FormData = CreateClientNoteKeywordInput;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputs: {
      flex: '1 0 auto',
      overflowY: 'auto',
      display: 'grid',
      gridAutoFlow: 'row',
      gridAutoRows: 'max-content',
      gap: theme.spacing(4),
    },
  }),
);

const NotesCreateKeywordFormModal = ({
  onClose,
  ...modalProps
}: NotesCreateKeywordFormModalProps) => {
  const classes = useStyles();

  const [createEntity, response] = useCreateClientNoteKeywordMutation();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<FormData>();

  useLoadingEffect(response.loading);

  const onSubmit = (input: FormData) => {
    createEntity({ variables: { input } }).catch((e) => {});
  };

  const onSuccess = () => {
    const cache = response.client.cache;

    cache.modify({
      fields: {
        clientNoteKeywords(existing = []) {
          return [
            ...existing,
            cache.writeQuery({
              data: response.data,
              query: CreateClientNoteKeywordDocument,
            }),
          ];
        },
      },
    });
  };

  /**
   * Display error messages from server response
   */
  useEffect(() => {
    if (response.error) addServerErrors(response.error, setError);
  }, [response.error, setError]);

  return (
    <FormModal
      modalProps={modalProps}
      title="Add Keyword"
      saveText="Save"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.inputs}>
        <NotesKeywordTextInput
          required
          name="text"
          variant="outlined"
          size="small"
          label="Word"
          control={control}
          error={!!errors.text}
          helperText={errors.text?.message}
        />
      </Box>
    </FormModal>
  );
};

export default NotesCreateKeywordFormModal;

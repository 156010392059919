import {
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { red, yellow } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { Box, IconButton } from '@mui/material';
import { _peopleMembers } from '@timed/app';
import {
  Link,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  formatPersonName,
  roundNumber,
} from '@timed/common';
import {
  Event,
  GetEventClaimsQuery,
  Member,
  MemberHoursType,
  OrderBy,
  PersonNamesFragment,
  useUpdateEventAttendeeMutation,
} from '@timed/gql';
import clsx from 'clsx';
import {
  addWeeks,
  format,
  formatISO,
  isAfter,
  startOfWeek,
  subWeeks,
} from 'date-fns';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';

type EventClaimsTableProps = {
  claims: GetEventClaimsQuery['eventClaims'];
  event: Pick<Event, 'startAt' | 'endAt'> & {
    member?: Pick<Member, 'id'> | null;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      fontSize: 11,
    },
    number: {
      fontFamily: 'monospace',
    },
    name: {
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

const EventClaimsTable = ({ claims, event }: EventClaimsTableProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [updateEventAttendee] = useUpdateEventAttendeeMutation({
    onCompleted: () => {
      enqueueSnackbar('Event updated', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon fontSize="small" style={{ fill: 'white' }} />
          </IconButton>
        ),
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.message ?? 'Error updating event', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon style={{ fill: 'white' }} />
          </IconButton>
        ),
      });
    },
  });

  const calcBackgroundColor = (total: number, allowed: number) =>
    total > allowed ? red[500] : total === allowed ? yellow[500] : undefined;

  type ProcessedClaimMinutes = {
    allowedMinutes: number;
    totalMinutes: number;
  };

  type ProcessedClaim = {
    date: Date;
    member: Pick<Member, 'id'> & PersonNamesFragment;
    minutes: ProcessedClaimMinutes[];
    issues?: string[] | null;
  };

  const dates = useMemo(
    () => [
      formatISO(
        subWeeks(startOfWeek(new Date(event.startAt), { weekStartsOn: 1 }), 1),
        {
          representation: 'date',
        },
      ),
      formatISO(startOfWeek(new Date(event.startAt), { weekStartsOn: 1 }), {
        representation: 'date',
      }),
      formatISO(
        addWeeks(startOfWeek(new Date(event.startAt), { weekStartsOn: 1 }), 1),
        {
          representation: 'date',
        },
      ),
    ],
    [event.startAt],
  );

  const processedClaims: ProcessedClaim[] = useMemo(
    () =>
      claims
        .sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        )
        .map(({ member, createdAt, issues }) => ({
          member,
          date: new Date(createdAt),
          issues: issues.reasons,
          minutes: dates.map((date) => {
            const hours = member.hours.filter((hour) => date === hour.date);

            return {
              allowedMinutes:
                hours.find(({ type }) => type === MemberHoursType.MAX_LIMIT)
                  ?.value ?? 0,
              totalMinutes:
                hours.find(({ type }) => type === MemberHoursType.SCHEDULED)
                  ?.value ?? 0,
            };
          }),
        })),
    [claims, dates],
  );

  return (
    <Table
      enableRowHighlighting
      size="small"
      wrapperStyle={{ overflow: undefined }}
    >
      <TableHeader hidden={smDown} order={OrderBy.ASC}>
        When
      </TableHeader>
      <TableHeader style={{ width: 'auto' }}>Claimed By</TableHeader>
      <TableHeader align="center">Issues</TableHeader>
      <TableHeader align="center">Last</TableHeader>
      <TableHeader align="center">This</TableHeader>
      <TableHeader align="center">Next</TableHeader>
      {processedClaims.map(({ date, member, minutes, issues }) => {
        const [lastWeek, thisWeek, nextWeek] = minutes;

        return (
          <TableRow>
            <TableCell>{format(date, 'dd/MM/yyyy HH:mm')}</TableCell>
            <TableCell>
              <span className={classes.name}>
                <Link
                  to={`${_peopleMembers.path}/${member.id}`}
                  target="_blank"
                  className={classes.text}
                >
                  {formatPersonName(member, { lastNameFirst: true })}
                </Link>
                {isAfter(new Date(event.endAt), new Date()) &&
                  event.member?.id !== member.id && (
                    <IconButton>
                      <AddIcon
                        fontSize="small"
                        onClick={() =>
                          updateEventAttendee({
                            variables: {
                              input: {
                                id: claims[0].event.id,
                                patch: { member: { id: member.id } },
                              },
                            },
                          })
                        }
                      />
                    </IconButton>
                  )}
              </span>
            </TableCell>
            <TableCell
              style={{ justifyContent: 'center', textAlign: 'center' }}
            >
              {!!issues?.length ? (
                <Box style={{ color: 'red' }}>
                  {issues.map((issue) => (
                    <Box>{issue}</Box>
                  ))}
                </Box>
              ) : (
                <CheckIcon fontSize="small" />
              )}
            </TableCell>
            <TableCell
              style={{
                backgroundColor: calcBackgroundColor(
                  lastWeek.totalMinutes,
                  lastWeek.allowedMinutes,
                ),
              }}
            >
              <Typography className={clsx(classes.text, classes.number)}>
                {roundNumber(lastWeek.totalMinutes / 60, 1)}/
                {roundNumber(lastWeek.allowedMinutes / 60, 1)}
              </Typography>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: calcBackgroundColor(
                  thisWeek.totalMinutes,
                  thisWeek.allowedMinutes,
                ),
              }}
            >
              <Typography className={clsx(classes.text, classes.number)}>
                {roundNumber(thisWeek.totalMinutes / 60, 1)}/
                {roundNumber(thisWeek.allowedMinutes / 60, 1)}
              </Typography>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: calcBackgroundColor(
                  nextWeek.totalMinutes,
                  nextWeek.allowedMinutes,
                ),
              }}
            >
              <Typography className={clsx(classes.text, classes.number)}>
                {roundNumber(nextWeek.totalMinutes / 60, 1)}/
                {roundNumber(nextWeek.allowedMinutes / 60, 1)}
              </Typography>
            </TableCell>
          </TableRow>
        );
      })}
    </Table>
  );
};

export default EventClaimsTable;

import { ApolloQueryResult } from '@apollo/client';
import {
  Branch,
  GetMeQuery,
  Member,
  ModuleType,
  Org,
  Permission,
  User,
} from '@timed/gql';
import { createContext } from 'react';

export type AuthContextType = {
  /**
   * Refetch GetMeQuery results.
   */
  refetch: () => Promise<ApolloQueryResult<GetMeQuery>> | void;

  /**
   * Check if user has permission to preform an action.
   */
  permissible: (values: {
    permissions?: Permission | Permission[];
    admin?: boolean;
    tester?: boolean;
  }) => boolean;

  /**
   * Log out the current user
   */
  logout: () => void;

  /**
   * User's active org
   */
  org?:
    | (Pick<
        Org,
        'id' | 'legalName' | 'preferredName' | 'membersCanSeeClockedTimes'
      > & {
        schedulingContactPerson?: Pick<
          Member,
          'id' | 'firstName' | 'lastName' | 'preferredName' | 'phone'
        > | null;
        schedulingContactPerson2?: Pick<
          Member,
          'id' | 'firstName' | 'lastName' | 'preferredName' | 'phone'
        > | null;
      })
    | null;

  /**
   * Set the current user's selected org.
   */
  setOrg: (member: Pick<Member, 'id'>) => void;

  /**
   * User's accessible orgs
   */
  orgs: Pick<Org, 'id' | 'legalName' | 'preferredName'>[];

  /**
   * The current user's selected branches.
   * This array will either consist of a sigle branch or all accessible branches.
   */
  branch?: Pick<Branch, 'id' | 'name'>;

  /**
   * Set the current user's selected branches.
   */
  setBranch: (branch?: Pick<Branch, 'id' | 'name'>) => void;

  /**
   * The current user's accessible branches
   */
  branches: Array<Pick<Branch, 'id' | 'name'>>;

  /**
   * User's active member
   */
  member?: Pick<
    Member,
    | 'id'
    | 'color'
    | 'superAdmin'
    | 'admin'
    | 'permissions'
    | 'firstName'
    | 'middleName'
    | 'lastName'
    | 'preferredName'
    | 'schedulable'
  > | null;

  members: (Pick<Member, 'id'> & { org: Pick<Org, 'id' | 'preferredName'> })[];

  /**
   * User's personal data
   */
  user: Pick<
    User,
    'id' | 'firstName' | 'middleName' | 'lastName' | 'preferredName'
  >;

  moduleAccess: ModuleType[];

  /**
   * Overridden permissions.
   */
  overriddenPermission?: Partial<Pick<Member, 'admin' | 'permissions'>>;

  /**
   * Temporarily change the current user's permissions
   */
  overridePermissions: React.Dispatch<
    React.SetStateAction<
      Partial<Pick<Member, 'admin' | 'permissions'>> | undefined
    >
  >;
};

const AuthContext = createContext<AuthContextType>({
  refetch: () => {},
  permissible: () => true,
  logout: () => {},
  overridePermissions: () => {},
  setBranch: () => {},
  setOrg: () => {},
  branches: [],
  org: null,
  orgs: [],
  user: { id: '', firstName: '' },
  members: [],
  moduleAccess: [],
});

export default AuthContext;

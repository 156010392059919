import { Event } from '@timed/gql';
import { CSSProperties, ComponentType, Props, createContext } from 'react';

/**
 * Type of interactable calendar entity.
 */
export type CalendarEntityType = 'cell' | 'event';

/**
 * Type of entity interaction.
 */
export type CalendarEntityInteraction = 'over' | 'down' | 'up';

/**
 * Interactable calendar entity.
 */
export type CalendarEntity = {
  key?: string;
  type: CalendarEntityType;
};

/**
 * Function type to interact with a calendar entity.
 */
export type CalendarInteractWithEntityFn = {
  reset?: boolean;
  target: CalendarEntity;
  interaction?: CalendarEntityInteraction;
};

/**
 * Interaction key/time pair.
 */
export type CalendarEntityInteractionRecord = {
  key: string;
  time: Date;
};

/**
 * Recorded entity interactions.
 */
export type CalendarEntityInteractions = {
  [key in CalendarEntityInteraction]: CalendarEntityInteractionRecord | null;
};

/**
 * Calendar event entity.
 */
export type CalendarEventEntity = {
  /**
   * Unique ID.
   */
  id: string;
  /**
   * Start datetime of the event.
   */
  startAt: Date;
  /**
   * End datetime of the event.
   */
  endAt: Date;
  /**
   * Duration in minutes of the event.
   */
  duration: number;
  /**
   * CSS styling.
   */
  style?: CSSProperties;
  /**
   * Content to display inside the event.
   */
  content: JSX.Element;
  /**
   * Event parts, split on each start-of-day occuring within the event period.
   */
};

/**
 * Calendar event entity.
 */
export type CalendarEventEntityPart = {
  /**
   * Start datetime of the event part.
   */
  startAt: Event['startAt'];
  /**
   * End datetime of the event part.
   */
  endAt: Event['endAt'];
  /**
   * Duration in minutes of the event part.
   */
  duration: number;
  width: number;
  height: number;
  leftOffset: number;
  topOffset: number;
  futureOverlaps: number;
  pastOverlaps: number;
  startOfOverlap: boolean;
  endOfOverlap: boolean;
  startOverlapsIndex: number;
  /**
   * The parent event entity
   */
  parent: CalendarEventEntity;
};

export type CalendarContextType = {
  interactive: boolean;
  today: Date;
  now: Date;
  from: Date;
  setFrom: (value: number | Date, redirect?: boolean) => void;
  range: number;
  setRange: (value: number, redirect?: boolean) => void;
  timezone?: string;
  setTimezone: (value?: string) => void;
  events: CalendarEventEntityPart[][];
  calcTime: (date: Date, reverse?: boolean) => Date;
  interactWithEntity: (props: CalendarInteractWithEntityFn) => void;
  entityInteractions: {
    [key in CalendarEntityType]: CalendarEntityInteractions;
  };
  weekStartsOn: 0 | 2 | 1 | 3 | 4 | 5 | 6;
  scrollableAreaRef: React.RefObject<HTMLDivElement> | null;
  cellClickModal: ComponentType | null;
  cellClickModalProps?: { [k: string]: unknown };
  eventClickModal: ComponentType | null;
  eventClickModalProps?: { [k: string]: unknown };
};

const CalendarContext = createContext<CalendarContextType>({
  interactive: true,
  today: new Date(),
  now: new Date(),
  from: new Date(),
  setFrom: () => {},
  range: 7,
  setRange: () => {},
  setTimezone: () => {},
  events: [],
  calcTime: () => new Date(),
  interactWithEntity: () => {},
  entityInteractions: {
    cell: {
      down: null,
      over: null,
      up: null,
    },
    event: {
      down: null,
      over: null,
      up: null,
    },
  },
  weekStartsOn: 1,
  scrollableAreaRef: null,
  cellClickModal: null,
  eventClickModal: null,
});

export default CalendarContext;

import { ClientListContext } from '@timed/client/components/List/context';
import { PaginationStatus, PaginationNavigation } from '@timed/common';
import { useContext } from 'react';

const ClientListPagination = () => {
  const { loading, totalCount, fetch, offset, limit } =
    useContext(ClientListContext);

  return (
    <>
      <PaginationNavigation
        action={(offset) => fetch({ offset })}
        limit={limit}
        offset={offset}
        totalCount={totalCount}
        size="large"
      />
      <PaginationStatus
        loading={loading}
        limit={limit}
        offset={offset}
        totalCount={totalCount}
      />
    </>
  );
};

export default ClientListPagination;

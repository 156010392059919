import { _peopleMembersProfileCategory } from '@timed/app';
import { useRouter } from '@timed/common';
import {
  MemberViewFiles,
  MemberViewFinancialDetails,
  MemberViewNotes,
  MemberViewOverview,
  MemberViewPermissions,
  MemberViewUnavailability,
  MemberViewUnavailabilityProvider,
} from '@timed/member';
import { Page } from '@timed/page';
import { RouteErrorNotFound } from '@timed/routes';

const RouteCoreSupportPeopleEmployeesProfileCategory = () => {
  const {
    query: { category, member },
  } = useRouter();

  let element!: JSX.Element;
  let label!: string;
  let path!: string;

  switch (category) {
    case 'overview':
      label = 'Overview';
      path = `/people/employees/${member}/overview`;
      element = <MemberViewOverview />;
      break;

    // case "shifts":
    //   label = "Shifts";
    //   path = `/people/employees/${profile}/shifts`;
    //   element = </>;
    //   break;

    case 'unavailability':
      label = 'Unavailability';
      path = `/people/employees/${member}/unavailability`;
      element = (
        <MemberViewUnavailabilityProvider>
          <MemberViewUnavailability />
        </MemberViewUnavailabilityProvider>
      );
      break;

    case 'notes':
      label = 'Notes';
      path = `/people/employees/${member}/notes`;
      element = <MemberViewNotes />;
      break;

    case 'finance':
      label = 'Finance';
      path = `/people/employees/${member}/finance`;
      element = <MemberViewFinancialDetails />;
      break;

    case 'files':
      label = 'Files';
      path = `/people/employees/${member}/files`;
      element = <MemberViewFiles />;
      break;

    case 'permissions':
      label = 'Permissions';
      path = `/people/employees/${member}/permissions`;
      element = <MemberViewPermissions />;
      break;

    // case "history":
    //   label = "History";
    //   path = `/people/employees/${profile}/history`;
    //   element = < />;
    //   break;
  }

  return !label || !path || !element ? (
    <RouteErrorNotFound />
  ) : (
    <Page {..._peopleMembersProfileCategory} path={path}>
      {element}
    </Page>
  );
};

export default RouteCoreSupportPeopleEmployeesProfileCategory;

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  ClientNdisPlanItemCategoryInput,
  ClientNdisPlanItemNameAutocomplete,
} from '@timed/client';
import {
  AutocompleteOption,
  CurrencyTextField,
  FormModal,
  ModalProps,
  roundNumber,
} from '@timed/common';
import {
  ClientNdisPlanItemCategory,
  ClientNdisPlanItemName,
  CreateClientNdisPlanItemDocument,
  CreateClientNdisPlanItemInput,
  OrderBy,
  QueryByIdInput,
  useCreateClientNdisPlanItemMutation,
} from '@timed/gql';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type ClientCreateNdisPlanItemFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  clientNdisPlan: QueryByIdInput;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        alignItems: 'start',
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
        flexDirection: 'column',
      },
    },
    group: {
      display: 'flex',
      gap: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        alignItems: 'center',
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
        flexDirection: 'column',
      },
    },
    input: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    textIconWrapper: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
  }),
);

type FormData = Omit<CreateClientNdisPlanItemInput, 'clientNdisPlan'>;

const ClientCreateNdisPlanItemFormModal = ({
  onClose,
  clientNdisPlan,
  ...modalProps
}: ClientCreateNdisPlanItemFormModalProps) => {
  const classes = useStyles();

  const [createEntity, response] = useCreateClientNdisPlanItemMutation();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      initialFunds: 0,
      category: ClientNdisPlanItemCategory.CORE,
    },
  });

  const category = watch('category');

  const [name, setName] = useState<AutocompleteOption<
    Pick<ClientNdisPlanItemName, 'id' | 'name'>
  > | null>(null);

  useEffect(() => setName(null), [setName, category]);

  const onSuccess = () => {
    const cache = response.client.cache;

    cache.modify({
      fields: {
        clientNdisPlanItems(existing = []) {
          return [
            ...existing,
            cache.writeQuery({
              data: response.data,
              query: CreateClientNdisPlanItemDocument,
            }),
          ];
        },
      },
    });

    reset();
  };

  const onSubmit = async (input: FormData) => {
    // Delete nullish values
    Object.keys(input).forEach(
      (k) =>
        (input[k as keyof FormData] === null ||
          //@ts-ignore
          input[k as keyof FormData] === 'undefined') &&
        delete input[k as keyof FormData],
    );

    if (input.initialFunds)
      input.initialFunds = roundNumber(
        Number((input.initialFunds as unknown as string).replace(/,/g, '')) *
          100,
        0,
      );

    input.name = { id: name?.value.id ?? '' };

    createEntity({
      variables: {
        input: {
          clientNdisPlan: _.pick(clientNdisPlan, ['id']),
          ...input,
        },
      },
    });
  };

  return (
    <FormModal
      modalProps={modalProps}
      title="Add NDIS Plan Item"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.group}>
          <Box className={classes.input}>
            <Typography className={classes.bold}>Category</Typography>
            <ClientNdisPlanItemCategoryInput
              required
              name="category"
              label={undefined}
              control={control}
              error={!!errors.category}
              helperText={errors.category?.message}
              formControlProps={{
                className: classes.input,
                size: 'small',
                variant: 'outlined',
                style: { width: 450 },
              }}
            />
          </Box>
        </Box>
        {category && (
          <Box className={classes.group}>
            <Box className={classes.input}>
              <Typography className={classes.bold}>Name</Typography>
              <ClientNdisPlanItemNameAutocomplete
                autocompleteProps={{ size: 'small', style: { width: 450 } }}
                name={name}
                setName={setName}
                orderBy={[{ name: OrderBy.ASC }]}
                where={{
                  category: {
                    _eq:
                      category === ClientNdisPlanItemCategory.CORE
                        ? 0
                        : category ===
                          ClientNdisPlanItemCategory.CAPACITY_BUILDING
                        ? 1
                        : 2,
                  },
                }}
              />
            </Box>
          </Box>
        )}
        <Box className={classes.group}>
          <Box className={classes.input}>
            <Typography className={classes.bold}>Initial Funds</Typography>
            <CurrencyTextField
              name="initialFunds"
              inputProps={{ style: { width: 414, textAlign: 'right' } }}
              size="small"
              control={control}
              error={!!errors.initialFunds}
              helperText={errors.initialFunds?.message}
            />
          </Box>
        </Box>
      </Box>
    </FormModal>
  );
};

export default ClientCreateNdisPlanItemFormModal;

import {
  BaseTextFieldProps,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { TransformInput, validateRequired } from '@timed/common';
import CodeMirror, {
  EditorView,
  ReactCodeMirrorProps,
} from '@uiw/react-codemirror';
import {
  Controller,
  RegisterOptions,
  UseControllerProps,
} from 'react-hook-form';

export type CodeInputProps = Pick<
  BaseTextFieldProps,
  'name' | 'label' | 'required' | 'defaultValue' | 'error' | 'helperText'
> &
  UseControllerProps<any> & {
    validation?: Omit<RegisterOptions, 'required'>;
    transform?: TransformInput;
    inputProps?: ReactCodeMirrorProps;
    onChange?: (v: string) => void;
    onBlur?: (v: string) => void;
  };

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      dispaly: 'inline-flex',
      flexDirection: 'column',
      position: 'relative',
      gap: theme.spacing(10),

      // '& .ͼ1.cm-focused': {
      //   border: '1px dotted ' + theme.palette.primary.main,
      // },
    },
    error: {
      color: theme.palette.error.main,
      margin: theme.spacing(1, 3.5, 0, 3.5),
      fontSize: '0.6964285714285714rem',
    },
    label: {
      margin: theme.spacing(0, 3.5, 0, 3.5),
      color: '#5E6C84',
      fontSize: 11.5,
    },
  }),
);

const CodeInput = ({
  validation,
  control,
  defaultValue = '',
  transform,
  onChange: onChangeControlled,
  onBlur: onBlurControlled,
  inputProps,
  helperText,
  label,
  ...props
}: CodeInputProps) => {
  const classse = useStyles();
  const theme = useTheme();

  const inputTheme = EditorView.theme({
    '&.cm-editor': {
      backgroundColor: theme.palette.background.default,
      border: '1px solid rgba(0, 0, 0, 0.23)',
      '&:hover': {
        border: '1px solid #172B4D',
      },
    },
    '&.cm-editor.cm-focused': {
      border: '1px solid ' + theme.palette.primary.main,
    },
  });

  const handleOnChange = (value: any, onChange: (...event: any[]) => void) => {
    try {
      onChange(value);
      !!onChangeControlled && onChangeControlled(value);
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnBlur = (value: any, onBlur: (...event: any[]) => void) => {
    try {
      onBlur(value);
      !!onBlurControlled && onBlurControlled(value);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Controller
      key={props.name}
      name={props.name!}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, ...field },
        fieldState: { error },
      }) => (
        <div className={classse.wrapper}>
          {!!label && (
            <label className={classse.label}>Fixed expenses JSON</label>
          )}
          <CodeMirror
            theme={inputTheme}
            onChange={(v) => handleOnChange(v, onChange)}
            onBlur={(v) => handleOnBlur(v, onBlur)}
            style={{
              border: !!error ? '1px solid red' : undefined,
              fontSize: 14,
            }}
            {...inputProps}
            {...field}
          />
          {!!error && <p className={classse.error}>{helperText}</p>}
        </div>
      )}
      rules={
        validation && props.required
          ? { ...validateRequired, ...validation }
          : validation
      }
    />
  );
};

export default CodeInput;

import { Checkbox, CheckboxProps } from '@timed/common';

const ClientAllowAutomaticEventPopulationCheckbox = (props: CheckboxProps) => (
  <Checkbox
    defaultChecked={false}
    label="Grant the system permission to automatically assign support workers"
    {...props}
  />
);

export default ClientAllowAutomaticEventPopulationCheckbox;

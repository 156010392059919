import { TextField, TextFieldProps } from '@timed/common';
import { australianBusinessNameRegex } from '@timed/common/regexps';
import { RegisterOptions } from 'react-hook-form';

export const validateAustralianBusinessName: RegisterOptions = {
  pattern: {
    value: australianBusinessNameRegex,
    message: 'Invalid character(s)',
  },
  maxLength: { value: 200, message: 'Too long' },
};

const AustralianBusinessNameInput = (props: TextFieldProps) => (
  <TextField
    label="Business Name"
    type="text"
    validation={validateAustralianBusinessName}
    {...props}
  />
);

export default AustralianBusinessNameInput;

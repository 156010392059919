import {
  Box,
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useAlert } from '@timed/alert';
import { useAuth } from '@timed/auth';
import {
  Button,
  DateInput,
  formatPersonName,
  roundNumber,
  Select,
} from '@timed/common';
import {
  EntityState,
  OrderBy,
  useGetActivitySlipMembersLazyQuery,
  useGetMemberWorkedHoursLazyQuery,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';

import {
  addDays,
  eachWeekOfInterval,
  format,
  isAfter,
  isBefore,
  isMonday,
  isWeekend,
  startOfWeek,
  subWeeks,
} from 'date-fns';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

export type MemberWorkedHours = {
  member: { id: string; name: string };
  dates: { date: Date; weekMinutes: number; weekendMinutes: number }[];
};

type FormData = {
  members: string[];
  startAt?: Date;
  endAt?: Date;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      alignItems: 'start',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
    },
    data: {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      // maxWidth: 1000,
      display: 'inline-grid',
      columnGap: theme.spacing(0),
      overflow: 'auto',
      '& .MuiTypography-root': {
        fontSize: 12,
      },
    },
  }),
);

const ReportMemberWorkedHours = () => {
  const classes = useStyles();

  const { branch } = useAuth();

  const [hoveredMemberId, setHoveredMemberId] = useState<string | null>();

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      startAt: subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 12),
      endAt: startOfWeek(new Date(), { weekStartsOn: 1 }),
    },
  });

  const alert = useAlert();

  const startAt = watch('startAt');
  const endAt = watch('endAt');
  const memberIds = watch('members');

  // const [reportData, setReportData] = useState<Array<MemberWorkedHours> | undefined>(undefined);

  const [getMembers, membersResposne] = useGetActivitySlipMembersLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [getEvents, eventsResponse] = useGetMemberWorkedHoursLazyQuery({
    fetchPolicy: 'network-only',
  });

  const eachWeek = useMemo<Date[]>(() => {
    if (!startAt || !endAt) return [];

    const weeks = eachWeekOfInterval(
      { start: startAt, end: endAt },
      { weekStartsOn: 1 },
    );

    // Remove last date if it is a Monday
    if (isMonday(weeks[weeks.length - 1])) weeks.pop();

    return weeks;
  }, [startAt, endAt]);

  useEffect(() => {
    eventsResponse.error &&
      alert.push({
        message: eventsResponse.error.graphQLErrors[0].message,
        severity: 'error',
      });
  }, [eventsResponse.error, alert]);

  useLoadingEffect(eventsResponse.loading);

  /**
   * Fetch members
   */
  useEffect(() => {
    getMembers({
      variables: {
        input: {
          entityStates: [EntityState.NORMAL, EntityState.ARCHIVED],
          where: {
            branchMembers: branch
              ? { branch: { id: { _eq: branch.id } } }
              : undefined,
            _or: [
              { schedulable: { _eq: true } },
              { events: { id: { _ne: null } } },
            ],
          },
          orderBy: [{ lastName: OrderBy.ASC_NULLS_FIRST }],
        },
      },
    });
  }, [branch, getMembers]);

  /**
   * Fetch events
   */
  useEffect(() => {
    if (!!memberIds?.length && startAt && endAt) {
      getEvents({
        variables: {
          input: {
            where: {
              startAt: { _lt: endAt },
              endAt: { _gt: startAt },
              member: { id: { _in: memberIds } },
            },
            orderBy: [{ startAt: OrderBy.ASC }, { duration: OrderBy.ASC }],
            entityStates: [EntityState.NORMAL, EntityState.ARCHIVED],
          },
        },
      }).catch((e) => {});
    }
  }, [startAt, endAt, memberIds, getEvents]);

  /**
   * Set select field default value
   */
  useEffect(() => {
    if (membersResposne.data)
      setValue(
        'members',
        membersResposne.data.members.map(({ id }) => id),
      );
  }, [membersResposne.data, setValue]);

  const reportData = useMemo(() => {
    if (
      !!eventsResponse.data &&
      !!startAt &&
      !!endAt &&
      eachWeek &&
      !!memberIds.length
    ) {
      const workedHours: MemberWorkedHours[] = [];

      memberIds.forEach((memberId) => {
        workedHours.push({
          member: {
            id: memberId,
            name: formatPersonName(
              membersResposne.data?.members.find(({ id }) => id === memberId)!,
              { capitaliseLastName: true, lastNameFirst: true },
            ),
          },
          dates: eachWeek.map((date) => {
            const thisMembersEvents =
              eventsResponse.data?.events
                .filter((event) => event.member?.id === memberId)
                .filter(
                  ({ startAt }) =>
                    isEqual(new Date(startAt), date) ||
                    (isAfter(new Date(startAt), date) &&
                      isBefore(new Date(startAt), addDays(date, 7))),
                ) || [];

            return {
              date,
              weekMinutes:
                thisMembersEvents
                  // Filter out weekend events
                  .filter(({ startAt }) => !isWeekend(new Date(startAt)))
                  .map(({ duration, passive }) => (!!passive ? 240 : duration))
                  .reduce((a, b) => a + b, 0) || 0,
              weekendMinutes:
                thisMembersEvents
                  // Filter out non-weekend events
                  .filter(({ startAt }) => isWeekend(new Date(startAt)))
                  .map(({ duration, passive }) => (!!passive ? 240 : duration))
                  .reduce((a, b) => a + b, 0) || 0,
            };
          }),
        });
      });

      return workedHours.filter(
        (work) =>
          !work.dates.every(
            (date) => date.weekMinutes === 0 && date.weekendMinutes === 0,
          ),
      );
    } else return [];
  }, [
    eventsResponse.data,
    startAt,
    endAt,
    eachWeek,
    memberIds,
    membersResposne.data?.members,
  ]);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.row}>
        <Select
          multiple
          control={control}
          defaultValue={[]}
          name="members"
          label="Employees"
          items={membersResposne.data?.members.map((member) => ({
            label: formatPersonName(member, { lastNameFirst: true }),
            value: member.id,
          }))}
          formControlProps={{
            variant: 'outlined',
            size: 'small',
            style: { width: 200 },
          }}
          renderValue={() => memberIds.length + ' selected'}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setValue(
              'members',
              membersResposne.data?.members.map(({ id }) => id) || [],
            );
          }}
        >
          All
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setValue('members', []);
          }}
        >
          Clear
        </Button>
      </Box>
      {!!memberIds?.length && (
        <Box className={classes.row}>
          <DateInput
            label="From"
            name="startAt"
            control={control}
            inputVariant="outlined"
            size="small"
            error={!!errors.startAt}
            helperText={errors.startAt?.message}
          />
          <DateInput
            label="To"
            name="endAt"
            control={control}
            inputVariant="outlined"
            size="small"
            error={!!errors.endAt}
            helperText={errors.endAt?.message}
          />
        </Box>
      )}
      {reportData && (
        <>
          <Divider />
          <Box
            className={classes.data}
            style={{
              gridTemplateColumns: `max-content repeat(${
                eachWeek.length * 2
              }, 64px)`,
            }}
            onMouseOut={() => {
              setHoveredMemberId(null);
            }}
          >
            <Box></Box>
            {eachWeek.map((date) => (
              <Typography
                className={classes.bold}
                style={{
                  gridColumn: 'span 2',
                  justifySelf: 'center',
                  fontSize: 16,
                }}
              >
                {format(date, 'dd/MM')}
              </Typography>
            ))}
            <Box></Box>
            {eachWeek.map(() => (
              <>
                <Typography
                  className={classes.bold}
                  style={{ justifySelf: 'center' }}
                >
                  Weekday
                </Typography>
                <Typography
                  className={classes.bold}
                  style={{ justifySelf: 'center' }}
                >
                  Weekend
                </Typography>
              </>
            ))}
            {reportData.map(({ member, dates }) => (
              <>
                <Typography
                  className={classes.bold}
                  onMouseOver={() => {
                    setHoveredMemberId(member.id);
                  }}
                  style={{
                    backgroundColor:
                      hoveredMemberId === member.id ? 'yellow' : undefined,
                  }}
                >
                  {member.name}
                </Typography>
                {dates.map(({ date, weekMinutes, weekendMinutes }) => (
                  <>
                    <Typography
                      style={{
                        justifySelf: 'center',
                        backgroundColor:
                          hoveredMemberId === member.id ? 'yellow' : undefined,
                      }}
                      onMouseOver={() => {
                        setHoveredMemberId(member.id);
                      }}
                    >
                      {weekMinutes
                        ? roundNumber(weekMinutes / 60, 2) || '-'
                        : '-'}
                    </Typography>
                    <Typography
                      style={{
                        justifySelf: 'center',
                        backgroundColor:
                          hoveredMemberId === member.id ? 'yellow' : undefined,
                      }}
                      onMouseOver={() => {
                        setHoveredMemberId(member.id);
                      }}
                    >
                      {weekendMinutes
                        ? roundNumber(weekendMinutes / 60, 2) || '-'
                        : '-'}
                    </Typography>
                  </>
                ))}
              </>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default ReportMemberWorkedHours;

import { jsDateToLocalISO8601DateString } from '@timed/common';
import {
  Client,
  Event,
  GetActivitySlipsQuery,
  GetPublicHolidaysQuery,
  Maybe,
  Member,
  PersonNamesFragment,
  PlanManager,
} from '@timed/gql';
import {
  addDays,
  addHours,
  eachDayOfInterval,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  min,
  startOfDay,
  subMilliseconds,
} from 'date-fns';
import _ from 'lodash';

/**
 * An event as requested from the API
 */
export type PayrollEvent = {
  original: Pick<
    Event,
    | 'id'
    | 'passive'
    | 'activeAssist'
    | 'travelDistance'
    | 'travelTime'
    | 'billable'
    | 'billingRegistrationGroup'
    | 'payable'
    | 'bonusPay'
    | 'clockedOnAt'
    | 'clockedOffAt'
    | 'hasNotes'
  > & {
    startAt: Date;
    endAt: Date;
    passiveStartAt?: Date;
    cancelled: boolean;
    publicHoliday: boolean;
  } & {
    client: Pick<Client, 'id' | 'ndisId'> &
      PersonNamesFragment & {
        planManager?: Maybe<Pick<PlanManager, 'id' | 'name'>>;
      };
  } & {
    member?: Maybe<
      Pick<Member, 'id' | 'externalId' | 'bonusEligible'> & PersonNamesFragment
    >;
  };
  shifts: PayrollShift[];
};

/**
 * Payroll shift which is, or makes up parts of, an event.
 */
export type PayrollShift = {
  startAt: Date;
  endAt: Date;
  passive?: boolean;
  passiveStartAt?: Date;
  activeAssist?: boolean;
};

type FormatEventsAsShiftsFn = {
  events: GetActivitySlipsQuery['events'];
  publicHolidays: GetPublicHolidaysQuery['publicHolidays'];
  /**
   * Include only the specified dates
   */
  include?: {
    after: Date;
    before: Date;
  };
};

type GetShiftsFromEventFn = Pick<FormatEventsAsShiftsFn, 'include'> & {
  event: PayrollEvent['original'];
  from?: Date;
};

/**
 * Extract all shifts from an event
 */
const getShiftsFromEvent = ({
  event,
  include,
  from,
}: GetShiftsFromEventFn): PayrollShift[] => {
  // If from is not set, this is the first shift in an event.
  from ??= event.startAt;

  if (include && isAfter(from, include.before)) return [];

  const endOfDayOfShift = startOfDay(addDays(from, 1));

  const eventIsPassive =
    event.passive && !!event.passiveStartAt && !event.activeAssist;

  const shiftIsPassive = eventIsPassive && isEqual(from, event.passiveStartAt!);

  const to = !eventIsPassive
    ? min([event.endAt, endOfDayOfShift])
    : shiftIsPassive
    ? addHours(event.passiveStartAt!, 8)
    : isSameDay(from, event.passiveStartAt!)
    ? isBefore(from, event.passiveStartAt!)
      ? event.passiveStartAt!
      : min([event.endAt, endOfDayOfShift])
    : min([event.endAt, endOfDayOfShift]);

  let laterShifts: PayrollShift[] = [];

  if (isAfter(event.endAt, to) && (!include || !isEqual(to, include.before)))
    laterShifts = getShiftsFromEvent({ event, include, from: to });

  // Disregard this shift if it does not occur inside the inclusion dates
  if (include && isBefore(from, include.after)) return laterShifts;

  const thisShift: PayrollShift = { startAt: from, endAt: to };

  // Assign passive attributes to object if it is the passive period of a passive event
  if (shiftIsPassive)
    Object.assign(
      thisShift,
      _.pick(event, ['passive', 'passiveStartAt', 'activeAssist']),
    );

  return [thisShift, ...laterShifts];
};

export const formatEventAsShifts = ({
  events,
  publicHolidays,
  include,
}: FormatEventsAsShiftsFn) => {
  const payrollEvents: PayrollEvent[] = [];

  events.forEach((event) => {
    const eachDay = eachDayOfInterval({
      start: new Date(event.startAt),
      end: subMilliseconds(new Date(event.endAt), 1),
    }).map((date) => jsDateToLocalISO8601DateString(date));

    const original = {
      ..._.omit(event, ['cancel']),
      startAt: new Date(event.startAt),
      endAt: new Date(event.endAt),
      passiveStartAt: event.passiveStartAt
        ? new Date(event.passiveStartAt)
        : undefined,
      cancelled: !!event.cancel?.id,
      publicHoliday:
        event.publicHoliday ||
        !!publicHolidays.filter(
          ({ date, region }) =>
            (!region || region === event.region) &&
            eachDay.includes(jsDateToLocalISO8601DateString(new Date(date))),
        ).length,
    };

    const shifts = getShiftsFromEvent({ include, event: original });

    // Do not add event to array if no shifts were generated. A lack of generated shifts could
    // indicate that the event exists outside the inclusion dates.
    if (shifts.length) payrollEvents.push({ original, shifts });
  });

  return payrollEvents;
};

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useAuth } from '@timed/auth';
import {
  addServerErrors,
  DateInput,
  FormModal,
  ModalProps,
  ProfileInput,
  Textarea,
  TimeInput,
} from '@timed/common';
import {
  CreateClientNoteDocument,
  CreateClientNoteInput,
  OrderBy,
  useCreateClientNoteMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { isValid } from 'date-fns';
import _ from 'lodash';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

type NotesCreateNoteFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  clientId?: string;
};

type FormData = Omit<CreateClientNoteInput, 'event'> & {
  time: Date;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        alignItems: 'start',
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
        flexDirection: 'column',
      },
    },
    textarea: {
      backgroundColor: theme.palette.background.paper,
      width: 322,
      color: theme.palette.text.primary,
      border: '1px solid ' + theme.palette.text.disabled,
      // borderRadius: 0,
      [theme.breakpoints.up('md')]: {
        width: 483,
      },
    },
    address: {
      width: 322,
      [theme.breakpoints.up('md')]: {
        width: 483,
      },
    },
    select: {
      width: 322,
    },
    dates: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridAutoColumns: '120px 90px ',
      gap: theme.spacing(4),
      alignItems: 'center',
    },
    authors: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridAutoColumns: '200px 200px ',
      gap: theme.spacing(4),
      alignItems: 'center',
    },
    passive: {
      width: 322,
      backgroundColor: theme.palette.background.paper2,
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2, 4),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    groups: {
      display: 'grid',
      gridTemplateColumns: 'auto',
      gap: theme.spacing(4),
    },
    group: {
      display: 'grid',
      gridTemplateColumns: 'max-content',
      gridAutoRows: 'max-content',
      gap: theme.spacing(1),
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    buttons: {
      flex: '0 1 max-content',
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

const NotesCreateNoteFormModal = ({
  onClose,
  clientId,
  ...modalProps
}: NotesCreateNoteFormModalProps) => {
  const classes = useStyles();

  const [createNote, { data, loading, error, client }] =
    useCreateClientNoteMutation();

  useLoadingEffect(loading);

  const { branch, member } = useAuth();

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      client: { id: clientId },
      commentedBy: { id: member!.id },
      commentedAt: new Date(),
      time: new Date(),
    },
  });

  if (!loading && !!error) addServerErrors(error, setError);

  const onSuccess = () => {
    const cache = client.cache;

    cache.modify({
      fields: {
        clientNotesAggregate: (existing = {}) => ({
          ..._.omit(existing, ['nodes']),
          nodes: [
            ...existing.nodes,
            cache.writeQuery({
              data: data,
              query: CreateClientNoteDocument,
            }),
          ],
        }),
      },
    });

    onClose();
  };

  const onSubmit = (values: FormData) => {
    createNote({
      variables: {
        input: { ..._.omit(values, ['time']) },
      },
    });
  };

  const handleChangeTime = useCallback(
    (date?: MaterialUiPickersDate) => {
      if (!!date && isValid(date)) {
        const currentDate = new Date(getValues('commentedAt'));
        currentDate.setHours(date.getHours(), date.getMinutes());
        setValue('commentedAt', currentDate);
      }
    },
    [getValues, setValue],
  );

  return (
    <FormModal
      modalProps={modalProps}
      title="New Note"
      loading={loading}
      success={!!data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.groups}>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Participant</Typography>
            <ProfileInput
              required
              type="client"
              watch={watch}
              name="client.id"
              control={control}
              error={!!errors.client?.id}
              helperText={errors.client?.id?.message}
              formControlProps={{ variant: 'outlined', size: 'small' }}
              orderBy={[{ lastName: OrderBy.ASC }, { firstName: OrderBy.ASC }]}
              where={{
                branch: branch ? { id: { _eq: branch.id } } : undefined,
              }}
            />
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Date</Typography>
            <Box className={classes.dates}>
              <DateInput
                required
                name="commentedAt"
                control={control}
                inputVariant="outlined"
                size="small"
                error={!!errors.commentedAt}
                helperText={errors.commentedAt?.message}
              />
              <TimeInput
                required
                keyboard
                name="time"
                control={control}
                inputVariant="outlined"
                size="small"
                onChange={(date) => {
                  if (isValid(date)) handleChangeTime(date);
                }}
                inputProps={{ style: { textAlign: 'center' } }}
                error={!!errors.time}
              />
            </Box>
          </Box>
          {/* <Box className={classes.group}>
            <Typography className={classes.bold}>Type</Typography>
            <ClientNoteTypeInput
              required
              name="type"
              control={control}
              error={!!errors.type}
              helperText={errors.type?.message}
              formControlProps={{ variant: 'outlined', size: 'small' }}
            />
          </Box> */}
          {/* <Box className={classes.group}>
            <Typography className={classes.bold}>
              Support Worker / Author
            </Typography>
            <Box className={classes.authors}>
              <ProfileInput
                required
                type="member"
                watch={watch}
                name="commentedBy.id"
                control={control}
                // error={!!errors.commentedBy?.id}
                // helperText={errors.commentedBy?.id?.message}
                formControlProps={{ variant: 'outlined', size: 'small' }}
              />
              <TextField
                // required
                name="commentedByText"
                control={control}
                variant="outlined"
                size="small"
                error={!!errors.commentedByText}
                helperText={errors.commentedByText?.message}
              />
            </Box>
          </Box> */}
          <Box className={classes.group}>
            <Typography className={classes.bold}>Comment</Typography>
            <Textarea
              name="comment"
              control={control}
              minRows={10}
              maxRows={30}
              className={classes.textarea}
              validation={{
                maxLength: {
                  value: 10000,
                  message: 'Exceeding 10,000 characters',
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </FormModal>
  );
};

export default NotesCreateNoteFormModal;

import { _reports } from '@timed/app';
import { Page } from '@timed/page';
import { ReportWizard } from '@timed/report';

const RouteCoreSupportReports = () => (
  <Page {..._reports}>
    <ReportWizard />
  </Page>
);

export default RouteCoreSupportReports;

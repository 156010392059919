import { Box, createStyles, makeStyles, useTheme } from '@material-ui/core';
import { red, yellow } from '@material-ui/core/colors';
import { _peopleMembers } from '@timed/app';
import { useAuth } from '@timed/auth';
import {
  DateInput,
  Link,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  formatPersonName,
  roundNumber,
} from '@timed/common';
import {
  EntityState,
  MemberHoursType,
  OrderBy,
  useReportMemberHoursGetMembers2LazyQuery,
} from '@timed/gql';
import {
  addYears,
  format,
  formatISO,
  isSameDay,
  isValid,
  startOfWeek,
} from 'date-fns';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      height: '100%',
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(4),
    },
    input: {
      display: 'flex',
      gap: theme.spacing(4),
    },
    blank: {
      background: `repeating-linear-gradient(135deg, ${theme.palette.background.paper}, ${theme.palette.background.paper} 8px, ${theme.palette.background.paper2} 8px, ${theme.palette.background.paper2} 16px);`,
    },
  }),
);

type FormData = {
  from?: Date;
  to?: Date;
};

const ReportMemberHours2 = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { branch } = useAuth();

  const { control, watch } = useForm<FormData>({
    defaultValues: {
      from: new Date(2023, 6, 1),
      to: addYears(new Date(2023, 6, 1), 1),
    },
  });

  const from = watch('from');
  const to = watch('to');

  /**
   * Fetch members.
   */
  const [getReport, { data, loading }] =
    useReportMemberHoursGetMembers2LazyQuery();

  useEffect(() => {
    if (!!from && isValid(from) && !!to && isValid(to))
      getReport({
        fetchPolicy: 'network-only',
        variables: {
          membersInput: {
            where: {
              branchMembers: !!branch
                ? { branch: { id: { _eq: branch.id } } }
                : undefined,
              events: {
                startAt: { _lt: to },
                endAt: { _gt: from },
                payable: { _eq: true },
                archive: null,
                delete: null,
                cancel: null,
              },
            },
            orderBy: [
              { lastName: OrderBy.ASC },
              { firstName: OrderBy.ASC_NULLS_FIRST },
            ],
            entityStates: [EntityState.ARCHIVED, EntityState.NORMAL],
          },
          hoursInput: {
            where: {
              date: {
                _gte: format(from, 'yyyy-MM-dd'),
                _lte: format(to, 'yyyy-MM-dd'),
              },
            },
            orderBy: [{ date: OrderBy.ASC }],
          },
        },
      });
  }, [getReport, from, to, branch]);

  const dates = useMemo(
    () =>
      [
        ...new Set(
          data?.members.flatMap(({ hours }) => hours.map(({ date }) => date)),
        ),
      ]
        .map((date) => new Date(date))
        .sort((a, b) => a.getTime() - b.getTime()),
    [data?.members],
  );

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.input}>
        <DateInput
          onFocus={(e) => e.target.select()}
          keyboard
          autoOk
          control={control}
          name="from"
          label="From date"
          inputVariant="outlined"
          size="small"
        />
        <DateInput
          onFocus={(e) => e.target.select()}
          keyboard
          autoOk
          control={control}
          name="to"
          label="To date"
          inputVariant="outlined"
          size="small"
        />
      </Box>
      <Table
        inline
        showIndexColumn
        enableRowHighlighting
        loading={loading}
        wrapperStyle={{ flex: '1 1 0' }}
      >
        <TableHeader sticky>Employee</TableHeader>
        {dates.map((date, i) => (
          <TableHeader
            key={i}
            align="center"
            style={{
              backgroundColor: isSameDay(
                date,
                startOfWeek(new Date(), { weekStartsOn: 1 }),
              )
                ? theme.palette.secondary.main
                : undefined,
            }}
          >
            {format(date, 'dd/MM/yyyy')}
          </TableHeader>
        ))}
        {data?.members.map(({ hours, ...member }, i) => (
          <TableRow key={i}>
            <TableCell>
              <Link to={_peopleMembers.path + `/${member.id}`}>
                {formatPersonName(member, {
                  capitaliseLastName: true,
                  lastNameFirst: true,
                })}
              </Link>
            </TableCell>
            {dates.map((date, i2) => {
              let limit: number | string | null = hours.find(
                (hour) =>
                  hour.type === MemberHoursType.MAX_LIMIT &&
                  formatISO(date, { representation: 'date' }) === hour.date,
              )?.value;

              if (limit === null) limit = Infinity;

              if (typeof limit === 'number' && limit !== Infinity)
                limit = roundNumber(limit / 60, 1);

              let scheduled = hours.find(
                (hour) =>
                  hour.type === MemberHoursType.SCHEDULED &&
                  formatISO(date, { representation: 'date' }) === hour.date,
              )?.value;

              if (typeof scheduled === 'number')
                scheduled = roundNumber(scheduled / 60, 1);

              scheduled ??= 0;

              return (
                <TableCell
                  key={i2}
                  className={limit === undefined ? classes.blank : undefined}
                  style={{
                    color: scheduled > limit ? 'white' : undefined,
                    backgroundColor:
                      scheduled > limit
                        ? red[500]
                        : scheduled === limit && limit !== undefined
                        ? yellow[500]
                        : undefined,
                  }}
                >
                  {limit !== undefined &&
                    scheduled + ' / ' + (limit === Infinity ? '∞' : limit)}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </Table>
    </Box>
  );
};

export default ReportMemberHours2;

import { Grid, Typography } from '@material-ui/core';
import { _peopleSupportCoordinators } from '@timed/app';
import {
  ClientContext,
  ClientNdisIdInput,
  ClientPlanManagerInput,
  ClientSupportCoordinatorInput,
} from '@timed/client';
import {
  Block,
  IconButtonMulti,
  Link,
  addServerErrors,
  formatPersonName,
} from '@timed/common';
import { Client, QueryByIdInput, useUpdateClientsMutation } from '@timed/gql';
import { isEqual } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
  patch: {
    ndisId: Client['ndisId'];
    planManager?: QueryByIdInput | null;
    supportCoordinator?: QueryByIdInput | null;
  };
};

const ClientUpdateNdisDetailsForm = () => {
  const client = useContext(ClientContext);

  const [editing, setEditing] = useState<boolean>(false);

  const [updateClients, response] = useUpdateClientsMutation();

  const defaultValues: FormData = {
    patch: {
      ndisId: client.ndisId,
      planManager: client.planManager?.id
        ? { id: client.planManager?.id }
        : null,
      supportCoordinator: client.supportCoordinator?.id
        ? { id: client.supportCoordinator?.id }
        : null,
    },
  };

  const {
    handleSubmit,
    watch,
    control,
    setError,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });

  const currentValues = watch();

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = ({ patch }: FormData) => {
    // Delete "planManager" object if it's "id" property is nullish
    !patch.planManager?.id && (patch.planManager = null);

    // Delete "supportCoordinator" object if it's "id" property is nullish
    !patch.supportCoordinator?.id && (patch.supportCoordinator = null);

    updateClients({
      variables: {
        input: {
          ids: [client.id],
          patch,
        },
      },
    }).catch((e) => {});
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block
        title="Participant Details"
        topRight={
          <IconButtonMulti
            enabled={editing}
            changed={!isEqual(currentValues, defaultValues)}
            setEditing={setEditing}
            loading={response.loading}
            success={!!response.data}
          />
        }
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={4} md={3} lg={2}>
            <Typography>NDIS ID</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                client.ndisId
              ) : (
                <ClientNdisIdInput
                  fullWidth
                  name="patch.ndisId"
                  variant="outlined"
                  size="small"
                  control={control}
                  error={!!errors.patch?.ndisId}
                  helperText={errors.patch?.ndisId?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Plan Manager</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                client.planManager?.name
              ) : (
                <ClientPlanManagerInput
                  displayEmpty
                  fullWidth
                  name="patch.planManager.id"
                  control={control}
                  error={!!errors.patch?.planManager}
                  // helperText={errors.patch?.planManager?.message}
                  disabled={!editing}
                  formControlProps={{ size: 'small', variant: 'outlined' }}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Support Coordinator</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                <Link
                  to={
                    _peopleSupportCoordinators.path +
                    '/' +
                    client.supportCoordinator?.id
                  }
                >
                  {client.supportCoordinator &&
                    formatPersonName(client.supportCoordinator)}
                </Link>
              ) : (
                <ClientSupportCoordinatorInput
                  displayEmpty
                  fullWidth
                  label={undefined}
                  name="patch.supportCoordinator.id"
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  error={!!errors.patch?.supportCoordinator}
                  // helperText={errors.patch?.supportCoordinator?.message}
                  disabled={!editing}
                  formControlProps={{ size: 'small', variant: 'outlined' }}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
      </Block>
    </form>
  );
};

export default ClientUpdateNdisDetailsForm;

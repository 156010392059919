import {
  TableCellProps,
  TableHidable,
  TableIndex,
  TableInteractable,
} from '@timed/common';
import { createContext } from 'react';

 

export type TableRowProps = React.PropsWithChildren<
  TableIndex &
    TableHidable &
    TableInteractable<HTMLTableRowElement> & {
      highlighted?: boolean;
      shrunk?: boolean;
    }
>;

export type TableRowContextType = Omit<TableRowProps, keyof TableIndex> &
  Required<TableIndex> & {
    cells: React.ReactElement<TableCellProps, string>[];
  };

const TableRowContext = createContext<TableRowContextType>({
  index: 0,
  cells: [],
});

export default TableRowContext;

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Protected } from '@timed/auth';
import { FormModal, ModalProps, NumberInput, Select } from '@timed/common';
import { Permission } from '@timed/gql';
import {
  ScheduleContextType,
  ScheduleShowEventStatusColoursCheckbox,
  StartDateType,
} from '@timed/schedule';
import { useForm } from 'react-hook-form';

type ScheduleSettingsModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  setFrom: ScheduleContextType['setFrom'];
  range: ScheduleContextType['dates']['range'];
  showEventStatusColours: ScheduleContextType['showEventStatusColours'];
  setShowEventStatusColours: ScheduleContextType['setShowEventStatusColours'];
};

type FormData = {
  weekStartsOn?: number | null;
  startDate?: StartDateType | null;
  dayOffset?: number | null;
  showEventStatusColours?: boolean | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        alignItems: 'start',
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
        flexDirection: 'column',
      },
    },
    textarea: {
      backgroundColor: theme.palette.background.paper,
      width: 322,
      color: theme.palette.text.primary,
      border: '1px solid ' + theme.palette.text.disabled,
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.up('md')]: {
        width: 483,
      },
    },
    address: {
      width: 322,
      [theme.breakpoints.up('md')]: {
        width: 483,
      },
    },
    select: {
      width: 322,
    },
    dates: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridAutoColumns: '120px 90px 80px',
      gap: theme.spacing(4),
      alignItems: 'center',
    },
    passive: {
      width: 322,
      backgroundColor: theme.palette.background.paper2,
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2, 4),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    groups: {
      display: 'grid',
      gridTemplateColumns: 'auto',
      gap: theme.spacing(4),
    },
    group: {
      display: 'grid',
      gridTemplateColumns: 'max-content',
      gridAutoRows: 'max-content',
      gap: theme.spacing(1),
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    buttons: {
      flex: '0 1 max-content',
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

const ScheduleSettingsModal = ({
  onClose,
  range,
  setFrom,
  showEventStatusColours,
  setShowEventStatusColours,
  ...modalProps
}: ScheduleSettingsModalProps) => {
  const classes = useStyles();

  const { handleSubmit, control, watch } = useForm<FormData>({
    defaultValues: {
      showEventStatusColours,
      weekStartsOn: parseInt(
        localStorage.getItem('schedule.settings.weekStartsOn') ?? '1',
      ),
      dayOffset: parseInt(
        localStorage.getItem('schedule.settings.dayOffset') ?? '7',
      ),
      startDate:
        (localStorage.getItem(
          'schedule.settings.startDate',
        ) as StartDateType) ?? 'current-week',
    },
  });

  const startDate = watch('startDate');

  const onSuccess = () => {};

  const onSubmit = ({ showEventStatusColours, ...values }: FormData) => {
    Object.entries(values).forEach(([k, v]) => {
      !!v
        ? localStorage.setItem(`schedule.settings.${k}`, v.toString())
        : localStorage.removeItem(`schedule.settings.${k}`);
    });

    if (range > 7) setFrom();

    if (
      showEventStatusColours !== undefined &&
      showEventStatusColours !== null
    ) {
      setShowEventStatusColours(showEventStatusColours);
      localStorage.setItem(
        'schedule.settings.showEventStatusColours',
        showEventStatusColours.toString(),
      );
    }

    onClose();
  };

  return (
    <FormModal
      modalProps={modalProps}
      title="Calendar Settings"
      loading={false}
      success={false}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.groups}>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Week starts on</Typography>
            <Select
              control={control}
              name="weekStartsOn"
              formControlProps={{ variant: 'outlined', size: 'small' }}
              className={classes.select}
              items={[
                { label: 'Sunday', value: '0' },
                { label: 'Monday', value: '1' },
                { label: 'Tuesday', value: '2' },
                { label: 'Wednesday', value: '3' },
                { label: 'Thursday', value: '4' },
                { label: 'Friday', value: '5' },
                { label: 'Saturday', value: '6' },
              ]}
            />
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>
              Start calendar from
            </Typography>
            <Select
              control={control}
              name="startDate"
              formControlProps={{ variant: 'outlined', size: 'small' }}
              className={classes.select}
              items={[
                { label: 'Current day', value: 'current-day' },
                { label: 'Start of current week', value: 'current-week' },
                { label: 'Start of previous week', value: 'prev-week' },
                { label: 'Offset in days', value: 'day-offset' },
              ]}
            />
            {startDate === 'day-offset' && (
              <>
                <Typography className={classes.bold}>Offset in days</Typography>
                <NumberInput
                  control={control}
                  name="dayOffset"
                  variant="outlined"
                />
              </>
            )}
          </Box>
          <Protected permissions={Permission.EVENT_READ}>
            <Box className={classes.group}>
              <ScheduleShowEventStatusColoursCheckbox
                control={control}
                name="showEventStatusColours"
                defaultChecked={showEventStatusColours}
              />
            </Box>
          </Protected>
        </Box>
      </Box>
    </FormModal>
  );
};

export default ScheduleSettingsModal;

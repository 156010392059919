import { _settingsOrganisationMedication } from '@timed/app';
import { Page } from '@timed/page';
import {
  SettingsOrganisationMedicationForm,
  SettingsOrganisationMedicationTable,
} from '@timed/settings';

const RouteSettingsOrganisationMedication = () => {
  return (
    <Page {..._settingsOrganisationMedication}>
      <SettingsOrganisationMedicationForm />
      <SettingsOrganisationMedicationTable />
    </Page>
  );
};

export default RouteSettingsOrganisationMedication;

import { Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { _peopleMembers } from '@timed/app';
import { Protected } from '@timed/auth';
import {
  Block,
  IconButton,
  Link,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  formatPersonName,
} from '@timed/common';
import {
  Member,
  MemberBranchAllocationsOrderByInput,
  OrderBy,
  useGetBranchesQuery,
  useGetMemberBranchAllocationsLazyQuery,
} from '@timed/gql';
import {
  MemberBranchAllocationActionMenuButton,
  MemberCreateBranchAllocationFormModal,
} from '@timed/member';
import { format } from 'date-fns';
import { useModal } from 'mui-modal-provider';
import { useEffect, useState } from 'react';

type MemberBranchAllocationsTableProps = {
  member: Pick<Member, 'id' | 'firstName' | 'lastName'>;
};

const MemberBranchAllocationsTable = ({
  member,
}: MemberBranchAllocationsTableProps) => {
  const { showModal } = useModal();

  const [orderBy, setOrderBy] = useState<MemberBranchAllocationsOrderByInput>({
    date: OrderBy.DESC,
  });

  const branchesResponse = useGetBranchesQuery({
    variables: { input: { orderBy: [{ name: OrderBy.ASC }] } },
  });

  const [getBranchAllocations, branchAllocationsResponse] =
    useGetMemberBranchAllocationsLazyQuery();

  useEffect(() => {
    getBranchAllocations({
      variables: {
        input: {
          where: { member: { id: { _eq: member.id } }, deletedAt: null },
          orderBy: [orderBy],
        },
      },
    });
  }, [orderBy, getBranchAllocations, member.id]);

  const handleClickCreateButton = () => {
    const modal: { hide: () => void } = showModal(
      MemberCreateBranchAllocationFormModal,
      {
        onClose: () => {
          modal.hide();
        },
        member,
        branches: branchesResponse.data?.branches ?? [],
      },
    );
  };

  return (
    <Block
      title="Branch Allocations"
      description="For financial reporting purposes"
      topRight={
        <Protected admin>
          <IconButton
            onClick={() => {
              handleClickCreateButton();
            }}
          >
            <AddIcon />
          </IconButton>
        </Protected>
      }
    >
      {!branchesResponse.data?.branches.length ||
      !branchAllocationsResponse.data?.memberBranchAllocations.length ? (
        <Typography>No recorded branch allocations.</Typography>
      ) : (
        <Table
          inline
          enableRowHighlighting
          loading={
            branchesResponse.loading || branchAllocationsResponse.loading
          }
        >
          <TableHeader
            order={orderBy.date}
            onClick={() => {
              setOrderBy({
                date:
                  !!orderBy.date && orderBy.date === OrderBy.ASC
                    ? OrderBy.DESC
                    : OrderBy.ASC,
              });
            }}
          >
            Effective-from Date
          </TableHeader>
          {branchesResponse.data?.branches.map(({ name }, i) => (
            <TableHeader align="center" key={i}>
              {name}
            </TableHeader>
          ))}
          <TableHeader
            order={orderBy.createdBy?.lastName}
            onClick={() => {
              setOrderBy({
                createdBy: {
                  lastName:
                    !!orderBy.createdBy?.lastName &&
                    orderBy.createdBy.lastName === OrderBy.ASC_NULLS_LAST
                      ? OrderBy.DESC_NULLS_LAST
                      : OrderBy.ASC_NULLS_LAST,
                },
              });
            }}
          >
            Set By
          </TableHeader>
          <TableHeader align="center"></TableHeader>
          {branchAllocationsResponse.data?.memberBranchAllocations.map(
            ({ id, values, date, createdBy }, i) => (
              <TableRow key={i}>
                <TableCell>
                  <Typography>
                    {format(new Date(date), 'dd/MM/yyyy')}
                  </Typography>
                </TableCell>
                {branchesResponse.data?.branches.map(({ id }, i) => {
                  const value =
                    (values.find((value) => value.branchId === id)?.value ??
                      0) / 100;

                  return (
                    <TableCell key={i}>
                      <Typography>{value ? value + '%' : '-'}</Typography>
                    </TableCell>
                  );
                })}
                <TableCell>
                  <Typography>
                    <Link to={`${_peopleMembers.path}/${createdBy.id}`}>
                      {formatPersonName(createdBy, {
                        capitaliseLastName: true,
                        lastNameFirst: true,
                      })}
                    </Link>
                  </Typography>
                </TableCell>
                <TableCell>
                  <MemberBranchAllocationActionMenuButton
                    branchAllocation={{ id, date, values }}
                    branches={branchesResponse.data?.branches ?? []}
                  />
                </TableCell>
              </TableRow>
            ),
          )}
        </Table>
      )}
    </Block>
  );
};

export default MemberBranchAllocationsTable;

import { formatString } from '@timed/common/utils/formatString';

/**
 * Format a value if it is a string.
 * @param value The value to format
 * @returns Formatted string or original value if it was not a string
 */
export const formatTfn = (value: string): any => {
  if (typeof value === 'string') {
    const v = formatString(value, { deepTrim: true });

    return v.slice(0, 3) + ' ' + v.slice(3, 6) + ' ' + v.slice(6, 9);
  }

  return value;
};

import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  isMember,
  MemberContext,
  MemberUpdatePermissionsForm,
} from '@timed/member';
import React, { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'grid',
      gridAutoRows: 'max-content',

      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(8),
        marginRight: theme.spacing(8),
      },
    },
  }),
);

const MemberViewPermissions = () => {
  const classes = useStyles();
  const member = useContext(MemberContext);

  return isMember(member) ? (
    <Box className={classes.wrapper}>
      <MemberUpdatePermissionsForm member={member} />
    </Box>
  ) : (
    <></>
  );
};

export default MemberViewPermissions;

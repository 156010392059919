import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import Person from '@material-ui/icons/Person';
import { contrastingColor, hexToRGB } from '@timed/common';
import { Maybe } from '@timed/gql';

type AvatarProps = Omit<MuiAvatarProps, 'color'> & {
  content?: (string | undefined | null)[] | React.ReactElement;
  color?: Maybe<string>;
  showBorder?: boolean;
  textColor?: string;
};

const Avatar = ({
  content,
  color,
  textColor,
  showBorder = true,
  ...props
}: AvatarProps): JSX.Element => {
  // Add octothorpe if missing and set default
  color = color ? (color[0] !== '#' ? '#' + color : color) : '#fff';

  const style: React.CSSProperties | undefined = { backgroundColor: color };

  textColor = textColor || contrastingColor(hexToRGB(color), true) || '#000';

  if (textColor) Object.assign(style, { color: textColor });

  if ((color === '#fff' || color === '#ffffff') && showBorder)
    Object.assign(style, {
      border: '1px solid ' + grey[300],
    });

  return (
    <MuiAvatar {...props} style={style}>
      {content ? (
        Array.isArray(content) ? (
          content.map((word?: string | null) =>
            typeof word === 'string' ? word.charAt(0).toUpperCase() : null,
          )
        ) : (
          content
        )
      ) : (
        <Person style={{ fontSize: 20 }} />
      )}
    </MuiAvatar>
  );
};

export default Avatar;

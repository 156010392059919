import { Typography, createStyles, makeStyles } from '@material-ui/core';
import { blue, red } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { _peopleMembers } from '@timed/app';
import { useAuth } from '@timed/auth';
import { Checkbox, Link, formatPersonName, roundNumber } from '@timed/common';
import {
  OrderBy,
  ReportMemberHoursGetSchedulesQueryResult,
  useReportMemberHoursGetMembersQuery,
  useReportMemberHoursGetSchedulesLazyQuery,
} from '@timed/gql';
import clsx from 'clsx';
import {
  addWeeks,
  format,
  formatISO,
  isEqual,
  startOfWeek,
  subDays,
  subWeeks,
} from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
  rollingHourLimits: boolean;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    table: {
      display: 'flex',
      gap: theme.spacing(1),
      gridTemplateColumns: 'auto auto',
    },
    column: {
      // flexBasis: 100,
      display: 'flex',
      flexFlow: 'column',
      // alignItems: 'start',
      gap: theme.spacing(1),
    },
    cell: {
      height: 32,
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
      flexGrow: 1,
      gap: theme.spacing(1),
      backgroundColor: theme.palette.background.paper2,
      fontSize: 13,
      '& .MuiTypography-root': {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 13,
      },
    },
    header: {
      backgroundColor: theme.palette.background.paper3,
    },
    lowCell: {
      backgroundColor: blue[100],
    },
    errorCell: {
      backgroundColor: red[100],
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    indented: {
      paddingLeft: theme.spacing(4),
    },
    icon: {
      height: 24,
      width: 24,
      // margin: theme.spacing(0, 1, 0, 0),
      border: '1px solid ' + theme.palette.text.primary,
      color: theme.palette.common.white,
    },
    okIcon: {
      backgroundColor: theme.palette.background.paper2,
      color: theme.palette.text.primary,
    },
    lowIcon: {
      backgroundColor: blue[300],
    },
    errorIcon: {
      backgroundColor: red[300],
    },
    legend: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.paper2,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    legendItem: {
      display: 'flex',
      gap: theme.spacing(2),
    },
  }),
);

const ReportClientProfitLoss = () => {
  const classes = useStyles();

  const { branch } = useAuth();

  const weekStartsOn = 1; // MONDAY

  const weekCount = 10;

  const currentWeek = startOfWeek(new Date(), { weekStartsOn });

  const firstDate = subWeeks(currentWeek, 2);

  const [loadCount, setLoadCount] = useState<number>(0);

  const [schedules, setSchedules] = useState<
    ReportMemberHoursGetSchedulesQueryResult['data'][]
  >([]);

  const { control, watch } = useForm<FormData>({
    defaultValues: { rollingHourLimits: true },
  });

  const rollingHourLimits = watch('rollingHourLimits');

  const dates = useMemo(() => {
    const dates: Date[] = [];
    for (let i = 0; i < weekCount; i++) dates[i] = addWeeks(firstDate, i);
    return dates;
  }, [firstDate]);

  /**
   * Fetch members.
   */
  const membersResponse = useReportMemberHoursGetMembersQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        where: {
          events: {
            startAt: {
              _lt: dates[dates.length - 1],
            },
            endAt: {
              _gt: dates[0],
            },
          },
          branchMembers: !!branch
            ? { branch: { id: { _eq: branch.id } } }
            : undefined,
        },
        orderBy: [
          { lastName: OrderBy.ASC },
          { firstName: OrderBy.ASC_NULLS_FIRST },
        ],
      },
    },
  });

  /**
   * Fetch schedules.
   */
  const [getSchedules, schedulesResponse] =
    useReportMemberHoursGetSchedulesLazyQuery({
      fetchPolicy: 'network-only',
    });

  const memberIds: string[] = useMemo<string[]>(
    () => membersResponse.data?.members.map(({ id }) => id) || [],
    [membersResponse],
  );

  const fetchSchedules = useCallback(
    (index: number) =>
      getSchedules({
        variables: {
          membersInput: {
            where: {
              id: {
                _in: memberIds,
              },
            },
          },
          scheduleInput: {
            startAt: formatISO(dates[index], { representation: 'date' }),
            endAt: formatISO(
              index === weekCount - 1
                ? subDays(addWeeks(dates[index], 1), 1)
                : subDays(dates[index + 1], 1),
              { representation: 'date' },
            ),
          },
        },
        onCompleted: (data) => {
          setSchedules([...schedules, data]);
          setLoadCount(loadCount + 1);
        },
      }),

    [getSchedules, dates, loadCount, memberIds, schedules],
  );

  /**
   * Fetch schedules.
   */
  useEffect(() => {
    if (!!memberIds.length && loadCount < weekCount) {
      fetchSchedules(loadCount);
    }
  }, [fetchSchedules, loadCount, memberIds]);

  return (
    <div className={classes.wrapper}>
      <Checkbox
        control={control}
        name="rollingHourLimits"
        label="Rolling weekly hour limits"
      />
      <div className={classes.table}>
        <div className={classes.column}>
          <Typography
            className={clsx(classes.cell, classes.header, classes.bold)}
          >
            Employee
          </Typography>
          {membersResponse.data?.members.map((member) => (
            <Typography className={classes.cell}>
              <Link to={_peopleMembers.path + `/${member.id}`}>
                {formatPersonName(member, {
                  capitaliseLastName: true,
                  lastNameFirst: true,
                })}
              </Link>
            </Typography>
          ))}
          <Typography
            className={clsx(classes.cell, classes.bold, classes.indented)}
          >
            Total
          </Typography>
        </div>
        <div className={classes.column}>
          <Typography
            className={clsx(classes.cell, classes.header, classes.bold)}
          >
            Has Car
          </Typography>
          {membersResponse.data?.members.map((member) => (
            <Typography
              className={classes.cell}
              style={{ justifyContent: 'center' }}
            >
              {member.hasCar && 'Yes'}
            </Typography>
          ))}
          <Typography className={classes.cell}></Typography>
        </div>
        {dates.slice(1).map((date, i) => (
          <div className={classes.column}>
            <Typography
              className={clsx(classes.cell, classes.header, classes.bold)}
            >
              {isEqual(date, firstDate)
                ? 'Last Week'
                : isEqual(date, currentWeek)
                ? 'This Week'
                : format(date, 'dd/MM')}
            </Typography>
            {membersResponse.data?.members.map((member) => {
              const thisSchedule = schedules[i + 1]?.members.find(
                ({ id }) => id === member.id,
              )?.schedule;

              const lastSchedule = schedules[i]?.members.find(
                ({ id }) => id === member.id,
              )?.schedule;

              const actualAllowedMinutes =
                rollingHourLimits && !!lastSchedule
                  ? (thisSchedule?.allowedMinutes ?? 0) +
                    (lastSchedule.allowedMinutes - lastSchedule.totalMinutes)
                  : thisSchedule?.allowedMinutes ?? 0;

              const normalAllowedMinutes = thisSchedule?.allowedMinutes ?? 0;

              const totalMinutes = thisSchedule?.totalMinutes ?? 0;

              const overtime = totalMinutes > actualAllowedMinutes;

              const lowTime = totalMinutes < actualAllowedMinutes / 2;

              return (
                <div
                  className={
                    overtime
                      ? clsx(classes.cell, classes.errorCell)
                      : lowTime
                      ? clsx(classes.cell, classes.lowCell)
                      : classes.cell
                  }
                >
                  {overtime ? (
                    <ClearIcon
                      className={clsx(classes.icon, classes.errorIcon)}
                    />
                  ) : lowTime ? (
                    <CheckIcon
                      className={clsx(classes.icon, classes.lowIcon)}
                    />
                  ) : (
                    <CheckIcon className={clsx(classes.icon, classes.okIcon)} />
                  )}
                  <Typography>
                    {!!thisSchedule
                      ? `${roundNumber(totalMinutes / 60, 2)} / ${roundNumber(
                          actualAllowedMinutes / 60,
                          2,
                        )}`
                      : 0}
                    {!!thisSchedule &&
                      rollingHourLimits &&
                      ` (${roundNumber(normalAllowedMinutes / 60, 2)})`}
                  </Typography>
                </div>
              );
            })}

            <Typography className={clsx(classes.cell, classes.bold)}>
              {roundNumber(
                (schedules[i]?.members
                  .map(({ schedule: { totalMinutes } }) => totalMinutes)
                  .reduce((a, b) => a + b) ?? 0) / 60,
                2,
              )}
            </Typography>
          </div>
        ))}
      </div>
      <div className={classes.legend}>
        <Typography className={classes.bold}>Legend</Typography>
        <div className={classes.legendItem}>
          <CheckIcon className={clsx(classes.icon, classes.okIcon)} />
          <Typography>Ok hours.</Typography>
        </div>
        <div className={classes.legendItem}>
          <CheckIcon className={clsx(classes.icon, classes.lowIcon)} />
          <Typography>Working fewer than half allowed weekly hours.</Typography>
        </div>
        <div className={classes.legendItem}>
          <ClearIcon className={clsx(classes.icon, classes.errorIcon)} />
          <Typography>Working more than allowed weekly hours.</Typography>
        </div>
      </div>
    </div>
  );
};

export default ReportClientProfitLoss;

import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { TextField } from '@timed/common';
import { MemberListContext } from '@timed/member';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        width: 100,
        '& .MuiInputLabel-root': {
          fontSize: 12,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
          transform: 'translate(14px,9px) scale(1)',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
          transform: 'translate(14px,-6px) scale(0.75)',
        },
      },
      '& .MuiOutlinedInput-input': {
        flexGrow: 1,
        padding: theme.spacing(2.75, 9, 2.75, 4),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1.79, 8, 1.79, 2),
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid ' + theme.palette.divider,
      },
    },
  }),
);

const MemberListControlSearchInput = () => {
  const classes = useStyles();

  const {
    input: { search, setSearch },
  } = useContext(MemberListContext);

  const { control, setValue, watch } = useForm<{ search: string | null }>({
    defaultValues: { search: search ?? '' },
  });

  const watched = watch('search');

  const handleBlur = () => {
    handleSubmit();
  };

  const handleKeyUp: React.KeyboardEventHandler<HTMLDivElement> = ({ key }) => {
    if (key === 'Enter') handleSubmit();
  };

  const handleSubmit = () => {
    if (watched?.trim() !== search)
      setSearch(!!watched ? watched.trim() : watched);
  };

  useEffect(() => {
    if (watched?.trim() !== search) {
      const delayDebounceFn = setTimeout(() => {
        setSearch(!!watched ? watched.trim() : watched);
      }, 250);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [watched, search, setSearch]);

  useEffect(() => {
    if (!search) setValue('search', '');
  }, [search, setValue]);

  return (
    <TextField
      name="search"
      label="Search"
      control={control}
      onBlur={handleBlur}
      onKeyUp={handleKeyUp}
      className={classes.root}
      variant="outlined"
      size="small"
    />
  );
};

export default MemberListControlSearchInput;

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { ClientCreateFormModal } from '@timed/client/components/CreateFormModal';
import { IconButton } from '@timed/common';
import { useModal } from 'mui-modal-provider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: theme.spacing(10),
      border: '1px solid ' + theme.palette.divider,
      borderRadius: 0,
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.down('sm')]: {
        height: theme.spacing(8),
        width: theme.spacing(8),
      },
    },
  }),
);

const ClientListCreateClientButton = () => {
  const classes = useStyles();
  const { showModal } = useModal();

  const handleOpenCreateClientModal: React.MouseEventHandler<
    HTMLButtonElement
  > = () => {
    const modal: { hide: () => void } = showModal(ClientCreateFormModal, {
      onClose: () => {
        modal.hide();
      },
    });
  };

  return (
    <IconButton
      size="small"
      aria-label="New"
      className={classes.button}
      onClick={handleOpenCreateClientModal}
    >
      <AddIcon fontSize="small" />
    </IconButton>
  );
};

export default ClientListCreateClientButton;

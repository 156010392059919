export enum ClientObservationDefaultThreshold {
  BLOOD_SYSTOLIC_LOWER_1 = 100,
  BLOOD_SYSTOLIC_LOWER_2 = 90,

  BLOOD_SYSTOLIC_UPPER_1 = 130,
  BLOOD_SYSTOLIC_UPPER_2 = 180,

  BLOOD_DIASTOLIC_LOWER_1 = 60,
  BLOOD_DIASTOLIC_LOWER_2 = 50,

  BLOOD_DIASTOLIC_UPPER_1 = 100,
  BLOOD_DIASTOLIC_UPPER_2 = 135,

  HEART_LOWER_1 = 50,
  HEART_LOWER_2 = 40,

  HEART_UPPER_1 = 120,
  HEART_UPPER_2 = 140,

  TEMP_LOWER_1 = 35.5,
  TEMP_LOWER_2 = 34.5,

  TEMP_UPPER_1 = 38.5,
  TEMP_UPPER_2 = 39.5,

  SPO2_LOWER_1 = 95,
  SPO2_LOWER_2 = 90,

  RESPIRATION_LOWER_1 = 10,
  RESPIRATION_LOWER_2 = 5,

  RESPIRATION_UPPER_1 = 25,
  RESPIRATION_UPPER_2 = 30,
}

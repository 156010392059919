export enum Activity {
  BILLABLE_WEEKDAY = '01_011-0107-1-1',
  BILLABLE_AFTERNOON = '01_015-0107-1-1',
  BILLABLE_NIGHT = '01_002-0107-1-1',
  BILLABLE_SATURDAY = '01_013-0107-1-1',
  BILLABLE_SUNDAY = '01_014-0107-1-1',
  BILLABLE_PUBLIC_HOLIDAY = '01_012-0107-1-1',

  NON_BILLABLE_WEEKDAY = '00_011-0107-1-1',
  NON_BILLABLE_AFTERNOON = '00_015-0107-1-1',
  NON_BILLABLE_NIGHT = '00_002_0107-1-1',
  NON_BILLABLE_SATURDAY = '00_013-0107-1-1',
  NON_BILLABLE_SUNDAY = '00_014-0107-1-1',
  NON_BILLABLE_PUBLIC_HOLIDAY = '00_012-0107-1-1',

  OVERNIGHT = '01_010-0107-1-1',

  KM = "KM'S_",

  TRAVEL_PUBLIC_HOLIDAY = 'Travel Time_ Pub Holiday',
  TRAVEL_WEEKDAY = 'Travel Time_ Weekday',
  TRAVEL_AFTERNOON = 'Travel Time_ Afternoon',
  TRAVEL_NIGHT = 'Travel Time_ Night',
  TRAVEL_SATURDAY = 'Travel Time_ Satuurday',
  TRAVEL_SUNDAY = 'Travel Time_ Sunday',

  CANCELLED_OVERNIGHT = 'Cancelled_ Passive Overnight',
  CANCELLED_AFTERNOON = 'Cancelled_ Afternoon',
  CANCELLED_NIGHT = 'Cancelled_ Night',
  CANCELLED_PUBLIC_HOLIDAY = 'Cancelled_ Public Holiday',
  CANCELLED_SATURDAY = 'Cancelled_ Saturday',
  CANCELLED_SUNDAY = 'Cancelled_ Sunday',
  CANCELLED_WEEKDAY = 'Cancelled_ Weekday',

  BONUS_1 = 'Bonus A-Id #1',

  BILLABLE_ACCESS_COMMUNITY_WEEKDAY = '04_104-0125-6-1',
  BILLABLE_ACCESS_COMMUNITY_AFTERNOON = '04_103-0125-6-1',
  BILLABLE_ACCESS_COMMUNITY_SATURDAY = '04_105-0125-6-1',
  BILLABLE_ACCESS_COMMUNITY_SUNDAY = '04_106-0125-6-1',
  BILLABLE_ACCESS_COMMUNITY_PUBLIC_HOLIDAY = '04_102-0125-6-1',

  BILLABLE_DAILY_LIFE_SKILLS = '15_037-0117-1-3',
}

import { createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  ClientNdisPlanItemTransactionControlCategoryAutocomplete,
  ClientNdisPlanItemTransactionControlReloadButton,
  ClientNdisPlanItemTransactionControlSupplierAutocomplete,
} from '@timed/client';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
        gap: theme.spacing(1),
      },
    },
    group: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
    },
    controls: {
      [theme.breakpoints.down('sm')]: {
        flexGrow: 1,
      },
    },
  }),
);

const ClientNdisPlanItemTransactionControls = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={clsx(classes.group, classes.controls)}>
        <ClientNdisPlanItemTransactionControlReloadButton />
        {/* <ClientNdisPlanItemTransactionControlClearButton /> */}
        <ClientNdisPlanItemTransactionControlCategoryAutocomplete />
        <ClientNdisPlanItemTransactionControlSupplierAutocomplete />
      </div>
    </div>
  );
};

export default ClientNdisPlanItemTransactionControls;

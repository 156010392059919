import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {
  Block,
  Button,
  Checkbox,
  DateInput,
  formatAddress,
  IconButton,
  Modal,
  ModalProps,
  PersonNameFirstInput,
  PersonNameLastInput,
  PersonNameMiddleInput,
  roundNumber,
  TextField,
} from '@timed/common';
import { Member, useGetMemberEmploymentAgreementDataQuery } from '@timed/gql';
import { format, parse } from 'date-fns';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSignatureCanvas from 'react-signature-canvas';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { formatCurrency } from '@timed/common/utils/formatCurrency';
import { SupportWorkerEmploymentAgreement } from '@timed/upload/templates';

type UploadBankDetailsFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
};

type FormData = Required<Pick<Member, 'firstName'>> &
  Partial<Pick<Member, 'middleName' | 'lastName'>> & {
    email: string;
    address: string;
    dear: string;
    employmentStartDate: string;
    agreeClock: boolean;
    agreeVehicle: boolean;
    agreeDate: string;
    signature: string;
  };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    blockWrapper: {
      flexGrow: 1,
      display: 'flex',
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    italic: {
      fontStyle: 'italic',
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(4),
    },
    paper: {
      backgroundColor: theme.palette.background.default,
    },
    formWrapper: {
      maxWidth: 1000,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    printWrapper: {
      margin: 30,
      padding: theme.spacing(6),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(8),
      backgroundColor: theme.palette.common.white,
      border: '3px solid #6d2d84',
      '& h1': {
        fontWeight: 'normal',
        fontSize: 30,
        margin: 0,
      },
      '& h2': {
        fontWeight: 'lighter',
        fontSize: 20,
        margin: 0,
      },
    },
    instructions: {
      margin: 30,
      padding: theme.spacing(6),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      backgroundColor: 'lightyellow',
      border: '3px solid #6d2d84',
    },
    group: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    checkboxGroup: {
      padding: theme.spacing(6),
      backgroundColor: theme.palette.background.paper,
    },
    footer: {
      display: 'flex',
      gap: theme.spacing(8),
    },
    signature: {
      border: '1px solid ' + theme.palette.common.black,
    },
    break: {
      breakBefore: 'always',
    },
    page: {
      backgroundColor: 'pint',
      margin: 100,
    },
  }),
);

const UploadEmploymentContractFormModal = ({
  onClose,
  ...modalProps
}: UploadBankDetailsFormModalProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const printRef = useRef<HTMLDivElement | null>(null);
  const sigCanvas = useRef<ReactSignatureCanvas | null>(null);
  const [signature, setSignature] = useState<string | null>(null);

  const { data } = useGetMemberEmploymentAgreementDataQuery({
    fetchPolicy: 'network-only',
  });

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>();

  // Assign default values to inputs
  useEffect(() => {
    if (!!data?.me.member) {
      const {
        firstName,
        middleName,
        lastName,
        email,
        employmentStartDate,
        unit,
        street,
        locality,
        region,
        postcode,
        country,
      } = data.me.member;

      setValue('firstName', firstName);
      setValue('middleName', middleName);
      setValue('lastName', lastName);
      setValue('email', email ?? '');
      setValue('employmentStartDate', employmentStartDate);
      setValue('dear', firstName + (lastName ? ' ' + lastName : ''));
      setValue(
        'address',
        formatAddress({ unit, street, locality, region, postcode, country }),
      );
    }
  }, [data, setValue]);

  const firstName = watch('firstName');
  const middleName = watch('middleName');
  const lastName = watch('lastName');
  const address = watch('address');
  const email = watch('email');
  const dear = watch('dear');
  const employmentStartDate = watch('employmentStartDate');
  const agreeClock = watch('agreeClock');
  const agreeVehicle = watch('agreeVehicle');
  const agreeDate = watch('agreeDate');

  // const resizeCanvas = () => {
  // if (sigCanvas.current) {
  //   const ratio = Math.max(window.devicePixelRatio || 1, 1);
  //   const canvas = sigCanvas.current.getCanvas();
  //   canvas.width = canvas.offsetWidth * ratio;
  //   canvas.height = canvas.offsetHeight * ratio;
  // }
  // };

  // useEffect(() => resizeCanvas(), [sigCanvas]);

  const downloadLink = useMemo(() => {
    if (!data) return undefined;

    if (
      !firstName ||
      !address ||
      !email ||
      !dear ||
      !signature ||
      !agreeClock ||
      !agreeVehicle ||
      !agreeDate ||
      !employmentStartDate
    )
      return <>Please complete form</>;

    return (
      <PDFDownloadLink
        document={
          <SupportWorkerEmploymentAgreement
            values={{
              firstName,
              middleName,
              lastName,
              address,
              email,
              payRate: data.me.member?.currentHourlyBasePayRate ?? 0,
              dear,
              signature,
              agreeDate: parse(agreeDate, 'yyyy-MM-dd', new Date()),
              employmentStartDate: parse(
                employmentStartDate,
                'yyyy-MM-dd',
                new Date(),
              ),
            }}
          />
        }
        fileName={`${format(new Date(), 'yyyyMMdd')}_022_EmploymentAgreement_${
          (!!lastName ? lastName.toUpperCase() + '_' : '') + firstName
        }`}
      >
        <Button variant="contained" color="primary" size="small">
          Download
        </Button>
      </PDFDownloadLink>
    );
  }, [
    data,
    firstName,
    middleName,
    lastName,
    dear,
    address,
    email,
    agreeClock,
    agreeVehicle,
    agreeDate,
    employmentStartDate,
    signature,
  ]);

  return (
    <Modal fullscreen disablePortal {...modalProps} onClose={onClose}>
      <div className={classes.blockWrapper}>
        <Block
          fullscreen
          modal
          title="Employment Contract"
          description=""
          topRight={
            <Box className={classes.controls}>
              <IconButton onClick={onClose}>
                <CloseRoundedIcon />
              </IconButton>
            </Box>
          }
          bottomLeft={<Button onClick={onClose}>Cancel</Button>}
          bottomRight={downloadLink}
        >
          <div className={classes.formWrapper}>
            <div className={classes.instructions}>
              <Typography className={classes.bold}>Instructions</Typography>
              <Typography>1. Please read the document carefully.</Typography>
              <Typography>
                2. Enter your details. Sign and date the last page of the
                document.
              </Typography>
              <Typography>
                3. Click the "Download" button to save a copy of the document.
              </Typography>
              <Typography>
                4. Go to "Uploads" in Timed and upload your copy of the
                document.
              </Typography>
            </div>
          </div>
          <div className={classes.formWrapper}>
            <div ref={printRef} className={classes.printWrapper}>
              <img
                src="https://timed-public.s3.ap-southeast-2.amazonaws.com/australian-carers-logo-full2.jpg"
                alt="logo"
                width="200px"
                style={{ alignSelf: 'center' }}
              />
              <h1 style={{ alignSelf: 'center' }}>
                Support Worker Employment Agreement
              </h1>
              <h2 style={{ alignSelf: 'center' }}>Private and Confidential</h2>
              <div style={{ display: 'flex', gap: theme.spacing(8) }}>
                <PersonNameFirstInput
                  required
                  control={control}
                  name="firstName"
                  variant="outlined"
                  size="small"
                  style={{ flexGrow: 1 }}
                />
                <PersonNameMiddleInput
                  control={control}
                  name="middleName"
                  variant="outlined"
                  size="small"
                  style={{ flexGrow: 1 }}
                />
                <PersonNameLastInput
                  required
                  control={control}
                  name="lastName"
                  variant="outlined"
                  size="small"
                  style={{ flexGrow: 1 }}
                />
              </div>
              <TextField
                required
                control={control}
                name="address"
                label="Home Address"
                variant="outlined"
                size="small"
                style={{ flexGrow: 1 }}
              />
              <TextField
                required
                control={control}
                name="dear"
                label="Dear"
                variant="outlined"
                size="small"
                style={{ flexGrow: 1 }}
              />
              <div className={classes.group}>
                <Typography className={classes.bold}>
                  Employment Agreement
                </Typography>
                <Typography>
                  Congratulations and welcome to Australian Carers. We are
                  pleased to offer you employment on the terms and conditions
                  set out in this letter, subject to the successful completion
                  of your probationary period.
                </Typography>
              </div>
              <div className={classes.group}>
                <Typography className={classes.bold}>Position</Typography>
                <Typography>
                  You are employed by Australian Carers Pty Limited ABN 95 633
                  325 854. You are engaged in the position of Support Worker
                  (Level 1) (Casual) and you may subsequently be engaged in
                  other roles as determined by Australian Carers, either on a
                  permanent basis or from time to time as Australian Carers
                  requires. Such other roles will be in line with your skills
                  and abilities. Your employment in this position (under the
                  terms and conditions of this employment Agreement) will
                  commence on:
                </Typography>
                <DateInput
                  keyboard
                  clearable
                  disableTime
                  control={control}
                  inputVariant="outlined"
                  size="small"
                  name="employmentStartDate"
                  label="Choose a Date"
                  error={!!errors.employmentStartDate}
                  helperText={errors.employmentStartDate?.message}
                  style={{ flexGrow: 1 }}
                />
                <Typography>
                  or as otherwise agreed between yourself and Australian Carers.
                </Typography>
              </div>
              <div className={classes.group}>
                <Typography>
                  Your employment in this position will be on a casual basis.
                </Typography>
              </div>
              <div className={classes.group}>
                <Typography>
                  The duties of this position are set out in the{' '}
                  <strong>
                    position description that has been electronically sent to
                    you
                  </strong>
                </Typography>
              </div>
              <div className={classes.group}>
                <Typography>
                  You will be required to perform these duties, and any other
                  duties that Australian Carers may reasonably assign to you
                  (which may include duties for the benefit of Australian Carers
                  and any related body corporate, as defined in the Corporations
                  Act 2001, of Australian Carers (Group Company or Group
                  Companies) or entities associated with the directors of
                  Australian Carers.
                </Typography>
              </div>
              <div className={classes.group}>
                <Typography>
                  You may, as reasonably required by Australian Carers, be
                  redeployed into other positions and/or locations within the
                  organisation, having regard to your performance, skills,
                  training and experience. If your position, duties, title or
                  reporting structure changes during your employment, this
                  Agreement continues to apply unless you and an authorised
                  representative of Australian Carers enter into a new written
                  employment Agreement or vary this Agreement in writing.
                </Typography>
              </div>
              <div className={classes.group}>
                <Typography>
                  Without in any way limiting Australian Carers rights under
                  this Agreement, Australian Carers may, in circumstances of
                  significant or ongoing poor performance and at the sole
                  discretion of Australian Carers, remove you from your position
                  and assign you to another position within the organisation
                  with regard to your skills, training and experience.
                </Typography>
              </div>
              <div className={classes.group}>
                <Typography>
                  You will report directly to your State Manager or any other
                  person nominated by Australian Carers from time to time. You
                  also report to Australian Carers Chief Executive Officer and
                  General Manager, who may at any time, direct and require you
                  to comply with their reasonable and lawful instructions.
                </Typography>
              </div>
              <div className={classes.group}>
                <Typography className={classes.bold}>
                  Probation Period
                </Typography>
                <Typography>
                  A probation period will apply for the first six months of your
                  employment. During this time, Australian Carers will assess
                  your progress and performance in the position.
                </Typography>
                <Typography>
                  During the probation period, you or Australian Carers may end
                  your employment for any reason by providing notice in
                  accordance with the 'Termination of Employment' clause below.
                </Typography>
              </div>
              <div className={classes.group}>
                <Typography className={classes.bold}>
                  Terms and conditions of employment
                </Typography>
                <Typography>
                  In addition to this Agreement, your employment will be
                  governed by the National Employment Standards (
                  <strong>NES</strong>) as set out in the Fair Work Act 2009 (
                  <strong>Act</strong>), as amended from time to time.
                </Typography>
                <Typography>
                  Your position is also covered by the Social, Community, Home
                  Care and Disability Services Industry Award 2010 (
                  <strong>Award</strong>), as amended.
                </Typography>
                <Typography>
                  A copy of the Award is available for you to review from here:{' '}
                  <a
                    href="https://awardviewer.fwo.gov.au/award/show/MA000100#P289_23916"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://awardviewer.fwo.gov.au/award/show/MA000100#P289_23916
                  </a>
                </Typography>
              </div>
              <div className={classes.group}>
                <Typography className={classes.bold}>
                  Ordinary hours of work
                </Typography>
                <Typography>
                  You agree to work shifts of up to 10 ordinary hours in
                  duration.
                </Typography>
                <Typography>
                  You agree to work up to the maximum allowable amount of broken
                  shifts.
                </Typography>
                <Typography>
                  You may be required to work reasonable additional hours as are
                  necessary to fulfil your duties or as otherwise required by
                  Australian Carers. You will be paid overtime in accordance
                  with the Award.
                </Typography>
              </div>
              <div className={classes.group}>
                <Typography className={classes.bold}>Remuneration</Typography>
                <Typography>
                  Your position will be classified as Level 1 Social and
                  Community Services Employee, (Pay Point 1) in accordance with
                  the Award.
                </Typography>
                <Typography>
                  You will be paid at a rate of{' '}
                  {formatCurrency(
                    roundNumber(
                      (data?.me.member?.currentHourlyBasePayRate ?? 0) / 100,
                      2,
                    ),
                  )}{' '}
                  per hour which includes the 25% loading for casual employees.
                  This rate is applicable Monday to Friday for work performed
                  between 6am and 8pm, higher rates are paid outside of these
                  hours and for weekends and public holidays
                </Typography>
                <Typography>
                  You are also entitled to shift and other allowances/penalties
                  pursuant to the Award.
                </Typography>
                <Typography>
                  Australian Carers will make the minimum superannuation
                  contribution that it is required to make under the
                  superannuation guarantee legislation on your behalf. Subject
                  to applicable law, Australian Carers will make superannuation
                  contributions into an eligible fund of your choice. If you do
                  not specify an eligible fund, Australian Carers will make
                  contributions to its default fund.
                </Typography>
                <Typography>
                  Wages will be deposited every four weeks on Thursday into your
                  nominated bank account. Australian Carers reserves the right
                  to change the day of the week or the frequency with which wage
                  payments are made.
                </Typography>
                <Typography>
                  This agreement includes the provision for averaging of work
                  hours over the four week period.
                </Typography>
                <Typography>
                  You agree that Australian Carers is entitled to deduct from
                  you:
                </Typography>
                <Typography>any overpayment of monies;</Typography>
                <Typography>
                  any amount that Australian Carers is legally obliged to
                  deduct;
                </Typography>
                <Typography>
                  any amount in respect of which you have provided prior written
                  authority; and
                </Typography>
                <Typography>
                  any amount authorized by or under a law of the Commonwealth, a
                  State or a Territory, or an order of a court.
                </Typography>
              </div>
              <div className={classes.group}>
                <Typography className={classes.bold}>Leave</Typography>
                <Typography>
                  As a casual employee you are not entitled to paid leave.
                  Please refer to the NES.
                </Typography>
              </div>
              <div className={classes.group}>
                <Typography className={classes.bold}>
                  Your obligations to Australian Carers
                </Typography>
                <Typography>
                  For the purpose of this clause, Australian Carers IT Systems
                  includes all electronic data, hardware, software, networks and
                  other systems owned, controlled or licensed by Australian
                  Carers.
                </Typography>
                <Typography>
                  Without limiting your duties at law or in equity, during your
                  employment you must:
                </Typography>
                <Typography>
                  perform all duties to the best of your ability at all times;
                </Typography>
                <Typography>
                  devote your whole time and attention to the duties assigned to
                  you during your ordinary hours of work and any additional
                  hours necessary for the performance of those duties;
                </Typography>
                <Typography>
                  use your best endeavours to promote and protect the interests
                  of Australian Carers and the Group Companies;
                </Typography>
                <Typography>
                  hold, maintain, update and ensure the currency of your
                  licenses (such as a driver's license), certifications and
                  qualifications required or necessary to lawfully perform your
                  role;
                </Typography>
                <Typography>
                  notify Australian Carers promptly of any accident, illness or
                  injury (and anticipated recovery period) or any other matter
                  preventing you, temporarily or permanently, from performing
                  your duties and responsibilities under this Agreement;
                </Typography>
                <Typography>
                  comply with all relevant occupational health and safety
                  legislation;
                </Typography>
                <Typography>
                  follow all reasonable and lawful directions given to you by
                  Australian Carers, including complying in all respects with
                  Australian Carers Code of Conduct, policies, procedures and
                  rules as amended from time to time and meet all training and
                  professional development requirements. These policies,
                  procedures and rules are not contained in this Agreement.
                  Copies of all current codes, policies, procedures and rules
                  will be provided to you during any induction or alternatively,
                  are available upon request to your supervisor;
                </Typography>
                <Typography>
                  seek and obtain approval from Australian Carers to engage in
                  secondary employment (including self- employment) and notify
                  Australian Carers of any actual, potential or perceived
                  conflict of interest between your employment with Australian
                  Carers and any other employer; and
                </Typography>
                <Typography>
                  disclose to Australian Carers any facts, information or
                  circumstances (including suspicions, concepts or ideas) which
                  may give rise to a conflict between your interests and the
                  interests of a Group Company.
                </Typography>
                <Typography>
                  Without limiting your duties, during your employment you must
                  not:
                </Typography>
                <Typography>
                  act in conflict with the best interests of, or compete with, a
                  Group Company;
                </Typography>
                <Typography>
                  prepare to be engaged or engage in any business or employment
                  other than for a Group Company except with the prior written
                  consent of Australian Carers;
                </Typography>
                <Typography>
                  in performing your duties, accept any financial or other
                  benefit except from Australian Carers;
                </Typography>
                <Typography>
                  do anything which does or may, in Australian Carers opinion,
                  damage the reputation of a Group Company;
                </Typography>
                <Typography>
                  do anything which infringes Australian Carer’s IP as defined
                  below;
                </Typography>
                <Typography>
                  do anything which infringes any applicable occupational health
                  and safety legislation;
                </Typography>
                <Typography>
                  use Australian Carers IT Systems for excessive or unreasonable
                  personal use; to view or distribute unlawful material, or
                  material which may be regarded as offensive or inappropriate;
                  or to copy, disclose or use material in breach of this
                  Agreement; or
                </Typography>
                <Typography>
                  unlawfully discriminate against, bully or sexually harass
                  another person or otherwise fail to comply with any laws
                  applying to your employment.
                </Typography>
                <Typography>
                  An employee engaging in any one or more of the abovementioned
                  behaviours will be deemed to have engaged in serious and
                  wilful misconduct and may be temporarily suspended from all
                  duties and that suspension may be without pay and/or you may
                  be dismissed without notice
                </Typography>
              </div>
              <div className={classes.group}>
                <Typography className={classes.bold}>
                  NDIS Code of Conduct (Workers)
                </Typography>
                <Typography className={classes.italic}>
                  You are required to comply with the NDIS Code of Conduct.
                </Typography>
                <Typography className={classes.italic}>
                  NDIS Code of Conduct promotes safe and ethical service
                  delivery, by setting out expectations for safe and ethical
                  services and supports for both NDIS providers and workers.
                </Typography>
                <Typography className={classes.italic}>
                  It requires workers and providers delivering NDIS supports to:
                </Typography>
                <Typography className={classes.italic}>
                  act with respect for individual rights to freedom of
                  expression, self-determination, and decision-making in
                  accordance with relevant laws and conventions
                </Typography>
                <Typography className={classes.italic}>
                  respect the privacy of people with disability
                </Typography>
                <Typography className={classes.italic}>
                  provide supports and services in a safe and competent manner
                  with care and skill
                </Typography>
                <Typography className={classes.italic}>
                  act with integrity, honesty, and transparency
                </Typography>
                <Typography className={classes.italic}>
                  promptly take steps to raise and act on concerns about matters
                  that might have an impact on the quality and safety of
                  supports provided to people with disability
                </Typography>
                <Typography className={classes.italic}>
                  take all reasonable steps to prevent and respond to all forms
                  of violence, exploitation, neglect, and abuse
                </Typography>
                <Typography className={classes.italic}>
                  take all reasonable steps to prevent sexual misconduct
                </Typography>
                <Typography>
                  Should Australian Carers Pty Ltd or any employee be found to
                  breach the NDIS Code of Conduct, then Australian Carers Pty
                  Ltd is committed to complying with any education, compliance
                  or enforcement action stipulated by the NDIS Commissioner
                </Typography>
              </div>
              <div className={classes.group}>
                <Typography className={classes.bold}>
                  Termination of employment
                </Typography>
                <Typography className={classes.italic}>
                  Probationary Period
                </Typography>
                <Typography>
                  Australian Carers may terminate your employment at any time,
                  for any reason and without notice prior to the completion of
                  the Probationary Period.
                </Typography>
                <Typography>
                  Dismissal without notice shall also apply in the case of
                  serious and wilful misconduct.{' '}
                </Typography>
                <Typography className={classes.italic}>
                  If you terminate this Agreement
                </Typography>
                <Typography>
                  You are required to provide the relevant period of notice,
                  pursuant to the NES unless as otherwise agreed between you and
                  Australian Carers.
                </Typography>
                <Typography>
                  Where you elect to terminate this Agreement and you do not
                  give the required notice pursuant to the NES, the unworked
                  period of notice will be deducted from your final pay. Unless
                  as otherwise agreed between you and Australian Carers, the
                  notice period may not be reduced by offsetting accrued annual
                  leave entitlements.
                </Typography>
                <Typography>
                  On receiving notice Australian Carers may elect to pay you in
                  lieu of notice and require that you take leave immediately and
                  that you do not work out the balance of any applicable notice
                  period.{' '}
                </Typography>
                <Typography className={classes.italic}>
                  If Australian Carers terminates this Agreement
                </Typography>
                <Typography>
                  Subject to any applicable legislation, your employment may be
                  terminated if:
                </Typography>
                <Typography>
                  you are declared medically unfit to perform your duties;
                </Typography>
                <Typography>
                  You engage in serious and wilful misconduct, in which case
                  termination may be immediate and without notice; or{' '}
                </Typography>
                <Typography>You are made redundant.</Typography>
                <Typography>
                  Applicable periods in respect of notice of termination are
                  referred to in the Award and the NES.
                </Typography>
                <Typography>
                  Any misrepresentation of information provided to Australian
                  Carers during your recruitment in regard to your
                  qualifications and experience, health or any other matter
                  which is relevant to your fitness to perform the duties of
                  this role, may be considered serious and wilful misconduct and
                  may result in the termination of your employment.
                </Typography>
                <Typography>
                  If notice is given to you by Australian Carers to terminate
                  your employment, then Australian Carers may:
                </Typography>
                <Typography>
                  direct you not to perform any duties, or to perform specified
                  duties;
                </Typography>
                <Typography>
                  direct you to remain away from Australian Carers premises;
                </Typography>
                <Typography>
                  direct you to have no contact with any employee, director,
                  client, patient, customer or supplier of Australian Carers,
                  including via social networking websites;
                </Typography>
                <Typography>
                  appoint another person to perform some or all of your duties;
                  and
                </Typography>
                <Typography>change your title.</Typography>
                <Typography>
                  If you are directed not to perform duties in accordance with
                  this clause, you remain bound by all terms of this Agreement.
                </Typography>
                <Typography>
                  Your ongoing appointment is subject to satisfactory criminal
                  record and prohibited employment checks (such as a Working
                  with Children Check). It is a condition of your ongoing
                  employment that you must maintain open disclosure of any
                  officially (by Police, Courts, Government or other regulatory
                  authorities) laid allegations, charges and/or convictions made
                  against you by advising the Australian Carers General Manager
                  immediately upon notice of those charges and/or convictions to
                  you.
                </Typography>
                <Typography>
                  Australian Carers reserves the right to investigate all new
                  charges and convictions against you to determine your
                  continued suitability for employment with Australian Carers or
                  a Group Company. You must reasonably cooperate with such
                  investigations. Australian Carers may elect to rescind this
                  offer or terminate your employment should these checks or
                  disclosures be deemed unsatisfactory.
                </Typography>
                <Typography>
                  Your appointment is subject to your visa status and your right
                  to work in Australia. It is a condition of your ongoing
                  employment that if you are a visa holder, you abide by your
                  visa conditions and work limitations and further, advise
                  Australian Carers in writing immediately of any change to your
                  visa or working rights status. Australian Carers may elect to
                  rescind this offer with no notice to or compensation for you
                  or, if you have already commenced employment terminate your
                  employment should your visa status deem you ineligible to work
                  in Australia.
                </Typography>
                <Typography>
                  If your employment is terminated for any reason, you must
                  return all Australian Carers property including physical items
                  (including but not limited to staff ID cards, building access
                  cards, IT equipment, staff uniforms, credit cards etc),
                  Confidential Information (as defined in the Confidentially
                  clause of this Agreement) and documents. You must also ensure
                  that all work-related materials belonging to Australian Carers
                  (including but not limited to emails, documents, reports,
                  client related information) are saved to Australian Carers
                  systems and are readily accessible by appropriate employees in
                  accordance with accepted practices and procedures.
                </Typography>
              </div>

              <div className={classes.group}>
                <Typography className={classes.bold}>
                  Confidentiality
                </Typography>
                <Typography>
                  For the purposes of this Agreement, Confidential Information
                  means any confidential information relating to the past,
                  present or future business of Australian Carers or a Group
                  Company that comes to your knowledge and includes information
                  about clients, patients or other employees; financial,
                  budgetary, marketing, research and business plan information;
                  the terms of this Agreement; the terms of any contract,
                  Agreement or business arrangement with third parties; trade
                  secrets; pricing structures; and any third party information
                  disclosed in confidence.
                </Typography>
                <Typography>
                  By accepting this offer of employment, you acknowledge and
                  agree that you will not, during the course of your employment
                  or thereafter, except with the consent of Australian Carers,
                  as required by law or in the proper performance of your
                  duties, use or disclose Confidential Information which would
                  result in gaining a benefit, including a financial benefit, to
                  you or others. This includes contacting any and all current
                  and former clients and patients of Australian Carers and any
                  and all current or former employees or contractors of
                  Australian Carers with a view to another contractual or
                  financial arrangement outside the business of Australian
                  Carers.
                </Typography>
                <Typography>
                  If your former clients or clients of former employers express
                  a desire to be clients of Australian Carers Pty Ltd, then all
                  referrals from you will be directed to Australian Carers Pty
                  Ltd via their website, email or telephone contact such that
                  your personal emails, phone contacts will not be used at all
                  in the process.
                </Typography>
                <Typography>
                  You must also take whatever measures are reasonably necessary
                  to prevent the disclosure or misuse of Confidential
                  Information which would cause harm or loss to Australian
                  Carers at any time in the future, for example making public
                  comments on social media (Facebook, Instagram etc).
                </Typography>
                <Typography>
                  Upon the termination of your employment and at any time
                  requested by Australian Carers Pty Ltd you are required to
                  cease using all confidential information of Australian Carers
                  Pty Ltd including participant information such as name,
                  address, NDIA number, contact information and disability type
                  or any other information relevant to that client(s).
                </Typography>
                <Typography>
                  On receiving a notice pursuant to this clause, or upon
                  cessation of your employment, your right to possess or use
                  confidential information ceases and you must immediately:
                </Typography>
                <Typography>
                  return all confidential information in your possession or
                  control to Australian Carers Pty Ltd including notes, case
                  summaries or any client information; and
                </Typography>
                <Typography>
                  destroy and certify in writing to Australian Carers Pty Ltd
                  the destruction of all confidential information in your
                  possession or control.
                </Typography>
                <Typography>
                  You must not under any circumstances contact by email, phone,
                  sms, social media or any other means, Australian Carers Pty
                  Ltd clients (participants) regarding your cessation of
                  employment.
                </Typography>
                <Typography>
                  Your obligations under this clause survive the termination of
                  your employment and this Agreement.
                </Typography>
              </div>

              <div className={classes.group}>
                <Typography className={classes.bold}>
                  Intellectual Property and Moral Rights
                </Typography>
                <Typography>
                  For the purposes of this clause, Intellectual Property Rights
                  means all intellectual property rights (whether or not
                  registered including all applications and the right to apply
                  for registration) including:
                </Typography>
                <Typography>
                  rights in relation to any patents, copyright, trademarks,
                  service marks, design rights, and all other rights arising
                  from intellectual activity;
                </Typography>
                <Typography>
                  all rights of a similar nature to any of the rights referred
                  to above which may subsist in Australia or elsewhere;
                </Typography>
                <Typography>
                  You must also take whatever measures are reasonably necessary
                  to prevent the disclosure or misuse of Confidential
                  Information which would cause harm or loss to Australian
                  Carers at any time in the future, for example making public
                  comments on social media (Facebook, Instagram etc).
                </Typography>
                <Typography>
                  all Confidential Information as defined above,
                </Typography>
                <Typography>
                  any such rights in any processes, technology, systems,
                  reports, specifications, blue-prints, trade names, domain
                  names, designs, brands and company names, trade secrets,
                  copyright works, URLs, drawings, discoveries, inventions,
                  improvements, technical data, research data, formulae,
                  computer programs, software, circuit layouts, knowhow, logos,
                  symbols.
                </Typography>
                <Typography>
                  Pursuant to this Agreement and as a term of your ongoing
                  employment, you:
                </Typography>
                <Typography>
                  assign to Australian Carers all existing and future
                  Intellectual Property Rights created or generated by you
                  (whether along or with Australian Carers, its other employees,
                  agents or contractors) for use by Australian Carers (or a
                  Group Company); and
                </Typography>
                <Typography>
                  agree that because of the above clause, all existing
                  Intellectual Property Rights are vested in Australian Carers
                  and, when created, all future Intellectual Property Rights
                  vest in Australian Carers;
                </Typography>
                <Typography>
                  must do all things reasonably requested by Australian Carers
                  in respect of the assignment of the Intellectual Property
                  Rights
                </Typography>
                <Typography>
                  must disclose the subject matter of all Intellectual Property
                  Rights to Australian Carers, immediately when Intellectual
                  Property Rights have been created.
                </Typography>
                <Typography>
                  consent to Australian Carers, a Group Company or their
                  suppliers, clients or customers using or adapting Works to
                  which you have contributed or which you have created in
                  connection with your employment, in any manner, and without
                  expressly acknowledging your contribution or creation in a way
                  which may otherwise infringe a Moral Right of yours. This
                  consent is given in relation to all Works made or to be made
                  by you in the course of your employment.
                </Typography>
                <Typography>
                  acknowledge that the consent given in the subclause above has
                  been given freely and genuinely, and without you being
                  subjected to any duress by Australian Carers, a Group Company
                  or a third party.
                </Typography>
                <Typography>
                  Your obligation sunder this clause survive the termination of
                  your employment and this Agreement.
                </Typography>
              </div>

              <div className={classes.group}>
                <Typography className={classes.bold}>
                  Restraint of Trade
                </Typography>
                <Typography>
                  You acknowledge that as a result of your employment with
                  Australian Carers, you will gain access to confidential
                  knowledge, information and data as well as other connections
                  and contacts.
                </Typography>
                <Typography>
                  That confidential information, data, knowledge and connections
                  is information which may be used by a former employee in your
                  position to set up a competing business and to solicit current
                  clients of Australian Carers.
                </Typography>
                <Typography>
                  To reasonably protect the goodwill and the legitimate business
                  interests of Australian Carers or its related entities, during
                  the Restraint Period and within the Restraint Period (referred
                  to below), you will not, without prior written consent of
                  Australian Carers or its related entities, directly or
                  indirectly:
                </Typography>
                <Typography>
                  Entice or solicit, or assist another person to entice or
                  solicit, an employee, contractor, officer, agent or supplier
                  of Australian Carers or its related entities with whom he has
                  had dealings prior to his employment ending, to cease to
                  provide services to Australian Carers or its related entities;
                </Typography>
                <Typography>
                  Canvass, solicit or deal with, counsel, procure or assist
                  another person to canvass, solicit or deal with any client of
                  Australian Carers or its related entities with whom he has had
                  dealings with during the 18 months period prior to his
                  employment ending.
                </Typography>
                <Typography>
                  Restraint Period means 18 months, from the date of termination
                  of your employment.
                </Typography>
                <Typography>
                  It is agreed that restraint will exist even if your employment
                  is terminated during the Probation Period and that the
                  Restraint Period will be as set out below, after the
                  completion of the Probation:
                </Typography>
                <Typography>18 months;</Typography>
                <Typography>15 months;</Typography>
                <Typography>12 months:</Typography>
                <Typography>Restraint Area Means:</Typography>
                <Typography>(a) Australia;</Typography>
                <Typography>(b) South Australia;</Typography>
                <Typography>(c) Queensland;</Typography>
                <Typography>(d) New South Wales</Typography>
                <Typography>
                  The metropolitan area of the capital city in which you are
                  employed at the date of termination of your employment.
                </Typography>
                <Typography>
                  Prior written consent includes a documented list of Clients
                  agreed and authorised in writing by Australian Carers or its
                  related entities.
                </Typography>
                <Typography>
                  The covenants given by you in this clause will apply, and may
                  be enforced against you, regardless of the reason(s) for the
                  termination of his employment.
                </Typography>
                <Typography>
                  Each restraint contained in this Agreement (resulting from any
                  combination of the wording) constitutes a separate and
                  independent provision, severable from the other restraints. If
                  a court of competent jurisdiction finally decides any such
                  restraint to be unenforceable or whole or in part, the
                  enforceability of the remainder of that restraint and any
                  other restraint will not be affected.
                </Typography>
              </div>

              <div className={classes.group}>
                <Typography className={classes.bold}>Surveillance</Typography>
                <Typography>
                  You agree that Australian Carers may, to the extent permitted
                  by law, conduct computer, camera and tracking surveillance of
                  you during your employment.
                </Typography>
                <Typography>
                  You consent to Australian Carers, from the commencement of
                  your employment and on a continuous, ongoing basis:
                </Typography>
                <Typography>
                  accessing, monitoring, logging and recording any communication
                  or information developed, used, received, stored or
                  transmitted by you using Australian Carers IT systems, either
                  at Australian Carers premises or at any other place. This
                  includes your use of Australian Carers IT systems outside of
                  or external to Australian Carers or client premises;
                </Typography>
                <Typography>
                  installing filtering systems in Australian Carers IT systems
                  which restrict the inward and outward flow of certain types of
                  material, including emails and viruses, with the result that
                  some email traffic may be blocked;
                </Typography>
                <Typography>
                  conducting camera (video) surveillance of you in any of
                  Australian Carers premises, including community locations; and
                </Typography>
                <Typography>
                  conducting tracking surveillance of the Employee while in a
                  vehicle belonging to the Employer.
                </Typography>
              </div>

              <div className={classes.group}>
                <Typography className={classes.bold}>Privacy</Typography>
                <Typography>
                  For the purposes of this clause, Personal Information has the
                  meaning in the Privacy Act 1988 (Cth).
                </Typography>
                <Typography>
                  In the course of your employment, you must not:
                </Typography>
                <Typography>
                  collect Personal Information of other people; or
                </Typography>
                <Typography>
                  use or disclose Personal Information of other people, except
                  if necessary to perform your duties; or authorised by
                  Australian Carers; and in accordance with applicable privacy
                  laws and Australian Carers policies,
                </Typography>
                <Typography>
                  Without limiting Australian Carers rights under applicable
                  laws, you agree that Australian Carers or a Group Company may:
                </Typography>
                <Typography>
                  use and disclose Personal Information that Australian Carers
                  has collected about you during your employment, or in
                  anticipation of that employment, for any purpose connected
                  with your employment and the operation of Australian Carers
                  business; and
                </Typography>
                <Typography>
                  transfer Personal Information from Australian Carers to a
                  Group Company in Australia or in other countries, Australian
                  Carers insurers and superannuation administrators, or
                  contractors or other service providers who perform services
                  for or on behalf of Australian Carers as part of Australian
                  Carers usual business operations.
                </Typography>
                <Typography>
                  By agreeing to the terms of employment, and without limiting
                  Australian Carers rights under applicable laws, you consent to
                  Australian Carers collecting, using and disclosing Personal
                  Information about you and transferring that Personal
                  Information externally (including internationally) when
                  necessary.
                </Typography>
              </div>

              <div className={classes.group}>
                <Typography className={classes.bold}>
                  Variations must be in writing
                </Typography>
                <Typography>
                  The terms and conditions referred to in this letter may only
                  be varied by a written Agreement signed by both you and an
                  authorised representative of Australian Carers. Authorised
                  representatives include and are limited to Australian Carers
                  Chief Executive Officer and Australian Carers General Manager
                </Typography>
                <Typography>
                  If you have any questions relating to the terms and conditions
                  of employment, please don't hesitate to contact Australian
                  Carers management on 13 77 77 or by email to the relevant
                  email below for the location you are employed to report to
                </Typography>
                <Typography>
                  Adelaide: adelaide@australiancarers.com.au
                </Typography>
                <Typography>
                  Rockhampton: rockhampton@australiancarers.com.au
                </Typography>
                <Typography>Sydney: sydney@australiancarers.com.au</Typography>
                <Typography>
                  Congratulations on your appointment and I trust that you find
                  your employment both satisfying and rewarding.
                </Typography>
                <Typography>Yours sincerely,</Typography>
                <Typography>Australian Carers</Typography>
              </div>
              <div className={classes.checkboxGroup}>
                <Checkbox
                  control={control}
                  name="agreeClock"
                  label="As part of your employment with Australian Carers you are required to clock on and off from a shift using a rostering program that we provide you access to, by placing a tick in this box you acknowledge that an essential term of your employment is to only clock on and off from a shift at the location you are advised to clock on and off and failure to do inferring you have commenced your shift when in fact you have not will be reasonably seen as intentionally being dishonest and is grounds for instant termination of your employment"
                />
              </div>
              <div className={classes.checkboxGroup}>
                <Checkbox
                  control={control}
                  name="agreeVehicle"
                  label={
                    <>
                      <Typography>
                        From time to time you may be required to drive vehicles
                        that are not owned by Australian Carers you agree and
                        undertake to:
                      </Typography>
                      <Typography>
                        <ul>
                          <li>
                            Abide by any road rules including alcohol and drug
                            related rules
                          </li>
                          <li>Drive in a safe manner</li>
                          <li>
                            Not drive a vehicle you are not licensed to drive
                          </li>
                          <li>
                            Advise Australian Carers of any loss or suspension
                            of your drivers licence
                          </li>
                          <li>Indemnify Australian Carers for:</li>
                          <ul>
                            <li>all insurance excesses</li>
                            <li>
                              any and all damaged caused to othervehicles,
                              property or persons while the vehicle is under
                              your control and responsibility
                            </li>
                            <li>
                              all infringement notices ( including parking
                              infringements ) issued while you are or did have
                              control of the vehicle
                            </li>
                          </ul>
                        </ul>
                      </Typography>
                    </>
                  }
                />
              </div>
              <div className={classes.footer}>
                <div className={classes.group} style={{ flex: '1 0 50%' }}>
                  <Typography className={classes.bold}>
                    Signature of Employee Accepting this Agreement:
                  </Typography>
                  <Controller
                    name="signature"
                    control={control}
                    render={() => (
                      <ReactSignatureCanvas
                        ref={sigCanvas}
                        penColor={theme.palette.text.primary}
                        canvasProps={{
                          style: { width: 'auto', height: 200 },
                          className: classes.signature,
                        }}
                        onEnd={() =>
                          setSignature(
                            sigCanvas.current?.getCanvas().toDataURL() ?? null,
                          )
                        }
                      />
                    )}
                  />

                  <Typography
                    onClick={() => sigCanvas.current?.clear()}
                    style={{
                      textAlign: 'end',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                  >
                    Clear
                  </Typography>
                </div>
                <div className={classes.group} style={{ flex: '1 0 50%' }}>
                  <Typography className={classes.bold}>
                    Date of Acceptance
                  </Typography>
                  <DateInput
                    keyboard
                    clearable
                    disableTime
                    control={control}
                    inputVariant="outlined"
                    size="small"
                    name="agreeDate"
                    error={!!errors.agreeDate}
                    helperText={errors.agreeDate?.message}
                  />
                </div>
              </div>
            </div>
          </div>
        </Block>
      </div>
    </Modal>
  );
};

export default UploadEmploymentContractFormModal;

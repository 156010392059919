import {
  createStyles,
  makeStyles,
  Modal as MuiModal,
  ModalProps as MuiModalProps,
  Paper,
  PaperProps,
  Theme,
  useMediaQuery,
  useTheme,
  Zoom,
} from '@material-ui/core';
import clsx from 'clsx';

export type ModalProps = MuiModalProps & {
  paperProps?: PaperProps;
  onExited?: () => void;
  fullscreen?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    fullscreenModal: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      boxSizing: 'border-box',
    },
    fullscreenPaper: {
      width: '100%',
      height: '100%',
      borderRadius: 'unset',
    },
  }),
);

const Modal = ({
  paperProps,
  children,
  open,
  onExited,
  fullscreen = false,
  ...props
}: ModalProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MuiModal
      {...props}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      closeAfterTransition
      className={
        fullscreen || smDown
          ? clsx(classes.modal, classes.fullscreenModal)
          : classes.modal
      }
      BackdropProps={{ timeout: 250 }}
      open={open}
    >
      <Zoom
        mountOnEnter
        unmountOnExit
        in={open}
        onExited={onExited}
        timeout={250}
      >
        <Paper
          {...paperProps}
          className={
            fullscreen || smDown
              ? clsx(
                  classes.paper,
                  classes.fullscreenPaper,
                  paperProps?.className,
                )
              : clsx(classes.paper, paperProps?.className)
          }
        >
          {children}
        </Paper>
      </Zoom>
    </MuiModal>
  );
};

export default Modal;

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  addServerErrors,
  Checkbox,
  FormModal,
  ModalProps,
  Textarea,
  TextField,
  transformNumberToFixedFloat,
} from '@timed/common';
import {
  GetVisaTypesQuery,
  useUpdateVisaTypeMutation,
  VisaType,
} from '@timed/gql';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type VisaTypeUpdateFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  visaType: GetVisaTypesQuery['visaTypes'][0];
};

type FormData = {
  patch: Pick<
    VisaType,
    | 'name'
    | 'description'
    | 'allowedFortnightlyWorkHours'
    | 'allowedWorkHoursWaived'
  >;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        alignItems: 'start',
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
        flexDirection: 'column',
      },
    },
    textarea: {
      backgroundColor: theme.palette.background.paper,
      width: 322,
      color: theme.palette.text.primary,
      border: '1px solid ' + theme.palette.text.disabled,
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.up('md')]: {
        width: 483,
      },
    },
    groups: {
      display: 'grid',
      gridTemplateColumns: 'auto',
      gap: theme.spacing(4),
    },
    group: {
      display: 'grid',
      gridTemplateColumns: 'max-content',
      gridAutoRows: 'max-content',
      gap: theme.spacing(1),
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    buttons: {
      flex: '0 1 max-content',
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

const VisaTypeUpdateFormModal = ({
  onClose,
  visaType,
  ...modalProps
}: VisaTypeUpdateFormModalProps) => {
  const classes = useStyles();

  const [updateVisaType, response] = useUpdateVisaTypeMutation();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      patch: _.pick(visaType, [
        'name',
        'description',
        'allowedFortnightlyWorkHours',
        'allowedWorkHoursWaived',
      ]),
    },
  });

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = (values: FormData) => {
    updateVisaType({
      variables: {
        input: {
          id: visaType.id,
          ...values,
        },
      },
    });
  };

  return (
    <FormModal
      modalProps={modalProps}
      title="Edit Visa Type"
      hasErrors={!!Object.keys(errors).length}
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onClose={() => {
        onClose();
      }}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.groups}>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Name</Typography>
            <TextField
              name="patch.name"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.patch?.name}
              helperText={errors.patch?.name?.message}
              validation={{
                maxLength: { value: 255, message: 'Exceeding 255 characters' },
              }}
            />
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Description</Typography>
            <Textarea
              name="patch.description"
              control={control}
              minRows={3}
              placeholder="Description."
              className={classes.textarea}
              validation={{
                maxLength: { value: 255, message: 'Exceeding 255 characters' },
              }}
            />
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>
              Allowed fortnightly work hours
            </Typography>
            <TextField
              control={control}
              name="patch.allowedFortnightlyWorkHours"
              type="number"
              inputProps={{ min: 0, max: 336, step: 1 }}
              transform={transformNumberToFixedFloat(0)}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
              variant="outlined"
              size="small"
            />
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Options</Typography>
            <Checkbox
              control={control}
              label="Waive allowed work hours"
              name="patch.allowedWorkHoursWaived"
              defaultChecked={visaType.allowedWorkHoursWaived ?? false}
              error={!!errors.patch?.allowedWorkHoursWaived}
              helperText={errors.patch?.allowedWorkHoursWaived?.message}
            />
          </Box>
        </Box>
      </Box>
    </FormModal>
  );
};

export default VisaTypeUpdateFormModal;

import {
  ClientFileType,
  ClientNoteFileType,
  EventFileType,
  MemberFileType,
} from '@timed/gql';
import { RegisterOptions } from 'react-hook-form';

export const validateFileType = (
  fileType: 'member' | 'client' | 'event' | 'clientNote',
): RegisterOptions => ({
  validate: {
    validFileType: (value) =>
      value in
        (fileType === 'client'
          ? ClientFileType
          : fileType === 'member'
          ? MemberFileType
          : fileType === 'clientNote'
          ? ClientNoteFileType
          : EventFileType) || 'Invalid document type',
  },
});

import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useAlert } from '@timed/alert';
import { _ideas } from '@timed/app';
import { Protected } from '@timed/auth';
import { Block, ButtonAsync, Textarea } from '@timed/common';
import {
  Permission,
  useGetOrgIdeasQuery,
  useUpdateOrgIdeasMutation,
} from '@timed/gql';
import { Page } from '@timed/page';
import { useForm } from 'react-hook-form';

type FormData = {
  patch: {
    ideas: string;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      flexGrow: 1,
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
      },
    },

    form: {
      flexGrow: 1,
      display: 'flex',
      flexFlow: 'column',
    },
    paper: {
      flexGrow: 1,
      gap: theme.spacing(0),
    },
    input: {
      padding: theme.spacing(4),
      overflowY: 'auto',
      outline: 'none',
      resize: 'none',
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
      fontFamily: 'inherit',
      WebkitTextFillColor: theme.palette.text.primary, // required for iOS
      opacity: 1, // required for iOS
      width: '100%',
      height: 'calc(100% - 3px)',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
  }),
);

const RouteCoreSupportIdeas = () => {
  const classes = useStyles();

  const alert = useAlert();

  const { data, loading } = useGetOrgIdeasQuery();

  const [updateIdeas, response] = useUpdateOrgIdeasMutation();

  const { handleSubmit, control } = useForm<FormData>();

  const onSubmit = (input: FormData) =>
    updateIdeas({ variables: { input } }).catch((e) => {});

  const onSuccess = () =>
    alert.push({ message: 'Form saved.', severity: 'success' });

  return (
    <Page {..._ideas}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Block
          paperProps={{ className: classes.paper }}
          title="Ideas and Suggestions"
          topRight={
            <Protected permissions={Permission.CLIENT_WRITE}>
              <ButtonAsync
                loading={response.loading}
                success={!!response.data}
                onSuccess={onSuccess}
                type="submit"
              >
                Save
              </ButtonAsync>
            </Protected>
          }
        >
          {loading || !data ? (
            <textarea
              className={classes.input}
              style={{ height: '', overflow: '' }}
              value={'Loading...'}
            />
          ) : (
            <Textarea
              name="patch.ideas"
              control={control}
              className={classes.input}
              style={{ height: '', overflow: '' }}
              defaultValue={data.me.member?.org.ideas ?? ''}
            />
          )}
        </Block>
      </form>
    </Page>
  );
};

export default RouteCoreSupportIdeas;

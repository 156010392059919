import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useAuth } from '@timed/auth';
import {
  ClientContext,
  ClientCreateBowelMovementFormModal,
  ClientUpdateBowelMovementFormModal,
  ClientUpdateObservationFormModal,
} from '@timed/client';
import { ClientCreateObservationFormModal } from '@timed/client/components/CreateObservationFormModal';
import { formatObservation } from '@timed/client/utils';
import { addServerErrors, Button, formatPersonName } from '@timed/common';
import {
  Client,
  ClientObservation,
  Member,
  OrderBy,
  useGetClientObservationsQuery,
  useUpdateClientsMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import clsx from 'clsx';
import { useModal } from 'mui-modal-provider';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      gap: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    form: {
      display: 'flex',
      gap: theme.spacing(4),
      alignItems: 'center',
    },
    button: {
      width: 'max-content',
    },
    // observations: {
    //   width: 'max-content',
    //   padding: theme.spacing(2),
    //   backgroundColor: theme.palette.background.paper,
    //   borderRadius: theme.shape.borderRadius,
    //   display: 'flex',
    //   flexDirection: 'column',
    //   gap: theme.spacing(2),
    //   '& .MuiTypography-body1': {
    //     fontFamily: 'monospace',
    //   },
    // },
    // observation: {
    //   display: 'flex',
    //   gap: theme.spacing(4),
    //   alignItems: 'center',
    //   cursor: 'pointer',
    // },
    // observationControls: {
    //   fontSize: 14,
    // },
    recorder: {
      // columnFill: 'auto',
      position: 'relative',
      top: '-8px',
      gridColumnStart: 1,
      gridColumnEnd: 12,
      fontSize: '9px !important',
      // '& .MuiTypography-root': {
      //   fontSize: 8 + ' !important',
      // },
    },

    grid: {
      width: 'max-content',
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      display: 'inline-grid',
      gridTemplateColumns:
        'max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content auto',
      columnGap: theme.spacing(4),
      rowGap: theme.spacing(0.25),
      '& .MuiTypography-root': {
        fontSize: 14,
        fontFamily: 'monospace',
        cursor: 'pointer',
      },
    },

    heading: {
      alignSelf: 'start',
      paddingBottom: theme.spacing(4),
      fontWeight: theme.typography.fontWeightMedium,
    },

    headingRotated: {
      transform: 'rotate(-45deg)',
    },
  }),
);

type FormData = {
  patch: {
    allowObservations?: boolean;
  };
};

const ClientViewObservations = () => {
  const classes = useStyles();

  const { showModal } = useModal();

  const { permissible } = useAuth();

  const client = useContext(ClientContext);

  const [editing, setEditing] = useState<boolean>(false);

  const defaultValues: FormData = {
    patch: {
      allowObservations: client.allowObservations,
    },
  };

  const {
    handleSubmit,
    watch,
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });

  const currentValues = watch();

  const [updateClients, response] = useUpdateClientsMutation();

  const { data, loading } = useGetClientObservationsQuery({
    variables: {
      input: {
        limit: 300,
        orderBy: [{ date: OrderBy.DESC }],
        where: {
          client: { id: { _eq: client.id } },
        },
      },
    },
  });

  const onSubmit = (values: FormData) => {
    updateClients({
      variables: { input: { ids: [client.id], ...values } },
    }).catch((e) => {});
    reset(values);
  };

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  useLoadingEffect(loading || response.loading);

  const handleOpenRecordObsModal = () => {
    const modal: { hide: () => void } = showModal(
      ClientCreateObservationFormModal,
      {
        onClose: () => {
          modal.hide();
        },
        clientId: client.id,
      },
    );
  };

  const handleOpenUpdateObsservationModal = useCallback(
    (
      observation: Pick<
        ClientObservation,
        | 'id'
        | 'date'
        | 'notes'
        | 'nursesName'
        | 'bloodSystolic'
        | 'bloodDiastolic'
        | 'heart'
        | 'temp'
        | 'spo2'
        | 'respiration'
        | 'weight'
      > & { client: Pick<Client, 'id'> } & {
        member?: Pick<Member, 'id'> | null;
      },
    ) => {
      const modal: { hide: () => void } = showModal(
        ClientUpdateObservationFormModal,
        {
          onClose: () => {
            modal.hide();
          },
          observation,
        },
      );
    },
    [showModal],
  );

  const handleOpenRecordBmModal = () => {
    const modal: { hide: () => void } = showModal(
      ClientCreateBowelMovementFormModal,
      {
        onClose: () => {
          modal.hide();
        },
        clientId: client.id,
      },
    );
  };

  const handleOpenUpdateBmModal = useCallback(
    (
      observation: Pick<
        ClientObservation,
        'id' | 'date' | 'notes' | 'stoolType'
      > & {
        client: Pick<Client, 'id'>;
        member?: Pick<Member, 'id'> | null;
      },
    ) => {
      const modal: { hide: () => void } = showModal(
        ClientUpdateBowelMovementFormModal,
        {
          onClose: () => {
            modal.hide();
          },
          observation,
        },
      );
    },
    [showModal],
  );

  const observations = useMemo(() => {
    if (!data || loading) return <>...Loading</>;

    // return formatObservation({ limits: client, obs: data.clientObservations[0] });

    // const obs =

    return !!data.clientObservations.length ? (
      <Box className={classes.grid}>
        <Typography color="textPrimary" className={classes.heading}>
          Date
        </Typography>
        <Typography
          color="textPrimary"
          className={clsx(classes.heading, classes.headingRotated)}
        >
          Blood
        </Typography>
        <Typography
          color="textPrimary"
          className={clsx(classes.heading, classes.headingRotated)}
        >
          Heart
        </Typography>
        <Typography
          color="textPrimary"
          className={clsx(classes.heading, classes.headingRotated)}
        >
          Temp
        </Typography>
        <Typography
          color="textPrimary"
          className={clsx(classes.heading, classes.headingRotated)}
        >
          SpO2
        </Typography>
        <Typography
          color="textPrimary"
          className={clsx(classes.heading, classes.headingRotated)}
        >
          Respir
        </Typography>
        <Typography
          color="textPrimary"
          className={clsx(classes.heading, classes.headingRotated)}
        >
          Weight
        </Typography>
        <Typography
          color="textPrimary"
          className={clsx(classes.heading, classes.headingRotated)}
        >
          BM
        </Typography>
        <Typography
          color="textPrimary"
          className={clsx(classes.heading, classes.headingRotated)}
        >
          Nurse
        </Typography>
        <Typography
          color="textPrimary"
          className={clsx(classes.heading, classes.headingRotated)}
        >
          SW
        </Typography>
        <Typography color="textPrimary" className={classes.heading}>
          Notes
        </Typography>

        {data.clientObservations.map((obs) => {
          const o = formatObservation({
            obs: { ...obs, member: obs.member ?? obs.event?.member },
            limits: client,
          });

          return (
            <>
              <Typography
                color="textPrimary"
                onClick={() => {
                  !!obs.stoolType
                    ? handleOpenUpdateBmModal(obs)
                    : handleOpenUpdateObsservationModal(obs);
                }}
              >
                {o[0]}
              </Typography>
              <Typography
                color="textPrimary"
                onClick={() => {
                  !!obs.stoolType
                    ? handleOpenUpdateBmModal(obs)
                    : handleOpenUpdateObsservationModal(obs);
                }}
              >
                {o[1] ?? '-'}
              </Typography>
              <Typography
                color="textPrimary"
                onClick={() => {
                  !!obs.stoolType
                    ? handleOpenUpdateBmModal(obs)
                    : handleOpenUpdateObsservationModal(obs);
                }}
              >
                {o[2] ?? '-'}
              </Typography>
              <Typography
                color="textPrimary"
                onClick={() => {
                  !!obs.stoolType
                    ? handleOpenUpdateBmModal(obs)
                    : handleOpenUpdateObsservationModal(obs);
                }}
              >
                {o[3] ?? '-'}
              </Typography>
              <Typography
                color="textPrimary"
                onClick={() => {
                  !!obs.stoolType
                    ? handleOpenUpdateBmModal(obs)
                    : handleOpenUpdateObsservationModal(obs);
                }}
              >
                {o[4] ?? '-'}
              </Typography>
              <Typography
                color="textPrimary"
                onClick={() => {
                  !!obs.stoolType
                    ? handleOpenUpdateBmModal(obs)
                    : handleOpenUpdateObsservationModal(obs);
                }}
              >
                {o[5] ?? '-'}
              </Typography>

              <Typography
                color="textPrimary"
                onClick={() => {
                  !!obs.stoolType
                    ? handleOpenUpdateBmModal(obs)
                    : handleOpenUpdateObsservationModal(obs);
                }}
              >
                {o[6] ?? '-'}
              </Typography>

              <Typography
                color="textPrimary"
                onClick={() => {
                  !!obs.stoolType
                    ? handleOpenUpdateBmModal(obs)
                    : handleOpenUpdateObsservationModal(obs);
                }}
              >
                {o[7] ?? '-'}
              </Typography>

              <Typography
                color="textPrimary"
                onClick={() => {
                  !!obs.stoolType
                    ? handleOpenUpdateBmModal(obs)
                    : handleOpenUpdateObsservationModal(obs);
                }}
              >
                {o[8] ?? '-'}
              </Typography>

              <Typography
                color="textPrimary"
                onClick={() => {
                  !!obs.stoolType
                    ? handleOpenUpdateBmModal(obs)
                    : handleOpenUpdateObsservationModal(obs);
                }}
              >
                {o[9]}
              </Typography>

              <Typography
                color="textPrimary"
                onClick={() => {
                  !!obs.stoolType
                    ? handleOpenUpdateBmModal(obs)
                    : handleOpenUpdateObsservationModal(obs);
                }}
              >
                {o[10]}
              </Typography>

              {permissible({ admin: true }) && (
                <Typography
                  color="textSecondary"
                  className={classes.recorder}
                  onClick={() => {
                    !!obs.stoolType
                      ? handleOpenUpdateBmModal(obs)
                      : handleOpenUpdateObsservationModal(obs);
                  }}
                >
                  {formatPersonName(obs.createdBy!, {
                    capitaliseLastName: true,
                    lastNameFirst: true,
                  })}
                </Typography>
              )}

              {/* <Typography
                color="textSecondary"
                className={classes.recorder}
                onClick={() => {
                  handleOpenUpdateObsservationModal(obs);
                }}
              >
                {!!obs.member &&
                  formatPersonName(obs.member, {
                    capitaliseLastName: true,
                    lastNameFirst: true,
                  })}
              </Typography> */}
            </>
          );

          // return (
          //   <Box>
          //     <Box className={classes.observation}>
          //       <Typography
          //         color="textPrimary"
          //         onClick={() => {
          //           handleOpenUpdateObsservationModal(d);
          //         }}
          //       >
          //         <ClientFormattedObservation limits={client} obs={d} />
          //       </Typography>
          //     </Box>
          //     <Typography color="textSecondary" className={clsx(classes.recorder, classes.bold)}>
          //       {formatPersonName(d.createdBy!, { capitaliseLastName: true, lastNameFirst: true })}
          //     </Typography>
          //   </Box>
          // )
        })}
      </Box>
    ) : (
      'No observations have been recorded.'
    );
  }, [
    data,
    loading,
    classes,
    client,
    handleOpenUpdateObsservationModal,
    handleOpenUpdateBmModal,
    permissible,
  ]);

  return (
    <Box className={classes.wrapper}>
      {/* <Protected permissions={Permission.CLIENT_WRITE}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Block
            inline
            title="Allow recording of observations"
            topRight={
              <IconButtonMulti
                enabled={editing}
                changed={!isEqual(currentValues, defaultValues)}
                setEditing={setEditing}
                loading={response.loading}
                success={!!response.data}
              />
            }
          >
            <Box className={classes.form}>
              <Typography>Allow recording of observations</Typography>
              <ClientAllowObservationsCheckbox
                name="patch.allowObservations"
                label={undefined}
                control={control}
                disabled={!editing}
                error={!!errors.patch?.allowObservations}
                helperText={errors.patch?.allowObservations?.message}
                formControlProps={{ size: 'small', variant: 'outlined' }}
              />
            </Box>
          </Block>
        </form>
        <ClientUpdateObservationThresholdsForm client={client} />
      </Protected> */}
      {client.allowObservations && (
        <>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              handleOpenRecordObsModal();
            }}
          >
            Record Obs
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              handleOpenRecordBmModal();
            }}
          >
            Record BM
          </Button>
        </>
      )}
      {observations}
    </Box>
  );
};

export default ClientViewObservations;

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { ButtonAsync, TextField, validateRequired } from '@timed/common';
import {
  CreateMedicationDocument,
  useCreateMedicationMutation,
} from '@timed/gql';
import { useForm } from 'react-hook-form';

type FormData = { name: string; pronunciation: string; url: string };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    form: {
      alignItems: 'start',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      border: '1px solid ' + theme.palette.divider,
      padding: theme.spacing(4),
    },
  }),
);

const SettingsOrganisationMedicationForm = () => {
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormData>();

  const [createMedication, createResponse] = useCreateMedicationMutation();

  const onSubmit = (input: FormData) => {
    createMedication({ variables: { input } }).catch((e) => {});
    reset();
  };

  const onSuccess = () => {
    createResponse.client.cache.modify({
      fields: {
        medications(existing = []) {
          return [
            ...existing,
            createResponse.client.writeQuery({
              data: createResponse.data,
              query: CreateMedicationDocument,
            }),
          ];
        },
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Typography variant="h3" className={classes.bold}>
        Add Medication
      </Typography>
      <TextField
        required
        control={control}
        name="name"
        label="Name"
        variant="outlined"
        size="small"
        error={!!errors.name}
        helperText={errors.name?.message}
        validation={validateRequired}
      />
      <TextField
        control={control}
        name="pronunciation"
        label="Pronunciation"
        variant="outlined"
        size="small"
        error={!!errors.pronunciation}
        helperText={errors.pronunciation?.message}
      />
      <TextField
        control={control}
        name="url"
        label="URL"
        variant="outlined"
        size="small"
        error={!!errors.url}
        helperText={errors.url?.message}
      />
      <ButtonAsync
        type="submit"
        variant="contained"
        color="primary"
        loading={createResponse.loading}
        success={!!createResponse.data}
        onSuccess={onSuccess}
      >
        Save
      </ButtonAsync>
    </form>
  );
};

export default SettingsOrganisationMedicationForm;

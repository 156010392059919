import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import clsx from 'clsx';

type ColorPickerProps = {
  setColor: (color: string) => void;
};

type ColorPositions = {
  topLeft: string;
  topRight: string;
  bottomLeft: string;
  bottomRight: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    picker: {
      display: 'grid',
      height: 'min-content',
      gridTemplateColumns: 'repeat(10, 1fr)',
      borderRadius: 4,
      '& div': {
        height: 13.33,
        width: 24,
        cursor: 'pointer',
      },
    },
    topLeft: { borderTopLeftRadius: 4 },
    topRight: { borderTopRightRadius: 4 },
    bottomLeft: { borderBottomLeftRadius: 4 },
    bottomRight: { borderBottomRightRadius: 4 },
    white: {
      borderTop: '1px solid ' + grey[300],
      borderLeft: '1px solid ' + grey[300],
    },
  }),
);

const ColorPicker = ({ setColor }: ColorPickerProps) => {
  const classes = useStyles();

  const colors = [
    [
      // "4D4D4D",
      // "999999",
      'ffffff',
      'F44E3B',
      'FE9200',
      'FCDC00',
      'DBDF00',
      'A4DD00',
      '68CCCA',
      '73D8FF',
      'AEA1FF',
      'FDA1FF',
    ],
    [
      // "333333",
      // "808080",
      'CCCCCC',
      'D33115',
      'E27300',
      'FCC400',
      'B0BC00',
      '68BC00',
      '16A5A5',
      '009CE0',
      '7B64FF',
      'FA28FF',
    ],
    [
      '333333',
      // "666666",
      // "B3B3B3",
      '9F0500',
      'C45100',
      'FB9E00',
      '808900',
      '194D33',
      '0C797D',
      '0062B1',
      '653294',
      'AB149E',
    ],
  ];

  const handleSetColor = (event: React.MouseEvent<HTMLElement, MouseEvent>) =>
    setColor(event.currentTarget.getAttribute('data-color')!);

  const positions: ColorPositions = {
    topLeft: colors[0][0],
    topRight: colors[0].slice(-1)[0],
    bottomLeft: colors.slice(-1)[0][0],
    bottomRight: colors.slice(-1)[0].slice(-1)[0],
  };

  return (
    <Box className={classes.picker}>
      {colors.map((group) =>
        group.map((color) => {
          const className =
            color === positions.topLeft
              ? classes.topLeft
              : color === positions.topRight
              ? classes.topRight
              : color === positions.bottomLeft
              ? classes.bottomLeft
              : color === positions.bottomRight
              ? classes.bottomRight
              : undefined;

          return (
            <Box
              data-color={color}
              style={{ backgroundColor: '#' + color }}
              onClick={(event) => handleSetColor(event)}
              className={
                color === 'ffffff' ? clsx(classes.white, className) : className
              }
            ></Box>
          );
        }),
      )}
    </Box>
  );
};

export default ColorPicker;

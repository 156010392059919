import { AlertProps } from '@material-ui/lab';
import { createContext } from 'react';

export type AlertPushFn = {
  message: string;
  severity: AlertProps['color'];
};

export type AlertContextType = {
  push: (alert: AlertPushFn) => void;
};

const AlertContext = createContext<AlertContextType>({
  push: () => {},
});

export default AlertContext;

import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Select } from '@timed/common';
import { NotesContext, NotesContextNoteTypeInput } from '@timed/notes/context';
import { ChangeEvent, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        '& .MuiSelect-root': {
          fontSize: 12,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.divider,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': {
        padding: theme.spacing(2, 9, 2, 4),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1.75, 8, 1.75, 2),
        },
      },
      '& .MuiTypography-root': {
        fontSize: '1rem',
        [theme.breakpoints.down('sm')]: {
          fontSize: 12,
        },
      },
    },
  }),
);

const NotesControlTypeInput = () => {
  const classes = useStyles();

  const {
    input: { type, setType },
  } = useContext(NotesContext);

  const { control, getValues, setValue } = useForm<{
    type: NotesContextNoteTypeInput;
  }>({
    defaultValues: { type },
  });

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setTimeout(() => (document.activeElement as HTMLElement).blur(), 0); // Unselect select field
    setType(event.target.value as NotesContextNoteTypeInput);
  };

  useEffect(() => {
    if (getValues('type') !== type) setValue('type', type);
  }, [type, getValues, setValue]);

  return (
    <Select
      name="type"
      label="Type"
      control={control}
      onChange={handleChange}
      className={classes.root}
      formControlProps={{ variant: 'outlined', size: 'small' }}
      items={[
        { label: 'All Notes', value: 'all' },
        { label: 'Shift Notes', value: 'shift' },
        { label: 'Other Notes', value: 'other' },
      ]}
    />
  );
};

export default NotesControlTypeInput;

import { intersperse } from '@timed/common';
import { Member } from '@timed/gql';

type FormatPermissionsFn = Partial<Pick<Member, 'admin' | 'permissions'>> & {
  showNone?: boolean;
};
/**
 * Format a person's permissions
 */
export const formatPermissions = ({
  admin,
  permissions,
  showNone = false,
}: FormatPermissionsFn) => {
  if (admin) return '';

  if (!!permissions?.length)
    return intersperse({
      array: permissions.map((p) => p.toLocaleLowerCase()),
      and: false,
    });

  return showNone ? 'None' : '';
};

import { createTheme } from '@material-ui/core/styles';

export const themeBase = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    htmlFontSize: 16,
    fontSize: 13,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontSize: 30,
      fontWeight: 400,
    },
    h2: {
      fontSize: 24,
      fontWeight: 400,
    },
    h3: {
      fontSize: 18,
      fontWeight: 400,
    },
  },
  spacing: 4,
  shape: {
    borderRadius: 4,
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0,
        paddingTop: 7.75,
        paddingBottom: 7.75,
        // height: 40,
      },
    },
    MuiIconButton: {
      root: {
        padding: 8,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
      },
      input: {
        paddingTop: 10.5,
        paddingBottom: 10.5,
      },
    },
  },
});

import { CircularProgress, makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { Button, ButtonProps } from '@timed/common';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

export type ButtonAsyncProps = ButtonProps & {
  loading: boolean;
  success: boolean;
  onComplete?: () => void;
  onSuccess?: () => void;
};

const useStyles = makeStyles(() => ({
  spinner: {
    color: green[500],
    position: 'absolute',
    top: 'calc(50% - 12px)',
    left: 'calc(50% - 12px)',
  },
  success: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}));

/**
 * ButtonAsync
 * @param onComplete Callback function to execute after timer
 * @param onSuccess Callback function to execute immediately upon success
 * @returns
 */
const ButtonAsync = ({
  loading,
  success,
  onComplete,
  onSuccess,
  children,
  className,
  disabled,
  ...props
}: ButtonAsyncProps) => {
  const classes = useStyles();
  const timer = useRef<number>();
  const [freeze, setFreeze] = useState<boolean>(false);
  const [succeeded, setSucceeded] = useState<boolean>(false);

  useEffect(() => {
    // Reset counter whenever loading starts
    loading && setSucceeded(false);
  }, [loading]);

  useEffect(() => {
    if (success && !succeeded) {
      setSucceeded(true);
      // Execute callback function immediately upon success
      onSuccess && onSuccess();
      if (onComplete) setFreeze(true);
    }
  }, [success, succeeded, onSuccess, onComplete]);

  useEffect(() => {
    if (onComplete && succeeded) {
      timer.current = window.setTimeout(() => {
        // Execute callback function after a delay
        onComplete && onComplete();
        setFreeze(false);
      }, 250);
    }
    return () => clearTimeout(timer.current);
  }, [succeeded, onComplete]);

  return (
    <Button
      className={freeze ? clsx(className, classes.success) : className}
      disabled={disabled || loading || freeze}
      {...props}
    >
      {children}
      {loading && (
        <CircularProgress thickness={5} size={24} className={classes.spinner} />
      )}
    </Button>
  );
};

export default ButtonAsync;

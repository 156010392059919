import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useAlert } from '@timed/alert';
import { Button, ButtonAsync, IconButton, Modal } from '@timed/common';
import {
  ClientNoteFile,
  DeleteClientNoteFilesMutation,
  DeleteEventFilesMutation,
  EventFile,
  QueryByIdInput,
  useDeleteClientNoteFilesMutation,
  useDeleteEventFilesMutation,
} from '@timed/gql';
import React, { useMemo } from 'react';

type NotesDeleteAttachmentFormModalProps = {
  onClose: () => void;
  attachment: QueryByIdInput &
    (Pick<ClientNoteFile, '__typename'> | Pick<EventFile, '__typename'>);
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    form: {
      padding: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        width: 500,
      },
    },
    title: {
      fontSize: '16px',
      display: 'grid',
      gridTemplateColumns: 'auto min-content',
      alignItems: 'center',
    },
    buttons: {
      marginTop: theme.spacing(1),
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

const NotesDeleteAttachmentFormModal = ({
  onClose,
  attachment,
}: NotesDeleteAttachmentFormModalProps) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(true);

  const alert = useAlert();

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const [deleteEventFile, eventFileResponse] = useDeleteEventFilesMutation();

  const [deleteNoteFile, noteFileResponse] = useDeleteClientNoteFilesMutation();

  const isDeletedEventFile = (
    f?: DeleteEventFilesMutation | DeleteClientNoteFilesMutation | null,
  ): f is DeleteEventFilesMutation =>
    !!f && f.hasOwnProperty('deleteEventFiles');

  const { client, data } = useMemo(() => {
    switch (attachment.__typename) {
      default:
      case 'ClientNoteFile':
        return noteFileResponse;
      case 'EventFile':
        return eventFileResponse;
    }
  }, [eventFileResponse, noteFileResponse, attachment.__typename]);

  const onSuccess = () => {
    alert.push({
      message: 'Attachment deleted',
      severity: 'info',
    });

    const cache = client.cache;

    if (isDeletedEventFile(data)) {
      data!.deleteEventFiles.length &&
        cache.evict({
          id: cache.identify({
            __typename: 'EventFile',
            id: data!.deleteEventFiles[0].id,
          }),
        });
      cache.gc();
    } else {
      data!.deleteClientNoteFiles.length &&
        cache.evict({
          id: cache.identify({
            __typename: 'ClientNoteFile',
            id: data!.deleteClientNoteFiles[0].id,
          }),
        });
      cache.gc();
    }
  };

  const onComplete = () => handleClose();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box className={classes.form}>
        <Box className={classes.title}>
          <Typography className={classes.bold}>Delete attachment</Typography>
          <IconButton aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Typography>
          Are you sure you want to delete this attachment?
        </Typography>

        <Box className={classes.buttons}>
          <ButtonAsync
            color="secondary"
            loading={eventFileResponse.loading || noteFileResponse.loading}
            success={!!eventFileResponse.data || !!noteFileResponse.data}
            onClick={() => {
              switch (attachment.__typename) {
                default:
                case 'ClientNoteFile':
                  return deleteNoteFile({
                    variables: { input: { ids: [attachment.id] } },
                  });
                case 'EventFile':
                  return deleteEventFile({
                    variables: { input: { ids: [attachment.id] } },
                  });
              }
            }}
            onComplete={onComplete}
            onSuccess={onSuccess}
          >
            Delete
          </ButtonAsync>
          <Button color="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default NotesDeleteAttachmentFormModal;

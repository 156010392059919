import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material';
import { useAuth } from '@timed/auth';
import {
  ClientListContext,
  ClientListControls,
  ClientListPagination,
} from '@timed/client';
import {
  Avatar,
  formatPersonName,
  roundNumber,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  useRouter,
} from '@timed/common';
import {
  Client,
  ClientListContextGetAggregatedRedactedClientsQuery,
  OrderBy,
  RedactedClient,
} from '@timed/gql';
import { differenceInMinutes, format } from 'date-fns';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    flipped: {
      transform: 'rotate(180deg)',
      WebkitTransform: 'rotate(180deg)',
      msTransform: 'rotate(180deg)',
    },
    wrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
      '& .MuiSvgIcon-root': {
        fontSize: 14,
      },
    },
    table: {
      display: 'inline-block',
      '& .MuiTypography-root': {
        fontSize: 12,
      },
      '& .MuiSvgIcon-root': {
        fontSize: 14,
      },
    },
    row: {
      height: 56,
      cursor: 'pointer',
      display: 'flex',
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    cell: {
      padding: theme.spacing(0, 4, 0, 4),
      display: 'flex',
      alignItems: 'center',
      '& .MuiTypography-root': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    header: {
      cursor: 'pointer',
      justifyContent: 'start',
      gap: theme.spacing(2),
      '&:hover': {
        backgroundColor: theme.palette.background.paper2,
      },
      '& .MuiTypography-root': {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium,
        overflow: 'hidden',
        whiteSpace: 'normal',
      },
    },
    headerLabelWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
    cellIndex: {
      width: 36,
      justifyContent: 'center',
    },
    cellName: {
      width: 260,
      '& .MuiTypography-root': {
        fontSize: 14,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    cellTrackFiles: {
      width: 90,
      justifyContent: 'center',
    },
    cellHours: {
      width: 80,
      justifyContent: 'end',
    },
    cellMonths: {
      width: 80,
      justifyContent: 'end',
    },
    cellFirstShift: {
      width: 92,
    },
    cellLastShift: {
      width: 92,
    },
    cellCreated: {
      width: 92,
    },
    avatarNameWrapper: {
      display: 'flex',
      gap: theme.spacing(4),
    },
    name: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    avatar: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      fontSize: 8,
    },
  }),
);

const ClientList = () => {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { permissible } = useAuth();

  const { navigate } = useRouter();

  const {
    nodes,
    loading,
    offset,
    input: { orderBy, setOrderBy },
  } = useContext(ClientListContext);

  const handleNavigate = (id: Client['id']) => () => navigate(id);

  const isRedacted = (
    entity: Pick<Client, 'id' | 'createdAt'> | Pick<RedactedClient, 'id'>,
  ): entity is ClientListContextGetAggregatedRedactedClientsQuery['redactedClientsAggregate']['nodes'][0] =>
    !entity.hasOwnProperty('trackFiles');

  const isTester = permissible({ tester: true });

  return (
    <Box className={classes.wrapper}>
      <ClientListControls />

      <ClientListPagination />

      {!!nodes?.length && (
        <Table
          inline
          enableRowHighlighting
          showIndexColumn={isTester && !smDown}
          indexOffset={offset}
          loading={loading}
          backgroundColor={theme.palette.background.default}
          border="line"
          wrapperStyle={{ flex: '1 1 0' }}
        >
          <TableHeader
            sticky
            title="Name of the employee"
            order={orderBy[0].lastName}
            onClick={() => {
              setOrderBy([
                {
                  lastName:
                    !!orderBy[0].lastName &&
                    orderBy[0].lastName === OrderBy.ASC_NULLS_LAST
                      ? OrderBy.DESC_NULLS_LAST
                      : OrderBy.ASC_NULLS_LAST,
                },
              ]);
            }}
          >
            Name
          </TableHeader>
          <TableHeader align="center" hidden={!isTester}>
            Track Files
          </TableHeader>
          <TableHeader
            align="center"
            hidden={!isTester}
            order={orderBy[0].totalBillableMinutes}
            onClick={() => {
              setOrderBy([
                {
                  totalBillableMinutes:
                    !!orderBy[0].totalBillableMinutes &&
                    orderBy[0].totalBillableMinutes === OrderBy.DESC_NULLS_LAST
                      ? OrderBy.ASC_NULLS_LAST
                      : OrderBy.DESC_NULLS_LAST,
                },
              ]);
            }}
          >
            Billable Hours
          </TableHeader>
          <TableHeader
            align="center"
            hidden={!isTester}
            order={orderBy[0].minutesBetweenFirstAndLastBillableEvents}
            onClick={() => {
              setOrderBy([
                {
                  minutesBetweenFirstAndLastBillableEvents:
                    !!orderBy[0].minutesBetweenFirstAndLastBillableEvents &&
                    orderBy[0].minutesBetweenFirstAndLastBillableEvents ===
                      OrderBy.DESC_NULLS_LAST
                      ? OrderBy.ASC_NULLS_LAST
                      : OrderBy.DESC_NULLS_LAST,
                },
              ]);
            }}
          >
            Service Months
          </TableHeader>
          <TableHeader
            align="center"
            hidden={!isTester}
            order={orderBy[0].firstBillableEventStartAt}
            onClick={() => {
              setOrderBy([
                {
                  firstBillableEventStartAt:
                    !!orderBy[0].firstBillableEventStartAt &&
                    orderBy[0].firstBillableEventStartAt ===
                      OrderBy.DESC_NULLS_LAST
                      ? OrderBy.ASC_NULLS_LAST
                      : OrderBy.DESC_NULLS_LAST,
                },
              ]);
            }}
          >
            First Shift
          </TableHeader>
          <TableHeader
            align="center"
            hidden={!isTester}
            order={orderBy[0].lastBillableEventStartAt}
            onClick={() => {
              setOrderBy([
                {
                  lastBillableEventStartAt:
                    !!orderBy[0].lastBillableEventStartAt &&
                    orderBy[0].lastBillableEventStartAt ===
                      OrderBy.DESC_NULLS_LAST
                      ? OrderBy.ASC_NULLS_LAST
                      : OrderBy.DESC_NULLS_LAST,
                },
              ]);
            }}
          >
            Last Shift
          </TableHeader>
          <TableHeader
            align="center"
            hidden={!isTester}
            order={orderBy[0].createdAt}
            onClick={() => {
              setOrderBy([
                {
                  createdAt:
                    !!orderBy[0].createdAt &&
                    orderBy[0].createdAt === OrderBy.DESC_NULLS_LAST
                      ? OrderBy.ASC_NULLS_LAST
                      : OrderBy.DESC_NULLS_LAST,
                },
              ]);
            }}
          >
            Created
          </TableHeader>
          {nodes.map((n, i) => (
            <TableRow key={i} onClick={handleNavigate(n.id)}>
              <TableCell>
                <div className={classes.avatarNameWrapper}>
                  {!smDown && (
                    <Avatar
                      aria-label="avatar"
                      className={classes.avatar}
                      color={n.color}
                      content={[n.firstName, n.middleName, n.lastName]}
                    />
                  )}
                  {formatPersonName(n, {
                    lastNameFirst: true,
                    capitaliseLastName: true,
                    middle: true,
                  })}
                </div>
              </TableCell>
              {!isRedacted(n) && (
                <>
                  <TableCell>{n.trackFiles && <CheckIcon />}</TableCell>
                  <TableCell>
                    {!!n.totalBillableMinutes &&
                      roundNumber(
                        n.totalBillableMinutes / 60,
                        0,
                      ).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    {!!n.firstBillableEvent &&
                      !!n.lastBillableEvent &&
                      roundNumber(
                        (differenceInMinutes(
                          new Date(n.lastBillableEvent.startAt),
                          new Date(n.firstBillableEvent.startAt),
                        ) /
                          60 /
                          24 /
                          365) *
                          12,
                        1,
                      ).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    {!!n.firstBillableEvent &&
                      format(
                        new Date(n.firstBillableEvent.startAt),
                        'dd/MM/yyyy',
                      )}
                  </TableCell>
                  <TableCell>
                    {!!n.lastBillableEvent &&
                      format(
                        new Date(n.lastBillableEvent.startAt),
                        'dd/MM/yyyy',
                      )}
                  </TableCell>
                  <TableCell>
                    {!!n.createdAt &&
                      format(new Date(n.createdAt), 'dd/MM/yyyy')}
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </Table>
      )}
    </Box>
  );
};

export default ClientList;

import { Calendar, CalendarProvider } from '@timed/common';
import {
  MemberContext,
  MemberUnavailabilityPeriodCreateFormModal,
  MemberUnavailableSummaryModal,
  MemberViewUnavailabilityContext,
} from '@timed/member';
import { useContext } from 'react';

const MemberViewUnavailability = () => {
  const { id } = useContext(MemberContext);
  const { unavailables } = useContext(MemberViewUnavailabilityContext);

  return (
    <CalendarProvider
      interactive
      events={unavailables}
      cellClickModal={
        MemberUnavailabilityPeriodCreateFormModal as React.ComponentType
      }
      cellClickModalProps={{ member: { id } }}
      eventClickModal={MemberUnavailableSummaryModal as React.ComponentType}
    >
      <Calendar />
    </CalendarProvider>
  );
};

export default MemberViewUnavailability;

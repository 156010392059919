import {
  Box,
  Collapse,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  Block,
  DataSize,
  formatPersonName,
  IconButton,
  Link,
} from '@timed/common';
import {
  ClientFileType,
  File,
  GetClientFilesQuery,
  Maybe,
  OrderBy,
  useGetClientFilesLazyQuery,
} from '@timed/gql';
import { useAuth } from '@timed/auth';
import {
  ClientContext,
  ClientFileCategory,
  ClientFileMenuButton,
  clientFilesMetadata,
  ClientFileUploadButton,
} from '@timed/client';
import { useLoadingEffect } from '@timed/loading';
import { format, isAfter } from 'date-fns';
import { Fragment, useContext, useEffect, useMemo, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      gap: theme.spacing(4),
      flex: '1 1 auto',
      display: 'flex',
      overflowY: 'hidden',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    tableWrapper: { display: 'flex', overflow: 'hidden' },
    paper: {
      // overflowY: "auto",
      flex: '1 1 auto',
      gridTemplateRows: 'min-content auto',
    },
    files: {
      backgroundColor: theme.palette.background.paper2,
      padding: 0 + ' !important',
      marginBottom: theme.spacing(4),
    },
    disabled: {
      backgroundColor: theme.palette.action.disabledBackground,
    },
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    actions: {
      display: 'inline-grid',
      gridAutoFlow: 'column',
      gridAutoColumns: 'max-content',
      gap: theme.spacing(1),
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },

    file: {
      cursor: 'pointer',
      '& .MuiTableCell-root': { borderBottom: 'none' },
    },
  }),
);

type FormattedData = {
  type: ClientFileType;
  name: string;
  category?: ClientFileCategory;
  updated: Date;
  files: {
    id: File['id'];
    size: string;
    name: string;
    createdAt: string;
    createdBy: GetClientFilesQuery['clientFiles'][0]['file']['createdBy'];
    dateOfFile?: string;
    expiresAt?: string;
    value?: Maybe<string>;
    raw: GetClientFilesQuery['clientFiles'][0];
  }[];
};

const formatData = (
  files: GetClientFilesQuery['clientFiles'],
): FormattedData[] => {
  const result: FormattedData[] = [];

  files.forEach((file: GetClientFilesQuery['clientFiles'][0]) => {
    const { category, name } = clientFilesMetadata.find(
      (meta) => meta.id === file.type,
    )!;

    const {
      dateOfFile,
      type,
      expiresAt,
      value,
      file: { id, createdAt, createdBy, size, name: fileName },
    } = file;

    !result.find((result) => result.type === type) &&
      result.push({
        type,
        category,
        name,
        updated: new Date(createdAt),
        files: [],
      });

    // If a new file is processed belonging to an existing file type, update
    // 'updated' property if new file is chronologically after any previous file
    if (
      result.find((result) => result.name === name) &&
      isAfter(
        new Date(createdAt),
        result.find((result) => result.name === name)!.updated,
      )
    ) {
      result.find((result) => result.category === category)!.updated = new Date(
        createdAt,
      );
    }

    result
      .find((result) => result.type === type)!
      .files.push({
        id,
        createdBy,
        createdAt: format(new Date(createdAt), 'd MMM yyyy'),
        dateOfFile: dateOfFile && format(new Date(dateOfFile), 'd MMM yyyy'),
        expiresAt: expiresAt && format(new Date(expiresAt), 'd MMM yyyy'),
        value,
        name: fileName,
        size:
          (size > DataSize.MB
            ? size / DataSize.MB
            : size / DataSize.KB
          ).toFixed(2) + (size > DataSize.MB ? 'MB' : 'KB'),
        raw: file,
      });
  });

  return result.sort((a, b) => (a.name < b.name ? -1 : 1));
};

function Row({
  category,
  files,
  name,
  updated,
  last,
}: FormattedData & {
  last: boolean;
}) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const auth = useAuth();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const {
    hasDate,
    hasExpiryDate,
    hasNote: hasValue,
  } = clientFilesMetadata.find((meta) => meta.name === name)!;

  return (
    <Fragment>
      <TableRow className={classes.file} onClick={() => setOpen(!open)}>
        <TableCell component="th" scope="row" align="left">
          {name}
        </TableCell>
        {mdUp && (
          <>
            <TableCell>{category}</TableCell>
            <TableCell align="right">{format(updated, 'd MMM yyyy')}</TableCell>
          </>
        )}
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow className={last ? classes.root : undefined}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Block>
              <Box className={classes.files}>
                <Table size="small" aria-label="files">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Upload Date</TableCell>
                      {mdUp && (
                        <>
                          <TableCell>Uploader</TableCell>
                          {hasDate && <TableCell>File Date</TableCell>}
                          {hasExpiryDate && <TableCell>Expiry</TableCell>}
                          {hasValue && <TableCell>Notes</TableCell>}
                          <TableCell>File Name</TableCell>
                          <TableCell>File Size</TableCell>
                        </>
                      )}
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {files.map((file, index) => (
                      <TableRow
                        key={index}
                        className={
                          index === files.length - 1 ? classes.file : undefined
                        }
                      >
                        <TableCell align="left">{file.createdAt}</TableCell>

                        {mdUp && (
                          //mdUp ? "right" : "left"}
                          <>
                            <TableCell>
                              <Link to={'../../' + file.createdBy.id}>
                                {formatPersonName(file.createdBy)!}
                              </Link>
                            </TableCell>{' '}
                            {hasDate && (
                              <TableCell component="th" scope="row">
                                {file.dateOfFile ? file.dateOfFile : '-'}
                              </TableCell>
                            )}
                            {hasExpiryDate && (
                              <TableCell>
                                {file.expiresAt ? file.expiresAt : '-'}
                              </TableCell>
                            )}
                            {hasValue && (
                              <TableCell>
                                {file.value ? file.value : '-'}
                              </TableCell>
                            )}
                            <TableCell>{file.name}</TableCell>
                            <TableCell>{file.size}</TableCell>
                          </>
                        )}
                        <TableCell align="right">
                          <Box className={classes.actions}>
                            <ClientFileMenuButton
                              auth={auth}
                              file={{ ...file.raw }}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Block>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export type ClientFileActions = 'download' | 'edit' | 'delete' | undefined;

const ClientViewFiles = () => {
  const classes = useStyles();
  const client = useContext(ClientContext);
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const [getClientFiles, clientResponse] = useGetClientFilesLazyQuery();

  const formattedData = useMemo(
    () => clientResponse.data && formatData(clientResponse.data.clientFiles),
    [clientResponse.data],
  );

  useLoadingEffect(clientResponse.loading);

  useEffect(() => {
    if (!clientResponse.data)
      getClientFiles({
        variables: {
          input: {
            where: { owner: { id: { _eq: client.id } } },
            orderBy: [{ dateOfFile: OrderBy.ASC }],
          },
        },
      });
  }, [getClientFiles, clientResponse.data, client.id]);

  return (
    <Box className={classes.wrapper}>
      <Box>
        <ClientFileUploadButton />
      </Box>

      {!formattedData || !clientResponse.data || clientResponse.loading ? (
        <>Loading...</>
      ) : (
        <Box className={classes.tableWrapper}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" size="small">
              <TableHead>
                <TableRow>
                  {formattedData.length === 0 ? (
                    <TableCell>No files</TableCell>
                  ) : (
                    <>
                      <TableCell align="left">Document</TableCell>
                      {mdUp && (
                        <>
                          <TableCell>Category</TableCell>
                          <TableCell align="right">Last Updated</TableCell>
                        </>
                      )}
                      <TableCell />
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {formattedData.map((row, index) => (
                  <Row
                    key={index}
                    last={index === formattedData?.length! - 1}
                    {...row}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default ClientViewFiles;

import { Button, Dialog, ModalProps } from '@timed/common';
import {
  ClientNoteKeyword,
  useDeleteClientNoteKeywordsMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';

type NotesDeleteKeywordDialogProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  keyword: Pick<ClientNoteKeyword, 'id' | 'text'>;
};

const NotesDeleteKeywordDialog = ({
  keyword,
  onClose,
  ...props
}: NotesDeleteKeywordDialogProps) => {
  const [deleteEntity, { loading, client }] =
    useDeleteClientNoteKeywordsMutation();

  useLoadingEffect(loading);

  const handleDelete = () => {
    deleteEntity({
      variables: { input: { ids: [keyword.id] } },

      onCompleted: ({ deleteClientNoteKeywords }) => {
        client.cache.evict({
          id: client.cache.identify({
            __typename: 'ClientNoteKeyword',
            id: deleteClientNoteKeywords[0].id,
          }),
        });
        client.cache.gc();
        onClose();
      },
    });
  };

  return (
    <Dialog
      {...props}
      title="Deleting Keyword"
      description={`Are you sure you want to delete "${keyword.text}"?`}
      actions={[
        <Button onClick={onClose}>Cancel</Button>,
        <Button onClick={handleDelete}>Yes</Button>,
      ]}
    />
  );
};

export default NotesDeleteKeywordDialog;

import {
  Box,
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useAuth } from '@timed/auth';
import {
  addServerErrors,
  Block,
  Button,
  ButtonAsync,
  FileInput,
  intersperse,
  Link,
  MIME,
  ProfileInput,
  Snackbar,
} from '@timed/common';
import {
  CreateMemberUploadDocument,
  MemberFileType,
  OrderBy,
  Permission,
  QueryByIdInput,
  useCreateMemberUploadMutation,
  useGetMissingMemberFilesQuery,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { memberFilesMetadata } from '@timed/member';
import {
  MemberUploadFileTypeInput,
  UploadBankDetailsFormModal,
  UploadEmploymentContractFormModal,
  UploadSuperDetailsFormModal,
  UploadTaxFileNumberDeclarationDetailsFormModal,
} from '@timed/upload';
import { useModal } from 'mui-modal-provider';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
  attachment: any;
  type: MemberFileType;
  uploadedFor?: QueryByIdInput;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      alignItems: 'start',
    },
    requiredFiles: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      alignItems: 'start',
    },
    resources: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    info: {
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(8),
      alignItems: 'start',
    },
    group: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      alignItems: 'start',
    },
    categoryInput: {
      '&.MuiInputBase-root': {
        minWidth: 120,
      },
    },
  }),
);

const UploadCreateForm = () => {
  const classes = useStyles();

  const { branch, permissible, member } = useAuth();
  const { showModal } = useModal();

  const [errorText, setErrorText] = useState<string>();
  const [successText, setSuccessText] = useState<string>();

  const [upload, response] = useCreateMemberUploadMutation();

  const missingFiles = useGetMissingMemberFilesQuery();

  useLoadingEffect(response.loading || missingFiles.loading);

  const {
    handleSubmit,
    control,
    setError,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormData>();

  const fileType = watch('type');

  const handleOpenUpdateBankDetailsFormModal = () => {
    const modal: { hide: () => void } = showModal(UploadBankDetailsFormModal, {
      onClose: () => {
        modal.hide();
      },
    });
  };

  const handleOpenUpdateSuperDetailsFormModal = () => {
    const modal: { hide: () => void } = showModal(UploadSuperDetailsFormModal, {
      onClose: () => {
        modal.hide();
      },
    });
  };

  const handleOpenUpdateTaxFileNumberDeclarationDetailsFormModal = () => {
    const modal: { hide: () => void } = showModal(
      UploadTaxFileNumberDeclarationDetailsFormModal,
      {
        onClose: () => {
          modal.hide();
        },
      },
    );
  };

  const handleOpenEmploymentContractFormModal = () => {
    const modal: { hide: () => void } = showModal(
      UploadEmploymentContractFormModal,
      {
        onClose: () => {
          modal.hide();
        },
      },
    );
  };

  useLoadingEffect(response.loading);

  useEffect(
    () =>
      response.error && addServerErrors(response.error, setError, setErrorText),
    [response.error, setError],
  );

  const onSubmit = (input: FormData) => {
    upload({
      variables: { input },
    }).catch((e) => {});
  };

  const onSuccess = () => {
    reset();

    setSuccessText('The file was uploaded successfully.');

    const cache = response.client.cache;

    cache.modify({
      fields: {
        memberUploads(existing = []) {
          return [
            ...existing,
            cache.writeQuery({
              data: response.data,
              query: CreateMemberUploadDocument,
            }),
          ];
        },
      },
    });
  };

  return (
    <Box className={classes.wrapper}>
      <Block title="Resources">
        <Box className={classes.resources}>
          <Typography className={classes.bold} variant="h3">
            VISA Information
          </Typography>
          <Typography variant="body2">
            As from the 1st of July 2023 eveyone will be on a maximum of 48
            hours per fortnight (24 hours per week) as per the new VISA
            conditions for students.
          </Typography>
          <Typography variant="body2">
            If you are NOT on a student VISA (TU 500 or subclass 500 then please
            upload a copy of your VISA.
          </Typography>
          <Typography variant="body2">
            If you do not upload your VISA we assume that you can only work up
            to 24 hours in a week.
          </Typography>
          <Typography className={classes.bold} variant="h3">
            Mandatory Training
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <Link
              to="https://www.service.nsw.gov.au/transaction/ndiswc-apply"
              target="_blank"
            >
              Apply for NDIS Worker Check
            </Link>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <Link
              to="https://www.service.nsw.gov.au/transaction/apply-for-a-working-with-children-check"
              target="_blank"
            >
              Apply for Working With Children Check
            </Link>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <Link
              to="https://training.ndiscommission.gov.au/course/view.php?id=6"
              target="_blank"
            >
              NDIS Worker Orientation Module – 'Quality, Safety and You'
            </Link>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <Link to="https://health.evelearningex.com/login/" target="_blank">
              Covid 19 infection control + PPE training
            </Link>
          </Typography>

          <Typography variant="body2" color="textSecondary">
            <Link
              to="https://www.trainingunlimited.com.au/aged-care-quality-standards/assist-clients-with-medication"
              target="_blank"
            >
              $$$$: Prompt, Assist with Medication Online Course
            </Link>
          </Typography>

          {/* <Typography variant="body2" color="textSecondary">
            <Link to="https://covid-19training.gov.au/login" target="_blank">
              Covid 19 infection control training (Old files only)
            </Link>
          </Typography> */}

          <Divider />

          <Typography className={classes.bold} variant="h3">
            Optional Training
          </Typography>

          <Typography variant="body2" color="textSecondary">
            <Link
              to="https://training.ndiscommission.gov.au/course/index.php?categoryid=3"
              target="_blank"
            >
              FREE: New Worker NDIS Induction Module
            </Link>
          </Typography>

          <Typography variant="body2" color="textSecondary">
            <Link
              to="https://www.ndiscommission.gov.au/workers/worker-training-modules-and-resources/supporting-effective-communication"
              target="_blank"
            >
              FREE: Supporting effective communication
            </Link>
          </Typography>

          <Typography variant="body2" color="textSecondary">
            <Link
              to="https://www.ndiscommission.gov.au/workers/worker-training-modules-and-resources/supporting-safe-and-enjoyable-meals"
              target="_blank"
            >
              FREE: Supporting safe and enjoyable meals
            </Link>
          </Typography>

          <Typography variant="body2" color="textSecondary">
            <Link to="https://mooc.utas.edu.au/course/32" target="_blank">
              FREE: Preventing Dementia (starts Oct 2024)
            </Link>
          </Typography>

          <Typography variant="body2" color="textSecondary">
            <Link to="https://mooc.utas.edu.au/course/33" target="_blank">
              FREE: Understanding Dementia (starts Feb 2025)
            </Link>
          </Typography>

          <Typography variant="body2" color="textSecondary">
            <Link to="https://ms.mooc.utas.edu.au" target="_blank">
              FREE: Understanding Multiple Sclerosis (starts Sept 2024)
            </Link>
          </Typography>

          <Typography variant="body2" color="textSecondary">
            <Link to="https://mooc.utas.edu.au/course/20238" target="_blank">
              FREE: Understanding Traumatic Brain Injury (starts Aug 2024)
            </Link>
          </Typography>

          <Typography variant="body2" color="textSecondary">
            <Link
              to="https://www.youtube.com/watch?v=b1KUM2Db8Bs"
              target="_blank"
            >
              FREE: WorkSafeBC Dementia Presentation - Part 1
            </Link>
          </Typography>

          <Typography variant="body2" color="textSecondary">
            <Link
              to="https://www.youtube.com/watch?v=sYZic1Kf4Dg"
              target="_blank"
            >
              FREE: WorkSafeBC Dementia Presentation - Part 2
            </Link>
          </Typography>

          <Typography variant="body2" color="textSecondary">
            <Link
              to="https://driverpracticetest.transport.nsw.gov.au/pages/practice-tests?init=true"
              target="_blank"
            >
              FREE: NSW Drivers Practice Tests
            </Link>
          </Typography>

          {/* <Typography variant="body2" color="textSecondary">
            <Link
              to="https://learning.epilepsyfoundation.org.au/product/introduction-to-epilepsy-for-disability-sector-workers-2022-individual/"
              target="_blank"
            >
              Introduction to Epilepsy for Disability Support Workers
            </Link>
          </Typography> */}

          <Divider />

          <Typography className={classes.bold} variant="h3">
            Videos
          </Typography>

          <Typography variant="body2" color="textSecondary">
            <Link
              to="https://www.youtube.com/watch?v=M3ulV9eJKQQ"
              target="_blank"
            >
              (Disturbing content warning) Video of absence seizure
            </Link>
          </Typography>

          <Typography variant="body2" color="textSecondary">
            <Link
              to="https://www.youtube.com/watch?v=wS-E5tqAL6c"
              target="_blank"
            >
              (Disturbing content warning) Video of seizure including aura,
              tonic, clonic, and postictal phases.
            </Link>
          </Typography>

          <Typography variant="body2" color="textSecondary">
            <Link
              to="https://www.youtube.com/watch?v=7KIsxmoR7to"
              target="_blank"
            >
              (Disturbing content warning) Seizure in Costco & 2 seizures at
              home
            </Link>
          </Typography>

          <Typography variant="body2" color="textSecondary">
            <Link
              to="https://www.youtube.com/watch?v=efHr4hOVZJc"
              target="_blank"
            >
              (Disturbing content warning) Three seizures I had
            </Link>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <Link
              to="https://www.youtube.com/watch?v=Jp-A99jEaKE"
              target="_blank"
            >
              (Disturbing content warning) First time peeing pants during
              seizure on Good Friday
            </Link>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <Link
              to="https://www.youtube.com/watch?v=Fa_w_Et9Jqg"
              target="_blank"
            >
              (Disturbing content warning) Myoclonic into Complex Partial
              Seizure
            </Link>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <Link
              to="https://www.youtube.com/watch?v=4W2u84mubyE"
              target="_blank"
            >
              (Disturbing content warning) Multiple seizure progression to Tonic
              Clonic
            </Link>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <Link
              to="https://www.youtube.com/watch?v=kO7GlfI--mg"
              target="_blank"
            >
              (Disturbing content warning) Lennox-Gastaut Syndrome: Silas' Story
            </Link>
          </Typography>
        </Box>
      </Block>

      {!!missingFiles.data?.me.member?.missingFiles.length && (
        <Block title="Please upload these files">
          <Box className={classes.requiredFiles}>
            <Typography variant="body2" color="textSecondary">
              {intersperse({
                array: missingFiles.data.me.member.missingFiles
                  .filter(
                    (file) =>
                      file !== MemberFileType.EMPLOYMENT_CONTRACT ||
                      (!!member?.schedulable &&
                        !!missingFiles.data?.me.member?.employmentStartDate),
                  )
                  .map(
                    (type) =>
                      memberFilesMetadata.find((m) => m.id === type)!.name,
                  ),
                sep: <br />,
                and: false,
              })}
            </Typography>
          </Box>
        </Block>
      )}

      <Block title="Actions">
        <Box className={classes.requiredFiles}>
          <Button
            variant="contained"
            onClick={() => handleOpenUpdateBankDetailsFormModal()}
          >
            Add Bank Details
          </Button>
          <Button
            variant="contained"
            onClick={() => handleOpenUpdateSuperDetailsFormModal()}
          >
            Add Superannuation Details
          </Button>
          <Button
            variant="contained"
            onClick={() =>
              handleOpenUpdateTaxFileNumberDeclarationDetailsFormModal()
            }
          >
            Add Tax File Declaration Details
          </Button>
          {(permissible({ tester: true }) ||
            (!permissible({ permissions: Permission.MEMBER_READ }) &&
              !permissible({ permissions: Permission.CLIENT_READ }) &&
              !!missingFiles.data?.me.member?.employmentStartDate &&
              missingFiles.data?.me.member?.missingFiles.includes(
                MemberFileType.EMPLOYMENT_CONTRACT,
              ))) && (
            <Button
              variant="contained"
              onClick={() => handleOpenEmploymentContractFormModal()}
            >
              Complete Employment Agreement
            </Button>
          )}
        </Box>
      </Block>

      <Block title="Upload a file">
        <Snackbar
          open={!!errorText && response.error}
          severity="error"
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          {errorText}
        </Snackbar>
        <Snackbar
          open={!!successText}
          severity="success"
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          {successText}
        </Snackbar>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          {permissible({ permissions: Permission.MEMBER_FILE_WRITE }) && (
            <Box className={classes.group}>
              <Typography className={classes.bold}>
                (Optional) Select an employee this upload is for:
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Leave this field blank to upload your own document.
              </Typography>
              <ProfileInput
                control={control}
                formControlProps={{ size: 'small', variant: 'outlined' }}
                watch={watch}
                type="member"
                name="uploadedFor.id"
                label={undefined}
                className={classes.categoryInput}
                where={
                  !!branch
                    ? { branchMembers: { branch: { id: { _eq: branch.id } } } }
                    : undefined
                }
                orderBy={[{ lastName: OrderBy.ASC }]}
              />
            </Box>
          )}

          <Box className={classes.group}>
            <Typography className={classes.bold}>
              Select a file category:
            </Typography>
            <MemberUploadFileTypeInput
              required
              formControlProps={{ size: 'small', variant: 'outlined' }}
              name="type"
              control={control}
              className={classes.categoryInput}
              label={undefined}
            />
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>
              Select the file to upload:
            </Typography>
            <FileInput
              required
              name="attachment"
              control={control}
              allowedMimeTypes={[
                MIME.JPG,
                MIME.PNG,
                MIME.PDF,
                MIME.DOCX,
                MIME.XLSX,
              ]}
              fileNamePrefix={
                fileType
                  ? memberFilesMetadata
                      .find(({ id }) => id === fileType)!
                      .name.slice(0, 3) + '_'
                  : undefined
              }
              setError={setError}
              setValue={setValue}
              formControlProps={{ size: 'small' }}
              error={!!errors.attachment}
              helperText={errors.attachment?.message?.toString()}
            />
            <Box className={classes.info}>
              <Typography variant="body1" style={{ fontSize: 12 }}>
                Maximum file size 15MB.
              </Typography>
              <Typography style={{ fontSize: 12 }}>
                Accepted file types: JPG, PNG, GIF, PDF, DOCX, XLSX.
              </Typography>
            </Box>
          </Box>
          <Box className={classes.group}>
            <ButtonAsync
              type="submit"
              color="primary"
              disabled={!!Object.keys(errors).length}
              loading={response.loading}
              success={!!response.data}
              onSuccess={onSuccess}
              variant="contained"
            >
              Upload
            </ButtonAsync>
          </Box>
        </form>
      </Block>
    </Box>
  );
};

export default UploadCreateForm;

import {
  Box,
  makeStyles,
  SwipeableDrawer,
  Tab,
  Tabs,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import InsertEmoticonOutlinedIcon from '@material-ui/icons/InsertEmoticonOutlined';
import NotesOutlinedIcon from '@material-ui/icons/NotesOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { createStyles } from '@material-ui/styles';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import {
  _ideas,
  _notes,
  _people,
  _reports,
  _schedule,
  _settings,
  _shifts,
  _start,
  _support,
  _uploads,
} from '@timed/app';
import { useAuth } from '@timed/auth';
import { useRouter } from '@timed/common';
import {
  Permission,
  ProcessingState,
  useGetEventClaimsNotificationBadgeLazyQuery,
  useGetMemberUploadsNotificationBadgeLazyQuery,
} from '@timed/gql';
import { NavBarContext } from '@timed/nav-bar/context';
import { usePage } from '@timed/page';
import { useContext, useEffect } from 'react';

type TabItem = {
  label: string;
  path: string;
  redirect: string | undefined;
  icon: JSX.Element;
  admin?: boolean;
  permissions?: Permission | Permission[];
  notification?: {
    content: boolean | number;
    permissions?: Permission | Permission[];
    admin?: boolean;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: '1 0 auto',
      display: 'flex',
      flexDirection: 'column',
      top: 'initial',
      height: 'initial',
      position: 'initial',
      zIndex: 'initial',
      '&.MuiDrawer-paperAnchorDockedLeft': {
        border: 'none',
      },
      // [theme.breakpoints.up('sm')]: {
      //   position: 'inherit',
      // },
    },

    wrapper: {
      // flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      '& .MuiDrawer-root': {
        display: 'flex',
        flexGrow: 1,
      },
    },
    tabs: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      minWidth: theme.spacing(16),
      [theme.breakpoints.down('sm')]: {
        minWidth: theme.spacing(12),
      },
    },
    tab: {
      lineHeight: 'initial',
      textTransform: 'none',
      fontSize: 11,
      minWidth: 'inherit', // Needed to overwrite MuiTab's default minWidth
      [theme.breakpoints.down('sm')]: {
        fontSize: 8,
      },
      '&.MuiTab-root': {
        padding: 0,
        height: theme.spacing(16),
        [theme.breakpoints.down('sm')]: {
          height: theme.spacing(12),
        },
      },
      '& .MuiTab-wrapper': {
        '& :first-child': {
          marginBottom: theme.spacing(0.25),
        },
      },
      '&.MuiTab-labelIcon': {
        minHeight: 'initial',
        // margin: theme.spacing(3, 0),
      },
      '& .MuiSvgIcon-root': {
        fontSize: theme.spacing(6),
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.background.default,
      },
      '&:last-child': {
        position: 'absolute',
        bottom: 0,
        width: '100%',
      },
      '&:nth-last-child(2)': {
        position: 'absolute',
        bottom: theme.spacing(16),
        [theme.breakpoints.down('sm')]: {
          height: theme.spacing(12),
        },
        width: '100%',
      },
      '& span': {
        // padding: theme.spacing(0, 1),
      },
    },
    // more: {
    //   marginTop: theme.spacing(4),
    //   color: theme.palette.text.primary,
    // },
    indicator: {
      left: 0,
      width: theme.spacing(1),
      [theme.breakpoints.down('lg')]: {
        width: theme.spacing(0.5),
      },
    },
    notificationBadge: {
      position: 'absolute',
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      right: 4,
      top: 4,
      backgroundColor: 'red',
      borderRadius: 4,
    },
  }),
);

const NavBarCoreSupport = () => {
  const classes = useStyles();
  const { pathname, navigate } = useRouter();
  const { permissible } = useAuth();
  const { getDirectPagePath } = usePage();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { isVisible, toggleDrawer } = useContext(NavBarContext);

  const [getMemberUploadsNotification, memberUploadsNotificationResponse] =
    useGetMemberUploadsNotificationBadgeLazyQuery();

  const [getEventClaimsNotification, eventClaimsNotificationResponse] =
    useGetEventClaimsNotificationBadgeLazyQuery();

  const { member, branch } = useAuth();

  const menuItems: (TabItem | undefined)[] = [
    {
      label: _start.label,
      icon: <DashboardOutlinedIcon />,
      path: _start.path,
      redirect: _start.redirect,
      permissions: _start.permissions,
    },
    {
      label: _schedule.label,
      icon: <EventOutlinedIcon />,
      path: _schedule.path,
      redirect: _schedule.redirect,
      permissions: _schedule.permissions,
    },
    {
      label: _people.label,
      icon: <PeopleOutlineIcon />,
      path: _people.path,
      permissions: _people.permissions,
      redirect:
        localStorage.getItem('page.redirect.people') ?? _people.redirect,
    },
    member?.schedulable
      ? {
          label: _shifts.label,
          icon: <ScheduleOutlinedIcon />,
          path: _shifts.path,
          redirect:
            localStorage.getItem('page.redirect.shifts') ?? _shifts.redirect,
          permissions: _shifts.permissions,
          notification: {
            content: !!eventClaimsNotificationResponse.data?.eventClaims.some(
              (claim) => !claim.dismissedAt,
            ),
            permissions: Permission.MEMBER_FILE_WRITE,
          },
        }
      : undefined,
    {
      label: _notes.label,
      icon: <NotesOutlinedIcon />,
      path: _notes.path,
      redirect: _notes.redirect,
      permissions: _notes.permissions,
    },
    {
      label: _uploads.label,
      icon: <CloudUploadOutlinedIcon />,
      path: _uploads.path,
      redirect:
        localStorage.getItem('page.redirect.uploads') ?? _uploads.redirect,
      permissions: _uploads.permissions,
      notification: {
        content:
          !!memberUploadsNotificationResponse.data?.me.member?.org.memberUploads.some(
            (upload) => upload.processingState === ProcessingState.PENDING,
          ),
        permissions: Permission.MEMBER_FILE_WRITE,
      },
    },
    {
      label: _reports.label,
      icon: <AssignmentOutlinedIcon />,
      path: _reports.path,
      redirect: _reports.redirect,
      permissions: _reports.permissions,
    },

    {
      label: _settings.label,
      icon: <SettingsOutlinedIcon />,
      path: _settings.path,
      redirect:
        localStorage.getItem('page.redirect.settings') ?? _settings.redirect,
      permissions: _settings.permissions,
    },
    {
      label: _support.label,
      icon: <HelpOutlineOutlinedIcon />,
      path: _support.path,
      redirect:
        localStorage.getItem('page.redirect.support') ?? _support.redirect,
      permissions: _support.permissions,
    },
  ].filter(Boolean);

  if (permissible({ tester: true }))
    menuItems.splice(6, 0, {
      label: _ideas.label,
      icon: <InsertEmoticonOutlinedIcon />,
      path: _ideas.path,
      redirect: _ideas.redirect,
      permissions: _ideas.permissions,
    });

  const currentTab = pathname.split('/', 2)[1];

  const handleClick =
    (link: string) => (_: React.KeyboardEvent | React.MouseEvent) =>
      link !== currentTab && navigate(link);

  /**
   * Notification icon: Member file uploads
   */
  useEffect(() => {
    if (
      !!member &&
      permissible({ permissions: Permission.MEMBER_FILE_WRITE })
    ) {
      getMemberUploadsNotification({
        variables: {
          input: {
            where: {
              uploadedFor: branch
                ? { branchMembers: { branch: { id: { _eq: branch.id } } } }
                : undefined,
              processingState: { _eq: ProcessingState.PENDING },
            },
          },
        },
      });
    }
  }, [branch, member, permissible, getMemberUploadsNotification]);

  /**
   * Notification icon: Event claims
   */
  useEffect(() => {
    if (!!member && permissible({ permissions: Permission.EVENT_WRITE }))
      getEventClaimsNotification({
        variables: {
          input: {
            where: {
              dismissedAt: { _eq: null },
              event: {
                startAt: { _gt: new Date() },
                member: {
                  id: { _eq: null },
                },
              },
              member: {
                schedulable: { _eq: true },
                branchMembers: branch
                  ? { branch: { id: { _eq: branch.id } } }
                  : undefined,
              },
            },
          },
        },
      });
  }, [branch, member, permissible, getEventClaimsNotification]);

  return (
    <Box className={classes.wrapper}>
      <SwipeableDrawer
        open={isVisible || !smDown}
        anchor="left"
        onOpen={toggleDrawer(true)}
        onClose={toggleDrawer(false)}
        variant={'permanent'}
        PaperProps={{ className: classes.root }}
        ModalProps={{ keepMounted: true }}
        style={{
          display: isVisible || !smDown ? 'flex' : 'none',
        }}
      >
        <Tabs
          orientation="vertical"
          value={'/' + currentTab}
          aria-label="Nav bar"
          className={classes.tabs}
          TabIndicatorProps={{ className: classes.indicator }}
        >
          {menuItems.map(
            (tab, i) =>
              permissible({
                admin: tab!.admin,
                permissions: tab!.permissions,
              }) && (
                <Tab
                  key={i}
                  label={
                    <>
                      {tab?.label}
                      {!!tab!.notification?.content && (
                        <div className={classes.notificationBadge}>
                          {/* {tab!.notification?.content} */}
                        </div>
                      )}
                    </>
                  }
                  icon={tab?.icon}
                  className={classes.tab}
                  value={tab!.path}
                  onClick={handleClick(getDirectPagePath(tab!))}
                />
              ),
          )}
        </Tabs>
      </SwipeableDrawer>
    </Box>
  );
};

export default NavBarCoreSupport;

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { ClientContextType } from '@timed/client';
import {
  addServerErrors,
  DateInput,
  formatPersonName,
  FormModal,
  ModalProps,
} from '@timed/common';
import {
  CreateClientNdisPlanDocument,
  CreateClientNdisPlanInput,
  PersonNamesFragment,
  useCreateClientNdisPlanMutation,
} from '@timed/gql';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type ClientCreateNdisPlanFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  client: Pick<ClientContextType, 'id'> & PersonNamesFragment;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        alignItems: 'start',
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
        flexDirection: 'column',
      },
    },
    group: {
      display: 'flex',
      gap: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        alignItems: 'center',
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
        flexDirection: 'column',
      },
    },
    input: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    textIconWrapper: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
  }),
);

type FormData = CreateClientNdisPlanInput;

const ClientCreateNdisPlanFormModal = ({
  onClose,
  client,
  ...modalProps
}: ClientCreateNdisPlanFormModalProps) => {
  const classes = useStyles();

  const [createEntity, response] = useCreateClientNdisPlanMutation();

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      startAt: null,
      endAt: null,
    },
  });

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSuccess = () => {
    const cache = response.client.cache;

    cache.modify({
      fields: {
        clientNdisPlans(existing = []) {
          return [
            ...existing,
            cache.writeQuery({
              data: response.data,
              query: CreateClientNdisPlanDocument,
            }),
          ];
        },
      },
    });

    reset();
  };

  const onSubmit = async (values: FormData) => {
    // Delete nullish values
    Object.keys(values).forEach(
      (k) =>
        (values[k as keyof FormData] === null ||
          values[k as keyof FormData] === 'undefined') &&
        delete values[k as keyof FormData],
    );

    createEntity({
      variables: {
        input: {
          ..._.omit(values, ['items']),
          client: { id: client.id },
        },
      },
    });
  };

  return (
    <FormModal
      modalProps={modalProps}
      title="Add NDIS Plan"
      description={`Participant: ${formatPersonName(client, {
        lastNameFirst: true,
        capitaliseLastName: true,
      })}`}
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.group}>
          <Box className={classes.input}>
            <Typography className={classes.bold}>Start date of Plan</Typography>
            <DateInput
              required
              keyboard
              clearable
              disableTime
              openTo="year"
              name="startAt"
              control={control}
              inputVariant="outlined"
              size="small"
              error={!!errors.startAt}
              helperText={errors.startAt?.message}
              InputAdornmentProps={{ style: { display: 'none' } }}
            />
          </Box>
          <Box className={classes.input}>
            <Typography className={classes.bold}>End date of Plan</Typography>
            <DateInput
              keyboard
              clearable
              disableTime
              openTo="year"
              name="endAt"
              control={control}
              inputVariant="outlined"
              size="small"
              error={!!errors.endAt}
              helperText={errors.endAt?.message}
              InputAdornmentProps={{ style: { display: 'none' } }}
            />
          </Box>
        </Box>
      </Box>
    </FormModal>
  );
};

export default ClientCreateNdisPlanFormModal;

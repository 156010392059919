import {
  Box,
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { indigo, red } from '@material-ui/core/colors';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import RefreshIcon from '@material-ui/icons/Refresh';
import {
  Button,
  CsvImportContext,
  FileInput,
  IconButton,
  MIME,
  roundNumber,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TimezoneInput,
} from '@timed/common';
import { TimezoneLabels } from '@timed/common/types/TimezoneLabels';
import { pluralise } from '@timed/common/utils/pluralise';
import {
  OrderBy,
  useCreateClientNotesMutation,
  useNotesImportFormGetClientIdsLazyQuery,
  useNotesImportFormGetMemberIdsLazyQuery,
  useValidateClientExternalIdsLazyQuery,
  useValidateMemberExternalIdsLazyQuery,
} from '@timed/gql';
import {
  addDays,
  differenceInMinutes,
  format,
  isSameDay,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns';
import { addMinutes } from 'date-fns/esm';
import { DateTime } from 'luxon';
import { useCallback, useContext, useEffect, useMemo, useReducer } from 'react';

type FormattedRow = {
  commentedByText: string;
  memberExternalId: string;
  clientExternalId: string;
  startAt: Date;
  endAt: Date;
  duration: number;
  travelDistance: number;
  comment: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(4),
    },
    input: {
      width: 'max-content',
      minWidth: 500,
    },
    inputs: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      // width: 300,
    },
    inputGroup: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    controls: {
      display: 'flex',
      gap: theme.spacing(2),
    },

    processButton: {
      backgroundColor: theme.palette.background.default,
    },
    control: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    textFieldError: {
      '& .MuiInputBase-root': {
        color: theme.palette.error.dark,
      },
    },
    message: {
      whiteSpace: 'pre-wrap',
      margin: 0,
    },
    loaderWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    loader: {
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: 'center',
    },
    icon: {
      fill: indigo[500],
    },
    spin: {
      animation: '$spin 750ms linear infinite',
      fill: theme.palette.success.dark,
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  }),
);

const ClientImportForm = () => {
  const classes = useStyles();

  const theme = useTheme();

  const { rows, parse, form, timezone } = useContext(CsvImportContext);

  type RowReducer = (
    state: FormattedRow[],
    dispatch: {
      type: 'set' | 'delete';
      index?: number;
      values?: string[][];
    },
  ) => FormattedRow[];

  const [formattedRows, setFormattedRows] = useReducer<RowReducer>(
    (existing, { type, index, values }) => {
      switch (type) {
        case 'set':
          return !values?.length
            ? []
            : values
                ?.map<FormattedRow>((value) => {
                  const [day, month, year] = value[1].includes('/')
                    ? value[1].split('/').map((value) => Number(value))
                    : value[1]
                        .split('-')
                        .reverse()
                        .map((value) => Number(value));

                  let [hours, minutes, seconds] = value[5]
                    .split(':')
                    .map((value) => Number(value));

                  const startAt = setHours(
                    setMinutes(
                      setSeconds(new Date(year, month - 1, day), seconds),
                      minutes,
                    ),
                    hours,
                  );

                  const estimatedDuration = roundNumber(
                    Number(value[7]) * 60,
                    0,
                  );

                  [hours, minutes, seconds] = value[6]
                    .split(':')
                    .map((value) => Number(value));

                  const endAt = isSameDay(
                    startAt,
                    addMinutes(startAt, estimatedDuration),
                  )
                    ? setHours(
                        setMinutes(setSeconds(startAt, seconds), minutes),
                        hours,
                      )
                    : addDays(
                        setHours(
                          setMinutes(setSeconds(startAt, seconds), minutes),
                          hours,
                        ),
                        1,
                      );

                  return {
                    endAt,
                    startAt,
                    duration: differenceInMinutes(endAt, startAt),
                    comment: value
                      .filter((_, i) => i > 8)
                      .join('\n')
                      .trim(),
                    travelDistance: !!value[8]
                      ? roundNumber(parseFloat(value[8]) * 1000, 0)
                      : 0,
                    clientExternalId: value[0],
                    memberExternalId: value[2],
                    commentedByText: value[4].toUpperCase() + ', ' + value[3],
                  };
                })
                .filter(Boolean);
        case 'delete':
          return existing.filter((_, i) => index !== i);
      }
    },
    [],
  );

  useEffect(() => {
    setFormattedRows({ type: 'set', values: rows });
  }, [rows]);

  /**
   * Validate client external ids query.
   */
  const [validateClientExternalIds, validateClientExternalIdsResponse] =
    useValidateClientExternalIdsLazyQuery({ fetchPolicy: 'network-only' });

  /**
   * Validate member external ids query.
   */
  const [validateMemberExternalIds, validateMemberExternalIdsResponse] =
    useValidateMemberExternalIdsLazyQuery({ fetchPolicy: 'network-only' });

  const [getClients, clientsResponse] = useNotesImportFormGetClientIdsLazyQuery(
    {
      fetchPolicy: 'network-only',
    },
  );

  const [getMembers, membersResponse] = useNotesImportFormGetMemberIdsLazyQuery(
    {
      fetchPolicy: 'network-only',
    },
  );

  /**
   * Create entities mutation.
   */
  const [createEntities, createEntitiesResponse] =
    useCreateClientNotesMutation();

  /**
   * Array of unique client external ids
   */
  const clientExternalIds = useMemo<string[]>(
    () =>
      !!formattedRows?.length
        ? [
            ...new Set(
              formattedRows
                .filter((row) => !!row.clientExternalId)
                .map((row) => row.clientExternalId),
            ),
          ]
        : [],
    [formattedRows],
  );

  /**
   * Array of unique member external ids
   */
  const memberExternalIds = useMemo<string[]>(
    () =>
      !!formattedRows?.length
        ? [
            ...new Set(
              formattedRows
                .filter((row) => !!row.memberExternalId)
                .map((row) => row.memberExternalId),
            ),
          ]
        : [],
    [formattedRows],
  );

  const fieldsMissingValues = useMemo(
    () =>
      formattedRows.filter(
        ({ clientExternalId, memberExternalId, startAt, endAt, duration }) =>
          !clientExternalId ||
          !memberExternalId ||
          !startAt ||
          !endAt ||
          !duration,
      ),
    [formattedRows],
  );

  const errorMessage = useMemo(
    () =>
      fieldsMissingValues.length
        ? 'Error: ' +
          fieldsMissingValues.length +
          ' ' +
          pluralise({
            singular: 'record',
            plural: 'records',
            quantity: fieldsMissingValues.length,
          }) +
          ' ' +
          pluralise({
            singular: '',
            plural: 'have',
            quantity: fieldsMissingValues.length,
          }) +
          ' ' +
          'missing ' +
          pluralise({
            singular: 'a value',
            plural: 'values',
            quantity: fieldsMissingValues.length,
          })
        : '',
    [fieldsMissingValues],
  );

  /**
   * Handle validating client external ids
   */
  const handleValidateClientExternalIds = useCallback(() => {
    if (!!clientExternalIds.length) {
      validateClientExternalIds({
        variables: {
          input: {
            exists: true,
            ids: clientExternalIds,
          },
        },
      });
    }
  }, [clientExternalIds, validateClientExternalIds]);

  /**
   * Handle validating member external ids
   */
  const handleValidateMemberExternalIds = useCallback(() => {
    if (!!memberExternalIds.length) {
      validateMemberExternalIds({
        variables: {
          input: {
            exists: true,
            ids: memberExternalIds,
          },
        },
      });
    }
  }, [memberExternalIds, validateMemberExternalIds]);

  /**
   * Handle creating entities
   */
  const handleCreateEntities = useCallback(() => {
    if (
      !!timezone &&
      !!formattedRows.length &&
      !!clientsResponse.data &&
      (!memberExternalIds.length ||
        (!!memberExternalIds.length && !!membersResponse.data))
    ) {
      const timezoneLabel = TimezoneLabels.find(
        (tz) => tz.value === timezone,
      )?.label;

      createEntities({
        variables: {
          input: {
            timezone,
            objects: formattedRows.map(
              ({
                comment,
                commentedByText,
                memberExternalId,
                clientExternalId,
                startAt,
                duration,
                travelDistance,
              }) => ({
                client: {
                  id: clientsResponse.data!.clients.find(
                    ({ externalId }) => externalId === clientExternalId,
                  )!.id!,
                },
                comment,
                commentedAt: DateTime.fromJSDate(startAt)
                  .setZone(timezoneLabel, { keepLocalTime: true })
                  .toJSDate(),
                commentedBy: memberExternalId
                  ? {
                      id: membersResponse.data!.members.find(
                        ({ externalId }) => externalId === memberExternalId,
                      )!.id!,
                    }
                  : undefined,
                commentedByText: !memberExternalId
                  ? commentedByText
                  : undefined,
                newEvent: {
                  startAt: DateTime.fromJSDate(startAt)
                    .setZone(timezoneLabel, { keepLocalTime: true })
                    .toJSDate(),
                  duration,
                  travelDistance,
                  client: {
                    id: clientsResponse.data!.clients.find(
                      ({ externalId }) => externalId === clientExternalId,
                    )!.id!,
                  },
                },
              }),
            ),
          },
        },
      });
    }
  }, [
    timezone,
    formattedRows,
    clientsResponse.data,
    memberExternalIds.length,
    membersResponse.data,
    createEntities,
  ]);

  const formattedDataTable = useMemo(
    () =>
      !!formattedRows.length && (
        <Table
          showIndexColumn
          enableRowHighlighting
          wrapperStyle={{ maxWidth: '100%' }}
        >
          <TableHeader sticky>Author Name</TableHeader>
          <TableHeader>Author External ID</TableHeader>
          <TableHeader>Participant External ID</TableHeader>
          <TableHeader align="center" order={OrderBy.ASC}>
            Start Date
          </TableHeader>
          <TableHeader align="center">Start Time</TableHeader>
          <TableHeader align="center">End Time</TableHeader>
          <TableHeader align="center">Hours</TableHeader>
          <TableHeader align="center">KMs</TableHeader>
          <TableHeader style={{ width: 'auto', minWidth: 500 }}>
            Comments
          </TableHeader>
          <TableHeader sticky></TableHeader>
          {formattedRows.map(
            (
              {
                commentedByText,
                memberExternalId,
                clientExternalId,
                startAt,
                endAt,
                duration,
                travelDistance,
                comment,
              },
              i,
            ) => (
              <TableRow key={i}>
                <TableCell>{commentedByText}</TableCell>
                <TableCell
                  style={{
                    color:
                      !memberExternalId ||
                      validateMemberExternalIdsResponse.data?.validateMemberExternalIds.ids.includes(
                        memberExternalId,
                      )
                        ? 'white'
                        : undefined,
                    backgroundColor:
                      !memberExternalId ||
                      validateMemberExternalIdsResponse.data?.validateMemberExternalIds.ids.includes(
                        memberExternalId,
                      )
                        ? red[300]
                        : undefined,
                  }}
                >
                  {memberExternalId || 'None provided'}
                </TableCell>
                <TableCell
                  style={{
                    color:
                      !clientExternalId ||
                      validateClientExternalIdsResponse.data?.validateClientExternalIds.ids.includes(
                        clientExternalId,
                      )
                        ? 'white'
                        : undefined,
                    backgroundColor:
                      !clientExternalId ||
                      validateClientExternalIdsResponse.data?.validateClientExternalIds.ids.includes(
                        clientExternalId,
                      )
                        ? red[300]
                        : undefined,
                  }}
                >
                  {clientExternalId || 'None provided'}
                </TableCell>
                <TableCell>{format(startAt, 'dd MMM yyyy')}</TableCell>
                <TableCell>{format(startAt, 'HH:mm')}</TableCell>
                <TableCell>{format(endAt, 'HH:mm')}</TableCell>
                <TableCell>{roundNumber(duration / 60, 2)}</TableCell>
                <TableCell>{travelDistance / 1000}</TableCell>
                <TableCell>
                  <p className={classes.message}>{comment}</p>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      setFormattedRows({ type: 'delete', index: i });
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ),
          )}
        </Table>
      ),
    [
      formattedRows,
      validateClientExternalIdsResponse.data,
      validateMemberExternalIdsResponse.data,
      classes.message,
    ],
  );

  /**
   * Validate client external ids.
   */
  useEffect(() => {
    if (
      !!clientExternalIds?.length &&
      !validateClientExternalIdsResponse.called
    ) {
      handleValidateClientExternalIds();
    }
  }, [
    clientExternalIds,
    handleValidateClientExternalIds,
    validateClientExternalIdsResponse.called,
  ]);

  /**
   * Validate member external ids after validation of client ids.
   */
  useEffect(() => {
    if (
      validateClientExternalIdsResponse.called &&
      !validateClientExternalIdsResponse.loading &&
      !!memberExternalIds.length &&
      !validateMemberExternalIdsResponse.called
    ) {
      handleValidateMemberExternalIds();
    }
  }, [
    validateClientExternalIdsResponse.called,
    validateClientExternalIdsResponse.loading,
    memberExternalIds,
    handleValidateMemberExternalIds,
    validateMemberExternalIdsResponse.called,
  ]);

  // const clientIdsFailedValidation = useMemo(() => {
  //   return clientExternalIds.filter((id) =>
  //     validateClientExternalIdsResponse.data?.validateClientExternalIds.ids.includes(
  //       id,
  //     ),
  //   );
  // }, [validateClientExternalIdsResponse.data, clientExternalIds]);

  // const memberIdsFailedValidation = useMemo(() => {
  //   return memberExternalIds.filter((id) =>
  //     validateMemberExternalIdsResponse.data?.validateMemberExternalIds.ids.includes(
  //       id,
  //     ),
  //   );
  // }, [validateMemberExternalIdsResponse.data, memberExternalIds]);

  /**
   * Get client ids.
   */
  useEffect(() => {
    if (!!clientExternalIds.length) {
      getClients({
        variables: {
          input: {
            where: {
              externalId: { _in: clientExternalIds },
              // branch: {id: branc}
            },
          },
        },
      });
    }
  }, [getClients, clientExternalIds]);

  /**
   * Get member ids.
   */
  useEffect(() => {
    if (!!memberExternalIds.length) {
      getMembers({
        variables: {
          input: { where: { externalId: { _in: memberExternalIds } } },
        },
      });
    }
  }, [getMembers, memberExternalIds]);

  const readyToImport =
    !!formattedRows.length &&
    validateClientExternalIdsResponse.called &&
    !validateClientExternalIdsResponse.loading &&
    validateClientExternalIdsResponse.data &&
    !validateClientExternalIdsResponse.data.validateClientExternalIds.ids
      .length &&
    validateMemberExternalIdsResponse.called &&
    !validateMemberExternalIdsResponse.loading &&
    validateMemberExternalIdsResponse.data &&
    !validateMemberExternalIdsResponse.data.validateMemberExternalIds.ids
      .length;

  return (
    <div className={classes.wrapper}>
      <Typography variant="h6">Import Notes</Typography>
      <Box className={classes.inputs}>
        <TimezoneInput
          name="timezone"
          control={form.control}
          className={classes.input}
        />
        <FileInput
          required
          onChange={(event) => parse((event.target as HTMLInputElement).files)}
          name="attachment"
          control={form.control}
          allowedMimeTypes={[MIME.CSV]}
          setError={form.setError}
          setValue={form.setValue}
          formControlProps={{ size: 'small' }}
          error={!!form.errors?.attachment}
          helperText={form.errors?.attachment?.message?.toString()}
          className={classes.input}
        />
        <Button
          onClick={handleCreateEntities}
          variant="contained"
          size="small"
          color="primary"
          style={{ width: 'max-content' }}
          disabled={
            !timezone ||
            !(
              validateClientExternalIdsResponse.called &&
              !validateClientExternalIdsResponse.loading &&
              validateMemberExternalIdsResponse.called &&
              !validateMemberExternalIdsResponse.loading
            ) ||
            !!validateClientExternalIdsResponse.data?.validateClientExternalIds
              .ids.length ||
            !!validateMemberExternalIdsResponse.data?.validateMemberExternalIds
              .ids.length ||
            !clientExternalIds.length ||
            clientExternalIds.length !== clientsResponse.data?.clients.length ||
            // (!!memberExternalIds.length &&
            //   memberExternalIds.length !==
            //     membersResponse.data?.members.length) ||
            !!createEntitiesResponse.data
          }
        >
          Import
        </Button>
      </Box>
      <Divider />

      {validateClientExternalIdsResponse.called && (
        <Box className={classes.loaderWrapper}>
          {validateClientExternalIdsResponse.loading ? (
            <Box className={classes.loader}>
              <RefreshIcon fontSize="small" className={classes.spin} />
              <span>Validating participant external IDs</span>
            </Box>
          ) : validateClientExternalIdsResponse.data?.validateClientExternalIds
              .ids.length ? (
            <>
              <Box className={classes.loader}>
                <ErrorOutlineIcon
                  fontSize="small"
                  style={{ fill: theme.palette.error.dark }}
                />
                <div style={{ color: theme.palette.error.dark }}>
                  The following participant external IDs failed validation.
                  Please add these participant before import.
                </div>
              </Box>
              <ul>
                {validateClientExternalIdsResponse.data.validateClientExternalIds.ids.map(
                  (id) => (
                    <li>
                      <span style={{ color: theme.palette.error.dark }}>
                        {id}
                      </span>
                    </li>
                  ),
                )}
              </ul>
            </>
          ) : (
            <Box className={classes.loader}>
              <CheckIcon
                fontSize="small"
                style={{ fill: theme.palette.success.dark }}
              />
              <div style={{ color: theme.palette.success.dark }}>
                Participant external IDs validated successfully
              </div>
            </Box>
          )}
        </Box>
      )}

      {validateMemberExternalIdsResponse.called && (
        <Box className={classes.loaderWrapper}>
          {validateMemberExternalIdsResponse.loading ? (
            <Box className={classes.loader}>
              <RefreshIcon fontSize="small" className={classes.spin} />
              <span>Validating employee external IDs</span>
            </Box>
          ) : validateMemberExternalIdsResponse.data?.validateMemberExternalIds
              .ids.length ? (
            <>
              <Box className={classes.loader}>
                <ErrorOutlineIcon
                  fontSize="small"
                  style={{ fill: theme.palette.error.dark }}
                />
                <div style={{ color: theme.palette.error.dark }}>
                  The following employee external IDs failed validation. Please
                  add these employees before import.
                </div>
              </Box>
              <ul>
                {validateMemberExternalIdsResponse.data.validateMemberExternalIds.ids.map(
                  (id) => (
                    <li>
                      <span style={{ color: theme.palette.error.dark }}>
                        {id} -{' '}
                        {
                          formattedRows.find(
                            ({ memberExternalId }) => memberExternalId === id,
                          )?.commentedByText
                        }
                      </span>
                    </li>
                  ),
                )}
              </ul>
            </>
          ) : (
            <Box className={classes.loader}>
              <CheckIcon
                fontSize="small"
                style={{ fill: theme.palette.success.dark }}
              />
              <div style={{ color: theme.palette.success.dark }}>
                Employee external IDs validated successfully
              </div>
            </Box>
          )}
        </Box>
      )}

      {!!readyToImport && (
        <Box className={classes.loaderWrapper}>
          {createEntitiesResponse.loading ? (
            <Box className={classes.loader}>
              <RefreshIcon fontSize="small" className={classes.spin} />
              <span>
                Importing{' '}
                {pluralise({
                  plural: 'records',
                  singular: 'record',
                  quantity: formattedRows.length,
                })}
              </span>
            </Box>
          ) : createEntitiesResponse.data?.createClientNotes.length ? (
            <Box className={classes.loader}>
              <CheckIcon
                fontSize="small"
                style={{ fill: theme.palette.success.dark }}
              />
              <div style={{ color: theme.palette.success.dark }}>
                {createEntitiesResponse.data.createClientNotes.length}{' '}
                {pluralise({
                  plural: 'records',
                  singular: 'record',
                  quantity:
                    createEntitiesResponse.data.createClientNotes.length,
                })}{' '}
                imported successfully.
              </div>
            </Box>
          ) : (
            <Box className={classes.loader}>
              <ArrowForwardIcon fontSize="small" className={classes.icon} />
              <div>
                {formattedRows.length}{' '}
                {pluralise({
                  plural: 'records',
                  singular: 'record',
                  quantity: formattedRows.length,
                })}{' '}
                ready to be imported
              </div>
            </Box>
          )}
        </Box>
      )}

      {!!formattedRows.length && formattedDataTable}
    </div>
  );
};

export default ClientImportForm;

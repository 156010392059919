import { Select, SelectMenuItem, SelectProps } from '@timed/common';
import { validateBillingRegistrationGroup } from '@timed/file/helpers/input/validations/billing-registration-group';
import { EventBillingRegistrationGroup } from '@timed/gql';

const EventBillingRegistrationGroupInput = ({
  defaultValue,
  ...props
}: Omit<SelectProps, 'items'>) => {
  const items: SelectMenuItem[] = [
    {
      label: 'Daily Personal Activities',
      value: EventBillingRegistrationGroup.DAILY_PERSONAL_ACTIVITIES,
    },
    {
      label: 'Community Participation',
      value: EventBillingRegistrationGroup.COMMUNITY_PARTICIPATION,
    },
    {
      label: 'Daily Life Skills',
      value: EventBillingRegistrationGroup.DAILY_LIFE_SKILLS,
    },
  ];

  return (
    <Select
      label="Billing Registration Group"
      validation={validateBillingRegistrationGroup}
      items={items}
      {...props}
    />
  );
};

export default EventBillingRegistrationGroupInput;

import { _shiftsAdminTravel } from '@timed/app';
import { Page } from '@timed/page';
import { ShiftsTravel } from '@timed/shifts';

const RouteCoreSupportShiftsAdminTravel = () => (
  <Page {..._shiftsAdminTravel}>
    <ShiftsTravel />
  </Page>
);

export default RouteCoreSupportShiftsAdminTravel;

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { red, yellow } from '@material-ui/core/colors';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import WarningIcon from '@mui/icons-material/Warning';
import { _peopleMembers } from '@timed/app';
import { AuthContextType, Protected } from '@timed/auth';
import {
  AddressInput,
  addServerErrors,
  Button,
  Checkbox,
  DateInput,
  formatPersonName,
  FormModal,
  intersperse,
  Link,
  ModalProps,
  roundNumber,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  Textarea,
  TimeInput,
} from '@timed/common';
import { pluralise } from '@timed/common/utils/pluralise';
import {
  EventBillClientCheckbox,
  EventBillingRegistrationGroupInput,
  EventBonusPayInput,
  EventDurationInput,
  EventMemberInput,
  EventPayAttendeeCheckbox,
  EventRequireCarCheckbox,
  EventSetToDefaultDurationButton,
} from '@timed/event';
import {
  Address,
  Event,
  GetEventByIdQuery,
  GetEventClaimsQuery,
  MemberHoursType,
  OrderBy,
  QueryByIdInput,
  useGetPublicHolidaysLazyQuery,
  useUpdateEventMutation,
} from '@timed/gql';
import { ScheduleContextType } from '@timed/schedule';
import {
  addHours,
  addMinutes,
  differenceInMinutes,
  eachDayOfInterval,
  format,
  formatISO,
  isBefore,
  isEqual,
  isValid,
  startOfWeek,
  subMilliseconds,
} from 'date-fns';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type EventUpdateFormModalProps = Omit<ModalProps, 'children'> & {
  closeSummaryPage: () => void;
  onClose: () => void;
  permissible: AuthContextType['permissible'];
  event: GetEventByIdQuery['eventById'];
  claims: GetEventClaimsQuery['eventClaims'];
  setTime: ScheduleContextType['setTime'];
};

type FormData = {
  unsetClockedOn?: boolean | null;
  unsetClockedOff?: boolean | null;
  patch: Pick<
    Event,
    | 'locked'
    | 'startAt'
    | 'endAt'
    | 'passiveStartAt'
    | 'activeAssist'
    | 'duration'
    | 'summary'
    | 'scheduleNotes'
    | 'passive'
    | 'billable'
    | 'billingRegistrationGroup'
    | 'payable'
    | 'bonusPay'
    | 'publicHoliday'
    | 'clockedOnAt'
    | 'clockedOffAt'
    | 'requireCar'
  > & {
    startAtTime?: Date | null;
    endAtTime?: Date | null;
    clockedOnAtTime?: Date | null;
    clockedOffAtTime?: Date | null;
    passiveStartAtTime?: Date | null;
    member?: QueryByIdInput | null;
    address?: Pick<
      Address,
      | 'unit'
      | 'street'
      | 'locality'
      | 'region'
      | 'postcode'
      | 'country'
      | 'latitude'
      | 'longitude'
    > | null;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        alignItems: 'start',
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
        flexDirection: 'column',
      },
    },
    textarea: {
      backgroundColor: theme.palette.background.paper,
      width: 322,
      color: theme.palette.text.primary,
      border: '1px solid ' + theme.palette.text.disabled,
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.up('md')]: {
        width: 483,
      },
    },
    address: {
      width: 322,
      [theme.breakpoints.up('md')]: {
        width: 483,
      },
    },
    select: {
      width: 322,
    },
    dates: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridAutoColumns: '120px 90px 80px',
      gap: theme.spacing(4),
      alignItems: 'center',
    },
    passive: {
      width: 322,
      backgroundColor: theme.palette.background.paper2,
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2, 4),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    groups: {
      display: 'grid',
      gridTemplateColumns: 'auto',
      gap: theme.spacing(4),
    },
    group: {
      display: 'grid',
      gridTemplateColumns: 'max-content',
      gridAutoRows: 'max-content',
      gap: theme.spacing(1),
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    buttons: {
      flex: '0 1 max-content',
      display: 'flex',
      justifyContent: 'space-between',
    },
    error: {
      width: 322,
      color: theme.palette.error.main,
      backgroundColor: red[50],
      border: '1px solid ' + red[800],
      padding: theme.spacing(2),
    },
  }),
);

const EventUpdateFormModal = ({
  onClose,
  closeSummaryPage,
  permissible,
  event,
  claims,
  setTime,
  ...modalProps
}: EventUpdateFormModalProps) => {
  const classes = useStyles();

  const [passive, setPassive] = useState<boolean>(event.passive);

  const [updateEvent, response] = useUpdateEventMutation();

  const [getPublicHolidays, publicHolidaysResponse] =
    useGetPublicHolidaysLazyQuery({ fetchPolicy: 'network-only' });

  const [lastToggledCheckbox, setLastToggleCheckbox] = useState<
    'billable' | 'payable' | null
  >();

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      patch: {
        startAt: setTime(event.startAt),
        startAtTime: setTime(event.startAt),
        endAt: setTime(event.endAt),
        endAtTime: setTime(event.endAt),
        clockedOnAt: !!event.clockedOnAt
          ? setTime(event.clockedOnAt)
          : undefined,
        clockedOnAtTime: !!event.clockedOnAt
          ? setTime(event.clockedOnAt)
          : undefined,
        clockedOffAt: !!event.clockedOffAt
          ? setTime(event.clockedOffAt)
          : undefined,
        clockedOffAtTime: !!event.clockedOffAt
          ? setTime(event.clockedOffAt)
          : undefined,
        duration: roundNumber(event.duration / 60, 2),
        member: { id: event.member?.id },
        summary: event.summary,
        scheduleNotes: event.scheduleNotes,
        passive: event.passive,
        passiveStartAt: !!event.passiveStartAt
          ? setTime(event.passiveStartAt)
          : undefined,
        passiveStartAtTime: !!event.passiveStartAt
          ? setTime(event.passiveStartAt)
          : undefined,
        activeAssist: event.activeAssist,
        locked: event.locked,
        billable: event.billable,
        billingRegistrationGroup: event.billingRegistrationGroup,
        payable: event.payable,
        bonusPay: !!event.bonusPay ? event.bonusPay / 100 : 0,
        requireCar: event.requireCar ?? false,
        publicHoliday: !!event.publicHoliday,
        address: _.pick(event, [
          'unit',
          'street',
          'locality',
          'region',
          'postcode',
          'country',
          'latitude',
          'longitude',
        ]),
      },
    },
  });

  const billable = watch('patch.billable');
  const payable = watch('patch.payable');
  const startAtWatched = watch('patch.startAt');
  const endAtWatched = watch('patch.endAt');
  const unsetClockedOn = watch('unsetClockedOn');
  const unsetClockedOff = watch('unsetClockedOff');
  const requireCar = watch('patch.requireCar');
  const region = watch('patch.address.region');

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = ({
    patch: { address, member, locked, bonusPay, ...patch },
  }: FormData) => {
    // Convert date values to correct timezone
    const { endAt, ...formattedPatch } = Object.fromEntries(
      Object.entries(patch).map(([k, v]): [k: string, v: any] => [
        k,
        v instanceof Date ? setTime(v, true) : v,
      ]),
    );

    if (!address || Object.keys(address).length === 0) {
      address = {
        unit: null,
        street: null,
        locality: null,
        region: null,
        postcode: null,
        country: null,
        latitude: null,
        longitude: null,
      };
    }

    if (!formattedPatch.passive) formattedPatch.passiveStartAt = null;

    if (!permissible({ tester: true })) {
      delete formattedPatch.clockedOnAt;
      delete formattedPatch.clockedOffAt;
    }

    updateEvent({
      variables: {
        input: {
          id: event.id,
          unsetClockedOff,
          unsetClockedOn,
          patch: {
            ..._.omit(formattedPatch, [
              'duration',
              'startAtTime',
              'endAtTime',
              'passiveStartAtTime',
              'clockedOnAtTime',
              'clockedOffAtTime',
            ]),
            ...address,
            member: member?.id ? member : null,
            duration: differenceInMinutes(endAt, formattedPatch.startAt),
            locked: permissible({ admin: true }) ? locked : undefined,
            bonusPay: permissible({ admin: true })
              ? roundNumber(bonusPay * 100, 2)
              : undefined,
          },
        },
      },
    });
  };

  const handleChangeStartAt = useCallback(
    (value: MaterialUiPickersDate) => {
      value ??= new Date();

      const endAt = getValues('patch.endAt');
      const duration = getValues('patch.duration');

      // Set new startAt value
      setValue('patch.startAt', value);

      if (endAt) {
        if (isBefore(endAt, value)) {
          // Set endAt to a time in the future if it occurs before the new startAt
          setValue('patch.endAt', addMinutes(value, duration * 60));
          setValue('patch.endAtTime', addMinutes(value, duration * 60));
        } else {
          setValue(
            'patch.duration',
            (endAt.getTime() - value.getTime()) / 1000 / 60 / 60,
          );
        }
      }
    },
    [getValues, setValue],
  );

  const handleChangeEndAt = useCallback(
    (value: MaterialUiPickersDate | string) => {
      if (typeof value === 'string') {
        if (value === '' || +value < 0) {
          setValue('patch.endAt', getValues('patch.startAt'));
          setValue('patch.endAtTime', getValues('patch.startAt'));
        } else if (!isNaN(parseFloat(value))) {
          const newEndAt = addMinutes(
            getValues('patch.startAt') as Date,
            parseFloat(value) * 60,
          );
          setValue('patch.endAt', newEndAt);
          setValue('patch.endAtTime', newEndAt);
        }
      } else {
        setValue('patch.endAt', value || new Date());
        setValue('patch.endAtTime', value || new Date());
      }
      if (
        getValues('patch.startAt') &&
        getValues('patch.endAt') &&
        isBefore(getValues('patch.startAt'), getValues('patch.endAt'))
      ) {
        setValue(
          'patch.duration',
          typeof value === 'string' && value.slice(-1) === '.'
            ? parseFloat(value)
            : roundNumber(
                ((getValues('patch.endAt')! as Date).getTime() -
                  (getValues('patch.startAt')! as Date).getTime()) /
                  1000 /
                  60 /
                  60,
                2,
              ),
        );
        if (
          value instanceof Date &&
          (!getValues('patch.endAtTime') ||
            !isEqual(value, new Date(getValues('patch.endAtTime')!)))
        )
          setValue('patch.endAtTime', new Date(value));
      } else {
        setValue('patch.duration', 0);
      }
    },
    [getValues, setValue],
  );

  const handleTogglePassive = () => {
    if (!passive) {
      // If duration is less than 12 hours, set it to 12 hours
      if (
        differenceInMinutes(
          getValues('patch.endAt'),
          getValues('patch.startAt'),
        ) < 720
      )
        handleChangeEndAt(addHours(getValues('patch.startAt'), 12));

      // Set a default value for passiveStartAt
      if (!getValues('patch.passiveStartAt')) {
        setValue(
          'patch.passiveStartAt',
          addHours(getValues('patch.startAt'), 2),
        );
        setValue(
          'patch.passiveStartAtTime',
          addHours(getValues('patch.startAt'), 2),
        );
      }
    } else {
      // Unset passiveSetAt if event was orginally not passive and a passive start time has been set
      if (!event.passive && !!getValues('patch.passiveStartAt'))
        setValue('patch.passiveStartAt', undefined);
    }

    setValue('patch.passive', !passive);
    setPassive(!passive);
  };

  const handleChangePassiveStartAt = useCallback(
    (passiveStartAt) => {
      if (isBefore(passiveStartAt, getValues('patch.startAt'))) {
        setValue('patch.startAtTime', passiveStartAt);
        handleChangeStartAt(passiveStartAt);
      }
    },
    [getValues, setValue, handleChangeStartAt],
  );

  const handleChangeStartAtTime = useCallback(
    (date?: MaterialUiPickersDate) => {
      if (!!date && isValid(date)) {
        const currentDate = new Date(getValues('patch.startAt'));
        currentDate.setHours(date.getHours(), date.getMinutes());
        handleChangeStartAt(currentDate);
      }
    },
    [getValues, handleChangeStartAt],
  );

  const handleChangeEndAtTime = useCallback(
    (date?: MaterialUiPickersDate) => {
      if (!!date && isValid(date)) {
        const currentDate = new Date(getValues('patch.endAt'));
        currentDate.setHours(date.getHours(), date.getMinutes());
        handleChangeEndAt(currentDate);
      }
    },
    [getValues, handleChangeEndAt],
  );

  const handleChangePassiveStartAtTime = useCallback(
    (date?: MaterialUiPickersDate) => {
      if (!!date && isValid(date)) {
        const currentDate = new Date(getValues('patch.passiveStartAt'));
        currentDate.setHours(date.getHours(), date.getMinutes());
        setValue('patch.passiveStartAt', currentDate);
        handleChangePassiveStartAt(currentDate);
      }
    },
    [getValues, setValue, handleChangePassiveStartAt],
  );

  const handleChangeClockedOnAtTime = useCallback(
    (date?: MaterialUiPickersDate) => {
      if (!!date && isValid(date)) {
        const currentDate = new Date(getValues('patch.clockedOnAt'));
        currentDate.setHours(date.getHours(), date.getMinutes());
        setValue('patch.clockedOnAt', currentDate);
      }
    },
    [getValues, setValue],
  );

  const handleChangeClockedOffAtTime = useCallback(
    (date?: MaterialUiPickersDate) => {
      if (!!date && isValid(date)) {
        const clockedOnAt = getValues('patch.clockedOnAt');

        if (isBefore(date, clockedOnAt)) {
          setError('patch.clockedOffAt', {
            message: "Can't occur before clock on",
          });
        } else {
          const currentDate = new Date(getValues('patch.clockedOffAt'));
          currentDate.setHours(date.getHours(), date.getMinutes());
          setValue('patch.clockedOffAt', currentDate);
        }
      }
    },
    [getValues, setValue, setError],
  );

  useEffect(() => {
    if (lastToggledCheckbox === 'billable') {
      if (billable && !payable) {
        // Set payable to true whenever billable equals true.
        setValue('patch.payable', true);
      }
    } else if (lastToggledCheckbox === 'payable') {
      if (billable && !payable) {
        // Set billable to false whenever payable equals false.
        setValue('patch.billable', false);
      }
    }
  }, [billable, payable, setValue, lastToggledCheckbox]);

  // Check for public holidays
  useEffect(() => {
    if (
      region &&
      startAtWatched &&
      endAtWatched &&
      isValid(startAtWatched) &&
      isValid(endAtWatched) &&
      isBefore(startAtWatched, endAtWatched)
    )
      getPublicHolidays({
        variables: {
          input: {
            where: {
              date: {
                _in: eachDayOfInterval({
                  start: startAtWatched,
                  end: subMilliseconds(endAtWatched, 1),
                }).map((date) => format(date, 'yyyy-MM-dd')),
              },
              _or: [{ region: null }, { region: { _eq: region } }],
            },
            orderBy: [{ date: OrderBy.ASC }],
          },
        },
      });
  }, [getPublicHolidays, region, startAtWatched, endAtWatched]);

  return (
    <FormModal
      modalProps={modalProps}
      title="Edit shift"
      hasErrors={!!Object.keys(errors).length}
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onClose={() => {
        onClose();
        closeSummaryPage();
      }}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.groups}>
          {response.error && (
            <Typography className={classes.error}>
              {response.error.message}
            </Typography>
          )}
          <Box className={classes.group}>
            <Typography className={classes.bold}>Passive overnight</Typography>
            <Box className={classes.passive}>
              <Box className={classes.groups}>
                <Box className={classes.group}>
                  <Checkbox
                    control={control}
                    name="patch.passive"
                    label="This is an overnight shift"
                    onChange={handleTogglePassive}
                    color="primary"
                    error={!!errors.patch?.passive}
                    helperText={errors.patch?.passive?.message}
                  />
                </Box>
                {passive && (
                  <>
                    <Box className={classes.group}>
                      <Typography className={classes.bold}>
                        Start of 8 hour passive period
                      </Typography>
                      <Box className={classes.dates}>
                        <DateInput
                          name="patch.passiveStartAt"
                          control={control}
                          inputVariant="outlined"
                          size="small"
                          onChange={handleChangePassiveStartAt}
                          error={!!errors.patch?.passiveStartAt}
                          helperText={errors.patch?.passiveStartAt?.message}
                        />
                        <TimeInput
                          keyboard
                          name="patch.passiveStartAtTime"
                          control={control}
                          inputVariant="outlined"
                          size="small"
                          onChange={(date) => {
                            if (isValid(date))
                              handleChangePassiveStartAtTime(date);
                          }}
                          inputProps={{ style: { textAlign: 'center' } }}
                          error={!!errors.patch?.passiveStartAt}
                        />
                      </Box>
                    </Box>
                    <Box className={classes.group}>
                      <Checkbox
                        control={control}
                        name="patch.activeAssist"
                        label="Passive period is active-assist"
                        color="primary"
                        error={!!errors.patch?.activeAssist}
                        helperText={errors.patch?.activeAssist?.message}
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>From</Typography>
            <Box className={classes.dates}>
              <DateInput
                required
                name="patch.startAt"
                control={control}
                inputVariant="outlined"
                size="small"
                onChange={handleChangeStartAt}
                error={!!errors.patch?.startAt}
                helperText={errors.patch?.startAt?.message}
              />
              <TimeInput
                required
                keyboard
                name="patch.startAtTime"
                control={control}
                inputVariant="outlined"
                size="small"
                onChange={(date) => {
                  if (isValid(date)) handleChangeStartAtTime(date);
                }}
                inputProps={{ style: { textAlign: 'center' } }}
                error={!!errors.patch?.startAt}
              />
              {!getValues('patch.passive') &&
                !!event.client.defaultEventDuration && (
                  <EventSetToDefaultDurationButton
                    onClick={() => {
                      handleChangeEndAt(
                        event.client.defaultEventDuration!.toString(),
                      );
                    }}
                  />
                )}
            </Box>
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>To</Typography>
            <Box className={classes.dates}>
              <DateInput
                required
                name="patch.endAt"
                control={control}
                inputVariant="outlined"
                size="small"
                onChange={handleChangeEndAt}
                error={!!errors.patch?.endAt}
                helperText={errors.patch?.endAt?.message}
              />
              <TimeInput
                required
                keyboard
                name="patch.endAtTime"
                control={control}
                inputVariant="outlined"
                size="small"
                onChange={(date) => {
                  if (isValid(date)) handleChangeEndAtTime(date);
                }}
                inputProps={{ style: { textAlign: 'center' } }}
                error={!!errors.patch?.endAt}
              />
              <EventDurationInput
                name="patch.duration"
                control={control}
                onChange={(e) => handleChangeEndAt(e.target.value)}
                variant="outlined"
                size="small"
                inputProps={{ style: { textAlign: 'center' } }}
                error={!!errors.patch?.duration}
                helperText={errors.patch?.duration?.message}
              />
            </Box>
          </Box>
          <Box className={classes.group}>
            {!!claims.length && (
              <>
                <Typography className={classes.bold}>Shift Claims</Typography>
                <Table showIndexColumn>
                  <TableHeader>Employee</TableHeader>
                  <TableHeader>Hours this week</TableHeader>
                  {claims.map((claim, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        {!claim.member.schedulable && (
                          <WarningIcon color="error" fontSize="small" />
                        )}
                        <Link
                          to={`${_peopleMembers.path}/${claim.member.id}`}
                          target="_blank"
                        >
                          {formatPersonName(claim.member, {
                            capitaliseLastName: true,
                            lastNameFirst: true,
                          })}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {roundNumber(
                          (claim.member.hours.find(
                            ({ date, type }) =>
                              date ===
                                formatISO(
                                  startOfWeek(new Date(), { weekStartsOn: 1 }),
                                  {
                                    representation: 'date',
                                  },
                                ) && type === MemberHoursType.SCHEDULED,
                          )?.value ?? 0) / 60,
                          2,
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </>
            )}
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>
              Support worker
              {event.locked &&
                !permissible({ admin: true }) &&
                ' (Locked by admin)'}
            </Typography>
            <EventMemberInput
              disabled={!!event.locked && !permissible({ admin: true })}
              control={control}
              name="patch.member.id"
              chipProps={{ onDelete: () => setValue('patch.member.id', '') }}
              formControlProps={{ variant: 'outlined', size: 'small' }}
              watch={watch}
              className={classes.select}
              clientId={event.client.id}
              startAt={startAtWatched}
              endAt={endAtWatched}
              error={!!errors.patch?.member}
              requirements={{ car: requireCar }}
              setTime={setTime}
            />
          </Box>
          {!!event.clockedOnAt && permissible({ tester: true }) && (
            <Box className={classes.group}>
              <Typography className={classes.bold}>Clocked On</Typography>
              <Box className={classes.dates}>
                <DateInput
                  required
                  name="patch.clockedOnAt"
                  control={control}
                  inputVariant="outlined"
                  size="small"
                  onChange={handleChangeClockedOnAtTime}
                  error={!!errors.patch?.clockedOnAt}
                  helperText={errors.patch?.clockedOnAt?.message}
                />
                <TimeInput
                  required
                  keyboard
                  name="patch.clockedOnAtTime"
                  control={control}
                  inputVariant="outlined"
                  size="small"
                  onChange={(date) => {
                    if (isValid(date)) handleChangeClockedOnAtTime(date);
                  }}
                  inputProps={{ style: { textAlign: 'center' } }}
                  error={!!errors.patch?.clockedOnAt}
                  helperText={errors.patch?.clockedOnAt?.message}
                />
                <Button
                  variant="contained"
                  style={{
                    fontSize: 11,
                    lineHeight: 1.273,
                    backgroundColor: unsetClockedOn ? yellow[700] : undefined,
                  }}
                  onClick={() => {
                    setValue('unsetClockedOn', true);
                  }}
                >
                  {unsetClockedOn ? 'Deleting On Save' : 'Delete'}
                </Button>
              </Box>
            </Box>
          )}
          {!!event.clockedOffAt && permissible({ tester: true }) && (
            <Box className={classes.group}>
              <Typography className={classes.bold}>Clocked Off</Typography>
              <Box className={classes.dates}>
                <DateInput
                  required
                  name="patch.clockedOffAt"
                  control={control}
                  inputVariant="outlined"
                  size="small"
                  onChange={handleChangeClockedOffAtTime}
                  error={!!errors.patch?.clockedOffAt}
                  helperText={errors.patch?.clockedOffAt?.message}
                />
                <TimeInput
                  required
                  keyboard
                  name="patch.clockedOffAtTime"
                  control={control}
                  inputVariant="outlined"
                  size="small"
                  onChange={(date) => {
                    if (isValid(date)) handleChangeClockedOffAtTime(date);
                  }}
                  inputProps={{ style: { textAlign: 'center' } }}
                  error={!!errors.patch?.clockedOffAt}
                  helperText={errors.patch?.clockedOffAt?.message}
                />
                <Button
                  variant="contained"
                  style={{
                    fontSize: 11,
                    lineHeight: 1.273,
                    backgroundColor: unsetClockedOff ? yellow[700] : undefined,
                  }}
                  onClick={() => {
                    setValue('unsetClockedOff', true);
                  }}
                >
                  {unsetClockedOff ? 'Deleting On Save' : 'Delete'}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        <Box className={classes.groups}>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Address</Typography>
            <AddressInput
              name="patch.address"
              control={control}
              clearErrors={clearErrors}
              setValue={setValue}
              setError={setError}
              error={!!errors.patch?.address}
              className={classes.address}
              // helperText={errors.patch?.address?.message}
              placeholder="Address"
            />
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>
              Message for the support worker
            </Typography>
            <Textarea
              name="patch.summary"
              control={control}
              minRows={3}
              placeholder="Short optional summary of the shift."
              className={classes.textarea}
              // error={!!errors.patch?.summary}
              // helperText={errors.patch?.summary?.message}
              validation={{
                maxLength: {
                  value: 1000,
                  message: 'Exceeding 1000 characters',
                },
              }}
            />
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Scheduling notes</Typography>
            <Textarea
              name="patch.scheduleNotes"
              control={control}
              minRows={3}
              placeholder="These notes are only visible to schedulers."
              className={classes.textarea}
              // error={!!errors.patch?.scheduleNotes}
              // helperText={errors.patch?.scheduleNotes?.message}
              validation={{
                maxLength: {
                  value: 2000,
                  message: 'Exceeding 1000 characters',
                },
              }}
            />
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Public Holiday</Typography>
            {!region || !startAtWatched || !endAtWatched ? (
              <Typography>Please set address, dates and times.</Typography>
            ) : publicHolidaysResponse.loading ? (
              <Typography>Checking for public holidays...</Typography>
            ) : publicHolidaysResponse.data?.publicHolidays.length ? (
              <Typography>
                This event occurs during{' '}
                {pluralise({
                  singular: 'a public holiday',
                  plural: 'multiple public holidays',
                  quantity: publicHolidaysResponse.data?.publicHolidays.length,
                })}
                :{' '}
                {intersperse({
                  array: publicHolidaysResponse.data.publicHolidays.map(
                    ({ description, region }) =>
                      `${description}${region ? ' (' + region + ')' : ''}`,
                  ),
                })}
              </Typography>
            ) : (
              <>
                <Typography variant="body2">
                  No state-wide public holidays found.
                </Typography>
                <Checkbox
                  control={control}
                  name="patch.publicHoliday"
                  label="This event occurs during a local public holiday"
                  error={!!errors.patch?.publicHoliday}
                  helperText={errors.patch?.publicHoliday?.message}
                />
              </>
            )}
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Options</Typography>
            <EventRequireCarCheckbox
              name="patch.requireCar"
              defaultChecked={event.requireCar}
              control={control}
              error={!!errors.patch?.requireCar}
              helperText={errors.patch?.requireCar?.message}
            />
            <Box>
              <EventBillClientCheckbox
                name="patch.billable"
                defaultChecked={event.billable}
                control={control}
                error={!!errors.patch?.billable}
                helperText={errors.patch?.billable?.message}
                onClick={() => {
                  setLastToggleCheckbox('billable');
                }}
              />
              <EventBillingRegistrationGroupInput
                id="patch.billingRegistrationGroup"
                name="patch.billingRegistrationGroup"
                control={control}
                error={!!errors.patch?.billingRegistrationGroup}
                helperText={errors.patch?.billingRegistrationGroup?.message}
                formControlProps={{ size: 'small', variant: 'outlined' }}
                style={{ width: 220 }}
              />
            </Box>
            <Box>
              <EventPayAttendeeCheckbox
                name="patch.payable"
                defaultChecked={event.payable}
                control={control}
                error={!!errors.patch?.payable}
                helperText={errors.patch?.payable?.message}
                onClick={() => {
                  setLastToggleCheckbox('payable');
                }}
              />
              <Protected admin>
                <EventBonusPayInput
                  id="patch.bonusPay"
                  name="patch.bonusPay"
                  control={control}
                  variant="outlined"
                  size="small"
                  style={{ width: 95 }}
                  error={!!errors.patch?.bonusPay}
                  helperText={errors.patch?.bonusPay?.message}
                />
              </Protected>
            </Box>
            <Protected admin>
              <Checkbox
                control={control}
                name="patch.locked"
                defaultChecked={!!event.locked}
                label="Lock shift"
                error={!!errors.patch?.locked}
                helperText={errors.patch?.locked?.message}
              />
            </Protected>
          </Box>
        </Box>
      </Box>
    </FormModal>
  );
};

export default EventUpdateFormModal;

import { AddressFullFragment } from '@timed/gql';

const FormattedAddress = ({
  unit,
  buildingName,
  street,
  locality,
  region,
  postcode,
  country,
  showPostcode,
  showCountry,
  showComma,
  defaultText,
}: AddressFullFragment & {
  showPostcode?: boolean;
  showCountry?: boolean;
  showComma?: boolean;
  defaultText?: string;
}) => {
  const line1: string[] = [];
  const line2: string[] = [];

  if (!!unit || street) line1.push(`${!!unit ? 'U' + unit : ''}`);
  if (!!street) line1.push(street);
  if (!!locality) line2.push(locality);
  if (!!showComma && locality && region) line2.push(', ');
  if (!!region) line2.push(region);
  if (!!showPostcode && !!postcode) line2.push(postcode);
  if (!!showCountry && !!country) line2.push(country);

  return !!buildingName || !!line1.length || !!line2.length ? (
    <div>
      {!!line1.length && (
        <div>
          {!!buildingName && buildingName + (line1.length ? ', ' : '')}
          {line1.join(' ')}
        </div>
      )}
      {!!line2.length && <div>{line2.join(' ').replace(' ,', ',')}</div>}
    </div>
  ) : !!defaultText ? (
    <div>{defaultText}</div>
  ) : null;
};

export default FormattedAddress;

import { Select, SelectProps } from '@timed/common';
import { RegisterOptions } from 'react-hook-form';

const jurisdictions: string[] = ['National', 'Remote', 'Very Remote'];

export const validateJurisdiction: RegisterOptions = {
  validate: {
    validateJurisdiction: (value) =>
      jurisdictions.includes(value) || 'Invalid value',
  },
};

const JurisdictionInput = (props: Omit<SelectProps, 'items'>) => (
  <Select
    label="Jurisdiction"
    validation={validateJurisdiction}
    items={['National', 'Remote', 'Very Remote'].map((value) => ({
      value,
      label: value,
    }))}
    {...props}
  />
);

export default JurisdictionInput;

import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core';
import { TableCellProps, TableContext, TableRowContext } from '@timed/common';
import clsx from 'clsx';
import _ from 'lodash';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    interactable: {
      cursor: 'pointer',
    },
  }),
);

const TableCell = ({
  children,
  onClick,
  className,
  style,
  indent,
  hidden = false,
  index = 0,
  ...props
}: TableCellProps) => {
  const { headers, showIndexColumn, columnWidths, persistentRowHighlighting } =
    useContext(TableContext);

  const row = useContext(TableRowContext);

  const classes = useStyles();

  const theme = useTheme();

  const header = !!headers?.[index] ? headers[index] : undefined;

  const columnIndex = showIndexColumn ? index + 1 : index;

  let style2 = {
    ...style,
    ..._.pick(header?.props.style, ['textAlign']),
    paddingTop: row.shrunk ? 0 : undefined,
    paddingBottom: row.shrunk ? 0 : undefined,
    paddingRight: row.shrunk ? 0 : undefined,
    paddingLeft: row.shrunk ? 0 : undefined,
    borderBottom: row.shrunk ? 0 : undefined,
  };

  if (!!indent) style2.paddingLeft = theme.spacing(indent * 4);

  if (header?.props.sticky)
    style2 = {
      position: 'sticky',
      zIndex: index + 1,
      left:
        !showIndexColumn && columnIndex === 0
          ? 0 // No offset if this is the first column
          : !!columnWidths.length
          ? columnWidths.slice(0, columnIndex).reduce((a, b) => a + b)
          : 0, // 0 if column widths have yet to be calculated
      ...style2,
    };

  if (!!header?.props.align && header.props.align !== 'left')
    style2 = { textAlign: header.props.align, ...style2 };

  return hidden || header?.props.hidden ? null : (
    <td
      key={columnIndex}
      onClick={onClick}
      className={
        !!onClick || persistentRowHighlighting
          ? clsx(className, classes.interactable)
          : className
      }
      style={style2}
      {...props}
    >
      {children}
    </td>
  );
};

export default TableCell;

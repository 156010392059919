import { TextField, TextFieldProps } from '@timed/common';
import { RegisterOptions } from 'react-hook-form';

export const validatePersonBio: RegisterOptions = {
  maxLength: { value: 255, message: 'Too long' },
};

const PersonBioInput = (props: TextFieldProps) => (
  <TextField type="text" validation={validatePersonBio} {...props} />
);

export default PersonBioInput;

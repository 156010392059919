import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from '@material-ui/core';
import { EntityState } from '@timed/gql';
import { ScheduleContext } from '@timed/schedule/context';
import { ChangeEvent, useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      flexGrow: 1,
      minWidth: theme.spacing(21),
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.default,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.divider,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': {
        fontSize: '1rem',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(0),
          fontSize: 'initial',
        },
      },
    },
  }),
);

const ScheduleStatesSelect = () => {
  const classes = useStyles();

  const { eventStates, setEventStates } = useContext(ScheduleContext);

  const items: { label: string; value: string }[] = [
    { label: 'Normal', value: EntityState.NORMAL },
    { label: 'Archived', value: EntityState.ARCHIVED },
    { label: 'Cancelled', value: EntityState.CANCELLED },
    { label: 'Deleted', value: EntityState.DELETED },
  ];

  return (
    <FormControl variant="outlined" size="small">
      <InputLabel id={'eventStates-label'}>State</InputLabel>
      <Select
        multiple
        className={classes.input}
        defaultValue={eventStates}
        id="eventStates"
        key="eventStates"
        labelId={'eventStates-label' || undefined}
        label="State"
        onChange={(
          event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
        ) => {
          setEventStates(event.target.value as EntityState[]);
        }}
      >
        {Object.values(items).map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ScheduleStatesSelect;

import { _uploadsProcess } from '@timed/app';
import { Page } from '@timed/page';
import { UploadFilesTable } from '@timed/upload/components/FilesTable';

const RouteCoreSupportUploadsProcess = () => {
  return (
    <Page {..._uploadsProcess}>
      <UploadFilesTable />
    </Page>
  );
};

export default RouteCoreSupportUploadsProcess;

import { createStyles, makeStyles, Theme } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import { useMemo } from 'react';

type PaginationProps = {
  size?: 'small' | 'large';
  maxButtonCount?: number;
  limit: number;
  offset: number;
  totalCount?: number;
  action: (offset: number) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      gap: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
    },
    button: {
      height: theme.spacing(6),
      width: theme.spacing(6),
      fontSize: 12,
      border: '1px solid ' + theme.palette.divider,
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      cursor: 'pointer',
      userSelect: 'none' /* Standard syntax */,
      msUserSelect: 'none' /* IE 10 and IE 11 */,
      WebkitUserSelect: 'none' /* Safari */,
    },
    largeButton: {
      height: theme.spacing(8),
      width: theme.spacing(8),
      fontSize: 14,
    },
    activeButton: {
      backgroundColor: theme.palette.secondary.main,
    },
  }),
);

const PaginationNavigation = ({
  action,
  limit,
  offset,
  totalCount = 0,
  maxButtonCount = 5,
  size = 'large',
}: PaginationProps) => {
  const classes = useStyles();

  const className =
    size === 'small'
      ? classes.button
      : clsx(classes.button, classes.largeButton);

  const currentPage = useMemo(
    () => Math.ceil((offset + limit) / limit),
    [offset, limit],
  );

  const totalPages = useMemo(
    () => Math.ceil(totalCount / limit),
    [totalCount, limit],
  );

  const buttonCount = useMemo(
    () => Math.min(Math.ceil(totalCount / limit), maxButtonCount),
    [maxButtonCount, limit, totalCount],
  );

  const currentGroup = useMemo(
    () => Math.trunc(currentPage / maxButtonCount - 0.01) + 1,
    [currentPage, maxButtonCount],
  );

  const handleClick = (page: number) => {
    if (page !== currentPage)
      action((Math.max(Math.min(page, totalPages), 1) - 1) * limit);
  };

  return totalPages > 1 ? (
    <div className={classes.wrapper}>
      {currentGroup !== 1 && (
        <div
          className={className}
          onClick={() => handleClick(currentPage - maxButtonCount)}
        >
          <ChevronLeftIcon />
        </div>
      )}
      {[...Array(buttonCount)].map((_, i) => {
        const page = (currentGroup - 1) * maxButtonCount + i + 1;
        return page <= totalPages ? (
          <div
            key={page}
            className={
              page === currentPage
                ? clsx(className, classes.activeButton)
                : className
            }
            onClick={() => handleClick(page)}
          >
            {page}
          </div>
        ) : null;
      })}
      {currentGroup * maxButtonCount < totalPages && (
        <div
          className={className}
          onClick={() => handleClick(currentPage + maxButtonCount)}
        >
          <ChevronRightIcon />
        </div>
      )}
    </div>
  ) : null;
};

export default PaginationNavigation;

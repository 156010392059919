import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  ClientContextType,
  clientFilesMetadata,
  ClientFileTypeInput,
} from '@timed/client';
import { ClientFileCategory } from '@timed/client/constants';
import {
  addServerErrors,
  DateInput,
  FileInput,
  formatPersonName,
  FormModal,
  MIME,
  ModalProps,
  TextField,
} from '@timed/common';
import { FileMetadata } from '@timed/file';
import {
  ClientFileType,
  CreateClientFileDocument,
  Maybe,
  useCreateClientFileMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type ClientFileCreateFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  client: ClientContextType;
};

type FormData = {
  type: ClientFileType;
  dateOfFile?: Maybe<Date>;
  expiresAt?: Maybe<Date>;
  value?: Maybe<string>;
  attachment: any;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputs: {
      height: 360,
      flex: '1 0 auto',
      overflowY: 'auto',
      display: 'grid',
      gridAutoFlow: 'row',
      gridAutoRows: 'max-content',
      gap: theme.spacing(4),
    },
    textarea: {
      backgroundColor: theme.palette.background.paper,
      width: 256,
      color: theme.palette.text.primary,
      border: '1px solid ' + theme.palette.text.disabled,
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.up('md')]: {
        width: 384,
      },
    },
    buttons: {
      flex: '0 1 max-content',
      display: 'flex',
      justifyContent: 'space-between',
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    info: {
      padding: theme.spacing(2, 4),
      color: theme.palette.text.primary,
      border: '1px solid ' + theme.palette.divider,
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
    },
  }),
);

const ClientFileCreateFormModal = ({
  client,
  onClose,
  ...modalProps
}: ClientFileCreateFormModalProps) => {
  const classes = useStyles();

  const [createFile, response] = useCreateClientFileMutation();
  const [metadata, setMetadata] =
    useState<FileMetadata<ClientFileType, ClientFileCategory>>();

  const {
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { errors },
  } = useForm<FormData>();

  useLoadingEffect(response.loading);

  const onSubmit = (values: FormData) => {
    createFile({
      variables: {
        input: {
          owner: { id: client.id },
          ...values,
        },
      },
    }).catch((e) => {});
  };

  const onSuccess = () => {
    const cache = response.client.cache;

    cache.modify({
      fields: {
        clientFiles(existing = []) {
          return [
            ...existing,
            cache.writeQuery({
              data: response.data,
              query: CreateClientFileDocument,
            }),
          ];
        },
      },
    });
  };

  /**
   * Display error messages from server response
   */
  useEffect(() => {
    if (response.error) addServerErrors(response.error, setError);
  }, [response.error, setError]);

  return (
    <FormModal
      modalProps={modalProps}
      title="File upload"
      description={`Store a file for ${formatPersonName(client)}`}
      saveText="Save"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.inputs}>
        <ClientFileTypeInput
          required
          name="type"
          control={control}
          error={!!errors.type}
          helperText={errors.type?.message}
          formControlProps={{ size: 'small', variant: 'outlined' }}
          onChange={(event) =>
            setMetadata(
              clientFilesMetadata.find(
                (meta) => meta.id === event.target.value,
              )!,
            )
          }
        />
        <FileInput
          required
          name="attachment"
          control={control}
          allowedMimeTypes={[
            MIME.JPG,
            MIME.PNG,
            MIME.PDF,
            MIME.DOCX,
            MIME.XLSX,
            MIME.HEIC,
            MIME.HEIF,
          ]}
          setError={setError}
          setValue={setValue}
          formControlProps={{ size: 'small' }}
          error={!!errors.attachment}
          helperText={errors.attachment?.message?.toString()}
        />
        {metadata?.hasDate && (
          <DateInput
            keyboard
            disableTime
            name="dateOfFile"
            defaultValue={null}
            label="Date Of File"
            control={control}
            inputVariant="outlined"
            size="small"
          />
        )}
        {metadata?.hasExpiryDate && (
          <DateInput
            keyboard
            defaultValue={null}
            name="expiresAt"
            label="Expiry Date"
            control={control}
            inputVariant="outlined"
            size="small"
          />
        )}
        {metadata?.hasNote && (
          <TextField
            name="value"
            variant="outlined"
            size="small"
            label="Value"
            type="text"
            validation={{ maxLength: { value: 255, message: 'Too long' } }}
            control={control}
            error={!!errors.value}
            helperText={errors.value?.message}
          />
        )}
      </Box>
    </FormModal>
  );
};

export default ClientFileCreateFormModal;

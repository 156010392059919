import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Button, ButtonAsync, IconButton, Modal } from '@timed/common';
import { Event, useDeleteEventsMutation } from '@timed/gql';
import { useSnackbar } from 'notistack';
import React from 'react';

type EventDeleteFormModalProps = {
  onClose: () => void;
  eventId: Event['id'];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      padding: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        width: 500,
      },
    },
    title: {
      fontSize: '16px',
      fontWeight: 'bold',
      display: 'grid',
      gridTemplateColumns: 'auto min-content',
      alignItems: 'center',
    },
    buttons: {
      marginTop: theme.spacing(1),
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

const EventDeleteFormModal = ({
  onClose,
  eventId,
}: EventDeleteFormModalProps) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(true);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const [deleteEvents, { data, loading, client }] = useDeleteEventsMutation({
    onCompleted: () => {
      enqueueSnackbar('Entity deleted', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon fontSize="small" style={{ fill: 'white' }} />
          </IconButton>
        ),
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.message ?? 'Error deleting entity', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon style={{ fill: 'white' }} />
          </IconButton>
        ),
      });
    },
  });

  const onSuccess = () => {
    const cache = client.cache;

    data!.deleteEvents.length &&
      cache.evict({
        id: cache.identify({
          __typename: 'Event',
          id: data!.deleteEvents[0].id,
        }),
      });
    cache.gc();
  };

  const onComplete = () => handleClose();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box className={classes.form}>
        <Box className={classes.title}>
          <Typography>Delete event</Typography>
          <IconButton aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Typography>Are you sure you want to delete this event?</Typography>

        <Box className={classes.buttons}>
          <ButtonAsync
            color="primary"
            loading={loading}
            success={!!data}
            onClick={() => {
              deleteEvents({ variables: { input: { ids: [eventId] } } });
            }}
            onComplete={onComplete}
            onSuccess={onSuccess}
          >
            Delete Event
          </ButtonAsync>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EventDeleteFormModal;

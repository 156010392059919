import {
  Box,
  createStyles,
  IconButton,
  Link,
  makeStyles,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {
  Block,
  Button,
  capitaliseFirstLetter,
  formatAddress,
  formatAge,
  formatPersonName,
  formatTimeDuration,
  intersperse,
  Modal,
  ModalProps,
  TextField,
} from '@timed/common';
import {
  AddressFullFragment,
  Client,
  Event,
  Member,
  OrderBy,
  PersonNamesFragment,
  useScheduleControlMembersInputLazyQuery,
} from '@timed/gql';
import {
  addDays,
  differenceInMinutes,
  format,
  formatISO,
  startOfWeek,
} from 'date-fns';
import { useEffect, useMemo } from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import clsx from 'clsx';

type EventAssignAttendeeFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  event: Pick<Event, 'id' | 'startAt' | 'endAt' | 'passive'> &
    AddressFullFragment & { client: Pick<Client, 'id'> & PersonNamesFragment };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        width: 400,
        maxHeight: 1000,
        gap: theme.spacing(4),
        overflowX: 'hidden',
        paddingRight: theme.spacing(4),
      },
    },
    shift: {
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      border: '1px solid ' + yellow[600],
      backgroundColor: yellow[100],
      display: 'grid',
      gridTemplateColumns: 'max-content auto',
      gap: theme.spacing(1),
      alignItems: 'center',
      '& .MuiTypography-root': {
        fontSize: 12,
      },
      '& .MuiTypography-root:nth-child(2n-1)': {
        fontWeight: theme.typography.fontWeightMedium,
        paddingRight: theme.spacing(2),
      },
    },
    search: {},
    people: {
      display: 'flex',
      flexDirection: 'column',
      // overflowY: 'auto',
      gap: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
      },
    },
    personWrapper: {
      // padding: theme.spacing(2),
      // border: '1px solid ' + theme.palette.divider,
      // borderRadius: theme.shape.borderRadius,
      // backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),

      // padding: theme.spacing(1),
      // fontWeight: theme.typography.fontWeightMedium,
      '& .MuiBox-root:first-child': {
        display: 'flex',
        justifyContent: 'space-between',
      },
      '& .MuiTypography-root': {
        // fontWeight: theme.typography.fontWeightRegular,
        fontSize: '90%',
      },
      '& .MuiTypography-root:nth-child(1)': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    person: {
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.default,
    },
    personIdentifiers: {
      // height: 50,
      display: 'flex',
      flexDirection: 'column',
      // gap: theme.spacing(1),
    },
    personPhone: {
      '& .MuiTypography-root': {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: 20,
      },
    },

    suitability: {
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: '16px auto',
      '& .MuiTypography-root:nth-child(0n+1)': {
        textAlign: 'center',
      },
    },
    pro: {
      color: 'green',
      '& .MuiSvgIcon-root': {
        color: 'green',
        width: 12,
        height: 12,
      },
    },
    con: {
      color: 'red',
      '& .MuiSvgIcon-root': {
        color: 'red',
        width: 12,
        height: 12,
      },
    },
    controls: {
      // padding: theme.spacing(1),
      display: 'flex',
      gap: theme.spacing(1),
      textAlign: 'center',
      alignItems: 'center',
      '& .MuiButton-root': {
        // padding: theme.spacing(1, 2),
        flexBasis: '50%',
        fontSize: 10,
      },
    },
  }),
);

const AssignButton = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: '#3bb027',
    '&:hover': {
      backgroundColor: '#297b1b',
    },
  },
}))(Button);

// const NotAvailableButton = withStyles((theme) => ({
//   root: {
//     color: theme.palette.common.white,
//     backgroundColor: '#b0273b',
//     '&:hover': {
//       backgroundColor: '#902030',
//     },
//   },
// }))(Button);

const EventAssignAttendeeFormModal = ({
  onClose,
  event,
  ...modalProps
}: EventAssignAttendeeFormModalProps) => {
  const classes = useStyles();

  // useLoadingEffect(loading);

  const payrollPeriodStartAt = startOfWeek(new Date(event.startAt), {
    weekStartsOn: 1,
  });
  const payrollPeriodEndAt = addDays(new Date(payrollPeriodStartAt), 6);

  const [getMembers, { data, loading }] =
    useScheduleControlMembersInputLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        input: {
          orderBy: [
            { lastName: OrderBy.ASC_NULLS_FIRST },
            { firstName: OrderBy.ASC_NULLS_FIRST },
          ],
          where: { schedulable: { _eq: true } },
        },

        scheduleInput: {
          startAt: formatISO(payrollPeriodStartAt, { representation: 'date' }),
          endAt: formatISO(payrollPeriodEndAt, { representation: 'date' }),
        },

        eventsInput: {
          limit: 1,
          where: {
            startAt: { _lt: event.endAt },
            endAt: { _gt: event.startAt },
          },
        },

        teamsInput: {
          limit: 1,
          where: { client: { id: { _eq: event.client.id } } },
        },

        unavailablesInput: {
          limit: 1,
          where: {
            startAt: { _lt: event.endAt },
            endAt: { _gt: event.startAt },
          },
        },
      },
    });

  /*

  Pros:
  - On preferred list
  - Available


  Cons:
  - Working more than 40 hours this week
  - Is unavailable ()
  - Scheduling conflict: <Date> at <Time> with <Participant>



  */

  type MemberSuitability = Pick<Member, 'id'> & {
    pros: string[];
    cons: string[];
    // uavailablePeriod: Pick<MemberUnavailable, 'notes'>;
    // existingEvents: Pick<Event, 'id' | 'startAt' | 'duration'> & {
    //   client: Pick<Client, 'id'> & PersonNamesFragment;
    // };
  };

  const memberStats = useMemo<MemberSuitability[] | undefined>(() => {
    if (!data) return;

    const members: MemberSuitability[] = [];

    data?.members.forEach((member) => {
      const pros: string[] = [];
      const cons: string[] = [];

      // if (member.unavailables?.length) {
      //   cons.push(
      //     'Unavailable' +
      //       (!!member.unavailables[0].notes ? ': ' + member.unavailables[0].notes : ''),
      //   );
      // }

      // if (member.events?.length) {
      //   cons.push(
      //     'Scheduling conflict: ' +
      //       formatPersonName(member.events[0].client) +
      //       ' at ' +
      //       format(new Date(member.events[0].startAt), 'HH:mm'),
      //   );
      // }

      if (
        member.schedule?.totalMinutes &&
        member.schedule?.totalMinutes +
          differenceInMinutes(new Date(event.endAt), new Date(event.startAt)) >=
          2400
      ) {
        cons.push('Exceeding 40 scheduled hours this week');
      }

      // if (!member.unavailables?.length && !member.events?.length) {
      //   pros.push('Available');
      // }

      // if (member.teams?.length) {
      //   pros.push('Preferred support worker');
      // }

      members.push({ pros, cons, id: member.id });
    });

    return members;

    // return members.sort(
    //   (a, b) => a.pros.length - a.cons.length,
    //   // a.pros.length - a.cons.length > b.pros.length - b.cons.length ? 1 : -1,
    // );

    // return _.sortBy(members);

    // return members.sort((a, b) => a.pros.length - a.cons.length > b.pros.length - b.cons.length);
    // return !data
    //   ? undefined
    //   : data.members.map((member) => ({ id: member.id, pros: ['Is good'], cons: ['Is bad'] }));
  }, [data, event.endAt, event.startAt]);

  useEffect(() => {
    if (!data && !loading) getMembers();
  }, [getMembers, data, loading]);

  const members = useMemo(() => {
    return !!data ? data.members : [];
  }, [data]);

  return (
    <Modal disablePortal onClose={onClose} {...modalProps}>
      <Block
        titleProps={{ variant: 'h2' }}
        title="Assign Worker"
        topRight={
          <IconButton onClick={onClose}>
            <CloseRoundedIcon />
          </IconButton>
        }
      >
        <Box className={classes.wrapper}>
          <Box className={classes.shift}>
            <Typography>Participant</Typography>
            <Typography>
              {formatPersonName(event.client, {
                lastNameFirst: true,
                capitaliseLastName: true,
              })}
            </Typography>
            <Typography>Date</Typography>
            <Typography>
              {format(new Date(event.startAt), "eeee, do 'of' MMMM")}
            </Typography>
            <Typography>Time</Typography>
            <Typography>
              {formatTimeDuration({
                start: new Date(event.startAt),
                end: new Date(event.endAt),
                options: { militaryTime: true },
              })}
              {' ('}
              {differenceInMinutes(
                new Date(event.endAt),
                new Date(event.startAt),
              ) / 60}{' '}
              hours{')'}
            </Typography>
            {event.passive && (
              <>
                <Typography>Passive-overnight</Typography>
                <Typography>Yes</Typography>
              </>
            )}
            <Typography>Address</Typography>
            <Typography>{formatAddress(event)}</Typography>
          </Box>

          <TextField variant="outlined" size="small" placeholder="Search" />

          {!members ? (
            'Loading...'
          ) : (
            <Box className={classes.people}>
              {members
                // Sort by suitability
                // .sort((a, b) => {
                //   // const aStats: MemberSuitability = memberStats?.find(
                //   //   (stats) => stats.id === a.id,
                //   // )!;
                //   // const bStats: MemberSuitability = memberStats?.find(
                //   //   (stats) => stats.id === b.id,
                //   // )!;

                //   return 1;
                //   // return aStats.pros.length - aStats.cons.length >
                //   //   bStats.pros.length - bStats.cons.length
                //   //   ? 1
                //   //   : -1;
                // })
                .map((member) => (
                  <Box className={classes.personWrapper}>
                    <Box className={classes.person}>
                      <Box>
                        <Box className={classes.personIdentifiers}>
                          <Typography variant="body1" color="textPrimary">
                            {formatPersonName(member, {
                              lastNameFirst: true,
                              capitaliseLastName: true,
                            })}
                          </Typography>

                          {(!!member.gender || !!member.dob) && (
                            <Typography variant="body2" color="textSecondary">
                              {intersperse({
                                array: [
                                  !!member.gender &&
                                    capitaliseFirstLetter(
                                      member.gender.toLocaleLowerCase(),
                                    ),
                                  member.dob &&
                                    formatAge(member.dob) + ' years old',
                                ].filter(Boolean),
                                and: false,
                              })}
                            </Typography>
                          )}
                        </Box>

                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className={classes.personPhone}
                        >
                          {!!member.phone ? (
                            <Link
                              href={'tel:' + member.phone.replace(/\s+/g, '')}
                            >
                              {member.phone}
                            </Link>
                          ) : (
                            'No phone number'
                          )}
                        </Typography>
                      </Box>
                    </Box>

                    {memberStats?.map((stat) => {
                      return (
                        stat.id === member.id &&
                        [
                          ...stat.pros.map((pro) => (
                            <Box
                              className={clsx(classes.suitability, classes.pro)}
                            >
                              <AddIcon />
                              <Typography>{pro}</Typography>
                            </Box>
                          )),
                          ...stat.cons.map((con) => (
                            <Box
                              className={clsx(classes.suitability, classes.con)}
                            >
                              <RemoveIcon />
                              <Typography>{con}</Typography>
                            </Box>
                          )),
                        ].map((stat) => stat)
                      );
                    })}

                    <Box className={classes.controls}>
                      <Button variant="contained">Not available</Button>
                      <AssignButton variant="contained">Assign</AssignButton>
                      <AssignButton variant="contained">
                        Assign & SMS
                      </AssignButton>
                    </Box>
                  </Box>
                ))}
            </Box>
          )}
        </Box>
      </Block>
    </Modal>
  );
};

export default EventAssignAttendeeFormModal;

import { json } from '@codemirror/lang-json';
import { CodeInput, CodeInputProps } from '@timed/common';
import { RegisterOptions } from 'react-hook-form';

export const validateJSON: RegisterOptions = {
  validate: {
    validateJSON: (v) => {
      try {
        return !v || JSON.parse(v);
      } catch (e: any) {
        try {
          const words = e.toString().split(' ');
          const [line, column] = [
            words[words.indexOf('line') + 1],
            words[words.indexOf('column') + 1],
          ];
          return `Syntax error at line ${line} column ${column}`;
        } catch (e) {
          return 'Syntax error';
        }
      }
    },
  },
};

type JSONInputProps = Omit<CodeInputProps, 'extensions'>;

const JSONInput = ({ inputProps, ...props }: JSONInputProps) => (
  <CodeInput
    {...props}
    inputProps={{ ...inputProps, extensions: [json()] }}
    validation={validateJSON}
  />
);

export default JSONInput;

import {
  createStyles,
  FormControl,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import { useAuth } from '@timed/auth';
import {
  Chip,
  ChipProps,
  formatPersonName,
  Select,
  SelectProps,
} from '@timed/common';
import {
  EntityState,
  GetClientsSelectQuery,
  GetClientsSelectRedactedQuery,
  GetMembersSelectQuery,
  MembersOrderByInput,
  MembersWhereInput,
  Permission,
  RedactedClientsOrderByInput,
  RedactedClientsWhereInput,
  useGetClientsSelectLazyQuery,
  useGetClientsSelectRedactedLazyQuery,
  useGetMembersSelectLazyQuery,
} from '@timed/gql';
import { useEffect, useMemo } from 'react';
import { UseFormWatch } from 'react-hook-form';

type ProfileInputProps = Omit<SelectProps, 'items'> & {
  chipProps?: ChipProps;
  type: 'client' | 'member';
  watch: UseFormWatch<any>;
  where?: RedactedClientsWhereInput | MembersWhereInput;
  orderBy?: (RedactedClientsOrderByInput | MembersOrderByInput)[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 185,
      backgroundColor: 'white',
    },
    loading: {
      '& .MuiOutlinedInput-root.Mui-disabled': {
        backgroundColor: theme.palette.background.default,
      },
    },
    select: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      height: 18,
    },
    chip: {
      // marginLeft: theme.spacing(0.5),
      height: 20,
    },
  }),
);

function ProfileInput({
  chipProps,
  type,
  where,
  orderBy,
  watch,
  ...props
}: ProfileInputProps) {
  const classes = useStyles();

  const { permissible } = useAuth();

  const watched = watch(props.name);

  const [getMembers, members] = useGetMembersSelectLazyQuery({
    variables: {
      input: {
        where,
        orderBy,
        entityStates: [EntityState.NORMAL, EntityState.ARCHIVED],
      },
    },
  });

  // const [getClients, clients] = useGetClientsSelectLazyQuery({
  //   variables: { input: { where, orderBy } },
  // });

  const [getNormalClients, clientsResponse] = useGetClientsSelectLazyQuery({
    variables: { input: { where, orderBy } },
  });

  const [getRedactedClients, redactedClientsResponse] =
    useGetClientsSelectRedactedLazyQuery({
      variables: { input: { where, orderBy } },
    });

  const [getClients, clients] = useMemo(
    () =>
      permissible({ permissions: Permission.CLIENT_READ })
        ? [getNormalClients, clientsResponse]
        : [getRedactedClients, redactedClientsResponse],
    [
      permissible,
      getNormalClients,
      getRedactedClients,
      clientsResponse,
      redactedClientsResponse,
    ],
  );

  const [data, loading] = useMemo(() => {
    switch (type) {
      case 'client':
        return [clients.data, clients.loading];
      case 'member':
        return [members.data, members.loading];
    }
  }, [type, clients, members]);

  useEffect(() => {
    if (!data && !loading) {
      switch (type) {
        case 'client':
          getClients();
          break;
        case 'member':
          getMembers();
          break;
      }
    }
  }, [data, getMembers, getClients, loading, type]);

  const profileIsMember = (
    profiles:
      | GetMembersSelectQuery
      | GetClientsSelectQuery
      | GetClientsSelectRedactedQuery,
  ): profiles is GetMembersSelectQuery => profiles.hasOwnProperty('members');

  const profileIsClient = (
    profiles:
      | GetMembersSelectQuery
      | GetClientsSelectQuery
      | GetClientsSelectRedactedQuery,
  ): profiles is GetClientsSelectQuery => profiles.hasOwnProperty('clients');

  const profileIsRedactedClient = (
    profiles:
      | GetMembersSelectQuery
      | GetClientsSelectQuery
      | GetClientsSelectRedactedQuery,
  ): profiles is GetClientsSelectRedactedQuery =>
    profiles.hasOwnProperty('redactedClients');

  const profiles = useMemo(() => {
    if (!data) return [];
    else if (profileIsMember(data)) return data.members;
    else if (profileIsClient(data)) return data.clients;
    else if (profileIsRedactedClient(data)) return data.redactedClients;
    else return [];
  }, [data]);

  const selected = useMemo(() => {
    if (!data) return null;
    else if (profileIsMember(data))
      return data.members.find(({ id }) => id === watched);
    else if (profileIsClient(data))
      return data.clients.find(({ id }) => id === watched);
    else if (profileIsRedactedClient(data))
      return data.redactedClients.find(({ id }) => id === watched);
    else return null;
  }, [data, watched]);

  if (loading || !data) return <>Loading...</>;

  const profileList = profiles
    ? profiles.map((item: any) => {
        return {
          value: item.id!,
          label: formatPersonName(item, {
            capitaliseLastName: true,
            lastNameFirst: true,
            postNominals: true,
          })!,
        };
      })
    : undefined;

  return loading ? (
    <FormControl className={classes.loading}>
      <TextField
        disabled
        label={type}
        variant="outlined"
        size="small"
        value="Loading..."
      />
    </FormControl>
  ) : (
    <Select
      items={profileList}
      renderValue={() =>
        selected && (
          <div className={classes.chips}>
            <Chip
              size="small"
              color="primary"
              label={formatPersonName(selected!, {
                capitaliseLastName: true,
                lastNameFirst: true,
                postNominals: true,
              })}
              className={classes.chip}
              onMouseDown={(event: any) => event.stopPropagation()}
              {...chipProps}
            />
          </div>
        )
      }
      {...props}
    />
  );
}

export default ProfileInput;

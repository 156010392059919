import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useAuth } from '@timed/auth';
import { ClientAutocomplete } from '@timed/client';
import {
  AutocompleteOption,
  Block,
  Button,
  Checkbox,
  DateInput,
  formatPersonName,
  InvisibleTextField,
  jsDateToLocalISO8601DateString,
  JurisdictionInput,
  roundNumber,
  Select,
  TextField,
  useRouter,
} from '@timed/common';
import { formatCurrency } from '@timed/common/utils/formatCurrency';
import {
  AddressRegionCode,
  Client,
  OrderBy,
  PersonNamesFragment,
  useReportParticipantQuoteGetInitialDataQuery,
  useReportParticipantQuoteGetPublicHolidaysLazyQuery,
} from '@timed/gql';
import clsx from 'clsx';
import {
  addDays,
  eachDayOfInterval,
  format,
  isBefore,
  isFriday,
  isMonday,
  isSaturday,
  isSunday,
  isThursday,
  isTuesday,
  isValid,
  isWednesday,
  isWeekend,
  isWithinInterval,
  subMilliseconds,
} from 'date-fns';
import { capitalize } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import ReactToPrint from 'react-to-print';

type ParticipantReportItem = {
  activityId: string | null;
  /**
   * Number of hours in the period.
   */
  weekHours: number | null;
  mondayHours: number | null;
  tuesdayHours: number | null;
  wednesdayHours: number | null;
  thursdayHours: number | null;
  fridayHours: number | null;
};

type FormData = {
  from: Date | null;
  to: Date | null;
  client?: Pick<Client, 'id'>;
  clientName?: string;
  clientNdisId?: string;
  includeTransport: boolean;
  includePublicHolidays: boolean;
  combineSupportCategories: boolean;
  jurisdiction: 'National' | 'Remote' | 'Very Remote';
  services: Array<ParticipantReportItem>;
  branchName: string;
  phone: string;
  email: string;
};

type ActivityIdData = {
  code: string;
  frequency: 'daily' | 'weekly';
  dateFilterFn: (date: Date) => boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    medium: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    purpleText: {
      color: theme.palette.primary.main,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    block: {
      backgroundColor: theme.palette.background.paper2,
    },
    inputs: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    inputGroup: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridAutoColumns: 'max-content',
      gap: theme.spacing(4),
      alignItems: 'center',
    },
    activity: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.25),
      '& .MuiTypography-root:first-child': {
        fontSize: 11,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    printWrapper: {
      padding: theme.spacing(16),
      backgroundColor: theme.palette.common.white,
      width: 950,
    },
    print: {
      fontSize: 12,
      colorAdjust: 'exact',
      WebkitPrintColorAdjust: 'exact',
      width: 822,
      display: 'flex',
      alignItems: 'start',
      flexFlow: 'column',
      gap: theme.spacing(8),
      overflow: 'auto',
    },
    header: {
      width: '100%',
      display: 'grid',
      gridAutoFlow: 'column',
      gridAutoColumns: 'max-content',
      justifyContent: 'space-between',
      alignItems: 'start',
      '& input': {
        fontSize: 9,
      },
    },
    printText: {
      flex: '1 0 100%',
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(0.5),
      alignItems: 'start',
    },
    printTextGroup: {
      flex: '1 0 auto',
      // flex: '1 0 auto',
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(6),
      alignItems: 'start',
    },
    printTextTable: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      rowGap: theme.spacing(0.5),
      columnGap: theme.spacing(4),
    },
    textGroup: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.5),
    },
    address: {
      display: 'flex',
      flexFlow: 'column',
      gap: 1,
      '& .MuiTypography-root': {
        fontSize: 11,
      },
    },
    supportsTable: {
      width: 'max-content',
      display: 'grid',
      gridTemplateColumns: '220px 60px 160px 80px 150px 150px',
      borderTop: '2px solid black',
      borderLeft: '2px solid black',
    },
    hoursTable: {
      width: 'max-content',
      display: 'grid',
      gridTemplateColumns: '120px 100px 100px 100px 100px 100px 100px 100px',
      borderTop: '2px solid black',
      borderLeft: '2px solid black',
    },
    tabelHeader: {
      color: 'white',
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: '#3C5291',
    },
    tableCell: {
      padding: theme.spacing(1),
      borderRight: '2px solid black',
      borderBottom: '2px solid black',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
    },
  }),
);

const ReportParticipantQuote = () => {
  const classes = useStyles();

  const { branch } = useAuth();

  const printRef = useRef<HTMLDivElement | null>(null);

  const {
    search: [searchParams],
  } = useRouter();

  const {
    control,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: {
      from: null,
      to: null,
      jurisdiction: 'National',
      phone: '13 77 77',
    },
  });

  // Update branch-specific fields
  useEffect(() => {
    if (branch) {
      setValue('branchName', branch.name);
      setValue(
        'email',
        `${branch?.name.toLowerCase()}@australiancarers.com.au`,
      );
    }
  }, [branch, setValue]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'services',
  });

  const services = useWatch({ control, name: 'services' });

  const selectedClient = watch('client');

  const clientName = watch('clientName');

  const clientNdisId = watch('clientNdisId');

  const from = watch('from');

  const to = watch('to');

  const jurisdiction = watch('jurisdiction');

  const includeTransport = watch('includeTransport');

  const includePublicHolidays = watch('includePublicHolidays');

  const combineSupportCategories = watch('combineSupportCategories');

  // const selectedClientRegion =

  // const address = useMemo(() => {
  //   switch (branch?.name) {
  //     default:
  //     case 'Sydney':
  //       return [
  //         'Suite 6.11',
  //         '8 Elizabeth Macarthur Drive',
  //         'Bella Vista NSW 2153',
  //       ];

  //     case 'Rockhampton':
  //       return ['Ground Floor', '39 East Street', 'Rockhampton QLD 4700'];

  //     case 'Adelaide':
  //       return ['Level 8', '33 Franklin Street', 'Adelaide SA 5000'];
  //   }
  // }, [branch]);

  const [client, setClient] = useState<AutocompleteOption<
    Pick<Client, 'id' | 'ndisId' | 'color'> & PersonNamesFragment
  > | null>(null);

  const activityIdData: ActivityIdData[] = useMemo(
    () => [
      // Weekday daytime
      {
        code: '01_011_0107_1_1',
        frequency: 'daily',
        dateFilterFn: (date) =>
          !isWeekend(date) &&
          isWithinInterval(date, {
            start: new Date(date).setHours(6),
            end: new Date(date).setHours(20),
          }),
      },
      // Weekday evening
      {
        code: '01_015_0107_1_1',
        frequency: 'daily',
        dateFilterFn: (date) =>
          !isWeekend(date) &&
          isWithinInterval(date, {
            start: new Date(date).setHours(20),
            end: addDays(new Date(date), 1).setHours(0),
          }),
      },
      // Weekday night
      {
        code: '01_002_0107_1_1',
        frequency: 'daily',
        dateFilterFn: (date) =>
          !isWeekend(date) &&
          isWithinInterval(date, {
            start: new Date(date).setHours(0),
            end: new Date(date).setHours(6),
          }),
      },
      // Saturday
      {
        code: '01_013_0107_1_1',
        frequency: 'weekly',
        dateFilterFn: isSaturday,
      },
      // Sunday
      { code: '01_014_0107_1_1', frequency: 'weekly', dateFilterFn: isSunday },

      // Weekday evening
      {
        code: '04_103_0125_6_1',
        frequency: 'daily',
        dateFilterFn: (date) =>
          !isWeekend(date) &&
          isWithinInterval(date, {
            start: new Date(date).setHours(20),
            end: addDays(new Date(date), 1).setHours(0),
          }),
      },
      // Weekday daytime
      {
        code: '04_104_0125_6_1',
        frequency: 'daily',
        dateFilterFn: (date) =>
          !isWeekend(date) &&
          isWithinInterval(date, {
            start: new Date(date).setHours(6),
            end: new Date(date).setHours(20),
          }),
      },
      // Saturday
      {
        code: '04_105_0125_6_1',
        frequency: 'weekly',
        dateFilterFn: isSaturday,
      },
      // Sunday
      { code: '04_106_0125_6_1', frequency: 'weekly', dateFilterFn: isSunday },
    ],
    [],
  );

  const { data } = useReportParticipantQuoteGetInitialDataQuery({
    fetchPolicy: 'network-only',
    variables: {
      clientsInput: {
        where: !!branch
          ? {
              branch: {
                id: {
                  _eq: branch.id,
                },
              },
            }
          : undefined,
        orderBy: [{ lastName: OrderBy.ASC }, { firstName: OrderBy.ASC }],
      },
      ndisActivitiesInput: {
        orderBy: [{ description: OrderBy.ASC }],
      },
    },
  });

  const [getPublicHolidays, publicHolidaysResponse] =
    useReportParticipantQuoteGetPublicHolidaysLazyQuery({
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    if (!!from && !!to && isValid(from) && isValid(to) && isBefore(from, to))
      getPublicHolidays({
        variables: {
          input: {
            where: {
              _or: [
                { region: { _eq: null } },
                { region: { _eq: AddressRegionCode.NSW } },
              ],
              date: {
                _gte: jsDateToLocalISO8601DateString(from),
                _lte: jsDateToLocalISO8601DateString(subMilliseconds(to, 1)),
              },
            },
          },
        },
      });
  }, [from, to, getPublicHolidays]);

  const datesInInterval = useMemo<Date[] | null>(
    () =>
      !!from && !!to && isValid(from) && isValid(to) && isBefore(from, to)
        ? eachDayOfInterval({ start: from, end: to })
        : null,
    [from, to],
  );

  const publicHolidays = useMemo<string[]>(
    () =>
      !publicHolidaysResponse.data?.publicHolidays.length
        ? []
        : publicHolidaysResponse.data.publicHolidays.map(({ date }) => date),
    [publicHolidaysResponse.data?.publicHolidays],
  );

  type CalculatedService = {
    description: string;
    totalHours: number;
    code: string;
    rate: string;
    total: number;
  };

  useEffect(() => {
    if (client?.value.id !== selectedClient?.id)
      setValue('client', client?.value);
  }, [client, selectedClient?.id, setValue]);

  const calculatedServices = useMemo<CalculatedService[] | null>(() => {
    if (!datesInInterval || !selectedClient || !services?.length) return null;

    const publicHolidayMatches: { date: Date; hours: number }[] = [];

    const nonPubHolServices = services
      .filter(
        ({ activityId, ...hours }) =>
          !!Object.values(hours).some((value) => !!value) &&
          !!activityId &&
          !!activityIdData.find(
            ({ code }) =>
              code ===
              data?.ndisActivities.find(({ id }) => id === activityId)?.code,
          ) &&
          !!data?.clients.find(({ id }) => id === selectedClient.id),
      )
      .map(({ activityId, ...hours }) => {
        const activity = data?.ndisActivities.find(
          ({ id }) => id === activityId,
        )!;

        const { code, frequency, dateFilterFn } = activityIdData.find(
          ({ code }) => code === activity.code,
        )!;

        let totalHours = 0;

        const checkPublicHoliday = (date: Date, hours: number): boolean => {
          if (publicHolidays.includes(jsDateToLocalISO8601DateString(date))) {
            publicHolidayMatches.push({ date, hours });
            return false;
          }

          return true;
        };

        switch (code) {
          case '04_103_0125_6_1': // weekday evening
          case '04_104_0125_6_1': // weekday daytime
          case '01_011_0107_1_1': // weekday daytime
          case '01_015_0107_1_1': // weekday evening
          case '01_002_0107_1_1': // weekday night
            totalHours =
              datesInInterval.filter(
                (date) =>
                  isMonday(date) &&
                  !!hours.mondayHours &&
                  checkPublicHoliday(date, hours.mondayHours),
              ).length *
                (hours.mondayHours ?? 0) +
              datesInInterval.filter(
                (date) =>
                  isTuesday(date) &&
                  !!hours.tuesdayHours &&
                  checkPublicHoliday(date, hours.tuesdayHours),
              ).length *
                (hours.tuesdayHours ?? 0) +
              datesInInterval.filter(
                (date) =>
                  isWednesday(date) &&
                  !!hours.wednesdayHours &&
                  checkPublicHoliday(date, hours.wednesdayHours),
              ).length *
                (hours.wednesdayHours ?? 0) +
              datesInInterval.filter(
                (date) =>
                  isThursday(date) &&
                  !!hours.thursdayHours &&
                  checkPublicHoliday(date, hours.thursdayHours),
              ).length *
                (hours.thursdayHours ?? 0) +
              datesInInterval.filter(
                (date) =>
                  isFriday(date) &&
                  !!hours.fridayHours &&
                  checkPublicHoliday(date, hours.fridayHours),
              ).length *
                (hours.fridayHours ?? 0);
            break;

          case '01_013_0107_1_1': // Saturday
          case '04_105_0125_6_1': // Saturday
            totalHours =
              datesInInterval.filter(
                (date) =>
                  isSaturday(date) &&
                  !!hours.weekHours &&
                  checkPublicHoliday(date, hours.weekHours),
              ).length * (hours.weekHours ?? 0);
            break;

          case '01_014_0107_1_1': // Sunday
          case '04_106_0125_6_1': // Sunday
            totalHours =
              datesInInterval.filter(
                (date) =>
                  isSunday(date) &&
                  !!hours.weekHours &&
                  checkPublicHoliday(date, hours.weekHours),
              ).length * (hours.weekHours ?? 0);
            break;
        }

        let rate = 0;

        switch (jurisdiction) {
          case 'National':
            rate = activity.rateNational;
            break;
          case 'Remote':
            rate = activity.rateRemote;
            break;
          case 'Very Remote':
            rate = activity.rateVeryRemote;
            break;
        }

        return {
          totalHours,
          code: activity.code,
          description: activity.description,
          rate: formatCurrency(roundNumber(rate / 100, 2), {
            decimals: 2,
          }),
          total: roundNumber(totalHours * (rate / 100), 2),
        };
      });

    if (!!publicHolidayMatches.length) {
      const activity = data?.ndisActivities.find(
        // ({ code }) => code === '04_102_0125_6_1',
        ({ code }) => code === '01_012_0107_1_1',
      )!;

      const totalHours = publicHolidayMatches
        .map(({ hours }) => Number(hours))
        .reduce((a, b) => a + b);

      let rate = 0;

      switch (jurisdiction) {
        case 'National':
          rate = activity.rateNational;
          break;

        case 'Remote':
          rate = activity.rateRemote;
          break;

        case 'Very Remote':
          rate = activity.rateVeryRemote;
          break;
      }

      if (includePublicHolidays)
        nonPubHolServices.push({
          totalHours,
          total: roundNumber(totalHours * (rate / 100), 2),
          rate: formatCurrency(roundNumber(rate / 100, 2), { decimals: 2 }),
          // code: '04_102_0125_6_1',
          code: '01_012_0107_1_1',
          // description:
          // 'Access Community Social and Rec Activ - Standard - Public Holiday',
          description:
            'Assistance With Self-Care Activities - Standard - Public Holiday',
        });
    }

    if (combineSupportCategories) {
      const selfCareActivies = nonPubHolServices.filter(({ description }) =>
        description.includes('Assistance With Self-Care Activities'),
      );
      const communityAccessActivies = nonPubHolServices.filter(
        ({ description }) =>
          description.includes('Access Community Social and Rec Activ'),
      );

      return [
        selfCareActivies.length
          ? {
              description: 'Assistance With Self-Care Activities - Standard',
              code: '',
              rate: '',
              total: selfCareActivies
                .map(({ total }) => total)
                .reduce((a, b) => a + b),
              totalHours: selfCareActivies
                .map(({ totalHours }) => totalHours)
                .reduce((a, b) => a + b),
            }
          : undefined,
        communityAccessActivies.length
          ? {
              description: 'Access Community Social and Rec Activ - Standard',
              code: '',
              rate: '',
              total: communityAccessActivies
                .map(({ total }) => total)
                .reduce((a, b) => a + b),
              totalHours: communityAccessActivies
                .map(({ totalHours }) => totalHours)
                .reduce((a, b) => a + b),
            }
          : undefined,
      ].filter(Boolean) as CalculatedService[];
    }

    return nonPubHolServices;
  }, [
    combineSupportCategories,
    includePublicHolidays,
    publicHolidays,
    datesInInterval,
    activityIdData,
    selectedClient,
    services,
    jurisdiction,
    data?.ndisActivities,
    data?.clients,
  ]);

  /**
   * Set client if specified in searchParams.
   */
  useEffect(() => {
    if (
      !!searchParams.get('c') &&
      !!data?.clients.find((c) => c.id === searchParams.get('c'))
    )
      setClient({
        label: formatPersonName(
          data.clients.find((c) => c.id === searchParams.get('c'))!,
          { lastNameFirst: true, capitaliseLastName: true },
        ),
        value: data.clients.find((c) => c.id === searchParams.get('c'))!,
      });
  }, [data, searchParams, setValue]);

  type WeekHours = {
    description: string;
    mon: number;
    tue: number;
    wed: number;
    thu: number;
    fri: number;
    sat: number;
    sun: number;
  };

  const weekHours: WeekHours[] = useMemo<WeekHours[]>(() => {
    if (!services?.length) return [];

    const weekDayActivityIds = [
      '2a5367ff-9f3c-486d-82b4-129ae3c40f4d',
      '3d7d1050-6ac9-43f5-816b-92fa42224102',
    ];
    const eveningActivityIds = [
      'db59060b-5ad3-4860-8187-1dc10e78b8d2',
      '4367864e-f586-43bb-90cd-4278075cd3f8',
    ];
    const nightActivityIds = ['12eb72e7-ad8e-43c9-aa39-34194bf67bfc'];
    const saturdayActivityIds = [
      '5f657cb7-ca3e-49b5-b8ef-ff35d8072674',
      '8575f39c-b4dc-4c6d-acd4-dcc3f9a44996',
    ];
    const sundayActivityIds = [
      '6f14b97b-14c2-4d82-9350-a77a6d416423',
      'b4c3f104-91e7-412e-b835-98ff6ecbbb2c',
    ];

    const dayTime: WeekHours = {
      description: 'Daytime',
      mon: services
        .filter(
          ({ activityId }) =>
            !!activityId && weekDayActivityIds.includes(activityId),
        )
        .map(({ mondayHours }) => Number(mondayHours) ?? 0)
        .reduce((a, b) => a! + b!, 0),
      tue: services
        .filter(
          ({ activityId }) =>
            !!activityId && weekDayActivityIds.includes(activityId),
        )
        .map(({ tuesdayHours }) => Number(tuesdayHours) ?? 0)
        .reduce((a, b) => a! + b!, 0),
      wed: services
        .filter(
          ({ activityId }) =>
            !!activityId && weekDayActivityIds.includes(activityId),
        )
        .map(({ wednesdayHours }) => Number(wednesdayHours) ?? 0)
        .reduce((a, b) => a! + b!, 0),
      thu: services
        .filter(
          ({ activityId }) =>
            !!activityId && weekDayActivityIds.includes(activityId),
        )
        .map(({ thursdayHours }) => Number(thursdayHours) ?? 0)
        .reduce((a, b) => a! + b!, 0),
      fri: services
        .filter(
          ({ activityId }) =>
            !!activityId && weekDayActivityIds.includes(activityId),
        )
        .map(({ fridayHours }) => Number(fridayHours) ?? 0)
        .reduce((a, b) => a! + b!, 0),
      sat: services
        .filter(
          ({ activityId }) =>
            !!activityId && saturdayActivityIds.includes(activityId),
        )
        .map(({ weekHours }) => Number(weekHours) ?? 0)
        .reduce((a, b) => a! + b!, 0),
      sun: services
        .filter(
          ({ activityId }) =>
            !!activityId && sundayActivityIds.includes(activityId),
        )
        .map(({ weekHours }) => Number(weekHours) ?? 0)
        .reduce((a, b) => a! + b!, 0),
    };
    const eveningTime: WeekHours = {
      description: 'Evening',
      mon: services
        .filter(
          ({ activityId }) =>
            !!activityId && eveningActivityIds.includes(activityId),
        )
        .map(({ mondayHours }) => Number(mondayHours) ?? 0)
        .reduce((a, b) => a! + b!, 0),
      tue: services
        .filter(
          ({ activityId }) =>
            !!activityId && eveningActivityIds.includes(activityId),
        )
        .map(({ tuesdayHours }) => Number(tuesdayHours) ?? 0)
        .reduce((a, b) => a! + b!, 0),
      wed: services
        .filter(
          ({ activityId }) =>
            !!activityId && eveningActivityIds.includes(activityId),
        )
        .map(({ wednesdayHours }) => Number(wednesdayHours) ?? 0)
        .reduce((a, b) => a! + b!, 0),
      thu: services
        .filter(
          ({ activityId }) =>
            !!activityId && eveningActivityIds.includes(activityId),
        )
        .map(({ thursdayHours }) => Number(thursdayHours) ?? 0)
        .reduce((a, b) => a! + b!, 0),
      fri: services
        .filter(
          ({ activityId }) =>
            !!activityId && eveningActivityIds.includes(activityId),
        )
        .map(({ fridayHours }) => Number(fridayHours) ?? 0)
        .reduce((a, b) => a! + b!, 0),
      sat: 0,
      sun: 0,
    };
    const nightTime: WeekHours = {
      description: 'Night',
      mon: services
        .filter(
          ({ activityId }) =>
            !!activityId && nightActivityIds.includes(activityId),
        )
        .map(({ mondayHours }) => Number(mondayHours) ?? 0)
        .reduce((a, b) => a! + b!, 0),
      tue: services
        .filter(
          ({ activityId }) =>
            !!activityId && nightActivityIds.includes(activityId),
        )
        .map(({ tuesdayHours }) => Number(tuesdayHours) ?? 0)
        .reduce((a, b) => a! + b!, 0),
      wed: services
        .filter(
          ({ activityId }) =>
            !!activityId && nightActivityIds.includes(activityId),
        )
        .map(({ wednesdayHours }) => Number(wednesdayHours) ?? 0)
        .reduce((a, b) => a! + b!, 0),
      thu: services
        .filter(
          ({ activityId }) =>
            !!activityId && nightActivityIds.includes(activityId),
        )
        .map(({ thursdayHours }) => Number(thursdayHours) ?? 0)
        .reduce((a, b) => a! + b!, 0),
      fri: services
        .filter(
          ({ activityId }) =>
            !!activityId && nightActivityIds.includes(activityId),
        )
        .map(({ fridayHours }) => Number(fridayHours) ?? 0)
        .reduce((a, b) => a! + b!, 0),
      sat: 0,
      sun: 0,
    };

    return [dayTime, eveningTime, nightTime];
  }, [services]);

  return (
    <Box className={classes.wrapper}>
      <Block
        title="Participant Details"
        paperProps={{ className: classes.block }}
      >
        <Box className={classes.inputs}>
          <Box className={classes.inputGroup}>
            <ClientAutocomplete
              autocompleteProps={{
                label: 'Existing participant',
                defaultValue: client,
                style: { width: 300 },
              }}
              client={client}
              setClient={setClient}
              orderBy={[{ lastName: OrderBy.ASC_NULLS_FIRST }]}
              where={{
                branch: !!branch ? { id: { _eq: branch.id } } : undefined,
              }}
            />
            <Typography>OR</Typography>
            <TextField
              control={control}
              name="clientName"
              variant="outlined"
              size="small"
              label="Name"
            />
            <TextField
              control={control}
              name="clientNdisId"
              variant="outlined"
              size="small"
              label="NDIS Number"
            />
          </Box>
        </Box>
      </Block>

      <Block title="Quote Details" paperProps={{ className: classes.block }}>
        <Box className={classes.inputs}>
          <Box className={classes.inputGroup}>
            <DateInput
              keyboard
              defaultValue={null}
              name="from"
              label="Start Date"
              control={control}
              inputVariant="outlined"
              size="small"
            />
            <DateInput
              keyboard
              defaultValue={null}
              name="to"
              label="End Date"
              control={control}
              inputVariant="outlined"
              size="small"
            />
          </Box>

          <Box className={classes.inputGroup}>
            <JurisdictionInput
              control={control}
              name="jurisdiction"
              formControlProps={{ size: 'small', variant: 'outlined' }}
            />
          </Box>

          <Box className={classes.inputGroup}>
            <Checkbox
              defaultChecked
              control={control}
              name="includeTransport"
              label="Include Transport activity"
              size="small"
            />
          </Box>
          <Box className={classes.inputGroup}>
            <Checkbox
              defaultChecked
              control={control}
              name="includePublicHolidays"
              label="Include public holidays"
              size="small"
            />
          </Box>
          <Box className={classes.inputGroup}>
            <Checkbox
              control={control}
              name="combineSupportCategories"
              label="Merge similar activities"
              size="small"
            />
          </Box>
        </Box>
      </Block>

      <Block title="Activities" paperProps={{ className: classes.block }}>
        <Box className={classes.inputs}>
          {fields.map((field, i) => (
            <Box className={classes.inputGroup}>
              <Select
                required
                key={field.id}
                control={control}
                variant="outlined"
                label="Activity"
                style={{ width: 550 }}
                items={data?.ndisActivities
                  .filter(({ code }) => code !== '04_102_0125_6_1')
                  .filter(({ code }) => code !== '01_012_0107_1_1')
                  .map(({ id, code, description }) => ({
                    label: (
                      <div className={classes.activity}>
                        <Typography>{code}</Typography>
                        <Typography>{description}</Typography>
                      </div>
                    ),
                    value: id,
                  }))}
                renderValue={(value) => (
                  <div className={classes.activity}>
                    <Typography>
                      {
                        data?.ndisActivities.find(({ id }) => id === value)
                          ?.code
                      }
                    </Typography>
                    <Typography noWrap>
                      {
                        data?.ndisActivities.find(({ id }) => id === value)
                          ?.description
                      }
                    </Typography>
                  </div>
                )}
                error={!!errors.services && !!errors.services?.[i]?.activityId}
                helperText={errors.services?.[i]?.activityId?.message}
                formControlProps={{ size: 'small', variant: 'outlined' }}
                {...register(`services.${i}.activityId` as const, {
                  onBlur: ({ target: { value } }) => {
                    setValue(`services.${i}.activityId`, value);
                  },
                })}
              />
              {!services?.[i]?.activityId ? null : activityIdData.find(
                  (a) =>
                    a.code ===
                    data?.ndisActivities.find(
                      ({ id }) => id === services[i].activityId,
                    )!.code,
                )?.frequency === 'weekly' ? (
                <TextField
                  required
                  key={field.id}
                  control={control}
                  variant="outlined"
                  size="small"
                  label="Hours"
                  style={{ width: 150 }}
                  {...register(`services.${i}.weekHours` as const, {
                    onBlur: ({ target: { value } }) => {
                      setValue(`services.${i}.weekHours`, value);
                    },
                  })}
                />
              ) : (
                (['mon', 'tues', 'wednes', 'thurs', 'fri'] as const).map(
                  (day) => (
                    <TextField
                      key={field.id}
                      control={control}
                      variant="outlined"
                      size="small"
                      label={`${capitalize(day)}day Hours`}
                      style={{ width: 150 }}
                      {...register(`services.${i}.${day}dayHours` as const, {
                        onBlur: ({ target: { value } }) => {
                          setValue(`services.${i}.${day}dayHours`, value);
                        },
                      })}
                    />
                  ),
                )
              )}

              <IconButton onClick={() => remove(i)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}

          <Button
            variant="contained"
            size="small"
            color="primary"
            style={{ width: 'max-content' }}
            onClick={() =>
              append({
                activityId: null,
                weekHours: null,
                mondayHours: null,
                tuesdayHours: null,
                wednesdayHours: null,
                thursdayHours: null,
                fridayHours: null,
              })
            }
          >
            Add Activity
          </Button>
        </Box>
      </Block>

      <div className={classes.printWrapper}>
        <style type="text/css" media="print">
          {' @page { margin: 48px !important; } '}
        </style>
        <div ref={printRef} className={classes.print}>
          <div className={classes.header}>
            <img
              src="https://timed-public.s3.ap-southeast-2.amazonaws.com/australian-carers-logo-full.png"
              alt="logo"
              width={257.43}
              height={64}
            />

            <Box className={classes.address}>
              <Typography className={classes.bold}>Sydney</Typography>
              <Typography color="textSecondary">Suite 6.11</Typography>
              <Typography color="textSecondary">
                8 Elizabeth Macarthur Drive
              </Typography>
              <Typography color="textSecondary">
                Bella Vista, NSW 2153
              </Typography>
            </Box>

            <Box className={classes.address}>
              <Typography className={classes.bold}>Adelaide</Typography>
              <Typography color="textSecondary">Level 8</Typography>
              <Typography color="textSecondary">33 Franklin Street</Typography>
              <Typography color="textSecondary">Adelaide SA 5000</Typography>
            </Box>

            <Box className={classes.address}>
              <Typography className={classes.bold}>Rockhampton</Typography>
              <Typography color="textSecondary">Ground Floor</Typography>
              <Typography color="textSecondary">39 East Street</Typography>
              <Typography color="textSecondary">
                Rockhampton QLD 4700
              </Typography>
            </Box>

            <Box className={classes.address}>
              <Typography className={classes.bold}>Perth</Typography>
              <Typography color="textSecondary">Level 6</Typography>
              <Typography color="textSecondary">
                251 Adelaide Terrace
              </Typography>
              <Typography color="textSecondary">Perth WA 6000</Typography>
            </Box>
          </div>

          <Typography className={classes.bold}>QUOTE</Typography>

          <div className={classes.printTextTable}>
            <Typography className={classes.bold}>Date of quote:</Typography>
            <Typography className={classes.medium}>
              {format(new Date(), 'dd/MM/yyyy')}
            </Typography>
            {!!from && !!to && isValid(from) && !!isValid(to) && (
              <>
                <Typography className={classes.bold}>Quote period:</Typography>
                <Typography className={classes.medium}>
                  {format(from, 'dd/MM/yyyy')} - {format(to, 'dd/MM/yyyy')}
                </Typography>
              </>
            )}
            {(!!client?.value || (clientName && clientNdisId)) && (
              <>
                <Typography className={classes.bold}>
                  NDIS Participant:
                </Typography>
                <Typography className={classes.medium}>
                  {!!client?.value
                    ? formatPersonName(client.value, {
                        lastNameFirst: true,
                        capitaliseLastName: true,
                      })
                    : clientName}
                </Typography>
                <Typography className={classes.bold}>NDIS Number:</Typography>
                <Typography className={classes.medium}>
                  {client?.value ? client.value.ndisId : clientNdisId}
                </Typography>
              </>
            )}
          </div>

          {!!weekHours.length && (
            <Box className={classes.hoursTable}>
              <div
                className={clsx(classes.tabelHeader, classes.tableCell)}
              ></div>
              <div className={clsx(classes.tabelHeader, classes.tableCell)}>
                Monday
              </div>
              <div className={clsx(classes.tabelHeader, classes.tableCell)}>
                Tuesday
              </div>
              <div className={clsx(classes.tabelHeader, classes.tableCell)}>
                Wednesday
              </div>
              <div className={clsx(classes.tabelHeader, classes.tableCell)}>
                Thursday
              </div>
              <div className={clsx(classes.tabelHeader, classes.tableCell)}>
                Friday
              </div>
              <div className={clsx(classes.tabelHeader, classes.tableCell)}>
                Saturday
              </div>
              <div className={clsx(classes.tabelHeader, classes.tableCell)}>
                Sunday
              </div>

              {weekHours
                .filter(
                  ({ mon, tue, wed, thu, fri, sat, sun }) =>
                    !!mon || !!tue || !!wed || !!thu || !!fri || !!sat || !!sun,
                )
                .map(({ description, mon, tue, wed, thu, fri, sat, sun }) => (
                  <>
                    <div className={classes.tableCell}>{description} hours</div>
                    <div className={classes.tableCell}>{mon}</div>
                    <div className={classes.tableCell}>{tue}</div>
                    <div className={classes.tableCell}>{wed}</div>
                    <div className={classes.tableCell}>{thu}</div>
                    <div className={classes.tableCell}>{fri}</div>
                    <div className={classes.tableCell}>{sat}</div>
                    <div className={classes.tableCell}>{sun}</div>
                  </>
                ))}
            </Box>
          )}

          <Box className={classes.supportsTable}>
            <div className={clsx(classes.tabelHeader, classes.tableCell)}>
              Description of Support
            </div>
            <div className={clsx(classes.tabelHeader, classes.tableCell)}>
              Total Hours
            </div>
            <div className={clsx(classes.tabelHeader, classes.tableCell)}>
              Activity ID / Name
            </div>
            <div className={clsx(classes.tabelHeader, classes.tableCell)}>
              Unit
            </div>
            <div className={clsx(classes.tabelHeader, classes.tableCell)}>
              Rate ({jurisdiction})
            </div>
            <div className={clsx(classes.tabelHeader, classes.tableCell)}>
              Total
            </div>

            {includeTransport && (
              <>
                <div className={classes.tableCell}>
                  As required and in addition to the quoted ammount for support
                  shifts
                </div>
                <div className={classes.tableCell}></div>
                <div className={classes.tableCell}>Transport</div>
                <div className={classes.tableCell}>Kilometre</div>
                <div className={classes.tableCell}>$1.15</div>
                <div className={classes.tableCell}>$0.00</div>
              </>
            )}

            {calculatedServices?.map(
              ({ code, description, rate, total, totalHours }) => (
                <>
                  <div className={classes.tableCell}>{description}</div>
                  <div className={classes.tableCell}>
                    {totalHours.toLocaleString()}
                  </div>
                  <div className={classes.tableCell}>{code}</div>
                  <div className={classes.tableCell}>
                    {!!rate ? 'Hour' : null}
                  </div>
                  <div className={classes.tableCell}>{rate}</div>
                  <div className={classes.tableCell}>
                    {formatCurrency(total, { decimals: 2 })}
                  </div>
                </>
              ),
            )}

            <Typography className={clsx(classes.tableCell, classes.bold)}>
              Total
            </Typography>
            <div className={classes.tableCell}>
              {calculatedServices?.length
                ? calculatedServices
                    .map(({ totalHours }) => totalHours)
                    .reduce((a, b) => a + b)
                : null}
            </div>
            <div className={classes.tableCell}></div>
            <div className={classes.tableCell}></div>
            <div className={classes.tableCell}></div>
            <div className={classes.tableCell}>
              {!!calculatedServices?.length
                ? formatCurrency(
                    calculatedServices
                      .map(({ total }) => total)
                      .reduce((a, b) => a + b),
                    { decimals: 2 },
                  )
                : '$0.00'}
            </div>
          </Box>

          <Typography>E&OE</Typography>

          <div className={classes.textGroup}>
            <InvisibleTextField
              control={control}
              name="branchName"
              className={classes.bold}
            />
            <InvisibleTextField
              control={control}
              name="phone"
              className={classes.medium}
            />
            <InvisibleTextField
              control={control}
              name="email"
              className={clsx(classes.medium, classes.purpleText)}
            />
          </div>
        </div>
      </div>

      <ReactToPrint
        documentTitle={`${format(new Date(), 'yyyyMMdd')}_060_Quote_${
          !!client?.value.lastName && !!client?.value.firstName
            ? client.value.lastName + '_' + client.value.firstName
            : clientName
        }`}
        trigger={() => (
          <Button
            variant="contained"
            size="small"
            color="primary"
            style={{ width: 'max-content' }}
          >
            Print Quote
          </Button>
        )}
        content={() => printRef.current}
      />
    </Box>
  );
};

export default ReportParticipantQuote;

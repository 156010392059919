import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useAlert } from '@timed/alert';
import { useAuth } from '@timed/auth';
import {
  Button,
  DateInput,
  formatPersonName,
  ProfileInput,
} from '@timed/common';
import {
  Member,
  OrderBy,
  useEmailMemberClockedPeriodsMutation,
  useGetEventsMembersWorkedHoursLazyQuery,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';

import { differenceInMinutes, format, startOfWeek, subWeeks } from 'date-fns';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useForm } from 'react-hook-form';

export type MemberClockedPeriod = {
  memberLastName?: string;
  memberFirstName: string;
  clockedOn: string;
  clockedOff: string;
  hours: string;
  clientLastName?: string;
  clientFirstName: string;
};

type FormData = {
  member: Pick<Member, 'id'>;
  startAt?: Date;
  endAt?: Date;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      alignItems: 'start',
    },
    data: {
      display: 'inline-grid',
      gridTemplateColumns: 'max-content max-content max-content auto',
      columnGap: theme.spacing(4),
      rowGap: theme.spacing(0),
    },
    email: {
      padding: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      border: '1px solid ' + theme.palette.divider,
    },
  }),
);

const headers = [
  { label: 'Employee Last Name', key: 'memberLastName' },
  { label: 'Employee First Name', key: 'memberFirstName' },
  { label: 'Clocked On', key: 'clockedOn' },
  { label: 'Clocked Off', key: 'clockedOff' },
  { label: 'Hours', key: 'hours' },
  { label: 'Participant Last Name', key: 'clientLastName' },
  { label: 'Participant First Name', key: 'clientFirstName' },
];

const ReportMemberClockedPeriods = () => {
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      startAt: subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1),
      endAt: startOfWeek(new Date(), { weekStartsOn: 1 }),
    },
  });

  const { branch } = useAuth();

  const alert = useAlert();

  const startAt = watch('startAt');
  const endAt = watch('endAt');
  const memberId = watch('member.id');

  const [reportData, setReportData] = useState<
    Array<MemberClockedPeriod> | undefined
  >(undefined);

  const [getEvents, { data, loading, error }] =
    useGetEventsMembersWorkedHoursLazyQuery({
      fetchPolicy: 'network-only',
    });

  const [emailMember] = useEmailMemberClockedPeriodsMutation();

  useEffect(() => {
    error &&
      alert.push({
        message: error.graphQLErrors[0].message,
        severity: 'error',
      });
  }, [error, alert]);

  useLoadingEffect(loading);

  /**
   * Generate report from fetched data
   */
  useEffect(() => {
    if (!!data && !!startAt && !!endAt && !!memberId) {
      setReportData(
        data.events.map((event) => ({
          clientFirstName: event.client.firstName,
          clientLastName: !!event.client.lastName ? event.client.lastName : '',
          memberFirstName: event.member!.firstName,
          memberLastName: !!event.member?.lastName ? event.member.lastName : '',
          clockedOn: format(new Date(event.clockedOnAt), 'dd/MM/yyyy HH:mm'),
          clockedOff: format(new Date(event.clockedOffAt), 'dd/MM/yyyy HH:mm'),
          hours: (
            differenceInMinutes(
              new Date(event.clockedOffAt ?? event.endAt),
              new Date(event.clockedOnAt),
            ) / 60
          )
            .toFixed(2)
            .toString(),
        })),
      );
    }
  }, [data, startAt, endAt, memberId]);

  const onSubmit = ({ member, startAt, endAt }: FormData) => {
    getEvents({
      variables: {
        input: {
          where: {
            clockedOnAt: { _ne: null },
            startAt: { _lt: endAt },
            endAt: { _gt: startAt },
            member: { id: { _eq: member.id } },
          },
          orderBy: [{ startAt: OrderBy.ASC }, { duration: OrderBy.ASC }],
        },
      },
    }).catch((e) => {});
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <ProfileInput
          required
          type={'member'}
          label="Employee"
          control={control}
          name="member.id"
          chipProps={{ onDelete: () => setValue('member.id', '') }}
          formControlProps={{ variant: 'outlined', size: 'small' }}
          watch={watch}
          style={{ minWidth: 200 }}
          error={!!errors.member?.id?.message}
          where={{
            events: { id: { _ne: null } },
            branchMembers: branch
              ? { branch: { id: { _eq: branch.id } } }
              : undefined,
          }}
          orderBy={[
            { lastName: OrderBy.ASC_NULLS_FIRST, firstName: OrderBy.ASC },
          ]}
        />

        {!!memberId && (
          <>
            <DateInput
              label="From"
              name="startAt"
              control={control}
              inputVariant="outlined"
              size="small"
              error={!!errors.startAt}
              helperText={errors.startAt?.message}
            />

            <DateInput
              label="To"
              name="endAt"
              control={control}
              inputVariant="outlined"
              size="small"
              error={!!errors.endAt}
              helperText={errors.endAt?.message}
            />
            <Button variant="contained" type="submit" color="primary">
              Prepare Email & CSV
            </Button>
          </>
        )}
      </form>

      {!!data?.events.length && startAt && endAt && (
        <Box className={classes.email}>
          <Typography>Hi {data.events[0].member!.firstName},</Typography>
          <br />
          <Typography>
            Your clocked hours for the time period{' '}
            {format(startAt, 'dd/MM/yyyy')} to {format(endAt, 'dd/MM/yyyy')} are
            below. If these times are inaccurate, please contact John Vellenga
            ASAP.
            <br />
            <br />
          </Typography>
          <Box className={classes.data}>
            <Typography className={classes.bold}>Clocked On</Typography>
            <Typography className={classes.bold}>Clocked Off</Typography>
            <Typography className={classes.bold}>Hours</Typography>
            <Typography className={classes.bold}>Participant</Typography>
            {data.events.map((event) => {
              const startAt = new Date(event.clockedOnAt);
              const endAt = new Date(event.clockedOffAt ?? event.endAt);
              const duration = differenceInMinutes(endAt, startAt) / 60;

              return (
                <>
                  <Box>{format(startAt, 'dd/MM/yyyy HH:mm')}</Box>
                  <Box>{format(endAt, 'dd/MM/yyyy HH:mm')}</Box>
                  <Box>{parseFloat(duration.toFixed(2))}</Box>
                  <Box>
                    {formatPersonName(event.client, {
                      capitaliseLastName: true,
                      lastNameFirst: true,
                    })}
                  </Box>
                </>
              );
            })}
          </Box>
          <br />
          <br />
          <Typography>
            <span className={classes.bold}>Total hours:</span>{' '}
            {(
              data.events
                .map(({ clockedOnAt, clockedOffAt, endAt }) =>
                  differenceInMinutes(
                    new Date(clockedOffAt ?? new Date(endAt)),
                    new Date(clockedOnAt),
                  ),
                )
                .reduce((sum, current) => sum + current, 0) / 60
            ).toFixed(2)}
          </Typography>
          <br />
          <Typography>Have a nice day.</Typography>
        </Box>
      )}

      {reportData && startAt && endAt && !loading && (
        <Box>
          <Button
            variant="text"
            type="submit"
            color="secondary"
            onClick={() =>
              emailMember({
                variables: {
                  input: {
                    id: memberId,
                    from: startAt,
                    to: endAt,
                  },
                },
              })
            }
          >
            Email JV
          </Button>
          <CSVLink
            style={{ justifySelf: 'center' }}
            data={reportData}
            headers={headers}
            // separator={"	"}
            filename={
              format(startAt, 'yyyyMMdd') +
              '-' +
              format(endAt, 'yyyyMMdd') +
              '_clocked-shifts.txt'
            }
            target="_blank"
          >
            <Button
              type="submit"
              variant="text"
              color="secondary"
              disabled={loading}
            >
              Download CSV
            </Button>
          </CSVLink>
        </Box>
      )}
    </>
  );
};

export default ReportMemberClockedPeriods;

import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Select } from '@timed/common';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        '& .MuiSelect-root': {
          fontSize: 12,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.divider,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': {
        padding: theme.spacing(2, 9, 2, 4),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1.75, 8, 1.75, 2),
        },
      },
      '& .MuiTypography-root': {
        fontSize: '1rem',
        [theme.breakpoints.down('sm')]: {
          fontSize: 12,
        },
      },
    },
  }),
);

type PaginationLimitInputProps = {
  limit: number;
  limits: number[];
  setLimit: Dispatch<SetStateAction<number>>;
};

const PaginationLimitInput = ({
  limit,
  limits,
  setLimit,
}: PaginationLimitInputProps) => {
  const classes = useStyles();

  const { control } = useForm<{ limit: number }>({
    defaultValues: { limit },
  });

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setTimeout(() => (document.activeElement as HTMLElement).blur(), 0); // Unselect select field
    setLimit(parseInt(event.target.value as string));
  };

  return (
    <Select
      name="limit"
      disableUnderline
      aria-label="pagination-limit"
      label="Limit"
      control={control}
      onChange={handleChange}
      className={classes.root}
      formControlProps={{
        variant: 'outlined',
        size: 'small',
      }}
      items={limits.map((limit) => ({
        value: limit,
        label: limit,
      }))}
    />
  );
};

export default PaginationLimitInput;

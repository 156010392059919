import { FileMetadata } from '@timed/file';
import { ClientNoteFileType } from '@timed/gql';

export const noteFilesMetadata: FileMetadata<ClientNoteFileType>[] = [
  {
    id: ClientNoteFileType.MISC,
    name: 'Miscellaneous',
    hasDate: true,
    hasNote: true,
  },
  {
    id: ClientNoteFileType.TRAVEL_RECEIPT,
    name: 'Travel Receipt',
    hasDate: true,
    hasNote: true,
  },
];

import {
  Autocomplete2,
  AutocompleteProps2,
} from '@timed/common/components/Autocomplete2';
import {
  ClientNdisPlanItemTransactionSupplier,
  ClientNdisPlanItemTransactionSuppliersOrderByInput,
  ClientNdisPlanItemTransactionSuppliersWhereInput,
  useGetClientNdisPlanItemTransationSuppliersQuery,
} from '@timed/gql';
import { useMemo } from 'react';

type ClientNdisPlanItemTransactionControlSupplierAutocompleteProps = {
  inputProps: Omit<
    AutocompleteProps2<Pick<ClientNdisPlanItemTransactionSupplier, 'id'>>,
    'options' | 'renderInput'
  >;
  where?: ClientNdisPlanItemTransactionSuppliersWhereInput;
  orderBy?: ClientNdisPlanItemTransactionSuppliersOrderByInput[];
};

const ClientNdisPlanItemTransactionControlSupplierAutocomplete = ({
  inputProps,
  ...input
}: ClientNdisPlanItemTransactionControlSupplierAutocompleteProps) => {
  const { data, loading } = useGetClientNdisPlanItemTransationSuppliersQuery({
    variables: { input },
  });

  const options = useMemo(
    () =>
      data?.clientNdisPlanItemTransactionSuppliers
        ? data.clientNdisPlanItemTransactionSuppliers.map((supplier) => ({
            label: supplier.name,
            value: { id: supplier.id },
          }))
        : [],
    [data?.clientNdisPlanItemTransactionSuppliers],
  );

  return (
    <Autocomplete2
      loading={loading}
      label="Supplier"
      {...inputProps}
      options={options}
    />
  );
};

export default ClientNdisPlanItemTransactionControlSupplierAutocomplete;

import { Checkbox, CheckboxProps } from '@timed/common';

const MemberBonusEligibleCheckbox = (props: CheckboxProps) => (
  <Checkbox
    defaultChecked={true}
    label="This person is eligible for pay bonuses"
    {...props}
  />
);

export default MemberBonusEligibleCheckbox;

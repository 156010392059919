import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import CallMadeIcon from '@material-ui/icons/CallMade';
import StarIcon from '@mui/icons-material/Star';
import { Protected, useAuth } from '@timed/auth';
import {
  ClientContactActionMenuButton,
  clientContactRelationsMetadata,
  ClientContext,
  ClientCreateContactFormModal,
} from '@timed/client';
import {
  Block,
  IconButton,
  intersperse,
  Link,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from '@timed/common';
import { OrderBy, Permission, useGetClientContactsQuery } from '@timed/gql';
import { useModal } from 'mui-modal-provider';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        '& .MuiTypography-root': {
          fontSize: 12,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
    tableWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      height: 18,
    },
    name: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
    preferred: {
      color: theme.palette.info.main,
    },
    emergencyIconWrapper: {
      width: 15,
      height: 15,
      margin: 1,
      position: 'relative',
    },
    emergencyIconHorizontal: {
      margin: 'auto',
      position: 'absolute',
      display: 'block',
      height: '33.3%',
      width: '100%',
      backgroundColor: theme.palette.error.main,
      top: '33.3%',
    },
    emergencyIconVertical: {
      margin: 'auto',
      position: 'absolute',
      display: 'block',
      height: '100%',
      width: '33.3%',
      backgroundColor: theme.palette.error.main,
      left: '33.3%',
    },
    legend: {
      display: 'flex',
      gap: theme.spacing(4),
    },
  }),
);

const ClientViewContacts = () => {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { permissible } = useAuth();

  const { showModal } = useModal();

  const client = useContext(ClientContext);

  const { data, loading } = useGetClientContactsQuery({
    variables: {
      input: {
        where: {
          client: {
            id: {
              _eq: client.id,
            },
          },
        },
        orderBy: [{ name: OrderBy.ASC }],
      },
    },
  });

  const handleClickCreateContactButton = () => {
    const modal: { hide: () => void } = showModal(
      ClientCreateContactFormModal,
      {
        onClose: () => {
          modal.hide();
        },
        client,
      },
    );
  };

  return (
    <Block
      title="Contacts"
      topRight={
        <Protected permissions={Permission.CLIENT_WRITE}>
          <IconButton
            onClick={() => {
              handleClickCreateContactButton();
            }}
          >
            <AddIcon />
          </IconButton>
        </Protected>
      }
    >
      <Box className={classes.wrapper}>
        {loading ? (
          'Loading...'
        ) : !!data?.clientContacts?.length ? (
          <Box className={classes.tableWrapper}>
            <Table enableRowHighlighting>
              <TableHeader>Name</TableHeader>
              <TableHeader style={{ width: 'auto' }}>Relation(s)</TableHeader>
              <TableHeader align={smDown ? 'center' : 'left'}>
                Email
              </TableHeader>
              <TableHeader align={smDown ? 'center' : 'left'}>
                Phone
              </TableHeader>
              <TableHeader
                align="center"
                hidden={!permissible({ permissions: Permission.CLIENT_WRITE })}
              ></TableHeader>
              {data.clientContacts.map(
                (
                  {
                    id,
                    name,
                    email,
                    phone,
                    relations,
                    customRelation,
                    emergencyContact,
                  },
                  i,
                ) => {
                  const relations2: string[] = relations?.length
                    ? relations?.map(
                        (r) =>
                          clientContactRelationsMetadata.find(
                            ({ id }) => id === r,
                          )?.name ?? '',
                      )
                    : [];

                  if (customRelation) relations2.push(customRelation);

                  return (
                    <TableRow key={i}>
                      <TableCell>
                        <div className={classes.name}>
                          <Typography>{name}</Typography>
                          {client.preferredContact?.id === id && (
                            <StarIcon
                              fontSize="small"
                              className={classes.preferred}
                            />
                          )}
                          {emergencyContact && (
                            <div className={classes.emergencyIconWrapper}>
                              <div
                                className={classes.emergencyIconHorizontal}
                              ></div>
                              <div
                                className={classes.emergencyIconVertical}
                              ></div>
                            </div>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>
                        {!!relations2.length && (
                          <Typography>
                            {intersperse({ array: relations2 })}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        {!!email && (
                          <Link to={'mailto:' + email}>
                            {smDown ? (
                              <IconButton size="small">
                                <CallMadeIcon fontSize="small" />
                              </IconButton>
                            ) : (
                              email
                            )}
                          </Link>
                        )}
                      </TableCell>
                      <TableCell>
                        {!!phone && (
                          <Link to={'tel:' + phone.replace(/\s+/g, '')}>
                            {smDown ? (
                              <IconButton size="small">
                                <CallMadeIcon fontSize="small" />
                              </IconButton>
                            ) : (
                              phone
                            )}
                          </Link>
                        )}
                      </TableCell>
                      <TableCell>
                        <ClientContactActionMenuButton contact={{ id, name }} />
                      </TableCell>
                    </TableRow>
                  );
                },
              )}
            </Table>
            <div className={classes.legend}>
              {!!client.preferredContact && (
                <div className={classes.name}>
                  <StarIcon fontSize="small" className={classes.preferred} />
                  <Typography variant="body2" color="textSecondary">
                    Preferred contact
                  </Typography>
                </div>
              )}
              {data.clientContacts.some(
                ({ emergencyContact }) => emergencyContact,
              ) && (
                <div className={classes.name}>
                  <div className={classes.emergencyIconWrapper}>
                    <div className={classes.emergencyIconHorizontal}></div>
                    <div className={classes.emergencyIconVertical}></div>
                  </div>
                  <Typography variant="body2" color="textSecondary">
                    Emergency contact
                  </Typography>
                </div>
              )}
            </div>
          </Box>
        ) : (
          <Typography>This participant does not have any contacts.</Typography>
        )}
      </Box>
    </Block>
  );
};

export default ClientViewContacts;

import {
  Address,
  Client,
  EntityState,
  Event,
  EventsWhereInput,
  GetConflictsQuery,
  GetPublicHolidaysQuery,
  Member,
  Org,
  PersonNamesFragment,
  ScheduleUnallocatedEventsQuery,
  Timezone,
} from '@timed/gql';
import { CSSProperties, createContext } from 'react';

export type MouseState = 'up' | 'down' | 'drag';

export type StartDateType =
  | 'current-day'
  | 'current-week'
  | 'prev-week'
  | 'day-offset';

export type EventAttributes =
  | 'no-notes'
  | 'client-not-billable'
  | 'member-not-payable'
  | 'public-holiday'
  | 'not-clocked';

export type SelectedEvent = Pick<
  Event,
  'id' | 'startAt' | 'endAt' | 'passive' | 'passiveStartAt' | 'notes'
> & {
  member?: null | Pick<Member, 'id'>;
  address?: null | Pick<
    Address,
    | 'street'
    | 'locality'
    | 'region'
    | 'postcode'
    | 'country'
    | 'latitude'
    | 'longitude'
  >;
};

export type SetCellFn = {
  type?: 'down' | 'over';
  value?: number;
};

export type SetEventFn = {
  type?: 'selected' | 'over';
  value?: Pick<SelectedEvent, 'id'> | SelectedEvent;
};

export type Dates = {
  today: Date;
  now: Date;
  from: Date;
  to: Date;
  range: number;
  autoMemberAssign: Date;
  currentTimezone?: string;
  clientTimezone?: string;
  memberTimezone?: string;
};

export type Shift = {
  event: {
    id: Event['id'];
    createdAt: Event['createdAt'];
    startAt: Event['startAt']; // Event start
    endAt: Event['endAt']; // Event end
    duration: number; // Event minutes
    clockedOn?: boolean;
    color: string;
    passive: Event['passive'];
    passiveStartAt?: Event['passiveStartAt'];
    passiveEndAt?: Date;
    passiveDuration?: number;
    activeAssist?: boolean;
    hasNotes: Event['hasNotes'];
    hasFiles: Event['hasFiles'];
    hasSeizureObservation: Event['hasSeizureObservation'];
    eventConflictExists: boolean;
    unavailableConflictExists: boolean;
    publicHoliday: Event['publicHoliday'];
    memberAssignedAutomatically: Event['memberAssignedAutomatically'];
    style: CSSProperties;
    title: string;
    cancelled: boolean;
    client: Pick<Client, 'id'> & PersonNamesFragment;
    member?: (Pick<Client, 'id'> & PersonNamesFragment) | null;
    travelTime: number;
    locked: boolean;
    verified: boolean;
    bonusPay: number;
  };

  startAt: Event['startAt']; // Shift start
  endAt: Event['endAt']; // Shift end
  duration: number; // Minutes

  width: number;
  height: number;
  leftOffset: number;
  topOffset: number;

  futureOverlaps: number;
  pastOverlaps: number;
  startOfOverlap: boolean;
  endOfOverlap: boolean;
  startOverlapsIndex: number;
};

export type ScheduleContextType = {
  refetch: () => void;
  setFrom: (value?: number | Date, redirect?: boolean) => void;
  setRange: (value: number, redirect?: boolean) => void;
  setTime: (date: Date, reverse?: boolean) => Date;
  setTimezone: (type: 'client' | 'member', value?: Timezone) => void;
  setCell: (props?: SetCellFn) => void;
  setEvent: (props: SetEventFn) => void;
  setMouse: (state: MouseState) => void;
  showEventStatusColours: boolean;
  setShowEventStatusColours: React.Dispatch<React.SetStateAction<boolean>>;
  showMemberHours: boolean;
  setShowMemberHours: React.Dispatch<React.SetStateAction<boolean>>;
  client: {
    get: () => string | undefined;
    set: (id: string, redirect: boolean) => void;
  };
  member: {
    get: () => string | undefined;
    set: (id: string, redirect: boolean) => void;
  };
  eventStates: EntityState[];
  setEventStates: (states: EntityState[]) => void;
  eventAttributes?: EventsWhereInput;
  setEventAttributes: (where?: EventsWhereInput) => void;
  dates: Dates;
  mouse: MouseState;
  target: {
    event: {
      over?: Event['id'];
      selected?: SelectedEvent;
    };
    cells: {
      down?: number;
      over?: number;
      up?: number;
    };
  };
  lists: {
    shifts?: Array<Array<Shift>>;
    eventConflicts?: GetConflictsQuery['conflicts'];
    unavailableConflicts?: GetConflictsQuery['conflicts'];
    unallocatedEvents?: ScheduleUnallocatedEventsQuery['events'];
    publicHolidays?: GetPublicHolidaysQuery['publicHolidays'];
  };
  settings: {
    taskAssignAttendeeFutureDays?: Org['taskAssignAttendeeFutureDays'];
  };
  scrollableAreaRef?: React.RefObject<HTMLDivElement>;
};

const ScheduleContext = createContext<ScheduleContextType>({
  refetch: () => {},
  setFrom: () => {},
  setRange: () => {},
  setTime: () => new Date(),
  setTimezone: () => {},
  setCell: () => {},
  setEvent: () => {},
  setMouse: () => {},
  client: { get: () => '', set: () => {} },
  member: { get: () => '', set: () => {} },
  eventStates: [
    EntityState.NORMAL,
    EntityState.ARCHIVED,
    EntityState.CANCELLED,
  ],
  setEventStates: () => {},
  setEventAttributes: () => {},
  showEventStatusColours: true,
  setShowEventStatusColours: () => {},
  showMemberHours: true,
  setShowMemberHours: () => {},
  dates: {
    today: new Date(),
    now: new Date(),
    from: new Date(),
    to: new Date(),
    range: 0,
    autoMemberAssign: new Date(),
  },
  mouse: 'up',
  target: { cells: {}, event: {} },
  lists: {
    // employees: undefined,
    // participants: undefined,
  },
  settings: {},
});

export default ScheduleContext;

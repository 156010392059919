import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@timed/common';
import { NotesContext, NotesControlSettingsModal } from '@timed/notes';
import { useModal } from 'mui-modal-provider';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      minWidth: 0,
      height: theme.spacing(10),
      border: '1px solid ' + theme.palette.divider,
      borderRadius: 0,
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.down('sm')]: {
        height: theme.spacing(8),
      },
      '& .MuiButton-label': {
        fontWeight: 'normal',
      },
    },
    notificationBadge: {
      animation: '$flash 3000ms infinite',
      position: 'absolute',
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      right: 4,
      top: 4,
      backgroundColor: 'red',
      borderRadius: 4,
    },
    '@keyframes flash': {
      '0%, 50%, 100%': {
        opacity: 1,
      },
      '25%, 75%': {
        opacity: 0.1,
      },
    },
  }),
);

const NotesControlNotificationsButton = () => {
  const classes = useStyles();
  const { showModal } = useModal();

  const {
    input: {
      showImporter,
      setShowImporter,
      highlightKeywords,
      setHighlightKeywords,
    },
  } = useContext(NotesContext);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    const modal: { hide: () => void } = showModal(NotesControlSettingsModal, {
      showImporter,
      setShowImporter,
      highlightKeywords,
      setHighlightKeywords,
      onClose: () => {
        modal.hide();
      },
    });
  };

  return (
    <Button
      variant="contained"
      aria-label="Alerts"
      className={classes.button}
      onClick={handleClick}
    >
      {true && <Box className={classes.notificationBadge} />}
      Notifications
    </Button>
  );
};

export default NotesControlNotificationsButton;

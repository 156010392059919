import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { _activities } from '@timed/app';
import { Page } from '@timed/page';
import { Outlet } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
    },
  }),
);

const RouteSupportCoordinationActivities = () => {
  const classes = useStyles();

  return (
    <Page {..._activities}>
      <Box className={classes.wrapper}>
        <Outlet />
      </Box>
    </Page>
  );
};

export default RouteSupportCoordinationActivities;

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useAuth } from '@timed/auth';
import { ClientDeleteObservationFormModal } from '@timed/client';
import {
  addServerErrors,
  DateInput,
  FormModal,
  IconButton,
  ModalProps,
  ProfileInput,
  TextField,
  TimeInput,
} from '@timed/common';
import {
  Client,
  ClientObservation,
  ClientObservationMedication,
  Maybe,
  Medication,
  Member,
  OrderBy,
  Permission,
  PersonNamesFragment,
  QueryByIdsInput,
  useGetClientMedicationsLazyQuery,
  useGetRedactedClientMedicationsLazyQuery,
  useUpdateClientObservationMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import clsx from 'clsx';
import { isValid, subMonths } from 'date-fns';
import _ from 'lodash';
import { useModal } from 'mui-modal-provider';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

type ClientUpdateMedicationFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  observation: Pick<
    ClientObservation,
    'id' | 'date' | 'notes' | 'nursesName'
  > & {
    client: Pick<Client, 'id'>;
    member?: Pick<Member, 'id'> | null;
    createdBy?: (Pick<Member, 'id'> & PersonNamesFragment) | null;
    givenMedications?: Maybe<
      Array<
        Pick<ClientObservationMedication, 'id' | 'quantity'> & {
          medication: Pick<Medication, 'id'>;
        }
      >
    >;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      justifyItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    items: {
      display: 'grid',
      gridTemplateColumns: 'max-content max-content',
      rowGap: theme.spacing(2),
      columnGap: theme.spacing(4),
      alignItems: 'center',
    },
    input: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 60,
      },
    },
    dateInput: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 120,
      },
    },
    notesInput: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 248,
      },
    },
    medications: {
      gridColumn: 'span 2',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    medication: {
      cursor: 'pointer',
      padding: theme.spacing(1),
      backgroundColor: 'white',
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
    },
    selectedMedication: {
      border: '1px solid ' + green[500],
      backgroundColor: green[50],
      color: green[800],
    },
    medicationName: {
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: 'center',
    },
  }),
);

type FormData = {
  patch: {
    date?: Date;
    time?: Date;
    notes?: string | null;
    nursesName?: string | null;
    member?: { id: string } | null;
    medications?: QueryByIdsInput;
    medicationQuantities: { [x: string]: string };
  };
};

const ClientUpdateMedicationFormModal = ({
  onClose,
  observation,
  ...modalProps
}: ClientUpdateMedicationFormModalProps) => {
  const classes = useStyles();

  const { permissible } = useAuth();

  const { showModal } = useModal();

  const [selectedMedications, setSelectedMedications] = useState<string[]>(
    observation.givenMedications?.map(({ medication }) => medication.id) ?? [],
  );

  const [getMedications, medicationsResponse] =
    useGetClientMedicationsLazyQuery({
      variables: {
        input: {
          where: { client: { id: { _eq: observation.client.id } } },
          orderBy: [{ medication: { name: OrderBy.ASC } }],
        },
      },
    });

  const [getRedactedMedications, redactedMedicationsResponse] =
    useGetRedactedClientMedicationsLazyQuery({
      variables: {
        input: {
          where: { client: { id: { _eq: observation.client.id } } },
          orderBy: [{ medication: { name: OrderBy.ASC } }],
        },
      },
    });

  const canFetchNonRedactedClients = useMemo(() => {
    return permissible({ permissions: [Permission.CLIENT_READ] });
  }, [permissible]);

  const data = canFetchNonRedactedClients
    ? medicationsResponse.data?.clientMedications
    : redactedMedicationsResponse.data?.clientMedications;

  const loading = canFetchNonRedactedClients
    ? medicationsResponse.loading
    : redactedMedicationsResponse.loading;

  useEffect(() => {
    if (!data)
      canFetchNonRedactedClients ? getMedications() : getRedactedMedications();
  }, [
    data,
    canFetchNonRedactedClients,
    getMedications,
    getRedactedMedications,
  ]);

  const [updateClientObservation, response] =
    useUpdateClientObservationMutation();

  useLoadingEffect(loading);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      patch: {
        ..._.pick(observation, ['date', 'notes', 'nursesName']),
        member: observation.member
          ? observation.member
          : permissible({ admin: true })
          ? observation.createdBy
          : null,
        time: new Date(observation.date),
      },
    },
  });

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = async ({
    patch: { member, medicationQuantities, ...patch },
  }: FormData) => {
    updateClientObservation({
      variables: {
        input: {
          id: observation.id,
          patch: {
            ..._.omit(patch, ['time']),
            member: member?.id ? { id: member.id } : null,
            givenMedications: {
              objects: _.uniq(selectedMedications).map((id) => ({
                medication: { id },
                quantity: medicationQuantities[id],
              })),
            },
          },
        },
      },
    });
  };

  const handleChangeTime = useCallback(
    (date?: MaterialUiPickersDate) => {
      if (!!date && isValid(date)) {
        const currentDate = new Date(getValues('patch.date')!);
        currentDate.setHours(date.getHours(), date.getMinutes());

        setValue('patch.date', currentDate);
      }
    },
    [getValues, setValue],
  );

  const handleOpenDeleteClientObservationModal = () => {
    const modal: { hide: () => void } = showModal(
      ClientDeleteObservationFormModal,
      {
        onClose: () => {
          modal.hide();
        },
        closePreviousModal: onClose,
        observation,
      },
    );
  };

  return (
    <FormModal
      modalProps={modalProps}
      title="Edit Observations"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      extraControls={
        permissible({ permissions: Permission.CLIENT_WRITE }) ? (
          <IconButton
            onClick={() => {
              handleOpenDeleteClientObservationModal();
            }}
          >
            <DeleteRoundedIcon />
          </IconButton>
        ) : undefined
      }
    >
      <Box className={classes.wrapper}>
        <Box className={classes.items}>
          <Typography variant="body1">Time</Typography>
          <Box className={classes.dateInput}>
            <DateInput
              required
              name="patch.date"
              control={control}
              inputVariant="outlined"
              size="small"
              inputProps={{ style: { textAlign: 'center' } }}
              error={!!errors.patch?.date}
              helperText={errors.patch?.date?.message}
            />
            <TimeInput
              required
              keyboard
              name="patch.time"
              control={control}
              inputVariant="outlined"
              size="small"
              onChange={(date) => {
                if (isValid(date)) handleChangeTime(date);
              }}
              inputProps={{ style: { textAlign: 'center' } }}
              error={!!errors.patch?.time}
              helperText={errors.patch?.time?.message}
            />
          </Box>

          {permissible({ permissions: Permission.MEMBER_READ }) && (
            <>
              <Typography variant="body1">Support worker</Typography>
              <Box className={classes.notesInput}>
                <ProfileInput
                  control={control}
                  name="patch.member.id"
                  chipProps={{
                    onDelete: () => setValue('patch.member.id', ''),
                  }}
                  formControlProps={{ variant: 'outlined', size: 'small' }}
                  watch={watch}
                  error={!!errors.patch?.member}
                  type="member"
                  orderBy={[{ lastName: OrderBy.ASC }]}
                  where={{
                    _or: [
                      {
                        events: {
                          client: { id: { _eq: observation.client.id } },
                          startAt: { _gte: subMonths(new Date(), 3) },
                        },
                      },
                      {
                        clientObservationsCreated: {
                          client: { id: { _eq: observation.client.id } },
                        },
                      },
                    ],
                  }}
                />
              </Box>
            </>
          )}

          <Typography variant="body1">Nurse Name</Typography>
          <Box className={classes.notesInput}>
            <TextField
              name="patch.nursesName"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.patch?.nursesName}
              helperText={errors.patch?.nursesName?.message}
              validation={{ maxLength: 255 }}
            ></TextField>
          </Box>

          <Typography variant="body1">Notes</Typography>
          <Box className={classes.notesInput}>
            <TextField
              multiline
              minRows={2}
              name="patch.notes"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.patch?.notes}
              helperText={errors.patch?.notes?.message}
              validation={{
                maxLength: {
                  message: 'Exceeding max length of 1000 characters',
                  value: 1000,
                },
              }}
            ></TextField>
          </Box>

          {loading && <Typography>Loading medications</Typography>}
          {!!data && (
            <>
              <Box className={classes.medications}>
                {data.map(({ medication }) => (
                  <Box
                    className={
                      selectedMedications.includes(medication.id)
                        ? clsx(classes.medication, classes.selectedMedication)
                        : classes.medication
                    }
                    onClick={() => {
                      selectedMedications.includes(medication.id)
                        ? setSelectedMedications(
                            selectedMedications.filter(
                              (id) => id !== medication.id,
                            ),
                          )
                        : setSelectedMedications([
                            ...selectedMedications,
                            medication.id,
                          ]);
                    }}
                  >
                    <Box>
                      <Box className={classes.medicationName}>
                        {selectedMedications.includes(medication.id) && (
                          <CheckRoundedIcon style={{ fill: green[800] }} />
                        )}
                        <Typography>{medication.name}</Typography>
                      </Box>
                      {selectedMedications.includes(medication.id) && (
                        <TextField
                          name={`patch.medicationQuantities.${medication.id}`}
                          defaultValue={
                            observation.givenMedications?.find(
                              ({ medication: { id } }) => id === medication.id,
                            )?.quantity
                          }
                          onClick={(event) => event.stopPropagation()}
                          control={control}
                          label="Quantity"
                          size="small"
                        />
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </FormModal>
  );
};

export default ClientUpdateMedicationFormModal;

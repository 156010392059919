import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { blue, green } from '@material-ui/core/colors';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { Protected } from '@timed/auth/components/Protected';
import { useAuth } from '@timed/auth/hooks';
import {
  Checkbox,
  formatPermissions,
  formatPersonName,
  FormModal,
  ModalProps,
  Select,
} from '@timed/common';
import { Member, OrderBy, useGetMemberPermissionsQuery } from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import clsx from 'clsx';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

type AuthOverridePermissionsFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
};

type FormData = Pick<Member, 'admin' | 'permissions'> & { memberId: string };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        maxWidth: theme.spacing(75),
      },
    },
    description: {
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: blue[100],
    },
    inputs: {
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.default,
    },
    buttons: {
      display: 'flex',
      gap: theme.spacing(4),
      '& .MuiButton-root': {
        flexBasis: 'auto',
      },
    },

    button: {
      cursor: 'pointer',
      padding: theme.spacing(2),
      backgroundColor: 'white',
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
    },
    selectedButton: {
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: 'center',
      border: '1px solid ' + green[500],
      backgroundColor: green[50],
      color: green[800],
    },
  }),
);

const AuthOverridePermissionsFormModal = ({
  onClose,
  ...modalProps
}: AuthOverridePermissionsFormModalProps) => {
  const classes = useStyles();

  const { overriddenPermission, overridePermissions, member } = useAuth();

  const { data, loading, error } = useGetMemberPermissionsQuery({
    variables: {
      input: {
        orderBy: [
          { lastName: OrderBy.ASC_NULLS_FIRST },
          { firstName: OrderBy.ASC },
        ],
      },
    },
  });

  const [choice, setChoice] = useState<
    undefined | 'manual' | 'inherit' | 'reset'
  >();

  const { handleSubmit, control } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      admin: member?.admin,
      permissions: member?.permissions,
    },
  });

  const onSubmit = ({ admin, permissions, memberId }: FormData) => {
    switch (choice) {
      case 'manual':
        if (admin === member?.admin) overridePermissions(undefined);
        else overridePermissions({ admin, permissions });
        break;

      case 'inherit':
        if (!data) break;
        const selectedMember = data.members.find(({ id }) => id === memberId)!;
        overridePermissions(selectedMember);
        break;

      case 'reset':
        overridePermissions(undefined);
        break;
    }
  };

  useLoadingEffect(loading);

  return (
    <Protected admin={!member?.admin}>
      <FormModal
        modalProps={modalProps}
        title="Override Permissions"
        loading={false}
        success={false}
        onSubmit={handleSubmit(onSubmit)}
        onClose={onClose}
      >
        <Box className={classes.wrapper}>
          {(member?.permissions || member?.admin) && (
            <>
              <Box className={classes.description}>
                <Typography variant="h3" className={classes.bold}>
                  Your Default Permissions:
                </Typography>
                <Typography variant="body1">
                  {formatPermissions(member)}
                </Typography>
              </Box>
              {!!overriddenPermission && (
                <Box className={classes.description}>
                  <Typography variant="h3" className={classes.bold}>
                    Your Current Permissions:
                  </Typography>
                  <Typography variant="body1">
                    {formatPermissions({
                      ...overriddenPermission,
                      showNone: true,
                    })}
                  </Typography>
                </Box>
              )}
            </>
          )}
          <Box className={classes.inputs}>
            <Box className={classes.buttons}>
              <Box
                className={
                  choice === 'manual'
                    ? clsx(classes.button, classes.selectedButton)
                    : classes.button
                }
                onClick={() => {
                  setChoice('manual');
                }}
              >
                {choice === 'manual' && (
                  <CheckRoundedIcon style={{ fill: green[800] }} />
                )}
                <Typography>Manual</Typography>
              </Box>
              <Box
                className={
                  choice === 'inherit'
                    ? clsx(classes.button, classes.selectedButton)
                    : classes.button
                }
                onClick={() => {
                  setChoice('inherit');
                }}
              >
                {choice === 'inherit' && (
                  <CheckRoundedIcon style={{ fill: green[800] }} />
                )}
                <Typography>Inherit</Typography>
              </Box>
              <Box
                className={
                  choice === 'reset'
                    ? clsx(classes.button, classes.selectedButton)
                    : classes.button
                }
                onClick={() => {
                  setChoice('reset');
                }}
              >
                {choice === 'reset' && (
                  <CheckRoundedIcon style={{ fill: green[800] }} />
                )}
                <Typography>Reset</Typography>
              </Box>
            </Box>

            {choice === 'manual' && (
              <Checkbox
                control={control}
                name="admin"
                size="small"
                color="default"
                label="Administrator"
              />
            )}

            {choice === 'inherit' && !!data && (
              <Select
                control={control}
                name="memberId"
                label="Inherit permissions from"
                formControlProps={{ variant: 'outlined', size: 'small' }}
                items={
                  loading || error
                    ? []
                    : data.members.map((m) => ({
                        value: m.id,
                        label: formatPersonName(m, {
                          capitaliseLastName: true,
                          lastNameFirst: true,
                        }),
                      }))
                }
                renderValue={(memberId) => {
                  const selectedMember = data.members.find(
                    ({ id }) => id === memberId,
                  )!;

                  return (
                    <Typography>
                      {formatPersonName(selectedMember)}
                      <br />
                      {formatPermissions(selectedMember)}
                    </Typography>
                  );
                }}
              />
            )}

            {choice === 'reset' && (
              <Typography>
                Press the save button to reset your permissions back to their
                defaults.
              </Typography>
            )}
          </Box>
        </Box>
      </FormModal>
    </Protected>
  );
};

export default AuthOverridePermissionsFormModal;

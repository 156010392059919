import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core';
import { blue, green, red, yellow } from '@material-ui/core/colors';
import { _schedule } from '@timed/app';
import { useAuth } from '@timed/auth';
import {
  formatPersonName,
  roundNumber,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  useRouter,
} from '@timed/common';
import { OrderBy, useScheduleMemberInfoLazyQuery } from '@timed/gql';
import { ScheduleContext } from '@timed/schedule/context';
import { setProfile } from '@timed/schedule/helpers';
import {
  addDays,
  addMinutes,
  addWeeks,
  format,
  formatISO,
  isBefore,
  startOfWeek,
  subWeeks,
} from 'date-fns';
import { useCallback, useContext, useEffect, useMemo } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    wrapper: {
      height: '100%',
      flex: '1 1 0',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0),
    },
    triangle: {
      borderLeft: '4px solid transparent',
      borderRight: '4px solid transparent',
      borderBottom: '8px solid ' + blue[500],
      display: 'inline-block',
    },
    circle: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      display: 'inline-block',
      backgroundColor: green[500],
    },
    blackCircle: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      display: 'inline-block',
      backgroundColor: 'black',
    },
    totals: {
      marginLeft: theme.spacing(4),
      fontWeight: theme.typography.fontWeightMedium,
      fontStyle: 'italic',
    },
    thisHeader: {
      '& #pay-all': {
        display: 'none',
      },
      '&:hover': {
        '& #paid': {
          display: 'none',
        },
        '& #pay-all': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'absolute',
          color: theme.palette.primary.main,
        },
      },
    },
  }),
);

const ScheduleMemberInfoList = () => {
  const classes = useStyles();

  const { branch, permissible } = useAuth();

  const {
    member,
    dates: { from },
  } = useContext(ScheduleContext);

  const getPayrollPeriod = useCallback(
    (date: Date): Date => startOfWeek(date, { weekStartsOn: 1 }),
    [],
  );

  const payrollPeriodFromCalander = useMemo<Date>(
    () => getPayrollPeriod(from),
    [from, getPayrollPeriod],
  );

  const theme = useTheme();

  const {
    navigate,
    search: [searchParams],
  } = useRouter();

  const [getMembers, { data, previousData, loading, refetch, called }] =
    useScheduleMemberInfoLazyQuery({
      pollInterval: 60000,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  const memberId = useMemo(() => member.get(), [member]);

  useEffect(() => {
    if (!called && !!branch)
      getMembers({
        variables: {
          scheduleInput: {
            dates: [
              {
                startAt: formatISO(subWeeks(payrollPeriodFromCalander, 1), {
                  representation: 'date',
                }),
                endAt: formatISO(
                  subWeeks(addDays(payrollPeriodFromCalander, 6), 1),
                  {
                    representation: 'date',
                  },
                ),
              },
              {
                startAt: formatISO(payrollPeriodFromCalander, {
                  representation: 'date',
                }),
                endAt: formatISO(addDays(payrollPeriodFromCalander, 6), {
                  representation: 'date',
                }),
              },
              {
                startAt: formatISO(addWeeks(payrollPeriodFromCalander, 1), {
                  representation: 'date',
                }),
                endAt: formatISO(
                  addWeeks(addDays(payrollPeriodFromCalander, 6), 1),
                  {
                    representation: 'date',
                  },
                ),
              },
            ],
          },
          membersInput: {
            where: {
              branchMembers: branch
                ? { branch: { id: { _eq: branch.id } } }
                : undefined,

              _or: [
                { schedulable: { _eq: true } },
                {
                  events: {
                    client: branch
                      ? { branch: { id: { _eq: branch.id } } }
                      : { id: { _ne: null } },
                  },
                },
              ],
            },
            orderBy: [{ lastName: OrderBy.ASC }, { firstName: OrderBy.ASC }],
          },
        },
      });
  });

  useEffect(() => {
    if (called)
      refetch({
        scheduleInput: {
          dates: [
            {
              startAt: formatISO(subWeeks(payrollPeriodFromCalander, 1), {
                representation: 'date',
              }),
              endAt: formatISO(
                subWeeks(addDays(payrollPeriodFromCalander, 6), 1),
                {
                  representation: 'date',
                },
              ),
            },
            {
              startAt: formatISO(payrollPeriodFromCalander, {
                representation: 'date',
              }),
              endAt: formatISO(addDays(payrollPeriodFromCalander, 6), {
                representation: 'date',
              }),
            },
            {
              startAt: formatISO(addWeeks(payrollPeriodFromCalander, 1), {
                representation: 'date',
              }),
              endAt: formatISO(
                addWeeks(addDays(payrollPeriodFromCalander, 6), 1),
                {
                  representation: 'date',
                },
              ),
            },
          ],
        },
        membersInput: {
          where: {
            branchMembers: branch
              ? { branch: { id: { _eq: branch.id } } }
              : undefined,
            _or: [
              { schedulable: { _eq: true } },
              {
                events: {
                  client: branch
                    ? { branch: { id: { _eq: branch.id } } }
                    : { id: { _ne: null } },
                },
              },
            ],
          },
          orderBy: [{ lastName: OrderBy.ASC }, { firstName: OrderBy.ASC }],
        },
      });
  }, [called, branch, getMembers, payrollPeriodFromCalander, refetch]);

  const [totalLastWeekMinutes, totalThisWeekMinutes, totalNextWeekMinutes] =
    useMemo<[number, number, number]>(
      () =>
        !data
          ? [0, 0, 0]
          : [
              data.members
                .map(({ schedules }) => schedules[0].totalMinutes)
                ?.reduce((a, b) => a + b, 0) ?? 0,
              data.members
                .map(({ schedules }) => schedules[1].totalMinutes)
                ?.reduce((a, b) => a + b, 0) ?? 0,
              data.members
                .map(({ schedules }) => schedules[2].totalMinutes)
                ?.reduce((a, b) => a + b, 0) ?? 0,
            ],
      [data],
    );

  const handleSetMember = (memberId: string) => {
    setProfile('member', searchParams, memberId);
    navigate(_schedule.path + '?' + searchParams);
  };

  return (
    <div className={classes.wrapper}>
      <Table
        inline
        enableRowHighlighting
        loading={loading}
        size="small"
        border="line"
        backgroundColor={theme.palette.background.paper}
        wrapperStyle={{ flex: '1 1 0' }}
      >
        <TableHeader align="center"></TableHeader>
        <TableHeader order={OrderBy.ASC}>Support Worker</TableHeader>
        <TableHeader
          align="center"
          hidden={
            !permissible({ tester: true }) &&
            !(permissible({ admin: true }) && branch?.name === 'Sydney')
          }
        >
          {format(subWeeks(payrollPeriodFromCalander, 1), 'MMM d')}
        </TableHeader>
        <TableHeader
          align="center"
          className={classes.thisHeader}
          hidden={
            !permissible({ tester: true }) &&
            !(permissible({ admin: true }) && branch?.name === 'Sydney')
          }
        >
          {format(payrollPeriodFromCalander, 'MMM d')}
        </TableHeader>
        <TableHeader
          align="center"
          hidden={
            !permissible({ tester: true }) &&
            !(permissible({ admin: true }) && branch?.name === 'Sydney')
          }
        >
          {format(addWeeks(payrollPeriodFromCalander, 1), 'MMM d')}
        </TableHeader>
        {(data || previousData)?.members?.map(
          ({ currentEvent, nextEvent, schedules, color, id, ...rest }, i) => {
            const nextShiftStartingSoon =
              !!nextEvent &&
              isBefore(new Date(nextEvent.startAt), addMinutes(new Date(), 90));

            const iconClass = !!currentEvent
              ? !currentEvent.clockedOnAt
                ? classes.blackCircle
                : classes.circle
              : nextShiftStartingSoon
              ? classes.triangle
              : undefined;

            const [
              [lastWeekTotalMinutes, lastWeekAllowedMinutes],
              [thisWeekTotalMinutes, thisWeekAllowedMinutes],
              [nextWeekTotalMinutes, nextWeekAllowedMinutes],
            ] = schedules.map(({ totalMinutes, allowedMinutes }) => [
              totalMinutes,
              allowedMinutes,
            ]);

            const actualThisWeekAllowedMinutes = Math.max(
              0,
              lastWeekTotalMinutes > lastWeekAllowedMinutes
                ? thisWeekAllowedMinutes -
                    (lastWeekTotalMinutes - lastWeekAllowedMinutes)
                : thisWeekAllowedMinutes,
            );

            const actualNextWeekAllowedMinutes = Math.max(
              0,
              thisWeekTotalMinutes > actualThisWeekAllowedMinutes
                ? nextWeekAllowedMinutes -
                    (thisWeekTotalMinutes - actualThisWeekAllowedMinutes)
                : nextWeekAllowedMinutes,
            );

            return (
              <TableRow
                key={i}
                highlighted={id === memberId}
                onClick={() => {
                  setProfile('client', searchParams, undefined);
                  handleSetMember(id);
                }}
              >
                <TableCell>
                  {!!currentEvent || nextShiftStartingSoon ? (
                    <Box className={iconClass}></Box>
                  ) : (
                    ''
                  )}
                </TableCell>
                <TableCell>
                  {formatPersonName(rest, {
                    lastNameFirst: true,
                    preferredAndLast: true,
                    capitaliseLastName: true,
                  })}
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor:
                      lastWeekTotalMinutes > lastWeekAllowedMinutes
                        ? red[500]
                        : lastWeekTotalMinutes > lastWeekAllowedMinutes * 0.8
                        ? yellow[500]
                        : undefined,
                    color:
                      lastWeekTotalMinutes > lastWeekAllowedMinutes
                        ? theme.palette.common.white
                        : undefined,
                  }}
                >
                  {roundNumber(lastWeekTotalMinutes / 60, 1)} /{' '}
                  {roundNumber(lastWeekAllowedMinutes / 60, 1)}
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor:
                      thisWeekTotalMinutes > actualThisWeekAllowedMinutes
                        ? red[500]
                        : thisWeekTotalMinutes >
                          actualThisWeekAllowedMinutes * 0.8
                        ? yellow[500]
                        : undefined,
                    color:
                      thisWeekTotalMinutes > actualThisWeekAllowedMinutes
                        ? theme.palette.common.white
                        : undefined,
                  }}
                >
                  {roundNumber(thisWeekTotalMinutes / 60, 1)} /{' '}
                  {roundNumber(actualThisWeekAllowedMinutes / 60, 1)}
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor:
                      nextWeekTotalMinutes > actualNextWeekAllowedMinutes
                        ? red[500]
                        : nextWeekTotalMinutes >
                          actualNextWeekAllowedMinutes * 0.8
                        ? yellow[500]
                        : undefined,
                    color:
                      nextWeekTotalMinutes > actualNextWeekAllowedMinutes
                        ? theme.palette.common.white
                        : undefined,
                  }}
                >
                  {roundNumber(nextWeekTotalMinutes / 60, 1)} /{' '}
                  {roundNumber(actualNextWeekAllowedMinutes / 60, 1)}
                </TableCell>
              </TableRow>
            );
          },
        )}

        <TableRow hidden={!permissible({ tester: true })}>
          <TableCell></TableCell>
          <TableCell>
            <span className={classes.totals}>Totals</span>
          </TableCell>
          <TableCell className={classes.bold}>
            {roundNumber(totalLastWeekMinutes / 60, 1)}
          </TableCell>
          <TableCell className={classes.bold}>
            {roundNumber(totalThisWeekMinutes / 60, 1)}
          </TableCell>
          <TableCell className={classes.bold}>
            {roundNumber(totalNextWeekMinutes / 60, 1)}
          </TableCell>
        </TableRow>
      </Table>
      <Table
        size="small"
        border="line"
        backgroundColor={theme.palette.background.paper}
        wrapperStyle={{ flexGrow: 0 }}
      >
        <TableHeader></TableHeader>
        <TableHeader colSpan={2} style={{ width: 'auto' }}>
          Legend
        </TableHeader>
        <TableRow>
          <TableCell>
            <Box className={classes.circle}></Box>
          </TableCell>
          <TableCell>On shift</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Box className={classes.blackCircle}></Box>
          </TableCell>
          <TableCell>Not clocked on</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Box className={classes.triangle}></Box>
          </TableCell>
          <TableCell>Upcoming shift</TableCell>
        </TableRow>
      </Table>
    </div>
  );
};

export default ScheduleMemberInfoList;

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AuthLogoutLink, useAuth } from '@timed/auth';
import { formatPersonName, IconButton } from '@timed/common';
import { useUpdateMembersPermissionsMutation } from '@timed/gql';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    account: {
      backgroundColor: theme.palette.appBar.main,
    },
    accountLine: {
      color: theme.palette.getContrastText(theme.palette.appBar.main),
      padding: theme.spacing(2, 4),
    },
    accountLink: {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.appBar.light,
      },
    },
    loggedInAs: {
      fontWeight: theme.typography.fontWeightMedium,
      [theme.breakpoints.up('sm')]: {
        fontSize: '12px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
      },
    },
    name: {
      fontWeight: theme.typography.fontWeightMedium,
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
  }),
);

const AppBarAccountUserMenu = () => {
  const classes = useStyles();

  const { user, member } = useAuth();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [updateEntity] = useUpdateMembersPermissionsMutation({
    onCompleted: (data) => {
      enqueueSnackbar(
        `Super administrator ${
          data.updateMembers[0].superAdmin ? 'enabled' : 'disabled'
        }`,
        {
          autoHideDuration: 250,
          variant: 'success',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
          action: (key) => (
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <CloseIcon fontSize="small" style={{ fill: 'white' }} />
            </IconButton>
          ),
        },
      );
    },
    onError: () => {
      enqueueSnackbar('Error updating permissions', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon style={{ fill: 'white' }} />
          </IconButton>
        ),
      });
    },
  });

  const handleClickName = useCallback(() => {
    if (
      member?.id === 'e7ec9b20-ac74-4a11-9398-d01acbee4e14' ||
      member?.id === 'd3640ba5-f22a-4f4a-b0f4-94e447ba6c80' ||
      member?.id === 'b01b999b-a809-4d6d-a9fc-246b42f43d1c' ||
      member?.id === '858d194b-b3c9-472f-9192-c01f9ff63a5a'
    ) {
      updateEntity({
        variables: {
          input: {
            ids: [member.id],
            patch: { superAdmin: !member.superAdmin },
          },
        },
      });
    }
  }, [member, updateEntity]);

  if (!user) return null;

  return (
    <Box className={classes.account}>
      <Box className={classes.accountLine} onClick={handleClickName}>
        <Typography noWrap variant="body2" className={classes.loggedInAs}>
          Logged in as
        </Typography>
        <Typography noWrap variant="body2" className={classes.name}>
          {formatPersonName(user)}
        </Typography>
      </Box>
      <AuthLogoutLink>
        <Typography className={clsx(classes.accountLine, classes.accountLink)}>
          Logout
        </Typography>
      </AuthLogoutLink>
    </Box>
  );
};

export default AppBarAccountUserMenu;

import {
  NumberInput,
  TextFieldProps,
  transformNumberToFixedFloat,
} from '@timed/common';
import { RegisterOptions } from 'react-hook-form';

export const validateClientDefaultEventDuration: RegisterOptions = {
  // pattern: validateFloat(2),
  max: { value: 24, message: 'Too long' },
  min: { value: 0, message: 'Invalid duration' },
};

const ClientDefaultEventDurationInput = (
  props: Omit<TextFieldProps, 'transform'>,
) => (
  <NumberInput
    min={0}
    max={168}
    step="0.5"
    label="Default shift hours"
    validation={validateClientDefaultEventDuration}
    transform={transformNumberToFixedFloat(2, 0, 168)}
    {...props}
  />
);

export default ClientDefaultEventDurationInput;

import { ClientContactRelation } from '@timed/gql';
import { RegisterOptions } from 'react-hook-form';

export const validateContactRelation: RegisterOptions = {
  validate: {
    validContactRelation: (value) =>
      !value ||
      (Array.isArray(value)
        ? value.every((v: any) => v in ClientContactRelation)
        : value in ClientContactRelation) ||
      'Invalid contact relation',
  },
};

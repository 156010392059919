import { Select, SelectProps } from '@timed/common';
import { ClientNdisPlanItemCategory } from '@timed/gql';
import { RegisterOptions } from 'react-hook-form';

export const validateClientNdisPlanItemCategory: RegisterOptions = {
  validate: {
    validCategory: (value) =>
      value in ClientNdisPlanItemCategory || 'Invalid value',
  },
};

const ClientNdisPlanItemCategoryInput = ({
  defaultValue,
  ...props
}: Omit<SelectProps, 'items'>) => {
  const formatLabel = (item: ClientNdisPlanItemCategory): string => {
    let label = '';

    switch (item) {
      case ClientNdisPlanItemCategory.CORE:
        label = 'Core';
        break;
      case ClientNdisPlanItemCategory.CAPACITY_BUILDING:
        label = 'Capacity Building';
        break;
      case ClientNdisPlanItemCategory.CAPITAL:
        label = 'Capital';
        break;
    }

    return label;
  };

  return (
    <Select
      label="Category"
      defaultValue={defaultValue || ClientNdisPlanItemCategory.CORE}
      validation={validateClientNdisPlanItemCategory}
      items={Object.values(ClientNdisPlanItemCategory).map((value) => ({
        value,
        label: formatLabel(value),
      }))}
      renderValue={(value) => formatLabel(value as ClientNdisPlanItemCategory)}
      {...props}
    />
  );
};

export default ClientNdisPlanItemCategoryInput;

import { Select, SelectProps } from '@timed/common';
import { TimezoneLabels } from '@timed/common/types/TimezoneLabels';
import { Timezone } from '@timed/gql';
import { RegisterOptions } from 'react-hook-form';

export const validateTimezone: RegisterOptions = {
  validate: {
    validTimezone: (value) => value in Timezone || 'Invalid value',
  },
};

const TimezoneInput = ({ ...props }: Omit<SelectProps, 'items'>) => (
  <Select
    label="Timezone"
    formControlProps={{ size: 'small', variant: 'outlined' }}
    validation={validateTimezone}
    items={TimezoneLabels.map(({ value, label }) => ({
      value,
      label: label.replace('/', ' / '),
    }))}
    {...props}
  />
);

export default TimezoneInput;

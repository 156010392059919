import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { blue, yellow } from '@material-ui/core/colors';
import { useAlert } from '@timed/alert';
import { useAuth } from '@timed/auth';
import { BranchSelectInput } from '@timed/branch';
import {
  AddressBuildingNameInput,
  AddressCountryInput,
  AddressInput,
  AddressUnitInput,
  addServerErrors,
  DateInput,
  EmailInput,
  FormModal,
  GenderInput,
  ModalProps,
  NullableBoolean,
  nullableBooleanStringToValue,
  PersonNameFirstInput,
  PersonNameLastInput,
  PersonNameMiddleInput,
  PhoneInput,
  PronounsInput,
  SalutationInput,
  TimezoneInput,
} from '@timed/common';
import { getTimezone } from '@timed/common/helpers/getTimezone';
import {
  Address,
  CreateMembersDocument,
  CreateMembersInput,
  Member,
  MemberBonusEligible,
  QueryByIdsNullableInput,
  useCreateMembersMutation,
} from '@timed/gql';
import {
  MemberBonusEligibleCheckbox,
  MemberExternalIdInput,
  MemberHasCarInput,
  MemberHasDriversLicenseInput,
  MemberInviteCheckbox,
  MemberPassportNumberInput,
  MemberSchedulableCheckbox,
  MemberTrackFilesCheckbox,
} from '@timed/member';
import clsx from 'clsx';
import { subYears } from 'date-fns';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type MemberCreateFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      justifyItems: 'flex-start',
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        width: 800,
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
      },
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(6, 1fr)',
      gap: theme.spacing(2),
      padding: theme.spacing(2),
      border: '1px solid ' + theme.palette.divider,
      backgroundColor: theme.palette.background.paper,
    },
    yellowGrid: {
      border: '1px solid ' + yellow[800],
      backgroundColor: yellow[50],
    },
    blueGrid: {
      border: '1px solid ' + blue[800],
      backgroundColor: blue[50],
    },
    fullWidth: {
      gridColumn: 'span 6',
    },
    twoThirdsWidth: {
      gridColumn: 'span 4',
    },
    halfWidth: {
      gridColumn: 'span 3',
    },
    thirdWidth: {
      gridColumn: 'span 2',
    },
    group: {
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(1),
    },
    title: {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 16,
    },
    inviteInfo: {
      fontSize: 11,
    },
  }),
);

type FormData = Pick<
  Member,
  | 'externalId'
  | 'firstName'
  | 'middleName'
  | 'lastName'
  | 'email'
  | 'phone'
  | 'salutation'
  | 'gender'
  | 'pronouns'
  | 'dob'
  | 'permissions'
  | 'admin'
  | 'schedulable'
  | 'trackFiles'
  | 'timezone'
  | 'passportCountry'
  | 'passportNumber'
  | 'unit'
  | 'buildingName'
> & {
  invite: boolean;
  bonusEligible: Pick<MemberBonusEligible, 'value'>;
  hasDriversLicense: NullableBoolean;
  hasCar: NullableBoolean;
  branches: QueryByIdsNullableInput;
  address?: Pick<
    Address,
    | 'street'
    | 'locality'
    | 'region'
    | 'postcode'
    | 'country'
    | 'latitude'
    | 'longitude'
  > | null;
};

const MemberCreateFormModal = ({
  onClose,
  ...modalProps
}: MemberCreateFormModalProps) => {
  const classes = useStyles();

  const alert = useAlert();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [createMembers, response] = useCreateMembersMutation();

  const { branch } = useAuth();

  const {
    control,
    handleSubmit,
    clearErrors,
    setError,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      externalId: '',
      email: undefined,
      phone: undefined,
      firstName: '',
      middleName: undefined,
      lastName: undefined,
      invite: false,
      address: null,
      trackFiles: true,
      schedulable: false,
      bonusEligible: { value: false },
      hasDriversLicense: 'null',
      hasCar: 'null',
      timezone: getTimezone(),
      passportCountry: undefined,
      passportNumber: undefined,
      branches: {
        ids: !!branch ? [branch.id] : [],
      },
    },
  });

  const watchEmail = watch('email');
  const watchPhone = watch('phone');

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSuccess = () => {
    alert.push({
      message: 'Successfully created employee',
      severity: 'success',
    });

    const cache = response.client.cache;

    cache.modify({
      fields: {
        members(existing = []) {
          return [
            ...existing,
            cache.writeQuery({
              data: response.data,
              query: CreateMembersDocument,
            }),
          ];
        },
      },
    });

    reset();
  };

  const onSubmit = async (values: FormData) => {
    // To avoid graphql mutation errors, delete location object if it is empty
    (!values.address || Object.keys(values.address).length === 0) &&
      delete values.address;

    // Format input for creating a single member entity
    let input: CreateMembersInput = {
      objects: [
        {
          moduleAccessCS: true,
          unavailableMoMorn: false,
          unavailableMoDay: false,
          unavailableMoNight: false,
          unavailableTuMorn: false,
          unavailableTuDay: false,
          unavailableTuNight: false,
          unavailableWeMorn: false,
          unavailableWeDay: false,
          unavailableWeNight: false,
          unavailableThMorn: false,
          unavailableThDay: false,
          unavailableThNight: false,
          unavailableFrMorn: false,
          unavailableFrDay: false,
          unavailableFrNight: false,
          unavailableSaMorn: false,
          unavailableSaDay: false,
          unavailableSaNight: false,
          unavailableSuMorn: false,
          unavailableSuDay: false,
          unavailableSuNight: false,
          hasCar: values.hasCar
            ? nullableBooleanStringToValue(values.hasCar)
            : undefined,
          hasDriversLicense: values.hasDriversLicense
            ? nullableBooleanStringToValue(values.hasDriversLicense)
            : undefined,
          ...values.address,
          ..._.omit(
            values,
            'address',
            'invite',
            'hasCar',
            'hasDriversLicense',
            'lastName',
            'middleName',
            'phone',
            'email',
            'unit',
            'buildingName',
            'externalId',
            'passportNumber',
          ),
          ..._.pickBy(
            _.pick(
              values,
              'lastName',
              'middleName',
              'phone',
              'email',
              'unit',
              'buildingName',
              'externalId',
              'passportNumber',
            ),
            (value) => !!value?.length && value?.length > 0,
          ),
        },
      ],
      invite: values.invite,
    };

    createMembers({ variables: { input } });
  };

  return (
    <FormModal
      modalProps={modalProps}
      title="Add employee"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        <Box className={clsx(classes.grid, classes.yellowGrid)}>
          <BranchSelectInput
            required
            multiple
            label="Branches"
            formControlProps={{
              size: 'small',
              variant: 'outlined',
              required: true,
              className: classes[smDown ? 'fullWidth' : 'thirdWidth'],
            }}
            name="branches.ids"
            control={control}
            watch={watch}
            error={!!errors.branches?.ids}
            helperText={errors.branches?.ids?.message}
          />
          <TimezoneInput
            required
            name="timezone"
            control={control}
            error={!!errors.timezone}
            helperText={errors.timezone?.message}
            placeholder="Timezone"
            formControlProps={{
              required: true,
              size: 'small',
              variant: 'outlined',
              className: classes[smDown ? 'fullWidth' : 'thirdWidth'],
            }}
          />
          <MemberExternalIdInput
            name="externalId"
            control={control}
            error={!!errors.externalId}
            helperText={errors.externalId?.message}
            size="small"
            variant="outlined"
            className={classes[smDown ? 'fullWidth' : 'thirdWidth']}
          />
        </Box>

        <Box className={classes.group}>
          <Typography className={classes.title}>Personal Details</Typography>
          <Box className={classes.grid}>
            <SalutationInput
              fullWidth
              name="salutation"
              control={control}
              error={!!errors.salutation}
              helperText={errors.salutation?.message}
              formControlProps={{
                size: 'small',
                variant: 'outlined',
                className: classes.fullWidth,
              }}
            />
            <PersonNameFirstInput
              required
              name="firstName"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              className={classes[smDown ? 'fullWidth' : 'thirdWidth']}
            />
            <PersonNameMiddleInput
              name="middleName"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.middleName}
              helperText={errors.middleName?.message}
              className={classes[smDown ? 'fullWidth' : 'thirdWidth']}
            />
            <PersonNameLastInput
              name="lastName"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
              className={classes[smDown ? 'fullWidth' : 'thirdWidth']}
            />
            <EmailInput
              name="email"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.email}
              helperText={errors.email?.message}
              className={classes[smDown ? 'fullWidth' : 'twoThirdsWidth']}
            />
            <PhoneInput
              name="phone"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.phone}
              helperText={errors.phone?.message}
              className={classes[smDown ? 'fullWidth' : 'thirdWidth']}
            />
            <GenderInput
              fullWidth
              name="gender"
              control={control}
              error={!!errors.gender}
              helperText={errors.gender?.message}
              formControlProps={{
                size: 'small',
                variant: 'outlined',
                className: classes[smDown ? 'fullWidth' : 'thirdWidth'],
              }}
            />
            <PronounsInput
              fullWidth
              name="pronouns"
              control={control}
              error={!!errors.pronouns}
              helperText={errors.pronouns?.message}
              formControlProps={{
                size: 'small',
                variant: 'outlined',
                className: classes[smDown ? 'fullWidth' : 'thirdWidth'],
              }}
            />
            <DateInput
              keyboard
              clearable
              disableFuture
              disableTime
              openTo="year"
              name="dob"
              initialFocusedDate={new Date().getFullYear() - 18 + '-1-1'}
              control={control}
              inputVariant="outlined"
              size="small"
              error={!!errors.dob}
              helperText={errors.dob?.message}
              maxDate={new Date()}
              minDate={subYears(new Date(), 120)}
              formControlProps={{
                className: classes[smDown ? 'fullWidth' : 'thirdWidth'],
              }}
              label="Date of Birth"
            />
            <AddressUnitInput
              name="unit"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.unit}
              helperText={errors.unit?.message}
              className={classes.halfWidth}
            />
            <AddressBuildingNameInput
              name="buildingName"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.unit}
              helperText={errors.unit?.message}
              className={classes.halfWidth}
            />
            <AddressInput
              name="address"
              control={control}
              clearErrors={clearErrors}
              setValue={setValue}
              setError={setError}
              error={!!errors.address}
              // helperText={errors.address?.message}
              placeholder="Residential address"
              className={classes.fullWidth}
            />
            <AddressCountryInput
              name="passportCountry"
              variant="outlined"
              control={control}
              defaultValue={null}
              label="Passport Country"
              error={!!errors.passportCountry}
              helperText={errors.passportCountry?.message}
              formControlProps={{
                className: classes[smDown ? 'fullWidth' : 'halfWidth'],
              }}
            />
            <MemberPassportNumberInput
              name="passportNumber"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.passportNumber}
              helperText={errors.passportNumber?.message}
              className={classes[smDown ? 'fullWidth' : 'halfWidth']}
            />
          </Box>
        </Box>

        <Box className={classes.group}>
          <Typography className={classes.title}>
            Scheduling Attributes
          </Typography>
          <Box className={classes.grid}>
            <MemberHasDriversLicenseInput
              name="hasDriversLicense"
              label="Drivers license?"
              control={control}
              formControlProps={{
                variant: 'outlined',
                className: classes.halfWidth,
              }}
            />
            <MemberHasCarInput
              name="hasCar"
              label="Has a car?"
              control={control}
              formControlProps={{
                variant: 'outlined',
                className: classes.halfWidth,
              }}
            />
          </Box>
        </Box>

        <Box className={classes.group}>
          <Typography className={classes.title}>Settings</Typography>
          <Box className={classes.grid}>
            <MemberTrackFilesCheckbox
              id="trackFiles"
              name="trackFiles"
              control={control}
              error={!!errors.trackFiles}
              helperText={errors.trackFiles?.message}
              formControlProps={{ className: classes.fullWidth }}
              color="primary"
            />
            <MemberSchedulableCheckbox
              id="schedulable"
              name="schedulable"
              control={control}
              error={!!errors.schedulable}
              helperText={errors.schedulable?.message}
              formControlProps={{ className: classes.fullWidth }}
              color="primary"
            />
            <MemberBonusEligibleCheckbox
              id="bonusEligible.value"
              name="bonusEligible.value"
              control={control}
              error={!!errors.bonusEligible?.value}
              helperText={errors.bonusEligible?.value?.message}
              formControlProps={{ className: classes.fullWidth }}
              color="primary"
            />
          </Box>
        </Box>

        <Box className={classes.group}>
          <Box className={clsx(classes.grid, classes.blueGrid)}>
            <MemberInviteCheckbox
              name="invite"
              control={control}
              formControlProps={{ className: classes.fullWidth }}
              disabled={!watchEmail && !watchPhone}
              error={!!errors.invite}
              helperText={errors.invite?.message}
              label={
                <Typography>
                  Send invitation immediately
                  {!watchEmail && !watchPhone && (
                    <div className={classes.inviteInfo}>
                      Requires either an email address or a phone number to be
                      set
                    </div>
                  )}
                </Typography>
              }
            />
          </Box>
        </Box>
      </Box>
    </FormModal>
  );
};

export default MemberCreateFormModal;

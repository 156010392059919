import { Menu, MenuItem } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Protected, useAuth } from '@timed/auth';
import { ClientAccessLogsModal, ClientContext } from '@timed/client';
import { IconButton } from '@timed/common';
import {
  Permission,
  useArchiveClientsMutation,
  useRestoreClientsMutation,
} from '@timed/gql';
import { useModal } from 'mui-modal-provider';
import { useContext, useState } from 'react';

const ClientViewActionMenuButton = () => {
  const { showModal } = useModal();

  const auth = useAuth();

  const client = useContext(ClientContext);

  const [archiveClients] = useArchiveClientsMutation();

  const [unarchiveClients] = useRestoreClientsMutation();

  const handleOpenAccessLogsModal = () => {
    const modal: { hide: () => void } = showModal(ClientAccessLogsModal, {
      onClose: () => {
        modal.hide();
      },
      client,
    });
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleArchive = () => {
    archiveClients({ variables: { input: { ids: [client.id] } } });
  };

  const handleUnarchive = () => {
    unarchiveClients({ variables: { input: { ids: [client.id] } } });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <span>
      <IconButton aria-label="menu" color="inherit" onClick={handleClick}>
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
          },
        }}
      >
        <Protected tester>
          <MenuItem onClick={handleOpenAccessLogsModal}>Access Logs</MenuItem>
        </Protected>

        {!client.archive &&
          auth.permissible({
            permissions: Permission.SUPPORTCOORDINATOR_WRITE,
          }) && (
            <MenuItem
              onClick={() => {
                handleArchive();
                handleClose();
              }}
            >
              Archive client
            </MenuItem>
          )}

        {client.archive &&
          auth.permissible({
            permissions: Permission.SUPPORTCOORDINATOR_WRITE,
          }) && (
            <MenuItem
              onClick={() => {
                handleUnarchive();
                handleClose();
              }}
            >
              Unarchive client
            </MenuItem>
          )}
      </Menu>
    </span>
  );
};

export default ClientViewActionMenuButton;

import { createContext } from 'react';

type NavBarContextType = {
  /**
   * Drawer visibility state
   */
  isVisible: boolean;

  /**
   * Toggle drawer visibility state
   */
  toggleDrawer: (
    state: boolean,
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
};

const NavBarContext = createContext<NavBarContextType>({
  isVisible: false,
  toggleDrawer: () => () => {},
});

export default NavBarContext;

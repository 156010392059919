import { Select, SelectMenuItem, SelectProps } from '@timed/common';
import { SeizureType } from '@timed/gql';
import { RegisterOptions } from 'react-hook-form';

export const validateSeizureType: RegisterOptions = {
  validate: {
    validSeizureType: (value) => value in SeizureType || 'Invalid value',
  },
};

const itemsDetailed: SelectMenuItem[] = [
  { label: 'Unknown', value: SeizureType.Unknown },
  { label: '?Absence', value: SeizureType.Absence },
  { label: '?Atypical-absense', value: SeizureType.AtypicalAbsense },
  { label: '?Atonic (muscles relax)', value: SeizureType.Atonic },
  { label: '?Clonic (shaking)', value: SeizureType.Clonic },
  { label: '?Tonic (muscles stiffen)', value: SeizureType.Tonic },
  {
    label: '?Tonic-clonic (stiffen then shaking)',
    value: SeizureType.TonicClonic,
  },
  {
    label: '?Atonic-tonic (muscles relax then stiffen)',
    value: SeizureType.AtonicTonic,
  },
  {
    label: '?Myoclonic (brief twitching muscles, no loss of consciousness)',
    value: SeizureType.Myoclonic,
  },
];

const itemsSimplified: SelectMenuItem[] = [
  { label: 'Unknown', value: SeizureType.Unknown },
  { label: '?Absence', value: SeizureType.Absence },
  { label: '?Atypical-absence', value: SeizureType.AtypicalAbsense },
  { label: '?Atonic', value: SeizureType.Atonic },
  { label: '?Clonic', value: SeizureType.Clonic },
  { label: '?Tonic ', value: SeizureType.Tonic },
  { label: '?Tonic-clonic', value: SeizureType.TonicClonic },
  { label: '?Atonic-tonic', value: SeizureType.AtonicTonic },
  { label: '?Myoclonic', value: SeizureType.Myoclonic },
];

const ClientSeizureTypeInput = ({
  defaultValue,
  ...props
}: Omit<SelectProps, 'items'>) => {
  return (
    <Select
      label="Type"
      formControlProps={{ variant: 'outlined', size: 'small' }}
      defaultValue={defaultValue || SeizureType.Unknown}
      validation={validateSeizureType}
      items={itemsDetailed}
      renderValue={(value) =>
        itemsSimplified.find((item) => item.value === value)?.label
      }
      {...props}
    />
  );
};

export default ClientSeizureTypeInput;

import { Checkbox, CheckboxProps } from '@timed/common';

const MemberSchedulableCheckbox = (props: CheckboxProps) => (
  <Checkbox
    defaultChecked={true}
    label="This person can be assigned to shifts"
    {...props}
  />
);

export default MemberSchedulableCheckbox;

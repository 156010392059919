import { Permission } from '@timed/gql';
import { PageMetadata } from '@timed/page';

/**
 * Personal metadata
 */
// export const _personal: PageMetadata = {
//   label: 'Personal',
//   path: '/personal',
//   redirect: '/personal/shifts',
//   level: 0,
// };
// export const _personalShifts: PageMetadata = {
//   label: 'My Shifts',
//   path: _personal.path + '/shifts',
//   level: 1,
//   newPage: true,
// };
// export const _personalUnavailability: PageMetadata = {
//   label: 'My Unavailability',
//   path: _personal.path + '/unavailability',
//   level: 1,
//   newPage: true,
// };

/**
 * Invitation metadata
 */
export const _invite: PageMetadata = {
  label: 'Invite',
  path: '/invite',
};

/**
 * Notifications metadata
 */
export const _notifications: PageMetadata = {
  label: 'Notifications',
  path: '/notifications',
};

/**
 * Schedule metadata
 */
export const _start: PageMetadata = {
  label: 'Home',
  path: '/home',
};

/**
 * Schedule metadata
 */
export const _schedule: PageMetadata = {
  label: 'Schedule',
  path: '/schedule',
};

/**
 * Schedule metadata
 */
export const _uploads: PageMetadata = {
  label: 'Uploads',
  path: '/uploads',
  redirect: '/uploads/overview',
};
export const _uploadsOverview: PageMetadata = {
  label: 'Upload Files',
  path: _uploads.path + '/overview',
  overruleRedirect: true,
};
export const _uploadsProcess: PageMetadata = {
  label: 'Process Files',
  path: _uploads.path + '/process',
  permissions: Permission.MEMBER_FILE_WRITE,
  overruleRedirect: true,
};
export const _uploadsForms: PageMetadata = {
  label: 'Forms',
  path: _uploads.path + '/forms',
  tester: true,
  overruleRedirect: true,
};
export const _uploadsTracker: PageMetadata = {
  label: 'Documentation Tracker',
  path: _uploads.path + '/tracker',
  permissions: Permission.MEMBER_FILE_WRITE,
  overruleRedirect: true,
};

/**
 * Shifts metadata
 */
export const _shifts: PageMetadata = {
  label: 'Shifts',
  path: '/shifts',
  redirect: '/shifts/personal/upcoming',
};
export const _shiftsPersonal: PageMetadata = {
  label: 'Personal',
  path: _shifts.path + '/personal',
  redirect: _shifts.path + '/personal/upcoming',
};
export const _shiftsPersonalUpcoming: PageMetadata = {
  label: 'My Upcoming Shifts',
  path: _shiftsPersonal.path + '/upcoming',
  overruleRedirect: true,
};
export const _shiftsPersonalClaim: PageMetadata = {
  label: 'Grab Shifts (OLD)',
  path: _shiftsPersonal.path + '/claim',
  overruleRedirect: true,
  tester: true,
};
export const _shiftsPersonalGrab: PageMetadata = {
  label: 'Grab Shifts',
  path: _shiftsPersonal.path + '/grab',
  overruleRedirect: true,
};
export const _shiftsPersonalUnavailability: PageMetadata = {
  label: 'Unavailability',
  path: _shiftsPersonal.path + '/unavailability',
  overruleRedirect: true,
};
export const _shiftsAdmin: PageMetadata = {
  label: 'Administration',
  path: _shifts.path + '/admin',
  redirect: _shifts.path + '/admin/missing-notes',
  permissions: Permission.EVENT_READ,
  overruleRedirect: true,
};
export const _shiftsAdminMissingNotes: PageMetadata = {
  label: 'Missing Case Notes',
  path: _shiftsAdmin.path + '/missing-notes',
  admin: true,
  overruleRedirect: true,
};
export const _shiftsAdminMissingSupportWorker: PageMetadata = {
  label: 'TBA Shifts',
  path: _shiftsAdmin.path + '/missing-support-worker',
  permissions: Permission.EVENT_READ,
  overruleRedirect: true,
};
export const _shiftsAdminTravel: PageMetadata = {
  label: 'Travel',
  path: _shiftsAdmin.path + '/travel',
  admin: true,
  overruleRedirect: true,
};
export const _shiftsAdminApprove: PageMetadata = {
  label: 'Approve Shifts (temp)',
  path: _shiftsAdmin.path + '/approve',
  admin: true,
};

/**
 * People metadata
 */
export const _people: PageMetadata = {
  label: 'People',
  path: '/people',
  redirect: '/people/employees',
};
export const _peopleMembers: PageMetadata = {
  label: 'Employees',
  path: _people.path + '/employees',
  overruleRedirect: true,
};
export const _peopleMembersImport: PageMetadata = {
  label: 'Import',
  path: _peopleMembers.path + '/import',
  permissions: [Permission.MEMBER_WRITE],
};
export const _peopleMembersProfile = {
  label: 'Profile',
};
export const _peopleMembersProfileCategory = {
  label: 'Categories',
};
export const _peopleClients: PageMetadata = {
  label: 'Participants',
  path: _people.path + '/participants',
  overruleRedirect: true,
};
export const _peopleClientsImport: PageMetadata = {
  label: 'Import',
  path: _peopleClients.path + '/import',
  permissions: [Permission.CLIENT_WRITE],
};
export const _peopleClientsProfile = {
  label: 'Profile',
};
export const _peopleClientsProfileCategory = {
  label: 'Categories',
};
export const _peopleSupportCoordinators: PageMetadata = {
  label: 'Support Coordinators',
  path: _people.path + '/support-coordinators',
  permissions: Permission.SUPPORTCOORDINATOR_READ,
  overruleRedirect: true,
};
export const _peopleSupportCoordinatorsProfile = {
  label: 'Profile',
};
export const _peopleSupportCoordinatorsProfileCategory = {
  label: 'Categories',
};
export const _peoplePlanManagers: PageMetadata = {
  label: 'Plan Managers',
  path: _people.path + '/plan-managers',
  permissions: [Permission.PLANMANAGER_READ],
  overruleRedirect: true,
};

/**
 * People metadata (Support Coordination Module)
 */
export const _ndisCoordinationPeople: PageMetadata = {
  label: 'People',
  path: '/people',
  redirect: '/people/employees',
};
export const _ndisCoordinationPeopleMembers: PageMetadata = {
  label: 'Support Coordinators',
  path: _people.path + '/support-coordinators',
  overruleRedirect: true,
};
export const _ndisCoordinationPeopleMembersProfile = {
  label: 'Profile',
};
export const _ndisCoordinationPeopleMembersProfileCategory = {
  label: 'Categories',
};
export const _ndisCoordinationPeopleClients: PageMetadata = {
  label: 'Participants',
  path: _people.path + '/participants',
  overruleRedirect: true,
};
export const _ndisCoordinationPeopleClientsProfile = {
  label: 'Profile',
};
export const _ndisCoordinationPeopleClientsProfileCategory = {
  label: 'Categories',
};
export const _ndisCoordinationPeoplePlanManagers: PageMetadata = {
  label: 'Plan Managers',
  path: _people.path + '/plan-managers',
  permissions: [Permission.PLANMANAGER_READ],
  overruleRedirect: true,
};

/**
 * Notes metadata
 */
export const _notes: PageMetadata = {
  label: 'Notes',
  path: '/notes',
  permissions: [Permission.CLIENT_READ],
};
export const _notesImport: PageMetadata = {
  label: 'Import',
  path: '/notes/import',
  permissions: [Permission.CLIENT_READ],
};

/**
 * Reports metadata
 */
export const _reports: PageMetadata = {
  label: 'Reports',
  path: '/reports',
  permissions: [
    Permission.MEMBER_READ,
    Permission.CLIENT_READ,
    Permission.PLANMANAGER_READ,
  ],
};


/**
 * Activities metadata
 */
export const _activities: PageMetadata = {
  label: 'Activities',
  path: '/activities',
  permissions: [
    Permission.MEMBER_READ,
    Permission.CLIENT_READ,
    Permission.PLANMANAGER_READ,
  ],
};

/**
 * Suggestions metadata
 */
export const _ideas: PageMetadata = {
  label: 'Ideas',
  path: '/ideas',
  admin: true,
};

/**
 * Settings metadata
 */
export const _settings: PageMetadata = {
  label: 'Settings',
  path: '/settings',
  redirect: '/settings/personal/account',
};
export const _settingsPersonal: PageMetadata = {
  label: 'Personal',
  path: _settings.path + '/personal',
  redirect: '/settings/personal/account',
};
export const _settingsPersonalAccount: PageMetadata = {
  label: 'Account',
  path: _settingsPersonal.path + '/account',
  overruleRedirect: true,
};
// export const _settingsPersonalAppearance: PageMetadata = {
//   label: 'Appearance',
//   path: _settingsPersonal.path + '/appearance',
// };
// export const _settingsPersonalNotifications: PageMetadata = {
//   label: 'Notifications',
//   path: _settingsPersonal.path + '/notifications',
// };
// export const _settingsPersonalSecurity: PageMetadata = {
//   label: 'Security',
//   path: _settingsPersonal.path + '/security',
// };
export const _settingsOrganisation: PageMetadata = {
  label: 'Organisation',
  path: _settings.path + '/organisation',
  redirect: '/settings/organisation/scheduling',
  admin: true,
};
export const _settingsOrganisationBranches: PageMetadata = {
  label: 'Branches',
  path: _settingsOrganisation.path + '/branches',
  admin: true,
  overruleRedirect: true,
};
export const _settingsOrganisationScheduling: PageMetadata = {
  label: 'Scheduling',
  path: _settingsOrganisation.path + '/scheduling',
  admin: true,
  overruleRedirect: true,
};
export const _settingsOrganisationVisaTypes: PageMetadata = {
  label: 'VISA Types',
  path: _settingsOrganisation.path + '/visa-types',
  admin: true,
  overruleRedirect: true,
};
export const _settingsOrganisationPayroll: PageMetadata = {
  label: 'Payroll',
  path: _settingsOrganisation.path + '/payroll',
  admin: true,
  overruleRedirect: true,
};
export const _settingsOrganisationBilling: PageMetadata = {
  label: 'Billing',
  path: _settingsOrganisation.path + '/billing',
  admin: true,
  overruleRedirect: true,
};
export const _settingsOrganisationMedication: PageMetadata = {
  label: 'Medication',
  path: _settingsOrganisation.path + '/medication',
  admin: true,
  overruleRedirect: true,
};
// export const _settingsOrganisationAccess: PageMetadata = {
//   label: 'Access',
//   path: _settingsOrganisation.path + '/access',
//   admin: true,
// };
// export const _settingsOrganisationBilling: PageMetadata = {
//   label: 'Billing',
//   path: _settingsOrganisation.path + '/billing',
// };
// export const _settingsOrganisationExports: PageMetadata = {
//   label: 'Exports',
//   path: _settingsOrganisation.path + '/exports',
// };
// export const _settingsOrganisationOverview: PageMetadata = {
//   label: 'Overview',
//   path: _settingsOrganisation.path + '/overview',
// };

/**
 * Support metadata
 */
export const _support: PageMetadata = {
  label: 'Support',
  path: '/support',
  redirect: '/support/help',
};
export const _supportHelp: PageMetadata = {
  label: 'Help',
  path: _support.path + '/help',
  overruleRedirect: true,
};
export const _supportContact: PageMetadata = {
  label: 'Contact',
  path: _support.path + '/contact',
  overruleRedirect: true,
};
export const _supportDebug: PageMetadata = {
  label: 'Debug',
  path: _support.path + '/debug',
  overruleRedirect: true,
};

import {
  Autocomplete2,
  AutocompleteProps2,
} from '@timed/common/components/Autocomplete2';
import {
  ClientNdisPlanItem,
  ClientNdisPlanItemCategory,
  ClientNdisPlanItemOrderByInput,
  ClientNdisPlanItemWhereInput,
  useGetClientNdisPlanItemsQuery,
} from '@timed/gql';
import { useMemo } from 'react';

type ClientNdisPlanItemAutocompleteProps = {
  inputProps: Omit<
    AutocompleteProps2<Pick<ClientNdisPlanItem, 'id'>>,
    'options' | 'renderInput'
  >;
  where?: ClientNdisPlanItemWhereInput;
  orderBy?: ClientNdisPlanItemOrderByInput[];
};

const ClientNdisPlanItemAutocomplete = ({
  inputProps,
  ...input
}: ClientNdisPlanItemAutocompleteProps) => {
  const { data, loading } = useGetClientNdisPlanItemsQuery({
    variables: { input },
  });

  const options = useMemo(
    () =>
      data?.clientNdisPlanItems
        ? data.clientNdisPlanItems.map((item) => ({
            label: item.name.name,
            value: { id: item.id },
          }))
        : [],
    [data?.clientNdisPlanItems],
  );

  return (
    <Autocomplete2
      loading={loading}
      label="Category"
      groupBy={(option) => {
        const item = data?.clientNdisPlanItems.find(
          ({ id }) => id === option.value.id,
        )!;

        if (item.category === ClientNdisPlanItemCategory.CAPACITY_BUILDING)
          return 'Capcity Building';

        if (item.category === ClientNdisPlanItemCategory.CAPITAL)
          return 'Capital';

        if (item.category === ClientNdisPlanItemCategory.CORE) return 'Core';

        return '';
      }}
      {...inputProps}
      options={options}
    />
  );
};

export default ClientNdisPlanItemAutocomplete;

import useResizeObserver from '@react-hook/resize-observer';
import { useLayoutEffect, useState } from 'react';

const useElementSize = (target?: HTMLElement | null) => {
  const [size, setSize] = useState<DOMRect>({
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
    toJSON: () => {},
  });

  useLayoutEffect(() => {
    !!target && setSize(target.getBoundingClientRect());
  }, [target]);

  // Where the magic happens
  useResizeObserver(target ?? null, (entry) => setSize(entry.contentRect));

  return size;
};

export default useElementSize;

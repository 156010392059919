import { _peopleClientsProfileCategory } from '@timed/app';
import { useAuth } from '@timed/auth';
import {
  ClientViewFiles,
  ClientViewBudget,
  ClientViewNotes,
  ClientViewObservations,
  ClientViewOverview,
  ClientViewTeam,
} from '@timed/client';
import { ClientViewMedications } from '@timed/client/components/ViewMedications';
import { useRouter } from '@timed/common';
import { Page } from '@timed/page';
import { RouteErrorNotFound } from '@timed/routes';

const RouteCoreSupportPeopleParticipantsProfileCategory = () => {
  const {
    query: { category, client },
  } = useRouter();

  const { permissible } = useAuth();

  let element!: JSX.Element;
  let label!: string;
  let path!: string;
  let redirect: string | undefined = undefined;

  switch (category) {
    case 'overview':
      label = 'Overview';
      path = `/people/participants/${client}/overview`;
      element = <ClientViewOverview />;
      break;

    case 'notes':
      label = 'Notes';
      path = `/people/participants/${client}/notes`;
      redirect = permissible({ admin: true })
        ? path + '/shift-notes'
        : undefined;
      element = <ClientViewNotes />;
      break;

    case 'team':
      label = 'Team';
      path = `/people/participants/${client}/team`;
      element = <ClientViewTeam />;
      break;

    case 'files':
      label = 'Files';
      path = `/people/participants/${client}/files`;
      element = <ClientViewFiles />;
      break;

    case 'obs':
      label = 'Obs';
      path = `/people/participants/${client}/obs`;
      element = <ClientViewObservations />;
      break;

    case 'meds':
      label = 'Meds';
      path = `/people/participants/${client}/meds`;
      element = <ClientViewMedications />;
      break;

    case 'budget':
      label = 'NDIS Plan';
      path = `/people/participants/${client}/budget`;
      element = <ClientViewBudget />;
      break;

    // case "history":
    //   label = "History";
    //   path = `/people/employees/${profile}/history`;
    //   element = < />;
    //   break;
  }

  return !label || !path || !element ? (
    <RouteErrorNotFound />
  ) : (
    <Page {..._peopleClientsProfileCategory} path={path} redirect={redirect}>
      {element}
    </Page>
  );
};

export default RouteCoreSupportPeopleParticipantsProfileCategory;

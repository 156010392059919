import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core';
import { TransformInput, validateRequired } from '@timed/common';
import _ from 'lodash';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

export type CurrencyTextFieldProps = Omit<MuiTextFieldProps, 'type'> & {
  defaultValue?: unknown;
  control?: Control<any>;
  validation?: Omit<RegisterOptions, 'required'>;
  transform?: TransformInput;
};

const CurrencyTextField = ({
  validation,
  control,
  defaultValue = '',
  transform,
  onChange: onChangeControlled,
  ...props
}: CurrencyTextFieldProps) => {
  return control ? (
    <Controller
      key={props.name}
      name={props.name!}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, ...field }, fieldState: { error } }) => (
        <NumericFormat
          fixedDecimalScale
          key={props.name}
          customInput={MuiTextField}
          variant="outlined"
          thousandSeparator={true}
          decimalScale={2}
          InputProps={{ startAdornment: <span>$</span> }}
          helperText={error?.message}
          onChange={(e) => {
            !!onChangeControlled && onChangeControlled(e);
            transform ? onChange(transform.output(e)) : onChange(e);
          }}
          {...field}
          {...props}
        />
      )}
      rules={
        validation && props.required
          ? { ...validateRequired, ...validation }
          : validation
      }
    />
  ) : (
    <NumericFormat
      customInput={MuiTextField}
      thousandSeparator={true}
      decimalScale={2}
      InputProps={{ startAdornment: <span>$</span> }}
      {..._.omit(props, ['value'])}
    />
  );
};

export default CurrencyTextField;

import { Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { blue, green, yellow } from '@material-ui/core/colors';
import {
  _peopleMembers,
  _shiftsPersonalGrab,
  _start,
  _supportHelp,
  _uploadsOverview,
} from '@timed/app';
import { useAuth } from '@timed/auth';
import { Link, NavLinks, formatPersonName, roundNumber } from '@timed/common';
import {
  EntityState,
  OrderBy,
  useStartGetAdvancedDataLazyQuery,
  useStartGetDataQuery,
} from '@timed/gql';
import { Page } from '@timed/page';
import {
  StartInfoBox,
  StartMissingFiles,
  StartUpcomingShifts,
  StartWelcome,
} from '@timed/start';
import clsx from 'clsx';
import {
  addDays,
  addWeeks,
  format,
  isAfter,
  isBefore,
  isEqual,
  startOfToday,
  startOfWeek,
} from 'date-fns';
import { useEffect, useMemo } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
      },
    },
    group: {
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(1),
    },
    shifts: {
      backgroundColor: theme.palette.info.lightest,
      padding: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2),
      },
    },
    info: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
      },
    },
  }),
);

const RouteCoreSupportReports = () => {
  const classes = useStyles();

  const { member, org, branch, permissible } = useAuth();

  const { data, loading } = useStartGetDataQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        where: {
          endAt: {
            _gte: startOfToday(),
          },
          startAt: {
            _lt: addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 13),
          },
        },
        orderBy: [{ startAt: OrderBy.ASC }, { endAt: OrderBy.ASC }],
      },
    },
  });

  const [getAdvancedData, advancedDataResponse] =
    useStartGetAdvancedDataLazyQuery();

  useEffect(() => {
    if (permissible({ tester: true }))
      getAdvancedData({
        fetchPolicy: 'network-only',
        variables: {
          eventsInput: {
            where: {
              endAt: {
                _gte: startOfWeek(new Date(), { weekStartsOn: 1 }),
              },
              startAt: {
                _lt: addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 13),
              },
              client: !!branch
                ? { branch: { id: { _eq: branch.id } } }
                : undefined,
            },
            orderBy: [{ startAt: OrderBy.ASC }, { endAt: OrderBy.ASC }],
          },
          clientsInput: {
            where: {
              branch: !!branch ? { id: { _eq: branch.id } } : undefined,
              events: {
                endAt: {
                  _gte: startOfWeek(new Date(), { weekStartsOn: 1 }),
                },
                startAt: {
                  _lt: addDays(
                    startOfWeek(new Date(), { weekStartsOn: 1 }),
                    13,
                  ),
                },
              },
            },
            entityStates: [EntityState.NORMAL],
            orderBy: [{ lastName: OrderBy.ASC }],
            limit: 1000,
            offset: 0,
          },
          membersInput: {
            where: {
              branchMembers: !!branch
                ? { branch: { id: { _eq: branch.id } } }
                : undefined,
              events: {
                endAt: {
                  _gte: startOfWeek(new Date(), { weekStartsOn: 1 }),
                },
                startAt: {
                  _lt: addDays(
                    startOfWeek(new Date(), { weekStartsOn: 1 }),
                    13,
                  ),
                },
              },
            },
            entityStates: [EntityState.NORMAL],
            orderBy: [{ lastName: OrderBy.ASC }],
            limit: 1000,
            offset: 0,
          },
        },
      });
  }, [getAdvancedData, permissible, branch]);

  const [
    eventsThisWeek,
    eventsNextWeek,
    unfilledEventsThisWeek,
    unfilledEventsNextWeek,
  ] = useMemo(() => {
    const startOfThisWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
    const startOfNextWeek = addWeeks(startOfThisWeek, 1);

    const eventsThisWeek = advancedDataResponse.data?.events.filter(
      ({ startAt }) => isBefore(new Date(startAt), startOfNextWeek),
    );

    const eventsNextWeek = advancedDataResponse.data?.events.filter(
      ({ startAt }) =>
        isAfter(new Date(startAt), startOfNextWeek) ||
        isEqual(new Date(startAt), startOfNextWeek),
    );

    return [
      // This week
      eventsThisWeek,

      // Next week
      eventsNextWeek,

      // Unfilled this week
      eventsThisWeek?.filter(({ member }) => !member),

      // Unfilled next week
      eventsNextWeek?.filter(({ member }) => !member),
    ];
  }, [advancedDataResponse.data?.events]);

  return (
    <Page {..._start}>
      <div className={classes.wrapper}>
        <NavLinks
          links={[
            _shiftsPersonalGrab,
            {
              label: 'Unavailability',
              path: `${_peopleMembers.path}/${member!.id}/unavailability`,
            },
            _uploadsOverview,
          ]}
        />
        <StartWelcome style={{ fontSize: '150%' }} />
        {loading || advancedDataResponse.loading ? (
          <Typography>Loading...</Typography>
        ) : (
          <>
            <StartMissingFiles files={data?.me.member?.missingFiles} />
            <div className={clsx(classes.group, classes.shifts)}>
              <StartUpcomingShifts events={data?.me.member?.events} />
              <ul style={{ paddingLeft: 12, margin: 0 }}>
                <li>
                  <Typography color="textSecondary">
                    Please{' '}
                    <Link to={'tel:' + org?.schedulingContactPerson?.phone}>
                      call the office
                    </Link>{' '}
                    immediately if you unable to attend any of your assigned
                    shifts.
                  </Typography>
                </li>
                <li>
                  <Typography color="textSecondary">
                    Please read <Link to={_supportHelp.path}>this page</Link>{' '}
                    before commencing work.
                  </Typography>
                </li>
              </ul>
            </div>
            {permissible({ tester: true }) && (
              <div className={classes.info}>
                <StartInfoBox
                  color={blue[400]}
                  title="Support workers on shifts this week"
                  value={
                    advancedDataResponse.data?.membersAggregate.aggregate
                      .totalCount || 0
                  }
                  onClick={() => {
                    if (permissible({ tester: true })) {
                      console.log();
                      console.log(
                        '--- SUPPORT WORKERS ON SHIFTS THIS WEEK ---',
                      );
                      console.log(
                        'Total count: ',
                        advancedDataResponse.data?.membersAggregate.aggregate
                          .totalCount || 0,
                      );
                      advancedDataResponse.data?.membersAggregate.nodes.forEach(
                        (node, i) =>
                          console.log(
                            `${i + 1} - ${formatPersonName(node, {
                              lastNameFirst: true,
                              capitaliseLastName: true,
                            })}`,
                          ),
                      );
                    }
                  }}
                />
                <StartInfoBox
                  color={blue[400]}
                  title="Participants serviced this week"
                  value={
                    advancedDataResponse.data?.clientsAggregate.aggregate
                      .totalCount || 0
                  }
                  onClick={() => {
                    if (permissible({ tester: true })) {
                      console.log();
                      console.log('--- PARTICIPANTS SERVICED THIS WEEK ---');
                      console.log(
                        'Total count: ',
                        advancedDataResponse.data?.clientsAggregate.aggregate
                          .totalCount || 0,
                      );
                      advancedDataResponse.data?.clientsAggregate.nodes.forEach(
                        (node, i) =>
                          console.log(
                            `${i + 1} - ${formatPersonName(node, {
                              lastNameFirst: true,
                              capitaliseLastName: true,
                            })}`,
                          ),
                      );
                    }
                  }}
                />
                <StartInfoBox
                  color={blue[400]}
                  title="Shifts this week"
                  value={eventsThisWeek?.length || 0}
                />
                <StartInfoBox
                  color={blue[400]}
                  title="Shifts next week"
                  value={eventsNextWeek?.length || 0}
                />
                <StartInfoBox
                  color={blue[400]}
                  title="Hours this week"
                  value={roundNumber(
                    (eventsThisWeek?.length
                      ? eventsThisWeek
                          .map(({ duration }) => duration)
                          .reduce((a, b) => a + b)
                      : 0) / 60,
                    1,
                  )}
                />
                <StartInfoBox
                  color={blue[400]}
                  title="Hours next week"
                  value={roundNumber(
                    (eventsNextWeek?.length
                      ? eventsNextWeek
                          .map(({ duration }) => duration)
                          .reduce((a, b) => a + b)
                      : 0) / 60,
                    1,
                  )}
                />
                <StartInfoBox
                  color={
                    !!unfilledEventsThisWeek?.length ? yellow[400] : green[400]
                  }
                  title="Unfilled shifts this week"
                  value={unfilledEventsThisWeek?.length || 0}
                  onClick={() => {
                    if (permissible({ tester: true })) {
                      console.log();
                      console.log('--- UNFILLED SHIFTS THIS WEEK ---');
                      console.log(
                        'Total count: ',
                        unfilledEventsThisWeek?.length || 0,
                      );
                      unfilledEventsThisWeek?.forEach((node, i) =>
                        console.log(
                          `${i + 1} - Participant: ${formatPersonName(
                            node.client,
                            {
                              lastNameFirst: true,
                              capitaliseLastName: true,
                            },
                          )} - Start At: ${format(
                            new Date(node.startAt),
                            'dd/MM/yyyy HH:mm',
                          )}`,
                        ),
                      );
                    }
                  }}
                />
                <StartInfoBox
                  color={
                    !!unfilledEventsNextWeek?.length ? yellow[400] : green[400]
                  }
                  title="Unfilled shifts next week"
                  value={unfilledEventsNextWeek?.length || 0}
                  onClick={() => {
                    if (permissible({ tester: true })) {
                      console.log();
                      console.log('--- UNFILLED SHIFTS NEXT WEEK ---');
                      console.log(
                        'Total count: ',
                        unfilledEventsNextWeek?.length || 0,
                      );
                      unfilledEventsNextWeek?.forEach((node, i) =>
                        console.log(
                          `${i + 1} - Participant: ${formatPersonName(
                            node.client,
                            {
                              lastNameFirst: true,
                              capitaliseLastName: true,
                            },
                          )} - Start At: ${format(
                            new Date(node.startAt),
                            'dd/MM/yyyy HH:mm',
                          )}`,
                        ),
                      );
                    }
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
    </Page>
  );
};

export default RouteCoreSupportReports;

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { _peopleMembersImport } from '@timed/app';
import { Button, useRouter } from '@timed/common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      minWidth: 0,
      height: theme.spacing(10),
      border: '1px solid ' + theme.palette.divider,
      borderRadius: 0,
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.down('sm')]: {
        height: theme.spacing(8),
      },
      '& .MuiButton-label': {
        fontWeight: 'normal',
      },
    },
  }),
);

const MemberImportButton = () => {
  const classes = useStyles();
  const { navigate } = useRouter();

  return (
    <Button
      variant="contained"
      className={classes.button}
      onClick={() => {
        navigate(_peopleMembersImport.path);
      }}
    >
      Import
    </Button>
  );
};

export default MemberImportButton;

import {
  Box,
  createStyles,
  InputAdornment,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  Autocomplete,
  AutocompleteOption,
  AutocompleteProps,
  Avatar,
  formatPersonName,
  TextField,
} from '@timed/common';
import {
  Client,
  ClientsOrderByInput,
  ClientsWhereInput,
  PersonNamesFragment,
  useGetClientsAutocompleteQuery,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { Dispatch, SetStateAction, useMemo } from 'react';

type ClientAutocompleteProps = {
  autocompleteProps?: Partial<
    AutocompleteProps<Pick<Client, 'id' | 'color'> & PersonNamesFragment>
  >;
  client?: AutocompleteOption<
    Pick<Client, 'id' | 'color'> & PersonNamesFragment
  > | null;
  setClient: Dispatch<
    SetStateAction<AutocompleteOption<
      Pick<Client, 'id' | 'color'> & PersonNamesFragment
    > | null>
  >;
  where?: ClientsWhereInput;
  orderBy?: ClientsOrderByInput[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      display: 'flex',
      flex: '1 1 auto',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
    avatar: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      fontSize: '65%',
      lineHeight: theme.spacing(6) + 'px',
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: 1,
    },
  }),
);

const ClientAutocomplete = ({
  autocompleteProps,
  client,
  setClient,
  ...input
}: ClientAutocompleteProps) => {
  const classes = useStyles();

  const { data, loading } = useGetClientsAutocompleteQuery({
    variables: { input },
  });

  useLoadingEffect(loading);

  const options = useMemo(() => {
    return data
      ? data.clients.map((c) => ({
          label: formatPersonName(c, {
            lastNameFirst: true,
            capitaliseLastName: true,
          }),
          value: c,
        }))
      : [];
  }, [data]);

  return (
    <Autocomplete
      {...autocompleteProps}
      name="client-autocomplete"
      label="Participant"
      options={options}
      value={client}
      setValue={setClient}
      renderOption={({ label, value }) => (
        <Box className={classes.item}>
          <Avatar
            className={classes.avatar}
            color={data?.clients.find((m) => m.id === value.id)?.color}
            content={[
              data?.clients.find((m) => m.id === value.id)?.firstName,
              data?.clients.find((m) => m.id === value.id)?.middleName,
              data?.clients.find((m) => m.id === value.id)?.lastName,
            ]}
          />
          <Typography>{label}</Typography>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={autocompleteProps?.label ?? 'Participant'}
          variant="outlined"
          size="small"
          InputProps={{
            ...params.InputProps,
            startAdornment: !client ? null : (
              <InputAdornment position="start">
                <Avatar
                  className={classes.avatar}
                  color={
                    data?.clients.find((m) => m.id === client?.value.id)?.color
                  }
                  content={[
                    data?.clients.find((m) => m.id === client?.value.id)
                      ?.firstName,
                    data?.clients.find((m) => m.id === client?.value.id)
                      ?.middleName,
                    data?.clients.find((m) => m.id === client?.value.id)
                      ?.lastName,
                  ]}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default ClientAutocomplete;

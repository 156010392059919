import { ModuleType } from '@timed/gql';
import { createContext } from 'react';
import { Path } from 'react-router-dom';

export type ModuleRedirectPath = { module: ModuleType; path: string | Path };

export type ModuleContextType = {
  /**
   * Drawer visibility state
   */
  activeModule: ModuleType;

  /**
   * Toggle drawer visibility state
   */
  setActiveModule: (module: ModuleType) => void;

  /**
   * Last viewed pages
   */
  redirectPaths: ModuleRedirectPath[];

  /**
   * Last viewed pages
   */
  setRedirectPath: React.Dispatch<ModuleRedirectPath>;
};

const ModuleContext = createContext<ModuleContextType>({
  activeModule: ModuleType.CS,
  setActiveModule: () => {},
  redirectPaths: [],
  setRedirectPath: () => {},
});

export default ModuleContext;

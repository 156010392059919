import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  _uploads,
  _uploadsForms,
  _uploadsOverview,
  _uploadsProcess,
} from '@timed/app';
import { NavLinks } from '@timed/common';
import { Page } from '@timed/page';
import { Outlet } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
    },
  }),
);

const RouteCoreSupportUploads = () => {
  const classes = useStyles();

  const links = [_uploadsOverview, _uploadsProcess, _uploadsForms];

  return (
    <Page {..._uploads}>
      <Box className={classes.wrapper}>
        <NavLinks links={links} />
        <Outlet />
      </Box>
    </Page>
  );
};

export default RouteCoreSupportUploads;

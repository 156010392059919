import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Protected } from '@timed/auth';
import {
  ClientNdisPlanItemAutocomplete,
  ClientNdisPlanItemTransactionSupplierAutocomplete,
} from '@timed/client';
import { ClientCreateNdisPlanItemTransactionSupplierFormModal } from '@timed/client/components/CreateNdisPlanItemTransactionSupplierFormModal';
import {
  Button,
  CurrencyTextField,
  DateInput,
  FormModal,
  IconButton,
  ModalProps,
  roundNumber,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TextField,
} from '@timed/common';
import {
  CreateClientNdisPlanItemTransactionsInput,
  OrderBy,
  useCreateClientNdisPlanItemTransactionsMutation,
} from '@timed/gql';
import { useModal } from 'mui-modal-provider';
import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

type ClientCreateNdisPlanItemTransactionBulkFormModalProps = Omit<
  ModalProps,
  'children'
> & {
  onClose: () => void;
  clientNdisPlanId: string;
  method: 'invoice' | 'adjustment';
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      maxHeight: 900,
    },
    controls: {
      display: 'flex',
      gap: theme.spacing(4),
    },
    group: {
      display: 'flex',
      gap: theme.spacing(1),
      flexDirection: 'column',
    },
  }),
);

type FormData = CreateClientNdisPlanItemTransactionsInput;

const ClientCreateNdisPlanItemTransactionBulkFormModal = ({
  onClose,
  method,
  clientNdisPlanId,
  ...modalProps
}: ClientCreateNdisPlanItemTransactionBulkFormModalProps) => {
  const classes = useStyles();

  const { showModal } = useModal();

  const handleOpenCreateSupplierModal = () => {
    const modal: { hide: () => void } = showModal(
      ClientCreateNdisPlanItemTransactionSupplierFormModal,
      { onClose: () => modal.hide() },
    );
  };

  const [createEntities, response] =
    useCreateClientNdisPlanItemTransactionsMutation();

  const {
    control,
    setValue,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ mode: 'all' });

  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: 'objects',
  });

  const defaultValues = {
    memo: '',
    billingDate: null,
    serviceDateStart: null,
    serviceDateEnd: null,
    invoiceNumber: '',
    value: 0,
    clientNdisPlanItem: { id: '' },
    clientNdisPlanItemTransactionSupplier: null,
  };

  // const onSuccess = () => {
  //   const cache = response.client.cache;

  //   cache.modify({
  //     fields: {
  //       clientNdisPlanItemTransactions(existing = []) {
  //         return [
  //           ...existing,
  //           cache.writeQuery({
  //             data: response.data,
  //             query: CreateClientNdisPlanItemTransactionDocument,
  //           }),
  //         ];
  //       },
  //     },
  //   });

  //   reset();
  // };

  const onSubmit = async (input: FormData) => {
    input.objects = input.objects.map(({ value, ...o }) => {
      value = roundNumber(Number(value.toString().replace(/,/g, '')) * 100, 0);

      if (method === 'invoice') value *= -1;

      return { ...o, value };
    });

    createEntities({ variables: { input } });
  };

  useEffect(() => {
    if (!fields.length)
      append(defaultValues, {
        shouldFocus: true,
        focusName: `objects.${fields.length}.clientNdisPlanItem`,
      });
  });

  return (
    <FormModal
      modalProps={modalProps}
      title={`Record bulk ${method}s`}
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        <Table enableRowHighlighting showIndexColumn>
          <TableHeader style={{ width: 250 }}>
            Support Category<span style={{ color: 'red' }}>*</span>
          </TableHeader>
          <TableHeader style={{ width: 180 }}>Billing Date</TableHeader>
          <TableHeader style={{ width: 180 }}>Service Start Date</TableHeader>
          <TableHeader style={{ width: 180 }}>Service End Date</TableHeader>
          <TableHeader style={{ width: 250 }} hidden={method !== 'invoice'}>
            Supplier
          </TableHeader>
          <TableHeader>Invoice Number</TableHeader>
          <TableHeader style={{ width: 110 }}>
            Ammount<span style={{ color: 'red' }}>*</span>
          </TableHeader>
          <TableHeader>Memo</TableHeader>
          <TableHeader></TableHeader>
          <TableHeader></TableHeader>
          {fields.map((field, i) => (
            <TableRow key={i}>
              <TableCell>
                <ClientNdisPlanItemAutocomplete
                  key={field.id}
                  inputProps={{
                    ...register(`objects.${i}.clientNdisPlanItem` as const, {
                      required: true,
                    }),
                    control,
                    label: '',
                    size: 'small',
                  }}
                  where={{
                    clientNdisPlan: { id: { _eq: clientNdisPlanId } },
                  }}
                  orderBy={[
                    {
                      category: OrderBy.ASC,
                      name: { name: OrderBy.ASC },
                    },
                  ]}
                />
              </TableCell>
              <TableCell>
                {/*@ts-ignore */}
                <DateInput
                  key={field.id}
                  keyboard
                  clearable
                  disableTime
                  control={control}
                  inputVariant="outlined"
                  size="small"
                  error={!!errors.objects?.[i]?.billingDate}
                  helperText={errors.objects?.[i]?.billingDate?.message}
                  {...register(`objects.${i}.billingDate` as const, {
                    onBlur: ({ target: { value } }) => {
                      setValue(`objects.${i}.billingDate`, value);
                    },
                  })}
                />
              </TableCell>
              <TableCell>
                {/*@ts-ignore */}
                <DateInput
                  key={field.id}
                  keyboard
                  clearable
                  disableTime
                  control={control}
                  inputVariant="outlined"
                  size="small"
                  error={!!errors.objects?.[i]?.serviceDateStart}
                  helperText={errors.objects?.[i]?.serviceDateStart?.message}
                  {...register(`objects.${i}.serviceDateStart` as const, {
                    onBlur: ({ target: { value } }) => {
                      setValue(`objects.${i}.serviceDateStart`, value);
                    },
                  })}
                />
              </TableCell>
              <TableCell>
                {/*@ts-ignore */}
                <DateInput
                  key={field.id}
                  keyboard
                  clearable
                  disableTime
                  control={control}
                  inputVariant="outlined"
                  size="small"
                  error={!!errors.objects?.[i]?.serviceDateEnd}
                  helperText={errors.objects?.[i]?.serviceDateEnd?.message}
                  {...register(`objects.${i}.serviceDateEnd` as const, {
                    onBlur: ({ target: { value } }) => {
                      setValue(`objects.${i}.serviceDateEnd`, value);
                    },
                  })}
                />
              </TableCell>
              <TableCell>
                <ClientNdisPlanItemTransactionSupplierAutocomplete
                  key={field.id}
                  inputProps={{
                    ...register(
                      `objects.${i}.clientNdisPlanItemTransactionSupplier` as const,
                    ),
                    control,
                    label: '',
                    size: 'small',
                  }}
                  orderBy={[{ name: OrderBy.ASC }]}
                />
              </TableCell>
              <TableCell>
                <TextField
                  key={field.id}
                  variant="outlined"
                  control={control}
                  minRows={3}
                  style={{ width: 150 }}
                  error={!!errors.objects?.[i]?.invoiceNumber}
                  helperText={errors.objects?.[i]?.invoiceNumber?.message}
                  validation={{
                    maxLength: {
                      value: 255,
                      message: 'Exceeding 255 characters',
                    },
                  }}
                  {...register(`objects.${i}.invoiceNumber` as const, {
                    onBlur: ({ target: { value } }) => {
                      setValue(`objects.${i}.invoiceNumber`, value);
                    },
                  })}
                />
              </TableCell>
              <TableCell>
                <CurrencyTextField
                  required
                  key={field.id}
                  size="small"
                  control={control}
                  error={!!errors.objects?.[i]?.value}
                  helperText={errors.objects?.[i]?.value?.message}
                  validation={{
                    min: {
                      value: method === 'invoice' ? 0 : -10000000,
                      message: 'Value cannot be negative',
                    },
                  }}
                  onClick={(event) =>
                    (event.target as HTMLInputElement).select()
                  }
                  {...register(`objects.${i}.value` as const, {
                    onBlur: ({ target: { value } }) => {
                      setValue(`objects.${i}.value`, value);
                    },
                  })}
                />
              </TableCell>
              <TableCell>
                <TextField
                  key={field.id}
                  variant="outlined"
                  control={control}
                  minRows={3}
                  style={{ width: 800 }}
                  error={!!errors.objects?.[i]?.memo}
                  helperText={errors.objects?.[i]?.memo?.message}
                  validation={{
                    maxLength: {
                      value: 255,
                      message: 'Exceeding 255 characters',
                    },
                  }}
                  {...register(`objects.${i}.memo` as const, {
                    onBlur: ({ target: { value } }) => {
                      setValue(`objects.${i}.memo`, value);
                    },
                  })}
                />
              </TableCell>
              <TableCell>
                <IconButton
                  disabled={fields.length === 1}
                  onClick={() => remove(i)}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </TableCell>
              <TableCell>
                <IconButton
                  onClick={() =>
                    insert(i, getValues(`objects.${i}`), {
                      shouldFocus: true,
                      focusName: `objects.${i + 1}.clientNdisPlanItem`,
                    })
                  }
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </Table>
        <Protected tester>
          <Box className={classes.controls}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                append(defaultValues, {
                  shouldFocus: true,
                  focusName: `objects.${fields.length}.clientNdisPlanItem`,
                });
              }}
            >
              Add Row
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                append(defaultValues);
                append(defaultValues);
                append(defaultValues);
                append(defaultValues);
                append(defaultValues, {
                  shouldFocus: true,
                  focusName: `objects.${fields.length + 4}.clientNdisPlanItem`,
                });
              }}
            >
              Add 5 Rows
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                handleOpenCreateSupplierModal();
              }}
            >
              Add Supplier
            </Button>
          </Box>
        </Protected>
      </Box>
    </FormModal>
  );
};

export default ClientCreateNdisPlanItemTransactionBulkFormModal;

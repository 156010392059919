import {
  Box,
  createStyles,
  Divider,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { blue, red, yellow } from '@material-ui/core/colors';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { useAuth } from '@timed/auth';
import {
  Block,
  Clock,
  formatPersonName,
  formatTimeDuration,
  Modal,
  ModalProps,
} from '@timed/common';
import { useMemberUnavailableSummaryModalGetUnavailableQuery } from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { MemberUnavailableSummaryModalMenuButton } from '@timed/member/components/UnavailableSummaryModalMenuButton';
import { differenceInMinutes, format, formatDuration } from 'date-fns';

type MemberUnavailableSummaryModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  id: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        width: 700,
        gap: theme.spacing(4),
      },
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      height: 18,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(4),
    },
    error: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.error.main,
      border: '1px solid ' + theme.palette.error.main,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      '& .MuiTypography-root': {
        fontSize: 13,
      },
    },
    cancelled: {
      backgroundColor: 'lightyellow',
      border: '1px solid gold',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      // margin: theme.spacing(2, 2, 0, 2),
      '& .MuiTypography-root': {
        fontSize: 13,
      },
    },
    people: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        // gridTemplateColumns: 'auto auto',
        gap: theme.spacing(4),
      },
    },
    attendee: {
      display: 'flex',
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: '50%',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
      },
      '& .MuiTypography-root': {
        fontSize: 13,
      },
    },
    attendeeText: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
      },
    },
    avatar: {
      width: theme.spacing(19),
      height: theme.spacing(19),
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: 1,
    },
    avatarMini: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      fontSize: 8,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: 1,
    },
    scrollableText: {
      whiteSpace: 'pre-wrap',
      fontSize: 13,
      [theme.breakpoints.up('md')]: {
        maxHeight: 700,
        overflowY: 'auto',
      },
    },
    schedulingNotes: {
      [theme.breakpoints.up('md')]: {
        maxHeight: 80,
      },
    },
    actions: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      '& div': {
        display: 'flex',
        gap: theme.spacing(2),
        '& .MuiButton-root': {
          flexBasis: '100%',
        },
      },
    },
    smallButton: {
      fontSize: 11,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      flexBasis: '100%',
      color: 'black',
    },
    items: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.5),
      [theme.breakpoints.down('sm')]: {
        height: 0,
      },
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      color: theme.palette.text.secondary,
      [theme.breakpoints.up('md')]: {
        maxHeight: 200,
        overflowY: 'auto',
      },
    },
    itemTitle: {
      fontSize: 12,
      fontWeight: theme.typography.fontWeightMedium,
    },
    attachments: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    attachment: {
      color: theme.palette.text.primary,
      padding: theme.spacing(0, 2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.secondary.main,
      display: 'flex',
      gap: theme.spacing(0.5),
      alignItems: 'center',
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
        cursor: 'pointer',
      },
      '& .MuiTypography-root:first-child': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    attachmentTexts: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
    },
    attachmentText: {
      fontSize: 10,
      lineHeight: 1,
    },
    observations: {
      fontFamily: 'monospace',
      cursor: 'pointer',
      fontSize: 12,
    },
    observationControls: {
      cursor: 'pointer',
      fontSize: 12,
    },
    recorder: {
      fontSize: 9,
    },
    description: {
      display: 'flex',
      gap: theme.spacing(2),
    },
    message: {
      width: 'max-content',
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      alignItems: 'center',
      marginTop: theme.spacing(2),
      padding: theme.spacing(2, 3, 2, 2),
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
        marginTop: theme.spacing(1),
        padding: theme.spacing(0, 1),
      },
    },
    messageText: {
      '& .MuiTypography-root:first-child': {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    infoMessage: {
      backgroundColor: blue[50],
      border: '1px solid ' + blue[800],
    },
    warnMessage: {
      backgroundColor: yellow[50],
      border: '1px solid ' + yellow[800],
    },
    errorMessage: {
      backgroundColor: red[50],
      border: '1px solid ' + red[800],
    },
    flexLine: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
    notes: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    note: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper2,
      fontSize: 11,
      cursor: 'pointer',
    },
    noteAuthor: {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 11,
      color: theme.palette.text.primary,
    },
    infoButtons: {
      display: 'flex',
      gap: theme.spacing(2),
    },
    infoButton: {
      height: 76,
      width: 76,
      fontSize: '19px !IMPORTANT',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    helpButton: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
      fontWeight: theme.typography.fontWeightBold,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
    carePlanButton: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    memberNameLine: {
      display: 'flex',
      gap: theme.spacing(1),
    },
  }),
);

const MemberUnavailableSummaryModal = ({
  onClose,
  id,
  ...modalProps
}: MemberUnavailableSummaryModalProps) => {
  const classes = useStyles();

  const theme = useTheme();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { permissible } = useAuth();

  const { data, loading } = useMemberUnavailableSummaryModalGetUnavailableQuery(
    {
      fetchPolicy: 'network-only',
      variables: { id },
    },
  );

  useLoadingEffect(loading);

  return !data?.memberUnavailableById ? null : (
    <Modal disablePortal onClose={onClose} {...modalProps}>
      <Block
        titleProps={{ variant: 'h3' }}
        title={
          <>
            <Typography className={classes.bold}>
              {`${format(
                new Date(data.memberUnavailableById.startAt),
                'EEE d MMM',
              )}, ${formatTimeDuration({
                start: new Date(data.memberUnavailableById.startAt),
                end: new Date(data.memberUnavailableById.endAt),
                options: {
                  militaryTime: true,
                  seperator: ' - ',
                },
              })}(${formatDuration(
                {
                  hours: Math.floor(
                    differenceInMinutes(
                      new Date(data.memberUnavailableById.endAt),
                      new Date(data.memberUnavailableById.startAt),
                    ) / 60,
                  ),
                  minutes:
                    differenceInMinutes(
                      new Date(data.memberUnavailableById.endAt),
                      new Date(data.memberUnavailableById.startAt),
                    ) % 60,
                },
                { format: ['hours', 'minutes'], delimiter: ', ' },
              )
                .replace('hours', 'hrs')
                .replace('minutes', 'mins')})`}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              Local time:{' '}
              <Clock militaryTime dateFirst dateFormat="EEE d MMM," />
            </Typography>
          </>
        }
        topRight={
          <Box className={classes.controls}>
            <MemberUnavailableSummaryModalMenuButton
              unavailable={data.memberUnavailableById}
              closeSummaryPage={onClose}
            />

            <IconButton onClick={onClose}>
              <CloseRoundedIcon />
            </IconButton>
          </Box>
        }
      >
        <Box className={classes.wrapper}>
          <Box className={classes.items}>
            <Box className={classes.item}>
              <Typography
                className={classes.itemTitle}
                variant="body1"
                color="textPrimary"
              >
                Notes
              </Typography>
              <Typography variant="body2">
                {data.memberUnavailableById.notes ?? 'None.'}
              </Typography>
            </Box>

            {permissible({ admin: true }) && (
              <>
                <Divider />
                <Box className={classes.item}>
                  <Typography
                    className={classes.itemTitle}
                    variant="body1"
                    color="error"
                  >
                    Creation
                  </Typography>
                  <Typography variant="body2" color="error">
                    {formatPersonName(data.memberUnavailableById.createdBy, { lastNameFirst: true, capitaliseLastName: true })} @ {format(new Date(data.memberUnavailableById.createdAt), 'dd/MM/yyyy HH:mm')}
                  </Typography>
                </Box>
                <Divider />
                <Box
                  className={classes.item}
                  style={{
                    paddingBottom: smDown ? theme.spacing(8) : undefined,
                  }}
                >
                  <Typography
                    variant="body2"
                    color="error"
                    className={classes.scrollableText}
                  >
                    Entity ID: {id}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Block>
    </Modal>
  );
};

export default MemberUnavailableSummaryModal;

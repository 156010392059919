import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { Button, CalendarContext, useRouter } from '@timed/common';
import clsx from 'clsx';
import { format, startOfWeek, subWeeks } from 'date-fns';
import { useContext } from 'react';

type ButtonType = 'today' | 'this-week' | 'prev-week';

type CalendarDateRedirectButtonProps = {
  type: ButtonType;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.getContrastText(theme.palette.background.default),
      border: '1px solid ' + theme.palette.divider,
      borderRadius: 0,
      textTransform: 'none',
      minWidth: 0,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 2),
      },
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
      '& .MuiButton-label': {
        // width: theme.spacing(6),
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        '& .MuiTypography-root': {
          lineHeight: 1,
          [theme.breakpoints.down('sm')]: {
            lineHeight: 0.9,
          },
        },
      },
    },
    multiline: {
      '& .MuiTypography-root:first-child': {
        fontSize: '80%',
        [theme.breakpoints.down('sm')]: {
          fontSize: '70%',
        },
      },
      '& .MuiTypography-root:last-child': {
        fontSize: '90%',
        [theme.breakpoints.down('sm')]: {
          fontSize: '80%',
        },
      },
    },
  }),
);

const CalendarDateRedirectButton = ({
  type,
}: CalendarDateRedirectButtonProps) => {
  const classes = useStyles();

  const {
    navigate,
    search: [searchParams],
  } = useRouter();

  const { scrollableAreaRef, today } = useContext(CalendarContext);

  const weekStartsOn = parseInt(
    localStorage.getItem('schedule.settings.weekStartsOn') ?? '1',
  ) as 0 | 2 | 1 | 3 | 4 | 5 | 6 | undefined;

  const handleChange: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    let date: Date;

    switch (type) {
      case 'today':
        date = today;
        break;

      case 'this-week':
        date = startOfWeek(today, { weekStartsOn });
        break;

      case 'prev-week':
        date = subWeeks(startOfWeek(today, { weekStartsOn }), 1);
        break;
    }
    const formattedDate = format(date, 'ddMMyyyy');

    if (searchParams.get('f') !== formattedDate) {
      searchParams.set('f', formattedDate);
      navigate(`?${searchParams}`);
    }

    if (scrollableAreaRef?.current?.scrollLeft)
      scrollableAreaRef.current.scrollLeft = 0;
  };

  let content: any;
  let className: string | null = null;

  switch (type) {
    case 'today':
      content = <Typography>Today</Typography>;
      className = classes.button;
      break;

    case 'this-week':
      content = (
        <>
          <Typography>This</Typography>
          <Typography>Week</Typography>
        </>
      );

      className = clsx(classes.button, classes.multiline);
      break;

    case 'prev-week':
      content = (
        <>
          <Typography>Prev</Typography>
          <Typography>Week</Typography>
        </>
      );

      className = clsx(classes.button, classes.multiline);
      break;
  }

  return (
    <Button variant="contained" className={className} onClick={handleChange}>
      {content}
    </Button>
  );
};

export default CalendarDateRedirectButton;

import { isNaN } from 'lodash';

/**
 * Capitalise first letter of a string
 */
export const primativeFromString = (
  value: string,
): string | number | boolean | null => {
  value = value.toLowerCase();

  if (value === 'null') return null;

  if (value === 'true') return true;

  if (value === 'false') return false;

  if (!isNaN(value)) return parseFloat(value);

  return value;
};

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useAlert } from '@timed/alert';
import { BranchNameInput } from '@timed/branch/components/NameInput';
import { addServerErrors, ButtonAsync } from '@timed/common';
import { CreateBranchesDocument, useCreateBranchesMutation } from '@timed/gql';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      alignItems: 'start',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
  }),
);

type FormData = {
  name: string;
};

const BranchCreateForm = () => {
  const classes = useStyles();

  const alert = useAlert();

  const [createBranch, { data, loading, error, client }] =
    useCreateBranchesMutation();

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: '',
    },
  });

  useEffect(() => error && addServerErrors(error, setError), [error, setError]);

  const onSuccess = () => {
    alert.push({ message: 'Successfully created branch', severity: 'success' });

    const cache = client.cache;

    cache.modify({
      fields: {
        branches(existing = []) {
          return [
            ...existing,
            cache.writeQuery({ data, query: CreateBranchesDocument }),
          ];
        },
      },
    });

    reset();
  };

  const onSubmit = async (values: FormData) => {
    createBranch({ variables: { input: { objects: [{ ...values }] } } });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box className={classes.wrapper}>
        <Typography variant="h2">Create a new branch</Typography>
        <BranchNameInput
          required
          name="name"
          label="Name"
          variant="outlined"
          size="small"
          control={control}
          error={!!errors.name}
          helperText={errors.name?.message}
        />
        <ButtonAsync
          type="submit"
          loading={loading}
          success={!!data}
          onSuccess={onSuccess}
          variant="contained"
        >
          Create
        </ButtonAsync>
      </Box>
    </form>
  );
};

export default BranchCreateForm;

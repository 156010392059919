import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ClientIncontinenceTypeInput } from '@timed/client/components/IncontinenceTypeInput';
import { ClientSeizureLostConsciousnessTypeInput } from '@timed/client/components/SeizureLostConsciousnessTypeInput';
import { ClientSeizureTypeInput } from '@timed/client/components/SeizureTypeInput';
import {
  addServerErrors,
  DateInput,
  FormModal,
  ModalProps,
  NumberInput,
  numberRegex,
  ProfileInput,
  TextField,
  TimeInput,
} from '@timed/common';
import { transformStringToNumber } from '@timed/common/transformations/stringToNumber';
import {
  Client,
  CreateClientObservationDocument,
  Event,
  IncontinenceType,
  Member,
  OrderBy,
  SeizureLostConsciousness,
  SeizureType,
  useCreateClientObservationMutation,
  useCreateClientObservationWithEventMutation,
} from '@timed/gql';
import clsx from 'clsx';
import {
  isAfter,
  isBefore,
  isValid,
  max,
  subMinutes,
  subMonths,
} from 'date-fns';
import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

type ClientCreateObservationSeizureFormModalProps = Omit<
  ModalProps,
  'children'
> & {
  onClose: () => void;
  clientId?: Client['id'];
  event?: Pick<Event, 'id' | 'startAt' | 'endAt'> & {
    member?: Pick<Member, 'id'> | null;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      justifyItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    items: {
      display: 'grid',
      gridTemplateColumns: 'max-content max-content',
      rowGap: theme.spacing(2),
      columnGap: theme.spacing(4),
      alignItems: 'center',
    },
    input: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 120,
      },
    },
    seizureType: {
      '& .MuiInputBase-root': {
        width: 240 + theme.spacing(2),
      },
    },
    dateInput: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 120,
      },
    },
    notesInput: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 248,
      },
    },
  }),
);

type FormData = {
  date?: Date;
  time?: Date | null;
  notes?: string | null;
  hadSeizure: boolean;
  seizureDuration?: number | null;
  seizureDurationMinutes?: number | null;
  seizureDurationSeconds?: number | null;
  postSeizureWalkDuration?: number | null;
  postSeizureWalkDurationMinutes?: number | null;
  postSeizureWalkDurationHours?: number | null;
  seizureType?: SeizureType | null;
  incontinenceType?: IncontinenceType | null;
  seizureLostConsciousness?: SeizureLostConsciousness | null;
  member?: { id: string } | null;
};

const ClientCreateObservationSeizureFormModal = ({
  onClose,
  clientId,
  event,
  ...modalProps
}: ClientCreateObservationSeizureFormModalProps) => {
  const classes = useStyles();

  const [createClientObservation, responseWithoutEvent] =
    useCreateClientObservationMutation();

  const [createClientObservationWithEvent, responseWithEvent] =
    useCreateClientObservationWithEventMutation();

  const now: Date = useMemo(() => new Date(), []);
  let date: Date = !!event
    ? max([subMinutes(now, 10), new Date(event.startAt)])
    : now;

  // Recording obs before event has started
  if (!!event && isBefore(now, new Date(event.startAt))) {
    date = new Date(event.startAt);
  }

  // Recording obs after event has finished
  if (!!event && isAfter(now, new Date(event.endAt))) {
    date = new Date(event.endAt);
  }

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      date,
      time: date,
      hadSeizure: true,
      seizureDurationMinutes: null,
      seizureDurationSeconds: null,
      postSeizureWalkDurationMinutes: null,
      postSeizureWalkDurationHours: null,
      seizureType: SeizureType.Unknown,
      seizureLostConsciousness: SeizureLostConsciousness.NOT_RECORDED,
      notes: null,
      member: event?.member ? { id: event?.member?.id } : null,
    },
  });

  const lostConsciousness = watch('seizureLostConsciousness');

  const response = useMemo(
    () => (!!event ? responseWithEvent : responseWithoutEvent),
    [event, responseWithoutEvent, responseWithEvent],
  );

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSuccess = () => {
    const cache = response.client.cache;

    cache.modify({
      fields: {
        clientObservations(existing = []) {
          return [
            ...existing,
            cache.writeQuery({
              data: response.data,
              query: CreateClientObservationDocument,
            }),
          ];
        },
      },
    });

    reset();
  };

  const onSubmit = async ({
    member,
    seizureDurationMinutes,
    seizureDurationSeconds,
    postSeizureWalkDurationMinutes,
    postSeizureWalkDurationHours,
    ...values
  }: FormData) => {
    if (!values.time || !isValid(values.time))
      setError('time', { message: 'Invalid time' });
    else {
      values = {
        ..._.omit(values, ['time']),
        hadSeizure: true,
        seizureDuration:
          seizureDurationMinutes || seizureDurationSeconds
            ? (seizureDurationMinutes ?? 0) * 60 + (seizureDurationSeconds ?? 0)
            : null,
        postSeizureWalkDuration:
          postSeizureWalkDurationMinutes || postSeizureWalkDurationHours
            ? (postSeizureWalkDurationMinutes ?? 0) +
              (postSeizureWalkDurationHours ?? 0) * 60
            : null,
      };

      if (event)
        createClientObservationWithEvent({
          variables: {
            input: {
              ...values,
              event: { id: event.id },
            },
          },
        });
      else
        createClientObservation({
          variables: {
            input: {
              ...values,
              client: { id: clientId! },
              member: member?.id ? { id: member.id } : null,
            },
          },
        });
    }
  };

  const handleChangeTime = useCallback(
    (date?: MaterialUiPickersDate) => {
      if (!!date && isValid(date)) {
        const currentDate = new Date(getValues('date')!);
        currentDate.setHours(date.getHours(), date.getMinutes());

        setValue('date', currentDate);
      }
    },
    [getValues, setValue],
  );

  if (!clientId && !event)
    throw new Error('clientId and eventId properties cannot both be undefined');

  return (
    <FormModal
      modalProps={modalProps}
      title="Record Seizure"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
      saveText="Record"
    >
      <Box className={classes.wrapper}>
        <Box className={classes.items}>
          <Typography variant="body1">Time</Typography>
          <Box className={classes.dateInput}>
            <DateInput
              required
              name="date"
              control={control}
              inputVariant="outlined"
              size="small"
              inputProps={{ style: { textAlign: 'center' } }}
              error={!!errors.date}
              helperText={errors.date?.message}
            />
            <TimeInput
              required
              keyboard
              name="time"
              control={control}
              inputVariant="outlined"
              size="small"
              onChange={(date) => {
                if (isValid(date)) handleChangeTime(date);
              }}
              inputProps={{ style: { textAlign: 'center' } }}
              error={!!errors.time}
              helperText={errors.time?.message}
            />
          </Box>
          <Typography variant="body1">Duration</Typography>
          <Box className={classes.input}>
            <NumberInput
              label="Minutes"
              name="seizureDurationMinutes"
              variant="outlined"
              size="small"
              control={control}
              transform={transformStringToNumber()}
              validation={{ pattern: numberRegex }}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
            />
            <NumberInput
              label="Seconds"
              name="seizureDurationSeconds"
              variant="outlined"
              size="small"
              control={control}
              transform={transformStringToNumber()}
              validation={{ pattern: numberRegex }}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
            />
          </Box>
          <Typography variant="body1">Type</Typography>
          <Box className={clsx(classes.input, classes.seizureType)}>
            <ClientSeizureTypeInput
              name="seizureType"
              control={control}
              error={!!errors.seizureType}
              helperText={errors.seizureType?.message}
            />
          </Box>
          <Typography variant="body1">Incontinence</Typography>
          <Box className={clsx(classes.input, classes.seizureType)}>
            <ClientIncontinenceTypeInput
              name="incontinenceType"
              control={control}
              error={!!errors.incontinenceType}
              helperText={errors.incontinenceType?.message}
            />
          </Box>
          <Typography variant="body1">Lost consciousness</Typography>
          <Box className={clsx(classes.input, classes.seizureType)}>
            <ClientSeizureLostConsciousnessTypeInput
              name="seizureLostConsciousness"
              control={control}
              error={!!errors.seizureLostConsciousness}
              helperText={errors.seizureLostConsciousness?.message}
            />
          </Box>
          <Typography variant="body1">Postictal walk duration</Typography>
          <Box className={classes.input}>
            <NumberInput
              label="Hours"
              name="postSeizureWalkDurationHours"
              variant="outlined"
              size="small"
              control={control}
              transform={transformStringToNumber()}
              validation={{ pattern: numberRegex }}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
            />
            <NumberInput
              label="Minutes"
              name="postSeizureWalkDurationMinutes"
              variant="outlined"
              size="small"
              control={control}
              transform={transformStringToNumber()}
              validation={{ pattern: numberRegex }}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
            />
          </Box>
          {!event && (
            <>
              <Typography variant="body1">Support worker</Typography>
              <Box className={classes.notesInput}>
                <ProfileInput
                  control={control}
                  name="member.id"
                  chipProps={{ onDelete: () => setValue('member.id', '') }}
                  formControlProps={{ variant: 'outlined', size: 'small' }}
                  watch={watch}
                  error={!!errors.member}
                  type="member"
                  orderBy={[{ lastName: OrderBy.ASC }]}
                  where={{
                    events: {
                      client: { id: { _eq: clientId } },
                      startAt: { _gte: subMonths(new Date(), 3) },
                    },
                  }}
                />
              </Box>
            </>
          )}
          <Typography variant="body1">Notes</Typography>
          <Box className={classes.notesInput}>
            <TextField
              multiline
              minRows={2}
              name="notes"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.notes}
              helperText={errors.notes?.message}
              validation={{
                maxLength: {
                  message: 'Exceeding max length of 1000 characters',
                  value: 1000,
                },
              }}
            ></TextField>
          </Box>
        </Box>
      </Box>
    </FormModal>
  );
};

export default ClientCreateObservationSeizureFormModal;

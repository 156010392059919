import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Select } from '@timed/common';
import { NotesContext } from '@timed/notes/context';
import { ChangeEvent, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
      flexGrow: 1,
      width: 150,
      [theme.breakpoints.down('sm')]: {
        '& .MuiSelect-root': {
          fontSize: 12,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.divider,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': {
        padding: theme.spacing(2, 9, 2, 4),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1.75, 8, 1.75, 2),
        },
      },
      '& .MuiTypography-root': {
        fontSize: '1rem',
        [theme.breakpoints.down('sm')]: {
          fontSize: 12,
        },
      },
    },
  }),
);

const NotesControlMatchesKeywordsInput = () => {
  const classes = useStyles();

  const {
    input: { matchesKeywords, setMatchesKeywords },
  } = useContext(NotesContext);

  const { control, getValues, setValue } = useForm<{
    matchesKeywords: string;
  }>({
    defaultValues: { matchesKeywords: matchesKeywords.toString() },
  });

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setTimeout(() => (document.activeElement as HTMLElement).blur(), 0); // Unselect select field
    setMatchesKeywords(event.target.value === 'true');
  };

  useEffect(() => {
    if (getValues('matchesKeywords') !== matchesKeywords.toString())
      setValue('matchesKeywords', matchesKeywords.toString());
  }, [matchesKeywords, getValues, setValue]);

  return (
    <Select
      name="matchesKeywords"
      label="Keyword matching"
      control={control}
      onChange={handleChange}
      className={classes.root}
      formControlProps={{ variant: 'outlined', size: 'small' }}
      items={[
        { label: 'Disabled', value: 'false' },
        { label: 'Matches only', value: 'true' },
      ]}
    />
  );
};

export default NotesControlMatchesKeywordsInput;

import { TextField, TextFieldProps } from '@timed/common';
import { organisationNameRegex } from '@timed/common/regexps';
import { RegisterOptions } from 'react-hook-form';

export const validateOrgPreferredName: RegisterOptions = {
  pattern: { value: organisationNameRegex, message: 'Invalid character(s)' },
  maxLength: { value: 20, message: 'Too long' },
};

const OrgPreferredNameInput = (props: TextFieldProps) => (
  <TextField
    name="preferredName"
    label="Preferred name of organisation"
    type="text"
    validation={validateOrgPreferredName}
    {...props}
  />
);

export default OrgPreferredNameInput;

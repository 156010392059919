import {
  PersonNameInput,
  TextFieldProps,
  transformStringToUppercase,
} from '@timed/common';

const PersonNameLastInput = (props: TextFieldProps) => (
  <PersonNameInput
    label="Last Name"
    transform={transformStringToUppercase}
    {...props}
  />
);

export default PersonNameLastInput;

import { Grid, makeStyles, useTheme } from '@material-ui/core';
import {
  addServerErrors,
  ButtonAsync,
  EmailInput,
  Link,
  PersonNameFirstInput,
  PersonNameLastInput,
  Snackbar,
} from '@timed/common';
import { useCreateUserMutation } from '@timed/gql';
import { UserPasswordInput } from '@timed/user';
import _ from 'lodash';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

type FormData = {
  firstName: string;
  lastName: string;
  identifier: string;
  password: string;
  password2: string;
};

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      '& .MuiInputBase-root': {
        fontSize: 16,
      },
    },
  },
}));

const UserCreateForm = () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [errorText, setErrorText] = useState<string>();
  const [createUser, response] = useCreateUserMutation();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>();
  const parsed = queryString.parse(location.search);
  const redirectTo = (parsed.ref as string) ? (parsed.ref as string) : '/';

  useEffect(
    () =>
      response.error && addServerErrors(response.error, setError, setErrorText),
    [response.error, setError],
  );

  const onSubmit = (values: FormData) => {
    if (values.password !== values.password2) {
      setError('password2', {
        message: 'Passwords do not match.',
        type: 'validate',
      });
    }

    createUser({ variables: { input: _.omit(values, ['password2']) } });
  };

  const onComplete = () => navigate(redirectTo);

  const onSuccess = () =>
    localStorage.setItem('token', response.data!.createUser.token);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Snackbar
        open={!!errorText && response.error}
        severity="error"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {errorText === 'UNIQUE_CONSTRAINT_VIOLATION'
          ? 'A user with this email address already exists. Did you mean to sign-in instead?'
          : errorText}
      </Snackbar>
      <Grid container spacing={4} justify="center">
        <Grid item xs={12} sm={6}>
          <PersonNameFirstInput
            autoFocus
            required
            fullWidth
            name="firstName"
            autoComplete="firstName"
            variant="outlined"
            control={control}
            size="small"
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PersonNameLastInput
            fullWidth
            name="lastName"
            autoComplete="lastName"
            variant="outlined"
            control={control}
            size="small"
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <EmailInput
            required
            fullWidth
            name="identifier"
            variant="outlined"
            size="small"
            control={control}
            error={!!errors.identifier}
            helperText={errors.identifier?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <UserPasswordInput
            required
            autoComplete="new-password"
            name="password"
            fullWidth
            variant="outlined"
            control={control}
            size="small"
            error={!!errors.password}
            helperText={errors.password?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <UserPasswordInput
            required
            autoComplete="new-password"
            name="password2"
            label="Confirm password"
            fullWidth
            variant="outlined"
            control={control}
            size="small"
            error={!!errors.password2}
            helperText={errors.password2?.message}
          />
        </Grid>
        {/* <Grid item xs={11}>
          <Typography variant="caption" color="textSecondary">
            By clicking Join, you agree to our{' '}
            <Link color="textPrimary" to="/policies/terms">
              Terms
            </Link>{' '}
            and{' '}
            <Link color="textPrimary" to="/policies/privacy">
              Privacy Policy
            </Link>
            . You may receive SMS notifications from us and can opt out at any
            time.
          </Typography>
        </Grid> */}
        <Grid item container xs={12}>
          <ButtonAsync
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            loading={response.loading}
            success={!!response.data}
            onComplete={onComplete}
            onSuccess={onSuccess}
          >
            Join
          </ButtonAsync>
        </Grid>
        <Grid
          item
          container
          justify="center"
          style={{ marginTop: theme.spacing(2) }}
        >
          <Link
            to={'/sign-in' + (redirectTo !== '/' ? '?ref=' + redirectTo : '')}
            variant="body1"
          >
            Already have an account? Sign in
          </Link>
        </Grid>{' '}
      </Grid>
    </form>
  );
};

export default UserCreateForm;

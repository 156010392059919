import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useAuth } from '@timed/auth';
import {
  ClientDeleteObservationFormModal,
  ClientIncontinenceTypeInput,
  ClientSeizureTypeInput,
} from '@timed/client';
import { ClientSeizureLostConsciousnessTypeInput } from '@timed/client/components/SeizureLostConsciousnessTypeInput';
import {
  addServerErrors,
  DateInput,
  FormModal,
  IconButton,
  ModalProps,
  NumberInput,
  numberRegex,
  ProfileInput,
  TextField,
  TimeInput,
  transformStringToNumber,
} from '@timed/common';
import {
  Client,
  ClientObservation,
  IncontinenceType,
  Member,
  OrderBy,
  Permission,
  PersonNamesFragment,
  SeizureLostConsciousness,
  SeizureType,
  useUpdateClientObservationMutation,
} from '@timed/gql';
import clsx from 'clsx';
import { isValid, subMonths } from 'date-fns';
import _ from 'lodash';
import { useModal } from 'mui-modal-provider';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

type ClientUpdateObservationSeizureFormModalProps = Omit<
  ModalProps,
  'children'
> & {
  onClose: () => void;
  observation: Pick<
    ClientObservation,
    | 'id'
    | 'date'
    | 'notes'
    | 'seizureDuration'
    | 'postSeizureWalkDuration'
    | 'seizureType'
    | 'seizureLostConsciousness'
    | 'incontinenceType'
  > & {
    client: Pick<Client, 'id'>;
    member?: Pick<Member, 'id'> | null;
    createdBy?: (Pick<Member, 'id'> & PersonNamesFragment) | null;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      justifyItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    items: {
      display: 'grid',
      gridTemplateColumns: 'max-content max-content',
      rowGap: theme.spacing(2),
      columnGap: theme.spacing(4),
      alignItems: 'center',
    },
    input: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 120,
      },
    },
    seizureType: {
      '& .MuiInputBase-root': {
        width: 240 + theme.spacing(2),
      },
    },
    dateInput: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 120,
      },
    },
    notesInput: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 248,
      },
    },
  }),
);

type FormData = {
  patch: {
    date?: Date;
    time?: Date;
    notes?: string | null;
    seizureDuration?: number | null;
    seizureDurationMinutes?: number | null;
    seizureDurationSeconds?: number | null;
    postSeizureWalkDuration?: number | null;
    postSeizureWalkDurationMinutes?: number | null;
    postSeizureWalkDurationHours?: number | null;
    seizureType?: SeizureType | null;
    seizureLostConsciousness?: SeizureLostConsciousness | null;
    incontinenceType?: IncontinenceType | null;
    member?: { id: string } | null;
  };
};

const ClientUpdateObservationSeizureFormModal = ({
  onClose,
  observation,
  ...modalProps
}: ClientUpdateObservationSeizureFormModalProps) => {
  const classes = useStyles();

  const { permissible } = useAuth();

  const { showModal } = useModal();

  const [updateClientObservation, response] =
    useUpdateClientObservationMutation();

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      patch: {
        ..._.pick(observation, [
          'date',
          'notes',
          'seizureDuration',
          'postSeizureWalkDuration',
          'seizureType',
        ]),
        incontinenceType:
          observation.incontinenceType ?? IncontinenceType.NotRecorded,
        seizureLostConsciousness:
          observation.seizureLostConsciousness ??
          SeizureLostConsciousness.NOT_RECORDED,
        member: observation.member
          ? observation.member
          : permissible({ admin: true })
          ? observation.createdBy
          : null,
        time: new Date(observation.date),
      },
    },
  });

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = async ({
    patch: {
      member,
      seizureDurationMinutes,
      seizureDurationSeconds,
      postSeizureWalkDurationMinutes,
      postSeizureWalkDurationHours,
      ...patch
    },
  }: FormData) => {
    patch = _.omit(patch, ['time']);

    updateClientObservation({
      variables: {
        input: {
          id: observation.id,
          patch: {
            ...patch,
            seizureDuration:
              seizureDurationMinutes || seizureDurationSeconds
                ? (seizureDurationMinutes ?? 0) * 60 +
                  (seizureDurationSeconds ?? 0)
                : null,
            postSeizureWalkDuration:
              postSeizureWalkDurationMinutes || postSeizureWalkDurationHours
                ? (postSeizureWalkDurationMinutes ?? 0) +
                  (postSeizureWalkDurationHours ?? 0) * 60
                : null,
            member: member?.id ? { id: member.id } : null,
          },
        },
      },
    });
  };

  const handleChangeTime = useCallback(
    (date?: MaterialUiPickersDate) => {
      if (!!date && isValid(date)) {
        const currentDate = new Date(getValues('patch.date')!);
        currentDate.setHours(date.getHours(), date.getMinutes());

        setValue('patch.date', currentDate);
      }
    },
    [getValues, setValue],
  );

  const handleOpenDeleteClientObservationModal = () => {
    const modal: { hide: () => void } = showModal(
      ClientDeleteObservationFormModal,
      {
        onClose: () => {
          modal.hide();
        },
        closePreviousModal: onClose,
        observation,
      },
    );
  };

  return (
    <FormModal
      modalProps={modalProps}
      title="Edit Seizure"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      extraControls={
        permissible({ permissions: Permission.CLIENT_WRITE }) ? (
          <IconButton
            onClick={() => {
              handleOpenDeleteClientObservationModal();
            }}
          >
            <DeleteRoundedIcon />
          </IconButton>
        ) : undefined
      }
    >
      <Box className={classes.wrapper}>
        <Box className={classes.items}>
          <Typography variant="body1">Time</Typography>
          <Box className={classes.dateInput}>
            <DateInput
              required
              name="patch.date"
              control={control}
              inputVariant="outlined"
              size="small"
              inputProps={{ style: { textAlign: 'center' } }}
              error={!!errors.patch?.date}
              helperText={errors.patch?.date?.message}
            />
            <TimeInput
              required
              keyboard
              name="patch.time"
              control={control}
              inputVariant="outlined"
              size="small"
              onChange={(date) => {
                if (isValid(date)) handleChangeTime(date);
              }}
              inputProps={{ style: { textAlign: 'center' } }}
              error={!!errors.patch?.time}
              helperText={errors.patch?.time?.message}
            />
          </Box>

          <Typography variant="body1">Duration</Typography>
          <Box className={classes.input}>
            <NumberInput
              label="Minutes"
              name="patch.seizureDurationMinutes"
              variant="outlined"
              size="small"
              control={control}
              transform={transformStringToNumber()}
              validation={{ pattern: numberRegex }}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
              defaultValue={
                !observation.seizureDuration
                  ? null
                  : Math.floor(observation.seizureDuration / 60)
              }
            />
            <NumberInput
              label="Seconds"
              name="patch.seizureDurationSeconds"
              variant="outlined"
              size="small"
              control={control}
              transform={transformStringToNumber()}
              validation={{ pattern: numberRegex }}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
              defaultValue={
                !observation.seizureDuration
                  ? null
                  : observation.seizureDuration -
                    Math.floor(observation.seizureDuration / 60) * 60
              }
            />
          </Box>

          <Typography variant="body1">Type</Typography>
          <Box className={clsx(classes.input, classes.seizureType)}>
            <ClientSeizureTypeInput
              name="patch.seizureType"
              control={control}
              error={!!errors.patch?.seizureType}
              helperText={errors.patch?.seizureType?.message}
            />
          </Box>

          <Typography variant="body1">Incontinence</Typography>
          <Box className={clsx(classes.input, classes.seizureType)}>
            <ClientIncontinenceTypeInput
              name="patch.incontinenceType"
              control={control}
              error={!!errors.patch?.incontinenceType}
              helperText={errors.patch?.incontinenceType?.message}
            />
          </Box>

          <Typography variant="body1">Lost consciousness</Typography>
          <Box className={clsx(classes.input, classes.seizureType)}>
            <ClientSeizureLostConsciousnessTypeInput
              name="patch.seizureLostConsciousness"
              control={control}
              error={!!errors.patch?.seizureLostConsciousness}
              helperText={errors.patch?.seizureLostConsciousness?.message}
            />
          </Box>

          <Typography variant="body1">Postictal walk duration</Typography>
          <Box className={classes.input}>
            <NumberInput
              label="Hours"
              name="patch.postSeizureWalkDurationHours"
              variant="outlined"
              size="small"
              control={control}
              transform={transformStringToNumber()}
              validation={{ pattern: numberRegex }}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
              defaultValue={
                !observation.postSeizureWalkDuration
                  ? null
                  : Math.floor((observation.postSeizureWalkDuration ?? 0) / 60)
              }
            />
            <NumberInput
              label="Minutes"
              name="patch.postSeizureWalkDurationMinutes"
              variant="outlined"
              size="small"
              control={control}
              transform={transformStringToNumber()}
              validation={{ pattern: numberRegex }}
              onClick={(event) => {
                (event.target as HTMLInputElement).select();
              }}
              defaultValue={
                !observation.postSeizureWalkDuration
                  ? null
                  : observation.postSeizureWalkDuration -
                    Math.floor(
                      (observation.postSeizureWalkDuration ?? 0) / 60,
                    ) *
                      60
              }
            />
          </Box>

          {permissible({ permissions: Permission.MEMBER_READ }) && (
            <>
              <Typography variant="body1">Support worker</Typography>
              <Box className={classes.notesInput}>
                <ProfileInput
                  control={control}
                  name="patch.member.id"
                  chipProps={{
                    onDelete: () => setValue('patch.member.id', ''),
                  }}
                  formControlProps={{ variant: 'outlined', size: 'small' }}
                  watch={watch}
                  error={!!errors.patch?.member}
                  type="member"
                  orderBy={[{ lastName: OrderBy.ASC }]}
                  where={{
                    _or: [
                      {
                        events: {
                          client: { id: { _eq: observation.client.id } },
                          startAt: { _gte: subMonths(new Date(), 3) },
                        },
                      },
                      {
                        clientObservationsCreated: {
                          client: { id: { _eq: observation.client.id } },
                        },
                      },
                    ],
                  }}
                />
              </Box>
            </>
          )}
          <Typography variant="body1">Notes</Typography>
          <Box className={classes.notesInput}>
            <TextField
              multiline
              minRows={2}
              name="patch.notes"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.patch?.notes}
              helperText={errors.patch?.notes?.message}
              validation={{
                maxLength: {
                  message: 'Exceeding max length of 1000 characters',
                  value: 1000,
                },
              }}
            ></TextField>
          </Box>
        </Box>
      </Box>
    </FormModal>
  );
};

export default ClientUpdateObservationSeizureFormModal;

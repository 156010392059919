import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { blue, green, red, yellow } from '@material-ui/core/colors';
import { _schedule } from '@timed/app';
import { useAuth } from '@timed/auth';
import {
  Avatar,
  formatPersonName,
  roundNumber,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  useRouter,
} from '@timed/common';
import {
  MemberHoursType,
  OrderBy,
  useScheduleMemberInfo2LazyQuery,
} from '@timed/gql';
import { ScheduleMemberInfoGenerateButton } from '@timed/schedule/components/MemberInfoGenerateButton';
import { ScheduleContext } from '@timed/schedule/context';
import { setProfile } from '@timed/schedule/helpers';
import {
  addMinutes,
  addWeeks,
  format,
  formatISO,
  isBefore,
  isSameDay,
  startOfWeek,
} from 'date-fns';
import { useContext, useEffect, useMemo } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    blank: {
      background: `repeating-linear-gradient(135deg, ${theme.palette.background.default}, ${theme.palette.background.default} 8px, ${theme.palette.background.paper} 8px, ${theme.palette.background.paper} 16px);`,
    },
    wrapper: {
      height: '100%',
      flex: '1 1 0',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0),
    },
    triangle: {
      borderLeft: '4px solid transparent',
      borderRight: '4px solid transparent',
      borderBottom: '8px solid ' + blue[500],
      display: 'inline-block',
    },
    circle: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      display: 'inline-block',
      backgroundColor: green[500],
    },
    blackCircle: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      display: 'inline-block',
      backgroundColor: 'black',
    },
    totals: {
      marginLeft: theme.spacing(4),
      fontWeight: theme.typography.fontWeightMedium,
      fontStyle: 'italic',
    },
    thisHeader: {
      '& #pay-all': {
        display: 'none',
      },
      '&:hover': {
        '& #paid': {
          display: 'none',
        },
        '& #pay-all': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'absolute',
          color: theme.palette.primary.main,
        },
      },
    },
    avatar: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: 1,
      fontSize: 8,
    },
  }),
);

const ScheduleMemberInfoList2 = () => {
  const classes = useStyles();

  const { branch, permissible } = useAuth();

  const {
    member,
    showMemberHours,
    dates: { from },
  } = useContext(ScheduleContext);

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  // Array of payroll period dates
  const dates = useMemo<Date[]>(() => {
    const date = startOfWeek(from, { weekStartsOn: 1 });
    return permissible({ tester: true }) && !smDown
      ? [
          date,
          addWeeks(date, 1),
          addWeeks(date, 2),
          addWeeks(date, 3),
          addWeeks(date, 4),
        ]
      : [
          /*date, addWeeks(date, 1), addWeeks(date, 2)*/
        ];
  }, [from, permissible, smDown]);

  const {
    navigate,
    search: [searchParams],
  } = useRouter();

  const [getMembers, { data, previousData, loading }] =
    useScheduleMemberInfo2LazyQuery();

  const memberId = useMemo(() => member.get(), [member]);

  useEffect(() => {
    getMembers({
      pollInterval: 60000,
      nextFetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        hoursInput: {
          where: {
            _or: dates.map((date) => ({
              date: { _eq: formatISO(date, { representation: 'date' }) },
            })),
          },
        },
        membersInput: {
          where: {
            branchMembers: branch
              ? { branch: { id: { _eq: branch.id } } }
              : undefined,
            _or: [
              { schedulable: { _eq: true } },
              {
                events: {
                  client: branch
                    ? { branch: { id: { _eq: branch.id } } }
                    : { id: { _ne: null } },
                },
              },
            ],
          },
          orderBy: [{ lastName: OrderBy.ASC }, { firstName: OrderBy.ASC }],
        },
      },
    });
  }, [branch, dates, getMembers]);

  // Weekly totoals
  const weeklyTotals = useMemo<number[]>(() => {
    if (!data) return dates.map(() => 0);

    const totals: number[] = [];

    dates.forEach((date, i) => {
      const x = data.members.flatMap(({ hours }) =>
        hours
          .filter(({ type }) => type === MemberHoursType.SCHEDULED)
          .filter(({ date: d }) => d === format(date, 'yyyy-MM-dd'))
          .map(({ value }) => value),
      );

      totals[i] = x.length ? x.reduce((a, b) => a + b) : 0;
    });

    return totals;
  }, [data, dates]);

  const handleSetMember = (memberId: string) => {
    setProfile('member', searchParams, memberId);
    navigate(_schedule.path + '?' + searchParams);
  };

  return (
    <div className={classes.wrapper}>
      <Table
        inline
        enableRowHighlighting
        loading={loading}
        size="small"
        // backgroundColor={theme.palette.background.paper}
        wrapperStyle={{ flex: '1 1 0' }}
      >
        <TableHeader align="center"></TableHeader>
        <TableHeader order={OrderBy.ASC}>Support Worker</TableHeader>
        {dates.map((date) => (
          <TableHeader
            align="center"
            hidden={
              !showMemberHours ||
              (!permissible({ tester: true }) &&
                !(permissible({ admin: true }) && branch?.name === 'Sydney'))
            }
            style={{
              backgroundColor: isSameDay(
                date,
                startOfWeek(new Date(), { weekStartsOn: 1 }),
              )
                ? theme.palette.secondary.main
                : undefined,
            }}
          >
            {format(date, 'MMM d')}
          </TableHeader>
        ))}
        <TableHeader
          align="center"
          hidden={
            branch?.name !== 'Sydney' ||
            !showMemberHours ||
            !permissible({ tester: true })
          }
        ></TableHeader>
        {(data || previousData)?.members?.map(
          ({ currentEvent, nextEvent, hours, color, id, ...rest }, i) => {
            const nextShiftStartingSoon =
              !!nextEvent &&
              isBefore(new Date(nextEvent.startAt), addMinutes(new Date(), 90));

            const iconClass = !!currentEvent
              ? !currentEvent.clockedOnAt
                ? classes.blackCircle
                : classes.circle
              : nextShiftStartingSoon
              ? classes.triangle
              : undefined;

            return (
              <TableRow
                key={i}
                highlighted={id === memberId}
                onClick={() => {
                  setProfile('client', searchParams, undefined);
                  handleSetMember(id);
                }}
              >
                <TableCell>
                  {!!currentEvent || nextShiftStartingSoon ? (
                    <Box className={iconClass}></Box>
                  ) : (
                    ''
                  )}
                </TableCell>
                {smDown ? (
                  <TableCell>
                    {formatPersonName(rest, {
                      lastNameFirst: true,
                      preferredAndLast: true,
                      capitaliseLastName: true,
                    })}
                  </TableCell>
                ) : (
                  <TableCell
                    style={{ display: 'flex', gap: 4, alignItems: 'center' }}
                  >
                    <Avatar
                      aria-label="avatar"
                      className={classes.avatar}
                      color={color}
                      content={[rest.firstName, rest.middleName, rest.lastName]}
                    />
                    {formatPersonName(rest, {
                      lastNameFirst: true,
                      preferredAndLast: true,
                      capitaliseLastName: true,
                    })}
                  </TableCell>
                )}

                {dates.map((date, i) => {
                  const scheduled = hours.find(
                    (h) =>
                      h.type === MemberHoursType.SCHEDULED &&
                      h.date === format(date, 'yyyy-MM-dd'),
                  )?.value;

                  let limit: number | null | undefined = hours.find(
                    (h) =>
                      h.type === MemberHoursType.MAX_LIMIT &&
                      h.date === format(date, 'yyyy-MM-dd'),
                  )?.value;

                  if (limit === null) limit = Infinity;

                  return (
                    <TableCell
                      key={i}
                      className={
                        limit === undefined && scheduled === undefined
                          ? classes.blank
                          : undefined
                      }
                      style={{
                        backgroundColor:
                          limit !== undefined && scheduled !== undefined
                            ? scheduled > limit
                              ? red[500]
                              : scheduled > limit * 0.8
                              ? yellow[500]
                              : undefined
                            : undefined,
                        color:
                          limit !== undefined && scheduled !== undefined
                            ? scheduled > limit
                              ? theme.palette.common.white
                              : undefined
                            : undefined,
                      }}
                    >
                      {(scheduled !== undefined || limit !== undefined) &&
                        roundNumber((scheduled ?? 0) / 60, 1)}
                      {(scheduled !== undefined || limit !== undefined) &&
                        ' / '}
                      {limit !== undefined &&
                        (limit === Infinity ? '∞' : roundNumber(limit / 60, 1))}
                    </TableCell>
                  );
                })}
                <TableCell>
                  <ScheduleMemberInfoGenerateButton memberId={id} />
                </TableCell>
              </TableRow>
            );
          },
        )}

        <TableRow hidden={!permissible({ tester: true })}>
          <TableCell></TableCell>
          <TableCell>
            <span className={classes.totals}>Totals</span>
          </TableCell>
          {weeklyTotals.map((total) => (
            <TableCell className={classes.bold}>
              {roundNumber(total / 60, 1)}
            </TableCell>
          ))}
        </TableRow>
      </Table>
      <Table
        size="small"
        border="line"
        backgroundColor={theme.palette.background.paper}
        wrapperStyle={{ flexGrow: 0 }}
      >
        <TableHeader></TableHeader>
        <TableHeader colSpan={2} style={{ width: 'auto' }}>
          Legend
        </TableHeader>
        <TableRow>
          <TableCell>
            <Box className={classes.circle}></Box>
          </TableCell>
          <TableCell>On shift</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Box className={classes.blackCircle}></Box>
          </TableCell>
          <TableCell>Not clocked on</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Box className={classes.triangle}></Box>
          </TableCell>
          <TableCell>Upcoming shift</TableCell>
        </TableRow>
      </Table>
    </div>
  );
};

export default ScheduleMemberInfoList2;

import {
  Box,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { BoxProps } from '@mui/material';
import { contrastingColor, hexToRGB } from '@timed/common';

type StartInfoBoxProps = Pick<BoxProps, 'onClick'> & {
  color: CSSProperties['color'];
  title: string;
  value: string | number;
};

const StartInfoBox = ({
  color = '#fff',
  title,
  value,
  ...props
}: StartInfoBoxProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      wrapper: {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        flexBasis: `calc(50% - ${theme.spacing(0.5)}px)`,
        padding: 16,
        backgroundColor: color,
        [theme.breakpoints.up('md')]: {
          height: 192,
          flexBasis: 192,
        },
      },
      title: {
        color: contrastingColor(hexToRGB(color))!,
        fontSize: 16,
        fontFamily: 'monospace',
        fontWeight: theme.typography.fontWeightBold,
        [theme.breakpoints.up('md')]: {
          fontSize: 18,
        },
      },
      value: {
        color: contrastingColor(hexToRGB(color))!,
        fontSize: 32,
        fontFamily: 'monospace',
        fontWeight: theme.typography.fontWeightBold,
        [theme.breakpoints.up('md')]: {
          fontSize: 48,
        },
      },
    }),
  );

  const classes = useStyles();

  return (
    <Box {...props} className={classes.wrapper}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.value}>{value.toString()}</Typography>
    </Box>
  );
};

export default StartInfoBox;

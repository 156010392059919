import {
  Grid,
  Link as MuiLink,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CallMadeRoundedIcon from '@material-ui/icons/CallMadeRounded';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Protected } from '@timed/auth';
import {
  AddressBuildingNameInput,
  AddressInput,
  AddressUnitInput,
  Block,
  DateInput,
  EmailInput,
  FormattedAddress,
  GenderInput,
  IconButtonMulti,
  Link,
  PersonBioInput,
  PersonNameFirstInput,
  PersonNameLastInput,
  PersonNameMiddleInput,
  PersonNamePreferredInput,
  PhoneInput,
  PronounsInput,
  SalutationInput,
  TextField,
  addServerErrors,
  formatAddress,
  formatAge,
  formatPronouns,
} from '@timed/common';
import {
  Address,
  Gender,
  HistoryRestorable,
  Member,
  Permission,
  Pronouns,
  Salutation,
  useUpdateMembersMutation,
} from '@timed/gql';
import { isMember } from '@timed/member';
import { format, subYears } from 'date-fns';
import _, { camelCase, isEqual, pick, startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type MemberUpdatePersonalDetailsFormProps = {
  member: Pick<
    Member,
    | 'id'
    | 'firstName'
    | 'middleName'
    | 'lastName'
    | 'preferredName'
    | 'email'
    | 'phone'
    | 'bio'
    | 'dob'
    | 'gender'
    | 'salutation'
    | 'pronouns'
    | 'study'
    | 'unit'
    | 'buildingName'
    | 'unit'
    | 'street'
    | 'locality'
    | 'region'
    | 'postcode'
    | 'country'
    | 'latitude'
    | 'longitude'
  > & { archive?: Pick<HistoryRestorable, 'id'> | null };
};

type FormData = {
  patch: Pick<
    Member,
    | 'firstName'
    | 'lastName'
    | 'middleName'
    | 'preferredName'
    | 'email'
    | 'phone'
    | 'bio'
    | 'dob'
    | 'gender'
    | 'salutation'
    | 'pronouns'
    | 'study'
    | 'unit'
    | 'buildingName'
  > & {
    address: Pick<
      Address,
      | 'street'
      | 'locality'
      | 'region'
      | 'postcode'
      | 'country'
      | 'latitude'
      | 'longitude'
    > | null;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      height: 18,
    },
    addressFields: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      gap: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: '96px 288px auto',
        gap: theme.spacing(4),
      },
    },
  }),
);

const MemberUpdatePersonalDetailsForm = ({
  member,
}: MemberUpdatePersonalDetailsFormProps) => {
  const classes = useStyles();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [editing, setEditing] = useState<boolean>(false);

  const [updateMembers, response] = useUpdateMembersMutation();

  const defaultValues: FormData = {
    patch: {
      firstName: member.firstName,
      middleName: member.middleName || '',
      lastName: member.lastName || '',
      preferredName: member.preferredName || '',
      email: member.email || '',
      phone: member.phone || '',
      bio: member.bio || '',
      dob: member.dob,
      gender: member.gender || Gender.NOTSPECIFIED,
      salutation: member.salutation || Salutation.NOTSPECIFIED,
      pronouns: member.pronouns || Pronouns.NOTSPECIFIED,
      study: member.study || '',
      unit: member.unit || '',
      buildingName: member.buildingName || '',
      address:
        ((member.street ||
          member.locality ||
          member.region ||
          member.postcode ||
          member.country ||
          member.latitude ||
          member.longitude) &&
          pick(member, [
            'street',
            'locality',
            'region',
            'postcode',
            'country',
            'latitude',
            'longitude',
          ])) ||
        null,
    },
  };

  const {
    handleSubmit,
    watch,
    control,
    setError,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });

  const currentValues = watch();

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = (values: FormData) => {
    // To avoid graphql mutation errors, delete location object if it is empty
    if (
      !values.patch.address ||
      Object.keys(values.patch.address).length === 0
    ) {
      values.patch.address = {
        street: null,
        locality: null,
        region: null,
        postcode: null,
        country: null,
        latitude: null,
        longitude: null,
      };
    }

    updateMembers({
      variables: {
        input: {
          ids: [member.id],
          patch: {
            ...values.patch.address,
            ..._.omit(values.patch, ['address']),
          },
        },
      },
    }).catch((e) => {});
    reset(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block
        title="Personal Details"
        topRight={
          !member.archive ? (
            <Protected permissions={Permission.MEMBER_WRITE}>
              <IconButtonMulti
                enabled={editing}
                changed={!isEqual(currentValues, defaultValues)}
                setEditing={setEditing}
                loading={response.loading}
                success={!!response.data}
              />
            </Protected>
          ) : undefined
        }
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Salutation</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.salutation !== Salutation.NOTSPECIFIED &&
                startCase(camelCase(member.salutation || undefined))
              ) : (
                <SalutationInput
                  fullWidth
                  name="patch.salutation"
                  control={control}
                  error={!!errors.patch?.salutation}
                  helperText={errors.patch?.salutation?.message}
                  disabled={!editing}
                  formControlProps={{
                    size: 'small',
                    variant: 'outlined',
                  }}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>First name</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.firstName
              ) : (
                <PersonNameFirstInput
                  fullWidth
                  required
                  name="patch.firstName"
                  variant="outlined"
                  size="small"
                  control={control}
                  error={!!errors.patch?.firstName}
                  helperText={errors.patch?.firstName?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Middle name</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.middleName
              ) : (
                <PersonNameMiddleInput
                  fullWidth
                  name="patch.middleName"
                  variant="outlined"
                  size="small"
                  control={control}
                  error={!!errors.patch?.middleName}
                  helperText={errors.patch?.middleName?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Last name</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.lastName?.toUpperCase()
              ) : (
                <PersonNameLastInput
                  fullWidth
                  name="patch.lastName"
                  variant="outlined"
                  size="small"
                  control={control}
                  error={!!errors.patch?.lastName}
                  helperText={errors.patch?.lastName?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Preferred name</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.preferredName
              ) : (
                <PersonNamePreferredInput
                  fullWidth
                  name="patch.preferredName"
                  variant="outlined"
                  size="small"
                  control={control}
                  error={!!errors.patch?.preferredName}
                  helperText={errors.patch?.preferredName?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Email</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                <Link to={'mailto:' + member.email}>{member.email}</Link>
              ) : (
                <EmailInput
                  fullWidth
                  name="patch.email"
                  variant="outlined"
                  size="small"
                  control={control}
                  error={!!errors.patch?.email}
                  helperText={errors.patch?.email?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Phone</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.phone && (
                  <MuiLink href={'tel:' + member.phone.replace(/\s+/g, '')}>
                    {member.phone}
                  </MuiLink>
                )
              ) : (
                <PhoneInput
                  fullWidth
                  name="patch.phone"
                  variant="outlined"
                  size="small"
                  control={control}
                  error={!!errors.patch?.phone}
                  helperText={errors.patch?.phone?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          {isMember(member) && (
            <>
              <Grid item xs={4} md={3} lg={2}>
                <Typography>Date of birth</Typography>
              </Grid>
              <Grid item xs={8} md={9} lg={10}>
                <Typography>
                  {!editing ? (
                    member.dob &&
                    format(new Date(member.dob), 'd MMM yyyy') +
                      ` (${formatAge(member.dob)} years old)`
                  ) : (
                    <DateInput
                      keyboard
                      clearable
                      disableFuture
                      disableTime
                      openTo="year"
                      name="patch.dob"
                      initialFocusedDate={
                        new Date().getFullYear() - 18 + '-1-1'
                      }
                      control={control}
                      inputVariant="outlined"
                      size="small"
                      error={!!errors.patch?.dob}
                      helperText={errors.patch?.dob?.message}
                      disabled={!editing}
                      maxDate={new Date()}
                      minDate={subYears(new Date(), 120)}
                    />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4} md={3} lg={2}>
                <Typography>Gender</Typography>
              </Grid>
              <Grid item xs={8} md={9} lg={10}>
                <Typography>
                  {!editing ? (
                    member.gender !== Gender.NOTSPECIFIED &&
                    startCase(camelCase(member.gender || undefined))
                  ) : (
                    <GenderInput
                      fullWidth
                      name="patch.gender"
                      control={control}
                      error={!!errors.patch?.gender}
                      helperText={errors.patch?.gender?.message}
                      disabled={!editing}
                      formControlProps={{
                        size: 'small',
                        variant: 'outlined',
                      }}
                    />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4} md={3} lg={2}>
                <Typography>Pronouns</Typography>
              </Grid>
              <Grid item xs={8} md={9} lg={10}>
                <Typography>
                  {!editing ? (
                    formatPronouns(member.pronouns || undefined, true)
                  ) : (
                    <PronounsInput
                      fullWidth
                      name="patch.pronouns"
                      control={control}
                      error={!!errors.patch?.pronouns}
                      helperText={errors.patch?.pronouns?.message}
                      disabled={!editing}
                      formControlProps={{
                        size: 'small',
                        variant: 'outlined',
                      }}
                    />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4} md={3} lg={2}>
                <Typography>Residential address</Typography>
              </Grid>
              <Grid item xs={8} md={9} lg={10}>
                <Typography>
                  {!editing ? (
                    <Link
                      to={
                        'https://www.google.com/maps/search/' +
                        formatAddress(member)
                      }
                      className={classes.link}
                    >
                      <FormattedAddress showPostcode {...member} />
                      <CallMadeRoundedIcon style={{ width: 10 }} />
                    </Link>
                  ) : (
                    <div className={classes.addressFields}>
                      <AddressUnitInput
                        name="patch.unit"
                        control={control}
                        error={!!errors.patch?.unit}
                        helperText={errors.patch?.unit?.message}
                        disabled={!editing}
                        variant="outlined"
                        size="small"
                      />
                      <AddressBuildingNameInput
                        name="patch.buildingName"
                        control={control}
                        error={!!errors.patch?.buildingName}
                        helperText={errors.patch?.buildingName?.message}
                        disabled={!editing}
                        variant="outlined"
                        size="small"
                      />
                      <AddressInput
                        name="patch.address"
                        control={control}
                        clearErrors={clearErrors}
                        setValue={setValue}
                        setError={setError}
                        error={!!errors.patch?.address}
                        // helperText={errors.patch?.address?.message}
                        placeholder={'Residential address'}
                        isDisabled={!editing}
                        style={{ gridColumn: smDown ? 'span 2' : undefined }}
                      />
                    </div>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4} md={3} lg={2}>
                <Typography>Study</Typography>
              </Grid>
              <Grid item xs={8} md={9} lg={10}>
                <Typography>
                  {!editing ? (
                    member.study
                  ) : (
                    <TextField
                      type="text"
                      fullWidth
                      validation={{ maxLength: 255 }}
                      name="patch.study"
                      variant="outlined"
                      size="small"
                      control={control}
                      error={!!errors.patch?.study}
                      helperText={errors.patch?.study?.message}
                      disabled={!editing}
                    />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4} md={3} lg={2}>
                <Typography>Bio</Typography>
              </Grid>
              <Grid item xs={8} md={9} lg={10}>
                <Typography>
                  {!editing ? (
                    member.bio
                  ) : (
                    <PersonBioInput
                      fullWidth
                      name="patch.bio"
                      variant="outlined"
                      size="small"
                      control={control}
                      error={!!errors.patch?.bio}
                      helperText={errors.patch?.bio?.message}
                      disabled={!editing}
                    />
                  )}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Block>
    </form>
  );
};

export default MemberUpdatePersonalDetailsForm;

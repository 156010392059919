import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import { Table, TableCell, TableHeader, TableRow } from '@timed/common';
import { QueryByIdInput } from '@timed/gql';
import { useModal } from 'mui-modal-provider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(8),
      alignItems: 'start',
    },
    table: {
      display: 'grid',
      gridTemplateColumns: 'repeat(8, auto)',
      columnGap: theme.spacing(8),
      rowGap: theme.spacing(2),
      alignItems: 'center',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(8),
      alignItems: 'start',
    },
    link: {
      color: theme.palette.primary.main,
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
    actions: {
      display: 'flex',
      gap: theme.spacing(2),
    },
    highligh: {
      backgroundColor: yellow[200],
    },
  }),
);

const UploadFormsTable = () => {
  const classes = useStyles();

  const { showModal } = useModal();

  // const { data, loading } = useGetMemberUploadsQuery({
  //   variables: {
  //     input: {
  //       where: { processingState: { _eq: ProcessingState.PENDING } },
  //       orderBy: [
  //         { uploadedFor: { lastName: OrderBy.ASC } },
  //         { file: { size: OrderBy.ASC } },
  //         { createdAt: OrderBy.ASC },
  //       ],
  //     },
  //   },
  // });

  // useLoadingEffect(loading);

  const handleOpenFormModal = (form: QueryByIdInput) => {
    // const modal: { hide: () => void } = showModal(UploadProcessModal, {
    //   onClose: () => {
    //     modal.hide();
    //   },
    //   form
    // });
  };

  enum FormCategory {
    NDIS_PARTICIPANT,
    SUPPORT_WORKER,
  }

  const forms = [
    {
      id: '0',
      title: 'Participant Care Plan',
      category: FormCategory.NDIS_PARTICIPANT,
    },
  ];

  return (
    <Table inline>
      <TableHeader>Title</TableHeader>
      {forms.map((form) => (
        <TableRow
          onClick={() => {
            handleOpenFormModal({ id: form.id });
          }}
        >
          <TableCell>{form.title}</TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

export default UploadFormsTable;

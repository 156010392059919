import { Timezone } from '@timed/gql';

export const TimezoneLabels: { value: Timezone; label: string }[] = [
  { value: Timezone.AU_ADELAIDE, label: 'Australia/Adelaide' },
  { value: Timezone.AU_BRISBANE, label: 'Australia/Brisbane' },
  { value: Timezone.AU_BROKEN_HILL, label: 'Australia/Broken Hill' },
  { value: Timezone.AU_DARWIN, label: 'Australia/Darwin' },
  { value: Timezone.AU_EUCLA, label: 'Australia/Eucla' },
  { value: Timezone.AU_HOBART, label: 'Australia/Hobart' },
  { value: Timezone.AU_LINDEMAN, label: 'Australia/Lindeman' },
  { value: Timezone.AU_LORD_HOWE, label: 'Australia/Lord Howe' },
  { value: Timezone.AU_MELBOURNE, label: 'Australia/Melbourne' },
  { value: Timezone.AU_PERTH, label: 'Australia/Perth' },
  { value: Timezone.AU_SYDNEY, label: 'Australia/Sydney' },
];

import { _peopleClients } from '@timed/app';
import { ClientList, ClientListProvider } from '@timed/client';
import { Page } from '@timed/page';

const RouteCoreSupportPeopleParticipants = () => {
  return (
    <Page {..._peopleClients}>
      <ClientListProvider>
        <ClientList />
      </ClientListProvider>
    </Page>
  );
};

export default RouteCoreSupportPeopleParticipants;

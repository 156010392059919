import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  addServerErrors,
  DateInput,
  FormModal,
  ModalProps,
  PostNominalsInput,
} from '@timed/common';
import {
  MemberPostNominal,
  UpdateMemberPostNominalInput,
  useUpdateMemberPostNominalMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type MemberUpdatePostNominalFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  postNominal: Pick<MemberPostNominal, 'id' | 'date' | 'value'>;
};

type FormData = UpdateMemberPostNominalInput;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputs: {
      flex: '1 0 auto',
      overflowY: 'auto',
      display: 'grid',
      gridAutoFlow: 'row',
      gridAutoRows: 'max-content',
      gap: theme.spacing(4),
    },
  }),
);

const MemberUpdatePostNominalFormModal = ({
  postNominal,
  onClose,
  ...modalProps
}: MemberUpdatePostNominalFormModalProps) => {
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      patch: {
        date: postNominal.date,
        value: postNominal.value,
      },
    },
  });

  const [updateEntity, response] = useUpdateMemberPostNominalMutation();

  useLoadingEffect(response.loading);

  const onSubmit = ({ patch }: FormData) => {
    updateEntity({
      variables: {
        input: {
          id: postNominal.id,
          patch,
        },
      },
    }).catch((e) => {});
  };

  /**
   * Display error messages from server response
   */
  useEffect(() => {
    if (response.error) addServerErrors(response.error, setError);
  }, [response.error, setError]);

  return (
    <FormModal
      modalProps={modalProps}
      title="Update Post-nominals"
      saveText="Save"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
    >
      <Box className={classes.inputs}>
        {response.error && (
          <Typography color="error">{response.error.message}</Typography>
        )}
        <DateInput
          required
          keyboard
          disableTime
          label="Effective-from date"
          name="patch.date"
          control={control}
          inputVariant="outlined"
          size="small"
          error={!!errors.patch?.date}
          helperText={errors.patch?.date?.message}
        />
        <PostNominalsInput
          required
          control={control}
          name="patch.value"
          variant="outlined"
          size="small"
          error={!!errors.patch?.value}
          helperText={errors.patch?.value?.message}
        />
      </Box>
    </FormModal>
  );
};

export default MemberUpdatePostNominalFormModal;

import {
  clientContactRelationsMetadata,
  validateContactRelation,
} from '@timed/client';
import { intersperse, Select, SelectProps } from '@timed/common';
import { ClientContactRelation } from '@timed/gql';

type ClientContactTypeInputProps = Omit<SelectProps, 'items'>;

const ClientContactTypeInput = ({
  defaultValue,
  ...props
}: ClientContactTypeInputProps) => (
  <Select
    multiple
    label="Relation(s)"
    validation={validateContactRelation}
    items={Object.values(ClientContactRelation).map((value) => ({
      value,
      label:
        clientContactRelationsMetadata.find(({ id }) => id === value)?.name ??
        '',
    }))}
    renderValue={(values) =>
      !!(values as ClientContactRelation[]).length
        ? intersperse({
            array: (values as ClientContactRelation[]).map(
              (value) =>
                clientContactRelationsMetadata.find(({ id }) => id === value)
                  ?.name ?? '',
            ),
          })
        : []
    }
    {...props}
  />
);

export default ClientContactTypeInput;

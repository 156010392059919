import { useTheme } from '@material-ui/core';
import { ClientNdisPlanItemTransactionContext } from '@timed/client';
import NdisPlanItemTransactionSupplierAutocomplete from '@timed/client/components/NdisPlanItemTransactionSupplierAutocomplete/NdisPlanItemTransactionSupplierAutocomplete';
import { OrderBy } from '@timed/gql';
import { useContext, useEffect } from 'react';

const ClientNdisPlanItemTransactionSupplierAutocomplete = () => {
  const theme = useTheme();

  const {
    input: { form, supplierId, setSupplierId },
  } = useContext(ClientNdisPlanItemTransactionContext);

  const supplier = form.watch('supplier');

  useEffect(() => {
    if (supplierId !== supplier?.id) setSupplierId(supplier?.id ?? null);
  }, [supplier, supplierId, setSupplierId]);

  return (
    <NdisPlanItemTransactionSupplierAutocomplete
      inputProps={{
        ...form.register(`supplier` as const),
        control: form.control,
        size: 'small',
        style: {
          width: 300,
          backgroundColor: theme.palette.background.default,
        },
      }}
      orderBy={[{ name: OrderBy.ASC }]}
    />
  );
};

export default ClientNdisPlanItemTransactionSupplierAutocomplete;

import {
  Box,
  Container,
  createStyles,
  Divider,
  Hidden,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { Protected } from '@timed/auth';
import { Avatar, Button, formatPersonName, useRouter } from '@timed/common';
import {
  OrderBy,
  Permission,
  SupportCoordinator,
  useGetSupportCoordinatorsQuery,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import {
  SupportCoordinatorCreateFormModal,
  SupportCoordinatorListSearch,
} from '@timed/support-coordinator';
import { useModal } from 'mui-modal-provider';
import { useMemo, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    controls: {
      display: 'flex',
      gap: theme.spacing(4),
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(8),
      },
    },
    root: {
      overflowY: 'scroll',
    },
    wrapper: {
      display: 'grid',
      // marginBottom: theme.spacing(4),
    },
    paper: {
      borderRadius: theme.shape.borderRadius,
      flex: '1 1 auto',
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(8),
      },
    },
    profile: {
      // borderRadius: theme.shape.borderRadius,
      cursor: 'pointer',
      padding: theme.spacing(2),
      display: 'grid',
      gridTemplateColumns: 'max-content auto max-content',
      alignItems: 'center',
      gap: theme.spacing(2),
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    name: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'grid',
      gap: theme.spacing(0),
    },
    avatar: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      fontSize: 12,
    },
    character: {
      fontWeight: theme.typography.fontWeightMedium,
      margin: theme.spacing(4, 0, 2, 0),
    },
    chips: {
      display: 'inline-grid',
      gridAutoColumns: 'max-content',
      justifyItems: 'flex-end',
      gap: theme.spacing(2),
    },
    checklist: {
      padding: theme.spacing(1, 2),
      display: 'grid',
      gridAutoColumns: 'max-content',
      justifyItems: 'flex-end',
      gap: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.secondary.main,
    },
    check: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  }),
);

const SupportCoordinatorList = () => {
  const classes = useStyles();
  const { navigate } = useRouter();
  const [searchTerm, setSearchTerm] = useState<string>();
  const { data, loading } = useGetSupportCoordinatorsQuery({
    variables: {
      input: {
        orderBy: [{ lastName: OrderBy.ASC }, { createdAt: OrderBy.ASC }],
      },
    },
  });
  const { showModal } = useModal();

  useLoadingEffect(loading);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleOpenModal = () => {
    const modal: { hide: () => void } = showModal(
      SupportCoordinatorCreateFormModal,
      {
        onClose: () => modal.hide(),
      },
    );
  };

  const handleNavigate =
    (supportCoordinatorId: SupportCoordinator['id']) =>
    (_: React.KeyboardEvent | React.MouseEvent) =>
      navigate(supportCoordinatorId);

  const supportCoordinators = useMemo(() => {
    if (data?.supportCoordinators) {
      // const clients = [...data?.clients];

      // Sort profiles in alphabetical order
      // data?.clients?.sort((previous, current) => {
      //   if (previous.lastName || current.lastName) {
      //     if (previous.lastName && current.lastName && previous.lastName !== current.lastName) {
      //       return previous.lastName.localeCompare(current.lastName);
      //     }

      //     return previous.lastName ? -1 : 1;
      //   }

      //   if (previous.firstName !== current.firstName) {
      //     return previous.firstName.localeCompare(current.firstName);
      //   }

      //   return isBefore(previous.lastActiveAt, current.lastActiveAt) ? -1 : 1;
      // });

      // Filter by search term
      return searchTerm
        ? data.supportCoordinators?.filter(
            (c) =>
              formatPersonName(c, { middle: true })
                .toLowerCase()
                .includes(searchTerm) ||
              formatPersonName(c, { preferred: true })
                .toLowerCase()
                .includes(searchTerm),
          )
        : data.supportCoordinators;
    }
  }, [data, searchTerm]);

  const characters: string[] = useMemo(() => {
    const characters: string[] = [];

    // Build characters array
    supportCoordinators?.forEach((c) => {
      if (
        !characters.includes(
          c.lastName?.charAt(0).toUpperCase() ||
            c.firstName?.charAt(0).toUpperCase(),
        )
      ) {
        characters.push(
          c.lastName?.charAt(0).toUpperCase() ||
            c.firstName?.charAt(0).toUpperCase(),
        );
      }
    });

    return characters.sort((a, b) => a.localeCompare(b));
  }, [supportCoordinators]);

  if (loading || !data) return <>Loading...</>;

  return (
    <>
      <Box className={classes.controls}>
        <SupportCoordinatorListSearch handleSearch={handleSearch} />
        <Protected permissions={Permission.CLIENT_WRITE}>
          <Box>
            <Button
              onClick={() => handleOpenModal()}
              variant="contained"
              color="primary"
            >
              Create
            </Button>
          </Box>
        </Protected>
      </Box>

      {supportCoordinators && supportCoordinators.length > 0 ? (
        <Box className={classes.root}>
          {characters.map((character) => (
            <Box className={classes.wrapper}>
              <Box key={character} className={classes.character}>
                {character}
              </Box>
              <Paper className={classes.paper}>
                {supportCoordinators
                  ?.filter(
                    (s) =>
                      (s.lastName?.charAt(0).toUpperCase() ||
                        s.firstName?.charAt(0).toUpperCase()) === character,
                  )
                  .map((s, i) => (
                    <>
                      {i !== 0 && <Divider />}
                      <Box
                        className={classes.profile}
                        onClick={handleNavigate(s.id)}
                      >
                        <Avatar
                          aria-label="avatar"
                          className={classes.avatar}
                          color="FFFFFF"
                          content={[s.firstName, s.middleName, s.lastName]}
                        />
                        <Box className={classes.name}>
                          <Typography color="textPrimary">
                            {formatPersonName(s, {
                              lastNameFirst: true,
                              capitaliseLastName: true,
                            })}
                          </Typography>

                          <Typography variant="body2" color="textSecondary">
                            {s.businessName}
                          </Typography>
                        </Box>

                        <Hidden smDown>
                          <Box className={classes.chips}>
                            {s.archive && (
                              <Box className={classes.check}>
                                <Typography noWrap variant="body2">
                                  Archived
                                </Typography>
                                <LockIcon fontSize="small" />
                              </Box>
                            )}
                          </Box>
                        </Hidden>
                      </Box>
                    </>
                  ))}
              </Paper>
            </Box>
          ))}
        </Box>
      ) : (
        <Container>No results</Container>
      )}
    </>
  );
};

export default SupportCoordinatorList;

import { ButtonProps } from '@material-ui/core';
import { Button } from '@timed/common';

const EventSetToDefaultDurationButton = ({ ...props }: ButtonProps) => {
  return (
    <Button
      variant="contained"
      style={{ fontSize: 11, lineHeight: 1.273 }}
      {...props}
    >
      Default Duration
    </Button>
  );
};

export default EventSetToDefaultDurationButton;

import { TextField, TextFieldProps, TransformInput } from '@timed/common';
import { bankBsbRegex } from '@timed/common/regexps/bankBsb';
import { RegisterOptions } from 'react-hook-form';

export const validateBankBsb: RegisterOptions = {
  pattern: {
    value: bankBsbRegex,
    message: 'Invalid character(s)',
  },
  minLength: { value: 7, message: 'Too Short' },
  maxLength: { value: 7, message: 'Too long' },
};

export const transformBankBsb: TransformInput = {
  input: (value: string) => value,
  output: ({ target: { value } }) => {
    const parts = value.split('-');

    if (parts.length === 2) {
      if (!!parts[1].length) {
        return parts[0] + '-' + parts[1].slice(0, 3);
      } else {
        return parts[0];
      }
    }

    return value.length === 4
      ? value.slice(0, 3) + '-' + value.slice(3)
      : value;
  },
};

const BankBsbInput = (props: TextFieldProps) => (
  <TextField
    type="text"
    validation={validateBankBsb}
    transform={transformBankBsb}
    {...props}
  />
);

export default BankBsbInput;

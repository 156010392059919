import { IconButton, useTheme } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { Typography } from '@mui/material';
import { _peopleMembers } from '@timed/app';
import {
  Block,
  Link,
  Modal,
  ModalProps,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  formatPersonName,
} from '@timed/common';
import {
  HistoryType,
  OrderBy,
  QueryByIdInput,
  useGetClientNdisPlanItemHistoriesQuery,
} from '@timed/gql';
import { format } from 'date-fns';
import _ from 'lodash';

type ClientNdisPlanItemHistoryModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  clientNdisPlanItem: QueryByIdInput;
};

const ClientNdisPlanItemHistoryModal = ({
  onClose,
  clientNdisPlanItem,
  ...modalProps
}: ClientNdisPlanItemHistoryModalProps) => {
  const theme = useTheme();

  const { data, loading } = useGetClientNdisPlanItemHistoriesQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        where: { clientNdisPlanItem: { id: { _eq: clientNdisPlanItem.id } } },
        orderBy: [{ date: OrderBy.DESC }],
        limit: 100,
      },
    },
  });

  return (
    <Modal disablePortal onClose={onClose} {...modalProps}>
      <Block
        titleProps={{ variant: 'h3' }}
        title="Ndis Plan Item History"
        topRight={
          <IconButton onClick={onClose}>
            <CloseRoundedIcon />
          </IconButton>
        }
      >
        <Table loading={loading}>
          <TableHeader>Date</TableHeader>
          <TableHeader>Actor</TableHeader>
          <TableHeader>Action</TableHeader>
          <TableHeader>Property</TableHeader>
          <TableHeader>Old Value</TableHeader>
          <TableHeader>New Value</TableHeader>
          {!data?.histories?.length ? (
            <TableRow>
              <TableCell
                colSpan={6}
                style={{ backgroundColor: theme.palette.divider }}
              >
                <Typography>No historical records exist.</Typography>
              </TableCell>
            </TableRow>
          ) : (
            data?.histories?.map((history, i) => {
              let fieldName: string = '';
              let newValue: any = null;
              let oldValue: any = null;

              if (history.type === HistoryType.UPDATE) {
                const values = Object.entries(
                  _.omit(history, [
                    '__typename',
                    'id',
                    'date',
                    'actor',
                    'type',
                    'clientNdisPlanItem',
                  ]),
                );

                fieldName = history.__typename!.slice(25);

                if (!!values[0] && !!values[0][1]) newValue = values[0][1];
                if (!!values[1] && !!values[1][1]) oldValue = values[1][1];
              }

              return (
                <>
                  {(history as any).restoredAt && (
                    <TableRow key={i}>
                      <TableCell>
                        {format(
                          new Date((history as any).restoredAt),
                          'dd/MM/yyyy HH:mm',
                        )}
                      </TableCell>
                      <TableCell>
                        {!!history.actor ? (
                          <Link
                            to={_peopleMembers.path + '/' + history.actor.id}
                          >
                            {formatPersonName(history.actor, {
                              capitaliseLastName: true,
                              lastNameFirst: true,
                            })}
                          </Link>
                        ) : (
                          'System'
                        )}
                      </TableCell>
                      <TableCell>Restored</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                  <TableRow key={i}>
                    <TableCell>
                      {format(new Date(history.date), 'dd/MM/yyyy HH:mm')}
                    </TableCell>
                    <TableCell>
                      {!!history.actor ? (
                        <Link to={_peopleMembers.path + '/' + history.actor.id}>
                          {formatPersonName(history.actor, {
                            capitaliseLastName: true,
                            lastNameFirst: true,
                          })}
                        </Link>
                      ) : (
                        'System'
                      )}
                    </TableCell>
                    <TableCell>
                      {history.type === HistoryType.UPDATE
                        ? 'Updated'
                        : 'Deleted'}
                    </TableCell>
                    <TableCell>{fieldName}</TableCell>
                    <TableCell>{oldValue}</TableCell>
                    <TableCell>{newValue}</TableCell>
                  </TableRow>
                </>
              );
            })
          )}
        </Table>
      </Block>
    </Modal>
  );
};

export default ClientNdisPlanItemHistoryModal;

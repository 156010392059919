import { Checkbox, CheckboxProps } from '@timed/common';

const ClientAllowObservationsCheckbox = (props: CheckboxProps) => (
  <Checkbox
    defaultChecked={false}
    label="Enable the recording of observations"
    {...props}
  />
);

export default ClientAllowObservationsCheckbox;

import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  _peopleMembers,
  _shifts,
  _shiftsAdminMissingNotes,
  _shiftsAdminMissingSupportWorker,
  _shiftsAdminTravel,
  _shiftsPersonalClaim,
  _shiftsPersonalGrab,
  _shiftsPersonalUpcoming,
} from '@timed/app';
import { useAuth } from '@timed/auth';
import { NavLinks } from '@timed/common';
import { Page } from '@timed/page';
import { Outlet } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
    },
  }),
);

const RouteCoreSupportShifts = () => {
  const classes = useStyles();

  const { member } = useAuth();

  return (
    <Page {..._shifts}>
      <Box className={classes.wrapper}>
        <NavLinks
          links={[
            _shiftsPersonalUpcoming,
            _shiftsPersonalGrab,
            _shiftsPersonalClaim,
            // _shiftsPersonalUnavailability,
            {
              label: 'Unavailability',
              path: `${_peopleMembers.path}/${member!.id}/unavailability`,
              overruleRedirect: true,
            },
            _shiftsAdminMissingSupportWorker,
            _shiftsAdminMissingNotes,
            _shiftsAdminTravel,
          ]}
        />
        <Outlet />
      </Box>
    </Page>
  );
};

export default RouteCoreSupportShifts;

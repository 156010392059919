import { TextField, TextFieldProps } from '@timed/common';
import { personNameRegex } from '@timed/common/regexps';
import { RegisterOptions } from 'react-hook-form';

export const validatePersonName: RegisterOptions = {
  pattern: { value: personNameRegex(), message: 'Invalid character(s)' },
  maxLength: { value: 255, message: 'Too long' },
};

const PersonNameInput = (props: TextFieldProps) => (
  <TextField type="text" validation={validatePersonName} {...props} />
);

export default PersonNameInput;

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { blue, yellow } from '@material-ui/core/colors';
import { useAlert } from '@timed/alert';
import { useAuth } from '@timed/auth';
import {
  ClientAllowAutomaticEventPopulationCheckbox,
  ClientAllowMedicationCheckbox,
  ClientAllowObservationsCheckbox,
  ClientAllowRecordSeizuresCheckbox,
  ClientBranchInput,
  ClientDefaultEventDurationInput,
  ClientEventsRequireHandoverCheckbox,
  ClientExternalIdInput,
  ClientModuleCsCheckbox,
  ClientModuleScCheckbox,
  ClientNdisIdInput,
  ClientPlanManagerInput,
} from '@timed/client';
import {
  AddressBuildingNameInput,
  AddressInput,
  AddressUnitInput,
  addServerErrors,
  EmailInput,
  FormModal,
  ModalProps,
  PersonNameFirstInput,
  PersonNameLastInput,
  PersonNameMiddleInput,
  PhoneInput,
  TimezoneInput,
} from '@timed/common';
import { getTimezone } from '@timed/common/helpers/getTimezone';
import {
  Address,
  Client,
  CreateClientsDocument,
  CreateClientsInput,
  ModuleType,
  Permission,
  QueryByIdInput,
  useCreateClientsMutation,
} from '@timed/gql';
import { ModuleContext } from '@timed/module';
import clsx from 'clsx';
import _ from 'lodash';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

type ClientCreateFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      justifyItems: 'flex-start',
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        width: 800,
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
      },
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(6, 1fr)',
      gap: theme.spacing(2),
      padding: theme.spacing(2),
      border: '1px solid ' + theme.palette.divider,
      backgroundColor: theme.palette.background.paper,
    },
    yellowGrid: {
      border: '1px solid ' + yellow[800],
      backgroundColor: yellow[50],
    },
    blueGrid: {
      border: '1px solid ' + blue[800],
      backgroundColor: blue[50],
    },
    fullWidth: {
      gridColumn: 'span 6',
    },
    twoThirdsWidth: {
      gridColumn: 'span 4',
    },
    halfWidth: {
      gridColumn: 'span 3',
    },
    thirdWidth: {
      gridColumn: 'span 2',
    },
    group: {
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(1),
    },
    title: {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 16,
    },
  }),
);

type FormData = Pick<
  Client,
  | 'moduleCS'
  | 'moduleSC'
  | 'externalId'
  | 'ndisId'
  | 'defaultEventDuration'
  | 'firstName'
  | 'middleName'
  | 'lastName'
  | 'email'
  | 'phone'
  | 'timezone'
  | 'allowMedication'
  | 'allowObservations'
  | 'allowRecordSeizures'
  | 'allowAutomaticEventPopulation'
  | 'requireHandover'
  | 'unit'
  | 'buildingName'
> & {
  branch: QueryByIdInput;
  planManager?: QueryByIdInput;
  address?: Pick<
    Address,
    | 'street'
    | 'locality'
    | 'region'
    | 'postcode'
    | 'country'
    | 'latitude'
    | 'longitude'
  > | null;
};

const ClientCreateFormModal = ({
  onClose,
  ...modalProps
}: ClientCreateFormModalProps) => {
  const classes = useStyles();

  const alert = useAlert();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { activeModule } = useContext(ModuleContext);

  const [createClients, response] = useCreateClientsMutation();

  const { branch, permissible } = useAuth();

  const {
    control,
    handleSubmit,
    clearErrors,
    setError,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      externalId: '',
      ndisId: '',
      defaultEventDuration: undefined,
      allowMedication: false,
      allowObservations: false,
      allowRecordSeizures: false,
      requireHandover: false,
      planManager: undefined,
      email: '',
      phone: '',
      firstName: '',
      middleName: '',
      lastName: '',
      unit: '',
      buildingName: '',
      address: null,
      timezone: getTimezone(),
      branch: { id: branch?.id },
    },
  });

  // const nullableStrings: Extract<string, FormData[keyof FormData]>[] = ['ndisId'] //readonly (keyof Pick<FormData, 'ndisId'>)[] = ['ndisId'];

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSuccess = () => {
    alert.push({
      message: 'Successfully created participant',
      severity: 'success',
    });
    const cache = response.client.cache;

    cache.modify({
      fields: {
        clients(existing = []) {
          return [
            ...existing,
            cache.writeQuery({
              data: response.data,
              query: CreateClientsDocument,
            }),
          ];
        },
      },
    });

    reset();
  };

  const onSubmit = async (values: FormData) => {
    console.log();
    console.log('values', values);
    console.log();

    // To avoid graphql mutation errors, delete location object if it is empty
    (!values.address || Object.keys(values.address).length === 0) &&
      delete values.address;
    !values.planManager?.id && delete values.planManager;

    // Delete nullish values
    Object.keys(values).forEach(
      (k) =>
        (values[k as keyof FormData] === null ||
          values[k as keyof FormData] === 'undefined') &&
        delete values[k as keyof FormData],
    );

    !values.defaultEventDuration && (values.defaultEventDuration = null);

    // Format input for creating a single member entity
    let input: CreateClientsInput = {
      objects: [
        {
          ...values.address,
          ..._.pick(values, 'moduleCS', 'modulesSC'),
          ..._.omit(
            values,
            'address',
            'ndisId',
            'lastName',
            'middleName',
            'phone',
            'email',
            'unit',
            'buildingName',
            'externalId',
          ),
          ..._.pickBy(
            _.pick(
              values,
              'ndisId',
              'lastName',
              'middleName',
              'phone',
              'email',
              'unit',
              'buildingName',
              'externalId',
            ),
            (value) => value.length > 0,
          ),
          // Assign "planManager" property to input object, if planManagerId was submitted
          // planManager: values.planManagerId ? { id: values.branchId } : null,
        },
      ],
    };

    console.log('input', input);

    createClients({ variables: { input } });
  };

  return (
    <FormModal
      modalProps={modalProps}
      title="Add participant"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        <Box className={clsx(classes.grid, classes.yellowGrid)}>
          <ClientBranchInput
            required
            name="branch.id"
            control={control}
            error={!!errors.branch?.id}
            helperText={errors.branch?.id?.message}
            formControlProps={{
              required: true,
              size: 'small',
              variant: 'outlined',
              className: classes[smDown ? 'fullWidth' : 'thirdWidth'],
            }}
          />
          <TimezoneInput
            required
            name="timezone"
            control={control}
            error={!!errors.timezone}
            helperText={errors.timezone?.message}
            placeholder="Timezone"
            formControlProps={{
              required: true,
              size: 'small',
              variant: 'outlined',
              className: classes[smDown ? 'fullWidth' : 'thirdWidth'],
            }}
          />
          <ClientExternalIdInput
            name="externalId"
            control={control}
            error={!!errors.externalId}
            helperText={errors.externalId?.message}
            size="small"
            variant="outlined"
            className={classes[smDown ? 'fullWidth' : 'thirdWidth']}
          />
        </Box>
        <Box className={clsx(classes.grid, classes.blueGrid)}>
          <ClientModuleCsCheckbox
            id="moduleCS"
            name="moduleCS"
            control={control}
            error={!!errors.moduleCS}
            helperText={errors.moduleCS?.message}
            formControlProps={{ className: classes.fullWidth }}
            color="primary"
            defaultChecked={activeModule === ModuleType.CS}
          />
          <ClientModuleScCheckbox
            id="moduleSC"
            name="moduleSC"
            control={control}
            error={!!errors.moduleSC}
            helperText={errors.moduleSC?.message}
            formControlProps={{ className: classes.fullWidth }}
            color="primary"
            defaultChecked={activeModule === ModuleType.SC}
          />
        </Box>
        <Box className={classes.group}>
          <Typography className={classes.title}>Personal Details</Typography>
          <Box className={classes.grid}>
            <PersonNameFirstInput
              required
              name="firstName"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              className={classes[smDown ? 'fullWidth' : 'thirdWidth']}
            />
            <PersonNameMiddleInput
              name="middleName"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.middleName}
              helperText={errors.middleName?.message}
              className={classes[smDown ? 'fullWidth' : 'thirdWidth']}
            />
            <PersonNameLastInput
              name="lastName"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
              className={classes[smDown ? 'fullWidth' : 'thirdWidth']}
            />
            <EmailInput
              name="email"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.email}
              helperText={errors.email?.message}
              className={classes[smDown ? 'fullWidth' : 'twoThirdsWidth']}
            />
            <PhoneInput
              name="phone"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.phone}
              helperText={errors.phone?.message}
              className={classes[smDown ? 'fullWidth' : 'thirdWidth']}
            />
            <AddressUnitInput
              name="unit"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.unit}
              helperText={errors.unit?.message}
              className={classes.halfWidth}
            />
            <AddressBuildingNameInput
              name="buildingName"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.unit}
              helperText={errors.unit?.message}
              className={classes.halfWidth}
            />
            <AddressInput
              name="address"
              control={control}
              clearErrors={clearErrors}
              setValue={setValue}
              setError={setError}
              error={!!errors.address}
              // helperText={errors.address?.message}
              placeholder="Residential address"
              className={classes.fullWidth}
            />
          </Box>
        </Box>
        <Box className={classes.group}>
          <Typography className={classes.title}>NDIS Details</Typography>
          <Box className={classes.grid}>
            <ClientNdisIdInput
              name="ndisId"
              control={control}
              className={
                permissible({ permissions: Permission.PLANMANAGER_READ })
                  ? classes[smDown ? 'fullWidth' : 'thirdWidth']
                  : classes[smDown ? 'fullWidth' : 'halfWidth']
              }
              variant="outlined"
              size="small"
              error={!!errors.ndisId}
              helperText={errors.ndisId?.message}
            />
            <ClientDefaultEventDurationInput
              fullWidth
              name="defaultEventDuration"
              control={control}
              variant="outlined"
              size="small"
              className={
                permissible({ permissions: Permission.PLANMANAGER_READ })
                  ? classes[smDown ? 'fullWidth' : 'thirdWidth']
                  : classes[smDown ? 'fullWidth' : 'halfWidth']
              }
              error={!!errors.defaultEventDuration}
              helperText={errors.defaultEventDuration?.message}
            />
            {permissible({ permissions: Permission.PLANMANAGER_READ }) && (
              <ClientPlanManagerInput
                displayEmpty
                name="planManager.id"
                control={control}
                error={!!errors.planManager?.id}
                helperText={errors.planManager?.id?.message}
                formControlProps={{
                  size: 'small',
                  variant: 'outlined',
                  className: classes[smDown ? 'fullWidth' : 'thirdWidth'],
                }}
              />
            )}
          </Box>
        </Box>
        <Box className={classes.group}>
          <Typography className={classes.title}>Settings</Typography>
          <Box className={classes.grid}>
            <ClientEventsRequireHandoverCheckbox
              id="requireHandover"
              name="requireHandover"
              control={control}
              error={!!errors.requireHandover}
              helperText={errors.requireHandover?.message}
              formControlProps={{ className: classes.fullWidth }}
              color="primary"
            />
            <ClientAllowObservationsCheckbox
              id="allowObservations"
              name="allowObservations"
              control={control}
              error={!!errors.allowObservations}
              helperText={errors.allowObservations?.message}
              formControlProps={{ className: classes.fullWidth }}
              color="primary"
            />
            <ClientAllowMedicationCheckbox
              id="allowMedication"
              name="allowMedication"
              control={control}
              error={!!errors.allowMedication}
              helperText={errors.allowMedication?.message}
              formControlProps={{ className: classes.fullWidth }}
              color="primary"
            />
            <ClientAllowRecordSeizuresCheckbox
              id="allowRecordSeizures"
              name="allowRecordSeizures"
              control={control}
              error={!!errors.allowRecordSeizures}
              helperText={errors.allowRecordSeizures?.message}
              formControlProps={{ className: classes.fullWidth }}
              color="primary"
            />
            <ClientAllowAutomaticEventPopulationCheckbox
              id="allowAutomaticEventPopulation"
              name="allowAutomaticEventPopulation"
              control={control}
              error={!!errors.allowAutomaticEventPopulation}
              helperText={errors.allowAutomaticEventPopulation?.message}
              formControlProps={{ className: classes.fullWidth }}
              color="primary"
            />
          </Box>
        </Box>
      </Box>
    </FormModal>
  );
};

export default ClientCreateFormModal;

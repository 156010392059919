import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { addServerErrors, IconButtonMulti, Textarea } from '@timed/common';
import { Member, useUpdateMembersNotesMutation } from '@timed/gql';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type MemberUpdateNotesFormProps = {
  member: Pick<Member, 'id' | 'notes'>;
};

type FormData = {
  patch: {
    notes: string;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: 'auto max-content',
      gap: theme.spacing(1),
    },
  }),
);

const MemberUpdateNotesForm = ({ member }: MemberUpdateNotesFormProps) => {
  const classes = useStyles();

  const [editing, setEditing] = useState<boolean>(false);

  const [updateMembersNotes, response] = useUpdateMembersNotesMutation();

  const defaultValues: FormData = {
    patch: {
      notes: member.notes || '',
    },
  };

  const { handleSubmit, watch, control, setError } = useForm<FormData>({
    defaultValues,
  });

  const currentValues = watch();

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = (values: FormData) =>
    updateMembersNotes({
      variables: { input: { ids: [member.id], ...values } },
    }).catch((e) => {});

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box className={classes.grid}>
        <Textarea
          name="patch.notes"
          control={control}
          minRows={4}
          placeholder="Notes"
          disabled={!editing}
        />
        <IconButtonMulti
          enabled={editing}
          changed={!isEqual(currentValues, defaultValues)}
          setEditing={setEditing}
          loading={response.loading}
          success={!!response.data}
        />
      </Box>
    </form>
  );
};

export default MemberUpdateNotesForm;

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useAuth } from '@timed/auth';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    links: {
      backgroundColor: theme.palette.appBar.dark,
      color: theme.palette.getContrastText(theme.palette.appBar.main),
      display: 'flex',
      flexDirection: 'column',
    },
    link: {
      cursor: 'pointer',
      padding: theme.spacing(2, 4),
      '&:hover': {
        backgroundColor: theme.palette.appBar.light,
      },
      '& p': {
        [theme.breakpoints.up('sm')]: {
          fontSize: '16px',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
        },
      },
    },
    linkSelected: {
      backgroundColor: theme.palette.appBar.light,
    },
  }),
);

interface AppBarAccountBranchMenuProps {
  handleClose: (event: React.MouseEvent<EventTarget>) => void;
}

const AppBarAccountBranchMenu = ({
  handleClose,
}: AppBarAccountBranchMenuProps) => {
  const classes = useStyles();
  const { branch, branches, setBranch } = useAuth();

  const handleChangeBranch = async (chosenBranchId?: string) => {
    if (
      !chosenBranchId ||
      branches.map(({ id }) => id).includes(chosenBranchId)
    )
      setBranch(branches.find(({ id }) => id === chosenBranchId));
  };

  if (branches.length < 2) return null;

  return (
    <Box className={classes.links}>
      {[{ id: undefined, name: 'All Branches' }, ...branches].map(
        ({ id, name }) => (
          <Box
            className={
              branch?.id === id
                ? clsx(classes.link, classes.linkSelected)
                : classes.link
            }
            onClick={(event) => {
              handleChangeBranch(id);
              handleClose(event);
            }}
          >
            <Typography>{name}</Typography>
          </Box>
        ),
      )}
    </Box>
  );
};

export default AppBarAccountBranchMenu;

import {
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { _schedule } from '@timed/app';
import { useAuth } from '@timed/auth';
import {
  formatPersonName,
  Select,
  useRouter,
  validateUuid,
} from '@timed/common';
import {
  EntityState,
  OrderBy,
  Permission,
  useGetClientsSelectLazyQuery,
  useGetClientsSelectRedactedLazyQuery,
} from '@timed/gql';
import { getProfile, ScheduleContext, setProfile } from '@timed/schedule';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loading: {
      '& .MuiOutlinedInput-root.Mui-disabled': {
        backgroundColor: theme.palette.background.default,
      },
    },
    input: {
      minWidth: theme.spacing(31),
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.down('sm')]: {
        minWidth: theme.spacing(22),
        padding: theme.spacing(0, 1, 0, 2),
        '& .MuiSelect-root': {
          fontSize: 12,
        },
      },
      '& .MuiSelect-root': {
        paddingRight: 0,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.divider,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': {
        fontSize: '1rem',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1.625, 0),
          fontSize: 'initial',
        },
      },
    },
    item: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.between('md', 'xl')]: {
        lineHeight: 1,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
    icon: {
      margin: theme.spacing(0, 2),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 0, 0, 1),
      },
    },
    formControl: {
      [theme.breakpoints.down('sm')]: {
        flex: '1 1 0px',
        '& .MuiFormLabel-root': {
          fontSize: 12,
          padding: theme.spacing(1),
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
          transform: 'translate(4px, 6px) scale(1)',
        },
        '& .MuiSelect-iconOutlined': {
          right: 0,
        },
      },
    },
  }),
);

const ScheduleControlsClientInput = () => {
  const classes = useStyles();

  const { branch, permissible } = useAuth();

  const {
    navigate,
    search: [searchParams],
  } = useRouter();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { setTimezone } = useContext(ScheduleContext);

  const [getClients, clientsResponse] = useGetClientsSelectLazyQuery();

  const [getRedactedClients, redactedClientsResponse] =
    useGetClientsSelectRedactedLazyQuery();

  const [data, loading] = useMemo(() => {
    const variables = {
      input: {
        where: {
          branch: branch ? { id: { _eq: branch.id } } : undefined,
        },
        orderBy: [{ lastName: OrderBy.ASC }, { firstName: OrderBy.ASC }],
      },
    };

    if (permissible({ permissions: Permission.CLIENT_READ })) {
      getClients({
        variables: {
          input: {
            ...variables.input,
            entityStates: permissible({ tester: true })
              ? [EntityState.ARCHIVED, EntityState.NORMAL]
              : [EntityState.NORMAL],
          },
        },
      });
      return [
        clientsResponse.data?.clients.map(({ __typename, ...c }) => c),
        clientsResponse.loading,
      ];
    } else {
      getRedactedClients({ variables });
      return [
        redactedClientsResponse.data?.redactedClients.map(
          ({ __typename, ...c }) => c,
        ),
        redactedClientsResponse.loading,
      ];
    }
  }, [
    branch,
    permissible,
    getClients,
    clientsResponse.data,
    clientsResponse.loading,
    getRedactedClients,
    redactedClientsResponse.data,
    redactedClientsResponse.loading,
  ]);

  const { control, reset, watch, setValue } = useForm<{ c: string }>({
    defaultValues: { c: getProfile('client', searchParams) || '' },
  });

  const selected = watch('c');

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    // Unselect select field
    setTimeout(() => (document.activeElement as HTMLElement).blur(), 0);

    setProfile('client', searchParams, event.target.value as string);

    setTimezone(
      'client',
      data?.find(({ id }) => id === event.target.value)?.timezone,
    );

    navigate(_schedule.path + '?' + searchParams);
  };

  /**
   * Delete 'c' (client id) from search params
   */
  const handleClear = useCallback(() => {
    reset({ c: '' });
    setProfile('client', searchParams);
    setTimezone('client', undefined);
    navigate(_schedule.path + '?' + searchParams);
  }, [navigate, reset, searchParams, setTimezone]);

  useEffect(() => {
    // Apply update to all visible ClientInput fields.
    setValue('c', searchParams.get('c') || '');
  }, [searchParams, setValue]);

  useEffect(() => {
    if (data && selected && !!data.find(({ id }) => id === selected))
      setTimezone('client', data.find(({ id }) => id === selected)!.timezone);
  }, [data, selected, setTimezone]);

  useEffect(() => {
    if (
      selected &&
      !!data?.length &&
      !data.map(({ id }) => id).includes(selected)
    ) {
      handleClear();
    }
  }, [selected, data, setValue, handleClear]);

  return (
    <Select
      name="c"
      label={
        loading
          ? 'Loading...'
          : selected
          ? !smDown
            ? 'Participant'
            : undefined
          : 'Participant'
      }
      control={control}
      validation={validateUuid}
      onChange={handleChange}
      className={classes.input}
      IconComponent={
        !!selected
          ? () => (
              <IconButton
                size="small"
                className={classes.icon}
                onClick={handleClear}
                onMouseDown={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              >
                <CloseRoundedIcon fontSize="small" />
              </IconButton>
            )
          : undefined
      }
      formControlProps={{
        variant: 'outlined',
        size: 'small',
        disabled: loading || !data?.length,
        className: classes.formControl,
      }}
      items={data?.map((client) => ({
        value: client.id,
        label: `${formatPersonName(client, {
          capitaliseLastName: true,
          lastNameFirst: true,
        })}${
          !!client.preferredName && client.preferredName !== client.firstName
            ? ` (${formatPersonName(client, {
                preferred: true,
              })})`
            : ``
        }`,
      }))}
      renderValue={(value) => (
        <Typography variant="body1" className={classes.item}>
          {data?.find((client) => client.id === value)
            ? formatPersonName(data.find((client) => client.id === value)!, {
                lastNameFirst: true,
                capitaliseLastName: true,
                preferredAndLast: true,
              })
            : ''}
        </Typography>
      )}
    />
  );
};

export default ScheduleControlsClientInput;

import { Typography } from '@material-ui/core';
import { useRouter } from '@timed/common';
import {
  Client,
  Permission,
  RedactedClient,
  useGetClientByIdLazyQuery,
  useGetRedactedClientByIdLazyQuery,
} from '@timed/gql';
import { ClientContext } from '@timed/client';
import { useLoadingEffect } from '@timed/loading';
import { RouteErrorNotFound } from '@timed/routes';
import React, { useEffect, useMemo } from 'react';
import { useAuth } from '@timed/auth';

type ClientProviderProps = React.PropsWithChildren<{}>;

export const isClient = (
  entity: Pick<Client, 'id' | 'createdAt'> | Pick<RedactedClient, 'id'>,
): entity is Client => entity.hasOwnProperty('createdAt');

const ClientProvider = ({ children }: ClientProviderProps) => {
  const { query } = useRouter();

  const auth = useAuth();

  const [clientById, clientResponse] = useGetClientByIdLazyQuery({
    variables: { id: query.client as string },
  });

  const [redactedClientById, redactedClientResponse] =
    useGetRedactedClientByIdLazyQuery({
      variables: { id: query.client as string },
    });

  const canFetchNonRedactedClients = useMemo(() => {
    return auth.permissible({
      permissions: [Permission.CLIENT_READ],
    });
  }, [auth]);

  const data = canFetchNonRedactedClients
    ? clientResponse.data?.clientById
    : redactedClientResponse.data?.redactedClientById;

  const loading = canFetchNonRedactedClients
    ? clientResponse.loading
    : redactedClientResponse.loading;

  const error = canFetchNonRedactedClients
    ? clientResponse.error
    : redactedClientResponse.error;

  useEffect(() => {
    if (!data) canFetchNonRedactedClients ? clientById() : redactedClientById();
  }, [
    data,
    canFetchNonRedactedClients,
    clientById,
    redactedClientById,
    query.client,
  ]);

  useLoadingEffect(loading);

  if (loading) return <Typography variant="body2">Loading...</Typography>;

  if (!query || !data || error) return <RouteErrorNotFound />;

  return (
    <ClientContext.Provider value={{ ...data }}>
      {children}
    </ClientContext.Provider>
  );
};

export default ClientProvider;

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Protected } from '@timed/auth';
import { Permission } from '@timed/gql';
import {
  ScheduleAttributesSelect,
  ScheduleClientInput,
  ScheduleConflictingEvents,
  ScheduleConflictingUnavailables,
  ScheduleDateBackButton,
  ScheduleDateForwardButton,
  ScheduleDateInput,
  ScheduleDateRangeInput,
  ScheduleDateRedirectButton,
  ScheduleDrawer,
  ScheduleMemberInput,
  ScheduleNonClockedEvents,
  ScheduleSettingsButton,
  ScheduleStatesSelect,
  ScheduleUnfilledEvents,
} from '@timed/schedule';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    controlsWrapper: {
      padding: theme.spacing(2),
      display: 'flex',
      gap: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      alignItems: 'start',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
        padding: theme.spacing(1),
        flexDirection: 'column',
      },
    },
    controls: {
      // flexGrow: 1,
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      minHeight: 40,
      [theme.breakpoints.down('lg')]: {
        gap: theme.spacing(2),
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: 32,
      },
    },
    seperator: {
      alignSelf: 'center',
      padding: theme.spacing(0, 2),
      color: theme.palette.divider,
      '&::before': {
        content: '"|"',
      },
    },
    group: {
      color: theme.palette.divider,
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
      '& .MuiOutlinedInput-label': {
        padding: 5,
      },
    },
    warnings: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      justifyContent: 'end',
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
    },
    people: {
      display: 'flex',
      width: '100%',
      gap: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
    },
  }),
);

const ScheduleControlBar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const xlOnly = useMediaQuery(theme.breakpoints.only('xl'));

  return (
    <div className={classes.controlsWrapper}>
      {smDown ? (
        <>
          <Box className={classes.controls}>
            <Box className={classes.group}>
              <ScheduleDateBackButton />
              <ScheduleDateInput />
              <ScheduleDateForwardButton />
              <ScheduleDateRangeInput />
              <Protected permissions={Permission.EVENT_READ}>
                <ScheduleDrawer />
              </Protected>
              <ScheduleSettingsButton />
            </Box>
          </Box>
          <Box className={classes.people}>
            <ScheduleClientInput />
            <Protected permissions={Permission.MEMBER_READ}>
              <ScheduleMemberInput />
            </Protected>
          </Box>
        </>
      ) : xlOnly ? (
        <>
          <Box className={classes.controls}>
            <Box className={classes.group}>
              <ScheduleDateBackButton />
              <ScheduleDateInput />
              <ScheduleDateForwardButton />
              <ScheduleDateRangeInput />
              <ScheduleDateRedirectButton type={'today'} />
            </Box>
            <Box className={classes.seperator} />
            <Box className={classes.group}>
              <ScheduleClientInput />
              <Protected permissions={Permission.MEMBER_READ}>
                <ScheduleMemberInput />
              </Protected>
            </Box>
            <Protected admin>
              <Box className={classes.seperator} />
              <Box className={classes.group}>
                <ScheduleStatesSelect />
                <ScheduleAttributesSelect />
              </Box>
            </Protected>
          </Box>
          <Protected permissions={Permission.EVENT_WRITE}>
            <Box className={classes.controls}>
              <Box className={classes.group}>
                <ScheduleConflictingUnavailables />
                <ScheduleConflictingEvents />
                <ScheduleUnfilledEvents />
                <ScheduleNonClockedEvents />
                <ScheduleSettingsButton />
              </Box>
            </Box>
          </Protected>
        </>
      ) : (
        <>
          <Box className={classes.controls}>
            <Box className={classes.group}>
              <ScheduleDateBackButton />
              <ScheduleDateInput />
              <ScheduleDateForwardButton />
              <ScheduleDateRangeInput />
              <ScheduleSettingsButton />
            </Box>
            <Box className={classes.people}>
              <ScheduleClientInput />
              <Protected permissions={Permission.MEMBER_READ}>
                <ScheduleMemberInput />
              </Protected>
              <Protected admin>
                <ScheduleStatesSelect />
              </Protected>
            </Box>
          </Box>
          <Protected permissions={Permission.EVENT_WRITE}>
            <Box className={classes.controls}>
              <Box className={classes.warnings}>
                <ScheduleConflictingUnavailables />
                <ScheduleConflictingEvents />
                <ScheduleUnfilledEvents />
                <ScheduleNonClockedEvents />
              </Box>
            </Box>
          </Protected>
        </>
      )}
    </div>
  );
};

export default ScheduleControlBar;

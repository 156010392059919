import {
  Box,
  createStyles,
  InputAdornment,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  Autocomplete,
  AutocompleteOption,
  AutocompleteProps,
  Avatar,
  formatPersonName,
  TextField,
} from '@timed/common';
import {
  Member,
  MembersOrderByInput,
  MembersWhereInput,
  PersonNamesFragment,
  useGetMembersQuery,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import React, { Dispatch, SetStateAction, useMemo } from 'react';

type MemberAutocompleteProps = {
  autocompleteProps?: Partial<
    AutocompleteProps<Pick<Member, 'id' | 'color'> & PersonNamesFragment>
  >;
  member?: AutocompleteOption<
    Pick<Member, 'id' | 'color'> & PersonNamesFragment
  > | null;
  setMember: Dispatch<
    SetStateAction<AutocompleteOption<
      Pick<Member, 'id' | 'color'> & PersonNamesFragment
    > | null>
  >;
  where?: MembersWhereInput;
  orderBy?: MembersOrderByInput[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      display: 'flex',
      flex: '1 1 auto',
      alignItems: 'center',
      gap: theme.spacing(4),
    },
    avatar: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      fontSize: '75%',
      lineHeight: theme.spacing(8) + 'px',
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: 1,
    },
  }),
);

const MemberAutocomplete = ({
  autocompleteProps,
  member,
  setMember,
  ...input
}: MemberAutocompleteProps) => {
  const classes = useStyles();

  const { data, loading } = useGetMembersQuery({ variables: { input } });

  useLoadingEffect(loading);

  const options = useMemo(() => {
    return data
      ? data.members.map((m) => ({
          label: formatPersonName(m, {
            lastNameFirst: true,
            capitaliseLastName: true,
          }),
          value: m,
        }))
      : [];
  }, [data]);

  return (
    <Autocomplete
      {...autocompleteProps}
      name="member-autocomplete"
      label="Add Employee"
      options={options}
      value={member}
      setValue={setMember}
      renderOption={({ label, value }) => (
        <Box className={classes.item}>
          <Avatar
            className={classes.avatar}
            color={data?.members.find((m) => m.id === value.id)?.color}
            content={[
              data?.members.find((m) => m.id === value.id)?.firstName,
              data?.members.find((m) => m.id === value.id)?.middleName,
              data?.members.find((m) => m.id === value.id)?.lastName,
            ]}
          />
          <Typography>{label}</Typography>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Add Employee"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            startAdornment: !member ? null : (
              <InputAdornment position="start">
                <Avatar
                  className={classes.avatar}
                  color={
                    data?.members.find((m) => m.id === member?.value.id)?.color
                  }
                  content={[
                    data?.members.find((m) => m.id === member?.value.id)
                      ?.firstName,
                    data?.members.find((m) => m.id === member?.value.id)
                      ?.middleName,
                    data?.members.find((m) => m.id === member?.value.id)
                      ?.lastName,
                  ]}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default MemberAutocomplete;

import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { _notes } from '@timed/app';
import {
  NotesControls,
  NotesList,
  NotesPaginationNavigation,
  NotesPaginationStatus,
  NotesProvider,
} from '@timed/notes';
import { Page } from '@timed/page';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      flexGrow: 1,
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
    },
  }),
);

const RouteCoreSupportNotes = () => {
  const classes = useStyles();

  return (
    <Page {..._notes}>
      <NotesProvider>
        <div className={classes.wrapper}>
          <NotesControls />
          <NotesPaginationStatus />
          <NotesPaginationNavigation />
          <NotesList />
        </div>
      </NotesProvider>
    </Page>
  );
};

export default RouteCoreSupportNotes;

import { Typography } from '@material-ui/core';
import { Stack } from '@mui/material';
import { Button, Dialog, ModalProps } from '@timed/common';
import {
  MemberBasePayRate,
  useDeleteMemberBranchAllocationMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { format } from 'date-fns';

type MemberDeleteBranchAllocationDialogProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  branchAllocation: Pick<MemberBasePayRate, 'id' | 'date'>;
};

const MemberDeleteBranchAllocationDialog = ({
  branchAllocation,
  onClose,
  ...props
}: MemberDeleteBranchAllocationDialogProps) => {
  const [deleteEntity, { loading, client }] =
    useDeleteMemberBranchAllocationMutation();

  useLoadingEffect(loading);

  const handleDelete = () => {
    deleteEntity({
      variables: { input: { id: branchAllocation.id } },

      onCompleted: ({ deleteMemberBranchAllocation }) => {
        client.cache.evict({
          id: client.cache.identify({
            __typename: 'MemberBranchAllocation',
            id: deleteMemberBranchAllocation.id,
          }),
        });
        client.cache.gc();
        onClose();
      },
    });
  };

  return (
    <Dialog
      {...props}
      title="Deleting Branch Allocation"
      description={
        <Stack spacing={2}>
          <Typography>
            Are you sure you want to delete this branch allocation?
          </Typography>
          <Typography>
            Effective-from date:{' '}
            {format(new Date(branchAllocation.date), 'dd/MM/yyyy')}
          </Typography>
        </Stack>
      }
      actions={[
        <Button onClick={onClose}>Cancel</Button>,
        <Button onClick={handleDelete}>Yes</Button>,
      ]}
    />
  );
};

export default MemberDeleteBranchAllocationDialog;

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useAlert } from '@timed/alert';
import { useAuth } from '@timed/auth';
import {
  Checkbox,
  DateInput,
  EmailInput,
  FormModal,
  ModalProps,
  PersonNameFirstInput,
  PersonNameLastInput,
  PersonNameMiddleInput,
  ProfileInput,
  SalutationInput,
  TextField,
} from '@timed/common';
import {
  OrderBy,
  Permission,
  QueryByIdInput,
  SetMemberTaxFileNumberDeclarationInput,
  useGetMemberTaxFileNumberDeclarationDetailsLazyQuery,
  useGetMemberTaxFileNumberDeclarationDetailsSelfLazyQuery,
  useUpdateMembersTaxFileNumberDeclarationDetailsMutation,
  useUpdateMemberTaxFileNumberDeclarationDetailsSelfMutation,
} from '@timed/gql';
import {
  MemberTaxFileNumberDeclarationAreYouInput,
  MemberTaxFileNumberDeclarationBasisOfPayInput,
} from '@timed/member';
import { subYears } from 'date-fns';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type UploadTaxFileNumberDeclarationDetailsFormModalProps = Omit<
  ModalProps,
  'children'
> & {
  onClose: () => void;
};

type FormData = QueryByIdInput & {
  patch: {
    taxFileNumberDeclaration?: SetMemberTaxFileNumberDeclarationInput | null;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        maxWidth: 800,
        maxHeight: 800,
      },
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    numbers: {
      display: 'grid',
      gridTemplateColumns: '85px auto',
      gap: theme.spacing(4),
    },
    group: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      alignItems: 'start',
    },
    title: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    select: {
      '&.MuiInputBase-root': {
        minWidth: 120,
      },
    },
    checkBoxes: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

const UploadTaxFileNumberDeclarationDetailsFormModal = ({
  onClose,
  ...modalProps
}: UploadTaxFileNumberDeclarationDetailsFormModalProps) => {
  const classes = useStyles();

  const alert = useAlert();

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>();

  const { permissible } = useAuth();

  const [getExistingValues, existingValuesResponse] =
    useGetMemberTaxFileNumberDeclarationDetailsLazyQuery();

  const [getExistingValuesSelf, existingValuesSelfResponse] =
    useGetMemberTaxFileNumberDeclarationDetailsSelfLazyQuery();

  const [updateOther, updateOtherResponse] =
    useUpdateMembersTaxFileNumberDeclarationDetailsMutation();

  const [updateSelf, updateSelfResponse] =
    useUpdateMemberTaxFileNumberDeclarationDetailsSelfMutation();

  const memberId = watch('id');

  useEffect(() => {
    if (permissible({ admin: true })) {
      if (!!memberId) getExistingValues({ variables: { id: memberId } });
    } else {
      getExistingValuesSelf();
    }
  }, [permissible, memberId, getExistingValues, getExistingValuesSelf]);

  useEffect(() => {
    if (!!existingValuesResponse.data) {
      setValue(
        'patch.taxFileNumberDeclaration.tfn',
        existingValuesResponse.data.memberById.taxFileNumberDeclaration?.tfn ??
          '',
      );

      setValue(
        'patch.taxFileNumberDeclaration.appliedForTfn',
        !!existingValuesResponse.data.memberById.taxFileNumberDeclaration
          ?.appliedForTfn,
      );

      setValue(
        'patch.taxFileNumberDeclaration.claimingExemptionMinor',
        !!existingValuesResponse.data.memberById.taxFileNumberDeclaration
          ?.claimingExemptionMinor,
      );

      setValue(
        'patch.taxFileNumberDeclaration.claimingExemptionPension',
        !!existingValuesResponse.data.memberById.taxFileNumberDeclaration
          ?.claimingExemptionPension,
      );

      setValue(
        'patch.taxFileNumberDeclaration.salutation',
        existingValuesResponse.data.memberById.taxFileNumberDeclaration
          ?.salutation ?? null,
      );

      setValue(
        'patch.taxFileNumberDeclaration.lastName',
        existingValuesResponse.data.memberById.taxFileNumberDeclaration
          ?.lastName ?? '',
      );

      setValue(
        'patch.taxFileNumberDeclaration.firstName',
        existingValuesResponse.data.memberById.taxFileNumberDeclaration
          ?.firstName ?? '',
      );

      setValue(
        'patch.taxFileNumberDeclaration.middleName',
        existingValuesResponse.data.memberById.taxFileNumberDeclaration
          ?.middleName ?? '',
      );

      setValue(
        'patch.taxFileNumberDeclaration.prevLastName',
        existingValuesResponse.data.memberById.taxFileNumberDeclaration
          ?.prevLastName ?? '',
      );

      setValue(
        'patch.taxFileNumberDeclaration.email',
        existingValuesResponse.data.memberById.taxFileNumberDeclaration
          ?.email ?? '',
      );

      setValue(
        'patch.taxFileNumberDeclaration.dob',
        existingValuesResponse.data.memberById.taxFileNumberDeclaration?.dob ??
          null,
      );

      setValue(
        'patch.taxFileNumberDeclaration.basisOfPay',
        existingValuesResponse.data.memberById.taxFileNumberDeclaration
          ?.basisOfPay ?? null,
      );

      setValue(
        'patch.taxFileNumberDeclaration.areYou',
        existingValuesResponse.data.memberById.taxFileNumberDeclaration
          ?.areYou ?? null,
      );

      setValue(
        'patch.taxFileNumberDeclaration.claimTaxFreeThreshold',
        !!existingValuesResponse.data.memberById.taxFileNumberDeclaration
          ?.claimTaxFreeThreshold,
      );

      setValue(
        'patch.taxFileNumberDeclaration.studentLoanDebt',
        !!existingValuesResponse.data.memberById.taxFileNumberDeclaration
          ?.studentLoanDebt,
      );
    }
  }, [existingValuesResponse.data, setValue]);

  useEffect(() => {
    if (!!existingValuesSelfResponse.data) {
      setValue(
        'patch.taxFileNumberDeclaration.tfn',
        existingValuesSelfResponse.data.me.member?.taxFileNumberDeclaration
          ?.tfn ?? '',
      );

      setValue(
        'patch.taxFileNumberDeclaration.appliedForTfn',
        !!existingValuesSelfResponse.data.me.member?.taxFileNumberDeclaration
          ?.appliedForTfn,
      );

      setValue(
        'patch.taxFileNumberDeclaration.claimingExemptionMinor',
        !!existingValuesSelfResponse.data.me.member?.taxFileNumberDeclaration
          ?.claimingExemptionMinor,
      );

      setValue(
        'patch.taxFileNumberDeclaration.claimingExemptionPension',
        !!existingValuesSelfResponse.data.me.member?.taxFileNumberDeclaration
          ?.claimingExemptionPension,
      );

      setValue(
        'patch.taxFileNumberDeclaration.salutation',
        existingValuesSelfResponse.data.me.member?.taxFileNumberDeclaration
          ?.salutation ?? null,
      );

      setValue(
        'patch.taxFileNumberDeclaration.lastName',
        existingValuesSelfResponse.data.me.member?.taxFileNumberDeclaration
          ?.lastName ?? '',
      );

      setValue(
        'patch.taxFileNumberDeclaration.firstName',
        existingValuesSelfResponse.data.me.member?.taxFileNumberDeclaration
          ?.firstName ?? '',
      );

      setValue(
        'patch.taxFileNumberDeclaration.middleName',
        existingValuesSelfResponse.data.me.member?.taxFileNumberDeclaration
          ?.middleName ?? '',
      );

      setValue(
        'patch.taxFileNumberDeclaration.prevLastName',
        existingValuesSelfResponse.data.me.member?.taxFileNumberDeclaration
          ?.prevLastName ?? '',
      );

      setValue(
        'patch.taxFileNumberDeclaration.email',
        existingValuesSelfResponse.data.me.member?.taxFileNumberDeclaration
          ?.email ?? '',
      );

      setValue(
        'patch.taxFileNumberDeclaration.dob',
        existingValuesSelfResponse.data.me.member?.taxFileNumberDeclaration
          ?.dob ?? null,
      );

      setValue(
        'patch.taxFileNumberDeclaration.basisOfPay',
        existingValuesSelfResponse.data.me.member?.taxFileNumberDeclaration
          ?.basisOfPay ?? null,
      );

      setValue(
        'patch.taxFileNumberDeclaration.areYou',
        existingValuesSelfResponse.data.me.member?.taxFileNumberDeclaration
          ?.areYou ?? null,
      );

      setValue(
        'patch.taxFileNumberDeclaration.claimTaxFreeThreshold',
        !!existingValuesSelfResponse.data.me.member?.taxFileNumberDeclaration
          ?.claimTaxFreeThreshold,
      );

      setValue(
        'patch.taxFileNumberDeclaration.studentLoanDebt',
        !!existingValuesSelfResponse.data.me.member?.taxFileNumberDeclaration
          ?.studentLoanDebt,
      );
    }
  }, [existingValuesSelfResponse.data, setValue]);

  const onSuccess = () => {
    alert.push({
      message: 'Successfully set tax file number declaration details',
      severity: 'success',
    });
  };

  const onSubmit = ({ patch }: FormData) => {
    memberId
      ? updateOther({
          variables: {
            input: { ids: [memberId], patch },
          },
        })
      : updateSelf({
          variables: {
            input: { patch },
          },
        });
  };

  return (
    <FormModal
      modalProps={modalProps}
      title="Tax File Number Declaration"
      loading={updateOtherResponse.loading || updateSelfResponse.loading}
      success={!!updateOtherResponse.data || !!updateSelfResponse.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        {permissible({ permissions: Permission.MEMBER_WRITE }) && (
          <Box className={classes.group}>
            <ProfileInput
              required
              control={control}
              formControlProps={{ size: 'small', variant: 'outlined' }}
              watch={watch}
              type="member"
              name="id"
              label="Employee"
              className={classes.select}
              orderBy={[{ lastName: OrderBy.ASC }]}
            />
          </Box>
        )}

        {((!permissible({ permissions: Permission.MEMBER_WRITE }) &&
          existingValuesSelfResponse.data?.me) ||
          (permissible({ permissions: Permission.MEMBER_WRITE }) &&
            memberId &&
            !!existingValuesResponse.data?.memberById)) && (
          <>
            <Typography className={classes.title}>
              1. What is your tax file number (TFN)?
            </Typography>
            <Box className={classes.group}>
              <TextField
                fullWidth
                name="patch.taxFileNumberDeclaration.tfn"
                variant="outlined"
                size="small"
                control={control}
                error={!!errors.patch?.taxFileNumberDeclaration?.tfn}
                helperText={
                  errors.patch?.taxFileNumberDeclaration?.tfn?.message
                }
              />
              <Checkbox
                defaultChecked={
                  permissible({ permissions: Permission.MEMBER_WRITE })
                    ? !!existingValuesResponse.data!.memberById
                        .taxFileNumberDeclaration?.appliedForTfn
                    : !!existingValuesSelfResponse.data!.me.member
                        ?.taxFileNumberDeclaration?.appliedForTfn
                }
                name="patch.taxFileNumberDeclaration.appliedForTfn"
                size="small"
                label="OR I have made a separate application/enquiry to the ATO for a new
                or existing TFN"
                control={control}
                error={!!errors.patch?.taxFileNumberDeclaration?.appliedForTfn}
                helperText={
                  errors.patch?.taxFileNumberDeclaration?.appliedForTfn?.message
                }
              />
              <Checkbox
                defaultChecked={
                  permissible({ permissions: Permission.MEMBER_WRITE })
                    ? !!existingValuesResponse.data!.memberById
                        .taxFileNumberDeclaration?.claimingExemptionMinor
                    : !!existingValuesSelfResponse.data!.me.member
                        ?.taxFileNumberDeclaration?.claimingExemptionMinor
                }
                name="patch.taxFileNumberDeclaration.claimingExemptionMinor"
                size="small"
                label="OR I am claiming an exemption because I am under 18 years of age and
                do not earn enough to pay tax."
                control={control}
                error={
                  !!errors.patch?.taxFileNumberDeclaration
                    ?.claimingExemptionMinor
                }
                helperText={
                  errors.patch?.taxFileNumberDeclaration?.claimingExemptionMinor
                    ?.message
                }
              />
              <Checkbox
                defaultChecked={
                  permissible({ permissions: Permission.MEMBER_WRITE })
                    ? !!existingValuesResponse.data!.memberById
                        .taxFileNumberDeclaration?.claimingExemptionPension
                    : !!existingValuesSelfResponse.data!.me.member
                        ?.taxFileNumberDeclaration?.claimingExemptionPension
                }
                name="patch.taxFileNumberDeclaration.claimingExemptionPension"
                size="small"
                label="OR I am claiming an exemption because I am in receipt of a pension,
                benefit or allowance."
                control={control}
                error={
                  !!errors.patch?.taxFileNumberDeclaration
                    ?.claimingExemptionMinor
                }
                helperText={
                  errors.patch?.taxFileNumberDeclaration
                    ?.claimingExemptionPension?.message
                }
              />
            </Box>
            <Typography className={classes.title}>
              2. What is your name?
            </Typography>
            <Box className={classes.group}>
              <SalutationInput
                fullWidth
                name="patch.taxFileNumberDeclaration.salutation"
                control={control}
                error={!!errors.patch?.taxFileNumberDeclaration?.salutation}
                helperText={
                  errors.patch?.taxFileNumberDeclaration?.salutation?.message
                }
                formControlProps={{
                  size: 'small',
                  variant: 'outlined',
                }}
              />
              <PersonNameLastInput
                fullWidth
                label="Surname or family name"
                name="patch.taxFileNumberDeclaration.lastName"
                variant="outlined"
                size="small"
                control={control}
                error={!!errors.patch?.taxFileNumberDeclaration?.lastName}
                helperText={
                  errors.patch?.taxFileNumberDeclaration?.lastName?.message
                }
              />
              <PersonNameFirstInput
                fullWidth
                label="First given name"
                name="patch.taxFileNumberDeclaration.firstName"
                variant="outlined"
                size="small"
                control={control}
                error={!!errors.patch?.taxFileNumberDeclaration?.firstName}
                helperText={
                  errors.patch?.taxFileNumberDeclaration?.firstName?.message
                }
              />
              <PersonNameMiddleInput
                fullWidth
                label="Other given names"
                name="patch.taxFileNumberDeclaration.middleName"
                variant="outlined"
                size="small"
                control={control}
                error={!!errors.patch?.taxFileNumberDeclaration?.middleName}
                helperText={
                  errors.patch?.taxFileNumberDeclaration?.middleName?.message
                }
              />
            </Box>
            <Typography className={classes.title}>
              3. If you have changed your name since you last dealt with the
              ATO, provide your previous family name.
            </Typography>
            <PersonNameLastInput
              fullWidth
              label={undefined}
              name="patch.taxFileNumberDeclaration.prevLastName"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.patch?.taxFileNumberDeclaration?.prevLastName}
              helperText={
                errors.patch?.taxFileNumberDeclaration?.prevLastName?.message
              }
            />
            <Typography className={classes.title}>
              4. What is your primary e-mail address?
            </Typography>
            <EmailInput
              fullWidth
              label={undefined}
              name="patch.taxFileNumberDeclaration.email"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.patch?.taxFileNumberDeclaration?.email}
              helperText={
                errors.patch?.taxFileNumberDeclaration?.email?.message
              }
            />
            <Typography className={classes.title}>
              5. What is your date of birth?
            </Typography>
            <DateInput
              keyboard
              clearable
              disableFuture
              disableTime
              openTo="year"
              name="patch.taxFileNumberDeclaration.dob"
              initialFocusedDate={new Date().getFullYear() - 18 + '-1-1'}
              control={control}
              inputVariant="outlined"
              size="small"
              error={!!errors.patch?.taxFileNumberDeclaration?.dob}
              helperText={errors.patch?.taxFileNumberDeclaration?.dob?.message}
              maxDate={new Date()}
              minDate={subYears(new Date(), 120)}
            />
            <Typography className={classes.title}>
              6. On what basis are you paid?
            </Typography>
            <MemberTaxFileNumberDeclarationBasisOfPayInput
              fullWidth
              name="patch.taxFileNumberDeclaration.basisOfPay"
              control={control}
              error={!!errors.patch?.taxFileNumberDeclaration?.basisOfPay}
              helperText={
                errors.patch?.taxFileNumberDeclaration?.basisOfPay?.message
              }
              formControlProps={{
                size: 'small',
                variant: 'outlined',
              }}
            />
            <Typography className={classes.title}>7. Are you a</Typography>
            <MemberTaxFileNumberDeclarationAreYouInput
              fullWidth
              name="patch.taxFileNumberDeclaration.areYou"
              control={control}
              error={!!errors.patch?.taxFileNumberDeclaration?.areYou}
              helperText={
                errors.patch?.taxFileNumberDeclaration?.areYou?.message
              }
              formControlProps={{
                size: 'small',
                variant: 'outlined',
              }}
            />
            <Typography className={classes.title}>
              8. Do you want to claim the tax-free threshold from this payer?
            </Typography>
            <Checkbox
              defaultChecked={
                permissible({ permissions: Permission.MEMBER_WRITE })
                  ? !!existingValuesResponse.data!.memberById
                      .taxFileNumberDeclaration?.claimTaxFreeThreshold
                  : !!existingValuesSelfResponse.data!.me.member
                      ?.taxFileNumberDeclaration?.claimTaxFreeThreshold
              }
              name="patch.taxFileNumberDeclaration.claimTaxFreeThreshold"
              size="small"
              label="Yes (do not check here if you are a foreign resident or working holiday maker, except if you are a foreign resident in receipt of an Australian Government pension or allowance)"
              control={control}
              error={
                !!errors.patch?.taxFileNumberDeclaration?.claimTaxFreeThreshold
              }
              helperText={
                errors.patch?.taxFileNumberDeclaration?.claimTaxFreeThreshold
                  ?.message
              }
            />
            <Typography className={classes.title}>
              9. Do you have a Higher Education Loan Program (HELP), VET Student
              Loan (VSL), Financial Supplement (FS), Student Start-up Loan (SSL)
              or Trade Support Loan (TSL) debt?
            </Typography>
            <Checkbox
              defaultChecked={
                permissible({ permissions: Permission.MEMBER_WRITE })
                  ? !!existingValuesResponse.data!.memberById
                      .taxFileNumberDeclaration?.studentLoanDebt
                  : !!existingValuesSelfResponse.data!.me.member
                      ?.taxFileNumberDeclaration?.studentLoanDebt
              }
              name="patch.taxFileNumberDeclaration.studentLoanDebt"
              size="small"
              label="Yes (your payer will withhold additional amounts to cover any compulsory repayment that may be raised on your notice of assessment)"
              control={control}
              error={!!errors.patch?.taxFileNumberDeclaration?.studentLoanDebt}
              helperText={
                errors.patch?.taxFileNumberDeclaration?.studentLoanDebt?.message
              }
            />
          </>
        )}
      </Box>
    </FormModal>
  );
};

export default UploadTaxFileNumberDeclarationDetailsFormModal;

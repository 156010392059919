import { _shiftsPersonalUpcoming } from '@timed/app/constants/metadata';
import { Page } from '@timed/page';
import { ShiftsUpcoming } from '@timed/shifts';

const RouteCoreSupportShiftsPersonalUpcoming = () => (
  <Page {..._shiftsPersonalUpcoming}>
    <ShiftsUpcoming />
  </Page>
);

export default RouteCoreSupportShiftsPersonalUpcoming;

export type LogoProps = { height: string; className?: any };

const Logo = ({ height, className }: LogoProps) => {
  return (
    <img
      src="/logo.svg"
      alt="Timed Logo"
      height={height}
      className={className}
    />
  );
};

export default Logo;

import { useMediaQuery, useTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { _peopleMembers } from '@timed/app';
import {
  Link,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  formatPersonName,
  roundNumber,
} from '@timed/common';
import {
  HistoryRestorable,
  Member,
  MemberScheduleTimeLimit,
  OrderBy,
  useGetMemberScheduleTimeLimitsQuery,
} from '@timed/gql';
import { format, isAfter } from 'date-fns';
import { useModal } from 'mui-modal-provider';

type MemberUpdateScheduleTimeLimitsFormProps = {
  member: Pick<Member, 'id'> & {
    archive?: Pick<HistoryRestorable, 'id'> | null;
  };
};

const MemberUpdateScheduleTimeLimitsForm = ({
  member,
}: MemberUpdateScheduleTimeLimitsFormProps) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { showModal } = useModal();

  const { data } = useGetMemberScheduleTimeLimitsQuery({
    variables: {
      id: member.id,
      input: {
        where: {
          deletedAt: {
            _eq: null,
          },
        },
        orderBy: [{ startAt: OrderBy.DESC }, { endAt: OrderBy.DESC }],
      },
    },
  });

  const handleOpenDeleteDialog = (
    limit: Pick<MemberScheduleTimeLimit, 'id'>,
  ) => {
    // const modal: { hide: () => void } = showModal(NotesDeleteKeywordDialog, {
    //   onClose: () => modal.hide(),
    //   keyword,
    // });

    console.log('deleting: ', limit);
  };

  return !data?.memberById.scheduleTimeLimits?.length ? null : (
    <Table inline enableRowHighlighting>
      <TableHeader>Limit</TableHeader>
      <TableHeader style={{ width: 'auto' }}>Description</TableHeader>
      <TableHeader order={OrderBy.DESC}>Start</TableHeader>
      <TableHeader>End</TableHeader>
      <TableHeader>Created By</TableHeader>
      <TableHeader>Created At</TableHeader>
      <TableHeader></TableHeader>
      {data?.memberById.scheduleTimeLimits.map(
        ({ limit, description, startAt, endAt, createdBy, createdAt }, i) => (
          <TableRow key={i}>
            <TableCell>
              {roundNumber(limit / 60, 1) +
                (isAfter(new Date(), new Date(endAt)) ? ' (Expired)' : '')}
            </TableCell>
            <TableCell>{description}</TableCell>
            <TableCell>{format(new Date(startAt), 'dd/MM/yyyy')}</TableCell>
            <TableCell>{format(new Date(endAt), 'dd/MM/yyyy')}</TableCell>
            <TableCell>
              <Link to={_peopleMembers.path + '/' + createdBy.id}>
                {formatPersonName(createdBy, {
                  capitaliseLastName: true,
                  lastNameFirst: true,
                })}
              </Link>
            </TableCell>
            <TableCell>{format(new Date(createdAt), 'dd/MM/yyyy')}</TableCell>
            <TableCell>
              {/* <IconButton
                aria-label="menu"
                color="inherit"
                size={smDown ? 'small' : 'medium'}
                onClick={() => {
                  handleOpenDeleteDialog({ id });
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton> */}
            </TableCell>
          </TableRow>
        ),
      )}
    </Table>
  );
};

export default MemberUpdateScheduleTimeLimitsForm;

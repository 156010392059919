import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Protected } from '@timed/auth';
import { ClientCreateNdisPlanItemTransactionSupplierFormModal } from '@timed/client/components/CreateNdisPlanItemTransactionSupplierFormModal';
import { ClientNdisPlanItemTransactionSupplierAutocomplete } from '@timed/client/components/NdisPlanItemTransactionSupplierAutocomplete';
import {
  CurrencyTextField,
  DateInput,
  FormModal,
  IconButton,
  ModalProps,
  roundNumber,
  Textarea,
} from '@timed/common';
import {
  ClientNdisPlanItem,
  CreateClientNdisPlanItemTransactionDocument,
  CreateClientNdisPlanItemTransactionInput,
  OrderBy,
  useCreateClientNdisPlanItemTransactionMutation,
} from '@timed/gql';
import { format } from 'date-fns';
import _ from 'lodash';
import { useModal } from 'mui-modal-provider';
import { useForm } from 'react-hook-form';

type ClientCreateNdisPlanItemTransactionFormModalProps = Omit<
  ModalProps,
  'children'
> & {
  onClose: () => void;
  clientNdisPlanItem: Pick<ClientNdisPlanItem, 'id' | 'name'>;
  method: 'invoice' | 'adjustment';
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    group: {
      display: 'flex',
      gap: theme.spacing(1),
      flexDirection: 'column',
    },
  }),
);

type FormData = Omit<
  CreateClientNdisPlanItemTransactionInput,
  'clientNdisPlanItem'
>;

const ClientCreateNdisPlanItemTransactionFormModal = ({
  onClose,
  clientNdisPlanItem,
  method,
  ...modalProps
}: ClientCreateNdisPlanItemTransactionFormModalProps) => {
  const classes = useStyles();

  const { showModal } = useModal();

  const [createEntity, response] =
    useCreateClientNdisPlanItemTransactionMutation();

  const {
    control,
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      billingDate: format(new Date(), 'yyyy-MM-dd'),
    },
  });

  const onSuccess = () => {
    const cache = response.client.cache;

    cache.modify({
      fields: {
        clientNdisPlanItemTransactions(existing = []) {
          return [
            ...existing,
            cache.writeQuery({
              data: response.data,
              query: CreateClientNdisPlanItemTransactionDocument,
            }),
          ];
        },
      },
    });

    reset();
  };

  const onSubmit = async (values: FormData) => {
    // Delete nullish values
    Object.keys(values).forEach(
      (k) =>
        (values[k as keyof FormData] === null ||
          values[k as keyof FormData] === 'undefined') &&
        delete values[k as keyof FormData],
    );

    values.value = Number(values.value.toString().replace(/,/g, '')) * 100;

    if (method === 'invoice' && values.value) values.value *= -1;

    createEntity({
      variables: {
        input: {
          ..._.omit(values, ['value']),
          value: roundNumber(values.value, 0),
          clientNdisPlanItem: { id: clientNdisPlanItem.id },
        },
      },
    });
  };

  const handleOpenCreateSupplierModal = () => {
    const modal: { hide: () => void } = showModal(
      ClientCreateNdisPlanItemTransactionSupplierFormModal,
      { onClose: () => modal.hide() },
    );
  };

  return (
    <FormModal
      modalProps={modalProps}
      title={`Record ${method}`}
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.group}>
          <Typography className={classes.bold}>Support Category</Typography>
          {clientNdisPlanItem.name.name}
        </Box>
        <Box className={classes.group}>
          <Typography className={classes.bold}>Date</Typography>
          <DateInput
            required
            keyboard
            clearable
            disableTime
            openTo="year"
            name="billingDate"
            control={control}
            inputVariant="outlined"
            size="small"
            error={!!errors.billingDate}
            helperText={errors.billingDate?.message}
            InputAdornmentProps={{ style: { display: 'none' } }}
          />
        </Box>
        {method === 'invoice' && (
          <Box className={classes.group}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              <Typography className={classes.bold}>Supplier</Typography>
              <Protected tester>
                <IconButton
                  size="small"
                  onClick={() => {
                    handleOpenCreateSupplierModal();
                  }}
                >
                  <AddIcon fontSize="small" />
                </IconButton>
              </Protected>
            </div>
            <ClientNdisPlanItemTransactionSupplierAutocomplete
              inputProps={{
                ...register(`clientNdisPlanItemTransactionSupplier` as const),
                control,
                label: '',
                size: 'small',
              }}
              orderBy={[{ name: OrderBy.ASC }]}
            />
          </Box>
        )}
        <Box className={classes.group}>
          <Typography className={classes.bold}>
            Ammount {method === 'adjustment' && '(+/-)'}
          </Typography>
          <CurrencyTextField
            required
            size="small"
            control={control}
            name="value"
            error={!!errors.value}
            helperText={errors.value?.message}
            onClick={(event) => (event.target as HTMLInputElement).select()}
            validation={{
              min: {
                value: method === 'invoice' ? 0 : -10000000,
                message: 'Value cannot be negative',
              },
            }}
          />
        </Box>
        <Box className={classes.group}>
          <Typography className={classes.bold}>Notes</Typography>
          <Textarea
            name="memo"
            control={control}
            minRows={3}
            validation={{
              maxLength: {
                value: 255,
                message: 'Exceeding 255 characters',
              },
            }}
          />
        </Box>
      </Box>
    </FormModal>
  );
};

export default ClientCreateNdisPlanItemTransactionFormModal;

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  CalendarDateBackButton,
  CalendarDateForwardButton,
  CalendarDateInput,
  CalendarDateRangeInput,
  CalendarDateRedirectButton,
} from '@timed/common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    controlsWrapper: {
      padding: theme.spacing(2),
      display: 'flex',
      gap: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      alignItems: 'start',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
        padding: theme.spacing(1),
        flexDirection: 'column',
      },
    },
    controls: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      minHeight: 40,
      [theme.breakpoints.down('lg')]: {
        gap: theme.spacing(2),
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: 32,
      },
    },
    group: {
      color: theme.palette.divider,
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
      '& .MuiOutlinedInput-label': {
        padding: 5,
      },
    },
  }),
);

const CalendarControlBar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.controlsWrapper}>
      <Box className={classes.controls}>
        <Box className={classes.group}>
          <CalendarDateBackButton />
          <CalendarDateInput />
          <CalendarDateForwardButton />
          <CalendarDateRangeInput />
          {!smDown && <CalendarDateRedirectButton type={'today'} />}
        </Box>
      </Box>
    </div>
  );
};

export default CalendarControlBar;

import { Theme } from '@material-ui/core/styles';
import { DeepPartial } from 'react-hook-form';

export const paletteLight: DeepPartial<Theme['palette']> = {
  type: 'light',
  avatar: {
    body: '#fafafa',
    text: '#9c27b0',
  },
  logo: {
    src: 'icon.white.svg',
  },
  appBar: {
    light: '#af2cc5',
    main: '#9c27b0',
    dark: '#89229b',
  },
  primary: {
    lightest: '#bc37d3',
    light: '#af2cc5',
    main: '#9c27b0',
    dark: '#89229b',
    darkest: '#771e86',
  },
  secondary: {
    lightest: '#ffd033',
    light: '#ffca1a',
    main: '#ffc400',
    dark: '#e6b000',
    darkest: '#cc9d00',
  },
  info: {
    lightest: '#DDEBF7',
    light: '#29B6F6',
    main: '#03A9F4',
    dark: '#039BE5',
    darkest: '#0288D1',
  },
  success: {
    main: 'rgb(46, 204, 113)',
  },
  warning: {
    lightest: '#FFF2CC',
    light: '#e98b39',
    main: '#e67e22',
    dark: '#d67118',
    darkest: '#bf6516',
  },
  error: {
    lightest: '#E57373',
    light: '#EF5350',
    main: '#F44336',
    dark: '#E53935',
    darkest: '#D32F2F',
  },
  background: {
    default: '#fafafa',
    paper: '#f1f2f5',
    paper2: '#e2e4ea',
    paper3: '#d3d6e0',
    paper4: '#c4c9d5',
    paper5: '#b5bbca',
  },
  text: {
    primary: '#172B4D',
    secondary: '#5E6C84',
  },
};

import {
  Client,
  HistoryRestorable,
  Member,
  PersonNamesFragment,
  SupportCoordinator,
} from '@timed/gql';
import { createContext } from 'react';

export type SupportCoordinatorContextType = Pick<
  SupportCoordinator,
  | 'id'
  | 'createdAt'
  | 'firstName'
  | 'middleName'
  | 'lastName'
  | 'preferredName'
  | 'email'
  | 'phone'
  | 'businessName'
  | 'businessEmail'
  | 'businessPhone'
  | 'abn'
  | 'salutation'
  | 'notes'
> & {
  archive?:
    | (Pick<HistoryRestorable, 'id' | 'date' | 'reason'> & {
        actor?: Pick<
          Member,
          'id' | 'firstName' | 'middleName' | 'lastName' | 'preferredName'
        > | null;
      })
    | null;
} & {
  delete?: Pick<HistoryRestorable, 'id' | 'date' | 'reason'> | null;
} & { createdBy?: (Pick<Member, 'id'> & PersonNamesFragment) | null } & {
  clients?: Array<Pick<Client, 'id'>> | null;
};

const SupportCoordinatorContext = createContext<SupportCoordinatorContextType>({
  id: '',
  firstName: '',
});

export default SupportCoordinatorContext;

import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ScheduleContext } from '@timed/schedule';
import { differenceInMinutes, format } from 'date-fns';
import { useContext, useMemo } from 'react';

type ScheduleCalendarNowIndicatorProps = {
  date: Date;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      margin: theme.spacing(0, 0.25),
      pointerEvents: 'none',
      whiteSpace: 'nowrap',
      borderBottom: '1px solid #FF0000',
      position: 'relative',
      zIndex: 100,
      color: 'rgba(255,0,0,0.8)',
      fontWeight: theme.typography.fontWeightBold,
      boxSizing: 'border-box',
      fontSize: '11px',
      height: 13,
      lineHeight: 1,
      backgroundColor: 'rgba(255,255,255,0.8)',
    },
  }),
);

const ScheduleCalendarNowIndicator = (
  props: ScheduleCalendarNowIndicatorProps,
) => {
  const classes = useStyles();
  const {
    setTime,
    dates: { now },
  } = useContext(ScheduleContext);

  const correctedTime = useMemo<Date>(() => {
    return setTime(now);
  }, [now, setTime]);

  return (
    <div
      className={classes.wrapper}
      style={{
        top:
          'calc((-100% - 13px) + (' +
          (differenceInMinutes(correctedTime, props.date) / 1440) * 100 +
          '%)',
      }}
    >
      {format(correctedTime, 'h:mm')}
    </div>
  );
};

export default ScheduleCalendarNowIndicator;

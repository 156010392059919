/**
 * Options object for formatString() function.
 */
type FormatString = {
  /**
   * Remove whitespace from both sides of the string.
   */
  trim?: boolean;

  /**
   * Remove all spaces from the string.
   */
  deepTrim?: boolean;

  /**
   * Convert the string into the specified case.
   */
  setCase?: 'upper' | 'lower';
};

/**
 * Format a value if it is a string.
 * @param value The value to format
 * @returns Formatted string or original value if it was not a string
 */
export const formatString = (
  value: string,
  { trim = true, deepTrim, setCase }: FormatString = {},
): string => {
  if (typeof value === 'string') {
    const newValue = deepTrim
      ? value.replace(/\s/g, '')
      : trim
      ? value.trim()
      : value;

    return setCase
      ? setCase === 'upper'
        ? newValue.toUpperCase()
        : newValue.toLowerCase()
      : newValue;
  }

  return value;
};

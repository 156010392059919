import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  addServerErrors,
  DateInput,
  FormModal,
  ModalProps,
  Textarea,
  TimeInput,
} from '@timed/common';
import {
  ClientNote,
  UpdateClientNoteInput,
  useUpdateClientNoteMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { isValid } from 'date-fns';
import _ from 'lodash';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

export type NotesUpdateNoteFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  note: Pick<ClientNote, 'id' | 'commentedAt' | 'comment'>;
};

type FormData = UpdateClientNoteInput & { time: Date };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        alignItems: 'start',
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
        flexDirection: 'column',
      },
    },
    textarea: {
      backgroundColor: theme.palette.background.paper,
      width: 322,
      color: theme.palette.text.primary,
      border: '1px solid ' + theme.palette.text.disabled,
      // borderRadius: 0,
      [theme.breakpoints.up('md')]: {
        width: 483,
      },
    },
    address: {
      width: 322,
      [theme.breakpoints.up('md')]: {
        width: 483,
      },
    },
    select: {
      width: 322,
    },
    dates: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridAutoColumns: '120px 90px 80px',
      gap: theme.spacing(4),
      alignItems: 'center',
    },
    passive: {
      width: 322,
      backgroundColor: theme.palette.background.paper2,
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2, 4),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    groups: {
      display: 'grid',
      gridTemplateColumns: 'auto',
      gap: theme.spacing(4),
    },
    group: {
      display: 'grid',
      gridTemplateColumns: 'max-content',
      gridAutoRows: 'max-content',
      gap: theme.spacing(1),
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    buttons: {
      flex: '0 1 max-content',
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

const NotesUpdateNoteFormModal = ({
  onClose,
  note,
  ...modalProps
}: NotesUpdateNoteFormModalProps) => {
  const classes = useStyles();

  const [updateNote, { data, loading, error }] = useUpdateClientNoteMutation();

  useLoadingEffect(loading);

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      id: note.id,
      time: note.commentedAt,
      patch: _.pick(note, ['commentedAt', 'comment']),
    },
  });

  if (!loading && !!error) addServerErrors(error, setError);

  const onSuccess = () => {
    onClose();
  };

  const onSubmit = (values: FormData) => {
    updateNote({
      variables: {
        input: { ..._.omit(values, ['time']) },
      },
    });
  };

  const handleChangeTime = useCallback(
    (date?: MaterialUiPickersDate) => {
      if (!!date && isValid(date)) {
        const currentDate = new Date(getValues('patch.commentedAt'));
        currentDate.setHours(date.getHours(), date.getMinutes());
        setValue('patch.commentedAt', currentDate);
      }
    },
    [getValues, setValue],
  );

  return (
    <FormModal
      modalProps={modalProps}
      title="Edit Note"
      loading={loading}
      success={!!data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.groups}>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Date</Typography>
            <Box className={classes.dates}>
              <DateInput
                required
                name="patch.commentedAt"
                control={control}
                inputVariant="outlined"
                size="small"
                error={!!errors.patch?.commentedAt}
                helperText={errors.patch?.commentedAt?.message}
              />
              <TimeInput
                required
                keyboard
                name="time"
                control={control}
                inputVariant="outlined"
                size="small"
                onChange={(date) => {
                  if (isValid(date)) handleChangeTime(date);
                }}
                inputProps={{ style: { textAlign: 'center' } }}
                error={!!errors.time}
              />
            </Box>
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Comment</Typography>
            <Textarea
              name="patch.comment"
              control={control}
              minRows={10}
              maxRows={30}
              className={classes.textarea}
              validation={{
                maxLength: {
                  value: 10000,
                  message: 'Exceeding 10,000 characters',
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </FormModal>
  );
};

export default NotesUpdateNoteFormModal;

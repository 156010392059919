import { ClientFileType } from '@timed/gql';
import { ClientFileCategory } from '@timed/client/constants/ClientFileCategory';
import { FileMetadata } from '@timed/file';

export const clientFilesMetadata: FileMetadata<ClientFileType, ClientFileCategory>[] =
  [
    {
      id: ClientFileType.MISC,
      name: '000. Miscellaneous',
      hasDate: true,
      hasNote: true,
      hasExpiryDate: true,
    },
    {
      id: ClientFileType.NDIS_PLAN,
      category: ClientFileCategory.NDIS,
      name: '001. NDIS Plan',
      hasDate: true,
      hasNote: true,
      hasExpiryDate: true,
    },
    {
      id: ClientFileType.INITIAL_CONSULT,
      category: ClientFileCategory.NDIS,
      name: '002. Initial Consult',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.SERVICE_AGREEMENT,
      category: ClientFileCategory.NDIS,
      name: '003. Service Agreement',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.PRIVACY_CONSENT,
      category: ClientFileCategory.NDIS,
      name: '004. Privacy Consent',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.CONSENT_TO_SHARE,
      category: ClientFileCategory.NDIS,
      name: '005. Consent To Share',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.CONSENT_TO_SHARE_NDIS,
      category: ClientFileCategory.NDIS,
      name: '006. Consent To Share Emailed to NDIS',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.CONSENT_TO_ACT,
      category: ClientFileCategory.NDIS,
      name: '007. Consent To Act',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.CONSENT_TO_ACT_NDIS,
      category: ClientFileCategory.NDIS,
      name: '008. Consent To Act Emailed to NDIS',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.CAREPLAN,
      category: ClientFileCategory.NDIS,
      name: '009. Care Plan',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.PLAN_MANAGER,
      category: ClientFileCategory.NDIS,
      name: '010. Plan Manager',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.NDIS_SERVICE_BOOKING,
      category: ClientFileCategory.NDIS,
      name: '011. NDIS Service Booking',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.RISK_ASSESSMENT,
      category: ClientFileCategory.NDIS,
      name: '013. Risk Assessment',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.CONFLICT_OF_INTEREST,
      category: ClientFileCategory.NDIS,
      name: '014. Conflict Of Interest',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.AC_HANDBOOK,
      category: ClientFileCategory.NDIS,
      name: '015. AC Handbook',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.PRIVACY_STATEMENT,
      category: ClientFileCategory.NDIS,
      name: '016. Privacy Statement',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.PARTICIPANT_CHARTER,
      category: ClientFileCategory.NDIS,
      name: '017. Participant Charter',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.LETTER_TO_PARTICIPANT_NDIS,
      category: ClientFileCategory.NDIS,
      name: '018. Letter to participant (NDIS)',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.OCCUPATIONAL_THERAPIST,
      category: ClientFileCategory.MEDICAL,
      name: '132. Occupational Therapist',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.PHYSIOTHERAPIST,
      category: ClientFileCategory.MEDICAL,
      name: '131. Physiotherapist',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.GP,
      category: ClientFileCategory.MEDICAL,
      name: '130. GP',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.REFERRALS,
      category: ClientFileCategory.MEDICAL,
      name: '033. Referrals',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.TEST_RESULTS,
      category: ClientFileCategory.MEDICAL,
      name: '034. Test Results',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.PSYCHOLOGIST,
      category: ClientFileCategory.MEDICAL,
      name: '133. Psychologist',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.OTHER_SPECIALISTS,
      category: ClientFileCategory.MEDICAL,
      name: '139. Other Specialists',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.CURRENT_MEDICATION,
      category: ClientFileCategory.MEDICAL,
      name: '037. Medication Management',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.PROGRESS_REPORT,
      category: ClientFileCategory.NDIS,
      name: '038. Progress Report',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.FUNCTIONAL_ASSESMENT,
      category: ClientFileCategory.NDIS,
      name: '039. Functional Assessment',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.EMERGENCY_RESPONSE_PLAN,
      category: ClientFileCategory.NDIS,
      name: '040. Emergency and Disaster Management Plan',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.QUOTE,
      category: ClientFileCategory.NDIS,
      name: '060. Quote',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.GUARDIANSHIP_ORDERS,
      category: ClientFileCategory.NDIS,
      name: '061. Guardianship Orders',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.CHANGE_OF_CIRCUMSTANCES,
      category: ClientFileCategory.NDIS,
      name: '062. Change of Circumstances',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.POSITIVE_SUPPORT_PLAN,
      category: ClientFileCategory.NDIS,
      name: '063. Positive Support Plan',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.MANUAL_HANDLING_PLAN,
      category: ClientFileCategory.NDIS,
      name: '064. Manual Handling Plan',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.CLIENT_NEEDS,
      category: ClientFileCategory.NDIS,
      name: '065. Client Information / Needs',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.NDIS_NOMINEE,
      category: ClientFileCategory.NDIS,
      name: '110. NDIS Nominee',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.INCIDENT_REPORT,
      category: ClientFileCategory.NDIS,
      name: '404. Incident Report',
      hasDate: true,
      hasNote: true,
    },
    {
      id: ClientFileType.SC_PLAN,
      category: ClientFileCategory.NDIS,
      name: '409. Support Coordination Plan',
      hasDate: true,
      hasNote: true,
    },
  ];

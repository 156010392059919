import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { FormModal, ModalProps, TextField } from '@timed/common';
import {
  CreateClientNdisPlanItemTransactionSupplierDocument,
  CreateClientNdisPlanItemTransactionSupplierInput,
  useCreateClientNdisPlanItemTransactionSupplierMutation,
} from '@timed/gql';
import { useForm } from 'react-hook-form';

type ClientCreateNdisPlanItemTransactionSupplierFormModalProps = Omit<
  ModalProps,
  'children'
> & {
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    group: {
      display: 'flex',
      gap: theme.spacing(1),
      flexDirection: 'column',
    },
  }),
);

type FormData = CreateClientNdisPlanItemTransactionSupplierInput;

const ClientCreateNdisPlanItemTransactionSupplierFormModal = ({
  onClose,
  ...modalProps
}: ClientCreateNdisPlanItemTransactionSupplierFormModalProps) => {
  const classes = useStyles();

  const [createEntity, response] =
    useCreateClientNdisPlanItemTransactionSupplierMutation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>();

  const onSuccess = () => {
    const cache = response.client.cache;

    cache.modify({
      fields: {
        clientNdisPlanItemTransactionSuppliers(existing = []) {
          return [
            ...existing,
            cache.writeQuery({
              data: response.data,
              query: CreateClientNdisPlanItemTransactionSupplierDocument,
            }),
          ];
        },
      },
    });

    reset();
  };

  const onSubmit = async (input: FormData) => {
    createEntity({ variables: { input } });
  };

  return (
    <FormModal
      modalProps={modalProps}
      title="Add Supplier"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.group}>
          <Typography className={classes.bold}>Name</Typography>
          <TextField
            required
            control={control}
            name="name"
            size="small"
            variant="outlined"
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        </Box>
      </Box>
    </FormModal>
  );
};

export default ClientCreateNdisPlanItemTransactionSupplierFormModal;

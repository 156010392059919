import { useMediaQuery, useTheme, withStyles } from '@material-ui/core';
import { useAuth } from '@timed/auth';
import { ButtonAsync } from '@timed/common';
import {
  Event,
  Permission,
  useClockOffEventAttendedMutation,
  useClockOffEventMutation,
  useClockOnEventAttendedMutation,
  useClockOnEventMutation,
} from '@timed/gql';
import {
  addSeconds,
  differenceInMilliseconds,
  differenceInMinutes,
  isBefore,
} from 'date-fns';
import { useEffect, useMemo, useRef, useState } from 'react';

type EventClockButtonProps = {
  event: Pick<
    Event,
    'id' | 'startAt' | 'endAt' | 'clockedOnAt' | 'clockedOffAt'
  >;
  setErrorText: React.Dispatch<React.SetStateAction<string | null>>;
};

const ClockOnButton = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: '#3bb027',
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(48),
    },
    '&:hover': {
      backgroundColor: '#297b1b',
    },
    '& .MuiButton-label': {
      fontSize: 32,
    },
  },
}))(ButtonAsync);

const ClockOffButton = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: 'red',
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(48),
    },
    '&:hover': {
      backgroundColor: 'darkred',
    },
  },
}))(ButtonAsync);

const EventClockOnButton = ({ event, setErrorText }: EventClockButtonProps) => {
  const timer = useRef<number>();

  const theme = useTheme();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { permissible } = useAuth();

  const [[latitude, longitude], setCoords] = useState<
    [string | null, string | null]
  >([null, null]);

  const [clockOnEvent, clockOnResponse] = useClockOnEventMutation();
  const [clockOnEventAttended, clockOnAttendedResponse] =
    useClockOnEventAttendedMutation();
  const [clockOffEvent, clockOffResponse] = useClockOffEventMutation();
  const [clockOffEventAttended, clockOffAttendedResponse] =
    useClockOffEventAttendedMutation();

  const [showClockOff, setShowClockOff] = useState<boolean>(
    !!event.clockedOnAt &&
      !isBefore(new Date(), addSeconds(new Date(event.clockedOnAt), 60)),
  );

  const [fetchingLocation, setFetchingLocation] = useState<boolean>(false);

  const loading =
    clockOnResponse.loading ||
    clockOnAttendedResponse.loading ||
    clockOffResponse.loading ||
    clockOffAttendedResponse.loading;

  const elapsedDurationPercentage = useMemo(() => {
    const duration = differenceInMinutes(
      new Date(event.endAt),
      new Date(event.startAt),
    );

    const elapsedDuration = differenceInMinutes(
      new Date(),
      new Date(event.startAt),
    );

    return Math.min(elapsedDuration / duration, 1);
  }, [event.endAt, event.startAt]);

  const handleClick = async () => {
    const geolocationApi = navigator.geolocation;

    if (!!geolocationApi) {
      setFetchingLocation(true);

      geolocationApi.getCurrentPosition(
        ({ coords }) => {
          setCoords([coords.latitude.toString(), coords.longitude.toString()]);
          setFetchingLocation(false);
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              setErrorText('Permission denied fetching geolocation');
              break;
            case error.POSITION_UNAVAILABLE:
              setErrorText('Position unavailable');
              break;
            case error.TIMEOUT:
              setErrorText('Timed out fetching geolocation');
              break;
            default:
              setErrorText('Unknown error fetching geolocation');
              break;
          }
          setFetchingLocation(false);
        },
        { enableHighAccuracy: true },
      );
    }
  };

  useEffect(() => {
    if (latitude && longitude) {
      if (!event.clockedOnAt)
        permissible({ permissions: Permission.EVENT_WRITE })
          ? clockOnEvent({
              variables: {
                input: { id: event.id, latitude, longitude },
              },
            })
          : clockOnEventAttended({
              variables: { input: { id: event.id, latitude, longitude } },
            });
      else
        permissible({ permissions: Permission.EVENT_WRITE })
          ? clockOffEvent({
              variables: {
                input: { id: event.id, latitude, longitude },
              },
            })
          : clockOffEventAttended({
              variables: { input: { id: event.id, latitude, longitude } },
            });

      // Unset coordinates
      setCoords([null, null]);
    }
  }, [
    event.id,
    event.clockedOnAt,
    permissible,
    latitude,
    longitude,
    clockOnEvent,
    clockOnEventAttended,
    clockOffEvent,
    clockOffEventAttended,
  ]);

  useEffect(() => {
    if (
      !!event.clockedOnAt &&
      isBefore(new Date(), addSeconds(new Date(event.clockedOnAt), 60))
    ) {
      const timeRemaining = differenceInMilliseconds(
        addSeconds(new Date(event.clockedOnAt), 60),
        new Date(),
      );

      timer.current = window.setTimeout(() => {
        setShowClockOff(true);
      }, timeRemaining);
    }
    return () => clearTimeout(timer.current);
  }, [event.clockedOnAt]);

  // Display nothing if:
  // 1) Event is more than 1 hour in the future.
  // 2) Event has been clocked off.
  if (
    (!event.clockedOnAt &&
      differenceInMinutes(new Date(event.startAt), new Date()) > 60) ||
    !!event.clockedOffAt
  )
    return null;

  return !showClockOff ? (
    <ClockOnButton
      loading={fetchingLocation || loading}
      success={
        (clockOnResponse.called &&
          !clockOnResponse.loading &&
          !clockOnResponse.error) ||
        (clockOnAttendedResponse.called &&
          !clockOnAttendedResponse.loading &&
          !clockOnAttendedResponse.error)
      }
      variant="contained"
      onClick={() => {
        handleClick();
      }}
      style={{ display: !!event.clockedOnAt ? 'none' : 'initial' }}
    >
      Click To
      <br />
      Start shift
    </ClockOnButton>
  ) : (
    <ClockOffButton
      loading={fetchingLocation || loading}
      success={
        (clockOffResponse.called &&
          !clockOffResponse.loading &&
          !clockOffResponse.error) ||
        (clockOffAttendedResponse.called &&
          !clockOffAttendedResponse.loading &&
          !clockOffAttendedResponse.error)
      }
      variant="contained"
      onClick={() => {
        handleClick();
      }}
      style={
        smDown
          ? {
              height: Math.max(
                theme.spacing(48) * elapsedDurationPercentage,
                36.5,
              ),
              fontSize: Math.max(32 * elapsedDurationPercentage, 16),
            }
          : undefined
      }
    >
      Click To End shift
    </ClockOffButton>
  );
};

export default EventClockOnButton;

import { Menu, MenuItem, useTheme } from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import { AuthContextType, Protected, useAuth } from '@timed/auth';
import { IconButton } from '@timed/common';
import {
  EventAccessLogsModal,
  EventDeleteFormModal,
  EventUpdateFormModal,
} from '@timed/event';
import { EventArchiveFormModal } from '@timed/event/components/ArchiveFormModal';
import { EventCancelFormModal } from '@timed/event/components/CancelFormModal';
import { EventHistoryModal } from '@timed/event/components/HistoryModal';
import { eventIssuesMetadata } from '@timed/event/constants/eventIssuesMetadata';
import {
  CreateEventIssueDocument,
  EntityRestorableState,
  EventIssueType,
  GetEventByIdQuery,
  GetEventClaimsQuery,
  useCreateEventIssueMutation,
  useRestoreEventsMutation,
} from '@timed/gql';
import { ScheduleContextType } from '@timed/schedule';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type EventSummaryModalMenuButtonProps = {
  event: GetEventByIdQuery['eventById'];
  claims: GetEventClaimsQuery['eventClaims'];
  setTime: ScheduleContextType['setTime'];
  closeSummaryPage: () => void;
  permissible: AuthContextType['permissible'];
};

const EventSummaryModalMenuButton = ({
  event,
  claims,
  closeSummaryPage,
  setTime,
}: EventSummaryModalMenuButtonProps) => {
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { showModal } = useModal();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { permissible } = useAuth();

  const [restoreEntities] = useRestoreEventsMutation({
    onCompleted: () => {
      enqueueSnackbar('Entity restored', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon fontSize="small" style={{ fill: 'white' }} />
          </IconButton>
        ),
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.message ?? 'Error restoring entity', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon style={{ fill: 'white' }} />
          </IconButton>
        ),
      });
    },
  });

  const [createIssue, { client }] = useCreateEventIssueMutation();

  const handleCreateIssue = (type: EventIssueType) => {
    createIssue({
      variables: { input: { type, event: { id: event.id } } },
      onCompleted: ({ createEventIssue: data }) => {
        if (client.cache) {
          client.cache.modify({
            id: `Event:${data.event.id}`,
            fields: {
              issues(existing = []) {
                return [
                  ...existing,
                  client.cache.writeQuery({
                    data,
                    query: CreateEventIssueDocument,
                  }),
                ];
              },
            },
          });
        }
      },
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenEditEventModal = () => {
    const modal: { hide: () => void } = showModal(EventUpdateFormModal, {
      onClose: () => {
        modal.hide();
      },
      closeSummaryPage,
      event,
      claims,
      setTime,
      permissible,
    });
  };

  const handleOpenCancelEventModal = () => {
    const modal: { hide: () => void } = showModal(EventCancelFormModal, {
      onClose: () => {
        modal.hide();
      },
      event,
    });
  };

  const handleOpenDeleteEventModal = () => {
    const modal: { hide: () => void } = showModal(EventDeleteFormModal, {
      onClose: () => {
        modal.hide();
      },
      eventId: event.id,
    });
  };

  const handleOpenArchiveEventModal = () => {
    const modal: { hide: () => void } = showModal(EventArchiveFormModal, {
      onClose: () => {
        modal.hide();
      },
      eventId: event.id,
    });
  };

  const handleOpenHistoriesModal = () => {
    const modal: { hide: () => void } = showModal(EventHistoryModal, {
      onClose: () => {
        modal.hide();
      },
      eventId: event.id,
    });
  };

  const handleOpenAccessLogsModal = () => {
    const modal: { hide: () => void } = showModal(EventAccessLogsModal, {
      onClose: () => {
        modal.hide();
      },
      eventId: event.id,
    });
  };

  return (
    <span>
      <IconButton aria-label="menu" onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {!event.cancel &&
          !event.delete &&
          (!event.archive || permissible({ tester: true })) && (
            <MenuItem
              onClick={() => {
                handleOpenEditEventModal();
                handleClose();
              }}
            >
              Edit event
            </MenuItem>
          )}

        {!event.archive &&
          !event.delete &&
          (event.cancel ? (
            <MenuItem
              onClick={() => {
                restoreEntities({
                  variables: {
                    input: {
                      type: EntityRestorableState.CANCELLED,
                      ids: [event.id],
                    },
                  },
                });
                handleClose();
              }}
            >
              Uncancel event
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => {
                handleOpenCancelEventModal();
                handleClose();
              }}
            >
              Cancel event
            </MenuItem>
          ))}

        {!event.delete &&
          (event.archive ? (
            <Protected tester>
              <MenuItem
                onClick={() => {
                  restoreEntities({
                    variables: {
                      input: {
                        type: EntityRestorableState.ARCHIVED,
                        ids: [event.id],
                      },
                    },
                  });
                  handleClose();
                }}
              >
                Unarchive event
              </MenuItem>
            </Protected>
          ) : (
            <Protected tester>
              <MenuItem
                onClick={() => {
                  handleOpenArchiveEventModal();
                  handleClose();
                }}
              >
                Archive event
              </MenuItem>
            </Protected>
          ))}

        {!event.archive &&
          (event.delete ? (
            <Protected tester>
              <MenuItem
                onClick={() => {
                  restoreEntities({
                    variables: {
                      input: {
                        type: EntityRestorableState.DELETED,
                        ids: [event.id],
                      },
                    },
                  });
                  handleClose();
                }}
              >
                Undelete event
              </MenuItem>
            </Protected>
          ) : (
            <Protected admin>
              <MenuItem
                onClick={() => {
                  handleOpenDeleteEventModal();
                  handleClose();
                }}
              >
                Delete event
              </MenuItem>
            </Protected>
          ))}

        <Protected admin>
          <MenuItem
            onClick={() => {
              handleOpenHistoriesModal();
              handleClose();
            }}
          >
            History
          </MenuItem>
        </Protected>

        <Protected tester>
          <MenuItem
            onClick={() => {
              handleOpenAccessLogsModal();
              handleClose();
            }}
          >
            Access Logs
          </MenuItem>
        </Protected>

        <Protected admin>
          {eventIssuesMetadata.map(({ name, type }) => (
            <MenuItem
              disabled={event.issues
                .filter(({ closedAt }) => !closedAt)
                .some((issue) => issue.type === EventIssueType[type])}
              style={{ backgroundColor: yellow[100] }}
              onClick={() => {
                handleCreateIssue(EventIssueType[type]);
                handleClose();
              }}
            >
              <div style={{ display: 'flex', gap: theme.spacing(2) }}>
                <span style={{ fontWeight: theme.typography.fontWeightMedium }}>
                  Add Issue:
                </span>
                {name}
              </div>
            </MenuItem>
          ))}
        </Protected>
      </Menu>
    </span>
  );
};

export default EventSummaryModalMenuButton;

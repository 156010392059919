import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import { _peopleMembers } from '@timed/app';
import { Button, formatPersonName, Link, roundNumber } from '@timed/common';
import {
  MemberUpload,
  OrderBy,
  PersonNamesFragment,
  ProcessingState,
  useDownloadMemberUploadByIdLazyQuery,
  useGetMemberUploadsQuery,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { memberFilesMetadata } from '@timed/member';
import { UploadProcessModal } from '@timed/upload/components/ProcessModal';
import clsx from 'clsx';
import { format } from 'date-fns';
import { useModal } from 'mui-modal-provider';
import { useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(8),
      alignItems: 'start',
    },
    table: {
      display: 'grid',
      gridTemplateColumns: 'repeat(8, auto)',
      columnGap: theme.spacing(8),
      rowGap: theme.spacing(2),
      alignItems: 'center',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(8),
      alignItems: 'start',
    },
    link: {
      color: theme.palette.primary.main,
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
    actions: {
      display: 'flex',
      gap: theme.spacing(2),
    },
    highligh: {
      backgroundColor: yellow[200],
    },
  }),
);

const UploadFilesTable = () => {
  const classes = useStyles();

  const { showModal } = useModal();

  const [highlightedRow, highlightRow] = useState<number | undefined>();

  const { data, loading } = useGetMemberUploadsQuery({
    variables: {
      input: {
        where: { processingState: { _eq: ProcessingState.PENDING } },
        orderBy: [
          { uploadedFor: { lastName: OrderBy.ASC } },
          { file: { size: OrderBy.ASC } },
          { createdAt: OrderBy.ASC },
        ],
      },
    },
  });

  const [downloadFile] = useDownloadMemberUploadByIdLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (data) =>
      window.open(data.downloadMemberUploadById.path, '_blank'),
  });

  useLoadingEffect(loading);

  const handleOpenProcessModal = (
    upload: Pick<MemberUpload, 'id' | 'type'> & {
      uploadedFor: PersonNamesFragment;
    },
    accept: boolean,
  ) => {
    const modal: { hide: () => void } = showModal(UploadProcessModal, {
      onClose: () => {
        modal.hide();
      },
      accept,
      upload,
    });
  };

  return (
    <Box className={classes.wrapper}>
      {!data?.memberUploads.length ||
      !data.memberUploads.filter(
        ({ processingState }) => processingState === ProcessingState.PENDING,
      ).length ? (
        <Typography>There are no pending uploaded files.</Typography>
      ) : (
        <Box className={classes.table}>
          <Typography className={classes.bold} variant="h3">
            Employee
          </Typography>
          <Typography className={classes.bold} variant="h3">
            Uploaded By
          </Typography>
          <Typography className={classes.bold} variant="h3">
            Uploaded At
          </Typography>
          <Typography className={classes.bold} variant="h3">
            Category
          </Typography>
          <Typography className={classes.bold} variant="h3">
            File
          </Typography>
          <Typography className={classes.bold} variant="h3">
            Size
          </Typography>
          <Typography className={classes.bold} variant="h3">
            MIME
          </Typography>
          <Typography className={classes.bold} variant="h3">
            Actions
          </Typography>
          <Box></Box>
          <Box></Box>
          <Box></Box>
          <Box></Box>
          <Box></Box>
          <Box></Box>
          <Box></Box>
          <Box></Box>
          <Box></Box>
          <Box></Box>
          <Box></Box>
          <Box></Box>
          <Box></Box>
          <Box></Box>
          <Box></Box>
          <Box></Box>
          {data.memberUploads
            .filter(
              ({ processingState }) =>
                processingState === ProcessingState.PENDING,
            )
            .map((upload, i) => (
              <>
                <Typography
                  className={
                    highlightedRow === i ? classes.highligh : undefined
                  }
                >
                  <Link to={_peopleMembers.path + '/' + upload.uploadedFor.id}>
                    {formatPersonName(upload.uploadedFor, {
                      capitaliseLastName: true,
                      lastNameFirst: true,
                    })}
                  </Link>
                </Typography>
                <Typography
                  className={
                    highlightedRow === i ? classes.highligh : undefined
                  }
                >
                  <Link to={_peopleMembers.path + '/' + upload.uploadedBy.id}>
                    {formatPersonName(upload.uploadedBy, {
                      capitaliseLastName: true,
                      lastNameFirst: true,
                    })}
                  </Link>
                </Typography>
                <Typography
                  className={
                    highlightedRow === i ? classes.highligh : undefined
                  }
                >
                  {format(new Date(upload.createdAt), 'dd/MM/yyyy HH:mm')}
                </Typography>
                <Typography
                  className={
                    highlightedRow === i ? classes.highligh : undefined
                  }
                >
                  {
                    memberFilesMetadata.find(({ id }) => id === upload.type)!
                      .name
                  }
                </Typography>
                <Typography
                  className={
                    highlightedRow === i
                      ? clsx(classes.link, classes.highligh)
                      : classes.link
                  }
                  onClick={() => {
                    highlightRow(i);
                    downloadFile({ variables: { id: upload.id } });
                  }}
                >
                  {upload.file.name}
                </Typography>
                <Typography
                  className={
                    highlightedRow === i ? classes.highligh : undefined
                  }
                >
                  {roundNumber(upload.file.size / 1024, 2)} KB
                </Typography>
                <Typography
                  className={
                    highlightedRow === i ? classes.highligh : undefined
                  }
                >
                  {upload.file.mime}
                </Typography>
                <Box className={classes.actions}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      handleOpenProcessModal(upload, true);
                    }}
                  >
                    Accept
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      handleOpenProcessModal(upload, false);
                    }}
                  >
                    Reject
                  </Button>
                </Box>
              </>
            ))}
        </Box>
      )}
    </Box>
  );
};

export default UploadFilesTable;

import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
} from '@material-ui/core';

export type IconButtonProps = MuiIconButtonProps;

const IconButton = ({ children, ...props }: IconButtonProps) => (
  <MuiIconButton {...props}>{children}</MuiIconButton>
);

export default IconButton;

import { useTheme } from '@material-ui/core';
import {
  CalendarContext,
  CalendarContextType,
  CalendarEventEntity,
  CalendarInteractWithEntityFn,
  contrastingColor,
  hexToRGB,
  useRouter,
} from '@timed/common';
import { StartDateType } from '@timed/schedule';
import {
  differenceInDays,
  differenceInMilliseconds,
  differenceInMinutes,
  format,
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  isBefore,
  isEqual,
  parse,
  startOfDay,
  startOfWeek,
  subDays,
  subWeeks,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { addDays, eachDayOfInterval, startOfMinute } from 'date-fns/esm';
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';

type CalendarProviderProps = React.PropsWithChildren<
  Pick<
    CalendarContextType,
    | 'interactive'
    | 'cellClickModal'
    | 'eventClickModal'
    | 'cellClickModalProps'
    | 'eventClickModalProps'
  > & {
    events: CalendarEventEntity[];
  }
>;

const CalendarProvider = ({
  children,
  interactive,
  cellClickModal,
  cellClickModalProps,
  eventClickModal,
  eventClickModalProps,
  events = [],
}: CalendarProviderProps) => {
  const {
    search: [searchParams, setSearchParams],
  } = useRouter();

  const theme = useTheme();

  const scrollableAreaRef = useRef<HTMLDivElement>(null);

  const [entityInteractions, interactWithEntity] = useReducer(
    (
      state: CalendarContextType['entityInteractions'],
      { interaction, target, reset }: CalendarInteractWithEntityFn,
    ): CalendarContextType['entityInteractions'] => {
      // Reset values for specified entity type.
      if (reset)
        return {
          ...state,
          [target.type]: Object.fromEntries(
            Object.keys(state[target.type]).map((k) => [k, null]),
          ),
        };

      if (!interaction)
        throw new Error('Interaction prop is required when reset = false');

      if (!target.key)
        throw new Error('Target.Key prop is required when reset = false');

      return {
        ...state,
        [target.type]: {
          ...state[target.type],
          [interaction]: {
            key: target.key,
            time: new Date(),
          },
        },
      };
    },
    {
      cell: {
        down: null,
        over: null,
        up: null,
      },
      event: {
        down: null,
        over: null,
        up: null,
      },
    },
  );

  /**
   * The current datetime
   */
  const [now, setNow] = useState<Date>(new Date());

  /**
   * Timer to refresh 'now' indicator on calendar
   */
  const timer = useRef<number>();

  /**
   * Default quantity of days between 'from' and 'to' dates
   */
  const defaultDateRange = 28;

  /**
   * Start of the current day.
   * Memoisation is requred to prevent infinite loops
   */
  const today = useMemo(() => startOfDay(now), [now]);

  /**
   * Quantity of days between and 'from' and 'to' dates
   */
  // const range =
  //   (searchParams.get('r') && parseInt(searchParams.get('r')!)) ||
  //   defaultDateRange;
  const range =
    (searchParams.get('r') && parseInt(searchParams.get('r')!)) ||
    (localStorage.getItem('schedule.range') &&
      parseInt(localStorage.getItem('schedule.range')!)) ||
    defaultDateRange;

  const weekStartsOn: CalendarContextType['weekStartsOn'] = 1;

  const dayOffset = parseInt(
    localStorage.getItem('schedule.settings.dayOffset') ?? '7',
  );

  const startDate =
    (localStorage.getItem('schedule.settings.startDate') as StartDateType) ??
    'current-week';

  /**
   * First date to display on the calendar.
   * Memoisation is requred to prevent infinite loops.
   */
  const from = useMemo(() => {
    return (
      (!!searchParams.get('f') &&
        parse(searchParams.get('f')!, 'ddMMyyyy', new Date())) ||
      (startDate === 'current-day' && today) ||
      (startDate === 'current-week' && startOfWeek(today, { weekStartsOn })) ||
      (startDate === 'prev-week' &&
        subWeeks(startOfWeek(today, { weekStartsOn }), 1)) ||
      (startDate === 'day-offset' && dayOffset && subDays(today, dayOffset)) ||
      today
    );
  }, [searchParams, today, dayOffset, startDate, weekStartsOn]);

  /**
   * Day after the last date displayed on the calendar.
   * Memoisation is requred to prevent infinite loops.
   */
  const to = useMemo(() => addDays(from, range), [from, range]);

  /**
   * The current timezone of the calendar.
   * Undefined = local timezone.
   */
  const [timezone, setTimezone] = useState<string | undefined>();

  /**
   * Set first date to display on the calendar
   */
  const setFrom = useCallback(
    (value?: number | Date, redirect = true): void => {
      if (!value) {
        const startDateType = localStorage.getItem(
          'schedule.settings.startDate',
        );
        const dayOffset = localStorage.getItem('schedule.settings.dayOffset');

        if (startDateType === 'prev-week')
          value = subDays(
            startOfWeek(today, {
              weekStartsOn: parseInt(
                localStorage.getItem('schedule.settings.weekStartsOn') ?? '1',
              ) as 0 | 2 | 1 | 3 | 4 | 5 | 6 | undefined,
            }),
            7,
          );
        else if (startDateType === 'day-offset' && !!dayOffset)
          value = subDays(today, parseInt(dayOffset) ?? 7);
        else if (startDateType === 'current-day') value = today;
        else
          value = startOfWeek(today, {
            weekStartsOn: parseInt(
              localStorage.getItem('schedule.settings.weekStartsOn') ?? '1',
            ) as 0 | 2 | 1 | 3 | 4 | 5 | 6 | undefined,
          });
      }

      searchParams.set(
        'f',
        format(
          typeof value === 'number' ? addDays(from, value) : value,
          'ddMMyyyy',
        ),
      );

      redirect && setSearchParams(searchParams);
    },

    // refetch();
    [from, searchParams, setSearchParams, today],
  );

  /**
   * Set quantity of days between 'from' and 'to' dates
   */
  const setRange = useCallback(
    (value: number, redirect = true): void => {
      value && localStorage.setItem('schedule.range', value.toString());
      searchParams.set('r', value.toString());
      redirect && setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const calcTime = useCallback(
    (date: Date, reverse = false): Date => {
      date = new Date(date);
      return !!timezone
        ? reverse
          ? zonedTimeToUtc(date, timezone)
          : utcToZonedTime(date, timezone)
        : date;
    },
    [timezone],
  );

  /**
   * Redirect on missing query parameters
   */
  useEffect(() => {
    let redirect: boolean = false;

    if (!searchParams.get('f')) {
      setFrom(from, false);
      redirect = true;
    }

    if (!searchParams.get('r')) {
      setRange(range, false);
      redirect = true;
    }

    redirect && setSearchParams(searchParams);
  }, [from, range, setFrom, setRange, setSearchParams, searchParams]);

  /**
   * "Now" indicator refresh timer.
   */
  useEffect(() => {
    // Calc the difference in time between the page render
    // and the start of the current minute so that the timer
    // refreshes on the minutes mark.
    const offset = differenceInMilliseconds(now, startOfMinute(now));
    timer.current = window.setTimeout(() => {
      setNow(new Date());
    }, 60000 - offset);
    return () => clearTimeout(timer.current);
  }, [now, setNow]);

  /**
   * Convert events into shifts
   */
  const formattedEvents: CalendarContextType['events'] | undefined =
    useMemo(() => {
      const formattedEvents: CalendarContextType['events'] = [];

      for (let i = 0; i < range; i++) {
        formattedEvents[i] = [];
      }

      // Step 1: Calculate base values for each event block

      events.forEach(({ style = {}, ...event }) => {
        const startAt = calcTime(event.startAt);
        const endAt = calcTime(event.endAt);
        const eachDate = eachDayOfInterval({ start: startAt, end: endAt }).map(
          (date) => format(date, 'yyyy-MM-dd'),
        );
        const eventDateStart = startOfDay(startAt);
        const eventDateEnd = startOfDay(endAt);

        // If event ends at midnight and spans multiple days, subtrack 1 day
        const daySpan =
          differenceInDays(eventDateEnd, eventDateStart) -
          (differenceInDays(eventDateEnd, eventDateStart) > 0 &&
          getHours(endAt) === 0
            ? 1
            : 0);

        // Assign default background color if one was not specified.
        style.backgroundColor ??= theme.palette.info.light;

        // Assign default font colour if one was not specified
        style.color ??= contrastingColor(hexToRGB(style.backgroundColor))!;

        let i = 0;
        while (i <= daySpan) {
          // Start of day of block
          const blockDateStart = addDays(eventDateStart, i);
          // End of day of block
          const blockDateEnd = addDays(blockDateStart, 1);
          // Start datetime of block
          const blockStartAt = i === 0 ? startAt : blockDateStart;
          // End datetime of block
          const blockEndAt = i < daySpan && daySpan > 0 ? blockDateEnd : endAt;
          if (blockStartAt < addDays(from, range) && blockStartAt >= from) {
            formattedEvents[differenceInDays(blockStartAt, from)].push({
              parent: {
                id: event.id,
                startAt,
                endAt,
                duration: differenceInMinutes(endAt, startAt),
                style,
                content: event.content,
              },
              startAt: blockStartAt,
              endAt: blockEndAt,
              duration: differenceInMinutes(blockEndAt, blockStartAt),
              width: 0,
              height:
                (differenceInMinutes(
                  new Date(
                    Date.UTC(
                      getYear(blockEndAt),
                      getMonth(blockEndAt),
                      getDate(blockEndAt),
                      getHours(blockEndAt),
                      getMinutes(blockEndAt),
                    ),
                  ),

                  new Date(
                    Date.UTC(
                      getYear(blockStartAt),
                      getMonth(blockStartAt),
                      getDate(blockStartAt),
                      getHours(blockStartAt),
                      getMinutes(blockStartAt),
                    ),
                  ),
                ) /
                  1440) *
                100,
              leftOffset: 0,
              topOffset:
                (differenceInMinutes(
                  new Date(
                    Date.UTC(
                      getYear(blockStartAt),
                      getMonth(blockStartAt),
                      getDate(blockStartAt),
                      getHours(blockStartAt),
                      getMinutes(blockStartAt),
                    ),
                  ),

                  new Date(
                    Date.UTC(
                      getYear(blockDateStart),
                      getMonth(blockDateStart),
                      getDate(blockDateStart),
                      getHours(blockDateStart),
                      getMinutes(blockDateStart),
                    ),
                  ),
                ) /
                  1440) *
                100,
              futureOverlaps: 0,
              pastOverlaps: 0,
              startOfOverlap: false,
              endOfOverlap: false,
              startOverlapsIndex: 0,
            });
          }
          i++;
        }
      });

      // Sort shifts in chronological order
      for (let i = 0; i < formattedEvents.length; i++) {
        formattedEvents[i].sort((previous, current) => {
          if (isBefore(previous.startAt, current.startAt)) {
            return -1;
          }
          if (isEqual(previous.startAt, current.startAt)) {
            // Both start at same time, now sort based on duration
            if (previous.duration > current.duration) {
              // Longest shift first
              return -1;
            }
            if (previous.duration === current.duration) {
              // Both are the same duration, now sort based on ID
              if (previous.parent.id > current.parent.id) {
                return -1;
              }
            }
          }
          return 1;
        });
      }

      // Calculate future and past overlaps
      for (let i = 0; i < formattedEvents.length; i++) {
        // Loop through each day
        for (let j = 0; j < formattedEvents[i].length; j++) {
          // Loop through each shift for this day
          formattedEvents[i][j].pastOverlaps = formattedEvents[i].filter(
            (event) => {
              /**
               * To calculate a past shifts, both conditions must be true:
               * - End after current shift starts
               * - (Start before current shift
               *   OR ((start at same time as current shift
               *          AND is longer than current shift)
               *      OR (start at same time as current shift
               *         AND is same length
               *        AND is id is before current shift's id))
               */
              return (
                (isBefore(event.startAt, formattedEvents[i][j].startAt) ||
                  (isEqual(event.startAt, formattedEvents[i][j].startAt) &&
                    (event.duration > formattedEvents[i][j].duration ||
                      (event.duration === formattedEvents[i][j].duration &&
                        event.parent.id > formattedEvents[i][j].parent.id)))) &&
                isBefore(formattedEvents[i][j].startAt, event.endAt)
              );
            },
          ).length;

          formattedEvents[i][j].futureOverlaps = formattedEvents[i].filter(
            (shift) => {
              return (
                (isBefore(formattedEvents[i][j].startAt, shift.startAt) ||
                  (isEqual(formattedEvents[i][j].startAt, shift.startAt) &&
                    (shift.duration < formattedEvents[i][j].duration ||
                      (shift.duration === formattedEvents[i][j].duration &&
                        shift.parent.id < formattedEvents[i][j].parent.id)))) &&
                isBefore(shift.startAt, formattedEvents[i][j].endAt)
              );
            },
          ).length;
        }
      }

      // Calculate overlap groups
      for (let i = 0; i < formattedEvents.length; i++) {
        // Only loop through days that have shifts
        if (formattedEvents[i].length > 0) {
          // For first shift of the day set startOfOverlap = true
          formattedEvents[i][0].startOfOverlap = true;
          // Loop through each shift for this day
          for (let j = 0; j < formattedEvents[i].length; j++) {
            if (formattedEvents[i][j].startOfOverlap) {
              formattedEvents[i][
                j + formattedEvents[i][j].futureOverlaps
              ].endOfOverlap = true;
              // Check if there is a shift after previous endOfOverlap
              if (
                formattedEvents[i][j + formattedEvents[i][j].futureOverlaps + 1]
              ) {
                // If there is, set it as startOfOverlap = true
                formattedEvents[i][
                  j + formattedEvents[i][j].futureOverlaps + 1
                ].startOfOverlap = true;
              }
            }
          }
        }
      }

      // Calculate widths
      for (let i = 0; i < formattedEvents.length; i++) {
        // Only loop through days that have shifts
        if (formattedEvents[i].length > 0) {
          // Loop through each shift for this day
          for (let j = 0; j < formattedEvents[i].length; j++) {
            if (formattedEvents[i][j].startOfOverlap) {
              // First shift in overlap group
              if (formattedEvents[i][j].pastOverlaps > 0) {
                // Shift has pastOverlaps

                // Loop past overlaps and find the shift with the earliest endAt
                // 1. Create new array of past overlays
                const pastOverlays: CalendarContextType['events'][0] =
                  formattedEvents[i].filter(
                    (shift) =>
                      (isBefore(shift.startAt, formattedEvents[i][j].startAt) ||
                        (isEqual(
                          shift.startAt,
                          formattedEvents[i][j].startAt,
                        ) &&
                          (shift.duration > formattedEvents[i][j].duration ||
                            (shift.duration ===
                              formattedEvents[i][j].duration &&
                              shift.parent.id >
                                formattedEvents[i][j].parent.id)))) &&
                      isBefore(formattedEvents[i][j].startAt, shift.endAt),
                  );

                const earliestEndAt = pastOverlays.sort((previous, current) =>
                  previous.endAt > current.endAt ? -1 : 1,
                )[0];

                formattedEvents[i][j].width = Math.min(
                  // Immediate previous shift's width times current shift's past overlaps
                  // 100 - shifts[i][j - 1].width * shifts[i][j].pastOverlaps,
                  earliestEndAt.leftOffset,

                  formattedEvents[i][j].futureOverlaps
                    ? // This might need to be checking against the latest-ending shift of the previous
                      // overlap group instead of the immediate previous one.
                      isBefore(
                        formattedEvents[i][j - 1].endAt,
                        formattedEvents[i][j + 1].startAt,
                      ) ||
                      isEqual(
                        formattedEvents[i][j - 1].endAt,
                        formattedEvents[i][j + 1].startAt,
                      )
                      ? 100 / (formattedEvents[i][j].futureOverlaps + 1)
                      : 100 /
                        (formattedEvents[i][j].pastOverlaps +
                          formattedEvents[i][j].futureOverlaps +
                          1)
                    : 100,
                );
              } else {
                // Shift does not have pastOverlaps

                // shifts[i][j].width = 100 / (futureOverlapsThatOverlapCount + 1);
                formattedEvents[i][j].width =
                  100 / (formattedEvents[i][j].futureOverlaps + 1);
              }
            } else {
              // Width is always the same as any shift in same overlap group
              formattedEvents[i][j].width = formattedEvents[i][j - 1].width;
            }

            // Calculate leftOffsets
            if (!formattedEvents[i][j].startOfOverlap) {
              formattedEvents[i][j].leftOffset =
                formattedEvents[i][j - 1].width +
                formattedEvents[i][j - 1].leftOffset;
            }
          }
        }
      }
      return formattedEvents;
    }, [events, calcTime, theme, range, from]);

  // console.log();
  // console.log('formattedEvents', formattedEvents);
  // console.log();

  return (
    <CalendarContext.Provider
      value={{
        interactive,
        today,
        now,
        from,
        setFrom,
        range,
        setRange,
        timezone,
        setTimezone,
        events: formattedEvents,
        calcTime,
        interactWithEntity,
        entityInteractions,
        weekStartsOn,
        scrollableAreaRef,
        cellClickModal,
        cellClickModalProps,
        eventClickModal,
        eventClickModalProps,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};

export default CalendarProvider;

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { useAuth } from '@timed/auth';
import { EventCard } from '@timed/event/components/Card';
import {
  GetMyShiftClaimsDocument,
  useClaimEventMutation,
  useDismissEventClaimMutation,
  useGetClaimableShiftsLazyQuery,
  useGetMyShiftClaimsLazyQuery,
} from '@timed/gql';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useReducer } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      cursor: 'pointer',
    },
    cardSelected: {
      '& div:first-child': {
        backgroundColor: green[300],
      },
      '& div:last-child': {
        backgroundColor: green[100],
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 24 24'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%2381c784' fill-opacity='1'%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        [theme.breakpoints.down('md')]: {
          backgroundPosition: 'right center',
        },
      },
    },
  }),
);

const ShiftsGrabList = () => {
  const classes = useStyles();

  const { branch } = useAuth();

  const [getEvents, eventsResponse] = useGetClaimableShiftsLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [getClaims, claimsResponse] = useGetMyShiftClaimsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) =>
      data.myEventClaims.length &&
      setClaimedEventIds({
        type: 'add',
        ids: data.myEventClaims.map(({ event }) => event.id),
      }),
  });

  const [claimEvent] = useClaimEventMutation({
    onCompleted: (data) => {
      const cache = claimsResponse.client.cache;

      cache.modify({
        fields: {
          myEventClaims: (existing = []) => [
            ...existing,
            cache.writeQuery({
              data: { myEventClaims: [data.claimEvent] },
              query: GetMyShiftClaimsDocument,
            }),
          ],
        },
      });

      return setClaimedEventIds({
        type: 'add',
        ids: [data.claimEvent.event.id],
      });
    },
  });
  const [dissmissEventClaim] = useDismissEventClaimMutation({
    onCompleted: (data) =>
      setClaimedEventIds({
        type: 'remove',
        ids: [data.dismissEventClaim.event.id],
      }),
  });

  const loading = useMemo(
    () => eventsResponse.loading || claimsResponse.loading,
    [eventsResponse.loading, claimsResponse.loading],
  );

  const claimedEventIdsReducer = (
    existingIds: string[],
    action: { type: 'add' | 'remove'; ids: string[] },
  ): string[] => {
    const { type, ids } = action;

    switch (type) {
      case 'add':
        return [...new Set([...existingIds, ...ids])];

      case 'remove':
        return existingIds.filter(
          (existingId) => !ids.map((id) => id).includes(existingId),
        );
    }
  };

  const [claimedEventIds, setClaimedEventIds] = useReducer(
    claimedEventIdsReducer,
    [],
  );

  const handleClickEventCard = useCallback(
    (id: string) => {
      if (!claimedEventIds.includes(id)) claimEvent({ variables: { id } });
      else {
        const eventClaimId = claimsResponse.data?.myEventClaims.find(
          ({ event }) => event.id === id,
        )?.id;

        if (eventClaimId)
          dissmissEventClaim({ variables: { id: eventClaimId } });
      }
    },
    [
      claimedEventIds,
      claimsResponse.data?.myEventClaims,
      claimEvent,
      dissmissEventClaim,
    ],
  );

  // Fetch events and existing claims
  useEffect(() => {
    getEvents(
      branch
        ? {
            variables: {
              input: {
                where: {
                  client: { branch: { id: { _eq: branch.id } } },
                },
              },
            },
          }
        : undefined,
    );
    getClaims({
      variables: {
        input: {
          where: {
            event: branch
              ? { client: { branch: { id: { _eq: branch.id } } } }
              : undefined,
          },
        },
      },
    });
  }, [getEvents, getClaims, branch]);

  return (
    <>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : !!eventsResponse.data?.eventsClaimable.length ? (
        eventsResponse.data?.eventsClaimable.map((event, i) => (
          <EventCard
            key={i}
            event={event}
            className={
              claimedEventIds.includes(event.id)
                ? clsx(classes.card, classes.cardSelected)
                : classes.card
            }
            onClick={() => {
              handleClickEventCard(event.id);
            }}
          />
        ))
      ) : (
        <Typography>
          There are currently no shifts to grab. Check back later.
        </Typography>
      )}
    </>
  );
};

export default ShiftsGrabList;

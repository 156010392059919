import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  addServerErrors,
  DateInput,
  FormModal,
  ModalProps,
  NumberInput,
  transformNumberToFixedFloat,
} from '@timed/common';
import {
  QueryByIdInput,
  UpdateMemberBasePayRateInput,
  useGetMemberBasePayRateByIdQuery,
  useUpdateMemberBasePayRateMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { MemberBasePayRateTypeInput } from '@timed/member/components/BasePayRateTypeInput';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type MemberUpdateBasePayRateFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  basePayRate: QueryByIdInput;
};

type FormData = Omit<UpdateMemberBasePayRateInput, 'id'>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputs: {
      flex: '1 0 auto',
      overflowY: 'auto',
      display: 'grid',
      gridAutoFlow: 'row',
      gridAutoRows: 'max-content',
      gap: theme.spacing(4),
    },
  }),
);

const MemberUpdateBasePayRateFormModal = ({
  basePayRate,
  onClose,
  ...modalProps
}: MemberUpdateBasePayRateFormModalProps) => {
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { errors },
  } = useForm<FormData>();

  const { loading } = useGetMemberBasePayRateByIdQuery({
    variables: { id: basePayRate.id },
    onCompleted: ({ memberBasePayRateById: { date, value, type } }) => {
      setValue('patch.date', date);
      setValue('patch.value', value / 100);
      setValue('patch.type', type);
    },
  });

  const [updateEntity, response] = useUpdateMemberBasePayRateMutation();

  useLoadingEffect(loading || response.loading);

  const onSubmit = ({ patch }: FormData) => {
    if (patch.value !== undefined && patch.value !== null)
      patch.value = Math.round(patch.value * 100);

    updateEntity({
      variables: {
        input: {
          id: basePayRate.id,
          patch,
        },
      },
    }).catch((e) => {});
  };

  /**
   * Display error messages from server response
   */
  useEffect(() => {
    if (response.error) addServerErrors(response.error, setError);
  }, [response.error, setError]);

  return (
    <FormModal
      modalProps={modalProps}
      title="Update Pay Rate"
      saveText="Save"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
    >
      <Box className={classes.inputs}>
        {response.error && (
          <Typography color="error">{response.error.message}</Typography>
        )}
        <DateInput
          required
          keyboard
          disableTime
          label="Effective-from date"
          name="patch.date"
          control={control}
          inputVariant="outlined"
          size="small"
          error={!!errors.patch?.date}
          helperText={errors.patch?.date?.message}
          disabled={loading}
        />
        <NumberInput
          required
          name="patch.value"
          variant="outlined"
          size="small"
          label="Value"
          control={control}
          error={!!errors.patch?.value}
          helperText={errors.patch?.value?.message}
          disabled={loading}
          transform={transformNumberToFixedFloat(2, 0, 10000000)}
          step="0.01"
          min={0}
          max={10000000} // 10 million
        />
        <MemberBasePayRateTypeInput
          required
          name="patch.type"
          control={control}
          error={!!errors.patch?.type}
          disabled={loading}
        />
      </Box>
    </FormModal>
  );
};

export default MemberUpdateBasePayRateFormModal;

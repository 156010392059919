import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { _people, _peopleClients, _peopleMembers } from '@timed/app';
import { NavLinks } from '@timed/common';
import { Page } from '@timed/page';
import { Outlet } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
    },
  }),
);

const RouteSupportCoordinationPeople = () => {
  const classes = useStyles();

  return (
    <Page {..._people}>
      <Box className={classes.wrapper}>
        <NavLinks
          links={[
            _peopleMembers,
            _peopleClients,
            // _peopleSupportCoordinators,
            // _peoplePlanManagers,
          ]}
        />
        <Outlet />
      </Box>
    </Page>
  );
};

export default RouteSupportCoordinationPeople;

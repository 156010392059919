import {
  addMinutes,
  differenceInMilliseconds,
  differenceInSeconds,
  format,
  startOfMinute,
  startOfSecond,
} from 'date-fns';
import { useEffect, useRef, useState } from 'react';

type ClockProps = {
  /**
   * Format the string in 24-hour format
   */
  militaryTime?: boolean;

  /**
   * Show seconds.
   */
  seconds?: boolean;

  /**
   * String format override
   */
  timeFormat?: string;

  /**
   * Date format string.
   */
  dateFormat?: string;

  /**
   * Date format string.
   */
  dateFirst?: boolean;

  /**
   * startTime.
   */
  startTime?: Date;
};

const Clock = ({
  militaryTime,
  seconds = false,
  dateFirst = false,
  timeFormat,
  dateFormat,
  startTime = new Date(),
}: ClockProps) => {
  const timeOffset = differenceInSeconds(startTime, new Date());

  const [now, setNow] = useState<Date>(startTime);

  const timer = useRef<number>();

  const secondsFormat = seconds ? ':ss' : '';

  timeFormat ??= militaryTime
    ? 'HH:mm' + secondsFormat
    : 'h:mm' + secondsFormat + ' a';

  dateFormat ??= dateFirst ? 'dd/MM/yyyy' : undefined;

  useEffect(() => {
    // If seconds is displayed, refresh every second, else refresh every minute
    const refreshTime = seconds ? 1000 : 60000;

    // Calc the difference in time between the component render time and the start of the current
    // second/minute so that the timer refreshes on the second/minute mark.
    const offset = differenceInMilliseconds(
      now,
      seconds ? startOfSecond(now) : startOfMinute(now),
    );

    timer.current = window.setTimeout(() => {
      setNow(addMinutes(new Date(), timeOffset / 60));
    }, refreshTime - offset);

    return () => clearTimeout(timer.current);
  }, [now, setNow, seconds, timeOffset]);

  const formatString =
    dateFirst && !!dateFormat
      ? (dateFormat ? dateFormat + ' ' : '') + timeFormat
      : timeFormat + (dateFormat ? ' ' + dateFormat : '');

  return <>{format(now, formatString)}</>;
};

export default Clock;

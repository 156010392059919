import { TextField, TextFieldProps } from '@timed/common';
import { uuidRegex } from '@timed/common/regexps';
import { RegisterOptions } from 'react-hook-form';

export const validateUuid: RegisterOptions = {
  pattern: { value: uuidRegex, message: 'Invalid token' },
};

const UuidInput = (props: TextFieldProps) => (
  <TextField label="Token" type="text" validation={validateUuid} {...props} />
);

export default UuidInput;

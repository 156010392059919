import { roundNumber } from '@timed/common';
import { GetActivitySlipsQuery, GetPublicHolidaysQuery } from '@timed/gql';
import { ActivitySlip, calculatePayrollActivityId } from '@timed/report';
import { Activity } from '@timed/report/constants';
import { calculatePayrollCategory } from '@timed/report/helpers/calculatePayrollCategory';
import { formatEventAsShifts } from '@timed/report/helpers/formatEventAsShifts';
import { differenceInMinutes, format, isAfter, startOfDay } from 'date-fns';
import { isEqual } from 'lodash';

type GenerateFn = {
  after: Date;
  before: Date;
  events: GetActivitySlipsQuery['events'];
  publicHolidays: GetPublicHolidaysQuery['publicHolidays'];
  capitaliseLastNames?: boolean;
};

export const generateActivitySlips = ({
  events,
  publicHolidays,
  after,
  before,
  capitaliseLastNames = true,
}: GenerateFn) => {
  const payrollEvents = formatEventAsShifts({
    events,
    publicHolidays,
    include: { after, before },
  });

  const activitySlips: ActivitySlip[] = [];

  payrollEvents.forEach((event) => {
    const memberExternalId = event.original.member!.externalId;
    const memberLastName = event.original.member!.lastName
      ? capitaliseLastNames
        ? event.original.member!.lastName.toUpperCase()
        : event.original.member!.lastName
      : '';
    const memberFirstName =
      event.original.member!.firstName +
      (!!event.original.member?.middleName
        ? ' ' + event.original.member.middleName
        : '');
    const ndisId = event.original.client.ndisId
      ? event.original.client.ndisId
      : '';
    const planManagerName = event.original.client.planManager?.name
      ? event.original.client.planManager?.name
      : '';

    event.shifts.forEach((shift, index) => {
      activitySlips.push({
        date: format(startOfDay(shift.startAt), 'dd/MM/yyyy'),
        memberExternalId,
        memberLastName,
        memberFirstName,
        planManagerName,
        ndisId,
        activityId: calculatePayrollActivityId({
          type: 'support',
          billingRegistrationGroup: event.original.billingRegistrationGroup,
          billable: event.original.billable,
          eventStart: event.original.startAt,
          eventEnd: event.original.endAt,
          shiftStart: shift.startAt,
          shiftEnd: shift.endAt,
          passive: !!shift.passive,
          publicHoliday: event.original.publicHoliday,
          cancelled: !!event.original.cancelled,
        }),
        payrollCategory: calculatePayrollCategory({
          eventStart: event.original.startAt,
          eventEnd: event.original.endAt,
          shiftStart: shift.startAt,
          shiftEnd: shift.endAt,
          passive: !!shift.passive,
          occursDuringPublicHoliday: event.original.publicHoliday,
          memberBonusEligible: event.original.member!.bonusEligible,
        }),
        units: shift.passive
          ? '1.00'
          : (differenceInMinutes(shift.endAt, shift.startAt) / 60).toFixed(2),
        notes:
          format(shift.startAt, 'eee dd/MM HH:mm-') +
          format(shift.endAt, 'HH:mm') +
          (event.original.cancelled ? ' (cancelled)' : ''),
      });

      // Add bonus pay to the first shift only
      if (
        index === 0 &&
        !!event.original.bonusPay &&
        event.original.bonusPay > 0
      )
        activitySlips.push({
          date: format(startOfDay(shift.startAt), 'dd/MM/yyyy'),
          memberExternalId,
          memberLastName,
          memberFirstName,
          planManagerName: 'TBA',
          ndisId,
          activityId: Activity.BONUS_1,
          payrollCategory: 'Bonus PC #1',
          units: roundNumber(event.original.bonusPay / 100, 2).toFixed(2),
          notes: 'Bonus',
        });
    });

    // Generate additional activity slips for event. Only computed if the the event starts within the
    // specified payroll period. If not, these activity slips belongs to the previous payroll period.
    if (
      (!!event.original.travelDistance || !!event.original.travelTime) &&
      (isAfter(event.original.startAt, after) ||
        isEqual(event.original.startAt, after))
    ) {
      const date = format(startOfDay(event.original.startAt), 'dd/MM/yyyy');

      // Kilometer allowance.
      if (!!event.original.travelDistance)
        activitySlips.push({
          date,
          memberExternalId,
          memberLastName,
          memberFirstName,
          planManagerName,
          ndisId,
          activityId: Activity.KM,
          payrollCategory: 'Km allowance',
          units: (event.original.travelDistance / 1000).toFixed(2),
          notes: event.original.travelDistance / 1000 + ' kilometers',
        });

      // Travel time allowance.
      if (!!event.original.travelTime)
        activitySlips.push({
          date,
          memberExternalId,
          memberLastName,
          memberFirstName,
          planManagerName,
          ndisId,
          activityId: calculatePayrollActivityId({
            type: 'travelTime',
            billingRegistrationGroup: event.original.billingRegistrationGroup,
            billable: event.original.billable,
            eventStart: event.original.startAt,
            eventEnd: event.original.endAt,
            shiftStart: event.original.startAt,
            shiftEnd: event.original.endAt,
            publicHoliday: event.original.publicHoliday,
          }),
          payrollCategory: calculatePayrollCategory({
            eventStart: event.original.startAt,
            eventEnd: event.original.endAt,
            shiftStart: event.original.startAt,
            shiftEnd: event.original.endAt,
            occursDuringPublicHoliday: event.original.publicHoliday,
            memberBonusEligible: event.original.member!.bonusEligible,
          }),
          units: (event.original.travelTime / 60).toFixed(2),
          notes: event.original.travelTime + ' minutes',
        });
    }
  });

  // Sort shifts by support worker name
  activitySlips.sort((a, b) =>
    a.memberLastName.localeCompare(b.memberLastName),
  );

  return activitySlips;
};

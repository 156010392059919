import { EventBillingRegistrationGroup } from '@timed/gql';
import { RegisterOptions } from 'react-hook-form';

export const validateBillingRegistrationGroup: RegisterOptions = {
  validate: {
    validBillingRegistrationGroup: (value) =>
      !value ||
      (Array.isArray(value)
        ? value.every((v: any) => v in EventBillingRegistrationGroup)
        : value in EventBillingRegistrationGroup) ||
      'Invalid registration group',
  },
};

import { _ndisCoordinationPeopleClients } from '@timed/app';
import { ClientList, ClientListProvider } from '@timed/client';
import { Page } from '@timed/page';

const RouteSupportCoordinationPeopleParticipants = () => {
  return (
    <Page {..._ndisCoordinationPeopleClients}>
      <ClientListProvider>
        {/* <NdisCoordinationClientList /> */}
        <ClientList />
      </ClientListProvider>
    </Page>
  );
};

export default RouteSupportCoordinationPeopleParticipants;

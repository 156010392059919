import { Grid, Typography } from '@material-ui/core';
import { Protected } from '@timed/auth';
import {
  AbnInput,
  addServerErrors,
  Block,
  formatAbn,
  IconButtonMulti,
  TextField,
} from '@timed/common';
import {
  HistoryRestorable,
  Member,
  Permission,
  SetMemberSuperInput,
  useUpdateMembersSuperDetailsMutation,
} from '@timed/gql';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type MemberUpdateSuperDetailsFormProps = {
  member: Pick<Member, 'id' | 'super'> & {
    archive?: Pick<HistoryRestorable, 'id'> | null;
  };
};

type FormData = {
  patch: {
    super?: SetMemberSuperInput | null;
  };
};

const MemberUpdateSuperDetailsForm = ({
  member,
}: MemberUpdateSuperDetailsFormProps) => {
  const [editing, setEditing] = useState<boolean>(false);

  const [updateMember, response] = useUpdateMembersSuperDetailsMutation();

  const defaultValues: FormData = {
    patch: {
      super: {
        ...member.super,
        fundAbn: !!member.super?.fundAbn
          ? formatAbn(member.super.fundAbn)
          : undefined,
      },
    },
  };

  const {
    handleSubmit,
    watch,
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });

  const currentValues = watch();

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = ({ patch }: FormData) => {
    updateMember({
      variables: {
        input: {
          ids: [member.id],
          patch,
        },
      },
    }).catch((e) => {});

    reset({ patch });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block
        title="Superannuation Details"
        topRight={
          !member.archive ? (
            <Protected permissions={Permission.MEMBER_WRITE}>
              <IconButtonMulti
                enabled={editing}
                changed={!isEqual(currentValues, defaultValues)}
                setEditing={setEditing}
                loading={response.loading}
                success={!!response.data}
              />
            </Protected>
          ) : undefined
        }
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={4} md={3} lg={2}>
            <Typography>USI</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.super?.usi
              ) : (
                <TextField
                  fullWidth
                  name="patch.super.usi"
                  variant="outlined"
                  size="small"
                  label="USI"
                  control={control}
                  error={!!errors.patch?.super?.usi}
                  helperText={errors.patch?.super?.usi?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Fund Name</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.super?.fundName
              ) : (
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Fund Name"
                  name="patch.super.fundName"
                  control={control}
                  error={!!errors.patch?.super?.fundName}
                  helperText={errors.patch?.super?.fundName?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Fund ABN</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                formatAbn(member.super?.fundAbn)
              ) : (
                <AbnInput
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Fund ABN"
                  name="patch.super.fundAbn"
                  control={control}
                  error={!!errors.patch?.super?.fundAbn}
                  helperText={errors.patch?.super?.fundAbn?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Account Name</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.super?.accountName
              ) : (
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Account Name"
                  name="patch.super.accountName"
                  control={control}
                  error={!!errors.patch?.super?.accountName}
                  helperText={errors.patch?.super?.accountName?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Account Number</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.super?.accountNumber
              ) : (
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Account Number"
                  name="patch.super.accountNumber"
                  control={control}
                  error={!!errors.patch?.super?.accountNumber}
                  helperText={errors.patch?.super?.accountNumber?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
      </Block>
    </form>
  );
};

export default MemberUpdateSuperDetailsForm;

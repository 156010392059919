import { useAuth } from '@timed/auth';
import { ClientContext } from '@timed/client';
import {
  EntityState,
  OrderBy,
  useNotesContextGetAggregatedClientNotesLazyQuery,
  useNotesContextGetClientNoteKeywordsQuery,
  useNotesContextGetFilesLazyQuery,
} from '@timed/gql';
import {
  NotesContext,
  NotesContextInput,
  NotesContextNoteTypeInput,
  NotesContextSelectNotesAction,
  NotesContextType,
} from '@timed/notes';
import { startOfToday } from 'date-fns';
import { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { Range } from 'react-date-range';

const ClientNoteListProvider: React.FC = ({ children }) => {
  const storagePrefix = 'client.notes.filters';

  const { permissible } = useAuth();

  const [getNotes, { data, loading, fetchMore, refetch, called }] =
    useNotesContextGetAggregatedClientNotesLazyQuery({
      notifyOnNetworkStatusChange: true,
    });

  const [getFiles, filesResponse] = useNotesContextGetFilesLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  const keywordsResponse = useNotesContextGetClientNoteKeywordsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        orderBy: [{ text: OrderBy.ASC }],
      },
    },
  });

  const defaultOrder = [
    { commentedAt: OrderBy.DESC },
    { commentedBy: { lastName: OrderBy.ASC } },
    { commentedBy: { firstName: OrderBy.ASC } },
    { commentedByText: OrderBy.ASC },
    { comment: OrderBy.DESC },
  ];

  /**
   * Date range to filter by.
   */
  const [dateRange, setDateRange] = useState<Range>(
    // JSON.parse(sessionStorage.getItem(storagePrefix + '.dateRange') ?? '') ?? {
    //   startDate: startOfToday(),
    // },
    { startDate: startOfToday() },
  );

  /**
   * Client ID to filter by.
   */
  const { id: client } = useContext(ClientContext);

  /**
   * Commenter ID to filter by.
   */
  const [commentedBy, setCommentedBy] = useState<string | null>(
    sessionStorage.getItem(storagePrefix + '.commenter'),
  );

  /**
   * Creator ID to filter by.
   */
  const [createdBy, setCreatedBy] = useState<string | null>(
    sessionStorage.getItem(storagePrefix + '.creator'),
  );

  /**
   * Search to filter by.
   */
  const [search, setSearch] = useState<string | null>(
    sessionStorage.getItem(storagePrefix + '.search'),
  );

  /**
   * Note type to filter by.
   */
  const [type, setType] = useState<NotesContextNoteTypeInput>(
    (sessionStorage.getItem(
      storagePrefix + '.type',
    ) as NotesContextNoteTypeInput) || 'all',
  );

  /**
   * Keyword-matching-state to filter by.
   */
  const [matchesKeywords, setMatchesKeywords] = useState<boolean>(
    sessionStorage.getItem(storagePrefix + '.matchesKeywords') === 'true',
  );

  /**
   * Entity states to filter by.
   */
  const [entityStates, setEntityStates] = useState<EntityState[]>([
    (sessionStorage.getItem(storagePrefix + '.state') as EntityState) ||
      EntityState.NORMAL,
  ]);

  /**
   * Ordering options.
   */
  const [orderBy, setOrderBy] =
    useState<NotesContextInput['orderBy']>(defaultOrder);

  /**
   * Query offset.
   */
  const [offset, setOffset] = useState<number>(0);

  /**
   * Possible pagination limits.
   */
  const limits = [50, 100, 200];

  /**
   * Query offset.
   */
  const [limit, setLimit] = useState<number>(
    parseInt(sessionStorage.getItem(storagePrefix + '.limit') ?? '') ||
      limits[0],
  );

  /**
   * Importer/Creator visibility state.
   */
  const [showImporter, setShowImporter] = useState<boolean>(
    sessionStorage.getItem(storagePrefix + '.showImporter') === 'true',
  );

  /**
   * Highlight keywords in comment state.
   */
  const [highlightKeywords, setHighlightKeywords] = useState<boolean>(
    sessionStorage.getItem(storagePrefix + '.highlightKeywords') === 'true',
  );

  const selectNotesReducer = (
    existingIds: string[],
    action: NotesContextSelectNotesAction,
  ): string[] => {
    switch (action.type) {
      case 'merge':
        return [...new Set([...existingIds, ...action.ids])];
      case 'remove':
        return existingIds.filter((id) => !action.ids.includes(id));
      case 'invert':
      default:
        return [
          ...existingIds.filter((id) => !action.ids.includes(id)),
          ...action.ids.filter((id) => !existingIds.includes(id)),
        ];
    }
  };

  /**
   * Highlight keywords in comment state.
   */
  const [selectedNotes, selectNotes] = useReducer(selectNotesReducer, []);

  /**
   * Reset all filters back to their defaults.
   */
  const reset = () => {
    setCommentedBy('');
    setCreatedBy('');
    setSearch('');
    setType('all');
    setMatchesKeywords(false);
    setEntityStates([EntityState.NORMAL]);
    setOffset(0);
  };

  const where = useMemo(
    () => ({
      client: { id: { _eq: client } },
      commentedBy: commentedBy ? { id: { _eq: commentedBy } } : undefined,
      createdBy: createdBy ? { id: { _eq: createdBy } } : undefined,
      searchableComment: search ? { _fulltext: search } : undefined,
      event:
        type === 'shift'
          ? { id: { _ne: null } }
          : type === 'other'
          ? { id: { _eq: null } }
          : undefined,
      keywordMatches: matchesKeywords ? { id: { _ne: null } } : undefined,
    }),

    [client, commentedBy, createdBy, search, type, matchesKeywords],
  );

  /**
   * Save date range to storage.
   */
  useEffect(() => {
    if (dateRange !== sessionStorage.getItem(storagePrefix + '.dateRange'))
      sessionStorage.setItem(
        storagePrefix + '.dateRange',
        JSON.stringify(dateRange) ?? '',
      );
  }, [dateRange]);

  /**
   * Save commenter to storage.
   */
  useEffect(() => {
    if (commentedBy !== sessionStorage.getItem(storagePrefix + '.commenter'))
      sessionStorage.setItem(storagePrefix + '.commenter', commentedBy ?? '');
  }, [commentedBy]);

  /**
   * Save creator to storage.
   */
  useEffect(() => {
    if (createdBy !== sessionStorage.getItem(storagePrefix + '.creator'))
      sessionStorage.setItem(storagePrefix + '.creator', createdBy ?? '');
  }, [createdBy]);

  /**
   * Save search to storage.
   */
  useEffect(() => {
    if (search !== sessionStorage.getItem(storagePrefix + '.search'))
      sessionStorage.setItem(storagePrefix + '.search', search ?? '');
  }, [search]);

  /**
   * Save type to storage.
   */
  useEffect(() => {
    if (type !== sessionStorage.getItem(storagePrefix + '.type'))
      sessionStorage.setItem(storagePrefix + '.type', type ?? '');
  }, [type]);

  /**
   * Save matchesKeywords to storage.
   */
  useEffect(() => {
    if (
      matchesKeywords.toString() !==
      sessionStorage.getItem(storagePrefix + '.matchesKeywords')
    )
      sessionStorage.setItem(
        storagePrefix + '.matchesKeywords',
        matchesKeywords.toString(),
      );
  }, [matchesKeywords]);

  /**
   * Save entityState to storage.
   */
  useEffect(() => {
    if (entityStates[0] !== sessionStorage.getItem(storagePrefix + '.state'))
      sessionStorage.setItem(storagePrefix + '.state', entityStates[0] ?? '');
  }, [entityStates]);

  /**
   * Save limit to storage.
   */
  useEffect(() => {
    if (limit.toString() !== sessionStorage.getItem(storagePrefix + '.limit'))
      sessionStorage.setItem(storagePrefix + '.limit', limit.toString() ?? '');
  }, [limit]);

  /**
   * Save showImporter.
   */
  useEffect(() => {
    if (
      showImporter.toString() !==
      sessionStorage.getItem(storagePrefix + '.showImporter')
    )
      sessionStorage.setItem(
        storagePrefix + '.showImporter',
        showImporter.toString(),
      );
  }, [showImporter]);

  /**
   * Save highlightKeywords.
   */
  useEffect(() => {
    if (
      highlightKeywords.toString() !==
      sessionStorage.getItem(storagePrefix + '.highlightKeywords')
    )
      sessionStorage.setItem(
        storagePrefix + '.highlightKeywords',
        highlightKeywords.toString(),
      );
  }, [highlightKeywords]);

  /**
   * Fetch subsequent data.
   */
  const fetch: NotesContextType['fetch'] = ({ offset } = {}) => {
    const input = {
      orderBy,
      limit,
      offset: offset ?? 0,
      where,
      entityStates:
        // Ignore entityStates if the user is not an admin
        permissible({ admin: true }) && entityStates && !!entityStates.length
          ? entityStates
          : undefined,
    };

    setOffset(offset ?? 0);

    if (offset !== undefined) {
      fetchMore({ variables: { input } });
    } else {
      refetch({ input });
    }
  };

  /**
   * Fetch initial data.
   */
  useEffect(() => {
    if (!called)
      getNotes({
        variables: {
          input: {
            orderBy,
            limit,
            offset,
            where,
            entityStates:
              // Ignore entityStates if the user is not an admin
              permissible({ admin: true }) &&
              entityStates &&
              !!entityStates.length
                ? entityStates
                : undefined,
          },
        },
      });
  });

  /**
   * Refetch data when needed.
   */
  useEffect(() => {
    if (called) {
      setOffset(0);
      refetch({
        input: {
          orderBy,
          limit,
          offset: 0,
          where,
          entityStates:
            // Ignore entityStates if the user is not an admin
            permissible({ admin: true }) &&
            entityStates &&
            !!entityStates.length
              ? entityStates
              : undefined,
        },
      });
    }
  }, [
    called,
    setOffset,
    where,
    entityStates,
    limit,
    orderBy,
    permissible,
    refetch,
  ]);

  /**
   * Fetch files.
   */
  useEffect(() => {
    if (data?.clientNotesAggregate.nodes.length)
      getFiles({
        variables: {
          clientNoteFilesInput: {
            where: {
              owner: {
                id: {
                  _in: data.clientNotesAggregate.nodes.map(({ id }) => id),
                },
              },
            },
            orderBy: [{ file: { createdAt: OrderBy.ASC } }],
          },
          eventFilesInput: {
            where: {
              owner: {
                clientNotes: {
                  id: {
                    _in: data.clientNotesAggregate.nodes.map(({ id }) => id),
                  },
                },
              },
            },
            orderBy: [{ file: { createdAt: OrderBy.ASC } }],
          },
        },
      });
  }, [getFiles, data?.clientNotesAggregate.nodes]);

  return (
    <NotesContext.Provider
      value={{
        fetch,
        reset,
        limit,
        limits,
        offset,
        setLimit,
        setOffset,
        totalCount: data?.clientNotesAggregate.aggregate.totalCount,
        nodes: data?.clientNotesAggregate.nodes,
        noteFiles: filesResponse.data?.clientNoteFiles,
        eventFiles: filesResponse.data?.eventFiles,
        keywords: keywordsResponse.data?.clientNoteKeywords,
        loading,
        selectNotes,
        selectedNotes,
        input: {
          dateRange,
          setDateRange,
          client,
          setClient: () => {},
          commentedBy,
          setCommentedBy,
          createdBy,
          setCreatedBy,
          search,
          setSearch,
          type,
          setType,
          matchesKeywords,
          setMatchesKeywords,
          entityStates,
          setEntityStates,
          orderBy,
          setOrderBy,
          defaultOrder,
          showImporter,
          setShowImporter,
          highlightKeywords,
          setHighlightKeywords,
        },
      }}
    >
      {children}
    </NotesContext.Provider>
  );
};

export default ClientNoteListProvider;

import { Select, SelectProps } from '@timed/common';
import { Gender } from '@timed/gql';
import { camelCase, startCase } from 'lodash';
import { RegisterOptions } from 'react-hook-form';

export const validateGender: RegisterOptions = {
  validate: {
    validGender: (value) => value in Gender || 'Invalid value',
  },
};

const GenderInput = ({
  defaultValue,
  ...props
}: Omit<SelectProps, 'items'>) => (
  <Select
    label="Gender"
    defaultValue={defaultValue || Gender.NOTSPECIFIED}
    validation={validateGender}
    items={Object.values(Gender).map((gender) => {
      return {
        value: gender,
        label:
          gender === Gender.NOTSPECIFIED
            ? 'Not specified'
            : startCase(camelCase(gender)),
      };
    })}
    renderValue={(value) =>
      value === Gender.NOTSPECIFIED
        ? 'Not specified'
        : startCase(camelCase(value as string))
    }
    {...props}
  />
);

export default GenderInput;

import { Typography } from '@material-ui/core';
import {
  Autocomplete,
  AutocompleteOption,
  AutocompleteProps,
  TextField,
} from '@timed/common';
import {
  ClientNdisPlanItemName,
  ClientNdisPlanItemNameOrderByInput,
  ClientNdisPlanItemNameWhereInput,
  useGetClientNdisPlanItemNamesQuery,
} from '@timed/gql';
import { Dispatch, SetStateAction, useMemo } from 'react';

type MemberAutocompleteProps = {
  autocompleteProps?: Partial<
    AutocompleteProps<Pick<ClientNdisPlanItemName, 'id' | 'name'>>
  >;
  name?: AutocompleteOption<Pick<ClientNdisPlanItemName, 'id' | 'name'>> | null;
  setName: Dispatch<
    SetStateAction<AutocompleteOption<
      Pick<ClientNdisPlanItemName, 'id' | 'name'>
    > | null>
  >;
  where?: ClientNdisPlanItemNameWhereInput;
  orderBy?: ClientNdisPlanItemNameOrderByInput[];
};

const ClientNdisPlanItemNameAutocomplete = ({
  autocompleteProps,
  name,
  setName,
  ...input
}: MemberAutocompleteProps) => {
  const { data } = useGetClientNdisPlanItemNamesQuery({
    variables: { input },
  });

  const options = useMemo(() => {
    return data?.clientNdisPlanItemNames
      ? data.clientNdisPlanItemNames.map((name) => ({
          label: name.name,
          value: name,
        }))
      : [];
  }, [data?.clientNdisPlanItemNames]);

  return (
    <Autocomplete
      {...autocompleteProps}
      name="name-autocomplete"
      label="Name"
      options={options}
      value={name}
      setValue={setName}
      renderOption={({ label }) => <Typography>{label}</Typography>}
      renderInput={(params) => (
        <TextField {...params} label="Name" variant="outlined" />
      )}
    />
  );
};

export default ClientNdisPlanItemNameAutocomplete;

/**
 * Round a given interger or float to a set number of decimal places
 * @param num The number to round
 * @param precision The number of decimal places
 * @returns Rounded number
 */
export const roundNumber = (num: number, precision: number): number =>
  Number(
    Math.round(parseFloat(num.toString() + 'e+' + precision.toString())) +
      (precision > 0 ? 'e-' + precision : ''),
  );

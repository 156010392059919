import { createStyles, makeStyles, Theme } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { IconButton } from '@timed/common';
import { ScheduleContext } from '@timed/schedule/context';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      backgroundColor: theme.palette.background.default,
      border: '1px solid ' + theme.palette.divider,
      borderRadius: 0,
      [theme.breakpoints.down('sm')]: {
        width: theme.spacing(8),
      },
    },
  }),
);

const ScheduleMemberInfoListVisibilityButton = () => {
  const classes = useStyles();

  const { showMemberHours, setShowMemberHours } = useContext(ScheduleContext);

  return (
    <IconButton
      className={classes.button}
      size="small"
      aria-label="back"
      onClick={() => setShowMemberHours(!showMemberHours)}
    >
      {showMemberHours ? <ChevronLeftIcon /> : <ChevronRightIcon />}
    </IconButton>
  );
};

export default ScheduleMemberInfoListVisibilityButton;

import {
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import { useAuth } from '@timed/auth';
import { BranchSelectInput } from '@timed/branch';
import { ClientExternalIdInput } from '@timed/client';
import {
  Button,
  CsvImportContext,
  FileInput,
  MIME,
  PersonNameFirstInput,
  PersonNameLastInput,
  PersonNamePreferredInput,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from '@timed/common';
import { pluralise } from '@timed/common/utils/pluralise';
import {
  ModuleType,
  QueryByIdInput,
  useCreateClientsMutation,
  useValidateClientExternalIdsLazyQuery,
} from '@timed/gql';
import _ from 'lodash';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';

type EntityObject = {
  firstName: string;
  lastName: string;
  preferredName: string;
  externalId: string;
};

type FormData = {
  branch: QueryByIdInput;
  objects: EntityObject[];
};

enum Column {
  firstName = 0,
  lastName = 1,
  preferredName = 2,
  externalId = 3,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(4),
      '& .MuiFormControl-root': {
        flex: '1 1 auto',
      },
    },
    input: {
      width: 'max-content',
      minWidth: 300,
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
    },
    inputs: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    inputGroup: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
    },
    controls: {
      display: 'flex',
      gap: theme.spacing(2),
    },
    button: {
      minWidth: 0,
      height: theme.spacing(10),
      border: '1px solid ' + theme.palette.divider,
      borderRadius: 0,
      [theme.breakpoints.down('sm')]: {
        height: theme.spacing(8),
      },
      '& .MuiButton-label': {
        fontWeight: 'normal',
      },
    },
    processButton: {
      backgroundColor: theme.palette.background.default,
    },
    control: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    textFieldError: {
      '& .MuiInputBase-root': {
        color: theme.palette.error.dark,
      },
    },
  }),
);

const ClientImportForm = () => {
  const classes = useStyles();

  const theme = useTheme();

  const auth = useAuth();

  const { rows, parse, form } = useContext(CsvImportContext);

  const {
    control,
    setValue,
    setError,
    watch,
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<FormData>({ mode: 'all' });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'objects',
  });

  const branch = watch('branch.id');

  const objects = useWatch({ control, name: 'objects' });

  /**
   * Validate external ids query.
   */
  const [validateExternalIds, validateExternalIdsResponse] =
    useValidateClientExternalIdsLazyQuery({ fetchPolicy: 'network-only' });

  /**
   * Create entities mutation.
   */
  const [createEntities, createEntitiesResponse] = useCreateClientsMutation();

  /**
   * Formatted rows from a CSV file.
   */
  const formattedRows = useMemo(
    () =>
      !rows.length
        ? // Return empty array
          []
        : // Return formatted, unique rows
          _.uniqWith(rows, (a, b) => a[0] === b[0])
            .map((row) => {
              const splitValue = row[0].split(' ');

              return [
                // First name
                splitValue[0],
                // Last name
                splitValue[1][0] === '('
                  ? splitValue[2]
                  : splitValue[1].toUpperCase(),
                // Preferred name
                splitValue[1][0] === '('
                  ? splitValue[1].split('(').pop()?.split(')')[0]
                  : undefined,
                // External ID
                row[0],
              ];
            })
            .filter(Boolean),
    [rows],
  );

  /**
   * Array of unique external ids
   */
  const externalIds = useMemo<string[]>(
    () =>
      !!objects?.length
        ? [
            ...new Set(
              objects
                .filter(({ externalId }) => !!externalId)
                .map(({ externalId }) => externalId),
            ),
          ]
        : [],
    [objects],
  );

  /**
   * Handle validating external ids
   */
  const handleValidateExternalIds = useCallback(() => {
    if (!!externalIds.length) {
      validateExternalIds({
        variables: {
          input: {
            exists: false,
            ids: externalIds,
          },
        },
      });
    }
  }, [externalIds, validateExternalIds]);

  /**
   * Handle submit records.
   */
  const onSubmit = (values: FormData) => {
    createEntities({
      variables: {
        input: {
          objects: values.objects.map(
            ({ externalId, firstName, lastName }) => ({
              externalId,
              firstName,
              lastName,
              module: ModuleType.CS,
              branch: { id: branch },
            }),
          ),
        },
      },
    });
  };

  const formattedDataTable = useMemo(
    () => (
      <Table
        inline
        showIndexColumn
        enableRowHighlighting
        hidden={!fields.length}
      >
        <TableHeader>First Name</TableHeader>
        <TableHeader>Last Name</TableHeader>
        <TableHeader>Preferred Name</TableHeader>
        <TableHeader>External ID</TableHeader>
        <TableHeader>Delete</TableHeader>
        {fields.map((field, i) => (
          <TableRow key={i}>
            <TableCell>
              <PersonNameFirstInput
                key={field.id}
                control={control}
                label={undefined}
                variant="outlined"
                error={!!errors.objects && !!errors.objects[i]?.firstName}
                helperText={
                  !!errors.objects && !!errors.objects[i]?.firstName?.message
                }
                {...register(`objects.${i}.firstName` as const, {
                  onBlur: ({ target: { value } }) => {
                    setValue(`objects.${i}.firstName`, value);
                  },
                })}
              />
            </TableCell>
            <TableCell>
              <PersonNameLastInput
                key={field.id}
                control={control}
                label={undefined}
                variant="outlined"
                error={!!errors.objects && !!errors.objects[i]?.lastName}
                helperText={
                  !!errors.objects && !!errors.objects[i]?.lastName?.message
                }
                {...register(`objects.${i}.lastName` as const, {
                  onBlur: ({ target: { value } }) => {
                    setValue(`objects.${i}.lastName`, value);
                  },
                })}
              />
            </TableCell>
            <TableCell>
              <PersonNamePreferredInput
                key={field.id}
                control={control}
                label={undefined}
                variant="outlined"
                error={!!errors.objects && !!errors.objects[i]?.preferredName}
                helperText={
                  !!errors.objects &&
                  !!errors.objects[i]?.preferredName?.message
                }
                {...register(`objects.${i}.preferredName` as const, {
                  onBlur: ({ target: { value } }) => {
                    setValue(`objects.${i}.preferredName`, value);
                  },
                })}
              />
            </TableCell>
            <TableCell>
              <ClientExternalIdInput
                key={field.id}
                control={control}
                label={undefined}
                variant="outlined"
                error={!!errors.objects && !!errors.objects[i]?.externalId}
                helperText={
                  !!errors.objects && !!errors.objects[i]?.externalId?.message
                }
                {...register(`objects.${i}.externalId` as const, {
                  onBlur: ({ target: { value } }) => {
                    setValue(`objects.${i}.externalId`, value);
                    handleValidateExternalIds();
                  },
                })}
              />
            </TableCell>
            <TableCell>
              <IconButton onClick={() => remove(i)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    ),
    [
      register,
      fields,
      remove,
      control,
      handleValidateExternalIds,
      errors.objects,
      setValue,
    ],
  );

  /**
   * Set default value for branch
   */
  useEffect(() => {
    if (!!auth.branch) setValue('branch.id', auth.branch.id);
  }, [auth.branch, setValue]);

  /**
   * Append fields array after rows are formatted
   */
  useEffect(() => {
    if (!!formattedRows?.length) {
      // Remove existing fields
      remove();

      // Add the new fields
      formattedRows.forEach((row) =>
        append({
          firstName: row[0] ?? '',
          lastName: row[1] ?? '',
          preferredName: row[2] ?? '',
          externalId: row[3] ?? '',
        }),
      );
    }
  }, [formattedRows, append, remove]);

  /**
   * Trigger field validation after rows are formatted
   */
  useEffect(() => {
    if (fields.length) trigger('objects');
  }, [trigger, fields]);

  /**
   * Validate external ids once fields are generated.
   */
  useEffect(() => {
    if (!!fields?.length && !validateExternalIdsResponse.called) {
      handleValidateExternalIds();
    }
  }, [fields, handleValidateExternalIds, validateExternalIdsResponse.called]);

  /**
   * Set form errors when server finds existing external ids in use
   */
  useEffect(() => {
    if (
      validateExternalIdsResponse.data?.validateClientExternalIds.ids.length
    ) {
      validateExternalIdsResponse.data?.validateClientExternalIds.ids.forEach(
        (id) =>
          objects
            .map(({ externalId }, i) => (externalId === id ? i : undefined))
            .filter((i) => i !== undefined)
            .forEach((i) =>
              setError(`objects.${i!}.externalId`, {
                message: 'ID already in use',
              }),
            ),
      );
    }
  }, [
    validateExternalIdsResponse.data?.validateClientExternalIds.ids,
    setError,
    objects,
  ]);

  /**
   * Set form errors from server response
   */
  useEffect(() => {
    if (createEntitiesResponse.error) {
      // Set errors if the errors occured within the "objects" field
      if (
        (
          createEntitiesResponse.error.graphQLErrors[0].extensions
            .exception as any
        ).response.message[0].property === 'objects'
      ) {
        (
          createEntitiesResponse.error.graphQLErrors[0].extensions
            .exception as any
        ).response.message[0].children.forEach((object: any) => {
          object.children.forEach(({ property, constraints }: any) => {
            setError(
              `objects.${object.property as number}.${property}` as any,
              { message: Object.values(constraints)[0] as string },
            );
          });
        });
      }
    }
  }, [createEntitiesResponse.error, setError]);

  return (
    <form className={classes.wrapper} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <Typography className={classes.bold}>
            1. Select a branch for the new employees.
          </Typography>
          <BranchSelectInput
            required
            label="Branch"
            formControlProps={{
              size: 'small',
              variant: 'outlined',
              className: classes.input,
            }}
            name="branch.id"
            control={control}
            watch={watch}
          />
        </div>
        {!!branch && (
          <div className={classes.inputGroup}>
            <Typography className={classes.bold}>
              2. Select a .CSV file.
            </Typography>
            <FileInput
              required
              onChange={(event) =>
                parse((event.target as HTMLInputElement).files)
              }
              name="attachment"
              control={form.control}
              allowedMimeTypes={[MIME.CSV]}
              setError={form.setError}
              setValue={form.setValue}
              formControlProps={{ size: 'small' }}
              error={!!form.errors?.attachment}
              helperText={form.errors?.attachment?.message?.toString()}
              className={classes.input}
            />
          </div>
        )}
      </div>

      {!!rows.length && (
        <>
          <Typography className={classes.info}>
            <span>{rows.length} records loaded.</span>

            {!!formattedRows.length && (
              <>
                <span>
                  {formattedRows.length} records formatted,{' '}
                  {rows.length - formattedRows.length} duplicate records
                  removed.
                </span>
                {formattedRows.some((row) => !row[Column.externalId]) && (
                  <span style={{ color: yellow[800] }}>
                    Warning:{' '}
                    {formattedRows.filter((row) => !row[Column.externalId])
                      .length +
                      ' ' +
                      pluralise({
                        singular: 'record',
                        plural: 'records',
                        quantity: formattedRows.filter(
                          (row) => !row[Column.externalId],
                        ).length,
                      }) +
                      ' missing external ids. These records will be skipped.'}
                  </span>
                )}
                {validateExternalIdsResponse.called &&
                  (validateExternalIdsResponse.loading ||
                  !validateExternalIdsResponse.data ? (
                    'Validating ids...'
                  ) : (
                    <>
                      <span
                        style={{
                          color: !!validateExternalIdsResponse.data
                            .validateClientExternalIds.ids.length
                            ? theme.palette.error.dark
                            : undefined,
                        }}
                      >
                        {!!validateExternalIdsResponse.data
                          .validateClientExternalIds.ids.length
                          ? 'The following external ids are already allocated to existing participants.'
                          : 'External ids successfully valdiated.'}
                      </span>
                      {!!validateExternalIdsResponse.data
                        .validateClientExternalIds.ids.length && (
                        <ul>
                          {validateExternalIdsResponse.data.validateClientExternalIds.ids.map(
                            (id) => (
                              <li>
                                <span
                                  style={{ color: theme.palette.error.dark }}
                                >
                                  {id}
                                </span>
                              </li>
                            ),
                          )}
                        </ul>
                      )}
                    </>
                  ))}
                {errors.objects?.length && (
                  <Typography color="error">
                    Field validation error: please review messages below.
                  </Typography>
                )}
              </>
            )}
          </Typography>

          <div className={classes.inputGroup}>
            <Typography className={classes.bold}>
              3. Upload imported records.
            </Typography>
            <div className={classes.controls}>
              {validateExternalIdsResponse.called &&
                !validateExternalIdsResponse.loading && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={
                      !!errors.objects?.length ||
                      !!validateExternalIdsResponse.data
                        ?.validateClientExternalIds.ids.length ||
                      !!createEntitiesResponse.data
                    }
                  >
                    Save
                  </Button>
                )}
            </div>
          </div>

          {!createEntitiesResponse.data &&
            !!formattedRows.length &&
            formattedDataTable}

          {!!createEntitiesResponse.data && (
            <Typography
              className={classes.info}
              style={{ color: theme.palette.success.dark }}
            >
              {createEntitiesResponse.data.createClients.length}{' '}
              {pluralise({
                singular: 'record',
                plural: 'records',
                quantity: createEntitiesResponse.data.createClients.length,
              })}{' '}
              imported successfully.
            </Typography>
          )}
        </>
      )}
    </form>
  );
};

export default ClientImportForm;

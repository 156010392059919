import { CalendarEventEntity } from '@timed/common';
import { createContext } from 'react';

export type MemberViewUnavailabilityContextType = {
  fetch: () => void;
  unavailables: CalendarEventEntity[];
};

const MemberViewUnavailabilityContext =
  createContext<MemberViewUnavailabilityContextType>({
    fetch: () => {},
    unavailables: [],
  });

export default MemberViewUnavailabilityContext;

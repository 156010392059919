/**
 * Convert a _local-time_ value to an ISO-8601 Date string.
 *
 * For instance: given 2020-05-13T03:59:50.000Z, if we're in UTC-4,
 * return "2020-05-12".
 *
 * Why? Because material-ui selects dates in local time, not in UTC. If we
 * were to run date.toISOString(), that would convert to UTC and then
 * convert to String; but if we convert to UTC, that changes the date.
 */
export const jsDateToLocalISO8601DateString = (date: Date) =>
  [
    String(date.getFullYear()),
    String(101 + date.getMonth()).substring(1),
    String(100 + date.getDate()).substring(1),
  ].join('-');

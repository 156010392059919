import { PersonNameInput, TextFieldProps } from '@timed/common';
import { personNameRegex } from '@timed/common/regexps';
import { RegisterOptions } from 'react-hook-form';

export const validatePersonNamePreferred: RegisterOptions = {
  pattern: {
    value: personNameRegex({ allowNumbers: true }),
    message: 'Invalid character(s)',
  },
  maxLength: { value: 20, message: 'Too long' },
};

const PersonNamePreferredInput = (props: TextFieldProps) => (
  <PersonNameInput
    label="Preferred Name"
    validation={validatePersonNamePreferred}
    {...props}
  />
);

export default PersonNamePreferredInput;

import { PaginationContextType } from '@timed/common';
import {
  ClientNdisPlanItemTransactionContextGetTransactionsQuery,
  ClientNdisPlanItemTransactionOrderByInput,
  OrderBy,
} from '@timed/gql';
import { Dispatch, SetStateAction, createContext } from 'react';
import { UseFormReturn } from 'react-hook-form';

export type ClientNdisPlanItemTransactionContextInput = {
  /**
   * Input controller.
   */
  form: UseFormReturn;
  /**
   * Filter by plan.
   */
  planId: string;
  /**
   * Filter by category.
   */
  categoryId: string | null;
  /**
   * Set supplier.
   */
  setCategoryId: Dispatch<SetStateAction<string | null>>;
  /**
   * Filter by supplier.
   */
  supplierId: string | null;
  /**
   * Set supplier.
   */
  setSupplierId: Dispatch<SetStateAction<string | null>>;
  /**
   * Ordering options.
   */
  orderBy: ClientNdisPlanItemTransactionOrderByInput[];
  /**
   * Set ordering options.
   */
  setOrderBy: Dispatch<
    SetStateAction<ClientNdisPlanItemTransactionOrderByInput[]>
  >;
};

export type ClientNdisPlanItemTransactionContextType = PaginationContextType<
  ClientNdisPlanItemTransactionContextGetTransactionsQuery['clientNdisPlanItemTransactionAggregate']['nodes']
> & {
  /**
   * Reset filters to their defaults
   */
  reset: () => void;
  /**
   * Network loading state.
   */
  loading: boolean;
  /**
   * Results filter.
   */
  input: ClientNdisPlanItemTransactionContextInput;
};

const ClientNdisPlanItemTransactionContext =
  createContext<ClientNdisPlanItemTransactionContextType>({
    fetch: () => {},
    reset: () => {},
    offset: 0,
    setOffset: () => 0,
    limit: 100,
    loading: false,
    input: {
      // @ts-ignore
      form: {},
      orderBy: [{ billingDate: OrderBy.DESC }],
      setOrderBy: () => {},
    },
  });

export default ClientNdisPlanItemTransactionContext;

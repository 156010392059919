import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { AlertProvider } from '@timed/alert';
import { _peopleMembers, _schedule } from '@timed/app';
import { AppBar } from '@timed/app-bar';
import { useAuth } from '@timed/auth';
import { useRouter } from '@timed/common';
import { ModuleType } from '@timed/gql';
import { ModuleContext } from '@timed/module';
import { NavBarCoreSupport, NavBarProvider } from '@timed/nav-bar';
import { NavBarSupportCoordination } from '@timed/nav-bar/components/NavBarSupportCoordination';
import { PageProvider } from '@timed/page';
import { RouteSetup } from '@timed/routes';
import ModalProvider from 'mui-modal-provider';
import { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      backgroundColor: theme.palette.background.default,
    },
    wrapper: {
      display: 'flex',
      flexFlow: 'column',
      // flex: '1 1 auto',
      // flexGrow: 1,
      // flexBasis: 'auto',
      flex: '1 1 auto',
    },
    module: {
      flexGrow: 1,
      display: 'flex',
      overflow: 'auto',
    },
    content: {
      // flex: '1 1 auto',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      padding: theme.spacing(4),
      width: 0,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
      },
    },
    warning: {
      padding: theme.spacing(4),
      color: theme.palette.error.main,
    },
  }),
);

const RouteAuthenticated = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { pathname } = useRouter();

  const { activeModule } = useContext(ModuleContext);

  const { member, branches } = useAuth();

  useEffect(() => {
    pathname === '/' &&
      navigate(
        activeModule === ModuleType.CS ? _schedule.path : _peopleMembers.path,
      );
  }, [pathname, navigate, activeModule]);

  return (
    <ModalProvider>
      <AlertProvider>
        <PageProvider>
          <NavBarProvider>
            <Box className={classes.root}>
              <Box className={classes.wrapper}>
                <AppBar />
                {!member ? (
                  <RouteSetup />
                ) : !branches.length ? (
                  <Typography className={classes.warning}>
                    You do not have access to any branches within this
                    organisation.
                    <br />
                    If this is a mistake please notify your employer.
                  </Typography>
                ) : !!member ? (
                  <Box className={classes.module}>
                    {activeModule === ModuleType.CS ? (
                      <NavBarCoreSupport />
                    ) : (
                      <NavBarSupportCoordination />
                    )}
                    <Box className={classes.content}>
                      <Outlet />
                    </Box>
                  </Box>
                ) : (
                  <RouteSetup />
                )}
              </Box>
            </Box>
          </NavBarProvider>
        </PageProvider>
      </AlertProvider>
    </ModalProvider>
  );
};

export default RouteAuthenticated;

import { Select, SelectProps } from '@timed/common';
import { DriversLicense } from '@timed/gql';
import _, { camelCase, startCase } from 'lodash';
import { RegisterOptions } from 'react-hook-form';

export const validateDriversLicence: RegisterOptions = {
  validate: {
    validValue: (value) => value in DriversLicense || 'Invalid value',
  },
};

const DriversLicenceInput = ({
  defaultValue,
  ...props
}: Omit<SelectProps, 'items'>) => (
  <Select
    label="Drivers Licence"
    defaultValue={defaultValue || DriversLicense.UNKNOWN}
    validation={validateDriversLicence}
    formControlProps={{
      size: 'small',
      variant: 'outlined',
      style: { minWidth: 150 },
    }}
    items={[
      { label: 'Unknown', value: DriversLicense.UNKNOWN },
      { label: 'No', value: DriversLicense.NO },
      { label: 'Yes', value: DriversLicense.YES },
      ...Object.values(_.omit(DriversLicense, ['UNKNOWN', 'YES', 'NO'])).map(
        (value) => ({
          value,
          label: value,
        }),
      ),
    ]}
    renderValue={(value) => startCase(camelCase(value as string))}
    {...props}
  />
);

export default DriversLicenceInput;

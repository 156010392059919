import { EventBillingRegistrationGroup } from '@timed/gql';
import { Activity } from '@timed/report';
import {
  addDays,
  eachDayOfInterval,
  isAfter,
  isBefore,
  isEqual,
  isSaturday,
  isSunday,
  isWithinInterval,
  set,
  startOfDay,
} from 'date-fns';

type CalculatePayrollActivityFn = {
  type: 'support' | 'travelTime';
  billingRegistrationGroup: EventBillingRegistrationGroup;
  billable: boolean;
  eventStart: Date;
  eventEnd: Date;
  shiftStart?: Date;
  shiftEnd?: Date;
  passive?: boolean;
  publicHoliday?: boolean;
  cancelled?: boolean;
};

export const calculatePayrollActivityId = ({
  billable,
  billingRegistrationGroup,
  eventEnd,
  eventStart,
  shiftEnd,
  shiftStart,
  type,
  passive = false,
  publicHoliday = false,
  cancelled = false,
}: CalculatePayrollActivityFn): Activity => {
  // Passive shift (ignore type)
  if (passive)
    return cancelled ? Activity.CANCELLED_OVERNIGHT : Activity.OVERNIGHT;

  // Public holiday
  if (publicHoliday)
    switch (type) {
      case 'support':
        if (billable) {
          switch (billingRegistrationGroup) {
            case EventBillingRegistrationGroup.COMMUNITY_PARTICIPATION:
              return Activity.BILLABLE_ACCESS_COMMUNITY_PUBLIC_HOLIDAY;
            case EventBillingRegistrationGroup.DAILY_LIFE_SKILLS:
              return Activity.BILLABLE_DAILY_LIFE_SKILLS;
            case EventBillingRegistrationGroup.DAILY_PERSONAL_ACTIVITIES:
              return Activity.BILLABLE_PUBLIC_HOLIDAY;
          }
        } else return Activity.NON_BILLABLE_PUBLIC_HOLIDAY;
        break;

      case 'travelTime':
        return Activity.TRAVEL_PUBLIC_HOLIDAY;
    }

  const datesInEvent = eachDayOfInterval({
    start: new Date(eventStart),
    end: new Date(eventEnd),
  }).filter((d) => !isEqual(new Date(eventEnd), d));

  // Sunday
  if (datesInEvent.some((d) => isSunday(d)))
    switch (type) {
      case 'support':
        if (billable) {
          switch (billingRegistrationGroup) {
            case EventBillingRegistrationGroup.COMMUNITY_PARTICIPATION:
              return Activity.BILLABLE_ACCESS_COMMUNITY_SUNDAY;
            case EventBillingRegistrationGroup.DAILY_LIFE_SKILLS:
              return Activity.BILLABLE_DAILY_LIFE_SKILLS;
            case EventBillingRegistrationGroup.DAILY_PERSONAL_ACTIVITIES:
              return Activity.BILLABLE_SUNDAY;
          }
        } else return Activity.NON_BILLABLE_SUNDAY;
        break;

      case 'travelTime':
        return Activity.TRAVEL_SUNDAY;
    }

  // Saturday
  if (datesInEvent.some((d) => isSaturday(d)))
    switch (type) {
      case 'support':
        if (billable) {
          switch (billingRegistrationGroup) {
            case EventBillingRegistrationGroup.COMMUNITY_PARTICIPATION:
              return Activity.BILLABLE_ACCESS_COMMUNITY_SATURDAY;
            case EventBillingRegistrationGroup.DAILY_LIFE_SKILLS:
              return Activity.BILLABLE_DAILY_LIFE_SKILLS;
            case EventBillingRegistrationGroup.DAILY_PERSONAL_ACTIVITIES:
              return Activity.BILLABLE_SATURDAY;
          }
        } else return Activity.NON_BILLABLE_SATURDAY;
        break;

      case 'travelTime':
        return Activity.TRAVEL_SATURDAY;
    }

  // Night. Event spans multiple days or shift starts between the hours of 00:00 and 06:00.
  if (
    (shiftStart &&
      isBefore(
        new Date(shiftStart),
        set(new Date(eventStart), { hours: 6 }),
      )) ||
    isAfter(new Date(eventEnd), startOfDay(addDays(new Date(eventStart), 1)))
  )
    switch (type) {
      case 'support':
        if (billable) {
          switch (billingRegistrationGroup) {
            case EventBillingRegistrationGroup.COMMUNITY_PARTICIPATION:
              return Activity.BILLABLE_ACCESS_COMMUNITY_AFTERNOON;
            case EventBillingRegistrationGroup.DAILY_LIFE_SKILLS:
              return Activity.BILLABLE_DAILY_LIFE_SKILLS;
            case EventBillingRegistrationGroup.DAILY_PERSONAL_ACTIVITIES:
              return Activity.BILLABLE_NIGHT;
          }
        } else return Activity.NON_BILLABLE_NIGHT;
        break;
      case 'travelTime':
        return Activity.TRAVEL_NIGHT;
    }

  // Weekday. Event occurs between the hours of 6am and 8pm and does not span multiple days.
  if (
    isWithinInterval(shiftStart ? new Date(shiftStart) : new Date(eventStart), {
      start: set(new Date(eventStart), { hours: 6, minutes: 0 }),
      end: set(new Date(eventStart), { hours: 20, minutes: 0 }),
    }) &&
    isWithinInterval(shiftEnd ? new Date(shiftEnd) : new Date(eventEnd), {
      start: set(new Date(eventStart), { hours: 6, minutes: 0 }),
      end: set(new Date(eventStart), { hours: 20, minutes: 0 }),
    })
  )
    switch (type) {
      case 'support':
        if (billable) {
          switch (billingRegistrationGroup) {
            case EventBillingRegistrationGroup.COMMUNITY_PARTICIPATION:
              return Activity.BILLABLE_ACCESS_COMMUNITY_WEEKDAY;
            case EventBillingRegistrationGroup.DAILY_LIFE_SKILLS:
              return Activity.BILLABLE_DAILY_LIFE_SKILLS;
            case EventBillingRegistrationGroup.DAILY_PERSONAL_ACTIVITIES:
              return Activity.BILLABLE_WEEKDAY;
          }
        } else return Activity.NON_BILLABLE_WEEKDAY;
        break;

      case 'travelTime':
        return Activity.TRAVEL_WEEKDAY;
    }

  // Afternoon. Event is between 8pm and midnight on the same day
  switch (type) {
    case 'support':
      if (billable) {
        switch (billingRegistrationGroup) {
          case EventBillingRegistrationGroup.COMMUNITY_PARTICIPATION:
            return Activity.BILLABLE_ACCESS_COMMUNITY_AFTERNOON;
          case EventBillingRegistrationGroup.DAILY_LIFE_SKILLS:
            return Activity.BILLABLE_DAILY_LIFE_SKILLS;
          case EventBillingRegistrationGroup.DAILY_PERSONAL_ACTIVITIES:
            return Activity.BILLABLE_AFTERNOON;
        }
      } else return Activity.NON_BILLABLE_AFTERNOON;
      break;

    case 'travelTime':
      return Activity.TRAVEL_AFTERNOON;
  }
};

import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  _settings,
  _settingsOrganisationBilling,
  _settingsOrganisationBranches,
  _settingsOrganisationMedication,
  _settingsOrganisationPayroll,
  _settingsOrganisationScheduling,
  _settingsOrganisationVisaTypes,
  _settingsPersonalAccount,
} from '@timed/app';
import { NavLinks } from '@timed/common';
import { Page } from '@timed/page';
import { Outlet } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
    },
  }),
);

const RouteSettings = () => {
  const classes = useStyles();

  return (
    <Page {..._settings}>
      <Box className={classes.wrapper}>
        <NavLinks
          links={[
            _settingsPersonalAccount,
            _settingsOrganisationBilling,
            _settingsOrganisationBranches,
            _settingsOrganisationMedication,
            _settingsOrganisationPayroll,
            _settingsOrganisationScheduling,
            _settingsOrganisationVisaTypes,
          ]}
        />
        <Outlet />
      </Box>
    </Page>
  );
};

export default RouteSettings;

import { FormControl, FormControlProps } from '@material-ui/core';
import {
  DatePicker as MuiDatePicker,
  KeyboardDatePicker as MuiKeyboardDatePicker,
  KeyboardDatePickerProps as MuiKeyboardDatePickerProps,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  dateStringToLocalDate,
  jsDateToLocalISO8601DateString,
} from '@timed/common';
import { addDays } from 'date-fns';
import { Control, Controller } from 'react-hook-form';

type DateInputProps = Omit<MuiKeyboardDatePickerProps, 'value' | 'onChange'> & {
  keyboard?: boolean;
  formControlProps?: FormControlProps;
  control?: Control<any>;
  name: string;
  renderDayOffset?: number; // Impacts only the rendered date, not the value
  onChange?: (
    date: MaterialUiPickersDate,
    value?: string | null | undefined,
  ) => void;
  /**
   * Date object will not contain time data. Fixes a bug with mui-datepickers
   * library. See: https://github.com/mui-org/material-ui-pickers/issues/1526#issuecomment-628237301
   */
  disableTime?: boolean;
};

const DateInput = ({
  keyboard,
  formControlProps,
  control,
  name,
  renderDayOffset,
  onChange,
  disableTime,
  defaultValue,
  ...props
}: DateInputProps) => {
  const commonProps = {
    autoOk: true,
    id: 'date-picker',
    KeyboardButtonProps: {
      'aria-label': 'date picker',
    },
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { value, onChange: controllerOnChange, ...field },
      }) => {
        return (
          <FormControl {...formControlProps}>
            {keyboard ? (
              <MuiKeyboardDatePicker
                {...field}
                value={
                  disableTime
                    ? dateStringToLocalDate(
                        renderDayOffset
                          ? addDays(value, renderDayOffset)
                          : value,
                      )
                    : renderDayOffset
                    ? addDays(value, renderDayOffset)
                    : value
                }
                onChange={(date: MaterialUiPickersDate) => {
                  if (disableTime) {
                    controllerOnChange(
                      date ? jsDateToLocalISO8601DateString(date) : null,
                    );
                    onChange &&
                      onChange(
                        date
                          ? new Date(jsDateToLocalISO8601DateString(date))
                          : null,
                      );
                  } else {
                    controllerOnChange(date);
                    onChange && onChange(date);
                  }
                }}
                format="dd / MM / yyyy"
                {...commonProps}
                {...props}
              />
            ) : (
              <MuiDatePicker
                {...field}
                value={
                  renderDayOffset ? addDays(value, renderDayOffset) : value
                }
                onChange={(date: MaterialUiPickersDate) => {
                  controllerOnChange(date);
                  onChange && onChange(date);
                }}
                format="d MMM yyyy"
                {...commonProps}
                {...props}
              />
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default DateInput;

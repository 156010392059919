import { TextField, TextFieldProps } from '@timed/common';
import { RegisterOptions } from 'react-hook-form';

export const validateClientExternalId: RegisterOptions = {
  maxLength: 255,
};

const ClientExternalIdInput = (props: TextFieldProps) => (
  <TextField
    label="External ID"
    type="text"
    validation={validateClientExternalId}
    {...props}
  />
);

export default ClientExternalIdInput;

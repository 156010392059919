import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useAlert } from '@timed/alert';
import { Button, ButtonAsync, IconButton, Modal } from '@timed/common';
import {
  QueryByIdInput,
  useDeleteMemberUnavailablesMutation,
} from '@timed/gql';
import React from 'react';

type MemberDeleteUnavailableFormModalProps = {
  onClose: () => void;
  unavailable: QueryByIdInput;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      padding: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        width: 500,
      },
    },
    title: {
      fontSize: '16px',
      fontWeight: 'bold',
      display: 'grid',
      gridTemplateColumns: 'auto min-content',
      alignItems: 'center',
    },
    buttons: {
      marginTop: theme.spacing(1),
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

const MemberDeleteUnavailableFormModal = ({
  onClose,
  unavailable,
}: MemberDeleteUnavailableFormModalProps) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(true);

  const alert = useAlert();

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const [deleteUnavailable, { data, loading, client }] =
    useDeleteMemberUnavailablesMutation();

  const onSuccess = () => {
    alert.push({
      message: 'Period deleted',
      severity: 'info',
    });

    const cache = client.cache;

    data!.deleteMemberUnavailables.length &&
      cache.evict({
        id: cache.identify({
          __typename: 'MemberUnavailable',
          id: data!.deleteMemberUnavailables[0].id,
        }),
      });
    cache.gc();
  };

  const onComplete = () => handleClose();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box className={classes.form}>
        <Box className={classes.title}>
          <Typography>Delete unavailable period</Typography>
          <IconButton aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Typography>Are you sure you want to delete this period?</Typography>

        <Box className={classes.buttons}>
          <ButtonAsync
            color="primary"
            loading={loading}
            success={!!data}
            onClick={() => {
              deleteUnavailable({
                variables: { input: { ids: [unavailable.id] } },
              });
            }}
            onComplete={onComplete}
            onSuccess={onSuccess}
          >
            Delete
          </ButtonAsync>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default MemberDeleteUnavailableFormModal;

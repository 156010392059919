import {
  Collapse,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { blue, green, orange, red } from '@material-ui/core/colors';
import { Box } from '@mui/material';
import { ClientNdisPlanItemActionMenuButton } from '@timed/client/components/NdisPlanItemActionMenuButton';
import { Table, TableCell, TableHeader, TableRow } from '@timed/common';
import { formatCurrency } from '@timed/common/utils/formatCurrency';
import {
  ClientNdisPlan,
  ClientNdisPlanItem,
  ClientNdisPlanItemCategory,
  ClientNdisPlanItemName,
  QueryByIdInput,
} from '@timed/gql';
import clsx from 'clsx';
import { useMemo } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      gap: theme.spacing(2),
    },
    input: {
      '&.MuiFormControl-root': {
        display: 'flex',
      },
    },
    bold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    row: {
      height: 52,
    },
  }),
);

type ClientNdisPlanItemTableProps = {
  open: boolean;
  clientNdisPlan: Pick<ClientNdisPlan, 'id'> & {
    coreSupportItem?: QueryByIdInput | null;
    supportCoordinationItem?: QueryByIdInput | null;
    items?:
      | (Pick<
          ClientNdisPlanItem,
          'id' | 'category' | 'managedBy' | 'currentFunds' | 'initialFunds'
        > & {
          name: Pick<ClientNdisPlanItemName, 'id' | 'name' | 'category'>;
        })[]
      | null;
  };
  prevClientNdisPlan?: Pick<ClientNdisPlan, 'id'> & {
    items?:
      | (Pick<
          ClientNdisPlanItem,
          'id' | 'category' | 'managedBy' | 'currentFunds' | 'initialFunds'
        > & {
          name: Pick<ClientNdisPlanItemName, 'id' | 'name' | 'category'>;
        })[]
      | null;
  };
};

const NdisPlanItemTable = ({
  clientNdisPlan,
  prevClientNdisPlan,
  open,
}: ClientNdisPlanItemTableProps) => {
  const classes = useStyles();

  // Merge items from this plan with the previous plan if the previous plan was provided
  const items = useMemo(() => {
    if (prevClientNdisPlan) {
      const names = [
        ...new Set([
          ...(clientNdisPlan.items?.length
            ? clientNdisPlan.items.map(({ name }) => name)
            : []),
          ...(prevClientNdisPlan?.items?.length
            ? prevClientNdisPlan.items.map(({ name }) => name)
            : []),
        ]),
      ];

      return names.map<NonNullable<typeof clientNdisPlan['items']>[0]>(
        (name) => ({
          id:
            clientNdisPlan.items?.find((item) => item.name === name)?.id ?? '',
          name,
          currentFunds:
            (clientNdisPlan.items?.find((plan) => plan.name === name)
              ?.currentFunds ?? 0) +
            (prevClientNdisPlan?.items?.find((plan) => plan.name === name)
              ?.currentFunds ?? 0),
          initialFunds:
            (prevClientNdisPlan?.items?.find((plan) => plan.name === name)
              ?.initialFunds ?? 0) +
            (clientNdisPlan.items?.find((plan) => plan.name === name)
              ?.initialFunds ?? 0),
          managedBy:
            clientNdisPlan.items?.find((plan) => plan.name === name)
              ?.managedBy ??
            prevClientNdisPlan?.items?.find((plan) => plan.name === name)
              ?.managedBy!,
          category:
            clientNdisPlan.items?.find((plan) => plan.name === name)
              ?.category ??
            prevClientNdisPlan?.items?.find((plan) => plan.name === name)
              ?.category,
        }),
      );
    } else {
      return clientNdisPlan.items ?? [];
    }
  }, [clientNdisPlan.items, prevClientNdisPlan]);

  const formatCategoryRow = (
    category: ClientNdisPlanItemCategory,
    index: number,
  ) => {
    let name: string;
    const filteredItems = items.filter((item) => item.category === category);
    const initialFunds =
      filteredItems
        .map(({ initialFunds }) => initialFunds ?? 0)
        .reduce((a, b) => a + b) / 100;
    const currentFunds =
      filteredItems
        .map(({ currentFunds }) => currentFunds)
        .reduce((a, b) => a + b) / 100;
    const spentFunds = initialFunds - currentFunds;
    const percentUsed = Math.round((spentFunds / initialFunds) * 100);

    switch (category) {
      case ClientNdisPlanItemCategory.CAPACITY_BUILDING:
        name = 'Capacity Building';
        break;
      case ClientNdisPlanItemCategory.CAPITAL:
        name = 'Capital';
        break;
      case ClientNdisPlanItemCategory.CORE:
        name = 'Core';
        break;
    }

    return (
      <TableRow key={index}>
        <TableCell
          className={clsx(classes.row, classes.bold)}
          style={{
            backgroundColor: '#ced5eb',
          }}
        >
          {name}
        </TableCell>
        <TableCell
          className={classes.bold}
          style={{
            backgroundColor: '#ced5eb',
          }}
        >
          {formatCurrency(initialFunds)}
        </TableCell>
        <TableCell
          className={classes.bold}
          style={{
            backgroundColor: '#ced5eb',
          }}
        >
          {formatCurrency(spentFunds)}
        </TableCell>
        <TableCell
          className={classes.bold}
          style={{
            backgroundColor: '#ced5eb',
          }}
        >
          {formatCurrency(currentFunds)}
        </TableCell>
        <TableCell
          className={classes.bold}
          style={{
            backgroundColor:
              percentUsed >= 50
                ? green[500]
                : percentUsed < 50 && percentUsed >= 25
                ? orange[500]
                : red[500],
            color: 'white',
            borderLeft: '2px solid black',
            borderRight: !!prevClientNdisPlan ? undefined : '2px solid black',
          }}
        >
          {percentUsed}%
        </TableCell>
        <TableCell
          style={{
            backgroundColor: '#ced5eb',
          }}
        ></TableCell>
      </TableRow>
    );
  };

  const formatRow = (item: typeof items[0], index: number) => {
    let linkedModule: string | null = null;

    if (item.id === clientNdisPlan.coreSupportItem?.id)
      linkedModule = 'Core Support';
    if (item.id === clientNdisPlan.supportCoordinationItem?.id)
      linkedModule = 'Support Coordination';

    const spentFunds = (item.initialFunds - item.currentFunds) / 100;
    const percentUsed = Math.round(
      (spentFunds / (item.initialFunds / 100)) * 100,
    );

    return (
      <TableRow key={index}>
        <TableCell className={classes.row} indent={1}>
          {item.name.name}
          {linkedModule && (
            <Typography variant="body2" style={{ color: blue[700] }}>
              These funds automatically reflect billed activities of the{' '}
              {linkedModule} module
            </Typography>
          )}
        </TableCell>
        <TableCell>{formatCurrency(item.initialFunds / 100)}</TableCell>
        <TableCell>{formatCurrency(spentFunds)}</TableCell>
        <TableCell>{formatCurrency(item.currentFunds / 100)}</TableCell>
        <TableCell
          style={{
            backgroundColor:
              percentUsed >= 50
                ? green[500]
                : percentUsed < 50 && percentUsed >= 25
                ? orange[500]
                : red[500],
            color: 'white',
            borderLeft: '2px solid black',
            borderRight: !!prevClientNdisPlan ? undefined : '2px solid black',
          }}
        >
          {Math.min(100, percentUsed)}%
        </TableCell>
        <TableCell>
          <ClientNdisPlanItemActionMenuButton clientNdisPlanItem={item} />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Collapse in={open} timeout="auto">
      <Box className={classes.wrapper}>
        <Table
          enableRowHighlighting
          border="line"
          borderSize="thick"
          borderColor="black"
        >
          <TableHeader
            style={{
              backgroundColor: '#5f77bd',
              color: 'white',
            }}
          >
            Support Category
          </TableHeader>
          <TableHeader
            align="right"
            style={{
              backgroundColor: '#5f77bd',
              color: 'white',
            }}
          >
            Allocated
          </TableHeader>
          <TableHeader
            align="right"
            style={{
              backgroundColor: '#5f77bd',
              color: 'white',
            }}
          >
            Spend to Date
          </TableHeader>
          <TableHeader
            align="right"
            style={{
              backgroundColor: '#5f77bd',
              color: 'white',
            }}
          >
            Left to Use
          </TableHeader>
          <TableHeader
            align="center"
            style={{
              backgroundColor: '#5f77bd',
              color: 'white',
            }}
          >
            % Spend
          </TableHeader>
          <TableHeader
            hidden={!!prevClientNdisPlan}
            style={{
              backgroundColor: '#5f77bd',
              color: 'white',
            }}
          ></TableHeader>
          {[...new Set(items.map(({ category }) => category))].map(
            (category, i) => (
              <>
                {formatCategoryRow(category!, i)}
                {items
                  .filter((item) => item.category === category)
                  .map((item, i) => formatRow(item, i))}
              </>
            ),
          )}
        </Table>
      </Box>
    </Collapse>
  );
};

export default NdisPlanItemTable;

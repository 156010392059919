import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { _schedule } from '@timed/app';
import { useAuth } from '@timed/auth';
import {
  addServerErrors,
  ButtonAsync,
  TextField,
  validateAustralianBusinessName,
} from '@timed/common';
import { useCreateOrgMutation } from '@timed/gql';
import OrgLegalNameInput from '@timed/org/components/LegalNameInput/LegalNameInput';
import OrgPreferredNameInput from '@timed/org/components/PreferredNameInput/PreferredNameInput';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

type FormData = {
  legalName: string;
  preferredName?: string;
  defaultBranchName: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
  }),
);

const OrgCreateForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { refetch } = useAuth();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>();

  const [createOrg, response] = useCreateOrgMutation();

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = (values: FormData) =>
    createOrg({
      variables: {
        input: {
          ..._.omit(values, 'defaultBranchName'),
          branches: { objects: [{ name: values.defaultBranchName }] },
        },
      },
    });

  const onComplete = () => navigate(_schedule.path);

  const onSuccess = () => refetch();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Typography className={classes.title}>Create new organisation</Typography>
      <Typography variant="body1">
        Complete the form below to create a brand new organisation.
      </Typography>
      <OrgLegalNameInput
        required
        fullWidth
        control={control}
        variant="outlined"
        size="small"
        error={!!errors.legalName}
        helperText={errors.legalName?.message}
      />
      <OrgPreferredNameInput
        fullWidth
        control={control}
        variant="outlined"
        size="small"
        error={!!errors.preferredName}
        helperText={errors.preferredName?.message}
      />
      <TextField
        fullWidth
        control={control}
        variant="outlined"
        size="small"
        error={!!errors.defaultBranchName}
        helperText={errors.defaultBranchName?.message}
        name="defaultBranchName"
        label="Name of the default branch"
        type="text"
        validation={validateAustralianBusinessName}
      />
      <ButtonAsync
        type="submit"
        color="secondary"
        size="small"
        loading={response.loading}
        success={!!response.data}
        onComplete={onComplete}
        onSuccess={onSuccess}
      >
        Create
      </ButtonAsync>
    </form>
  );
};

export default OrgCreateForm;

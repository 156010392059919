import { Typography } from '@material-ui/core';
import { Table, TableCell, TableHeader, TableRow } from '@timed/common';
import { OrderBy, useGetMedicationsQuery } from '@timed/gql';

const SettingsOrganisationMedicationTable = () => {
  const { data, loading, error } = useGetMedicationsQuery({
    variables: {
      input: {
        orderBy: [{ name: OrderBy.ASC }],
      },
    },
  });

  if (loading) return <Typography>Loading</Typography>;

  if (!!error)
    return <Typography>An error occured while fetching medication</Typography>;

  return (
    <Table inline>
      <TableHeader>Name</TableHeader>
      <TableHeader>Pronunciation</TableHeader>
      <TableHeader>URL</TableHeader>
      {data?.medications.map((med) => (
        <TableRow>
          <TableCell>{med.name}</TableCell>
          <TableCell>{med.pronunciation}</TableCell>
          <TableCell>{med.url}</TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

export default SettingsOrganisationMedicationTable;

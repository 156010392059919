import { Permission } from '@timed/gql';
import { createContext } from 'react';

export type PageMetadata = {
  /**
   * Title of the page
   */
  label: string;

  /**
   * Pathname of the page
   */
  path: string;

  /**
   * Path to redirect to if Page.path === Router.pathname
   */
  redirect?: string;

  /**
   * Overrule the parent page's default redirect path.
   */
  overruleRedirect?: boolean;

  /**
   * Restrict the loading of the metadata to the specified permission(s).
   * To also prevent the page from rendering, wrap child components with
   * \<Protected permission={PERMISSION} />{children}\</Protected>, exported from the 'Auth' module.
   */
  permissions?: Permission | Permission[];

  /**
   * Restrict the loading of the metadata to admins.
   * To also prevent the page from rendering, wrap child components with
   * \<Protected admin={true} />{children}\</Protected>, exported from the 'Auth' module.
   */
  admin?: boolean;

  /**
   * Restrict the loading of the metadata to testers.
   * To also prevent the page from rendering, wrap child components with
   * \<Protected tester={true} />{children}\</Protected>, exported from the 'Auth' module.
   */
  tester?: boolean;

  /**
   * Addition items to render for the breadcrumbs menu. Not required for
   * rendering the default breadcrumb for the page.
   */
};

type PageContextType = {
  /**
   * Process supplied page metadata
   */
  addPage: (page: PageMetadata) => void;
  saveRedirect: (path: string) => void;
  getDirectPagePath: (page: Pick<PageMetadata, 'path' | 'redirect'>) => string;
};

const PageContext = createContext<PageContextType>({
  addPage: () => {},
  saveRedirect: () => {},
  getDirectPagePath: () => '',
});

export default PageContext;

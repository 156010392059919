import { TextField, TextFieldProps } from '@timed/common';
import { RegisterOptions } from 'react-hook-form';

export const validateAddressBuildingNameInput: RegisterOptions = {
  maxLength: { value: 255, message: 'Too long' },
};

const AddressBuildingNameInput = (props: TextFieldProps) => (
  <TextField
    type="text"
    label="Building Name"
    validation={validateAddressBuildingNameInput}
    {...props}
  />
);

export default AddressBuildingNameInput;

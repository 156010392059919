import { Business } from '@timed/gql';

/**
 * Options object for formatBusinessName() function
 */
type FormatBusinessName = {
  /**
   * Capitalise name.
   */
  capitalise?: boolean;

  /**
   * Return preferred name, if exists. If not, fallback to usual formatter.
   */
  preferred?: boolean;
};

/**
 * Format a businesses name
 */
export const formatBusinessName = (
  business: Pick<Business, 'legalName' | 'preferredName'>,
  { capitalise = false, preferred = false }: FormatBusinessName = {},
): string =>
  preferred && business.preferredName
    ? capitalise
      ? business.preferredName.toUpperCase()
      : business.preferredName
    : capitalise
    ? business.legalName.toUpperCase()
    : business.legalName;

import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { useAuth } from '@timed/auth';
import {
  Block,
  formatPersonName,
  Modal,
  ModalProps,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from '@timed/common';
import { Event, OrderBy, useGetEventAccessLogsLazyQuery } from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { format } from 'date-fns';
import { useEffect, useMemo } from 'react';

type EventAccessLogsModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  eventId: Event['id'];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    controls: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(4),
    },
  }),
);

const EventAccessLogsModal = ({
  onClose,
  eventId,
  ...modalProps
}: EventAccessLogsModalProps) => {
  const classes = useStyles();

  const { permissible } = useAuth();

  const [getAccessLogs, { data, loading }] = useGetEventAccessLogsLazyQuery({
    variables: {
      input: {
        where: { event: { id: { _eq: eventId } } },
        orderBy: [{ date: OrderBy.DESC }],
        limit: 100,
      },
    },
    fetchPolicy: 'network-only',
  });

  const hasPermission = useMemo(() => {
    return permissible({ tester: true });
  }, [permissible]);

  useLoadingEffect(loading);

  // Fetch access logs
  useEffect(() => {
    if (hasPermission && !data && !loading) getAccessLogs();
  }, [hasPermission, getAccessLogs, data, loading]);

  return (
    <Modal disablePortal onClose={onClose} {...modalProps}>
      <Block
        titleProps={{ variant: 'h3' }}
        title="Access Logs"
        topRight={
          <Box className={classes.controls}>
            <IconButton onClick={onClose}>
              <CloseRoundedIcon />
            </IconButton>
          </Box>
        }
      >
        <Table
          showIndexColumn
          enableRowHighlighting
          loading={loading}
          maxHeight={500}
        >
          <TableHeader order={OrderBy.DESC}>Date</TableHeader>
          <TableHeader>Time</TableHeader>
          <TableHeader>Actor</TableHeader>
          <TableHeader>IP address</TableHeader>
          {data?.accessLogs.map((log, i) => (
            <TableRow key={i}>
              <TableCell>{format(new Date(log.date), 'dd/MM/yyyy')}</TableCell>
              <TableCell>{format(new Date(log.date), 'HH:mm:ss')}</TableCell>
              <TableCell>
                {formatPersonName(log.actor, {
                  capitaliseLastName: true,
                  lastNameFirst: true,
                })}
              </TableCell>
              <TableCell>{log.ip}</TableCell>
            </TableRow>
          ))}
        </Table>
      </Block>
    </Modal>
  );
};

export default EventAccessLogsModal;

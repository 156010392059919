import { CurrencyTextField, TextFieldProps } from '@timed/common';

const EventBonusPayInput = (props: TextFieldProps) => (
  <CurrencyTextField
    label="Bonus Pay"
    onClick={(event) => {
      (event.target as HTMLInputElement).select();
    }}
    {...props}
  />
);

export default EventBonusPayInput;

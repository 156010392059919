import { Theme } from '@material-ui/core/styles';
import { DeepPartial } from 'react-hook-form';

export const paletteDark: DeepPartial<Theme['palette']> = {
  type: 'dark',
  avatar: {
    body: '#af52bf',
    text: '#fafafa',
  },
  logo: {
    src: 'icon.svg',
  },
  appBar: {
    light: '#2c2c2c',
    main: '#1f1f1f',
    dark: '#121212',
  },
  primary: {
    lightest: '#bc37d3',
    light: '#af2cc5',
    main: '#9c27b0',
    dark: '#89229b',
    darkest: '#771e86',
  },
  secondary: {
    lightest: '#bc37d3',
    light: '#af2cc5',
    main: '#9c27b0',
    dark: '#89229b',
    darkest: '#771e86',
  },
  info: {
    main: 'rgb(26, 188, 156)',
  },
  success: {
    main: 'rgb(46, 204, 113)',
  },
  warning: {
    lightest: '#eb9950',
    light: '#e98b39',
    main: '#e67e22',
    dark: '#d67118',
    darkest: '#bf6516',
  },
  error: {
    lightest: '#ed7669',
    light: '#ea6153',
    main: '#e74c3c',
    dark: '#e43725',
    darkest: '#d62c1a',
  },
  background: {
    default: '#2c2c2c',
    paper: '#393939',
    paper2: '#464646',
    paper3: '#525252',
    paper4: '#5f5f5f',
    paper5: '#6C6C6C',
  },
  text: {
    primary: '#ecf0f1',
    secondary: '#bdc3c7',
    disabled: 'rgba(255,255,255,0.3)',
  },
  action: {
    active: '#af52bf',
  },
  divider: 'rgba(255,255,255,0.12)',
};

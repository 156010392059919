import { Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { IconButton } from '@timed/common';
import { QueryByIdInput } from '@timed/gql';
import { MemberDeleteUnavailableFormModal } from '@timed/member/components/DeleteUnavailableFormModal';
import { useModal } from 'mui-modal-provider';
import { useState } from 'react';

type MemberUnavailableSummaryModalMenuButtonProps = {
  unavailable: QueryByIdInput;
  closeSummaryPage: () => void;
};

const MemberUnavailableSummaryModalMenuButton = ({
  unavailable,
  closeSummaryPage,
}: MemberUnavailableSummaryModalMenuButtonProps) => {
  const { showModal } = useModal();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleOpenEditEventModal = () => {
  //   const modal: { hide: () => void } = showModal(EventUpdateFormModal, {
  //     onClose: () => {
  //       modal.hide();
  //     },
  //     closeSummaryPage,
  //   });
  // };

  const handleOpenDeleteModal = () => {
    const modal: { hide: () => void } = showModal(
      MemberDeleteUnavailableFormModal,
      {
        onClose: () => {
          modal.hide();
        },
        unavailable,
      },
    );
  };

  return (
    <span>
      <IconButton aria-label="menu" onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 60 * 4.5,
            width: '20ch',
          },
        }}
      >
        {/* {!event.cancel && (
          <MenuItem
            onClick={() => {
              handleOpenEditEventModal();
              handleClose();
            }}
          >
            Edit
          </MenuItem>
        )} */}

        <MenuItem
          onClick={() => {
            handleOpenDeleteModal();
            handleClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </span>
  );
};

export default MemberUnavailableSummaryModalMenuButton;

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useAuth } from '@timed/auth';
import { Button, useRouter } from '@timed/common';
import { ModuleType } from '@timed/gql';
import { ModuleContext } from '@timed/module';
import clsx from 'clsx';
import { useCallback, useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      marginTop: theme.spacing(4),
      gap: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
        gap: theme.spacing(2),
      },
    },
    link: {
      backgroundColor: theme.palette.appBar.dark,
      color: theme.palette.getContrastText(theme.palette.appBar.main),
      borderRadius: 0,
      textTransform: 'none',

      // width: 'max-content',
      height: theme.spacing(12),
      minWidth: 'max-content',
      [theme.breakpoints.down('sm')]: {
        height: theme.spacing(10),
      },
      '& .MuiButton-label': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        // [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, 2),
        // },
      },
      '& .MuiTypography-root:first-child': {
        lineHeight: 1.1,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: '14px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '12px',
        },
      },
      '& .MuiTypography-root:last-child': {
        lineHeight: 1.1,
        fontSize: '12px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '10px',
        },
      },
    },
    inactiveLink: {
      '&:hover': {
        backgroundColor: theme.palette.appBar.light,
      },
    },
    activeLink: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }),
);

const AppBarNavigation = () => {
  const classes = useStyles();

  const { pathname, navigate } = useRouter();

  const { moduleAccess } = useAuth();

  const { activeModule, setActiveModule, redirectPaths, setRedirectPath } =
    useContext(ModuleContext);

  const handleClick = useCallback(
    (module: ModuleType) => {
      if (module !== activeModule) {
        setRedirectPath({ module: activeModule, path: pathname });
        setActiveModule(module);
        navigate(
          redirectPaths.find((redirect) => redirect.module === module)?.path ??
            '/',
        );
      }
    },
    [
      setActiveModule,
      navigate,
      activeModule,
      pathname,
      redirectPaths,
      setRedirectPath,
    ],
  );

  // Do not show navigation menu if there are fewer than 2 available options.
  if (moduleAccess.length < 2) return null;

  return (
    <Box className={classes.wrapper}>
      {moduleAccess.includes(ModuleType.CS) && (
        <Button
          className={
            activeModule === ModuleType.CS
              ? clsx(classes.activeLink, classes.link)
              : clsx(classes.inactiveLink, classes.link)
          }
          onClick={() => {
            handleClick(ModuleType.CS);
          }}
        >
          <Typography>Core</Typography>
          <Typography>Support</Typography>
        </Button>
      )}
      {moduleAccess.includes(ModuleType.SC) && (
        <Button
          className={
            activeModule === ModuleType.SC
              ? clsx(classes.activeLink, classes.link)
              : clsx(classes.inactiveLink, classes.link)
          }
          onClick={() => {
            handleClick(ModuleType.SC);
          }}
        >
          <Typography>Support</Typography>
          <Typography>Coordination</Typography>
        </Button>
      )}
    </Box>
  );
};

export default AppBarNavigation;

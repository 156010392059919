import {
  Box,
  createStyles,
  makeStyles,
  SwipeableDrawer,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import DnsOutlinedIcon from '@material-ui/icons/DnsOutlined';
import { Button } from '@timed/common';
import {
  ScheduleConflictingEvents,
  ScheduleConflictingUnavailables,
  ScheduleContext,
  ScheduleMemberInfoList2,
  ScheduleNonClockedEvents,
  ScheduleUnfilledEvents,
} from '@timed/schedule';
import { addDays, isBefore } from 'date-fns';
import { useContext, useMemo, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      minWidth: 0,
      width: theme.spacing(10),
      backgroundColor: theme.palette.background.default,
      border: '1px solid ' + theme.palette.divider,
      borderRadius: 0,
      '& .MuiSvgIcon-root': {
        color: 'rgba(0, 0, 0, 0.54)',
      },
      [theme.breakpoints.down('sm')]: {
        height: 32,
        width: theme.spacing(8),
      },
    },
    info: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    notificationBadge: {
      animation: '$flash 3000ms infinite',
      position: 'absolute',
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      right: 4,
      top: 4,
      backgroundColor: 'red',
      borderRadius: 4,
    },
    '@keyframes flash': {
      '0%, 50%, 100%': {
        opacity: 1,
      },
      '25%, 75%': {
        opacity: 0.1,
      },
    },
  }),
);

const ScheduleDrawer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [isVisible, toggleDrawer] = useState<boolean>(false);

  const {
    lists: { eventConflicts, unavailableConflicts, unallocatedEvents },
  } = useContext(ScheduleContext);

  /**
   * Toggle visibility state of drawer
   * Will not close drawer upon "Tab" and "Shift" key-down events
   */
  const handleToggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      toggleDrawer(open);
    };

  const upcomingUnallocatedEvents = useMemo(
    () =>
      unallocatedEvents?.filter(({ startAt }) =>
        isBefore(new Date(startAt), addDays(new Date(), 5)),
      ),
    [unallocatedEvents],
  );

  return (
    <>
      <Button className={classes.button} onClick={handleToggleDrawer(true)}>
        {(!!eventConflicts?.length ||
          !!unavailableConflicts?.length ||
          !!upcomingUnallocatedEvents?.length) && (
          <Box className={classes.notificationBadge} />
        )}
        <DnsOutlinedIcon fontSize="small" />
      </Button>
      <SwipeableDrawer
        open={isVisible || !smDown}
        anchor="right"
        onOpen={handleToggleDrawer(true)}
        onClose={handleToggleDrawer(false)}
        variant={'temporary'}
        ModalProps={{ keepMounted: true }}
      >
        <Box className={classes.info}>
          <ScheduleConflictingUnavailables />
          <ScheduleConflictingEvents />
          <ScheduleUnfilledEvents />
          <ScheduleNonClockedEvents />
        </Box>
        <ScheduleMemberInfoList2 />
      </SwipeableDrawer>
    </>
  );
};

export default ScheduleDrawer;

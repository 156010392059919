import { TextField, TextFieldProps } from '@timed/common';
import { phoneRegex } from '@timed/common/regexps';
import { RegisterOptions } from 'react-hook-form';

export const validatePhone: RegisterOptions = {
  pattern: { value: phoneRegex, message: 'Invalid phone number' },
  minLength: { value: 9, message: 'Too short' },
  maxLength: { value: 20, message: 'Too long' },
};

const PhoneInput = (props: TextFieldProps) => (
  <TextField label="Phone" type="tel" validation={validatePhone} {...props} />
);

export default PhoneInput;

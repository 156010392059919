import {
  InputBase,
  InputProps,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { TransformInput, validateRequired } from '@timed/common';
import clsx from 'clsx';
import { Control, Controller, RegisterOptions } from 'react-hook-form';

export type InvisibleTextFieldProps = InputProps & {
  defaultValue?: unknown;
  control?: Control<any>;
  validation?: Omit<RegisterOptions, 'required'>;
  transform?: TransformInput;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      boxSizing: 'border-box',
      '& input': {
        padding: 2,
      },
      '& input:hover': {
        boxShadow: 'inset 0 -1px 0 0 ' + theme.palette.divider,
      },
      '& input:focus': {
        boxShadow: 'inset 0 -1px 0 0 ' + theme.palette.common.black,
      },
    },
  }),
);

const InvisibleTextField = ({
  validation,
  control,
  defaultValue = '',
  transform,
  className,
  onChange: onChangeControlled,
  ...props
}: InvisibleTextFieldProps) => {
  const classes = useStyles();

  return control ? (
    <Controller
      key={props.name}
      name={props.name!}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, ...field }, fieldState: { error } }) => (
        <InputBase
          key={props.name}
          onChange={(e) => {
            !!onChangeControlled && onChangeControlled(e);
            transform ? onChange(transform.output(e)) : onChange(e);
          }}
          className={clsx(classes.input, className)}
          {...field}
          {...props}
        />
      )}
      rules={
        validation && props.required
          ? { ...validateRequired, ...validation }
          : validation
      }
    />
  ) : (
    <InputBase className={clsx(classes.input, className)} {...props} />
  );
};

export default InvisibleTextField;

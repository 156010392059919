import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
} from '@material-ui/lab';
import { TextField } from '@timed/common';
import _ from 'lodash';
import { Control, Controller } from 'react-hook-form';
import CircularProgress from '@material-ui/core/CircularProgress';

export type AutocompleteOption<T> = {
  label: string;
  value: T;
};

export type AutocompleteProps2<T> = Omit<
  MuiAutocompleteProps<AutocompleteOption<T>, false, false, false>,
  'value' | 'renderInput'
> &
  Partial<
    Pick<
      MuiAutocompleteProps<AutocompleteOption<T>, false, false, false>,
      'renderInput'
    >
  > & {
    options: AutocompleteOption<T>[] | undefined;
    name: string;
    label?: string;
    control: Control<any>;
    loading?: boolean;
  };

const Autocomplete2 = <T extends unknown>({
  name,
  label,
  defaultValue,
  options,
  renderInput,
  control,
  onChange,
  loading = false,
  ...props
}: AutocompleteProps2<T>) => (
  <Controller
    {...props}
    control={control}
    name={name}
    defaultValue={defaultValue}
    render={({ field: { ref, value, onChange, onBlur, ...field } }) => {
      if (!!onBlur) console.error('MUI Autocomplete does not support onBlur');
      return (
        <MuiAutocomplete
          loading={loading}
          {...props}
          {...field}
          id={field.name}
          key={field.name}
          value={options.find((option) => _.isEqual(option.value, value))}
          defaultValue={options.find((option) =>
            _.isEqual(option.value, defaultValue),
          )}
          onChange={(_, option) => onChange(option?.value)}
          options={options}
          getOptionLabel={(option) => option.label}
          renderInput={
            renderInput ||
            ((params) => (
              <TextField
                {...params}
                inputRef={ref}
                label={label}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            ))
          }
        />
      );
    }}
  />
);

export default Autocomplete2;

import { Select, SelectProps } from '@timed/common';
import { Salutation } from '@timed/gql';
import { camelCase, startCase } from 'lodash';
import { RegisterOptions } from 'react-hook-form';

export const validateSalutation: RegisterOptions = {
  validate: {
    validSalutation: (value) => value in Salutation || 'Invalid value',
  },
};

const SalutationInput = ({
  defaultValue,
  ...props
}: Omit<SelectProps, 'items'>) => (
  <Select
    label="Salutation"
    defaultValue={defaultValue || Salutation.NOTSPECIFIED}
    validation={validateSalutation}
    items={Object.values(Salutation).map((salutation) => {
      return { value: salutation, label: startCase(camelCase(salutation)) };
    })}
    renderValue={(value) => startCase(camelCase(value as string))}
    {...props}
  />
);

export default SalutationInput;

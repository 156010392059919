/**
 * Options object for pluralise() function.
 */
type Pluralise = {
  /**
   * Singular version of value.
   */
  singular: string;

  /**
   * Singular version of value.
   */
  plural: string;

  /**
   * Singular version of value.
   */
  quantity: number;
};

/**
 * Pluralise a string
 */
export const pluralise = ({ singular, plural, quantity }: Pluralise): string =>
  quantity === 1 ? singular : plural;

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { addServerErrors, ButtonAsync, UuidInput } from '@timed/common';
import { useProcessMemberInvitationMutation } from '@timed/gql';
import { useAuth } from '@timed/auth';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

interface FormData {
  token: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
    title: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  }),
);

const OrgJoinForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { refetch } = useAuth();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>();

  const [processInvite, response] = useProcessMemberInvitationMutation({
    onError: (errors) => addServerErrors(errors, setError),
  });

  const onSubmit = (values: FormData) =>
    processInvite({ variables: { input: values } });

  const onComplete = () => response.data && navigate('/');

  const onSuccess = () => refetch();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Typography className={classes.title}>
        Join existing organisation
      </Typography>
      <Typography variant="body1">
        Follow the invitaiton link in your welcome email or enter your token
        below.
      </Typography>
      <UuidInput
        required
        fullWidth
        name="token"
        variant="outlined"
        size="small"
        control={control}
        error={!!errors.token}
        helperText={errors.token?.message}
      />
      <ButtonAsync
        type="submit"
        color="secondary"
        size="small"
        loading={response.loading}
        success={!!response.data}
        onComplete={onComplete}
        onSuccess={onSuccess}
      >
        Join
      </ButtonAsync>
    </form>
  );
};

export default OrgJoinForm;

import { TextField, TextFieldProps } from '@timed/common';
import { RegisterOptions } from 'react-hook-form';

export const validateMemberExternalId: RegisterOptions = {
  maxLength: { value: 10, message: 'Too long' },
};

const MemberExternalIdInput = (props: TextFieldProps) => (
  <TextField
    label="External ID"
    type="text"
    validation={validateMemberExternalId}
    {...props}
  />
);

export default MemberExternalIdInput;

import {
  createTheme,
  Theme,
  ThemeProvider as MuiThemeProvider,
  useMediaQuery,
} from '@material-ui/core';
import { paletteDark, paletteLight, themeBase } from '@timed/common';
import { PaletteTypes, ThemeContext } from '@timed/theme';
import { useState } from 'react';
import { DeepPartial } from 'react-hook-form';

type ThemeProviderProps = React.PropsWithChildren<{}>;

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const prefersDarkPalette = useMediaQuery('(prefers-color-scheme: dark)');

  const [palette, setPalette] = useState<PaletteTypes>(
    (localStorage.getItem('theme') as PaletteTypes) ||
      (prefersDarkPalette && 'dark') ||
      'light',
  );

  const paletteMap: { [key: string]: DeepPartial<Theme['palette']> } = {
    dark: paletteDark,
    light: paletteLight,
  };

  const handleSetPalette = (palette: PaletteTypes) => {
    setPalette(palette);
    localStorage.setItem('theme', palette);
  };

  return (
    <ThemeContext.Provider value={{ palette, setPalette: handleSetPalette }}>
      <MuiThemeProvider<Theme>
        theme={createTheme(themeBase, { palette: paletteMap[palette] })}
      >
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;

import { createStyles, makeStyles, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  addServerErrors,
  formatPersonName,
  FormModal,
  IconButton,
  ModalProps,
  Textarea,
} from '@timed/common';
import {
  Client,
  ClientNote,
  CreateClientNoteDocument,
  Event,
  PersonNamesFragment,
  useCreateClientNoteMutation,
  useUpdateClientNoteMutation,
} from '@timed/gql';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

type EventUpdateCaseNotesFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  event: Pick<Event, 'id' | 'startAt'> & {
    client: Pick<Client, 'id'> & PersonNamesFragment;
  };
  note?: Pick<ClientNote, 'id' | 'comment'>;
};

type FormData = { comment: ClientNote['comment'] };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textarea: {
      margin: 0,
      padding: theme.spacing(2),
      overflowY: 'auto',
      outline: 'none',
      resize: 'none',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      border: '1px solid ' + theme.palette.text.disabled,
      borderRadius: theme.shape.borderRadius,
      fontFamily: 'inherit',
      WebkitTextFillColor: theme.palette.text.primary, // required for iOS
      opacity: 1, // required for iOS
      [theme.breakpoints.up('md')]: {
        width: 600,
        height: 240,
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 1px)',
        height: 'calc(100% - 1px)',
        fontSize: 16,
      },
    },
  }),
);

const EventUpdateCaseNotesFormModal = ({
  onClose,
  event,
  note,
  ...modalProps
}: EventUpdateCaseNotesFormModalProps) => {
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { handleSubmit, control, setError } = useForm<FormData>({
    defaultValues: { comment: note?.comment },
  });

  const [createNotes, createNotesResponse] = useCreateClientNoteMutation({
    onCompleted: () => {
      enqueueSnackbar('Case notes recorded', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon fontSize="small" style={{ fill: 'white' }} />
          </IconButton>
        ),
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.message ?? 'Error recording case notes', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon style={{ fill: 'white' }} />
          </IconButton>
        ),
      });
    },
  });

  const [updateNotes, updateNotesResponse] = useUpdateClientNoteMutation({
    onCompleted: () => {
      enqueueSnackbar('Case notes updated', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon fontSize="small" style={{ fill: 'white' }} />
          </IconButton>
        ),
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.message ?? 'Error updating case notes', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon style={{ fill: 'white' }} />
          </IconButton>
        ),
      });
    },
  });

  const [data, loading, error] = useMemo(() => {
    return [
      createNotesResponse.data || updateNotesResponse.data,
      createNotesResponse.loading || updateNotesResponse.loading,
      createNotesResponse.error || updateNotesResponse.error,
    ];
  }, [
    createNotesResponse.data,
    createNotesResponse.loading,
    createNotesResponse.error,
    updateNotesResponse.data,
    updateNotesResponse.loading,
    updateNotesResponse.error,
  ]);

  useEffect(() => error && addServerErrors(error, setError), [error, setError]);

  const onSuccess = () => {
    // Update cache if note was created
    if (!note) {
      const cache = updateNotesResponse.client.cache;

      cache.modify({
        fields: {
          clientNotes(existing = []) {
            return [
              ...existing,
              cache.writeQuery({ data, query: CreateClientNoteDocument }),
            ];
          },
        },
      });
    }
  };

  const onSubmit = ({ comment }: FormData) => {
    if (!!note) {
      updateNotes({
        variables: {
          input: {
            id: note.id,
            patch: { comment },
          },
        },
      });
    } else
      createNotes({
        variables: {
          input: {
            comment,
            commentedAt: new Date(),
            existingEvent: { id: event.id },
            client: { id: event.client.id },
          },
        },
      });
  };

  return (
    <FormModal
      modalProps={modalProps}
      title="Edit case notes"
      description={`Shift starting ${format(
        new Date(event.startAt),
        'h:mma',
      ).toLocaleLowerCase()} on the ${format(
        new Date(event.startAt),
        "do 'of' MMM",
      )} with participant ${formatPersonName(event.client)}`}
      loading={loading}
      success={!!data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Textarea
        minRows={20}
        name="comment"
        control={control}
        placeholder="Notes"
        className={classes.textarea}
        style={{ height: '', overflow: '' }}
        validation={{
          maxLength: { value: 10000, message: 'Exceeding 10,000 characters' },
        }}
      />
    </FormModal>
  );
};

export default EventUpdateCaseNotesFormModal;

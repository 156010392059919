import { ButtonAsync, ButtonProps } from '@timed/common';
import {
  Client,
  CreateClientObservationDocument,
  Event,
  Member,
  useCreateClientObservationMutation,
  useCreateClientObservationWithEventMutation,
} from '@timed/gql';
import { isAfter, isBefore } from 'date-fns';
import { useMemo, useRef, useState } from 'react';

type ClientCreateFoodButtonProps = ButtonProps & {
  itemName: string;
  itemKilojoules: number;
  clientId: Client['id'];
  event: Pick<Event, 'id' | 'startAt' | 'endAt'> & {
    member?: Pick<Member, 'id'> | null;
  };
};

const ClientCreateFoodButton = ({
  clientId,
  event,
  disabled,
  itemName,
  itemKilojoules,
  ...buttonProps
}: ClientCreateFoodButtonProps) => {
  const timer = useRef<number>();

  const [disableButton, setDisableButton] = useState<boolean>(false);

  const [createObservation, responseWithoutEvent] =
    useCreateClientObservationMutation();
  const [createObservationWithEvent, responseWithEvent] =
    useCreateClientObservationWithEventMutation();

  const calcDate = () => {
    const now: Date = new Date();
    let date: Date = now;

    // Recording obs before event has started
    if (!!event && isBefore(now, new Date(event.startAt))) {
      date = new Date(event.startAt);
    }

    // Recording obs after event has finished
    if (!!event && isAfter(now, new Date(event.endAt))) {
      date = new Date(event.endAt);
    }

    return date;
  };

  const response = useMemo(
    () => (!!event ? responseWithEvent : responseWithoutEvent),
    [event, responseWithoutEvent, responseWithEvent],
  );

  const onSuccess = () => {
    const cache = response.client.cache;

    cache.modify({
      fields: {
        clientObservations(existing = []) {
          return [
            ...existing,
            cache.writeQuery({
              data: response.data,
              query: CreateClientObservationDocument,
            }),
          ];
        },
      },
    });

    setDisableButton(true);

    timer.current = window.setTimeout(() => {
      // Timout for 5 minutes
      setDisableButton(false);
    }, 300000);
  };

  return (
    <ButtonAsync
      loading={response.loading}
      success={!!response.data}
      onSuccess={onSuccess}
      disabled={disabled || disableButton}
      onClick={() => {
        if (event)
          createObservationWithEvent({
            variables: {
              input: {
                date: calcDate(),
                event: { id: event.id },
                givenFood: {
                  objects: [
                    { description: itemName, kilojoules: itemKilojoules },
                  ],
                },
              },
            },
          });
        else
          createObservation({
            variables: {
              input: {
                date: calcDate(),
                client: { id: clientId! },
                givenFood: {
                  objects: [
                    { description: itemName, kilojoules: itemKilojoules },
                  ],
                },
              },
            },
          });
      }}
      {...buttonProps}
    >
      {itemName}
    </ButtonAsync>
  );
};

export default ClientCreateFoodButton;

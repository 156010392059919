import { TextField, TextFieldProps } from '@timed/common';
import { organisationNameRegex } from '@timed/common/regexps';
import { RegisterOptions } from 'react-hook-form';

export const validateBranchName: RegisterOptions = {
  pattern: { value: organisationNameRegex, message: 'Invalid character(s)' },
  maxLength: { value: 100, message: 'Too long' },
};

const BranchNameInput = (props: TextFieldProps) => (
  <TextField type="text" validation={validateBranchName} {...props} />
);

export default BranchNameInput;

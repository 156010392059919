import {
  Box,
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { _schedule } from '@timed/app';
import {
  Button,
  DateInput,
  formatPersonName,
  NumberInput,
  Select,
  useRouter,
} from '@timed/common';
import {
  Client,
  Event,
  EventFilter,
  OrderBy,
  useShiftsGetEventsShouldNotHaveTravelLazyQuery,
  useShiftsGetEventsWithTravelLazyQuery,
  useUpdateEventMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { setProfile } from '@timed/schedule';
import {
  addDays,
  format,
  startOfToday,
  startOfWeek,
  subDays,
  subWeeks,
} from 'date-fns';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
  period: 'previous' | 'current' | 'custom' | undefined;
  from?: Date;
  to?: Date;
  events: { [k: string]: Pick<Event, 'travelTime'> };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    controls: {
      width: 300,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    dateInput: {
      display: 'flex',
      gap: theme.spacing(4),
    },

    title: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    shifts: {
      display: 'flex',
      flexWrap: 'wrap',

      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(4),
        // gridTemplateColumns: 'auto',
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(8),
        // gridTemplateColumns: 'repeat(5, 200px)',
      },
    },
    shift: {
      display: 'grid',
      gridTemplateColumns: '48px auto',
      // gap: theme.spacing(4),
      // width: 'max-content',
      // padding: theme.spacing(4),

      borderRadius: theme.shape.borderRadius,
      border: '1px solid ' + theme.palette.divider,
    },
    date: {
      padding: theme.spacing(1.5),
      backgroundColor: grey[500],
      color: theme.palette.common.white,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0),
      alignItems: 'center',
      justifyItems: 'start',
      '& .MuiTypography-root': {
        fontWeight: theme.typography.fontWeightMedium,
      },
      '& .MuiTypography-root:first-child': {
        fontSize: 24,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    info: {
      padding: theme.spacing(1.5),
      backgroundColor: grey[100],
    },
    infoLine: {
      display: 'grid',
      gridTemplateColumns: 'min-content auto',
      gap: theme.spacing(2),
    },
    missingTravel: {
      display: 'inline-grid',
      alignItems: 'center',
      gridTemplateColumns: 'repeat(4, max-content)',
      columnGap: theme.spacing(8),
      rowGap: theme.spacing(1),
    },
    hasTravel: {
      display: 'inline-grid',
      alignItems: 'center',
      gridTemplateColumns: 'repeat(5, max-content)',
      columnGap: theme.spacing(8),
      rowGap: theme.spacing(1),
    },
    actions: {
      display: 'flex',
      gap: theme.spacing(2),
    },
    startDate: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0),
      '& .MuiTypography-root:first-child': {
        fontSize: 14,
      },
      '& .MuiTypography-root:last-child': {
        fontSize: 10,
      },
    },
    previousShift: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0),
      '& .MuiTypography-root': {
        fontSize: 10,
      },
      '& .MuiTypography-root:first-child': {
        fontSize: 14,
      },
    },
  }),
);

const ShiftsTravel = () => {
  const classes = useStyles();

  const WEEK_STARTS_ON = 1; // MONDAY

  const {
    navigate,
    search: [searchParams],
  } = useRouter();

  const { control, watch, setValue, getValues } = useForm<FormData>();

  const period = watch('period');
  const from = watch('from');
  const to = watch('to');

  const [getEventsWithTravel, eventsWithTravelResponse] =
    useShiftsGetEventsWithTravelLazyQuery();
  const [getEventsShouldHaveTravel, eventsShouldHaveTravelResponse] =
    useShiftsGetEventsWithTravelLazyQuery();
  const [getEventsShouldNotHaveTravel, eventsShouldNotHaveTravelResponse] =
    useShiftsGetEventsShouldNotHaveTravelLazyQuery();

  const [updateEvent] = useUpdateEventMutation();

  const loading =
    eventsWithTravelResponse.loading ||
    eventsShouldHaveTravelResponse.loading ||
    eventsShouldNotHaveTravelResponse.loading;

  useLoadingEffect(loading);

  useEffect(() => {
    if (period) {
      switch (period) {
        case 'current':
          setValue(
            'from',
            startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
          );
          setValue(
            'to',
            addDays(
              startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
              6,
            ),
          );
          break;
        case 'previous':
          setValue(
            'from',
            subWeeks(
              startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
              1,
            ),
          );
          setValue(
            'to',
            subDays(
              startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
              1,
            ),
          );
          break;
        case 'custom':
          setValue('from', startOfToday());
          setValue('to', startOfToday());
          break;
      }
    }
  }, [period, setValue]);

  const periods = [
    { label: 'Most recently ended payroll period', value: 'previous' },
    { label: 'Current payroll period', value: 'current' },
    { label: 'Custom date range', value: 'custom' },
  ];

  const fetchData = useCallback(() => {
    if (!!from && !!to) {
      // Fetch events with existing travel time.
      getEventsWithTravel({
        variables: {
          input: {
            where: {
              startAt: {
                _gte: from,
                _lt: to,
              },
              travelTime: { _ne: 0 },
            },
            orderBy: [
              { startAt: OrderBy.ASC },
              { client: { lastName: OrderBy.ASC } },
              { client: { firstName: OrderBy.ASC } },
              { createdAt: OrderBy.ASC },
            ],
          },
        },
      });

      // Fetch events which should have travel time.
      getEventsShouldHaveTravel({
        variables: {
          input: {
            entityFilters: [EventFilter.SHOULD_HAVE_TRAVEL_TIME],
            where: {
              member: { id: { _ne: null } },
              // travelTime: { _eq: 0 },
              startAt: {
                _gte: from,
                _lt: addDays(to, 1),
              },
            },
            orderBy: [
              { startAt: OrderBy.ASC },
              { client: { lastName: OrderBy.ASC } },
              { client: { firstName: OrderBy.ASC } },
              { createdAt: OrderBy.ASC },
            ],
          },
        },
      });

      // Fetch events which have travel time but should not.
      getEventsShouldNotHaveTravel({
        variables: {
          input: {
            entityFilters: [EventFilter.SHOULD_NOT_HAVE_TRAVEL_TIME],
            where: {
              member: { id: { _ne: null } },
              // travelTime: { _ne: 0 },
              startAt: {
                _gte: from,
                _lt: addDays(to, 1),
              },
            },
          },
        },
      });
    }
  }, [
    getEventsWithTravel,
    getEventsShouldHaveTravel,
    getEventsShouldNotHaveTravel,
    from,
    to,
  ]);

  const onSubmit = (id: string, time?: string) => {
    if (time && !!Number(time))
      updateEvent({
        variables: {
          input: {
            id,
            patch: {
              travelTime: Number(time),
            },
          },
        },
      });
  };

  const handleNavigate = useCallback(
    (event: Pick<Event, 'startAt'> & { client: Pick<Client, 'id'> }) => {
      setProfile('client', searchParams, event.client.id);
      setProfile('member', searchParams, undefined);

      const formattedDate = format(
        startOfWeek(new Date(event.startAt), { weekStartsOn: 1 }),
        'ddMMyyyy',
      );

      if (searchParams.get('f') !== formattedDate) {
        searchParams.set('f', formattedDate);
      }

      navigate(_schedule.path + '?' + searchParams);
    },
    [navigate, searchParams],
  );

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.controls}>
        <Select
          control={control}
          name="period"
          label="Reporting period"
          items={periods}
          formControlProps={{
            variant: 'outlined',
            size: 'small',
            style: { gridColumn: 'Span 2' },
          }}
        />
        {period && (
          <Box className={classes.dateInput}>
            <DateInput
              disableToolbar
              autoOk
              control={control}
              name="from"
              label="From start of day"
              inputVariant="outlined"
              size="small"
              disabled={period === 'current' || period === 'previous'}
            />
            <DateInput
              disableToolbar
              autoOk
              control={control}
              name="to"
              label="To end of day"
              inputVariant="outlined"
              size="small"
              disabled={period === 'current' || period === 'previous'}
            />
          </Box>
        )}
        <Button
          variant="contained"
          size="small"
          color="primary"
          style={{ width: 'max-content' }}
          onClick={() => {
            fetchData();
          }}
          disabled={!period || !from || !to}
        >
          Load Shifts
        </Button>
      </Box>
      <Divider />

      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        (!!eventsWithTravelResponse?.data?.events.length ||
          !!eventsShouldHaveTravelResponse?.data?.events.length) && (
          <>
            {eventsShouldHaveTravelResponse.data && (
              <>
                <Typography className={classes.bold} variant="h3">
                  Shifts Which Should Have Travel
                </Typography>
                {!eventsShouldHaveTravelResponse.data.events.length ? (
                  <Typography>None found.</Typography>
                ) : (
                  <Box className={classes.missingTravel}>
                    <Typography className={classes.bold}>
                      Participant
                    </Typography>
                    <Typography className={classes.bold}>Attendee</Typography>
                    <Typography className={classes.bold}>
                      Shift Start
                    </Typography>
                    <Typography className={classes.bold}>
                      Set Travel Minutes
                    </Typography>
                    {eventsShouldHaveTravelResponse.data.events.map((event) => (
                      <>
                        <Typography>
                          {formatPersonName(event.client, {
                            capitaliseLastName: true,
                            lastNameFirst: true,
                          })}
                        </Typography>
                        <Typography>
                          {formatPersonName(event.member!, {
                            capitaliseLastName: true,
                            lastNameFirst: true,
                          })}
                        </Typography>
                        <Box className={classes.startDate}>
                          <Typography>
                            {format(new Date(event.startAt), 'EEEE')}
                          </Typography>
                          <Typography>
                            {format(
                              new Date(event.startAt),
                              'dd/MM/yyyy HH:mm',
                            )}
                          </Typography>
                        </Box>
                        <Box style={{ display: 'flex', gap: 8 }}>
                          <NumberInput
                            defaultValue={30}
                            variant="outlined"
                            size="small"
                            control={control}
                            name={'events.' + event.id + '.travelTime'}
                          />
                          <Button
                            onClick={() => {
                              onSubmit(
                                event.id,
                                getValues(
                                  ('events.' + event.id + '.travelTime') as any,
                                ),
                              );
                            }}
                            variant="contained"
                            size="small"
                          >
                            Save
                          </Button>
                          <Button
                            onClick={() => {
                              handleNavigate(event);
                            }}
                            variant="contained"
                            size="small"
                          >
                            Go To Shift
                          </Button>
                        </Box>
                      </>
                    ))}
                  </Box>
                )}
              </>
            )}
            {eventsWithTravelResponse.data && (
              <>
                <Divider />
                <Typography className={classes.bold} variant="h3">
                  Shifts With Travel
                </Typography>
                {!eventsWithTravelResponse.data.events.length ? (
                  <Typography>None found.</Typography>
                ) : (
                  <Box className={classes.hasTravel}>
                    <Typography className={classes.bold}>
                      Participant
                    </Typography>
                    <Typography className={classes.bold}>Attendee</Typography>
                    <Typography className={classes.bold}>
                      Shift Start
                    </Typography>
                    <Typography className={classes.bold}>
                      Travel Minutes
                    </Typography>
                    <Typography className={classes.bold}>Validity</Typography>
                    {eventsWithTravelResponse.data.events.map((event) => (
                      <>
                        <Typography>
                          {formatPersonName(event.client, {
                            capitaliseLastName: true,
                            lastNameFirst: true,
                          })}
                        </Typography>
                        <Typography>
                          {formatPersonName(event.member!, {
                            capitaliseLastName: true,
                            lastNameFirst: true,
                          })}
                        </Typography>
                        <Box className={classes.startDate}>
                          <Typography>
                            {format(new Date(event.startAt), 'EEEE')}
                          </Typography>
                          <Typography>
                            {format(
                              new Date(event.startAt),
                              'dd/MM/yyyy HH:mm',
                            )}
                          </Typography>
                        </Box>
                        <Typography>{event.travelTime}</Typography>
                        {!eventsShouldNotHaveTravelResponse.data?.events
                          .map(({ id }) => id)
                          .includes(event.id) ? (
                          <Typography>OK</Typography>
                        ) : (
                          <Typography>Should not have travel</Typography>
                        )}
                      </>
                    ))}
                  </Box>
                )}
              </>
            )}
          </>
        )
      )}
    </Box>
  );
};

export default ShiftsTravel;

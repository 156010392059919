import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Protected } from '@timed/auth';
import { PaginationLimitInput } from '@timed/common';
import { Permission } from '@timed/gql';
import {
  NotesContext,
  NotesControlAuthorInput,
  NotesControlClearButton,
  NotesControlClientInput,
  NotesControlCreatorInput,
  NotesControlMatchesKeywordsInput,
  NotesControlReloadButton,
  NotesControlSearchInput,
  NotesControlSettingsButton,
  NotesControlStateInput,
  NotesControlTypeInput,
  NotesCreateNoteButton,
  NotesExportButton,
  NotesImportButton,
} from '@timed/notes';
import clsx from 'clsx';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
        padding: theme.spacing(1),
      },
    },
    group: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
    },
    controls: {
      [theme.breakpoints.down('sm')]: {
        flexGrow: 1,
      },
    },
  }),
);

const NotesControls = () => {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    limit,
    limits,
    setLimit,
    input: { showImporter },
  } = useContext(NotesContext);

  return (
    <div className={classes.wrapper}>
      <div className={clsx(classes.group, classes.controls)}>
        <NotesControlReloadButton />
        {!smDown && <NotesControlClearButton />}
        {/* <NotesControlDateRangeInput /> */}
        <NotesControlClientInput />
        <Protected permissions={Permission.MEMBER_READ}>
          <NotesControlAuthorInput />
        </Protected>

        {!smDown && showImporter && <NotesControlCreatorInput />}
        <NotesControlSearchInput />
        <NotesControlMatchesKeywordsInput />
        <NotesControlTypeInput />
        <Protected admin>{!smDown && <NotesControlStateInput />}</Protected>
        <PaginationLimitInput
          limit={limit}
          limits={limits!}
          setLimit={setLimit!}
        />
        {smDown && (
          <>
            <Protected permissions={Permission.CLIENT_WRITE}>
              <NotesCreateNoteButton />
            </Protected>
            <Protected permissions={Permission.CLIENT_WRITE}>
              <NotesImportButton />
            </Protected>
            {/* <Protected tester>
              <NotesControlNotificationsButton />
            </Protected> */}
            <Protected permissions={Permission.CLIENT_WRITE}>
              <NotesControlSettingsButton />
            </Protected>
          </>
        )}
      </div>
      {!smDown && (
        <div className={classes.group}>
          <Protected permissions={Permission.CLIENT_WRITE}>
            <NotesCreateNoteButton />
          </Protected>
          <Protected permissions={Permission.CLIENT_READ}>
            <NotesImportButton />
          </Protected>
          <Protected permissions={Permission.CLIENT_READ}>
            <NotesExportButton />
          </Protected>
          {/* <Protected permissions={Permission.CLIENT_WRITE}>
            <NotesControlNotificationsButton />
          </Protected> */}
          <Protected permissions={Permission.CLIENT_WRITE}>
            <NotesControlSettingsButton />
          </Protected>
        </div>
      )}
    </div>
  );
};

export default NotesControls;

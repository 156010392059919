import { useTheme } from '@material-ui/core';
import {
  Document,
  Font,
  Image,
  Link,
  Page,
  Path,
  StyleSheet,
  Svg,
  Text,
  View,
} from '@react-pdf/renderer';
import { roundNumber } from '@timed/common';
import { formatCurrency } from '@timed/common/utils/formatCurrency';
import { format } from 'date-fns';

type SupportWorkerEmploymentAgreementProps = {
  values: {
    firstName: string;
    middleName?: string | null;
    lastName?: string | null;
    dear: string;
    address: string;
    email: string;
    payRate: number;
    employmentStartDate: Date;
    agreeDate: Date;
    signature: string;
  };
};

export const SupportWorkerEmploymentAgreement = ({
  values,
}: SupportWorkerEmploymentAgreementProps) => {
  const theme = useTheme();

  const {
    firstName,
    middleName,
    lastName,
    dear,
    address,
    email,
    payRate,
    employmentStartDate,
    agreeDate,
    signature,
  } = values;

  const styles = StyleSheet.create({
    page: {
      padding: theme.spacing(8),
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      fontSize: 10,
      fontFamily: 'Roboto',
    },
    contentWrapper: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    pageFooter: {
      fontSize: 8,
      textAlign: 'center',
    },
    content: {
      height: '100%',
      border: '3px solid #6d2d84',
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    bold: {
      fontWeight: 'bold',
    },
    italic: {},
    header: {
      fontSize: 22,
    },
    subHeader: {
      fontSize: 16,
      fontWeight: 'light',
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    inputWrapper: {
      flex: '1 0 auto',
      display: 'flex',
      textAlign: 'left',
      gap: theme.spacing(0.5),
    },
    input: {
      border: '1 solid lightgrey',
      padding: theme.spacing(0.5),
    },
    logo: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      textAlign: 'center',
      margin: '20% 0',
    },
    footer: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(8),
    },
    checkbox: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
    },
    checkboxLabel: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
  });

  Font.register({
    family: 'Roboto',
    fonts: [
      {
        src: 'https://cdnjs.cloudflare.com/ajax/libs/materialize/0.98.1/fonts/roboto/Roboto-Light.ttf',
        fontWeight: 'light',
      },
      {
        src: 'https://cdnjs.cloudflare.com/ajax/libs/materialize/0.98.1/fonts/roboto/Roboto-Regular.ttf',
        fontWeight: 'normal',
      },
      {
        src: 'https://cdnjs.cloudflare.com/ajax/libs/materialize/0.98.1/fonts/roboto/Roboto-Bold.ttf',
        fontWeight: 'bold',
      },
      {
        src: 'https://cdnjs.cloudflare.com/ajax/libs/materialize/0.98.1/fonts/roboto/Roboto-Italic.ttf',
        fontStyle: 'italic',
      },
    ],
  });

  return (
    <Document title="Australian Carers - Support Worker Employment Agreement">
      <Page size="A4" style={styles.page}>
        <View style={styles.contentWrapper}>
          <View style={styles.content}>
            <View style={styles.logo}>
              <Image
                src="/australian-carers-logo-full2.jpg"
                style={{ width: '40%', margin: '0 auto' }}
              />
              <Text style={styles.header}>
                Support Worker Employment Agreement
              </Text>
              <Text style={styles.subHeader}>Private and Confidential</Text>
            </View>
            <View style={styles.section}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: theme.spacing(2),
                }}
              >
                <View style={styles.inputWrapper}>
                  <Text style={styles.bold}>First Name</Text>
                  <Text style={styles.input}>{firstName}</Text>
                </View>
                <View style={styles.inputWrapper}>
                  <Text style={styles.bold}>Middle Name</Text>
                  <Text style={styles.input}>{middleName || ' '}</Text>
                </View>
                <View style={styles.inputWrapper}>
                  <Text style={styles.bold}>Last Name</Text>
                  <Text style={styles.input}>{lastName || ' '}</Text>
                </View>
              </View>
              <View style={styles.inputWrapper}>
                <Text style={styles.bold}>Home Address</Text>
                <Text style={styles.input}>{address}</Text>
              </View>
              <View style={styles.inputWrapper}>
                <Text style={styles.bold}>Email Address</Text>
                <Text style={styles.input}>{email}</Text>
              </View>
            </View>
          </View>
          <Text style={styles.pageFooter}>Page 1</Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.contentWrapper}>
          <View style={styles.content}>
            <Text>Dear {dear},</Text>
            <View style={styles.section}>
              <Text style={styles.bold}>Employment Agreement</Text>
              <Text>
                Congratulations and welcome to Australian Carers. We are pleased
                to offer you employment on the terms and conditions set out in
                this letter, subject to the successful completion of your
                probationary period.
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.bold}>Position</Text>
              <Text>
                You are employed by Australian Carers Pty Limited ABN 95 633 325
                854. You are engaged in the position of Support Worker (Level 1)
                (Casual) and you may subsequently be engaged in other roles as
                determined by Australian Carers, either on a permanent basis or
                from time to time as Australian Carers requires. Such other
                roles will be in line with your skills and abilities. Your
                employment in this position (under the terms and conditions of
                this employment Agreement) will commence on:
              </Text>
              <Text style={styles.input}>
                {format(employmentStartDate, 'dd/MM/yyyy')}
              </Text>
              <Text>
                or as otherwise agreed between yourself and Australian Carers.
              </Text>
            </View>

            <View style={styles.section}>
              <Text>
                Your employment in this position will be on a casual basis.
              </Text>
            </View>
            <View style={styles.section}>
              <Text>
                The duties of this position are set out in the{' '}
                <Text style={styles.bold}>
                  position description that has been electronically sent to you
                </Text>
              </Text>
            </View>
            <View style={styles.section}>
              <Text>
                You will be required to perform these duties, and any other
                duties that Australian Carers may reasonably assign to you
                (which may include duties for the benefit of Australian Carers
                and any related body corporate, as defined in the Corporations
                Act 2001, of Australian Carers (Group Company or Group
                Companies) or entities associated with the directors of
                Australian Carers.
              </Text>
            </View>
            <View style={styles.section}>
              <Text>
                You may, as reasonably required by Australian Carers, be
                redeployed into other positions and/or locations within the
                organisation, having regard to your performance, skills,
                training and experience. If your position, duties, title or
                reporting structure changes during your employment, this
                Agreement continues to apply unless you and an authorised
                representative of Australian Carers enter into a new written
                employment Agreement or vary this Agreement in writing.
              </Text>
            </View>
            <View style={styles.section}>
              <Text>
                Without in any way limiting Australian Carers rights under this
                Agreement, Australian Carers may, in circumstances of
                significant or ongoing poor performance and at the sole
                discretion of Australian Carers, remove you from your position
                and assign you to another position within the organisation with
                regard to your skills, training and experience.
              </Text>
            </View>
            <View style={styles.section}>
              <Text>
                You will report directly to your State Manager or any other
                person nominated by Australian Carers from time to time. You
                also report to Australian Carers Chief Executive Officer and
                General Manager, who may at any time, direct and require you to
                comply with their reasonable and lawful instructions.
              </Text>
            </View>
          </View>
          <Text style={styles.pageFooter}>Page 2</Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.contentWrapper}>
          <View style={styles.content}>
            <View style={styles.section}>
              <Text style={styles.bold}>Probation Period</Text>
              <Text>
                A probation period will apply for the first six months of your
                employment. During this time, Australian Carers will assess your
                progress and performance in the position.
              </Text>
              <Text>
                During the probation period, you or Australian Carers may end
                your employment for any reason by providing notice in accordance
                with the 'Termination of Employment' clause below.
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.bold}>
                Terms and conditions of employment
              </Text>
              <Text>
                In addition to this Agreement, your employment will be governed
                by the National Employment Standards (
                <Text style={styles.bold}>NES</Text>) as set out in the Fair
                Work Act 2009 (<Text style={styles.bold}>Act</Text>), as amended
                from time to time.
              </Text>
              <Text>
                Your position is also covered by the Social, Community, Home
                Care and Disability Services Industry Award 2010 (
                <Text style={styles.bold}>Award</Text>), as amended.
              </Text>
              <Text>
                A copy of the Award is available for you to review from here:{' '}
                <Link src="https://awardviewer.fwo.gov.au/award/show/MA000100#P289_23916">
                  https://awardviewer.fwo.gov.au/award/show/MA000100#P289_23916
                </Link>
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.bold}>Ordinary hours of work</Text>
              <Text>
                You agree to work shifts of up to 10 ordinary hours in duration.
              </Text>
              <Text>
                You agree to work up to the maximum allowable amount of broken
                shifts.
              </Text>
              <Text>
                You may be required to work reasonable additional hours as are
                necessary to fulfil your duties or as otherwise required by
                Australian Carers. You will be paid overtime in accordance with
                the Award.
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.bold}>Remuneration</Text>
              <Text>
                Your position will be classified as Level 1 Social and Community
                Services Employee, (Pay Point 1) in accordance with the Award.
              </Text>
              <Text>
                You will be paid at a rate of{' '}
                {formatCurrency(roundNumber(payRate / 100, 2))} per hour which
                includes the 25% loading for casual employees. This rate is
                applicable Monday to Friday for work performed between 6am and
                8pm, higher rates are paid outside of these hours and for
                weekends and public holidays
              </Text>
              <Text>
                You are also entitled to shift and other allowances/penalties
                pursuant to the Award.
              </Text>
              <Text>
                Australian Carers will make the minimum superannuation
                contribution that it is required to make under the
                superannuation guarantee legislation on your behalf. Subject to
                applicable law, Australian Carers will make superannuation
                contributions into an eligible fund of your choice. If you do
                not specify an eligible fund, Australian Carers will make
                contributions to its default fund.
              </Text>
              <Text>
                Wages will be deposited every four weeks on Thursday into your
                nominated bank account. Australian Carers reserves the right to
                change the day of the week or the frequency with which wage
                payments are made.
              </Text>
            </View>
          </View>
          <Text style={styles.pageFooter}>Page 3</Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.contentWrapper}>
          <View style={styles.content}>
            <View style={styles.section}>
              <Text>
                This agreement includes the provision for averaging of work
                hours over the four week period.
              </Text>
              <Text>
                You agree that Australian Carers is entitled to deduct from you:
              </Text>
              <Text>any overpayment of monies;</Text>
              <Text>
                any amount that Australian Carers is legally obliged to deduct;
              </Text>
              <Text>
                any amount in respect of which you have provided prior written
                authority; and
              </Text>
              <Text>
                any amount authorized by or under a law of the Commonwealth, a
                State or a Territory, or an order of a court.
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.bold}>Leave</Text>
              <Text>
                As a casual employee you are not entitled to paid leave. Please
                refer to the NES.
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.bold}>
                Your obligations to Australian Carers
              </Text>
              <Text>
                For the purpose of this clause, Australian Carers IT Systems
                includes all electronic data, hardware, software, networks and
                other systems owned, controlled or licensed by Australian
                Carers.
              </Text>
              <Text>
                Without limiting your duties at law or in equity, during your
                employment you must:
              </Text>
              <Text>
                perform all duties to the best of your ability at all times;
              </Text>
              <Text>
                devote your whole time and attention to the duties assigned to
                you during your ordinary hours of work and any additional hours
                necessary for the performance of those duties;
              </Text>
              <Text>
                use your best endeavours to promote and protect the interests of
                Australian Carers and the Group Companies;
              </Text>
              <Text>
                hold, maintain, update and ensure the currency of your licenses
                (such as a driver's license), certifications and qualifications
                required or necessary to lawfully perform your role;
              </Text>
              <Text>
                notify Australian Carers promptly of any accident, illness or
                injury (and anticipated recovery period) or any other matter
                preventing you, temporarily or permanently, from performing your
                duties and responsibilities under this Agreement;
              </Text>
              <Text>
                comply with all relevant occupational health and safety
                legislation;
              </Text>
              <Text>
                follow all reasonable and lawful directions given to you by
                Australian Carers, including complying in all respects with
                Australian Carers Code of Conduct, policies, procedures and
                rules as amended from time to time and meet all training and
                professional development requirements. These policies,
                procedures and rules are not contained in this Agreement. Copies
                of all current codes, policies, procedures and rules will be
                provided to you during any induction or alternatively, are
                available upon request to your supervisor;
              </Text>
              <Text>
                seek and obtain approval from Australian Carers to engage in
                secondary employment (including self- employment) and notify
                Australian Carers of any actual, potential or perceived conflict
                of interest between your employment with Australian Carers and
                any other employer; and
              </Text>
            </View>
          </View>
          <Text style={styles.pageFooter}>Page 4</Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.contentWrapper}>
          <View style={styles.content}>
            <View style={styles.section}>
              <Text>
                disclose to Australian Carers any facts, information or
                circumstances (including suspicions, concepts or ideas) which
                may give rise to a conflict between your interests and the
                interests of a Group Company.
              </Text>
              <Text>
                Without limiting your duties, during your employment you must
                not:
              </Text>
              <Text>
                act in conflict with the best interests of, or compete with, a
                Group Company;
              </Text>
              <Text>
                prepare to be engaged or engage in any business or employment
                other than for a Group Company except with the prior written
                consent of Australian Carers;
              </Text>
              <Text>
                in performing your duties, accept any financial or other benefit
                except from Australian Carers;
              </Text>
              <Text>
                do anything which does or may, in Australian Carers opinion,
                damage the reputation of a Group Company;
              </Text>
              <Text>
                do anything which infringes Australian Carer’s IP as defined
                below;
              </Text>
              <Text>
                do anything which infringes any applicable occupational health
                and safety legislation;
              </Text>
              <Text>
                use Australian Carers IT Systems for excessive or unreasonable
                personal use; to view or distribute unlawful material, or
                material which may be regarded as offensive or inappropriate; or
                to copy, disclose or use material in breach of this Agreement;
                or
              </Text>
              <Text>
                unlawfully discriminate against, bully or sexually harass
                another person or otherwise fail to comply with any laws
                applying to your employment.
              </Text>
              <Text>
                An employee engaging in any one or more of the abovementioned
                behaviours will be deemed to have engaged in serious and wilful
                misconduct and may be temporarily suspended from all duties and
                that suspension may be without pay and/or you may be dismissed
                without notice
              </Text>
            </View>
          </View>
          <Text style={styles.pageFooter}>Page 5</Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.contentWrapper}>
          <View style={styles.content}>
            <View style={styles.section}>
              <Text style={styles.bold}>NDIS Code of Conduct (Workers)</Text>
              <Text style={styles.italic}>
                You are required to comply with the NDIS Code of Conduct.
              </Text>
              <Text style={styles.italic}>
                NDIS Code of Conduct promotes safe and ethical service delivery,
                by setting out expectations for safe and ethical services and
                supports for both NDIS providers and workers.
              </Text>
              <Text style={styles.italic}>
                It requires workers and providers delivering NDIS supports to:
              </Text>
              <Text style={styles.italic}>
                act with respect for individual rights to freedom of expression,
                self-determination, and decision-making in accordance with
                relevant laws and conventions
              </Text>
              <Text style={styles.italic}>
                respect the privacy of people with disability
              </Text>
              <Text style={styles.italic}>
                provide supports and services in a safe and competent manner
                with care and skill
              </Text>
              <Text style={styles.italic}>
                act with integrity, honesty, and transparency
              </Text>
              <Text style={styles.italic}>
                promptly take steps to raise and act on concerns about matters
                that might have an impact on the quality and safety of supports
                provided to people with disability
              </Text>
              <Text style={styles.italic}>
                take all reasonable steps to prevent and respond to all forms of
                violence, exploitation, neglect, and abuse
              </Text>
              <Text style={styles.italic}>
                take all reasonable steps to prevent sexual misconduct
              </Text>
              <Text>
                Should Australian Carers Pty Ltd or any employee be found to
                breach the NDIS Code of Conduct, then Australian Carers Pty Ltd
                is committed to complying with any education, compliance or
                enforcement action stipulated by the NDIS Commissioner
              </Text>
            </View>
          </View>
          <Text style={styles.pageFooter}>Page 6</Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.contentWrapper}>
          <View style={styles.content}>
            <View style={styles.section}>
              <Text style={styles.bold}>Termination of employment</Text>
              <Text style={styles.italic}>Probationary Period</Text>
              <Text>
                Australian Carers may terminate your employment at any time, for
                any reason and without notice prior to the completion of the
                Probationary Period.
              </Text>
              <Text>
                Dismissal without notice shall also apply in the case of serious
                and wilful misconduct.
              </Text>
              <Text style={styles.italic}>If you terminate this Agreement</Text>
              <Text>
                You are required to provide the relevant period of notice,
                pursuant to the NES unless as otherwise agreed between you and
                Australian Carers.
              </Text>
              <Text>
                Where you elect to terminate this Agreement and you do not give
                the required notice pursuant to the NES, the unworked period of
                notice will be deducted from your final pay. Unless as otherwise
                agreed between you and Australian Carers, the notice period may
                not be reduced by offsetting accrued annual leave entitlements.
              </Text>
              <Text>
                On receiving notice Australian Carers may elect to pay you in
                lieu of notice and require that you take leave immediately and
                that you do not work out the balance of any applicable notice
                period.
              </Text>
              <Text style={styles.italic}>
                If Australian Carers terminates this Agreement
              </Text>
              <Text>
                Subject to any applicable legislation, your employment may be
                terminated if:
              </Text>
              <Text>
                you are declared medically unfit to perform your duties;
              </Text>
              <Text>
                You engage in serious and wilful misconduct, in which case
                termination may be immediate and without notice; or
              </Text>
              <Text>You are made redundant.</Text>
              <Text>
                Applicable periods in respect of notice of termination are
                referred to in the Award and the NES.
              </Text>
              <Text>
                Any misrepresentation of information provided to Australian
                Carers during your recruitment in regard to your qualifications
                and experience, health or any other matter which is relevant to
                your fitness to perform the duties of this role, may be
                considered serious and wilful misconduct and may result in the
                termination of your employment.
              </Text>
              <Text>
                If notice is given to you by Australian Carers to terminate your
                employment, then Australian Carers may:
              </Text>
              <Text>
                direct you not to perform any duties, or to perform specified
                duties;
              </Text>
              <Text>
                direct you to remain away from Australian Carers premises;
              </Text>
              <Text>
                direct you to have no contact with any employee, director,
                client, patient, customer or supplier of Australian Carers,
                including via social networking websites;
              </Text>
              <Text>
                appoint another person to perform some or all of your duties;
                and
              </Text>
              <Text>change your title.</Text>
              <Text>
                If you are directed not to perform duties in accordance with
                this clause, you remain bound by all terms of this Agreement.
              </Text>
            </View>
          </View>
          <Text style={styles.pageFooter}>Page 7</Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.contentWrapper}>
          <View style={styles.content}>
            <View style={styles.section}>
              <Text>
                Your ongoing appointment is subject to satisfactory criminal
                record and prohibited employment checks (such as a Working with
                Children Check). It is a condition of your ongoing employment
                that you must maintain open disclosure of any officially (by
                Police, Courts, Government or other regulatory authorities) laid
                allegations, charges and/or convictions made against you by
                advising the Australian Carers General Manager immediately upon
                notice of those charges and/or convictions to you.
              </Text>
              <Text>
                Australian Carers reserves the right to investigate all new
                charges and convictions against you to determine your continued
                suitability for employment with Australian Carers or a Group
                Company. You must reasonably cooperate with such investigations.
                Australian Carers may elect to rescind this offer or terminate
                your employment should these checks or disclosures be deemed
                unsatisfactory.
              </Text>
              <Text>
                Your appointment is subject to your visa status and your right
                to work in Australia. It is a condition of your ongoing
                employment that if you are a visa holder, you abide by your visa
                conditions and work limitations and further, advise Australian
                Carers in writing immediately of any change to your visa or
                working rights status. Australian Carers may elect to rescind
                this offer with no notice to or compensation for you or, if you
                have already commenced employment terminate your employment
                should your visa status deem you ineligible to work in
                Australia.
              </Text>
              <Text>
                If your employment is terminated for any reason, you must return
                all Australian Carers property including physical items
                (including but not limited to staff ID cards, building access
                cards, IT equipment, staff uniforms, credit cards etc),
                Confidential Information (as defined in the Confidentially
                clause of this Agreement) and documents. You must also ensure
                that all work-related materials belonging to Australian Carers
                (including but not limited to emails, documents, reports, client
                related information) are saved to Australian Carers systems and
                are readily accessible by appropriate employees in accordance
                with accepted practices and procedures.
              </Text>
            </View>
          </View>
          <Text style={styles.pageFooter}>Page 8</Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.contentWrapper}>
          <View style={styles.content}>
            <View style={styles.section}>
              <Text style={styles.bold}>Confidentiality</Text>
              <Text>
                For the purposes of this Agreement, Confidential Information
                means any confidential information relating to the past, present
                or future business of Australian Carers or a Group Company that
                comes to your knowledge and includes information about clients,
                patients or other employees; financial, budgetary, marketing,
                research and business plan information; the terms of this
                Agreement; the terms of any contract, Agreement or business
                arrangement with third parties; trade secrets; pricing
                structures; and any third party information disclosed in
                confidence.
              </Text>
              <Text>
                By accepting this offer of employment, you acknowledge and agree
                that you will not, during the course of your employment or
                thereafter, except with the consent of Australian Carers, as
                required by law or in the proper performance of your duties, use
                or disclose Confidential Information which would result in
                gaining a benefit, including a financial benefit, to you or
                others. This includes contacting any and all current and former
                clients and patients of Australian Carers and any and all
                current or former employees or contractors of Australian Carers
                with a view to another contractual or financial arrangement
                outside the business of Australian Carers.
              </Text>
              <Text>
                If your former clients or clients of former employers express a
                desire to be clients of Australian Carers Pty Ltd, then all
                referrals from you will be directed to Australian Carers Pty Ltd
                via their website, email or telephone contact such that your
                personal emails, phone contacts will not be used at all in the
                process.
              </Text>
              <Text>
                You must also take whatever measures are reasonably necessary to
                prevent the disclosure or misuse of Confidential Information
                which would cause harm or loss to Australian Carers at any time
                in the future, for example making public comments on social
                media (Facebook, Instagram etc).
              </Text>
              <Text>
                Upon the termination of your employment and at any time
                requested by Australian Carers Pty Ltd you are required to cease
                using all confidential information of Australian Carers Pty Ltd
                including participant information such as name, address, NDIA
                number, contact information and disability type or any other
                information relevant to that client(s).
              </Text>
              <Text>
                On receiving a notice pursuant to this clause, or upon cessation
                of your employment, your right to possess or use confidential
                information ceases and you must immediately:
              </Text>
              <Text>
                return all confidential information in your possession or
                control to Australian Carers Pty Ltd including notes, case
                summaries or any client information; and
              </Text>
              <Text>
                destroy and certify in writing to Australian Carers Pty Ltd the
                destruction of all confidential information in your possession
                or control.
              </Text>
              <Text>
                You must not under any circumstances contact by email, phone,
                sms, social media or any other means, Australian Carers Pty Ltd
                clients (participants) regarding your cessation of employment.
              </Text>
              <Text>
                Your obligations under this clause survive the termination of
                your employment and this Agreement.
              </Text>
            </View>
          </View>
          <Text style={styles.pageFooter}>Page 9</Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.contentWrapper}>
          <View style={styles.content}>
            <View style={styles.section}>
              <Text style={styles.bold}>
                Intellectual Property and Moral Rights
              </Text>
              <Text>
                For the purposes of this clause, Intellectual Property Rights
                means all intellectual property rights (whether or not
                registered including all applications and the right to apply for
                registration) including:
              </Text>
              <Text>
                rights in relation to any patents, copyright, trademarks,
                service marks, design rights, and all other rights arising from
                intellectual activity;
              </Text>
              <Text>
                all rights of a similar nature to any of the rights referred to
                above which may subsist in Australia or elsewhere;
              </Text>
              <Text>
                You must also take whatever measures are reasonably necessary to
                prevent the disclosure or misuse of Confidential Information
                which would cause harm or loss to Australian Carers at any time
                in the future, for example making public comments on social
                media (Facebook, Instagram etc).
              </Text>
              <Text>all Confidential Information as defined above,</Text>
              <Text>
                any such rights in any processes, technology, systems, reports,
                specifications, blue-prints, trade names, domain names, designs,
                brands and company names, trade secrets, copyright works, URLs,
                drawings, discoveries, inventions, improvements, technical data,
                research data, formulae, computer programs, software, circuit
                layouts, knowhow, logos, symbols.
              </Text>
              <Text>
                Pursuant to this Agreement and as a term of your ongoing
                employment, you:
              </Text>
              <Text>
                assign to Australian Carers all existing and future Intellectual
                Property Rights created or generated by you (whether along or
                with Australian Carers, its other employees, agents or
                contractors) for use by Australian Carers (or a Group Company);
                and
              </Text>
              <Text>
                agree that because of the above clause, all existing
                Intellectual Property Rights are vested in Australian Carers
                and, when created, all future Intellectual Property Rights vest
                in Australian Carers;
              </Text>
              <Text>
                must do all things reasonably requested by Australian Carers in
                respect of the assignment of the Intellectual Property Rights
              </Text>
              <Text>
                must disclose the subject matter of all Intellectual Property
                Rights to Australian Carers, immediately when Intellectual
                Property Rights have been created.
              </Text>
              <Text>
                consent to Australian Carers, a Group Company or their
                suppliers, clients or customers using or adapting Works to which
                you have contributed or which you have created in connection
                with your employment, in any manner, and without expressly
                acknowledging your contribution or creation in a way which may
                otherwise infringe a Moral Right of yours. This consent is given
                in relation to all Works made or to be made by you in the course
                of your employment.
              </Text>
              <Text>
                acknowledge that the consent given in the subclause above has
                been given freely and genuinely, and without you being subjected
                to any duress by Australian Carers, a Group Company or a third
                party.
              </Text>
              <Text>
                Your obligation sunder this clause survive the termination of
                your employment and this Agreement.
              </Text>
            </View>
          </View>
          <Text style={styles.pageFooter}>Page 10</Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page} wrap={false}>
        <View style={styles.contentWrapper}>
          <View style={styles.content}>
            <View style={styles.section}>
              <Text style={styles.bold}>Restraint of Trade</Text>
              <Text>
                You acknowledge that as a result of your employment with
                Australian Carers, you will gain access to confidential
                knowledge, information and data as well as other connections and
                contacts.
              </Text>
              <Text>
                That confidential information, data, knowledge and connections
                is information which may be used by a former employee in your
                position to set up a competing business and to solicit current
                clients of Australian Carers.
              </Text>
              <Text>
                To reasonably protect the goodwill and the legitimate business
                interests of Australian Carers or its related entities, during
                the Restraint Period and within the Restraint Period (referred
                to below), you will not, without prior written consent of
                Australian Carers or its related entities, directly or
                indirectly:
              </Text>
              <Text>
                Entice or solicit, or assist another person to entice or
                solicit, an employee, contractor, officer, agent or supplier of
                Australian Carers or its related entities with whom he has had
                dealings prior to his employment ending, to cease to provide
                services to Australian Carers or its related entities;
              </Text>
              <Text>
                Canvass, solicit or deal with, counsel, procure or assist
                another person to canvass, solicit or deal with any client of
                Australian Carers or its related entities with whom he has had
                dealings with during the 18 months period prior to his
                employment ending.
              </Text>
              <Text>
                Restraint Period means 18 months, from the date of termination
                of your employment.
              </Text>
              <Text>
                It is agreed that restraint will exist even if your employment
                is terminated during the Probation Period and that the Restraint
                Period will be as set out below, after the completion of the
                Probation:
              </Text>
              <Text>18 months;</Text>
              <Text>15 months;</Text>
              <Text>12 months:</Text>
              <Text>Restraint Area Means:</Text>
              <Text>(a) Australia;</Text>
              <Text>(b) South Australia;</Text>
              <Text>(c) Queensland;</Text>
              <Text>(d) New South Wales</Text>
              <Text>
                The metropolitan area of the capital city in which you are
                employed at the date of termination of your employment.
              </Text>
              <Text>
                Prior written consent includes a documented list of Clients
                agreed and authorised in writing by Australian Carers or its
                related entities.
              </Text>
              <Text>
                The covenants given by you in this clause will apply, and may be
                enforced against you, regardless of the reason(s) for the
                termination of his employment.
              </Text>
              <Text>
                Each restraint contained in this Agreement (resulting from any
                combination of the wording) constitutes a separate and
                independent provision, severable from the other restraints. If a
                court of competent jurisdiction finally decides any such
                restraint to be unenforceable or whole or in part, the
                enforceability of the remainder of that restraint and any other
                restraint will not be affected.
              </Text>
            </View>
          </View>
          <Text style={styles.pageFooter}>Page 11</Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page} wrap={false}>
        <View style={styles.contentWrapper}>
          <View style={styles.content}>
            <View style={styles.section}>
              <Text style={styles.bold}>Surveillance</Text>
              <Text>
                You agree that Australian Carers may, to the extent permitted by
                law, conduct computer, camera and tracking surveillance of you
                during your employment.
              </Text>
              <Text>
                You consent to Australian Carers, from the commencement of your
                employment and on a continuous, ongoing basis:
              </Text>
              <Text>
                accessing, monitoring, logging and recording any communication
                or information developed, used, received, stored or transmitted
                by you using Australian Carers IT systems, either at Australian
                Carers premises or at any other place. This includes your use of
                Australian Carers IT systems outside of or external to
                Australian Carers or client premises;
              </Text>
              <Text>
                installing filtering systems in Australian Carers IT systems
                which restrict the inward and outward flow of certain types of
                material, including emails and viruses, with the result that
                some email traffic may be blocked;
              </Text>
              <Text>
                conducting camera (video) surveillance of you in any of
                Australian Carers premises, including community locations; and
              </Text>
              <Text>
                conducting tracking surveillance of the Employee while in a
                vehicle belonging to the Employer.
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.bold}>Privacy</Text>
              <Text>
                For the purposes of this clause, Personal Information has the
                meaning in the Privacy Act 1988 (Cth).
              </Text>
              <Text>In the course of your employment, you must not:</Text>
              <Text>collect Personal Information of other people; or</Text>
              <Text>
                use or disclose Personal Information of other people, except if
                necessary to perform your duties; or authorised by Australian
                Carers; and in accordance with applicable privacy laws and
                Australian Carers policies,
              </Text>
              <Text>
                Without limiting Australian Carers rights under applicable laws,
                you agree that Australian Carers or a Group Company may:
              </Text>
              <Text>
                use and disclose Personal Information that Australian Carers has
                collected about you during your employment, or in anticipation
                of that employment, for any purpose connected with your
                employment and the operation of Australian Carers business; and
              </Text>
              <Text>
                transfer Personal Information from Australian Carers to a Group
                Company in Australia or in other countries, Australian Carers
                insurers and superannuation administrators, or contractors or
                other service providers who perform services for or on behalf of
                Australian Carers as part of Australian Carers usual business
                operations.
              </Text>
              <Text>
                By agreeing to the terms of employment, and without limiting
                Australian Carers rights under applicable laws, you consent to
                Australian Carers collecting, using and disclosing Personal
                Information about you and transferring that Personal Information
                externally (including internationally) when necessary.
              </Text>
            </View>
          </View>
          <Text style={styles.pageFooter}>Page 12</Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.contentWrapper}>
          <View style={styles.content}>
            <View style={styles.section}>
              <Text style={styles.bold}>Variations must be in writing</Text>
              <Text>
                The terms and conditions referred to in this letter may only be
                varied by a written Agreement signed by both you and an
                authorised representative of Australian Carers. Authorised
                representatives include and are limited to Australian Carers
                Chief Executive Officer and Australian Carers General Manager
              </Text>
              <Text>
                If you have any questions relating to the terms and conditions
                of employment, please don't hesitate to contact Australian
                Carers management on 13 77 77 or by email to the relevant email
                below for the location you are employed to report to
              </Text>
              <Text>Adelaide: adelaide@australiancarers.com.au</Text>
              <Text>Rockhampton: rockhampton@australiancarers.com.au</Text>
              <Text>Sydney: sydney@australiancarers.com.au</Text>
              <Text>
                Congratulations on your appointment and I trust that you find
                your employment both satisfying and rewarding.
              </Text>
              <Text>Yours sincerely,</Text>
              <Text>Australian Carers</Text>
            </View>
            <View style={styles.section}>
              <View style={styles.checkbox}>
                <Svg height="16" viewBox="0 0 24 24" width="16">
                  <Path d="M0 0h24v24H0z" />
                  <Path
                    fill="black"
                    d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                  />
                </Svg>
                <View style={styles.checkboxLabel}>
                  <Text>
                    As part of your employment with Australian Carers you are
                    required to clock on and off from a shift using a rostering
                    program that we provide you access to, by placing a tick in
                    this box you acknowledge that an essential term of your
                    employment is to only clock on and off from a shift at the
                    location you are advised to clock on and off and failure to
                    do inferring you have commenced your shift when in fact you
                    have not will be reasonably seen as intentionally being
                    dishonest and is grounds for instant termination of your
                    employment
                  </Text>
                </View>
              </View>
              <View style={styles.checkbox}>
                <Svg height="16" viewBox="0 0 24 24" width="16">
                  <Path d="M0 0h24v24H0z" />
                  <Path
                    fill="black"
                    d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                  />
                </Svg>
                <View style={styles.checkboxLabel}>
                  <Text>
                    From time to time you may be required to drive vehicles that
                    are not owned by Australian Carers you agree and undertake
                    to:
                  </Text>
                  <Text>
                    - Abide by any road rules including alcohol and drug related
                    rules
                  </Text>
                  <Text>- Drive in a safe manner</Text>
                  <Text>
                    - Not drive a vehicle you are not licensed to drive
                  </Text>
                  <Text>
                    - Advise Australian Carers of any loss or suspension of your
                    drivers licence
                  </Text>
                  <Text>
                    - Indemnify Australian Carers for: all insurance excesses,
                    any and all damaged caused to othervehicles, property or
                    persons while the vehicle is under your control and
                    responsibility, all infringement notices ( including parking
                    infringements ) issued while you are or did have control of
                    the vehicle
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.footer}>
              <View style={{ flex: '1 0 50%' }}>
                <View style={styles.inputWrapper}>
                  <Text style={styles.bold}>
                    Signature of Employee Accepting this Agreement
                  </Text>
                  {!!signature && <Image src={signature} />}
                </View>
              </View>
              <View style={{ flex: '1 0 50%' }}>
                <View style={styles.inputWrapper}>
                  <Text style={styles.bold}>Date of Acceptance</Text>
                  <Text style={styles.input}>
                    {format(agreeDate, 'dd/MM/yyyy')}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <Text style={styles.pageFooter}>Page 13</Text>
        </View>
      </Page>
    </Document>
  );
};

import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { blue, green, orange, red, yellow } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { _peopleClients, _peopleMembers } from '@timed/app';
import { useAuth } from '@timed/auth';
import { clientFilesMetadata } from '@timed/client';
import {
  Button,
  formatAddress,
  formatPersonName,
  Link,
  roundNumber,
} from '@timed/common';
import { pluralise } from '@timed/common/utils/pluralise';
import {
  ClientFileType,
  MemberFileType,
  OrderBy,
  useTrackFilesGetClientFilesLazyQuery,
  useTrackFilesGetClientsQuery,
  useTrackFilesGetMemberFilesLazyQuery,
  useTrackFilesGetMembersQuery,
  useTrackFilesGetOrgSettingsQuery,
  useUpdateOrgMemberDocumentTrackerCollapsedFilesMutation,
  useUpdateOrgMemberDocumentTrackerVisibleFilesMutation,
} from '@timed/gql';
import {
  MemberFileCreateFormModal,
  MemberFileCreateFormModalProps,
  memberFilesMetadata,
  MemberUpdateFileFormModal,
  MemberUpdateFileFormModalProps,
} from '@timed/member';
import clsx from 'clsx';
import { addMonths, format, isAfter } from 'date-fns';
import { subMonths } from 'date-fns/esm';
import { useModal } from 'mui-modal-provider';
import { useCallback, useEffect, useMemo, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    statistics: {
      display: 'flex',
      width: 'max-content',
      flexDirection: 'column',
      gap: theme.spacing(1),
      backgroundColor: theme.palette.background.paper2,
      padding: theme.spacing(4),
    },
    table: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.5),
      alignItems: 'start',
    },
    row: {
      flexGrow: 1,
      display: 'flex',
      gap: theme.spacing(0.5),
    },
    cell: {
      // flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      flexShrink: 0,
      flexBasis: 100,
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      '& .MuiTypography-root': {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 13,
      },
      cursor: 'pointer',
    },
    collapsedCell: {
      flexBasis: 30,
    },
    hoveredCell: {
      backgroundColor: blue[100],
    },
    okCell: {
      backgroundColor: theme.palette.background.paper,
    },
    warningCell: {
      backgroundColor: yellow[100],
    },
    errorCell: {
      backgroundColor: orange[100],
    },
    urgentCell: {
      backgroundColor: red[100],
    },
    disabledCell: {
      background: `repeating-linear-gradient(135deg, ${theme.palette.background.paper2}, ${theme.palette.background.paper2} 8px, ${theme.palette.background.paper3} 8px, ${theme.palette.background.paper3} 16px);`,
    },
    unschedulableCell: {
      backgroundColor: orange[100],
      // background: `repeating-linear-gradient(135deg, ${red[100]}, ${red[100]} 8px, ${red[300]} 8px, ${red[300]} 16px);`,
    },
    header: {
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: theme.palette.background.paper2,
      fontSize: 13,
      alignItems: 'initial',
      flexFlow: 'column',
    },
    subHeader: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 11,
    },
    personName: {
      flexBasis: 200,
    },
    externalId: {
      flexBasis: 40,
    },
    schedulable: {
      flexBasis: 60,
    },
    address: {
      flexBasis: 300,
    },
    collapsedAddress: {
      flexBasis: 40,
    },
    data: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(0.5),
    },
    result: {
      height: 24,
      marginLeft: theme.spacing(1),
      display: 'flex',
      gap: theme.spacing(0.5),
      lineHeight: 1,
      flexFlow: 'column',
      fontSize: 11 + 'px !IMPORTANT',
      '& span:first-child': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    icon: {
      height: 24,
      width: 24,
      // margin: theme.spacing(0, 1, 0, 0),
      border: '1px solid ' + theme.palette.text.primary,
      color: theme.palette.common.white,
    },
    schedulableIcon: {
      width: 52,
      backgroundColor: green[300],
    },
    unschedulableIcon: {
      width: 52,
      backgroundColor: orange[300],
    },
    okIcon: {
      backgroundColor: theme.palette.background.paper2,
      color: theme.palette.text.primary,
    },
    warningIcon: {
      backgroundColor: yellow[300],
      color: theme.palette.text.primary,
    },
    errorIcon: {
      backgroundColor: orange[300],
    },
    urgentIcon: {
      backgroundColor: red[300],
    },
  }),
);

const ReportDocumentTracker = () => {
  const classes = useStyles();

  const theme = useTheme();

  const { showModal } = useModal();

  const { branch } = useAuth();

  const storagePrefix = 'reports.documentTracker';

  const ownerTypeFromStorage = sessionStorage.getItem(
    `${storagePrefix}.ownerType`,
  );

  const [ownerType, setOwnerType] = useState<'member' | 'client'>(
    ownerTypeFromStorage === 'member' || ownerTypeFromStorage === 'client'
      ? ownerTypeFromStorage
      : 'member',
  );

  useEffect(() => {
    if (ownerType !== ownerTypeFromStorage) {
      sessionStorage.setItem(`${storagePrefix}.ownerType`, ownerType);
    }
  });

  type TrackedFile<T> = {
    type: T;
    expiryType?: 'expires' | 'renews';
    defaultExpiry?: number;
    required: boolean;
  };

  const trackedMemberFiles: TrackedFile<MemberFileType>[] = useMemo<
    TrackedFile<MemberFileType>[]
  >(
    () => [
      {
        type: MemberFileType.POLICE_CHECK,
        expiryType: 'renews',
        defaultExpiry: 60,
        required: true,
      },
      {
        type: MemberFileType.WORKING_WITH_CHILDREN_CHECK,
        expiryType: 'expires',
        defaultExpiry: 60,
        required: true,
      },
      { type: MemberFileType.NDIS_MODULE, required: true },
      { type: MemberFileType.COVID_19, required: true },
      { type: MemberFileType.PPE, required: true },
      {
        type: MemberFileType.NEW_NDIS_CLEARANCE,
        expiryType: 'expires',
        defaultExpiry: 60,
        required: true,
      },
      {
        type: MemberFileType.NEW_WORKER_NDIS_INDUCTION_MODULE,
        required: false,
      },
      { type: MemberFileType.VISA, required: true, expiryType: 'expires' },
      { type: MemberFileType.PASSPORT, expiryType: 'expires', required: true },
      { type: MemberFileType.EMPLOYMENT_CONTRACT, required: true },
      {
        type: MemberFileType.CONFIRMATION_OF_ENROLLMENT,
        required: false,
        expiryType: 'expires',
      },
      { type: MemberFileType.TAX_FILE_DECLARATION, required: true },
      {
        type: MemberFileType.ASSISTING_WITH_SELF_MEDICATION,
        required: false,
      },
      {
        type: MemberFileType.FIRST_AID,
        required: false,
        expiryType: 'expires',
        defaultExpiry: 36,
      },
      {
        type: MemberFileType.CPR,
        required: false,
        expiryType: 'expires',
        defaultExpiry: 12,
      },
      {
        type: MemberFileType.MANUAL_HANDLING,
        required: false,
      },
      {
        type: MemberFileType.INDIVIDUAL_SUPPORT,
        required: false,
      },
      {
        type: MemberFileType.PROOF_OF_COVID_VACCINATION,
        required: false,
      },
      {
        type: MemberFileType.UNDERSTANDING_TRAUMATIC_BRAIN_INJURY,
        required: false,
      },
      {
        type: MemberFileType.EFFECTIVE_COMMUNICATION,
        required: false,
      },
      {
        type: MemberFileType.SUPPORTING_SAFE_MEALS,
        required: false,
      },
      {
        type: MemberFileType.UNDERSTANDING_DEMENTIA,
        required: false,
      },
      {
        type: MemberFileType.PREVENTING_DEMENTIA,
        required: false,
      },
      {
        type: MemberFileType.SEIZURE_RECOGITION,
        required: false,
      },
    ],
    [],
  );

  const trackedClientFiles: TrackedFile<ClientFileType>[] = useMemo<
    TrackedFile<ClientFileType>[]
  >(
    () => [
      {
        type: ClientFileType.NDIS_PLAN,
        required: false,
      },
      {
        type: ClientFileType.INITIAL_CONSULT,
        required: true,
      },
      {
        type: ClientFileType.SERVICE_AGREEMENT,
        required: true,
      },
      {
        type: ClientFileType.PRIVACY_CONSENT,
        required: true,
      },
      {
        type: ClientFileType.CONSENT_TO_SHARE,
        required: true,
      },
      // {
      //   type: ClientFileType.CONSENT_TO_SHARE_NDIS,
      //   required: true,
      // },
      {
        type: ClientFileType.CONSENT_TO_ACT,
        required: true,
      },
      // {
      //   type: ClientFileType.CONSENT_TO_ACT_NDIS,
      //   required: true,
      // },
      {
        type: ClientFileType.CAREPLAN,
        required: true,
      },
      {
        type: ClientFileType.NDIS_SERVICE_BOOKING,
        required: true,
      },
      {
        type: ClientFileType.RISK_ASSESSMENT,
        required: true,
      },
      {
        type: ClientFileType.CONFLICT_OF_INTEREST,
        required: false,
      },
      {
        type: ClientFileType.AC_HANDBOOK,
        required: true,
      },
      {
        type: ClientFileType.PRIVACY_STATEMENT,
        required: true,
      },
    ],
    [],
  );

  const [collapsedMemberColumns, setCollapsedMemberColumns] = useState<
    MemberFileType[]
  >([]);

  const [visibleMemberColumns, setVisibleMemberColumns] = useState<
    MemberFileType[]
  >(trackedMemberFiles.map(({ type }) => type));

  const [collapsedClientColumns, setCollapsedClientColumns] = useState<
    ClientFileType[]
  >([]);

  const [visibleClientColumns, setVisibleClientColumns] = useState<
    ClientFileType[]
  >(trackedClientFiles.map(({ type }) => type));

  const [visibleIdentitiyColumns, setVisibleIdentitiyColumns] = useState<
    ('person-names' | 'external-ids')[]
  >(['person-names', 'external-ids']);

  const [collapsedAddress, setCollapsedAddress] = useState<boolean>(true);

  const [hoveredMember, setHoveredMember] = useState<string | null>();
  const [hoveredClient, setHoveredClient] = useState<string | null>();
  const [highlightedMember, setHighlightedMember] = useState<string | null>();
  const [highlightedClient, setHighlightedClient] = useState<string | null>();

  const handleOpenCreateModal = useCallback(
    (props: Pick<MemberFileCreateFormModalProps, 'member' | 'type'>) => {
      const modal: { hide: () => void } = showModal(MemberFileCreateFormModal, {
        onClose: () => modal.hide(),
        ...props,
      });
    },
    [showModal],
  );

  const handleOpenUpdateModal = useCallback(
    ({
      member,
      file,
    }: Pick<MemberUpdateFileFormModalProps, 'member' | 'file'>) => {
      const modal: { hide: () => void } = showModal(MemberUpdateFileFormModal, {
        onClose: () => modal.hide(),
        member,
        file,
      });
    },
    [showModal],
  );

  const membersResponse = useTrackFilesGetMembersQuery({
    variables: {
      input: {
        where: {
          trackFiles: { _eq: true },
          branchMembers: branch
            ? { branch: { id: { _eq: branch.id } } }
            : undefined,
        },
        orderBy: [
          { lastName: OrderBy.ASC_NULLS_LAST },
          { firstName: OrderBy.ASC },
        ],
      },
    },
  });

  const clientsResponse = useTrackFilesGetClientsQuery({
    variables: {
      input: {
        where: {
          trackFiles: { _eq: true },
          branch: branch ? { id: { _eq: branch.id } } : undefined,
        },
        orderBy: [
          { lastName: OrderBy.ASC_NULLS_LAST },
          { firstName: OrderBy.ASC },
        ],
      },
    },
  });

  const orgSettingsResponse = useTrackFilesGetOrgSettingsQuery();

  const [trackMemberFiles, trackMemberFilesResponse] =
    useTrackFilesGetMemberFilesLazyQuery();

  const [trackClientFiles, trackClientFilesResponse] =
    useTrackFilesGetClientFilesLazyQuery();

  useEffect(() => {
    if (
      !!orgSettingsResponse.data?.me.member?.org
        .memberDocumentTrackerCollapsedFiles?.length
    )
      setCollapsedMemberColumns(
        orgSettingsResponse.data.me.member.org
          .memberDocumentTrackerCollapsedFiles,
      );

    if (
      !!orgSettingsResponse.data?.me.member?.org
        .clientDocumentTrackerCollapsedFiles?.length
    )
      setCollapsedClientColumns(
        orgSettingsResponse.data.me.member.org
          .clientDocumentTrackerCollapsedFiles,
      );
    if (
      !!orgSettingsResponse.data?.me.member?.org
        .memberDocumentTrackerVisibleFiles?.length
    )
      setVisibleMemberColumns(
        orgSettingsResponse.data.me.member.org
          .memberDocumentTrackerVisibleFiles,
      );
    if (
      !!orgSettingsResponse.data?.me.member?.org
        .clientDocumentTrackerVisibleFiles?.length
    )
      setVisibleClientColumns(
        orgSettingsResponse.data.me.member.org
          .clientDocumentTrackerVisibleFiles,
      );
  }, [orgSettingsResponse.data?.me]);

  const [updateVisibleMemberFiles] =
    useUpdateOrgMemberDocumentTrackerVisibleFilesMutation();

  const [updateCollapsedMemberFiles] =
    useUpdateOrgMemberDocumentTrackerCollapsedFilesMutation();

  const [updateVisibleClientFiles] =
    useUpdateOrgMemberDocumentTrackerVisibleFilesMutation();

  const [updateCollapsedClientFiles] =
    useUpdateOrgMemberDocumentTrackerCollapsedFilesMutation();

  const handleUpdateVisibleFiles = (
    fileType: MemberFileType | ClientFileType,
  ) => {
    switch (ownerType) {
      case 'client':
        setVisibleClientColumns(
          visibleClientColumns.includes(fileType as ClientFileType)
            ? visibleClientColumns.filter((type) => fileType !== type)
            : [...visibleClientColumns, fileType as ClientFileType],
        );

        updateVisibleClientFiles({
          variables: {
            input: {
              patch: {
                clientDocumentTrackerVisibleFiles:
                  visibleClientColumns.includes(fileType as ClientFileType)
                    ? visibleClientColumns.filter((type) => fileType !== type)
                    : [...visibleClientColumns, fileType as ClientFileType],
              },
            },
          },
        });
        break;

      case 'member':
        setVisibleMemberColumns(
          visibleMemberColumns.includes(fileType as MemberFileType)
            ? visibleMemberColumns.filter((type) => fileType !== type)
            : [...visibleMemberColumns, fileType as MemberFileType],
        );

        updateVisibleMemberFiles({
          variables: {
            input: {
              patch: {
                memberDocumentTrackerVisibleFiles:
                  visibleMemberColumns.includes(fileType as MemberFileType)
                    ? visibleMemberColumns.filter((type) => fileType !== type)
                    : [...visibleMemberColumns, fileType as MemberFileType],
              },
            },
          },
        });
        break;
    }
  };

  const handleUpdateCollapsedFiles = (
    fileType: MemberFileType | ClientFileType,
  ) => {
    switch (ownerType) {
      case 'client':
        setCollapsedClientColumns(
          collapsedClientColumns.includes(fileType as ClientFileType)
            ? collapsedClientColumns.filter((type) => fileType !== type)
            : [...collapsedClientColumns, fileType as ClientFileType],
        );

        updateCollapsedClientFiles({
          variables: {
            input: {
              patch: {
                clientDocumentTrackerCollapsedFiles:
                  collapsedClientColumns.includes(fileType as ClientFileType)
                    ? collapsedClientColumns.filter((type) => fileType !== type)
                    : [...collapsedClientColumns, fileType as ClientFileType],
              },
            },
          },
        });
        break;

      case 'member':
        setCollapsedMemberColumns(
          collapsedMemberColumns.includes(fileType as MemberFileType)
            ? collapsedMemberColumns.filter((type) => fileType !== type)
            : [...collapsedMemberColumns, fileType as MemberFileType],
        );

        updateCollapsedMemberFiles({
          variables: {
            input: {
              patch: {
                memberDocumentTrackerCollapsedFiles:
                  collapsedMemberColumns.includes(fileType as MemberFileType)
                    ? collapsedMemberColumns.filter((type) => fileType !== type)
                    : [...collapsedMemberColumns, fileType as MemberFileType],
              },
            },
          },
        });
        break;
    }
  };

  useEffect(() => {
    if (membersResponse.data)
      trackMemberFiles({
        variables: {
          input: {
            where: {
              type: { _in: trackedMemberFiles.map(({ type }) => type) },
              owner: {
                trackFiles: { _eq: true },
                branchMembers: branch
                  ? { branch: { id: { _eq: branch.id } } }
                  : undefined,
              },
            },
            orderBy: [{ file: { createdAt: OrderBy.DESC } }],
          },
        },
      });
  }, [membersResponse.data, trackMemberFiles, trackedMemberFiles, branch]);

  useEffect(() => {
    if (clientsResponse.data)
      trackClientFiles({
        variables: {
          input: {
            where: {
              type: { _in: trackedClientFiles.map(({ type }) => type) },
              owner: {
                trackFiles: { _eq: true },
                branch: branch ? { id: { _eq: branch.id } } : undefined,
              },
            },
            orderBy: [{ file: { createdAt: OrderBy.DESC } }],
          },
        },
      });
  }, [clientsResponse.data, trackClientFiles, trackedClientFiles, branch]);

  const formatMemberHeader = (
    fileType: MemberFileType,
    description?: string,
  ) => {
    return visibleMemberColumns.includes(fileType) ? (
      <Typography
        className={
          collapsedMemberColumns.includes(fileType)
            ? clsx(classes.cell, classes.collapsedCell, classes.header)
            : clsx(classes.cell, classes.header)
        }
        onClick={() => handleUpdateCollapsedFiles(fileType)}
      >
        <span className={classes.bold}>
          {collapsedMemberColumns.includes(fileType)
            ? memberFilesMetadata
                .find(({ id }) => id === fileType)
                ?.name.slice(0, 3)
            : memberFilesMetadata.find(({ id }) => id === fileType)?.name}
        </span>
        {!collapsedMemberColumns.includes(fileType) && (
          <span className={classes.subHeader}>{description}</span>
        )}
      </Typography>
    ) : null;
  };

  const formatClientHeader = (
    fileType: ClientFileType,
    description?: string,
  ) => {
    return visibleClientColumns.includes(fileType) ? (
      <Typography
        className={
          collapsedClientColumns.includes(fileType)
            ? clsx(classes.cell, classes.collapsedCell, classes.header)
            : clsx(classes.cell, classes.header)
        }
        onClick={() => handleUpdateCollapsedFiles(fileType)}
      >
        <span className={classes.bold}>
          {collapsedClientColumns.includes(fileType)
            ? clientFilesMetadata
                .find(({ id }) => id === fileType)
                ?.name.slice(0, 3)
            : clientFilesMetadata.find(({ id }) => id === fileType)?.name}
        </span>
        {!collapsedClientColumns.includes(fileType) && (
          <span className={classes.subHeader}>{description}</span>
        )}
      </Typography>
    ) : null;
  };

  type FormattedCells<T> = {
    [x: string]: {
      fileType: T;
      cell: JSX.Element;
    }[];
  };

  const formattedMemberCells: FormattedCells<MemberFileType> = useMemo(() => {
    if (
      !!membersResponse.data?.members.length &&
      trackMemberFilesResponse.called &&
      !trackMemberFilesResponse.loading
    ) {
      const formatted: FormattedCells<MemberFileType> = {};

      membersResponse.data.members.forEach(({ id: memberId }) => {
        formatted[memberId] = trackedMemberFiles.map(
          ({ type, defaultExpiry, expiryType, required }) => {
            const file = trackMemberFilesResponse.data?.memberFiles.find(
              ({ type: fileType, owner }) =>
                type === fileType && owner.id === memberId,
            );

            const visaNa =
              type === MemberFileType.VISA &&
              !!membersResponse.data?.members.find(({ id }) => id === memberId)
                ?.australianCitizen;

            const tfnNa =
              !file &&
              type === MemberFileType.TAX_FILE_DECLARATION &&
              !!membersResponse.data?.members.find(({ id }) => id === memberId)
                ?.taxFileNumberDeclaration?.tfn;

            const fileMissing = !file;

            const missingExpiry =
              !!file && expiryType === 'expires' && !file.expiresAt;

            const expired =
              !tfnNa &&
              !visaNa &&
              ((!!file?.expiresAt &&
                isAfter(new Date(), new Date(file.expiresAt))) ||
                (!!defaultExpiry &&
                  ((expiryType === 'expires' &&
                    !!file?.expiresAt &&
                    isAfter(new Date(), new Date(file.expiresAt))) ||
                    (expiryType === 'renews' &&
                      !!file?.issuedAt &&
                      isAfter(
                        new Date(),
                        addMonths(new Date(file.issuedAt), defaultExpiry),
                      )))));

            const expiringSoon =
              !tfnNa &&
              !visaNa &&
              !!defaultExpiry &&
              ((expiryType === 'expires' &&
                !!file?.expiresAt &&
                isAfter(new Date(), subMonths(new Date(file.expiresAt), 1))) ||
                (expiryType === 'renews' &&
                  !!file?.issuedAt &&
                  isAfter(
                    new Date(),
                    subMonths(
                      addMonths(new Date(file.issuedAt), defaultExpiry),
                      1,
                    ),
                  )));

            const cellClass =
              hoveredMember === memberId || highlightedMember === memberId
                ? clsx(classes.cell, classes.hoveredCell)
                : clsx(
                    classes.cell,
                    classes[
                      !membersResponse.data?.members.find(
                        ({ id }) => id === memberId,
                      )?.schedulable
                        ? 'unschedulableCell'
                        : !tfnNa && !visaNa && fileMissing && required
                        ? 'urgentCell'
                        : !tfnNa && !visaNa && (expired || missingExpiry)
                        ? 'errorCell'
                        : !tfnNa && !visaNa && expiringSoon
                        ? 'warningCell'
                        : !!file || required
                        ? 'okCell'
                        : 'disabledCell'
                    ],
                  );

            const icon =
              !tfnNa && !visaNa && fileMissing && required ? (
                <ClearIcon className={clsx(classes.icon, classes.urgentIcon)} />
              ) : !tfnNa && !visaNa && (expired || missingExpiry) ? (
                <ClearIcon className={clsx(classes.icon, classes.errorIcon)} />
              ) : !tfnNa && !visaNa && expiringSoon ? (
                <PriorityHighIcon
                  className={clsx(classes.icon, classes.warningIcon)}
                />
              ) : !!file || required ? (
                <CheckIcon className={clsx(classes.icon, classes.okIcon)} />
              ) : null;

            const text1 =
              !tfnNa && !visaNa && fileMissing && required
                ? 'File missing'
                : !!file && !visaNa && missingExpiry && expiryType === 'expires'
                ? 'Date not set'
                : expired
                ? 'Expired'
                : expiringSoon
                ? 'Expiring'
                : tfnNa
                ? 'TFN recorded'
                : visaNa
                ? 'Australian Citizen'
                : !!file || required
                ? 'Valid'
                : '';

            let text2 = null;

            if (file) {
              if (!tfnNa && !visaNa && expired)
                text2 =
                  expiryType === 'expires' &&
                  !!file?.expiresAt &&
                  isAfter(new Date(), new Date(file.expiresAt))
                    ? format(new Date(file.expiresAt), 'dd/MM/yyyy')
                    : !!file.issuedAt
                    ? format(
                        addMonths(new Date(file.issuedAt), defaultExpiry!),
                        'dd/MM/yyyy',
                      )
                    : 'Missing dates';
              else if (
                !tfnNa &&
                !visaNa &&
                expiryType === 'renews' &&
                !file?.issuedAt
              )
                text2 = 'Date not set';
              else if (
                !tfnNa &&
                !visaNa &&
                expiryType === 'expires' &&
                file.expiresAt
              )
                text2 = format(new Date(file.expiresAt), 'dd/MM/yyyy');
              else if (
                !tfnNa &&
                !visaNa &&
                expiryType === 'renews' &&
                file.issuedAt &&
                defaultExpiry
              )
                text2 = format(
                  addMonths(new Date(file.issuedAt), defaultExpiry),
                  'dd/MM/yyyy',
                );
            }

            return {
              fileType: type,
              cell: (
                <div
                  className={cellClass}
                  onMouseOver={() => {
                    setHoveredMember(memberId);
                  }}
                  onClick={() =>
                    !!file
                      ? handleOpenUpdateModal({
                          member: membersResponse.data?.members.find(
                            ({ id }) => id === memberId,
                          )!,
                          file: trackMemberFilesResponse.data?.memberFiles.find(
                            ({ id }) => id === file.id,
                          )!,
                        })
                      : handleOpenCreateModal({
                          member: membersResponse.data?.members.find(
                            ({ id }) => id === memberId,
                          )!,
                          type,
                        })
                  }
                >
                  <div className={classes.data}>
                    {icon}
                    <Typography className={classes.result}>
                      <span>{text1}</span>
                      {text2 && <span>{text2}</span>}
                    </Typography>
                  </div>
                </div>
              ),
            };
          },
        );
      });

      return formatted;
    }

    return {};
  }, [
    membersResponse.data,
    trackMemberFilesResponse.data,
    trackMemberFilesResponse.called,
    trackMemberFilesResponse.loading,
    trackedMemberFiles,
    classes,
    handleOpenCreateModal,
    handleOpenUpdateModal,
    highlightedMember,
    hoveredMember,
  ]);

  const formattedClientCells: FormattedCells<ClientFileType> = useMemo(() => {
    if (
      !!clientsResponse.data?.clients.length &&
      trackClientFilesResponse.called &&
      !trackClientFilesResponse.loading
    ) {
      const formatted: FormattedCells<ClientFileType> = {};

      clientsResponse.data.clients.forEach(({ id: clientId }) => {
        formatted[clientId] = trackedClientFiles.map(
          ({ type, defaultExpiry, expiryType, required }) => {
            const file = trackClientFilesResponse.data?.clientFiles.find(
              ({ type: fileType, owner }) =>
                type === fileType && owner.id === clientId,
            );

            const fileMissing = !file;

            const missingExpiry =
              !!file && expiryType === 'expires' && !file.expiresAt;

            const expired =
              (!!file?.expiresAt &&
                isAfter(new Date(), new Date(file.expiresAt))) ||
              (!!defaultExpiry &&
                ((expiryType === 'expires' &&
                  !!file?.expiresAt &&
                  isAfter(new Date(), new Date(file.expiresAt))) ||
                  (expiryType === 'renews' &&
                    !!file?.issuedAt &&
                    isAfter(
                      new Date(),
                      addMonths(new Date(file.issuedAt), defaultExpiry),
                    ))));

            const expiringSoon =
              !!defaultExpiry &&
              ((expiryType === 'expires' &&
                !!file?.expiresAt &&
                isAfter(new Date(), subMonths(new Date(file.expiresAt), 1))) ||
                (expiryType === 'renews' &&
                  !!file?.issuedAt &&
                  isAfter(
                    new Date(),
                    subMonths(
                      addMonths(new Date(file.issuedAt), defaultExpiry),
                      1,
                    ),
                  )));

            const cellClass =
              hoveredClient === clientId || highlightedClient === clientId
                ? clsx(classes.cell, classes.hoveredCell)
                : clsx(
                    classes.cell,
                    classes[
                      fileMissing && required
                        ? 'urgentCell'
                        : expired || missingExpiry
                        ? 'errorCell'
                        : expiringSoon
                        ? 'warningCell'
                        : !!file || required
                        ? 'okCell'
                        : 'disabledCell'
                    ],
                  );

            const icon =
              fileMissing && required ? (
                <ClearIcon className={clsx(classes.icon, classes.urgentIcon)} />
              ) : expired || missingExpiry ? (
                <ClearIcon className={clsx(classes.icon, classes.errorIcon)} />
              ) : expiringSoon ? (
                <PriorityHighIcon
                  className={clsx(classes.icon, classes.warningIcon)}
                />
              ) : !!file || required ? (
                <CheckIcon className={clsx(classes.icon, classes.okIcon)} />
              ) : null;

            const text1 =
              fileMissing && required
                ? 'File missing'
                : missingExpiry && expiryType === 'expires'
                ? 'Date not set'
                : expired
                ? 'Expired'
                : expiringSoon
                ? 'Expiring'
                : !!file || required
                ? 'Valid'
                : '';

            let text2 = null;

            if (file) {
              if (expired)
                text2 =
                  expiryType === 'expires' &&
                  !!file?.expiresAt &&
                  isAfter(new Date(), new Date(file.expiresAt))
                    ? format(new Date(file.expiresAt), 'dd/MM/yyyy')
                    : !!file.issuedAt
                    ? format(
                        addMonths(new Date(file.issuedAt), defaultExpiry!),
                        'dd/MM/yyyy',
                      )
                    : 'Missing dates';
              else if (expiryType === 'renews' && !file?.issuedAt)
                text2 = 'Date not set';
              else if (expiryType === 'expires' && file.expiresAt)
                text2 = format(new Date(file.expiresAt), 'dd/MM/yyyy');
              else if (
                expiryType === 'renews' &&
                file.issuedAt &&
                defaultExpiry
              )
                text2 = format(
                  addMonths(new Date(file.issuedAt), defaultExpiry),
                  'dd/MM/yyyy',
                );
            }

            return {
              fileType: type,
              cell: (
                <div
                  className={cellClass}
                  onMouseOver={() => {
                    setHighlightedClient(clientId);
                  }}
                  // onClick={() =>
                  //   !!file
                  //     ? handleOpenUpdateModal({
                  //         member: clientsResponse.data?.clients.find(
                  //           ({ id }) => id === clientId,
                  //         )!,
                  //         file: trackClientFilesResponse.data?.clientFiles.find(
                  //           ({ id }) => id === file.id,
                  //         )!,
                  //       })
                  //     : handleOpenCreateModal({
                  //         member: clientsResponse.data?.clients.find(
                  //           ({ id }) => id === clientId,
                  //         )!,
                  //         type,
                  //       })
                  // }
                >
                  <div className={classes.data}>
                    {icon}
                    <Typography className={classes.result}>
                      <span>{text1}</span>
                      {text2 && <span>{text2}</span>}
                    </Typography>
                  </div>
                </div>
              ),
            };
          },
        );
      });

      return formatted;
    }

    return {};
  }, [
    clientsResponse.data,
    trackClientFilesResponse.data,
    trackClientFilesResponse.called,
    trackClientFilesResponse.loading,
    trackedClientFiles,
    classes,
    // handleOpenCreateModal,
    // handleOpenUpdateModal,
    highlightedClient,
    hoveredClient,
  ]);

  return (
    <div className={classes.wrapper}>
      {orgSettingsResponse.data && (
        <div className={classes.statistics}>
          <Typography className={classes.bold}>
            File Usage Statistics: Organisation
          </Typography>
          <Typography>
            Total file count:{' '}
            {(
              orgSettingsResponse.data.me.member?.org.fileStorageStatistics
                .fileCount ?? 0
            ).toLocaleString()}
          </Typography>
          <Typography>
            Used space:{' '}
            {roundNumber(
              (orgSettingsResponse.data.me.member?.org.fileStorageStatistics
                .usedSpace ?? 0) /
                1024 /
                1024,
              2,
            ).toLocaleString()}{' '}
            MB
          </Typography>
        </div>
      )}
      <ButtonGroup variant="contained">
        <Button
          onClick={() => {
            setOwnerType('member');
          }}
          style={{
            backgroundColor:
              ownerType === 'member'
                ? theme.palette.background.paper3
                : theme.palette.background.paper2,
          }}
        >
          Employees
        </Button>
        <Button
          onClick={() => {
            setOwnerType('client');
          }}
          style={{
            backgroundColor:
              ownerType === 'client'
                ? theme.palette.background.paper3
                : theme.palette.background.paper2,
          }}
        >
          Participants
        </Button>
      </ButtonGroup>
      {ownerType === 'member' && (
        <div
          className={classes.table}
          onMouseOut={() => setHoveredMember(null)}
        >
          <div className={classes.row}>
            <Typography
              className={clsx(
                classes.cell,
                classes.header,
                classes.personName,
                classes.bold,
              )}
              onClick={() => {
                setVisibleIdentitiyColumns(
                  visibleIdentitiyColumns?.includes('person-names')
                    ? visibleIdentitiyColumns.filter(
                        (c) => c !== 'person-names',
                      )
                    : [...(visibleIdentitiyColumns ?? []), 'person-names'],
                );
              }}
            >
              Employee Name
            </Typography>
            <Typography
              className={clsx(
                classes.cell,
                classes.header,
                classes.externalId,
                classes.bold,
              )}
              onClick={() => {
                setVisibleIdentitiyColumns(
                  visibleIdentitiyColumns?.includes('external-ids')
                    ? visibleIdentitiyColumns.filter(
                        (c) => c !== 'external-ids',
                      )
                    : [...(visibleIdentitiyColumns ?? []), 'external-ids'],
                );
              }}
            >
              ID
            </Typography>
            <Typography
              className={clsx(
                classes.cell,
                classes.header,
                collapsedAddress ? classes.collapsedAddress : classes.address,
                classes.bold,
              )}
              onClick={() => {
                setCollapsedAddress(!collapsedAddress);
              }}
            >
              {collapsedAddress ? 'Add.' : 'Address'}
            </Typography>
            <Typography
              className={clsx(
                classes.cell,
                classes.header,
                classes.schedulable,
                classes.bold,
              )}
            >
              Can Attend Shifts
            </Typography>
            {trackedMemberFiles.map(({ type, expiryType, defaultExpiry }) => {
              const expiryInYears = !!defaultExpiry
                ? roundNumber(defaultExpiry / 12, 1)
                : 0;

              return formatMemberHeader(
                type,
                !!expiryType && !!defaultExpiry
                  ? expiryType === 'expires'
                    ? `Expiry: ${expiryInYears} ${pluralise({
                        singular: 'year',
                        plural: 'years',
                        quantity: expiryInYears,
                      })} from issue`
                    : `Renewel: every ${pluralise({
                        singular: 'year',
                        plural: `${expiryInYears} years`,
                        quantity: expiryInYears,
                      })}`
                  : '',
              );
            })}
            <Typography
              className={clsx(
                classes.cell,
                classes.header,
                classes.schedulable,
                classes.bold,
              )}
            >
              Bank Details
            </Typography>
            <Typography
              className={clsx(
                classes.cell,
                classes.header,
                classes.schedulable,
                classes.bold,
              )}
            >
              Super Details
            </Typography>
            <Typography
              className={clsx(
                classes.cell,
                classes.header,
                classes.schedulable,
                classes.bold,
              )}
            >
              TFN
            </Typography>
            <Typography
              className={clsx(
                classes.cell,
                classes.header,
                classes.schedulable,
                classes.bold,
              )}
            >
              Has Car
            </Typography>
          </div>
          {!!trackMemberFilesResponse.data &&
            membersResponse.data?.members
              .filter(({ trackFiles }) => trackFiles)
              .map((member) => {
                return (
                  <div className={classes.row}>
                    <div
                      className={
                        hoveredMember === member.id ||
                        highlightedMember === member.id
                          ? clsx(
                              classes.cell,
                              classes.hoveredCell,
                              classes.personName,
                            )
                          : !member.schedulable
                          ? clsx(
                              classes.cell,
                              classes.personName,
                              classes.unschedulableCell,
                            )
                          : clsx(classes.cell, classes.personName)
                      }
                      onMouseOver={() => {
                        setHoveredMember(member.id);
                      }}
                      onClick={() =>
                        setHighlightedMember(
                          highlightedMember !== member.id ? member.id : null,
                        )
                      }
                    >
                      {visibleIdentitiyColumns?.includes('person-names') && (
                        <Typography>
                          <Link
                            to={_peopleMembers.path + `/${member.id}/files`}
                          >
                            {formatPersonName(member, {
                              capitaliseLastName: true,
                              lastNameFirst: true,
                            })}
                          </Link>
                        </Typography>
                      )}
                    </div>

                    <div
                      className={
                        hoveredMember === member.id ||
                        highlightedMember === member.id
                          ? clsx(
                              classes.cell,
                              classes.hoveredCell,
                              classes.externalId,
                            )
                          : !member.schedulable
                          ? clsx(
                              classes.cell,
                              classes.externalId,
                              classes.unschedulableCell,
                            )
                          : clsx(classes.cell, classes.externalId)
                      }
                      onMouseOver={() => {
                        setHoveredMember(member.id);
                      }}
                      onClick={() =>
                        setHighlightedMember(
                          highlightedMember !== member.id ? member.id : null,
                        )
                      }
                    >
                      {visibleIdentitiyColumns?.includes('external-ids') && (
                        <Typography>
                          <Link to={_peopleMembers.path + `/${member.id}`}>
                            {member.externalId}
                          </Link>
                        </Typography>
                      )}
                    </div>

                    <div
                      className={
                        hoveredMember === member.id ||
                        highlightedMember === member.id
                          ? clsx(
                              classes.cell,
                              classes.hoveredCell,
                              collapsedAddress
                                ? classes.collapsedAddress
                                : classes.address,
                            )
                          : !member.schedulable
                          ? clsx(
                              classes.cell,
                              collapsedAddress
                                ? classes.collapsedAddress
                                : classes.address,
                              classes.unschedulableCell,
                            )
                          : clsx(
                              classes.cell,
                              collapsedAddress
                                ? classes.collapsedAddress
                                : classes.address,
                            )
                      }
                      onMouseOver={() => {
                        setHoveredMember(member.id);
                      }}
                      onClick={() =>
                        setHighlightedMember(
                          highlightedMember !== member.id ? member.id : null,
                        )
                      }
                    >
                      {!collapsedAddress && (
                        <Typography>
                          {!!formatAddress(member) ? (
                            <Link
                              to={`https://www.google.com/maps/search/${formatAddress(
                                member,
                              )}`}
                            >
                              {formatAddress(member)}
                            </Link>
                          ) : (
                            'None.'
                          )}
                        </Typography>
                      )}
                    </div>

                    <div
                      className={
                        hoveredMember === member.id ||
                        highlightedMember === member.id
                          ? clsx(
                              classes.cell,
                              classes.hoveredCell,
                              classes.schedulable,
                            )
                          : !member.schedulable
                          ? clsx(
                              classes.cell,
                              classes.schedulable,
                              classes.unschedulableCell,
                            )
                          : clsx(classes.cell, classes.schedulable)
                      }
                      onMouseOver={() => {
                        setHoveredMember(member.id);
                      }}
                      onClick={() =>
                        setHighlightedMember(
                          highlightedMember !== member.id ? member.id : null,
                        )
                      }
                    >
                      {member.schedulable ? (
                        <CheckIcon
                          className={clsx(
                            classes.icon,
                            classes.schedulableIcon,
                          )}
                        />
                      ) : (
                        <ClearIcon
                          className={clsx(
                            classes.icon,
                            classes.unschedulableIcon,
                          )}
                        />
                      )}
                    </div>

                    {formattedMemberCells[member.id].map(({ fileType, cell }) =>
                      visibleMemberColumns.includes(fileType) ? (
                        collapsedMemberColumns.includes(fileType) ? (
                          <div
                            className={
                              hoveredMember === member.id ||
                              highlightedMember === member.id
                                ? clsx(
                                    classes.cell,
                                    classes.collapsedCell,
                                    classes.hoveredCell,
                                  )
                                : !member.schedulable
                                ? clsx(
                                    classes.cell,
                                    classes.collapsedCell,
                                    classes.unschedulableCell,
                                  )
                                : clsx(
                                    classes.cell,
                                    classes.collapsedCell,
                                    classes.okCell,
                                  )
                            }
                            onMouseOver={() => {
                              setHoveredMember(member.id);
                            }}
                          >
                            <div className={classes.data}></div>
                          </div>
                        ) : (
                          cell
                        )
                      ) : null,
                    )}

                    <div
                      className={
                        hoveredMember === member.id ||
                        highlightedMember === member.id
                          ? clsx(
                              classes.cell,
                              classes.hoveredCell,
                              classes.schedulable,
                            )
                          : !member.schedulable
                          ? clsx(
                              classes.cell,
                              classes.schedulable,
                              classes.unschedulableCell,
                            )
                          : clsx(classes.cell, classes.schedulable)
                      }
                      onMouseOver={() => {
                        setHoveredMember(member.id);
                      }}
                      onClick={() =>
                        setHighlightedMember(
                          highlightedMember !== member.id ? member.id : null,
                        )
                      }
                    >
                      {!!member.bank?.accountNumber ? (
                        <CheckIcon
                          className={clsx(
                            classes.icon,
                            classes.schedulableIcon,
                          )}
                        />
                      ) : (
                        <ClearIcon
                          className={clsx(
                            classes.icon,
                            classes.unschedulableIcon,
                          )}
                        />
                      )}
                    </div>

                    <div
                      className={
                        hoveredMember === member.id ||
                        highlightedMember === member.id
                          ? clsx(
                              classes.cell,
                              classes.hoveredCell,
                              classes.schedulable,
                            )
                          : !member.schedulable
                          ? clsx(
                              classes.cell,
                              classes.schedulable,
                              classes.unschedulableCell,
                            )
                          : clsx(classes.cell, classes.schedulable)
                      }
                      onMouseOver={() => {
                        setHoveredMember(member.id);
                      }}
                      onClick={() =>
                        setHighlightedMember(
                          highlightedMember !== member.id ? member.id : null,
                        )
                      }
                    >
                      {!!member.super?.fundName ? (
                        <CheckIcon
                          className={clsx(
                            classes.icon,
                            classes.schedulableIcon,
                          )}
                        />
                      ) : (
                        <ClearIcon
                          className={clsx(
                            classes.icon,
                            classes.unschedulableIcon,
                          )}
                        />
                      )}
                    </div>

                    <div
                      className={
                        hoveredMember === member.id ||
                        highlightedMember === member.id
                          ? clsx(
                              classes.cell,
                              classes.hoveredCell,
                              classes.schedulable,
                            )
                          : !member.schedulable
                          ? clsx(
                              classes.cell,
                              classes.schedulable,
                              classes.unschedulableCell,
                            )
                          : clsx(classes.cell, classes.schedulable)
                      }
                      onMouseOver={() => {
                        setHoveredMember(member.id);
                      }}
                      onClick={() =>
                        setHighlightedMember(
                          highlightedMember !== member.id ? member.id : null,
                        )
                      }
                    >
                      {!!member.taxFileNumberDeclaration?.tfn ? (
                        <CheckIcon
                          className={clsx(
                            classes.icon,
                            classes.schedulableIcon,
                          )}
                        />
                      ) : (
                        <ClearIcon
                          className={clsx(
                            classes.icon,
                            classes.unschedulableIcon,
                          )}
                        />
                      )}
                    </div>

                    <div
                      className={
                        hoveredMember === member.id ||
                        highlightedMember === member.id
                          ? clsx(
                              classes.cell,
                              classes.hoveredCell,
                              classes.schedulable,
                            )
                          : !member.schedulable
                          ? clsx(
                              classes.cell,
                              classes.schedulable,
                              classes.unschedulableCell,
                            )
                          : clsx(
                              classes.cell,
                              classes.schedulable,
                              classes.disabledCell,
                            )
                      }
                      onMouseOver={() => {
                        setHoveredMember(member.id);
                      }}
                      onClick={() =>
                        setHighlightedMember(
                          highlightedMember !== member.id ? member.id : null,
                        )
                      }
                    >
                      {!!member.hasCar && (
                        <CheckIcon
                          className={clsx(
                            classes.icon,
                            classes.schedulableIcon,
                          )}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
        </div>
      )}
      {ownerType === 'client' && (
        <div
          className={classes.table}
          onMouseOut={() => setHoveredClient(null)}
        >
          <div className={classes.row}>
            <Typography
              className={clsx(
                classes.cell,
                classes.header,
                classes.personName,
                classes.bold,
              )}
            >
              Participant Name
            </Typography>
            {trackedClientFiles.map(({ type, expiryType, defaultExpiry }) => {
              const expiryInYears = !!defaultExpiry
                ? roundNumber(defaultExpiry / 12, 1)
                : 0;

              return formatClientHeader(
                type,
                !!expiryType && !!defaultExpiry
                  ? expiryType === 'expires'
                    ? `Expiry: ${expiryInYears} ${pluralise({
                        singular: 'year',
                        plural: 'years',
                        quantity: expiryInYears,
                      })} from issue`
                    : `Renewel: every ${pluralise({
                        singular: 'year',
                        plural: `${expiryInYears} years`,
                        quantity: expiryInYears,
                      })}`
                  : '',
              );
            })}
          </div>
          {!!trackClientFilesResponse.data &&
            clientsResponse.data?.clients
              .filter(({ trackFiles }) => trackFiles)
              .map((client) => {
                return (
                  <div className={classes.row}>
                    <div
                      className={
                        hoveredClient === client.id ||
                        highlightedClient === client.id
                          ? clsx(
                              classes.cell,
                              classes.hoveredCell,
                              classes.personName,
                            )
                          : clsx(classes.cell, classes.personName)
                      }
                      onMouseOver={() => {
                        setHoveredClient(client.id);
                      }}
                      onClick={() =>
                        setHoveredClient(
                          highlightedClient !== client.id ? client.id : null,
                        )
                      }
                    >
                      <Typography>
                        <Link to={_peopleClients.path + `/${client.id}`}>
                          {formatPersonName(client, {
                            capitaliseLastName: true,
                            lastNameFirst: true,
                          })}
                        </Link>
                      </Typography>
                    </div>

                    {formattedClientCells[client.id].map(({ fileType, cell }) =>
                      visibleClientColumns.includes(fileType) ? (
                        collapsedClientColumns.includes(fileType) ? (
                          <div
                            className={
                              hoveredClient === client.id ||
                              highlightedClient === client.id
                                ? clsx(
                                    classes.cell,
                                    classes.collapsedCell,
                                    classes.hoveredCell,
                                  )
                                : clsx(
                                    classes.cell,
                                    classes.collapsedCell,
                                    classes.okCell,
                                  )
                            }
                            onMouseOver={() => {
                              setHoveredClient(client.id);
                            }}
                          >
                            <div className={classes.data}></div>
                          </div>
                        ) : (
                          cell
                        )
                      ) : null,
                    )}
                  </div>
                );
              })}
        </div>
      )}
    </div>
  );
};

export default ReportDocumentTracker;

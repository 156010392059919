import { Select, SelectProps } from '@timed/common';
import { AddressCountryCode } from '@timed/gql';
import { camelCase, startCase } from 'lodash';
import { RegisterOptions } from 'react-hook-form';

export const validateCountry: RegisterOptions = {
  validate: {
    validateCountry: (value) =>
      !value || value in AddressCountryCode || 'Invalid value',
  },
};

const AddressCountryInput = ({
  defaultValue,
  formControlProps,
  ...props
}: Omit<SelectProps, 'items'>) => (
  <Select
    formControlProps={{
      size: 'small',
      variant: 'outlined',
      ...formControlProps,
    }}
    defaultValue={defaultValue === null ? null : AddressCountryCode.Australia}
    validation={validateCountry}
    items={Object.values(AddressCountryCode).map((country) => {
      return {
        value: country,
        label: startCase(camelCase(country)),
      };
    })}
    renderValue={(value) => startCase(camelCase(value as string))}
    {...props}
  />
);

export default AddressCountryInput;

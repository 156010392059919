import { Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { _peopleMembers } from '@timed/app';
import { Protected } from '@timed/auth';
import {
  Block,
  Chip,
  IconButton,
  Link,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  capitaliseFirstLetter,
  formatPersonName,
} from '@timed/common';
import { formatCurrency } from '@timed/common/utils/formatCurrency';
import {
  Member,
  MemberBasePayRateType,
  MemberBasePayRatesOrderByInput,
  OrderBy,
  useGetMemberBasePayRatesLazyQuery,
} from '@timed/gql';
import { MemberBasePayRateActionMenuButton } from '@timed/member/components/BasePayRateActionMenuButton';
import MemberCreateBasePayRateFormModal from '@timed/member/components/CreateBasePayRateFormModal/CreateBasePayRateFormModal';
import { format } from 'date-fns';
import { DateTime } from 'luxon';
import { useModal } from 'mui-modal-provider';
import { useEffect, useState } from 'react';

type MemberUpdateBankDetailsFormProps = {
  member: Pick<Member, 'id' | 'firstName' | 'lastName'>;
};

const MemberBasePayRatesTable = ({
  member,
}: MemberUpdateBankDetailsFormProps) => {
  const { showModal } = useModal();

  const [orderBy, setOrderBy] = useState<MemberBasePayRatesOrderByInput>({
    date: OrderBy.DESC,
  });

  const [getBasePayRates, { data, loading }] =
    useGetMemberBasePayRatesLazyQuery({});

  useEffect(() => {
    getBasePayRates({
      variables: {
        input: {
          where: { member: { id: { _eq: member.id } } },
          orderBy: [orderBy],
        },
      },
    });
  }, [orderBy, getBasePayRates, member.id]);

  const handleClickCreateBasePayRateButton = () => {
    const modal: { hide: () => void } = showModal(
      MemberCreateBasePayRateFormModal,
      {
        onClose: () => {
          modal.hide();
        },
        member,
      },
    );
  };

  return (
    <Block
      title="Pay Rates"
      topRight={
        <Protected admin>
          <IconButton
            onClick={() => {
              handleClickCreateBasePayRateButton();
            }}
          >
            <AddIcon />
          </IconButton>
        </Protected>
      }
    >
      <Table inline enableRowHighlighting loading={loading}>
        <TableHeader
          order={orderBy.value}
          onClick={() => {
            setOrderBy({
              value:
                !!orderBy.value && orderBy.value === OrderBy.ASC
                  ? OrderBy.DESC
                  : OrderBy.ASC,
            });
          }}
        >
          Value
        </TableHeader>
        <TableHeader
          order={orderBy.type}
          onClick={() => {
            setOrderBy({
              type:
                !!orderBy.type && orderBy.type === OrderBy.ASC
                  ? OrderBy.DESC
                  : OrderBy.ASC,
            });
          }}
        >
          Type
        </TableHeader>
        <TableHeader
          order={orderBy.date}
          onClick={() => {
            setOrderBy({
              date:
                !!orderBy.date && orderBy.date === OrderBy.ASC
                  ? OrderBy.DESC
                  : OrderBy.ASC,
            });
          }}
        >
          Effective-from Date
        </TableHeader>
        <TableHeader>Status</TableHeader>
        <TableHeader
          order={orderBy.createdBy?.lastName}
          onClick={() => {
            setOrderBy({
              createdBy: {
                lastName:
                  !!orderBy.createdBy?.lastName &&
                  orderBy.createdBy.lastName === OrderBy.ASC_NULLS_LAST
                    ? OrderBy.DESC_NULLS_LAST
                    : OrderBy.ASC_NULLS_LAST,
              },
            });
          }}
        >
          Set By
        </TableHeader>
        <TableHeader align="center"></TableHeader>
        {data?.memberBasePayRates.map(
          ({ id, value, type, date, createdBy, active }, i) => {
            let style: React.CSSProperties = {};

            const notYetActive =
              (DateTime.fromFormat(date, 'yyyy-MM-dd', {
                zone: 'Australia/Sydney',
              })
                .diff(DateTime.now())
                .toObject().milliseconds ?? 0) > 0;

            return (
              <TableRow key={i}>
                <TableCell style={style}>
                  <Typography>{formatCurrency(value / 100)}</Typography>
                </TableCell>
                <TableCell style={style}>
                  <Chip
                    size="small"
                    color={
                      type === MemberBasePayRateType.ANNUAL
                        ? 'secondary'
                        : 'primary'
                    }
                    label={capitaliseFirstLetter(type.toLocaleLowerCase())}
                  />
                </TableCell>
                <TableCell style={style}>
                  <Typography>
                    {format(new Date(date), 'dd/MM/yyyy')}
                  </Typography>
                </TableCell>
                <TableCell style={style}>
                  {active && <Typography>Current</Typography>}
                  {notYetActive && (
                    <Typography color="error">Not yet active</Typography>
                  )}
                  {!notYetActive && !active && (
                    <Typography color="error">Historical rate</Typography>
                  )}
                </TableCell>
                <TableCell style={style}>
                  <Typography>
                    {createdBy ? (
                      <Link to={`${_peopleMembers.path}/${createdBy.id}`}>
                        {formatPersonName(createdBy, {
                          capitaliseLastName: true,
                          lastNameFirst: true,
                        })}
                      </Link>
                    ) : (
                      'System'
                    )}
                  </Typography>
                </TableCell>
                <TableCell style={style}>
                  <MemberBasePayRateActionMenuButton
                    basePayRate={{ id, date, value }}
                  />
                </TableCell>
              </TableRow>
            );
          },
        )}
      </Table>
    </Block>
  );
};

export default MemberBasePayRatesTable;

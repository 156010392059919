import {
  Box,
  BoxProps,
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import CalendarContext, {
  CalendarEventEntityPart,
} from '@timed/common/context/CalendarContext';
import {
  differenceInMinutes,
  format,
  formatDuration,
  getHours,
  getMinutes,
  isSameDay,
} from 'date-fns';
import { debounce } from 'lodash';
import { useModal } from 'mui-modal-provider';
import { useCallback, useContext, useMemo, useState } from 'react';

type CalendarEventProps = CalendarEventEntityPart;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      overflow: 'hidden',
      border: 'thin solid ' + theme.palette.divider,
      lineHeight: '13px',
      backgroundColor: '#fafafa',
      position: 'absolute',
      pointerEvents: 'all',
    },
    passiveOverlay: {
      display: 'block',
      position: 'absolute',
      width: '100%',
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 8 8'%3E%3Cg fill='%23000000' fill-opacity='0.15'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E")`,
    },
    circle: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      border: '1px solid white',
      display: 'inline-block',
      backgroundColor: red[500],
      marginRight: theme.spacing(0.25),
      '&::after': {
        position: 'absolute',
        top: 5,
        left: 1,
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: red[500],
        animation: '$circle 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    icons: {
      display: 'flex',
      gap: theme.spacing(0.25),
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    '@keyframes circle': {
      '0%': {
        transform: 'scale(1)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(4)',
        opacity: 0,
      },
    },
  }),
);

const CalendarEvent = (props: CalendarEventProps) => {
  const classes = useStyles();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const iOS = /iPad|iPhone|iPod/.test(navigator.platform);

  const [isHovered, setIsHovered] = useState(false);

  const { interactWithEntity, eventClickModal, eventClickModalProps } =
    useContext(CalendarContext);

  const { showModal } = useModal();

  const handleClick = useCallback(() => {
    if (!!eventClickModal) {
      const modal: { hide: () => void } = showModal(eventClickModal, {
        onClose: () => {
          modal.hide();
        },
        id: props.parent.id,
        ...eventClickModalProps,
      });
    }
  }, [showModal, eventClickModal, eventClickModalProps, props.parent.id]);

  const renderTime = () => {
    const startMinutes = getMinutes(props.parent.startAt);
    const endMinutes = getMinutes(props.parent.endAt);

    const sameAMPM =
      (getHours(props.parent.startAt) < 12 &&
        getHours(props.parent.endAt) < 12) ||
      (getHours(props.parent.startAt) > 12 &&
        getHours(props.parent.endAt) > 12);

    let prefix = '',
      suffix = '';

    if (startMinutes === 0) {
      if (sameAMPM && isSameDay(props.parent.startAt, props.parent.endAt)) {
        prefix = format(props.parent.startAt, 'h').toLowerCase();
      } else {
        prefix = format(
          props.parent.startAt,
          smDown ? 'h' : 'ha',
        ).toLowerCase();
      }
    } else {
      // Minutes === 01-59
      if (sameAMPM && isSameDay(props.parent.startAt, props.parent.endAt)) {
        prefix = format(props.parent.startAt, 'h:mm').toLowerCase();
      } else {
        prefix = format(
          props.parent.startAt,
          smDown ? 'h:mm' : 'h:mma',
        ).toLowerCase();
      }
    }

    if (endMinutes === 0) {
      if (sameAMPM && isSameDay(props.parent.startAt, props.parent.endAt)) {
        suffix = format(props.parent.endAt, smDown ? 'h' : 'ha').toLowerCase();
      } else {
        suffix = format(props.parent.endAt, smDown ? 'h' : 'ha').toLowerCase();
      }
    } else {
      // Minutes === 01-59
      if (sameAMPM && isSameDay(props.parent.startAt, props.parent.endAt)) {
        suffix = format(
          props.parent.endAt,
          smDown ? 'h:mm' : 'h:mma',
        ).toLowerCase();
      } else {
        suffix = format(
          props.parent.endAt,
          smDown ? 'h:mm' : 'h:mma',
        ).toLowerCase();
      }
    }

    return prefix + '-' + suffix;
  };

  const calcDuration = () => {
    return formatDuration(
      {
        hours: Math.floor(
          differenceInMinutes(props.parent.endAt, props.parent.startAt) / 60,
        ),
        minutes:
          differenceInMinutes(props.parent.endAt, props.parent.startAt) % 60,
      },
      { format: ['hours', 'minutes'], delimiter: ', ' },
    )
      .replace('hours', 'hrs')
      .replace('minutes', 'mins');
  };

  const debouncedHandleMouseEnter = debounce(() => setIsHovered(true), 500);

  const handleMouse = useCallback(
    ({ type }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      switch (type) {
        case 'mouseover':
          // Set hovered event id
          interactWithEntity({
            interaction: 'over',
            target: { key: props.parent.id, type: 'event' },
          });
          debouncedHandleMouseEnter();
          break;
        case 'mouseout':
          // Unset hovered event id
          interactWithEntity({ reset: true, target: { type: 'event' } });
          setIsHovered(false);
          debouncedHandleMouseEnter.cancel();
          break;
      }
    },
    [props.parent.id, interactWithEntity, debouncedHandleMouseEnter],
  );

  const interactionEvents = useMemo<BoxProps>(() => {
    return iOS
      ? { onClick: handleClick }
      : {
          onClick: handleClick,
          onMouseOver: handleMouse,
          onMouseOut: handleMouse,
        };
  }, [iOS, handleMouse, handleClick]);

  return (
    <Box
      {...interactionEvents}
      className={classes.wrapper}
      style={{
        ...props.parent.style,
        top: props.topOffset + '%',
        left: props.leftOffset + '%',
        width: props.width + '%',
        height: props.height + '%',
        cursor: 'pointer',
      }}
    >
      {props.parent.content}
    </Box>
  );
};

export default CalendarEvent;

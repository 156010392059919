import { Typography } from '@material-ui/core';
import { CalendarEventEntity, useRouter } from '@timed/common';
import {
  MemberUnavailablesWhereInput,
  useMemberViewUnavailablesGetUnavailablesLazyQuery,
} from '@timed/gql';
import {
  MemberContext,
  MemberViewUnavailabilityContext,
  MemberViewUnavailabilityContextType,
} from '@timed/member';
import { addMonths, differenceInMinutes, parse, startOfWeek } from 'date-fns';
import { useContext, useEffect, useMemo } from 'react';

const MemberUnavailabilityProvider: React.FC = ({ children }) => {
  const {
    search: [searchParams],
  } = useRouter();

  const member = useContext(MemberContext);

  const [getUnavailables, { data, refetch, called }] =
    useMemberViewUnavailablesGetUnavailablesLazyQuery({
      notifyOnNetworkStatusChange: true,
    });

  /**
   * First date to display on the calendar.
   * Memoisation is requred to prevent infinite loops.
   */
  const from = useMemo(() => {
    return (
      (!!searchParams.get('f') &&
        parse(searchParams.get('f')!, 'ddMMyyyy', new Date())) ||
      startOfWeek(new Date(), { weekStartsOn: 1 })
    );
  }, [searchParams]);

  const where: MemberUnavailablesWhereInput = useMemo(
    () => ({
      owner: {
        id: {
          _eq: member.id,
        },
      },
      startAt: {
        _lt: addMonths(from, 3),
      },
      endAt: {
        _gte: from,
      },
      deletedAt: { _eq: null },
    }),
    [from, member.id],
  );

  /**
   * Fetch subsequent data.
   */
  const fetch: MemberViewUnavailabilityContextType['fetch'] = () =>
    refetch({ input: { where } });

  /**
   * Fetch initial data.
   */
  useEffect(() => {
    if (!called)
      getUnavailables({
        variables: {
          input: {
            where,
          },
        },
      });
  });

  /**
   * Refetch data when needed.
   */
  useEffect(() => {
    if (called) refetch({ input: { where } });
  }, [called, where, refetch]);

  /**
   * Formatted unavailables
   */
  const unavailables: CalendarEventEntity[] = useMemo<CalendarEventEntity[]>(
    () =>
      !!data
        ? data.memberUnavailables.flatMap(({ dates, ...u }) =>
            dates.map(([startAt, endAt], i) => {
              startAt = new Date(startAt);
              endAt = new Date(endAt);

              return {
                id: u.id, // `${u.id}-${i}`,
                startAt,
                endAt,
                duration: differenceInMinutes(endAt, startAt),
                content: <Typography>{u.notes}</Typography>,
                style:
                  u.createdBy.id !== u.owner.id
                    ? { backgroundColor: '#FF0000' }
                    : undefined,
              };
            }),
          )
        : [],
    [data],
  );

  return (
    <MemberViewUnavailabilityContext.Provider value={{ fetch, unavailables }}>
      {children}
    </MemberViewUnavailabilityContext.Provider>
  );
};

export default MemberUnavailabilityProvider;

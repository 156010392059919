import { createStyles, IconButton, makeStyles, Theme } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { CalendarContext } from '@timed/common';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: theme.spacing(10),
      backgroundColor: theme.palette.background.default,
      border: '1px solid ' + theme.palette.divider,
      borderRadius: 0,
      [theme.breakpoints.down('sm')]: {
        width: theme.spacing(8),
      },
    },
  }),
);

const CalendarDateForwardButton = () => {
  const classes = useStyles();
  const { setFrom, scrollableAreaRef } = useContext(CalendarContext);

  return (
    <IconButton
      className={classes.button}
      size="small"
      aria-label="Forward"
      onClick={() => {
        setFrom(7);
        if (scrollableAreaRef?.current?.scrollLeft)
          scrollableAreaRef.current.scrollLeft = 0;
      }}
    >
      <ChevronRightIcon />
    </IconButton>
  );
};

export default CalendarDateForwardButton;

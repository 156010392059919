import {
  Box,
  makeStyles,
  SwipeableDrawer,
  Tab,
  Tabs,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { createStyles } from '@material-ui/styles';
import {
  _activities,
  _people,
  _reports,
  _settings,
  _support,
} from '@timed/app';
import { useAuth } from '@timed/auth';
import { useRouter } from '@timed/common';
import { Permission } from '@timed/gql';
import { NavBarContext } from '@timed/nav-bar/context';
import { usePage } from '@timed/page';
import { useContext } from 'react';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';

type TabItem = {
  label: string;
  path: string;
  redirect: string | undefined;
  icon: JSX.Element;
  admin?: boolean;
  permissions?: Permission | Permission[];
  notification?: {
    content: boolean | number;
    permissions?: Permission | Permission[];
    admin?: boolean;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: '1 0 auto',
      display: 'flex',
      flexDirection: 'column',
      top: 'initial',
      height: 'initial',
      position: 'initial',
      zIndex: 'initial',
      '&.MuiDrawer-paperAnchorDockedLeft': {
        border: 'none',
      },
      // [theme.breakpoints.up('sm')]: {
      //   position: 'inherit',
      // },
    },

    wrapper: {
      // flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      '& .MuiDrawer-root': {
        display: 'flex',
        flexGrow: 1,
      },
    },
    tabs: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      minWidth: theme.spacing(16),
      [theme.breakpoints.down('sm')]: {
        minWidth: theme.spacing(12),
      },
    },
    tab: {
      lineHeight: 'initial',
      textTransform: 'none',
      fontSize: 11,
      minWidth: 'inherit', // Needed to overwrite MuiTab's default minWidth
      [theme.breakpoints.down('sm')]: {
        fontSize: 8,
      },
      '&.MuiTab-root': {
        padding: 0,
        height: theme.spacing(16),
        [theme.breakpoints.down('sm')]: {
          height: theme.spacing(12),
        },
      },
      '& .MuiTab-wrapper': {
        '& :first-child': {
          marginBottom: theme.spacing(0.25),
        },
      },
      '&.MuiTab-labelIcon': {
        minHeight: 'initial',
        // margin: theme.spacing(3, 0),
      },
      '& .MuiSvgIcon-root': {
        fontSize: theme.spacing(6),
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.background.default,
      },
      '&:last-child': {
        position: 'absolute',
        bottom: 0,
        width: '100%',
      },
      '&:nth-last-child(2)': {
        position: 'absolute',
        bottom: theme.spacing(16),
        [theme.breakpoints.down('sm')]: {
          height: theme.spacing(12),
        },
        width: '100%',
      },
      '& span': {
        // padding: theme.spacing(0, 1),
      },
    },
    // more: {
    //   marginTop: theme.spacing(4),
    //   color: theme.palette.text.primary,
    // },
    indicator: {
      left: 0,
      width: theme.spacing(1),
      [theme.breakpoints.down('lg')]: {
        width: theme.spacing(0.5),
      },
    },
    notificationBadge: {
      position: 'absolute',
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      right: 4,
      top: 4,
      backgroundColor: 'red',
      borderRadius: 4,
    },
  }),
);

const NavBarSupportCoordination = () => {
  const classes = useStyles();
  const { pathname, navigate } = useRouter();
  const { permissible } = useAuth();
  const { getDirectPagePath } = usePage();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { isVisible, toggleDrawer } = useContext(NavBarContext);

  const menuItems: TabItem[] = [
    {
      label: _activities.label,
      icon: <TimerOutlinedIcon />,
      path: _activities.path,
      redirect: _activities.redirect,
    },
    {
      label: _people.label,
      icon: <PeopleOutlineIcon />,
      path: _people.path,
      permissions: _people.permissions,
      redirect: _people.redirect,
    },

    {
      label: _reports.label,
      icon: <AssignmentOutlinedIcon />,
      path: '',
      redirect: '',
    },
    {
      label: _settings.label,
      icon: <SettingsOutlinedIcon />,
      path: _settings.path,
      redirect: _settings.redirect,
      permissions: _settings.permissions,
    },
    {
      label: _support.label,
      icon: <HelpOutlineOutlinedIcon />,
      path: _support.path,
      redirect: _support.redirect,
      permissions: _support.permissions,
    },
  ];

  const currentTab = pathname.split('/', 2)[1];

  const handleClick =
    (link: string) => (_: React.KeyboardEvent | React.MouseEvent) =>
      link !== currentTab && navigate(link);

  return (
    <Box className={classes.wrapper}>
      <SwipeableDrawer
        open={isVisible || !smDown}
        anchor="left"
        onOpen={toggleDrawer(true)}
        onClose={toggleDrawer(false)}
        variant={'permanent'}
        PaperProps={{ className: classes.root }}
        ModalProps={{ keepMounted: true }}
        style={{
          display: isVisible || !smDown ? 'flex' : 'none',
        }}
      >
        <Tabs
          orientation="vertical"
          value={'/' + currentTab}
          aria-label="Nav bar"
          className={classes.tabs}
          TabIndicatorProps={{ className: classes.indicator }}
        >
          {menuItems.map(
            (tab, index) =>
              permissible({
                admin: tab.admin,
                permissions: tab.permissions,
              }) && (
                <Tab
                  key={index}
                  label={
                    <>
                      {tab?.label}
                      {!!tab.notification?.content && (
                        <Box className={classes.notificationBadge}>
                          {tab.notification?.content}
                        </Box>
                      )}
                    </>
                  }
                  icon={tab?.icon}
                  className={classes.tab}
                  value={tab.path}
                  onClick={handleClick(getDirectPagePath(tab))}
                />
              ),
          )}
        </Tabs>
      </SwipeableDrawer>
    </Box>
  );
};

export default NavBarSupportCoordination;

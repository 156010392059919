import { Select, SelectProps, capitaliseFirstLetter } from '@timed/common';
import { MemberBasePayRateType } from '@timed/gql';
import { RegisterOptions } from 'react-hook-form';

export const validateTimezone: RegisterOptions = {
  validate: {
    validTimezone: (value) => value in MemberBasePayRateType || 'Invalid value',
  },
};

const MemberBasePayRateTypeInput = ({
  defaultValue,
  ...props
}: Omit<SelectProps, 'items'>) => {
  return (
    <Select
      label="Type"
      formControlProps={{ variant: 'outlined', size: 'small' }}
      defaultValue={defaultValue || MemberBasePayRateType.HOURLY}
      validation={validateTimezone}
      items={Object.entries(MemberBasePayRateType).map(([label, value]) => ({
        label: capitaliseFirstLetter(label.toLocaleLowerCase()),
        value,
      }))}
      {...props}
    />
  );
};

export default MemberBasePayRateTypeInput;

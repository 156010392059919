import {
  Box,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { DateInput, FormModal, ModalProps } from '@timed/common';
import {
  ClientNdisPlan,
  UpdateClientNdisPlanInput,
  useUpdateClientNdisPlanMutation,
} from '@timed/gql';
import { addYears, format } from 'date-fns';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        alignItems: 'start',
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
        flexDirection: 'column',
      },
    },
    input: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    textIconWrapper: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
  }),
);

type ClientUpdateNdisPlanFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  clientNdisPlan: Pick<ClientNdisPlan, 'id' | 'startAt' | 'endAt'>;
};

type FormData = UpdateClientNdisPlanInput;

const ClientUpdateNdisPlanForm = ({
  onClose,
  clientNdisPlan,
  ...modalProps
}: ClientUpdateNdisPlanFormModalProps) => {
  const classes = useStyles();

  const [updateEntity, response] = useUpdateClientNdisPlanMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      patch: {
        startAt: clientNdisPlan.startAt ? clientNdisPlan.startAt : null,
        endAt: clientNdisPlan.endAt ? clientNdisPlan.endAt : null,
      },
    },
  });

  const onSubmit = ({ patch }: FormData) => {
    updateEntity({
      variables: {
        input: { id: clientNdisPlan.id, patch },
      },
    });
  };

  return (
    <FormModal
      modalProps={modalProps}
      title="Update NDIS Plan"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.input}>
          <Typography className={classes.bold}>Start date of Plan</Typography>
          <DateInput
            required
            keyboard
            clearable
            disableTime
            control={control}
            openTo="year"
            name="patch.startAt"
            inputVariant="outlined"
            size="small"
            error={!!errors.patch?.startAt}
            helperText={errors.patch?.startAt?.message}
            InputAdornmentProps={{ style: { display: 'none' } }}
            minDate={'2013-07-01'}
            maxDate={format(addYears(new Date(), 1), 'yyyy-MM-dd')}
          />
        </Box>
        <Box className={classes.input}>
          <Typography className={classes.bold}>End date of Plan</Typography>
          <DateInput
            keyboard
            clearable
            disableTime
            control={control}
            openTo="year"
            name="patch.endAt"
            inputVariant="outlined"
            size="small"
            error={!!errors.patch?.endAt}
            helperText={errors.patch?.endAt?.message}
            InputAdornmentProps={{ style: { display: 'none' } }}
            minDate={'2013-07-01'}
            maxDate={format(addYears(new Date(), 3), 'yyyy-MM-dd')}
          />
        </Box>
      </Box>
    </FormModal>
  );
};

export default ClientUpdateNdisPlanForm;

import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { TableContext } from '@timed/common';
import { useContext } from 'react';
import AutorenewIcon from '@material-ui/icons/Autorenew';

const TableLoading = () => {
  const { loading, alphaMuliplier, backgroundColor, backgroundIsDark } =
    useContext(TableContext);

  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      loading: {
        position: 'absolute',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backdropFilter: 'blur(8px)',
        zIndex: 1200,
        top: 0,
        bottom: 0,
        '& .MuiSvgIcon-root': {
          fontSize: 48,
          fill: `color-mix(in srgb, ${backgroundIsDark ? 'white' : 'black'} ${
            30 * alphaMuliplier
          }%, ${backgroundColor})`,
          [theme.breakpoints.up('md')]: {
            fontSize: 64,
          },
        },
      },
      spin: {
        animation: '$spin 900ms linear infinite',
      },
      '@keyframes spin': {
        '0%': { transform: 'rotate(0deg)' },
        '100%': { transform: 'rotate(360deg)' },
      },
    });
  });

  const classes = useStyles();

  return !loading ? null : (
    <div className={classes.loading}>
      <AutorenewIcon className={classes.spin} />
    </div>
  );
};

export default TableLoading;

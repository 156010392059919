import { TableContext, TableHeaderProps } from '@timed/common';
import StraightIcon from '@mui/icons-material/Straight';
import { CSSProperties, useContext } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const TableHeader = ({
  children,
  onClick,
  hidden = false,
  align,
  className,
  order,
  sticky: stickyColumn,
  style,
  title,
  index = 0,
  ...props
}: TableHeaderProps) => {
  const {
    columnRefs,
    columnWidths,
    showIndexColumn: showRowIndexes,
    backgroundColor,
    alphaMuliplier,
    backgroundIsDark,
  } = useContext(TableContext);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      interactable: {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: `color-mix(in srgb, ${
            backgroundIsDark ? 'white' : 'black'
          } ${10 * alphaMuliplier}%, ${backgroundColor})!important`,
        },
      },
      flipped: {
        transform: 'rotate(180deg)',
        WebkitTransform: 'rotate(180deg)',
        msTransform: 'rotate(180deg)',
      },
    }),
  );
  const classes = useStyles();

  let ascending: boolean = false;
  let descending: boolean = false;

  switch (order) {
    case 'ASC':
    case 'ASC_NULLS_FIRST':
    case 'ASC_NULLS_LAST':
      ascending = true;
      break;
    case 'DESC':
    case 'DESC_NULLS_FIRST':
    case 'DESC_NULLS_LAST':
      descending = true;
      break;
  }

  let style2: CSSProperties = { ...style };

  // let style: CSSProperties = { ...props?.style };
  let wrapperStyle: CSSProperties = {};
  const columnIndex = showRowIndexes ? index + 1 : index;

  // Apply widths if a width has explicitly been set.
  if (style?.width !== undefined) {
    // style2.width = style?.width;
    wrapperStyle.width = style?.width;
  }

  // Apply offset for sticky cell
  if (stickyColumn)
    style2 = {
      zIndex: index + 1001,
      left:
        !showRowIndexes && columnIndex === 0
          ? 0 // No offset if this is the first column
          : !!columnWidths.length
          ? columnWidths.slice(0, columnIndex).reduce((a, b) => a + b)
          : 0,
      ...style2,
    };

  if (!!align && align !== 'left')
    wrapperStyle = {
      justifyContent: align,
      ...wrapperStyle,
    };

  if (!!onClick) className = clsx(classes.interactable, className);

  return hidden ? null : (
    <th
      key={columnIndex}
      ref={(el) => (columnRefs.current[columnIndex] = el)}
      title={title}
      onClick={onClick}
      style={style2}
      className={className}
      {...props}
    >
      <div className="wrapper" style={wrapperStyle}>
        {children}
        {ascending && <StraightIcon />}
        {descending && <StraightIcon className={classes.flipped} />}
      </div>
    </th>
  );
};

export default TableHeader;

/**
 * Phone regex
 * - May include a '+' at the beginning
 * - May contain spaces, tabs and line breaks
 * - May contain up to four digits inside a pair of parentheses at any point
 *   but only once.
 * - May contain periods in any section other than the parentheses
 * - May contain hypens in any section unless there are parentheses, in which
 *   case they are only permissible after the parentheses.
 * - Cannot include alphabetical characters
 */
export const phoneRegex = /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/;

import {
  Box,
  createStyles,
  Hidden,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { _schedule } from '@timed/app';
import { Protected } from '@timed/auth';
import { Permission } from '@timed/gql';
import { Page } from '@timed/page';
import {
  ScheduleCalendar,
  ScheduleControlBar,
  ScheduleMemberInfoList2,
  ScheduleMemberInfoListVisibilityButton,
  ScheduleProvider,
} from '@timed/schedule';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      flexGrow: 1,
      display: 'flex',
      gap: theme.spacing(4),
    },
    left: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    calendar: {
      flexGrow: 1,
      overflowX: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

const RouteCoreSupportSchedule = () => {
  const classes = useStyles();

  return (
    <Page {..._schedule}>
      <ScheduleProvider>
        <div className={classes.wrapper}>
          <Protected permissions={Permission.EVENT_WRITE}>
            <Hidden smDown>
              <Box className={classes.left}>
                <Protected tester>
                  <ScheduleMemberInfoListVisibilityButton />
                </Protected>
                <ScheduleMemberInfoList2 />
              </Box>
            </Hidden>
          </Protected>
          <Box className={classes.calendar}>
            <ScheduleControlBar />
            <ScheduleCalendar />
          </Box>
        </div>
      </ScheduleProvider>
    </Page>
  );
};

export default RouteCoreSupportSchedule;

import { Checkbox, CheckboxProps } from '@timed/common';

const ClientEventsRequireHandoverCheckbox = (props: CheckboxProps) => (
  <Checkbox
    defaultChecked={false}
    label="Shifts require handovers"
    {...props}
  />
);

export default ClientEventsRequireHandoverCheckbox;

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import { IconButton } from '@timed/common';
import { NavBarContext } from '@timed/nav-bar/context';
import { useContext } from 'react';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { NavBarLogo } from '@timed/nav-bar/components/Logo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.appBar.main,
      width: theme.spacing(12),
      height: theme.spacing(12),
    },
    icon: {
      color: theme.palette.getContrastText(theme.palette.appBar.main),
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  }),
);

const NavBarMenuIcon = () => {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { isVisible, toggleDrawer } = useContext(NavBarContext);

  return smDown ? (
    <Box className={classes.wrapper}>
      <IconButton className={classes.icon} onClick={toggleDrawer(!isVisible)}>
        {isVisible ? <MenuOpenIcon /> : <MenuRoundedIcon />}
      </IconButton>
    </Box>
  ) : (
    <NavBarLogo />
  );
};

export default NavBarMenuIcon;

import {
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { useAuth } from '@timed/auth';
import { formatPersonName, Select, validateUuid } from '@timed/common';
import { OrderBy, useGetMembersSelectLazyQuery } from '@timed/gql';
import { NotesContext } from '@timed/notes/context';
import { ChangeEvent, useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loading: {
      '& .MuiOutlinedInput-root.Mui-disabled': {
        backgroundColor: theme.palette.background.default,
      },
    },
    input: {
      flexGrow: 1,
      minWidth: theme.spacing(48),
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.down('sm')]: {
        minWidth: theme.spacing(22),
        padding: theme.spacing(0, 1, 0, 2),
        '& .MuiSelect-root': {
          fontSize: 12,
        },
      },
      '& .MuiSelect-root': {
        paddingRight: 0,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.divider,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': {
        fontSize: '1rem',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1.625, 0),
          fontSize: 'initial',
        },
      },
    },
    item: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.between('md', 'xl')]: {
        lineHeight: 1,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
    icon: {
      margin: theme.spacing(0, 2),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 0, 0, 1),
      },
    },
    formControl: {
      [theme.breakpoints.down('sm')]: {
        '& .MuiFormLabel-root': {
          fontSize: 12,
          padding: theme.spacing(1),
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
          transform: 'translate(4px, 6px) scale(1)',
        },
        '& .MuiSelect-iconOutlined': {
          right: 0,
        },
      },
    },
  }),
);

const NotesControlCreatorInput = () => {
  const classes = useStyles();

  const { branch } = useAuth();

  const {
    input: { createdBy, setCreatedBy },
  } = useContext(NotesContext);

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [getMembers, { data, loading }] = useGetMembersSelectLazyQuery();

  const { control, reset, watch, setValue } = useForm<{ id: string }>({
    defaultValues: { id: createdBy || '' },
  });

  const selected = watch('id');

  useEffect(() => {
    getMembers({
      variables: {
        input: {
          where: {
            branchMembers: !!branch
              ? { branch: { id: { _eq: branch.id } } }
              : undefined,
          },
          orderBy: [{ lastName: OrderBy.ASC }, { firstName: OrderBy.ASC }],
        },
      },
    });
  }, [branch, getMembers]);

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setTimeout(() => (document.activeElement as HTMLElement).blur(), 0); // Unselect select field
    setCreatedBy(event.target.value as string);
  };

  /**
   * Handle clear value.
   */
  const handleClear = useCallback(() => {
    reset({ id: '' });
    setCreatedBy(null);
  }, [reset, setCreatedBy]);

  useEffect(() => {
    if (
      selected &&
      !!data?.members?.length &&
      !data.members.map(({ id }) => id).includes(selected)
    ) {
      handleClear();
    }
  }, [selected, data, setValue, handleClear]);

  useEffect(() => {
    if (selected !== createdBy) setValue('id', createdBy ?? '');
  }, [createdBy, selected, setValue]);

  return (
    <Select
      name="id"
      label={
        loading
          ? 'Loading...'
          : selected
          ? !smDown
            ? 'Importor'
            : undefined
          : 'Importor'
      }
      control={control}
      validation={validateUuid}
      onChange={handleChange}
      className={classes.input}
      IconComponent={
        !!selected
          ? () => (
              <IconButton
                size="small"
                className={classes.icon}
                onClick={handleClear}
                onMouseDown={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              >
                <CloseRoundedIcon fontSize="small" />
              </IconButton>
            )
          : undefined
      }
      formControlProps={{
        variant: 'outlined',
        size: 'small',
        disabled: loading || !data?.members?.length,
        className: classes.formControl,
      }}
      items={data?.members?.map((entity) => ({
        value: entity.id,
        label: formatPersonName(entity, {
          capitaliseLastName: true,
          lastNameFirst: true,
          preferredAndLast: true,
        })!,
      }))}
      renderValue={(value) => (
        <Typography variant="body1" className={classes.item}>
          {data?.members.find(({ id }) => id === value)
            ? formatPersonName(data.members.find(({ id }) => id === value)!, {
                lastNameFirst: true,
                capitaliseLastName: true,
                preferredAndLast: true,
              })
            : ''}
        </Typography>
      )}
    />
  );
};

export default NotesControlCreatorInput;

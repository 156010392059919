import {
  Box,
  Collapse,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useAuth } from '@timed/auth';
import {
  Block,
  DataSize,
  formatPersonName,
  IconButton,
  Link,
} from '@timed/common';
import {
  File,
  GetMemberFilesQuery,
  Maybe,
  Member,
  MemberFileType,
  OrderBy,
  PersonNamesPartialFragment,
  useGetMemberFilesLazyQuery,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import {
  MemberContext,
  MemberFileCategory,
  MemberFileMenuButton,
  memberFilesMetadata,
  MemberFileUploadButton,
} from '@timed/member';
import { format, isAfter } from 'date-fns';
import { Fragment, useContext, useEffect, useMemo, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      gap: theme.spacing(8),
      flex: '1 1 auto',
      display: 'flex',
      overflowY: 'hidden',
      flexDirection: 'column',
    },
    tableWrapper: { display: 'flex', overflow: 'hidden' },
    paper: {
      // overflowY: "auto",
      flex: '1 1 auto',
      gridTemplateRows: 'min-content auto',
    },
    block: {
      backgroundColor: theme.palette.background.paper2,
      padding: 0 + ' !important',
      marginBottom: theme.spacing(4),
    },
    disabled: {
      backgroundColor: theme.palette.action.disabledBackground,
    },
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    actions: {
      display: 'inline-grid',
      gridAutoFlow: 'column',
      gridAutoColumns: 'max-content',
      gap: theme.spacing(1),
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },

    file: {
      cursor: 'pointer',
      '& .MuiTableCell-root': { borderBottom: 'none' },
    },
    container: {
      padding: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        maxWidth: 'max-content',
      },
    },
  }),
);

type FormattedData = {
  type: MemberFileType;
  name: string;
  category?: MemberFileCategory;
  updated: Date;
  files: {
    id: File['id'];
    size: string;
    name: string;
    createdAt: string;
    createdBy: GetMemberFilesQuery['memberFiles'][0]['file']['createdBy'];
    dateOfFile?: string;
    expiresAt?: string;
    issuedAt?: string;
    value?: Maybe<string>;
    raw: GetMemberFilesQuery['memberFiles'][0];
    processedAt?: Date;
    processedBy?: (Pick<Member, 'id'> & PersonNamesPartialFragment) | null;
    processingNote?: string | null;
  }[];
};

const formatData = (
  files: GetMemberFilesQuery['memberFiles'],
): FormattedData[] => {
  const result: FormattedData[] = [];

  files.forEach((file: GetMemberFilesQuery['memberFiles'][0]) => {
    const { category, name } = memberFilesMetadata.find(
      (meta) => meta.id === file.type,
    )!;

    const {
      dateOfFile,
      type,
      expiresAt,
      issuedAt,
      value,
      memberUpload,
      file: { id, createdAt, createdBy, size, name: fileName },
    } = file;

    !result.find((result) => result.type === type) &&
      result.push({
        type,
        category,
        name,
        updated: new Date(createdAt),
        files: [],
      });

    // If a new file is processed belonging to an existing file type, update
    // 'updated' property if new file is chronologically after any previous file
    if (
      result.find((result) => result.name === name) &&
      isAfter(
        new Date(createdAt),
        result.find((result) => result.name === name)!.updated,
      )
    ) {
      result.find((result) => result.category === category)!.updated = new Date(
        createdAt,
      );
    }

    result
      .find((result) => result.type === type)!
      .files.push({
        id,
        createdBy,
        createdAt: format(new Date(createdAt), 'd MMM yyyy'),
        dateOfFile: dateOfFile && format(new Date(dateOfFile), 'd MMM yyyy'),
        expiresAt: expiresAt && format(new Date(expiresAt), 'd MMM yyyy'),
        issuedAt: issuedAt && format(new Date(issuedAt), 'd MMM yyyy'),
        value,
        name: fileName,
        size:
          (size > DataSize.MB
            ? size / DataSize.MB
            : size / DataSize.KB
          ).toFixed(2) + (size > DataSize.MB ? 'MB' : 'KB'),
        raw: file,
        processedAt: memberUpload?.processedAt,
        processedBy: memberUpload?.processedBy,
        processingNote: memberUpload?.processingNote,
      });
  });

  return result.sort((a, b) => (a.name < b.name ? -1 : 1));
};

function Row({
  category,
  files,
  name,
  updated,
  last,
}: FormattedData & {
  last: boolean;
}) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const auth = useAuth();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const {
    hasDate,
    hasExpiryDate,
    hasIssueDate,
    hasNote: hasValue,
  } = memberFilesMetadata.find((meta) => meta.name === name)!;

  return (
    <Fragment>
      <TableRow className={classes.file} onClick={() => setOpen(!open)}>
        <TableCell component="th" scope="row" align="left">
          {name}
        </TableCell>
        {mdUp && (
          <>
            <TableCell>{category}</TableCell>
            <TableCell align="right">{format(updated, 'd MMM yyyy')}</TableCell>
          </>
        )}
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow className={last ? classes.root : undefined}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Block paperProps={{ className: classes.block }}>
              <Table size="small" aria-label="files">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Upload Date</TableCell>
                    {mdUp && (
                      <>
                        <TableCell>Uploader</TableCell>
                        {hasDate && <TableCell>File Date</TableCell>}
                        {hasExpiryDate && <TableCell>Expiry</TableCell>}
                        {hasIssueDate && <TableCell>Issued</TableCell>}
                        {hasValue && <TableCell>Notes</TableCell>}
                        <TableCell>File Name</TableCell>
                        <TableCell>File Size</TableCell>
                        {files.some(({ processedAt }) => !!processedAt) && (
                          <TableCell>Processed At</TableCell>
                        )}
                        {files.some(({ processedBy }) => !!processedBy) && (
                          <TableCell>Processed By</TableCell>
                        )}
                        {files.some(
                          ({ processingNote }) => !!processingNote,
                        ) && <TableCell>Processing Note</TableCell>}
                      </>
                    )}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.map((file, index) => (
                    <TableRow
                      key={index}
                      className={
                        index === files.length - 1 ? classes.file : undefined
                      }
                    >
                      <TableCell align="left">{file.createdAt}</TableCell>

                      {mdUp && (
                        //mdUp ? "right" : "left"}
                        <>
                          <TableCell>
                            <Link to={'../../' + file.createdBy.id}>
                              {formatPersonName(file.createdBy)!}
                            </Link>
                          </TableCell>{' '}
                          {hasDate && (
                            <TableCell component="th" scope="row">
                              {file.dateOfFile ? file.dateOfFile : '-'}
                            </TableCell>
                          )}
                          {hasExpiryDate && (
                            <TableCell>
                              {file.expiresAt ? file.expiresAt : '-'}
                            </TableCell>
                          )}
                          {hasIssueDate && (
                            <TableCell>
                              {file.issuedAt ? file.issuedAt : '-'}
                            </TableCell>
                          )}
                          {hasValue && (
                            <TableCell>
                              {file.value ? file.value : '-'}
                            </TableCell>
                          )}
                          <TableCell>{file.name}</TableCell>
                          <TableCell>{file.size}</TableCell>
                          <TableCell>
                            {!!file.processedAt
                              ? format(
                                  new Date(file.processedAt),
                                  'HH:mm dd/MM/yyyy',
                                )
                              : ''}
                          </TableCell>
                          <TableCell>
                            {!!file.processedBy
                              ? formatPersonName(file.processedBy, {
                                  capitaliseLastName: true,
                                  lastNameFirst: true,
                                })
                              : ''}
                          </TableCell>
                          <TableCell>
                            {!!file.processingNote ? file.processingNote : ''}
                          </TableCell>
                        </>
                      )}
                      <TableCell align="right">
                        <Box className={classes.actions}>
                          <MemberFileMenuButton
                            auth={auth}
                            file={{ ...file.raw }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Block>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export type MemberFileActions = 'download' | 'edit' | 'delete' | undefined;

const MemberViewFiles = () => {
  const classes = useStyles();
  const member = useContext(MemberContext);
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const [getMemberFiles, memberResponse] = useGetMemberFilesLazyQuery();

  const formattedData = useMemo(
    () => memberResponse.data && formatData(memberResponse.data.memberFiles),
    [memberResponse.data],
  );

  useLoadingEffect(memberResponse.loading);

  useEffect(() => {
    if (!memberResponse.data)
      getMemberFiles({
        variables: {
          input: {
            where: { owner: { id: { _eq: member.id } } },
            orderBy: [{ dateOfFile: OrderBy.ASC }],
          },
        },
      });
  }, [getMemberFiles, memberResponse.data, member.id]);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.container}>
        <MemberFileUploadButton />
        <Typography variant="body1" style={{ fontSize: 12 }}>
          Maximum file size 15MB.
        </Typography>
        <Typography style={{ fontSize: 12 }}>
          Accepted file types: JPG, PNG, GIF, PDF, DOCX, XLSX.
        </Typography>
      </Box>

      <Block
        title="File Categories"
        paperProps={{ style: { maxWidth: 'max-content', flexGrow: 0 } }}
        titleProps={{ variant: 'h3' }}
      >
        <Typography style={{ fontSize: 14 }}>
          0. Miscellaneous
          <br />
          1. Certification
          <br />
          2. Employment
          <br />
          3. Financial
          <br />
          4. Identification
          <br />
          5. Personal
          <br />
          6. Medical
        </Typography>
      </Block>

      {!formattedData || !memberResponse.data || memberResponse.loading ? (
        <>Loading...</>
      ) : (
        <Box className={classes.tableWrapper}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" size="small">
              <TableHead>
                <TableRow>
                  {formattedData.length === 0 ? (
                    <TableCell>No files</TableCell>
                  ) : (
                    <>
                      <TableCell align="left">Document</TableCell>
                      {mdUp && (
                        <>
                          <TableCell>Category</TableCell>
                          <TableCell align="right">Last Updated</TableCell>
                        </>
                      )}
                      <TableCell />
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {formattedData.map((row, index) => (
                  <Row
                    key={index}
                    last={index === formattedData?.length! - 1}
                    {...row}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default MemberViewFiles;

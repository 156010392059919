import { TextField, TextFieldProps } from '@timed/common';

export type NumberInputProps = TextFieldProps & {
  min?: number;
  max?: number;
  step?: string;
};

const NumberInput = ({ min, max, step, ...props }: NumberInputProps) => (
  <TextField
    type="number"
    inputProps={{ min, max, step: step ?? 1 }}
    {...props}
  />
);

export default NumberInput;

import {
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { ShiftsGrabList } from '@timed/shifts/components/GrabList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
      },
    },
    list: {
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(2),
        flexDirection: 'column',
      },
      [theme.breakpoints.up('md')]: {
        flexWrap: 'wrap',
        gap: theme.spacing(4),
      },
    },
    green: {
      padding: theme.spacing(1),
      backgroundColor: green[300],
    },
    grey: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper2,
    },
  }),
);

const ShiftsGrab = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Typography variant="h1">Grab Shifts</Typography>
      <Typography>
        Pick your desired shifts from available upcoming shifts. Your employer
        will process your claims.
      </Typography>
      <Typography>
        <span className={classes.green}>Green shifts</span> have been grabbed by
        you.
      </Typography>
      <Typography>
        <span className={classes.grey}>Grey shifts</span> are available to grab.
      </Typography>
      <Divider />
      <div className={classes.list}>
        <ShiftsGrabList />
      </div>
    </div>
  );
};

export default ShiftsGrab;

import { ButtonProps } from '@material-ui/core';
import { Button } from '@timed/common';
import { useAutomaticallyAssignAttendeeMutation } from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { useEffect } from 'react';

type EventAssignAttendeeAutomaticallyButtonProps = ButtonProps & {
  eventId: string;
  setErrorText: React.Dispatch<React.SetStateAction<string | null>>;
};

const EventAssignAttendeeAutomaticallyButton = ({
  eventId,
  setErrorText,
  ...props
}: EventAssignAttendeeAutomaticallyButtonProps) => {
  const [assignAttendee, { loading, error }] =
    useAutomaticallyAssignAttendeeMutation({
      variables: { input: { id: eventId } },
    });

  useLoadingEffect(loading);

  useEffect(() => {
    if (!!error) setErrorText(error.message);
  }, [error, setErrorText]);

  return (
    <Button
      variant="contained"
      color="primary"
      // style={{ fontSize: 11, lineHeight: 1.273 }}
      onClick={() => {
        assignAttendee();
      }}
      {...props}
    >
      Auto Assign
    </Button>
  );
};

export default EventAssignAttendeeAutomaticallyButton;

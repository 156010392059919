import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useAuth } from '@timed/auth';
import { addServerErrors, Button, Textarea } from '@timed/common';
import {
  useUpdateCurrentMemberTypicalUnavailabilityMutation,
  useUpdateMembersTypicalUnavailabilityMutation,
} from '@timed/gql';
import { MemberContextType } from '@timed/member';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type MemberUpdateUnavailableDaysFormProps = {
  member: MemberContextType;
};

type FormData = {
  patch: {
    unavailableNotes?: string | null;
    unavailableMoMorn: boolean;
    unavailableMoDay: boolean;
    unavailableMoNight: boolean;
    unavailableTuMorn: boolean;
    unavailableTuDay: boolean;
    unavailableTuNight: boolean;
    unavailableWeMorn: boolean;
    unavailableWeDay: boolean;
    unavailableWeNight: boolean;
    unavailableThMorn: boolean;
    unavailableThDay: boolean;
    unavailableThNight: boolean;
    unavailableFrMorn: boolean;
    unavailableFrDay: boolean;
    unavailableFrNight: boolean;
    unavailableSaMorn: boolean;
    unavailableSaDay: boolean;
    unavailableSaNight: boolean;
    unavailableSuMorn: boolean;
    unavailableSuDay: boolean;
    unavailableSuNight: boolean;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputs: {
      flex: '1 0 auto',
      overflowY: 'auto',
      // borderRadius: theme.shape.borderRadius,
      // border: '1px solid ' + theme.palette.divider,
      // padding: theme.spacing(4),
    },
    textarea: {
      backgroundColor: theme.palette.background.paper,
      width: 256,
      color: theme.palette.text.primary,
      border: '1px solid ' + theme.palette.text.disabled,
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.up('md')]: {
        width: 384,
      },
    },
    dates: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridAutoColumns: 120,
      gap: theme.spacing(4),
      alignItems: 'center',
    },
    row: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridAutoColumns: 'max-content',
      gap: theme.spacing(4),
    },
    days: {
      gridAutoFlow: 'column',
      display: 'grid',
      gridTemplateRows: 'auto auto auto auto auto',
      gap: theme.spacing(0),
      alignItems: 'center',
    },
    groups: {
      display: 'flex',
      gap: theme.spacing(4),
      flexFlow: 'row',
      [theme.breakpoints.down('sm')]: {
        flexFlow: 'column',
      },
    },
    group: {
      display: 'grid',
      gridTemplateColumns: 'max-content',
      gridAutoRows: 'max-content',
      gap: theme.spacing(1),
      '& .MuiInputBase-input': {
        textAlign: 'center',
      },
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    buttons: {
      flex: '0 1 max-content',
      display: 'flex',
      justifyContent: 'space-between',
    },
    endDate: {
      display: 'grid',
      gap: 16,
      gridTemplateColumns: 'auto 120px',
    },

    typicalUnavailableDays: {
      display: 'grid',
      gridTemplateColumns: 'repeat(8, max-content)',
      columnGap: theme.spacing(0),
      rowGap: theme.spacing(0),
      alignItems: 'center',
      justifyItems: 'center',
    },
  }),
);

const MemberUpdateUnavailableDaysForm = ({
  member,
}: MemberUpdateUnavailableDaysFormProps) => {
  const classes = useStyles();

  const { member: currentMember } = useAuth();

  const [updateMember, response] =
    useUpdateMembersTypicalUnavailabilityMutation();
  const [updateCurrentMember, responseCurrent] =
    useUpdateCurrentMemberTypicalUnavailabilityMutation();

  const defaultValues: FormData = {
    patch: {
      unavailableNotes: member.unavailableNotes,
      unavailableMoMorn: member.unavailableMoMorn!,
      unavailableMoDay: member.unavailableMoDay!,
      unavailableMoNight: member.unavailableMoNight!,
      unavailableTuMorn: member.unavailableTuMorn!,
      unavailableTuDay: member.unavailableTuDay!,
      unavailableTuNight: member.unavailableTuNight!,
      unavailableWeMorn: member.unavailableWeMorn!,
      unavailableWeDay: member.unavailableWeDay!,
      unavailableWeNight: member.unavailableWeNight!,
      unavailableThMorn: member.unavailableThMorn!,
      unavailableThDay: member.unavailableThDay!,
      unavailableThNight: member.unavailableThNight!,
      unavailableFrMorn: member.unavailableFrDay!,
      unavailableFrDay: member.unavailableFrMorn!,
      unavailableFrNight: member.unavailableFrNight!,
      unavailableSaMorn: member.unavailableSaMorn!,
      unavailableSaDay: member.unavailableSaDay!,
      unavailableSaNight: member.unavailableSaNight!,
      unavailableSuMorn: member.unavailableSuMorn!,
      unavailableSuDay: member.unavailableSuDay!,
      unavailableSuNight: member.unavailableSuNight!,
    },
  };

  const { handleSubmit, control, setError } = useForm<FormData>({
    defaultValues,
  });

  const onSubmit = ({ patch }: FormData) => {
    currentMember!.id === member.id
      ? updateCurrentMember({
          variables: {
            input: { patch },
          },
        }).catch((e) => {})
      : updateMember({
          variables: {
            input: {
              ids: [member.id],
              patch,
            },
          },
        }).catch((e) => {});
  };

  /**
   * Display error messages from server response
   */
  useEffect(() => {
    if (response.error) addServerErrors(response.error!, setError);
    if (responseCurrent.error)
      addServerErrors(responseCurrent.error!, setError);
  }, [response.error, responseCurrent.error, setError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box className={classes.inputs}>
        <Box className={classes.groups}>
          {/* <Box className={classes.group}>
            <Typography className={classes.bold}>Unavailable Days</Typography>
            <Typography variant="body2" color="textSecondary">
              Check the boxes matching typical <span className={classes.bold}>unavailability</span>{' '}
              periods.
            </Typography>
            <Box className={classes.typicalUnavailableDays}>
              <Box></Box>
              <Typography>M</Typography>
              <Typography>T</Typography>
              <Typography>W</Typography>
              <Typography>T</Typography>
              <Typography>F</Typography>
              <Typography>S</Typography>
              <Typography>S</Typography>
              <Typography>00:00-06:00</Typography>
              <Checkbox
                size="small"
                control={control}
                name="patch.unavailableMoMorn"
                defaultChecked={member.unavailableMoMorn!}
              />
              <Checkbox
                size="small"
                control={control}
                name="patch.unavailableTuMorn"
                defaultChecked={member.unavailableTuMorn!}
              />
              <Checkbox
                size="small"
                control={control}
                name="patch.unavailableWeMorn"
                defaultChecked={member.unavailableWeMorn!}
              />
              <Checkbox
                size="small"
                control={control}
                name="patch.unavailableThMorn"
                defaultChecked={member.unavailableThMorn!}
              />
              <Checkbox
                size="small"
                control={control}
                name="patch.unavailableFrMorn"
                defaultChecked={member.unavailableFrMorn!}
              />
              <Checkbox
                size="small"
                control={control}
                name="patch.unavailableSaMorn"
                defaultChecked={member.unavailableSaMorn!}
              />
              <Checkbox
                size="small"
                control={control}
                name="patch.unavailableSuMorn"
                defaultChecked={member.unavailableSuMorn!}
              />
              <Typography>06:00-18:00</Typography>
              <Checkbox
                size="small"
                control={control}
                name="patch.unavailableMoDay"
                defaultChecked={member.unavailableMoDay!}
              />
              <Checkbox
                size="small"
                control={control}
                name="patch.unavailableTuDay"
                defaultChecked={member.unavailableTuDay!}
              />
              <Checkbox
                size="small"
                control={control}
                name="patch.unavailableWeDay"
                defaultChecked={member.unavailableWeDay!}
              />
              <Checkbox
                size="small"
                control={control}
                name="patch.unavailableThDay"
                defaultChecked={member.unavailableThDay!}
              />
              <Checkbox
                size="small"
                control={control}
                name="patch.unavailableFrDay"
                defaultChecked={member.unavailableFrDay!}
              />
              <Checkbox
                size="small"
                control={control}
                name="patch.unavailableSaDay"
                defaultChecked={member.unavailableSaDay!}
              />
              <Checkbox
                size="small"
                control={control}
                name="patch.unavailableSuDay"
                defaultChecked={member.unavailableSuDay!}
              />
              <Typography>18:00-00:00</Typography>
              <Checkbox
                size="small"
                control={control}
                name="patch.unavailableMoNight"
                defaultChecked={member.unavailableMoNight!}
              />
              <Checkbox
                size="small"
                control={control}
                name="patch.unavailableTuNight"
                defaultChecked={member.unavailableTuNight!}
              />
              <Checkbox
                size="small"
                control={control}
                name="patch.unavailableWeNight"
                defaultChecked={member.unavailableWeNight!}
              />
              <Checkbox
                size="small"
                control={control}
                name="patch.unavailableThNight"
                defaultChecked={member.unavailableThNight!}
              />
              <Checkbox
                size="small"
                control={control}
                name="patch.unavailableFrNight"
                defaultChecked={member.unavailableFrNight!}
              />
              <Checkbox
                size="small"
                control={control}
                name="patch.unavailableSaNight"
                defaultChecked={member.unavailableSaNight!}
              />
              <Checkbox
                size="small"
                control={control}
                name="patch.unavailableSuNight"
                defaultChecked={member.unavailableSuNight!}
              />
            </Box>
          </Box> */}

          <Box className={classes.group}>
            <Typography className={classes.bold}>Notes</Typography>
            <Textarea
              name="patch.unavailableNotes"
              control={control}
              minRows={4}
              className={classes.textarea}
            />
            <Button
              type="submit"
              style={{ width: 'max-content', alignSelf: 'flex-end' }}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default MemberUpdateUnavailableDaysForm;

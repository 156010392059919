import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { formatPersonName, roundNumber } from '@timed/common';
import { Member, PersonNamesFragment } from '@timed/gql';
import { PaySlip } from '@timed/report/components/MemberPaySlipForm';
import clsx from 'clsx';
import { addDays, format, isAfter, isBefore, isEqual } from 'date-fns';

const useStyles = makeStyles((theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    paySlip: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    paySlipDescription: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: theme.spacing(1),
    },
    paySlipDescriptionLeft: {
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    paySlipDescriptionRight: {
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      textAlign: 'right',
    },
    company: {
      '& .MuiTypography-root': {
        fontSize: 24,
      },
    },
    payrollItems: {
      border: '1px solid ' + theme.palette.divider,
      padding: theme.spacing(2),
      display: 'grid',
      gridTemplateColumns: 'repeat(5, auto)',
      gap: theme.spacing(1),
      '& .MuiTypography-root': {
        fontSize: 12,
      },
    },
    eventItems: {
      border: '1px solid ' + theme.palette.divider,
      padding: theme.spacing(2),
      display: 'grid',
      gridTemplateColumns: 'repeat(5, auto)',
      gap: theme.spacing(1),
      '& .MuiTypography-root': {
        fontSize: 12,
      },
    },
  }),
);

type ReportMemberPaySlipProps = {
  member: Pick<Member, 'id' | 'externalId'> & PersonNamesFragment;
  basePayRate: string;
  date: Date;
  items: PaySlip[];
  itemsYTD: PaySlip[];
  showShifts?: boolean;
};

const ReportMemberPaySlip = ({
  member,
  items,
  itemsYTD,
  basePayRate,
  date,
  showShifts = false,
}: ReportMemberPaySlipProps) => {
  const classes = useStyles();

  const grossPay = roundNumber(
    items
      .filter((item) => item.category !== 'Super Expenses')
      .filter(
        (item) =>
          !item.event ||
          ((isAfter(item.event.startAt, date) ||
            isEqual(item.event.startAt, date)) &&
            (isBefore(item.event.endAt, addDays(date, 7)) ||
              isEqual(item.event.endAt, addDays(date, 7)))),
      )
      .map(({ pay }) => pay)
      .reduce((a, b) => a + b, 0),
    2,
  );

  const uniqueCategories = [...new Set(items.map((item) => item.category))];

  return (
    <Box className={classes.paySlip}>
      <Box className={classes.paySlipDescription}>
        <Box className={classes.paySlipDescriptionLeft}>
          <Box>
            <Typography
              variant="h1"
              className={clsx(classes.bold, classes.company)}
            >
              Australian Carers Pty Ltd
            </Typography>
            <Typography>ABN. 95 633 325 854</Typography>
          </Box>
          <Box>
            <Typography>
              Pay Slip For:{' '}
              {formatPersonName(member, {
                lastNameFirst: true,
                capitaliseLastName: true,
                middle: true,
              })}
            </Typography>
          </Box>
          <Box>
            <Typography>Classification: Support Worker - Other</Typography>
          </Box>
          <Box>
            <Typography>Est. Annual Salary: $_</Typography>
          </Box>
          <Box>
            <Typography>Hourly Rate: ${basePayRate}</Typography>
          </Box>
          <Box>
            <Typography>
              Pay Period: {format(date, 'd/M/yyyy')} -{' '}
              {format(addDays(date, 6), 'd/M/yyyy')}
            </Typography>
          </Box>
          <Box>
            <Typography>Superannuation Fund:</Typography>
          </Box>
        </Box>
        <Box className={classes.paySlipDescriptionRight}>
          <Box>
            <Typography>
              Employee Refernce Number: {member.externalId}
            </Typography>
          </Box>
          <Box>
            <Typography>
              Payment Date: {format(new Date(), 'd/M/yyyy')}
            </Typography>
          </Box>
          <Box>
            <Typography>GROSS Pay: ${grossPay.toFixed(2)}</Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.payrollItems}>
        <Typography className={classes.bold}>DESCRIPTION</Typography>
        <Typography className={classes.bold}>UNITS</Typography>
        <Typography className={classes.bold}>RATE</Typography>
        <Typography className={classes.bold}>AMOUNT</Typography>
        {/* <Typography className={classes.bold}>YTD</Typography> */}
        <Typography className={classes.bold}>TYPE</Typography>

        {uniqueCategories.map((category) => {
          // const paysYTD = itemsYTD
          //   .filter((item) => item.category === category)
          //   .filter(
          //     (item) =>
          //       !item.event ||
          //       ((isAfter(item.event.startAt, date) || isEqual(item.event.startAt, date)) &&
          //         (isBefore(item.event.endAt, addDays(date, 7)) ||
          //           isEqual(item.event.endAt, addDays(date, 7)))),
          //   )
          //   .map((item) => item.pay);

          let hoursArray: number[] = items
            .filter((item) => item.category === category)
            .filter(
              (item) =>
                !item.event ||
                ((isAfter(item.event.startAt, date) ||
                  isEqual(item.event.startAt, date)) &&
                  (isBefore(item.event.endAt, addDays(date, 7)) ||
                    isEqual(item.event.endAt, addDays(date, 7)))),
            )
            .map(({ hours }) => hours)
            .filter((hours) => !!hours) as number[];

          const hours: number | undefined = !!hoursArray.length
            ? roundNumber(
                hoursArray.reduce((a, b) => a + b, 0),
                2,
              )
            : undefined;

          const rate = items.filter((item) => item.category === category)[0]
            .rate;

          const pay: number | undefined =
            category === 'Super Expenses'
              ? items
                  .filter(
                    (item) =>
                      !item.event ||
                      ((isAfter(item.event.startAt, date) ||
                        isEqual(item.event.startAt, date)) &&
                        (isBefore(item.event.endAt, addDays(date, 7)) ||
                          isEqual(item.event.endAt, addDays(date, 7)))),
                  )
                  .find((item) => item.category === category)!.pay
              : !!hours && !!rate
              ? roundNumber(hours * rate, 2)
              : undefined;

          const type = items.filter((item) => item.category === category)[0]
            .type;

          // const paidYTD = !!paysYTD.length
          //   ? roundNumber(
          //       paysYTD.reduce((a, b) => a + b, 0),
          //       2,
          //     )
          //   : undefined;

          return !!hours && !!pay ? (
            <>
              <Typography>{category}</Typography>
              <Typography>{!!hours ? hours.toFixed(2) : ''}</Typography>
              <Typography>
                {!!rate ? '$' + roundNumber(rate, 2).toFixed(2) : ''}
              </Typography>
              <Typography>${!!pay ? pay.toFixed(2) : ''}</Typography>
              {/* <Typography>${!!paidYTD ? paidYTD.toFixed(2) : ''}</Typography> */}
              <Typography>{type}</Typography>
            </>
          ) : null;
        })}
      </Box>
      {showShifts && (
        <Box className={classes.eventItems}>
          <Typography className={classes.bold}>DESCRIPTION</Typography>
          <Typography className={classes.bold}>UNITS</Typography>
          <Typography className={classes.bold}>RATE</Typography>
          <Typography className={classes.bold}>AMOUNT</Typography>
          <Typography className={classes.bold}>TYPE</Typography>

          {items
            .filter(
              (item) =>
                !item.event ||
                ((isAfter(item.event.startAt, date) ||
                  isEqual(item.event.startAt, date)) &&
                  (isBefore(item.event.endAt, addDays(date, 7)) ||
                    isEqual(item.event.endAt, addDays(date, 7)))),
            )
            .map(({ description, hours, pay, rate, type }) => (
              <>
                <Typography>{description}</Typography>
                <Typography>
                  {!!hours ? roundNumber(hours, 2).toFixed(2) : ''}
                </Typography>
                <Typography>
                  {!!rate ? '$' + roundNumber(rate, 2).toFixed(2) : ''}
                </Typography>
                <Typography>${roundNumber(pay, 2).toFixed(2)}</Typography>
                <Typography>{type}</Typography>
              </>
            ))}
        </Box>
      )}
    </Box>
  );
};

export default ReportMemberPaySlip;

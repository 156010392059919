import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { _schedule } from '@timed/app';
import { useAuth } from '@timed/auth';
import {
  Button,
  formatPersonName,
  roundNumber,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  useRouter,
} from '@timed/common';
import {
  Client,
  Event,
  OrderBy,
  useDismissEventClaimMutation,
  useGetEventsWithClaimsLazyQuery,
  useGetMemberSchedulesLazyQuery,
  useGetOrgClaimRangeSettingQuery,
  useUpdateEventAttendeeMutation,
} from '@timed/gql';
import { setProfile } from '@timed/schedule';
import {
  addDays,
  addWeeks,
  differenceInMinutes,
  differenceInWeeks,
  format,
  formatISO,
  startOfWeek,
  subWeeks,
} from 'date-fns';
import { useCallback, useEffect, useMemo, useReducer } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    title: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: theme.spacing(4),
      '& div:first-child': {
        display: 'flex',
        flexFlow: 'column',
        flexGrow: 1,
      },
    },
    actions: {
      display: 'flex',
      gap: theme.spacing(2),
      '& .MuiButton-root': {
        height: 34,
        alignSelf: 'center',
      },
    },
  }),
);

const ShiftsProcessClaims = () => {
  const classes = useStyles();

  const theme = useTheme();

  const { branch } = useAuth();

  const {
    navigate,
    search: [searchParams],
  } = useRouter();

  const orgsResponse = useGetOrgClaimRangeSettingQuery({
    fetchPolicy: 'network-only',
  });

  const [getEvents, { data }] = useGetEventsWithClaimsLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [getSchedules, schedulesResponse] = useGetMemberSchedulesLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [updateEvent, updateEventResponse] = useUpdateEventAttendeeMutation();

  const [dismissEvent] = useDismissEventClaimMutation();

  const [acceptedClaims, addAcceptedClaims] = useReducer(
    (existingIds: string[], newId: string) => [...existingIds, newId],
    [],
  );

  const handleNavigate = useCallback(
    (event: Pick<Event, 'startAt'> & { client: Pick<Client, 'id'> }) => {
      setProfile('client', searchParams, event.client.id);
      setProfile('member', searchParams, undefined);

      const formattedDate = format(
        startOfWeek(new Date(event.startAt), { weekStartsOn: 1 }),
        'ddMMyyyy',
      );

      if (searchParams.get('f') !== formattedDate) {
        searchParams.set('f', formattedDate);
      }

      navigate(_schedule.path + '?' + searchParams);
    },
    [navigate, searchParams],
  );

  const dates = useMemo<Date[]>(
    () =>
      new Array(
        differenceInWeeks(
          addDays(
            startOfWeek(new Date(), { weekStartsOn: 1 }),
            orgsResponse.data?.me.member?.org.claimEventsDayRange ?? 2,
          ),
          startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }),
        ),
      )
        .fill(null)
        .map((_, i) =>
          addWeeks(
            startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }),
            i,
          ),
        ),
    [orgsResponse.data],
  );

  // Fetch events
  useEffect(() => {
    getEvents({
      variables: {
        eventsInput: {
          where: {
            startAt: {
              _lt: addDays(
                new Date(),
                orgsResponse.data?.me.member?.org.claimEventsDayRange ?? 2,
              ),
            },
            endAt: { _gt: new Date() },
            member: { id: { _eq: null } },
            claims: { id: { _ne: null } },
            client: !!branch
              ? { branch: { id: { _eq: branch.id } } }
              : undefined,
          },
          orderBy: [{ startAt: OrderBy.ASC }],
        },
        claimsInput: {
          where: {
            dismissedAt: { _eq: null },
            member: {
              archive: { _eq: null },
              delete: { _eq: null },
              schedulable: { _eq: true },
            },
          },
          orderBy: [{ createdAt: OrderBy.ASC }],
        },
      },
    });
  }, [
    getEvents,
    branch,
    orgsResponse.data?.me.member?.org.claimEventsDayRange,
  ]);

  /**
   * IDs off all unique members whom have claimed a shift for this period.
   */
  const memberIds = useMemo<string[]>(
    () => [
      ...new Set(
        data?.events.flatMap(({ claims }) =>
          claims.map(({ member: { id } }) => id),
        ),
      ),
    ],
    [data?.events],
  );

  useEffect(() => {
    if (!!data?.events.length) {
      getSchedules({
        variables: {
          membersInput: {
            where: {
              id: {
                _in: memberIds,
              },
            },
          },
          schedulesInput: {
            dates: [
              subWeeks(dates[0], 1),
              ...dates,
              addWeeks(dates.at(-1)!, 1),
            ].map((date) => ({
              startAt: formatISO(date, { representation: 'date' }),
              endAt: formatISO(addDays(date, 6), { representation: 'date' }),
            })),
          },
        },
      });
    }
  }, [data, memberIds, getSchedules, dates]);

  const handleUpdateEvent = (eventId: string, memberId: string) => {
    updateEvent({
      variables: {
        input: {
          id: eventId,
          patch: {
            member: {
              id: memberId,
            },
          },
        },
      },
    });
  };

  useEffect(() => {
    if (!updateEventResponse.error && !!updateEventResponse.data) {
      addAcceptedClaims(updateEventResponse.data.updateEvent.id);
    }
  }, [updateEventResponse.data, updateEventResponse.error]);

  return !data?.events?.length ? (
    <Typography>No claims to process</Typography>
  ) : !schedulesResponse.data ? (
    <Typography>Loading schedules...</Typography>
  ) : (
    <div className={classes.wrapper}>
      {data?.events.map((event) => {
        const scheduleIndex = dates.findIndex(
          (date) =>
            date.getTime() ===
            startOfWeek(new Date(event.startAt), {
              weekStartsOn: 1,
            }).getTime(),
        );

        return (
          <Table hidden={!event.claims.length} wrapperStyle={{ width: 1000 }}>
            <TableHeader>
              <div className={classes.title}>
                <div>
                  <Typography className={classes.bold}>
                    {format(
                      new Date(event.startAt),
                      "EEEE, do 'of' MMMM yyyy, HH:mm-",
                    )}
                    {format(new Date(event.endAt), 'HH:mm')}
                    {' ('}
                    {differenceInMinutes(
                      new Date(event.endAt),
                      new Date(event.startAt),
                    ) / 60}{' '}
                    hours{')'}
                  </Typography>
                  <Typography className={classes.bold}>
                    {formatPersonName(event.client, {
                      capitaliseLastName: true,
                      lastNameFirst: true,
                      preferredAndLast: true,
                    })}
                  </Typography>
                </div>
                <div className={classes.actions}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleNavigate(event);
                    }}
                  >
                    Go To Shift
                  </Button>
                </div>
              </div>
            </TableHeader>
            <TableRow>
              <TableCell>
                <Table
                  enableRowHighlighting
                  size="small"
                  backgroundColor={theme.palette.background.paper}
                >
                  <TableHeader style={{ width: 'auto' }}>
                    Requested By
                  </TableHeader>
                  <TableHeader align="center">Last</TableHeader>
                  <TableHeader align="center">This</TableHeader>
                  <TableHeader align="center">Next</TableHeader>
                  <TableHeader align="center">Has Car</TableHeader>
                  <TableHeader align="center">Available</TableHeader>
                  <TableHeader align="center">Conflict</TableHeader>
                  <TableHeader align="center"></TableHeader>
                  {event.claims.map((claim, i) => {
                    const { schedules } = schedulesResponse.data?.members.find(
                      ({ id }) => id === claim.member.id,
                    )!;

                    return (
                      <TableRow key={i}>
                        <TableCell>
                          {formatPersonName(claim.member, {
                            capitaliseLastName: true,
                            lastNameFirst: true,
                          })}
                        </TableCell>
                        <TableCell>
                          {scheduleIndex > 0
                            ? `${roundNumber(
                                schedules[scheduleIndex].totalMinutes / 60,
                                2,
                              )}/${roundNumber(
                                schedules[scheduleIndex].allowedMinutes / 60,
                                2,
                              )}`
                            : 'Unknown'}
                        </TableCell>
                        <TableCell>{`${roundNumber(
                          schedules[scheduleIndex + 1].totalMinutes / 60,
                          2,
                        )}/${roundNumber(
                          schedules[scheduleIndex + 1].allowedMinutes / 60,
                          2,
                        )}`}</TableCell>
                        <TableCell>
                          {scheduleIndex < schedules.length
                            ? `${roundNumber(
                                schedules[scheduleIndex + 2].totalMinutes / 60,
                                2,
                              )}/${roundNumber(
                                schedules[scheduleIndex + 2].allowedMinutes /
                                  60,
                                2,
                              )}`
                            : 'Unknown'}
                        </TableCell>
                        <TableCell>
                          {claim.member.hasCar
                            ? 'Yes'
                            : claim.member.hasCar === null
                            ? 'Unknown'
                            : 'No'}
                        </TableCell>
                        <TableCell>WIP</TableCell>
                        <TableCell>WIP</TableCell>
                        <TableCell>
                          <div className={classes.actions}>
                            <Button
                              variant="contained"
                              onClick={() => {
                                dismissEvent({
                                  variables: { id: claim.id },
                                });
                              }}
                            >
                              Dismiss
                            </Button>
                            <Button
                              variant="contained"
                              onClick={() => {
                                handleUpdateEvent(event.id, claim.member.id);
                              }}
                            >
                              Accept
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </Table>
              </TableCell>
            </TableRow>
          </Table>
        );
      })}
    </div>
  );
};

export default ShiftsProcessClaims;

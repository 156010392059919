import { Typography } from '@material-ui/core';
import { Stack } from '@mui/material';
import { Button, Dialog, ModalProps } from '@timed/common';
import {
  MemberPostNominal,
  useDeleteMemberPostNominalMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { format } from 'date-fns';

type MemberDeletePostNominalDialogProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  postNominal: Pick<MemberPostNominal, 'id' | 'date' | 'value'>;
};

const MemberDeletePostNominalDialog = ({
  postNominal,
  onClose,
  ...props
}: MemberDeletePostNominalDialogProps) => {
  const [deleteEntity, { loading, client }] =
    useDeleteMemberPostNominalMutation();

  useLoadingEffect(loading);

  const handleDelete = () => {
    deleteEntity({
      variables: { input: { id: postNominal.id } },

      onCompleted: ({ deleteMemberPostNominal }) => {
        client.cache.evict({
          id: client.cache.identify({
            __typename: 'MemberPostNominal',
            id: deleteMemberPostNominal.id,
          }),
        });
        client.cache.gc();
        onClose();
      },
    });
  };

  return (
    <Dialog
      {...props}
      title="Deleting Post-nominals"
      description={
        <Stack spacing={2}>
          <Typography>
            Are you sure you want to delete these post-nominals?
          </Typography>
          <Typography color="textPrimary">
            {postNominal.value}{' '}
            {format(new Date(postNominal.date), 'dd/MM/yyyy')}
          </Typography>
        </Stack>
      }
      actions={[
        <Button onClick={onClose}>Cancel</Button>,
        <Button onClick={handleDelete}>Yes</Button>,
      ]}
    />
  );
};

export default MemberDeletePostNominalDialog;

import {
  Box,
  createStyles,
  Link,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import WarningIcon from '@material-ui/icons/Warning';
import { useAuth } from '@timed/auth';
import { formatPersonName, intersperse } from '@timed/common';
import { Member } from '@timed/gql';
import React from 'react';

type EventCantAttendBoxProps = {
  schedulingContactPerson?: Pick<
    Member,
    'id' | 'firstName' | 'lastName' | 'preferredName' | 'phone'
  > | null;
  schedulingContactPerson2?: Pick<
    Member,
    'id' | 'firstName' | 'lastName' | 'preferredName' | 'phone'
  > | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cantAttendEventWarning: {
      width: '100%',
      padding: theme.spacing(1),
      gap: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      border: '1px solid ' + yellow[600],
      borderRadius: theme.shape.borderRadius,
      backgroundColor: yellow[100],
      '& .MuiTypography-root': {
        fontSize: 10.5,
      },
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  }),
);

const PhoneNumberLink = ({ phone }: { phone: string }): JSX.Element => (
  <Link href={'tel:' + phone.replace(/\s+/g, '')}>{phone}</Link>
);

const formatSchedulingContactPeople = ({
  schedulingContactPerson,
  schedulingContactPerson2,
}: EventCantAttendBoxProps): JSX.Element => {
  if (!schedulingContactPerson && !schedulingContactPerson2)
    return <>John Vellenga</>;

  const contacts: { name: string; phone?: string | null }[] = [];

  if (!!schedulingContactPerson)
    contacts.push({
      name: formatPersonName(schedulingContactPerson, {
        preferredAndLast: true,
      }),
      phone: schedulingContactPerson.phone,
    });

  if (!!schedulingContactPerson2)
    contacts.push({
      name: formatPersonName(schedulingContactPerson2, {
        preferredAndLast: true,
      }),
      phone: schedulingContactPerson2.phone,
    });

  if (contacts.length)
    return intersperse({
      finalSeperatorString: 'or',
      array: contacts.map(({ name, phone }) => (
        <>
          {name}
          {!!phone ? (
            <>
              {' '}
              on <PhoneNumberLink phone={phone} />
            </>
          ) : (
            ''
          )}
        </>
      )),
    });

  return (
    <>
      John Vellenga on <PhoneNumberLink phone={'0410 330 999'} />
    </>
  );
};

const EventCantAttendBox = ({
  schedulingContactPerson,
  schedulingContactPerson2,
}: EventCantAttendBoxProps) => {
  const classes = useStyles();

  const org = useAuth().org;

  schedulingContactPerson ??= org?.schedulingContactPerson;

  schedulingContactPerson2 ??= org?.schedulingContactPerson2;

  return (
    <Box className={classes.cantAttendEventWarning}>
      <WarningIcon color="secondary" />
      <Typography variant="body1">
        Need help with this shift? Please call{' '}
        {formatSchedulingContactPeople({
          schedulingContactPerson,
          schedulingContactPerson2,
        })}
      </Typography>
    </Box>
  );
};

export default EventCantAttendBox;

import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { _peopleMembers } from '@timed/app';
import {
  ClientNdisPlanItemTransactionActionMenuButton,
  ClientNdisPlanItemTransactionContext,
  ClientNdisPlanItemTransactionControls,
} from '@timed/client';
import {
  Block,
  formatPersonName,
  Link,
  PaginationNavigation,
  PaginationStatus,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from '@timed/common';
import { formatCurrency } from '@timed/common/utils/formatCurrency';
import { OrderBy } from '@timed/gql';
import { format } from 'date-fns';
import _ from 'lodash';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      maxHeight: '80vh',
      gap: theme.spacing(4),
      flex: '1 1 auto',
      display: 'flex',
      overflowY: 'hidden',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    input: {
      '&.MuiFormControl-root': {
        display: 'flex',
      },
    },
  }),
);

type ClientNdisPlanItemTransactionTableModalProps = {
  onClose: () => void;
};

const ClientNdisPlanItemTransactionTableModal = ({
  onClose,
}: ClientNdisPlanItemTransactionTableModalProps) => {
  const classes = useStyles();

  const {
    loading,
    nodes,
    limit,
    offset,
    fetch,
    totalCount,
    input: { orderBy, setOrderBy },
  } = useContext(ClientNdisPlanItemTransactionContext);

  return (
    <Block
      title="Transactions"
      topRight={
        <IconButton onClick={onClose}>
          <CloseRoundedIcon />
        </IconButton>
      }
      paperProps={{
        style: {
          backgroundColor: 'white',
        },
      }}
    >
      <Box className={classes.wrapper}>
        <ClientNdisPlanItemTransactionControls />

        <PaginationStatus
          loading={loading}
          limit={limit}
          offset={offset}
          totalCount={totalCount}
          what="transactions"
        />

        {!!nodes?.length && (
          <Table inline enableRowHighlighting loading={loading}>
            <TableHeader
              order={orderBy[0].billingDate}
              onClick={() => {
                setOrderBy([
                  {
                    billingDate:
                      !!orderBy[0].billingDate &&
                      orderBy[0].billingDate === OrderBy.DESC_NULLS_LAST
                        ? OrderBy.ASC_NULLS_LAST
                        : OrderBy.DESC_NULLS_LAST,
                  },
                ]);
              }}
            >
              Billing Date
            </TableHeader>
            <TableHeader
              order={orderBy[0].serviceDateStart}
              onClick={() => {
                setOrderBy([
                  {
                    serviceDateStart:
                      !!orderBy[0].serviceDateStart &&
                      orderBy[0].serviceDateStart === OrderBy.DESC_NULLS_LAST
                        ? OrderBy.ASC_NULLS_LAST
                        : OrderBy.DESC_NULLS_LAST,
                  },
                ]);
              }}
            >
              Service Start Date
            </TableHeader>
            <TableHeader
              order={orderBy[0].serviceDateEnd}
              onClick={() => {
                setOrderBy([
                  {
                    serviceDateEnd:
                      !!orderBy[0].serviceDateEnd &&
                      orderBy[0].serviceDateEnd === OrderBy.DESC_NULLS_LAST
                        ? OrderBy.ASC_NULLS_LAST
                        : OrderBy.DESC_NULLS_LAST,
                  },
                ]);
              }}
            >
              Service End Date
            </TableHeader>
            <TableHeader
              order={orderBy[0].clientNdisPlanItem?.name?.name}
              onClick={() => {
                setOrderBy([
                  {
                    clientNdisPlanItem: {
                      name: {
                        name:
                          orderBy[0].clientNdisPlanItem?.name?.name &&
                          orderBy[0].clientNdisPlanItem.name?.name ===
                            OrderBy.ASC
                            ? OrderBy.DESC
                            : OrderBy.ASC,
                      },
                    },
                  },
                ]);
              }}
            >
              Category
            </TableHeader>
            <TableHeader
              order={orderBy[0].clientNdisPlanItemTransactionSupplier?.name}
              onClick={() => {
                setOrderBy([
                  {
                    clientNdisPlanItemTransactionSupplier: {
                      name:
                        !!orderBy[0].clientNdisPlanItemTransactionSupplier
                          ?.name &&
                        orderBy[0].clientNdisPlanItemTransactionSupplier
                          .name === OrderBy.ASC
                          ? OrderBy.DESC
                          : OrderBy.ASC,
                    },
                  },
                ]);
              }}
            >
              Supplier
            </TableHeader>
            <TableHeader
              order={orderBy[0].invoiceNumber}
              onClick={() => {
                setOrderBy([
                  {
                    invoiceNumber:
                      !!orderBy[0].invoiceNumber &&
                      orderBy[0].invoiceNumber === OrderBy.ASC_NULLS_LAST
                        ? OrderBy.DESC_NULLS_LAST
                        : OrderBy.ASC_NULLS_LAST,
                  },
                ]);
              }}
            >
              Invoice Number
            </TableHeader>
            <TableHeader
              align="right"
              order={orderBy[0].value}
              onClick={() => {
                setOrderBy([
                  {
                    value:
                      !!orderBy[0].value && orderBy[0].value === OrderBy.ASC
                        ? OrderBy.DESC
                        : OrderBy.ASC,
                  },
                ]);
              }}
            >
              Ammount
            </TableHeader>
            <TableHeader
              order={orderBy[0].memo}
              onClick={() => {
                setOrderBy([
                  {
                    memo:
                      !!orderBy[0].memo && orderBy[0].memo === OrderBy.ASC
                        ? OrderBy.DESC
                        : OrderBy.ASC,
                  },
                ]);
              }}
            >
              Memo
            </TableHeader>
            <TableHeader
              order={orderBy[0].createdAt}
              onClick={() => {
                setOrderBy([
                  {
                    createdAt:
                      !!orderBy[0].createdAt &&
                      orderBy[0].createdAt === OrderBy.DESC
                        ? OrderBy.ASC
                        : OrderBy.DESC,
                  },
                ]);
              }}
            >
              Recorded At
            </TableHeader>
            <TableHeader
              order={orderBy[0].actor?.lastName}
              onClick={() => {
                setOrderBy([
                  {
                    actor: {
                      lastName:
                        !!orderBy[0].actor?.lastName &&
                        orderBy[0].actor.lastName === OrderBy.ASC_NULLS_LAST
                          ? OrderBy.DESC_NULLS_LAST
                          : OrderBy.ASC_NULLS_LAST,
                    },
                  },
                ]);
              }}
            >
              Recorded By
            </TableHeader>
            <TableHeader></TableHeader>
            {nodes.map((transaction, i) => (
              <TableRow key={i}>
                <TableCell>
                  {!!transaction.billingDate &&
                    format(new Date(transaction.billingDate), 'dd/MM/yyyy')}
                </TableCell>
                <TableCell>
                  {!!transaction.serviceDateStart &&
                    format(
                      new Date(transaction.serviceDateStart),
                      'dd/MM/yyyy',
                    )}
                </TableCell>
                <TableCell>
                  {!!transaction.serviceDateEnd &&
                    format(new Date(transaction.serviceDateEnd), 'dd/MM/yyyy')}
                </TableCell>
                <TableCell>
                  {transaction.clientNdisPlanItem.name.name}
                </TableCell>
                <TableCell>
                  {transaction.clientNdisPlanItemTransactionSupplier?.name}
                </TableCell>
                <TableCell>{transaction.invoiceNumber}</TableCell>
                <TableCell>
                  <Typography
                    style={{ color: transaction.value > 0 ? 'green' : 'red' }}
                  >
                    {transaction.value > 0 && '+'}
                    {formatCurrency(transaction.value / 100)}
                  </Typography>
                </TableCell>
                <TableCell>{transaction.memo}</TableCell>
                <TableCell>
                  {format(new Date(transaction.createdAt), 'dd/MM/yyyy HH:mm')}
                </TableCell>
                <TableCell>
                  {!!transaction.actor ? (
                    <Link to={_peopleMembers.path + '/' + transaction.actor.id}>
                      {formatPersonName(transaction.actor, {
                        capitaliseLastName: true,
                        lastNameFirst: true,
                      })}
                    </Link>
                  ) : (
                    'System'
                  )}
                </TableCell>
                <TableCell>
                  <ClientNdisPlanItemTransactionActionMenuButton
                    clientNdisPlanItemTransaction={_.pick(transaction, ['id'])}
                  />
                </TableCell>
              </TableRow>
            ))}
          </Table>
        )}

        <PaginationNavigation
          action={(offset) => fetch({ offset })}
          limit={limit}
          offset={offset}
          totalCount={totalCount}
          size="large"
        />
      </Box>
    </Block>
  );
};

export default ClientNdisPlanItemTransactionTableModal;

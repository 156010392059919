import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useAlert } from '@timed/alert';
import {
  addServerErrors,
  DateInput,
  formatPersonName,
  FormModal,
  ModalProps,
  TextField,
} from '@timed/common';
import { FileMetadata } from '@timed/file';
import {
  MemberFileType,
  MemberUpload,
  PersonNamesFragment,
  ProcessMemberUploadInput,
  useProcessMemberUploadMutation,
} from '@timed/gql';
import {
  MemberFileCategory,
  memberFilesMetadata,
  MemberFileTypeInput,
} from '@timed/member';
import { UploadProcessingNoteSelectInput } from '@timed/upload';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

type UploadProcessModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  upload: Pick<MemberUpload, 'id' | 'type'> & {
    uploadedFor: PersonNamesFragment;
  };
  accept: boolean;
};

type FormData = Omit<ProcessMemberUploadInput, 'file'> & {
  processingNoteSelect: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    inputs: {
      flex: '1 0 auto',
      overflowY: 'auto',
      display: 'grid',
      gridAutoFlow: 'row',
      gridAutoRows: 'max-content',
      gap: theme.spacing(4),
    },
  }),
);

const UploadProcessModal = ({
  onClose,
  upload,
  accept,
  ...modalProps
}: UploadProcessModalProps) => {
  const classes = useStyles();

  const alert = useAlert();

  const {
    handleSubmit,
    control,
    setError,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: { accept, type: upload.type },
  });

  const processingNoteSelect = watch('processingNoteSelect');

  const type = watch('type');

  const [process, { data, loading, error }] = useProcessMemberUploadMutation();

  const metadata = useMemo<
    FileMetadata<MemberFileType, MemberFileCategory>
  >(() => {
    return memberFilesMetadata.find(({ id }) => id === type)!;
  }, [type]);

  useEffect(() => error && addServerErrors(error, setError), [error, setError]);

  const onSuccess = () => {
    alert.push({
      message: 'Successfully processed the file',
      severity: 'success',
    });
  };

  const onSubmit = ({ processingNoteSelect, ...values }: FormData) => {
    if (!!processingNoteSelect && processingNoteSelect !== 'Other')
      values.processingNote = processingNoteSelect;

    process({
      variables: { input: { file: { id: upload.id }, ...values } },
    });
  };

  return (
    <FormModal
      modalProps={modalProps}
      title={`${accept ? 'Accepting' : 'Rejecting'} File`}
      description={`Uploaded for ${formatPersonName(upload.uploadedFor, {
        lastNameFirst: true,
        capitaliseLastName: true,
      })}`}
      loading={loading}
      success={!!data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        {!accept && (
          <Typography color="error" className={classes.bold}>
            You are rejecting file:{' '}
            {memberFilesMetadata.find(({ id }) => id === upload.type)!.name}
          </Typography>
        )}
        {accept && (
          <Box className={classes.inputs}>
            <MemberFileTypeInput
              required
              name="type"
              control={control}
              error={!!errors.type}
              helperText={errors.type?.message}
              formControlProps={{ size: 'small', variant: 'outlined' }}
            />
            {metadata?.hasDate && (
              <DateInput
                keyboard
                disableTime
                name="dateOfFile"
                defaultValue={null}
                label="Date Of File"
                control={control}
                inputVariant="outlined"
                size="small"
              />
            )}
            {metadata?.hasExpiryDate && (
              <DateInput
                keyboard
                defaultValue={null}
                name="expiresAt"
                label="File Expiry Date"
                control={control}
                inputVariant="outlined"
                size="small"
              />
            )}
            {metadata?.hasNote && (
              <TextField
                name="value"
                variant="outlined"
                size="small"
                label="File Value"
                type="text"
                validation={{ maxLength: { value: 255, message: 'Too long' } }}
                control={control}
                error={!!errors.value}
                helperText={errors.value?.message}
              />
            )}
          </Box>
        )}
        <Box className={classes.inputs}>
          {!accept && (
            <UploadProcessingNoteSelectInput
              required
              control={control}
              name="processingNoteSelect"
              formControlProps={{ size: 'small', variant: 'outlined' }}
            />
          )}
          {(accept || processingNoteSelect === 'Other') && (
            <TextField
              multiline
              minRows={3}
              placeholder="Max length 255 characters."
              name="processingNote"
              variant="outlined"
              size="small"
              label="Processing Note"
              type="text"
              validation={{ maxLength: { value: 255, message: 'Too long' } }}
              control={control}
              error={!!errors.processingNote}
              helperText={errors.processingNote?.message}
            />
          )}
        </Box>
      </Box>
    </FormModal>
  );
};

export default UploadProcessModal;

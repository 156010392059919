import { TextField, TextFieldProps } from '@timed/common';
import { RegisterOptions } from 'react-hook-form';

export const validateNotesKeywordText: RegisterOptions = {
  maxLength: { value: 45, message: 'Too long' },
};

const NotesKeywordTextInput = (props: TextFieldProps) => (
  <TextField type="text" validation={validateNotesKeywordText} {...props} />
);

export default NotesKeywordTextInput;

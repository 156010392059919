import {
  Box,
  createStyles,
  Divider,
  makeStyles,
  Paper,
  PaperProps,
  Theme,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';

export type BlockProps = React.PropsWithChildren<{
  title?: string | JSX.Element;
  description?: string | JSX.Element;
  topRight?: JSX.Element;
  bottomLeft?: JSX.Element;
  bottomRight?: JSX.Element;
  paperProps?: PaperProps;
  titleProps?: TypographyProps;
  descriptionProps?: TypographyProps;
  modal?: boolean;
  inline?: boolean;
  fullscreen?: boolean;
}>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    inlinePaper: {
      display: 'inline-flex',
    },
    controlsWrapper: {
      flex: '0 0 auto',
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    left: {
      flexShrink: 1,
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    right: {
      flexShrink: 1,
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    content: {
      overflowY: 'auto',
      flexGrow: 1,
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    title: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    description: {
      fontWeight: theme.typography.fontWeightLight,
    },
  }),
);

const Block = ({
  children,
  title,
  description,
  topRight,
  bottomLeft,
  bottomRight,
  paperProps = {},
  titleProps = {},
  descriptionProps = {},
  modal = false,
  inline = false,
  fullscreen = false,
}: BlockProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  paperProps.className = clsx(
    inline
      ? clsx(classes.paper, classes.inlinePaper, paperProps.className)
      : classes.paper,
    paperProps.className,
  );

  titleProps.variant = titleProps.variant || 'h2';
  titleProps.className = clsx(classes.title, titleProps.className);

  const showHeader = !!title || !!description || topRight;
  const showFooter = !!bottomLeft || !!bottomRight;

  return (
    <Paper {...paperProps}>
      {showHeader && (
        <Box className={classes.controlsWrapper}>
          <Box className={classes.controls}>
            <Box className={classes.left}>
              {!!title && (
                <Typography className={classes.title} {...titleProps}>
                  {title}
                </Typography>
              )}
              {!!description && (
                <Typography
                  className={classes.description}
                  {...descriptionProps}
                >
                  {description}
                </Typography>
              )}
            </Box>
            <Box className={classes.right}>{topRight}</Box>
          </Box>
          <Divider />
        </Box>
      )}
      <Box
        className={classes.content}
        style={fullscreen || (!!smDown && modal) ? { height: 0 } : undefined}
      >
        {children}
      </Box>
      {showFooter && (
        <Box className={classes.controlsWrapper}>
          <Divider />
          <Box className={classes.controls}>
            <Box className={classes.left}>{bottomLeft}</Box>
            <Box className={classes.right}>{bottomRight}</Box>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default Block;

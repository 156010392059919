import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  roundNumber,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from '@timed/common';
import { billingRates } from '@timed/report';
import { format, isEqual } from 'date-fns';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: '100%',
      flex: '1 1 0',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  }),
);

const SettingsOrganisationBillingForm = () => {
  const classes = useStyles();

  // Unique effective-from dates of billing rates
  const dates = billingRates
    .map(({ date }) => date)
    .filter(
      (date, i, self) =>
        self.findIndex((d) => d.getTime() === date.getTime()) === i,
    );

  return (
    <Box className={classes.wrapper}>
      <Table
        inline
        enableRowHighlighting
        persistentRowHighlighting
        border="line"
      >
        <TableHeader>NDIS Price Guides</TableHeader>
        <TableHeader></TableHeader>
        <TableHeader align="right"></TableHeader>
        {dates.map((date, i) => {
          const rates = billingRates.filter((rate) => isEqual(date, rate.date));
          return (
            <>
              <TableRow key={i}>
                <TableCell colSpan={3} className={classes.bold}>
                  Effective {format(date, 'dd MMM yyyy')}
                </TableCell>
              </TableRow>
              {rates.map(
                (
                  { supportItemNumber: id, supportItemName: description, rate },
                  i2,
                ) => (
                  <TableRow key={i2}>
                    <TableCell indent={1}>{id}</TableCell>
                    <TableCell>{description}</TableCell>
                    <TableCell>
                      ${roundNumber(rate / 100, 2).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ),
              )}
            </>
          );
        })}
      </Table>
    </Box>
  );
};

export default SettingsOrganisationBillingForm;

import { Box, createStyles, makeStyles } from '@material-ui/core';
import { green, red, yellow } from '@material-ui/core/colors';
import { _peopleClients, _peopleMembers } from '@timed/app';
import { useAuth } from '@timed/auth';
import {
  DateInput,
  Link,
  Select,
  SelectMenuItem,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  formatPersonName,
} from '@timed/common';
import {
  EntityState,
  OrderBy,
  useReportWorkedWithGetProfilesQuery,
  useReportWorkedWithGetResultClientsLazyQuery,
  useReportWorkedWithGetResultMembersLazyQuery,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import {
  isAfter,
  isEqual,
  isValid,
  startOfWeek,
  subWeeks,
  subYears,
} from 'date-fns';
import addDays from 'date-fns/addDays';
import { subDays } from 'date-fns/esm';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

export type Statistic = {
  name: string;
  firstShiftDate: Date;
  lastShiftDate: Date;
  totalShiftsCount: number;
  totalShiftsDuration: number;
};

type FormData = {
  type: 'member' | 'client';
  selectedProfileId: string;
  period: 'previous' | 'current' | 'custom' | undefined;
  from?: Date;
  to?: Date;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    grid: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      gridTemplateColumns: 'auto auto',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(4),
    },
    info: {
      padding: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    warning: {
      backgroundColor: yellow[50],
      border: '1px solid ' + yellow[500],
    },
    error: {
      backgroundColor: red[50],
      border: '1px solid ' + red[100],
    },
    success: {
      backgroundColor: green[50],
      border: '1px solid ' + green[100],
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    checker: {
      padding: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper2,
      border: '1px solid ' + theme.palette.divider,
      display: 'grid',
      gridTemplateColumns: 'repeat(6, max-content)',
      columnGap: theme.spacing(4),
      rowGap: theme.spacing(0),
      alignItems: 'center',
    },
  }),
);

const ReportWorkedWith = () => {
  const classes = useStyles();

  const WEEK_STARTS_ON = 1; // MONDAY

  const { control, watch, setValue } = useForm<FormData>({
    defaultValues: {
      period: 'custom',
      from: subYears(new Date(), 1),
      to: new Date(),
    },
  });

  const { branch } = useAuth();

  const period = watch('period');
  const from = watch('from');
  const to = watch('to');
  const type = watch('type');
  const selectedProfileId = watch('selectedProfileId');

  const periods = useMemo<SelectMenuItem[]>(
    () => [
      { label: 'Most recently ended payroll period', value: 'previous' },
      { label: 'Current payroll period', value: 'current' },
      { label: 'Custom date range', value: 'custom' },
    ],
    [],
  );

  const [getResultMembers, membersResult] =
    useReportWorkedWithGetResultMembersLazyQuery();
  const [getResultClients, clientsResult] =
    useReportWorkedWithGetResultClientsLazyQuery();

  const profilesResponse = useReportWorkedWithGetProfilesQuery({
    fetchPolicy: 'network-only',
    variables: {
      clientsInput: {
        where: {
          events: {
            id: {
              _ne: null,
            },
            payable: {
              _eq: true,
            },
            billable: {
              _eq: true,
            },
            delete: { id: { _eq: null } },
          },
          branch: !!branch ? { id: { _eq: branch.id } } : undefined,
        },
        orderBy: [
          { lastName: OrderBy.ASC_NULLS_FIRST },
          { firstName: OrderBy.ASC },
        ],
        entityStates: [EntityState.NORMAL, EntityState.ARCHIVED],
      },
      membersInput: {
        where: {
          events: {
            id: {
              _ne: null,
            },
            payable: {
              _eq: true,
            },
            billable: {
              _eq: true,
            },
            delete: { id: { _eq: null } },
          },
          branchMembers: !!branch
            ? { branch: { id: { _eq: branch.id } } }
            : undefined,
        },
        orderBy: [
          { lastName: OrderBy.ASC_NULLS_FIRST },
          { firstName: OrderBy.ASC },
        ],
        entityStates: [EntityState.NORMAL, EntityState.ARCHIVED],
      },
    },
  });

  useLoadingEffect(profilesResponse.loading);

  useEffect(() => {
    if (period) {
      switch (period) {
        case 'current':
          setValue(
            'from',
            startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
          );
          setValue(
            'to',
            addDays(
              startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
              6,
            ),
          );
          break;
        case 'previous':
          setValue(
            'from',
            subWeeks(
              startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
              1,
            ),
          );
          setValue(
            'to',
            subDays(
              startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
              1,
            ),
          );
          break;
      }
    }
  }, [period, setValue]);

  useEffect(() => {
    if (!!from && !!to) {
      if (
        isEqual(
          from,
          startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
        ) &&
        isEqual(
          to,
          addDays(startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }), 6),
        )
      ) {
        setValue('period', 'current');
      } else if (
        isEqual(
          from,
          subWeeks(
            startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
            1,
          ),
        ) &&
        isEqual(
          to,
          subDays(startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }), 1),
        )
      ) {
        setValue('period', 'previous');
      } else {
        setValue('period', 'custom');
      }
    }
  }, [from, to, setValue]);

  useEffect(() => {
    if (
      !!selectedProfileId &&
      !!from &&
      !!to &&
      isValid(from) &&
      isValid(to) &&
      isAfter(to, from)
    ) {
      switch (type) {
        case 'client':
          getResultMembers({
            variables: {
              input: {
                entityStates: [EntityState.ARCHIVED, EntityState.NORMAL],
                where: {
                  events: {
                    client: { id: { _eq: selectedProfileId } },
                    delete: { id: { _eq: null } },
                    billable: { _eq: true },
                    payable: { _eq: true },
                    startAt: { _gte: from },
                    endAt: { _lt: to },
                  },
                },
                orderBy: [
                  { lastName: OrderBy.ASC_NULLS_LAST },
                  { firstName: OrderBy.ASC },
                ],
              },
            },
          });
          break;

        case 'member':
          getResultClients({
            variables: {
              input: {
                entityStates: [EntityState.ARCHIVED, EntityState.NORMAL],
                where: {
                  events: {
                    member: { id: { _eq: selectedProfileId } },
                    delete: { id: { _eq: null } },
                    billable: { _eq: true },
                    payable: { _eq: true },
                    startAt: { _gte: from },
                    endAt: { _lt: to },
                  },
                },
                orderBy: [
                  { lastName: OrderBy.ASC_NULLS_LAST },
                  { firstName: OrderBy.ASC },
                ],
              },
            },
          });
          break;
      }
    }
  }, [type, from, to, getResultClients, getResultMembers, selectedProfileId]);

  return (
    <div className={classes.grid}>
      <Box className={classes.row}>
        <Select
          control={control}
          name="type"
          label="Entity type"
          items={[
            { label: 'Participants', value: 'client' },
            { label: 'Support Workers', value: 'member' },
          ]}
          formControlProps={{
            variant: 'outlined',
            size: 'small',
            style: { width: 160 },
          }}
        />
      </Box>
      {!!type && (
        <Box className={classes.row}>
          <Select
            control={control}
            name="selectedProfileId"
            label="Person"
            items={(type === 'member'
              ? profilesResponse.data?.members
              : profilesResponse.data?.clients
            )?.map(({ id, ...profile }) => ({
              value: id,
              label: formatPersonName(profile, {
                capitaliseLastName: true,
                lastNameFirst: true,
              }),
            }))}
            formControlProps={{
              variant: 'outlined',
              size: 'small',
              style: { width: 160 },
            }}
          />
          <Select
            control={control}
            name="period"
            label="Reporting period"
            items={periods}
            formControlProps={{
              variant: 'outlined',
              size: 'small',
              style: { width: 160 },
            }}
          />
          {period && (
            <Box className={classes.row}>
              <DateInput
                disableToolbar
                autoOk
                control={control}
                name="from"
                label="From start of day"
                inputVariant="outlined"
                size="small"
              />
              <DateInput
                disableToolbar
                autoOk
                control={control}
                name="to"
                label="To end of day"
                inputVariant="outlined"
                size="small"
              />
            </Box>
          )}
        </Box>
      )}
      {!!period && !!selectedProfileId && <></>}
      {!!selectedProfileId &&
        (type === 'client'
          ? !!membersResult.data?.members.length
          : !!clientsResult.data?.clients.length) && (
          <Table inline showIndexColumn wrapperStyle={{ flex: '1 1 0' }}>
            <TableHeader>
              {type === 'client' ? 'Support Workers' : 'Participants'}
            </TableHeader>
            {(type === 'client'
              ? membersResult.data?.members
              : clientsResult.data?.clients)!.map((person, i) => (
              <TableRow key={i}>
                <TableCell>
                  <Link
                    to={`${
                      (type === 'client' ? _peopleClients : _peopleMembers).path
                    }/${selectedProfileId}`}
                  >
                    {formatPersonName(person, {
                      capitaliseLastName: true,
                      lastNameFirst: true,
                    })}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        )}
    </div>
  );
};

export default ReportWorkedWith;

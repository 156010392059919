import {
  Box,
  Button,
  createStyles,
  Link,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useAuth } from '@timed/auth';
import { formatPersonName } from '@timed/common';
import { OrderBy, useGetMyUnavailablesLazyQuery } from '@timed/gql';
import {
  MemberUnavailabilityPeriod,
  MemberUpdateUnavailableDaysForm,
} from '@timed/member';
import { useModal } from 'mui-modal-provider';
import { useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      alignItems: 'start',
    },
    title: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    unavailables: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(8),
      },
    },
    unavailablePeriod: {
      // width: 'max-content',
      padding: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      cursor: 'pointer',
    },
  }),
);

const ShiftsUnavailability = () => {
  const classes = useStyles();

  const auth = useAuth();

  const { showModal } = useModal();

  const [getUnavailables, { data, loading }] = useGetMyUnavailablesLazyQuery();

  // Fetch events
  useEffect(() => {
    if (!data && !loading)
      getUnavailables({
        variables: {
          input: {
            orderBy: [{ startAt: OrderBy.ASC }],
          },
        },
      });
  }, [getUnavailables, data, loading]);

  const handleOpenCreateUnavailabilityPeriodModal = () => {
    // const modal: { hide: () => void } = showModal(
    //   MemberCreateUnavailabilityPeriodForMeForm,
    //   {
    //     onClose: () => {
    //       modal.hide();
    //     },
    //   },
    // );
  };

  return loading ? (
    <Typography>Loading...</Typography>
  ) : (
    <Box className={classes.wrapper}>
      <MemberUpdateUnavailableDaysForm member={auth.member!} />
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenCreateUnavailabilityPeriodModal()}
      >
        Add Unavailability
      </Button>
      <Box className={classes.unavailables}>
        {!data?.myUnavailables.length ? (
          <Typography variant="body1">
            You have no logged future unavailability.
          </Typography>
        ) : (
          <>
            <Typography variant="body1">
              If any of the below periods change, please contact{' '}
              {auth.org?.schedulingContactPerson ? (
                <>
                  {formatPersonName(auth.org.schedulingContactPerson)}
                  {!!auth.org.schedulingContactPerson.phone ? (
                    <>
                      {' '}
                      on{' '}
                      <Link
                        href={
                          'tel:' +
                          auth.org.schedulingContactPerson.phone!.replace(
                            /\s+/g,
                            '',
                          )
                        }
                      >
                        {auth.org.schedulingContactPerson?.phone}
                      </Link>
                    </>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                ' your employer'
              )}
              .
            </Typography>
            {data.myUnavailables.map((period) => (
              <MemberUnavailabilityPeriod period={period} />
            ))}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ShiftsUnavailability;

import { formatString } from '@timed/common/utils/formatString';

/**
 * Format a value if it is a string.
 * @param value The value to format
 * @returns Formatted string or original value if it was not a string
 */
export const formatAbn = (value: string): any => {
  if (typeof value === 'string') {
    const v = formatString(value, { deepTrim: true });

    return (
      v.slice(0, 2) +
      ' ' +
      v.slice(2, 5) +
      ' ' +
      v.slice(5, 8) +
      ' ' +
      v.slice(8, 11)
    );
  }

  return value;
};

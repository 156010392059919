import { useAuth } from '@timed/auth';
import {
  EntityState,
  MembersWhereInput,
  ModuleType,
  OrderBy,
  Permission,
  useMemberListContextGetAggregatedMembersLazyQuery,
  useMemberListContextGetAggregatedRedactedMembersLazyQuery,
  useMemberListContextGetMembersExtraFieldsLazyQuery,
} from '@timed/gql';
import {
  MemberListContext,
  MemberListContextInput,
  MemberListContextType,
} from '@timed/member';
import { useEffect, useMemo, useState } from 'react';

type MemberListProviderProps = React.PropsWithChildren<{
  module: ModuleType;
}>;

const MemberListProvider = ({ children, module }: MemberListProviderProps) => {
  const storagePrefix = 'list.filters';

  const { permissible, branch } = useAuth();

  /**
   * Comment text to filter by.
   */
  const [search, setSearch] = useState<string | null>(
    sessionStorage.getItem(storagePrefix + '.search'),
  );

  /**
   * Entity states to filter by.
   */
  const [entityStates, setEntityStates] = useState<EntityState[]>(
    !!sessionStorage.getItem(storagePrefix + '.state')?.includes(',')
      ? (sessionStorage
          .getItem(storagePrefix + '.state')!
          .split(',') as EntityState[])
      : !!sessionStorage.getItem(storagePrefix + '.state')
      ? [sessionStorage.getItem(storagePrefix + '.state') as EntityState]
      : [EntityState.NORMAL, EntityState.ARCHIVED, EntityState.DELETED],
  );

  /**
   * Ordering options.
   */
  const [orderBy, setOrderBy] = useState<MemberListContextInput['orderBy']>(
    sessionStorage
      .getItem('member.' + storagePrefix + '.orderBy')
      ?.includes(',')
      ? [
          {
            [sessionStorage
              .getItem('member.' + storagePrefix + '.orderBy')!
              .split(',')[0]]:
              OrderBy[
                sessionStorage
                  .getItem('member.' + storagePrefix + '.orderBy')!
                  .split(',')[1] as OrderBy
              ],
          },
        ]
      : [{ lastName: OrderBy.ASC_NULLS_LAST }],
  );

  /**
   * Query offset.
   */
  const [offset, setOffset] = useState<number>(0);

  /**
   * Query limit.
   */
  const limit = 100;

  /**
   * Reset all filters back to their defaults.
   */
  const reset = () => {
    setSearch('');
    setEntityStates([
      EntityState.NORMAL,
      EntityState.ARCHIVED,
      EntityState.DELETED,
    ]);
    setOffset(0);
  };

  const where = useMemo(() => {
    let where: MembersWhereInput = {
      branchMembers: !!branch
        ? { branch: { id: { _eq: branch.id } } }
        : undefined,
      moduleAccessSC: module === ModuleType.SC ? { _eq: true } : undefined,
    };

    if (search) {
      where._or = [
        { lastName: { _re: '(?i)' + search } },
        { firstName: { _re: '(?i)' + search } },
        { middleName: { _re: '(?i)' + search } },
        { preferredName: { _re: '(?i)' + search } },
        { locality: { _re: '(?i)' + search } },
      ];

      if (permissible({ permissions: Permission.MEMBER_READ }))
        where._or.push({ externalId: { _re: '(?i)' + search } });
    }

    return where;
  }, [search, permissible, branch, module]);

  const hasPermission = useMemo(
    () => permissible({ permissions: Permission.MEMBER_READ }),
    [permissible],
  );

  const [getMembersExtraFields, membersExtraFieldsResponse] =
    useMemberListContextGetMembersExtraFieldsLazyQuery({
      notifyOnNetworkStatusChange: true,
    });

  const [getMembers, membersResponse] =
    useMemberListContextGetAggregatedMembersLazyQuery({
      notifyOnNetworkStatusChange: true,
      onCompleted(data) {
        getMembersExtraFields({
          variables: {
            input: {
              where,
              orderBy,
              limit,
              offset,
              entityStates:
                // Ignore entityStates if the user lacks permissions
                permissible({ permissions: Permission.MEMBER_READ }) &&
                entityStates &&
                !!entityStates.length
                  ? entityStates
                  : undefined,
            },
          },
        });
      },
    });

  const [getRedactedMembers, redactedMembersResponse] =
    useMemberListContextGetAggregatedRedactedMembersLazyQuery({
      notifyOnNetworkStatusChange: true,
    });

  const getEntities = useMemo(
    () => (hasPermission ? getMembers : getRedactedMembers),
    [hasPermission, getMembers, getRedactedMembers],
  );

  const refetch = useMemo(
    () =>
      hasPermission ? membersResponse.refetch : redactedMembersResponse.refetch,
    [hasPermission, membersResponse.refetch, redactedMembersResponse.refetch],
  );

  const fetchMore = useMemo(
    () =>
      hasPermission
        ? membersResponse.fetchMore
        : redactedMembersResponse.fetchMore,
    [
      hasPermission,
      membersResponse.fetchMore,
      redactedMembersResponse.fetchMore,
    ],
  );

  const aggregate = useMemo(
    () =>
      hasPermission
        ? membersResponse.data?.membersAggregate.aggregate
        : redactedMembersResponse.data?.redactedMembersAggregate.aggregate,
    [
      hasPermission,
      membersResponse.data?.membersAggregate.aggregate,
      redactedMembersResponse.data?.redactedMembersAggregate.aggregate,
    ],
  );

  const nodes = useMemo(
    () =>
      hasPermission
        ? membersResponse.data?.membersAggregate.nodes.map((node) =>
            !!membersExtraFieldsResponse.data?.members.find(
              ({ id }) => id === node.id,
            )
              ? {
                  ...node,
                  ...membersExtraFieldsResponse.data?.members.find(
                    ({ id }) => id === node.id,
                  ),
                }
              : node,
          )
        : redactedMembersResponse.data?.redactedMembersAggregate.nodes,
    [
      hasPermission,
      membersResponse.data?.membersAggregate.nodes,
      redactedMembersResponse.data?.redactedMembersAggregate.nodes,
      membersExtraFieldsResponse.data?.members,
    ],
  );

  const called = membersResponse.called ?? redactedMembersResponse.called;

  const loading = membersResponse.loading ?? redactedMembersResponse.loading;

  /**
   * Save search to storage.
   */
  useEffect(() => {
    if (search !== sessionStorage.getItem(storagePrefix + '.search'))
      sessionStorage.setItem(storagePrefix + '.search', search ?? '');
  }, [search]);

  /**
   * Save entityState to storage.
   */
  useEffect(() => {
    if (
      entityStates[0] !== sessionStorage.getItem(storagePrefix + '.state') ||
      entityStates.length !==
        sessionStorage.getItem(storagePrefix + '.state')?.split(',').length
    )
      sessionStorage.setItem(
        storagePrefix + '.state',
        entityStates.join(',') ?? '',
      );
  }, [entityStates]);

  /**
   * Save orderBy to storage.
   */
  useEffect(() => {
    if (
      Object.keys(Object.values(orderBy)[0])[0] !==
        sessionStorage.getItem('member.' + storagePrefix + '.orderBy') ||
      Object.values(Object.values(orderBy)[0])[0] !==
        sessionStorage.getItem('member.' + storagePrefix + '.orderBy')
    )
      sessionStorage.setItem(
        'member.' + storagePrefix + '.orderBy',
        Object.entries(Object.values(orderBy)[0]).every(([k, v]) => !!k && !!v)
          ? Object.keys(Object.values(orderBy)[0])[0] +
              ',' +
              Object.values(Object.values(orderBy)[0])[0]
          : '',
      );
  }, [orderBy]);

  /**
   * Fetch subsequent data.
   */
  const fetch: MemberListContextType['fetch'] = ({ offset } = {}) => {
    const input = {
      where,
      orderBy,
      limit,
      offset: offset ?? 0,
      entityStates:
        // Ignore entityStates if the user lacks permissions
        permissible({ permissions: Permission.MEMBER_READ }) &&
        entityStates &&
        !!entityStates.length
          ? entityStates
          : undefined,
    };

    setOffset(offset ?? 0);

    if (offset !== undefined) {
      fetchMore({ variables: { input } });
    } else {
      refetch({ input });
    }
  };

  /**
   * Fetch initial data.
   */
  useEffect(() => {
    if (!called)
      getEntities({
        variables: {
          input: {
            where,
            orderBy,
            limit,
            offset,
            entityStates:
              // Ignore entityStates if the user lacks permissions
              permissible({ permissions: Permission.MEMBER_READ }) &&
              entityStates &&
              !!entityStates.length
                ? entityStates
                : undefined,
          },
        },
      });
  });

  /**
   * Refetch data when needed.
   */
  useEffect(() => {
    if (called) {
      setOffset(0);
      refetch({
        input: {
          where,
          orderBy,
          limit,
          offset: 0,
          entityStates:
            // Ignore entityStates if the user lacks permissions
            permissible({ permissions: Permission.MEMBER_READ }) &&
            entityStates &&
            !!entityStates.length
              ? entityStates
              : undefined,
        },
      });
    }
  }, [
    called,
    setOffset,
    where,
    entityStates,
    limit,
    orderBy,
    permissible,
    refetch,
  ]);

  return (
    <MemberListContext.Provider
      value={{
        fetch,
        reset,
        limit,
        offset,
        setOffset,
        totalCount: aggregate?.totalCount,
        nodes,
        loading,
        loadingExtraFields: membersExtraFieldsResponse.loading,
        input: {
          search,
          setSearch,
          entityStates,
          setEntityStates,
          orderBy,
          setOrderBy,
        },
      }}
    >
      {children}
    </MemberListContext.Provider>
  );
};

export default MemberListProvider;

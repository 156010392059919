import { Grid, Typography } from '@material-ui/core';
import { Protected } from '@timed/auth';
import { addServerErrors, Block, IconButtonMulti } from '@timed/common';
import {
  HistoryRestorable,
  Member,
  Permission,
  useUpdateMembersMutation,
} from '@timed/gql';
import {
  MemberRemindClockCheckbox,
  MemberRemindEventsCheckbox,
  MemberRemindNotesCheckbox,
} from '@timed/member';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type MemberUpdateCommunicationPreferencesFormProps = {
  member: Pick<
    Member,
    'id' | 'remindClock' | 'remindEvents' | 'remindNotes'
  > & {
    archive?: Pick<HistoryRestorable, 'id'> | null;
  };
};

type FormData = {
  patch: Pick<Member, 'remindClock' | 'remindEvents' | 'remindNotes'>;
};

const MemberUpdateCommunicationPreferencesForm = ({
  member,
}: MemberUpdateCommunicationPreferencesFormProps) => {
  const [editing, setEditing] = useState<boolean>(false);

  const [updateMember, response] = useUpdateMembersMutation();

  const defaultValues: FormData = {
    patch: {
      remindClock: member.remindClock,
      remindEvents: member.remindEvents,
      remindNotes: member.remindNotes,
    },
  };

  const { handleSubmit, watch, control, setError } = useForm<FormData>({
    defaultValues,
  });

  const currentValues = watch();

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = ({ patch }: FormData) => {
    updateMember({
      variables: {
        input: {
          ids: [member.id],
          patch,
        },
      },
    }).catch((e) => {});
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block
        title="Communication Preferences"
        topRight={
          !member.archive ? (
            <Protected permissions={Permission.MEMBER_WRITE}>
              <IconButtonMulti
                size="small"
                enabled={editing}
                changed={!isEqual(currentValues, defaultValues)}
                setEditing={setEditing}
                loading={response.loading}
                success={!!response.data}
              />
            </Protected>
          ) : undefined
        }
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Clock on/off reminders</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.remindClock ? (
                  'On'
                ) : (
                  'Off'
                )
              ) : (
                <MemberRemindClockCheckbox
                  name="patch.remindClock"
                  control={control}
                  defaultChecked={!!member.remindClock}
                  label={undefined}
                />
              )}
            </Typography>
          </Grid>

          <Grid item xs={4} md={3} lg={2}>
            <Typography>Upcoming shift reminders</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.remindEvents ? (
                  'On'
                ) : (
                  'Off'
                )
              ) : (
                <MemberRemindEventsCheckbox
                  name="patch.remindEvents"
                  control={control}
                  defaultChecked={!!member.remindEvents}
                  label={undefined}
                />
              )}
            </Typography>
          </Grid>

          <Grid item xs={4} md={3} lg={2}>
            <Typography>Incomplete case notes reminders</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.remindNotes ? (
                  'On'
                ) : (
                  'Off'
                )
              ) : (
                <MemberRemindNotesCheckbox
                  name="patch.remindNotes"
                  control={control}
                  defaultChecked={!!member.remindNotes}
                  label={undefined}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
      </Block>
    </form>
  );
};

export default MemberUpdateCommunicationPreferencesForm;

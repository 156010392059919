import { Button, Dialog, ModalProps } from '@timed/common';
import {
  ClientNdisPlanItem,
  useDeleteClientNdisPlanItemsMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';

type ClientDeleteNdisPlanItemDialogProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  clientNdisPlanItem: Pick<ClientNdisPlanItem, 'id' | 'name'>;
};

const ClientDeleteNdisPlanItemDialog = ({
  clientNdisPlanItem,
  onClose,
  ...props
}: ClientDeleteNdisPlanItemDialogProps) => {
  const [deleteEntity, { loading, client }] =
    useDeleteClientNdisPlanItemsMutation();

  useLoadingEffect(loading);

  const handleDelete = () => {
    deleteEntity({
      variables: { input: { ids: [clientNdisPlanItem.id] } },

      onCompleted: ({ deleteClientNdisPlanItems }) => {
        client.cache.evict({
          id: client.cache.identify({
            __typename: 'ClientNdisPlanItem',
            id: deleteClientNdisPlanItems[0].id,
          }),
        });
        client.cache.gc();
        onClose();
      },
    });
  };

  return (
    <Dialog
      {...props}
      title="Deleting NDIS Plan Item"
      description={`Are you sure you want to delete: ${clientNdisPlanItem.name}?`}
      actions={[
        <Button onClick={onClose}>Cancel</Button>,
        <Button onClick={handleDelete}>Yes</Button>,
      ]}
    />
  );
};

export default ClientDeleteNdisPlanItemDialog;

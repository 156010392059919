import { _ndisCoordinationPeopleClientsProfileCategory } from '@timed/app';
import {
  ClientViewFiles,
  ClientViewBudget,
  ClientViewOverview,
} from '@timed/client';
import { useRouter } from '@timed/common';
import { Page } from '@timed/page';
import { RouteErrorNotFound } from '@timed/routes';

const RouteSupportCoordinationPeopleParticipantsProfileCategory = () => {
  const {
    query: { category, client },
  } = useRouter();

  let element!: JSX.Element;
  let label!: string;
  let path!: string;

  switch (category) {
    case 'overview':
      label = 'Overview';
      path = `/people/participants/${client}/overview`;
      element = <ClientViewOverview />;
      break;

    case 'notes':
      label = 'Notes';
      path = `/people/participants/${client}/notes`;
      element = <></>;
      break;

    case 'activities':
      label = 'Activities';
      path = `/people/participants/${client}/activities`;
      element = <></>;
      break;

    case 'files':
      label = 'Files';
      path = `/people/participants/${client}/files`;
      element = <ClientViewFiles />;
      break;

    case 'budget':
      label = 'NDIS Plan';
      path = `/people/participants/${client}/budget`;
      element = <ClientViewBudget />;
      break;
  }

  return !label || !path || !element ? (
    <RouteErrorNotFound />
  ) : (
    <Page {..._ndisCoordinationPeopleClientsProfileCategory} path={path}>
      {element}
    </Page>
  );
};

export default RouteSupportCoordinationPeopleParticipantsProfileCategory;

import { Select, SelectProps } from '@timed/common';
import { uploadProcessingNotes } from '@timed/upload';

const UploadProcessingNoteSelectInput = ({
  defaultValue,
  ...props
}: Omit<SelectProps, 'items'>) => (
  <Select
    label="Description"
    items={uploadProcessingNotes.map((value) => ({
      value,
      label: value,
    }))}
    renderValue={(value) => value as string}
    {...props}
  />
);

export default UploadProcessingNoteSelectInput;

import { Menu, MenuItem } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useAuth } from '@timed/auth';
import { IconButton } from '@timed/common';
import {
  Permission,
  useArchiveMembersMutation,
  useRestoreMembersMutation,
} from '@timed/gql';
import { MemberContext } from '@timed/member';
import { useContext, useState } from 'react';

const MemberViewActionMenuButton = () => {
  const auth = useAuth();

  const member = useContext(MemberContext);

  const [archiveMembers] = useArchiveMembersMutation();

  const [unarchiveMembers] = useRestoreMembersMutation();

  const handleOpenModal = () => {
    // const modal: { hide: () => void } = showModal(ProfileArchiveForm, {
    //   onClose: () => modal.hide(),
    //   profile,
    // });
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleArchive = () => {
    archiveMembers({ variables: { input: { ids: [member.id] } } });
  };

  const handleUnarchive = () => {
    unarchiveMembers({ variables: { input: { ids: [member.id] } } });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <span>
      <IconButton aria-label="menu" color="inherit" onClick={handleClick}>
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
          },
        }}
      >
        {!member.archive &&
          auth.permissible({
            permissions: Permission.SUPPORTCOORDINATOR_WRITE,
          }) && (
            <MenuItem
              onClick={() => {
                handleArchive();
                handleOpenModal();
                handleClose();
              }}
            >
              Archive member
            </MenuItem>
          )}

        {member.archive &&
          auth.permissible({
            permissions: Permission.SUPPORTCOORDINATOR_WRITE,
          }) && (
            <MenuItem
              onClick={() => {
                handleUnarchive();
                handleOpenModal();
                handleClose();
              }}
            >
              Unarchive member
            </MenuItem>
          )}
      </Menu>
    </span>
  );
};

export default MemberViewActionMenuButton;

import {
  BoxProps,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  CalendarContext,
  CalendarControlBar,
  CalendarEvent,
  CalendarNowIndicator,
  CalendarTimes,
  useElementSize,
} from '@timed/common';
import clsx from 'clsx';
import {
  addDays,
  addMinutes,
  differenceInDays,
  eachMonthOfInterval,
  format,
  isEqual,
  isMonday,
  isSameDay,
  isSameMonth,
  isSaturday,
  isSunday,
  isToday,
  isWeekend,
  startOfWeek,
} from 'date-fns';
import { eachDayOfInterval, eachWeekOfInterval } from 'date-fns/esm';
import { useModal } from 'mui-modal-provider';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

const Calendar = () => {
  const { showModal } = useModal();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [scale, setScale] = useState<number>(1);

  const columnWidth = smDown ? 40 * scale : 60 * scale;

  const {
    from,
    range,
    now,
    setFrom,
    events,
    calcTime,
    interactive,
    interactWithEntity,
    entityInteractions,
    weekStartsOn,
    scrollableAreaRef,
    cellClickModal,
    cellClickModalProps,
  } = useContext(CalendarContext);

  const { width } = useElementSize(
    scrollableAreaRef!.current?.parentElement?.parentElement,
  );

  /**
   * Open a modal when a cell is clicked.
   */
  useEffect(() => {
    if (
      !!cellClickModal &&
      !!entityInteractions.cell.down?.key &&
      !!entityInteractions.cell.up?.key
    ) {
      const modal: { hide: () => void } = showModal(cellClickModal, {
        startAt: new Date(
          Math.min(
            parseInt(entityInteractions.cell.down.key),
            parseInt(entityInteractions.cell.up.key),
          ),
        ),
        endAt: addMinutes(
          new Date(
            Math.max(
              parseInt(entityInteractions.cell.down.key),
              parseInt(entityInteractions.cell.up.key),
            ),
          ),
          smDown ? 60 : 30,
        ),
        onClose: () => {
          interactWithEntity({ reset: true, target: { type: 'cell' } });
          modal.hide();
        },
        ...(cellClickModalProps ?? {}),
      });

      interactWithEntity({ reset: true, target: { type: 'cell' } });
    }
  }, [
    showModal,
    smDown,
    cellClickModal,
    cellClickModalProps,
    interactWithEntity,
    entityInteractions.cell.down?.key,
    entityInteractions.cell.up?.key,
  ]);

  const paper1OffColor = '#f5f4f1';
  const paper2OffColor = '#eae8e2';
  const paper3OffColor = '#e0ddd3';

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      wrapper: {
        flexGrow: 1,
        display: 'flex',
        flexFlow: 'column',
        gap: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
          gap: theme.spacing(1),
        },
      },
      calendar: {
        flexGrow: 1,
        display: 'flex',
        userSelect: 'none' /* Standard */,
        WebkitUserSelect: 'none' /* Safari */,
        msUserSelect: 'none' /* IE10+/Edge */,
        MozUserSelect: 'none' /* Firefox */,
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.up('md')]: {
          paddingBottom: 12,
        },
      },
      scrollableArea: {
        overflowY: 'hidden',
        overflowX: 'auto',
        display: 'flex',
        flexGrow: 1,
      },
      month: {
        display: 'flex',
        flexDirection: 'column',
      },
      monthLabel: {
        textTransform: 'uppercase',
        height: 40,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          height: 32,
        },
        '& .MuiTypography-root': {
          position: 'sticky',
          left: 0,
          display: 'flex',
          flexDirection: 'column',
          lineHeight: 1,
          '& span:first-child': {
            fontSize: 14,
            fontWeight: theme.typography.fontWeightMedium,
            [theme.breakpoints.down('sm')]: {
              fontSize: 11,
            },
          },
          '& span:last-child': {
            fontSize: 12,
            fontWeight: theme.typography.fontWeightRegular,
            [theme.breakpoints.down('sm')]: {
              fontSize: 9,
            },
          },
        },
      },
      columns: {
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexGrow: 1,
      },
      column: {
        boxSizing: 'border-box',
        flexGrow: 1,
        flexShrink: 1,
        width: columnWidth,
        borderLeft: 'thin solid ' + theme.palette.divider,
        borderTop: '1px solid rgba(0, 0, 0, 0.5)', //+ theme.palette.divider,
        borderBottom: '1px solid rgba(0, 0, 0, 0.5)', // + theme.palette.divider,
      },
      date: {
        height: 40,
        lineHeight: 1.5,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        '&:hover': {
          backgroundColor: theme.palette.background.paper3 + ' !important',
        },
        '& span': {
          fontSize: 12,
        },
        '& span:first-child': {
          fontSize: 12,
          flexGrow: 1,
        },
        '& span:last-child': {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightBold,
        },
        [theme.breakpoints.down('sm')]: {
          height: 32,
          lineHeight: 1.3,
          '& span': {
            fontSize: 11,
          },
          '& span:last-child': {
            fontSize: 12,
            fontWeight: theme.typography.fontWeightBold,
          },
        },
      },
      oddWeekDate: {
        '&:hover': {
          backgroundColor: paper3OffColor + ' !important',
        },
      },
      cellsWrapper: {
        height: 'calc(100% - 40px)',
        [theme.breakpoints.down('sm')]: {
          height: 'calc(100% - 32px)',
        },
      },
      cells: {
        display: 'flex',
        flexFlow: 'column',
        height: '100%',
        cursor: interactive ? 'crosshair' : 'default',
        '& div': {
          flexGrow: 1,
          '&:hover': interactive
            ? {
                backgroundColor:
                  theme.palette.background.paper4 + ' !important',
              }
            : undefined,
        },
        '& div:nth-child(2n+1)': {
          borderTop: 'thin solid ' + theme.palette.divider,
        },
        '& div:nth-child(2n+2)': {
          borderTop:
            'thin ' + (smDown ? 'solid ' : 'dotted ') + theme.palette.divider,
        },
        '& div:first-child': {
          borderTop: '1px solid rgba(0, 0, 0, 0.5)', //+ theme.palette.divider,
        },
      },
      oddWeekCells: {
        '& div': {
          '&:hover': interactive
            ? { backgroundColor: paper3OffColor + ' !important' }
            : undefined,
        },
      },
      events: {
        zIndex: 2,
        marginRight: '20%',
        position: 'relative',
        pointerEvents: 'none',
      },
    }),
  );

  const classes = useStyles();

  /**
   * Handle mouse out.
   */
  const handleMouseOut: React.MouseEventHandler<HTMLDivElement> = () => {
    // Clear all observed calendar entity interactions
    interactWithEntity({ reset: true, target: { type: 'cell' } });
    interactWithEntity({ reset: true, target: { type: 'event' } });
  };

  /**
   * Generate column dates.
   */
  const dates = useMemo<Date[]>(() => {
    return eachDayOfInterval({ start: from, end: addDays(from, range - 1) });
  }, [range, from]);

  const handleMouseEvent = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      switch (event.type) {
        case 'mousedown':
          interactWithEntity({
            interaction: 'down',
            target: {
              key: event.currentTarget.getAttribute('data-date')!,
              type: 'cell',
            },
          });
          break;
        case 'mouseup':
          interactWithEntity({
            interaction: 'up',
            target: {
              key: event.currentTarget.getAttribute('data-date')!,
              type: 'cell',
            },
          });
          break;
        case 'mouseenter':
          if (entityInteractions.cell.down) {
            interactWithEntity({
              interaction: 'over',
              target: {
                key: event.currentTarget.getAttribute('data-date')!,
                type: 'cell',
              },
            });
          }
          break;
      }
    },
    [entityInteractions.cell.down, interactWithEntity],
  );

  const interactionEvents = useMemo<BoxProps | undefined>(
    () =>
      !interactive
        ? undefined
        : {
            onMouseDown: handleMouseEvent,
            onMouseUp: handleMouseEvent,
            onMouseEnter: handleMouseEvent,
          },
    [handleMouseEvent, interactive],
  );

  const months = useMemo(() => {
    return eachMonthOfInterval({
      start: dates[0],
      end: dates[dates.length - 1],
    });
  }, [dates]);

  const weeks = useMemo(() => {
    return eachWeekOfInterval(
      { start: dates[0], end: dates[dates.length - 1] },
      { weekStartsOn },
    );
  }, [dates, weekStartsOn]);

  return (
    <div className={classes.wrapper}>
      <CalendarControlBar />
      <div
        className={classes.calendar}
        style={{ width }}
        onWheel={(event) => {
          event.deltaY < 0
            ? scale < 2 && setScale(scale + 0.5)
            : scale > 0.5 && setScale(scale - 0.5);
        }}
      >
        <CalendarTimes />
        <div
          className={classes.scrollableArea}
          ref={scrollableAreaRef}
          onMouseLeave={handleMouseOut}
        >
          {months.map((month, i) => (
            <div
              className={classes.month}
              style={{
                flexBasis:
                  (dates.filter((date) => isSameMonth(month, date)).length /
                    dates.length) *
                    100 +
                  '%',
                zIndex: i,
              }}
            >
              <div className={classes.monthLabel}>
                <Typography>
                  <span>
                    {format(
                      month,
                      dates.filter((date) => isSameMonth(month, date))
                        .length === 1
                        ? 'MMM'
                        : 'MMMM',
                    )}
                  </span>
                  <span>{format(month, 'yyyy')}</span>
                </Typography>
              </div>
              <div className={classes.columns}>
                {dates
                  .filter((date) => isSameMonth(month, date))
                  .map((date, ci) => {
                    const formattedDate = format(date, 'ccc');

                    const oddWeek = !!(
                      weeks.findIndex((week) =>
                        isEqual(week, startOfWeek(date, { weekStartsOn })),
                      ) % 2
                    );

               

                    const autoMemberAssign = new Date();

                 

                    return (
                      <div
                        className={classes.column}
                        key={ci}
                        style={{
                          borderRight:
                            (i === months.length - 1 &&
                              ci ===
                                dates.filter((date) => isSameMonth(month, date))
                                  .length -
                                  1) ||
                            (isSaturday(date) && weekStartsOn === 0) ||
                            (isSunday(date) && weekStartsOn === 1)
                              ? '1px solid rgba(0, 0, 0, 0.5)' // + theme.palette.divider
                              : undefined,
                          borderLeft:
                            i === 0 && ci === 0
                              ? '1px solid rgba(0, 0, 0, 0.5)' // + theme.palette.divider
                              : (isSunday(date) && weekStartsOn === 0) ||
                                (isMonday(date) && weekStartsOn === 1)
                              ? '0'
                              : undefined,
                        }}
                      >
                        <div
                          className={
                            oddWeek
                              ? clsx(classes.date, classes.oddWeekDate)
                              : classes.date
                          }
                          onClick={() => {
                            if (from !== date) setFrom(date);
                          }}
                          style={{
                            backgroundColor: isToday(date)
                              ? theme.palette.secondary.main
                              : isSameDay(date, autoMemberAssign)
                              ? theme.palette.primary.main
                              : isWeekend(date)
                              ? oddWeek
                                ? paper1OffColor
                                : theme.palette.background.paper
                              : undefined,
                            color: isSameDay(date, autoMemberAssign)
                              ? theme.palette.getContrastText(
                                  theme.palette.primary.main,
                                )
                              : undefined,
                          }}
                        >
                          <span>
                            {smDown || range >= 28
                              ? ['M', 'W', 'F'].includes(
                                  formattedDate.charAt(0),
                                )
                                ? formattedDate.charAt(0)
                                : formattedDate.charAt(0) +
                                  formattedDate.charAt(1)
                              : format(date, 'cccccc')}
                          </span>
                          <span

                          >
                            {format(date, 'd')}
                          </span>
                        </div>
                        <div className={classes.cellsWrapper}>
                          <div
                            className={
                              oddWeek
                                ? clsx(classes.cells, classes.oddWeekCells)
                                : classes.cells
                            }
                          >
                            {Array.from(
                              { length: smDown ? 24 : 48 },
                              (_, i) => i,
                            ).map((i) => {
                              const timestamp = addMinutes(
                                date,
                                i * (smDown ? 60 : 30),
                              ).getTime();

                              return (
                                <div
                                  {...interactionEvents}
                                  data-date={addMinutes(
                                    date,
                                    i * (smDown ? 60 : 30),
                                  ).getTime()}
                                  style={{
                                    backgroundColor:
                                      entityInteractions.cell.down &&
                                      entityInteractions.cell.over &&
                                      timestamp <=
                                        Math.max(
                                          parseInt(
                                            entityInteractions.cell.down.key,
                                          ),
                                          parseInt(
                                            entityInteractions.cell.over.key,
                                          ),
                                        ) &&
                                      timestamp >=
                                        Math.min(
                                          parseInt(
                                            entityInteractions.cell.down.key,
                                          ),
                                          parseInt(
                                            entityInteractions.cell.over.key,
                                          ),
                                        )
                                        ? oddWeek
                                          ? paper3OffColor
                                          : theme.palette.background.paper3
                                        : (smDown && i >= 6 && i < 20) ||
                                          (!smDown && i >= 12 && i < 40)
                                        ? isWeekend(date)
                                          ? oddWeek
                                            ? paper2OffColor
                                            : theme.palette.background.paper2
                                          : oddWeek
                                          ? paper1OffColor
                                          : theme.palette.background.paper
                                        : isWeekend(date)
                                        ? oddWeek
                                          ? paper1OffColor
                                          : theme.palette.background.paper
                                        : theme.palette.background.default,
                                  }}
                                />
                              );
                            })}
                          </div>
                          {isSameDay(date, calcTime(now)) && (
                            <CalendarNowIndicator date={date} />
                          )}
                        </div>
                        {!!events.length && (
                          <div
                            className={classes.events}
                            style={{
                              height:
                                'calc(100% - ' + (smDown ? 32 : 40) + 'px)',
                              top: 'calc(-100% + ' + (smDown ? 32 : 40) + 'px)',
                            }}
                          >
                            {events[differenceInDays(date, from)].map(
                              (shift, i) => (
                                <CalendarEvent key={i} {...shift} />
                              ),
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          ))}
        </div>
        {!smDown && <CalendarTimes />}
      </div>
    </div>
  );
};

export default Calendar;

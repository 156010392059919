import { BillingRate } from '@timed/report/constants/BillingRates';

export type BillingTime = {
  billingRateType: BillingRate['type'];
  start: number;
  end: number;
};

export const billingTimes: BillingTime[] = [
  { billingRateType: 'night', start: 0, end: 6 },
  { billingRateType: 'evening', start: 20, end: 24 },
  { billingRateType: 'weekday-daytime', start: 6, end: 20 },
];

import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ClientListContext } from '@timed/client/components/List/context';
import { Select } from '@timed/common';
import { EntityState } from '@timed/gql';
import _ from 'lodash';
import { ChangeEvent, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        '& .MuiSelect-root': {
          fontSize: 12,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.divider,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': {
        padding: theme.spacing(2, 9, 2, 4),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1.75, 8, 1.75, 2),
        },
      },
      '& .MuiTypography-root': {
        fontSize: '1rem',
        [theme.breakpoints.down('sm')]: {
          fontSize: 12,
        },
      },
    },
  }),
);

const ClientListControlStateInput = () => {
  const classes = useStyles();

  const {
    input: { entityStates, setEntityStates },
  } = useContext(ClientListContext);

  const { control, getValues, setValue } = useForm<{ state: string }>({
    defaultValues: { state: entityStates.join(',') },
  });

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setTimeout(() => (document.activeElement as HTMLElement).blur(), 0); // Unselect select field
    setEntityStates(
      (event.target.value as string)?.includes(',')
        ? ((event.target.value as string).split(',') as EntityState[])
        : [event.target.value as EntityState],
    );
  };

  useEffect(() => {
    if (!_.isEqual([getValues('state')], entityStates))
      setValue(
        'state',
        entityStates.length > 1 ? entityStates.join(',') : entityStates[0],
      );
  }, [entityStates, getValues, setValue]);

  return (
    <Select
      disableUnderline
      name="state"
      aria-label="state-filter"
      label="Status"
      control={control}
      onChange={handleChange}
      className={classes.root}
      formControlProps={{ variant: 'outlined', size: 'small' }}
      items={[
        {
          label: 'All',
          value: [
            EntityState.NORMAL,
            EntityState.ARCHIVED,
            EntityState.DELETED,
          ].join(','),
        },
        { label: 'Receiving Supports', value: EntityState.NORMAL },
        { label: 'Deleted', value: EntityState.DELETED },
        { label: 'Archived', value: EntityState.ARCHIVED },
      ]}
    />
  );
};

export default ClientListControlStateInput;

import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useAuth } from '@timed/auth';
import { ClientModuleCsCheckbox } from '@timed/client/components/ModuleCsCheckbox';
import { ClientModuleScCheckbox } from '@timed/client/components/ModuleScCheckbox';
import { ClientContext } from '@timed/client/context';
import { addServerErrors, Block } from '@timed/common';
import {
  UpdateClientsInput,
  useUpdateClientsModulesMutation,
} from '@timed/gql';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
  patch: Pick<UpdateClientsInput['patch'], 'moduleCS' | 'moduleSC'>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
  }),
);

const ClientUpdateModuleForm = () => {
  const classes = useStyles();

  const client = useContext(ClientContext);

  const { permissible } = useAuth();

  const [updateEntity, response] = useUpdateClientsModulesMutation();

  const {
    control,
    setError,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      patch: {
        moduleCS: client.moduleCS,
        moduleSC: client.moduleSC,
      },
    },
  });

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const handleOnChange = (patch: FormData['patch']) => {
    updateEntity({
      variables: { input: { patch, ids: [client.id] } },
    }).catch(() => {});
  };

  useEffect(
    () => setValue('patch.moduleCS', client.moduleCS),
    [setValue, client.moduleCS],
  );

  useEffect(
    () => setValue('patch.moduleSC', client.moduleSC),
    [setValue, client.moduleSC],
  );

  return (
    <Block
      title="Application Modules"
      description="At least one module must be enabled"
    >
      <Box className={classes.wrapper}>
        <ClientModuleCsCheckbox
          id="patch.moduleCS"
          name="patch.moduleCS"
          label="Core Support"
          control={control}
          error={!!errors.patch?.moduleCS}
          helperText={errors.patch?.moduleCS?.message}
          color="primary"
          disabled={
            !permissible({ admin: true }) ||
            (client.moduleCS && !client.moduleSC)
          }
          onChange={() => {
            handleOnChange({ moduleCS: !client.moduleCS });
          }}
        />
        <ClientModuleScCheckbox
          id="patch.moduleSC"
          name="patch.moduleSC"
          label="Support Coordination"
          control={control}
          error={!!errors.patch?.moduleSC}
          helperText={errors.patch?.moduleSC?.message}
          color="primary"
          disabled={
            !permissible({ admin: true }) ||
            (client.moduleSC && !client.moduleCS)
          }
          onChange={() => {
            handleOnChange({ moduleSC: !client.moduleSC });
          }}
        />
      </Box>
    </Block>
  );
};

export default ClientUpdateModuleForm;

import { Typography, TypographyProps } from '@mui/material';
import { useAuth } from '@timed/auth';
import { printTimePeriod } from '@timed/common';

const StartWelcome = (props: TypographyProps) => {
  const { member } = useAuth();

  return (
    <Typography {...props}>
      Good {printTimePeriod(new Date())}{' '}
      {member?.id === 'd3640ba5-f22a-4f4a-b0f4-94e447ba6c80'
        ? `Mr Vellenga`
        : member?.firstName}
      ,
    </Typography>
  );
};

export default StartWelcome;

import { PersonNameInput, TextFieldProps } from '@timed/common';
import { personNameRegex } from '@timed/common/regexps';
import { RegisterOptions } from 'react-hook-form';

export const validatePostNominalsInput: RegisterOptions = {
  pattern: {
    value: personNameRegex({ allowNumbers: true }),
    message: 'Invalid character(s)',
  },
  maxLength: { value: 20, message: 'Too long' },
};

const PostNominalsInput = (props: TextFieldProps) => (
  <PersonNameInput
    label="Post-nominals"
    validation={validatePostNominalsInput}
    {...props}
  />
);

export default PostNominalsInput;

import { useAuth } from '@timed/auth';
import { useRouter } from '@timed/common';
import { PageContext, PageMetadata } from '@timed/page';
import { useCallback } from 'react';

const PageProvider: React.FC = ({ children }) => {
  const { navigate, pathname } = useRouter();

  const { permissible } = useAuth();

  /**
   * Process a page's supplied metadata and create relevant menu items
   */
  const addPage = useCallback(
    (page: PageMetadata) => {
      if (
        (page.admin || page.permissions) &&
        !permissible({ admin: page.admin, permissions: page.permissions })
      )
        // Don't do anything
        return;

      if (page.redirect && page.path === pathname) {
        navigate(page.redirect, { replace: true });
      }
    },
    [pathname, navigate, permissible],
  );

  /**
   * Save last active page as a redirect
   */
  const saveRedirect = useCallback((path: string) => {
    const tab = path.split('/')[1];

    if (localStorage.getItem(`page.redirect.${tab}`) !== path)
      localStorage.setItem(`page.redirect.${tab}`, path);
  }, []);

  /**
   * Returns page's redirect path if set, otherwise returns page's path.
   */
  const getDirectPagePath = ({
    path,
    redirect,
  }: Pick<PageMetadata, 'path' | 'redirect'>): string => redirect || path;

  return (
    <PageContext.Provider value={{ addPage, saveRedirect, getDirectPagePath }}>
      {children}
    </PageContext.Provider>
  );
};

export default PageProvider;

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      gap: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
    },
  }),
);

type PaginationStatusProps = {
  loading: boolean;
  limit: number;
  offset: number;
  totalCount?: number;
  what?: string;
};

const PaginationStatus = ({
  loading,
  limit,
  offset,
  totalCount,
  what = 'records',
}: PaginationStatusProps) => {
  const classes = useStyles();

  return (
    <Typography className={classes.wrapper}>
      {loading
        ? `Fetching ${what}`
        : totalCount
        ? `Showing ${offset + 1}  ${
            limit > 1 && offset + 1 !== totalCount
              ? 'to ' + Math.min(limit + offset, totalCount)
              : ''
          } of ${totalCount.toLocaleString()}`
        : `No ${what} found`}
    </Typography>
  );
};

export default PaginationStatus;

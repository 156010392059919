import { useMediaQuery, useTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { _peopleMembers } from '@timed/app';
import {
  formatPersonName,
  IconButton,
  Link,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from '@timed/common';
import {
  ClientNoteKeyword,
  OrderBy,
  useGetClientNoteKeywordsQuery,
} from '@timed/gql';
import { NotesDeleteKeywordDialog } from '@timed/notes/components/DeleteKeywordDialog';
import { format } from 'date-fns';
import { useModal } from 'mui-modal-provider';

const NotesControlSettingsKeywordsTable = () => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { showModal } = useModal();

  const { data, loading } = useGetClientNoteKeywordsQuery({
    variables: {
      input: {
        orderBy: [{ text: OrderBy.ASC }],
      },
    },
  });

  const handleOpenDeleteDialog = (
    keyword: Pick<ClientNoteKeyword, 'id' | 'text'>,
  ) => {
    const modal: { hide: () => void } = showModal(NotesDeleteKeywordDialog, {
      onClose: () => modal.hide(),
      keyword,
    });
  };

  return (
    <Table loading={loading} maxHeight={400}>
      <TableHeader sticky>Word</TableHeader>
      <TableHeader>Created At</TableHeader>
      <TableHeader>Created By</TableHeader>
      <TableHeader></TableHeader>
      {data?.clientNoteKeywords?.map(
        ({ id, text, createdBy, createdAt }, i) => (
          <TableRow key={i}>
            <TableCell>{text}</TableCell>
            <TableCell>
              {format(new Date(createdAt), 'dd/MM/yyyy HH:mm')}
            </TableCell>
            <TableCell>
              <Link to={_peopleMembers.path + '/' + createdBy.id}>
                {formatPersonName(createdBy, {
                  capitaliseLastName: true,
                  lastNameFirst: true,
                })}
              </Link>
            </TableCell>
            <TableCell>
              <IconButton
                aria-label="menu"
                color="inherit"
                size={smDown ? 'small' : 'medium'}
                onClick={() => {
                  handleOpenDeleteDialog({ id, text });
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </TableCell>
          </TableRow>
        ),
      )}
    </Table>
  );
};

export default NotesControlSettingsKeywordsTable;

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@timed/common';
import { NotesContext, NotesExportModal } from '@timed/notes';
import { useModal } from 'mui-modal-provider';
import { useCallback, useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      minWidth: 0,
      height: theme.spacing(10),
      border: '1px solid ' + theme.palette.divider,
      borderRadius: 0,
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.down('sm')]: {
        height: theme.spacing(8),
      },
      '& .MuiButton-label': {
        fontWeight: 'normal',
      },
    },
  }),
);

const NotesExportButton = () => {
  const classes = useStyles();
  const { showModal } = useModal();

  const { selectedNotes } = useContext(NotesContext);

  const handleOpenExportNotesModal = useCallback(() => {
    const modal: { hide: () => void } = showModal(NotesExportModal, {
      ids: selectedNotes,
      onClose: () => {
        modal.hide();
      },
    });
  }, [showModal, selectedNotes]);

  return (
    <Button
      disabled={!selectedNotes.length}
      variant="contained"
      className={classes.button}
      onClick={() => {
        handleOpenExportNotesModal();
      }}
    >
      Export
    </Button>
  );
};

export default NotesExportButton;

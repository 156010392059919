import { Select, SelectMenuItem, SelectProps } from '@timed/common';
import { IncontinenceType } from '@timed/gql';
import { RegisterOptions } from 'react-hook-form';

export const validateIncontinenceType: RegisterOptions = {
  validate: {
    validIncontinenceType: (value) =>
      value in IncontinenceType || 'Invalid value',
  },
};

const items: SelectMenuItem[] = [
  { label: 'Not recorded', value: IncontinenceType.NotRecorded },
  { label: 'No incontinence', value: IncontinenceType.Nil },
  { label: 'Bowel', value: IncontinenceType.Bowel },
  { label: 'Urine', value: IncontinenceType.Urine },
  { label: 'Both', value: IncontinenceType.Both },
];

const ClientIncontinenceTypeInput = ({
  defaultValue,
  ...props
}: Omit<SelectProps, 'items'>) => (
  <Select
    label="Type"
    formControlProps={{ variant: 'outlined', size: 'small' }}
    defaultValue={defaultValue ?? IncontinenceType.NotRecorded}
    validation={validateIncontinenceType}
    items={items}
    renderValue={(value) => items.find((item) => item.value === value)?.label}
    {...props}
  />
);


export default ClientIncontinenceTypeInput;

import { ClientNdisPlanItemTransactionProvider } from '@timed/client';
import { ClientNdisPlanItemTransactionsTable } from '@timed/client/components/NdisPlanItemTransactions/Table';
import { Modal, ModalProps } from '@timed/common';
import { QueryByIdInput } from '@timed/gql';

type ClientNdisPlanItemTransactionsModalProps = Omit<ModalProps, 'children'> & {
  clientNdisPlan: QueryByIdInput;
  onClose: () => void;
};

const ClientNdisPlanItemTransactionsModal = ({
  onClose,
  clientNdisPlan,
  ...modalProps
}: ClientNdisPlanItemTransactionsModalProps) => {
  return (
    <Modal {...modalProps} disablePortal onClose={onClose}>
      <ClientNdisPlanItemTransactionProvider clientNdisPlan={clientNdisPlan}>
        <ClientNdisPlanItemTransactionsTable onClose={onClose} />
      </ClientNdisPlanItemTransactionProvider>
    </Modal>
  );
};

export default ClientNdisPlanItemTransactionsModal;

import { Menu, MenuItem, useMediaQuery, useTheme } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Protected } from '@timed/auth';
import {
  ClientCreateNdisPlanItemTransactionFormModal,
  ClientDeleteNdisPlanItemDialog,
  ClientNdisPlanItemHistoryModal,
} from '@timed/client';
import { ClientUpdateNdisPlanItemForm } from '@timed/client/components/UpdateNdisPlanItemForm';
import { IconButton } from '@timed/common';
import {
  ClientNdisPlanItem,
  ClientNdisPlanItemUpdatableFieldsInput,
  Permission,
  useUpdateClientNdisPlanItemMutation,
} from '@timed/gql';
import { useModal } from 'mui-modal-provider';
import { useState } from 'react';

type ClientNdisPlanItemActionMenuButtonProps = {
  clientNdisPlanItem: Pick<
    ClientNdisPlanItem,
    'id' | 'currentFunds' | 'name' | 'initialFunds' | 'category'
  >;
};

const ClientNdisPlanItemActionMenuButton = ({
  clientNdisPlanItem,
}: ClientNdisPlanItemActionMenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { showModal } = useModal();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [updateEntity] = useUpdateClientNdisPlanItemMutation();

  const handleUpdateEntity = (
    field: keyof Pick<
      ClientNdisPlanItemUpdatableFieldsInput,
      'coreSupportItem' | 'supportCoordinationItem'
    >,
  ) => {
    updateEntity({
      variables: {
        input: {
          id: clientNdisPlanItem.id,
          patch: {
            [field]: true,
          },
        },
      },
    });
  };

  const handleOpenDeleteDialog = () => {
    const modal: { hide: () => void } = showModal(
      ClientDeleteNdisPlanItemDialog,
      {
        onClose: () => modal.hide(),
        clientNdisPlanItem,
      },
    );
  };

  const handleOpenViewHistoryModal = () => {
    const modal: { hide: () => void } = showModal(
      ClientNdisPlanItemHistoryModal,
      {
        onClose: () => modal.hide(),
        clientNdisPlanItem,
      },
    );
  };

  const handleOpenCreateTransactionModal = (
    method: 'invoice' | 'adjustment',
  ) => {
    const modal: { hide: () => void } = showModal(
      ClientCreateNdisPlanItemTransactionFormModal,
      {
        onClose: () => modal.hide(),
        clientNdisPlanItem,
        method,
      },
    );
  };

  const handleOpenUpdateModal = () => {
    const modal: { hide: () => void } = showModal(
      ClientUpdateNdisPlanItemForm,
      {
        onClose: () => modal.hide(),
        clientNdisPlanItem,
      },
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <span>
      <IconButton
        aria-label="menu"
        color="inherit"
        size={smDown ? 'small' : 'medium'}
        onClick={handleClick}
      >
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <Protected tester>
          <MenuItem
            onClick={() => {
              handleOpenCreateTransactionModal('adjustment');
              handleClose();
            }}
          >
            Record Adjustment
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleOpenViewHistoryModal();
              handleClose();
            }}
          >
            View history
          </MenuItem>
        </Protected>
        <Protected permissions={Permission.CLIENT_WRITE}>
          <MenuItem
            onClick={() => {
              handleOpenUpdateModal();
              handleClose();
            }}
          >
            Update item
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleOpenDeleteDialog();
              handleClose();
            }}
          >
            Delete item
          </MenuItem>
        </Protected>
        <Protected tester>
          <MenuItem
            onClick={() => {
              handleUpdateEntity('coreSupportItem');
              handleClose();
            }}
          >
            <span style={{ fontWeight: theme.typography.fontWeightMedium }}>
              Set billable: Core Support
            </span>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleUpdateEntity('supportCoordinationItem');
              handleClose();
            }}
          >
            <span style={{ fontWeight: theme.typography.fontWeightMedium }}>
              Set billable: Support Coordination
            </span>
          </MenuItem>
        </Protected>
      </Menu>
    </span>
  );
};

export default ClientNdisPlanItemActionMenuButton;

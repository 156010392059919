import { _shiftsAdminApprove } from '@timed/app';
import { Page } from '@timed/page';
import { ShiftsApprove } from '@timed/shifts';

const RouteCoreSupportShiftsAdminApprove = () => (
  <Page {..._shiftsAdminApprove}>
    <ShiftsApprove />
  </Page>
);

export default RouteCoreSupportShiftsAdminApprove;

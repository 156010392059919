import {
  ClientNdisPlanItemTransactionContext,
  ClientNdisPlanItemTransactionContextInput,
  ClientNdisPlanItemTransactionContextType,
} from '@timed/client';
import {
  ClientNdisPlanItemTransactionWhereInput,
  OrderBy,
  QueryByIdInput,
  useClientNdisPlanItemTransactionContextGetTransactionsLazyQuery,
} from '@timed/gql';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

const ClientNdisPlanItemTransactionProvider: React.FC<{
  clientNdisPlan: QueryByIdInput;
}> = ({ children, clientNdisPlan }) => {
  const storagePrefix = 'client.budget.transaction.filters';

  const [getEntities, { called, loading, data, refetch, fetchMore }] =
    useClientNdisPlanItemTransactionContextGetTransactionsLazyQuery({
      notifyOnNetworkStatusChange: true,
    });

  const form = useForm();

  /**
   * Ordering options.
   */
  const [orderBy, setOrderBy] = useState<
    ClientNdisPlanItemTransactionContextInput['orderBy']
  >(
    sessionStorage.getItem(storagePrefix + '.orderBy')?.includes(',')
      ? [
          {
            [sessionStorage.getItem(storagePrefix + '.orderBy')!.split(',')[0]]:
              OrderBy[
                sessionStorage
                  .getItem(storagePrefix + '.orderBy')!
                  .split(',')[1] as OrderBy
              ],
          },
        ]
      : [{ billingDate: OrderBy.DESC_NULLS_LAST }],
  );

  /**
   * Category.
   */
  const [categoryId, setCategoryId] = useState<string | null>(null);

  /**
   * Supplier.
   */
  const [supplierId, setSupplierId] = useState<string | null>(null);

  /**
   * Query offset.
   */
  const [offset, setOffset] = useState<number>(0);

  /**
   * Pagination limit.
   */
  const limit = 100;

  /**
   * Reset all filters back to their defaults.
   */
  const reset = () => {
    setCategoryId(null);
    setSupplierId(null);
    setOffset(0);
  };

  const where = useMemo<ClientNdisPlanItemTransactionWhereInput>(
    () => ({
      clientNdisPlanItem: categoryId
        ? { id: { _eq: categoryId } }
        : { clientNdisPlan: { id: { _eq: clientNdisPlan.id } } },
      clientNdisPlanItemTransactionSupplier: supplierId
        ? { id: { _eq: supplierId } }
        : undefined,
    }),
    [categoryId, supplierId, clientNdisPlan.id],
  );

  /**
   * Save orderBy to storage.
   */
  useEffect(() => {
    if (
      Object.keys(Object.values(orderBy)[0])[0] !==
        sessionStorage.getItem(storagePrefix + '.orderBy') ||
      Object.values(Object.values(orderBy)[0])[0] !==
        sessionStorage.getItem(storagePrefix + '.orderBy')
    )
      sessionStorage.setItem(
        storagePrefix + '.orderBy',
        Object.entries(Object.values(orderBy)[0]).every(([k, v]) => !!k && !!v)
          ? Object.keys(Object.values(orderBy)[0])[0] +
              ',' +
              Object.values(Object.values(orderBy)[0])[0]
          : '',
      );
  }, [orderBy]);

  /**
   * Fetch subsequent data.
   */
  const fetch: ClientNdisPlanItemTransactionContextType['fetch'] = ({
    offset,
  } = {}) => {
    const input = {
      where,
      orderBy,
      limit,
      offset: offset ?? 0,
    };

    setOffset(offset ?? 0);

    if (offset !== undefined) {
      fetchMore({ variables: { input } }).then((x) =>
        console.log('DONE - fetchMore', x),
      );
    } else {
      refetch({ input }).then(() => console.log('DONE - refetch'));
    }
  };

  /**
   * Fetch initial data.
   */
  useEffect(() => {
    if (!called)
      getEntities({
        variables: {
          input: {
            where,
            orderBy,
            limit,
            offset,
          },
        },
      });
  });

  /**
   * Refetch data when needed.
   */
  useEffect(() => {
    if (called) {
      setOffset(0);
      refetch({
        input: {
          where,
          orderBy,
          limit,
          offset: 0,
        },
      });
    }
  }, [called, setOffset, where, limit, orderBy, refetch]);

  return (
    <ClientNdisPlanItemTransactionContext.Provider
      value={{
        fetch,
        reset,
        limit,
        offset,
        setOffset,
        totalCount:
          data?.clientNdisPlanItemTransactionAggregate.aggregate.totalCount,
        nodes: data?.clientNdisPlanItemTransactionAggregate.nodes,
        loading,
        input: {
          form,
          planId: clientNdisPlan.id,
          categoryId,
          setCategoryId,
          supplierId,
          setSupplierId,
          orderBy,
          setOrderBy,
        },
      }}
    >
      {children}
    </ClientNdisPlanItemTransactionContext.Provider>
  );
};

export default ClientNdisPlanItemTransactionProvider;

import { TextField, TextFieldProps } from '@timed/common';
import { ndisParticipantIdRegex } from '@timed/common/regexps';
import { RegisterOptions } from 'react-hook-form';

export const validateClientNdisId: RegisterOptions = {
  pattern: { value: ndisParticipantIdRegex, message: 'Invalid id' },
};

const ClientNdisIdInput = (props: TextFieldProps) => (
  <TextField
    label="NDIS ID Number"
    type="text"
    validation={validateClientNdisId}
    {...props}
  />
);

export default ClientNdisIdInput;

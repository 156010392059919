import { Event } from '@timed/gql';
import { BillingRate, billingRates } from '@timed/report';
import { isAfter } from 'date-fns';

/**
 * Determine the KM billing rate for a specific event.
 * @param event The event to check.
 * @returns BillingRate.
 */
export const getKmBillingRate = ({
  startAt,
}: Pick<Event, 'startAt'>): BillingRate | undefined => {
  return billingRates
    .filter((rate) => isAfter(new Date(startAt), rate.date))
    .find((rate) => rate.type === 'km');
};

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useAuth } from '@timed/auth';
import { formatPersonName, formatTimeDuration } from '@timed/common';
import { OrderBy, useGetShiftsMissingAnAttendeeLazyQuery } from '@timed/gql';
import { differenceInMinutes, format } from 'date-fns';
import { useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    title: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    shifts: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(8),
      },
    },
    shift: {
      // width: 'max-content',
      padding: theme.spacing(4),
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      cursor: 'pointer',
    },
  }),
);

const ShiftsMissingSupportWorker = () => {
  const classes = useStyles();

  const { branch } = useAuth();

  const [getEvents, { data, loading }] =
    useGetShiftsMissingAnAttendeeLazyQuery();

  // Fetch events
  useEffect(() => {
    getEvents({
      variables: {
        input: {
          where: {
            member: null,
            startAt: { _gt: new Date() },
            client: !!branch
              ? { branch: { id: { _eq: branch.id } } }
              : undefined,
          },
          orderBy: [{ startAt: OrderBy.ASC }],
        },
      },
    });
  }, [branch, getEvents]);

  return loading ? (
    <Typography>Loading...</Typography>
  ) : (
    <Box className={classes.wrapper}>
      <Box className={classes.shifts}>
        {!data?.events.length ? (
          <Typography variant="body1">
            There are currently no TBA future shifts at this time.
          </Typography>
        ) : (
          <>
            {data?.events.map((event) => {
              return (
                <Box className={classes.shift}>
                  <Box className={classes.title}>
                    <Typography variant="h3" className={classes.bold}>
                      {format(new Date(event.startAt), "EEEE, do 'of' MMMM")}
                    </Typography>
                  </Box>
                  <Typography variant="body2">
                    {formatTimeDuration({
                      start: new Date(event.startAt),
                      end: new Date(event.endAt),
                      options: { militaryTime: true },
                    })}{' '}
                    (
                    {differenceInMinutes(
                      new Date(event.endAt),
                      new Date(event.startAt),
                    ) / 60}{' '}
                    hours)
                  </Typography>
                  <Typography variant="body2">
                    {formatPersonName(event.client)} •{' '}
                    {event.locality + ' ' + event.region}
                  </Typography>
                </Box>
              );
            })}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ShiftsMissingSupportWorker;

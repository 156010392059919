import { Checkbox, CheckboxProps } from '@timed/common';

const ClientModuleScCheckbox = (props: CheckboxProps) => (
  <Checkbox
    defaultChecked={false}
    label="Module: Support Coordination"
    {...props}
  />
);

export default ClientModuleScCheckbox;

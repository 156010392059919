import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { _peopleSupportCoordinators } from '@timed/app';
import { Page } from '@timed/page';
import { SupportCoordinatorList } from '@timed/support-coordinator';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(8),
      overflow: 'hidden',
    },
  }),
);

const RouteCoreSupportPeopleSupportCoordinators = () => {
  const classes = useStyles();

  return (
    <Page {..._peopleSupportCoordinators}>
      <Box className={classes.container}>
        <SupportCoordinatorList />
      </Box>
    </Page>
  );
};

export default RouteCoreSupportPeopleSupportCoordinators;

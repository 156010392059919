import { Menu, MenuItem, useMediaQuery, useTheme } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Protected } from '@timed/auth';
import {
  ClientCreateNdisPlanItemTransactionBulkFormModal,
  ClientDeleteNdisPlanDialog,
  ClientNdisPlanHistoryModal,
  ClientNdisPlanItemTransactionsModal,
  ClientUpdateNdisPlanForm,
} from '@timed/client';
import { ClientCreateNdisPlanItemFormModal } from '@timed/client/components/CreateNdisPlanItemFormModal';
import { IconButton } from '@timed/common';
import { ClientNdisPlan, Permission } from '@timed/gql';
import _ from 'lodash';
import { useModal } from 'mui-modal-provider';
import { useState } from 'react';

type ClientNdisPlanItemActionMenuButtonProps = {
  clientNdisPlan: Pick<ClientNdisPlan, 'id' | 'startAt' | 'endAt'>;
};

const ClientNdisPlanActionMenuButton = ({
  clientNdisPlan,
}: ClientNdisPlanItemActionMenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { showModal } = useModal();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenDeleteDialog = () => {
    const modal: { hide: () => void } = showModal(ClientDeleteNdisPlanDialog, {
      onClose: () => modal.hide(),
      clientNdisPlan,
    });
  };

  const handleOpenHistoryModal = () => {
    const modal: { hide: () => void } = showModal(ClientNdisPlanHistoryModal, {
      onClose: () => modal.hide(),
      clientNdisPlan,
    });
  };

  const handleOpenUpdateModal = () => {
    const modal: { hide: () => void } = showModal(ClientUpdateNdisPlanForm, {
      onClose: () => modal.hide(),
      clientNdisPlan,
    });
  };

  const handleOpenCreateItemModal = () => {
    const modal: { hide: () => void } = showModal(
      ClientCreateNdisPlanItemFormModal,
      {
        onClose: () => modal.hide(),
        clientNdisPlan,
      },
    );
  };

  const handleOpenCreateBulkInvoicesModal = () => {
    const modal: { hide: () => void } = showModal(
      ClientCreateNdisPlanItemTransactionBulkFormModal,
      {
        onClose: () => modal.hide(),
        method: 'invoice',
        clientNdisPlanId: clientNdisPlan.id,
      },
    );
  };

  const handleOpenViewTransactionsModal = () => {
    const modal: { hide: () => void } = showModal(
      ClientNdisPlanItemTransactionsModal,
      {
        onClose: () => modal.hide(),
        clientNdisPlan: _.pick(clientNdisPlan, ['id']),
      },
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <span>
      <IconButton
        aria-label="menu"
        color="inherit"
        size={smDown ? 'small' : 'medium'}
        onClick={handleClick}
      >
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '24ch',
          },
        }}
      >
        <Protected tester>
          <MenuItem
            onClick={() => {
              handleOpenCreateItemModal();
              handleClose();
            }}
          >
            Add Category
          </MenuItem>
        </Protected>
        <Protected tester>
          <MenuItem
            onClick={() => {
              handleOpenViewTransactionsModal();
              handleClose();
            }}
          >
            View transactions
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleOpenCreateBulkInvoicesModal();
              handleClose();
            }}
          >
            Record invoices
          </MenuItem>
        </Protected>
        <Protected tester>
          <MenuItem
            onClick={() => {
              handleOpenUpdateModal();
              handleClose();
            }}
          >
            Update
          </MenuItem>
        </Protected>
        <Protected permissions={Permission.CLIENT_WRITE}>
          <MenuItem
            onClick={() => {
              handleOpenDeleteDialog();
              handleClose();
            }}
          >
            Delete
          </MenuItem>
        </Protected>
        <Protected tester>
          <MenuItem
            onClick={() => {
              handleOpenHistoryModal();
              handleClose();
            }}
          >
            View history
          </MenuItem>
        </Protected>
      </Menu>
    </span>
  );
};

export default ClientNdisPlanActionMenuButton;

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Protected, useAuth } from '@timed/auth';
import {
  AutocompleteOption,
  Avatar,
  Block,
  formatPersonName,
  IconButton,
  IconButtonMulti,
} from '@timed/common';
import {
  Client,
  Member,
  OrderBy,
  Permission,
  PersonNamesFragment,
  SetClientTeamMembersDocument,
  useGetClientTeamsQuery,
  useSetClientTeamMembersMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { MemberAutocomplete } from '@timed/member';
import { isEmpty, xor } from 'lodash';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';

type ClientUpdateTeamFormProps = React.PropsWithChildren<{
  client: Pick<Client, 'id'>;
}>;

enum ClientTeamMemberActionKind {
  ADD,
  REMOVE,
  SET,
}

interface MembersAction {
  type: ClientTeamMemberActionKind;
  members: Array<Pick<Member, 'id' | 'color'> & PersonNamesFragment>;
}

interface MembersState {
  members: Array<Pick<Member, 'id' | 'color'> & PersonNamesFragment>;
}

// Our reducer function that uses a switch statement to handle our actions
const membersReducer = (
  state: MembersState,
  action: MembersAction,
): MembersState => {
  const { type, members } = action;

  switch (type) {
    case ClientTeamMemberActionKind.ADD:
      return { members: [...new Set([...state.members, ...members])] };

    case ClientTeamMemberActionKind.REMOVE:
      return {
        members: state.members.filter(
          (m) => !members.map((mem) => mem.id).includes(m.id),
        ),
      };

    case ClientTeamMemberActionKind.SET:
      return { members };

    default:
      return state;
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'grid',
      alignItems: 'start',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: 'auto',
        gap: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'auto auto',
        gap: theme.spacing(4),
      },
    },
    item: {
      display: 'flex',
      flex: '1 1 auto',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
      },
    },
    avatar: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      fontSize: '75%',
      lineHeight: theme.spacing(8) + 'px',
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: 1,
    },
    paper: {
      backgroundColor: theme.palette.background.paper2,
    },
    people: {
      display: 'grid',
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
      },
    },
    message: {
      backgroundColor: theme.palette.secondary.light,
    },
  }),
);

const ClientUpdateTeamForm = ({ client }: ClientUpdateTeamFormProps) => {
  const classes = useStyles();

  const { branch } = useAuth();

  const [editing, setEditing] = useState<boolean>(false);

  const [setTeamMembers, response] = useSetClientTeamMembersMutation();

  const { handleSubmit } = useForm();

  // Currently selected preferred member
  const [preferredMember, setPreferredMember] = useState<AutocompleteOption<
    Pick<Member, 'id' | 'color'> & PersonNamesFragment
  > | null>(null);

  // All preferred members
  const [preferredMembers, setPreferredMembers] = useReducer(membersReducer, {
    members: [],
  });

  // Currently selected blocked member
  const [blockedMember, setBlockedMember] = useState<AutocompleteOption<
    Pick<Member, 'id' | 'color'> & PersonNamesFragment
  > | null>(null);

  // All blocked members
  const [blockedMembers, setBlockedMembers] = useReducer(membersReducer, {
    members: [],
  });

  // Fetch pre-existing team members
  const { data, loading } = useGetClientTeamsQuery({
    variables: {
      input: {
        where: { client: { id: { _eq: client.id } }, deletedAt: null },
        orderBy: [{ member: { lastName: OrderBy.ASC_NULLS_FIRST } }],
      },
    },
  });

  // Pre-existing preferred team members
  const initialPreferred = useMemo<string[]>(() => {
    if (!data) return [];

    const members = response.data?.setClientTeamMembers
      ? response.data?.setClientTeamMembers
          .filter((c) => c.preferred)
          .map((c) => c.member)
      : data.clientTeams.filter((c) => c.preferred).map((c) => c.member);

    setPreferredMembers({ members, type: ClientTeamMemberActionKind.SET });

    return members.map((m) => m.id);
  }, [data, response.data]);

  // Pre-existing blocked team members
  const initialBlocked: string[] = useMemo<string[]>(() => {
    if (!data) return [];

    const members = response.data?.setClientTeamMembers
      ? response.data?.setClientTeamMembers
          .filter((c) => !c.preferred)
          .map((c) => c.member)
      : data.clientTeams.filter((c) => !c.preferred).map((c) => c.member);

    setBlockedMembers({ members, type: ClientTeamMemberActionKind.SET });

    return members.map((m) => m.id);
  }, [data, response.data]);

  useEffect(() => {
    if (preferredMember) {
      setPreferredMembers({
        members: [preferredMember.value],
        type: ClientTeamMemberActionKind.ADD,
      });

      // Remove from blocked list if member exists there
      setBlockedMembers({
        members: [preferredMember.value],
        type: ClientTeamMemberActionKind.REMOVE,
      });
    }

    if (blockedMember) {
      setBlockedMembers({
        members: [blockedMember.value],
        type: ClientTeamMemberActionKind.ADD,
      });

      // Remove from blocked list if member exists there
      setPreferredMembers({
        members: [blockedMember.value],
        type: ClientTeamMemberActionKind.REMOVE,
      });
    }
  }, [preferredMember, blockedMember]);

  useLoadingEffect(loading);

  const onSubmit = () => {
    setTeamMembers({
      variables: {
        input: {
          client: { id: client.id },
          objects: [
            ...preferredMembers.members.map((m) => ({
              member: { id: m.id },
              preferred: true,
            })),
            ...blockedMembers.members.map((m) => ({
              member: { id: m.id },
              preferred: false,
            })),
          ],
        },
      },
    }).catch((e) => {});

    setPreferredMember(null);
    setBlockedMember(null);
  };

  const onSuccess = () => {
    const cache = response.client.cache;

    cache.modify({
      fields: {
        clientTeams(existing = []) {
          const teams = cache.writeQuery({
            data: response.data,
            query: SetClientTeamMembersDocument,
          });

          return [...existing, teams];
        },
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block
        title="Team"
        topRight={
          <Protected permissions={Permission.CLIENT_WRITE}>
            <IconButtonMulti
              enabled={editing}
              changed={
                !isEmpty(
                  xor(
                    initialPreferred,
                    preferredMembers.members.map((p) => p.id),
                  ),
                ) ||
                !isEmpty(
                  xor(
                    initialBlocked,
                    blockedMembers.members.map((b) => b.id),
                  ),
                )
              }
              setEditing={setEditing}
              loading={response.loading}
              success={!!response.data}
              onSuccess={onSuccess}
            />
          </Protected>
        }
      >
        <Box className={classes.wrapper}>
          <Block
            title="Prefer List"
            description="Employees on this list will be recommended for shifts with this participant above other employees."
            titleProps={{ variant: 'h3' }}
            paperProps={{ className: classes.paper }}
          >
            <Box className={classes.people}>
              {editing && (
                <MemberAutocomplete
                  autocompleteProps={{ disabled: !editing }}
                  member={preferredMember}
                  setMember={setPreferredMember}
                  orderBy={[{ lastName: OrderBy.ASC_NULLS_FIRST }]}
                  where={{
                    branchMembers: branch
                      ? { branch: { id: { _eq: branch.id } } }
                      : undefined,
                  }}
                />
              )}
              {!preferredMembers.members.length ? (
                <Typography>None.</Typography>
              ) : (
                preferredMembers.members.map((m) => (
                  <Box className={classes.item}>
                    <Avatar
                      className={classes.avatar}
                      color={m.color}
                      content={[m.firstName, m.middleName, m.lastName]}
                    />
                    <Typography>
                      {formatPersonName(m, {
                        capitaliseLastName: true,
                        lastNameFirst: true,
                      })}
                      {m.preferredName && m.preferredName !== m?.firstName
                        ? ' (' + m.preferredName + ')'
                        : ''}
                    </Typography>
                    {editing && (
                      <IconButton
                        onClick={() =>
                          setPreferredMembers({
                            members: [m],
                            type: ClientTeamMemberActionKind.REMOVE,
                          })
                        }
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </Box>
                ))
              )}
            </Box>
          </Block>
          <Block
            title="Block List"
            description="Employees on this list are unassignable to future shifts with this participant."
            titleProps={{ variant: 'h3' }}
            paperProps={{ className: classes.paper }}
          >
            <Box className={classes.people}>
              {editing && (
                <MemberAutocomplete
                  autocompleteProps={{ disabled: !editing }}
                  member={blockedMember}
                  setMember={setBlockedMember}
                  orderBy={[{ lastName: OrderBy.ASC_NULLS_FIRST }]}
                  where={{
                    branchMembers: branch
                      ? { branch: { id: { _eq: branch.id } } }
                      : undefined,
                  }}
                />
              )}

              {!blockedMembers.members.length ? (
                <Typography>None.</Typography>
              ) : (
                blockedMembers.members.map((m) => (
                  <Box className={classes.item}>
                    <Avatar
                      className={classes.avatar}
                      color={m.color}
                      content={[m.firstName, m.middleName, m.lastName]}
                    />
                    <Typography>
                      {formatPersonName(m, {
                        capitaliseLastName: true,
                        lastNameFirst: true,
                      })}{' '}
                      {m.preferredName && m.preferredName !== m?.firstName
                        ? ' (' + m.preferredName + ')'
                        : ''}
                    </Typography>
                    {editing && (
                      <IconButton
                        onClick={() =>
                          setBlockedMembers({
                            members: [m],
                            type: ClientTeamMemberActionKind.REMOVE,
                          })
                        }
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </Box>
                ))
              )}
            </Box>
          </Block>
        </Box>
      </Block>
    </form>
  );
};

export default ClientUpdateTeamForm;

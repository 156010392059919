/**
 * Get saved id of specified profile type
 * @param type The type of the profile
 * @returns The id of the profile
 *
 */
export const getProfile = (
  type: 'client' | 'member',
  searchParams: URLSearchParams,
): string | undefined =>
  searchParams.get(type.charAt(0)) ||
  sessionStorage.getItem(`schedule.${type}`) ||
  undefined;

import {
  Branch,
  Client,
  ClientContact,
  ClientNdisPlan,
  ClientNdisPlanItem,
  HistoryRestorable,
  Member,
  PersonNamesFragment,
  PlanManager,
  RedactedClient,
  SupportCoordinator,
} from '@timed/gql';
import { createContext } from 'react';

export type ClientContextType = Pick<
  RedactedClient,
  | 'id'
  | 'firstName'
  | 'middleName'
  | 'lastName'
  | 'preferredName'
  | 'age'
  | 'gender'
  | 'salutation'
  | 'pronouns'
  | 'color'
  | 'allowMedication'
  | 'allowObservations'
  | 'allowRecordSeizures'
  | 'allowAutomaticEventPopulation'
  | 'bloodSystolicUpperThreshold1'
  | 'bloodSystolicLowerThreshold1'
  | 'bloodDiastolicUpperThreshold1'
  | 'bloodDiastolicLowerThreshold1'
  | 'heartUpperThreshold1'
  | 'heartLowerThreshold1'
  | 'spo2UpperThreshold1'
  | 'spo2LowerThreshold1'
  | 'respirationUpperThreshold1'
  | 'respirationLowerThreshold1'
  | 'bloodSystolicUpperThreshold2'
  | 'bloodSystolicLowerThreshold2'
  | 'bloodDiastolicUpperThreshold2'
  | 'bloodDiastolicLowerThreshold2'
  | 'heartUpperThreshold2'
  | 'heartLowerThreshold2'
  | 'spo2UpperThreshold2'
  | 'spo2LowerThreshold2'
  | 'respirationUpperThreshold2'
  | 'respirationLowerThreshold2'
  | 'unit'
  | 'street'
  | 'locality'
  | 'region'
  | 'postcode'
  | 'country'
  | 'latitude'
  | 'longitude'
  | 'requireHandover'
> &
  Partial<
    Pick<
      Client,
      | 'createdAt'
      | 'moduleCS'
      | 'moduleSC'
      | 'dob'
      | 'email'
      | 'phone'
      | 'ndisId'
      | 'ndisPlanStartDate'
      | 'ndisPlanEndDate'
      | 'ndisPlanInitialFundsCore'
      | 'ndisPlanInitialFundsCs'
      | 'ndisPlanInitialFundsCb'
      | 'defaultEventDuration'
      | 'active'
      | 'notes'
      | 'confidentialNotes'
    >
  > & {
    preferredContact?: Pick<ClientContact, 'id'> | null;
  } & {
    archive?:
      | (Pick<HistoryRestorable, 'id' | 'date' | 'reason'> & {
          actor?: Pick<
            Member,
            'id' | 'firstName' | 'middleName' | 'lastName' | 'preferredName'
          > | null;
        })
      | null;
  } & {
    delete?:
      | (Pick<HistoryRestorable, 'id' | 'date' | 'reason'> & {
          actor?: Pick<
            Member,
            'id' | 'firstName' | 'middleName' | 'lastName' | 'preferredName'
          > | null;
        })
      | null;
  } & { createdBy?: (Pick<Member, 'id'> & PersonNamesFragment) | null } & {
    branch?: Pick<Branch, 'id' | 'name'> | null;
  } & {
    planManager?: Pick<PlanManager, 'id' | 'name'> | null;
  } & {
    supportCoordinator?: Pick<
      SupportCoordinator,
      'id' | 'firstName' | 'lastName'
    > | null;
  } & {
    currentNdisPlan?:
      | (Pick<ClientNdisPlan, 'id'> & {
          coreSupportItem?: Pick<
            ClientNdisPlanItem,
            'id' | 'currentFunds'
          > | null;
          supportCoordinationItem?: Pick<
            ClientNdisPlanItem,
            'id' | 'currentFunds'
          > | null;
        })
      | null;
  };

const ClientContext = createContext<ClientContextType>({
  id: '',
  firstName: '',
  active: false,
  allowMedication: false,
  allowObservations: false,
  allowRecordSeizures: false,
  allowAutomaticEventPopulation: false,
  requireHandover: false,
});

export default ClientContext;

import { Grid, Typography } from '@material-ui/core';
import { Protected } from '@timed/auth';
import {
  Block,
  IconButtonMulti,
  NumberInput,
  addServerErrors,
  roundNumber,
} from '@timed/common';
import {
  HistoryRestorable,
  Member,
  useUpdateMembersAttributesMutation,
} from '@timed/gql';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type MemberUpdateAttributesFormProps = {
  member: Pick<
    Member,
    'id' | 'empathyRating' | 'reliabilityRating' | 'lateness'
  > & {
    archive?: Pick<HistoryRestorable, 'id'> | null;
  };
};

type FormData = {
  patch: Pick<Member, 'empathyRating' | 'reliabilityRating'>;
};

const MemberUpdateAttributesForm = ({
  member,
}: MemberUpdateAttributesFormProps) => {
  const [editing, setEditing] = useState<boolean>(false);

  const [updateMembers, response] = useUpdateMembersAttributesMutation();

  const defaultValues: FormData = {
    patch: {
      empathyRating: !!member.empathyRating
        ? roundNumber(member.empathyRating / 100, 2)
        : null,
      reliabilityRating: !!member.reliabilityRating
        ? roundNumber(member.reliabilityRating / 100, 2)
        : null,
    },
  };

  const {
    handleSubmit,
    watch,
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });

  const currentValues = watch();

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = (values: FormData) => {
    const {
      patch: { empathyRating, reliabilityRating, ...patch },
    } = values;

    updateMembers({
      variables: {
        input: {
          ids: [member.id],
          patch: {
            ...patch,
            empathyRating:
              empathyRating?.toString() !== ''
                ? roundNumber(Number(empathyRating) * 100, 2)
                : null,
            reliabilityRating:
              reliabilityRating?.toString() !== ''
                ? roundNumber(Number(reliabilityRating) * 100, 2)
                : null,
          },
        },
      },
      onCompleted: (data) =>
        reset({
          patch: {
            empathyRating:
              data.updateMembers[0].empathyRating &&
              data.updateMembers[0].empathyRating / 100,
            reliabilityRating:
              data.updateMembers[0].reliabilityRating &&
              data.updateMembers[0].reliabilityRating / 100,
          },
        }),
    }).catch((e) => {});
    reset(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block
        title="Attributes"
        topRight={
          !member.archive ? (
            <Protected tester>
              <IconButtonMulti
                enabled={editing}
                changed={!isEqual(currentValues, defaultValues)}
                setEditing={setEditing}
                loading={response.loading}
                success={!!response.data}
              />
            </Protected>
          ) : undefined
        }
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Empathy</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.empathyRating !== null &&
                member.empathyRating !== undefined ? (
                  member.empathyRating / 100 + '/10'
                ) : (
                  'Unset'
                )
              ) : (
                <NumberInput
                  fullWidth
                  name="patch.empathyRating"
                  control={control}
                  error={!!errors.patch?.empathyRating}
                  helperText={errors.patch?.empathyRating?.message}
                  disabled={!editing}
                  min={0}
                  max={100}
                  step="0.01"
                  validation={{ min: 0, max: 100 }}
                  size="small"
                  variant="outlined"
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Reliability</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.reliabilityRating !== null &&
                member.reliabilityRating !== undefined ? (
                  member.reliabilityRating / 100 + '/10'
                ) : (
                  'Unset'
                )
              ) : (
                <NumberInput
                  fullWidth
                  name="patch.reliabilityRating"
                  control={control}
                  error={!!errors.patch?.reliabilityRating}
                  helperText={errors.patch?.reliabilityRating?.message}
                  disabled={!editing}
                  min={0}
                  max={100}
                  step="0.01"
                  validation={{ min: 0, max: 100 }}
                  size="small"
                  variant="outlined"
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Lateness</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography
              color={(member.lateness ?? 0) > 50 ? 'error' : 'initial'}
            >
              {member.lateness?.toFixed(0) ?? 0}%
            </Typography>
          </Grid>
        </Grid>
      </Block>
    </form>
  );
};

export default MemberUpdateAttributesForm;

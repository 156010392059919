import {
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Box } from '@mui/material';
import {
  Block,
  Button,
  formatPersonName,
  Modal,
  ModalProps,
} from '@timed/common';
import { pluralise } from '@timed/common/utils/pluralise';
import {
  Client,
  ClientNoteType,
  PersonNamesFragment,
  useExportClientNotesQuery,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { format, isAfter, isBefore, startOfToday } from 'date-fns';
import { useMemo, useRef } from 'react';
import { CSVLink } from 'react-csv';
import ReactToPrint from 'react-to-print';

export type NotesExportModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  ids: string[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    medium: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    block: {
      backgroundColor: theme.palette.background.paper2,
    },
    printWrapper: {
      display: 'none',
      padding: theme.spacing(4),
      backgroundColor: 'white',
    },
    print: {
      colorAdjust: 'exact',
      WebkitPrintColorAdjust: 'exact',
      display: 'flex',
      alignItems: 'start',
      flexFlow: 'column',
      gap: theme.spacing(4),
    },
    items: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(8),
    },
    item: {
      breakInside: 'avoid-page',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      border: '1px solid ' + theme.palette.divider,
      backgroundColor: grey[50],
      padding: theme.spacing(2),
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    group: {
      display: 'flex',
      flexDirection: 'column',
    },
    table: {
      width: 'max-content',
      '& td': {
        paddingRight: theme.spacing(2),
      },
      '& tr > td:last-child': {
        color: theme.palette.text.secondary,
      },
    },
    note: {
      breakBefore: 'avoid',
      padding: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      whiteSpace: 'pre-wrap',
      '& div:last-child': {
        alignSelf: 'end',
        fontStyle: 'italic',
        color: theme.palette.text.secondary,
      },
    },
    exportButtons: {
      display: 'flex',
      gap: theme.spacing(2),
    },
  }),
);

const NotesExportModal = ({
  onClose,
  ids,
  ...props
}: NotesExportModalProps) => {
  const classes = useStyles();

  const printRef = useRef<HTMLDivElement | null>(null);

  const { data, loading } = useExportClientNotesQuery({
    variables: { input: { where: { id: { _in: ids } } } },
  });

  useLoadingEffect(loading);

  const headers = [
    { label: 'Commented At', key: 'commentedAt' },
    { label: 'Participant', key: 'participant' },
    { label: 'Author', key: 'author' },
    { label: 'Notes', key: 'notes' },
    { label: 'Type', key: 'type' },
  ];

  const from: Date | null = useMemo(
    () =>
      !!data?.clientNotes.length
        ? new Date(
            [...data.clientNotes]
              .sort((a, b) =>
                isAfter(new Date(a.commentedAt), new Date(b.commentedAt))
                  ? 1
                  : 0,
              )
              .at(0)?.commentedAt,
          )
        : null,
    [data?.clientNotes],
  );

  const to: Date | null = useMemo(
    () =>
      !!data?.clientNotes.length
        ? new Date(
            [...data.clientNotes]
              .sort((a, b) =>
                isBefore(new Date(a.commentedAt), new Date(b.commentedAt))
                  ? 1
                  : 0,
              )
              .at(0)?.commentedAt,
          )
        : null,
    [data?.clientNotes],
  );

  const client: (Pick<Client, 'id'> & PersonNamesFragment) | null | undefined =
    useMemo(
      () =>
        [...new Set(data?.clientNotes.map(({ client: { id } }) => id))]
          .length === 1
          ? data?.clientNotes[0].client
          : null,
      [data?.clientNotes],
    );

  return (
    <Modal title="Exporting Notes" onClose={onClose} {...props}>
      <Block
        title="Export Notes"
        bottomLeft={<Button onClick={onClose}>Cancel</Button>}
        bottomRight={
          !data?.clientNotes.length ? (
            <Button disabled></Button>
          ) : (
            <Box className={classes.exportButtons}>
              <ReactToPrint
                documentTitle={`${format(
                  new Date(),
                  'yyyyMMdd',
                )}_Participant_Case_Notes`}
                trigger={() => (
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{ width: 'max-content' }}
                  >
                    Print PDF
                  </Button>
                )}
                content={() => printRef.current}
              />
              <CSVLink
                target="_blank"
                style={{ justifySelf: 'center' }}
                filename={format(startOfToday(), 'yyyyMMdd') + '_notes.csv'}
                headers={headers}
                data={data.clientNotes.map((note) => ({
                  commentedAt: format(
                    new Date(note.commentedAt),
                    'dd/MM/yyyy HH:mm',
                  ),
                  author: !!note.commentedBy
                    ? formatPersonName(note.commentedBy, {
                        capitaliseLastName: true,
                        lastNameFirst: true,
                      })
                    : note.commentedByText ?? 'Unknown',
                  participant: formatPersonName(note.client, {
                    capitaliseLastName: true,
                    lastNameFirst: true,
                  }),
                  notes: note.comment?.trim() || '',
                  type: note.type === ClientNoteType.SHIFT ? 'Shift' : 'Other',
                }))}
              >
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Export CSV
                </Button>
              </CSVLink>
            </Box>
          )
        }
      >
        <Typography>
          Exporting <strong>{ids.length}</strong>{' '}
          {pluralise({
            quantity: ids.length,
            singular: 'note',
            plural: 'notes',
          })}
          .
        </Typography>
        {data?.clientNotes.length && (
          <div className={classes.printWrapper}>
            <style type="text/css" media="print">
              {' @page { margin: 64px !important; } '}
            </style>
            <div ref={printRef} className={classes.print}>
              <div className={classes.header} style={{ width: '100%' }}>
                <div className={classes.group}>
                  <Typography variant="h1">Case Notes</Typography>
                </div>
                {((!!from && !!to) || client) && (
                  <div className={classes.group} style={{ alignItems: 'end' }}>
                    {!!from && !!to && (
                      <Typography variant="body2" color="textSecondary">
                        {format(from, 'dd/MM/yyyy')} to{' '}
                        {format(to, 'dd/MM/yyyy')}
                      </Typography>
                    )}
                    {!!client && (
                      <Typography variant="body2" color="textSecondary">
                        {formatPersonName(client, {
                          lastNameFirst: true,
                          capitaliseLastName: true,
                        })}
                      </Typography>
                    )}
                  </div>
                )}
              </div>
              <Box className={classes.items}>
                {data?.clientNotes.map((note) => (
                  <div className={classes.item}>
                    <div className={classes.header}>
                      <div className={classes.group}>
                        <Typography className={classes.bold}>
                          {format(
                            new Date(note.commentedAt!),
                            'eeee, do MMM yyyy',
                          )}
                        </Typography>
                      </div>
                      <div
                        className={classes.group}
                        style={{ alignItems: 'end' }}
                      >
                        <Typography>
                          {format(new Date(note.commentedAt!), 'HH:mm')}
                        </Typography>
                      </div>
                    </div>
                    <Divider />
                    {!client && (
                      <>
                        <div className={classes.group}>
                          <table className={classes.table}>
                            <tbody>
                              <tr>
                                <td>
                                  <Typography variant="body2">
                                    Participant
                                  </Typography>
                                </td>
                                <td>
                                  <Typography variant="body2">
                                    {formatPersonName(note.client, {
                                      capitaliseLastName: true,
                                      lastNameFirst: true,
                                    })}
                                  </Typography>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <Divider />
                      </>
                    )}
                    <Typography className={classes.note}>
                      {!!note.comment ? (
                        <>
                          <div>{note.comment.trim()}</div>
                          <div>
                            {!!note.commentedBy
                              ? '― ' +
                                formatPersonName(note.commentedBy, {
                                  capitaliseLastName: true,
                                  lastNameFirst: true,
                                })
                              : 'Unknown'}
                          </div>
                        </>
                      ) : (
                        'No recorded case notes'
                      )}
                    </Typography>
                  </div>
                ))}
              </Box>
            </div>
          </div>
        )}
      </Block>
    </Modal>
  );
};

export default NotesExportModal;

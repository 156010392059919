import { PaginationContextType } from '@timed/common';
import {
  ClientListContextGetAggregatedClientsQuery,
  ClientListContextGetAggregatedRedactedClientsQuery,
  ClientsOrderByInput,
  EntityState,
  OrderBy,
} from '@timed/gql';
import { createContext, Dispatch, SetStateAction } from 'react';

export type ClientListContextInput = {
  /**
   * Filter by search.
   */
  search: string | null;
  /**
   * Set search.
   */
  setSearch: Dispatch<SetStateAction<string | null>>;
  /**
   * Filter by entity states.
   */
  entityStates: EntityState[];
  /**
   * Set entity states.
   */
  setEntityStates: Dispatch<SetStateAction<EntityState[]>>;
  /**
   * Ordering options.
   */
  orderBy: ClientsOrderByInput[];
  /**
   * Set ordering options.
   */
  setOrderBy: Dispatch<SetStateAction<ClientsOrderByInput[]>>;
};

export type ClientListContextType = PaginationContextType<
  | ClientListContextGetAggregatedClientsQuery['clientsAggregate']['nodes']
  | ClientListContextGetAggregatedRedactedClientsQuery['redactedClientsAggregate']['nodes']
> & {
  /**
   * Reset filters to their defaults
   */
  reset: () => void;
  /**
   * Network loading state.
   */
  loading: boolean;
  /**
   * Results filter.
   */
  input: ClientListContextInput;
};

const ClientListContext = createContext<ClientListContextType>({
  fetch: () => {},
  reset: () => {},
  offset: 0,
  setOffset: () => 0,
  limit: 100,
  loading: false,
  input: {
    search: null,
    setSearch: () => {},
    entityStates: [EntityState.NORMAL],
    setEntityStates: () => {},
    orderBy: [{ lastName: OrderBy.ASC_NULLS_FIRST }],
    setOrderBy: () => {},
  },
});

export default ClientListContext;

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {
  formatPersonName,
  IconButton,
  Select,
  SelectProps,
  validateUuid,
} from '@timed/common';
import { OrderBy, useGetSupportCoordinatorsQuery } from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import React from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loading: {
      '& .MuiOutlinedInput-root.Mui-disabled': {
        backgroundColor: theme.palette.background.default,
      },
    },
    input: {
      backgroundColor: theme.palette.background.default,
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      height: 20,
    },
    name: {
      // fontSize: "90%",
      paddingRight: theme.spacing(2),
      flex: '1 1 auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    control: {
      flex: '0 0 auto',
    },
  }),
);

const ClientSupportCoordinatorInput = ({
  defaultValue,
  getValues,
  setValue,
  ...props
}: Omit<SelectProps, 'items'> & {
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
}) => {
  const classes = useStyles();

  const { data, loading } = useGetSupportCoordinatorsQuery({
    variables: {
      input: {
        orderBy: [
          { lastName: OrderBy.ASC },
          { firstName: OrderBy.ASC_NULLS_FIRST },
        ],
      },
    },
  });

  const handleClear = () => setValue(props.name, null);

  useLoadingEffect(loading);

  return data?.supportCoordinators && data?.supportCoordinators.length === 0 ? (
    <>No support coordinators currently exist</>
  ) : (
    <Select
      label="Support Coordinator"
      validation={validateUuid}
      items={
        data?.supportCoordinators &&
        Object.values(data.supportCoordinators).map((coordinator) => {
          return {
            value: coordinator.id,
            label: formatPersonName(coordinator, {
              capitaliseLastName: true,
              lastNameFirst: true,
            }),
          };
        })
      }
      renderValue={(value) =>
        loading ? (
          <Typography variant="body2">Loading...</Typography>
        ) : !value ? (
          <></>
        ) : (
          <div className={classes.content}>
            <Typography variant="body1" className={classes.name}>
              {formatPersonName(
                data?.supportCoordinators?.find(
                  (coordinator) => coordinator.id === value,
                )!,
                {
                  capitaliseLastName: true,
                  lastNameFirst: true,
                },
              )}
            </Typography>
            {getValues(props.name) && (
              <IconButton
                size="small"
                className={classes.control}
                onClick={handleClear}
                onMouseDown={(event) => event.stopPropagation()}
              >
                <CloseRoundedIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        )
      }
      {...props}
    />
  );
};

export default ClientSupportCoordinatorInput;

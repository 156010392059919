import { Select, SelectProps } from '@timed/common';
import { MemberTaxFileNumberDeclarationBasisOfPay } from '@timed/gql';
import { camelCase, startCase } from 'lodash';
import { RegisterOptions } from 'react-hook-form';

export const validateMemberTaxFileNumberDeclarationBasisOfPay: RegisterOptions = {
  validate: {
    validateMemberTaxFileNumberDeclarationBasisOfPay: (value) =>
      !value ||
      value in MemberTaxFileNumberDeclarationBasisOfPay ||
      'Invalid value',
  },
};

const MemberTaxFileNumberDeclarationBasisOfPayInput = ({
  defaultValue,
  ...props
}: Omit<SelectProps, 'items'>) => (
  <Select
    defaultValue={defaultValue}
    validation={validateMemberTaxFileNumberDeclarationBasisOfPay}
    items={Object.values(MemberTaxFileNumberDeclarationBasisOfPay).map(
      (value) => ({ value, label: startCase(camelCase(value)) }),
    )}
    renderValue={(value) => startCase(camelCase(value as string))}
    {...props}
  />
);

export default MemberTaxFileNumberDeclarationBasisOfPayInput;

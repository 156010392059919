import {
  Badge,
  Box,
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  withStyles,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import CheckIcon from '@mui/icons-material/Check';
import { useAuth } from '@timed/auth';
import {
  Avatar,
  formatPermissions,
  formatPersonName,
  roundNumber,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  useRouter,
} from '@timed/common';
import {
  Member,
  MemberListContextGetAggregatedRedactedMembersQuery,
  OrderBy,
  RedactedMember,
} from '@timed/gql';
import {
  isMember,
  MemberListContext,
  MemberListControls,
  MemberListPagination,
} from '@timed/member';
import { differenceInMinutes, format } from 'date-fns';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    flipped: {
      transform: 'rotate(180deg)',
      WebkitTransform: 'rotate(180deg)',
      msTransform: 'rotate(180deg)',
    },
    wrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
      '& .MuiSvgIcon-root': {
        fontSize: 14,
      },
    },
    table: {
      display: 'inline-block',
      '& .MuiTypography-root': {
        fontSize: 12,
      },
      '& .MuiSvgIcon-root': {
        fontSize: 14,
      },
    },
    row: {
      height: 56,
      cursor: 'pointer',
      display: 'flex',
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    cell: {
      padding: theme.spacing(0, 4, 0, 4),
      display: 'flex',
      alignItems: 'center',
      '& .MuiTypography-root': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    header: {
      cursor: 'pointer',
      justifyContent: 'start',
      gap: theme.spacing(2),
      '&:hover': {
        backgroundColor: theme.palette.background.paper2,
      },
      '& .MuiTypography-root': {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium,
        overflow: 'hidden',
        whiteSpace: 'normal',
      },
    },
    headerLabelWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
    cellIndex: {
      width: 36,
      justifyContent: 'center',
    },
    cellName: {
      width: 260,
      '& .MuiTypography-root': {
        fontSize: 14,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    cellAccess: {
      width: 70,
      justifyContent: 'center',
    },
    cellSchedulable: {
      width: 100,
      justifyContent: 'center',
    },
    cellTrackFiles: {
      width: 90,
      justifyContent: 'center',
    },
    cellBonusEligible: {
      width: 90,
      justifyContent: 'center',
    },
    cellId: {
      width: 64,
      justifyContent: 'center',
    },
    cellPermission: {
      width: 90,
    },
    cellHours: {
      width: 80,
      justifyContent: 'end',
    },
    cellMonths: {
      width: 80,
      justifyContent: 'end',
    },
    cellHourlyRate: {
      width: 80,
      justifyContent: 'end',
    },
    cellFirstShift: {
      width: 92,
    },
    cellLastShift: {
      width: 92,
    },
    cellEmployed: {
      width: 92,
    },
    cellCreated: {
      width: 92,
    },
    cellLastOnline: {
      width: 130,
    },
    avatarNameWrapper: {
      display: 'flex',
      gap: theme.spacing(4),
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
    },
    name: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    avatar: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      fontSize: 8,
    },
    spin: {
      animation: '$spin 750ms linear infinite',
      fill: theme.palette.success.dark,
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  }),
);

const ActiveBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }),
)(Badge);

const MemberList = () => {
  const classes = useStyles();
  const theme = useTheme();

  const { permissible } = useAuth();

  const { navigate } = useRouter();

  const {
    nodes,
    loading,
    loadingExtraFields,
    offset,
    input: { orderBy, setOrderBy },
  } = useContext(MemberListContext);

  const handleNavigate =
    (memberId: Member['id']) => (_: React.KeyboardEvent | React.MouseEvent) =>
      navigate(memberId);

  const isRedacted = (
    entity: Pick<Member, 'id' | 'createdAt'> | Pick<RedactedMember, 'id'>,
  ): entity is MemberListContextGetAggregatedRedactedMembersQuery['redactedMembersAggregate']['nodes'][0] =>
    !entity.hasOwnProperty('externalId');

  const isAdmin = permissible({ admin: true });
  const isTester = permissible({ tester: true });

  return (
    <Box className={classes.wrapper}>
      <MemberListControls />

      <MemberListPagination />

      {!!nodes?.length && (
        <Table
          inline
          enableRowHighlighting
          showIndexColumn={isTester}
          indexOffset={offset}
          loading={loading}
          backgroundColor={theme.palette.background.default}
          border="line"
          wrapperStyle={{ flex: '1 1 0' }}
        >
          <TableHeader
            sticky
            title="Name of the employee"
            order={orderBy[0].lastName}
            onClick={() => {
              setOrderBy([
                {
                  lastName:
                    !!orderBy[0].lastName &&
                    orderBy[0].lastName === OrderBy.ASC_NULLS_LAST
                      ? OrderBy.DESC_NULLS_LAST
                      : OrderBy.ASC_NULLS_LAST,
                },
              ]);
            }}
          >
            Name
          </TableHeader>
          <TableHeader
            align="center"
            title="Does the employee have access?"
            hidden={!isAdmin}
          >
            Access
          </TableHeader>
          <TableHeader
            align="center"
            title="Is the employee schedulable?"
            hidden={!isTester}
          >
            Schedulable
          </TableHeader>
          <TableHeader
            align="center"
            title="Is file tracking enabled?"
            hidden={!isTester}
          >
            Track Files
          </TableHeader>
          <TableHeader
            align="center"
            title="Is this employee eligible for the bonus rate?"
            hidden={!isTester}
          >
            Bonue Eligible
          </TableHeader>
          <TableHeader
            align="center"
            title="External ID of the employee."
            hidden={!isAdmin}
          >
            ID
          </TableHeader>
          <TableHeader
            align="center"
            title="Contract externally saved?"
            hidden={!isTester}
            onClick={() => {
              setOrderBy([
                {
                  employementContractExternallySaved:
                    !!orderBy[0].employementContractExternallySaved &&
                    orderBy[0].employementContractExternallySaved ===
                      OrderBy.DESC_NULLS_LAST
                      ? OrderBy.ASC_NULLS_LAST
                      : OrderBy.DESC_NULLS_LAST,
                },
              ]);
            }}
          >
            Contract Saved?
          </TableHeader>
          <TableHeader
            align="center"
            title="Permissions of the employee."
            hidden={!isAdmin}
          >
            Permission
          </TableHeader>
          <TableHeader
            align="center"
            title="Total billable hours."
            hidden={!isTester}
            order={orderBy[0].totalBillableMinutes}
            onClick={() => {
              setOrderBy([
                {
                  totalBillableMinutes:
                    !!orderBy[0].totalBillableMinutes &&
                    orderBy[0].totalBillableMinutes === OrderBy.DESC_NULLS_LAST
                      ? OrderBy.ASC_NULLS_LAST
                      : OrderBy.DESC_NULLS_LAST,
                },
              ]);
            }}
          >
            Billable Hours
          </TableHeader>
          <TableHeader
            align="center"
            title="Quantity of months the employee has been actively working."
            hidden={!isTester}
            order={orderBy[0].minutesBetweenFirstAndLastBillableEvents}
            onClick={() => {
              setOrderBy([
                {
                  minutesBetweenFirstAndLastBillableEvents:
                    !!orderBy[0].minutesBetweenFirstAndLastBillableEvents &&
                    orderBy[0].minutesBetweenFirstAndLastBillableEvents ===
                      OrderBy.DESC_NULLS_LAST
                      ? OrderBy.ASC_NULLS_LAST
                      : OrderBy.DESC_NULLS_LAST,
                },
              ]);
            }}
          >
            Tenure Months
          </TableHeader>
          <TableHeader align="center" title="Hourly rate." hidden={!isTester}>
            Hourly Rate ($)
          </TableHeader>
          <TableHeader align="center" title="Annual Salary." hidden={!isTester}>
            Annual Salary ($)
          </TableHeader>
          <TableHeader
            align="center"
            title="Lateness."
            hidden={!isTester}
            order={orderBy[0].lateness}
            onClick={() => {
              setOrderBy([
                {
                  lateness:
                    !!orderBy[0].lateness &&
                    orderBy[0].lateness === OrderBy.DESC_NULLS_LAST
                      ? OrderBy.ASC_NULLS_LAST
                      : OrderBy.DESC_NULLS_LAST,
                },
              ]);
            }}
          >
            Lateness
          </TableHeader>
          <TableHeader
            align="center"
            title="Date of first shift."
            hidden={!isTester}
            order={orderBy[0].firstBillableEventStartAt}
            onClick={() => {
              setOrderBy([
                {
                  firstBillableEventStartAt:
                    !!orderBy[0].firstBillableEventStartAt &&
                    orderBy[0].firstBillableEventStartAt ===
                      OrderBy.DESC_NULLS_LAST
                      ? OrderBy.ASC_NULLS_LAST
                      : OrderBy.DESC_NULLS_LAST,
                },
              ]);
            }}
          >
            First Shift
          </TableHeader>
          <TableHeader
            align="center"
            title="Date of most recent shift."
            hidden={!isTester}
            order={orderBy[0].lastBillableEventStartAt}
            onClick={() => {
              setOrderBy([
                {
                  lastBillableEventStartAt:
                    !!orderBy[0].lastBillableEventStartAt &&
                    orderBy[0].lastBillableEventStartAt ===
                      OrderBy.DESC_NULLS_LAST
                      ? OrderBy.ASC_NULLS_LAST
                      : OrderBy.DESC_NULLS_LAST,
                },
              ]);
            }}
          >
            Last Shift
          </TableHeader>
          <TableHeader
            align="center"
            title="Employment start date."
            hidden={!isAdmin}
            order={orderBy[0].employmentStartDate}
            onClick={() => {
              setOrderBy([
                {
                  employmentStartDate:
                    !!orderBy[0].employmentStartDate &&
                    orderBy[0].employmentStartDate === OrderBy.DESC_NULLS_LAST
                      ? OrderBy.ASC_NULLS_LAST
                      : OrderBy.DESC_NULLS_LAST,
                },
              ]);
            }}
          >
            Employed
          </TableHeader>
          <TableHeader
            align="center"
            title="Creation date."
            hidden={!isAdmin}
            order={orderBy[0].createdAt}
            onClick={() => {
              setOrderBy([
                {
                  createdAt:
                    !!orderBy[0].createdAt &&
                    orderBy[0].createdAt === OrderBy.DESC_NULLS_LAST
                      ? OrderBy.ASC_NULLS_LAST
                      : OrderBy.DESC_NULLS_LAST,
                },
              ]);
            }}
          >
            Created
          </TableHeader>
          <TableHeader
            align="center"
            title="Last online datetime."
            hidden={!isTester}
            order={orderBy[0].lastActiveAt}
            onClick={() => {
              setOrderBy([
                {
                  lastActiveAt:
                    !!orderBy[0].lastActiveAt &&
                    orderBy[0].lastActiveAt === OrderBy.DESC_NULLS_LAST
                      ? OrderBy.ASC_NULLS_LAST
                      : OrderBy.DESC_NULLS_LAST,
                },
              ]);
            }}
          >
            Last Online
          </TableHeader>
          {nodes.map((n, i) => (
            <TableRow key={i} onClick={handleNavigate(n.id)}>
              <TableCell>
                <div className={classes.avatarNameWrapper}>
                  {isMember(n) &&
                  n.lastActiveAt &&
                  differenceInMinutes(new Date(), new Date(n.lastActiveAt)) <=
                    15 ? (
                    <ActiveBadge
                      overlap="circle"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      variant="dot"
                    >
                      <Avatar
                        aria-label="avatar"
                        className={classes.avatar}
                        color={n.color}
                        content={[n.firstName, n.middleName, n.lastName]}
                      />
                    </ActiveBadge>
                  ) : (
                    <Avatar
                      aria-label="avatar"
                      className={classes.avatar}
                      color={n.color}
                      content={[n.firstName, n.middleName, n.lastName]}
                    />
                  )}
                  {formatPersonName(n, {
                    lastNameFirst: true,
                    capitaliseLastName: true,
                    middle: true,
                  })}
                </div>
              </TableCell>
              {!isRedacted(n) && (
                <>
                  <TableCell>
                    {n.hasAccess && <CheckIcon />}
                    {n.invitationMostRecentlyReceived &&
                      !n.invitationMostRecentlyReceived?.deletedAt &&
                      !n.invitationMostRecentlyReceived?.usedAt &&
                      'Invited'}
                  </TableCell>
                  <TableCell>{n.schedulable ? <CheckIcon /> : null}</TableCell>
                  <TableCell>{n.trackFiles ? <CheckIcon /> : null}</TableCell>
                  <TableCell>
                    {n.bonusEligible ? <CheckIcon /> : null}
                  </TableCell>
                  <TableCell>{n.externalId}</TableCell>
                  <TableCell>{n.employementContractExternallySaved}</TableCell>
                  <TableCell>
                    {n.admin ? (
                      'Sys Admin'
                    ) : n.permissions && formatPermissions(n).length ? (
                      <div title={formatPermissions(n).join('')}>Elevated</div>
                    ) : (
                      ''
                    )}
                  </TableCell>
                  <TableCell>
                    {loadingExtraFields ? (
                      <Skeleton animation="wave" />
                    ) : (
                      !!n.totalBillableMinutes &&
                      roundNumber(
                        n.totalBillableMinutes / 60,
                        0,
                      ).toLocaleString()
                    )}
                  </TableCell>
                  <TableCell>
                    {loadingExtraFields ? (
                      <Skeleton animation="wave" />
                    ) : (
                      !!n.firstBillableEvent &&
                      !!n.lastBillableEvent &&
                      roundNumber(
                        (differenceInMinutes(
                          new Date(n.lastBillableEvent.startAt),
                          new Date(n.firstBillableEvent.startAt),
                        ) /
                          60 /
                          24 /
                          365) *
                          12,
                        1,
                      ).toLocaleString()
                    )}
                  </TableCell>
                  <TableCell>
                    {!!n.currentHourlyBasePayRate &&
                      roundNumber(
                        n.currentHourlyBasePayRate / 100,
                        2,
                      ).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    {!!n.currentAnnualPayRate &&
                      roundNumber(
                        n.currentAnnualPayRate / 100,
                        2,
                      ).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    {loadingExtraFields ? (
                      <Skeleton animation="wave" />
                    ) : (
                      n.lateness !== null &&
                      n.lateness !== undefined &&
                      n.lateness.toFixed(0) + '%'
                    )}
                  </TableCell>
                  <TableCell>
                    {loadingExtraFields ? (
                      <Skeleton animation="wave" />
                    ) : (
                      !!n.firstBillableEvent &&
                      format(
                        new Date(n.firstBillableEvent.startAt),
                        'dd/MM/yyyy',
                      )
                    )}
                  </TableCell>
                  <TableCell>
                    {loadingExtraFields ? (
                      <Skeleton animation="wave" />
                    ) : (
                      !!n.lastBillableEvent &&
                      format(
                        new Date(n.lastBillableEvent.startAt),
                        'dd/MM/yyyy',
                      )
                    )}
                  </TableCell>
                  <TableCell>
                    {!!n.employmentStartDate &&
                      format(new Date(n.employmentStartDate), 'dd/MM/yyyy')}
                  </TableCell>
                  <TableCell>
                    {!!n.createdAt &&
                      format(new Date(n.createdAt), 'dd/MM/yyyy')}
                  </TableCell>
                  <TableCell>
                    {!!n.lastActiveAt &&
                      format(new Date(n.lastActiveAt), 'dd/MM/yyyy HH:mm')}
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </Table>
      )}
    </Box>
  );
};

export default MemberList;

import { Grid, Typography } from '@material-ui/core';
import { Protected } from '@timed/auth';
import { BranchSelectInput } from '@timed/branch';
import { addServerErrors, Block, IconButtonMulti } from '@timed/common';
import {
  Branch,
  Client,
  HistoryRestorable,
  OrderBy,
  QueryByIdInput,
  useUpdateClientsMutation,
} from '@timed/gql';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type ClientUpdateOrganisationDetailsFormProps = {
  client: Pick<Client, 'id'> & {
    branch: Pick<Branch, 'id' | 'name'>;
    archive?: Pick<HistoryRestorable, 'id'> | null;
  };
};

type FormData = {
  patch: {
    branch: QueryByIdInput;
  };
};

const ClientUpdateOrganisationDetailsForm = ({
  client,
}: ClientUpdateOrganisationDetailsFormProps) => {
  const [editing, setEditing] = useState<boolean>(false);

  const [updateClient, response] = useUpdateClientsMutation();

  const defaultValues: FormData = {
    patch: {
      branch: { id: client.branch.id },
    },
  };

  const {
    handleSubmit,
    watch,
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });

  const currentValues = watch();

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = ({ patch }: FormData) => {
    updateClient({
      variables: {
        input: { ids: [client.id], patch },
      },
    }).catch((e) => {});
    reset({ patch });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block
        title="Organisational Details"
        topRight={
          !client.archive ? (
            <Protected admin>
              <IconButtonMulti
                enabled={editing}
                changed={!isEqual(currentValues, defaultValues)}
                setEditing={setEditing}
                loading={response.loading}
                success={!!response.data}
              />
            </Protected>
          ) : undefined
        }
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Branch</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                client.branch.name
              ) : (
                <BranchSelectInput
                  orderBy={[{ name: OrderBy.ASC }]}
                  watch={watch}
                  name="patch.branch.id"
                  control={control}
                  error={!!errors.patch?.branch?.id}
                  // helperText={errors.patch?.branches?.message}
                  disabled={!editing}
                  formControlProps={{
                    size: 'small',
                    variant: 'outlined',
                  }}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
      </Block>
    </form>
  );
};

export default ClientUpdateOrganisationDetailsForm;

import { ApolloError } from '@apollo/client';
import { ErrorOption } from 'react-hook-form';

/**
 * Process errors from server response. Automatically populate form fields with
 * their respective errors messages. Optionally executes a callback function
 * with an input-field-agnostic error message.
 * @param errors Error object from server response
 * @param setError Function provided by react-hook-form to assign field errors
 * @param callback Optional function to execute
 */
export const addServerErrors = <T>(
  errors: ApolloError,
  setError: // Fields are strings
  | ((fieldName: keyof T, error: { type: string; message: string }) => void)
    // Fields may contain objects
    | ((name: string, error: ErrorOption) => void),
  callback?: (message?: string) => void,
) => {
  // console.log(JSON.stringify(errors));
  if (errors.graphQLErrors.length > 0) {
    // GraphQL error(s) occured
    errors.graphQLErrors.forEach((error) => {
      if (error.message === 'Unprocessable Entity Exception') {
        // Process user input errors
        try {
          // Try catch required if server provides incorrect error message
          (error.extensions?.exception as any).response.message.forEach(
            (fieldError: any) => {
              if (fieldError.property) {
                // TEMPORARY: Check for location property
                // Location is the only input that is an object.
                // As such, it's child error messages should be
                // merged into one.
                if (fieldError.property === 'location') {
                  if (fieldError.children?.length > 0) {
                    const messages: string[] = [];
                    fieldError.children.forEach((child: any) => {
                      // This will map errors to the key's parent node
                      Object.keys(child.constraints).forEach((key, i) => {
                        // Collect error messages from each child
                        messages.push(child.constraints[key as keyof T]);
                      });
                    });
                    // Format messages array into a human-friendly string
                    const formattedMessage = messages.join(', ').toLowerCase();
                    setError(fieldError.property, {
                      type: 'server',
                      message:
                        // Capitalise the first letter in the error message
                        formattedMessage[0].toUpperCase() +
                        formattedMessage.slice(1),
                    });
                  }
                }
                if (fieldError.children?.length > 0) {
                  fieldError.children.forEach((child: any) => {
                    // TEMPORARY: Check for location property
                    // Location is the only input that is an object.
                    // As such, it's child error messages should be
                    // merged into one.
                    if (child.property === 'location') {
                      const messages: string[] = [];
                      // This will map errors to the key's parent node
                      Object.keys(child.constraints).forEach((key, i) => {
                        // Collect error messages from each child
                        messages.push(child.constraints[key as keyof T]);
                      });
                      // Format messages array into a human-friendly string
                      const formattedMessage = messages
                        .join(', ')
                        .toLowerCase();
                      setError(fieldError.property, {
                        type: 'server',
                        message:
                          // Capitalise the first letter in the error message
                          formattedMessage[0].toUpperCase() +
                          formattedMessage.slice(1),
                      });
                    } else {
                      // Child is not a location object
                      console.log(child);
                      Object.keys(child.constraints).forEach((key, i) => {
                        setError(
                          (fieldError.property.toString() +
                            '.' +
                            child.property.toString()) as keyof T & string,
                          {
                            type: key,
                            message: child.constraints[key as keyof T],
                          },
                        );
                      });

                      // setError(fieldError.property + "." + child.property, {
                      //   type: "",
                      //   message: child.constraints[key as keyof T]
                      // })
                    }
                  });
                } else {
                  // Field is not an object and has no children
                  Object.keys(fieldError.constraints).forEach((key, i) => {
                    setError(fieldError.property, {
                      type: key,
                      message: fieldError.constraints[key as keyof T],
                    });
                  });
                }
              }
            },
          );
          // Clear result of previous executions of callback()
          callback && callback();
        } catch (e) {
          console.log(e);
        }
      } else {
        // Server didn't reply with an expected error message.
        // Check for a field arguments in error response. If a
        // field argument doesn't exist, error is field-agnostic.
        const arg: unknown = error.extensions?.arguments;
        arg
          ? setError(arg as keyof T & string, {
              type: 'server',
              message: error.message,
            })
          : callback && callback(error.message);
        // arg
        //   ? setError(arg as keyof T, {
        //       type: "server",
        //       message: error.message,
        //     })
        //   : callback && callback(error.message);
      }
    });
  } else if (errors.message.includes('NetworkError') && callback) {
    // Execute callback on connnection error
    callback('Network error: Could not reach server');
  } else if (callback) {
    // Execute callback on unknown error(s)
    callback(errors.message);
  }
};

import { TextareaAutosize, TextareaAutosizeProps } from '@material-ui/core';
import { TransformInput, validateRequired } from '@timed/common';
import { Control, Controller, RegisterOptions } from 'react-hook-form';

type TextareaProps = TextareaAutosizeProps & {
  control?: Control<any>;
  validation?: Omit<RegisterOptions, 'required'>;
  transform?: TransformInput;
};

const Textarea = ({
  control,
  defaultValue = '',
  validation,
  transform,
  ...props
}: TextareaProps) => (
  <Controller
    key={props.name}
    name={props.name!}
    control={control}
    defaultValue={defaultValue}
    rules={
      validation && props.required
        ? { ...validateRequired, ...validation }
        : validation
    }
    render={({ field: { onChange, ...field } }) => (
      <TextareaAutosize
        key={field.name}
        onChange={transform ? (e) => onChange(transform.output(e)) : onChange}
        style={{ fontSize: 16, padding: 8 }}
        {...props}
        {...field}
      />
    )}
  />
);

export default Textarea;

import { Link as MuiLink, LinkProps as MuiLinkProps } from '@material-ui/core';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

type LinkProps = MuiLinkProps & RouterLinkProps;

const Link = ({ children, to, ...props }: LinkProps) =>
  typeof to === 'string' &&
  (/^https?:\/\//.test(to) ||
    /^mailto?:/.test(to) ||
    /^tel?:/.test(to) ||
    /^blob?:/.test(to)) ? (
    // If linking to external website, render as <a>
    <MuiLink href={to} target="_blank" {...props}>
      {children}
    </MuiLink>
  ) : (
    // If linking to internal resource, render as <RouterLink>
    <MuiLink component={RouterLink} to={to} {...props}>
      {children}
    </MuiLink>
  );

export default Link;

import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  addServerErrors,
  FormModal,
  IconButton,
  ModalProps,
  Textarea,
} from '@timed/common';
import {
  EventBillClientCheckbox,
  EventPayAttendeeCheckbox,
} from '@timed/event';
import { Event, useCancelEventsMutation } from '@timed/gql';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type EventCancelFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  event: Pick<Event, 'id'>;
};

type FormData = {
  billable: boolean;
  payable: boolean;
  reason: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
      },
    },
    textarea: {
      margin: 0,
      padding: theme.spacing(2),
      overflowY: 'auto',
      outline: 'none',
      resize: 'none',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      border: '1px solid ' + theme.palette.text.disabled,
      borderRadius: theme.shape.borderRadius,
      fontFamily: 'inherit',
      WebkitTextFillColor: theme.palette.text.primary, // required for iOS
      opacity: 1, // required for iOS
      [theme.breakpoints.up('md')]: {
        width: 500,
      },
    },
  }),
);

const EventCancelFormModal = ({
  onClose,

  event,
  ...modalProps
}: EventCancelFormModalProps) => {
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    setError,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      billable: false,
      payable: false,
      reason: '',
    },
  });

  const billable = watch('billable');

  const [cancelEvents, response] = useCancelEventsMutation({
    onCompleted: () => {
      enqueueSnackbar('Event cancelled', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon fontSize="small" style={{ fill: 'white' }} />
          </IconButton>
        ),
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.message ?? 'Could not cancel event', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon style={{ fill: 'white' }} />
          </IconButton>
        ),
      });
    },
  });

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = (values: FormData) =>
    cancelEvents({
      variables: { input: { objects: [{ id: event.id, ...values }] } },
    });

  // Set payable to true whenever billable equals true.
  useEffect(() => {
    if (billable && !getValues('payable')) {
      setValue('payable', true);
    }
  }, [billable, getValues, setValue]);

  return (
    <FormModal
      modalProps={modalProps}
      title="Cancel shift"
      saveText="Cancel Shift"
      cancelText="Close"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onClose={() => {
        onClose();
      }}
    >
      <Box className={classes.wrapper}>
        <EventBillClientCheckbox
          name="billable"
          control={control}
          error={!!errors.billable}
          helperText={errors.billable?.message}
          defaultChecked={false}
        />
        <EventPayAttendeeCheckbox
          name="payable"
          disabled={billable}
          control={control}
          error={!!errors.payable}
          helperText={errors.payable?.message}
          defaultChecked={false}
        />
        <Textarea
          name="reason"
          control={control}
          minRows={5}
          placeholder="Optional reason for the cancellation"
          className={classes.textarea}
          style={{ height: '', overflow: '' }}
        />
      </Box>
    </FormModal>
  );
};

export default EventCancelFormModal;

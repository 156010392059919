import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Select } from '@timed/common';
import { EntityState } from '@timed/gql';
import { NotesContext } from '@timed/notes/context';
import { ChangeEvent, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        '& .MuiSelect-root': {
          fontSize: 12,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.divider,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': {
        padding: theme.spacing(2, 9, 2, 4),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1.75, 8, 1.75, 2),
        },
      },
      '& .MuiTypography-root': {
        fontSize: '1rem',
        [theme.breakpoints.down('sm')]: {
          fontSize: 12,
        },
      },
    },
  }),
);

const NotesControlStateInput = () => {
  const classes = useStyles();

  const {
    input: { entityStates, setEntityStates },
  } = useContext(NotesContext);

  const { control, getValues, setValue } = useForm<{ state: EntityState }>({
    defaultValues: { state: entityStates[0] },
  });

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setTimeout(() => (document.activeElement as HTMLElement).blur(), 0); // Unselect select field
    setEntityStates([event.target.value as EntityState]);
  };

  useEffect(() => {
    if (getValues('state') !== entityStates[0])
      setValue('state', entityStates[0]);
  }, [entityStates, getValues, setValue]);

  return (
    <Select
      name="state"
      label="Status"
      control={control}
      onChange={handleChange}
      className={classes.root}
      formControlProps={{ variant: 'outlined', size: 'small' }}
      items={[
        { label: 'Normal', value: EntityState.NORMAL },
        { label: 'Deleted', value: EntityState.DELETED },
      ]}
    />
  );
};

export default NotesControlStateInput;

import { CsvImportContext, CsvImportSetDataReducer } from '@timed/common';
import { getTimezone } from '@timed/common/helpers/getTimezone';
import { Timezone } from '@timed/gql';
import Papa from 'papaparse';
import { useCallback, useEffect, useReducer } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
  attachment: any;
  timezone: Timezone;
};

const CsvImportProvider: React.FC = ({ children }) => {
  /**
   * Imported headers and rows from a CSV file.
   */
  const [[headers, rows], setData] = useReducer<CsvImportSetDataReducer>(
    (_, { action, data }) => {
      const defaultValue: [string[], string[][]] = [[], []];

      switch (action) {
        case 'reset':
          return defaultValue;

        default:
          if (!data?.length) return defaultValue;

          return [
            // Header row
            data[0],
            // Non-header rows
            data.slice(1).map((row) => [
              // All non-overflowing columns
              ...row.slice(0, data[0].length - 1),
              // Merge overflowing final columns
              row
                .slice(data[0].length - 1)
                .map((line: string) => line.trim())
                .join('\r\n'),
            ]),
          ];
      }
    },
    [[], []],
  );

  const parse = useCallback((files: FileList | null) => {
    if (!!files?.length)
      Papa.parse<any>(files[0], {
        header: false,
        skipEmptyLines: true,
        complete: ({ data }) => setData({ data }),
      });
  }, []);

  /**
   * Form-control properties.
   */
  const {
    control,
    setError,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      timezone: getTimezone(),
    },
  });

  const attachment = watch('attachment');

  const timezone = watch('timezone');

  // Reset data if file is deleted.
  useEffect(() => {
    if (!!headers.length && !!rows.length && !attachment)
      setData({ action: 'reset' });
  }, [headers, rows, attachment]);

  return (
    <CsvImportContext.Provider
      value={{
        timezone,
        headers,
        rows,
        parse,
        form: {
          control,
          setError,
          setValue,
          errors,
        },
      }}
    >
      {children}
    </CsvImportContext.Provider>
  );
};

export default CsvImportProvider;

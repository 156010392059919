import { TextField, TextFieldProps } from '@timed/common';
import { RegisterOptions } from 'react-hook-form';

export const validateAddressUnit: RegisterOptions = {
  maxLength: { value: 255, message: 'Too long' },
};

const AddressUnitInput = (props: TextFieldProps) => (
  <TextField
    type="text"
    label="Unit"
    validation={validateAddressUnit}
    {...props}
  />
);

export default AddressUnitInput;

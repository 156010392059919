import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useAuth } from '@timed/auth';
import { ClientDeleteBowelMovementFormModal } from '@timed/client/components/DeleteBowelMovementFormModal';
import {
  addServerErrors,
  DateInput,
  FormModal,
  IconButton,
  ModalProps,
  ProfileInput,
  Select,
  TextField,
  TimeInput,
} from '@timed/common';
import {
  Client,
  ClientObservation,
  Member,
  OrderBy,
  Permission,
  PersonNamesFragment,
  StoolType,
  UrineColor,
  useUpdateClientObservationMutation,
} from '@timed/gql';
import { isValid, subMonths } from 'date-fns';
import _, { camelCase, startCase } from 'lodash';
import { useModal } from 'mui-modal-provider';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

type ClientUpdateBowelMovementFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  observation: Pick<
    ClientObservation,
    'id' | 'date' | 'notes' | 'nursesName' | 'stoolType' | 'urineColor'
  > & {
    client: Pick<Client, 'id'>;
    member?: Pick<Member, 'id'> | null;
    createdBy?: (Pick<Member, 'id'> & PersonNamesFragment) | null;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      justifyItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    types: {
      alignItems: 'center',
      display: 'flex',
      gap: theme.spacing(4),
    },
    type: {
      padding: theme.spacing(2),
      backgroundColor: 'white',
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
    },
    selectedType: {
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: 'center',
      border: '1px solid ' + green[500],
      backgroundColor: green[50],
      color: green[800],
    },
    items: {
      display: 'grid',
      gridTemplateColumns: 'max-content max-content',
      rowGap: theme.spacing(2),
      columnGap: theme.spacing(4),
      alignItems: 'center',
    },
    input: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 100,
      },
    },
    dateInput: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 120,
      },
    },
    notesInput: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: 248,
      },
    },
  }),
);

type FormData = {
  patch: {
    date?: Date;
    time?: Date;
    notes?: string | null;
    nursesName?: string | null;
    stoolType?: StoolType | null;
    urineColor?: UrineColor | null;
    member?: { id: string } | null;
  };
};

const ClientUpdateBowelMovementFormModal = ({
  onClose,
  observation,
  ...modalProps
}: ClientUpdateBowelMovementFormModalProps) => {
  const classes = useStyles();

  const { permissible } = useAuth();

  const { showModal } = useModal();

  const [updateClientObservation, response] =
    useUpdateClientObservationMutation();

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      patch: {
        ..._.pick(observation, [
          'date',
          'notes',
          'stoolType',
          'urineColor',
          'nursesName',
        ]),
        member: observation.member
          ? observation.member
          : permissible({ admin: true })
          ? observation.createdBy
          : null,
        time: observation.date,
      },
    },
  });

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = async ({
    patch: { member, stoolType, urineColor, ...patch },
  }: FormData) => {
    patch = _.omit(patch, ['time']);

    if (!!observation.stoolType) Object.assign(patch, { stoolType });
    if (!!observation.urineColor) Object.assign(patch, { urineColor });

    updateClientObservation({
      variables: {
        input: {
          id: observation.id,
          patch: { ...patch, member: member?.id ? { id: member.id } : null },
        },
      },
    });
  };

  const handleChangeTime = useCallback(
    (date?: MaterialUiPickersDate) => {
      if (!!date && isValid(date)) {
        const currentDate = new Date(getValues('patch.date')!);
        currentDate.setHours(date.getHours(), date.getMinutes());

        setValue('patch.date', currentDate);
      }
    },
    [getValues, setValue],
  );

  const handleOpenDeleteClientBowelMovementModal = () => {
    const modal: { hide: () => void } = showModal(
      ClientDeleteBowelMovementFormModal,
      {
        onClose: () => {
          modal.hide();
        },
        closePreviousModal: onClose,
        observation,
      },
    );
  };

  return (
    <FormModal
      modalProps={modalProps}
      title={'Edit ' + (observation.stoolType ? 'Bowel Movement' : 'Urine')}
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      extraControls={
        permissible({ permissions: Permission.CLIENT_WRITE }) ? (
          <IconButton
            onClick={() => {
              handleOpenDeleteClientBowelMovementModal();
            }}
          >
            <DeleteRoundedIcon />
          </IconButton>
        ) : undefined
      }
    >
      <Box className={classes.wrapper}>
        <Box className={classes.items}>
          <Typography variant="body1">Time</Typography>
          <Box className={classes.dateInput}>
            <DateInput
              required
              name="patch.date"
              control={control}
              inputVariant="outlined"
              size="small"
              inputProps={{ style: { textAlign: 'center' } }}
              error={!!errors.patch?.date}
              helperText={errors.patch?.date?.message}
            />
            <TimeInput
              required
              keyboard
              name="patch.time"
              control={control}
              inputVariant="outlined"
              size="small"
              onChange={(date) => {
                if (isValid(date)) handleChangeTime(date);
              }}
              inputProps={{ style: { textAlign: 'center' } }}
              error={!!errors.patch?.time}
              helperText={errors.patch?.time?.message}
            />
          </Box>

          {!!observation.stoolType && (
            <>
              <Typography variant="body1">Stool Type</Typography>
              <Box className={classes.input}>
                <Select
                  name="patch.stoolType"
                  variant="outlined"
                  control={control}
                  formControlProps={{ size: 'small' }}
                  error={!!errors.patch?.stoolType}
                  helperText={errors.patch?.stoolType?.message}
                  items={Object.values(StoolType).map((type) => {
                    return {
                      value: type,
                      label: startCase(camelCase(type)),
                    };
                  })}
                />
              </Box>
            </>
          )}

          {!!observation.urineColor && (
            <>
              <Typography variant="body1">Urine Colour</Typography>
              <Box className={classes.input}>
                <Select
                  name="patch.urineColor"
                  variant="outlined"
                  control={control}
                  formControlProps={{ size: 'small' }}
                  error={!!errors.patch?.urineColor}
                  helperText={errors.patch?.urineColor?.message}
                  items={[
                    {
                      value: UrineColor.CLEAR,
                      label: 'Clear',
                    },
                    {
                      value: UrineColor.LIGHTYELLOW,
                      label: 'Light Yellow',
                    },
                    {
                      value: UrineColor.YELLOW,
                      label: 'Yellow',
                    },
                    {
                      value: UrineColor.DARKYELLOW,
                      label: 'Dark Yellow',
                    },
                    {
                      value: UrineColor.BROWN,
                      label: 'Brown',
                    },
                  ]}
                />
              </Box>
            </>
          )}

          {permissible({ permissions: Permission.MEMBER_READ }) && (
            <>
              <Typography variant="body1">Support worker</Typography>
              <Box className={classes.notesInput}>
                <ProfileInput
                  control={control}
                  name="patch.member.id"
                  chipProps={{
                    onDelete: () => setValue('patch.member.id', ''),
                  }}
                  formControlProps={{ variant: 'outlined', size: 'small' }}
                  watch={watch}
                  error={!!errors.patch?.member}
                  type="member"
                  orderBy={[{ lastName: OrderBy.ASC }]}
                  where={{
                    _or: [
                      {
                        events: {
                          client: { id: { _eq: observation.client.id } },
                          startAt: { _gte: subMonths(new Date(), 3) },
                        },
                      },
                      {
                        clientObservationsCreated: {
                          client: { id: { _eq: observation.client.id } },
                        },
                      },
                    ],
                  }}
                />
              </Box>
            </>
          )}

          <Typography variant="body1">Nurse Name</Typography>
          <Box className={classes.notesInput}>
            <TextField
              name="patch.nursesName"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.patch?.nursesName}
              helperText={errors.patch?.nursesName?.message}
              validation={{ maxLength: 255 }}
            ></TextField>
          </Box>

          <Typography variant="body1">Notes</Typography>
          <Box className={classes.notesInput}>
            <TextField
              multiline
              minRows={2}
              name="patch.notes"
              variant="outlined"
              size="small"
              control={control}
              error={!!errors.patch?.notes}
              helperText={errors.patch?.notes?.message}
              validation={{ maxLength: 255 }}
            ></TextField>
          </Box>
        </Box>
      </Box>
    </FormModal>
  );
};

export default ClientUpdateBowelMovementFormModal;

import { Select, SelectProps } from '@timed/common';
import { MemberFileType } from '@timed/gql';
import { memberFilesMetadata } from '@timed/member';
import { validateFileType } from '@timed/file';

const MemberUploadFileTypeInput = ({
  defaultValue,
  ...props
}: Omit<SelectProps, 'items'>) => (
  <Select
    label="Category"
    validation={validateFileType('member')}
    items={Object.values(memberFilesMetadata)
      .filter(
        ({ id }) =>
          ![
            MemberFileType.STAFF_CARD_PHOTO_ID,
            MemberFileType.WORK_RIGHTS,
            MemberFileType.VEVO,
          ].includes(id),
      )
      .map(({ id, name }) => {
        return {
          value: id,
          label: name,
        };
      })
      .sort((a, b) => (a.label > b.label ? 1 : -1))}
    renderValue={(value) =>
      memberFilesMetadata.find((meta) => meta.id === value)?.name
    }
    {...props}
  />
);

export default MemberUploadFileTypeInput;

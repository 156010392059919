import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core';
import { Link } from '@timed/common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      backgroundColor: theme.palette.appBar.main,
      display: 'flex',
      width: theme.spacing(16),
      height: theme.spacing(16),
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        width: theme.spacing(12),
        height: theme.spacing(12),
      },
      '&:hover': {
        backgroundColor: theme.palette.appBar.light,
      },
    },
  }),
);

const NavBarLogo = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Link to="">
      <Box className={classes.logo}>
        <img src={'/' + theme.palette.logo.src} alt="Timed Logo" />
      </Box>
    </Link>
  );
};

export default NavBarLogo;

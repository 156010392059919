import { Grid, Typography } from '@material-ui/core';
import { Protected, useAuth } from '@timed/auth';
import {
  AddressCountryInput,
  addServerErrors,
  Block,
  capitaliseFirstLetter,
  DateInput,
  IconButtonMulti,
  primativeFromString,
  Radio,
  RadioGroup,
  TextField,
  transformNumberToFixedFloat,
} from '@timed/common';
import {
  AddressCountryCode,
  HistoryRestorable,
  Member,
  MemberFileType,
  OrderBy,
  Permission,
  useGetMemberFilesLazyQuery,
  useUpdateMembersMutation,
} from '@timed/gql';
import {
  MemberPassportNumberInput,
  MemberVisaTypeInput,
  MemberWorkRightsInput,
} from '@timed/member';
import { format } from 'date-fns';
import { differenceInDays } from 'date-fns/esm';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type MemberUpdateWorkRightsFormProps = {
  member: Pick<
    Member,
    | 'id'
    | 'australianCitizen'
    | 'allowedFortnightlyWorkHours'
    | 'workRights'
    | 'workRightsLastCheckedAt'
    | 'passportCountry'
    | 'passportNumber'
    | 'visaType'
  > & { archive?: Pick<HistoryRestorable, 'id'> | null };
};

type FormData = {
  patch: {
    workRights: Member['workRights'];
    visaTypeId?: string | null;
    workRightsLastCheckedAt: Date | null;
    allowedFortnightlyWorkHours: Member['allowedFortnightlyWorkHours'];
    australianCitizen: string;
    passportCountry?: AddressCountryCode | null;
    passportNumber?: string | null;
  };
};

const MemberUpdateWorkRightsFormForm = ({
  member,
}: MemberUpdateWorkRightsFormProps) => {
  const [editing, setEditing] = useState<boolean>(false);

  const { permissible } = useAuth();

  const [updateMember, response] = useUpdateMembersMutation();

  const [getMemberFiles, memberFilesResponse] = useGetMemberFilesLazyQuery({
    variables: {
      input: {
        limit: 1,
        where: {
          owner: { id: { _eq: member.id } },
          type: { _eq: MemberFileType.VISA },
        },
        orderBy: [
          { dateOfFile: OrderBy.DESC_NULLS_LAST },
          { file: { createdAt: OrderBy.DESC_NULLS_LAST } },
        ],
      },
    },
  });

  const defaultValues: FormData = {
    patch: {
      australianCitizen:
        member.australianCitizen === null
          ? 'null'
          : member.australianCitizen
          ? 'true'
          : 'false',
      visaTypeId: member.visaType ? member.visaType.id : null,
      allowedFortnightlyWorkHours: member.allowedFortnightlyWorkHours,
      workRights: member.workRights,
      workRightsLastCheckedAt: !!member.workRightsLastCheckedAt
        ? member.workRightsLastCheckedAt
        : null,
      passportCountry: member.passportCountry,
      passportNumber: member.passportNumber,
    },
  };

  const {
    handleSubmit,
    watch,
    control,
    setError,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });

  const currentValues = watch();

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  useEffect(() => {
    if (
      !memberFilesResponse.data &&
      !memberFilesResponse.loading &&
      permissible({ permissions: Permission.MEMBER_FILE_READ })
    )
      getMemberFiles();
  }, [
    getMemberFiles,
    memberFilesResponse.data,
    memberFilesResponse.loading,
    permissible,
  ]);

  const onSubmit = ({
    patch: { visaTypeId, australianCitizen, ...patch },
  }: FormData) => {
    if (visaTypeId !== undefined)
      Object.assign(patch, {
        visaType: visaTypeId ? { id: visaTypeId } : null,
      });

    if (australianCitizen !== undefined)
      Object.assign(patch, {
        australianCitizen: primativeFromString(australianCitizen),
      });

    updateMember({
      variables: {
        input: { patch, ids: [member.id] },
      },
    }).catch((e) => {});

    reset({ patch: { ...patch, australianCitizen } });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block
        title="Work Rights"
        topRight={
          !member.archive ? (
            <Protected permissions={Permission.MEMBER_WRITE}>
              <IconButtonMulti
                size="small"
                enabled={editing}
                changed={!isEqual(currentValues, defaultValues)}
                setEditing={setEditing}
                loading={response.loading}
                success={!!response.data}
              />
            </Protected>
          ) : undefined
        }
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Australian Citizen</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.australianCitizen ? (
                  'Yes'
                ) : member.australianCitizen === null ? (
                  'Unknown'
                ) : (
                  'No'
                )
              ) : (
                <RadioGroup
                  name="patch.australianCitizen"
                  control={control}
                  style={{ display: 'flex', flexDirection: 'row' }}
                  error={!!errors.patch?.australianCitizen}
                  helperText={errors.patch?.australianCitizen?.message}
                >
                  <Radio
                    label="Yes"
                    value="true"
                    currentValue={getValues('patch.australianCitizen')}
                  />
                  <Radio
                    label="No"
                    value="false"
                    currentValue={getValues('patch.australianCitizen')}
                  />
                  <Radio
                    label="Unknown"
                    value="null"
                    currentValue={getValues('patch.australianCitizen')}
                  />
                </RadioGroup>
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Passport country</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.passportCountry === null ? (
                  ''
                ) : (
                  member.passportCountry
                )
              ) : (
                <AddressCountryInput
                  control={control}
                  name="patch.passportCountry"
                  error={!!errors.patch?.passportCountry}
                  helperText={errors.patch?.passportCountry?.message}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Passport Number</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.passportNumber === null ? (
                  ''
                ) : (
                  member.passportNumber
                )
              ) : (
                <MemberPassportNumberInput
                  control={control}
                  name="patch.passportNumber"
                  error={!!errors.patch?.passportNumber}
                  helperText={errors.patch?.passportNumber?.message}
                  variant="outlined"
                  size="small"
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>VISA Class</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                !!member.visaType && member.visaType.name
              ) : (
                <MemberVisaTypeInput
                  name="patch.visaTypeId"
                  control={control}
                  watch={watch}
                  variant="outlined"
                  formControlProps={{ size: 'small' }}
                  chipProps={{
                    onDelete: () => setValue('patch.visaTypeId', ''),
                  }}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Status</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                !!member.workRights &&
                capitaliseFirstLetter(member.workRights.toLowerCase())
              ) : (
                <MemberWorkRightsInput
                  name="patch.workRights"
                  control={control}
                  setValue={setValue}
                  error={!!errors.patch?.workRights}
                  helperText={errors.patch?.workRights?.message}
                />
              )}
            </Typography>
          </Grid>
          {!editing && !!memberFilesResponse.data?.memberFiles[0] && (
            <>
              <Grid item xs={4} md={3} lg={2}>
                <Typography>Document Date</Typography>
              </Grid>
              <Grid item xs={8} md={9} lg={10}>
                <Typography>
                  {!!memberFilesResponse.data?.memberFiles[0].dateOfFile
                    ? format(
                        new Date(
                          memberFilesResponse.data?.memberFiles[0].dateOfFile,
                        ),
                        'dd/MM/yyyy',
                      )
                    : 'Unknown'}
                </Typography>
              </Grid>
              <Grid item xs={4} md={3} lg={2}>
                <Typography>Document Expiry</Typography>
              </Grid>
              <Grid item xs={8} md={9} lg={10}>
                <Typography>
                  {!!memberFilesResponse.data?.memberFiles[0].expiresAt
                    ? format(
                        new Date(
                          memberFilesResponse.data?.memberFiles[0].expiresAt,
                        ),
                        'dd/MM/yyyy',
                      )
                    : 'Unknown'}
                </Typography>
              </Grid>
              <Grid item xs={4} md={3} lg={2}>
                <Typography>Document Comment</Typography>
              </Grid>
              <Grid item xs={8} md={9} lg={10}>
                <Typography>
                  {!!memberFilesResponse.data?.memberFiles[0].value
                    ? memberFilesResponse.data?.memberFiles[0].value
                    : 'None'}
                </Typography>
              </Grid>
            </>
          )}

          <Grid item xs={4} md={3} lg={2}>
            <Typography>Last Check Date</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                !!member.workRightsLastCheckedAt &&
                format(new Date(member.workRightsLastCheckedAt), 'dd/MM/yyyy') +
                  ' (' +
                  differenceInDays(
                    new Date(),
                    new Date(member.workRightsLastCheckedAt),
                  ) +
                  ' days ago)'
              ) : (
                <DateInput
                  keyboard
                  clearable
                  disableTime
                  control={control}
                  inputVariant="outlined"
                  size="small"
                  name="patch.workRightsLastCheckedAt"
                  error={!!errors.patch?.workRightsLastCheckedAt}
                  helperText={errors.patch?.workRightsLastCheckedAt?.message}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Max hrs fortnight</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                !!member.allowedFortnightlyWorkHours &&
                member.allowedFortnightlyWorkHours
              ) : (
                <TextField
                  control={control}
                  name="patch.allowedFortnightlyWorkHours"
                  type="number"
                  variant="outlined"
                  size="small"
                  inputProps={{ min: 0, max: 336, step: 1 }}
                  transform={transformNumberToFixedFloat(0)}
                  onClick={(event) => {
                    (event.target as HTMLInputElement).select();
                  }}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
      </Block>
    </form>
  );
};

export default MemberUpdateWorkRightsFormForm;

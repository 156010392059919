import { _settingsOrganisationVisaTypes } from '@timed/app';
import { Page } from '@timed/page';
import { SettingsOrganisationVisaTypesForm } from '@timed/settings';

const RouteSettingsOrganisationVisaTypes = () => {
  return (
    <Page {..._settingsOrganisationVisaTypes}>
      <SettingsOrganisationVisaTypesForm />
    </Page>
  );
};

export default RouteSettingsOrganisationVisaTypes;

import { Select, SelectProps } from '@timed/common';
import { MemberTaxFileNumberDeclarationAreYou } from '@timed/gql';
import { camelCase, startCase } from 'lodash';
import { RegisterOptions } from 'react-hook-form';

export const validateMemberTaxFileNumberDeclarationAreYou: RegisterOptions = {
  validate: {
    validValue: (value) =>
      !value ||
      value in MemberTaxFileNumberDeclarationAreYou ||
      'Invalid value',
  },
};

const MemberTaxFileNumberDeclarationAreYouInput = ({
  defaultValue,
  ...props
}: Omit<SelectProps, 'items'>) => (
  <Select
    defaultValue={defaultValue}
    validation={validateMemberTaxFileNumberDeclarationAreYou}
    items={Object.values(MemberTaxFileNumberDeclarationAreYou).map((value) => ({
      value,
      label:
        startCase(camelCase(value)) +
        (value !== MemberTaxFileNumberDeclarationAreYou.HOLIDAY_MAKER
          ? ' for tax purposes'
          : ''),
    }))}
    renderValue={(value) => startCase(camelCase(value as string))}
    {...props}
  />
);

export default MemberTaxFileNumberDeclarationAreYouInput;

/**
 * Options object for formatPersonName() function
 */
type FormatCurrency = {
  /**
   * Decimal places
   */
  decimals?: number;
};

/**
 * Format currency
 */
export const formatCurrency = (
  value: number,
  { decimals = 2 }: FormatCurrency = {},
): string => {
  return new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  }).format(value);
};

import { ApolloProvider } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { App } from '@timed/app';
import { ApolloClient } from '@timed/gql';
import { LoadingProvider } from '@timed/loading';
import { ModuleProvider } from '@timed/module';
import { ThemeProvider } from '@timed/theme';
import locale from 'date-fns/locale/en-AU';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import { SnackbarProvider } from 'notistack';

if (!!locale && !!locale.options) {
  locale.options.weekStartsOn = 1;
}

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={ApolloClient}>
      <ThemeProvider>
        <CssBaseline />
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
          <LoadingProvider>
            <ModuleProvider>
              <SnackbarProvider autoHideDuration={2500}>
                <Router>
                  <App />
                </Router>
              </SnackbarProvider>
            </ModuleProvider>
          </LoadingProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

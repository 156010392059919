import {
  Box,
  Button,
  createStyles,
  FormControl,
  FormControlProps,
  FormHelperText,
  makeStyles,
  OutlinedInput,
  OutlinedInputProps,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { IconButton, MIME, validateRequired } from '@timed/common';
import clsx from 'clsx';
import React, { ChangeEvent, useState } from 'react';
import {
  Control,
  Controller,
  RegisterOptions,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';

export type FileInputProps = OutlinedInputProps & {
  control?: Control<any>;
  helperText?: string;
  name: string;
  setError: UseFormSetError<any>;
  setValue: UseFormSetValue<any>;
  allowedMimeTypes: MIME[];
  formControlProps?: FormControlProps;
  validation?: Omit<RegisterOptions, 'required'>;
  fileNamePrefix?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      flex: '1 1 auto',
    },
    outline: {
      width: '100%',
      '& .MuiInputBase-input': {
        cursor: 'default',
      },
      '& .MuiFormLabel-root.Mui-disabled': {
        color: theme.palette.text.secondary,
      },
    },
    contents: {
      width: '100%',
      height: 32,
      padding: theme.spacing(2, 2, 0, 2),
      position: 'absolute',
      display: 'flex',
      overflow: 'hidden',
      gap: theme.spacing(2),
      alignItems: 'center',
      pointerEvents: 'none',
    },
    selectButton: {
      flexShrink: 0,
      padding: theme.spacing(0, 2),
      pointerEvents: 'auto',
    },
    fileName: {
      flex: '1 1 auto',
    },
    clearButton: { flexShrink: 0, pointerEvents: 'auto' },
    errorMessage: {
      color: theme.palette.error.main,
    },
  }),
);

const FileInput = ({
  control,
  required,
  name,
  error,
  onChange,
  setError,
  setValue,
  formControlProps,
  allowedMimeTypes,
  helperText,
  validation,
  className,
  fileNamePrefix,
  ...props
}: FileInputProps) => {
  const classes = useStyles();
  const { palette } = useTheme();
  const [attachment, setAttachment] = useState<any>();
  const anchorRef = React.useRef<HTMLInputElement>(null);

  const handleChange = ({
    target: {
      validity: { valid },
      files,
    },
  }: ChangeEvent<HTMLInputElement>) => {
    const file = !!fileNamePrefix
      ? new File([files![0]], fileNamePrefix + files![0].name, files![0])
      : files![0];

    setAttachment(file);

    if (file.size > 15728640)
      setError(name, { message: 'File size cannot exceed 15MB.' });
    else if (!valid) setError(name, { message: 'Invalid file' });
    else setValue(name, file);
  };

  const handleClear = () => {
    setAttachment(null);
    setValue(name, null);
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={
        validation && required
          ? { ...validateRequired, ...validation }
          : validation
      }
      render={({ field: { value, ...field } }) => (
        <Box
          className={
            className ? clsx(classes.wrapper, className) : classes.wrapper
          }
          color={!!error ? palette.error.main : palette.background.paper}
          onKeyDown={(event) =>
            event.keyCode === 32 && anchorRef?.current?.click()
          }
        >
          <FormControl {...formControlProps} className={classes.outline}>
            <OutlinedInput error={error} {...props} />
            <FormHelperText className={classes.errorMessage}>
              {error && helperText}
            </FormHelperText>
            <Box className={classes.contents}>
              <Button
                component="label"
                variant="contained"
                size="small"
                color="primary"
                className={classes.selectButton}
              >
                Select File
                <input
                  {...field}
                  hidden
                  required={required}
                  name={name}
                  ref={anchorRef}
                  type="file"
                  accept={allowedMimeTypes.join(',')}
                  onChange={(event) => {
                    onChange && onChange(event);
                    handleChange(event);
                  }}
                />
              </Button>
              <Typography
                color="textPrimary"
                noWrap
                className={classes.fileName}
              >
                {!!attachment && attachment.name}
              </Typography>
              {attachment && (
                <IconButton
                  size="small"
                  className={classes.clearButton}
                  onClick={handleClear}
                  onMouseDown={(event: any) => event.stopPropagation()}
                >
                  <CloseRoundedIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
          </FormControl>
        </Box>
      )}
    />
  );
};

export default FileInput;

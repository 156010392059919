import { FileMetadata } from '@timed/file';
import { EventFileType } from '@timed/gql';

export const eventFilesMetadata: FileMetadata<EventFileType>[] = [
  {
    id: EventFileType.MISC,
    name: 'Miscellaneous',
    hasDate: true,
    hasNote: true,
  },
  {
    id: EventFileType.TRAVEL_RECEIPT,
    name: 'Travel Receipt',
    hasDate: true,
    hasNote: true,
  },
];

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { AuthContextType, Protected, useAuth } from '@timed/auth';
import {
  AddressInput,
  addServerErrors,
  Checkbox,
  DateInput,
  FormModal,
  intersperse,
  ModalProps,
  ProfileInput,
  roundNumber,
  Textarea,
  TimeInput,
} from '@timed/common';
import { pluralise } from '@timed/common/utils/pluralise';
import {
  EventBillClientCheckbox,
  EventBillingRegistrationGroupInput,
  EventBonusPayInput,
  EventDurationInput,
  EventMemberInput,
  EventPayAttendeeCheckbox,
  EventRequireCarCheckbox,
} from '@timed/event';
import {
  Address,
  AddressRegionCode,
  CreateEventDocument,
  CreateEventInput,
  Event,
  EventBillingRegistrationGroup,
  OrderBy,
  QueryByIdInput,
  useCreateEventMutation,
  useGetClientsQuery,
  useGetPublicHolidaysLazyQuery,
} from '@timed/gql';
import { ScheduleContextType } from '@timed/schedule';
import {
  addHours,
  addMinutes,
  differenceInMinutes,
  eachDayOfInterval,
  format,
  isBefore,
  isEqual,
  isValid,
  subMilliseconds,
} from 'date-fns';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type EventCreateFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  permissible: AuthContextType['permissible'];
  client: ScheduleContextType['client'];
  member: ScheduleContextType['member'];
  setTime: ScheduleContextType['setTime'];
  setTimezone: ScheduleContextType['setTimezone'];
  startAt: Date;
  endAt: Date;
};

type FormData = Pick<
  Event,
  | 'locked'
  | 'startAt'
  | 'endAt'
  | 'passiveStartAt'
  | 'activeAssist'
  | 'duration'
  | 'summary'
  | 'scheduleNotes'
  | 'passive'
  | 'billable'
  | 'billingRegistrationGroup'
  | 'payable'
  | 'bonusPay'
  | 'publicHoliday'
  | 'requireCar'
> & {
  startAtTime?: Date | null;
  endAtTime?: Date | null;
  passiveStartAtTime?: Date | null;
  client: QueryByIdInput;
  member?: QueryByIdInput | null;
  address?: Pick<
    Address,
    | 'unit'
    | 'street'
    | 'locality'
    | 'region'
    | 'postcode'
    | 'country'
    | 'latitude'
    | 'longitude'
  > | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        alignItems: 'start',
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
        flexDirection: 'column',
      },
    },
    textarea: {
      backgroundColor: theme.palette.background.paper,
      width: 322,
      color: theme.palette.text.primary,
      border: '1px solid ' + theme.palette.text.disabled,
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.up('md')]: {
        width: 483,
      },
    },
    address: {
      width: 322,
      [theme.breakpoints.up('md')]: {
        width: 483,
      },
    },
    select: {
      width: 322,
    },
    dates: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridAutoColumns: '120px 90px 80px',
      gap: theme.spacing(4),
      alignItems: 'center',
    },
    passive: {
      width: 322,
      backgroundColor: theme.palette.background.paper2,
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2, 4),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    groups: {
      display: 'grid',
      gridTemplateColumns: 'auto',
      gap: theme.spacing(4),
    },
    group: {
      display: 'grid',
      gridTemplateColumns: 'max-content',
      gridAutoRows: 'max-content',
      gap: theme.spacing(1),
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    buttons: {
      flex: '0 1 max-content',
      display: 'flex',
      justifyContent: 'space-between',
    },
    error: {
      width: 322,
      color: theme.palette.error.main,
      backgroundColor: red[50],
      border: '1px solid ' + red[800],
      padding: theme.spacing(2),
    },
  }),
);

const EventCreateFormModal = ({
  client,
  member,
  setTime,
  setTimezone,
  startAt,
  endAt,
  onClose,
  permissible,
  ...modalProps
}: EventCreateFormModalProps) => {
  const classes = useStyles();

  const theme = useTheme();

  const { branch } = useAuth();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [passive, setPassive] = useState<boolean>(false);

  const [createEvents, response] = useCreateEventMutation();

  const [getPublicHolidays, publicHolidaysResponse] =
    useGetPublicHolidaysLazyQuery({ fetchPolicy: 'network-only' });

  const clients = useGetClientsQuery({
    variables: { input: { orderBy: [{ lastName: OrderBy.ASC }] } },
  });

  const selectedClient = clients.data?.clients.find(
    (p) => p.id === client.get(),
  );

  const [lastToggledCheckbox, setLastToggleCheckbox] = useState<
    'billable' | 'payable' | null
  >();

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      startAt,
      startAtTime: startAt,
      endAt: addMinutes(
        startAt,
        endAt &&
          (differenceInMinutes(endAt, startAt) > (smDown ? 60 : 30) ||
            !selectedClient)
          ? differenceInMinutes(endAt, startAt)
          : (selectedClient?.defaultEventDuration || 0) * 60,
      ),
      endAtTime: addMinutes(
        startAt,
        endAt &&
          (differenceInMinutes(endAt, startAt) > (smDown ? 60 : 30) ||
            !selectedClient)
          ? differenceInMinutes(endAt, startAt)
          : (selectedClient?.defaultEventDuration || 0) * 60,
      ),
      duration:
        endAt &&
        (differenceInMinutes(endAt, startAt) > (smDown ? 60 : 30) ||
          !selectedClient)
          ? differenceInMinutes(endAt, startAt) / 60
          : selectedClient?.defaultEventDuration || (smDown ? 60 : 30),
      member: { id: member.get() || '' },
      client: { id: client.get() || '' },
      passive: false,
      activeAssist: false,
      locked: false,
      billable: true,
      payable: true,
      billingRegistrationGroup:
        EventBillingRegistrationGroup.DAILY_PERSONAL_ACTIVITIES,
      bonusPay: 0,
      requireCar: false,
      address: _.pick(selectedClient, [
        'unit',
        'street',
        'locality',
        'region',
        'postcode',
        'country',
        'latitude',
        'longitude',
      ]),
    },
  });

  const [region, setRegion] = useState<AddressRegionCode | null | undefined>(
    selectedClient?.region,
  );

  const participant = watch('client');
  const billable = watch('billable');
  const payable = watch('payable');
  const startAtWatched = watch('startAt');
  const endAtWatched = watch('endAt');
  const requireCar = watch('requireCar');
  const address = watch('address');

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSuccess = () => {
    const newClient = response.data!.createEvent.client;

    if (!member.get() || (!!client.get() && client.get() !== newClient.id)) {
      client.set(newClient.id, true);
      if (!!newClient.timezone) setTimezone('client', newClient.timezone);
    }

    const cache = response.client.cache;

    cache.modify({
      fields: {
        events(existing = []) {
          const events = cache.writeQuery({
            data: response.data,
            query: CreateEventDocument,
          });
          return [...existing, events];
        },
      },
    });
  };

  const onSubmit = ({
    address,
    member,
    locked,
    bonusPay,
    ...values
  }: FormData) => {
    // Convert date values to correct timezone
    const { endAt, ...formattedValues } = Object.fromEntries(
      Object.entries(values).map(([k, v]): [k: string, v: any] => [
        k,
        v instanceof Date ? setTime(v, true) : v,
      ]),
    );

    if (!formattedValues.passive) formattedValues.passiveStartAt = null;

    createEvents({
      variables: {
        input: {
          ..._.omit(formattedValues, [
            'duration',
            'startAtTime',
            'endAtTime',
            'passiveStartAtTime',
          ]),
          ...address,
          member: member?.id ? member : null,
          duration: differenceInMinutes(endAt, formattedValues.startAt),
          locked: permissible({ admin: true }) ? locked : undefined,
          bonusPay: permissible({ admin: true })
            ? roundNumber(bonusPay * 100, 2)
            : undefined,
        } as CreateEventInput,
      },
    });
  };

  const handleChangeStartAt = useCallback(
    (value: MaterialUiPickersDate) => {
      value ??= new Date();

      const duration = getValues('duration');

      // Set new startAt value
      setValue('startAt', value);

      if (endAtWatched) {
        if (isBefore(endAtWatched, value)) {
          // Set endAt to a time in the future if it occurs before the new startAt
          setValue('endAt', addMinutes(value, duration * 60));
          setValue('endAtTime', addMinutes(value, duration * 60));
        } else {
          setValue(
            'duration',
            (endAtWatched.getTime() - value.getTime()) / 1000 / 60 / 60,
          );
        }
      }
    },
    [getValues, setValue, endAtWatched],
  );

  const handleChangeEndAt = useCallback(
    (value: MaterialUiPickersDate | string) => {
      if (typeof value === 'string') {
        if (value === '' || +value < 0) {
          setValue('endAt', getValues('startAt'));
          setValue('endAtTime', getValues('startAt'));
        } else if (!isNaN(parseFloat(value))) {
          const newEndAt = addMinutes(
            getValues('startAt') as Date,
            parseFloat(value) * 60,
          );
          setValue('endAt', newEndAt);
          setValue('endAtTime', newEndAt);
        }
      } else {
        setValue('endAt', value || new Date());
        setValue('endAtTime', value || new Date());
      }

      if (
        getValues('startAt') &&
        getValues('endAt') &&
        isBefore(getValues('startAt'), getValues('endAt'))
      ) {
        setValue(
          'duration',
          typeof value === 'string' && value.slice(-1) === '.'
            ? parseFloat(value)
            : roundNumber(
                ((getValues('endAt')! as Date).getTime() -
                  (getValues('startAt')! as Date).getTime()) /
                  1000 /
                  60 /
                  60,
                2,
              ),
        );
        if (
          value instanceof Date &&
          (!getValues('endAtTime') ||
            !isEqual(value, new Date(getValues('endAtTime')!)))
        )
          setValue('endAtTime', new Date(value));
      } else {
        setValue('duration', 0);
      }

      setEndAtHasChanged(true);
    },
    [getValues, setValue],
  );

  const handleTogglePassive = () => {
    if (!passive) {
      // If duration is less than 12 hours, set it to 12 hours
      if (differenceInMinutes(getValues('endAt'), getValues('startAt')) < 720)
        handleChangeEndAt(addHours(getValues('startAt'), 12));

      // Set a default value for passiveStartAt
      if (!getValues('passiveStartAt')) {
        setValue('passiveStartAt', addHours(getValues('startAt'), 2));
        setValue('passiveStartAtTime', addHours(getValues('startAt'), 2));
      }
    }

    setValue('passive', !passive);
    setPassive(!passive);
  };

  const handleChangePassiveStartAt = useCallback(
    (passiveStartAt) => {
      if (isBefore(passiveStartAt, getValues('startAt'))) {
        setValue('startAtTime', passiveStartAt);
        handleChangeStartAt(passiveStartAt);
      }
    },
    [getValues, setValue, handleChangeStartAt],
  );

  const handleChangeStartAtTime = useCallback(
    (date?: MaterialUiPickersDate) => {
      if (!!date && isValid(date)) {
        const currentDate = new Date(getValues('startAt'));
        currentDate.setHours(date.getHours(), date.getMinutes());
        handleChangeStartAt(currentDate);
      }
    },
    [getValues, handleChangeStartAt],
  );

  const handleChangeEndAtTime = useCallback(
    (date?: MaterialUiPickersDate) => {
      if (!!date && isValid(date)) {
        const currentDate = new Date(getValues('endAt'));
        currentDate.setHours(date.getHours(), date.getMinutes());
        handleChangeEndAt(currentDate);
      }
    },
    [getValues, handleChangeEndAt],
  );

  const handleChangePassiveStartAtTime = useCallback(
    (date?: MaterialUiPickersDate) => {
      if (!!date && isValid(date)) {
        const currentDate = new Date(getValues('passiveStartAt'));
        currentDate.setHours(date.getHours(), date.getMinutes());
        setValue('passiveStartAt', currentDate);
        handleChangePassiveStartAt(currentDate);
      }
    },
    [getValues, setValue, handleChangePassiveStartAt],
  );

  const [endAtHasChanged, setEndAtHasChanged] = useState<boolean>(false);

  useEffect(() => {
    if (address?.region !== region) setRegion(address?.region);
  }, [address, region]);

  // Update fields when participant changes
  useEffect(() => {
    if (participant.id || clients.data) {
      const newClient = clients.data?.clients.find(
        (c) => c.id === (participant.id ?? client.get()),
      );

      if (newClient?.timezone) setTimezone('client', newClient.timezone);

      setRegion(newClient?.region);

      // Overwrite address with the new client's address
      setValue(
        'address',
        _.pick(newClient, [
          'unit',
          'street',
          'locality',
          'region',
          'postcode',
          'country',
          'latitude',
          'longitude',
        ]),
      );
    }
  }, [client, clients.data, participant.id, setValue, setTimezone]);

  // Update fields when participant changes
  useEffect(() => {
    if ((participant.id || clients.data) && !endAtHasChanged) {
      const newClient = clients.data?.clients.find(
        (c) => c.id === (participant.id ?? client.get()),
      );

      // Set duration to client's default event duration
      if (
        newClient?.defaultEventDuration &&
        differenceInMinutes(endAtWatched, startAtWatched) === (smDown ? 60 : 30)
      )
        handleChangeEndAt(
          addMinutes(startAtWatched, newClient.defaultEventDuration * 60),
        );
    }
  }, [
    smDown,
    client,
    clients.data,
    participant.id,
    handleChangeEndAt,
    endAtWatched,
    startAtWatched,
    endAtHasChanged,
  ]);

  useEffect(() => {
    if (lastToggledCheckbox === 'billable') {
      if (billable && !payable) {
        // Set payable to true whenever billable equals true.
        setValue('payable', true);
      }
    } else if (lastToggledCheckbox === 'payable') {
      if (billable && !payable) {
        // Set billable to false whenever payable equals false.
        setValue('billable', false);
      }
    }
  }, [billable, payable, setValue, lastToggledCheckbox]);

  // Check for public holidays
  useEffect(() => {
    if (
      region &&
      startAtWatched &&
      endAtWatched &&
      isValid(startAtWatched) &&
      isValid(endAtWatched) &&
      isBefore(startAtWatched, endAtWatched)
    )
      getPublicHolidays({
        variables: {
          input: {
            where: {
              date: {
                _in: eachDayOfInterval({
                  start: startAtWatched,
                  end: subMilliseconds(endAtWatched, 1),
                }).map((date) => format(date, 'yyyy-MM-dd')),
              },
              _or: [{ region: null }, { region: { _eq: region } }],
            },
            orderBy: [{ date: OrderBy.ASC }],
          },
        },
      });
  }, [getPublicHolidays, region, startAtWatched, endAtWatched]);

  return (
    <FormModal
      modalProps={modalProps}
      title="Add shift"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.groups}>
          {response.error && (
            <Typography className={classes.error}>
              {response.error.message}
            </Typography>
          )}
          <Box className={classes.group}>
            <Typography className={classes.bold}>Participant</Typography>
            <ProfileInput
              required
              control={control}
              name="client.id"
              chipProps={{ onDelete: () => setValue('client.id', '') }}
              formControlProps={{ variant: 'outlined', size: 'small' }}
              type={'client'}
              orderBy={[{ lastName: OrderBy.ASC }]}
              where={{
                branch: !!branch ? { id: { _eq: branch.id } } : undefined,
              }}
              watch={watch}
              className={classes.select}
              error={!!errors.client?.id?.message}
            />
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Passive overnight</Typography>
            <Box className={classes.passive}>
              <Box className={classes.groups}>
                <Box className={classes.group}>
                  <Checkbox
                    control={control}
                    name="passive"
                    label="This is an overnight shift"
                    onChange={handleTogglePassive}
                    color="primary"
                    error={!!errors.passive}
                    helperText={errors.passive?.message}
                  />
                </Box>
                {passive && (
                  <>
                    <Box className={classes.group}>
                      <Typography className={classes.bold}>
                        Start of 8 hour passive period
                      </Typography>
                      <Box className={classes.dates}>
                        <DateInput
                          name="passiveStartAt"
                          control={control}
                          inputVariant="outlined"
                          size="small"
                          onChange={handleChangePassiveStartAt}
                          error={!!errors.passiveStartAt}
                          helperText={errors.passiveStartAt?.message}
                        />
                        <TimeInput
                          keyboard
                          name="passiveStartAtTime"
                          control={control}
                          inputVariant="outlined"
                          size="small"
                          onChange={(date) => {
                            if (isValid(date))
                              handleChangePassiveStartAtTime(date);
                          }}
                          inputProps={{ style: { textAlign: 'center' } }}
                          error={!!errors.passiveStartAt}
                        />
                      </Box>
                    </Box>
                    <Box className={classes.group}>
                      <Checkbox
                        control={control}
                        name="activeAssist"
                        label="Passive period is active-assist"
                        color="primary"
                        error={!!errors.activeAssist}
                        helperText={errors.activeAssist?.message}
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>From</Typography>
            <Box className={classes.dates}>
              <DateInput
                required
                name="startAt"
                control={control}
                inputVariant="outlined"
                size="small"
                onChange={handleChangeStartAt}
                error={!!errors.startAt}
                helperText={errors.startAt?.message}
              />
              <TimeInput
                required
                keyboard
                name="startAtTime"
                control={control}
                inputVariant="outlined"
                size="small"
                onChange={(date) => {
                  if (isValid(date)) handleChangeStartAtTime(date);
                }}
                inputProps={{ style: { textAlign: 'center' } }}
                error={!!errors.startAt}
              />
            </Box>
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>To</Typography>
            <Box className={classes.dates}>
              <DateInput
                required
                name="endAt"
                control={control}
                inputVariant="outlined"
                size="small"
                onChange={handleChangeEndAt}
                error={!!errors.endAt}
                helperText={errors.endAt?.message}
              />
              <TimeInput
                required
                keyboard
                name="endAtTime"
                control={control}
                inputVariant="outlined"
                size="small"
                onChange={(date) => {
                  if (isValid(date)) handleChangeEndAtTime(date);
                }}
                inputProps={{ style: { textAlign: 'center' } }}
                error={!!errors.endAt}
              />
              <EventDurationInput
                name="duration"
                control={control}
                onChange={(e) => handleChangeEndAt(e.target.value)}
                variant="outlined"
                size="small"
                inputProps={{ style: { textAlign: 'center' } }}
                error={!!errors.duration}
                helperText={errors.duration?.message}
              />
            </Box>
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Support worker</Typography>
            {participant.id ? (
              <EventMemberInput
                control={control}
                name="member.id"
                chipProps={{ onDelete: () => setValue('member.id', '') }}
                formControlProps={{ variant: 'outlined', size: 'small' }}
                watch={watch}
                className={classes.select}
                clientId={getValues('client.id')}
                startAt={startAtWatched}
                endAt={endAtWatched}
                error={!!errors.member}
                requirements={{ car: requireCar }}
                setTime={setTime}
              />
            ) : (
              <Typography variant="body2">
                Please select a participant.
              </Typography>
            )}
          </Box>
        </Box>
        <Box className={classes.groups}>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Address</Typography>
            <AddressInput
              name="address"
              control={control}
              clearErrors={clearErrors}
              setValue={setValue}
              setError={setError}
              error={!!errors.address}
              className={classes.address}
              // helperText={errors.address?.message}
              placeholder="Address"
            />
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>
              Message for the support worker
            </Typography>
            <Textarea
              name="summary"
              control={control}
              minRows={3}
              placeholder="Short optional summary of the shift."
              className={classes.textarea}
              // error={!!errors.summary}
              // helperText={errors.summary?.message}
              validation={{
                maxLength: {
                  value: 1000,
                  message: 'Exceeding 1000 characters',
                },
              }}
            />
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Scheduling notes</Typography>
            <Textarea
              name="scheduleNotes"
              control={control}
              minRows={3}
              placeholder="These notes are only visible to schedulers."
              className={classes.textarea}
              // error={!!errors.scheduleNotes}
              // helperText={errors.scheduleNotes?.message}
              validation={{
                maxLength: {
                  value: 1000,
                  message: 'Exceeding 1000 characters',
                },
              }}
            />
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Public Holiday</Typography>
            {!region || !startAtWatched || !endAtWatched ? (
              <Typography>Please set address, dates and times.</Typography>
            ) : publicHolidaysResponse.loading ? (
              <Typography>Checking for public holidays...</Typography>
            ) : publicHolidaysResponse.data?.publicHolidays.length ? (
              <Typography>
                This event occurs during{' '}
                {pluralise({
                  singular: 'a public holiday',
                  plural: 'multiple public holidays',
                  quantity: publicHolidaysResponse.data?.publicHolidays.length,
                })}
                :{' '}
                {intersperse({
                  array: publicHolidaysResponse.data.publicHolidays.map(
                    ({ description, region }) =>
                      `${description}${region ? ' (' + region + ')' : ''}`,
                  ),
                })}
              </Typography>
            ) : (
              <>
                <Typography variant="body2">
                  No state-wide public holidays found.
                </Typography>
                <Checkbox
                  control={control}
                  name="publicHoliday"
                  label="This event occurs during a local public holiday"
                  error={!!errors.publicHoliday}
                  helperText={errors.publicHoliday?.message}
                />
              </>
            )}
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Options</Typography>
            <EventRequireCarCheckbox
              id="requireCar"
              name="requireCar"
              control={control}
              error={!!errors.requireCar}
              helperText={errors.requireCar?.message}
            />
            <Box>
              <EventBillClientCheckbox
                id="billable"
                name="billable"
                control={control}
                error={!!errors.billable}
                helperText={errors.billable?.message}
                onClick={() => {
                  setLastToggleCheckbox('billable');
                }}
              />
              <EventBillingRegistrationGroupInput
                id="billingRegistrationGroup"
                name="billingRegistrationGroup"
                control={control}
                error={!!errors.billingRegistrationGroup}
                helperText={errors.billingRegistrationGroup?.message}
                formControlProps={{ size: 'small', variant: 'outlined' }}
                style={{ width: 220 }}
              />
            </Box>
            <Box>
              <EventPayAttendeeCheckbox
                id="payable"
                name="payable"
                control={control}
                error={!!errors.payable}
                helperText={errors.payable?.message}
                onClick={() => {
                  setLastToggleCheckbox('payable');
                }}
              />
              <Protected admin>
                <EventBonusPayInput
                  id="bonusPay"
                  name="bonusPay"
                  control={control}
                  variant="outlined"
                  size="small"
                  style={{ width: 80 }}
                  error={!!errors.bonusPay}
                  helperText={errors.bonusPay?.message}
                />
              </Protected>
            </Box>
            <Protected admin>
              <Checkbox
                control={control}
                name="locked"
                defaultChecked={false}
                label="Lock shift"
                error={!!errors.locked}
                helperText={errors.locked?.message}
              />
            </Protected>
          </Box>
        </Box>
      </Box>
    </FormModal>
  );
};

export default EventCreateFormModal;

import { PaginationContextType } from '@timed/common';
import {
  EntityState,
  MemberListContextGetAggregatedMembersQuery,
  MemberListContextGetAggregatedRedactedMembersQuery,
  MemberListContextGetMembersExtraFieldsQuery,
  MembersOrderByInput,
  OrderBy,
} from '@timed/gql';
import { createContext, Dispatch, SetStateAction } from 'react';

export type MemberListContextInput = {
  /**
   * Filter by search.
   */
  search: string | null;
  /**
   * Set search.
   */
  setSearch: Dispatch<SetStateAction<string | null>>;
  /**
   * Filter by entity states.
   */
  entityStates: EntityState[];
  /**
   * Set entity states.
   */
  setEntityStates: Dispatch<SetStateAction<EntityState[]>>;
  /**
   * Ordering options.
   */
  orderBy: MembersOrderByInput[];
  /**
   * Set ordering options.
   */
  setOrderBy: Dispatch<SetStateAction<MembersOrderByInput[]>>;
};

export type MemberListContextType = PaginationContextType<
  | (MemberListContextGetAggregatedMembersQuery['membersAggregate']['nodes'][0] &
      MemberListContextGetMembersExtraFieldsQuery['members'][0])[]
  | MemberListContextGetAggregatedRedactedMembersQuery['redactedMembersAggregate']['nodes']
> & {
  /**
   * Reset filters to their defaults
   */
  reset: () => void;
  /**
   * Network loading state.
   */
  loading: boolean;
  /**
   * Network loading state.
   */
  loadingExtraFields: boolean;
  /**
   * Results filter.
   */
  input: MemberListContextInput;
};

const MemberListContext = createContext<MemberListContextType>({
  fetch: () => {},
  reset: () => {},
  offset: 0,
  setOffset: () => 0,
  limit: 100,
  loading: false,
  loadingExtraFields: false,
  input: {
    search: null,
    setSearch: () => {},
    entityStates: [EntityState.NORMAL],
    setEntityStates: () => {},
    orderBy: [{ lastName: OrderBy.ASC_NULLS_FIRST }],
    setOrderBy: () => {},
  },
});

export default MemberListContext;

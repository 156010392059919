import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { IconButton, useRouter } from '@timed/common';
import { _peopleClients } from '@timed/app';

const ClientViewCloseButton = () => {
  const { navigate } = useRouter();

  const handleClick = () => {
    navigate(_peopleClients.path);
  };

  return (
    <IconButton onClick={handleClick}>
      <CloseRoundedIcon />
    </IconButton>
  );
};

export default ClientViewCloseButton;

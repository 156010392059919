import {
  Box,
  createStyles,
  Fade,
  makeStyles,
  Popper,
  Theme,
  Typography,
} from '@material-ui/core';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { AppBarAccountBranchMenu } from '@timed/app-bar/components/Account/BranchMenu';
import { AppBarAccountOrgMenu } from '@timed/app-bar/components/Account/OrgMenu';
import { AppBarAccountUserMenu } from '@timed/app-bar/components/Account/UserMenu';
import { useAuth } from '@timed/auth';
import { Button, formatBusinessName } from '@timed/common';
import clsx from 'clsx';
import { useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      padding: theme.spacing(0, 4),
      height: theme.spacing(16),
      textAlign: 'start',
      textTransform: 'none',
      color: theme.palette.getContrastText(theme.palette.appBar.main),
      borderRadius: 0,
      [theme.breakpoints.down('sm')]: {
        height: theme.spacing(12),
      },
      backgroundColor: theme.palette.appBar.main,
      '&:hover': {
        backgroundColor: theme.palette.appBar.light,
      },
      '& p': {
        fontWeight: theme.typography.fontWeightMedium,
      },
      '& .MuiButton-label': {
        display: 'flex',
        gap: theme.spacing(4),
      },
    },
    buttonActive: {
      backgroundColor: theme.palette.appBar.light,
    },
    text: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      '& .MuiTypography-root': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    org: {
      fontSize: '16px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
    branch: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: '12px',
      gap: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
      },
    },
    links: {
      borderBottom: '1px solid white',
      backgroundColor: theme.palette.appBar.dark,
      color: theme.palette.getContrastText(theme.palette.appBar.main),
      textOverflow: 'ellipsis',
      display: 'flex',
      flexDirection: 'column',
    },
    link: {
      display: 'flex',
      padding: theme.spacing(2, 4),
      cursor: 'pointer',
      '& p': {
        [theme.breakpoints.up('sm')]: {
          fontSize: '16px',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
        },
      },
    },
    activeLink: {
      backgroundColor: theme.palette.appBar.light,
    },
  }),
);

type MenuType = 'main' | 'branch' | 'org';

const AppBarAccount = () => {
  const classes = useStyles();
  const { user, member, branch, branches, org, orgs, permissible } = useAuth();

  const [anchorMenuEl, setAnchorMenuEl] = useState<HTMLElement | null>(null);

  const [anchorBranchMenuEl, setAnchorBranchMenuEl] =
    useState<HTMLElement | null>(null);

  const [anchorOrgMenuEl, setAnchorOrgMenuEl] = useState<HTMLElement | null>(
    null,
  );

  const openMenu = Boolean(anchorMenuEl);

  const openBranchMenu = Boolean(anchorBranchMenuEl);

  const openOrgMenu = Boolean(anchorOrgMenuEl);

  const handleMouse = (
    menu: MenuType,
    action: 'enter' | 'leave',
    event: React.MouseEvent<HTMLElement>,
  ) => {
    let dispatch: React.Dispatch<React.SetStateAction<HTMLElement | null>>;

    switch (menu) {
      case 'main':
        dispatch = setAnchorMenuEl;
        break;
      case 'branch':
        dispatch = setAnchorBranchMenuEl;
        break;
      case 'org':
        dispatch = setAnchorOrgMenuEl;
        break;
    }

    switch (action) {
      case 'enter':
        dispatch(event.currentTarget);
        break;
      case 'leave':
        dispatch(null);
        break;
    }
  };

  /**
   * Close all menus
   */
  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    setAnchorMenuEl(null);
    setAnchorBranchMenuEl(null);
    setAnchorOrgMenuEl(null);
  };

  return (
    <Box
      onMouseOver={(event) => {
        handleMouse('main', 'enter', event);
      }}
      onMouseOut={(event) => {
        handleMouse('main', 'leave', event);
      }}
    >
      <Button
        id="account-menu-button"
        className={
          openMenu ? clsx(classes.button, classes.buttonActive) : classes.button
        }
        aria-controls={openMenu ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
      >
        <Box className={classes.text}>
          {!member && (
            <Typography className={classes.text}>My Account</Typography>
          )}
          {!!org && (
            <Typography noWrap variant="body2" className={classes.org}>
              {formatBusinessName(org, { preferred: true })}
            </Typography>
          )}
          {!!user && (
            <Typography noWrap variant="body2" className={classes.branch}>
              {branch?.name ?? (!!branches.length ? 'All branches' : '')}
              <span>
                {process.env.REACT_APP_VERSION?.split('-', 1)[0]}
                {(member?.id === 'e7ec9b20-ac74-4a11-9398-d01acbee4e14' ||
                  member?.id === 'd3640ba5-f22a-4f4a-b0f4-94e447ba6c80' ||
                  member?.id === 'b01b999b-a809-4d6d-a9fc-246b42f43d1c' ||
                  member?.id === '858d194b-b3c9-472f-9192-c01f9ff63a5a') &&
                  !permissible({ tester: true }) &&
                  '.'}
              </span>
            </Typography>
          )}
        </Box>
      </Button>
      <Popper
        transition
        open={openMenu}
        anchorEl={anchorMenuEl}
        role={undefined}
        style={{ zIndex: 1300 }}
        placement="bottom-start"
      >
        {({ TransitionProps }) => {
          return (
            <Fade {...TransitionProps} timeout={200}>
              <Box
                style={{
                  minWidth: document.getElementById('account-menu-button')
                    ?.offsetWidth,
                }}
              >
                {(branches.length > 1 || orgs.length > 1) && (
                  <Box className={classes.links}>
                    {branches.length > 1 && (
                      <Box
                        onMouseEnter={(event) => {
                          handleMouse('branch', 'enter', event);
                        }}
                        onMouseLeave={(event) => {
                          handleMouse('branch', 'leave', event);
                        }}
                        className={
                          openBranchMenu
                            ? clsx(classes.link, classes.activeLink)
                            : classes.link
                        }
                      >
                        <div
                          onMouseEnter={(event) => {
                            event.stopPropagation();
                          }}
                          style={{
                            flexGrow: 1,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Typography>Branches</Typography>
                          <ArrowRightRoundedIcon fontSize="small" />
                          <Popper
                            transition
                            open={openBranchMenu}
                            anchorEl={anchorBranchMenuEl}
                            role={undefined}
                            style={{ zIndex: 1300 }}
                            placement="right-start"
                          >
                            <AppBarAccountBranchMenu
                              handleClose={handleClose}
                            />
                          </Popper>
                        </div>
                      </Box>
                    )}
                    {orgs.length > 1 &&
                      !(
                        (member?.id ===
                          'e7ec9b20-ac74-4a11-9398-d01acbee4e14' ||
                          member?.id ===
                            'd3640ba5-f22a-4f4a-b0f4-94e447ba6c80') &&
                        !permissible({ tester: true })
                      ) && (
                        <Box
                          onMouseEnter={(event) => {
                            handleMouse('org', 'enter', event);
                          }}
                          onMouseLeave={(event) => {
                            handleMouse('org', 'leave', event);
                          }}
                          className={
                            openOrgMenu
                              ? clsx(classes.link, classes.activeLink)
                              : classes.link
                          }
                        >
                          <div
                            onMouseEnter={(event) => {
                              event.stopPropagation();
                            }}
                            style={{
                              flexGrow: 1,
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Typography>Organisations</Typography>
                            <ArrowRightRoundedIcon fontSize="small" />
                            <Popper
                              transition
                              open={openOrgMenu}
                              anchorEl={anchorOrgMenuEl}
                              role={undefined}
                              style={{ zIndex: 1300 }}
                              placement="right-start"
                            >
                              <AppBarAccountOrgMenu handleClose={handleClose} />
                            </Popper>
                          </div>
                        </Box>
                      )}
                  </Box>
                )}
                <AppBarAccountUserMenu />
              </Box>
            </Fade>
          );
        }}
      </Popper>
    </Box>
  );
};

export default AppBarAccount;

import { roundNumber } from '@timed/common';
import {
  EventsWithShiftsFn,
  eventWithShifts,
  FormattedShift,
  UnformattedEvent,
} from '@timed/event';
import {
  Event,
  HistoryRestorable,
  Maybe,
  Member,
  MemberBasePayRate,
  Org,
} from '@timed/gql';
import {
  calculatePayrollCategory,
  getBillingRate,
  getKmBillingRate,
} from '@timed/report';
import { BillingRate, PayrollCategory } from '@timed/report/constants';
import {
  areIntervalsOverlapping,
  differenceInMinutes,
  isAfter,
  max,
} from 'date-fns';
import _, { isEqual } from 'lodash';

export type PayRates = Pick<
  Org,
  | 'basePayRate'
  | 'passiveAllowance'
  | 'kmAllowance'
  | 'afternoonPayMultiplier'
  | 'nightPayMultiplier'
  | 'satPayMultiplier'
  | 'sunPayMultiplier'
  | 'pubPayMultiplier'
  | 'bonus2PayRate'
  | 'bonus3PayRate'
  | 'bonus4PayRate'
  | 'bonus5PayRate'
  | 'bonus6PayRate'
  | 'bonus7PayRate'
  | 'bonus8PayRate'
  | 'bonus9PayRate'
  | 'bonus10PayRate'
  | 'bonus11PayRate'
  | 'bonus12PayRate'
>;

type MemberWithPayRates = Pick<Member, 'id'> & {
  basePayRates: Pick<MemberBasePayRate, 'date' | 'value'>[];
};

/**
 * Payroll expenses
 */
export type PayrollExpense = {
  /**
   * Exepense in cents.
   */
  expense: number;
  category: PayrollCategory;
  shift?: FormattedShift;
  travelTime?: number;
};

/**
 * Billing revenue
 */
export type BillingRevenue = {
  /**
   * Revenue in cents.
   */
  revenue: number;
  billingRate: BillingRate;
  shift?: FormattedShift;
};

/**
 * Unformatted partial event entitiy.
 */
export type UnaccountedEvent = UnformattedEvent &
  Pick<Event, 'billable' | 'payable' | 'travelDistance' | 'travelTime'> & {
    member?: Maybe<Pick<Member, 'id' | 'bonusEligibleStartAt'>>;
    cancel?: Maybe<Pick<HistoryRestorable, 'id'>>;
  };

/**
 * Unformatted partial event entitiy.
 */
export type AccountedEvent<T> = T & {
  expenses: PayrollExpense[];
  revenue: BillingRevenue[];
  duration: number;
};

/**
 * Function properties.
 */
type AccountedEventsWithShiftsFn = Omit<EventsWithShiftsFn, 'event'> & {
  /**
   * Event entity to format
   */
  event: UnaccountedEvent;

  /**
   * Does the event occurs on a public holiday?
   */
  occursDuringPublicHoliday: boolean;

  /**
   * Pay rates
   */
  orgPayRates: PayRates;

  /**
   * Member pay rates
   */
  memberPayRates: MemberWithPayRates[];
};

type CalculateExpenseFn = {
  category: PayrollCategory;
  orgPayRates: PayRates;
  memberPayRate?: number;
  units: number;
  member?: Maybe<Pick<Member, 'bonusEligible'>>;
  eventDuration?: number;
};

/**
 * Calculate expense.
 */
const calculateExpense = ({
  category,
  member,
  orgPayRates,
  memberPayRate,
  units,
  eventDuration,
}: CalculateExpenseFn): number => {
  if (category === 'Km allowance')
    return roundNumber(((orgPayRates.kmAllowance ?? 0) * units) / 100, 2);

  if (
    category === 'overnight allowance' ||
    category === 'overnight allowance 2' ||
    category === 'Cancelled Overnight Allowance'
  )
    return roundNumber((orgPayRates.passiveAllowance ?? 0) / 100, 2);

  if (!eventDuration) throw new Error('Missing event duration');

  // Convert minutes into hours
  units = units / 60;
  eventDuration = eventDuration / 60;

  const bonus = member?.bonusEligible
    ? (eventDuration <= 2
        ? orgPayRates.bonus2PayRate
        : eventDuration <= 3
        ? orgPayRates.bonus3PayRate
        : eventDuration <= 4
        ? orgPayRates.bonus4PayRate
        : eventDuration <= 5
        ? orgPayRates.bonus5PayRate
        : eventDuration <= 6
        ? orgPayRates.bonus6PayRate
        : eventDuration <= 7
        ? orgPayRates.bonus7PayRate
        : eventDuration <= 8
        ? orgPayRates.bonus8PayRate
        : eventDuration <= 9
        ? orgPayRates.bonus9PayRate
        : eventDuration <= 10
        ? orgPayRates.bonus10PayRate
        : eventDuration <= 11
        ? orgPayRates.bonus11PayRate
        : eventDuration > 11
        ? orgPayRates.bonus12PayRate
        : 0) ?? 0
    : 0;

  let multiplier = 1;

  switch (category) {
    case 'Cancelled Afternoon':
    case 'L1P1 Afternoon':
    case 'L1P1 AFT BONUS 2':
    case 'L1P1 AFT BONUS 3':
    case 'L1P1 AFT BONUS 4':
    case 'L1P1 AFT BONUS 5':
    case 'L1P1 AFT BONUS 6':
    case 'L1P1 AFT BONUS 7':
    case 'L1P1 AFT BONUS 8':
    case 'L1P1 AFT BONUS 9':
    case 'L1P1 AFT BONUS 10':
    case 'L1P1 AFT BONUS 11':
    case 'L1P1 AFT BONUS 12':
      multiplier = (orgPayRates.afternoonPayMultiplier ?? 1) / 100;
      break;
    case 'Cancelled Night':
    case 'L1P1 Night':
    case 'L1P1 NHT BONUS 2':
    case 'L1P1 NHT BONUS 3':
    case 'L1P1 NHT BONUS 4':
    case 'L1P1 NHT BONUS 5':
    case 'L1P1 NHT BONUS 6':
    case 'L1P1 NHT BONUS 7':
    case 'L1P1 NHT BONUS 8':
    case 'L1P1 NHT BONUS 9':
    case 'L1P1 NHT BONUS 10':
    case 'L1P1 NHT BONUS 11':
    case 'L1P1 NHT BONUS 12':
      multiplier = (orgPayRates.nightPayMultiplier ?? 1) / 100;
      break;
    case 'Cancelled Saturday':
    case 'L1P1 Sat':
    case 'L1P1 SAT BONUS 2':
    case 'L1P1 SAT BONUS 3':
    case 'L1P1 SAT BONUS 4':
    case 'L1P1 SAT BONUS 5':
    case 'L1P1 SAT BONUS 6':
    case 'L1P1 SAT BONUS 7':
    case 'L1P1 SAT BONUS 8':
    case 'L1P1 SAT BONUS 9':
    case 'L1P1 SAT BONUS 10':
    case 'L1P1 SAT BONUS 11':
    case 'L1P1 SAT BONUS 12':
      multiplier = (orgPayRates.satPayMultiplier ?? 1) / 100;
      break;
    case 'Cancelled Sunday':
    case 'L1P1 Sun':
    case 'L1P1 SUN BONUS 2':
    case 'L1P1 SUN BONUS 3':
    case 'L1P1 SUN BONUS 4':
    case 'L1P1 SUN BONUS 5':
    case 'L1P1 SUN BONUS 6':
    case 'L1P1 SUN BONUS 7':
    case 'L1P1 SUN BONUS 8':
    case 'L1P1 SUN BONUS 9':
    case 'L1P1 SUN BONUS 10':
    case 'L1P1 SUN BONUS 11':
    case 'L1P1 SUN BONUS 12':
      multiplier = (orgPayRates.sunPayMultiplier ?? 1) / 100;
      break;
    case 'Cancelled Public Holiday':
    case 'L1P1 Pub':
    case 'L1P1 PUB BONUS 2':
    case 'L1P1 PUB BONUS 3':
    case 'L1P1 PUB BONUS 4':
    case 'L1P1 PUB BONUS 5':
    case 'L1P1 PUB BONUS 6':
    case 'L1P1 PUB BONUS 7':
    case 'L1P1 PUB BONUS 8':
    case 'L1P1 PUB BONUS 9':
    case 'L1P1 PUB BONUS 10':
    case 'L1P1 PUB BONUS 11':
    case 'L1P1 PUB BONUS 12':
      multiplier = (orgPayRates.pubPayMultiplier ?? 1) / 100;
      break;
  }

  if (!memberPayRate) console.log('No recorded pay rate: ', member);

  return roundNumber(
    (((memberPayRate ?? orgPayRates.basePayRate ?? 0) + bonus) / 100) *
      multiplier *
      units,
    2,
  );
};

/**
 * Format events as shifts.
 */
export const accountedEventWithShifts = <T extends Event>({
  event,
  dates,
  occursDuringPublicHoliday,
  orgPayRates,
  memberPayRates,
}: AccountedEventsWithShiftsFn): AccountedEvent<T> => {
  if (!event.startAt || !event.endAt) throw new Error('Missing event dates');

  const bonusSystemStartDate = !!event.member?.bonusEligibleStartAt
    ? max([new Date('2023-09-19'), new Date(event.member.bonusEligibleStartAt)])
    : null;

  // Assign default pay values, if necessary
  orgPayRates =
    orgPayRates ??
    Object.fromEntries(
      Object.entries(_.omit(orgPayRates, '__typename')).map(([k, v]) => [
        k,
        !v ? 0 : v,
      ]),
    );

  const { shifts } = eventWithShifts({ event, dates });

  let expenses: PayrollExpense[] = [];
  let revenue: BillingRevenue[] = [];

  if (!!shifts.length) {
    // Calculate expenses if attendee is payable
    if (event.payable) {
      expenses = shifts.map<PayrollExpense>((shift) => {
        const category = calculatePayrollCategory({
          occursDuringPublicHoliday,
          passive: shift.passive,
          eventStart: event.startAt,
          eventEnd: event.endAt,
          shiftStart: shift.startAt,
          shiftEnd: shift.endAt,
          memberBonusEligible:
            !!bonusSystemStartDate &&
            (isEqual(new Date(event.startAt), bonusSystemStartDate) ||
              isAfter(new Date(event.startAt), bonusSystemStartDate)),
        });

        return {
          shift,
          category,
          expense: calculateExpense({
            category,
            orgPayRates,
            memberPayRate:
              memberPayRates
                .find(({ id }) => id === event.member?.id)
                ?.basePayRates.find((payRate, i, self) => {
                  return areIntervalsOverlapping(
                    {
                      start: new Date(event.startAt),
                      end: new Date(event.endAt),
                    },
                    {
                      start: new Date(payRate.date),
                      end:
                        i < self.length - 1
                          ? new Date(self[i + 1].date)
                          : new Date('9999-12-31'),
                    },
                  );
                })?.value ?? 0,
            member: !!event.member
              ? {
                  ...event.member,
                  bonusEligible:
                    !!bonusSystemStartDate &&
                    (isEqual(new Date(event.startAt), bonusSystemStartDate) ||
                      isAfter(new Date(event.startAt), bonusSystemStartDate)),
                }
              : undefined,
            units: shift.duration,
            eventDuration: differenceInMinutes(
              new Date(event.endAt),
              new Date(event.startAt),
            ),
          }),
        };
      });
      // Calculate travel distance expenses
      if (event.travelDistance)
        expenses.push({
          category: 'Km allowance',
          expense: calculateExpense({
            category: 'Km allowance',
            orgPayRates,
            units: event.travelDistance / 1000,
          }),
        });

      // Calculate travel time expenses
      if (event.travelTime)
        expenses.push({
          category: expenses.find((expense) => !expense.shift?.passive)!
            .category,
          expense: calculateExpense({
            category: expenses.find((expense) => !expense.shift?.passive)!
              .category,
            orgPayRates,
            memberPayRate:
              memberPayRates
                .find(({ id }) => id === event.member?.id)
                ?.basePayRates.find((payRate, i, self) => {
                  return areIntervalsOverlapping(
                    {
                      start: new Date(event.startAt),
                      end: new Date(event.endAt),
                    },
                    {
                      start: new Date(payRate.date),
                      end:
                        i < self.length - 1
                          ? new Date(self[i + 1].date)
                          : new Date('9999-12-31'),
                    },
                  );
                })?.value ?? 0,
            member: !!event.member
              ? {
                  ...event.member,
                  bonusEligible:
                    !!bonusSystemStartDate &&
                    (isEqual(new Date(event.startAt), bonusSystemStartDate) ||
                      isAfter(new Date(event.startAt), bonusSystemStartDate)),
                }
              : undefined,
            units: event.travelTime,
            eventDuration: differenceInMinutes(
              new Date(event.endAt),
              new Date(event.startAt),
            ),
          }),
        });
    }

    // Calculate revenue if client is billable
    if (event.billable) {
      revenue = shifts.map<BillingRevenue>((shift) => {
        const billingRate = getBillingRate(
          { ...event, passive: shift.passive },
          occursDuringPublicHoliday,
        )!;

        return {
          shift,
          billingRate,
          revenue: billingRate.rate * (shift.duration / 60),
        };
      });

      // Calculate travel distance expenses
      if (!!event.travelDistance) {
        const billingRate = getKmBillingRate(event)!;
        revenue.push({
          billingRate,
          revenue: billingRate.rate * (event.travelDistance / 1000),
        });
      }

      // Calculate travel time expenses
      if (event.travelTime) {
        const billingRate = getBillingRate(event, occursDuringPublicHoliday)!;
        revenue.push({
          billingRate,
          revenue: billingRate.rate * (event.travelTime / 60),
        });
      }
    }
  }

  return {
    ...event,
    expenses,
    revenue,
    duration: differenceInMinutes(
      new Date(event.endAt),
      new Date(event.startAt),
    ),
  } as AccountedEvent<T>;
};

import {
  Box,
  ClickAwayListener,
  createStyles,
  Fade,
  makeStyles,
  Popper,
  Theme,
  Typography,
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import { _schedule } from '@timed/app';
import { useAuth } from '@timed/auth';
import { Button, formatPersonName, useRouter } from '@timed/common';
import { pluralise } from '@timed/common/utils/pluralise';
import { OrderBy, useGetNonClockedEventsLazyQuery } from '@timed/gql';
import { ScheduleContext } from '@timed/schedule';
import { setProfile } from '@timed/schedule/helpers';
import { format, isToday, startOfWeek, subWeeks } from 'date-fns';
import { isTomorrow } from 'date-fns/esm';
import { useContext, useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      padding: theme.spacing(1.75),
      backgroundColor: blue[50],
      border: '1px solid ' + blue[800],
      borderRadius: 0,
      textAlign: 'start',
      textTransform: 'none',
      '& .MuiButton-label': {
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'start',
      },
      '&:hover': {
        backgroundColor: blue[100],
      },
      '& .MuiTypography-root': {
        fontSize: 10,
        lineHeight: 1.1,
      },
      '& .MuiTypography-root:first-child': {
        fontSize: 11,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    list: {
      maxHeight: 200,
      overflowY: 'auto',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
    },
    item: {
      padding: theme.spacing(0.5),
      backgroundColor: blue[50],
      borderLeft: '1px solid ' + blue[800],
      borderRight: '1px solid ' + blue[800],
      borderBottom: '1px solid ' + blue[800],
      '&:hover': {
        backgroundColor: blue[100],
      },
      '& .MuiTypography-root': {
        fontSize: 10,
      },
      '& .MuiTypography-root:first-child': {
        fontSize: 11,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  }),
);

const ScheduleNonClockedEvents = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const open = Boolean(anchorEl);

  const {
    navigate,
    search: [searchParams],
  } = useRouter();

  const { branch } = useAuth();

  const {
    dates: { from, to, now },
  } = useContext(ScheduleContext);

  const [getEvents, { data }] = useGetNonClockedEventsLazyQuery({
    // pollInterval: 60000,
    fetchPolicy: 'network-only',
  });

  /**
   * Toggle menu display
   */
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  /**
   * Close menu
   */
  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getEvents({
      variables: {
        input: {
          where: {
            client: branch ? { branch: { id: { _eq: branch.id } } } : undefined,
            billable: { _eq: true },
            member: { id: { _ne: null } },
            startAt: {
              _gte: subWeeks(startOfWeek(new Date()), 1),
              _lt: now,
            },
            _or: [
              { clockedOnAt: { _eq: null } },
              { clockedOffAt: { _eq: null }, endAt: { _lt: new Date() } },
            ],
          },
          orderBy: [{ startAt: OrderBy.ASC }, { duration: OrderBy.ASC }],
        },
      },
    });
  }, [getEvents, from, to, now, branch]);

  const handleClickLink = (
    startAt: Date,
    clientId: string,
    memberId?: string,
  ) => {
    setAnchorEl(null);

    setProfile('client', searchParams, clientId);
    setProfile('member', searchParams, memberId);

    const formattedDate = format(
      startOfWeek(startAt, { weekStartsOn: 1 }),
      'ddMMyyyy',
    );

    if (searchParams.get('f') !== formattedDate) {
      searchParams.set('f', formattedDate);
    }

    navigate(_schedule.path + '?' + searchParams);
  };

  return !data?.events.length ? null : (
    <>
      <Button
        id="non-clocked-shifts-menu-button"
        className={classes.button}
        onClick={handleClick}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
      >
        <ErrorRoundedIcon style={{ fill: blue[800] }} />

        <Box>
          <Typography noWrap>Non-clocked</Typography>
          <Typography noWrap>
            {data?.events.length}{' '}
            {pluralise({
              quantity: data.events.length,
              plural: 'shifts',
              singular: 'shift',
            })}
          </Typography>
        </Box>
      </Button>
      <Popper
        transition
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        style={{ zIndex: 1300 }}
        placement="bottom"
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <Box>
              <ClickAwayListener onClickAway={handleClose}>
                <Box
                  className={classes.list}
                  style={{
                    width: document.getElementById(
                      'non-clocked-shifts-menu-button',
                    )?.offsetWidth,
                  }}
                >
                  {data?.events.map((event) => (
                    <Box
                      className={classes.item}
                      onClick={(e) => {
                        handleClickLink(
                          new Date(event.startAt),
                          event.client.id,
                          event.member?.id,
                        );
                        e.stopPropagation();
                      }}
                    >
                      <Typography>
                        {isToday(new Date(event.startAt))
                          ? 'Today'
                          : isTomorrow(new Date(event.startAt))
                          ? 'Tomorrow'
                          : format(new Date(event.startAt), 'EEE')}
                        {', '}
                        {format(new Date(event.startAt), 'd MMM')}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {formatPersonName(event.member!, {
                          lastNameFirst: true,
                          capitaliseLastName: true,
                        })}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </ClickAwayListener>
            </Box>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default ScheduleNonClockedEvents;

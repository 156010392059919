import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { green, red, yellow } from '@material-ui/core/colors';
import { useAlert } from '@timed/alert';
import { useAuth } from '@timed/auth';
import {
  Button,
  Checkbox,
  DateInput,
  formatPersonName,
  jsDateToLocalISO8601DateString,
  roundNumber,
  Select,
  SelectMenuItem,
} from '@timed/common';
import {
  Event,
  EventsWhereInput,
  Member,
  OrderBy,
  PersonNamesFragment,
  useGetActivitySlipsLazyQuery,
  useGetCurrentOrgPayrollSettingsLazyQuery,
  useGetEventsMembersPaySlipsDataLazyQuery,
  useGetPublicHolidaysLazyQuery,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import ReportMemberPaySlip from '@timed/report/components/MemberPaySlip/MemberPaySlip';
import { PayrollCategory } from '@timed/report/constants';
import { generatePaySlip } from '@timed/report/helpers/generatePaySlip';
import clsx from 'clsx';
import { isMonday, startOfWeek, subMilliseconds, subWeeks } from 'date-fns';
import addDays from 'date-fns/addDays';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactToPrint from 'react-to-print';

type FormData = {
  member: string;
  period: 'previous' | 'current' | 'custom' | undefined;
  date?: Date;
  showShifts?: boolean;
  // from?: Date;
  // to?: Date;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    grid: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      gridTemplateColumns: 'auto auto',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(4),
    },
    info: {
      padding: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    warning: {
      backgroundColor: yellow[50],
      border: '1px solid ' + yellow[100],
    },
    error: {
      backgroundColor: red[50],
      border: '1px solid ' + red[100],
    },
    success: {
      backgroundColor: green[50],
      border: '1px solid ' + green[100],
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    paySlip: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      paddingTop: theme.spacing(4),
    },
    paySlipDescription: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: theme.spacing(1),
    },
    paySlipDescriptionLeft: {
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    paySlipDescriptionRight: {
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      textAlign: 'right',
    },
    paySlipData: {
      border: '1px solid ' + theme.palette.divider,
      padding: theme.spacing(2),
      display: 'grid',
      gridTemplateColumns: 'auto auto auto auto auto',
      gap: theme.spacing(1),
    },
  }),
);

export type PaySlip = {
  category: PayrollCategory | 'Super Expenses';
  description: string;
  hours?: number;
  rate?: number;
  pay: number;
  type: 'Wages' | 'Tax' | 'Super Expenses';
  event?: Pick<Event, 'startAt' | 'endAt'>;
};

const ReportMemberPaySlipForm = () => {
  const classes = useStyles();

  const { branch } = useAuth();

  const paySlipRef = useRef<HTMLDivElement | null>(null);

  const WEEK_STARTS_ON = 1; // MONDAY

  const [items, setItems] = useState<Array<PaySlip> | undefined>(undefined);

  const [itemsYTD, setItemsYTD] = useState<Array<PaySlip> | undefined>(
    undefined,
  );

  const { control, watch, setValue } = useForm<FormData>({
    defaultValues: { period: 'previous', showShifts: false },
  });

  const alert = useAlert();

  const period = watch('period');
  const date = watch('date');
  const member = watch('member');
  const showShifts = watch('showShifts');

  const startOfFinancialYear = useMemo(() => {
    return !!date ? new Date(date.getFullYear(), 6, 1) : null;
  }, [date]);

  const periods = useMemo<SelectMenuItem[]>(
    () => [
      { label: 'Most recently ended payroll period', value: 'previous' },
      { label: 'Current payroll period', value: 'current' },
      { label: 'Custom date', value: 'custom' },
    ],
    [],
  );

  const [getMembers, membersResposne] =
    useGetEventsMembersPaySlipsDataLazyQuery({
      fetchPolicy: 'network-only',
    });

  const [getOrgPayRates, orgPayRatesResposne] =
    useGetCurrentOrgPayrollSettingsLazyQuery({
      fetchPolicy: 'network-only',
    });

  const [getEvents, { data, loading, error }] = useGetActivitySlipsLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [getEventsYearToDate, eventsYearToDateResponse] =
    useGetActivitySlipsLazyQuery({
      fetchPolicy: 'network-only',
    });

  const [getPublicHolidays, publicHolidaysResponse] =
    useGetPublicHolidaysLazyQuery({
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    error &&
      alert.push({
        message: error.graphQLErrors[0].message,
        severity: 'error',
      });
  }, [error, alert]);

  useLoadingEffect(
    loading || membersResposne.loading || publicHolidaysResponse.loading,
  );

  useEffect(() => {
    if (period) {
      switch (period) {
        case 'current':
          setValue(
            'date',
            startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
          );
          break;
        case 'previous':
          setValue(
            'date',
            subWeeks(
              startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
              1,
            ),
          );
          break;
        case 'custom':
          setValue(
            'date',
            startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
          );
          break;
      }
    }
  }, [period, setValue]);

  /**
   * Fetch org pay rates
   */
  useEffect(() => {
    getOrgPayRates();
  }, [getOrgPayRates]);

  /**
   * Fetch members
   */
  useEffect(() => {
    if (!!date)
      getMembers({
        variables: {
          input: {
            where: {
              startAt: { _lte: addDays(date, 7) },
              endAt: { _gte: date },
              member: { schedulable: { _eq: true } },
              payable: { _eq: true },
              client: {
                firstName: { _ne: 'Reminders' },
                branch: !!branch ? { id: { _eq: branch.id } } : undefined,
              },
            },
            orderBy: [
              { member: { lastName: OrderBy.ASC_NULLS_FIRST } },
              { member: { firstName: OrderBy.ASC } },
            ],
          },
        },
      });
  }, [branch, getMembers, date]);

  /**
   * Unique selectable members.
   */
  const selectableMembers = useMemo<
    [
      string,
      Pick<Member, 'id' | 'currentHourlyBasePayRate'> & PersonNamesFragment,
    ][]
  >(() => {
    return !!membersResposne.data?.events.length
      ? [
          ...new Map<string, any>(
            membersResposne.data.events
              .filter(({ member }) => !!member)
              .map(({ member }) => member)
              .map((arr) => [arr!['id'], arr]),
          ),
        ]
      : [];
  }, [membersResposne.data]);

  /**
   * Selectable members with errors.
   */
  const [membersMissingNotes, membersNotClocked] = useMemo(() => {
    if (!!selectableMembers.length) {
      const list: (Pick<Member, 'id'> & PersonNamesFragment)[][] = [[], []];

      selectableMembers.forEach(([id, m]) => {
        if (!!m) {
          if (
            !!membersResposne
              // Get this member's shifts
              .data!.events.filter(({ member }) => member!.id === id)
              .some(({ hasNotes }) => !hasNotes)
          )
            list[0].push(m);
        }
      });

      selectableMembers.forEach(([id, m]) => {
        if (!!m) {
          if (
            !!membersResposne
              // Get this member's shifts
              .data!.events.filter(({ member }) => member!.id === id)
              .some(
                ({ clockedOffAt, clockedOnAt }) =>
                  !clockedOnAt || !clockedOffAt,
              )
          )
            list[1].push(m);
        }
      });

      return [list[0], list[1]];
    }

    return [[], []];
  }, [selectableMembers, membersResposne.data]);

  const selectedMember = useMemo(() => {
    return !!selectableMembers && !!member
      ? selectableMembers.find(([id]) => id === member)!
      : undefined;
  }, [selectableMembers, member]);

  /**
   * Fetch events and public holidays
   */
  const fetchData = useCallback(() => {
    if (date && startOfFinancialYear && !!member) {
      console.log('loading lots ');

      const where: EventsWhereInput = {
        endAt: { _gte: date },
        startAt: { _lte: addDays(date, 8) },
        payable: { _eq: true },
        member: { id: { _eq: member } },
        client: { firstName: { _ne: 'Reminders' } },
      };

      getEvents({
        variables: {
          input: {
            where,
            orderBy: [{ startAt: OrderBy.ASC }],
          },
        },
      });

      getEventsYearToDate({
        variables: {
          input: {
            where: {
              endAt: { _gte: startOfFinancialYear },
              startAt: { _lte: addDays(date, 8) },
              payable: { _eq: true },
              member: { id: { _eq: member } },
              client: { firstName: { _ne: 'Reminders' } },
            },
            orderBy: [{ startAt: OrderBy.ASC }],
          },
        },
      });

      getPublicHolidays({
        variables: {
          input: {
            orderBy: [{ date: OrderBy.ASC }],
            where: {
              date: {
                _gte: jsDateToLocalISO8601DateString(date),
                _lte: jsDateToLocalISO8601DateString(
                  addDays(subMilliseconds(date, 1), 7),
                ),
              },
            },
          },
        },
      });
    }
  }, [
    date,
    getEvents,
    getEventsYearToDate,
    getPublicHolidays,
    member,
    startOfFinancialYear,
  ]);

  /**
   * Generate pay slip data
   */
  useEffect(() => {
    if (
      !!selectedMember &&
      !!data &&
      !!publicHolidaysResponse.data &&
      !!orgPayRatesResposne.data &&
      !!membersResposne.data &&
      date
    ) {
      // setReportData([{ category: 'Base Hourly', hours: 1, rate: 10, pay: 100, type: 'Wages' }]);

      setItems(
        generatePaySlip({
          after: date,
          before: addDays(date, 8),
          events: data.events,
          publicHolidays: publicHolidaysResponse.data?.publicHolidays,
          payRates: orgPayRatesResposne.data.me.member!.org,
          basePayRate:
            membersResposne.data.events.find(
              ({ member }) => member!.id === selectedMember[0],
            )!.member!.currentHourlyBasePayRate ??
            orgPayRatesResposne.data.me.member!.org.basePayRate ??
            0,
        }),
      );
    }
  }, [
    alert,
    data,
    selectedMember,
    membersResposne.data,
    publicHolidaysResponse.data,
    orgPayRatesResposne.data,
    date,
  ]);

  /**
   * Generate YTD pay slip data
   */
  useEffect(() => {
    if (
      !!selectedMember &&
      !!eventsYearToDateResponse.data &&
      !!publicHolidaysResponse.data &&
      !!orgPayRatesResposne.data &&
      !!membersResposne.data &&
      date &&
      startOfFinancialYear
    ) {
      // setReportData([{ category: 'Base Hourly', hours: 1, rate: 10, pay: 100, type: 'Wages' }]);

      setItemsYTD(
        generatePaySlip({
          after: startOfFinancialYear,
          before: addDays(date, 8),
          events: eventsYearToDateResponse.data.events,
          publicHolidays: publicHolidaysResponse.data?.publicHolidays,
          payRates: orgPayRatesResposne.data.me.member!.org,
          basePayRate:
            membersResposne.data.events.find(
              ({ member }) => member!.id === selectedMember[0],
            )!.member!.currentHourlyBasePayRate ??
            orgPayRatesResposne.data.me.member!.org.basePayRate ??
            0,
        }),
      );
    }
  }, [
    alert,
    eventsYearToDateResponse.data,
    selectedMember,
    membersResposne.data,
    publicHolidaysResponse.data,
    orgPayRatesResposne.data,
    date,
    startOfFinancialYear,
  ]);

  return (
    <Box>
      <div className={classes.grid}>
        <Box className={classes.row}>
          <Select
            control={control}
            name="period"
            label="Reporting period"
            items={periods}
            formControlProps={{ variant: 'outlined', size: 'small' }}
          />
          {period && (
            <Box className={classes.row}>
              <DateInput
                disableToolbar
                autoOk
                control={control}
                name="date"
                label="Payroll Period Date"
                inputVariant="outlined"
                size="small"
                disabled={period === 'current' || period === 'previous'}
                shouldDisableDate={(date) => !!date && !isMonday(date)}
              />
            </Box>
          )}
        </Box>
        {!!period && !!selectableMembers && (
          <Box className={classes.row}>
            <Select
              control={control}
              name="member"
              label="Employee"
              items={selectableMembers.map(([id, member]) => ({
                label: formatPersonName(member, { lastNameFirst: true }),
                value: id,
              }))}
              formControlProps={{
                variant: 'outlined',
                size: 'small',
                style: { width: 200 },
              }}
              renderValue={(value) =>
                !!selectableMembers.find(([id]) => id === value)
                  ? formatPersonName(
                      selectableMembers.find(([id]) => id === value)![1],
                      {
                        lastNameFirst: true,
                        capitaliseLastName: true,
                      },
                    )
                  : 'No one'
              }
            />
          </Box>
        )}

        {!!membersResposne.data &&
          !membersResposne.loading &&
          !membersMissingNotes?.length &&
          !membersNotClocked?.length && (
            <Box className={clsx(classes.info, classes.success)}>
              All Support workers have completed their case notes and
              successfully clocked on and off for each of their shifts.
            </Box>
          )}

        {!!membersResposne.data &&
          !membersResposne.loading &&
          !!membersMissingNotes?.length && (
            <Box className={clsx(classes.info, classes.error)}>
              <Typography className={classes.bold}>
                Missing case notes:
              </Typography>
              {membersMissingNotes.map((member) => (
                <Typography variant="body2" color="textSecondary">
                  -{' '}
                  {formatPersonName(member, {
                    capitaliseLastName: true,
                    lastNameFirst: true,
                  })}
                </Typography>
              ))}
            </Box>
          )}

        {!!membersResposne.data &&
          !membersResposne.loading &&
          !!membersNotClocked?.length && (
            <Box className={clsx(classes.info, classes.error)}>
              <Typography className={classes.bold}>
                Not clocked on/off:
              </Typography>
              {membersNotClocked.map((member) => (
                <Typography variant="body2" color="textSecondary">
                  -{' '}
                  {formatPersonName(member, {
                    capitaliseLastName: true,
                    lastNameFirst: true,
                  })}
                </Typography>
              ))}
            </Box>
          )}

        <Box className={classes.row}>
          <Checkbox
            control={control}
            name="showShifts"
            label="Show shifts on pay slip"
          />
        </Box>

        <Box className={classes.row}>
          <Button
            variant="contained"
            color="primary"
            disabled={loading || !date || !member}
            onClick={() => {
              fetchData();
            }}
          >
            Generate Payslip
          </Button>
          {!!selectedMember && !!date && items && !loading && (
            <ReactToPrint
              trigger={() => (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading || !date || !member}
                >
                  Print Payslip
                </Button>
              )}
              content={() => paySlipRef.current}
            />
          )}
        </Box>
        <Box></Box>
      </div>
      {!!selectedMember && !!date && items && itemsYTD && !loading && (
        <>
          <style type="text/css" media="print">
            {' @page { margin: 64px !important; } '}
          </style>
          <div ref={paySlipRef}>
            <ReportMemberPaySlip
              showShifts={showShifts}
              date={date}
              member={selectedMember[1]}
              items={items}
              itemsYTD={itemsYTD}
              basePayRate={roundNumber(
                (selectedMember[1].currentHourlyBasePayRate ??
                  orgPayRatesResposne.data!.me.member!.org.basePayRate ??
                  0) / 100,
                2,
              ).toFixed(2)}
            />
          </div>
        </>
      )}
    </Box>
  );
};

export default ReportMemberPaySlipForm;

import {
  Box,
  BoxProps,
  createStyles,
  Hidden,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import AlarmOnRoundedIcon from '@material-ui/icons/AlarmOnRounded';
import CakeIcon from '@material-ui/icons/Cake';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import FaceRoundedIcon from '@material-ui/icons/FaceRounded';
import FlagIcon from '@material-ui/icons/Flag';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import HotelIcon from '@material-ui/icons/Hotel';
import LockIcon from '@material-ui/icons/Lock';
import { Protected, useAuth } from '@timed/auth';
import { contrastingColor, hexToRGB } from '@timed/common';
import { EventSummaryModal2 } from '@timed/event';
import { ScheduleContext, Shift } from '@timed/schedule';
import clsx from 'clsx';
import {
  differenceInMinutes,
  format,
  formatDuration,
  getHours,
  getMinutes,
  isBefore,
  isSameDay,
  startOfWeek,
} from 'date-fns';
import { debounce, isNumber } from 'lodash';
import { useModal } from 'mui-modal-provider';
import { useCallback, useContext, useMemo, useState } from 'react';

type ScheduleCalendarEventProps = Shift;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      overflow: 'hidden',
      border: 'thin solid ' + theme.palette.divider,
      lineHeight: '13px',
      backgroundColor: '#fafafa',
      position: 'absolute',
      pointerEvents: 'all',
    },
    passiveOverlay: {
      display: 'block',
      position: 'absolute',
      width: '100%',
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 8 8'%3E%3Cg fill='%23000000' fill-opacity='0.15'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E")`,
    },
    text: {
      position: 'relative',
      padding: theme.spacing(0.25),
      '& div:first-child': {
        fontWeight: 'bold',
      },
      '& div:nth-child(-n+3)': {
        fontSize: 12,
      },
      '& div:nth-child(n+3)': {
        fontSize: 10,
      },
    },
    conflictIndicator: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      border: '1px solid white',
      display: 'inline-block',
      marginRight: theme.spacing(0.25),
      '&::after': {
        position: 'absolute',
        width: 8,
        height: 8,
        borderRadius: '50%',
        animation: '$circle 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    conflictIndicatorPosition1: {
      '&::after': {
        top: 4,
        left: 1,
      },
    },
    conflictIndicatorPosition2: {
      '&::after': {
        top: 4,
        left: 10,
      },
    },
    eventConflictIndicator: {
      backgroundColor: red[500],
      '&::after': {
        backgroundColor: red[500],
      },
    },
    unavailableConflictIndicator: {
      backgroundColor: theme.palette.common.black,
      '&::after': {
        backgroundColor: theme.palette.common.black,
      },
    },
    icons: {
      display: 'flex',
      gap: theme.spacing(0.25),
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    '@keyframes circle': {
      '0%': {
        transform: 'scale(1)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(4)',
        opacity: 0,
      },
    },
  }),
);

const ScheduleCalendarEvent = (props: ScheduleCalendarEventProps) => {
  const classes = useStyles();
  const auth = useAuth();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const iOS = /iPad|iPhone|iPod/.test(navigator.platform);

  const { permissible } = useAuth();

  const [isHovered, setIsHovered] = useState(false);

  const { setEvent, setTime } = useContext(ScheduleContext);

  const { showModal } = useModal();

  const [passiveDuration, durationBeforePassive] = useMemo(() => {
    if (!props.event.passiveStartAt || !props.event.passiveEndAt) return [];

    const passiveDuration = differenceInMinutes(
      props.event.passiveEndAt,
      props.event.passiveStartAt,
    );

    const durationBeforePassive = differenceInMinutes(
      props.event.passiveStartAt,
      props.startAt,
    );

    return props.event.passiveStartAt && props.event.passiveEndAt
      ? [passiveDuration, durationBeforePassive]
      : [];
  }, [props.event.passiveEndAt, props.event.passiveStartAt, props.startAt]);

  const handleOpenModal = useCallback(() => {
    const modal: { hide: () => void } = showModal(EventSummaryModal2, {
      onClose: () => {
        modal.hide();
      },
      auth,
      eventId: props.event.id,
      color: props.event.color,
      selectedPayrollPeriod: startOfWeek(props.startAt, { weekStartsOn: 1 }),
      setTime,
    });
  }, [
    auth,
    props.event.color,
    props.event.id,
    props.startAt,
    setTime,
    showModal,
  ]);

  const calcTime = () => {
    const startMinutes = getMinutes(props.event.startAt);
    const endMinutes = getMinutes(props.event.endAt);

    const sameAMPM =
      (getHours(props.event.startAt) < 12 &&
        getHours(props.event.endAt) < 12) ||
      (getHours(props.event.startAt) > 12 && getHours(props.event.endAt) > 12);

    let prefix = '',
      suffix = '';

    if (startMinutes === 0) {
      if (sameAMPM && isSameDay(props.event.startAt, props.event.endAt)) {
        prefix = format(props.event.startAt, 'h').toLowerCase();
      } else {
        prefix = format(props.event.startAt, smDown ? 'h' : 'ha').toLowerCase();
      }
    } else {
      // Minutes === 01-59
      if (sameAMPM && isSameDay(props.event.startAt, props.event.endAt)) {
        prefix = format(props.event.startAt, 'h:mm').toLowerCase();
      } else {
        prefix = format(
          props.event.startAt,
          smDown ? 'h:mm' : 'h:mma',
        ).toLowerCase();
      }
    }

    if (endMinutes === 0) {
      if (sameAMPM && isSameDay(props.event.startAt, props.event.endAt)) {
        suffix = format(props.event.endAt, smDown ? 'h' : 'ha').toLowerCase();
      } else {
        suffix = format(props.event.endAt, smDown ? 'h' : 'ha').toLowerCase();
      }
    } else {
      // Minutes === 01-59
      if (sameAMPM && isSameDay(props.event.startAt, props.event.endAt)) {
        suffix = format(
          props.event.endAt,
          smDown ? 'h:mm' : 'h:mma',
        ).toLowerCase();
      } else {
        suffix = format(
          props.event.endAt,
          smDown ? 'h:mm' : 'h:mma',
        ).toLowerCase();
      }
    }

    return prefix + '-' + suffix;
  };

  const calcDuration = () => {
    return formatDuration(
      {
        hours: Math.floor(
          differenceInMinutes(props.event.endAt, props.event.startAt) / 60,
        ),
        minutes:
          differenceInMinutes(props.event.endAt, props.event.startAt) % 60,
      },
      { format: ['hours', 'minutes'], delimiter: ', ' },
    )
      .replace('hours', 'hrs')
      .replace('minutes', 'mins');
  };

  const calcPassiveStyle = (): React.CSSProperties => ({
    height: `calc(${(passiveDuration! / props.duration) * 100}% )`,
    top: isBefore(props.startAt, props.event.passiveStartAt)
      ? `calc(${(durationBeforePassive! / props.duration) * 100}%  )`
      : undefined,
    borderTop: durationBeforePassive
      ? '1px solid rgba(0, 0, 0, 0.3)'
      : undefined,
    borderBottom:
      props.event.passiveEndAt &&
      isBefore(props.event.passiveEndAt, props.endAt)
        ? '1px solid rgba(0, 0, 0, 0.3)'
        : undefined,
    backgroundColor:
      contrastingColor(hexToRGB(props.event.style.backgroundColor || '#fff'))! +
      '1f',
  });

  const handleClick = useCallback(() => handleOpenModal(), [handleOpenModal]);

  const debouncedHandleMouseEnter = debounce(() => setIsHovered(true), 500);

  const handleMouse = useCallback(
    ({ type }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      switch (type) {
        case 'mouseover':
          // Set hovered event id
          setEvent({ type: 'over', value: { id: props.event.id } });
          debouncedHandleMouseEnter();
          break;
        case 'mouseout':
          // Unset hovered event id
          setEvent({ type: 'over' });
          setIsHovered(false);
          debouncedHandleMouseEnter.cancel();
          break;
      }
    },
    [props.event.id, setEvent, debouncedHandleMouseEnter],
  );

  const interactionEvents = useMemo<BoxProps>(() => {
    return iOS
      ? { onClick: handleClick }
      : {
          onClick: handleClick,
          onMouseOver: handleMouse,
          onMouseOut: handleMouse,
        };
  }, [iOS, handleMouse, handleClick]);

  return (
    <Box
      {...interactionEvents}
      draggable
      className={classes.wrapper}
      style={{
        ...props.event.style,
        top: props.topOffset + '%',
        left: props.leftOffset + '%',
        width: props.width + '%',
        height: props.height + '%',
        cursor: 'pointer',
      }}
    >
      {props.event.passive &&
        !props.event.activeAssist &&
        isNumber(durationBeforePassive) && (
          <div
            className={classes.passiveOverlay}
            style={calcPassiveStyle()}
          ></div>
        )}
      <div className={classes.text}>
        <div>
          <span>
            {props.event.eventConflictExists && (
              <div
                className={clsx(
                  classes.conflictIndicator,
                  classes.eventConflictIndicator,
                  classes.conflictIndicatorPosition1,
                )}
              />
            )}
            {props.event.unavailableConflictExists && (
              <div
                className={clsx(
                  classes.conflictIndicator,
                  classes.unavailableConflictIndicator,
                  classes[
                    `conflictIndicatorPosition${
                      !!props.event.eventConflictExists ? 2 : 1
                    }`
                  ],
                )}
              />
            )}

            {props.event.title}
          </span>
        </div>
        <div>
          <span>{calcTime()}</span>
        </div>
        <Hidden smDown>
          <div>
            <span>{calcDuration()}</span>
          </div>
        </Hidden>
        {props.event.cancelled && (
          <div>
            <span
              style={{
                fontWeight: theme.typography.fontWeightBold,
                color: theme.palette.error.main,
              }}
            >
              Cancelled
            </span>
          </div>
        )}

        {(props.event.clockedOn ||
          props.event.locked ||
          props.event.passive ||
          props.event.hasNotes ||
          props.event.hasFiles ||
          props.event.hasSeizureObservation ||
          props.event.publicHoliday ||
          props.event.travelTime ||
          (props.event.bonusPay && permissible({ admin: true })) ||
          props.event.memberAssignedAutomatically) && (
          <div className={classes.icons}>
            {props.event.locked && <LockIcon style={{ fontSize: 12 }} />}
            {props.event.clockedOn && (
              <AlarmOnRoundedIcon style={{ fontSize: 12 }} />
            )}
            {props.event.passive && !props.event.activeAssist && (
              <HotelIcon style={{ fontSize: 12 }} />
            )}
            {props.event.hasNotes && <FlagIcon style={{ fontSize: 12 }} />}
            {props.event.hasSeizureObservation && (
              <FlashOnIcon style={{ fontSize: 12 }} />
            )}
            {props.event.hasFiles && (
              <DescriptionOutlinedIcon style={{ fontSize: 12 }} />
            )}
            {props.event.publicHoliday && <CakeIcon style={{ fontSize: 12 }} />}
            {!!props.event.travelTime && (
              <DriveEtaIcon style={{ fontSize: 12 }} />
            )}
            {props.event.memberAssignedAutomatically && (
              <FaceRoundedIcon style={{ fontSize: 12 }} />
            )}
            <Protected admin>
              {props.event.bonusPay > 0 && (
                <span style={{ fontWeight: 'bold' }}>B</span>
              )}
            </Protected>
          </div>
        )}
      </div>
    </Box>
  );
};

export default ScheduleCalendarEvent;

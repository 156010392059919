import {
  Box,
  createStyles,
  IconButton,
  InputAdornment,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { TextField } from '@timed/common';
import { useForm } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      marginTop: theme.spacing(4),
      width: 300,
      height: theme.spacing(12),
      minWidth: 'max-content',
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
      [theme.breakpoints.down('sm')]: {
        height: theme.spacing(10),
      },
    },
    input: {
      alignSelf: 'center',
      backgroundColor: theme.palette.background.default,
      flexGrow: 1,
      '& .MuiOutlinedInput-input': {
        color: theme.palette.text.secondary,
        flexGrow: 1,
        padding: theme.spacing(2.75, 1, 2.75, 1),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1.79, 8, 1.79, 2),
        },
        '& .MuiInputAdornment-root, .MuiInputAdornment-positionEnd': {
          marginLeft: 0,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.secondary,
      },
      '& .Mui-focused': {
        '& .MuiOutlinedInput-input': {
          color: theme.palette.text.primary,
        },
        '& .MuiSvgIcon-root': {
          color: theme.palette.text.primary,
        },
      },
    },
  }),
);

const AppBarSearchInput = () => {
  const classes = useStyles();

  const { control, setValue, watch } = useForm<{ search: string | null }>({
    defaultValues: {},
  });

  const watched = watch('search');

  const handleBlur = () => {
    handleSubmit();
  };

  const handleKeyUp: React.KeyboardEventHandler<HTMLDivElement> = ({ key }) => {
    if (key === 'Enter') handleSubmit();
  };

  const handleSubmit = () => {
    // if (watched?.trim() !== search)
    //   setSearch(!!watched ? watched.trim() : watched);
  };

  // useEffect(() => {
  //   if (watched?.trim() !== search) {
  //     const delayDebounceFn = setTimeout(() => {
  //       setSearch(!!watched ? watched.trim() : watched);
  //     }, 250);

  //     return () => clearTimeout(delayDebounceFn);
  //   }
  // }, [watched, search, setSearch]);

  // useEffect(() => {
  //   if (!search) setValue('search', '');
  // }, [search, setValue]);

  // return (
  //   <TextField
  //     name="search"
  //     label="Search"
  //     control={control}
  //     onBlur={handleBlur}
  //     onKeyUp={handleKeyUp}
  //     className={classes.root}
  //     variant="outlined"
  //     size="small"
  //   />
  // );

  return (
    <Box className={classes.wrapper}>
      <TextField
        name="search"
        control={control}
        onBlur={handleBlur}
        onKeyUp={handleKeyUp}
        className={classes.input}
        variant="outlined"
        size="small"
        onClick={(event) => {
          (event.target as HTMLInputElement).select();
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          ),
          endAdornment: !!watched ? (
            <InputAdornment position="end">
              <IconButton>
                <ClearIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ) : undefined,
        }}
      />
    </Box>
  );
};

export default AppBarSearchInput;

import { pluralise } from '@timed/common/utils/pluralise';

type FormatTimeDurationFn = {
  /**
   * Quantity of units.
   */
  units: number;

  /**
   * Unit type.
   */
  type: 'minutes' | 'seconds';
};

/**
 * Format two Date objects into a duration string.
 * E.g: 9-11am, 10:30am-2pm, 12:30-8pm, 10pm-12am
 */
export const formatTime = ({ units, type }: FormatTimeDurationFn) => {
  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  switch (type) {
    case 'minutes':
      hours = Math.floor(units / 60);
      minutes = Math.floor(units - hours * 60);
      break;

    case 'seconds':
      hours = Math.floor(units / 60 / 60);
      minutes = Math.floor(units / 60 - hours * 60 * 60);
      seconds = Math.floor(units - hours * 60 * 60 - minutes * 60);
      break;
  }

  const result: string[] = [];

  if (!!hours)
    result.push(
      `${hours} ${pluralise({
        quantity: hours,
        plural: 'hrs',
        singular: 'hr',
      })}`,
    );

  if (!!minutes)
    result.push(
      `${minutes} ${pluralise({
        quantity: minutes,
        plural: 'mins',
        singular: 'min',
      })}`,
    );

  if (!!seconds)
    result.push(
      `${seconds} ${pluralise({
        quantity: seconds,
        plural: 'secs',
        singular: 'sec',
      })}`,
    );

  return result.join(' ');
};

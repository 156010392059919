import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useAuth } from '@timed/auth';
import {
  ButtonAsync,
  Checkbox,
  NumberInput,
  ProfileInput,
  roundNumber,
  TextField,
  transformNumberToFixedFloat,
} from '@timed/common';
import {
  OrderBy,
  QueryByIdInput,
  useGetOrgSettingsByIdQuery,
  useUpdateOrgSchedulingMutation,
} from '@timed/gql';
import { addDays, format } from 'date-fns';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
  patch: {
    schedulingContactPerson: QueryByIdInput | null;
    schedulingContactPerson2: QueryByIdInput | null;
    membersCanSeeClockedTimes: boolean;
    taskAssignAttendeeEnable: boolean;
    taskAssignAttendeeFutureDays: number | null;
    taskAssignAttendeePastWeeks: number | null;
    quantityOfFutureShiftsMembersCanSee: number | null;
    quantityOfPastShiftsMembersCanSee: number | null;
    unfilledEventsDayRange: number | null;
    clockOnWarnMinutes: number | null;
    clockOffWarnMinutes: number | null;
    allowedDurationBetweenConflicts: number | null;
    travelTimeAllowSameClient: boolean;
    travelTimeAllowedDurationBetweenEvents: number | null;
    claimEventsDayRange: number | null;
    scheduleMaxDayTimeLimitDefault: number | null;
    scheduleMaxWeekTimeLimitDefault: number | null;
    maxMemberLatenessForAutomaticSundayEvents: number | null;
    maxMemberLatenessForAutomaticSaturdayEvents: number | null;
    maxMemberLatenessForAutomaticPublicHolidayEvents: number | null;
    maxMemberLatenessForAutomaticHandoverEvents: number | null;
    autoArchiveClockedOffEventsAfterNumberOfHours: number | null;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        alignItems: 'start',
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
      },
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  }),
);

const SettingsOrganisationSchedulingForm = () => {
  const classes = useStyles();

  const [updateOrg, updateResponse] = useUpdateOrgSchedulingMutation();

  const { handleSubmit, control, setValue, watch } = useForm<FormData>(); //({

  const taskAssignAttendeeFutureDays = watch(
    'patch.taskAssignAttendeeFutureDays',
  );

  const { data, loading } = useGetOrgSettingsByIdQuery({
    variables: { id: useAuth().org!.id },
  });

  const onSubmit = ({
    patch: {
      scheduleMaxDayTimeLimitDefault,
      scheduleMaxWeekTimeLimitDefault,
      maxMemberLatenessForAutomaticSundayEvents,
      maxMemberLatenessForAutomaticSaturdayEvents,
      maxMemberLatenessForAutomaticHandoverEvents,
      maxMemberLatenessForAutomaticPublicHolidayEvents,
      ...patch
    },
  }: FormData) => {
    if (!patch.schedulingContactPerson?.id)
      patch.schedulingContactPerson = null;
    if (!patch.schedulingContactPerson2?.id)
      patch.schedulingContactPerson2 = null;

    updateOrg({
      variables: {
        input: {
          patch: {
            ...patch,
            scheduleMaxDayTimeLimitDefault: !!scheduleMaxDayTimeLimitDefault
              ? Number(scheduleMaxDayTimeLimitDefault) * 60
              : null,
            scheduleMaxWeekTimeLimitDefault: !!scheduleMaxWeekTimeLimitDefault
              ? Number(scheduleMaxWeekTimeLimitDefault) * 60
              : null,
            maxMemberLatenessForAutomaticSundayEvents:
              maxMemberLatenessForAutomaticSundayEvents === 0
                ? null
                : maxMemberLatenessForAutomaticSundayEvents,
            maxMemberLatenessForAutomaticSaturdayEvents:
              maxMemberLatenessForAutomaticSaturdayEvents === 0
                ? null
                : maxMemberLatenessForAutomaticSaturdayEvents,
            maxMemberLatenessForAutomaticHandoverEvents:
              maxMemberLatenessForAutomaticHandoverEvents === 0
                ? null
                : maxMemberLatenessForAutomaticHandoverEvents,
            maxMemberLatenessForAutomaticPublicHolidayEvents:
              maxMemberLatenessForAutomaticPublicHolidayEvents === 0
                ? null
                : maxMemberLatenessForAutomaticPublicHolidayEvents,
          },
        },
      },
    }).catch((e) => {});
  };

  useEffect(() => {
    if (data) {
      const {
        taskAssignAttendeeEnable,
        taskAssignAttendeeFutureDays,
        taskAssignAttendeePastWeeks,
        membersCanSeeClockedTimes,
        quantityOfFutureShiftsMembersCanSee,
        quantityOfPastShiftsMembersCanSee,
        schedulingContactPerson,
        schedulingContactPerson2,
        unfilledEventsDayRange,
        clockOnWarnMinutes,
        clockOffWarnMinutes,
        allowedDurationBetweenConflicts,
        travelTimeAllowSameClient,
        travelTimeAllowedDurationBetweenEvents,
        claimEventsDayRange,
        scheduleMaxDayTimeLimitDefault,
        scheduleMaxWeekTimeLimitDefault,
        maxMemberLatenessForAutomaticSundayEvents,
        maxMemberLatenessForAutomaticSaturdayEvents,
        maxMemberLatenessForAutomaticPublicHolidayEvents,
        maxMemberLatenessForAutomaticHandoverEvents,
        autoArchiveClockedOffEventsAfterNumberOfHours,
      } = data.orgById;

      setValue(
        'patch.taskAssignAttendeeEnable',
        taskAssignAttendeeEnable || false,
      );
      setValue(
        'patch.taskAssignAttendeeFutureDays',
        taskAssignAttendeeFutureDays || null,
      );
      setValue(
        'patch.taskAssignAttendeePastWeeks',
        taskAssignAttendeePastWeeks || null,
      );
      setValue(
        'patch.membersCanSeeClockedTimes',
        membersCanSeeClockedTimes || false,
      );
      setValue(
        'patch.quantityOfFutureShiftsMembersCanSee',
        quantityOfFutureShiftsMembersCanSee || 0,
      );
      setValue(
        'patch.quantityOfPastShiftsMembersCanSee',
        quantityOfPastShiftsMembersCanSee || 0,
      );
      setValue(
        'patch.schedulingContactPerson',
        !!schedulingContactPerson ? { id: schedulingContactPerson.id } : null,
      );
      setValue(
        'patch.schedulingContactPerson2',
        !!schedulingContactPerson2 ? { id: schedulingContactPerson2.id } : null,
      );
      setValue('patch.unfilledEventsDayRange', unfilledEventsDayRange || 0);
      setValue('patch.clockOnWarnMinutes', clockOnWarnMinutes || 0);
      setValue('patch.clockOffWarnMinutes', clockOffWarnMinutes || 0);
      setValue(
        'patch.allowedDurationBetweenConflicts',
        allowedDurationBetweenConflicts || 0,
      );
      setValue(
        'patch.travelTimeAllowSameClient',
        travelTimeAllowSameClient || false,
      );
      setValue(
        'patch.travelTimeAllowedDurationBetweenEvents',
        travelTimeAllowedDurationBetweenEvents ?? 0,
      );
      setValue('patch.claimEventsDayRange', claimEventsDayRange ?? 0);
      setValue(
        'patch.scheduleMaxDayTimeLimitDefault',
        !!scheduleMaxDayTimeLimitDefault
          ? roundNumber(scheduleMaxDayTimeLimitDefault / 60, 2)
          : null,
      );
      setValue(
        'patch.scheduleMaxWeekTimeLimitDefault',
        !!scheduleMaxWeekTimeLimitDefault
          ? roundNumber(scheduleMaxWeekTimeLimitDefault / 60, 2)
          : null,
      );
      setValue(
        'patch.maxMemberLatenessForAutomaticSundayEvents',
        !!maxMemberLatenessForAutomaticSundayEvents
          ? roundNumber(maxMemberLatenessForAutomaticSundayEvents, 2)
          : null,
      );
      setValue(
        'patch.maxMemberLatenessForAutomaticSaturdayEvents',
        !!maxMemberLatenessForAutomaticSaturdayEvents
          ? roundNumber(maxMemberLatenessForAutomaticSaturdayEvents, 2)
          : null,
      );
      setValue(
        'patch.maxMemberLatenessForAutomaticPublicHolidayEvents',
        !!maxMemberLatenessForAutomaticPublicHolidayEvents
          ? roundNumber(maxMemberLatenessForAutomaticPublicHolidayEvents, 2)
          : null,
      );
      setValue(
        'patch.maxMemberLatenessForAutomaticHandoverEvents',
        !!maxMemberLatenessForAutomaticHandoverEvents
          ? roundNumber(maxMemberLatenessForAutomaticHandoverEvents, 2)
          : null,
      );
      setValue(
        'patch.autoArchiveClockedOffEventsAfterNumberOfHours',
        autoArchiveClockedOffEventsAfterNumberOfHours !== null &&
          autoArchiveClockedOffEventsAfterNumberOfHours !== undefined
          ? autoArchiveClockedOffEventsAfterNumberOfHours
          : null,
      );
    }
  }, [data, setValue]);

  if (loading) return <Typography>Loading</Typography>;

  if (!data)
    return (
      <Typography>
        An error occured while fetching existing configuration
      </Typography>
    );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.wrapper}>
      <Typography className={classes.bold}>
        Scheduling contact person
      </Typography>
      <ProfileInput
        control={control}
        name="patch.schedulingContactPerson.id"
        chipProps={{
          onDelete: () => setValue('patch.schedulingContactPerson.id', ''),
        }}
        formControlProps={{ variant: 'outlined', size: 'small' }}
        type={'member'}
        orderBy={[{ lastName: OrderBy.ASC }]}
        where={{
          phone: { _ne: null },
          branchMembers: { branch: { name: { _eq: 'Sydney' } } },
        }}
        watch={watch}
      />
      <Typography className={classes.bold}>
        Secondary scheduling contact person
      </Typography>
      <ProfileInput
        control={control}
        name="patch.schedulingContactPerson2.id"
        chipProps={{
          onDelete: () => setValue('patch.schedulingContactPerson2.id', ''),
        }}
        formControlProps={{ variant: 'outlined', size: 'small' }}
        type={'member'}
        orderBy={[{ lastName: OrderBy.ASC }]}
        where={{
          phone: { _ne: null },
          branchMembers: { branch: { name: { _eq: 'Sydney' } } },
        }}
        watch={watch}
      />
      <Checkbox
        control={control}
        name="patch.membersCanSeeClockedTimes"
        label="Support workers can see their own clocked times."
      />
      <Checkbox
        control={control}
        name="patch.taskAssignAttendeeEnable"
        label="Enable task: Assign Attendee."
      />
      <Box>
        <Typography className={classes.bold}>
          Assign Attendee Task: Days in the future to assign attendees.
        </Typography>
        <TextField
          control={control}
          name="patch.taskAssignAttendeeFutureDays"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 182, step: '1' }}
          transform={transformNumberToFixedFloat(0)}
        />
        (next date:{' '}
        {format(
          addDays(new Date(), taskAssignAttendeeFutureDays ?? 0),
          'dd/MM/yyyy',
        )}
        )
      </Box>
      <Box>
        <Typography className={classes.bold}>
          Assign Attendee Task: Weeks in the past to find attendees.
        </Typography>
        <TextField
          control={control}
          name="patch.taskAssignAttendeePastWeeks"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 8, step: '1' }}
          transform={transformNumberToFixedFloat(0)}
        />
      </Box>
      <Box>
        <Typography className={classes.bold}>
          Number of other-worker shifts a support worker can see in the future
          after their own
        </Typography>
        <TextField
          control={control}
          name="patch.quantityOfFutureShiftsMembersCanSee"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 10, step: '1' }}
          transform={transformNumberToFixedFloat(0)}
        />
      </Box>
      <Box>
        <Typography className={classes.bold}>
          Number of other-worker shifts a support worker can see in the past
          before their own
        </Typography>
        <TextField
          control={control}
          name="patch.quantityOfPastShiftsMembersCanSee"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 10, step: '1' }}
          transform={transformNumberToFixedFloat(0)}
        />
      </Box>
      <Box>
        <Typography className={classes.bold}>
          Day range to check for unfilled shifts
        </Typography>
        <TextField
          control={control}
          name="patch.unfilledEventsDayRange"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 1, max: 100, step: '1' }}
          transform={transformNumberToFixedFloat(0)}
        />
      </Box>
      <Box>
        <Typography className={classes.bold}>
          Number of minutes early/late for clock on highlights{' '}
          {'(Shifts -> Admin -> Approve)'}
        </Typography>
        <TextField
          control={control}
          name="patch.clockOnWarnMinutes"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 1, max: 100, step: '1' }}
          transform={transformNumberToFixedFloat(0)}
        />
      </Box>
      <Box>
        <Typography className={classes.bold}>
          Number of minutes early/late for clock off highlights{' '}
          {'(Shifts -> Admin -> Approve)'}
        </Typography>
        <TextField
          control={control}
          name="patch.clockOffWarnMinutes"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 1, max: 100, step: '1' }}
          transform={transformNumberToFixedFloat(0)}
        />
      </Box>
      <Box>
        <Typography className={classes.bold}>
          Allowed duration between conflicts in minutes.
        </Typography>
        <TextField
          control={control}
          name="patch.allowedDurationBetweenConflicts"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 1440, step: '1' }}
          transform={transformNumberToFixedFloat(0)}
        />
      </Box>
      <Checkbox
        control={control}
        name="patch.travelTimeAllowSameClient"
        label="Allow travel time to be assigned to subsequent events for the same participant."
      />
      <Box>
        <Typography className={classes.bold}>
          Allowed duration in minutes between events for travel time
          eligibility.
        </Typography>
        <TextField
          control={control}
          name="patch.travelTimeAllowedDurationBetweenEvents"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 1440, step: '1' }}
          transform={transformNumberToFixedFloat(0)}
        />
      </Box>
      <Box>
        <Typography className={classes.bold}>
          Days in advance an employee can claim a shift.
        </Typography>
        <TextField
          control={control}
          name="patch.claimEventsDayRange"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 365, step: '1' }}
          transform={transformNumberToFixedFloat(0)}
        />
      </Box>
      <Box>
        <Typography className={classes.bold}>
          Default maximum schedulable hours per day
        </Typography>
        <TextField
          control={control}
          name="patch.scheduleMaxDayTimeLimitDefault"
          variant="outlined"
          size="small"
        />
      </Box>
      <Box>
        <Typography className={classes.bold}>
          Default maximum schedulable hours per week
        </Typography>
        <TextField
          control={control}
          name="patch.scheduleMaxWeekTimeLimitDefault"
          variant="outlined"
          size="small"
        />
      </Box>
      <Box>
        <Typography className={classes.bold}>
          Maximum support worker lateness percentage for automatic Sunday shift
          allocation
        </Typography>
        <NumberInput
          control={control}
          name="patch.maxMemberLatenessForAutomaticSundayEvents"
          variant="outlined"
          size="small"
          max={100}
          min={0}
          transform={transformNumberToFixedFloat(0)}
        />
      </Box>
      <Box>
        <Typography className={classes.bold}>
          Maximum support worker lateness percentage for automatic Saturday
          shift allocation
        </Typography>
        <NumberInput
          control={control}
          name="patch.maxMemberLatenessForAutomaticSaturdayEvents"
          variant="outlined"
          size="small"
          max={100}
          min={0}
          transform={transformNumberToFixedFloat(0)}
        />
      </Box>
      <Box>
        <Typography className={classes.bold}>
          Maximum support worker lateness percentage for automatic
          public-holiday shift allocation
        </Typography>
        <NumberInput
          control={control}
          name="patch.maxMemberLatenessForAutomaticPublicHolidayEvents"
          variant="outlined"
          size="small"
          max={100}
          min={0}
          transform={transformNumberToFixedFloat(0)}
        />
      </Box>
      <Box>
        <Typography className={classes.bold}>
          Maximum support worker lateness percentage for automatic handover
          shift allocation
        </Typography>
        <NumberInput
          control={control}
          name="patch.maxMemberLatenessForAutomaticHandoverEvents"
          variant="outlined"
          size="small"
          max={100}
          min={0}
          transform={transformNumberToFixedFloat(0)}
        />
      </Box>
      <Box>
        <Typography className={classes.bold}>
          Auto archive clocked-off shifts after number of hours (leave blank to
          disable, set as 0 for near-instant)
        </Typography>
        <NumberInput
          control={control}
          name="patch.autoArchiveClockedOffEventsAfterNumberOfHours"
          variant="outlined"
          size="small"
          max={168}
          min={0}
          transform={transformNumberToFixedFloat(0)}
        />
      </Box>
      <ButtonAsync
        type="submit"
        variant="contained"
        color="primary"
        loading={updateResponse.loading}
        success={!!updateResponse.data}
      >
        Save
      </ButtonAsync>
    </form>
  );
};

export default SettingsOrganisationSchedulingForm;

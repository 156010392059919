import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { intersperse } from '@timed/common';
import { OrderBy, useGetBranchesQuery } from '@timed/gql';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      alignItems: 'start',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
  }),
);

const BranchList = () => {
  const classes = useStyles();

  const { data, loading } = useGetBranchesQuery({
    variables: { input: { orderBy: [{ name: OrderBy.ASC }] } },
  });

  return (
    <Box className={classes.wrapper}>
      <Typography variant="h2">Existing Branches</Typography>
      <Typography variant="body1">
        {loading
          ? '...Loading'
          : !data
          ? 'None'
          : intersperse({ array: data?.branches.map(({ name }) => name) })}
      </Typography>
    </Box>
  );
};

export default BranchList;

import { useMediaQuery, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ClientContext, ClientCreateNdisPlanFormModal } from '@timed/client';
import { IconButton } from '@timed/common';
import { useModal } from 'mui-modal-provider';
import { useContext } from 'react';

const ClientCreateNdisPlanButton = () => {
  const { showModal } = useModal();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const client = useContext(ClientContext);

  const handleOpenModal = () => {
    const modal: { hide: () => void } = showModal(
      ClientCreateNdisPlanFormModal,
      { client, onClose: () => modal.hide() },
    );
  };

  return (
    <IconButton
      aria-label="menu"
      color="inherit"
      size={smDown ? 'small' : 'medium'}
      onClick={() => handleOpenModal()}
    >
      <AddIcon fontSize="small" />
    </IconButton>
  );
};

export default ClientCreateNdisPlanButton;

import {
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { useAlert } from '@timed/alert';
import {
  AbnInput,
  addServerErrors,
  ButtonAsync,
  EmailInput,
  PhoneInput,
  TextField,
  validateAustralianBusinessName,
  validatePersonName,
} from '@timed/common';
import {
  CreatePlanManagersDocument,
  useCreatePlanManagersMutation,
} from '@timed/gql';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
  name: string;
  businessName?: string;
  abn?: string;
  contactEmail?: string;
  billingEmail?: string;
  phone?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: 500,
      height: 'max-content',
      padding: theme.spacing(2),
      color: theme.palette.grey[800],
      display: 'grid',
      gridTemplateColumns: 'auto',
      gap: '16px',
      boxShadow: 'none',
      border: '1px solid ' + theme.palette.grey[400],
      justifyItems: 'flex-start',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      gap: '16px',
    },
    fullWidth: {
      gridColumn: 'span 2',
    },
  }),
);

const PlanManagerCreateForm = (): JSX.Element => {
  const classes = useStyles();

  const alert = useAlert();

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      abn: '',
      contactEmail: '',
      billingEmail: '',
      name: '',
      businessName: '',
      phone: '',
    },
  });

  const [createPlanManager, response] = useCreatePlanManagersMutation();

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSuccess = () => {
    reset();
    alert.push({
      message: 'Successfully created plan manager',
      severity: 'success',
    });
    const cache = response.client.cache;
    cache.modify({
      fields: {
        planManagers(existing = []) {
          const planManager = cache.writeQuery({
            data: response.data!,
            query: CreatePlanManagersDocument,
          });
          return [...existing, planManager];
        },
      },
    });
  };

  const onSubmit = async (values: FormData) => {
    // Delete empty values
    Object.keys(values).forEach(
      (k) => !values[k as keyof FormData] && delete values[k as keyof FormData],
    );

    createPlanManager({ variables: { input: { objects: [values] } } });
  };

  return (
    <Paper className={classes.paper}>
      <Typography>Add plan manager</Typography>
      <form className={classes.grid} onSubmit={handleSubmit(onSubmit)}>
        <TextField
          required
          label="Name"
          type="text"
          validation={validatePersonName}
          name="name"
          variant="outlined"
          size="small"
          control={control}
          error={!!errors.name}
          helperText={errors.name?.message}
        />
        <TextField
          required
          label="Business Name"
          type="text"
          validation={validateAustralianBusinessName}
          name="businessName"
          variant="outlined"
          size="small"
          control={control}
          error={!!errors.businessName}
          helperText={errors.businessName?.message}
        />
        <AbnInput
          name="abn"
          control={control}
          variant="outlined"
          size="small"
          error={!!errors.abn}
          helperText={errors.abn?.message}
          className={classes.fullWidth}
        />
        <EmailInput
          label="Contact Email"
          name="contactEmail"
          variant="outlined"
          size="small"
          control={control}
          error={!!errors.contactEmail}
          helperText={errors.contactEmail?.message}
          className={classes.fullWidth}
        />
        <EmailInput
          name="billingEmail"
          label="Billing Email"
          variant="outlined"
          size="small"
          control={control}
          error={!!errors.billingEmail}
          helperText={errors.billingEmail?.message}
          className={classes.fullWidth}
        />
        <PhoneInput
          name="phone"
          variant="outlined"
          size="small"
          control={control}
          error={!!errors.phone}
          helperText={errors.phone?.message}
          className={classes.fullWidth}
        />
        <ButtonAsync
          fullWidth
          type="submit"
          color="secondary"
          loading={response.loading}
          success={!!response.data}
          onSuccess={onSuccess}
        >
          Create
        </ButtonAsync>
      </form>
    </Paper>
  );
};

export default PlanManagerCreateForm;

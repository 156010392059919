/**
 * Password Regex
 * Must contain:
 *  - At least 1 uppercase character
 *  - At least 1 lowercase character
 *  - At least 1 numberic character
 *  - At least 1 special character
 */
export const passwordRegex =
  /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,1000})/;

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ClientNdisPlanItemTransactionContext } from '@timed/client/components/NdisPlanItemTransactions/context';
import { IconButton } from '@timed/common';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: theme.spacing(10),
      border: '1px solid ' + theme.palette.divider,
      borderRadius: 0,
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.down('sm')]: {
        height: theme.spacing(8),
        width: theme.spacing(8),
      },
    },
    spin: {
      animation: '$spin 750ms linear infinite',
      fill: theme.palette.success.dark,
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  }),
);

const ClientNdisPlanItemTransactionControlReloadButton = () => {
  const classes = useStyles();

  const { fetch, loading } = useContext(ClientNdisPlanItemTransactionContext);

  return (
    <IconButton
      size="small"
      aria-label="New"
      className={classes.button}
      onClick={() => fetch()}
    >
      <RefreshIcon
        fontSize="small"
        className={loading ? classes.spin : undefined}
      />
    </IconButton>
  );
};

export default ClientNdisPlanItemTransactionControlReloadButton;

import { _shiftsPersonalClaim } from '@timed/app/constants/metadata';
import { Page } from '@timed/page';
import { ShiftsClaim } from '@timed/shifts';

const RouteCoreSupportShiftsPersonalClaim = () => (
  <Page {..._shiftsPersonalClaim}>
    <ShiftsClaim />
  </Page>
);

export default RouteCoreSupportShiftsPersonalClaim;

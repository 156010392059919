import { Box, FormHelperText, useTheme } from '@material-ui/core';
import { formatAddress, validateRequired } from '@timed/common';
import { Address, AddressCountryCode, AddressRegionCode } from '@timed/gql';
import { getCode } from 'country-list';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import {
  Control,
  Controller,
  RegisterOptions,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { GroupTypeBase, OptionTypeBase, Props } from 'react-select';

export const validateAddress: RegisterOptions = {
  validate: {
    validateAddress: (
      value: Pick<
        Address,
        | 'unit'
        | 'street'
        | 'locality'
        | 'region'
        | 'postcode'
        | 'country'
        | 'latitude'
        | 'longitude'
      >,
    ) => {
      if (value) {
        const messages: string[] = [];

        !value.street?.trim() && messages.push('street is required');

        !value.locality?.trim() && messages.push('suburb / town is required');

        !value.region && messages.push('state / territory is required');

        !value.country && messages.push('country is required');

        !value.latitude?.trim() && messages.push('invalid latitude');

        !value.longitude?.trim() && messages.push('invalid longitude');

        const formattedMessage = messages.join(', ');

        return messages.length > 0
          ? formattedMessage[0].toUpperCase() + formattedMessage.slice(1)
          : true;
      } else return true;
    },
  },
};

export type AddressInputProps = Props<
  OptionTypeBase,
  false,
  GroupTypeBase<OptionTypeBase>
> & {
  control: Control<any>;
  error: boolean;
  helperText?: React.ReactNode;
  name: string;
  defaultValue?:
    | Pick<
        Address,
        | 'unit'
        | 'street'
        | 'locality'
        | 'region'
        | 'postcode'
        | 'country'
        | 'latitude'
        | 'longitude'
      >
    | null
    | undefined;
  clearErrors: UseFormClearErrors<any>;
  setError: UseFormSetError<any>;
  setValue: UseFormSetValue<any>;
};

type GoogleMapsAPIResponse = {
  streetNumber: string | null;
  route: string | null;
  locality: string | null;
  region: AddressRegionCode | null;
  postcode: string | null;
  country: AddressCountryCode | null;
  latitude: string | null;
  longitude: string | null;
};

const AddressInput = ({
  control,
  name,
  placeholder,
  clearErrors,
  setValue,
  setError,
  defaultValue,
  error,
  helperText,
  style, // material-ui prop
  styles, // react-select prop
  className,
  ...props
}: AddressInputProps) => {
  const theme = useTheme();
  /**
   * Clear input
   */
  const handleClear = () => {
    setValue(name, null);
    clearErrors(name);
  };

  /**
   * Fetch address details from external API and assign result to field
   */
  const handleLookup = (autoComplete: any): void => {
    if (autoComplete) {
      // Optimisitically update UI before call to external API
      try {
        setValue(name, {
          unit:
            autoComplete.label.charAt(0) === 'U' ||
            autoComplete.label.charAt(0) === 'u'
              ? autoComplete.value.terms[0].value.substring(1)
              : undefined,
          street: autoComplete.value?.structured_formatting?.main_text,
          locality: autoComplete.value?.terms[2]?.value,
          region: autoComplete.value?.terms[3]?.value,
          country: getCode(autoComplete.value?.terms[4]?.value),
        });
      } catch (e) {}
    }

    if (!autoComplete?.value?.place_id) {
      // Address was deleted, clear input
      handleClear();
    } else {
      try {
        geocodeByPlaceId(autoComplete.value.place_id).then((results) => {
          const result: GoogleMapsAPIResponse = {
            streetNumber: null,
            route: null,
            locality: null,
            region: null,
            postcode: null,
            country: null,
            latitude: results[0].geometry.location.lat().toFixed(7),
            longitude: results[0].geometry.location.lng().toFixed(7),
          };

          for (const component of results[0].address_components) {
            if (component.types.includes('street_number'))
              result.streetNumber = component.long_name.trim();
            if (component.types.includes('route'))
              result.route = component.long_name.trim();
            if (component.types.includes('locality'))
              result.locality = component.long_name.trim();
            if (component.types.includes('administrative_area_level_1'))
              result.region = component.short_name as AddressRegionCode;
            if (component.types.includes('postal_code'))
              result.postcode = component.short_name.trim();
            if (component.types.includes('country'))
              result.country = component.long_name as AddressCountryCode;
          }

          setValue(
            name,
            {
              unit:
                autoComplete.label.charAt(0) === 'U' ||
                autoComplete.label.charAt(0) === 'u'
                  ? autoComplete.value.terms[0].value.substring(1)
                  : undefined,
              street:
                result.streetNumber &&
                result.route &&
                result.streetNumber + ' ' + result.route,
              locality: result.locality,
              region: result.region,
              postcode: result.postcode,
              country: result.country,
              latitude: result.latitude,
              longitude: result.longitude,
            },
            { shouldValidate: true },
          );
        });
      } catch (err) {
        setError(name, {
          message:
            'Could not reach address lookup API. Please check your internet connection.',
        });
      }
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={
        props.required ? { ...validateRequired, ...validateAddress } : undefined
      }
      defaultValue={defaultValue || null}
      render={({ field: { name, value } }) => (
        <Box style={style} className={className}>
          <GooglePlacesAutocomplete
            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            minLengthAutocomplete={3}
            autocompletionRequest={{
              componentRestrictions: { country: ['au'] },
            }}
            selectProps={{
              name,
              value: value && {
                label: formatAddress(value, true),
                value: { place_id: null },
              },
              // defaultValue: defaultValue && {
              //   // label: formatAddress(defaultValue, true),
              //   value: { place_id: null },
              // },
              onChange: handleLookup,
              escapeClearsValue: true,
              backspaceRemovesValue: true,
              isClearable: true,
              openMenuOnFocus: false,
              openMenuOnClick: false,
              placeholder: placeholder ? placeholder : 'Address',
              styles: {
                menu: (provided: any) => ({
                  ...provided,
                  backgroundColor: theme.palette.background.default,
                  // Setting zIndex fixes an overlapping issue with the component
                  zIndex: 9999,
                }),
                control: (styles) => {
                  Object.assign(styles, {
                    backgroundColor: theme.palette.background.paper,
                    borderColor: theme.palette.text.disabled,
                  });
                  return error ? { ...styles, borderColor: '#ff1744' } : styles;
                },
                singleValue: (styles) => ({
                  ...styles,
                  color: theme.palette.text.primary,
                }),
                ...styles,
              },
              ...props,
            }}
            onLoadFailed={() =>
              setError(name, {
                message:
                  'Error occured while trying to load address lookup API script',
              })
            }
          />
          <FormHelperText variant="outlined" style={{ color: '#ff1744' }}>
            {helperText}
          </FormHelperText>
        </Box>
      )}
    />
  );
};

export default AddressInput;

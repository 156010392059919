import { useTheme } from '@material-ui/core';
import { ClientNdisPlanItemTransactionContext } from '@timed/client';
import NdisPlanItemAutocomplete from '@timed/client/components/NdisPlanItemAutocomplete/NdisPlanItemAutocomplete';
import { OrderBy } from '@timed/gql';
import { useContext, useEffect } from 'react';

const ClientNdisPlanItemTransactionControlCategoryAutocomplete = () => {
  const theme = useTheme();

  const {
    input: { form, categoryId, setCategoryId, planId },
  } = useContext(ClientNdisPlanItemTransactionContext);

  const category = form.watch('category');

  useEffect(() => {
    if (categoryId !== category?.id) setCategoryId(category?.id ?? null);
  }, [category, categoryId, setCategoryId]);

  return (
    <NdisPlanItemAutocomplete
      inputProps={{
        ...form.register(`category` as const),
        control: form.control,
        size: 'small',
        style: {
          width: 300,
          backgroundColor: theme.palette.background.default,
        },
      }}
      where={{
        clientNdisPlan: { id: { _eq: planId } },
      }}
      orderBy={[
        {
          category: OrderBy.ASC,
          name: { name: OrderBy.ASC },
        },
      ]}
    />
  );
};

export default ClientNdisPlanItemTransactionControlCategoryAutocomplete;

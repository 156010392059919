import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { Checkbox, CheckboxProps } from '@timed/common';

const MemberInviteCheckbox = (props: CheckboxProps) => (
  <Checkbox
    defaultChecked={false}
    label="Send invitation immediately"
    icon={<RadioButtonUncheckedIcon />}
    checkedIcon={<CheckCircleOutlineRoundedIcon />}
    {...props}
  />
);

export default MemberInviteCheckbox;

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  addServerErrors,
  DateInput,
  FormModal,
  ModalProps,
  Snackbar,
  Switch,
  Textarea,
  TimeInput,
} from '@timed/common';
import {
  CreateMemberUnavailablesDocument,
  Member,
  MemberUnavailablesCreatableFieldsInput,
  useCreateMemberUnavailablesMutation,
} from '@timed/gql';
import {
  addDays,
  isAfter,
  isEqual,
  isSameDay,
  isValid,
  startOfDay,
  subHours,
} from 'date-fns';
import { addHours } from 'date-fns/esm';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

type MemberUnavailabilityPeriodCreateFormModalProps = Omit<
  ModalProps,
  'children'
> & {
  onClose: () => void;
  member: Pick<Member, 'id'>;
  startAt?: Date;
  endAt?: Date;
};

type FormData = Pick<
  MemberUnavailablesCreatableFieldsInput,
  'startAt' | 'endAt' | 'notes' | 'color' | 'rrule'
> & {
  startAtTime?: Date | null;
  endAtTime?: Date | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputs: {
      // height: 360,
      flex: '1 0 auto',
      overflowY: 'auto',
    },
    textarea: {
      backgroundColor: theme.palette.background.paper,
      width: 256,
      color: theme.palette.text.primary,
      border: '1px solid ' + theme.palette.text.disabled,
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.up('md')]: {
        width: 384,
      },
    },
    dates: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridAutoColumns: 120,
      gap: theme.spacing(4),
      alignItems: 'center',
    },
    row: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridAutoColumns: 'max-content',
      gap: theme.spacing(4),
    },
    days: {
      gridAutoFlow: 'column',
      display: 'grid',
      gridTemplateRows: 'auto auto auto auto auto',
      gap: theme.spacing(0),
      alignItems: 'center',
    },
    groups: {
      display: 'grid',
      gridTemplateColumns: 'auto',
      // gridAutoFlow: "row",
      gap: theme.spacing(4),
    },
    group: {
      display: 'grid',
      gridTemplateColumns: 'max-content',
      gridAutoRows: 'max-content',
      gap: theme.spacing(1),
      '& .MuiInputBase-input': {
        textAlign: 'center',
      },
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    buttons: {
      flex: '0 1 max-content',
      display: 'flex',
      justifyContent: 'space-between',
    },
    endDate: {
      display: 'grid',
      gap: 16,
      gridTemplateColumns: 'auto 120px',
    },
  }),
);

const MemberUnavailabilityPeriodCreateFormModal = ({
  onClose,
  member,
  startAt = new Date(),
  endAt = addHours(startAt, 1),
  ...modalProps
}: MemberUnavailabilityPeriodCreateFormModalProps) => {
  const classes = useStyles();

  const [create, response] = useCreateMemberUnavailablesMutation();

  const { handleSubmit, control, setError, setValue, getValues, watch } =
    useForm<FormData>({
      defaultValues: {
        startAt,
        startAtTime: startAt,
        endAt,
        endAtTime: endAt,
        notes: '',
        color: '#ffffff',
      },
    });

  startAt = watch('startAt');
  endAt = watch('endAt');

  const [allDay, setAllDay] = useState<boolean>(
    isEqual(startAt, startOfDay(startAt)) &&
      isEqual(endAt, addDays(startOfDay(startAt), 1)),
  );

  /**
   * True if:
   * 1. startAt and endAt occur on the same day, or
   * 2. startAt is midnight and endAt is midnight the next day.
   */
  const allDayVisible = useMemo<boolean>(
    () =>
      isSameDay(startAt, endAt) ||
      (isEqual(startAt, startOfDay(startAt)) &&
        isEqual(endAt, addDays(startOfDay(startAt), 1))),
    [startAt, endAt],
  );

  const onSubmit = ({ endAtTime, startAtTime, ...values }: FormData) => {
    if (allDay && allDayVisible)
      values = {
        ...values,
        startAt: startOfDay(startAt),
        endAt: addDays(startOfDay(startAt), 1),
      };

    create({
      variables: {
        input: {
          owner: member,
          objects: [values],
        },
      },
    }).catch((e) => {});
  };

  const onSuccess = () => {
    const cache = response.client.cache;

    cache.modify({
      fields: {
        memberUnavailables(existing = []) {
          return [
            ...existing,
            cache.writeQuery({
              data: response.data,
              query: CreateMemberUnavailablesDocument,
            }),
          ];
        },
      },
    });
  };

  const handleChangeStartAt = useCallback(
    (date: Date) => {
      setValue('startAt', date);

      if (
        isEqual(date, getValues('endAt')) ||
        isAfter(date, getValues('endAt'))
      ) {
        setValue('endAt', addHours(date, 1));
      }
    },
    [getValues, setValue],
  );

  const handleChangeEndAt = useCallback(
    (date: Date) => {
      setValue('endAt', date);

      if (
        isEqual(getValues('startAt'), date) ||
        isAfter(getValues('startAt'), date)
      ) {
        setValue('startAt', subHours(date, 1));
      }
    },
    [getValues, setValue],
  );

  const handleChangeStartAtTime = useCallback(
    (date?: MaterialUiPickersDate) => {
      if (!!date && isValid(date)) {
        const currentDate = new Date(getValues('startAt'));
        currentDate.setHours(date.getHours(), date.getMinutes());
        handleChangeStartAt(currentDate);
      }
    },
    [getValues, handleChangeStartAt],
  );

  const handleChangeEndAtTime = useCallback(
    (date?: MaterialUiPickersDate) => {
      if (!!date && isValid(date)) {
        const currentDate = new Date(getValues('endAt'));
        currentDate.setHours(date.getHours(), date.getMinutes());
        handleChangeEndAt(currentDate);
      }
    },
    [getValues, handleChangeEndAt],
  );

  /**
   * Display error messages from server response
   */
  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  return (
    <FormModal
      modalProps={modalProps}
      title="Add unavailability"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Snackbar
        open={!!response.error}
        severity="error"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {response.error?.message}
      </Snackbar>
      <Box className={classes.inputs}>
        <Box className={classes.groups}>
          {allDayVisible && (
            <Box className={classes.group}>
              <Typography className={classes.bold}>All day</Typography>
              <Switch
                checked={allDay}
                name="allDay"
                onChange={() => setAllDay(!allDay)}
                color="primary"
              />
            </Box>
          )}
          <Box className={allDayVisible && allDay ? classes.row : undefined}>
            <Box className={classes.group}>
              <Typography className={classes.bold}>From</Typography>
              <Box className={classes.dates}>
                <DateInput
                  disablePast
                  required
                  name="startAt"
                  control={control}
                  inputVariant="outlined"
                  size="small"
                  onChange={(date) => date && handleChangeStartAt(date)}
                />
                {(!allDayVisible || !allDay) && (
                  <TimeInput
                    required
                    keyboard
                    name="startAtTime"
                    control={control}
                    inputVariant="outlined"
                    size="small"
                    onChange={(date) => date && handleChangeStartAtTime(date)}
                    value={startAt}
                  />
                )}
              </Box>
            </Box>
            <Box className={classes.group}>
              <Typography className={classes.bold}>To</Typography>
              <Box className={classes.dates}>
                <DateInput
                  disablePast
                  required
                  name="endAt"
                  control={control}
                  inputVariant="outlined"
                  size="small"
                  onChange={(date) => date && handleChangeEndAt(date)}
                />
                {(!allDayVisible || !allDay) && (
                  <TimeInput
                    required
                    keyboard
                    name="endAtTime"
                    control={control}
                    inputVariant="outlined"
                    size="small"
                    onChange={(date) => date && handleChangeEndAtTime(date)}
                    value={endAt}
                  />
                )}
              </Box>
            </Box>
          </Box>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Notes</Typography>
            <Textarea
              name="notes"
              control={control}
              minRows={5}
              placeholder="Reason for unavailability"
              className={classes.textarea}
            />
          </Box>
        </Box>
      </Box>
    </FormModal>
  );
};;

export default MemberUnavailabilityPeriodCreateFormModal;

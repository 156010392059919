import {
  createStyles,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormHelperText,
  makeStyles,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
  Theme,
} from '@material-ui/core';
import { validateRequired } from '@timed/common';
import { Control, Controller, RegisterOptions } from 'react-hook-form';

export type SwitchProps = MuiSwitchProps & {
  label?: string;
  control?: Control<any>;
  validation?: Omit<RegisterOptions, 'required'>;
  formControlProps?: FormControlProps;
  formControlLabelProps?: Omit<FormControlLabelProps, 'control' | 'label'>;
  name: string;
  error?: boolean;
  helperText?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      '& .MuiFormControlLabel-label': {
        color: theme.palette.error.main,
      },
    },
  }),
);

const Switch = ({
  disabled,
  label,
  name,
  control,
  validation,
  defaultChecked,
  // defaultValue,
  formControlProps,
  formControlLabelProps,
  error,
  helperText,
  className,
  ...props
}: SwitchProps) => {
  const classes = useStyles();

  const input = ({
    name,
    value,
    onChange,
  }: {
    name: string;
    value?: any;
    onChange?: (...event: any[]) => void;
  }) => (
    <FormControl disabled={disabled} {...formControlProps}>
      <FormControlLabel
        label={label}
        className={error ? classes.error : className}
        control={
          <MuiSwitch
            key={name}
            name={name}
            value={value}
            onChange={(event) => onChange && onChange(event.target.checked)}
            // defaultChecked={!!defaultChecked}
            defaultChecked={value}
            {...props}
          />
        }
        {...formControlLabelProps}
      />
      {error && (
        <FormHelperText style={{ marginLeft: 32 }} error>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );

  return !!control ? (
    <Controller
      control={control}
      name={name}
      rules={
        validation && props.required
          ? { ...validateRequired, ...validation }
          : validation
      }
      render={({ field }) => input(field)}
    />
  ) : (
    input({ name })
  );
};

export default Switch;

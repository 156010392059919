import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { _peopleClients } from '@timed/app';
import { Protected, useAuth } from '@timed/auth';
import {
  ClientAdminNotesForm,
  ClientContext,
  ClientNoteList,
  ClientNoteListProvider,
} from '@timed/client';
import ClientConfidentialNotesForm from '@timed/client/components/ConfidentialNotesForm/ConfidentialNotesForm';
import { NavLinks, NavLinksProps, useRouter } from '@timed/common';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      flexGrow: 1,
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
      },
    },
  }),
);

const ClientViewNotes = () => {
  const classes = useStyles();

  const { permissible } = useAuth();

  const {
    query: { subcategory },
  } = useRouter();

  const client = useContext(ClientContext);

  const links: NavLinksProps['links'] = [
    {
      label: 'General Notes',
      path: _peopleClients.path + '/' + client.id + '/notes/shift-notes',
    },
    {
      label: 'Admin Notes',
      path: _peopleClients.path + '/' + client.id + '/notes/admin-notes',
      admin: true,
    },
    {
      label: 'Confidential Notes',
      path: _peopleClients.path + '/' + client.id + '/notes/confidential-notes',
      tester: true,
    },
  ];

  let content: JSX.Element = <></>;

  if (!permissible({ admin: true }) || subcategory === 'shift-notes')
    content = (
      <ClientNoteListProvider>
        <ClientNoteList />
      </ClientNoteListProvider>
    );
  else if (subcategory === 'admin-notes')
    content = (
      <Protected admin>
        <ClientAdminNotesForm />
      </Protected>
    );
  else if (subcategory === 'confidential-notes')
    content = (
      <Protected tester>
        <ClientConfidentialNotesForm />
      </Protected>
    );

  return (
    <Box className={classes.wrapper}>
      <Protected admin>
        <NavLinks hideBackground links={links} size="small" />
      </Protected>
      {content}
    </Box>
  );
};

export default ClientViewNotes;

import { Menu, MenuItem, useMediaQuery, useTheme } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useAuth } from '@timed/auth';
import {
  ClientDeleteContactDialog,
  ClientUpdateContactFormModal,
} from '@timed/client';
import { IconButton } from '@timed/common';
import { ClientContact, Permission } from '@timed/gql';
import { useModal } from 'mui-modal-provider';
import { useState } from 'react';

type ClientContactActionMenuButtonProps = {
  contact: Pick<ClientContact, 'id' | 'name'>;
};

const ClientContactActionMenuButton = ({
  contact,
}: ClientContactActionMenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { permissible } = useAuth();
  const { showModal } = useModal();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenDeleteDialog = () => {
    const modal: { hide: () => void } = showModal(ClientDeleteContactDialog, {
      onClose: () => modal.hide(),
      contact,
    });
  };

  const handleOpenUpdateModal = () => {
    const modal: { hide: () => void } = showModal(
      ClientUpdateContactFormModal,
      {
        onClose: () => modal.hide(),
        contactId: contact.id,
      },
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <span>
      <IconButton
        aria-label="menu"
        color="inherit"
        size={smDown ? 'small' : 'medium'}
        onClick={handleClick}
      >
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
          },
        }}
      >
        {permissible({
          permissions: Permission.CLIENT_WRITE,
        }) && (
          <MenuItem
            onClick={() => {
              handleOpenUpdateModal();
              handleClose();
            }}
          >
            Update contact
          </MenuItem>
        )}
        {permissible({
          permissions: Permission.CLIENT_WRITE,
        }) && (
          <MenuItem
            onClick={() => {
              handleOpenDeleteDialog();
              handleClose();
            }}
          >
            Delete contact
          </MenuItem>
        )}
      </Menu>
    </span>
  );
};

export default ClientContactActionMenuButton;

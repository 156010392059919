import { Theme, createStyles, makeStyles } from '@material-ui/core';
import WarningIcon from '@mui/icons-material/Warning';
import { Typography, TypographyProps } from '@mui/material';
import { _uploadsOverview } from '@timed/app';
import { useAuth } from '@timed/auth';
import { Link } from '@timed/common';
import { pluralise } from '@timed/common/utils/pluralise';
import { MemberFileType, Permission } from '@timed/gql';

type StartMissingFilesProps = TypographyProps & { files?: MemberFileType[] };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: theme.palette.warning.lightest,
      padding: theme.spacing(1),
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(2),
        padding: theme.spacing(2),
      },
    },
    link: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  }),
);

const StartMissingFiles = ({ files, ...props }: StartMissingFilesProps) => {
  const classes = useStyles();

  const { permissible } = useAuth();

  if (
    !files?.length ||
    (!permissible({ tester: true }) &&
      (permissible({ permissions: Permission.MEMBER_READ }) ||
        permissible({ permissions: Permission.CLIENT_READ })))
  )
    return null;

  return (
    <div className={classes.wrapper}>
      <WarningIcon fontSize="medium" color="warning" />
      <Typography {...props}>
        You need to upload{' '}
        <Link to={_uploadsOverview.path} className={classes.link}>
          {files.length}{' '}
          {pluralise({
            singular: 'document',
            plural: 'documents',
            quantity: files.length,
          })}
        </Link>
        .
      </Typography>
    </div>
  );
};

export default StartMissingFiles;

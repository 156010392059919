import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  Button,
  ButtonAsync,
  IconButton,
  Modal,
  ModalProps,
} from '@timed/common';
import {
  ClientObservation,
  useDeleteClientObservationsMutation,
} from '@timed/gql';
import { useState } from 'react';

type ClientDeleteObservationFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  closePreviousModal: () => void;
  observation: Pick<ClientObservation, 'id'>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      padding: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        width: 500,
      },
    },
    title: {
      fontSize: '16px',
      fontWeight: 'bold',
      display: 'grid',
      gridTemplateColumns: 'auto min-content',
      alignItems: 'center',
    },
    buttons: {
      marginTop: theme.spacing(1),
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

const ClientDeleteObservationFormModal = ({
  onClose,
  closePreviousModal,
  observation,
  ...modalProps
}: ClientDeleteObservationFormModalProps) => {
  const classes = useStyles();

  const [open, setOpen] = useState(true);

  const [
    deleteObservation,
    {
      data,
      loading,
      client: { cache },
    },
  ] = useDeleteClientObservationsMutation({
    variables: {
      input: {
        ids: [observation.id],
      },
    },
  });

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const onSuccess = () => {
    data!.deleteClientObservations.length &&
      cache.evict({
        id: cache.identify({
          __typename: 'ClientObservation',
          id: data!.deleteClientObservations[0].id,
        }),
      });

    cache.gc();
  };

  const onComplete = () => {
    closePreviousModal();
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box className={classes.form}>
        <Box className={classes.title}>
          <Typography>Delete Observation</Typography>
          <IconButton aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Typography>
          Are you sure you want to delete this observation?
        </Typography>

        <Box className={classes.buttons}>
          <ButtonAsync
            color="secondary"
            loading={loading}
            success={!!data}
            onClick={() => {
              deleteObservation();
            }}
            onComplete={onComplete}
            onSuccess={onSuccess}
          >
            Delete Observation
          </ButtonAsync>
          <Button color="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ClientDeleteObservationFormModal;

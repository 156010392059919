import {
  Box,
  BoxProps,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import { formatPersonName, formatTimeDuration } from '@timed/common';
import { Event, PersonNamesFragment } from '@timed/gql';
import clsx from 'clsx';
import { differenceInMinutes, format } from 'date-fns';

type EventCardProps = BoxProps & {
  event: Pick<Event, 'id' | 'startAt' | 'endAt' | 'locality' | 'region'> & {
    client: PersonNamesFragment;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'grid',
      gridTemplateColumns: '50px auto',
      border: '1px solid ' + theme.palette.background.paper2,
    },
    date: {
      backgroundColor: theme.palette.background.paper2,
      padding: theme.spacing(1),
      display: 'grid',
      gridAutoFlow: 'row',
      gap: theme.spacing(1),
      alignItems: 'center',
      textAlign: 'center',
      '& span': {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium,
      },
      '& span:first-child': {
        fontSize: 20,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    info: {
      backgroundColor: theme.palette.background.paper,
      display: 'grid',
      gridTemplateColumns: 'min-content auto',
      gap: theme.spacing(1),
      padding: theme.spacing(1),
      alignItems: 'center',
    },
  }),
);

const EventCard = ({ event, className, ...props }: EventCardProps) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.wrapper, className)} {...props}>
      <div className={classes.date}>
        <span>{format(new Date(event.startAt), 'd')}</span>
        <span>{format(new Date(event.startAt), 'MMM')}</span>
        <span>{format(new Date(event.startAt), 'EEE')}</span>
      </div>
      <div className={classes.info}>
        <ScheduleRoundedIcon fontSize="small" />
        <span>
          {formatTimeDuration({
            start: new Date(event.startAt),
            end: new Date(event.endAt),
            options: { militaryTime: true },
          })}{' '}
          (
          {differenceInMinutes(new Date(event.endAt), new Date(event.startAt)) /
            60}{' '}
          hours)
        </span>
        <PersonOutlineOutlinedIcon fontSize="small" />
        <span>
          {formatPersonName(event.client, {
            capitaliseLastName: true,
            lastNameFirst: true,
            preferredAndLast: true,
          })}
        </span>
        <HomeOutlinedIcon fontSize="small" />
        <span>{event.locality + ', ' + event.region}</span>
      </div>
    </Box>
  );
};

export default EventCard;

import { TextField, TextFieldProps } from '@timed/common';
import { bankAccountNumberRegex } from '@timed/common/regexps/bankAccountNumber';
import { RegisterOptions } from 'react-hook-form';

export const validateBankAccountNumber: RegisterOptions = {
  pattern: {
    value: bankAccountNumberRegex,
    message: 'Invalid character(s)',
  },
  maxLength: { value: 40, message: 'Too long' },
};

const BankAccountNumberInput = (props: TextFieldProps) => (
  <TextField type="text" validation={validateBankAccountNumber} {...props} />
);

export default BankAccountNumberInput;

import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import { useAuth } from '@timed/auth';
import { Link, useRouter } from '@timed/common';
import { PageMetadata } from '@timed/page';
import clsx from 'clsx';

export type NavLinksProps = {
  links: Omit<PageMetadata, 'redirect'>[];
  size?: 'small' | 'large';
  hideBackground?: boolean;
};

const NavLinks = ({
  links,
  size = 'large',
  hideBackground = false,
}: NavLinksProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      links: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: hideBackground
          ? undefined
          : theme.spacing(size === 'small' ? 1 : 2),
        backgroundColor: hideBackground
          ? undefined
          : theme.palette.background.paper,
        gap: theme.spacing(size === 'small' && !hideBackground ? 1 : 2),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1),
          gap: theme.spacing(1),
        },
      },
      link: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        padding: theme.spacing(0, size === 'small' ? 1 : 2),
        height: size === 'small' ? 32 : 40,
        textOverflow: 'none',
        textDecoration: 'none',
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(0, 0.75),
          height: size === 'small' ? 24 : 32,
          fontSize: 12,
          lineHeight: 1,
        },
        '&:hover': {
          textDecoration: 'none',
        },
      },
      inactiveLink: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.default,
        border: '1px solid ' + theme.palette.divider,
        '&:hover': {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.primary.main,
          border: '1px solid ' + theme.palette.primary.dark,
        },
      },
      activeLink: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        border: '1px solid ' + theme.palette.primary.dark,
      },
      protectedInactiveLink: {
        color: theme.palette.text.primary,
        backgroundColor: yellow[100],
        border: '1px solid ' + yellow[400],
        '&:hover': {
          backgroundColor: yellow[600],
          border: '1px solid ' + yellow[700],
        },
      },
      protectedActiveLink: {
        color: theme.palette.text.primary,
        backgroundColor: yellow[600],
        border: '1px solid ' + yellow[700],
      },
    }),
  );

  const classes = useStyles();
  const { permissible } = useAuth();
  const { pathname } = useRouter();

  return (
    <Box className={classes.links}>
      {links
        .filter(
          ({ tester, admin, permissions }) =>
            (!tester && !admin && !permissions) ||
            (tester && permissible({ tester })) ||
            (admin && permissible({ admin })) ||
            (!!permissions && permissible({ permissions })),
        )
        .map((link, i) => (
          <Link
            key={i}
            to={link.path}
            className={clsx(
              classes.link,
              pathname === link.path
                ? false //(link.admin || link.permissions) && permissible(link)
                  ? classes.protectedActiveLink
                  : classes.activeLink
                : false //(link.admin || link.permissions) && permissible(link)
                ? classes.protectedInactiveLink
                : classes.inactiveLink,
            )}
          >
            {link.label}
          </Link>
        ))}
    </Box>
  );
};

export default NavLinks;

import {
  Box,
  createStyles,
  Divider,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { blue, lightBlue, red, yellow } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';
import CallMadeRoundedIcon from '@material-ui/icons/CallMadeRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import LockIcon from '@material-ui/icons/Lock';
import BackHandIcon from '@mui/icons-material/BackHand';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import MedicationLiquidSharpIcon from '@mui/icons-material/MedicationLiquidSharp';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import PsychologyIcon from '@mui/icons-material/Psychology';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import { _peopleClients, _peopleMembers } from '@timed/app';
import { AuthContextType, Protected, useAuth } from '@timed/auth';
import {
  ClientCreateBowelMovementFormModal,
  ClientCreateFoodButton,
  ClientCreateMedicationFormModal,
  ClientCreateObservationFormModal,
  ClientCreateObservationSeizureFormModal,
  ClientFormattedObservation,
  ClientUpdateBowelMovementFormModal,
  ClientUpdateMedicationFormModal,
  ClientUpdateObservationFormModal,
  ClientUpdateObservationSeizureFormModal,
} from '@timed/client';
import {
  Avatar,
  Block,
  Button,
  Clock,
  createDownloadLink,
  DataSize,
  formatAddress,
  formatPersonName,
  formatTimeDuration,
  intersperse,
  jsDateToLocalISO8601DateString,
  Link,
  Modal,
  ModalProps,
  roundNumber,
} from '@timed/common';
import {
  EventAssignAttendeeAutomaticallyButton,
  EventClaimsTable,
  EventClockButton,
  EventCreateFileFormModal,
  eventFilesMetadata,
  EventSummaryModalMenuButton,
  EventSummaryModalVerifyButton,
  EventUpdateCaseNotesFormModal,
  EventUpdateFormModal,
  EventUpdateTravelFormModal,
} from '@timed/event';
import {
  Client,
  ClientFileType,
  ClientObservation,
  ClientObservationMedication,
  Event,
  EventIssue,
  GetClientNotesQuery,
  GetEventByIdQuery,
  GetEventOwnAndRelatedByIdQuery,
  Maybe,
  Medication,
  Member,
  MemberPostNominalsWhereInput,
  OrderBy,
  Permission,
  PersonNamesFragment,
  useCloseEventIssueMutation,
  useDownloadClientCarePlanLazyQuery,
  useDownloadEventFileByIdLazyQuery,
  useGetClientEnergyIntakeLazyQuery,
  useGetClientNotesLazyQuery,
  useGetClientObservationsLazyQuery,
  useGetEventByIdLazyQuery,
  useGetEventClaimsLazyQuery,
  useGetEventFilesLazyQuery,
  useGetEventOwnAndRelatedByIdLazyQuery,
  useGetMemberPostNominalsLazyQuery,
  useGetMemberScheduleByIdLazyQuery,
  useGetMemberScheduleMineLazyQuery,
  useGetPublicHolidaysLazyQuery,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { ScheduleContextType } from '@timed/schedule';
import clsx from 'clsx';
import { subMilliseconds } from 'date-fns';
import {
  addDays,
  addHours,
  addMinutes,
  addWeeks,
  differenceInMinutes,
  format,
  formatDuration,
  formatISO,
  isAfter,
  isEqual,
  startOfDay,
  startOfWeek,
  subMinutes,
  subWeeks,
} from 'date-fns';
import { eachDayOfInterval, isBefore } from 'date-fns/esm';
import _, { upperFirst } from 'lodash';
import { useModal } from 'mui-modal-provider';
import { useEffect, useMemo, useState } from 'react';

type EventSummaryModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  auth: AuthContextType;
  eventId: Event['id'];
  selectedPayrollPeriod: Date;
  setTime: ScheduleContextType['setTime'];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        width: 800,
        gap: theme.spacing(4),
      },
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      height: 18,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(4),
    },
    error: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.error.main,
      border: '1px solid ' + theme.palette.error.main,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      '& .MuiTypography-root': {
        fontSize: 13,
      },
    },
    cancelled: {
      backgroundColor: 'lightyellow',
      border: '1px solid gold',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      // margin: theme.spacing(2, 2, 0, 2),
      '& .MuiTypography-root': {
        fontSize: 13,
      },
    },
    people: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        // gridTemplateColumns: 'auto auto',
        gap: theme.spacing(4),
      },
    },
    attendee: {
      display: 'flex',
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: '50%',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
      },
      '& .MuiTypography-root': {
        fontSize: 13,
      },
    },
    attendeeText: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
      },
    },
    avatar: {
      width: theme.spacing(19),
      height: theme.spacing(19),
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: 1,
    },
    avatarMini: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      fontSize: 8,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: 1,
    },
    scrollableText: {
      whiteSpace: 'pre-wrap',
      fontSize: 13,
      [theme.breakpoints.up('md')]: {
        maxHeight: 700,
        overflowY: 'auto',
      },
    },
    schedulingNotes: {
      [theme.breakpoints.up('md')]: {
        maxHeight: 80,
      },
    },
    actions: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      '& div': {
        display: 'flex',
        gap: theme.spacing(2),
        '& .MuiButton-root': {
          flexBasis: '100%',
        },
      },
    },
    smallButton: {
      fontSize: 11,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      flexBasis: '100%',
      color: 'black',
    },
    items: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.5),
      [theme.breakpoints.down('sm')]: {
        height: 0,
      },
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      color: theme.palette.text.secondary,
      [theme.breakpoints.up('md')]: {
        maxHeight: 200,
        overflowY: 'auto',
      },
    },
    itemTitle: {
      fontSize: 12,
      fontWeight: theme.typography.fontWeightMedium,
    },
    attachments: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    attachment: {
      color: theme.palette.text.primary,
      padding: theme.spacing(0, 2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.secondary.main,
      display: 'flex',
      gap: theme.spacing(0.5),
      alignItems: 'center',
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
        cursor: 'pointer',
      },
      '& .MuiTypography-root:first-child': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    attachmentTexts: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
    },
    attachmentText: {
      fontSize: 10,
      lineHeight: 1,
    },
    observations: {
      fontFamily: 'monospace',
      cursor: 'pointer',
      fontSize: 12,
    },
    observationControls: {
      cursor: 'pointer',
      fontSize: 12,
    },
    recorder: {
      fontSize: 9,
    },
    description: {
      display: 'flex',
      gap: theme.spacing(2),
    },
    message: {
      width: 'max-content',
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      alignItems: 'center',
      marginTop: theme.spacing(2),
      padding: theme.spacing(2, 3, 2, 2),
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
        marginTop: theme.spacing(1),
        padding: theme.spacing(0, 1),
      },
    },
    messageText: {
      '& .MuiTypography-root:first-child': {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    infoMessage: {
      backgroundColor: blue[50],
      border: '1px solid ' + blue[800],
    },
    warnMessage: {
      backgroundColor: yellow[50],
      border: '1px solid ' + yellow[800],
    },
    errorMessage: {
      backgroundColor: red[50],
      border: '1px solid ' + red[800],
    },
    flexLine: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
    notes: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    note: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper2,
      fontSize: 11,
      cursor: 'pointer',
    },
    noteAuthor: {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 11,
      color: theme.palette.text.primary,
    },
    infoButtons: {
      display: 'flex',
      gap: theme.spacing(2),
    },
    infoButton: {
      height: 76,
      width: 76,
      fontSize: '19px !IMPORTANT',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    helpButton: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
      fontWeight: theme.typography.fontWeightBold,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
    carePlanButton: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    memberNameLine: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    issues: {
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(2),
      backgroundColor: red[50],
      padding: theme.spacing(4),
      border: '1px solid ' + red[500],
      '& .MuiTypography-root': {
        fontSize: 22,
      },
    },
    issue: {
      display: 'flex',
      gap: theme.spacing(2),
      '& .MuiTypography-root': {
        fontSize: 18,
      },
    },
  }),
);

const EventSummaryModal = ({
  onClose,
  auth,
  eventId,
  selectedPayrollPeriod,
  setTime,
  ...modalProps
}: EventSummaryModalProps) => {
  const classes = useStyles();

  const theme = useTheme();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { showModal } = useModal();

  const { member, org, permissible } = useAuth();

  const [errorText, setErrorText] = useState<string | null>(null);

  const [getEvent, eventResponse] = useGetEventByIdLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: eventId,
      eventIssuesInput: {
        where: {
          closedAt: { _eq: null },
        },
        orderBy: [{ createdAt: OrderBy.ASC }],
      },
      fileTypeInput: { type: ClientFileType.CAREPLAN },
    },
  });

  const [getEventOwnAndRelated, eventOwnAndRelatedResponse] =
    useGetEventOwnAndRelatedByIdLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        id: eventId,
        fileTypeInput: { type: ClientFileType.CAREPLAN },
        eventIssuesInput: {
          where: { closedAt: null },
        },
      },
    });

  const [getEventNotes, eventNotesResponse] = useGetClientNotesLazyQuery();

  const [getEventClaims, eventClaimsResponse] = useGetEventClaimsLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      eventClaimsInput: {
        where: {
          dismissedAt: { _eq: null },
          member: { archive: null, delete: null },
          event: { id: { _eq: eventId } },
        },
        orderBy: [{ createdAt: OrderBy.ASC }],
      },
      hoursInput: {
        where: {
          _or: [
            subWeeks(
              startOfWeek(selectedPayrollPeriod, { weekStartsOn: 1 }),
              1,
            ),
            startOfWeek(selectedPayrollPeriod, { weekStartsOn: 1 }),
            addWeeks(
              startOfWeek(selectedPayrollPeriod, { weekStartsOn: 1 }),
              1,
            ),
          ].map((date) => ({
            date: { _eq: formatISO(date, { representation: 'date' }) },
          })),
        },
        orderBy: [{ createdAt: OrderBy.ASC }],
      },
    },
  });

  const [getObservations, observationsResponse] =
    useGetClientObservationsLazyQuery({
      variables: {
        input: {
          where: { event: { id: { _eq: eventId } } },
          orderBy: [{ date: OrderBy.ASC }],
        },
      },
    });

  const [getMemberPostNominals, memberPostNominalsResponse] =
    useGetMemberPostNominalsLazyQuery({ fetchPolicy: 'network-only' });

  const [getFiles, filesResponse] = useGetEventFilesLazyQuery({
    variables: {
      input: {
        where: { owner: { id: { _eq: eventId } } },
        orderBy: [{ dateOfFile: OrderBy.ASC }],
      },
    },
  });

  const [downloadFile] = useDownloadEventFileByIdLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => createDownloadLink(data.downloadEventFileById.path),
  });

  const [downloadCarePlan] = useDownloadClientCarePlanLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => createDownloadLink(data.downloadClientCarePlan.path),
  });

  const [getMember, memberResponse] = useGetMemberScheduleByIdLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [getRedactedMember, redactedMemberResponse] =
    useGetMemberScheduleMineLazyQuery({
      fetchPolicy: 'network-only',
    });

  const [getEnergyIntake, energyInTakeResponse] =
    useGetClientEnergyIntakeLazyQuery();

  const [getPublicHolidays, publicHolidaysResponse] =
    useGetPublicHolidaysLazyQuery({ fetchPolicy: 'network-only' });

  const [closeEventIssue] = useCloseEventIssueMutation({
    onCompleted: (data) => {
      const cache = memberResponse.client.cache;

      cache.modify({
        id: `Event:${data.closeEventIssue.event.id}`,
        fields: {
          issues(existing: EventIssue[] = []) {
            return existing.filter(({ id }) => id !== data.closeEventIssue.id);
          },
        },
      });
    },
  });

  const isEvent = (
    e?: GetEventByIdQuery | GetEventOwnAndRelatedByIdQuery,
  ): e is GetEventByIdQuery => !!e && e.hasOwnProperty('eventById');

  const hasPermission = useMemo(() => {
    return auth.permissible({
      permissions: [
        Permission.EVENT_READ,
        Permission.CLIENT_READ,
        Permission.MEMBER_READ,
      ],
    });
  }, [auth]);

  const [data, loading] = useMemo(() => {
    return [
      hasPermission ? eventResponse.data : eventOwnAndRelatedResponse.data,
      eventResponse.loading || eventOwnAndRelatedResponse.loading,
    ];
  }, [hasPermission, eventResponse, eventOwnAndRelatedResponse]);

  const event:
    | GetEventByIdQuery['eventById']
    | GetEventOwnAndRelatedByIdQuery['eventOwnAndRelatedById']
    | undefined = useMemo(() => {
    return (
      (!!data &&
        (isEvent(data) ? data.eventById : data.eventOwnAndRelatedById)) ||
      undefined
    );
  }, [data]);

  useEffect(() => {
    if (
      observationsResponse.called &&
      !observationsResponse.loading &&
      eventResponse.called &&
      !eventResponse.loading
    ) {
      const whereOrObjects: MemberPostNominalsWhereInput['_or'] = [];

      if (event?.member)
        whereOrObjects.push({
          date: {
            _lte: jsDateToLocalISO8601DateString(new Date(event?.startAt)),
          },
          member: { id: { _eq: event?.member.id } },
        });

      if (
        observationsResponse.data?.clientObservations.length &&
        !!observationsResponse.data.clientObservations[0].createdBy
      )
        _.union(
          whereOrObjects,
          observationsResponse.data?.clientObservations.map(
            ({ date, createdBy }) => ({
              date: { _lte: jsDateToLocalISO8601DateString(new Date(date)) },
              member: { id: { _eq: createdBy!.id } },
            }),
          ),
        );

      getMemberPostNominals({
        variables: {
          input: {
            orderBy: [{ date: OrderBy.ASC }],
            where: { _or: whereOrObjects },
          },
        },
      });
    }
  }, [
    observationsResponse,
    eventResponse,
    event?.member,
    event?.startAt,
    getMemberPostNominals,
  ]);

  /**
   * Event has an altered state and is non-edittable.
   */
  const nonEdittable = useMemo(
    () =>
      !!event?.cancel ||
      (isEvent(data) && (!!data?.eventById.delete || !!data.eventById.archive)),
    [data, event?.cancel],
  );

  useLoadingEffect(loading);

  // Fetch event
  useEffect(() => {
    if (hasPermission) {
      getEvent();
      getEventClaims();
    } else {
      getEventOwnAndRelated();
    }
  }, [hasPermission, getEvent, getEventClaims, getEventOwnAndRelated]);

  // Fetch schedule of attending member
  useEffect(() => {
    if (!!data) {
      if (
        isEvent(data)
          ? !!data.eventById.member
          : data.eventOwnAndRelatedById.member?.id === member?.id
      ) {
        const endOfPayrollPeriod = addDays(selectedPayrollPeriod, 6);

        if (isEvent(data)) {
          getMember({
            variables: {
              id: data.eventById.member!.id,
              scheduleInput: {
                startAt: formatISO(selectedPayrollPeriod, {
                  representation: 'date',
                }),
                endAt: formatISO(endOfPayrollPeriod, {
                  representation: 'date',
                }),
              },
            },
          });
        } else {
          getRedactedMember({
            variables: {
              scheduleInput: {
                startAt: formatISO(selectedPayrollPeriod, {
                  representation: 'date',
                }),
                endAt: formatISO(endOfPayrollPeriod, {
                  representation: 'date',
                }),
              },
            },
          });
        }
      }
    }
  }, [data, getMember, getRedactedMember, selectedPayrollPeriod, member?.id]);

  // Fetch notes for this event
  useEffect(() => {
    if (!eventNotesResponse.data && !eventNotesResponse.loading)
      getEventNotes({
        variables: {
          input: {
            where: {
              event: {
                id: {
                  _eq: eventId,
                },
              },
            },
          },
        },
      });
  }, [
    eventId,
    eventNotesResponse.data,
    eventNotesResponse.loading,
    getEventNotes,
  ]);

  // Fetch client observations for this event
  useEffect(() => {
    if (!observationsResponse.data && !observationsResponse.loading)
      getObservations();
  }, [
    observationsResponse.data,
    observationsResponse.loading,
    getObservations,
  ]);

  // Fetch files attached to this event
  useEffect(() => {
    if (!filesResponse.data && !filesResponse.loading) getFiles();
  }, [filesResponse.data, filesResponse.loading, getFiles]);

  // Fetch client energy intake.
  useEffect(() => {
    if (!energyInTakeResponse.data && !energyInTakeResponse.loading && !!event)
      getEnergyIntake({
        variables: {
          input: {
            where: {
              client: { id: { _eq: event.client.id } },
              date: {
                _gte: startOfDay(setTime(event.startAt)),
                _lt: addDays(
                  startOfDay(
                    setTime(
                      isEqual(
                        addDays(startOfDay(setTime(event.endAt)), 1),
                        setTime(event.endAt),
                      )
                        ? event.startAt
                        : event.endAt,
                    ),
                  ),
                  1,
                ),
              },
            },
          },
        },
      });
  }, [
    energyInTakeResponse.data,
    energyInTakeResponse.loading,
    getEnergyIntake,
    event,
    setTime,
  ]);

  // Fetch public holidays this event occurs on
  useEffect(() => {
    if (event) {
      getPublicHolidays({
        variables: {
          input: {
            where: {
              date: {
                _in: eachDayOfInterval({
                  start: new Date(event.startAt),
                  end: subMilliseconds(new Date(event.endAt), 1),
                }).map((date) => format(date, 'yyyy-MM-dd')),
              },
              _or: [{ region: null }, { region: { _eq: event.region } }],
            },
            orderBy: [{ date: OrderBy.ASC }],
          },
        },
      });
    }
  }, [event, getPublicHolidays]);

  const totalEnergyIntake = useMemo<{ date: Date; kj: number }[] | null>(() => {
    if (!energyInTakeResponse.data?.clientObservations.length) return null;

    const foodObservations =
      energyInTakeResponse.data.clientObservations.filter(
        ({ givenFood }) => !!givenFood?.length,
      );

    // const uniqueDates = foodObservations
    //   .filter(
    //     ({ date }, i, self) =>
    //       self.findIndex(({ date: d }) =>
    //         isEqual(startOfDay(new Date(d)), startOfDay(new Date(date))),
    //       ) === i,
    //   )
    //   .map(({ date }) => date);

    const uniqueDates = eachDayOfInterval({
      start: setTime(event?.startAt),
      end: setTime(event?.endAt),
    });

    const totals: { date: Date; kj: number }[] = [];

    uniqueDates.forEach((date) =>
      totals.push({
        date,
        kj: foodObservations
          .filter(({ date: d }) =>
            isEqual(startOfDay(setTime(d)), startOfDay(date)),
          )
          .map(({ givenFood }) => givenFood![0].kilojoules || 0)
          .reduce((a, b) => a + b, 0),
      }),
    );

    return !!totals.length ? totals : null;
  }, [energyInTakeResponse.data, event?.startAt, event?.endAt, setTime]);

  const membersScheduledHoursThisWeek = useMemo<number | null>(() => {
    return memberResponse.data?.memberById.schedule?.totalMinutes
      ? memberResponse.data.memberById.schedule.totalMinutes / 60
      : redactedMemberResponse.data?.me.member?.schedule.totalMinutes
      ? redactedMemberResponse.data?.me.member?.schedule.totalMinutes / 60
      : null;
  }, [
    memberResponse.data?.memberById.schedule?.totalMinutes,
    redactedMemberResponse.data?.me.member?.schedule.totalMinutes,
  ]);

  const membersAllowedHoursThisWeek = useMemo<number | null>(() => {
    return memberResponse.data?.memberById.schedule?.allowedMinutes
      ? memberResponse.data.memberById.schedule.allowedMinutes / 60
      : redactedMemberResponse.data?.me.member?.schedule.allowedMinutes
      ? redactedMemberResponse.data?.me.member?.schedule.allowedMinutes / 60
      : null;
  }, [
    memberResponse.data?.memberById.schedule?.allowedMinutes,
    redactedMemberResponse.data?.me.member?.schedule.allowedMinutes,
  ]);

  const handleOpenEditModal = () => {
    if (isEvent(data)) {
      const modal: { hide: () => void } = showModal(EventUpdateFormModal, {
        onClose: () => {
          modal.hide();
        },
        permissible: auth.permissible,
        closeSummaryPage: onClose,
        event: data.eventById,
        claims: eventClaimsResponse.data?.eventClaims ?? [],
        setTime,
      });
    }
  };

  // const handleOpenAssignAttendeeModal = () => {
  //   if (isEvent(data)) {
  //     const modal: { hide: () => void } = showModal(EventAssignAttendeeFormModal, {
  //       onClose: () => {
  //         modal.hide();
  //       },
  //       event: data.eventById,
  //     });
  //   }
  // };

  const handleOpenEditCaseNotesModal = (
    note?: GetClientNotesQuery['clientNotes'][0],
  ) => {
    if (event) {
      const modal: { hide: () => void } = showModal(
        EventUpdateCaseNotesFormModal,
        {
          onClose: () => {
            modal.hide();
          },
          event,
          note,
        },
      );
    }
  };

  const handleOpenEditTravelModal = () => {
    if (event) {
      const modal: { hide: () => void } = showModal(
        EventUpdateTravelFormModal,
        {
          onClose: () => {
            modal.hide();
          },
          event,
        },
      );
    }
  };

  const handleOpenRecordObsModal = () => {
    if (event) {
      const modal: { hide: () => void } = showModal(
        ClientCreateObservationFormModal,
        {
          onClose: () => {
            modal.hide();
          },
          event,
        },
      );
    }
  };

  const handleOpenUpdateObsModal = (
    observation: Pick<
      ClientObservation,
      | 'id'
      | 'date'
      | 'bloodSystolic'
      | 'bloodDiastolic'
      | 'heart'
      | 'temp'
      | 'spo2'
      | 'respiration'
    > & { client: Pick<Client, 'id'> } & {
      member?: Pick<Member, 'id'> | null;
    } & { createdBy?: (Pick<Member, 'id'> & PersonNamesFragment) | null },
  ) => {
    if (event) {
      const modal: { hide: () => void } = showModal(
        ClientUpdateObservationFormModal,
        {
          onClose: () => {
            modal.hide();
          },
          observation,
        },
      );
    }
  };

  const handleOpenRecordBmModal = () => {
    if (event) {
      const modal: { hide: () => void } = showModal(
        ClientCreateBowelMovementFormModal,
        {
          onClose: () => {
            modal.hide();
          },
          event,
        },
      );
    }
  };

  const handleOpenUpdateBmModal = (
    observation: Pick<
      ClientObservation,
      'id' | 'date' | 'notes' | 'stoolType'
    > & {
      client: Pick<Client, 'id'>;
    },
  ) => {
    if (event) {
      const modal: { hide: () => void } = showModal(
        ClientUpdateBowelMovementFormModal,
        {
          onClose: () => {
            modal.hide();
          },
          observation,
        },
      );
    }
  };

  const handleOpenUpdateSeizureModal = (
    observation: Pick<
      ClientObservation,
      | 'id'
      | 'date'
      | 'notes'
      | 'seizureDuration'
      | 'seizureType'
      | 'seizureLostConsciousness'
      | 'postSeizureWalkDuration'
    > & {
      client: Pick<Client, 'id'>;
    },
  ) => {
    if (event) {
      const modal: { hide: () => void } = showModal(
        ClientUpdateObservationSeizureFormModal,
        {
          onClose: () => {
            modal.hide();
          },
          observation,
        },
      );
    }
  };

  const handleOpenRecordMedicationModal = () => {
    if (event) {
      const modal: { hide: () => void } = showModal(
        ClientCreateMedicationFormModal,
        {
          onClose: () => {
            modal.hide();
          },
          event,
          clientId: event.client.id,
        },
      );
    }
  };

  const handleOpenRecordSeizuresModal = () => {
    if (event) {
      const modal: { hide: () => void } = showModal(
        ClientCreateObservationSeizureFormModal,
        {
          onClose: () => {
            modal.hide();
          },
          event,
          clientId: event.client.id,
        },
      );
    }
  };

  const handleOpenUpdateMedicationModal = (
    observation: Pick<
      ClientObservation,
      'id' | 'date' | 'notes' | 'nursesName'
    > & {
      client: Pick<Client, 'id'>;
      member?: Pick<Member, 'id'> | null;
      givenMedications?: Maybe<
        Array<
          Pick<ClientObservationMedication, 'id' | 'quantity'> & {
            medication: Pick<Medication, 'id'>;
          }
        >
      >;
    },
  ) => {
    if (event) {
      const modal: { hide: () => void } = showModal(
        ClientUpdateMedicationFormModal,
        {
          onClose: () => {
            modal.hide();
          },
          observation,
        },
      );
    }
  };

  const handleOpenCreateFileModal = () => {
    if (event) {
      const modal: { hide: () => void } = showModal(EventCreateFileFormModal, {
        onClose: () => {
          modal.hide();
        },
        event,
      });
    }
  };

  return !event ? null : (
    <Modal disablePortal onClose={onClose} {...modalProps}>
      <Block
        titleProps={{ variant: 'h3' }}
        title={
          <>
            {(event.publicHoliday ||
              !!publicHolidaysResponse.data?.publicHolidays.length) && (
              <Typography variant="caption" color="primary">
                PUBLIC HOLIDAY
                {!!publicHolidaysResponse.data?.publicHolidays.length && ' - '}
                {!!publicHolidaysResponse.data?.publicHolidays.length &&
                  intersperse({
                    array: publicHolidaysResponse.data.publicHolidays.map(
                      ({ description, region }) =>
                        `${description}${region ? ' (' + region + ')' : ''}`,
                    ),
                  })}
              </Typography>
            )}
            <Typography className={classes.bold}>
              {format(setTime(event.startAt), 'EEE d MMM')}
              {', '}
              {formatTimeDuration({
                start: setTime(event.startAt),
                end: setTime(event.endAt),
                options: {
                  militaryTime: true,
                  seperator: ' - ',
                },
              })}
              {' ('}
              {formatDuration(
                {
                  hours: Math.floor(
                    differenceInMinutes(
                      setTime(event.endAt),
                      setTime(event.startAt),
                    ) / 60,
                  ),
                  minutes:
                    differenceInMinutes(
                      setTime(event.endAt),
                      setTime(event.startAt),
                    ) % 60,
                },
                { format: ['hours', 'minutes'], delimiter: ', ' },
              )
                .replace('hours', 'hrs')
                .replace('minutes', 'mins')}
              {')'}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              Local time:{' '}
              <Clock militaryTime dateFirst dateFormat="EEE d MMM," />
            </Typography>
          </>
        }
        description={
          <Box className={classes.description}>
            {isEvent(data) &&
              data.eventById.locked &&
              permissible({ permissions: Permission.EVENT_READ }) && (
                <Box className={clsx(classes.message, classes.warnMessage)}>
                  {!smDown && <LockIcon style={{ fill: yellow[800] }} />}
                  <Box className={classes.messageText}>
                    <Typography variant="body1">Locked</Typography>
                  </Box>
                </Box>
              )}
            {event.client.requireHandover && (
              <Box className={clsx(classes.message, classes.infoMessage)}>
                {!smDown && <ErrorRoundedIcon style={{ fill: blue[800] }} />}
                <Box className={classes.messageText}>
                  <Typography variant="body1">Handover required</Typography>
                </Box>
              </Box>
            )}
            {event.requireCar && (
              <Box className={clsx(classes.message, classes.infoMessage)}>
                {!smDown && <ErrorRoundedIcon style={{ fill: blue[800] }} />}
                <Box className={classes.messageText}>
                  <Typography variant="body1">Car required</Typography>
                </Box>
              </Box>
            )}
            {event.clockedOnAt &&
              isAfter(
                new Date(event.clockedOnAt).setSeconds(0),
                new Date(event.startAt),
              ) && (
                <Box className={clsx(classes.message, classes.errorMessage)}>
                  {!smDown && <ErrorRoundedIcon style={{ fill: red[800] }} />}
                  <Box className={classes.messageText}>
                    <Typography variant="body1">Started late</Typography>
                  </Box>
                </Box>
              )}
            {!event.cancel &&
              !event.clockedOnAt &&
              isAfter(new Date(), new Date(event.startAt)) && (
                <Box className={clsx(classes.message, classes.warnMessage)}>
                  {!smDown && (
                    <ErrorRoundedIcon style={{ fill: yellow[800] }} />
                  )}
                  <Box className={classes.messageText}>
                    <Typography variant="body1">Please clock on</Typography>
                  </Box>
                </Box>
              )}
            {!event.cancel &&
              !eventNotesResponse.data?.clientNotes.length &&
              isAfter(new Date(), new Date(event.startAt)) && (
                <Box className={clsx(classes.message, classes.warnMessage)}>
                  {!smDown && (
                    <ErrorRoundedIcon style={{ fill: yellow[800] }} />
                  )}
                  <Box className={classes.messageText}>
                    <Typography variant="body1">Missing notes</Typography>
                  </Box>
                </Box>
              )}
          </Box>
        }
        topRight={
          <Box className={classes.controls}>
            {permissible({ admin: true }) && (
              <EventSummaryModalVerifyButton
                event={event}
                closeSummaryModal={onClose}
              />
            )}
            {hasPermission && isEvent(data) && (
              <EventSummaryModalMenuButton
                event={data.eventById}
                claims={eventClaimsResponse.data?.eventClaims ?? []}
                closeSummaryPage={onClose}
                setTime={setTime}
                permissible={auth.permissible}
              />
            )}
            <IconButton onClick={onClose}>
              <CloseRoundedIcon />
            </IconButton>
          </Box>
        }
      >
        <Box className={classes.wrapper}>
          {!!errorText && (
            <Box className={classes.error}>
              <Typography>{errorText}</Typography>
            </Box>
          )}
          {event.cancel && (
            <Box className={classes.cancelled}>
              <Typography className={classes.bold}>
                This shift is cancelled.
              </Typography>
              <Typography>
                Cancelled
                {isEvent(data) &&
                  ' by ' +
                    formatPersonName(data.eventById.cancel!.actor!, {
                      capitaliseLastName: true,
                    })}{' '}
                at{' '}
                {format(setTime(event.cancel?.date), "h:mma 'on' dd/MM/yyyy")}.
              </Typography>
              {hasPermission && (
                <Typography>
                  {event.cancel?.reason
                    ? 'Reason: ' + event.cancel.reason
                    : 'No reason was provided.'}
                </Typography>
              )}
            </Box>
          )}
          {isEvent(data) && !!data.eventById.delete && (
            <Box className={classes.cancelled}>
              <Typography className={classes.bold}>
                This shift was deleted.
              </Typography>
              <Typography>
                Deleted by{' '}
                {formatPersonName(data.eventById.delete.actor!, {
                  capitaliseLastName: true,
                })}{' '}
                at{' '}
                {format(
                  setTime(data.eventById.delete.date),
                  "h:mma 'on' dd/MM/yyyy",
                )}
                .
              </Typography>
              <Typography>
                {!!data.eventById.delete.reason
                  ? 'Reason: ' + data.eventById.delete.reason
                  : 'No reason was provided.'}
              </Typography>
            </Box>
          )}
          {!!event.issues.filter(({ closedAt }) => !closedAt).length && (
            <Box className={classes.issues}>
              <Typography variant="h3" className={classes.bold}>
                There are issues with this shift:
              </Typography>
              {event.issues
                .filter(({ closedAt }) => !closedAt)
                .map((issue, i) => (
                  <Box key={i} className={classes.issue}>
                    <Typography>
                      {i + 1}.{' '}
                      {upperFirst(
                        issue.type.toLocaleLowerCase().replaceAll('_', ' '),
                      )}
                    </Typography>
                    <Protected admin>
                      <Button
                        variant="contained"
                        size="small"
                        color="default"
                        onClick={() => {
                          closeEventIssue({ variables: { id: issue.id } });
                        }}
                      >
                        Resolved
                      </Button>
                    </Protected>
                  </Box>
                ))}
            </Box>
          )}
          <Box className={classes.people}>
            <Box className={classes.attendee}>
              <Box className={classes.attendeeText}>
                <Avatar
                  className={classes.avatar}
                  color={event.member?.color}
                  content={
                    !!event.member
                      ? [
                          event.member?.firstName,
                          event.member.middleName,
                          event.member?.lastName,
                        ]
                      : ['?']
                  }
                />
                <Box>
                  <Typography className={classes.bold}>
                    Support Worker
                  </Typography>
                  <Typography color="textPrimary">
                    {event.member ? (
                      permissible({ permissions: Permission.MEMBER_READ }) ? (
                        <div className={classes.memberNameLine}>
                          <Link
                            to={_peopleMembers.path + '/' + event.member.id}
                            className={classes.link}
                          >
                            {formatPersonName(
                              {
                                ...event.member,
                                postNominals:
                                  memberPostNominalsResponse.data?.memberPostNominals.find(
                                    ({ date, member }) =>
                                      member.id === event.member!.id &&
                                      isBefore(
                                        new Date(date),
                                        new Date(event.startAt),
                                      ),
                                  )?.value,
                              },
                              {
                                capitaliseLastName: true,
                                lastNameFirst: true,
                                postNominals: true,
                              },
                            )}
                            <CallMadeRoundedIcon style={{ width: 10 }} />
                          </Link>
                          <Protected tester>
                            {isEvent(data) &&
                              data.eventById.member?.bonusEligible && (
                                <span title="Bonus pay multiplier eligible">
                                  b
                                </span>
                              )}
                          </Protected>
                          {isEvent(data) &&
                            data.eventById.member?.canPromptMedication && (
                              <MedicationLiquidSharpIcon fontSize="small" />
                            )}
                          {isEvent(data) &&
                            data.eventById.member
                              ?.hasCertSupportingSafeMeals && (
                              <LocalDiningIcon fontSize="small" />
                            )}
                          {isEvent(data) &&
                            data.eventById.member
                              ?.hasCertEffectiveCommunication && (
                              <RecordVoiceOverIcon fontSize="small" />
                            )}
                          {isEvent(data) && data.eventById.member?.hasCar && (
                            <DirectionsCarIcon fontSize="small" />
                          )}
                          {isEvent(data) &&
                            data.eventById.member
                              ?.hasCertPreventingDementia && (
                              <PsychologyIcon fontSize="small" />
                            )}
                          {isEvent(data) &&
                            data.eventById.member
                              ?.hasCertUnderstandingDementia && (
                              <PsychologyAltIcon fontSize="small" />
                            )}
                          {isEvent(data) &&
                            data.eventById.member?.hasCertManualHandling && (
                              <BackHandIcon fontSize="small" />
                            )}
                          {isEvent(data) &&
                            data.eventById.member?.hasCertSeizureRecognition &&
                            'ep'}
                          {isEvent(data) && data.eventById.member?.canHoist && (
                            <PrecisionManufacturingOutlinedIcon fontSize="small" />
                          )}
                        </div>
                      ) : (
                        formatPersonName(event.member, {
                          capitaliseLastName: true,
                          lastNameFirst: true,
                          postNominals: true,
                        })
                      )
                    ) : (
                      'TBA'
                    )}
                  </Typography>
                  {!!event.passive && (
                    <Typography
                      variant="body2"
                      style={{ color: blue[500] }}
                      className={classes.bold}
                    >
                      Passive overnight{' '}
                      {formatTimeDuration({
                        start: setTime(event.passiveStartAt),
                        end: addHours(setTime(event.passiveStartAt), 8),
                        options: { militaryTime: true, seperator: ' - ' },
                      })}
                      {event.activeAssist && ' (active assist)'}
                    </Typography>
                  )}
                  {((!!member && member.id === event.member?.id) ||
                    isEvent(data)) && (
                    <Typography
                      variant="body2"
                      color={
                        !!event.member?.lateCount.length &&
                        event.member.lateCount[0] / event.member.lateCount[1] >=
                          0.5
                          ? 'error'
                          : 'textSecondary'
                      }
                      className={classes.flexLine}
                    >
                      {!!event.member?.lateCount.length &&
                        event.member.lateCount[0] / event.member.lateCount[1] >=
                          0.5 && (
                          <ErrorRoundedIcon
                            style={{ fill: red[800], height: 14, width: 14 }}
                          />
                        )}
                      Late{' '}
                      {!!event.member?.lateCount.length
                        ? roundNumber(
                            (event.member.lateCount[0] /
                              event.member.lateCount[1]) *
                              100,
                            1,
                          )
                        : ''}
                      % of the time
                    </Typography>
                  )}
                  {membersScheduledHoursThisWeek !== null && (
                    <Typography
                      variant="body2"
                      className={
                        !!membersScheduledHoursThisWeek &&
                        !!membersAllowedHoursThisWeek &&
                        membersAllowedHoursThisWeek <
                          membersScheduledHoursThisWeek
                          ? classes.bold
                          : undefined
                      }
                      color={
                        !!membersScheduledHoursThisWeek &&
                        !!membersAllowedHoursThisWeek &&
                        membersAllowedHoursThisWeek <
                          membersScheduledHoursThisWeek
                          ? 'error'
                          : 'textSecondary'
                      }
                    >
                      {!memberResponse.loading &&
                      !redactedMemberResponse.loading
                        ? `Working ${roundNumber(
                            membersScheduledHoursThisWeek || 0,
                            2,
                          )}${
                            !!membersAllowedHoursThisWeek
                              ? '/' +
                                roundNumber(membersAllowedHoursThisWeek, 2)
                              : ''
                          } hours this week`
                        : 'Loading schedule...'}
                    </Typography>
                  )}
                </Box>
              </Box>
              {smDown && (
                <Box className={classes.infoButtons}>
                  {event.client.hasFile &&
                    (permissible({
                      permissions: Permission.CLIENT_FILE_READ,
                    }) ||
                      member?.id === event.member?.id) && (
                      <Box
                        className={clsx(
                          classes.infoButton,
                          classes.carePlanButton,
                        )}
                        onClick={() => {
                          downloadCarePlan({
                            variables: { id: event.client.id },
                          });
                        }}
                      >
                        CARE PLAN
                      </Box>
                    )}
                  {member?.id === event.member?.id &&
                    isAfter(
                      new Date(),
                      subMinutes(new Date(event.startAt), 95),
                    ) &&
                    isBefore(
                      new Date(),
                      addMinutes(new Date(event.endAt), 300),
                    ) && (
                      <Link
                        to="tel:0410332999"
                        className={clsx(classes.infoButton, classes.helpButton)}
                      >
                        HELP ME
                      </Link>
                    )}
                </Box>
              )}
            </Box>
            {!smDown && (
              <Box className={classes.attendee}>
                {' '}
                <Box className={classes.attendeeText}>
                  <Avatar
                    className={classes.avatar}
                    color={event.client?.color}
                    content={
                      !!event.client
                        ? [
                            event.client?.firstName,
                            event.client?.middleName,
                            event.client?.lastName,
                          ]
                        : ['?']
                    }
                  />
                  <Box>
                    <Typography className={classes.bold}>
                      Participant
                    </Typography>
                    <Typography color="textPrimary">
                      <Link
                        to={_peopleClients.path + '/' + event.client.id}
                        className={classes.link}
                      >
                        {formatPersonName(event.client, {
                          capitaliseLastName: true,
                          lastNameFirst: true,
                        })}
                        <CallMadeRoundedIcon style={{ width: 10 }} />
                      </Link>
                    </Typography>

                    <Typography variant="body2" color="textSecondary">
                      <Link
                        to={
                          'https://www.google.com/maps/search/' +
                          formatAddress(event)
                        }
                        className={classes.link}
                      >
                        {formatAddress(event) || 'None.'}
                        <CallMadeRoundedIcon style={{ width: 10 }} />
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>

          {/* {auth.member?.id === event.member?.id && (
            <EventCantAttendBox
              schedulingContactPerson={auth.org?.schedulingContactPerson}
            />
          )} */}

          {(hasPermission || auth?.member?.id === event.member?.id) && (
            <Box className={classes.actions}>
              {event.member &&
                !nonEdittable &&
                (permissible({ permissions: Permission.EVENT_WRITE }) ||
                  event.member.id === member?.id) && (
                  <EventClockButton setErrorText={setErrorText} event={event} />
                )}
              {hasPermission &&
                isBefore(setTime(new Date()), setTime(event.endAt)) && (
                  <Box>
                    <Button
                      variant="contained"
                      disabled={nonEdittable}
                      color="primary"
                      onClick={() => {
                        handleOpenEditModal();
                        // handleOpenAssignAttendeeModal();
                      }}
                    >
                      {!!event.member
                        ? 'Find replacement'
                        : 'Assign Support Worker'}
                    </Button>
                    <EventAssignAttendeeAutomaticallyButton
                      eventId={event.id}
                      // disabled={nonEdittable}
                      disabled
                      setErrorText={setErrorText}
                    />
                  </Box>
                )}

              {(!!event.clockedOnAt || permissible({ admin: true })) && (
                <Box>
                  <Button
                    variant="contained"
                    disabled={nonEdittable}
                    onClick={() => {
                      handleOpenEditCaseNotesModal(
                        eventNotesResponse.data?.clientNotes.find(
                          ({ commentedBy }) => member?.id === commentedBy?.id,
                        ),
                      );
                    }}
                  >
                    Edit Notes
                  </Button>
                  <Button
                    variant="contained"
                    disabled={nonEdittable}
                    onClick={() => {
                      handleOpenEditTravelModal();
                    }}
                  >
                    Edit Travel
                  </Button>
                  {/* {!!event.member && <Button variant="contained">Not available</Button>} */}
                </Box>
              )}
              {(event.client.allowObservations ||
                event.client.allowMedication ||
                event.client.allowRecordSeizures) &&
                (!!event.clockedOnAt || permissible({ admin: true })) && (
                  <Box>
                    {event.client.allowObservations && (
                      <>
                        <ClientCreateFoodButton
                          itemName="Ensure"
                          itemKilojoules={1000}
                          clientId={event.client.id}
                          event={event}
                          className={classes.smallButton}
                          variant="contained"
                          disabled={nonEdittable}
                          style={{
                            flexBasis: '20%',
                            backgroundColor: lightBlue[500],
                          }}
                        />
                        <Button
                          variant="contained"
                          disabled={nonEdittable}
                          color="secondary"
                          className={classes.smallButton}
                          onClick={() => {
                            handleOpenRecordObsModal();
                          }}
                          style={{ minWidth: 0, flexBasis: '5%' }}
                        >
                          Obs
                        </Button>

                        <Button
                          variant="contained"
                          disabled={nonEdittable}
                          color="secondary"
                          className={classes.smallButton}
                          onClick={() => {
                            handleOpenRecordBmModal();
                          }}
                        >
                          Toilet
                        </Button>
                      </>
                    )}
                    {event.client.allowMedication && (
                      <Button
                        variant="contained"
                        disabled={nonEdittable}
                        color="secondary"
                        className={classes.smallButton}
                        onClick={() => {
                          handleOpenRecordMedicationModal();
                        }}
                      >
                        Medication
                      </Button>
                    )}
                    {event.client.allowRecordSeizures && (
                      <Button
                        variant="contained"
                        disabled={nonEdittable}
                        color="secondary"
                        className={classes.smallButton}
                        onClick={() => {
                          handleOpenRecordSeizuresModal();
                        }}
                      >
                        Seizure
                      </Button>
                    )}
                  </Box>
                )}
            </Box>
          )}

          <Box className={classes.items}>
            {isEvent(data) &&
              !!eventClaimsResponse.data?.eventClaims.length && (
                <EventClaimsTable
                  event={event}
                  claims={eventClaimsResponse.data.eventClaims}
                />
              )}

            {!!totalEnergyIntake ? (
              <>
                <Divider />
                <Box className={classes.item}>
                  <Typography
                    className={classes.itemTitle}
                    variant="body1"
                    color="textPrimary"
                  >
                    Energy intake
                  </Typography>
                  <Typography variant="body2">
                    {intersperse({
                      sep: ', ',
                      and: false,
                      array: totalEnergyIntake.map(({ date, kj }) => {
                        return (
                          <>
                            <span
                              style={{
                                backgroundColor:
                                  kj < 4000
                                    ? red[600]
                                    : kj < 8000
                                    ? yellow[600]
                                    : undefined,
                                color:
                                  kj < 4000
                                    ? 'white'
                                    : kj < 8000
                                    ? 'black'
                                    : undefined,
                              }}
                            >
                              {kj}kJ
                            </span>{' '}
                            / 8,000kJ ({format(date, 'd/M/yy')})
                          </>
                        );
                      }),
                    })}
                  </Typography>
                </Box>
              </>
            ) : (
              event.client.allowObservations && (
                <>
                  <Divider />
                  <Box className={classes.item}>
                    <Typography
                      className={classes.itemTitle}
                      variant="body1"
                      color="textPrimary"
                    >
                      Energy intake (
                      {format(startOfDay(setTime(event.startAt)), 'dd/MM/yyyy')}
                      )
                    </Typography>
                    <Typography variant="body2">
                      <span
                        style={{ backgroundColor: red[600], color: 'white' }}
                      >
                        0kJ
                      </span>{' '}
                      / 8,000kJ
                    </Typography>
                  </Box>
                </>
              )
            )}

            {smDown && (
              <>
                <Divider />
                <Box className={classes.item}>
                  <Typography
                    className={classes.itemTitle}
                    variant="body1"
                    color="textPrimary"
                  >
                    Participant
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <Link to={_peopleClients.path + '/' + event.client.id}>
                      {formatPersonName(event.client, {
                        capitaliseLastName: true,
                        lastNameFirst: true,
                      })}
                    </Link>
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <Link
                      to={
                        'https://www.google.com/maps/search/' +
                        formatAddress(event)
                      }
                    >
                      {formatAddress(event) || 'None.'}
                    </Link>
                  </Typography>
                </Box>
              </>
            )}

            {isEvent(data) && (
              <>
                <Divider />
                <Box className={classes.item}>
                  <Typography
                    className={classes.itemTitle}
                    variant="body1"
                    color="textPrimary"
                  >
                    Accounting
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Participant{' '}
                    {data.eventById.billable ? 'billable' : 'not billable'}.
                    Employee{' '}
                    {data.eventById.payable
                      ? 'payable' +
                        (permissible({ admin: true }) &&
                        !!data.eventById.bonusPay
                          ? ` + $${roundNumber(
                              data.eventById.bonusPay / 100,
                              2,
                            ).toFixed(2)} bonus`
                          : '')
                      : 'not payable'}
                    .
                  </Typography>
                </Box>
              </>
            )}

            {event.summary && (
              <>
                <Divider />
                <Box className={classes.item}>
                  <Typography
                    className={classes.itemTitle}
                    variant="body1"
                    color="textPrimary"
                  >
                    Message from scheduler
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className={classes.scrollableText}
                  >
                    {event.summary ?? 'None.'}
                  </Typography>
                </Box>
              </>
            )}

            {!!event.clockedOnAt &&
              ((org?.membersCanSeeClockedTimes &&
                event.member?.id === member?.id) ||
                permissible({ permissions: Permission.EVENT_WRITE })) && (
                <>
                  <Divider />
                  <Box className={classes.item}>
                    <Typography
                      className={classes.itemTitle}
                      variant="body1"
                      color="textPrimary"
                    >
                      Clocked times
                    </Typography>
                    <Box
                      style={{ display: 'flex', alignItems: 'center', gap: 4 }}
                    >
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className={classes.flexLine}
                      >
                        {isEvent(data) && !!data.eventById.clockedOnBy ? (
                          <>
                            <Avatar
                              className={classes.avatarMini}
                              color={data.eventById.clockedOnBy.color}
                              content={[
                                data.eventById.clockedOnBy.firstName,
                                data.eventById.clockedOnBy.middleName,
                                data.eventById.clockedOnBy.lastName,
                              ]}
                            />
                            <Link
                              to={
                                _peopleMembers.path +
                                '/' +
                                data.eventById.clockedOnBy.id
                              }
                            >
                              {formatPersonName(data.eventById.clockedOnBy, {
                                capitaliseLastName: true,
                                lastNameFirst: true,
                              })}
                            </Link>{' '}
                            c
                          </>
                        ) : (
                          ' C'
                        )}
                        locked on{' '}
                        {format(
                          setTime(event.clockedOnAt),
                          'eee, d MMM, HH:mm',
                        )}
                        .
                      </Typography>
                      {isEvent(data) && permissible({ admin: true }) && (
                        <Link
                          to={
                            'https://www.google.com/maps/place/' +
                            data.eventById.clockedOnLatitude +
                            ',' +
                            data.eventById.clockedOnLongitude
                          }
                        >
                          <CallMadeRoundedIcon style={{ width: 10 }} />
                        </Link>
                      )}
                    </Box>
                    {!!event.clockedOffAt && (
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 4,
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className={classes.flexLine}
                        >
                          {isEvent(data) && !!data.eventById.clockedOffBy ? (
                            <>
                              <Avatar
                                className={classes.avatarMini}
                                color={data.eventById.clockedOffBy.color}
                                content={[
                                  data.eventById.clockedOffBy.firstName,
                                  data.eventById.clockedOffBy.middleName,
                                  data.eventById.clockedOffBy.lastName,
                                ]}
                              />
                              <Link
                                to={
                                  _peopleMembers.path +
                                  '/' +
                                  data.eventById.clockedOffBy.id
                                }
                              >
                                {formatPersonName(data.eventById.clockedOffBy, {
                                  capitaliseLastName: true,
                                  lastNameFirst: true,
                                })}
                              </Link>{' '}
                              c
                            </>
                          ) : (
                            ' C'
                          )}
                          locked off{' '}
                          {format(
                            setTime(event.clockedOffAt),
                            'eee, d MMM, HH:mm',
                          )}
                          .
                        </Typography>
                        {isEvent(data) && permissible({ admin: true }) && (
                          <Link
                            to={
                              'https://www.google.com/maps/place/' +
                              data.eventById.clockedOffLatitude +
                              ',' +
                              data.eventById.clockedOffLongitude
                            }
                          >
                            <CallMadeRoundedIcon style={{ width: 10 }} />
                          </Link>
                        )}
                      </Box>
                    )}
                  </Box>
                </>
              )}

            {!nonEdittable && !!event.travelDistance && (
              <>
                <Divider />
                <Box className={classes.item}>
                  <Typography
                    className={classes.itemTitle}
                    variant="body1"
                    color="textPrimary"
                  >
                    Travel distance
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {event.travelDistance / 1000 + ' kilometer'}
                    {event.travelDistance > 1000 && 's'}
                  </Typography>
                </Box>
              </>
            )}
            {!nonEdittable && !!event.travelTime && (
              <>
                <Divider />
                <Box className={classes.item}>
                  <Typography
                    className={classes.itemTitle}
                    variant="body1"
                    color="textPrimary"
                  >
                    Travel time
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {event.travelTime + ' minute'}
                    {event.travelTime > 1 && 's'}
                  </Typography>
                </Box>
              </>
            )}
            {!!observationsResponse.data &&
              !!observationsResponse.data.clientObservations.length && (
                <>
                  <Divider />
                  <Box
                    className={classes.item}
                    style={{
                      paddingBottom: smDown ? theme.spacing(8) : undefined,
                    }}
                  >
                    <Typography
                      className={classes.itemTitle}
                      variant="body1"
                      color="textPrimary"
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: 4,
                      }}
                    >
                      Observations
                      {permissible({ permissions: Permission.CLIENT_READ }) && (
                        <Link
                          to={
                            _peopleClients.path + '/' + event.client.id + '/obs'
                          }
                        >
                          <CallMadeRoundedIcon style={{ width: 10 }} />
                        </Link>
                      )}
                    </Typography>
                    {!observationsResponse.data.clientObservations.length
                      ? 'None.'
                      : observationsResponse.data.clientObservations.map(
                          (o) => (
                            <>
                              <Typography
                                color="textSecondary"
                                className={classes.observations}
                                onClick={() => {
                                  if (
                                    (permissible({
                                      permissions: Permission.CLIENT_WRITE,
                                    }) ||
                                      member?.id === event.member?.id) &&
                                    !o.givenFood?.length
                                  )
                                    !!o.givenMedications?.length
                                      ? handleOpenUpdateMedicationModal(o)
                                      : !!o.stoolType || o.urineColor
                                      ? handleOpenUpdateBmModal(o)
                                      : o.hadSeizure
                                      ? handleOpenUpdateSeizureModal(o)
                                      : handleOpenUpdateObsModal(o);
                                }}
                              >
                                <ClientFormattedObservation
                                  limits={event.client}
                                  obs={o}
                                  setTime={setTime}
                                />
                              </Typography>
                              <Protected admin>
                                {!!o.createdBy && (
                                  <Typography
                                    color="textSecondary"
                                    className={clsx(
                                      classes.recorder,
                                      classes.bold,
                                    )}
                                  >
                                    Recorded by{' '}
                                    {formatPersonName(
                                      {
                                        ...o.createdBy,
                                        postNominals:
                                          memberPostNominalsResponse.data?.memberPostNominals.find(
                                            ({ date, member }) =>
                                              member.id === o.createdBy!.id &&
                                              isBefore(
                                                new Date(date),
                                                new Date(o.date),
                                              ),
                                          )?.value,
                                      },
                                      {
                                        capitaliseLastName: true,
                                        lastNameFirst: true,
                                        postNominals: true,
                                      },
                                    )}
                                  </Typography>
                                )}
                              </Protected>
                            </>
                          ),
                        )}
                  </Box>
                </>
              )}

            <Divider />
            <Box
              className={classes.item}
              style={{
                paddingBottom: smDown ? theme.spacing(8) : undefined,
              }}
            >
              <Typography
                className={classes.itemTitle}
                variant="body1"
                color="textPrimary"
              >
                Case notes
              </Typography>
              <Box className={clsx(classes.notes, classes.scrollableText)}>
                {eventNotesResponse.loading
                  ? 'Fetching notes...'
                  : !eventNotesResponse.data?.clientNotes.length
                  ? 'None'
                  : eventNotesResponse.data.clientNotes.map((note) => (
                      <Box
                        className={classes.note}
                        onClick={() =>
                          (note.commentedBy?.id === member?.id ||
                            permissible({
                              permissions: Permission.EVENT_WRITE,
                            })) &&
                          handleOpenEditCaseNotesModal(note)
                        }
                      >
                        <Typography className={classes.noteAuthor}>
                          {!!note.commentedBy
                            ? formatPersonName(note.commentedBy, {
                                lastNameFirst: true,
                                capitaliseLastName: true,
                              })
                            : note.commentedByText}
                        </Typography>
                        {note.comment?.trim()}
                      </Box>
                    ))}
              </Box>
            </Box>

            <Divider />

            {(permissible({ permissions: Permission.EVENT_READ }) ||
              event.member?.id === member!.id) && (
              <Box
                className={classes.item}
                style={{ paddingBottom: smDown ? theme.spacing(8) : undefined }}
              >
                <Typography
                  className={classes.itemTitle}
                  variant="body1"
                  color="textPrimary"
                >
                  Attachments
                </Typography>
                <Box className={classes.attachments}>
                  {filesResponse.data?.eventFiles.map(
                    ({ id, type, file: { size } }) => (
                      <Button
                        className={classes.attachment}
                        onClick={() => {
                          downloadFile({ variables: { id } });
                        }}
                      >
                        <DescriptionOutlinedIcon fontSize="small" />
                        <Box className={classes.attachmentTexts}>
                          <Typography className={classes.attachmentText}>
                            {
                              eventFilesMetadata.find(
                                (meta) => meta.id === type,
                              )?.name
                            }
                          </Typography>
                          <Typography className={classes.attachmentText}>
                            {roundNumber(
                              size > DataSize.MB
                                ? size / DataSize.MB
                                : size / DataSize.KB,
                              2,
                            ) + (size > DataSize.MB ? 'MB' : 'KB')}
                          </Typography>
                        </Box>
                      </Button>
                    ),
                  )}
                  <IconButton
                    onClick={() => {
                      handleOpenCreateFileModal();
                    }}
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            )}

            {isEvent(data) &&
              !!data.eventById.createdBy &&
              permissible({ admin: true }) && (
                <>
                  <Divider />
                  <Box
                    className={classes.item}
                    style={{
                      paddingBottom: smDown ? theme.spacing(8) : undefined,
                    }}
                  >
                    <Typography
                      className={classes.itemTitle}
                      variant="body1"
                      color="textPrimary"
                    >
                      Creation
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Shift created on{' '}
                      {format(
                        new Date(data.eventById.createdAt),
                        "dd/MM/yy 'at' HH:mm:ss",
                      )}{' '}
                      by{' '}
                      <Link
                        to={
                          _peopleMembers.path +
                          '/' +
                          data.eventById.createdBy.id
                        }
                      >
                        {formatPersonName(data.eventById.createdBy, {
                          capitaliseLastName: true,
                          lastNameFirst: true,
                        })}
                      </Link>
                    </Typography>
                  </Box>
                </>
              )}

            {hasPermission && isEvent(data) && data.eventById.scheduleNotes && (
              <>
                <Divider />
                <Box
                  className={classes.item}
                  style={{
                    paddingBottom: smDown ? theme.spacing(8) : undefined,
                  }}
                >
                  <Typography className={classes.itemTitle} color="error">
                    Scheduling notes
                  </Typography>
                  <Typography
                    variant="body2"
                    color="error"
                    className={clsx(
                      classes.scrollableText,
                      classes.schedulingNotes,
                    )}
                  >
                    {data.eventById.scheduleNotes ?? 'None.'}
                  </Typography>
                </Box>
              </>
            )}

            {permissible({ admin: true }) && (
              <>
                <Divider />
                <Box
                  className={classes.item}
                  style={{
                    paddingBottom: smDown ? theme.spacing(8) : undefined,
                  }}
                >
                  <Typography
                    variant="body2"
                    color="error"
                    className={classes.scrollableText}
                  >
                    Event ID: {event.id}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Block>
    </Modal>
  );
};

export default EventSummaryModal;

import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { IconButton, useRouter } from '@timed/common';
import { _peopleMembers } from '@timed/app';

const MemberViewCloseButton = () => {
  const { navigate } = useRouter();

  const handleClick = () => {
    navigate(_peopleMembers.path);
  };

  return (
    <IconButton onClick={handleClick}>
      <CloseRoundedIcon />
    </IconButton>
  );
};

export default MemberViewCloseButton;

import { Menu, MenuItem } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { IconButton } from '@timed/common';
import {
  File,
  MemberFile,
  Permission,
  useDeleteMemberFilesMutation,
  useDownloadMemberFileByIdLazyQuery,
} from '@timed/gql';
import { useAlert } from '@timed/alert';
import { AuthContextType } from '@timed/auth';
import { MemberContext, MemberUpdateFileFormModal } from '@timed/member';
import { useModal } from 'mui-modal-provider';
import { useContext, useState } from 'react';

type MemberFilesMenuButtonProps = React.PropsWithChildren<{
  auth: AuthContextType;
  file: Pick<
    MemberFile,
    'id' | 'type' | 'dateOfFile' | 'expiresAt' | 'value'
  > & {
    file: Pick<File, 'id' | 'name'>;
  };
}>;

const MemberFilesMenuButton = ({ auth, file }: MemberFilesMenuButtonProps) => {
  const { showModal } = useModal();
  const member = useContext(MemberContext);
  const alert = useAlert();

  const handleOpenModal = () => {
    const modal: { hide: () => void } = showModal(MemberUpdateFileFormModal, {
      onClose: () => modal.hide(),
      member,
      file,
    });
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [downloadFile] = useDownloadMemberFileByIdLazyQuery({
    variables: { id: file.id },
    fetchPolicy: 'no-cache',
    onCompleted: (data) =>
      window.open(data.downloadMemberFileById.path, '_blank'),
  });

  const [deleteFile, deleteResponse] = useDeleteMemberFilesMutation({
    variables: { input: { ids: [file.id] } },
    onCompleted: (data) => {
      alert.push({
        message: 'File deleted',
        severity: 'info',
      });
      const cache = deleteResponse.client.cache;
      cache.evict({
        id: cache.identify({
          __typename: 'MemberFile',
          id: data.deleteMemberFiles[0].id,
        }),
      });
      cache.gc();
    },
  });

  return (
    <span>
      <IconButton
        aria-label="menu"
        color="inherit"
        onClick={handleClick}
        size="small"
      >
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
          },
        }}
      >
        {auth.permissible({ permissions: Permission.CLIENT_FILE_READ }) && (
          <MenuItem
            onClick={() => {
              downloadFile();
              handleClose();
            }}
          >
            Download
          </MenuItem>
        )}

        {auth.permissible({ permissions: Permission.CLIENT_FILE_WRITE }) && (
          <MenuItem
            onClick={() => {
              handleOpenModal();
              handleClose();
            }}
          >
            Edit
          </MenuItem>
        )}

        {auth.permissible({ permissions: Permission.CLIENT_FILE_WRITE }) && (
          <MenuItem
            onClick={() => {
              deleteFile();
              handleClose();
            }}
          >
            Delete
          </MenuItem>
        )}
      </Menu>
    </span>
  );
};

export default MemberFilesMenuButton;

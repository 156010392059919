import { TextField, TextFieldProps } from '@timed/common';
import { passwordRegex } from '@timed/common/regexps';
import { RegisterOptions } from 'react-hook-form';

export const validateUserPassword: RegisterOptions = {
  pattern: {
    value: passwordRegex,
    message:
      'Passwords must be at least 8 characters in length and contain a ' +
      'combination of lowercase, uppercase and special characters',
  },
};

const UserPasswordInput = (props: TextFieldProps) => (
  <TextField
    label="Password"
    type="password"
    validation={validateUserPassword}
    {...props}
  />
);

export default UserPasswordInput;

import { TimezoneLabels } from '@timed/common/types/TimezoneLabels';
import { Timezone } from '@timed/gql';

/**
 * Get current timezone.
 */
export const getTimezone = (): Timezone | undefined => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  return TimezoneLabels.find(
    (timezoneLabel) => timezoneLabel.label === timeZone,
  )?.value;
};

export enum MIME {
  // PDF
  PDF = 'application/pdf',

  // Microsoft Word (OpenXML)
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',

  // Microsoft Excel (OpenXML)
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

  // JPEG images
  JPG = 'image/jpeg',

  // GIF images
  GIF = 'image/gif',

  // PNG images
  PNG = 'image/png',

  // Comma-separated values file
  CSV = 'text/csv',

  // HEIC images
  HEIC = 'image/heic',

  // HEIF images
  HEIF = 'image/heif',
}

import { TextField, TextFieldProps, TransformInput } from '@timed/common';
import { abnRegex } from '@timed/common/regexps';
import { RegisterOptions } from 'react-hook-form';

export const validateAbn: RegisterOptions = {
  pattern: {
    value: abnRegex,
    message: 'Invalid ABN. Must consist of 11 numeric characters.',
  },
};

export const transformAbn: TransformInput = {
  input: (value: string) => value,
  output: ({ target: { value } }) => {
    if (value.length >= 14) return value.slice(0, 14);

    if (value.length === 3 || value.length === 7 || value.length === 11) {
      if (value.charAt(value.length - 1) === ' ') return value.trim();

      if (value.charAt(value.length - 2) !== ' ')
        return (
          value.slice(0, value.length - 1) +
          ' ' +
          value.charAt(value.length - 1)
        );
    }

    return value;
  },
};

const AbnInput = (props: TextFieldProps) => (
  <TextField
    label="ABN"
    type="text"
    validation={validateAbn}
    transform={transformAbn}
    {...props}
  />
);

export default AbnInput;

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  addServerErrors,
  DateInput,
  formatPersonName,
  FormModal,
  ModalProps,
  NumberInput,
  transformNumberToFixedFloat,
} from '@timed/common';
import {
  CreateMemberBasePayRateDocument,
  CreateMemberBasePayRateInput,
  PersonNamesPartialFragment,
  QueryByIdInput,
  useCreateMemberBasePayRateMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { MemberBasePayRateTypeInput } from '@timed/member/components/BasePayRateTypeInput';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type MemberCreateBasePayRateFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  member: QueryByIdInput & PersonNamesPartialFragment;
};

type FormData = Omit<CreateMemberBasePayRateInput, 'member'>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputs: {
      flex: '1 0 auto',
      overflowY: 'auto',
      display: 'grid',
      gridAutoFlow: 'row',
      gridAutoRows: 'max-content',
      gap: theme.spacing(4),
    },
  }),
);

const MemberCreateBasePayRateFormModal = ({
  member,
  onClose,
  ...modalProps
}: MemberCreateBasePayRateFormModalProps) => {
  const classes = useStyles();

  const [createEntity, response] = useCreateMemberBasePayRateMutation();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<FormData>();

  useLoadingEffect(response.loading);

  useEffect(() => {
    if (response.error) addServerErrors(response.error, setError);
  }, [response.error, setError]);

  const onSubmit = (values: FormData) => {
    values.value = Math.round(values.value * 100);

    createEntity({
      variables: {
        input: {
          member: { id: member.id },
          ...values,
        },
      },
    }).catch((e) => {});
  };

  const onSuccess = () => {
    const cache = response.client.cache;

    cache.modify({
      fields: {
        memberBasePayRates(existing = []) {
          return [
            ...existing,
            cache.writeQuery({
              data: response.data,
              query: CreateMemberBasePayRateDocument,
            }),
          ];
        },
      },
    });
  };

  /**
   * Display error messages from server response
   */
  useEffect(() => {
    if (response.error) addServerErrors(response.error, setError);
  }, [response.error, setError]);

  return (
    <FormModal
      modalProps={modalProps}
      title="Create Base Pay Rate"
      description={`For ${formatPersonName(member, {
        lastNameFirst: true,
        capitaliseLastName: true,
      })}`}
      saveText="Save"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.inputs}>
        {response.error && (
          <Typography color="error">{response.error.message}</Typography>
        )}
        <DateInput
          required
          keyboard
          disableTime
          label="Effective-from date"
          name="date"
          control={control}
          inputVariant="outlined"
          size="small"
          error={!!errors.date}
          helperText={errors.date?.message}
        />
        <NumberInput
          required
          name="value"
          variant="outlined"
          size="small"
          label="Value"
          control={control}
          error={!!errors.value}
          helperText={errors.value?.message}
          transform={transformNumberToFixedFloat(2, 0, 10000000)}
          step="0.01"
          min={0}
          max={10000000} // 10 million
        />
        <MemberBasePayRateTypeInput
          required
          name="type"
          control={control}
          error={!!errors.type}
        />
      </Box>
    </FormModal>
  );
};

export default MemberCreateBasePayRateFormModal;

import { Pronouns } from '@timed/gql';

export const formatPronouns = (pronouns?: Pronouns, hideBlank = false) =>
  pronouns === Pronouns.NOTSPECIFIED
    ? !hideBlank
      ? 'Not specified'
      : ''
    : pronouns === Pronouns.HE
    ? 'He, Him, His'
    : pronouns === Pronouns.SHE
    ? 'She, Her, Hers'
    : 'They, Them, Their';

import { _start } from '@timed/app';
import { useProcessMemberInvitationMutation } from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const RouteInvite = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [validateToken, { loading }] = useProcessMemberInvitationMutation({
    variables: { input: { token } },
    onCompleted: () => {
      navigate(_start.path);
    },
    onError: () => {
      navigate('/');
    },
  });

  useLoadingEffect(loading);

  useEffect(() => {
    validateToken();
  }, [validateToken]);

  return null;
};

export default RouteInvite;

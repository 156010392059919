import { useAuth } from '@timed/auth';
import {
  ClientListContext,
  ClientListContextInput,
  ClientListContextType,
} from '@timed/client';
import {
  EntityState,
  ModuleType,
  OrderBy,
  Permission,
  useClientListContextGetAggregatedClientsLazyQuery,
  useClientListContextGetAggregatedRedactedClientsLazyQuery,
} from '@timed/gql';
import { ModuleContext } from '@timed/module';
import React, { useContext, useEffect, useMemo, useState } from 'react';

const ClientListProvider: React.FC = ({ children }) => {
  const storagePrefix = 'list.filters';

  const { permissible, branch } = useAuth();

  const { activeModule } = useContext(ModuleContext);

  const [getClients, clientsResponse] =
    useClientListContextGetAggregatedClientsLazyQuery({
      notifyOnNetworkStatusChange: true,
    });

  const [getRedactedClients, redactedClientsResponse] =
    useClientListContextGetAggregatedRedactedClientsLazyQuery({
      notifyOnNetworkStatusChange: true,
    });

  /**
   * Comment text to filter by.
   */
  const [search, setSearch] = useState<string | null>(
    sessionStorage.getItem(storagePrefix + '.search'),
  );

  /**
   * Entity states to filter by.
   */
  const [entityStates, setEntityStates] = useState<EntityState[]>(
    !!sessionStorage.getItem(storagePrefix + '.state')?.includes(',')
      ? (sessionStorage
          .getItem(storagePrefix + '.state')!
          .split(',') as EntityState[])
      : !!sessionStorage.getItem(storagePrefix + '.state')
      ? [sessionStorage.getItem(storagePrefix + '.state') as EntityState]
      : [EntityState.NORMAL, EntityState.ARCHIVED, EntityState.DELETED],
  );

  /**
   * Ordering options.
   */
  const [orderBy, setOrderBy] = useState<ClientListContextInput['orderBy']>(
    sessionStorage
      .getItem('client.' + storagePrefix + '.orderBy')
      ?.includes(',')
      ? [
          {
            [sessionStorage
              .getItem('client.' + storagePrefix + '.orderBy')!
              .split(',')[0]]:
              OrderBy[
                sessionStorage
                  .getItem('client.' + storagePrefix + '.orderBy')!
                  .split(',')[1] as OrderBy
              ],
          },
        ]
      : [{ lastName: OrderBy.ASC_NULLS_LAST }],
  );

  /**
   * Query offset.
   */
  const [offset, setOffset] = useState<number>(0);

  /**
   * Possible pagination limits.
   */
  const limit = 100;

  /**
   * Reset all filters back to their defaults.
   */
  const reset = () => {
    setSearch('');
    setEntityStates([
      EntityState.NORMAL,
      EntityState.ARCHIVED,
      EntityState.DELETED,
    ]);
    setOffset(0);
  };

  const where = useMemo(
    () => ({
      branch: branch ? { id: { _eq: branch.id } } : undefined,
      _or: search
        ? [
            { lastName: { _re: '(?i)' + search } },
            { firstName: { _re: '(?i)' + search } },
            { middleName: { _re: '(?i)' + search } },
            { preferredName: { _re: '(?i)' + search } },
            { locality: { _re: '(?i)' + search } },
          ]
        : undefined,
      moduleCS: activeModule === ModuleType.CS ? { _eq: true } : undefined,
      moduleSC: activeModule === ModuleType.SC ? { _eq: true } : undefined,
    }),
    [branch, search, activeModule],
  );

  const hasPermission = useMemo(
    () => permissible({ permissions: Permission.CLIENT_READ }),
    [permissible],
  );

  const getEntities = useMemo(
    () => (hasPermission ? getClients : getRedactedClients),
    [hasPermission, getClients, getRedactedClients],
  );

  const refetch = useMemo(
    () =>
      hasPermission ? clientsResponse.refetch : redactedClientsResponse.refetch,
    [hasPermission, clientsResponse.refetch, redactedClientsResponse.refetch],
  );

  const fetchMore = useMemo(
    () =>
      hasPermission
        ? clientsResponse.fetchMore
        : redactedClientsResponse.fetchMore,
    [
      hasPermission,
      clientsResponse.fetchMore,
      redactedClientsResponse.fetchMore,
    ],
  );

  const aggregate = useMemo(
    () =>
      hasPermission
        ? clientsResponse.data?.clientsAggregate.aggregate
        : redactedClientsResponse.data?.redactedClientsAggregate.aggregate,
    [
      hasPermission,
      clientsResponse.data?.clientsAggregate.aggregate,
      redactedClientsResponse.data?.redactedClientsAggregate.aggregate,
    ],
  );

  const nodes = useMemo(
    () =>
      hasPermission
        ? clientsResponse.data?.clientsAggregate.nodes
        : redactedClientsResponse.data?.redactedClientsAggregate.nodes,
    [
      hasPermission,
      clientsResponse.data?.clientsAggregate.nodes,
      redactedClientsResponse.data?.redactedClientsAggregate.nodes,
    ],
  );

  const called = clientsResponse.called ?? redactedClientsResponse.called;

  const loading = clientsResponse.loading ?? redactedClientsResponse.loading;

  /**
   * Save search to storage.
   */
  useEffect(() => {
    if (search !== sessionStorage.getItem(storagePrefix + '.search'))
      sessionStorage.setItem(storagePrefix + '.search', search ?? '');
  }, [search]);

  /**
   * Save entityState to storage.
   */
  useEffect(() => {
    if (
      entityStates[0] !== sessionStorage.getItem(storagePrefix + '.state') ||
      entityStates.length !==
        sessionStorage.getItem(storagePrefix + '.state')?.split(',').length
    )
      sessionStorage.setItem(
        storagePrefix + '.state',
        entityStates.join(',') ?? '',
      );
  }, [entityStates]);

  /**
   * Save orderBy to storage.
   */
  useEffect(() => {
    if (
      Object.keys(Object.values(orderBy)[0])[0] !==
        sessionStorage.getItem('client.' + storagePrefix + '.orderBy') ||
      Object.values(Object.values(orderBy)[0])[0] !==
        sessionStorage.getItem('client.' + storagePrefix + '.orderBy')
    )
      sessionStorage.setItem(
        'client.' + storagePrefix + '.orderBy',
        Object.entries(Object.values(orderBy)[0]).every(([k, v]) => !!k && !!v)
          ? Object.keys(Object.values(orderBy)[0])[0] +
              ',' +
              Object.values(Object.values(orderBy)[0])[0]
          : '',
      );
  }, [orderBy]);

  /**
   * Fetch subsequent data.
   */
  const fetch: ClientListContextType['fetch'] = ({ offset } = {}) => {
    const input = {
      where,
      orderBy,
      limit,
      offset: offset ?? 0,
      entityStates:
        // Ignore entityStates if the user lacks permissions
        permissible({ permissions: Permission.CLIENT_READ }) &&
        entityStates &&
        !!entityStates.length
          ? entityStates
          : undefined,
    };

    setOffset(offset ?? 0);

    if (offset !== undefined) {
      fetchMore({ variables: { input } });
    } else {
      refetch({ input });
    }
  };

  /**
   * Fetch initial data.
   */
  useEffect(() => {
    if (!called)
      getEntities({
        variables: {
          input: {
            where,
            orderBy,
            limit,
            offset,
            entityStates:
              // Ignore entityStates if the user lacks permissions
              permissible({ permissions: Permission.CLIENT_READ }) &&
              entityStates &&
              !!entityStates.length
                ? entityStates
                : undefined,
          },
        },
      });
  });

  /**
   * Refetch data when needed.
   */
  useEffect(() => {
    if (called) {
      setOffset(0);
      refetch({
        input: {
          where,
          orderBy,
          limit,
          offset: 0,
          entityStates:
            // Ignore entityStates if the user lacks permissions
            permissible({ permissions: Permission.CLIENT_READ }) &&
            entityStates &&
            !!entityStates.length
              ? entityStates
              : undefined,
        },
      });
    }
  }, [
    called,
    setOffset,
    where,
    entityStates,
    limit,
    orderBy,
    permissible,
    refetch,
  ]);

  return (
    <ClientListContext.Provider
      value={{
        fetch,
        reset,
        limit,
        offset,
        setOffset,
        totalCount: aggregate?.totalCount,
        nodes,
        loading,
        input: {
          search,
          setSearch,
          entityStates,
          setEntityStates,
          orderBy,
          setOrderBy,
        },
      }}
    >
      {children}
    </ClientListContext.Provider>
  );
};

export default ClientListProvider;

import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
} from '@material-ui/core';
import { Control, Controller, RegisterOptions } from 'react-hook-form';

export type RadioGroupProps = MuiRadioGroupProps &
  React.PropsWithChildren<{
    label?: string;
    control?: Control<any>;
    validation?: Omit<RegisterOptions, 'required'>;
    formControlProps?: FormControlProps;
    name: string;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
  }>;

const RadioGroup = ({
  children,
  disabled,
  label,
  name,
  control,
  validation,
  defaultChecked,
  defaultValue,
  formControlProps,
  error,
  helperText,
  className,
  ...props
}: RadioGroupProps) => {
  const input = ({
    name,
    value,
    onChange,
  }: {
    name: string;
    value?: any;
    onChange?: (...event: any[]) => void;
  }) => (
    <FormControl disabled={disabled} {...formControlProps}>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <MuiRadioGroup
        key={name}
        name={name}
        value={value}
        onChange={(event) => onChange && onChange(event)}
        {...props}
      >
        {children}
      </MuiRadioGroup>
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );

  return !!control ? (
    <Controller
      control={control}
      name={name}
      rules={validation}
      render={({ field }) => input(field)}
    />
  ) : (
    input({ name })
  );
};

export default RadioGroup;

import { EventIssueType } from '@timed/gql';

type EventIssuesMetadata = {
  type: EventIssueType;
  name: string;
};

export const eventIssuesMetadata: EventIssuesMetadata[] = [
  {
    type: EventIssueType.CLOCKED_WRONG_LOCATION,
    name: 'Clocked wrong location',
  },
  {
    type: EventIssueType.CLOCKED_WRONG_TIME,
    name: 'Clocked wrong time',
  },
  {
    type: EventIssueType.DID_NOT_CLOCK,
    name: 'Did not clock',
  },
  {
    type: EventIssueType.DID_NOT_CLOCK_ON,
    name: 'Did not clock on',
  },
  {
    type: EventIssueType.DID_NOT_CLOCK_OFF,
    name: 'Did not clock off',
  },
  {
    type: EventIssueType.FIX_NOTES,
    name: 'Fix notes',
  },
  {
    type: EventIssueType.ADD_NOTES,
    name: 'Add notes',
  },
  {
    type: EventIssueType.MISSING_HANDOVER,
    name: 'Missing handover',
  },
  {
    type: EventIssueType.MISSING_KILOMETRES,
    name: 'Missing kilometres',
  },
];

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { IconButton } from '@timed/common';
import { NotesContext, NotesCreateNoteFormModal } from '@timed/notes';
import { useModal } from 'mui-modal-provider';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: theme.spacing(10),
      border: '1px solid ' + theme.palette.divider,
      borderRadius: 0,
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.down('sm')]: {
        height: theme.spacing(8),
        width: theme.spacing(8),
      },
    },
  }),
);

const NotesCreateNoteButton = () => {
  const classes = useStyles();
  const { showModal } = useModal();

  const {
    input: { client },
  } = useContext(NotesContext);

  const handleOpenCreateNoteModal: React.MouseEventHandler<
    HTMLButtonElement
  > = () => {
    const modal: { hide: () => void } = showModal(NotesCreateNoteFormModal, {
      clientId: client ?? undefined,
      onClose: () => {
        modal.hide();
      },
    });
  };

  return (
    <IconButton
      size="small"
      aria-label="New"
      className={classes.button}
      onClick={handleOpenCreateNoteModal}
    >
      <AddIcon fontSize="small" />
    </IconButton>
  );
};

export default NotesCreateNoteButton;

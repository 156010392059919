import { NavBarContext } from '@timed/nav-bar';
import { useState } from 'react';

const NavBarProvider: React.FC = ({ children }) => {
  const [isVisible, toggleDrawer] = useState<boolean>(false);

  /**
   * Toggle visibility state of drawer
   * Will not close drawer upon "Tab" and "Shift" key-down events
   */
  const handleToggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      toggleDrawer(open);
    };

  return (
    <NavBarContext.Provider
      value={{ isVisible, toggleDrawer: handleToggleDrawer }}
    >
      {children}
    </NavBarContext.Provider>
  );
};

export default NavBarProvider;

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Protected } from '@timed/auth';
import { useGetMemberFinancialDetailsQuery } from '@timed/gql';
import {
  MemberContext,
  MemberUpdateBankDetailsForm,
  MemberUpdateSuperDetailsForm,
  MemberUpdateTaxFileDeclarationDetailsForm,
} from '@timed/member';
import { MemberBasePayRatesTable } from '@timed/member/components/BasePayRatesTable';
import { MemberBranchAllocationsTable } from '@timed/member/components/BranchAllocationsTable';
import _ from 'lodash';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
    },
    archived: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
      },
    },
  }),
);

const MemberViewFinancialDetails = () => {
  const classes = useStyles();
  const member = useContext(MemberContext);

  const { data, loading } = useGetMemberFinancialDetailsQuery({
    variables: { id: member.id },
  });

  return (
    <Box className={classes.wrapper}>
      {loading || !data ? (
        <Typography>Loading...</Typography>
      ) : (
        <>
          <Protected tester>
            <MemberBasePayRatesTable member={member} />
          </Protected>
          <Protected tester>
            <MemberBranchAllocationsTable member={member} />
          </Protected>
          <MemberUpdateBankDetailsForm
            member={{
              ..._.pick(member, ['id', 'archive']),
              bank: _.omit(data.memberById.bank, ['__typename']),
            }}
          />
          <MemberUpdateSuperDetailsForm
            member={{
              ..._.pick(member, ['id', 'archive']),
              super: _.omit(data.memberById.super, ['__typename']),
            }}
          />
          <MemberUpdateTaxFileDeclarationDetailsForm
            member={{
              ..._.pick(member, [
                'id',
                'archive',
                'salutation',
                'firstName',
                'middleName',
                'lastName',
                'dob',
                'email',
              ]),
              taxFileNumberDeclaration: _.omit(
                data.memberById.taxFileNumberDeclaration,
                ['__typename'],
              ),
            }}
          />
        </>
      )}
    </Box>
  );
};

export default MemberViewFinancialDetails;

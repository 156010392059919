import { Select, SelectProps } from '@timed/common';
import { validateFileType } from '@timed/file';
import { ClientNoteFileType } from '@timed/gql';
import { noteFilesMetadata } from '@timed/notes';

const ClientNoteFileTypeInput = ({
  defaultValue,
  ...props
}: Omit<SelectProps, 'items'>) => (
  <Select
    label="Category"
    validation={validateFileType('clientNote')}
    items={Object.values(ClientNoteFileType)
      .map((name) => {
        return {
          value: name,
          label: noteFilesMetadata.find((meta) => meta.id === name)!.name,
        };
      })
      .sort((a, b) => (a.label > b.label ? 1 : -1))}
    renderValue={(value) =>
      noteFilesMetadata.find((meta) => meta.id === value)?.name
    }
    {...props}
  />
);

export default ClientNoteFileTypeInput;

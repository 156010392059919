import { TransformInput } from '@timed/common';

/**
 * Transform value into a number with variable decimal places
 */
export const transformNumberToFixedFloat = (
  decimals: number,
  min?: number,
  max?: number,
): TransformInput => ({
  input: (value: number) =>
    isNaN(value) || value <= 0 ? '' : value.toString(),
  output: (event) => {
    // Return null if value is not set
    if (!event.target.value) return null;

    // Return value as-is if the it ends in a period (value is a partial-float)
    if (!!decimals && event.target.value.slice(-1) === '.')
      return event.target.value;

    const output = decimals
      ? parseFloat(parseFloat(event.target.value).toFixed(decimals))
      : parseInt(event.target.value);

    return isNaN(output)
      ? 0
      : min
      ? Math.max(min, max ? Math.min(max, output) : output)
      : max
      ? Math.min(max, output)
      : output;
  },
});

import { PaginationStatus } from '@timed/common';
import { NotesContext } from '@timed/notes';
import { useContext } from 'react';

const NotesPaginationStatus = () => {
  const { loading, totalCount, offset, limit } = useContext(NotesContext);

  return (
    <PaginationStatus
      loading={loading}
      limit={limit}
      offset={offset}
      totalCount={totalCount}
    />
  );
};

export default NotesPaginationStatus;

/**
 * Get dimensions of scrollbar.
 */
export const getScrollBarDimensions = (): { width: number; height: number } => {
  const el = document.createElement('div');
  el.style.cssText = 'overflow:scroll; visibility:hidden; position:absolute;';
  document.body.appendChild(el);
  const width = el.offsetWidth - el.clientWidth;
  const height = el.offsetHeight - el.clientHeight;
  el.remove();

  return { width, height };
};

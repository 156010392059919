import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core';
import {
  TableProps,
  TableContext,
  TableHeader,
  TableLoading,
  TableRow,
  paletteLight,
} from '@timed/common';
import React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const Table = ({
  children,
  maxHeight,
  wrapperStyle,
  backgroundColor = paletteLight.background?.default,
  hidden = false,
  loading = false,
  inline = false,
  showIndexColumn: showRowIndexes = false,
  enableRowHighlighting = false,
  persistentRowHighlighting: persistentHighlights = false,
  indexOffset = 0,
  size = 'large',
  border = 'grid',
  borderSize = 'normal',
  borderColor,
}: TableProps) => {
  const columnRefs = useRef<Array<HTMLDivElement | null>>([]);

  const [columnWidths, setColumnWidths] = useState<number[]>([]);

  const [lastClickedRow, setLastClickedRow] = useState<number | null>(null);

  /**
   * Array of headers.
   */
  const headers = (Array.isArray(children) ? children : [children])
    .flat()
    // Extract elements from React Fragments (<></>)
    .flatMap((props) =>
      !!props &&
      props.type.name === React.Fragment.name &&
      !!props.props.children &&
      props.props.children.length
        ? props.props.children.flat()
        : props,
    )
    .filter((props) => !!props?.type && props.type.name === TableHeader.name);

  /**
   * Array of rows.
   */
  const rows = (Array.isArray(children) ? children : [children])
    .flat()
    // Extract elements from React Fragments (<></>)
    .flatMap((props) =>
      !!props &&
      props.type.name === React.Fragment.name &&
      !!props.props.children &&
      props.props.children.length
        ? props.props.children.flat()
        : props,
    )
    .filter((props) => !!props?.type && props.type.name === TableRow.name);

  const calcWidths = useCallback(() => {
    !!columnRefs.current.length &&
      setColumnWidths(
        [...columnRefs.current].map(
          (el) => el?.getBoundingClientRect().width ?? 0,
        ),
      );
  }, []);

  const autoWidthColumnExists = useMemo(
    () =>
      headers.some(
        ({ props: { style, hidden } }) => !hidden && style?.width === 'auto',
      ),
    [headers],
  );

  const theme = useTheme();

  const fontColor = theme.palette.getContrastText(backgroundColor ?? '#000');
  const backgroundIsDark = fontColor === '#fff';
  const c = backgroundIsDark ? 'white' : 'black'; // Mix colour
  const alphaMuliplier = backgroundIsDark ? 3 : 1; // Alpha multiplier
  const sizeMultiplier = size === 'large' ? 1 : 0.5; // Size multiplier
  borderColor =
    borderColor ??
    `color-mix(in srgb, ${c} ${15 * alphaMuliplier}%, ${backgroundColor})`;

  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      loading: {
        position: 'absolute',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backdropFilter: 'blur(8px)',
        zIndex: 1200,
        top: 0,
        bottom: 0,
        '& .MuiSvgIcon-root': {
          fontSize: 48,
          fill: `color-mix(in srgb, ${c} ${
            30 * alphaMuliplier
          }%, ${backgroundColor})`,
          [theme.breakpoints.up('md')]: {
            fontSize: 64,
          },
        },
      },
      interactableCell: {
        cursor: 'pointer',
      },
      interactableHeader: {
        '&:hover': {
          backgroundColor: `color-mix(in srgb, ${c} ${
            10 * alphaMuliplier
          }%, ${backgroundColor})!important`,
        },
      },
      wrapper: {
        position: 'relative',
        width: inline ? 'fit-content' : 'auto',
        maxWidth: '100%',
        display: inline ? undefined : 'flex',
        flexGrow: inline ? undefined : 1,
        color: fontColor,
        fontSize: size === 'small' ? 12 : undefined,
      },
      table: {
        breakAfter: 'avoid-page',
        height: 'auto',
        margin: 0,
        padding: 0,
        flexGrow: inline ? undefined : 1,
        textAlign: 'left',
        fontWeight: 'inherit',
        whiteSpace: 'nowrap',
        tableLayout: 'fixed',
        backgroundColor,
        border: 'none',
        borderTop: `${borderSize === 'normal' ? 1 : 2}px solid ${borderColor}`,
        borderLeft: `${borderSize === 'normal' ? 1 : 2}px solid ${borderColor}`,
        borderRight:
          border === 'grid'
            ? 'none'
            : `${borderSize === 'normal' ? 1 : 2}px solid ${borderColor}`,
        borderCollapse: 'inherit',
        borderSpacing: 0,
        '& thead tr': {
          position: 'sticky',
          zIndex: 1000,
          top: 0,
        },
        '& th, td': {
          padding: theme.spacing(1 * sizeMultiplier),
          backgroundColor,
          borderBottom: `${
            borderSize === 'normal' ? 1 : 2
          }px solid ${borderColor}`,
          borderRight:
            border === 'grid'
              ? `${borderSize === 'normal' ? 1 : 2}px solid ${borderColor}`
              : 'none',
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(2 * sizeMultiplier),
          },
        },
        '& th': {
          position: 'sticky',
          width: autoWidthColumnExists ? 0 : 'auto',
          top: 0,
          zIndex: 1000,
          fontWeight: theme.typography.fontWeightMedium,
          backgroundColor: `color-mix(in srgb, ${c} ${
            5 * alphaMuliplier
          }%, ${backgroundColor})`,
          '& .wrapper': {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(2 * sizeMultiplier),
          },
          '& .MuiSvgIcon-root': {
            fontSize: 14,
          },
        },
        '& tr': {
          height: 32 * sizeMultiplier,
          backgroundColor,
          '&:hover': {
            '& > td': enableRowHighlighting
              ? {
                  backgroundColor: `color-mix(in srgb, ${c} ${
                    5 * alphaMuliplier
                  }%, ${backgroundColor})`,
                }
              : { background: 'none' },
            '& > th': {
              backgroundColor: `color-mix(in srgb, ${c} ${
                7.5 * alphaMuliplier
              }%, ${backgroundColor})`,
            },
          },
          [theme.breakpoints.up('md')]: {
            height: 24 * sizeMultiplier,
          },
        },
      },
      persistentHighlight: {
        '& td': {
          backgroundColor: `color-mix(in srgb, ${c} ${
            10 * alphaMuliplier
          }%, ${backgroundColor})`,
        },
        '&:hover': {
          '& td': {
            backgroundColor: `color-mix(in srgb, ${c} ${
              10 * alphaMuliplier
            }%, ${backgroundColor}) !important`,
          },
        },
      },
      indexesHeader: {
        left: 0,
      },
      indexesCell: {
        position: 'sticky',
        left: 0,
        textAlign: 'center',
      },
    });
  });

  const classes = useStyles();

  useEffect(() => {
    calcWidths();
    window.addEventListener('resize', calcWidths);
    return () => window.removeEventListener('resize', calcWidths);
  }, [calcWidths]);

  if (hidden) return null;

  return (
    <TableContext.Provider
      value={{
        autoWidthColumnExists,
        headers,
        rows,
        columnRefs,
        columnWidths,
        setColumnWidths,
        lastClickedRow,
        setLastClickedRow,
        backgroundColor,
        border,
        borderSize,
        inline,
        size,
        maxHeight,
        hidden,
        indexOffset,
        loading,
        persistentRowHighlighting: persistentHighlights,
        showIndexColumn: showRowIndexes,
        alphaMuliplier,
        sizeMultiplier,
        fontColor,
        backgroundIsDark,
      }}
    >
      <div
        className={classes.wrapper}
        style={{
          maxHeight,
          overflow: loading ? 'hidden' : 'auto',
          ...wrapperStyle,
        }}
      >
        <TableLoading />
        <table className={classes.table}>
          {!!headers.length && (
            <thead>
              <tr>
                {showRowIndexes && (
                  <th
                    ref={(el) => (columnRefs.current[0] = el)}
                    className={classes.indexesHeader}
                    style={{ zIndex: 1001, width: 0 }}
                  ></th>
                )}
                {headers.map(({ props: { children, ...props } }, i) => (
                  <TableHeader {...props} key={i} index={i}>
                    {children}
                  </TableHeader>
                ))}
              </tr>
            </thead>
          )}
          {!!rows.length && (
            <tbody>
              {rows.map(({ props: { children, ...props } }, i) => (
                <TableRow {...props} key={i} index={i}>
                  {children}
                </TableRow>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </TableContext.Provider>
  );
};

export default Table;

import { Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { _peopleMembers } from '@timed/app';
import { Protected, useAuth } from '@timed/auth';
import {
  Block,
  IconButton,
  Link,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  formatPersonName,
} from '@timed/common';
import {
  Member,
  MemberPostNominalsOrderByInput,
  OrderBy,
  Permission,
  useGetMemberPostNominalsLazyQuery,
} from '@timed/gql';
import {
  MemberCreatePostNominalFormModal,
  MemberPostNominalActionMenuButton,
} from '@timed/member';
import { format } from 'date-fns';
import { useModal } from 'mui-modal-provider';
import { useEffect, useState } from 'react';

type MemberPostNominalsTableTableProps = {
  member: Pick<Member, 'id' | 'firstName' | 'lastName'>;
};

const MemberPostNominalsTable = ({
  member,
}: MemberPostNominalsTableTableProps) => {
  const { showModal } = useModal();

  const { permissible } = useAuth();

  const [orderBy, setOrderBy] = useState<MemberPostNominalsOrderByInput>({
    date: OrderBy.DESC,
  });

  const [getPostNominals, postNominalssResponse] =
    useGetMemberPostNominalsLazyQuery();

  useEffect(() => {
    getPostNominals({
      variables: {
        input: {
          where: { member: { id: { _eq: member.id } }, deletedAt: null },
          orderBy: [orderBy],
        },
      },
    });
  }, [orderBy, getPostNominals, member.id]);

  const handleClickCreateButton = () => {
    const modal: { hide: () => void } = showModal(
      MemberCreatePostNominalFormModal,
      {
        onClose: () => {
          modal.hide();
        },
        member,
      },
    );
  };

  return (
    <Block
      title="Post-nominals"
      topRight={
        <Protected permissions={Permission.MEMBER_WRITE}>
          <IconButton
            onClick={() => {
              handleClickCreateButton();
            }}
          >
            <AddIcon />
          </IconButton>
        </Protected>
      }
    >
      {!postNominalssResponse.data?.memberPostNominals.length ? (
        <Typography>No recorded post-nominals.</Typography>
      ) : (
        <Table
          inline
          enableRowHighlighting
          loading={postNominalssResponse.loading}
        >
          <TableHeader
            order={orderBy.date}
            onClick={() => {
              setOrderBy({
                date:
                  !!orderBy.date && orderBy.date === OrderBy.ASC
                    ? OrderBy.DESC
                    : OrderBy.ASC,
              });
            }}
          >
            Effective-from Date
          </TableHeader>
          <TableHeader>Value</TableHeader>
          <TableHeader
            hidden={!permissible({ admin: true })}
            order={orderBy.createdBy?.lastName}
            onClick={() => {
              setOrderBy({
                createdBy: {
                  lastName:
                    !!orderBy.createdBy?.lastName &&
                    orderBy.createdBy.lastName === OrderBy.ASC_NULLS_LAST
                      ? OrderBy.DESC_NULLS_LAST
                      : OrderBy.ASC_NULLS_LAST,
                },
              });
            }}
          >
            Set By
          </TableHeader>
          <TableHeader align="center"></TableHeader>
          {postNominalssResponse.data?.memberPostNominals.map(
            ({ id, value, date, createdBy }, i) => (
              <TableRow key={i}>
                <TableCell>
                  <Typography>
                    {format(new Date(date), 'dd/MM/yyyy')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>{value}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    <Link to={`${_peopleMembers.path}/${createdBy.id}`}>
                      {formatPersonName(createdBy, {
                        capitaliseLastName: true,
                        lastNameFirst: true,
                      })}
                    </Link>
                  </Typography>
                </TableCell>
                <TableCell>
                  <MemberPostNominalActionMenuButton
                    postNominal={{ id, date, value }}
                  />
                </TableCell>
              </TableRow>
            ),
          )}
        </Table>
      )}
    </Block>
  );
};

export default MemberPostNominalsTable;

import {
  Box,
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { useAuth } from '@timed/auth';
import { ClientAutocomplete } from '@timed/client';
import {
  AutocompleteOption,
  Block,
  Button,
  DateInput,
  formatPersonName,
  formatTime,
} from '@timed/common';
import {
  Client,
  IncontinenceType,
  OrderBy,
  PersonNamesFragment,
  SeizureLostConsciousness,
  SeizureType,
  useReportParticipantSeizuresGetObservationsLazyQuery,
} from '@timed/gql';
import { format, isAfter, isValid } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactToPrint from 'react-to-print';

type FormData = {
  from: Date | null;
  to: Date | null;
  client?: Pick<Client, 'id'>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    medium: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    block: {
      backgroundColor: theme.palette.background.paper2,
    },
    inputs: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    inputGroup: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridAutoColumns: 'max-content',
      gap: theme.spacing(4),
      alignItems: 'center',
    },
    printWrapper: {
      padding: theme.spacing(4),
      backgroundColor: 'white',
    },
    print: {
      colorAdjust: 'exact',
      WebkitPrintColorAdjust: 'exact',
      width: 822,
      display: 'flex',
      alignItems: 'start',
      flexFlow: 'column',
      gap: theme.spacing(4),
    },

    items: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(8),
    },

    item: {
      breakInside: 'avoid-page',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      border: '1px solid ' + theme.palette.divider,
      backgroundColor: grey[50],
      padding: theme.spacing(2),
    },

    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    group: {
      display: 'flex',
      flexDirection: 'column',
    },

    table: {
      width: 'max-content',
      '& td': {
        paddingRight: theme.spacing(2),
      },
      '& tr > td:last-child': {
        color: theme.palette.text.secondary,
      },
    },

    note: {
      breakBefore: 'avoid',
      padding: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      whiteSpace: 'pre-wrap',
      '& div:last-child': {
        alignSelf: 'end',
        fontStyle: 'italic',
        color: theme.palette.text.secondary,
      },
    },
  }),
);

const ReportParticipantObservationSeizures = () => {
  const classes = useStyles();

  const { branch } = useAuth();

  const printRef = useRef<HTMLDivElement | null>(null);

  const { control, watch, setValue } = useForm<FormData>({
    mode: 'all',
    defaultValues: { from: null, to: null },
  });

  const selectedClient = watch('client');

  const from = watch('from');

  const to = watch('to');

  const [client, setClient] = useState<AutocompleteOption<
    Pick<Client, 'id'> & PersonNamesFragment
  > | null>(null);

  useEffect(() => {
    if (client?.value.id !== selectedClient?.id)
      setValue('client', client?.value);
  }, [client, selectedClient?.id, setValue]);

  const [getObservations, { data }] =
    useReportParticipantSeizuresGetObservationsLazyQuery({
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    if (
      client &&
      !!from &&
      isValid(from) &&
      !!to &&
      isValid(to) &&
      isAfter(to, from)
    )
      getObservations({
        variables: {
          input: {
            where: {
              hadSeizure: { _eq: true },
              client: { id: { _eq: client.value.id } },
              date: {
                _gte: from,
                _lt: to,
              },
            },
            orderBy: [{ date: OrderBy.ASC }],
          },
        },
      });
  }, [client, getObservations, from, to]);

  return (
    <Box className={classes.wrapper}>
      <Block title="Filters" paperProps={{ className: classes.block }}>
        <Box className={classes.inputs}>
          <Box className={classes.inputGroup}>
            <ClientAutocomplete
              autocompleteProps={{
                defaultValue: client,
                style: { width: 300 },
              }}
              client={client}
              setClient={setClient}
              orderBy={[
                {
                  lastName: OrderBy.ASC_NULLS_FIRST,
                  firstName: OrderBy.ASC_NULLS_FIRST,
                },
              ]}
              where={{
                branch: !!branch ? { id: { _eq: branch.id } } : undefined,
                observations: { hadSeizure: { _eq: true } },
              }}
            />
          </Box>
          <Box className={classes.inputGroup}>
            <DateInput
              keyboard
              defaultValue={null}
              name="from"
              label="Start Date"
              control={control}
              inputVariant="outlined"
              size="small"
            />
            <DateInput
              keyboard
              defaultValue={null}
              name="to"
              label="End Date"
              control={control}
              inputVariant="outlined"
              size="small"
            />
          </Box>
        </Box>
      </Block>

      {!!client &&
        !!from &&
        isValid(from) &&
        !!to &&
        isValid(to) &&
        isAfter(to, from) && (
          <>
            <ReactToPrint
              documentTitle={`${format(
                new Date(),
                'yyyyMMdd',
              )}_Participant_Seizure_Report${
                !!client?.value.lastName && !!client?.value.firstName
                  ? +'_' + client.value.lastName + '_' + client.value.firstName
                  : ''
              }`}
              trigger={() => (
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  style={{ width: 'max-content' }}
                >
                  Print PDF
                </Button>
              )}
              content={() => printRef.current}
            />
            <div className={classes.printWrapper}>
              <style type="text/css" media="print">
                {' @page { margin: 64px !important; } '}
              </style>
              <div ref={printRef} className={classes.print}>
                <div className={classes.header} style={{ width: '100%' }}>
                  <div className={classes.group}>
                    <Typography variant="h1">Observed Seizures</Typography>
                  </div>
                  <div className={classes.group} style={{ alignItems: 'end' }}>
                    <Typography variant="body2" color="textSecondary">
                      {format(from, 'dd/MM/yyyy')} to {format(to, 'dd/MM/yyyy')}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {formatPersonName(client.value, {
                        lastNameFirst: true,
                        capitaliseLastName: true,
                      })}
                    </Typography>
                  </div>
                </div>

                <Box className={classes.items}>
                  {data?.clientObservations.map((observation) => (
                    <div className={classes.item}>
                      <div className={classes.header}>
                        <div className={classes.group}>
                          <Typography className={classes.bold}>
                            {format(
                              new Date(observation.date!),
                              'eeee, do MMM yyyy',
                            )}
                          </Typography>
                        </div>
                        <div
                          className={classes.group}
                          style={{ alignItems: 'end' }}
                        >
                          <Typography>
                            {format(new Date(observation.date!), 'HH:mm')}
                          </Typography>
                        </div>
                      </div>
                      <Divider />
                      <div className={classes.group}>
                        <table className={classes.table}>
                          <tbody>
                            <tr>
                              <td>
                                <Typography variant="body2">
                                  Seizure duration
                                </Typography>
                              </td>
                              <td>
                                <Typography variant="body2">
                                  {formatTime({
                                    type: 'seconds',
                                    units: observation.seizureDuration ?? 0,
                                  })}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Typography variant="body2">
                                  Seizure type
                                </Typography>
                              </td>
                              <td>
                                <Typography variant="body2">
                                  {observation.seizureType ===
                                  SeizureType.Absence
                                    ? '?Absence seizure'
                                    : observation.seizureType ===
                                      SeizureType.Atonic
                                    ? '?Atonic seizure'
                                    : observation.seizureType ===
                                      SeizureType.AtypicalAbsense
                                    ? '?Atypical-absense seizure'
                                    : observation.seizureType ===
                                      SeizureType.Clonic
                                    ? '?Clonic seizure'
                                    : observation.seizureType ===
                                      SeizureType.Tonic
                                    ? '?Tonic seizure'
                                    : observation.seizureType ===
                                      SeizureType.TonicClonic
                                    ? '?Tonic-clonic seizure'
                                    : observation.seizureType ===
                                      SeizureType.AtonicTonic
                                    ? '?Atonic-tonic seizure'
                                    : observation.seizureType ===
                                      SeizureType.Myoclonic
                                    ? '?Myoclonic seizure'
                                    : observation.seizureType ===
                                      SeizureType.Unknown
                                    ? 'Unknown'
                                    : 'Not recorded'}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Typography variant="body2">
                                  Consciousness lost
                                </Typography>
                              </td>
                              <td>
                                <Typography variant="body2">
                                  {observation.seizureLostConsciousness ===
                                  SeizureLostConsciousness.YES
                                    ? 'Yes'
                                    : observation.seizureLostConsciousness ===
                                      SeizureLostConsciousness.NO
                                    ? 'No'
                                    : observation.seizureLostConsciousness ===
                                      SeizureLostConsciousness.UNKNOWN
                                    ? 'Unknown'
                                    : 'Not recorded'}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Typography variant="body2">
                                  Postictal walking duration
                                </Typography>
                              </td>
                              <td>
                                <Typography variant="body2">
                                  {observation.postSeizureWalkDuration !==
                                    undefined &&
                                  observation.postSeizureWalkDuration !== null
                                    ? `${formatTime({
                                        type: 'minutes',
                                        units:
                                          observation.postSeizureWalkDuration,
                                      })}`
                                    : 'Not recorded'}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Typography variant="body2">
                                  Incontinence
                                </Typography>
                              </td>
                              <td>
                                <Typography variant="body2">
                                  {observation.incontinenceType ===
                                  IncontinenceType.Nil
                                    ? 'No incontinence'
                                    : observation.incontinenceType ===
                                      IncontinenceType.Bowel
                                    ? 'Bowel'
                                    : observation.incontinenceType ===
                                      IncontinenceType.Urine
                                    ? 'Urine'
                                    : observation.incontinenceType ===
                                      IncontinenceType.Both
                                    ? 'Both'
                                    : 'Not recorded'}
                                </Typography>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <Divider />
                      <Typography className={classes.note}>
                        {!!observation.notes ? (
                          <>
                            <div>{observation.notes.trim()}</div>
                            <div>
                              {!!observation.createdBy || !!observation.member
                                ? '― ' +
                                  formatPersonName(
                                    {
                                      ...(observation.member ??
                                        observation.createdBy!),
                                      postNominals:
                                        observation.member
                                          ?.currentPostNominal ??
                                        observation.createdBy!
                                          .currentPostNominal,
                                    },
                                    {
                                      capitaliseLastName: true,
                                      lastNameFirst: true,
                                      postNominals: true,
                                    },
                                  )
                                : 'Unknown'}
                            </div>
                          </>
                        ) : (
                          'No recorded case notes'
                        )}
                      </Typography>
                    </div>
                  ))}
                </Box>
              </div>
            </div>
            <ReactToPrint
              documentTitle={`${format(
                new Date(),
                'yyyyMMdd',
              )}_Participant_Seizure_Report${
                !!client?.value.lastName && !!client?.value.firstName
                  ? +'_' + client.value.lastName + '_' + client.value.firstName
                  : ''
              }`}
              trigger={() => (
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  style={{ width: 'max-content' }}
                >
                  Print PDF
                </Button>
              )}
              content={() => printRef.current}
            />
          </>
        )}
    </Box>
  );
};

export default ReportParticipantObservationSeizures;

import { Checkbox, CheckboxProps } from '@timed/common';

const MemberRemindNotesCheckbox = (props: CheckboxProps) => (
  <Checkbox
    defaultChecked={true}
    label="Incomplete case notes reminders"
    {...props}
  />
);

export default MemberRemindNotesCheckbox;

import { useMediaQuery, useTheme } from '@material-ui/core';
import { PaginationNavigation } from '@timed/common';
import { NotesContext } from '@timed/notes';
import { useContext } from 'react';

const NotesPagination = () => {
  const { totalCount, fetch, offset, limit } = useContext(NotesContext);
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <PaginationNavigation
      action={(offset) => fetch({ offset })}
      limit={limit}
      offset={offset}
      totalCount={totalCount}
      size="large"
      maxButtonCount={!smDown ? 15 : 6}
    />
  );
};

export default NotesPagination;

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  AppBarAccount,
  AppBarNavigation,
  AppBarSearchInput,
} from '@timed/app-bar';
import { Protected } from '@timed/auth';
import { NavBarMenuIcon } from '@timed/nav-bar/components/MenuIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      userSelect: 'none' /* Standard */,
      WebkitUserSelect: 'none' /* Safari */,
      msUserSelect: 'none' /* IE10+/Edge */,
      MozUserSelect: 'none' /* Firefox */,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.appBar.main,
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(4),
      },
    },
    menus: {
      display: 'flex',
      gap: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
      },
    },
    left: {
      display: 'flex',
    },
  }),
);

const AppBar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.menus}>
        <Box className={classes.left}>
          <NavBarMenuIcon />
          <AppBarAccount />
        </Box>
        {/* {mdUp && (
          <Protected tester>
            <AppBarSearchInput />
          </Protected>
        )} */}
        <AppBarNavigation />
      </Box>
      {/* {mdUp && <ThemeToggle />} */}
    </Box>
  );
};

export default AppBar;

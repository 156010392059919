import {
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { _schedule } from '@timed/app';
import { useAuth } from '@timed/auth';
import {
  formatPersonName,
  Select,
  useRouter,
  validateUuid,
} from '@timed/common';
import { EntityState, OrderBy, useGetMembersSelectLazyQuery } from '@timed/gql';
import { getProfile, ScheduleContext, setProfile } from '@timed/schedule';
import { ChangeEvent, useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loading: {
      '& .MuiOutlinedInput-root.Mui-disabled': {
        backgroundColor: theme.palette.background.default,
      },
    },
    input: {
      minWidth: theme.spacing(40),
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.down('sm')]: {
        minWidth: theme.spacing(29),
        padding: theme.spacing(0, 1, 0, 2),
        '& .MuiSelect-root': {
          fontSize: 12,
        },
      },
      '& .MuiSelect-root': {
        paddingRight: 0,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.divider,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': {
        fontSize: '1rem',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1.625, 0),
          fontSize: 'initial',
        },
      },
    },
    item: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.between('md', 'xl')]: {
        lineHeight: 1,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
    icon: {
      margin: theme.spacing(0, 2),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 0, 0, 1),
      },
    },
    formControl: {
      [theme.breakpoints.down('sm')]: {
        flex: '1 1 0px',
        '& .MuiFormLabel-root': {
          fontSize: 12,
          padding: theme.spacing(1),
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
          transform: 'translate(4px, 6px) scale(1)',
        },
        '& .MuiSelect-iconOutlined': {
          right: 0,
        },
      },
    },
  }),
);

const ScheduleControlsMemberInput = () => {
  const classes = useStyles();

  const { branch, permissible } = useAuth();

  const {
    navigate,
    search: [searchParams],
  } = useRouter();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { setTimezone } = useContext(ScheduleContext);

  const [getMembers, { data, loading }] = useGetMembersSelectLazyQuery();

  const { control, reset, watch, setValue } = useForm<{ m: string }>({
    defaultValues: { m: getProfile('member', searchParams) || '' },
  });

  const selected = watch('m');

  useEffect(() => {
    getMembers({
      variables: {
        input: {
          where: {
            branchMembers: branch
              ? { branch: { id: { _eq: branch.id } } }
              : undefined,
            _or: [
              { schedulable: { _eq: true } },
              { events: { id: { _ne: null } } },
            ],
          },
          orderBy: [{ lastName: OrderBy.ASC }, { firstName: OrderBy.ASC }],
          entityStates: permissible({ tester: true })
            ? [EntityState.ARCHIVED, EntityState.NORMAL]
            : [EntityState.NORMAL],
        },
      },
    });
  }, [getMembers, branch]);

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    // Unselect select field
    setTimeout(() => (document.activeElement as HTMLElement).blur(), 0);

    setProfile('member', searchParams, event.target.value as string);

    setTimezone(
      'member',
      data?.members.find(({ id }) => id === event.target.value)?.timezone,
    );

    navigate(_schedule.path + '?' + searchParams);
  };

  /**
   * Delete 'm' (member id) from search params
   */
  const handleClear = useCallback(() => {
    reset({ m: '' });
    setProfile('member', searchParams);
    setTimezone('member', undefined);
    navigate(_schedule.path + '?' + searchParams);
  }, [navigate, reset, searchParams, setTimezone]);

  useEffect(() => {
    // Apply update to all visible MemberInput fields.
    setValue('m', searchParams.get('m') || '');
  }, [setValue, searchParams]);

  useEffect(() => {
    if (data && selected && !!data.members.find(({ id }) => id === selected))
      setTimezone(
        'member',
        data.members.find(({ id }) => id === selected)!.timezone,
      );
  }, [data, selected, setTimezone]);

  useEffect(() => {
    if (
      selected &&
      !!data?.members?.length &&
      !data.members.map(({ id }) => id).includes(selected)
    ) {
      handleClear();
    }
  }, [selected, data, setValue, handleClear]);

  return (
    <Select
      name="m"
      label={
        loading
          ? 'Loading...'
          : selected
          ? !smDown
            ? 'Support Worker'
            : undefined
          : 'Support Worker'
      }
      control={control}
      validation={validateUuid}
      onChange={handleChange}
      className={classes.input}
      IconComponent={
        !!selected
          ? () => (
              <IconButton
                size="small"
                className={classes.icon}
                onClick={handleClear}
                onMouseDown={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              >
                <CloseRoundedIcon fontSize="small" />
              </IconButton>
            )
          : undefined
      }
      formControlProps={{
        variant: 'outlined',
        size: 'small',
        disabled: loading || !data?.members?.length,
        className: classes.formControl,
      }}
      items={data?.members?.map((member) => ({
        value: member.id,
        label: `${formatPersonName(member, {
          capitaliseLastName: true,
          lastNameFirst: true,
        })}${
          !!member.preferredName && member.preferredName !== member.firstName
            ? ` (${formatPersonName(member, {
                preferred: true,
              })})`
            : ``
        }`,
      }))}
      renderValue={(value) => (
        <Typography variant="body1" className={classes.item}>
          {data?.members.find((employee) => employee.id === value)
            ? formatPersonName(
                data.members.find((employee) => employee.id === value)!,
                {
                  lastNameFirst: true,
                  capitaliseLastName: true,
                },
              )
            : 'Unknown'}
        </Typography>
      )}
    />
  );
};

export default ScheduleControlsMemberInput;

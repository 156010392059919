import { Typography, TypographyProps } from '@material-ui/core';

type CopyrightProps = TypographyProps;

const Copyright = (props: CopyrightProps) => {
  return (
    <Typography variant="body2" color="textSecondary" align="center" {...props}>
      Copyright &copy; Workout Group Limited {new Date().getFullYear()}
      <br />
      ABN: 21 644 193 260
    </Typography>
  );
};

export default Copyright;

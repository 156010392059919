import { createStyles, makeStyles, Theme } from '@material-ui/core';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { Button } from '@timed/common';
import { ScheduleSettingsModal } from '@timed/schedule/components/SettingsModal';
import { ScheduleContext } from '@timed/schedule/context';
import { useModal } from 'mui-modal-provider';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      minWidth: 0,
      width: theme.spacing(10),
      backgroundColor: theme.palette.background.default,
      border: '1px solid ' + theme.palette.divider,
      borderRadius: 0,
      '& .MuiSvgIcon-root': {
        color: 'rgba(0, 0, 0, 0.54)',
      },
      [theme.breakpoints.down('sm')]: {
        height: 32,
        width: theme.spacing(8),
      },
    },
  }),
);

const ScheduleSettingsButton = () => {
  const classes = useStyles();
  const { showModal } = useModal();

  const {
    setFrom,
    showEventStatusColours,
    setShowEventStatusColours,
    dates: { range },
  } = useContext(ScheduleContext);

  const handleOpenSettingsModal = () => {
    const modal: { hide: () => void } = showModal(ScheduleSettingsModal, {
      range,
      setFrom,
      showEventStatusColours,
      setShowEventStatusColours,
      onClose: () => {
        modal.hide();
      },
    });
  };

  return (
    <>
      <Button
        className={classes.button}
        onClick={() => handleOpenSettingsModal()}
      >
        <SettingsOutlinedIcon fontSize="small" />
      </Button>
    </>
  );
};

export default ScheduleSettingsButton;

import { Timezone } from '@timed/gql';
import { createContext } from 'react';
import { UseFormReturn } from 'react-hook-form';

type FormData = {
  attachment: any;
  timezone: Timezone;
};

export type CsvImportSetDataReducer = (
  state: [string[], string[][]],
  dispatch: {
    action?: 'reset';
    data?: string[][];
  },
) => [string[], string[][]];

export type CsvImportContextType = {
  /**
   * Parse an imported CSV file.
   */
  parse: (files: FileList | null) => void;
  /**
   * Imported headers from a CSV file.
   */
  headers: string[];
  /**
   * Imported rows from a CSV file.
   */
  rows: string[][];
  /**
   * Form-control properties.
   */
  form: Pick<UseFormReturn<FormData>, 'setError' | 'setValue'> & {
    control?: UseFormReturn<FormData>['control'];
    errors?: UseFormReturn<FormData>['formState']['errors'];
  };
  /**
   * Timezone
   */
  timezone?: Timezone;
};

export const CsvImportContext = createContext<CsvImportContextType>({
  parse: () => {},
  headers: [],
  rows: [],
  form: {
    setError: () => {},
    setValue: () => {},
  },
});

import { Grid, Typography } from '@material-ui/core';
import { Protected } from '@timed/auth';
import {
  addServerErrors,
  BankAccountNameInput,
  BankAccountNumberInput,
  BankBsbInput,
  Block,
  IconButtonMulti,
} from '@timed/common';
import {
  HistoryRestorable,
  Member,
  Permission,
  SetMemberBankInput,
  useUpdateMembersBankDetailsMutation,
} from '@timed/gql';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type MemberUpdateBankDetailsFormProps = {
  member: Pick<Member, 'id' | 'bank'> & {
    archive?: Pick<HistoryRestorable, 'id'> | null;
  };
};

type FormData = {
  patch: {
    bank?: SetMemberBankInput | null;
  };
};

const MemberUpdateBankDetailsForm = ({
  member,
}: MemberUpdateBankDetailsFormProps) => {
  const [editing, setEditing] = useState<boolean>(false);

  const [updateMember, response] = useUpdateMembersBankDetailsMutation();

  const defaultValues: FormData = {
    patch: {
      bank: {
        accountName: member.bank?.accountName,
        accountNumber: member.bank?.accountNumber,
        bsb:
          member.bank?.bsb && member.bank.bsb.length === 6
            ? member.bank.bsb.slice(0, 3) + '-' + member.bank.bsb.slice(3, 6)
            : undefined,
      },
    },
  };

  const {
    handleSubmit,
    watch,
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });

  const currentValues = watch();

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = ({ patch: { bank } }: FormData) => {
    updateMember({
      variables: {
        input: {
          ids: [member.id],
          patch: {
            bank: {
              ...bank,
              bsb: !!bank?.bsb ? bank.bsb.replace('-', '') : undefined,
            },
          },
        },
      },
    }).catch((e) => {});

    reset({ patch: { bank } });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block
        title="Bank Details"
        topRight={
          !member.archive ? (
            <Protected permissions={Permission.MEMBER_WRITE}>
              <IconButtonMulti
                enabled={editing}
                changed={!isEqual(currentValues, defaultValues)}
                setEditing={setEditing}
                loading={response.loading}
                success={!!response.data}
              />
            </Protected>
          ) : undefined
        }
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={4} md={3} lg={2}>
            <Typography>BSB</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                !!member.bank?.bsb ? (
                  member.bank.bsb.slice(0, 3) +
                  '-' +
                  member.bank.bsb.slice(3, 6)
                ) : (
                  ''
                )
              ) : (
                <BankBsbInput
                  fullWidth
                  name="patch.bank.bsb"
                  variant="outlined"
                  size="small"
                  label="BSB"
                  control={control}
                  error={!!errors.patch?.bank?.bsb}
                  helperText={errors.patch?.bank?.bsb?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Account Name</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.bank?.accountName
              ) : (
                <BankAccountNameInput
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Account Name"
                  name="patch.bank.accountName"
                  control={control}
                  error={!!errors.patch?.bank?.accountName}
                  helperText={errors.patch?.bank?.accountName?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Account Number</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.bank?.accountNumber
              ) : (
                <BankAccountNumberInput
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Account Number"
                  name="patch.bank.accountNumber"
                  control={control}
                  error={!!errors.patch?.bank?.accountNumber}
                  helperText={errors.patch?.bank?.accountNumber?.message}
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
      </Block>
    </form>
  );
};

export default MemberUpdateBankDetailsForm;

import {
  ApolloClient,
  ApolloLink,
  FieldFunctionOptions,
  FieldReadFunction,
  InMemoryCache,
  RequestHandler,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import _ from 'lodash';

const authLink = setContext((_, { headers }) => {
  // Get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log('[GraphQL Error]:');
      console.log('  [Message]:', message);
      console.log('  [Locations]:', locations);
      console.log('  [Path]:', path);
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const httpLink = createUploadLink({
  uri:
    window.location.origin === process.env.REACT_APP_URL
      ? process.env.REACT_APP_API_URL!.toString()
      : process.env.REACT_APP_API_URL_DEV!.toString(),
  credentials: 'include',
  headers: {
    'Apollo-Require-Preflight': true,
  },
});

// const wsLink = new GraphQLWsLink(
//   createClient({
//     url: 'ws://localhost:5000/graphql',
//     // window.location.origin === process.env.REACT_APP_URL
//     //   ? process.env.REACT_APP_API_WS_URL!.toString()
//     //   : process.env.REACT_APP_API_WS_URL_DEV!.toString(),
//     connectionParams: {
//       authToken: token,
//     },
//   }),
// );

// const splitLink = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
//   },
//   wsLink,
//   httpLink,
// );

const readAggregatedResponse: FieldReadFunction<
  { aggregate: unknown; nodes: unknown[] },
  unknown,
  FieldFunctionOptions<
    { input?: { limit?: number; offset?: number } },
    Record<string, unknown>
  >
> = (existing, { args }) => {
  const { limit, offset } =
    !!args && !!args.input ? args.input : { limit: 0, offset: 0 };

  return (
    existing && {
      ..._.omit(existing, ['nodes']),
      nodes: !!existing.nodes?.length
        ? existing.nodes.slice(offset, offset! + limit!)
        : [],
    }
  );
};

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    authLink,
    errorLink as unknown as ApolloLink | RequestHandler,
    httpLink as unknown as ApolloLink | RequestHandler,
    // splitLink as unknown as ApolloLink | RequestHandler,
  ]),
  cache: new InMemoryCache({
    possibleTypes: {
      Address: [
        'Client',
        'Member',
        'Event',
        'RedactedClient',
        'NdisCoordinationClient',
      ],
      Business: ['Org'],
      Person: [
        'User',
        'Client',
        'Member',
        'RedactedClient',
        'RedactedMember',
        'NdisCoordinationClient',
      ],
    },
    typePolicies: {
      Query: {
        fields: {
          clientsAggregate: {
            keyArgs: false,
            read: readAggregatedResponse,
          },
          redactedClientsAggregate: {
            keyArgs: false,
            read: readAggregatedResponse,
          },
          clientNotesAggregate: {
            keyArgs: false,
            read: readAggregatedResponse,
          },
          membersAggregate: {
            keyArgs: false,
            read: readAggregatedResponse,
          },
          redactedMembersAggregate: {
            keyArgs: false,
            read: readAggregatedResponse,
          },
          ndisCoordinationClientsAggregate: {
            keyArgs: false,
            read: readAggregatedResponse,
          },
          clientNdisPlanItemTransactionAggregate: {
            keyArgs: false,
            read: readAggregatedResponse,
          },
        },
      },
    },
  }),
  name: process.env.REACT_APP_NAME,
  version: process.env.REACT_APP_VERSION,
  queryDeduplication: true,
  connectToDevTools: window.location.origin === process.env.REACT_APP_URL,
});

export default apolloClient;

import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { Block, formatPersonName, Link } from '@timed/common';
import { _peopleMembers } from '@timed/app';
import { Protected } from '@timed/auth';
import { SupportCoordinatorContext } from '@timed/support-coordinator';
import { SupportCoordinatorUpdatePersonalDetailsForm } from '@timed/support-coordinator/components/UpdatePersonalDetailsForm';
import { SupportCoordinatorUpdateSupportCoordinationDetailsForm } from '@timed/support-coordinator/components/UpdateSupportCoordinationDetailsForm';
import { format } from 'date-fns';
import React, { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(8),
      },
    },
    wrapper: {
      display: 'grid',
      gridAutoRows: 'max-content',

      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(8),
        marginRight: theme.spacing(8),
      },
    },
    archived: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
      },
    },
  }),
);

const SupportCoordinatorViewOverview = () => {
  const classes = useStyles();
  const supportCoordinator = useContext(SupportCoordinatorContext);

  return (
    <Box className={classes.wrapper}>
      {supportCoordinator.archive && (
        <Block>
          <Box className={classes.archived}>
            <LockIcon fontSize="small" />
            <Typography>
              Archived on{' '}
              {format(new Date(supportCoordinator.archive.date), 'd MMM yyyy')}{' '}
              by{' '}
              <Link to={'../../' + supportCoordinator.archive.actor!.id}>
                {formatPersonName(supportCoordinator.archive.actor!)}
              </Link>
            </Typography>
          </Box>
        </Block>
      )}

      <SupportCoordinatorUpdatePersonalDetailsForm
        supportCoordinator={supportCoordinator}
      />

      <SupportCoordinatorUpdateSupportCoordinationDetailsForm
        supportCoordinator={supportCoordinator}
      />

      <Protected admin={true}>
        <Block>
          <Grid container spacing={4}>
            <Grid item xs={4} md={3} lg={2}>
              <Typography>Created</Typography>
            </Grid>
            <Grid item xs={8} md={9} lg={10}>
              <Typography>
                {format(
                  new Date(supportCoordinator.createdAt),
                  'h:mm a, dd MMMM yyyy',
                )}
              </Typography>
            </Grid>
            {supportCoordinator.createdBy && (
              <>
                <Grid item xs={4} md={3} lg={2}>
                  <Typography>Creator</Typography>
                </Grid>
                <Grid item xs={8} md={9} lg={10}>
                  <Link
                    to={
                      _peopleMembers.path +
                      '/' +
                      supportCoordinator.createdBy?.id
                    }
                  >
                    <Typography>
                      {formatPersonName(supportCoordinator.createdBy!)}
                    </Typography>
                  </Link>
                </Grid>
              </>
            )}
          </Grid>
        </Block>
      </Protected>
    </Box>
  );
};

export default SupportCoordinatorViewOverview;

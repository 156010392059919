import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { ClientContactRelationsInput } from '@timed/client';
import {
  addServerErrors,
  Checkbox,
  EmailInput,
  FormModal,
  ModalProps,
  PersonNameInput,
  PhoneInput,
  TextField,
} from '@timed/common';
import {
  UpdateClientContactInput,
  useGetClientContactByIdQuery,
  useUpdateClientContactMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type ClientUpdateContactFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  contactId: string;
};

type FormData = Omit<UpdateClientContactInput, 'id'>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputs: {
      flex: '1 0 auto',
      overflowY: 'auto',
      display: 'grid',
      gridAutoFlow: 'row',
      gridAutoRows: 'max-content',
      gap: theme.spacing(4),
    },
  }),
);

const ClientUpdateContactFormModal = ({
  contactId,
  onClose,
  ...modalProps
}: ClientUpdateContactFormModalProps) => {
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: { patch: { relations: [] } },
  });

  const { loading } = useGetClientContactByIdQuery({
    variables: { id: contactId },
    onCompleted: (data) => {
      Object.entries(
        _.pick<FormData['patch']>(data.clientContactById, [
          'name',
          'email',
          'phone',
          'relations',
          'customRelation',
          'emergencyContact',
        ]),
      ).forEach(([k, v]) =>
        setValue(`patch.${k as keyof FormData['patch']}`, v),
      );

      if (data.clientContactById.client.preferredContact?.id === contactId)
        setValue(`patch.preferred`, true);
    },
  });

  const [updateEntity, response] = useUpdateClientContactMutation();

  useLoadingEffect(loading || response.loading);

  const onSubmit = ({ patch }: FormData) => {
    updateEntity({
      variables: {
        input: {
          id: contactId,
          patch,
        },
      },
    }).catch((e) => {});
  };

  /**
   * Display error messages from server response
   */
  useEffect(() => {
    if (response.error) addServerErrors(response.error, setError);
  }, [response.error, setError]);

  return (
    <FormModal
      modalProps={modalProps}
      title="Update Contact Person"
      saveText="Save"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
    >
      <Box className={classes.inputs}>
        {response.error && (
          <Typography color="error">{response.error.message}</Typography>
        )}
        <PersonNameInput
          required
          name="patch.name"
          variant="outlined"
          size="small"
          label="Name"
          control={control}
          error={!!errors.patch?.name}
          helperText={errors.patch?.name?.message}
          disabled={loading}
        />
        <EmailInput
          name="patch.email"
          variant="outlined"
          size="small"
          label="Email Address"
          control={control}
          error={!!errors.patch?.email}
          helperText={errors.patch?.email?.message}
          disabled={loading}
        />
        <PhoneInput
          name="patch.phone"
          variant="outlined"
          size="small"
          label="Phone Number"
          control={control}
          error={!!errors.patch?.phone}
          helperText={errors.patch?.phone?.message}
          disabled={loading}
        />
        <ClientContactRelationsInput
          name="patch.relations"
          control={control}
          error={!!errors.patch?.relations}
          helperText={errors.patch?.relations?.message}
          formControlProps={{ size: 'small', variant: 'outlined' }}
          disabled={loading}
        />
        <TextField
          name="patch.customRelation"
          variant="outlined"
          size="small"
          label="Additional relations"
          type="text"
          validation={{ maxLength: { value: 255, message: 'Too long' } }}
          control={control}
          error={!!errors.patch?.customRelation}
          helperText={errors.patch?.customRelation?.message}
          disabled={loading}
        />
        <Checkbox
          name="patch.emergencyContact"
          size="small"
          label="Emergency contact"
          defaultChecked={false}
          control={control}
          error={!!errors.patch?.emergencyContact}
          helperText={errors.patch?.emergencyContact?.message}
          disabled={loading}
        />
        <Checkbox
          name="patch.preferred"
          size="small"
          label="This is the preferred contact"
          defaultChecked={false}
          control={control}
          error={!!errors.patch?.preferred}
          helperText={errors.patch?.preferred?.message}
          disabled={loading}
        />
      </Box>
    </FormModal>
  );
};

export default ClientUpdateContactFormModal;

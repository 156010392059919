import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  addServerErrors,
  DateInput,
  FormModal,
  ModalProps,
  NumberInput,
  TextField,
  transformNumberToFixedFloat,
} from '@timed/common';
import {
  CreateMemberScheduleTimeLimitsInput,
  GetMemberScheduleTimeLimitsDocument,
  Member,
  OrderBy,
  useCreateMemberScheduleTimeLimitMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { MemberScheduleTimeLimitDescriptionInput } from '@timed/member/components/ScheduleTimeLimitDescriptionInput';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type MemberCreateScheduleTimeLimitFormModalProps = Omit<
  ModalProps,
  'children'
> & {
  member: Pick<Member, 'id'>;
  onClose: () => void;
};

type FormData = CreateMemberScheduleTimeLimitsInput['objects'][0] & {
  descriptionSelect: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(4),
    },
    inputs: {
      flex: '1 0 auto',
      overflowY: 'auto',
      display: 'grid',
      gridAutoFlow: 'row',
      gridAutoRows: 'max-content',
      gap: theme.spacing(4),
    },
    error: {
      color: theme.palette.error.main,
    },
  }),
);

const MemberCreateScheduleTimeLimitFormModal = ({
  onClose,
  member,
  ...modalProps
}: MemberCreateScheduleTimeLimitFormModalProps) => {
  const classes = useStyles();

  const [createEntity, response] = useCreateMemberScheduleTimeLimitMutation();

  const {
    handleSubmit,
    control,
    setError,
    watch,
    formState: { errors },
  } = useForm<FormData>({ defaultValues: { startAt: null, endAt: null } });

  const descriptionSelect = watch('descriptionSelect');

  useLoadingEffect(response.loading);

  const onSubmit = ({ limit, descriptionSelect, ...input }: FormData) => {
    if (descriptionSelect !== 'Other') input.description = descriptionSelect;

    createEntity({
      variables: {
        input: {
          objects: [
            {
              ...input,
              limit: limit * 60,
              member: { id: member.id },
            },
          ],
        },
      },
      refetchQueries: () => [
        {
          query: GetMemberScheduleTimeLimitsDocument,
          variables: {
            id: member.id,
            input: {
              where: {
                deletedAt: {
                  _eq: null,
                },
              },
              orderBy: [{ startAt: OrderBy.DESC }, { endAt: OrderBy.DESC }],
            },
          },
        },
      ],
    }).catch((e) => {});
  };

  /**
   * Display error messages from server response
   */
  useEffect(() => {
    if (response.error) addServerErrors(response.error, setError);
  }, [response.error, setError]);

  return (
    <FormModal
      modalProps={modalProps}
      title="Add Alternative Time Limit"
      saveText="Save"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        {!!response.error && (
          <Typography className={classes.error}>
            {response.error.message}
          </Typography>
        )}
        <Box className={classes.inputs}>
          <NumberInput
            required
            control={control}
            name="limit"
            variant="outlined"
            size="small"
            label="Limit"
            transform={transformNumberToFixedFloat(2)}
            onClick={(event) => {
              (event.target as HTMLInputElement).select();
            }}
          />
          <MemberScheduleTimeLimitDescriptionInput
            required
            control={control}
            name="descriptionSelect"
            formControlProps={{ size: 'small', variant: 'outlined' }}
          />
          {descriptionSelect === 'Other' && (
            <TextField
              required
              control={control}
              name="description"
              variant="outlined"
              size="small"
              label="Description"
            />
          )}
          <DateInput
            required
            keyboard
            label="Start"
            name="startAt"
            control={control}
            inputVariant="outlined"
            size="small"
            error={!!errors.startAt}
            helperText={errors.startAt?.message}
          />
          <DateInput
            required
            keyboard
            label="End"
            name="endAt"
            control={control}
            inputVariant="outlined"
            size="small"
            error={!!errors.endAt}
            helperText={errors.endAt?.message}
          />
        </Box>
      </Box>
    </FormModal>
  );
};

export default MemberCreateScheduleTimeLimitFormModal;

import { Menu, MenuItem, useMediaQuery, useTheme } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Protected } from '@timed/auth';
import {
  ClientDeleteNdisPlanItemTransactionDialog,
  ClientNdisPlanItemTransactionHistoryModal,
} from '@timed/client';
import { IconButton } from '@timed/common';
import { Permission, QueryByIdInput } from '@timed/gql';
import { useModal } from 'mui-modal-provider';
import { useState } from 'react';

type ClientNdisPlanItemTransactionActionMenuButtonProps = {
  clientNdisPlanItemTransaction: QueryByIdInput;
};

const ClientNdisPlanItemTransactionActionMenuButton = ({
  clientNdisPlanItemTransaction,
}: ClientNdisPlanItemTransactionActionMenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { showModal } = useModal();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenDeleteDialog = () => {
    const modal: { hide: () => void } = showModal(
      ClientDeleteNdisPlanItemTransactionDialog,
      {
        onClose: () => modal.hide(),
        clientNdisPlanItemTransaction,
      },
    );
  };

  const handleOpenViewHistoryModal = () => {
    const modal: { hide: () => void } = showModal(
      ClientNdisPlanItemTransactionHistoryModal,
      {
        onClose: () => modal.hide(),
        clientNdisPlanItemTransaction,
      },
    );
  };

  // const handleOpenUpdateModal = () => {
  //   const modal: { hide: () => void } = showModal(
  //     ClientUpdateNdisPlanItemForm,
  //     {
  //       onClose: () => modal.hide(),
  //       clientNdisPlanItem,
  //     },
  //   );
  // };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <span>
      <IconButton
        aria-label="menu"
        color="inherit"
        size={smDown ? 'small' : 'medium'}
        onClick={handleClick}
      >
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <Protected tester>
          <MenuItem
            onClick={() => {
              handleOpenViewHistoryModal();
              handleClose();
            }}
          >
            View history
          </MenuItem>
        </Protected>
        <Protected permissions={Permission.CLIENT_BUDGET_WRITE}>
          {/* <MenuItem
            onClick={() => {
              handleOpenUpdateModal();
              handleClose();
            }}
          >
            Update
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              handleOpenDeleteDialog();
              handleClose();
            }}
          >
            Delete
          </MenuItem>
        </Protected>
      </Menu>
    </span>
  );
};

export default ClientNdisPlanItemTransactionActionMenuButton;

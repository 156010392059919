import { HistoryRestorable, Maybe } from '@timed/gql';
import { PayrollCategory } from '@timed/report';
import {
  addDays,
  differenceInMinutes,
  eachDayOfInterval,
  isAfter,
  isBefore,
  isEqual,
  isSaturday,
  isSunday,
  isWithinInterval,
  set,
  startOfDay,
} from 'date-fns';

type CalculatePayrollActivityFn = {
  eventStart: Date;
  eventEnd: Date;
  shiftStart: Date;
  shiftEnd: Date;
  passive?: boolean;
  cancel?: Maybe<Pick<HistoryRestorable, 'id'>>;
  occursDuringPublicHoliday?: boolean;
  memberBonusEligible: boolean;
};

export const calculatePayrollCategory = ({
  eventEnd,
  eventStart,
  shiftEnd,
  shiftStart,
  passive,
  cancel,
  occursDuringPublicHoliday,
  memberBonusEligible,
}: CalculatePayrollActivityFn): PayrollCategory => {
  // Passive shift
  if (passive)
    return !!cancel ? 'Cancelled Overnight Allowance' : 'overnight allowance 2';

  let category: 'WKD' | 'AFT' | 'NHT' | 'SAT' | 'SUN' | 'PUB' | undefined;

  const datesInEvent = eachDayOfInterval({
    start: new Date(eventStart),
    end: new Date(eventEnd),
  }).filter((d) => !isEqual(new Date(eventEnd), d));

  // Public holiday
  if (occursDuringPublicHoliday) category = 'PUB';
  // Sunday
  else if (datesInEvent.some((d) => isSunday(d))) category = 'SUN';
  // Saturday
  else if (datesInEvent.some((d) => isSaturday(d))) category = 'SAT';
  // Night
  else if (
    isBefore(new Date(shiftStart), set(new Date(eventStart), { hours: 6 })) ||
    isAfter(new Date(eventEnd), startOfDay(addDays(new Date(eventStart), 1)))
  )
    category = 'NHT';
  // Weekday
  else if (
    isWithinInterval(new Date(shiftStart), {
      start: set(new Date(eventStart), { hours: 6, minutes: 0 }),
      end: set(new Date(eventStart), { hours: 20, minutes: 0 }),
    }) &&
    isWithinInterval(new Date(shiftEnd), {
      start: set(new Date(eventStart), { hours: 6, minutes: 0 }),
      end: set(new Date(eventStart), { hours: 20, minutes: 0 }),
    })
  )
    category = 'WKD';
  // Afternoon
  else if (!category) category = 'AFT';

  if (!memberBonusEligible) {
    switch (category) {
      case 'WKD':
        return !!cancel ? 'Cancelled Weekday' : 'Base Hourly';
      case 'AFT':
        return !!cancel ? 'Cancelled Afternoon' : 'L1P1 Afternoon';
      case 'NHT':
        return !!cancel ? 'Cancelled Night' : 'L1P1 Night';
      case 'SAT':
        return !!cancel ? 'Cancelled Saturday' : 'L1P1 Sat';
      case 'SUN':
        return !!cancel ? 'Cancelled Sunday' : 'L1P1 Sun';
      case 'PUB':
        return !!cancel ? 'Cancelled Public Holiday' : 'L1P1 Pub';
    }
  }

  let bonus: number;

  const duration = differenceInMinutes(
    new Date(eventEnd),
    new Date(eventStart),
  );

  if (duration <= 120) bonus = 2;
  else if (duration <= 180) bonus = 3;
  else if (duration <= 240) bonus = 4;
  else if (duration <= 300) bonus = 5;
  else if (duration <= 360) bonus = 6;
  else if (duration <= 420) bonus = 7;
  else if (duration <= 480) bonus = 8;
  else if (duration <= 540) bonus = 9;
  else if (duration <= 600) bonus = 10;
  else if (duration <= 660) bonus = 11;
  else bonus = 12;

  return ('L1P1 ' + category + ' BONUS ' + bonus) as PayrollCategory;
};

import { Select, SelectProps } from '@timed/common';
import { eventFilesMetadata } from '@timed/event/constants';
import { validateFileType } from '@timed/file';
import { EventFileType } from '@timed/gql';

const EventFileTypeInput = ({
  defaultValue,
  ...props
}: Omit<SelectProps, 'items'>) => (
  <Select
    label="Category"
    validation={validateFileType('event')}
    items={Object.values(EventFileType)
      .map((name) => {
        return {
          value: name,
          label: eventFilesMetadata.find((meta) => meta.id === name)!.name,
        };
      })
      .sort((a, b) => (a.label > b.label ? 1 : -1))}
    renderValue={(value) =>
      eventFilesMetadata.find((meta) => meta.id === value)?.name
    }
    {...props}
  />
);

export default EventFileTypeInput;

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Protected, useAuth } from '@timed/auth';
import { Checkbox, FormModal, IconButton, ModalProps } from '@timed/common';
import { Permission } from '@timed/gql';
import { NotesControlSettingsKeywordsTable } from '@timed/notes/components/ControlSettingsKeywordsTable';
import { NotesCreateKeywordFormModal } from '@timed/notes/components/CreateKeywordFormModal';
import { NotesContextInput } from '@timed/notes/context';
import { useModal } from 'mui-modal-provider';
import { useForm } from 'react-hook-form';

type NotesSettingsModalProps = Omit<ModalProps, 'children'> &
  Pick<
    NotesContextInput,
    | 'showImporter'
    | 'setShowImporter'
    | 'highlightKeywords'
    | 'setHighlightKeywords'
  > & {
    onClose: () => void;
  };

type FormData = Pick<NotesContextInput, 'showImporter' | 'highlightKeywords'>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        alignItems: 'start',
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
        flexDirection: 'column',
      },
    },
    groups: {
      display: 'grid',
      gridTemplateColumns: 'auto',
      gap: theme.spacing(4),
    },
    group: {
      display: 'grid',
      gridTemplateColumns: 'max-content',
      gridAutoRows: 'max-content',
      gap: theme.spacing(1),
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    keywordControls: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
  }),
);

const NotesControlSettingsModal = ({
  onClose,
  showImporter,
  setShowImporter,
  highlightKeywords,
  setHighlightKeywords,
  ...modalProps
}: NotesSettingsModalProps) => {
  const classes = useStyles();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { permissible } = useAuth();

  const { showModal } = useModal();

  const { handleSubmit, control } = useForm<FormData>({
    defaultValues: { showImporter, highlightKeywords },
  });

  const onSuccess = () => {};

  const onSubmit = ({ showImporter, highlightKeywords }: FormData) => {
    setShowImporter(showImporter);
    setHighlightKeywords(highlightKeywords);
    onClose();
  };

  const handleOpenCreateKeywordFormModal = () => {
    const modal: { hide: () => void } = showModal(NotesCreateKeywordFormModal, {
      onClose: () => {
        modal.hide();
      },
    });
  };

  return (
    <FormModal
      modalProps={modalProps}
      title="Notes Settings"
      loading={false}
      success={false}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.groups}>
          <Box className={classes.group}>
            <Typography className={classes.bold}>Columns</Typography>
            <Checkbox
              control={control}
              label="Show Importer column"
              name="showImporter"
              formControlProps={{ variant: 'outlined', size: 'small' }}
            />
          </Box>
        </Box>
        <Protected admin>
          <Box className={classes.groups}>
            <Box className={classes.group}>
              <Box className={classes.keywordControls}>
                <Typography className={classes.bold}>Keywords</Typography>
                <IconButton
                  aria-label="menu"
                  color="inherit"
                  size={smDown ? 'small' : 'medium'}
                  onClick={handleOpenCreateKeywordFormModal}
                >
                  <AddIcon fontSize="small" />
                </IconButton>
              </Box>
              <NotesControlSettingsKeywordsTable />
            </Box>
          </Box>
        </Protected>
        <Protected permissions={Permission.CLIENT_READ}>
          <Box className={classes.groups}>
            <Box className={classes.group}>
              <Checkbox
                control={control}
                label="Highlight keywords in comments"
                name="highlightKeywords"
                formControlProps={{ variant: 'outlined', size: 'small' }}
              />
            </Box>
          </Box>
        </Protected>
      </Box>
    </FormModal>
  );
};

export default NotesControlSettingsModal;

import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@material-ui/core';

export type ButtonProps = MuiButtonProps;

const Button = (props: ButtonProps) => <MuiButton {...props} />;

export default Button;

import { _uploadsOverview } from '@timed/app';
import { Page } from '@timed/page';
import { UploadCreateForm } from '@timed/upload';

const RouteCoreSupportUploadsAdd = () => {
  return (
    <Page {..._uploadsOverview}>
      <UploadCreateForm />
    </Page>
  );
};

export default RouteCoreSupportUploadsAdd;

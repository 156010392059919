import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { _supportDebug } from '@timed/app';
import { Page } from '@timed/page';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      padding: theme.spacing(4),
      '& .MuiTypography-root:first-child': {
        fontSize: 18,
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
        padding: theme.spacing(1),
      },
    },
    qa: {
      display: 'flex',
      alignItems: 'start',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.paper2,
      gap: theme.spacing(1),
      padding: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(2),
        padding: theme.spacing(4),
      },
    },
  }),
);

const RouteSupportHelp = () => {
  const classes = useStyles();

  return (
    <Page {..._supportDebug}>
      <Box className={classes.wrapper}>
        <Box className={classes.qa}>
          <Typography className={classes.bold}>When do I get paid?</Typography>
          <Typography>
            You get paid usually on the Monday for the previous week.
          </Typography>
        </Box>
        <Box className={classes.qa}>
          <Typography className={classes.bold}>
            Can I use my phone at work?
          </Typography>
          <Typography>
            No. The only time you can use your phone at work is when you clock
            on/off, add shift notes or contact the office.
          </Typography>
        </Box>
        <Box className={classes.qa}>
          <Typography className={classes.bold}>
            I cannot see who is doing the next shift
          </Typography>
          <Typography>
            You should see several shifts before and after your current shift.
            Make sure you select the participant.
          </Typography>
          <img
            src="https://timed-public.s3.ap-southeast-2.amazonaws.com/faq-2.png"
            alt="How to add a participant"
            height={225}
          />
        </Box>
        <Box className={classes.qa}>
          <Typography className={classes.bold}>
            I cannot clock on / clock off
          </Typography>
          <Typography>
            Ensure locations services is enabled for your device.
            <br />
            <br />
            iPhone: Settings -&gt; Privacy & Security -&gt; Location Services -
            &gt; Safari.
          </Typography>
        </Box>

        <Box className={classes.qa}>
          <Typography className={classes.bold}>
            How do I write notes?
          </Typography>
          <Typography>
            All times are recorded using 24 hr clock and 4 numbers (digits).
            <br />
            Like 0600 or 1800
            <br />
            <br />
            Not 6am
            <br />
            Not 600
            <br />
            Not 06:00
            <br />
            Not 0600hrs
            <br />
            <br />
            <br />
            The computer reads your notes, so it’s important to write in this
            way.
            <br />
            <br />
            We provide two types of support that need different notes.
            <br />
            <br />
            <br />
            1 - handover shift. These are shifts where you are relieving another
            Support Worker (SW) or a Carer or a family member.
            <br />
            <br />
            Here you write in your notes:
            <br />
            - 0700 handover from (name of SW or person you are replacing). This
            HAS TO BE the first line. And it’s the only thing on that line.
            <br />
            - Not: 0700 I received handover from …
            <br />
            - Note: “hand over” is not the same as “handover”.
            <br />
            - Write your notes during your shift and save each time. The
            computer constantly reads your notes and will alert your supervisor
            if there is or could be a problem.
            <br />
            - At the end of your shift you will be relieved by the next person
            (SW or other responsible adult).
            <br />
            - You record this as:
            <br />
            - 1400 handover to (name of responsible adult)
            <br />
            - That HAS TO BE the last line.
            <br />
            <br />- With a Handover Shift, you are responsible, and{' '}
            <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
              you cannot leave
            </span>{' '}
            the participant without a handover to an adult who will take
            responsibility.
            <br />
            - Just to be clear: you cannot “handover” to a 6 year old child or
            the gardener or do the handover to the next SW over the phone.
            <br />- If the next SW is late, you wait until he/she is physically
            present. You can call your supervisor, but{' '}
            <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
              under no circumstances can you leave this type of participant
            </span>{' '}
            without a responsible adult present.
            <br />
            <br />
            <br />
            2 - Non-handover shifts. The same level of care is required, but
            there is no handover. Again, recording notes is important.
            <br />
            <br />
            - 0730 started shift with (name of participant)
            <br />
            - 0930 cleaned the dishes
            <br />
            - 1145 prepared lunch
            <br />
            - Etc
            <br />
            - 1305 finished shift with (name of participant)
            <br />
            <br />
            First line always the start
            <br />
            Last line always the finish.
            <br />
            The computer reads the notes and the office is notified if not
            correct.
          </Typography>
        </Box>

        <Box className={classes.qa}>
          <Typography className={classes.bold}>
            Can I administer medication?
          </Typography>
          <Typography>
            Anything to do with medication is strictly regulated.
            <br />
            <br />
            <br />
            <span style={{ fontWeight: 'bold', fontSize: '150%' }}>
              If you don’t have any medication certification:
            </span>
            <br />
            <br />
            You are{' '}
            <span
              style={{
                fontWeight: 'bold',
                fontStyle: 'italic',
                textDecoration: 'underline',
              }}
            >
              not
            </span>{' '}
            to:
            <br />
            <span style={{ textDecoration: 'underline' }}>
              Prompt or assist or administer
            </span>{' '}
            any medication.
            <br />
            <br />
            To be clear: when you get to your shift, you cannot ask “did you
            take your medication”?
            <br />
            Or “don’t forget to take your medication”.
            <br />
            <br />
            You cannot touch medication, not handle a bottle or a Webster Pack
            to hand to the participant even when asked.
            <br />
            <br />
            When you notice anything out of the ordinary, like medication in a
            Webster Pack has not been taken, you call your supervisor.
            <br />
            <br />
            <span style={{ textDecoration: 'underline' }}>
              Some of this seems ridiculous, but it’s the law.
            </span>
            <br />
            <br />
            <br />
            <br />
            <span style={{ fontWeight: 'bold', fontSize: '150%' }}>
              If you have an “Assist with Self Medication” certificate
            </span>
            <br />
            <br />
            You can ask “did you take your medication”.
            <br />
            That and the response has to be recorded in your notes.
            <br />
            <br />
            When asked, or prompted, you can bring the medication to the
            participant.
            <br />
            <br />
            However It is still your responsibility to do the medication check,
            as per your training for your certification.
            <br />
            <br />
            <span style={{ fontWeight: 'bold' }}>
              You supervise the taking of medication but you cannot administer
              medication.
            </span>
            <br />
            For example you cannot put the medication into the participant’s
            mouth even when asked.
            <br />
            <br />
            Record time, medication and be alert to any physical, cognitive or
            behavioural changes prior to or after taking the medication. If
            there are any changes you must report them immediately to your
            supervisor.
            <br />
            <br />
            <span style={{ fontWeight: 'bold', fontSize: '150%' }}>
              Note: Every participant has the right to refuse all or some of the
              medication.
            </span>
            <br />
            <br />
            If the participant refuses to take the medication, you notify your
            supervisor and record “refused medication“ in your notes.
            <br />
            <br />
            <br />
            <br />
            <span style={{ fontSize: '150%' }}>
              If you have a certificate that allows you to{' '}
              <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                administer
              </span>{' '}
              <span style={{ fontWeight: 'bold' }}>
                medication, then you can help with medication as per your
                training.
              </span>
            </span>
            <br />
            <br />
            In addition:
            <br />
            <br />
            Record time, medication and be alert to any physical, cognitive or
            behavioural changes prior to or after taking the medication. If
            there are any changes you must report them immediately to your
            supervisor.
            <br />
            <br />
            <span style={{ fontWeight: 'bold', fontSize: '150%' }}>
              Note: Every participant has the right to refuse all or some of the
              medication.
            </span>
            <br />
            <br />
            If the participant refuses to take the medication, you notify your
            supervisor and record “refused medication“ in your notes.
          </Typography>
        </Box>

        <Box className={classes.qa}>
          <Typography className={classes.bold}>
            When do I need to arrive for my shifts?
          </Typography>
          <Typography>
            <span style={{ fontWeight: 'bold' }}>You have to be on time.</span>
            <br />
            <br />
            You record your clock on and clock off.
            <br />
            The computer checks this.
            <br />
            <br />
            At the top of your shift it will show how often you are late. If you
            are late too often, the computer will not allocate you any future
            handover shifts.
            <br />
            <br />
            Some new support workers think they can clock on before they get to
            work. That way they clock on at the right time. Please don’t.
            <br />
            If you can think of a way to beat the computer, then so have the
            computer programmers
            <br />
            GPS coordinates are accurate to within a meter. The program allows
            30 meters.
            <br />
            Any more, an alert pops up.
            <br />
            <br />
            (This is actually for your benefit. It proves that you are at the
            participant’s address.)
          </Typography>
        </Box>
      </Box>
    </Page>
  );
};
export default RouteSupportHelp;

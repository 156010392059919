import { Event } from '@timed/gql';
import { BillingRate, billingRates, billingTimes } from '@timed/report';
import {
  addHours,
  areIntervalsOverlapping,
  eachDayOfInterval,
  isAfter,
  isEqual,
  isSaturday,
  isSunday,
  startOfDay,
} from 'date-fns';

const _getBillingRate = (date: Date, type: BillingRate['type']) =>
  billingRates
    .filter((rate) => isAfter(new Date(date), rate.date))
    .find((rate) => rate.type === type);

/**
 * Determine the billing rate for a specific event.
 * Order of importance: Public Holiday > Sunday > Saturday > Night > Evening > Weekday Day-time.
 * @param event The event to check.
 * @returns BillingRate.
 */
export const getBillingRate = (
  event: Pick<Event, 'startAt' | 'endAt'> & Partial<Pick<Event, 'passive'>>,
  occursDuringPublicHoliday: boolean,
): BillingRate | undefined => {
  // Event is night-time sleepover.
  if (event.passive) return _getBillingRate(event.startAt, 'passive');

  // Event occurs on a public holiday
  if (occursDuringPublicHoliday)
    return _getBillingRate(event.startAt, 'public-holiday');

  // Each day of event.
  const dates = eachDayOfInterval({
    start: new Date(event.startAt),
    end: new Date(event.endAt),
  }).filter((date) => !isEqual(new Date(event.endAt), date));

  // Event occurs on a Sunday.
  if (dates.some((d) => isSunday(d)))
    return _getBillingRate(event.startAt, 'sunday');

  // Event occurs on a Saturday.
  if (dates.some((d) => isSaturday(d)))
    return _getBillingRate(event.startAt, 'saturday');

  // Event occurs on a weekday.
  return _getBillingRate(
    event.startAt,
    billingTimes.find(
      (time) =>
        areIntervalsOverlapping(
          { start: new Date(event.startAt), end: new Date(event.endAt) },
          {
            start: addHours(startOfDay(new Date(event.startAt)), time.start),
            end: addHours(startOfDay(new Date(event.startAt)), time.end),
          },
        ) ||
        areIntervalsOverlapping(
          { start: new Date(event.startAt), end: new Date(event.endAt) },
          {
            start: addHours(startOfDay(new Date(event.endAt)), time.start),
            end: addHours(startOfDay(new Date(event.endAt)), time.end),
          },
        ),
    )!.billingRateType,
  );
};

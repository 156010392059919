import {
  Checkbox,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Theme, useTheme } from '@material-ui/core/styles';
import AddIcon from '@mui/icons-material/Add';
import { useMediaQuery } from '@mui/material';
import { _peopleClients, _peopleMembers } from '@timed/app';
import { useAuth } from '@timed/auth';
import {
  formatPersonName,
  IconButton,
  Link,
  roundNumber,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  wordsRegex,
} from '@timed/common';
import { EventCreateFileFormModal } from '@timed/event';
import { OrderBy, Permission } from '@timed/gql';
import {
  NotesAttachment,
  NotesContext,
  NotesCreateFileFormModal,
  NotesSelectionStatus,
  NotesUpdateNoteFormModal,
  NotesUpdateNoteFormModalProps,
} from '@timed/notes';
import { differenceInMinutes, format } from 'date-fns';
import { useModal } from 'mui-modal-provider';
import { useCallback, useContext, useMemo } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      flexGrow: 1,
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
    },
    control: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    message: {
      whiteSpace: 'pre-wrap',
      margin: 0,
    },
    highlight: {
      backgroundColor: theme.palette.secondary.lightest,
    },
    attachments: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
  }),
);

const NotesList = () => {
  const classes = useStyles();
  const { showModal } = useModal();
  const { permissible } = useAuth();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    nodes,
    eventFiles,
    noteFiles,
    keywords,
    loading,
    selectNotes,
    selectedNotes,
    input: { orderBy, setOrderBy, showImporter, highlightKeywords },
  } = useContext(NotesContext);

  const handleOpenCreateEventFileModal = useCallback(
    (id: string) => {
      const modal: { hide: () => void } = showModal(EventCreateFileFormModal, {
        onClose: () => {
          modal.hide();
        },
        event: { id },
      });
    },
    [showModal],
  );

  const handleOpenCreateNotesFileModal = useCallback(
    (id: string) => {
      const modal: { hide: () => void } = showModal(NotesCreateFileFormModal, {
        onClose: () => {
          modal.hide();
        },
        note: { id },
      });
    },
    [showModal],
  );

  const keywordRegex = useMemo(
    () =>
      !!keywords?.length
        ? wordsRegex(keywords.map(({ text }) => text))
        : undefined,
    [keywords],
  );

  const handleHighlightKeywords = useCallback(
    (comment?: string): string | undefined => {
      return !!keywordRegex && !!comment && !!highlightKeywords
        ? comment.replace(
            keywordRegex,
            `<span class="${classes.highlight}">$1</span>`,
          )
        : comment;
    },
    [keywordRegex, highlightKeywords, classes.highlight],
  );

  const handleOpenUpdateNoteModal = useCallback(
    (note: NotesUpdateNoteFormModalProps['note']) => {
      const modal: { hide: () => void } = showModal(NotesUpdateNoteFormModal, {
        note,
        onClose: () => {
          modal.hide();
        },
      });
    },
    [showModal],
  );

  if (!nodes?.length) return null;

  return (
    <div className={classes.wrapper}>
      {!smDown && <NotesSelectionStatus />}
      <Table
        inline
        enableRowHighlighting
        showIndexColumn={!smDown}
        loading={loading}
        wrapperStyle={{ flex: '1 1 0' }}
      >
        <TableHeader
          sticky
          hidden={
            smDown || !permissible({ permissions: Permission.CLIENT_READ })
          }
          align="center"
        >
          <Checkbox
            checked={nodes.every(({ id }) => selectedNotes.includes(id))}
            onClick={(event) =>
              selectNotes({
                type: nodes
                  .map(({ id }) => id)
                  .every((id) => selectedNotes.includes(id))
                  ? 'remove'
                  : 'merge',
                ids: event.currentTarget.value
                  ? selectedNotes
                  : nodes.map(({ id }) => id),
              })
            }
          />
        </TableHeader>
        <TableHeader
          sticky
          order={orderBy[0].commentedAt}
          onClick={() => {
            setOrderBy(
              orderBy[0].commentedAt === OrderBy.ASC
                ? [{ commentedAt: OrderBy.DESC }]
                : [{ commentedAt: OrderBy.ASC }],
            );
          }}
        >
          Date
        </TableHeader>
        <TableHeader sticky>Time</TableHeader>
        <TableHeader
          order={orderBy[0].client?.lastName}
          onClick={() => {
            setOrderBy(
              orderBy[0].client?.lastName === OrderBy.ASC
                ? [{ client: { lastName: OrderBy.DESC } }]
                : [{ client: { lastName: OrderBy.ASC } }],
            );
          }}
        >
          Participant
        </TableHeader>
        <TableHeader
          order={orderBy[0].commentedBy?.lastName}
          onClick={() => {
            setOrderBy(
              orderBy[0].commentedBy?.lastName === OrderBy.ASC
                ? [{ commentedBy: { lastName: OrderBy.DESC } }]
                : [{ commentedBy: { lastName: OrderBy.ASC } }],
            );
          }}
        >
          Author
        </TableHeader>
        <TableHeader align="center">Start Date</TableHeader>
        <TableHeader align="center">Start Time</TableHeader>
        <TableHeader align="center">End Time</TableHeader>
        <TableHeader align="center">Hours</TableHeader>
        <TableHeader align="center">KM's</TableHeader>
        <TableHeader style={{ width: 'auto', minWidth: 400 }}>
          Comment
        </TableHeader>
        <TableHeader
          hidden={!showImporter}
          order={orderBy[0].createdBy?.lastName}
          onClick={() => {
            setOrderBy(
              orderBy[0].createdBy?.lastName === OrderBy.ASC
                ? [{ createdBy: { lastName: OrderBy.DESC } }]
                : [{ createdBy: { lastName: OrderBy.ASC } }],
            );
          }}
        >
          Importer
        </TableHeader>
        <TableHeader align="center">Attachments</TableHeader>
        {nodes.map((note, i) => (
          <TableRow key={i}>
            <TableCell>
              <Checkbox
                checked={selectedNotes.includes(note.id)}
                onClick={() => selectNotes({ ids: [note.id] })}
              />
            </TableCell>
            <TableCell>
              <Typography>
                {format(new Date(note.commentedAt), 'dd/MM/yyyy')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                {format(new Date(note.commentedAt), 'HH:mm')}
              </Typography>
            </TableCell>
            <TableCell>
              {permissible({ permissions: Permission.CLIENT_READ }) ? (
                <Link to={_peopleClients.path + '/' + note.client.id}>
                  {formatPersonName(note.client, {
                    capitaliseLastName: true,
                    lastNameFirst: true,
                    preferredAndLast: true,
                  })}
                </Link>
              ) : (
                formatPersonName(note.client, {
                  capitaliseLastName: true,
                  lastNameFirst: true,
                  preferredAndLast: true,
                })
              )}
            </TableCell>
            <TableCell>
              <Typography>
                {note.commentedBy &&
                permissible({ permissions: Permission.MEMBER_READ }) ? (
                  <Link to={_peopleMembers.path + '/' + note.commentedBy.id}>
                    {formatPersonName(note.commentedBy, {
                      capitaliseLastName: true,
                      lastNameFirst: true,
                    })}
                  </Link>
                ) : (
                  note.commentedByText
                )}
              </Typography>
            </TableCell>
            <TableCell>
              {!!note.event?.startAt && (
                <Typography>
                  {format(new Date(note.event.startAt), 'dd/MM/yyyy')}
                </Typography>
              )}
            </TableCell>
            <TableCell>
              {!!note.event?.startAt && (
                <Typography>
                  {format(new Date(note.event.startAt), 'HH:mm')}
                </Typography>
              )}
            </TableCell>
            <TableCell>
              {!!note.event?.endAt && (
                <Typography>
                  {format(new Date(note.event.endAt), 'HH:mm')}
                </Typography>
              )}
            </TableCell>
            <TableCell>
              {!!note.event?.startAt && !!note.event.endAt && (
                <Typography>
                  {roundNumber(
                    differenceInMinutes(
                      new Date(note.event.endAt),
                      new Date(note.event.startAt),
                    ) / 60,
                    2,
                  )}
                </Typography>
              )}
            </TableCell>
            <TableCell>
              {!!note.event?.travelDistance && (
                <Typography>{note.event.travelDistance / 1000}</Typography>
              )}
            </TableCell>
            <TableCell onClick={() => handleOpenUpdateNoteModal(note)}>
              {
                <p
                  className={classes.message}
                  dangerouslySetInnerHTML={{
                    __html: handleHighlightKeywords(note.comment?.trim()) ?? '',
                  }}
                />
              }
            </TableCell>
            <TableCell>
              <Typography>
                <Link to={_peopleMembers.path + '/' + note.createdBy.id}>
                  {formatPersonName(note.createdBy, {
                    capitaliseLastName: true,
                    lastNameFirst: true,
                  })}
                </Link>
              </Typography>
            </TableCell>
            <TableCell>
              <div className={classes.attachments}>
                {[
                  ...(eventFiles?.filter(
                    ({ owner: { id } }) => id === note.event?.id,
                  ) ?? []),
                  ...(noteFiles?.filter(
                    ({ owner: { id } }) => id === note.id,
                  ) ?? []),
                ].map((props) => (
                  <NotesAttachment {...props} />
                ))}
                <IconButton
                  onClick={() =>
                    note.event
                      ? handleOpenCreateEventFileModal(note.event.id)
                      : handleOpenCreateNotesFileModal(note.id)
                  }
                >
                  <AddIcon fontSize="small" />
                </IconButton>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </div>
  );
};

export default NotesList;

import {
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { IconButton } from '@timed/common';
import { useGenerateMemberHoursMutation } from '@timed/gql';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      backgroundColor: theme.palette.background.default,
      border: '1px solid ' + theme.palette.divider,
      borderRadius: 0,
      [theme.breakpoints.down('sm')]: {
        width: theme.spacing(8),
        height: theme.spacing(8),
      },
    },
    icon: {
      fontSize: 8,
    },
    spin: {
      animation: '$spin 750ms linear infinite',
      fill: theme.palette.success.dark,
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  }),
);

type ScheduleMemberInfoGenerateButtonProps = {
  memberId: string;
};

const ScheduleMemberInfoGenerateButton = ({
  memberId,
}: ScheduleMemberInfoGenerateButtonProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [generate, { loading, client }] = useGenerateMemberHoursMutation({
    variables: { input: { member: { id: memberId } } },
    onCompleted(data) {
      if (client) {
        client.cache.modify({
          id: `Member:${memberId}`,
          fields: {
            hours() {
              return data.generateMemberHours;
            },
          },
        });
      }
    },
  });

  return (
    <IconButton
      disabled={loading}
      className={classes.button}
      size="small"
      aria-label="back"
      onClick={(event) => {
        event.stopPropagation();
        if (!loading) generate();
      }}
    >
      <RefreshIcon
        fontSize={smDown ? 'default' : 'small'}
        className={loading ? classes.spin : undefined}
      />
    </IconButton>
  );
};

export default ScheduleMemberInfoGenerateButton;

import {
  createStyles,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps as MuiDialogProps,
  DialogTitle,
  makeStyles,
  Theme,
} from '@material-ui/core';

export type DialogProps = Omit<MuiDialogProps, 'title'> & {
  onExited?: () => void;
  title: string | JSX.Element;
  description?: string | JSX.Element;
  content?: JSX.Element;
  actions: JSX.Element[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      [theme.breakpoints.up('md')]: {
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      boxSizing: 'border-box',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '100%',
        borderRadius: 'unset',
      },
    },
  }),
);

const Dialog = ({
  open,
  onExited,
  title,
  description,
  content,
  actions,
  ...props
}: DialogProps) => {
  const classes = useStyles();

  return (
    <MuiDialog
      {...props}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      closeAfterTransition
      className={classes.dialog}
      BackdropProps={{ timeout: 250 }}
      open={open}
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {!!description && <DialogContentText>{description}</DialogContentText>}
        {content}
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </MuiDialog>
  );
};

export default Dialog;

import {
  Badge,
  Box,
  createStyles,
  Divider,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
  useTheme,
  withStyles,
} from '@material-ui/core';
import BackHandIcon from '@mui/icons-material/BackHand';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import MedicationLiquidSharpIcon from '@mui/icons-material/MedicationLiquidSharp';
import PsychologyIcon from '@mui/icons-material/Psychology';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import { _peopleMembers, _peopleMembersProfile } from '@timed/app';
import { useAuth } from '@timed/auth';
import { Avatar, formatPersonName, useRouter } from '@timed/common';
import { Permission } from '@timed/gql';
import {
  MemberContext,
  MemberViewActionMenuButton,
  MemberViewCloseButton,
} from '@timed/member';
import { Page } from '@timed/page';
import { differenceInMinutes } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';

type TabItem = {
  label: string;
  path: string;
  permission?: Permission | Permission[];
  admin?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    top: {
      flex: '0 1 auto',
      display: 'grid',
      // gap: theme.spacing(2),
    },
    card: {
      display: 'grid',
      // gridAutoFlow: "column",
      // gridAutoColumns: "max-content",
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(2),
        gridTemplateColumns: 'max-content auto max-content',
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
        gridTemplateColumns: 'max-content auto max-content',
      },
    },
    avatar: {
      width: theme.spacing(20),
      height: theme.spacing(20),
      fontSize: '175%',
      lineHeight: theme.spacing(8) + 'px',
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: 1,
      [theme.breakpoints.down('sm')]: {
        width: theme.spacing(16),
        height: theme.spacing(16),
        fontSize: '150%',
      },
    },
    description: {
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(1),
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(0),
      },
    },
    icons: {
      display: 'flex',
      gap: theme.spacing(1),
      '& .MuiSvgIcon-fontSizeSmall': {
        fontSize: 24,
        borderRadius: 12,
        backgroundColor: theme.palette.background.paper2,
        padding: 4,
      },
      '& span': {
        width: 24,
        height: 24,
        textAlign: 'center',
        lineHeight: 1,
        fontWeight: theme.typography.fontWeightBold,
        borderRadius: 12,
        backgroundColor: theme.palette.background.paper2,
        padding: 4,
      },
    },
    typography: {
      [theme.breakpoints.up('md')]: {
        // lineHeight: '32px',
      },
    },
    controls: {
      display: 'grid',
      alignSelf: 'flex-start',
      alignItems: 'center',

      gridAutoFlow: 'column',
      gridTemplateColumns: 'max-content',
      gap: theme.spacing(4),
    },
    tabRow: {
      display: 'grid',
      alignItems: 'center',

      gridAutoFlow: 'column',
      gridTemplateColumns: 'auto max-content',
    },
    tabs: {
      color: theme.palette.text.primary,
      '& .MuiTabs-flexContainer': {
        display: 'inline-grid',
        gridAutoFlow: 'column',
        [theme.breakpoints.down('md')]: {
          gap: theme.spacing(4),
        },
        [theme.breakpoints.up('md')]: {
          gap: theme.spacing(8),
        },
      },
    },
    tab: {
      textTransform: 'none',
      minWidth: 'inherit',
      '&.MuiTab-root': {
        padding: 0,
      },
      '& span': {
        padding: theme.spacing(1, 0),
      },
    },
    indicator: {
      height: theme.spacing(1),
    },
    section: {
      display: 'flex',
      flexFlow: 'column',
      flexGrow: 1,
      // flex: '1 1 auto',
      // display: 'grid',
      // overflowY: 'auto',
      marginTop: theme.spacing(4),
      gap: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1),
        gap: theme.spacing(1),
      },
      [theme.breakpoints.up('md')]: {},
    },
  }),
);

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }),
)(Badge);

const RouteCoreSupportPeopleEmployeesProfile = () => {
  const classes = useStyles();
  const { pathname, navigate } = useRouter();
  const { member, branch, permissible } = useAuth();

  const [initialBranch] = useState<string | undefined>(branch?.id);

  useEffect(() => {
    if (branch && branch.id !== initialBranch) navigate(_peopleMembers.path);
  }, [branch, initialBranch, navigate]);

  const profile = useContext(MemberContext);

  const theme = useTheme();

  const currentTab = pathname.split('/').pop();

  const menuItems: TabItem[] = [
    {
      label: 'Overview',
      path: 'overview',
    },
    // {
    //   label: "Shifts",
    //   path: "shifts",
    // },
    {
      label: 'Unavil.',
      path: 'unavailability',
      permission:
        member?.id === profile.id ? undefined : Permission.MEMBER_READ,
    },
    {
      label: 'Notes',
      path: 'notes',
      admin: true,
    },
    {
      label: 'Finance',
      path: 'finance',
      admin: true,
    },
    {
      label: 'Files',
      path: 'files',
      permission: Permission.MEMBER_FILE_READ,
    },
    {
      label: 'Perms.',
      path: 'permissions',
      admin: true,
    },
    // {
    //   label: "History",
    //   path: "history",
    //   role: Role.ADMINISTRATOR,
    // },
  ];

  const handleClick =
    (link: string) => (_: React.KeyboardEvent | React.MouseEvent) =>
      link !== currentTab && navigate(link);

  const navigation: JSX.Element = false ? (
    <></>
  ) : (
    <Tabs
      value={currentTab}
      aria-label="Profile nav bar"
      className={classes.tabs}
      TabIndicatorProps={{ className: classes.indicator }}
    >
      {menuItems.map(
        (tab, index) =>
          permissible({ admin: tab.admin, permissions: tab.permission }) && (
            <Tab
              disableRipple
              key={index}
              label={tab.label}
              className={classes.tab}
              value={tab.path}
              onClick={handleClick(tab.path)}
            />
          ),
      )}
    </Tabs>
  );

  const avatar = (
    <Avatar
      content={[profile.firstName, profile.middleName, profile.lastName]}
      variant="circular"
      color={
        // If profile.color is not set or is white set avatar color as
        // the same as paper background color
        !profile.color ||
        profile.color?.toLowerCase() === '#fff' ||
        profile.color?.toLowerCase() === '#ffffff'
          ? theme.palette.type === 'light'
            ? '#fff'
            : theme.palette.background.paper
          : profile.color
      }
      textColor={
        // If profile.color is not set or is white set avatar text color
        // as the same as typography primary color
        ((!profile.color ||
          profile.color?.toLowerCase() === '#fff' ||
          profile.color?.toLowerCase() === '#ffffff') &&
          theme.palette.text.primary) ||
        undefined
      }
      className={classes.avatar}
    />
  );

  return (
    <Page
      {..._peopleMembersProfile}
      path={`/people/employees/${profile.id}`}
      redirect={`/people/employees/${profile.id}/overview`}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.top}>
          <Box className={classes.card}>
            {profile.lastActiveAt &&
            differenceInMinutes(new Date(), new Date(profile.lastActiveAt)) <=
              15 ? (
              <StyledBadge
                overlap="circle"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot"
              >
                {avatar}
              </StyledBadge>
            ) : (
              avatar
            )}
            <Box className={classes.description}>
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.typography}
              >
                {formatPersonName(
                  { ...profile, postNominals: profile.currentPostNominal },
                  {
                    middle: true,
                    capitaliseLastName: true,
                    lastNameFirst: true,
                    postNominals: true,
                  },
                )}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.typography}
              >
                {profile.archive
                  ? 'Archived Employee'
                  : [
                      'e7ec9b20-ac74-4a11-9398-d01acbee4e14',
                      'd3640ba5-f22a-4f4a-b0f4-94e447ba6c80',
                    ].includes(profile.id)
                  ? 'Developer'
                  : 'Employee'}
              </Typography>

              <div className={classes.icons}>
                {profile.bonusEligible && <span>b</span>}
                {profile.canPromptMedication && (
                  <MedicationLiquidSharpIcon fontSize="small" />
                )}
                {profile.hasCertSupportingSafeMeals && (
                  <LocalDiningIcon fontSize="small" />
                )}
                {profile.hasCertEffectiveCommunication && (
                  <RecordVoiceOverIcon fontSize="small" />
                )}
                {profile.hasCar && <DirectionsCarIcon fontSize="small" />}
                {profile.hasCertPreventingDementia && (
                  <PsychologyIcon fontSize="small" />
                )}
                {profile.hasCertUnderstandingDementia && (
                  <PsychologyAltIcon fontSize="small" />
                )}
                {profile.hasCertManualHandling && (
                  <BackHandIcon fontSize="small" />
                )}
                {profile.hasCertSeizureRecognition && <span>ep</span>}
                {profile.canHoist && (
                  <PrecisionManufacturingOutlinedIcon fontSize="small" />
                )}
              </div>
            </Box>
            <Box className={classes.controls}>
              {permissible({ permissions: Permission.MEMBER_WRITE }) && (
                <MemberViewActionMenuButton />
              )}
              <MemberViewCloseButton />
            </Box>
          </Box>
          <Box className={classes.tabRow}>
            {navigation}
            {/* <IconButton size="small">
            <MoreHorizIcon fontSize="small" />
          </IconButton> */}
          </Box>
        </Box>
        <Divider />
        <Box className={classes.section}>
          <Outlet />
        </Box>
      </Box>
    </Page>
  );
};

export default RouteCoreSupportPeopleEmployeesProfile;

import {
  Box,
  Checkbox,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useAuth } from '@timed/auth';
import {
  ClientContext,
  ClientCreateNdisPlanButton,
  ClientNdisPlanActionMenuButton,
  ClientNdisPlanItemTable,
} from '@timed/client';
import {
  Block,
  IconButton,
  roundNumber,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from '@timed/common';
import { formatCurrency } from '@timed/common/utils/formatCurrency';
import {
  OrderBy,
  UpdateClientNdisPlanInput,
  useGetClientNdisPlansQuery,
} from '@timed/gql';
import { differenceInDays, format, isAfter } from 'date-fns';
import { useContext, useReducer } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      gap: theme.spacing(4),
      flex: '1 1 auto',
      display: 'flex',
      overflowY: 'hidden',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    input: {
      '&.MuiFormControl-root': {
        display: 'flex',
      },
    },
    header: {
      backgroundColor: '#3C5291',
      color: 'white',
    },
  }),
);

type FormData = {
  plans: UpdateClientNdisPlanInput['patch'][];
};

const NdisPlanTable = () => {
  const classes = useStyles();
  const client = useContext(ClientContext);
  const { permissible } = useAuth();

  const selectPlansReducer = (
    existingIds: string[],
    action: { type: 'add' | 'remove'; id: string },
  ): string[] => {
    switch (action.type) {
      case 'add':
        return [...new Set([...existingIds, action.id])];
      case 'remove':
        return existingIds.filter((id) => id !== action.id);
    }
  };

  const [rollOveredPlans, setRollOveredPlans] = useReducer(
    selectPlansReducer,
    [],
  );

  const handleChangeRollover = (
    { target: { checked } }: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => {
    setRollOveredPlans({ id, type: checked ? 'add' : 'remove' });
  };

  const { control } = useForm<FormData>();

  const { fields, append } = useFieldArray({
    control,
    name: 'plans',
  });

  type RowOpenReducer = (
    state: boolean[],
    dispatch: {
      type: 'set-length' | 'update';
      index: number;
      newValue?: boolean;
    },
  ) => boolean[];

  const [open, setOpen] = useReducer<RowOpenReducer>(
    (existing, { type, index, newValue }) => {
      switch (type) {
        case 'set-length':
          const indexes = [...new Array(index)].map(() => false);
          if (indexes.length) indexes[0] = true; // Pre-open the first row
          return indexes;
        case 'update':
          existing[index] = newValue!;
          return [...existing];
      }
    },
    [],
  );

  const { data, loading } = useGetClientNdisPlansQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'standby',
    variables: {
      input: {
        where: {
          client: {
            id: {
              _eq: client.id,
            },
          },
        },
        orderBy: [
          { startAt: OrderBy.DESC_NULLS_LAST },
          { endAt: OrderBy.DESC_NULLS_LAST },
        ],
      },
      itemsInput: {
        orderBy: [
          { category: OrderBy.ASC },
          { name: { name: OrderBy.ASC } },
          { createdAt: OrderBy.ASC },
        ],
      },
    },
    onCompleted: (data) => {
      data.clientNdisPlans.forEach(({ startAt, endAt }) =>
        append({ startAt, endAt }),
      );

      setOpen({
        type: 'set-length',
        newValue: false,
        index: data.clientNdisPlans.length,
      });
    },
  });

  if (!permissible({ tester: true })) {
    return <Typography>WIP</Typography>;
  }

  return (
    <Block title="Budgets" topRight={<ClientCreateNdisPlanButton />}>
      <Box className={classes.wrapper}>
        {loading ? (
          <Typography>Loading...</Typography>
        ) : !data ? (
          <Typography>
            No NDIS plans have been added to this participant.
          </Typography>
        ) : (
          <Table
            inline
            enableRowHighlighting
            border="grid"
            borderSize="thick"
            borderColor="black"
          >
            <TableHeader
              style={{
                backgroundColor: '#3C5291',
                color: 'white',
              }}
            >
              Start Date
            </TableHeader>
            <TableHeader
              style={{
                backgroundColor: '#3C5291',
                color: 'white',
              }}
            >
              End Date
            </TableHeader>
            <TableHeader
              style={{
                backgroundColor: '#3C5291',
                color: 'white',
              }}
            >
              Plan Elapsed Time
            </TableHeader>
            <TableHeader
              style={{
                backgroundColor: '#3C5291',
                color: 'white',
              }}
            >
              Initial Funds
            </TableHeader>
            <TableHeader
              align="center"
              style={{
                width: 0,
                backgroundColor: '#3C5291',
                color: 'white',
              }}
            ></TableHeader>
            <TableHeader
              align="center"
              style={{
                width: 0,
                backgroundColor: '#3C5291',
                color: 'white',
              }}
            ></TableHeader>
            <TableHeader
              align="center"
              style={{
                backgroundColor: '#3C5291',
                color: 'white',
              }}
              hidden={!permissible({ tester: true })}
            >
              Rollover
            </TableHeader>
            {data.clientNdisPlans.map(
              (plan, i) =>
                fields[i] && (
                  <>
                    <TableRow key={i}>
                      <TableCell>
                        <Typography>
                          {format(new Date(plan.startAt), 'dd / MM / yyyy')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {plan.endAt && (
                          <Typography>
                            {format(new Date(plan.endAt), 'dd / MM / yyyy')}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {plan.startAt &&
                          plan.endAt &&
                          isAfter(new Date(plan.endAt), new Date(plan.startAt))
                            ? roundNumber(
                                Math.min(
                                  (differenceInDays(
                                    new Date(plan.startAt),
                                    new Date(),
                                  ) /
                                    differenceInDays(
                                      new Date(plan.startAt),
                                      new Date(plan.endAt),
                                    )) *
                                    100,
                                  100,
                                ),
                                2,
                              ) + '%'
                            : '-'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {formatCurrency(plan.initialFunds / 100)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            setOpen({
                              index: i,
                              newValue: !open[i],
                              type: 'update',
                            })
                          }
                        >
                          {open[i] ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <ClientNdisPlanActionMenuButton clientNdisPlan={plan} />
                      </TableCell>
                      <TableCell>
                        {i < data.clientNdisPlans.length - 1 && (
                          <Checkbox
                            onChange={(event) => {
                              handleChangeRollover(event, plan.id);
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow shrunk={!open[i]}>
                      <TableCell colSpan={7}>
                        {!plan.items?.length ? (
                          <Typography
                            style={{ display: !open[i] ? 'none' : undefined }}
                          >
                            No funding items exist in this plan.
                          </Typography>
                        ) : (
                          <ClientNdisPlanItemTable
                            open={open[i]}
                            clientNdisPlan={plan}
                            prevClientNdisPlan={
                              rollOveredPlans.includes(plan.id) &&
                              i + 1 < data.clientNdisPlans.length
                                ? data.clientNdisPlans[i + 1]
                                : undefined
                            }
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                ),
            )}
          </Table>
        )}
      </Box>
    </Block>
  );
};

export default NdisPlanTable;

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Copyright, Logo } from '@timed/common';
import { UserLoginForm } from '@timed/user';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    paper: {
      width: '100%',
      maxWidth: 405,
      marginTop: 'auto',
      marginBottom: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: theme.spacing(4),
      padding: theme.spacing(4),
    },
    copyright: {
      marginBottom: theme.spacing(4),
    },
  }),
);

const RouteSignIn = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.paper}>
        <Logo height="60px" />
        <Typography>v{process.env.REACT_APP_VERSION}</Typography>
        <Typography component="h1" variant="h1">
          Sign In
        </Typography>
        <UserLoginForm />
      </Box>
      <Copyright className={classes.copyright} />
    </Box>
  );
};

export default RouteSignIn;

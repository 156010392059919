import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  Block,
  Button,
  Chip,
  formatPersonName,
  Modal,
  ModalProps,
} from '@timed/common';
import { Branch, Client, Member } from '@timed/gql';
import { format } from 'date-fns';
import { ReactElement, useRef } from 'react';
import ReactToPrint from 'react-to-print';

export type ReportProfitAndLossStatementExportModalProps = Omit<
  ModalProps,
  'children'
> & {
  onClose: () => void;
  report: ReactElement;
  stats: ReactElement;
  clients?: Pick<Client, 'id' | 'firstName' | 'lastName'>[];
  members?: Pick<Member, 'id' | 'firstName' | 'lastName'>[];
  from: Date;
  to: Date;
  branch?: Pick<Branch, 'id' | 'name'>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    peopleWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    people: {
      width: 680,
      fontSize: 10,
      fontWeight: theme.typography.fontWeightBold,
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(1),
    },
    print: {
      colorAdjust: 'exact',
      WebkitPrintColorAdjust: 'exact',
      display: 'flex',
      alignItems: 'center',
      flexFlow: 'column',
      gap: theme.spacing(4),
    },
  }),
);

const ReportProfitAndLossStatementExportModal = ({
  onClose,
  report,
  stats,
  clients,
  members,
  from,
  to,
  branch,
  ...props
}: ReportProfitAndLossStatementExportModalProps) => {
  const classes = useStyles();

  const printRef = useRef<HTMLDivElement | null>(null);

  return (
    <Modal title="Print Profit & Loss Statement" onClose={onClose} {...props}>
      <Block
        title="Print Preview"
        bottomLeft={<Button onClick={onClose}>Cancel</Button>}
        bottomRight={
          <ReactToPrint
            documentTitle={`${format(
              new Date(),
              'yyyyMMdd',
            )}_Profit_And_Loss_Statement${
              branch ? '_' + branch.name : ''
            }_${format(from, 'yyyyMMdd')}-${format(to, 'yyyyMMdd')}`}
            trigger={() => (
              <Button
                variant="contained"
                color="primary"
                style={{ width: 'max-content' }}
              >
                Print Statement
              </Button>
            )}
            content={() => printRef.current}
          />
        }
        paperProps={{ style: { height: 800 } }}
      >
        <style type="text/css" media="print">
          {' @page { margin: 64px !important } '}
        </style>
        <div ref={printRef} className={classes.print}>
          <Typography className={classes.bold} variant="h1">
            Profit & Loss Statement
          </Typography>
          <Typography className={classes.bold} variant="h3">
            {format(from, 'dd/MM/yyyy')} - {format(to, 'dd/MM/yyyy')}
          </Typography>
          {branch && (
            <Typography className={classes.bold} variant="h3">
              {branch.name}
            </Typography>
          )}
          <Typography variant="body2" color="textSecondary">
            Generated on {format(new Date(), 'dd/MM/yyyy')}
          </Typography>
          {report}
          {stats}
          {!!members && (
            <Box className={classes.peopleWrapper}>
              <Typography variant="h3" className={classes.bold}>
                Support Workers
              </Typography>
              <Typography className={classes.people}>
                {[
                  ...new Set(
                    members.map((member) =>
                      formatPersonName(member, {
                        lastNameFirst: true,
                        capitaliseLastName: true,
                      }),
                    ),
                  ),
                ].map((member) => (
                  <Chip label={member} style={{ fontSize: 11 }} size="small" />
                ))}
              </Typography>
            </Box>
          )}
          {!!clients && (
            <Box className={classes.peopleWrapper}>
              <Typography variant="h3" className={classes.bold}>
                Participants
              </Typography>
              <Typography className={classes.people}>
                {clients
                  .map((client) =>
                    formatPersonName(client, {
                      lastNameFirst: true,
                      capitaliseLastName: true,
                    }),
                  )
                  .map((client) => (
                    <Chip
                      label={client}
                      style={{ fontSize: 11 }}
                      size="small"
                    />
                  ))}
              </Typography>
            </Box>
          )}
        </div>
      </Block>
    </Modal>
  );
};

export default ReportProfitAndLossStatementExportModal;

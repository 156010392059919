import {
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Protected } from '@timed/auth';
import {
  ClientImportButton,
  ClientListControlClearButton,
  ClientListControlReloadButton,
  ClientListControlSearchInput,
  ClientListControlStateInput,
  ClientListCreateClientButton,
} from '@timed/client';
import { Permission } from '@timed/gql';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
        gap: theme.spacing(1),
      },
    },
    group: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
    },
    controls: {
      [theme.breakpoints.down('sm')]: {
        flexGrow: 1,
      },
    },
  }),
);

const ClientListControls = () => {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.wrapper}>
      <div className={clsx(classes.group, classes.controls)}>
        <ClientListControlReloadButton />
        {!smDown && <ClientListControlClearButton />}
        <ClientListControlSearchInput />
        <Protected permissions={Permission.CLIENT_READ}>
          <ClientListControlStateInput />
        </Protected>
      </div>
      <Protected permissions={Permission.CLIENT_WRITE}>
        <div className={classes.group}>
          <ClientListCreateClientButton />
          <ClientImportButton />
        </div>
      </Protected>
    </div>
  );
};

export default ClientListControls;

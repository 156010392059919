import { useTheme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { CSSProperties } from '@material-ui/styles';
import { useAuth } from '@timed/auth';
import {
  contrastingColor,
  formatPersonName,
  hexToRGB,
  useRouter,
} from '@timed/common';
import { TimezoneLabels } from '@timed/common/types/TimezoneLabels';
import {
  EntityState,
  Event,
  EventsWhereInput,
  GetEventsOwnAndRelatedQuery,
  GetEventsQuery,
  Member,
  OrderBy,
  Permission,
  Timezone,
  useGetConflictsLazyQuery,
  useGetEventsLazyQuery,
  useGetEventsOwnAndRelatedLazyQuery,
  useGetPublicHolidaysLazyQuery,
  useGetScheduleOrgSettingsLazyQuery,
  useScheduleUnallocatedEventsLazyQuery,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import {
  MouseState,
  ScheduleContext,
  ScheduleContextType,
  SelectedEvent,
  SetCellFn,
  SetEventFn,
  Shift,
  StartDateType,
  getProfile,
  setProfile,
} from '@timed/schedule';
import {
  addHours,
  addMinutes,
  addWeeks,
  areIntervalsOverlapping,
  differenceInDays,
  differenceInMilliseconds,
  differenceInMinutes,
  format,
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isEqual,
  parse,
  startOfDay,
  startOfToday,
  startOfWeek,
  subDays,
  subMilliseconds,
  subWeeks,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { addDays, eachDayOfInterval, startOfMinute } from 'date-fns/esm';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

/*

MOUSE ACTIONS
-------------

Drag over cells to create event:
  - onMouseDown: set datetime1 from 'hovered cell'
  - onMouseUp: set datetime2 from 'hovered cell'
  Needed: 2 states: cell1 and cell2

Drag events to change start time
  - onMouseDown
  - Get the distance between the click point and the top of the
    shift (not event). This distance is used to determine accurate
    'from' date.
  Needed: 2 states: top offset distance and the eventId

Drag event top and bottom borders to change from and to times:
 - Create two invisible elements, one at the top and one at the
   bottom of the shift.
 - If the top element is onMouseDown and onMouseLeave, the 'from'
   time is altered
 - If the bottom element is onMouseDown and onMouseLeave, the 'to'
   time is altered.
   Needed: 

Double click event to edit
  Needed: 1 state: eventId


*/

type MemberColors = {
  id: Member['id'];
  color: string;
};

// // Not yet in use
// type Query = {
//   /**
//    * 'From' date
//    */
//   f: string;
//   /**
//    * Date range
//    */
//   r: string;
//   /**
//    * Employee id
//    */
//   e: string;
//   /**
//    * Participant id
//    */
//   p: string;
// };

type ScheduleProviderProps = React.PropsWithChildren<{}>;

const ScheduleProvider = ({ children }: ScheduleProviderProps) => {
  const {
    search: [searchParams, setSearchParams],
  } = useRouter();

  const { permissible, member, branch } = useAuth();

  const theme = useTheme();

  const scrollableAreaRef = useRef<HTMLDivElement>(null);

  const [getEvents, eventsResponse] = useGetEventsLazyQuery({
    // pollInterval: 30000,
    // fetchPolicy: 'network-only',
  });

  const [getUnallocatedEvents, unallocatedEventsResponse] =
    useScheduleUnallocatedEventsLazyQuery({
      pollInterval: 30000,
      fetchPolicy: 'network-only',
    });

  const [getEventsOwnAndRelated, eventsOwnAndRelatedResponse] =
    useGetEventsOwnAndRelatedLazyQuery({
      // pollInterval: 30000,
      // fetchPolicy: 'network-only',
    });

  const [getPublicHolidays, publicHolidaysResponse] =
    useGetPublicHolidaysLazyQuery();

  // const [getMemberTimezone, memberTimezoneResponse] = useGetMemberTimezoneLazyQuery();
  // const [getRedactedClientTimezone, redactedClientTimezoneResponse] =
  //   useGetRedactedClientTimezoneLazyQuery();

  const [getEventConflicts, eventConflictsResponse] =
    useGetConflictsLazyQuery();

  const [getUnavailableConflicts, unavailableConflictsResponse] =
    useGetConflictsLazyQuery();

  const [getOrgSettings, orgSettings] = useGetScheduleOrgSettingsLazyQuery();

  const isEvent = (
    e:
      | GetEventsQuery['events'][0]
      | GetEventsOwnAndRelatedQuery['eventsOwnAndRelated'][0],
  ): e is Event => e.hasOwnProperty('delete');

  useLoadingEffect(eventsResponse.loading);
  useLoadingEffect(eventsOwnAndRelatedResponse.loading);

  const [eventStates, setEventStates] = useState<EntityState[]>([
    EntityState.NORMAL,
    EntityState.ARCHIVED,
    EntityState.CANCELLED,
  ]);

  const [eventAttributes, setEventAttributes] = useState<EventsWhereInput>();

  const [mouse, setMouseState] = useState<MouseState>('up');

  const [cellDown, setCellDownState] = useState<number>();

  const [cellOver, setCellOverState] = useState<number>();

  const [eventOver, setEventOverState] = useState<Event['id']>();

  const [, setEventSelectedState] =
    useState<ScheduleContextType['target']['event']['selected']>();

  /**
   * The current datetime
   */
  const [now, setNow] = useState<Date>(new Date());

  /**
   * Timer to refresh 'now' indicator on calendar
   */
  const timer = useRef<number>();

  /**
   * Default quantity of days between 'from' and 'to' dates
   */
  const defaultDateRange = 28;

  // const employee = searchParams.get("e") || undefined;
  // const participant = searchParams.get("p") || undefined;

  /**
   * Start of the current day.
   * Memoisation is requred to prevent infinite loops
   */
  const today = useMemo(() => startOfDay(now), [now]);

  /**
   * Quantity of days between and 'from' and 'to' dates
   */
  const range =
    (searchParams.get('r') && parseInt(searchParams.get('r')!)) ||
    (localStorage.getItem('schedule.range') &&
      parseInt(localStorage.getItem('schedule.range')!)) ||
    defaultDateRange;

  const weekStartsOn = parseInt(
    localStorage.getItem('schedule.settings.weekStartsOn') ?? '1',
  ) as 0 | 2 | 1 | 3 | 4 | 5 | 6 | undefined;

  const dayOffset = parseInt(
    localStorage.getItem('schedule.settings.dayOffset') ?? '7',
  );

  const startDate =
    (localStorage.getItem('schedule.settings.startDate') as StartDateType) ??
    'current-week';

  /**
   * First date to display on the calendar.
   * Memoisation is requred to prevent infinite loops.
   */
  const from = useMemo(() => {
    return (
      (!!searchParams.get('f') &&
        parse(searchParams.get('f')!, 'ddMMyyyy', new Date())) ||
      (startDate === 'current-day' && today) ||
      (startDate === 'current-week' && startOfWeek(today, { weekStartsOn })) ||
      (startDate === 'prev-week' &&
        subWeeks(startOfWeek(today, { weekStartsOn }), 1)) ||
      (startDate === 'day-offset' && dayOffset && subDays(today, dayOffset)) ||
      today
    );
  }, [searchParams, today, dayOffset, startDate, weekStartsOn]);

  /**
   * Day after the last date displayed on the calendar.
   * Memoisation is requred to prevent infinite loops.
   */
  const to = useMemo(() => addDays(from, range), [from, range]);

  /**
   * The current timezone of the schedule.
   * Undefined = local timezone.
   */
  const [clientTimezone, setClientTimezone] = useState<string>();
  const [memberTimezone, setMemberTimezone] = useState<string>();

  const timezone = useMemo<string | undefined>(() => {
    return clientTimezone ?? memberTimezone ?? undefined;
  }, [clientTimezone, memberTimezone]);

  const setTimezone = useCallback(
    (type: 'client' | 'member', value?: Timezone): void => {
      const tz = TimezoneLabels.find((t) => t.value === value)?.label;
      switch (type) {
        case 'client':
          setClientTimezone(tz);
          break;
        case 'member':
          setMemberTimezone(tz);
          break;
      }
    },
    [],
  );

  /**
   * The current timezone of the schedule.
   * Undefined = local timezone.
   */
  const [showEventStatusColours, setShowEventStatusColours] = useState<boolean>(
    localStorage.getItem('schedule.settings.showEventStatusColours') !==
      'false',
  );

  /**
   * Visibility of member-hour information.
   */
  const [showMemberHours, setShowMemberHours] = useState<boolean>(
    localStorage.getItem('schedule.settings.showMemberHours') !== 'true',
  );

  useEffect(
    () =>
      localStorage.setItem(
        'schedule.settings.showMemberHours',
        (!showMemberHours).toString(),
      ),
    [showMemberHours],
  );

  /**
   * Date at which the auto-member-assign task is running.
   */
  const autoMemberAssign = useMemo(
    () =>
      addDays(
        new Date(),
        orgSettings.data?.me.member?.org.taskAssignAttendeeFutureDays ?? 0,
      ),
    [orgSettings.data?.me.member?.org.taskAssignAttendeeFutureDays],
  );

  const refetch = useCallback(
    () =>
      !!eventsResponse.data?.events
        ? eventsResponse.refetch
        : eventsOwnAndRelatedResponse.refetch,
    [eventsResponse, eventsOwnAndRelatedResponse],
  );

  /**
   * Set first date to display on the calendar
   */
  const setFrom = useCallback(
    (value?: number | Date, redirect = true): void => {
      if (!value) {
        const startDateType = localStorage.getItem(
          'schedule.settings.startDate',
        );
        const dayOffset = localStorage.getItem('schedule.settings.dayOffset');

        if (startDateType === 'prev-week')
          value = subDays(
            startOfWeek(today, {
              weekStartsOn: parseInt(
                localStorage.getItem('schedule.settings.weekStartsOn') ?? '1',
              ) as 0 | 2 | 1 | 3 | 4 | 5 | 6 | undefined,
            }),
            7,
          );
        else if (startDateType === 'day-offset' && !!dayOffset)
          value = subDays(today, parseInt(dayOffset) ?? 7);
        else if (startDateType === 'current-day') value = today;
        else
          value = startOfWeek(today, {
            weekStartsOn: parseInt(
              localStorage.getItem('schedule.settings.weekStartsOn') ?? '1',
            ) as 0 | 2 | 1 | 3 | 4 | 5 | 6 | undefined,
          });
      }

      searchParams.set(
        'f',
        format(
          typeof value === 'number' ? addDays(from, value) : value,
          'ddMMyyyy',
        ),
      );

      redirect && setSearchParams(searchParams);
    },

    // refetch();
    [from, searchParams, setSearchParams, today],
  );

  /**
   * Set quantity of days between 'from' and 'to' dates
   */
  const setRange = useCallback(
    (value: number, redirect = true): void => {
      value && localStorage.setItem('schedule.range', value.toString());
      searchParams.set('r', value.toString());
      redirect && setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  /**
   * Wrapper around helper getProfile() functions. Get saved id of specified member or client.
   */
  const getProfileWrapper = useCallback(
    (type: 'client' | 'member'): string | undefined =>
      getProfile(type, searchParams),
    [searchParams],
  );

  const clientId = getProfileWrapper('client');

  // Set memberId to the current user's member id if they do not have permission to read other members
  const memberId = !permissible({ permissions: Permission.MEMBER_READ })
    ? member!.id
    : getProfileWrapper('member');

  /**
   * Wrapper around helper function "setProfile". Save profile id of the
   * specified profile type.
   * @param id If blank, unsets existing value
   * @param redirect If true, apply new value to search params immediately.
   */
  const setProfileWrapper = useCallback(
    ({
      type,
      id,
      timezone,
      redirect = true,
    }: {
      type: 'client' | 'member';
      id?: string;
      timezone?: Timezone;
      redirect?: boolean;
    }) => {
      setProfile(type, searchParams, id);
      switch (type) {
        case 'client':
          setClientTimezone(timezone);
          break;
        case 'member':
          setClientTimezone(timezone);
          break;
      }
      redirect && setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const setEventSelected = (
    event: ScheduleContextType['target']['event']['selected'],
  ) => {
    setEventSelectedState(event);
  };

  const setEvent = ({ type, value }: SetEventFn) => {
    switch (type) {
      case 'selected':
        setEventSelected(value as SelectedEvent);
        break;
      case 'over':
        value?.id ? setEventOverState(value.id) : setEventOverState(undefined);
        break;
    }
  };

  const setCell = (data?: SetCellFn) => {
    if (!data) {
      setCellDownState(undefined);
      setCellOverState(undefined);
    } else {
      switch (data.type) {
        case 'down':
          // setCellUpState(undefined);
          setCellDownState(data.value);
          setCellOverState(data.value);
          break;
        case 'over':
          setCellOverState(data.value);
          break;
      }
    }
  };

  const setTime = useCallback(
    (date: Date, reverse = false): Date => {
      date = new Date(date);
      return !!timezone
        ? reverse
          ? zonedTimeToUtc(date, timezone)
          : utcToZonedTime(date, timezone)
        : date;
    },
    [timezone],
  );

  /**
   * Redirect on missing query parameters
   */
  useEffect(() => {
    let redirect: boolean = false;

    if (!searchParams.get('f')) {
      setFrom(from, false);
      redirect = true;
    }

    if (!searchParams.get('r')) {
      setRange(range, false);
      redirect = true;
    }

    if (!searchParams.get('m') && getProfileWrapper('member')) {
      setProfileWrapper({ type: 'member', id: memberId, redirect: false });
      redirect = true;
    }

    if (!searchParams.get('c') && getProfileWrapper('client')) {
      setProfileWrapper({ type: 'client', id: clientId, redirect: false });
      redirect = true;
    }

    redirect && setSearchParams(searchParams);
  }, [
    from,
    range,
    getProfileWrapper,
    clientId,
    setFrom,
    setRange,
    setProfileWrapper,
    setSearchParams,
    searchParams,
    memberId,
  ]);

  /**
   * "Now" indicator refresh timer.
   */
  useEffect(() => {
    // Calc the difference in time between the page render
    // and the start of the current minute so that the timer
    // refreshes on the minutes mark.
    const offset = differenceInMilliseconds(now, startOfMinute(now));
    timer.current = window.setTimeout(() => {
      setNow(new Date());
    }, 60000 - offset);
    return () => clearTimeout(timer.current);
  }, [now, setNow]);

  /**
   * Fetch org settings.
   */
  useEffect(() => {
    if (permissible({ permissions: Permission.EVENT_READ })) {
      getOrgSettings();
    }
  }, [permissible, getOrgSettings]);

  /**
   * Fetch public holidays
   * */
  useEffect(() => {
    if (isBefore(from, to))
      getPublicHolidays({
        variables: {
          input: {
            where: {
              date: {
                _gte: format(from, 'yyyy-MM-dd'),
                _lte: format(addDays(to, 1), 'yyyy-MM-dd'),
              },
            },
            orderBy: [{ date: OrderBy.ASC, region: OrderBy.ASC }],
          },
        },
      });
  }, [getPublicHolidays, from, to]);

  /**
   * Fetch unallocated events
   */
  useEffect(() => {
    if (
      !unallocatedEventsResponse.called &&
      permissible({ permissions: Permission.EVENT_READ })
    )
      getUnallocatedEvents({
        variables: {
          input: {
            where: {
              client: branch
                ? { branch: { id: { _eq: branch.id } } }
                : undefined,
              member: { id: null },
              startAt: {
                _gte: startOfToday(),
                _lt: addWeeks(startOfToday(), 2),
              },
            },
            orderBy: [{ startAt: OrderBy.ASC }, { duration: OrderBy.ASC }],
          },
        },
      });
  }, [branch, permissible, getUnallocatedEvents, unallocatedEventsResponse]);

  /**
   * Fetch events
   */
  useEffect(() => {
    if (isBefore(from, to) && (memberId || clientId)) {
      const where = {
        endAt: { _gte: subDays(from, 1) },
        startAt: { _lte: addDays(to, 1) },
      };

      if (memberId || clientId) {
        if (
          permissible({
            permissions: [
              Permission.CLIENT_READ,
              Permission.MEMBER_READ,
              Permission.EVENT_READ,
            ],
          })
        ) {
          clientId &&
            Object.assign(where, { client: { id: { _eq: clientId } } });
          memberId &&
            Object.assign(where, { member: { id: { _eq: memberId } } });

          if (eventAttributes) Object.assign(where, { _or: eventAttributes });

          getEvents({
            variables: {
              input: {
                where,
                orderBy: [
                  { startAt: OrderBy.ASC },
                  { duration: OrderBy.ASC },
                  { createdAt: OrderBy.ASC },
                  { id: OrderBy.ASC },
                ],
                entityStates: !eventStates.length
                  ? [
                      EntityState.NORMAL,
                      EntityState.CANCELLED,
                      EntityState.DELETED,
                      EntityState.ARCHIVED,
                    ]
                  : eventStates,
              },
            },
          });
        } else {
          getEventsOwnAndRelated({
            variables: {
              input: {
                where,
                client: clientId ? { id: clientId } : undefined,
                orderBy: [
                  { startAt: OrderBy.ASC },
                  { duration: OrderBy.ASC },
                  { id: OrderBy.ASC },
                ],
              },
            },
          });
        }
      }
    }
  }, [
    getEvents,
    getEventsOwnAndRelated,
    memberId,
    clientId,
    from,
    to,
    permissible,
    eventStates,
    eventAttributes,
    branch,
  ]);

  /**
   * Fetch conflicts.
   */
  useEffect(() => {
    if (permissible({ permissions: Permission.EVENT_READ })) {
      getEventConflicts({
        variables: {
          input: {
            where: {
              member: branch
                ? { branchMembers: { branch: { id: { _eq: branch.id } } } }
                : undefined,
              endAt: { _gte: now },
              memberUnavailableConflicts: {
                memberUnavailable: { id: { _eq: null } },
              },
            },
            orderBy: [{ startAt: OrderBy.ASC }, { endAt: OrderBy.ASC }],
          },
        },
      });
      getUnavailableConflicts({
        variables: {
          input: {
            where: {
              member: branch
                ? { branchMembers: { branch: { id: { _eq: branch.id } } } }
                : undefined,
              endAt: { _gte: now },
              memberUnavailableConflicts: {
                memberUnavailable: { id: { _ne: null } },
              },
            },
            orderBy: [{ startAt: OrderBy.ASC }, { endAt: OrderBy.ASC }],
          },
        },
      });
    }
  }, [now, getEventConflicts, getUnavailableConflicts, permissible, branch]);

  /**
   * Convert events into shifts
   */
  const shifts: Array<Array<Shift>> | undefined = useMemo(() => {
    const memberColors: MemberColors[] = [];
    const usedColors: string[] = [];

    const eventColors = [
      '#ffcdd2', // Red
      '#bbdefb', // Blue
      '#fff9c4', // Yellow
      '#e1bee7', // Purple
      '#c8e6c9', // Green
      '#ffe0b2', // Orange
      '#f8bbd0', // Pink
      '#d1c4e9', // Deep Purple
      '#ffccbc', // Deep Orange
      '#b3e5fc', // Light Blue
      '#dcedc8', // Light Green
      '#ffecb3', // Amber
      '#b2ebf2', // Cyan
      '#f0f4c3', // Lime
      '#b2dfdb', // Teal
      '#c5cae9', // Indigo
    ];

    const allocateColor = (memberId: string): string => {
      if (memberColors.some(({ id }) => id === memberId)) {
        // Employee has already been allocated a colour
        // const index = employeeColors.indexOf({ id: employeeId })
        return memberColors.find((memberColor) => memberColor.id === memberId)
          ?.color!;
      } else {
        const color = eventColors[usedColors.length];
        memberColors.push({ id: memberId, color });
        usedColors.push(color);
        return color;
      }
    };

    if (
      !(eventsResponse.data || !eventsOwnAndRelatedResponse.data) &&
      !(memberId || !clientId)
    ) {
      return undefined;
    }

    const shifts: Array<Array<Shift>> = [];

    for (let i = 0; i < range; i++) {
      shifts[i] = [];
    }

    // Step 1: Calculate base values for each event block
    (
      eventsResponse.data?.events ||
      eventsOwnAndRelatedResponse.data?.eventsOwnAndRelated
    )?.forEach((event) => {
      const startAt = setTime(event.startAt);
      const endAt = setTime(event.endAt);
      const eachDate = eachDayOfInterval({
        start: startAt,
        end: subMilliseconds(endAt, 1),
      }).map((date) => format(date, 'yyyy-MM-dd'));
      const passiveStartAt =
        event.passive && event.passiveStartAt
          ? setTime(event.passiveStartAt)
          : null;
      const passiveEndAt =
        event.passive && passiveStartAt ? addHours(passiveStartAt, 8) : null;
      const eventDateStart = startOfDay(startAt);
      const eventDateEnd = startOfDay(endAt);

      // If event ends at midnight and spans multiple days, subtrack 1 day
      const daySpan =
        differenceInDays(eventDateEnd, eventDateStart) -
        (differenceInDays(eventDateEnd, eventDateStart) > 0 &&
        getHours(endAt) === 0 &&
        getMinutes(endAt) === 0
          ? 1
          : 0);

      let color = event.cancel
        ? theme.palette.common.black
        : clientId
        ? event.member
          ? event.member.color
            ? event.member.color
            : allocateColor(event.member.id)
          : theme.palette.background.paper
        : event.client.color
        ? event.client.color
        : theme.palette.background.paper;

      let style: CSSProperties = event.cancel
        ? {
            backgroundColor: theme.palette.background.paper,
            // Thin diagonal lines
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23bababa' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E")`,
          }
        : event.verified
        ? {
            backgroundColor: color,
            // Ticks
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='${contrastingColor(
              hexToRGB(color),
            )?.replace(
              '#',
              '%23',
            )}59' fill-opacity='1'%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
          }
        : event.hasIssue
        ? {
            backgroundColor: color,
            // Crosses
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23000' fill-opacity='1'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
          }
        : isEvent(event) && !event.payable
        ? {
            backgroundColor: color,
            // Thin vertical lines
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='5' height='1' viewBox='0 0 40 1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h20v1H0z' fill='%23000' fill-opacity='1' fill-rule='evenodd'/%3E%3C/svg%3E")`,
          }
        : event.member
        ? showEventStatusColours &&
          (permissible({ permissions: Permission.EVENT_READ }) ||
            event.member.id === member!.id) &&
          isAfter(new Date(event.startAt), new Date('2022-08-29')) &&
          ((!event.clockedOnAt &&
            isAfter(now, addMinutes(new Date(event.startAt), 5)) &&
            isBefore(now, new Date(event.endAt))) ||
            (!event.clockedOffAt && isAfter(now, new Date(event.endAt))))
          ? {
              // color: 'black',
              backgroundColor: '#FF69B4',
              backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23b8276f' fill-opacity='1'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
            }
          : event.member.color
          ? { backgroundColor: color }
          : { backgroundColor: color }
        : {
            backgroundColor: theme.palette.background.paper,

            // Thick disagonal lines
            // backgroundImage: `url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ccc' fill-opacity='0.6' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E")`,

            // Crosses
            // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ccc' fill-opacity='1'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,

            // Hexigon
            // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23fcdc00' fill-opacity='1' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,

            // Polka dots
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.4' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E")`,
          };

      Object.assign(style, { color: contrastingColor(hexToRGB(color)) });

      if (color.toLowerCase() === '#ffffff' || color.toLowerCase() === '#fff') {
        Object.assign(style, { border: '1px solid ' + grey[700] });
      }

      let i = 0;
      while (i <= daySpan) {
        // Start of day of block
        const blockDateStart = addDays(eventDateStart, i);
        // End of day of block
        const blockDateEnd = addDays(blockDateStart, 1);
        // Start datetime of block
        const blockStartAt = i === 0 ? startAt : blockDateStart;
        // End datetime of block
        const blockEndAt = i < daySpan && daySpan > 0 ? blockDateEnd : endAt;
        if (blockStartAt < addDays(from, range) && blockStartAt >= from) {
          shifts[differenceInDays(blockStartAt, from)].push({
            event: {
              id: event.id,
              createdAt: new Date(event.createdAt),
              startAt,
              endAt,
              duration: differenceInMinutes(endAt, startAt),
              clockedOn: isEvent(event) ? !!event.clockedOnAt : false,
              color,
              hasNotes: event.hasNotes,
              hasFiles: false,
              hasSeizureObservation: event.hasSeizureObservation,
              locked: (isEvent(event) && event.locked) ?? false,
              verified: (isEvent(event) && event.verified) ?? false,
              bonusPay: isEvent(event) ? event.bonusPay : 0,
              // hasFiles: event.hasFiles,
              eventConflictExists:
                !event.cancel &&
                !!eventConflictsResponse.data &&
                eventConflictsResponse.data.conflicts.some(
                  (c) =>
                    c.member.id === event.member?.id &&
                    areIntervalsOverlapping(
                      {
                        start: new Date(event.startAt),
                        end: new Date(event.endAt),
                      },
                      { start: new Date(c.startAt), end: new Date(c.endAt) },
                    ),
                ),
              unavailableConflictExists:
                !event.cancel &&
                !!unavailableConflictsResponse.data &&
                unavailableConflictsResponse.data.conflicts.some(
                  ({ eventConflicts }) =>
                    eventConflicts.some(
                      ({ conflict, event: { id } }) =>
                        event.id === id &&
                        !!unavailableConflictsResponse.data?.conflicts.find(
                          ({ unavailableConflicts }) =>
                            !!unavailableConflicts.find(
                              ({ conflict: { id } }) => conflict.id === id,
                            ),
                        ),
                    ),
                ),
              publicHoliday:
                event.publicHoliday ||
                publicHolidaysResponse.data?.publicHolidays.some(
                  ({ date, region }) =>
                    (!region || region === event.region) &&
                    eachDate.includes(date),
                ),
              memberAssignedAutomatically: event.memberAssignedAutomatically,
              passive: event.passive,
              passiveStartAt: passiveStartAt
                ? isBefore(passiveStartAt, blockStartAt)
                  ? blockStartAt
                  : passiveStartAt
                : undefined,
              passiveEndAt: passiveEndAt
                ? isBefore(blockEndAt, passiveEndAt)
                  ? blockEndAt
                  : passiveEndAt
                : undefined,
              activeAssist: event.activeAssist,
              cancelled: !!event.cancel,
              style,
              title: clientId
                ? event.member
                  ? formatPersonName(event.member, { preferred: true })!
                  : '(TBA)'
                : formatPersonName(event.client, { preferred: true })!,
              client: event.client,
              member: event.member,
              travelTime: event.travelTime ?? 0,
            },
            startAt: blockStartAt,
            endAt: blockEndAt,
            duration: differenceInMinutes(blockEndAt, blockStartAt),
            width: 0,
            height:
              (differenceInMinutes(
                new Date(
                  Date.UTC(
                    getYear(blockEndAt),
                    getMonth(blockEndAt),
                    getDate(blockEndAt),
                    getHours(blockEndAt),
                    getMinutes(blockEndAt),
                  ),
                ),

                new Date(
                  Date.UTC(
                    getYear(blockStartAt),
                    getMonth(blockStartAt),
                    getDate(blockStartAt),
                    getHours(blockStartAt),
                    getMinutes(blockStartAt),
                  ),
                ),
              ) /
                1440) *
              100,
            leftOffset: 0,
            topOffset:
              (differenceInMinutes(
                new Date(
                  Date.UTC(
                    getYear(blockStartAt),
                    getMonth(blockStartAt),
                    getDate(blockStartAt),
                    getHours(blockStartAt),
                    getMinutes(blockStartAt),
                  ),
                ),

                new Date(
                  Date.UTC(
                    getYear(blockDateStart),
                    getMonth(blockDateStart),
                    getDate(blockDateStart),
                    getHours(blockDateStart),
                    getMinutes(blockDateStart),
                  ),
                ),
              ) /
                1440) *
              100,
            futureOverlaps: 0,
            pastOverlaps: 0,
            startOfOverlap: false,
            endOfOverlap: false,
            startOverlapsIndex: 0,
          });
        }
        i++;
      }
    });

    // Sort shifts in chronological order
    for (let i = 0; i < shifts.length; i++) {
      shifts[i].sort((previous, current) => {
        if (isBefore(previous.startAt, current.startAt)) {
          return -1;
        }
        if (isEqual(previous.startAt, current.startAt)) {
          // Both start at same time, now sort based on duration
          if (previous.duration > current.duration) {
            // Longest shift first
            return -1;
          }
          if (previous.duration === current.duration) {
            // Both are the same duration, now sort based on ID
            if (previous.event.id > current.event.id) {
              return -1;
            }
          }
        }
        return 1;
      });
    }

    // Calculate future and past overlaps
    for (let i = 0; i < shifts.length; i++) {
      // Loop through each day
      for (let j = 0; j < shifts[i].length; j++) {
        // Loop through each shift for this day
        shifts[i][j].pastOverlaps = shifts[i].filter((shift) => {
          /**
           * To calculate a past shifts, both conditions must be true:
           * - End after current shift starts
           * - (Start before current shift
           *   OR ((start at same time as current shift
           *          AND is longer than current shift)
           *      OR (start at same time as current shift
           *         AND is same length
           *        AND is id is before current shift's id))
           */
          return (
            (isBefore(shift.startAt, shifts[i][j].startAt) ||
              (isEqual(shift.startAt, shifts[i][j].startAt) &&
                (shift.duration > shifts[i][j].duration ||
                  (shift.duration === shifts[i][j].duration &&
                    shift.event.id > shifts[i][j].event.id)))) &&
            isBefore(shifts[i][j].startAt, shift.endAt)
          );
        }).length;

        shifts[i][j].futureOverlaps = shifts[i].filter((shift) => {
          return (
            (isBefore(shifts[i][j].startAt, shift.startAt) ||
              (isEqual(shifts[i][j].startAt, shift.startAt) &&
                (shift.duration < shifts[i][j].duration ||
                  (shift.duration === shifts[i][j].duration &&
                    shift.event.id < shifts[i][j].event.id)))) &&
            isBefore(shift.startAt, shifts[i][j].endAt)
          );
        }).length;
      }
    }

    // Calculate overlap groups
    for (let i = 0; i < shifts.length; i++) {
      // Only loop through days that have shifts
      if (shifts[i].length > 0) {
        // For first shift of the day set startOfOverlap = true
        shifts[i][0].startOfOverlap = true;
        // Loop through each shift for this day
        for (let j = 0; j < shifts[i].length; j++) {
          if (shifts[i][j].startOfOverlap) {
            shifts[i][j + shifts[i][j].futureOverlaps].endOfOverlap = true;
            // Check if there is a shift after previous endOfOverlap
            if (shifts[i][j + shifts[i][j].futureOverlaps + 1]) {
              // If there is, set it as startOfOverlap = true
              shifts[i][j + shifts[i][j].futureOverlaps + 1].startOfOverlap =
                true;
            }
          }
        }
      }
    }

    // Calculate widths
    for (let i = 0; i < shifts.length; i++) {
      // Only loop through days that have shifts
      if (shifts[i].length > 0) {
        // Loop through each shift for this day
        for (let j = 0; j < shifts[i].length; j++) {
          if (shifts[i][j].startOfOverlap) {
            // First shift in overlap group
            if (shifts[i][j].pastOverlaps > 0) {
              // Shift has pastOverlaps

              // Loop past overlaps and find the shift with the earliest endAt
              // 1. Create new array of past overlays
              const pastOverlays: Shift[] = shifts[i].filter(
                (shift) =>
                  (isBefore(shift.startAt, shifts[i][j].startAt) ||
                    (isEqual(shift.startAt, shifts[i][j].startAt) &&
                      (shift.duration > shifts[i][j].duration ||
                        (shift.duration === shifts[i][j].duration &&
                          isBefore(
                            shift.event.createdAt,
                            shifts[i][j].event.createdAt,
                          ))))) &&
                  isBefore(shifts[i][j].startAt, shift.endAt),
              );
              const earliestEndAt = pastOverlays.sort((previous, current) =>
                previous.endAt > current.endAt ? -1 : 1,
              )[0];

              shifts[i][j].width = Math.min(
                // Immediate previous shift's width times current shift's past overlaps
                // 100 - shifts[i][j - 1].width * shifts[i][j].pastOverlaps,
                earliestEndAt.leftOffset,

                shifts[i][j].futureOverlaps
                  ? // This might need to be checking against the latest-ending shift of the previous
                    // overlap group instead of the immediate previous one.
                    isBefore(
                      shifts[i][j - 1].endAt,
                      shifts[i][j + 1].startAt,
                    ) ||
                    isEqual(shifts[i][j - 1].endAt, shifts[i][j + 1].startAt)
                    ? 100 / (shifts[i][j].futureOverlaps + 1)
                    : 100 /
                      (shifts[i][j].pastOverlaps +
                        shifts[i][j].futureOverlaps +
                        1)
                  : 100,
              );
            } else {
              // Shift does not have pastOverlaps

              // shifts[i][j].width = 100 / (futureOverlapsThatOverlapCount + 1);
              shifts[i][j].width = 100 / (shifts[i][j].futureOverlaps + 1);
            }
          } else {
            // Width is always the same as any shift in same overlap group
            shifts[i][j].width = shifts[i][j - 1].width;
          }

          // Calculate leftOffsets
          if (!shifts[i][j].startOfOverlap) {
            shifts[i][j].leftOffset =
              shifts[i][j - 1].width + shifts[i][j - 1].leftOffset;
          }
        }
      }
    }
    return shifts;
  }, [
    eventsOwnAndRelatedResponse.data,
    publicHolidaysResponse.data,
    eventConflictsResponse.data,
    unavailableConflictsResponse.data,
    eventsResponse.data,
    showEventStatusColours,
    permissible,
    memberId,
    clientId,
    setTime,
    member,
    theme,
    range,
    from,
    now,
  ]);

  return (
    <ScheduleContext.Provider
      value={{
        refetch,
        setFrom,
        setRange,
        setTime,
        setTimezone,
        setCell,
        setEvent,
        setMouse: setMouseState,
        showEventStatusColours,
        setShowEventStatusColours,
        showMemberHours,
        setShowMemberHours,
        client: {
          get: () => getProfileWrapper('client'),
          set: (id: string, redirect: boolean) =>
            setProfileWrapper({ type: 'client', id, redirect }),
        },
        member: {
          get: () =>
            !permissible({ permissions: Permission.MEMBER_READ })
              ? member!.id
              : getProfileWrapper('member'),
          set: (id: string, redirect: boolean) =>
            setProfileWrapper({ type: 'member', id, redirect }),
        },
        eventStates,
        setEventStates,
        eventAttributes,
        setEventAttributes,
        dates: {
          today,
          now,
          from,
          to,
          range,
          autoMemberAssign,
          currentTimezone: timezone,
          clientTimezone: clientTimezone,
          memberTimezone: memberTimezone,
        },
        mouse,
        target: {
          // employee,
          // participant,
          cells: { down: cellDown, over: cellOver },
          event: {
            over: eventOver,
          },
        },
        lists: {
          // employees: employees.data?.getManyEmployees,
          // participants: participants.data?.getManyParticipants,
          shifts,
          eventConflicts: eventConflictsResponse.data?.conflicts,
          unavailableConflicts: unavailableConflictsResponse.data?.conflicts,
          publicHolidays: publicHolidaysResponse.data?.publicHolidays,
          unallocatedEvents: unallocatedEventsResponse.data?.events,
        },
        settings: orgSettings.data?.me.member?.org ?? {},
        scrollableAreaRef,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};

export default ScheduleProvider;

export enum ReportModule {
  EVENT_TRAVEL = 'SHIFT_TRAVEL',
  EVENT_CHECKER = 'SHIFT_CHECKER',
  ACTIVITY_SLIPS = 'PAYROLL_(ACTIVITY_SLIPS)',
  EMPLOYEE_CLOCKED_SHIFTS = 'EMPLOYEE_CLOCKED_SHIFTS',
  EMPLOYEE_HOURS = 'EMPLOYEE_HOURS',
  EMPLOYEE_HOURS_2 = 'EMPLOYEE_HOURS_2',
  EMPLOYEE_WORKED_HOURS = 'EMPLOYEE_WORKED_HOURS',
  EMPLOYEE_PAY_SLIP = 'EMPLOYEE_PAY_SLIP',
  PROFIT_AND_LOSS_STATEMENT = 'PROFIT_AND_LOSS_STATEMENT',
  PARTICIPANT_QUOTE_GENERATOR = 'PARTICIPANT_QUOTE_GENERATOR',
  WORKED_WITH = 'WORKED_WITH',
  PARTICIPANT_SEIZURES = 'PARTICIPANT_SEIZURES',
  DOCUMENT_TRACKER = 'DOCUMENT_TRACKER',
}

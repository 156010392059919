import { PaginationNavigation, PaginationStatus } from '@timed/common';
import { MemberListContext } from '@timed/member';
import { useContext } from 'react';

const MemberListPagination = () => {
  const { loading, totalCount, fetch, offset, limit } =
    useContext(MemberListContext);

  return (
    <>
      <PaginationNavigation
        action={(offset) => fetch({ offset })}
        limit={limit}
        offset={offset}
        totalCount={totalCount}
        size="large"
      />
      <PaginationStatus
        loading={loading}
        limit={limit}
        offset={offset}
        totalCount={totalCount}
      />
    </>
  );
};

export default MemberListPagination;

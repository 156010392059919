import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { Protected } from '@timed/auth';
import { Block, formatPersonName, Link } from '@timed/common';
import { Permission } from '@timed/gql';
import {
  isMember,
  MemberContext,
  MemberInviteBox,
  MemberUpdateAttributesForm,
  MemberUpdateColorForm,
  MemberUpdateCommunicationPreferencesForm,
  MemberUpdateMemberDetailsForm,
  MemberUpdateOrganisationDetailsForm,
  MemberUpdatePersonalDetailsForm,
  MemberUpdateScheduleSettingsForm,
  MemberUpdateWorkRightsForm,
} from '@timed/member';
import { MemberPostNominalsTable } from '@timed/member/components/PostNominalsTable';
import { format } from 'date-fns';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
    },
    archived: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
      },
    },
  }),
);

const MemberViewOverview = () => {
  const classes = useStyles();
  const member = useContext(MemberContext);

  return (
    <Box className={classes.wrapper}>
      {member.archive && (
        <Block>
          <Box className={classes.archived}>
            <LockIcon fontSize="small" />
            <Typography>
              Archived on {format(new Date(member.archive.date), 'd MMM yyyy')}{' '}
              by{' '}
              <Link to={'../../' + member.archive.actor!.id}>
                {formatPersonName(member.archive.actor!)}
              </Link>
            </Typography>
          </Box>
        </Block>
      )}
      <Protected permissions={Permission.MEMBER_WRITE}>
        {member && !member.hasAccess && !member.archive && !member.delete && (
          <MemberInviteBox />
        )}
      </Protected>
      <MemberUpdatePersonalDetailsForm member={member} />
      <Protected permissions={Permission.MEMBER_READ}>
        <MemberPostNominalsTable member={member} />
      </Protected>
      {isMember(member) && (
        <>
          <Protected tester>
            <MemberUpdateAttributesForm member={member} />
          </Protected>
          <MemberUpdateColorForm member={member} />
          <MemberUpdateMemberDetailsForm member={member} />
          <Protected permissions={Permission.MEMBER_WRITE}>
            <MemberUpdateWorkRightsForm member={member} />
          </Protected>
          <Protected admin={true}>
            <MemberUpdateScheduleSettingsForm member={member} />
          </Protected>
          <Protected permissions={Permission.MEMBER_WRITE}>
            <MemberUpdateCommunicationPreferencesForm member={member} />
          </Protected>
          <Protected admin={true}>
            <MemberUpdateOrganisationDetailsForm member={member} />
          </Protected>
          <Protected admin={true}>
            <Block>
              <Grid container spacing={4}>
                {member.lastActiveAt && (
                  <>
                    <Grid item xs={4} md={3} lg={2}>
                      <Typography>Last active</Typography>
                    </Grid>
                    <Grid item xs={8} md={9} lg={10}>
                      <Typography>
                        {format(
                          new Date(member.lastActiveAt),
                          'h:mm a, dd MMMM yyyy',
                        )}
                      </Typography>
                    </Grid>
                  </>
                )}
                <Grid item xs={4} md={3} lg={2}>
                  <Typography>Created at</Typography>
                </Grid>
                <Grid item xs={8} md={9} lg={10}>
                  <Typography>
                    {format(new Date(member.createdAt), 'h:mm a, dd MMMM yyyy')}
                  </Typography>
                </Grid>
                {member.createdBy && (
                  <>
                    <Grid item xs={4} md={3} lg={2}>
                      <Typography>Created by</Typography>
                    </Grid>
                    <Grid item xs={8} md={9} lg={10}>
                      <Link to={'../../' + member.createdBy?.id}>
                        <Typography>
                          {formatPersonName(member.createdBy!)}
                        </Typography>
                      </Link>
                    </Grid>
                  </>
                )}
                {!!member.invitationMostRecentlyReceived && (
                  <>
                    {member.invitationMostRecentlyReceived.usedAt ? (
                      <>
                        <Grid item xs={4} md={3} lg={2}>
                          <Typography>Invite accepted at</Typography>
                        </Grid>
                        <Grid item xs={8} md={9} lg={10}>
                          <Typography>
                            {format(
                              new Date(
                                member.invitationMostRecentlyReceived.usedAt,
                              ),
                              'h:mm a, dd MMMM yyyy',
                            )}
                          </Typography>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={4} md={3} lg={2}>
                          <Typography>Last invited at</Typography>
                        </Grid>
                        <Grid item xs={8} md={9} lg={10}>
                          <Typography>
                            {format(
                              new Date(
                                member.invitationMostRecentlyReceived.lastSender.sentAt,
                              ),
                              'h:mm a, dd MMMM yyyy',
                            )}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={4} md={3} lg={2}>
                      <Typography>
                        {member.invitationMostRecentlyReceived.usedAt
                          ? 'I'
                          : 'Last i'}
                        nvited by
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={9} lg={10}>
                      <Link
                        to={
                          '../../' +
                          member.invitationMostRecentlyReceived.lastSender
                            .sentBy?.id
                        }
                      >
                        <Typography>
                          {formatPersonName(
                            member.invitationMostRecentlyReceived.lastSender
                              .sentBy,
                          )}
                        </Typography>
                      </Link>
                    </Grid>
                  </>
                )}
              </Grid>
            </Block>
          </Protected>
        </>
      )}
    </Box>
  );
};

export default MemberViewOverview;

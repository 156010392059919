import { FileMetadata } from '@timed/file';
import { MemberFileType } from '@timed/gql';
import { MemberFileCategory } from '@timed/member/constants/categories';

export const memberFilesMetadata: FileMetadata<MemberFileType, MemberFileCategory>[] =
  [
    /**
     * Miscellaneous
     */
    {
      id: MemberFileType.MISC,
      name: '000. Miscellaneous',
      hasDate: true,
      hasNote: true,
      hasExpiryDate: true,
    },

    /**
     * Certifications
     */
    {
      id: MemberFileType.VISA,
      category: MemberFileCategory.CERTIFICATION,
      name: '014. VISA / VEVO / Workrights',
      hasDate: true,
      hasExpiryDate: true,
      hasIssueDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.POLICE_CHECK,
      category: MemberFileCategory.CERTIFICATION,
      name: '101. Police Check',
      hasDate: true,
      hasIssueDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.WORKING_WITH_CHILDREN_CHECK,
      category: MemberFileCategory.CERTIFICATION,
      name: '002. Working With Children Check',
      hasDate: true,
      hasExpiryDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.NDIS_MODULE,
      category: MemberFileCategory.CERTIFICATION,
      name: '003. NDIS Module',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.NEW_WORKER_NDIS_INDUCTION_MODULE,
      category: MemberFileCategory.CERTIFICATION,
      name: '009. New Worker NDIS Induction Module',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.COVID_19,
      category: MemberFileCategory.CERTIFICATION,
      name: '004. COVID 19 Module',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.NEW_NDIS_CLEARANCE,
      category: MemberFileCategory.CERTIFICATION,
      name: '008. New NDIS Clearence',
      hasDate: true,
      hasNote: true,
      hasExpiryDate: true,
    },
    {
      id: MemberFileType.ASSISTING_WITH_SELF_MEDICATION,
      category: MemberFileCategory.CERTIFICATION,
      name: '005. Assisting With Self Medication',
      hasDate: true,
      hasNote: true,
      hasExpiryDate: true,
    },
    {
      id: MemberFileType.FIRST_AID,
      category: MemberFileCategory.CERTIFICATION,
      name: '011. First Aid Certificate',
      hasDate: true,
      hasNote: true,
      hasExpiryDate: true,
    },
    {
      id: MemberFileType.CPR,
      category: MemberFileCategory.CERTIFICATION,
      name: '012. CPR Certificate',
      hasDate: true,
      hasNote: true,
      hasExpiryDate: true,
    },
    {
      id: MemberFileType.MANUAL_HANDLING,
      category: MemberFileCategory.CERTIFICATION,
      name: '213. Manual Handling Certificate',
      hasDate: true,
      hasNote: true,
      hasExpiryDate: true,
    },
    {
      id: MemberFileType.INDIVIDUAL_SUPPORT,
      category: MemberFileCategory.CERTIFICATION,
      name: '021. Individual Support Certificate',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.COVID_19_TRAVEL_EXEMPTION,
      category: MemberFileCategory.CERTIFICATION,
      name: '029. COVID-19 Travel Exemption',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.PPE,
      category: MemberFileCategory.CERTIFICATION,
      name: '006. Covid 19 - PPE Module',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.EFFECTIVE_COMMUNICATION,
      category: MemberFileCategory.CERTIFICATION,
      name: '207. Supporting Effective Communication',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.SUPPORTING_SAFE_MEALS,
      category: MemberFileCategory.CERTIFICATION,
      name: '208. Supporting Safe and Enjoyable Meals',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.UNDERSTANDING_DEMENTIA,
      category: MemberFileCategory.CERTIFICATION,
      name: '209. Understanding Dementia',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.PREVENTING_DEMENTIA,
      category: MemberFileCategory.CERTIFICATION,
      name: '210. Preventing Dementia',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.SEIZURE_RECOGITION,
      category: MemberFileCategory.CERTIFICATION,
      name: '220. Seizure Recognition and First Aid Certification',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.UNDERSTANDING_TRAUMATIC_BRAIN_INJURY,
      category: MemberFileCategory.CERTIFICATION,
      name: '206. Understanding Traumatic Brain Injury',
      hasDate: true,
      hasNote: true,
    },

    /**
     * Employment
     */
    {
      id: MemberFileType.STAFF_APPLICATION,
      category: MemberFileCategory.EMPLOYMENT,
      name: '027. Staff Application',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.EMPLOYMENT_CONTRACT,
      category: MemberFileCategory.EMPLOYMENT,
      name: '022. Employment Contract',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.CONFIRMATION_OF_ENROLLMENT,
      category: MemberFileCategory.EMPLOYMENT,
      name: '028. Confirmation of Enrollment',
      hasDate: true,
      hasNote: true,
      hasExpiryDate: true,
    },
    {
      id: MemberFileType.PRIVACY_AND_CONFIDENTIAILITY_DECLARATION,
      category: MemberFileCategory.EMPLOYMENT,
      name: '023. Privacy And Confidentiality Declaration',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.NON_DISCLOSURE_AGREEMENT,
      category: MemberFileCategory.EMPLOYMENT,
      name: '023. Non-disclosure Agreement',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.TAX_FILE_DECLARATION,
      category: MemberFileCategory.EMPLOYMENT,
      name: '025. TAX File Declaration',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.STAFF_CARD_PHOTO_ID,
      category: MemberFileCategory.EMPLOYMENT,
      name: '026. Staff Card Photo ID',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.INTERVIEW,
      category: MemberFileCategory.EMPLOYMENT,
      name: '040. Interview/Phone Screen',
      hasDate: true,
      hasNote: true,
    },

    /**
     * Financial
     */
    {
      id: MemberFileType.BANK_DETAILS,
      category: MemberFileCategory.FINANCIAL,
      name: '031. Bank Details',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.SUPERANNUATION,
      category: MemberFileCategory.FINANCIAL,
      name: '030. Superannutation',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.TAX_FILE_NUMBER,
      category: MemberFileCategory.FINANCIAL,
      name: '099. TAX File Number',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.TRAVEL_RECEIPT,
      category: MemberFileCategory.FINANCIAL,
      name: '303. Travel Receipt',
      hasDate: true,
      hasNote: true,
    },

    /**
     * Identification
     */
    {
      id: MemberFileType.PASSPORT,
      category: MemberFileCategory.IDENTIFICATION,
      name: '015. Passport / Citizenship certificate',
      hasDate: true,
      hasExpiryDate: true,
      hasIssueDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.DRIVERS_LICENCE,
      category: MemberFileCategory.IDENTIFICATION,
      name: '024. Drivers Licence',
      hasDate: true,
      hasExpiryDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.BIRTH_CERTIFICATE,
      category: MemberFileCategory.IDENTIFICATION,
      name: '017. Birth Certificate',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.MEDICARE_CARD,
      category: MemberFileCategory.IDENTIFICATION,
      name: '016. Medicare Card',
      hasDate: true,
      hasExpiryDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.PHOTO_ID_CARD,
      category: MemberFileCategory.IDENTIFICATION,
      name: '404. Photo ID Card',
      hasDate: true,
      hasExpiryDate: true,
      hasNote: true,
    },

    /**
     * Personal
     */
    {
      id: MemberFileType.RESUME,
      category: MemberFileCategory.PERSONAL,
      name: '020. Resumé',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.CAR_REGISTRATION,
      category: MemberFileCategory.PERSONAL,
      name: '033. Car Registration',
      hasDate: true,
      hasNote: true,
      hasExpiryDate: true,
    },
    {
      id: MemberFileType.CTP_INSURANCE,
      category: MemberFileCategory.PERSONAL,
      name: '034. CTP Insurance',
      hasDate: true,
      hasExpiryDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.CAR_INSURANCE,
      category: MemberFileCategory.PERSONAL,
      name: '032. Car Insurance',
      hasDate: true,
      hasNote: true,
      hasExpiryDate: true,
    },

    /**
     * Medical
     */
    {
      id: MemberFileType.VACCINATION_RECORD,
      category: MemberFileCategory.MEDICAL,
      name: '050. Vaccination Record (excluding covid)',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.COVID_TEST_RESULTS,
      category: MemberFileCategory.MEDICAL,
      name: '051. COVID-19 Test Results ',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.PROOF_OF_COVID_VACCINATION,
      category: MemberFileCategory.MEDICAL,
      name: '052. Proof of COVID-19 Vaccination',
      hasDate: true,
      hasNote: true,
    },
    {
      id: MemberFileType.PROOF_OF_OVERSEAS_HEALTH_COVER,
      category: MemberFileCategory.MEDICAL,
      name: '053. Proof of Overseas Health Cover',
      hasDate: true,
      hasNote: true,
    },

    /**
     * To be deleted
     */
    {
      id: MemberFileType.WORK_RIGHTS,
      category: MemberFileCategory.EMPLOYMENT,
      name: '999. Work Rights (deprecated)',
      hasDate: true,
      hasNote: true,
      hasExpiryDate: true,
    },
    {
      id: MemberFileType.VEVO,
      category: MemberFileCategory.IDENTIFICATION,
      name: '018. VEVO (deprecated)',
      hasDate: true,
      hasNote: true,
      hasExpiryDate: true,
    },
  ];

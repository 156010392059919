import { _shiftsPersonalGrab } from '@timed/app/constants/metadata';
import { Page } from '@timed/page';
import { ShiftsGrab } from '@timed/shifts';

const RouteCoreSupportShiftsPersonalGrab = () => (
  <Page {..._shiftsPersonalGrab}>
    <ShiftsGrab />
  </Page>
);

export default RouteCoreSupportShiftsPersonalGrab;

import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Protected } from '@timed/auth';
import {
  ClientContext,
  ClientUpdateNdisDetailsForm,
  isClient,
} from '@timed/client';
import NdisPlanTable from '@timed/client/components/NdisPlanTable/NdisPlanTable';
import { Permission } from '@timed/gql';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
    },
    row: {
      [theme.breakpoints.up('lg')]: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridAutoColumns: '50%',
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexFlow: 'column',
        gap: theme.spacing(1),
      },
    },
    archived: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
      },
    },
  }),
);

const ClientViewOverview = () => {
  const classes = useStyles();
  const client = useContext(ClientContext);

  return (
    <Box className={classes.wrapper}>
      {isClient(client) && (
        <Protected permissions={Permission.CLIENT_BUDGET_READ}>
          <ClientUpdateNdisDetailsForm />
          <NdisPlanTable />
        </Protected>
      )}
    </Box>
  );
};

export default ClientViewOverview;

import {
  Tab,
  TabProps,
  Tabs as MuiTabs,
  TabsProps as MuiTabsProps,
  Theme,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { useAuth } from '@timed/auth';
import { Permission } from '@timed/gql';

type TabsProps<T> = Omit<MuiTabsProps, 'value'> & {
  name: string;
  state: [T, React.Dispatch<React.SetStateAction<T>>];
  items: (Omit<TabProps, 'value'> & {
    value: T;
    admin?: boolean;
    permissions?: Permission | Permission[];
  })[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      color: theme.palette.text.primary,
      '& .MuiTabs-flexContainer': {
        display: 'inline-grid',
        gridAutoFlow: 'column',
        [theme.breakpoints.down('md')]: {
          gap: theme.spacing(4),
        },
        [theme.breakpoints.up('md')]: {
          gap: theme.spacing(8),
        },
      },
    },
    tab: {
      textTransform: 'none',
      minWidth: 'inherit',
      '&.MuiTab-root': {
        padding: 0,
      },
      '& span': {
        padding: theme.spacing(1, 0),
      },
    },
    indicator: {
      height: theme.spacing(1),
    },
  }),
);

const Tabs = <T extends unknown>({
  name,
  items,
  state: [tab, setTab],
  ...props
}: TabsProps<T>) => {
  const classes = useStyles();

  const handleChange = (_: React.ChangeEvent<{}>, value: T) => {
    setTab(value);
  };

  const { permissible } = useAuth();

  return (
    <MuiTabs
      {...props}
      value={tab}
      onChange={handleChange}
      className={classes.tabs}
      TabIndicatorProps={{ className: classes.indicator }}
    >
      {items
        .filter(
          ({ admin, permissions }) =>
            !(admin || permissions) ||
            (admin && permissible({ admin })) ||
            (permissions && permissible({ permissions })),
        )
        .map(({ value, ...props }, index) => (
          <Tab
            {...props}
            id={name + '-tab-' + index}
            value={value}
            className={classes.tab}
          />
        ))}
    </MuiTabs>
  );
};

export default Tabs;

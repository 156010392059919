import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { TableCell, TableContext, TableRowProps } from '@timed/common';
import TableRowContext from '@timed/common/context/TableRowContext';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { isFragment } from 'react-is';

const TableRow = ({
  children,
  onClick,
  hidden = false,
  highlighted = false,
  index = 0,
  shrunk = false,
}: TableRowProps) => {
  const {
    persistentRowHighlighting: persistentHighlights,
    lastClickedRow,
    setLastClickedRow,
    alphaMuliplier,
    backgroundColor,
    backgroundIsDark,
    showIndexColumn: showRowIndexes,
    indexOffset = 0,
  } = useContext(TableContext);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      indexesCell: {
        position: 'sticky',
        left: 0,
        textAlign: 'center',
      },
      interactable: {
        cursor: 'pointer',
      },
      persistentHighlight: {
        '& td': {
          backgroundColor: `color-mix(in srgb, ${
            backgroundIsDark ? 'white' : 'black'
          } ${10 * alphaMuliplier}%, ${backgroundColor})`,
        },
        '&:hover': {
          '& td': {
            backgroundColor: `color-mix(in srgb, ${
              backgroundIsDark ? 'white' : 'black'
            } ${10 * alphaMuliplier}%, ${backgroundColor}) !important`,
          },
        },
      },
    }),
  );

  const classes = useStyles();

  /**
   * Array of cells.
   */
  const cells = !!children
    ? (
        (Array.isArray(children)
          ? children
          : [children]) as React.ReactElement[]
      )
        .flat()
        .flatMap((child) =>
          isFragment(child) && !!child.props.children.length
            ? child.props.children
            : child,
        )
        .filter(({ type }) => !!type && type.name === TableCell.name)
    : [];

  let rowClassName: React.HTMLAttributes<HTMLTableRowElement>['className'] =
    undefined;

  if (!!onClick) rowClassName = classes.interactable;

  if (lastClickedRow === index || highlighted)
    rowClassName = clsx(rowClassName, classes.persistentHighlight);

  return hidden ? null : (
    <TableRowContext.Provider
      value={{ hidden, index, onClick, cells, highlighted, shrunk }}
    >
      <tr
        key={index}
        onClick={(event) => {
          persistentHighlights &&
            setLastClickedRow(lastClickedRow === index ? null : index);
          !!onClick && onClick(event);
        }}
        className={rowClassName}
        style={{ height: shrunk ? 0 : undefined }}
      >
        {showRowIndexes && (
          <td className={classes.indexesCell}>{indexOffset + index + 1}</td>
        )}
        {cells.map(({ props: { children, ...props } }, i) => (
          <TableCell {...props} key={i} index={i}>
            {children}
          </TableCell>
        ))}
      </tr>
    </TableRowContext.Provider>
  );
};

export default TableRow;

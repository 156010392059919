import { Button, Dialog, ModalProps } from '@timed/common';
import { ClientContact, useDeleteClientContactsMutation } from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';

type ClientDeleteContactDialogProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  contact: Pick<ClientContact, 'id' | 'name'>;
};

const ClientDeleteContactDialog = ({
  contact,
  onClose,
  ...props
}: ClientDeleteContactDialogProps) => {
  const [deleteEntity, { loading, client }] = useDeleteClientContactsMutation();

  useLoadingEffect(loading);

  const handleDelete = () => {
    deleteEntity({
      variables: { input: { ids: [contact.id] } },

      onCompleted: ({ deleteClientContacts }) => {
        client.cache.evict({
          id: client.cache.identify({
            __typename: 'ClientContact',
            id: deleteClientContacts[0].id,
          }),
        });
        client.cache.gc();
        onClose();
      },
    });
  };

  return (
    <Dialog
      {...props}
      title="Deleting Contact"
      description={`Are you sure you want to delete "${contact.name}"?`}
      actions={[
        <Button onClick={onClose}>Cancel</Button>,
        <Button onClick={handleDelete}>Yes</Button>,
      ]}
    />
  );
};

export default ClientDeleteContactDialog;

import { useAlert } from '@timed/alert';
import { Button } from '@timed/common';
import { useUninviteMemberMutation } from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { MemberContext } from '@timed/member';
import { useContext } from 'react';

const MemberInviteCancelButton = () => {
  const alert = useAlert();

  const { invitationMostRecentlyReceived } = useContext(MemberContext);

  const [
    uninviteMembers,
    {
      loading,
      client: { cache },
    },
  ] = useUninviteMemberMutation({
    onCompleted: ({ uninviteMember }) => {
      cache.evict({
        id: cache.identify({
          __typename: 'MemberInvitation',
          id: uninviteMember?.id,
        }),
      });

      cache.gc();

      alert.push({ message: 'Invitation cancelled', severity: 'info' });
    },
  });

  useLoadingEffect(loading);

  return !invitationMostRecentlyReceived ? null : (
    <Button
      onClick={() => {
        uninviteMembers({
          variables: {
            input: {
              id: invitationMostRecentlyReceived.id,
            },
          },
        });
      }}
    >
      Cancel
    </Button>
  );
};

export default MemberInviteCancelButton;

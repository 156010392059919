import { Menu, MenuItem, useMediaQuery, useTheme } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { IconButton } from '@timed/common';
import { MemberBasePayRate } from '@timed/gql';
import { MemberDeleteBasePayRateDialog } from '@timed/member/components/DeleteBasePayRateDialog';
import MemberUpdateBasePayRateFormModal from '@timed/member/components/UpdateBasePayRateFormModal/UpdateMemberBasePayRateFormModal';
import { useModal } from 'mui-modal-provider';
import { useState } from 'react';

type MemberBasePayRateActionMenuButtonProps = {
  basePayRate: Pick<MemberBasePayRate, 'id' | 'date' | 'value'>;
};

const MemberBasePayRateActionMenuButton = ({
  basePayRate,
}: MemberBasePayRateActionMenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { showModal } = useModal();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenDeleteDialog = () => {
    const modal: { hide: () => void } = showModal(
      MemberDeleteBasePayRateDialog,
      {
        onClose: () => modal.hide(),
        basePayRate,
      },
    );
  };

  const handleOpenUpdateModal = () => {
    const modal: { hide: () => void } = showModal(
      MemberUpdateBasePayRateFormModal,
      {
        onClose: () => modal.hide(),
        basePayRate,
      },
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <span>
      <IconButton
        aria-label="menu"
        color="inherit"
        size={smDown ? 'small' : 'medium'}
        onClick={handleClick}
      >
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleOpenUpdateModal();
            handleClose();
          }}
        >
          Update pay rate
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenDeleteDialog();
            handleClose();
          }}
        >
          Delete pay rate
        </MenuItem>
      </Menu>
    </span>
  );
};

export default MemberBasePayRateActionMenuButton;

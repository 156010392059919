import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { CalendarContext, Select, useRouter } from '@timed/common';
import { ChangeEvent, useContext } from 'react';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
      flexGrow: 1,
      '& .MuiSelect-root': {
        fontSize: 12,
      },
      [theme.breakpoints.down('sm')]: {
        '& .MuiSelect-root': {
          fontSize: 12,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.divider,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': {
        padding: theme.spacing(2, 9, 2, 4),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1.75, 8, 1.75, 2),
        },
      },
      '& .MuiTypography-root': {
        fontSize: '1rem',
        [theme.breakpoints.down('sm')]: {
          fontSize: 12,
        },
      },
    },
  }),
);

const CalendarDateRangeInput = () => {
  const classes = useStyles();

  const { control } = useForm();

  const { setRange, scrollableAreaRef } = useContext(CalendarContext);

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    search: [searchParams],
  } = useRouter();

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    if (searchParams.get('r') !== event.target.value) {
      setRange(Number(event.target.value as string));
      if (scrollableAreaRef?.current?.scrollLeft)
        scrollableAreaRef.current.scrollLeft = 0;
    }
  };

  const items = [
    { label: 'Week', value: '7' },
    { label: 'Fortnight', value: '14' },
    { label: 'Month', value: '28' },
    { label: '2 Months', value: '56' },
    { label: '3 Months', value: '84' },
  ];

  return (
    <Select
      label={smDown ? undefined : 'Range'}
      disableUnderline
      aria-label="date-range"
      name="r"
      value={searchParams.get('r') || 28}
      control={control}
      onChange={handleChange}
      className={classes.root}
      formControlProps={{
        variant: 'outlined',
        size: 'small',
      }}
      items={items}
      renderValue={(value) => (
        <Typography>
          {items.find((item) => value === item.value)
            ? items.find((item) => value === item.value)?.label
            : value + ' days'}
        </Typography>
      )}
    />
  );
};

export default CalendarDateRangeInput;

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import { ButtonGroup } from '@mui/material';
import {
  Button,
  createDownloadLink,
  DataSize,
  roundNumber,
} from '@timed/common';
import {
  ClientNoteFile,
  EventFile,
  useDownloadClientNoteFileByIdLazyQuery,
  useDownloadEventFileByIdLazyQuery,
} from '@timed/gql';
import { noteFilesMetadata } from '@timed/notes';
import { NotesDeleteAttachmentFormModal } from '@timed/notes/components/DeleteAttachmentFormModal';
import { useModal } from 'mui-modal-provider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoButton: {
      color: theme.palette.text.primary,
      padding: theme.spacing(2),
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
        cursor: 'pointer',
      },
      '& .MuiButton-label': {
        display: 'flex',
        gap: theme.spacing(1),
      },
      '& .MuiTypography-root:first-child': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
    },
    text: {
      fontSize: 10,
      lineHeight: 1,
    },
    deleteButton: {
      minWidth: 36,
      backgroundColor: theme.palette.secondary.dark,
      '&:hover': {
        backgroundColor: theme.palette.secondary.darkest,
        cursor: 'pointer',
      },
    },
  }),
);

const NotesAttachment = ({
  __typename,
  id,
  type,
  file: { size },
}:
  | (Pick<ClientNoteFile, '__typename' | 'id' | 'type'> & {
      file: Pick<ClientNoteFile['file'], 'size'>;
    })
  | (Pick<EventFile, '__typename' | 'id' | 'type'> & {
      file: Pick<ClientNoteFile['file'], 'size'>;
    })) => {
  const classes = useStyles();

  const { showModal } = useModal();

  const [downloadEventFile] = useDownloadEventFileByIdLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => createDownloadLink(data.downloadEventFileById.path),
  });

  const [downloadNoteFile] = useDownloadClientNoteFileByIdLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (data) =>
      createDownloadLink(data.downloadClientNoteFileById.path),
  });

  const handleOpenDeleteModal = () => {
    const modal: { hide: () => void } = showModal(
      NotesDeleteAttachmentFormModal,
      {
        onClose: () => {
          modal.hide();
        },
        attachment: { __typename, id },
      },
    );
  };

  return (
    <ButtonGroup disableElevation variant="contained">
      <Button
        className={classes.infoButton}
        onClick={() => {
          switch (__typename) {
            case 'ClientNoteFile':
              downloadNoteFile({ variables: { id } });
              break;
            case 'EventFile':
              downloadEventFile({ variables: { id } });
              break;
          }
        }}
      >
        <DescriptionOutlinedIcon fontSize="small" />
        <div className={classes.content}>
          <Typography className={classes.text}>
            {noteFilesMetadata.find((meta) => meta.id === type)?.name}
          </Typography>
          <Typography className={classes.text}>
            {roundNumber(
              size > DataSize.MB ? size / DataSize.MB : size / DataSize.KB,
              2,
            ) + (size > DataSize.MB ? 'MB' : 'KB')}
          </Typography>
        </div>
      </Button>
      <Button className={classes.deleteButton} onClick={handleOpenDeleteModal}>
        <DeleteIcon fontSize="small" />
      </Button>
    </ButtonGroup>
  );
};

export default NotesAttachment;

/**
 * Download a file using a temporary anchor.
 */
export const createDownloadLink = (path: string): void => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.download = path;
  a.href = path;
  a.click();
  document.body.removeChild(a);
};

import { Event } from '@timed/gql';
import {
  addDays,
  addHours,
  differenceInMinutes,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  min,
  startOfDay,
} from 'date-fns';
import _ from 'lodash';

/**
 * Payroll shift which is, or makes up parts of, an event.
 */
export type FormattedShift = Pick<
  Event,
  'startAt' | 'endAt' | 'duration' | 'passive' | 'publicHoliday'
>;

/**
 * Unformatted partial event entitiy.
 */
export type UnformattedEvent = Pick<
  Event,
  | 'id'
  | 'startAt'
  | 'endAt'
  | 'activeAssist'
  | 'passive'
  | 'passiveStartAt'
  | 'publicHoliday'
>;

/**
 * Formatted event entitiy.
 */
export type FormattedEvent<T> = T & {
  shifts: FormattedShift[];
  duration: number;
};

/**
 * Function properties.
 */
export type EventsWithShiftsFn = {
  /**
   * Event entities to format.
   */
  event: UnformattedEvent;

  /**
   * Filter out shifts occuring outside specified dates.
   */
  dates?: {
    from: Date;
    to: Date;
  };
};

/**
 * Function properties.
 */
type GetShiftsFromEventFn = Pick<EventsWithShiftsFn, 'dates'> & {
  event: UnformattedEvent;
  from?: Date;
};

/**
 * Get shifts from an event entity.
 */
export const eventWithShifts = <T extends Event>({
  event,
  dates,
}: EventsWithShiftsFn): FormattedEvent<T> => {
  if (!event.startAt || !event.endAt) throw new Error('Missing event dates');

  /**
   * Extract all shifts from an event
   */
  const getShiftsFromEvent = ({
    event,
    dates,
    from,
  }: GetShiftsFromEventFn): FormattedShift[] => {
    const startAt = new Date(event.startAt);
    const endAt = new Date(event.endAt);
    const passiveStartAt = event.passiveStartAt
      ? new Date(event.passiveStartAt)
      : undefined;

    // If from is not set, this is the first shift in an event.
    from ??= startAt;

    if (dates && isAfter(from, dates.to)) return [];

    const endOfDayOfShift = startOfDay(addDays(from, 1));

    const eventIsPassive =
      event.passive && !!event.passiveStartAt && !event.activeAssist;

    const shiftIsPassive = eventIsPassive && isEqual(from, passiveStartAt!);

    const to = !eventIsPassive
      ? min([endAt, endOfDayOfShift])
      : shiftIsPassive
      ? addHours(passiveStartAt!, 8)
      : isSameDay(from, passiveStartAt!)
      ? isBefore(from, passiveStartAt!)
        ? passiveStartAt!
        : min([endAt, endOfDayOfShift])
      : min([endAt, endOfDayOfShift]);

    let laterShifts: FormattedShift[] = [];

    if (isAfter(endAt, to) && (!dates || !isEqual(to, dates.to)))
      laterShifts = getShiftsFromEvent({ event, from: to });

    // Disregard this shift if it does not occur inside the inclusion dates
    if (dates && isBefore(from, dates.from)) return laterShifts;

    const thisShift: FormattedShift = {
      startAt: from,
      endAt: to,
      duration: differenceInMinutes(to, from),
      passive: shiftIsPassive,
    };

    // Assign passive attributes to object if it is the passive period of a passive event
    if (shiftIsPassive)
      _.merge(thisShift, _.pick(event, ['passiveStartAt', 'activeAssist']));

    return [thisShift, ...laterShifts];
  };

  return {
    ...event,
    shifts: getShiftsFromEvent({ dates, event }),
    duration: differenceInMinutes(
      new Date(event.endAt),
      new Date(event.startAt),
    ),
  } as FormattedEvent<T>;
};

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  addServerErrors,
  DateInput,
  FileInput,
  formatPersonName,
  FormModal,
  MIME,
  ModalProps,
  TextField,
  transformNumberToFixedFloat,
} from '@timed/common';
import { FileMetadata } from '@timed/file';
import {
  CreateMemberFileDocument,
  Maybe,
  Member,
  MemberFileType,
  PersonNamesPartialFragment,
  useCreateMemberFileMutation,
  useUpdateMembersMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import {
  memberFilesMetadata,
  MemberFileTypeInput,
  MemberWorkRightsInput,
} from '@timed/member';
import { MemberFileCategory } from '@timed/member/constants';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

export type MemberFileCreateFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  member: Pick<
    Member,
    | 'id'
    | 'allowedFortnightlyWorkHours'
    | 'workRights'
    | 'workRightsLastCheckedAt'
  > &
    PersonNamesPartialFragment;
  type?: MemberFileType;
};

type FormData = {
  type?: MemberFileType;
  dateOfFile?: Maybe<Date>;
  expiresAt?: Maybe<Date>;
  issuedAt?: Maybe<Date>;
  value?: Maybe<string>;
  attachment: any;

  // Work rights only fields
  workRights: Member['workRights'];
  workRightsLastCheckedAt: Date | null;
  allowedFortnightlyWorkHours: Member['allowedFortnightlyWorkHours'];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputs: {
      flex: '1 0 auto',
      overflowY: 'auto',
      display: 'grid',
      gridAutoFlow: 'row',
      gridAutoRows: 'max-content',
      gap: theme.spacing(4),
    },
    textarea: {
      backgroundColor: theme.palette.background.paper,
      width: 256,
      color: theme.palette.text.primary,
      border: '1px solid ' + theme.palette.text.disabled,
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.up('md')]: {
        width: 384,
      },
    },
    buttons: {
      flex: '0 1 max-content',
      display: 'flex',
      justifyContent: 'space-between',
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    info: {
      padding: theme.spacing(2, 4),
      color: theme.palette.text.primary,
      border: '1px solid ' + theme.palette.divider,
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
    },
    workRights: {
      padding: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      border: '1px solid ' + theme.palette.divider,
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    workRightsText: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
  }),
);

const MemberFileCreateFormModal = ({
  member,
  type,
  onClose,
  ...modalProps
}: MemberFileCreateFormModalProps) => {
  const classes = useStyles();

  const [createFile, response] = useCreateMemberFileMutation();
  const [updateMember] = useUpdateMembersMutation();

  const [metadata, setMetadata] =
    useState<FileMetadata<MemberFileType, MemberFileCategory>>();

  const defaultValues: Pick<
    FormData,
    | 'type'
    | 'workRights'
    | 'workRightsLastCheckedAt'
    | 'allowedFortnightlyWorkHours'
  > = {
    type,
    allowedFortnightlyWorkHours: member.allowedFortnightlyWorkHours,
    workRights: member.workRights,
    workRightsLastCheckedAt: member.workRightsLastCheckedAt,
  };

  const {
    handleSubmit,
    control,
    setError,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });

  const currentValues = {
    workRights: watch('workRights'),
    workRightsLastCheckedAt: watch('workRightsLastCheckedAt'),
    allowedFortnightlyWorkHours: watch('allowedFortnightlyWorkHours'),
  };

  const fileType = watch('type');

  useLoadingEffect(response.loading);

  const onSubmit = ({
    workRights,
    workRightsLastCheckedAt,
    allowedFortnightlyWorkHours,
    type,
    ...values
  }: FormData) => {
    createFile({
      variables: {
        input: {
          owner: { id: member.id },
          type: type!,
          ...values,
        },
      },
    }).catch((e) => {});

    if (type === MemberFileType.VISA && !isEqual(defaultValues, currentValues))
      updateMember({
        variables: {
          input: {
            ids: [member.id],
            patch: {
              workRights,
              workRightsLastCheckedAt,
              allowedFortnightlyWorkHours,
            },
          },
        },
      });
  };

  const onSuccess = () => {
    const cache = response.client.cache;

    cache.modify({
      fields: {
        memberFiles(existing = []) {
          return [
            ...existing,
            cache.writeQuery({
              data: response.data,
              query: CreateMemberFileDocument,
            }),
          ];
        },
      },
    });
  };

useEffect(() => {
  setMetadata(memberFilesMetadata.find((meta) => meta.id === fileType)!);
}, [fileType]);

/**
 * Display error messages from server response
 */
useEffect(() => {
  if (response.error) addServerErrors(response.error, setError);
}, [response.error, setError]);

return (
  <FormModal
    modalProps={modalProps}
    title="File upload"
    description={`Store a file for ${formatPersonName(member)}`}
    saveText="Upload"
    loading={response.loading}
    success={!!response.data}
    onSubmit={handleSubmit(onSubmit)}
    onSuccess={onSuccess}
    onClose={onClose}
  >
    <Box className={classes.inputs}>
      <MemberFileTypeInput
        required
        name="type"
        control={control}
        error={!!errors.type}
        helperText={errors.type?.message}
        formControlProps={{ size: 'small', variant: 'outlined' }}
      />
      <FileInput
        required
        name="attachment"
        control={control}
        allowedMimeTypes={[
          MIME.JPG,
          MIME.PNG,
          MIME.PDF,
          MIME.DOCX,
          MIME.XLSX,
          MIME.HEIC,
          MIME.HEIF,
        ]}
        setError={setError}
        setValue={setValue}
        formControlProps={{ size: 'small' }}
        error={!!errors.attachment}
        helperText={errors.attachment?.message?.toString()}
      />

      {metadata?.hasDate && (
        <DateInput
          keyboard
          disableTime
          name="dateOfFile"
          defaultValue={null}
          label="Date Of File"
          control={control}
          inputVariant="outlined"
          size="small"
        />
      )}
      {metadata?.hasIssueDate && (
        <DateInput
          keyboard
          defaultValue={null}
          name="issuedAt"
          label="Issue Date"
          control={control}
          inputVariant="outlined"
          size="small"
        />
      )}
      {metadata?.hasExpiryDate && (
        <DateInput
          keyboard
          defaultValue={null}
          name="expiresAt"
          label="Expiry Date"
          control={control}
          inputVariant="outlined"
          size="small"
        />
      )}
      {metadata?.hasNote && (
        <TextField
          name="value"
          variant="outlined"
          size="small"
          label="Value"
          type="text"
          validation={{ maxLength: { value: 255, message: 'Too long' } }}
          control={control}
          error={!!errors.value}
          helperText={errors.value?.message}
        />
      )}
      {fileType === MemberFileType.VISA && (
        <Box className={classes.workRights}>
          <Box className={classes.workRightsText}>
            <Typography variant="body1" className={classes.bold}>
              Work rights
            </Typography>
            <Typography variant="body2">
              Altering these fields will overwrite existing values
            </Typography>
          </Box>
          <MemberWorkRightsInput
            label="Work rights"
            name="workRights"
            control={control}
            setValue={setValue}
            error={!!errors.workRights}
            helperText={errors.workRights?.message}
          />
          <DateInput
            keyboard
            clearable
            disableTime
            label="Last checked date"
            control={control}
            inputVariant="outlined"
            size="small"
            name="workRightsLastCheckedAt"
            error={!!errors.workRightsLastCheckedAt}
            helperText={errors.workRightsLastCheckedAt?.message}
          />
          <TextField
            control={control}
            label="Fortnightly hours"
            name="allowedFortnightlyWorkHours"
            type="number"
            variant="outlined"
            size="small"
            inputProps={{ min: 0, max: 336, step: 1 }}
            transform={transformNumberToFixedFloat(0)}
            onClick={(event) => {
              (event.target as HTMLInputElement).select();
            }}
          />
        </Box>
      )}
    </Box>
  </FormModal>
);
};

export default MemberFileCreateFormModal;

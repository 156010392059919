import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { TableRowProps } from '@timed/common';
import { OrderBy } from '@timed/gql';
import { createContext } from 'react';

export type TableIndex = { index?: number };

export type TableHidable = { hidden?: boolean };

export type TableStylable = {
  style?: React.CSSProperties;
  className?: string;
  indent?: number;
};

export type TableInteractable<T> = { onClick?: React.MouseEventHandler<T> };

export type TableProps = React.PropsWithChildren<
  TableHidable &
    Pick<React.CSSProperties, 'backgroundColor' | 'maxHeight'> & {
      loading?: boolean;
      inline?: boolean;
      showIndexColumn?: boolean;
      enableRowHighlighting?: boolean;
      persistentRowHighlighting?: boolean;
      indexOffset?: number;
      size?: 'small' | 'large';
      border?: 'line' | 'grid';
      borderSize?: 'normal' | 'thick';
      borderColor?: React.CSSProperties['borderColor'];
      wrapperStyle?: Omit<React.CSSProperties, 'maxHeight'>;
    }
>;

export type TableCellProps = React.PropsWithChildren<
  TableIndex &
    TableHidable &
    TableStylable &
    TableInteractable<HTMLTableCellElement> &
    Pick<
      React.DetailedHTMLProps<
        React.TdHTMLAttributes<HTMLTableCellElement>,
        HTMLTableCellElement
      >,
      'colSpan' | 'rowSpan'
    >
>;

export type TableHeaderProps = TableCellProps & {
  order?: keyof typeof OrderBy | null;
  align?: 'left' | 'center' | 'right';
  sticky?: boolean;
  title?: string;
};

export type TableContextType = Omit<TableProps, 'indexOffset'> &
  Required<Pick<TableProps, 'indexOffset'>> & {
    columnRefs: React.MutableRefObject<(HTMLDivElement | null)[]>;
    columnWidths: Array<number>;
    setColumnWidths: React.Dispatch<React.SetStateAction<number[]>>;
    autoWidthColumnExists: boolean;
    headers: React.ReactElement<TableHeaderProps, string>[];
    rows: React.ReactElement<TableRowProps, string>[];
    lastClickedRow: number | null;
    setLastClickedRow: React.Dispatch<React.SetStateAction<number | null>>;
    alphaMuliplier: number;
    sizeMultiplier: number;
    backgroundIsDark: boolean;
    fontColor: CSSProperties['color'];
  };

export type TableReducer<T> = (state: T, newEntities: T) => T;

const TableContext = createContext<TableContextType>({
  autoWidthColumnExists: false,
  columnRefs: { current: [] },
  columnWidths: [],
  setColumnWidths: () => {},
  headers: [],
  rows: [],
  lastClickedRow: null,
  setLastClickedRow: () => {},
  alphaMuliplier: 1,
  sizeMultiplier: 1,
  fontColor: '#fff',
  backgroundIsDark: false,
  indexOffset: 0,
});

export default TableContext;

import { Grid, Typography } from '@material-ui/core';
import { Protected } from '@timed/auth';
import {
  Block,
  Checkbox,
  DateInput,
  EmailInput,
  IconButtonMulti,
  PersonNameFirstInput,
  PersonNameLastInput,
  PersonNameMiddleInput,
  Radio,
  RadioGroup,
  SalutationInput,
  TfnInput,
  addServerErrors,
  formatTfn,
  primativeFromString,
} from '@timed/common';
import {
  HistoryRestorable,
  Member,
  MemberTaxFileNumberDeclarationAreYou,
  Permission,
  Salutation,
  SetMemberTaxFileNumberDeclarationInput,
  useUpdateMembersTaxFileNumberDeclarationDetailsMutation,
} from '@timed/gql';
import {
  MemberTaxFileNumberDeclarationAreYouInput,
  MemberTaxFileNumberDeclarationBasisOfPayInput,
} from '@timed/member';
import { format, subYears } from 'date-fns';
import { camelCase, isEqual, startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type MemberUpdateTaxFileDeclarationDetailsFormProps = {
  member: Pick<Member, 'id' | 'taxFileNumberDeclaration'> &
    Partial<
      Pick<
        Member,
        'salutation' | 'firstName' | 'middleName' | 'lastName' | 'dob' | 'email'
      >
    > & {
      archive?: Pick<HistoryRestorable, 'id'> | null;
    };
};

type FormData = {
  patch: {
    taxFileNumberDeclaration?:
      | (Omit<
          SetMemberTaxFileNumberDeclarationInput,
          'claimTaxFreeThreshold' | 'studentLoanDebt'
        > & {
          claimTaxFreeThreshold?: string;
          studentLoanDebt?: string;
        })
      | null;
  };
};

const MemberUpdateTaxFileDeclarationDetailsForm = ({
  member,
}: MemberUpdateTaxFileDeclarationDetailsFormProps) => {
  const [editing, setEditing] = useState<boolean>(false);

  const [updateMember, response] =
    useUpdateMembersTaxFileNumberDeclarationDetailsMutation();

  const defaultValues: FormData = {
    patch: {
      taxFileNumberDeclaration: {
        ...member.taxFileNumberDeclaration,
        tfn: member.taxFileNumberDeclaration?.tfn
          ? formatTfn(member.taxFileNumberDeclaration.tfn)
          : '',
        claimTaxFreeThreshold:
          member.taxFileNumberDeclaration?.claimTaxFreeThreshold?.toString() ??
          'null',
        studentLoanDebt:
          member.taxFileNumberDeclaration?.studentLoanDebt?.toString() ??
          'null',
        salutation:
          member.taxFileNumberDeclaration?.salutation ===
            Salutation.NOTSPECIFIED && member.salutation
            ? member.salutation
            : member.taxFileNumberDeclaration?.salutation ??
              Salutation.NOTSPECIFIED,
        firstName:
          member.taxFileNumberDeclaration?.firstName ?? member.firstName,
        middleName:
          member.taxFileNumberDeclaration?.middleName ?? member.middleName,
        lastName: member.taxFileNumberDeclaration?.lastName ?? member.lastName,
        dob: member.taxFileNumberDeclaration?.dob ?? member.dob,
        email: member.taxFileNumberDeclaration?.email ?? member.email,
      },
    },
  };

  const {
    handleSubmit,
    watch,
    control,
    setError,
    getValues,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });

  const currentValues = watch();

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = ({ patch }: FormData) => {
    if (patch.taxFileNumberDeclaration?.claimTaxFreeThreshold !== undefined)
      Object.assign(patch.taxFileNumberDeclaration, {
        claimTaxFreeThreshold: primativeFromString(
          patch.taxFileNumberDeclaration.claimTaxFreeThreshold,
        ),
      });

    if (patch.taxFileNumberDeclaration?.studentLoanDebt !== undefined)
      Object.assign(patch.taxFileNumberDeclaration, {
        studentLoanDebt: primativeFromString(
          patch.taxFileNumberDeclaration.studentLoanDebt,
        ),
      });

    updateMember({
      variables: {
        input: {
          ids: [member.id],
          patch: patch as SetMemberTaxFileNumberDeclarationInput,
        },
      },
    }).catch((e) => {});
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block
        title="Tax File Number Declaration Details"
        topRight={
          !member.archive ? (
            <Protected permissions={Permission.MEMBER_WRITE}>
              <IconButtonMulti
                enabled={editing}
                changed={!isEqual(currentValues, defaultValues)}
                setEditing={setEditing}
                loading={response.loading}
                success={!!response.data}
              />
            </Protected>
          ) : undefined
        }
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={4} md={3} lg={2}>
            <Typography>TFN</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                !!member.taxFileNumberDeclaration?.tfn &&
                formatTfn(member.taxFileNumberDeclaration?.tfn)
              ) : (
                <TfnInput
                  fullWidth
                  name="patch.taxFileNumberDeclaration.tfn"
                  variant="outlined"
                  size="small"
                  control={control}
                  error={!!errors.patch?.taxFileNumberDeclaration?.tfn}
                  helperText={
                    errors.patch?.taxFileNumberDeclaration?.tfn?.message
                  }
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>
              I have made a separate application/enquiry to the ATO for a new or
              existing TFN
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                !!member.taxFileNumberDeclaration?.appliedForTfn ? (
                  'Yes'
                ) : member.taxFileNumberDeclaration?.appliedForTfn !== null ? (
                  'No'
                ) : (
                  ''
                )
              ) : (
                <Checkbox
                  defaultChecked={
                    !!member.taxFileNumberDeclaration?.appliedForTfn
                  }
                  name="patch.taxFileNumberDeclaration.appliedForTfn"
                  size="small"
                  control={control}
                  error={
                    !!errors.patch?.taxFileNumberDeclaration?.appliedForTfn
                  }
                  helperText={
                    errors.patch?.taxFileNumberDeclaration?.appliedForTfn
                      ?.message
                  }
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>
              I am claiming an exemption because I am under 18 years of age and
              do not earn enough to pay tax.
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                !!member.taxFileNumberDeclaration?.claimingExemptionMinor ? (
                  'Yes'
                ) : member.taxFileNumberDeclaration?.claimingExemptionMinor !==
                  null ? (
                  'No'
                ) : (
                  ''
                )
              ) : (
                <Checkbox
                  defaultChecked={
                    !!member.taxFileNumberDeclaration?.claimingExemptionMinor
                  }
                  name="patch.taxFileNumberDeclaration.claimingExemptionMinor"
                  size="small"
                  control={control}
                  error={
                    !!errors.patch?.taxFileNumberDeclaration
                      ?.claimingExemptionMinor
                  }
                  helperText={
                    errors.patch?.taxFileNumberDeclaration
                      ?.claimingExemptionMinor?.message
                  }
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>
              I am claiming an exemption because I am in receipt of a pension,
              benefit or allowance.
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                !!member.taxFileNumberDeclaration?.claimingExemptionPension ? (
                  'Yes'
                ) : member.taxFileNumberDeclaration
                    ?.claimingExemptionPension !== null ? (
                  'No'
                ) : (
                  ''
                )
              ) : (
                <Checkbox
                  defaultChecked={
                    !!member.taxFileNumberDeclaration?.claimingExemptionPension
                  }
                  name="patch.taxFileNumberDeclaration.claimingExemptionPension"
                  size="small"
                  control={control}
                  error={
                    !!errors.patch?.taxFileNumberDeclaration
                      ?.claimingExemptionPension
                  }
                  helperText={
                    errors.patch?.taxFileNumberDeclaration
                      ?.claimingExemptionPension?.message
                  }
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Salutation</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.taxFileNumberDeclaration?.salutation !==
                  Salutation.NOTSPECIFIED &&
                startCase(
                  camelCase(
                    member.taxFileNumberDeclaration?.salutation || undefined,
                  ),
                )
              ) : (
                <SalutationInput
                  fullWidth
                  name="patch.taxFileNumberDeclaration.salutation"
                  control={control}
                  error={!!errors.patch?.taxFileNumberDeclaration?.salutation}
                  helperText={
                    errors.patch?.taxFileNumberDeclaration?.salutation?.message
                  }
                  disabled={!editing}
                  formControlProps={{
                    size: 'small',
                    variant: 'outlined',
                  }}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Family name</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.taxFileNumberDeclaration?.lastName
              ) : (
                <PersonNameLastInput
                  fullWidth
                  name="patch.taxFileNumberDeclaration.lastName"
                  variant="outlined"
                  size="small"
                  control={control}
                  error={!!errors.patch?.taxFileNumberDeclaration?.lastName}
                  helperText={
                    errors.patch?.taxFileNumberDeclaration?.lastName?.message
                  }
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>First given name</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.taxFileNumberDeclaration?.firstName
              ) : (
                <PersonNameFirstInput
                  fullWidth
                  name="patch.taxFileNumberDeclaration.firstName"
                  variant="outlined"
                  size="small"
                  control={control}
                  error={!!errors.patch?.taxFileNumberDeclaration?.firstName}
                  helperText={
                    errors.patch?.taxFileNumberDeclaration?.firstName?.message
                  }
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Other given names</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.taxFileNumberDeclaration?.middleName
              ) : (
                <PersonNameMiddleInput
                  fullWidth
                  name="patch.taxFileNumberDeclaration.middleName"
                  variant="outlined"
                  size="small"
                  control={control}
                  error={!!errors.patch?.taxFileNumberDeclaration?.middleName}
                  helperText={
                    errors.patch?.taxFileNumberDeclaration?.middleName?.message
                  }
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>
              If you have changed your name since you last dealt with the ATO,
              provide your previous family name.
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.taxFileNumberDeclaration?.prevLastName
              ) : (
                <PersonNameLastInput
                  fullWidth
                  name="patch.taxFileNumberDeclaration.prevLastName"
                  variant="outlined"
                  size="small"
                  control={control}
                  error={!!errors.patch?.taxFileNumberDeclaration?.prevLastName}
                  helperText={
                    errors.patch?.taxFileNumberDeclaration?.prevLastName
                      ?.message
                  }
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>What is your primary e-mail address?</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.taxFileNumberDeclaration?.email
              ) : (
                <EmailInput
                  fullWidth
                  name="patch.taxFileNumberDeclaration.email"
                  variant="outlined"
                  size="small"
                  control={control}
                  error={!!errors.patch?.taxFileNumberDeclaration?.email}
                  helperText={
                    errors.patch?.taxFileNumberDeclaration?.email?.message
                  }
                  disabled={!editing}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>What is your date of birth?</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.taxFileNumberDeclaration?.dob &&
                format(
                  new Date(member.taxFileNumberDeclaration?.dob),
                  'd MMM yyyy',
                )
              ) : (
                <DateInput
                  keyboard
                  clearable
                  disableFuture
                  disableTime
                  openTo="year"
                  name="patch.taxFileNumberDeclaration.dob"
                  initialFocusedDate={new Date().getFullYear() - 18 + '-1-1'}
                  control={control}
                  inputVariant="outlined"
                  size="small"
                  error={!!errors.patch?.taxFileNumberDeclaration?.dob}
                  helperText={
                    errors.patch?.taxFileNumberDeclaration?.dob?.message
                  }
                  disabled={!editing}
                  maxDate={new Date()}
                  minDate={subYears(new Date(), 120)}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>On what basis are you paid?</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                startCase(
                  camelCase(
                    member.taxFileNumberDeclaration?.basisOfPay || undefined,
                  ),
                )
              ) : (
                <MemberTaxFileNumberDeclarationBasisOfPayInput
                  fullWidth
                  name="patch.taxFileNumberDeclaration.basisOfPay"
                  control={control}
                  error={!!errors.patch?.taxFileNumberDeclaration?.basisOfPay}
                  helperText={
                    errors.patch?.taxFileNumberDeclaration?.basisOfPay?.message
                  }
                  disabled={!editing}
                  formControlProps={{
                    size: 'small',
                    variant: 'outlined',
                  }}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Are you</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                startCase(
                  camelCase(
                    member.taxFileNumberDeclaration?.areYou || undefined,
                  ),
                ) +
                (!!member.taxFileNumberDeclaration?.areYou &&
                member.taxFileNumberDeclaration?.areYou !==
                  MemberTaxFileNumberDeclarationAreYou.HOLIDAY_MAKER
                  ? ' for tax purposes'
                  : '')
              ) : (
                <MemberTaxFileNumberDeclarationAreYouInput
                  name="patch.taxFileNumberDeclaration.areYou"
                  control={control}
                  error={!!errors.patch?.taxFileNumberDeclaration?.areYou}
                  helperText={
                    errors.patch?.taxFileNumberDeclaration?.areYou?.message
                  }
                  disabled={!editing}
                  formControlProps={{
                    size: 'small',
                    variant: 'outlined',
                  }}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>
              Do you want to claim the tax-free threshold from this payer?
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                !!member.taxFileNumberDeclaration?.claimTaxFreeThreshold ? (
                  'Yes'
                ) : member.taxFileNumberDeclaration?.claimTaxFreeThreshold !==
                  null ? (
                  'No'
                ) : (
                  ''
                )
              ) : (
                <RadioGroup
                  name="patch.taxFileNumberDeclaration.claimTaxFreeThreshold"
                  control={control}
                  style={{ display: 'flex', flexDirection: 'row' }}
                  error={
                    !!errors.patch?.taxFileNumberDeclaration
                      ?.claimTaxFreeThreshold
                  }
                  helperText={
                    errors.patch?.taxFileNumberDeclaration
                      ?.claimTaxFreeThreshold?.message
                  }
                >
                  <Radio
                    label="Unknown"
                    value="null"
                    currentValue={getValues(
                      'patch.taxFileNumberDeclaration.claimTaxFreeThreshold',
                    )}
                  />
                  <Radio
                    label="Yes"
                    value="true"
                    currentValue={getValues(
                      'patch.taxFileNumberDeclaration.claimTaxFreeThreshold',
                    )}
                  />
                  <Radio
                    label="No"
                    value="false"
                    currentValue={getValues(
                      'patch.taxFileNumberDeclaration.claimTaxFreeThreshold',
                    )}
                  />
                </RadioGroup>
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>
              Do you have a Higher Education Loan Program (HELP), VET Student
              Loan (VSL), Financial Supplement (FS), Student Start-up Loan (SSL)
              or Trade Support Loan (TSL) debt?
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                !!member.taxFileNumberDeclaration?.studentLoanDebt ? (
                  'Yes'
                ) : member.taxFileNumberDeclaration?.studentLoanDebt !==
                  null ? (
                  'No'
                ) : (
                  ''
                )
              ) : (
                <RadioGroup
                  name="patch.taxFileNumberDeclaration.studentLoanDebt"
                  control={control}
                  style={{ display: 'flex', flexDirection: 'row' }}
                  error={
                    !!errors.patch?.taxFileNumberDeclaration?.studentLoanDebt
                  }
                  helperText={
                    errors.patch?.taxFileNumberDeclaration?.studentLoanDebt
                      ?.message
                  }
                >
                  <Radio
                    label="Unknown"
                    value="null"
                    currentValue={getValues(
                      'patch.taxFileNumberDeclaration.studentLoanDebt',
                    )}
                  />
                  <Radio
                    label="Yes"
                    value="true"
                    currentValue={getValues(
                      'patch.taxFileNumberDeclaration.studentLoanDebt',
                    )}
                  />
                  <Radio
                    label="No"
                    value="false"
                    currentValue={getValues(
                      'patch.taxFileNumberDeclaration.studentLoanDebt',
                    )}
                  />
                </RadioGroup>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Block>
    </form>
  );
};

export default MemberUpdateTaxFileDeclarationDetailsForm;

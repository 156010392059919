import {
  Box,
  createStyles,
  FormControl,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { useAuth } from '@timed/auth';
import {
  Chip,
  ChipProps,
  formatPersonName,
  roundNumber,
  Select,
  SelectMenuItem,
  SelectProps,
} from '@timed/common';
import {
  Client,
  GetEventsPotentialAttendeesQuery,
  MemberWorkRights,
  useGetEventsPotentialAttendeesLazyQuery,
  useGetOrgScheduleLatenessSettingsQuery,
} from '@timed/gql';
import { ScheduleContextType } from '@timed/schedule';
import clsx from 'clsx';
import {
  addDays,
  differenceInHours,
  eachDayOfInterval,
  formatISO,
  isSaturday,
  isSunday,
  startOfWeek,
} from 'date-fns';
import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { UseFormWatch } from 'react-hook-form';

type EventMemberInputProps = Omit<SelectProps, 'items'> & {
  chipProps: ChipProps;
  watch: UseFormWatch<any>;
  clientId: Client['id'];
  startAt: Date;
  endAt: Date;
  requirements?: { car?: boolean };
  setTime: ScheduleContextType['setTime'];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 185,
      backgroundColor: 'white',
    },
    loading: {
      '& .MuiOutlinedInput-root.Mui-disabled': {
        backgroundColor: theme.palette.background.default,
      },
    },
    select: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    item: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      gap: theme.spacing(0),
      '& .MuiTypography-root': {
        [theme.breakpoints.up('md')]: {
          lineHeight: 1.1,
        },
      },
    },
    itemWithError: {
      backgroundColor: theme.palette.background.paper2,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      height: 18,
    },
    chip: {
      // marginLeft: theme.spacing(0.5),
      height: 20,
    },

    bold: {
      fontWeight: theme.typography.fontWeightBold,
    },

    unavailable: {
      // color: theme.palette.error.main,
    },

    available: {
      color: 'green',
    },

    description: {
      '& .MuiTypography-root': {
        fontSize: 10.5,
      },
    },
    name: {
      fontSize: 13,
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
    strike: {
      textDecoration: 'line-through',
      color: theme.palette.text.disabled,
    },
  }),
);

type ShiftType = 'saturday' | 'sunday' | 'other'; // | 'public-holiday'

function EventMemberInput({
  chipProps,
  type,
  watch,
  clientId,
  startAt,
  endAt,
  requirements,
  setTime,
  ...props
}: EventMemberInputProps) {
  const classes = useStyles();

  const { permissible } = useAuth();

  const watched = watch(props.name);

  const [getAttendees, { loading, data }] =
    useGetEventsPotentialAttendeesLazyQuery({
      fetchPolicy: 'no-cache',
    });

  const settingsResponse = useGetOrgScheduleLatenessSettingsQuery({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const payrollPeriodStartAt = startOfWeek(new Date(startAt), {
      weekStartsOn: 1,
    });

    const payrollPeriodEndAt = addDays(new Date(payrollPeriodStartAt), 6);

    getAttendees({
      variables: {
        input: {
          clientId,
          startAt: setTime(new Date(startAt), true),
          endAt: setTime(new Date(endAt), true),
        },
        scheduleInput: {
          startAt: formatISO(payrollPeriodStartAt, { representation: 'date' }),
          endAt: formatISO(payrollPeriodEndAt, { representation: 'date' }),
        },
      },
    });
  }, [getAttendees, startAt, endAt, clientId, setTime]);

  const profiles = data?.eventPotentialAttendees.members;

  const selected = profiles?.find(({ id }) => id === watched);

  const formattedProfiles:
    | {
        item: SelectMenuItem;
        profile: GetEventsPotentialAttendeesQuery['eventPotentialAttendees']['members'][0];
        errorsExist: boolean;
      }[]
    | undefined = profiles?.map((p) => {
    const messages: {
      text: string;
      color?: 'error' | 'warn' | 'success';
      disabled?: boolean;
    }[] = [];

    const hours =
      (data?.eventPotentialAttendees.hours.find(({ id }) => id === p.id)
        ?.thisWeek ?? 0) / 60;

    const scheduledHours = p.schedule.allowedMinutes / 60;

    const oversheduled =
      (data?.eventPotentialAttendees.hours.find(({ id }) => id === p.id)
        ?.thisWeek ?? 0) >= p.schedule.allowedMinutes;

    const clockdata = data?.eventPotentialAttendees.members.find(
      ({ id }) => id === p.id,
    )!.lateCount;

    const team = data?.eventPotentialAttendees.teams.find(
      ({ id }) => id === p.id,
    );

    const conflicts = data?.eventPotentialAttendees.conflicts.filter(
      ({ id }) => id === p.id,
    );

    const unavailable = !!data?.eventPotentialAttendees.unavailables.find(
      ({ id }) => id === p.id,
    );

    if (team && team.preferred)
      messages.push({
        color: 'success',
        text: 'On preferred list',
      });

    if (
      data?.eventPotentialAttendees.members.find(({ id }) => id === p.id)
        ?.workRights === MemberWorkRights.NIL
    )
      messages.push({
        color: 'error',
        text: 'Missing work rights',
        disabled: true,
      });

    if (team && !team.preferred)
      messages.push({
        color: 'error',
        text: 'On block list',
        disabled: !permissible({ admin: true }),
      });

    if (!!conflicts?.length)
      messages.push({
        color: 'error',
        text:
          'Already scheduled with ' +
          formatPersonName(conflicts[0].client, {
            capitaliseLastName: true,
            lastNameFirst: true,
          }),
      });

    if (unavailable)
      messages.push({
        color: 'error',
        text: 'Unavailable',
        disabled: !permissible({ admin: true }),
      });

    if (differenceInHours(endAt, startAt) + hours >= 48)
      messages.push({
        color: 'warn',
        text: 'Working too many hours',
        disabled: !permissible({ admin: true }),
      });

    if (!p.hasCar && requirements?.car)
      messages.push({
        color: 'error',
        text: 'Does not have a car',
        disabled: !permissible({ admin: true }),
      });

    const latePercentage =
      !!clockdata && clockdata[0] !== undefined && !!clockdata[1]
        ? (clockdata[0] / clockdata[1]) * 100
        : 0;

    const datesInEvent = eachDayOfInterval({
      start: new Date(startAt),
      end: new Date(endAt),
    }).filter((d) => !isEqual(new Date(endAt), d));

    const shiftType: ShiftType = datesInEvent.some((d) => isSunday(d))
      ? 'sunday'
      : datesInEvent.some((d) => isSaturday(d))
      ? 'saturday'
      : 'other';

    const allowedLateness =
      shiftType === 'sunday'
        ? settingsResponse.data?.me.member?.org
            .maxMemberLatenessForAutomaticSundayEvents ?? 100
        : shiftType === 'saturday'
        ? settingsResponse.data?.me.member?.org
            .maxMemberLatenessForAutomaticSaturdayEvents ?? 100
        : 100;

    if (latePercentage > allowedLateness)
      messages.push({
        color: 'error',
        text: `Late too often for this shift type`,
        disabled: !permissible({ admin: true }),
      });

    return {
      errorsExist: messages.some(
        ({ color }) => color === 'error' || color === 'warn',
      ),
      profile: p,
      item: {
        value: p.id,
        disabled: !!messages.filter(({ disabled }) => disabled).length,
        label: (
          <Box
            className={
              messages.filter(
                ({ color }) => color === 'error' || color === 'warn',
              ).length
                ? clsx(classes.item, classes.itemWithError)
                : classes.item
            }
          >
            <Typography
              variant="body1"
              className={
                messages.filter(
                  ({ color }) => color === 'error' || color === 'warn',
                ).length
                  ? clsx(classes.name, classes.strike)
                  : classes.name
              }
            >
              {
                formatPersonName(p, {
                  capitaliseLastName: true,
                  lastNameFirst: true,
                })!
              }
              {p.preferredName !== p.firstName && (
                <> ({formatPersonName(p, { preferred: true })})</>
              )}
              {p.bonusEligible && permissible({ tester: true }) && (
                <span title="Bonus pay multiplier eligible">b</span>
              )}
              {p.hasCar && <DirectionsCarIcon fontSize="small" />}
            </Typography>

            <Typography className={classes.description}>
              {p.locality && p.region && (
                <Typography variant="body2" color="textSecondary">
                  {p.locality + ' ' + p.region}
                </Typography>
              )}
              {hours > 0 && (
                <Typography
                  variant="body2"
                  color={oversheduled ? 'error' : 'textSecondary'}
                  style={{ marginLeft: 16 }}
                >
                  {`${roundNumber(hours, 2)}/${roundNumber(
                    scheduledHours,
                    2,
                  )} hours scheduled this week`}
                </Typography>
              )}
              {!!clockdata && clockdata[0] !== undefined && !!clockdata[1] && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{
                    marginLeft: 16,
                    color:
                      clockdata[0] / clockdata[1] > 0.9
                        ? 'red'
                        : clockdata[0] / clockdata[1] > 0.3
                        ? 'brown'
                        : 'inherit',
                  }}
                >
                  {`Clocked late ${clockdata[0]}/${
                    clockdata[1]
                  } times (${roundNumber(
                    (clockdata[0] / clockdata[1]) * 100,
                    0,
                  )}%)`}
                </Typography>
              )}
              {messages.map(({ text, color }) => (
                <Typography
                  variant="body2"
                  style={{
                    marginLeft: 16,
                    color:
                      color === 'success'
                        ? 'green'
                        : color === 'error'
                        ? 'red'
                        : color === 'warn'
                        ? 'brown'
                        : 'inherit',
                  }}
                >
                  {text}
                </Typography>
              ))}
            </Typography>
          </Box>
        ),
      },
    };
  });

  return loading ? (
    <FormControl className={classes.loading}>
      <TextField
        disabled
        label={type}
        variant={props.variant || 'outlined'}
        size="small"
        value="Loading..."
        className={props.className}
      />
    </FormControl>
  ) : (
    <Select
      items={
        !!formattedProfiles?.length
          ? [
              { label: 'Unattended', value: 0 },
              ...formattedProfiles
                ?.sort((a, b) =>
                  (a.errorsExist && b.errorsExist) ||
                  (!a.errorsExist && !b.errorsExist)
                    ? a.profile.lastName?.localeCompare(
                        b.profile.lastName ?? '',
                      ) ?? 0
                    : a.errorsExist
                    ? 1
                    : 0,
                )
                .map(({ item }) => item),
            ]
          : []
      }
      renderValue={() =>
        selected && (
          <div className={classes.chips}>
            <Chip
              size="small"
              color="primary"
              label={formatPersonName(selected!, {
                capitaliseLastName: true,
                lastNameFirst: true,
              })}
              className={classes.chip}
              onMouseDown={(event: any) => event.stopPropagation()}
              {...chipProps}
            />
          </div>
        )
      }
      {...props}
    />
  );
}

export default EventMemberInput;

import { TextField, TextFieldProps } from '@timed/common';
import { emailRegex } from '@timed/common/regexps';
import { RegisterOptions } from 'react-hook-form';

export const validateEmail: RegisterOptions = {
  pattern: { value: emailRegex, message: 'Invalid email address' },
};

const EmailInput = (props: TextFieldProps) => (
  <TextField label="Email" type="email" validation={validateEmail} {...props} />
);

export default EmailInput;

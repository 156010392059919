import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { IconButton } from '@timed/common';
import { Event, useVerifyEventsMutation } from '@timed/gql';
import { isAfter } from 'date-fns';

type EventSummaryModalVerifyButtonProps = {
  event: Pick<Event, 'id' | 'endAt' | 'verified'>;
  closeSummaryModal: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 10,
      '& .MuiSvgIcon-root': {
        fontSize: 12,
      },
    },
  }),
);

const EventSummaryModalVerifyButton = ({
  event,
  closeSummaryModal,
}: EventSummaryModalVerifyButtonProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const [verifyEvent] = useVerifyEventsMutation();

  const handleClick = () => {
    verifyEvent({
      variables: { input: { ids: [event.id], verified: !event.verified } },
    }).then(({ data }) => {
      if (data?.verifyEvents[0].verified)
        setTimeout(() => closeSummaryModal(), 100);
    });
  };

  return isAfter(new Date(), new Date(event.endAt)) ? (
    <IconButton
      aria-label="menu"
      onClick={handleClick}
      style={event.verified ? { color: theme.palette.success.dark } : undefined}
    >
      <span className={classes.wrapper}>
        {event.verified ? <CheckIcon /> : <ClearIcon />}
        <span>{event.verified ? 'Verified' : 'Unverified'}</span>
      </span>
    </IconButton>
  ) : null;
};

export default EventSummaryModalVerifyButton;

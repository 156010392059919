import { Select, SelectProps } from '@timed/common';
import { ClientFileType } from '@timed/gql';
import { clientFilesMetadata } from '@timed/client';
import { validateFileType } from '@timed/file';

const ClientFileTypeInput = ({
  defaultValue,
  ...props
}: Omit<SelectProps, 'items'>) => (
  <Select
    label="Category"
    validation={validateFileType('client')}
    items={Object.values(ClientFileType)
      .map((name) => {
        return {
          value: name,
          label: clientFilesMetadata.find((meta) => meta.id === name)!.name,
        };
      })
      .sort((a, b) => (a.label > b.label ? 1 : -1))}
    renderValue={(value) =>
      clientFilesMetadata.find((meta) => meta.id === value)?.name
    }
    {...props}
  />
);

export default ClientFileTypeInput;

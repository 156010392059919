import { _uploadsProcess } from '@timed/app';
import { Protected } from '@timed/auth';
import { Page } from '@timed/page';
import { UploadFormsTable } from '@timed/upload';

const RouteCoreSupportUploadsForms = () => {
  return (
    <Page {..._uploadsProcess}>
      <Protected tester>
        <span style={{ fontWeight: 'bold', color: 'red' }}>
          TESTER ONLY W.I.P
        </span>
      </Protected>
      <UploadFormsTable />
    </Page>
  );
};

export default RouteCoreSupportUploadsForms;

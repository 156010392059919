import { useAuth } from '@timed/auth/hooks';
import { Link, useRouter } from '@timed/common';
import { PropsWithChildren } from 'react';

type AuthLogoutLinkProps = PropsWithChildren<{}>;

const AuthLogoutLink = ({ children }: AuthLogoutLinkProps) => {
  const { pathname } = useRouter();
  const { logout } = useAuth();

  return (
    <Link
      to={'sign-in' + (pathname === '/' ? '' : '?ref=' + pathname)}
      onClick={logout}
    >
      {children}
    </Link>
  );
};

export default AuthLogoutLink;

import { Checkbox, CheckboxProps } from '@timed/common';

const ClientAllowMedicationCheckbox = (props: CheckboxProps) => (
  <Checkbox
    defaultChecked={false}
    label="Enable the recording of medication"
    {...props}
  />
);

export default ClientAllowMedicationCheckbox;

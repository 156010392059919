import {
  createStyles,
  FormControl,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import { ChipProps, intersperse, Select, SelectProps } from '@timed/common';
import {
  BranchesOrderByInput,
  BranchesWhereInput,
  useGetBranchesQuery,
} from '@timed/gql';
import { isArray } from 'lodash';
import { useMemo } from 'react';
import { UseFormWatch } from 'react-hook-form';

type BranchSelectInputProps = Omit<SelectProps, 'items'> & {
  chipProps?: ChipProps;
  watch: UseFormWatch<any>;
  where?: BranchesWhereInput;
  orderBy?: BranchesOrderByInput[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 185,
      backgroundColor: 'white',
    },
    loading: {
      '& .MuiOutlinedInput-root.Mui-disabled': {
        backgroundColor: theme.palette.background.default,
      },
    },
    select: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    chips: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
  }),
);

const BranchSelectInput = ({
  chipProps,
  type,
  where,
  orderBy,
  watch,
  multiple,
  ...props
}: BranchSelectInputProps) => {
  const classes = useStyles();

  const watched = watch(props.name);

  const { data, loading } = useGetBranchesQuery({
    variables: { input: { where, orderBy } },
  });

  const selected = useMemo(() => {
    return data?.branches.filter(({ id }) =>
      isArray(watched) ? watched.includes(id) : watched === id,
    );
  }, [data, watched]);

  const items = !!data
    ? data.branches.map((b) => {
        return { value: b.id!, label: b.name };
      })
    : undefined;

  return loading ? (
    <FormControl className={classes.loading}>
      <TextField
        disabled
        label={type}
        variant="outlined"
        size="small"
        value="Loading..."
      />
    </FormControl>
  ) : (
    <Select
      multiple={multiple}
      items={items}
      renderValue={() =>
        intersperse({
          array: !!selected?.length ? selected.map(({ name }) => name) : [],
        })
      }
      {...props}
    />
  );
};

export default BranchSelectInput;

import { useAuth } from '@timed/auth';
import { PageMetadata, usePage } from '@timed/page';
import { RouteErrorForbidden } from '@timed/routes';
import { useEffect } from 'react';

type PageProps = React.PropsWithChildren<PageMetadata>;

const Page = ({
  /**
   * Note: Cannot use a spred operator on props in useEffect() dependency arrays as it would
   * cause infinite loops.
   */
  children,
  admin,
  permissions,
  label,
  path,
  redirect,
  overruleRedirect,
}: PageProps) => {
  const { permissible } = useAuth();
  const { addPage, saveRedirect } = usePage();

  useEffect(
    () => addPage({ admin, permissions, path, redirect, label }),
    [addPage, admin, permissions, path, redirect, label],
  );

  useEffect(() => {
    if (!!overruleRedirect) saveRedirect(path);
  }, [saveRedirect, overruleRedirect, path]);

  /**
   * This check will only prevent child nodes from rendering. To prevent
   * page metadata from being processed, PageProvider will also check permissions.
   */
  return (admin || permissions) && !permissible({ admin, permissions }) ? (
    <RouteErrorForbidden />
  ) : (
    <>{children}</>
  );
};

export default Page;

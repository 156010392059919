import { _peopleMembers } from '@timed/app';
import { ModuleType } from '@timed/gql';
import { MemberList, MemberListProvider } from '@timed/member';
import { Page } from '@timed/page';

const RouteSupportCoordinationPeopleEmployees = () => {
  return (
    <Page {..._peopleMembers}>
      <MemberListProvider module={ModuleType.SC}>
        <MemberList />
      </MemberListProvider>
    </Page>
  );
};

export default RouteSupportCoordinationPeopleEmployees;

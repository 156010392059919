import {
  Radio as MuiRadio,
  FormControlLabel,
  FormControlLabelProps,
  RadioProps as MuiRadioProps,
} from '@material-ui/core';

export type RadioProps = MuiRadioProps & {
  label?: string;
  formControlLabelProps?: Omit<FormControlLabelProps, 'control' | 'label'>;
  currentValue: unknown;
};

const Radio = ({
  label,
  value,
  currentValue,
  formControlLabelProps,
  ...props
}: RadioProps) => (
  <FormControlLabel
    value={value}
    checked={currentValue === value}
    label={label}
    control={<MuiRadio {...props} />}
    {...formControlLabelProps}
  />
);

export default Radio;

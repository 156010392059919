import {
  createStyles,
  FormControl,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { _schedule } from '@timed/app';
import { useRouter } from '@timed/common';
import { format, isValid, parse } from 'date-fns';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    datePicker: {
      '& .MuiInput-underline:hover:not(.Nui-disabled)::before': {
        borderBottom: 'none',
      },
      '& .MuiInput-underline::before': {
        borderBottom: 'none',
        transition: 'none',
      },
      '& .MuiInput-underline::after': {
        borderBottom: 'none',
        transition: 'none',
      },
      '& .MuiFormControl-root': {
        flexGrow: 1,
      },
    },
    input: {
      flex: '1 1 min-content',
      backgroundColor: theme.palette.background.default,
      userSelect: 'none',
      msUserSelect: 'none',
      MozUserSelect: 'none',
      WebkitUserSelect: 'none',
      cursor: 'pointer',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.divider,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': {
        width: 80,
        padding: theme.spacing(0, 3.5),
        [theme.breakpoints.down('sm')]: {
          width: 64,
          fontSize: 12,
          padding: theme.spacing(0, 2),
        },
      },
    },
    dialog: {
      '& .MuiPickersCalendarHeader-dayLabel': {
        color: theme.palette.text.secondary,
      },
    },
  }),
);

const ScheduleControlsDateInput = () => {
  const classes = useStyles();
  const {
    navigate,
    search: [searchParams],
  } = useRouter();

  const handleChange = (date: MaterialUiPickersDate) => {
    if (!isValid(date)) return;

    const formattedDate = format(date as Date, 'ddMMyyyy');

    if (searchParams.get('f') !== formattedDate) {
      searchParams.set('f', formattedDate);
      navigate(_schedule.path + '?' + searchParams);
    }
  };

  return (
    <FormControl className={classes.datePicker}>
      <DatePicker
        showTodayButton
        autoOk
        aria-label="date picker"
        inputVariant="outlined"
        size="small"
        format="dd/MM/yyyy"
        id="date-picker"
        value={
          searchParams.get('f') &&
          parse(searchParams.get('f')!, 'ddMMyyyy', new Date())
        }
        onChange={handleChange}
        inputProps={{ className: classes.input }}
        InputProps={{ className: classes.input }}
        DialogProps={{ className: classes.dialog }}
      />
    </FormControl>
  );
};

export default ScheduleControlsDateInput;

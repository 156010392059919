import {
  Box,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import {
  CurrencyTextField,
  FormModal,
  ModalProps,
  roundNumber,
} from '@timed/common';
import {
  ClientNdisPlanItem,
  UpdateClientNdisPlanItemInput,
  useUpdateClientNdisPlanItemMutation,
} from '@timed/gql';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        alignItems: 'start',
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
        flexDirection: 'column',
      },
    },
    input: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    textIconWrapper: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
  }),
);

type ClientUpdateNdisPlanItemFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  clientNdisPlanItem: Pick<ClientNdisPlanItem, 'id' | 'initialFunds'>;
};

type FormData = UpdateClientNdisPlanItemInput;

const ClientUpdateNdisPlanItemForm = ({
  onClose,
  clientNdisPlanItem,
  ...modalProps
}: ClientUpdateNdisPlanItemFormModalProps) => {
  const classes = useStyles();

  const [updateEntity, response] = useUpdateClientNdisPlanItemMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      patch: {
        initialFunds: roundNumber(clientNdisPlanItem.initialFunds / 100, 2),
      },
    },
  });

  const onSubmit = ({ patch }: FormData) => {
    if (!!patch.initialFunds)
      patch.initialFunds = roundNumber(
        (typeof patch.initialFunds === 'number'
          ? patch.initialFunds
          : Number(
              (patch.initialFunds as unknown as string).replace(/,/g, ''),
            )) * 100,
        0,
      );

    updateEntity({
      variables: {
        input: { id: clientNdisPlanItem.id, patch },
      },
    });
  };

  return (
    <FormModal
      modalProps={modalProps}
      title="Update NDIS Plan Item"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.input}>
          <Typography className={classes.bold}>Initial Funds</Typography>
          <CurrencyTextField
            name="patch.initialFunds"
            inputProps={{ style: { textAlign: 'right' } }}
            size="small"
            control={control}
            error={!!errors.patch?.initialFunds}
            helperText={errors.patch?.initialFunds?.message}
          />
        </Box>
      </Box>
    </FormModal>
  );
};

export default ClientUpdateNdisPlanItemForm;

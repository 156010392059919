import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  addServerErrors,
  DateInput,
  FormModal,
  ModalProps,
  NumberInput,
  roundNumber,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  transformNumberToFixedFloat,
} from '@timed/common';
import {
  Branch,
  MemberBranchAllocation,
  MemberBranchAllocationsUpdatableFieldsInput,
  useUpdateMemberBranchAllocationMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type MemberUpdateBranchAllocationFormModalProps = Omit<
  ModalProps,
  'children'
> & {
  onClose: () => void;
  branchAllocation: Pick<MemberBranchAllocation, 'id' | 'date' | 'values'>;
  branches: Pick<Branch, 'id' | 'name'>[];
};

type FormData = {
  patch: Omit<MemberBranchAllocationsUpdatableFieldsInput, 'values'> & {
    branches: { [k: string]: number };
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputs: {
      flex: '1 0 auto',
      overflowY: 'auto',
      display: 'grid',
      gridAutoFlow: 'row',
      gridAutoRows: 'max-content',
      gap: theme.spacing(4),
    },
  }),
);

const MemberUpdateBranchAllocationFormModal = ({
  branchAllocation,
  branches,
  onClose,
  ...modalProps
}: MemberUpdateBranchAllocationFormModalProps) => {
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      patch: {
        date: branchAllocation.date,
        branches: Object.fromEntries(
          branchAllocation.values.map(({ branchId, value }) => [
            branchId,
            roundNumber(value / 100, 2),
          ]),
        ),
      },
    },
  });

  const [updateEntity, response] = useUpdateMemberBranchAllocationMutation();

  useLoadingEffect(response.loading);

  const onSubmit = ({ patch: { branches, ...values } }: FormData) => {
    const patch = {
      ...values,
      values: Object.entries(branches)
        .filter(([_, v]) => !!v)
        .map(([k, v]) => ({
          branchId: k,
          value: roundNumber(v! * 100, 0),
        })),
    };

    console.log('patch', patch);

    updateEntity({
      variables: {
        input: {
          id: branchAllocation.id,
          patch,
        },
      },
    }).catch((e) => {});
  };

  /**
   * Display error messages from server response
   */
  useEffect(() => {
    if (response.error) addServerErrors(response.error, setError);
  }, [response.error, setError]);

  return (
    <FormModal
      modalProps={modalProps}
      title="Update Branch Allocation"
      saveText="Save"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
    >
      <Box className={classes.inputs}>
        {response.error && (
          <Typography color="error">{response.error.message}</Typography>
        )}
        <DateInput
          required
          keyboard
          disableTime
          label="Effective-from date"
          name="patch.date"
          control={control}
          inputVariant="outlined"
          size="small"
          error={!!errors.patch?.date}
          helperText={errors.patch?.date?.message}
        />
        <Table inline enableRowHighlighting>
          {branches.map(({ name }) => (
            <TableHeader>{name}</TableHeader>
          ))}
          <TableRow>
            {branches.map(({ id }) => (
              <TableCell>
                <NumberInput
                  name={`patch.branches.${id}`}
                  variant="outlined"
                  size="small"
                  label="Percent"
                  control={control}
                  transform={transformNumberToFixedFloat(2, 0, 10000)}
                  step="0.01"
                  min={0}
                  max={10000}
                />
              </TableCell>
            ))}
          </TableRow>
        </Table>
      </Box>
    </FormModal>
  );
};

export default MemberUpdateBranchAllocationFormModal;

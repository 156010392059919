import { RGB } from '@timed/common';

export const contrastingColor = (
  rgb: RGB | null,
  extremeOnly?: boolean,
): string | null => {
  if (!rgb) return null;
  let o = Math.round((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000);
  return extremeOnly
    ? o > 254
      ? '#000000'
      : '#ffffff'
    : o > 125
    ? '#000000'
    : '#ffffff';
};

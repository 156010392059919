import { TextField, TextFieldProps } from '@timed/common';
import { personNameRegex } from '@timed/common/regexps';
import { RegisterOptions } from 'react-hook-form';

export const validateBankAccountName: RegisterOptions = {
  pattern: {
    value: personNameRegex({ allowNumbers: true }),
    message: 'Invalid character(s)',
  },
  maxLength: { value: 40, message: 'Too long' },
};

const BankAccountNameInput = (props: TextFieldProps) => (
  <TextField type="text" validation={validateBankAccountName} {...props} />
);

export default BankAccountNameInput;

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ClientNdisPlanItemTransactionContext } from '@timed/client/components/NdisPlanItemTransactions/context';
import { Button } from '@timed/common';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      minWidth: 0,
      height: theme.spacing(10),
      border: '1px solid ' + theme.palette.divider,
      borderRadius: 0,
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.down('sm')]: {
        height: theme.spacing(8),
      },
      '& .MuiButton-label': {
        fontWeight: 'normal',
      },
    },
  }),
);

const ClientNdisPlanItemTransactionControlClearButton = () => {
  const classes = useStyles();

  const { reset } = useContext(ClientNdisPlanItemTransactionContext);

  return (
    <Button
      variant="contained"
      className={classes.button}
      onClick={() => reset()}
    >
      Clear Filters
    </Button>
  );
};

export default ClientNdisPlanItemTransactionControlClearButton;

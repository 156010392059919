import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { blue } from '@mui/material/colors';
import { Button } from '@timed/common';
import { pluralise } from '@timed/common/utils/pluralise';
import { NotesContext } from '@timed/notes';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      backgroundColor: blue[100],
      padding: theme.spacing(2),
    },
  }),
);

const PaginationStatus = () => {
  const classes = useStyles();

  const { selectNotes, selectedNotes } = useContext(NotesContext);

  if (!selectedNotes.length) return null;

  return (
    <Typography className={classes.wrapper}>
      {selectedNotes.length}{' '}
      {pluralise({
        quantity: selectedNotes.length,
        singular: 'item',
        plural: 'items',
      })}{' '}
      selected
      <Button
        variant="outlined"
        onClick={() => {
          selectNotes({ ids: selectedNotes, type: 'remove' });
        }}
      >
        {pluralise({
          quantity: selectedNotes.length,
          singular: 'Deselect',
          plural: 'Deselect All',
        })}
      </Button>
    </Typography>
  );
};

export default PaginationStatus;

import { _start } from '@timed/app/constants';
import { AuthProvider } from '@timed/auth';
import { ClientProvider } from '@timed/client';
import { ModuleType } from '@timed/gql';
import { MemberProvider } from '@timed/member';
import { ModuleContext } from '@timed/module';
import {
  RouteAuthenticated,
  RouteCoreSupportIdeas,
  RouteCoreSupportNotes,
  RouteCoreSupportNotesImport,
  RouteCoreSupportPeople,
  RouteCoreSupportPeopleClientImport,
  RouteCoreSupportPeopleEmployees,
  RouteCoreSupportPeopleEmployeesProfile,
  RouteCoreSupportPeopleEmployeesProfileCategory,
  RouteCoreSupportPeopleMemberImport,
  RouteCoreSupportPeopleParticipants,
  RouteCoreSupportPeopleParticipantsProfile,
  RouteCoreSupportPeopleParticipantsProfileCategory,
  RouteCoreSupportPeoplePlanManagers,
  RouteCoreSupportPeopleSupportCoordinators,
  RouteCoreSupportPeopleSupportCoordinatorsProfile,
  RouteCoreSupportPeopleSupportCoordinatorsProfileCategory,
  RouteCoreSupportReports,
  RouteCoreSupportSchedule,
  RouteCoreSupportShifts,
  RouteCoreSupportShiftsAdmin,
  RouteCoreSupportShiftsAdminApprove,
  RouteCoreSupportShiftsAdminMissingNotes,
  RouteCoreSupportShiftsAdminMissingSupportWorker,
  RouteCoreSupportShiftsAdminTravel,
  RouteCoreSupportShiftsPersonal,
  RouteCoreSupportShiftsPersonalClaim,
  RouteCoreSupportShiftsPersonalGrab,
  RouteCoreSupportShiftsPersonalUnavailability,
  RouteCoreSupportShiftsPersonalUpcoming,
  RouteCoreSupportStart,
  RouteCoreSupportUploads,
  RouteCoreSupportUploadsForms,
  RouteCoreSupportUploadsOverview,
  RouteCoreSupportUploadsProcess,
  RouteErrorNotFound,
  RouteInvite,
  RouteJoin,
  RouteSettings,
  RouteSettingsOrganisation,
  RouteSettingsOrganisationBilling,
  RouteSettingsOrganisationBranches,
  RouteSettingsOrganisationMedication,
  RouteSettingsOrganisationPayroll,
  RouteSettingsOrganisationScheduling,
  RouteSettingsOrganisationVisaTypes,
  RouteSettingsPersonal,
  RouteSettingsPersonalAccount,
  RouteSetup,
  RouteSignIn,
  RouteSupport,
  RouteSupportContact,
  RouteSupportCoordinationActivities,
  RouteSupportCoordinationPeople,
  RouteSupportCoordinationPeopleEmployees,
  RouteSupportCoordinationPeopleEmployeesProfile,
  RouteSupportCoordinationPeopleEmployeesProfileCategory,
  RouteSupportCoordinationPeopleParticipants,
  RouteSupportCoordinationPeopleParticipantsProfile,
  RouteSupportCoordinationPeopleParticipantsProfileCategory,
  RouteSupportDebug,
  RouteSupportHelp,
  RouteVerifyProcessToken,
  RouteVerifyResendToken,
} from '@timed/routes';
import { SupportCoordinatorProvider } from '@timed/support-coordinator';
import { ReactElement, useContext } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

const App = () => {
  const { activeModule } = useContext(ModuleContext);

  let moduleRoutes: ReactElement = <></>;

  switch (activeModule) {
    case ModuleType.CS:
      moduleRoutes = (
        <>
          {/**
           * Home
           */}
          <Route path={_start.path} element={<RouteCoreSupportStart />} />

          {/**
           * Schedule
           */}
          <Route path="schedule" element={<RouteCoreSupportSchedule />} />

          {/**
           * Shifts
           */}
          <Route path="shifts" element={<RouteCoreSupportShifts />}>
            <Route path="personal" element={<RouteCoreSupportShiftsPersonal />}>
              <Route
                path="upcoming"
                element={<RouteCoreSupportShiftsPersonalUpcoming />}
              />
              <Route
                path="claim"
                element={<RouteCoreSupportShiftsPersonalClaim />}
              />
              <Route
                path="grab"
                element={<RouteCoreSupportShiftsPersonalGrab />}
              />
              <Route
                path="unavailability"
                element={<RouteCoreSupportShiftsPersonalUnavailability />}
              />
            </Route>
            <Route path="admin" element={<RouteCoreSupportShiftsAdmin />}>
              <Route
                path="missing-notes"
                element={<RouteCoreSupportShiftsAdminMissingNotes />}
              />
              <Route
                path="missing-support-worker"
                element={<RouteCoreSupportShiftsAdminMissingSupportWorker />}
              />
              <Route
                path="approve"
                element={<RouteCoreSupportShiftsAdminApprove />}
              />
              <Route
                path="travel"
                element={<RouteCoreSupportShiftsAdminTravel />}
              />
            </Route>
          </Route>

          {/**
           * People
           */}
          <Route path="people" element={<RouteCoreSupportPeople />}>
            <Route path="employees" element={<Outlet />}>
              <Route
                path="import"
                element={<RouteCoreSupportPeopleMemberImport />}
              />
              <Route path="" element={<RouteCoreSupportPeopleEmployees />} />
              <Route
                path=":member"
                element={
                  <MemberProvider>
                    <RouteCoreSupportPeopleEmployeesProfile />
                  </MemberProvider>
                }
              >
                <Route
                  path=":category"
                  element={<RouteCoreSupportPeopleEmployeesProfileCategory />}
                />
                <Route
                  path=":category/:subcategory"
                  element={<RouteCoreSupportPeopleEmployeesProfileCategory />}
                />
              </Route>
            </Route>

            <Route path="participants" element={<Outlet />}>
              <Route
                path="import"
                element={<RouteCoreSupportPeopleClientImport />}
              />
              <Route path="" element={<RouteCoreSupportPeopleParticipants />} />
              <Route
                path=":client"
                element={
                  <ClientProvider>
                    <RouteCoreSupportPeopleParticipantsProfile />
                  </ClientProvider>
                }
              >
                <Route
                  path=":category"
                  element={
                    <RouteCoreSupportPeopleParticipantsProfileCategory />
                  }
                />
                <Route
                  path=":category/:subcategory"
                  element={
                    <RouteCoreSupportPeopleParticipantsProfileCategory />
                  }
                />
              </Route>
            </Route>

            <Route path="support-coordinators" element={<Outlet />}>
              <Route
                path=""
                element={<RouteCoreSupportPeopleSupportCoordinators />}
              />
              <Route
                path=":supportCoordinator"
                element={
                  <SupportCoordinatorProvider>
                    <RouteCoreSupportPeopleSupportCoordinatorsProfile />
                  </SupportCoordinatorProvider>
                }
              >
                <Route
                  path=":category"
                  element={
                    <RouteCoreSupportPeopleSupportCoordinatorsProfileCategory />
                  }
                />
              </Route>
            </Route>

            <Route path="plan-managers" element={<Outlet />}>
              <Route path="" element={<RouteCoreSupportPeoplePlanManagers />} />
            </Route>
          </Route>

          {/**
           * Notes
           */}
          <Route path="notes" element={<Outlet />}>
            <Route path="" element={<RouteCoreSupportNotes />} />
            <Route path="import" element={<RouteCoreSupportNotesImport />} />
          </Route>

          {/**
           * Reports
           */}
          <Route path="reports" element={<RouteCoreSupportReports />}>
            <Route path=":category" element={<RouteCoreSupportReports />} />
          </Route>

          {/**
           * Suggestions
           */}
          <Route path="ideas" element={<RouteCoreSupportIdeas />} />

          {/**
           * Upload Centre route
           */}
          <Route path="uploads" element={<RouteCoreSupportUploads />}>
            <Route
              path="overview"
              element={<RouteCoreSupportUploadsOverview />}
            />
            <Route
              path="process"
              element={<RouteCoreSupportUploadsProcess />}
            />
            <Route path="forms" element={<RouteCoreSupportUploadsForms />} />
          </Route>
        </>
      );
      break;

    case ModuleType.SC:
      moduleRoutes = (
        <>
          {/**
           * Activities
           */}
          <Route
            path="activities"
            element={<RouteSupportCoordinationActivities />}
          />

          {/**
           * People
           */}
          <Route path="people" element={<RouteSupportCoordinationPeople />}>
            <Route path="employees" element={<Outlet />}>
              <Route
                path=""
                element={<RouteSupportCoordinationPeopleEmployees />}
              />
              <Route
                path=":member"
                element={
                  <MemberProvider>
                    <RouteSupportCoordinationPeopleEmployeesProfile />
                  </MemberProvider>
                }
              >
                <Route
                  path=":category"
                  element={
                    <RouteSupportCoordinationPeopleEmployeesProfileCategory />
                  }
                />
                <Route
                  path=":category/:subcategory"
                  element={
                    <RouteSupportCoordinationPeopleEmployeesProfileCategory />
                  }
                />
              </Route>
            </Route>

            <Route path="participants" element={<Outlet />}>
              <Route
                path=""
                element={<RouteSupportCoordinationPeopleParticipants />}
              />
              <Route
                path=":client"
                element={
                  <ClientProvider>
                    <RouteSupportCoordinationPeopleParticipantsProfile />
                  </ClientProvider>
                }
              >
                <Route
                  path=":category"
                  element={
                    <RouteSupportCoordinationPeopleParticipantsProfileCategory />
                  }
                />
                <Route
                  path=":category/:subcategory"
                  element={
                    <RouteSupportCoordinationPeopleParticipantsProfileCategory />
                  }
                />
              </Route>
            </Route>
          </Route>
        </>
      );
      break;
  }

  return (
    <Routes>
      <Route path="sign-in" element={<RouteSignIn />} />
      <Route path="join" element={<RouteJoin />} />
      <Route path="verify/resend/:type" element={<RouteVerifyResendToken />} />
      <Route path="verify/:token" element={<RouteVerifyProcessToken />} />

      {/**
       * Authenticated route (child routes require the user to the logged in)
       */}
      <Route
        path="/"
        element={
          <AuthProvider>
            <RouteAuthenticated />
          </AuthProvider>
        }
      >
        {/**
         * Module specific
         */}
        {moduleRoutes}

        {/**
         * Not Found
         */}
        <Route path="*" element={<RouteErrorNotFound />} />

        {/**
         * Setup
         */}
        <Route path="setup" element={<RouteSetup />} />

        {/**
         * Invitation.
         */}
        <Route path="invite/:token" element={<RouteInvite />} />

        {/**
         * Notifications
         */}
        {/* <Route path="notifications" element={<Notifications />} /> */}

        {/**
         * Settings
         */}
        <Route path="settings" element={<RouteSettings />}>
          <Route path="personal" element={<RouteSettingsPersonal />}>
            <Route path="account" element={<RouteSettingsPersonalAccount />} />
          </Route>
          <Route path="organisation" element={<RouteSettingsOrganisation />}>
            <Route
              path="branches"
              element={<RouteSettingsOrganisationBranches />}
            />
            <Route
              path="scheduling"
              element={<RouteSettingsOrganisationScheduling />}
            />
            <Route
              path="visa-types"
              element={<RouteSettingsOrganisationVisaTypes />}
            />
            <Route
              path="payroll"
              element={<RouteSettingsOrganisationPayroll />}
            />
            <Route
              path="billing"
              element={<RouteSettingsOrganisationBilling />}
            />
            <Route
              path="medication"
              element={<RouteSettingsOrganisationMedication />}
            />
          </Route>
        </Route>

        {/**
         * Support
         */}
        <Route path="support" element={<RouteSupport />}>
          <Route path="help" element={<RouteSupportHelp />} />
          <Route path="contact" element={<RouteSupportContact />} />
          <Route path="debug" element={<RouteSupportDebug />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;

import {
  Box,
  ClickAwayListener,
  createStyles,
  Fade,
  makeStyles,
  Popper,
  Theme,
  Typography,
} from '@material-ui/core';
import { green, yellow } from '@material-ui/core/colors';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import { _schedule } from '@timed/app';
import {
  Button,
  formatPersonName,
  intersperse,
  useRouter,
} from '@timed/common';
import { pluralise } from '@timed/common/utils/pluralise';
import { ScheduleContext } from '@timed/schedule/context';
import { setProfile } from '@timed/schedule/helpers';
import clsx from 'clsx';
import {
  addDays,
  format,
  isAfter,
  isBefore,
  isEqual,
  isToday,
  startOfTomorrow,
  startOfWeek,
  subWeeks,
} from 'date-fns';
import { isTomorrow } from 'date-fns/esm';
import { useContext, useMemo, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      cursor: 'default',
      padding: theme.spacing(1.75),
      backgroundColor: green[50],
      border: '1px solid ' + theme.palette.success.main,
      borderRadius: 0,
      textAlign: 'start',
      textTransform: 'none',
      '& .MuiButton-label': {
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'start',
      },
      '&:hover': {
        backgroundColor: green[100],
      },
      '& .MuiTypography-root': {
        fontSize: 10,
        lineHeight: 1.1,
      },
      '& .MuiTypography-root:first-child': {
        fontSize: 11,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    warning: {
      cursor: 'pointer',
      backgroundColor: yellow[50],
      border: '1px solid ' + theme.palette.warning.main,
      '&:hover': {
        backgroundColor: yellow[200],
      },
    },
    list: {
      maxHeight: 200,
      overflowY: 'auto',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
    },
    item: {
      padding: theme.spacing(0.5),
      backgroundColor: yellow[50],
      borderLeft: '1px solid ' + theme.palette.warning.main,
      borderRight: '1px solid ' + theme.palette.warning.main,
      borderBottom: '1px solid ' + theme.palette.warning.main,
      '&:hover': {
        backgroundColor: yellow[200],
      },
      '& .MuiTypography-root': {
        fontSize: 10,
      },
      '& .MuiTypography-root:first-child': {
        fontSize: 11,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  }),
);

const ScheduleUnfilledEvents = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const open = Boolean(anchorEl);

  const {
    navigate,
    search: [searchParams],
  } = useRouter();

  const {
    lists: { unallocatedEvents },
  } = useContext(ScheduleContext);

  /**
   * Toggle menu display
   */
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (unallocatedEvents?.length)
      setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  /**
   * Close menu
   */
  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    setAnchorEl(null);
  };

  const [unfilledToday, unfilledTomorrow, unfilledOtherDays] = useMemo<
    [number, number, number]
  >(() => {
    if (!unallocatedEvents?.length) return [0, 0, 0];

    const today = unallocatedEvents.filter((event) =>
      isBefore(new Date(event.startAt), startOfTomorrow()),
    );

    const tomorrow = unallocatedEvents.filter(
      (event) =>
        (isAfter(new Date(event.startAt), startOfTomorrow()) ||
          isEqual(new Date(event.startAt), startOfTomorrow())) &&
        isBefore(new Date(event.startAt), addDays(startOfTomorrow(), 1)),
    );

    const otherDays = unallocatedEvents.filter(
      (event) =>
        isAfter(new Date(event.startAt), addDays(startOfTomorrow(), 1)) ||
        isEqual(new Date(event.startAt), addDays(startOfTomorrow(), 1)),
    );

    return [today.length, tomorrow.length, otherDays.length];
  }, [unallocatedEvents]);

  const message = useMemo<string | undefined>(() => {
    if (!!unallocatedEvents?.length) {
      let messages: string[] = [];

      if (!!unfilledToday) messages.push(`${unfilledToday} today`);
      if (!!unfilledTomorrow) messages.push(`${unfilledTomorrow} tomorrow`);
      if (!!unfilledOtherDays)
        messages.push(
          `${unfilledOtherDays} ${
            !!unfilledToday || !!unfilledTomorrow
              ? 'more'
              : pluralise({
                  quantity: unallocatedEvents.length,
                  plural: 'shifts',
                  singular: 'shift',
                })
          }`,
        );

      return intersperse({ array: messages, and: false });
    }
  }, [unallocatedEvents, unfilledToday, unfilledTomorrow, unfilledOtherDays]);

  const handleClickLink = (clientId: string, startAt: Date) => {
    setAnchorEl(null);

    setProfile('client', searchParams, clientId);
    setProfile('member', searchParams, undefined);

    const formattedDate = format(
      subWeeks(startOfWeek(startAt, { weekStartsOn: 1 }), 1),
      'ddMMyyyy',
    );

    if (searchParams.get('f') !== formattedDate) {
      searchParams.set('f', formattedDate);
    }

    navigate(_schedule.path + '?' + searchParams);
  };

  return (
    <>
      <Button
        id="unfilled-shifts-menu-button"
        className={
          unallocatedEvents?.length
            ? clsx(classes.button, classes.warning)
            : classes.button
        }
        onClick={handleClick}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
      >
        {unallocatedEvents?.length ? (
          <ErrorRoundedIcon style={{ fill: yellow[800] }} />
        ) : (
          <CheckRoundedIcon style={{ fill: green[800] }} />
        )}
        <Box>
          <Typography noWrap>Unfilled</Typography>
          <Typography variant="body2">
            {!!unallocatedEvents?.length ? message : 'Checked 14 days'}
          </Typography>
        </Box>
      </Button>
      <Popper
        transition
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        style={{ zIndex: 1300 }}
        placement="bottom"
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <Box>
              <ClickAwayListener onClickAway={handleClose}>
                <Box
                  className={classes.list}
                  style={{
                    minWidth: document.getElementById(
                      'unfilled-shifts-menu-button',
                    )?.offsetWidth,
                  }}
                >
                  {unallocatedEvents?.map((event) => (
                    <Box
                      className={classes.item}
                      onClick={(e) => {
                        handleClickLink(
                          event.client.id,
                          new Date(event.startAt),
                        );
                        e.stopPropagation();
                      }}
                    >
                      <Typography noWrap>
                        {isToday(new Date(event.startAt))
                          ? 'Today'
                          : isTomorrow(new Date(event.startAt))
                          ? 'Tomorrow'
                          : format(new Date(event.startAt), 'EEE')}
                        {', '}
                        {format(new Date(event.startAt), 'd MMM')}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {formatPersonName(event.client, {
                          lastNameFirst: true,
                          preferredAndLast: true,
                        })}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </ClickAwayListener>
            </Box>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default ScheduleUnfilledEvents;

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { IconButton, intersperse, Select } from '@timed/common';
import { EventAttributes, ScheduleContext } from '@timed/schedule/context';
import { ChangeEvent, useContext } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
  eventAttributes?: EventAttributes[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      flexGrow: 1,
      minWidth: theme.spacing(30),
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.default,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.divider,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': {
        fontSize: '1rem',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(0),
          fontSize: 'initial',
        },
      },
    },
    values: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.between('md', 'xl')]: {
        lineHeight: 1,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
    control: {
      margin: theme.spacing(0, 2),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 0, 0, 1),
      },
    },
  }),
);

const ScheduleAttributesSelect = () => {
  const classes = useStyles();

  const { setEventAttributes } = useContext(ScheduleContext);

  const items: { label: string; value: EventAttributes }[] = [
    { label: 'No Notes', value: 'no-notes' },
    { label: 'Not Clocked', value: 'not-clocked' },
    { label: 'Occurs On Public Holiday', value: 'public-holiday' },
    { label: 'Participant Not Billable', value: 'client-not-billable' },
    { label: 'Employee Not Payable', value: 'member-not-payable' },
  ];

  const { control, setValue, watch } = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const eventAttributes = watch('eventAttributes');

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const where = {};

    const attributes = event.target.value as EventAttributes[];

    if (attributes.includes('no-notes'))
      Object.assign(where, { notes: { _eq: null } });

    if (attributes.includes('not-clocked'))
      Object.assign(where, {
        _or: [
          { clockedOnAt: { _eq: null } },
          { clockedOnAt: { _ne: null }, clockedOffAt: { _eq: null } },
        ],
      });

    if (attributes.includes('public-holiday'))
      Object.assign(where, { publicHoliday: { _eq: true } });

    if (attributes.includes('client-not-billable'))
      Object.assign(where, { billable: { _eq: false } });

    if (attributes.includes('member-not-payable'))
      Object.assign(where, { payable: { _eq: false } });

    setEventAttributes(where);
  };

  /**
   * Delete all attributes.
   */
  const handleClear = () => {
    setValue('eventAttributes', []);
    setEventAttributes();
  };

  return (
    <Select
      multiple
      name="eventAttributes"
      label="Attributes"
      control={control}
      defaultValue={[]}
      onChange={handleChange}
      className={classes.input}
      IconComponent={
        !!eventAttributes?.length
          ? () => (
              <IconButton
                size="small"
                className={classes.control}
                onClick={handleClear}
                onMouseDown={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              >
                <CloseRoundedIcon fontSize="small" />
              </IconButton>
            )
          : undefined
      }
      formControlProps={{
        variant: 'outlined',
        size: 'small',
      }}
      items={items}
      renderValue={(values) => (
        <Typography variant="body1" className={classes.values}>
          {intersperse({
            array: items
              .filter((item) =>
                (values as EventAttributes[]).includes(item.value),
              )
              .map((item) => item.label),
            and: false,
          })}
        </Typography>
      )}
    />
  );
};

export default ScheduleAttributesSelect;

import { Grid, Typography } from '@material-ui/core';
import { Protected } from '@timed/auth';
import { BranchSelectInput } from '@timed/branch';
import {
  addServerErrors,
  Block,
  IconButtonMulti,
  intersperse,
} from '@timed/common';
import {
  Branch,
  HistoryRestorable,
  Member,
  OrderBy,
  QueryByIdsInput,
  useUpdateMembersMutation,
} from '@timed/gql';
import { MemberModuleAccessCheckbox } from '@timed/member';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type MemberUpdateOrganisationDetailsFormProps = {
  member: Pick<Member, 'id' | 'admin' | 'moduleAccessCS' | 'moduleAccessSC'> & {
    branches?: Array<Pick<Branch, 'id' | 'name'>> | null;
    archive?: Pick<HistoryRestorable, 'id'> | null;
  };
};

type FormData = {
  patch: {
    branches: QueryByIdsInput;
    moduleAccessCS: boolean;
    moduleAccessSC: boolean;
  };
};

const MemberUpdateOrganisationDetailsForm = ({
  member,
}: MemberUpdateOrganisationDetailsFormProps) => {
  const [editing, setEditing] = useState<boolean>(false);

  const [updateMembers, response] = useUpdateMembersMutation();

  const defaultValues: FormData = {
    patch: {
      moduleAccessCS: member.moduleAccessCS ?? false,
      moduleAccessSC: member.moduleAccessSC ?? false,
      branches: { ids: member?.branches?.map(({ id }) => id) ?? [] },
    },
  };

  const {
    handleSubmit,
    watch,
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });

  const currentValues = watch();

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = ({ patch }: FormData) => {
    updateMembers({
      variables: {
        input: { ids: [member.id], patch },
      },
    }).catch((e) => {});
    reset({ patch });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block
        title="Organisational Details"
        topRight={
          !member.archive ? (
            <Protected admin>
              <IconButtonMulti
                enabled={editing}
                changed={!isEqual(currentValues, defaultValues)}
                setEditing={setEditing}
                loading={response.loading}
                success={!!response.data}
              />
            </Protected>
          ) : undefined
        }
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Branches</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                intersperse({ array: member.branches!.map(({ name }) => name) })
              ) : (
                <BranchSelectInput
                  multiple
                  orderBy={[{ name: OrderBy.ASC }]}
                  watch={watch}
                  name="patch.branches.ids"
                  control={control}
                  error={!!errors.patch?.branches?.ids}
                  // helperText={errors.patch?.branches?.message}
                  disabled={!editing}
                  formControlProps={{
                    size: 'small',
                    variant: 'outlined',
                  }}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Core Support App</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.admin || member.moduleAccessCS ? (
                  'Access'
                ) : (
                  'No access'
                )
              ) : (
                <MemberModuleAccessCheckbox
                  defaultChecked={!!member.moduleAccessCS}
                  control={control}
                  name="patch.moduleAccessCS"
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Typography>Support Coordination App</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              {!editing ? (
                member.admin || member.moduleAccessSC ? (
                  'Access'
                ) : (
                  'No access'
                )
              ) : (
                <MemberModuleAccessCheckbox
                  defaultChecked={!!member.moduleAccessSC}
                  control={control}
                  name="patch.moduleAccessSC"
                />
              )}
            </Typography>
          </Grid>
        </Grid>
      </Block>
    </form>
  );
};

export default MemberUpdateOrganisationDetailsForm;

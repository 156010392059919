import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useAuth } from '@timed/auth';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    links: {
      backgroundColor: theme.palette.appBar.dark,
      color: theme.palette.getContrastText(theme.palette.appBar.main),
      display: 'flex',
      flexDirection: 'column',
    },
    link: {
      cursor: 'pointer',
      padding: theme.spacing(2, 4),
      '&:hover': {
        backgroundColor: theme.palette.appBar.light,
      },
      '& p': {
        [theme.breakpoints.up('sm')]: {
          fontSize: '16px',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
        },
      },
    },
    linkSelected: {
      backgroundColor: theme.palette.appBar.light,
    },
  }),
);
interface AppBarAccountOrgMenuProps {
  handleClose: (event: React.MouseEvent<EventTarget>) => void;
}

const AppBarAccountOrgMenu = ({ handleClose }: AppBarAccountOrgMenuProps) => {
  const classes = useStyles();

  const { org, setOrg, members } = useAuth();

  const handleChangeOrg = async (id: string) => {
    if (members.map(({ id }) => id).includes(id)) setOrg({ id });
  };

  if (members.length < 1) return null;

  return (
    <Box className={classes.links}>
      {members.map((member) => (
        <Box
          className={
            org?.id === member.org.id
              ? clsx(classes.link, classes.linkSelected)
              : classes.link
          }
          onClick={(event) => {
            handleChangeOrg(member.id);
            handleClose(event);
          }}
        >
          <Typography>{member.org.preferredName}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default AppBarAccountOrgMenu;

import {
  Box,
  createStyles,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  makeStyles,
  OutlinedInput,
  Theme,
} from '@material-ui/core';
import {
  addServerErrors,
  Block,
  ColorPicker,
  IconButtonMulti,
  validateColorHex,
} from '@timed/common';
import { Client, Permission, useUpdateClientsColorsMutation } from '@timed/gql';
import { Protected } from '@timed/auth';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

type ClientUpdateColorFormProps = React.PropsWithChildren<{
  client: Pick<Client, 'id' | 'color'>;
}>;

type FormData = {
  patch: {
    color: string;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      display: 'grid',
      gridTemplateColumns: 'max-content auto max-content',
      gap: theme.spacing(4),
      alignItems: 'center',
    },
  }),
);

const ClientUpdateColorForm = ({ client }: ClientUpdateColorFormProps) => {
  const classes = useStyles();

  const [editing, setEditing] = useState<boolean>(false);

  const [updateClientsColors, response] = useUpdateClientsColorsMutation();

  const defaultValues: FormData = {
    patch: {
      color: client.color?.substring(1) || '',
    },
  };

  const {
    handleSubmit,
    control,
    watch,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormData>({ defaultValues });

  const currentValues = watch();

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const updateColor = (color: string) => {
    updateClientsColors({
      variables: { input: { ids: [client.id], patch: { color: '#' + color } } },
    }).catch((e) => {});
    reset({ patch: { color } });
  };

  const onSubmit = (values: FormData) => {
    updateColor(values.patch.color);
  };

  const handleColorPicker = (color: string) => {
    updateColor(color);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block
        title="Color"
        topRight={
          <Protected permissions={Permission.CLIENT_WRITE}>
            <IconButtonMulti
              enabled={editing}
              changed={!isEqual(currentValues, defaultValues)}
              setEditing={setEditing}
              loading={response.loading}
              success={!!response.data}
            />
          </Protected>
        }
      >
        <Box className={classes.grid}>
          <ColorPicker setColor={handleColorPicker} />
          {!editing ? (
            client.color
          ) : (
            <Controller
              key="color"
              name="patch.color"
              control={control}
              rules={validateColorHex}
              render={({ field }) => (
                <FormControl
                  variant="outlined"
                  size="small"
                  disabled={!editing}
                  error={!!errors.patch?.color}
                >
                  <InputLabel htmlFor="color">{'Hex'}</InputLabel>
                  <OutlinedInput
                    {...field}
                    error={!!errors.patch?.color}
                    label={'Hex'}
                    startAdornment={
                      <InputAdornment position="start">#</InputAdornment>
                    }
                  />
                  <FormHelperText>
                    {!!errors.patch?.color && errors.patch?.color.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
          )}
        </Box>
      </Block>
    </form>
  );
};

export default ClientUpdateColorForm;

/*
EFFECTIVE 1 JUL 2024
    Night: 00:00 - 06:00, Day: 06:00 - 20:00, Evening: 20:00 - 00:00
    01_002_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Night         $75.82
    01_010_0107_1_1     Assistance With Self-Care Activities - Night-Time Sleepover             $286.56
    01_011_0107_1_1	    Assistance With Self-Care Activities - Standard - Weekday Daytime       $67.56
    01_012_0107_1_1	    Assistance With Self-Care Activities - Standard - Public Holiday        $150.10
    01_013_0107_1_1	    Assistance With Self-Care Activities - Standard - Saturday              $95.07
    01_014_0107_1_1	    Assistance With Self-Care Activities - Standard - Sunday                $122.59
    01_015_0107_1_1	    Assistance With Self-Care Activities - Standard - Weekday Evening       $74.44

    04_102_0125_6_1     Access Community Social and Rec Activ - Standard - Public Holiday       $150.10
    04_103_0125_6_1     Access Community Social and Rec Activ - Standard - Weekday Evening      $74.44
    04_104_0125_6_1     Access Community Social and Rec Activ - Standard - Weekday Daytime      $67.56
    04_105_0125_6_1     Access Community Social and Rec Activ - Standard - Saturday             $95.07
    04_106_0125_6_1     Access Community Social and Rec Activ - Standard - Sunday               $122.59

    15_037_0117_1_3     Skill Development And Training including Public Transport Training      $67.56

EFFECTIVE 1 JUL 2023 ✓✓
    Night: 00:00 - 06:00, Day: 06:00 - 20:00, Evening: 20:00 - 00:00
    01_002_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Night         $73.46
    01_010_0107_1_1     Assistance With Self-Care Activities - Night-Time Sleepover             $276.27
    01_011_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Daytime       $65.47
    01_012_0107_1_1     Assistance With Self-Care Activities - Standard - Public Holiday        $145.44
    01_013_0107_1_1     Assistance With Self-Care Activities - Standard - Saturday              $92.12
    01_014_0107_1_1     Assistance With Self-Care Activities - Standard - Sunday                $118.78
    01_015_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Evening       $72.13

    04_102_0125_6_1     Access Community Social and Rec Activ - Standard - Public Holiday       $145.44
    04_103_0125_6_1     Access Community Social and Rec Activ - Standard - Weekday Evening      $72.13
    04_104_0125_6_1     Access Community Social and Rec Activ - Standard - Weekday Daytime      $65.47
    04_105_0125_6_1     Access Community Social and Rec Activ - Standard - Saturday             $92.12
    04_106_0125_6_1     Access Community Social and Rec Activ - Standard - Sunday               $118.78

    15_037_0117_1_3     Skill Development And Training including Public Transport Training      $65.47

EFFECTIVE 5 MAY 2023 ✓
    Night: 00:00 - 06:00, Day: 06:00 - 20:00, Evening: 20:00 - 00:00
    01_002_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Night         $69.77
    01_010_0107_1_1     Assistance With Self-Care Activities - Night-Time Sleepover             $262.16
    01_011_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Daytime       $62.17
    01_012_0107_1_1     Assistance With Self-Care Activities - Standard - Public Holiday        $138.20
    01_013_0107_1_1     Assistance With Self-Care Activities - Standard - Saturday              $87.51
    01_014_0107_1_1     Assistance With Self-Care Activities - Standard - Sunday                $112.85
    01_015_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Evening       $68.50

EFFECTIVE 1 OCT 2023 ✓
    Night: 00:00 - 06:00, Day: 06:00 - 20:00, Evening: 20:00 - 00:00
    01_002_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Night         $69.77
    01_010_0107_1_1     Assistance With Self-Care Activities - Night-Time Sleepover             $262.16
    01_011_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Daytime       $62.17
    01_012_0107_1_1     Assistance With Self-Care Activities - Standard - Public Holiday        $138.20
    01_013_0107_1_1     Assistance With Self-Care Activities - Standard - Saturday              $87.51
    01_014_0107_1_1     Assistance With Self-Care Activities - Standard - Sunday                $112.85
    01_015_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Evening       $68.50

EFFECTIVE 1 JUL 2022 (Released: 27 JUN 2022) ✓✓
    Night: 00:00 - 06:00, Day: 06:00 - 20:00, Evening: 20:00 - 00:00
    01_002_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Night         $69.77
    01_010_0107_1_1     Assistance with Self-Care Activities - Night-Time Sleepover             $262.16
    01_011_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Daytime       $62.17
    01_012_0107_1_1     Assistance With Self-Care Activities - Standard - Public Holiday        $138.20
    01_013_0107_1_1     Assistance With Self-Care Activities - Standard - Saturday              $87.51
    01_014_0107_1_1     Assistance With Self-Care Activities - Standard - Sunday                $112.85
    01_015_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Evening       $68.50

EFFECTIVE 19 APR 2022 ✓
    Night: 00:00 - 06:00, Day: 06:00 - 20:00, Evening: 20:00 - 00:00
    01_002_0107_1_1     Assistance with Self-Care Activities - Standard - Weekday Night         $64.14
    01_010_0107_1_1     Assistance with Self-Care Activities - Night-Time Sleepover             $242.95
    01_011_0107_1_1     Assistance with Self-Care Activities - Standard - Weekday Daytime       $57.23
    01_012_0107_1_1     Assistance with Self-Care Activities - Standard - Public Holiday        $126.40
    01_013_0107_1_1     Assistance with Self-Care Activities - Standard - Saturday              $80.28
    01_014_0107_1_1     Assistance with Self-Care Activities - Standard - Sunday                $103.34
    01_015_0107_1_1     Assistance with Self-Care Activities - Standard - Weekday Evening       $62.99

EFFECTIVE 1 MAR 2022 ✓✓
    Night: 00:00 - 06:00, Day: 06:00 - 20:00, Evening: 20:00 - 00:00
    01_002_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Night         $64.14
    01_010_0107_1_1     Assistance With Self-Care Activities - Night-Time Sleepover             $242.95
    01_011_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Daytime       $57.23
    01_012_0107_1_1     Assistance With Self-Care Activities - Standard - Public Holiday        $126.40
    01_013_0107_1_1     Assistance With Self-Care Activities - Standard - Saturday              $80.28
    01_014_0107_1_1     Assistance With Self-Care Activities - Standard - Sunday                $103.34
    01_015_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Evening       $62.99

EFFECTIVE 7 DEC 2021 ✓
    Night: 00:00 - 06:00, Day: 06:00 - 20:00, Evening: 20:00 - 00:00
    01_002_0107_1_1     Assistance with Self-Care Activities - Standard - Weekday Night         $64.00
    01_010_0107_1_1     Assistance with Self-Care Activities - Night-Time Sleepover             $242.53
    01_011_0107_1_1     Assistance with Self-Care Activities - Standard - Weekday Daytime       $57.10
    01_012_0107_1_1     Assistance with Self-Care Activities - Standard - Public Holiday        $126.11
    01_013_0107_1_1     Assistance with Self-Care Activities - Standard - Saturday              $80.10
    01_014_0107_1_1     Assistance with Self-Care Activities - Standard - Sunday                $103.11
    01_015_0107_1_1     Assistance with Self-Care Activities - Standard - Weekday Evening       $62.85
    
EFFECTIVE 1 SEP 2021 ✓
    Night: 00:00 - 06:00, Day: 06:00 - 20:00, Evening: 20:00 - 00:00
    01_002_0107_1_1     Assistance with Self-Care Activities - Standard - Weekday Night         $64.00
    01_010_0107_1_1     Assistance with Self-Care Activities - Night-Time Sleepover             $242.53
    01_011_0107_1_1     Assistance with Self-Care Activities - Standard - Weekday Daytime       $57.10
    01_012_0107_1_1     Assistance with Self-Care Activities - Standard - Public Holiday        $126.11
    01_013_0107_1_1     Assistance with Self-Care Activities - Standard - Saturday              $80.10
    01_014_0107_1_1     Assistance with Self-Care Activities - Standard - Sunday                $103.11
    01_015_0107_1_1     Assistance with Self-Care Activities - Standard - Weekday Evening       $62.85
    
EFFECTIVE 1 JUL 2021 ✓✓
    Night: 00:00 - 06:00, Day: 06:00 - 20:00, Evening: 20:00 - 00:00
    01_002_0107_1_1     Assistance with Self-Care Activities - Standard - Weekday Night         $64.00
    01_010_0107_1_1     Assistance with Self-Care Activities - Night-Time Sleepover             $242.53
    01_011_0107_1_1     Assistance with Self-Care Activities - Standard - Weekday Daytime       $57.10
    01_012_0107_1_1     Assistance with Self-Care Activities - Standard - Public Holiday        $126.11
    01_013_0107_1_1     Assistance with Self-Care Activities - Standard - Saturday              $80.10
    01_014_0107_1_1     Assistance with Self-Care Activities - Standard - Sunday                $103.11
    01_015_0107_1_1     Assistance with Self-Care Activities - Standard - Weekday Evening       $62.85
    
EFFECTIVE 1 MAR 2021 ✓
    Night: 00:00 - 06:00, Day: 06:00 - 20:00, Evening: 20:00 - 00:00
    01_002_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Night         $62.17
    01_010_0107_1_1     Assistance With Self-Care Activities - Night-Time Sleepover             $235.81
    01_011_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Daytime       $55.47
    01_012_0107_1_1     Assistance With Self-Care Activities - Standard - Public Holiday        $122.51
    01_013_0107_1_1     Assistance With Self-Care Activities - Standard - Saturday              $77.81
    01_014_0107_1_1     Assistance With Self-Care Activities - Standard - Sunday                $100.16
    01_015_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Evening       $61.05

EFFECTIVE 1 DEC 2020 ✓✓
    Night: 00:00 - 06:00, Day: 06:00 - 20:00, Evening: 20:00 - 00:00
    01_002_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Night         $62.17
    01_010_0107_1_1     Assistance With Self-Care Activities - Night-Time Sleepover             $235.81
    01_011_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Daytime       $55.47
    01_012_0107_1_1     Assistance With Self-Care Activities - Standard - Public Holiday        $122.51
    01_013_0107_1_1     Assistance With Self-Care Activities - Standard - Saturday              $77.81
    01_014_0107_1_1     Assistance With Self-Care Activities - Standard - Sunday                $100.16
    01_015_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Evening       $61.05

EFFECTIVE 25 OCT 2020 ✓
    Night: 00:00 - 06:00, Day: 06:00 - 20:00, Evening: 20:00 - 00:00
    01_002_0107_1_1     Assistance with Self-Care Activities - Standard - Weekday Night         $60.87
    01_010_0107_1_1     Assistance with Self-Care Activities - Night-Time Sleepover             $231.06
    01_011_0107_1_1     Assistance with Self-Care Activities - Standard - Weekday Daytime       $54.30
    01_012_0107_1_1     Assistance with Self-Care Activities - Standard - Public Holiday        $119.94
    01_013_0107_1_1     Assistance with Self-Care Activities - Standard - Saturday              $76.18
    01_014_0107_1_1     Assistance with Self-Care Activities - Standard - Sunday                $98.06
    01_015_0107_1_1     Assistance with Self-Care Activities - Standard - Weekday Evening       $59.77

EFFECTIVE 1 OCT 2020 ✓
    Night: 00:00 - 06:00, Day: 06:00 - 20:00, Evening: 20:00 - 00:00
    01_002_0107_1_1     Assistance with Self-Care Activities - Standard - Weekday Night         $60.87
    01_010_0107_1_1     Assistance with Self-Care Activities - Night-Time Sleepover             $231.06
    01_011_0107_1_1     Assistance with Self-Care Activities - Standard - Weekday Daytime       $54.30
    01_012_0107_1_1     Assistance with Self-Care Activities - Standard - Public Holiday        $119.94
    01_013_0107_1_1     Assistance with Self-Care Activities - Standard - Saturday              $76.18
    01_014_0107_1_1     Assistance with Self-Care Activities - Standard - Sunday                $98.06
    01_015_0107_1_1     Assistance with Self-Care Activities - Standard - Weekday Evening       $59.77

EFFECTIVE 1 JUL 2020 ✓✓
    Night: 00:00 - 06:00, Day: 06:00 - 20:00, Evening: 20:00 - 00:00
    01_002_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Night         $60.87
    01_010_0107_1_1     Assistance With Self-Care Activities - Night-Time Sleepover             $231.06
    01_011_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Daytime       $54.30
    01_012_0107_1_1     Assistance With Self-Care Activities - Standard - Public Holiday        $119.94
    01_013_0107_1_1     Assistance With Self-Care Activities - Standard - Saturday              $76.18
    01_014_0107_1_1     Assistance With Self-Care Activities - Standard - Sunday                $98.06
    01_015_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Evening       $59.77

EFFECTIVE 30 APR 2020 ✓✓
    Night: 00:00 - 06:00, Day: 06:00 - 20:00, Afternoon: 20:00 - 00:00
    01_002_0107_1_1     Assistance With Self-Care Activities - Standard - Active Overnight (including COVID19 loading)      $65.34
    01_010_0107_1_1     Assistance With Self-Care Activities - Night-Time Sleepover	(including COVID19 loading)             $235.43
    01_011_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Daytime (including COVID19 loading)       $58.14
    01_012_0107_1_1     Assistance With Self-Care Activities - Standard - Public Holiday (including COVID19 loading)        $130.17
    01_013_0107_1_1     Assistance With Self-Care Activities - Standard - Saturday (including COVID19 loading)              $79.96
    01_014_0107_1_1     Assistance With Self-Care Activities - Standard - Sunday (including COVID19 loading)                $103.97
    01_015_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Afternoon (including COVID19 loading)     $64.14

EFFECTIVE 1 MAR 2020 ✓
    Night: 00:00 - 06:00, Day: 06:00 - 20:00, Afternoon: 20:00 - 00:00
    01_002_0107_1_1     Assistance With Self-Care Activities - Standard - Active Overnight      $59.40
    01_010_0107_1_1     Assistance With Self-Care Activities - Night-Time Sleepover 			      $214.03
    01_011_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Daytime       $52.85
    01_012_0107_1_1     Assistance With Self-Care Activities - Standard - Public Holiday        $118.34
    01_013_0107_1_1     Assistance With Self-Care Activities - Standard - Saturday              $72.69
    01_014_0107_1_1     Assistance With Self-Care Activities - Standard - Sunday                $94.52
    01_015_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Afternoon     $58.31

EFFECTIVE 1 DEC 2019 ✓
    Night: 00:00 - 06:00, Day: 06:00 - 20:00, Afternoon: 20:00 - 00:00
    01_002_0107_1_1     Assistance With Self-Care Activities - Standard - Active Overnight      $59.40
    01_010_0107_1_1     Assistance With Self-Care Activities - Night-Time Sleepover             $214.03
    01_011_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Daytime       $52.85
    01_012_0107_1_1     Assistance With Self-Care Activities - Standard - Public Holiday        $118.34
    01_013_0107_1_1     Assistance With Self-Care Activities - Standard - Saturday              $72.69
    01_014_0107_1_1     Assistance With Self-Care Activities - Standard - Sunday                $94.52
    01_015_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Afternoon     $58.31

EFFECTIVE 1 OCT 2019 ✓
    Night: 00:00 - 06:00, Day: 06:00 - 20:00, Afternoon: 20:00 - 00:00
    01_002_0107_1_1     Assistance With Self-Care Activities - Standard - Active Overnight      $59.40
    01_010_0107_1_1     Assistance With Self-Care Activities - Night-Time Sleepover             $214.03
    01_011_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Daytime       $52.85
    01_012_0107_1_1     Assistance With Self-Care Activities - Standard - Public Holiday        $118.34
    01_013_0107_1_1     Assistance With Self-Care Activities - Standard - Saturday              $72.69
    01_014_0107_1_1     Assistance With Self-Care Activities - Standard - Sunday                $94.52
    01_015_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Afternoon     $58.31

EFFECTIVE 1 JUL 2019 ✓
    Night: 00:00 - 06:00, Day: 06:00 - 20:00, Evening: 20:00 - 00:00
    01_002_0107_1_1     Assistance With Self-Care Activities - Standard - Active Overnight      $59.40
    01_010_0107_1_1     Assistance With Self-Care Activities - Night-Time Sleepover             $214.03
    01_011_0107_1_1     Assistance With Self-Care Activities - Standard - Weekday Daytime       $52.85
    01_012_0107_1_1     Assistance With Self-Care Activities - Standard - Public Holiday        $118.34
    01_013_0107_1_1     Assistance With Self-Care Activities - Standard - Saturday              $72.69
    01_014_0107_1_1     Assistance With Self-Care Activities - Standard - Sunday                $94.52
    01_015_0107_1_1     Assistance With Self-Care Activities - Standard - Evening               $58.31
*/

export type BillingRate = {
  /**
   * Unique identifier within the effective-dates.
   */
  supportItemNumber: string;

  /**
   * Type of rate.
   */
  type:
    | 'weekday-daytime'
    | 'evening'
    | 'night'
    | 'saturday'
    | 'sunday'
    | 'public-holiday'
    | 'passive'
    | 'km';

  /**
   * Description of the rate.
   */
  supportItemName: string;

  /**
   * Billable hourly rate.
   */
  rate: number;

  /**
   * Billable hourly rate.
   */
  unit: 'hour' | 'each';

  /**
   * Effective-from date.
   */
  date: Date;
};

export const billingRates: BillingRate[] = [
  /**
   *  EFFECTIVE 1 JULY 2024
   */
  {
    supportItemNumber: 'KM',
    supportItemName: 'Kilometres travelled with participant',
    type: 'km',
    rate: 115,
    unit: 'each',
    date: new Date(2024, 6, 1),
  },
  {
    supportItemNumber: '01_002_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Night',
    type: 'night',
    rate: 7582,
    unit: 'hour',
    date: new Date(2024, 6, 1),
  },
  {
    supportItemNumber: '01_010_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Night-Time Sleepover',
    type: 'passive',
    rate: 28656,
    unit: 'each',
    date: new Date(2024, 6, 1),
  },
  {
    supportItemNumber: '01_011_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Daytime',
    type: 'weekday-daytime',
    rate: 6756,
    unit: 'hour',
    date: new Date(2024, 6, 1),
  },
  {
    supportItemNumber: '01_012_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Public Holiday',
    type: 'public-holiday',
    rate: 15010,
    unit: 'hour',
    date: new Date(2024, 6, 1),
  },
  {
    supportItemNumber: '01_013_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Saturday',
    type: 'saturday',
    rate: 9507,
    unit: 'hour',
    date: new Date(2024, 6, 1),
  },
  {
    supportItemNumber: '01_014_0107_1_1',
    supportItemName: 'Assistance With Self-Care Activities - Standard - Sunday',
    type: 'sunday',
    rate: 12259,
    unit: 'hour',
    date: new Date(2024, 6, 1),
  },
  {
    supportItemNumber: '01_015_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Evening',
    type: 'evening',
    rate: 7444,
    unit: 'hour',
    date: new Date(2024, 6, 1),
  },
  {
    supportItemNumber: '04_102-0125-6-1',
    supportItemName:
      'Access Community Social and Rec Activ - Standard - Public Holiday',
    type: 'public-holiday',
    rate: 15010,
    unit: 'hour',
    date: new Date(2024, 6, 1),
  },
  {
    supportItemNumber: '04_103-0125-6-1',
    supportItemName:
      'Access Community Social and Rec Activ - Standard - Weekday Evening',
    type: 'evening',
    rate: 7444,
    unit: 'hour',
    date: new Date(2024, 6, 1),
  },
  {
    supportItemNumber: '04_104-0125-6-1',
    supportItemName:
      'Access Community Social and Rec Activ - Standard - Weekday Daytime',
    type: 'weekday-daytime',
    rate: 6756,
    unit: 'hour',
    date: new Date(2024, 6, 1),
  },
  {
    supportItemNumber: '04_105-0125-6-1',
    supportItemName:
      'Access Community Social and Rec Activ - Standard - Saturday',
    type: 'saturday',
    rate: 9507,
    unit: 'hour',
    date: new Date(2024, 6, 1),
  },
  {
    supportItemNumber: '04_106-0125-6-1',
    supportItemName:
      'Access Community Social and Rec Activ - Standard - Sunday',
    type: 'sunday',
    rate: 12259,
    unit: 'hour',
    date: new Date(2024, 6, 1),
  },
  {
    supportItemNumber: '15_037-0117-1-3',
    supportItemName:
      'Skill Development And Training including Public Transport Training',
    type: 'weekday-daytime',
    rate: 6756,
    unit: 'hour',
    date: new Date(2024, 6, 1),
  },
  /**
   *  EFFECTIVE 1 JULY 2023
   */
  {
    supportItemNumber: 'KM',
    supportItemName: 'Kilometres travelled with participant',
    type: 'km',
    rate: 110,
    unit: 'each',
    date: new Date(2023, 6, 1),
  },
  {
    supportItemNumber: '01_002_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Night',
    type: 'night',
    rate: 7346,
    unit: 'hour',
    date: new Date(2023, 6, 1),
  },
  {
    supportItemNumber: '01_010_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Night-Time Sleepover',
    type: 'passive',
    rate: 27627,
    unit: 'each',
    date: new Date(2023, 6, 1),
  },
  {
    supportItemNumber: '01_011_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Daytime',
    type: 'weekday-daytime',
    rate: 6547,
    unit: 'hour',
    date: new Date(2023, 6, 1),
  },
  {
    supportItemNumber: '01_012_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Public Holiday',
    type: 'public-holiday',
    rate: 14544,
    unit: 'hour',
    date: new Date(2023, 6, 1),
  },
  {
    supportItemNumber: '01_013_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Saturday',
    type: 'saturday',
    rate: 9212,
    unit: 'hour',
    date: new Date(2023, 6, 1),
  },
  {
    supportItemNumber: '01_014_0107_1_1',
    supportItemName: 'Assistance With Self-Care Activities - Standard - Sunday',
    type: 'sunday',
    rate: 11878,
    unit: 'hour',
    date: new Date(2023, 6, 1),
  },
  {
    supportItemNumber: '01_015_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Evening',
    type: 'evening',
    rate: 7213,
    unit: 'hour',
    date: new Date(2023, 6, 1),
  },
  {
    supportItemNumber: '04_102-0125-6-1',
    supportItemName:
      'Access Community Social and Rec Activ - Standard - Public Holiday',
    type: 'public-holiday',
    rate: 14544,
    unit: 'hour',
    date: new Date(2023, 6, 1),
  },
  {
    supportItemNumber: '04_103-0125-6-1',
    supportItemName:
      'Access Community Social and Rec Activ - Standard - Weekday Evening',
    type: 'evening',
    rate: 7213,
    unit: 'hour',
    date: new Date(2023, 6, 1),
  },
  {
    supportItemNumber: '04_104-0125-6-1',
    supportItemName:
      'Access Community Social and Rec Activ - Standard - Weekday Daytime',
    type: 'weekday-daytime',
    rate: 6547,
    unit: 'hour',
    date: new Date(2023, 6, 1),
  },
  {
    supportItemNumber: '04_105-0125-6-1',
    supportItemName:
      'Access Community Social and Rec Activ - Standard - Saturday',
    type: 'saturday',
    rate: 9212,
    unit: 'hour',
    date: new Date(2023, 6, 1),
  },
  {
    supportItemNumber: '04_106-0125-6-1',
    supportItemName:
      'Access Community Social and Rec Activ - Standard - Sunday',
    type: 'sunday',
    rate: 11878,
    unit: 'hour',
    date: new Date(2023, 6, 1),
  },
  {
    supportItemNumber: '15_037-0117-1-3',
    supportItemName:
      'Skill Development And Training including Public Transport Training',
    type: 'weekday-daytime',
    rate: 6547,
    unit: 'hour',
    date: new Date(2023, 6, 1),
  },
  /**
   *  EFFECTIVE 1 JULY 2022
   */
  {
    supportItemNumber: '01_002_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Night',
    type: 'night',
    rate: 6977,
    unit: 'hour',
    date: new Date(2022, 6, 1),
  },
  {
    supportItemNumber: '01_010_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Night-Time Sleepover',
    type: 'passive',
    rate: 26216,
    unit: 'each',
    date: new Date(2022, 6, 1),
  },
  {
    supportItemNumber: '01_011_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Daytime',
    type: 'weekday-daytime',
    rate: 6217,
    unit: 'hour',
    date: new Date(2022, 6, 1),
  },
  {
    supportItemNumber: '01_012_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Public Holiday',
    type: 'public-holiday',
    rate: 13820,
    unit: 'hour',
    date: new Date(2022, 6, 1),
  },
  {
    supportItemNumber: '01_013_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Saturday',
    type: 'saturday',
    rate: 8751,
    unit: 'hour',
    date: new Date(2022, 6, 1),
  },
  {
    supportItemNumber: '01_014_0107_1_1',
    supportItemName: 'Assistance With Self-Care Activities - Standard - Sunday',
    type: 'sunday',
    rate: 11285,
    unit: 'hour',
    date: new Date(2022, 6, 1),
  },
  {
    supportItemNumber: '01_015_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Evening',
    type: 'evening',
    rate: 6850,
    unit: 'hour',
    date: new Date(2022, 6, 1),
  },
  /**
   *  EFFECTIVE 1 MAR 2022
   */
  {
    supportItemNumber: '01_002_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Night',
    type: 'night',
    rate: 6414,
    unit: 'hour',
    date: new Date(2022, 2, 1),
  },
  {
    supportItemNumber: '01_010_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Night-Time Sleepover',
    type: 'passive',
    rate: 24295,
    unit: 'each',
    date: new Date(2022, 2, 1),
  },
  {
    supportItemNumber: '01_011_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Daytime',
    type: 'weekday-daytime',
    rate: 5723,
    unit: 'hour',
    date: new Date(2022, 2, 1),
  },
  {
    supportItemNumber: '01_012_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Public Holiday',
    type: 'public-holiday',
    rate: 12640,
    unit: 'hour',
    date: new Date(2022, 2, 1),
  },
  {
    supportItemNumber: '01_013_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Saturday',
    type: 'saturday',
    rate: 8028,
    unit: 'hour',
    date: new Date(2022, 2, 1),
  },
  {
    supportItemNumber: '01_014_0107_1_1',
    supportItemName: 'Assistance With Self-Care Activities - Standard - Sunday',
    type: 'sunday',
    rate: 10334,
    unit: 'hour',
    date: new Date(2022, 2, 1),
  },
  {
    supportItemNumber: '01_015_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Evening',
    type: 'evening',
    rate: 6299,
    unit: 'hour',
    date: new Date(2022, 2, 1),
  },
  /**
   *  EFFECTIVE 1 JUL 2021
   */
  {
    supportItemNumber: '01_002_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Night',
    type: 'night',
    rate: 6400,
    unit: 'hour',
    date: new Date(2021, 6, 1),
  },
  {
    supportItemNumber: '01_010_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Night-Time Sleepover',
    type: 'passive',
    rate: 24253,
    unit: 'each',
    date: new Date(2021, 6, 1),
  },
  {
    supportItemNumber: '01_011_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Daytime',
    type: 'weekday-daytime',
    rate: 5710,
    unit: 'hour',
    date: new Date(2021, 6, 1),
  },
  {
    supportItemNumber: '01_012_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Public Holiday',
    type: 'public-holiday',
    rate: 12611,
    unit: 'hour',
    date: new Date(2021, 6, 1),
  },
  {
    supportItemNumber: '01_013_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Saturday',
    type: 'saturday',
    rate: 8010,
    unit: 'hour',
    date: new Date(2021, 6, 1),
  },
  {
    supportItemNumber: '01_014_0107_1_1',
    supportItemName: 'Assistance With Self-Care Activities - Standard - Sunday',
    type: 'sunday',
    rate: 10311,
    unit: 'hour',
    date: new Date(2021, 6, 1),
  },
  {
    supportItemNumber: '01_015_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Evening',
    type: 'evening',
    rate: 6285,
    unit: 'hour',
    date: new Date(2021, 6, 1),
  },
  /**
   *  EFFECTIVE 1 DEC 2020
   */
  {
    supportItemNumber: '01_002_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Night',
    type: 'night',
    rate: 6217,
    unit: 'hour',
    date: new Date(2020, 11, 1),
  },
  {
    supportItemNumber: '01_010_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Night-Time Sleepover',
    type: 'passive',
    rate: 23581,
    unit: 'each',
    date: new Date(2020, 11, 1),
  },
  {
    supportItemNumber: '01_011_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Daytime',
    type: 'weekday-daytime',
    rate: 5547,
    unit: 'hour',
    date: new Date(2020, 11, 1),
  },
  {
    supportItemNumber: '01_012_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Public Holiday',
    type: 'public-holiday',
    rate: 12251,
    unit: 'hour',
    date: new Date(2020, 11, 1),
  },
  {
    supportItemNumber: '01_013_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Saturday',
    type: 'saturday',
    rate: 7781,
    unit: 'hour',
    date: new Date(2020, 11, 1),
  },
  {
    supportItemNumber: '01_014_0107_1_1',
    supportItemName: 'Assistance With Self-Care Activities - Standard - Sunday',
    type: 'sunday',
    rate: 10016,
    unit: 'hour',
    date: new Date(2020, 11, 1),
  },
  {
    supportItemNumber: '01_015_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Evening',
    type: 'evening',
    rate: 6105,
    unit: 'hour',
    date: new Date(2020, 11, 1),
  },
  /**
   *  EFFECTIVE 1 JUL 2020
   */
  {
    supportItemNumber: '01_002_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Night',
    type: 'night',
    rate: 6087,
    unit: 'hour',
    date: new Date(2020, 6, 1),
  },
  {
    supportItemNumber: '01_010_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Night-Time Sleepover',
    type: 'passive',
    rate: 23106,
    unit: 'each',
    date: new Date(2020, 6, 1),
  },
  {
    supportItemNumber: '01_011_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Daytime',
    type: 'weekday-daytime',
    rate: 5430,
    unit: 'hour',
    date: new Date(2020, 6, 1),
  },
  {
    supportItemNumber: '01_012_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Public Holiday',
    type: 'public-holiday',
    rate: 11994,
    unit: 'hour',
    date: new Date(2020, 6, 1),
  },
  {
    supportItemNumber: '01_013_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Saturday',
    type: 'saturday',
    rate: 7618,
    unit: 'hour',
    date: new Date(2020, 6, 1),
  },
  {
    supportItemNumber: '01_014_0107_1_1',
    supportItemName: 'Assistance With Self-Care Activities - Standard - Sunday',
    type: 'sunday',
    rate: 9806,
    unit: 'hour',
    date: new Date(2020, 6, 1),
  },
  {
    supportItemNumber: '01_015_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Evening',
    type: 'evening',
    rate: 5977,
    unit: 'hour',
    date: new Date(2020, 6, 1),
  },
  /**
   *  EFFECTIVE 30 APR 2020
   */
  {
    supportItemNumber: '01_002_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Active Overnight (including COVID19 loading)',
    type: 'night',
    rate: 6534,
    unit: 'hour',
    date: new Date(2020, 3, 30),
  },
  {
    supportItemNumber: '01_010_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Night-Time Sleepover (including COVID19 loading)',
    type: 'passive',
    rate: 23543,
    unit: 'each',
    date: new Date(2020, 3, 30),
  },
  {
    supportItemNumber: '01_011_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Daytime (including COVID19 loading)',
    type: 'weekday-daytime',
    rate: 5814,
    unit: 'hour',
    date: new Date(2020, 3, 30),
  },
  {
    supportItemNumber: '01_012_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Public Holiday (including COVID19 loading)',
    type: 'public-holiday',
    rate: 13017,
    unit: 'hour',
    date: new Date(2020, 3, 30),
  },
  {
    supportItemNumber: '01_013_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Saturday (including COVID19 loading)',
    type: 'saturday',
    rate: 7996,
    unit: 'hour',
    date: new Date(2020, 3, 30),
  },
  {
    supportItemNumber: '01_014_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Sunday (including COVID19 loading)',
    type: 'sunday',
    rate: 10397,
    unit: 'hour',
    date: new Date(2020, 3, 30),
  },
  {
    supportItemNumber: '01_015_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Afternoon (including COVID19 loading)',
    type: 'evening',
    rate: 6414,
    unit: 'hour',
    date: new Date(2020, 3, 30),
  },
  /**
   *  EFFECTIVE 1 Jul 2019
   */
  {
    supportItemNumber: 'KM',
    supportItemName: 'Kilometres travelled with participant',
    type: 'km',
    rate: 100,
    unit: 'each',
    date: new Date(2019, 6, 1),
  },
  {
    supportItemNumber: '01_002_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Active Overnight',
    type: 'night',
    rate: 5940,
    unit: 'hour',
    date: new Date(2019, 6, 1),
  },
  {
    supportItemNumber: '01_010_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Night-Time Sleepover',
    type: 'passive',
    rate: 21403,
    unit: 'each',
    date: new Date(2019, 6, 1),
  },
  {
    supportItemNumber: '01_011_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Daytime',
    type: 'weekday-daytime',
    rate: 5285,
    unit: 'hour',
    date: new Date(2019, 6, 1),
  },
  {
    supportItemNumber: '01_012_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Public Holiday',
    type: 'public-holiday',
    rate: 11834,
    unit: 'hour',
    date: new Date(2019, 6, 1),
  },
  {
    supportItemNumber: '01_013_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Saturday',
    type: 'saturday',
    rate: 7269,
    unit: 'hour',
    date: new Date(2019, 6, 1),
  },
  {
    supportItemNumber: '01_014_0107_1_1',
    supportItemName: 'Assistance With Self-Care Activities - Standard - Sunday',
    type: 'sunday',
    rate: 9452,
    unit: 'hour',
    date: new Date(2019, 6, 1),
  },
  {
    supportItemNumber: '01_015_0107_1_1',
    supportItemName:
      'Assistance With Self-Care Activities - Standard - Weekday Evening',
    type: 'evening',
    rate: 5831,
    unit: 'hour',
    date: new Date(2019, 6, 1),
  },
];

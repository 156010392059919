import {
  Box,
  createStyles,
  Divider,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { _settingsOrganisationBranches } from '@timed/app';
import { BranchCreateForm, BranchList } from '@timed/branch';
import { Page } from '@timed/page';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
  }),
);

const RouteSettingsOrganisationBranches = () => {
  const classes = useStyles();

  return (
    <Page {..._settingsOrganisationBranches}>
      <Box className={classes.wrapper}>
        <BranchCreateForm />
        <Divider />
        <BranchList />
      </Box>
    </Page>
  );
};

export default RouteSettingsOrganisationBranches;

import { Branch } from '@timed/gql';
import { ComputedExpense, Expense } from '@timed/report';
import {
  areIntervalsOverlapping,
  differenceInDays,
  differenceInYears,
  getDaysInYear,
  isBefore,
  max,
  min,
  parse,
} from 'date-fns';
import { isEqual } from 'lodash';

type ComputeFixedExpensesFn = (x: {
  from: Date;
  to: Date;
  branch?: Pick<Branch, 'id' | 'name'>;
  expenses: Expense<string>[];
}) => ComputedExpense[];

/**
 * Validate, format and assign default values from raw data.
 */
export const formatFixedExpenses: ComputeFixedExpensesFn = ({
  from,
  to,
  branch,
  expenses,
}) => {
  const boundary = { start: from, end: to };
  const maxDate = new Date(9999, 11, 31);

  // --------------------------------------------------------
  // FORMAT
  // --------------------------------------------------------

  const formatted = expenses
    .map<Expense<Date>>(({ branches, effectiveDates, rates, ...expense }) => ({
      ...expense,
      effectiveDates: {
        start: max([
          from,
          parse(effectiveDates.start, 'yyyy-MM-dd', new Date()),
        ]),
        end: min([
          to,
          !!effectiveDates.end
            ? parse(effectiveDates.end, 'yyyy-MM-dd', new Date())
            : to,
        ]),
      },
      rates: rates
        .map(({ effectiveDate, ...rate }) => ({
          ...rate,
          effectiveDate: max([
            from,
            parse(effectiveDate, 'yyyy-MM-dd', new Date()),
          ]),
        }))
        // Sort rates in chronological order.
        .sort((a, b) => a.effectiveDate.getTime() - b.effectiveDate.getTime())
        // Filter out rates which are non-applicable to the specified time period.
        .filter(({ effectiveDate }, i, self) =>
          areIntervalsOverlapping(
            {
              start: effectiveDate,
              end:
                i < self.length - 1
                  ? (self[i + 1].effectiveDate as unknown as Date)
                  : maxDate,
            },
            boundary,
          ),
        ),
      branches: branches
        .map(({ effectiveDate, allocations }) => ({
          effectiveDate: max([
            from,
            parse(effectiveDate, 'yyyy-MM-dd', new Date()),
          ]),
          allocations: allocations
            // Filter out allocations which do not match the current branch.
            .filter(
              (allocation) => !branch || branch.name === allocation.branch,
            )
            // Assign default percent value if missing.
            .map(({ branch, percent }, _, self) => ({
              branch,
              percent:
                percent ??
                (self.some(({ percent }) => percent !== undefined)
                  ? undefined
                  : 100),
            }))
            // Filter out allocations with percentage values exceeding the minimum and maximum.
            .filter(
              (allocation) =>
                !!allocation.percent &&
                allocation.percent >= 0 &&
                allocation.percent <= 100,
            ),
        }))
        // Sort branches in chronological order.
        .sort((a, b) => a.effectiveDate.getTime() - b.effectiveDate.getTime())
        // Filter out branches lacking allocations
        .filter(({ allocations }) => allocations.length)
        // Filter out branches which are non-applicable to the specified time period.
        .filter(({ effectiveDate }, i, self) =>
          areIntervalsOverlapping(
            {
              start: effectiveDate,
              end:
                i < self.length - 1
                  ? (self[i + 1].effectiveDate as unknown as Date)
                  : maxDate,
            },
            boundary,
          ),
        ),
    }))
    // Filter out expenses lacking rate and branch data.
    .filter(({ rates, branches }) => !!rates.length && !!branches.length)
    // Sort expenses in chronological order.
    .sort(
      (a, b) =>
        a.effectiveDates.start.getTime() - b.effectiveDates.start.getTime(),
    )
    // Filter out expenses which are non-applicable to the specified time period.
    .filter(
      ({ effectiveDates, rates }) =>
        // Interval is valid
        (isBefore(effectiveDates.start, effectiveDates.end!) ||
          isEqual(effectiveDates.start, effectiveDates.end!)) &&
        // Effective dates within specified timed period
        areIntervalsOverlapping(
          { start: effectiveDates.start, end: effectiveDates.end ?? maxDate },
          boundary,
        ) &&
        // At least one rate is within specified time period
        !!rates.filter(({ effectiveDate }, i) =>
          areIntervalsOverlapping(
            {
              start: effectiveDate,
              end: i < rates.length - 1 ? rates[i + 1].effectiveDate : maxDate,
            },
            boundary,
          ),
        ).length,
    )
    // Sort expenses in alphabetical order.
    .sort((a, b) => a.name.localeCompare(b.name));

  // --------------------------------------------------------
  // CALCULATE TOTALS
  // --------------------------------------------------------
  const daysInYear =
    differenceInYears(to, from) < 1
      ? 52 * 7 // To match MYOB system
      : Math.max(getDaysInYear(from), getDaysInYear(to));

  return formatted
    .map<ComputedExpense>((expense) => {
      let total = 0;

      expense.rates.forEach((rate, i) => {
        let cost = rate.cost;

        switch (rate.freq) {
          case 'annual':
            cost = rate.cost / daysInYear;
            break;
          case 'quarter':
            cost = (rate.cost * 4) / daysInYear;
            break;
          case 'month':
            cost = (rate.cost * 12) / daysInYear;
            break;
          case 'week':
            cost = (rate.cost * 52) / daysInYear;
            break;
        }

        expense.branches.forEach((branch, i2) => {
          branch.allocations.forEach((branchAllocation) => {
            const days = differenceInDays(
              min([
                expense.effectiveDates.end!,
                i < expense.rates.length - 1
                  ? expense.rates[i + 1].effectiveDate
                  : maxDate,
                i2 < expense.branches.length - 1
                  ? expense.branches[i2 + 1].effectiveDate
                  : maxDate,
              ]),
              max([rate.effectiveDate, branch.effectiveDate]),
            );

            if (days <= 0) return;

            total += ((branchAllocation.percent ?? 100) * cost * days) / 100;
          });
        });
      });

      return { ...expense, total };
    })
    .filter(({ total }) => total > 0);
};

import { _settingsOrganisationBilling } from '@timed/app';
import { Page } from '@timed/page';
import { SettingsOrganisationBillingForm } from '@timed/settings';

const RouteSettingsOrganisationBilling = () => {
  return (
    <Page {..._settingsOrganisationBilling}>
      <SettingsOrganisationBillingForm />
    </Page>
  );
};

export default RouteSettingsOrganisationBilling;

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useAlert } from '@timed/alert';
import { useAuth } from '@timed/auth';
import {
  AbnInput,
  formatAbn,
  FormModal,
  ModalProps,
  ProfileInput,
  TextField,
} from '@timed/common';
import {
  OrderBy,
  Permission,
  QueryByIdInput,
  SetMemberSuperInput,
  useGetMemberSuperDetailsLazyQuery,
  useGetMemberSuperDetailsSelfLazyQuery,
  useUpdateMembersSuperDetailsMutation,
  useUpdateMemberSuperDetailsSelfMutation,
} from '@timed/gql';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type UploadSuperDetailsFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
};

type FormData = QueryByIdInput & {
  patch: {
    super?: SetMemberSuperInput | null;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    numbers: {
      display: 'grid',
      gridTemplateColumns: '85px auto',
      gap: theme.spacing(4),
    },
    group: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      '& .MuiTypography-root': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    select: {
      '&.MuiInputBase-root': {
        minWidth: 120,
      },
    },
  }),
);

const UploadSuperDetailsFormModal = ({
  onClose,
  ...modalProps
}: UploadSuperDetailsFormModalProps) => {
  const classes = useStyles();

  const alert = useAlert();

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>();

  const { permissible } = useAuth();

  const [getExistingValues, existingValuesResponse] =
    useGetMemberSuperDetailsLazyQuery();

  const [getExistingValuesSelf, existingValuesSelfResponse] =
    useGetMemberSuperDetailsSelfLazyQuery();

  const [updateOther, updateOtherResponse] =
    useUpdateMembersSuperDetailsMutation();

  const [updateSelf, updateSelfResponse] =
    useUpdateMemberSuperDetailsSelfMutation();

  const memberId = watch('id');

  useEffect(() => {
    if (permissible({ admin: true })) {
      if (!!memberId) getExistingValues({ variables: { id: memberId } });
    } else {
      getExistingValuesSelf();
    }
  }, [permissible, memberId, getExistingValues, getExistingValuesSelf]);

  useEffect(() => {
    if (!!existingValuesResponse.data) {
      setValue(
        'patch.super.usi',
        existingValuesResponse.data.memberById.super?.usi ?? '',
      );

      setValue(
        'patch.super.fundName',
        existingValuesResponse.data.memberById.super?.fundName ?? '',
      );

      setValue(
        'patch.super.fundAbn',
        formatAbn(existingValuesResponse.data.memberById.super?.fundAbn) ?? '',
      );

      setValue(
        'patch.super.accountNumber',
        existingValuesResponse.data.memberById.super?.accountNumber ?? '',
      );

      setValue(
        'patch.super.accountName',
        existingValuesResponse.data.memberById.super?.accountName ?? '',
      );
    }
  }, [existingValuesResponse.data, setValue]);

  useEffect(() => {
    if (!!existingValuesSelfResponse.data) {
      setValue(
        'patch.super.usi',
        existingValuesSelfResponse.data.me.member?.super?.usi ?? '',
      );

      setValue(
        'patch.super.fundName',
        existingValuesSelfResponse.data.me.member?.super?.fundName ?? '',
      );

      setValue(
        'patch.super.fundAbn',
        formatAbn(existingValuesSelfResponse.data.me.member?.super?.fundAbn) ??
          '',
      );

      setValue(
        'patch.super.accountNumber',
        existingValuesSelfResponse.data.me.member?.super?.accountNumber ?? '',
      );

      setValue(
        'patch.super.accountName',
        existingValuesSelfResponse.data.me.member?.super?.accountName ?? '',
      );
    }
  }, [existingValuesSelfResponse.data, setValue]);

  const onSuccess = () => {
    alert.push({
      message: 'Successfully set superannuation details',
      severity: 'success',
    });
  };

  const onSubmit = ({ patch }: FormData) => {
    memberId
      ? updateOther({
          variables: {
            input: { ids: [memberId], patch },
          },
        })
      : updateSelf({
          variables: {
            input: { patch },
          },
        });
  };

  return (
    <FormModal
      modalProps={modalProps}
      title="Superannuation Details"
      loading={updateOtherResponse.loading || updateSelfResponse.loading}
      success={!!updateOtherResponse.data || !!updateSelfResponse.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        {permissible({ permissions: Permission.MEMBER_WRITE }) && (
          <Box className={classes.group}>
            <ProfileInput
              required
              control={control}
              formControlProps={{ size: 'small', variant: 'outlined' }}
              watch={watch}
              type="member"
              name="id"
              label="Employee"
              className={classes.select}
              orderBy={[{ lastName: OrderBy.ASC }]}
            />
          </Box>
        )}

        {(!permissible({ permissions: Permission.MEMBER_WRITE }) ||
          (permissible({ permissions: Permission.MEMBER_WRITE }) &&
            memberId &&
            existingValuesResponse.data)) && (
          <>
            <Box className={classes.group}>
              <Typography>Fund ABN (if known)</Typography>
              <AbnInput
                fullWidth
                variant="outlined"
                size="small"
                label={undefined}
                name="patch.super.fundAbn"
                control={control}
                error={!!errors.patch?.super?.fundAbn}
                helperText={errors.patch?.super?.fundAbn?.message}
              />
            </Box>
            <Box className={classes.group}>
              <Typography>Fund Name</Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label={undefined}
                name="patch.super.fundName"
                control={control}
                error={!!errors.patch?.super?.fundName}
                helperText={errors.patch?.super?.fundName?.message}
              />
            </Box>
            <Box className={classes.group}>
              <Typography>
                Unique Superannuation Identifier (USI) (if known)
              </Typography>
              <TextField
                fullWidth
                name="patch.super.usi"
                variant="outlined"
                size="small"
                label={undefined}
                control={control}
                error={!!errors.patch?.super?.usi}
                helperText={errors.patch?.super?.usi?.message}
              />
            </Box>
            <Box className={classes.group}>
              <Typography>Your account name</Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label={undefined}
                name="patch.super.accountName"
                control={control}
                error={!!errors.patch?.super?.accountName}
                helperText={errors.patch?.super?.accountName?.message}
              />
            </Box>
            <Box className={classes.group}>
              <Typography>Your member number</Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label={undefined}
                name="patch.super.accountNumber"
                control={control}
                error={!!errors.patch?.super?.accountNumber}
                helperText={errors.patch?.super?.accountNumber?.message}
              />
            </Box>
          </>
        )}
      </Box>
    </FormModal>
  );
};

export default UploadSuperDetailsFormModal;

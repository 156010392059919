import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { Typography, TypographyProps } from '@mui/material';
import { _schedule } from '@timed/app';
import { Link, intersperse } from '@timed/common';
import { pluralise } from '@timed/common/utils/pluralise';
import { Event } from '@timed/gql';
import {
  addDays,
  addWeeks,
  isAfter,
  isBefore,
  isEqual,
  startOfToday,
  startOfTomorrow,
  startOfWeek,
} from 'date-fns';
import { useMemo } from 'react';

type UpcomingShiftsProps = TypographyProps & {
  events?: Pick<Event, 'id' | 'startAt' | 'endAt'>[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  }),
);

const StartUpcomingShifts = ({ events, ...props }: UpcomingShiftsProps) => {
  const classes = useStyles();

  const [thisWeek, nextWeek, today, tomorrow] = useMemo(() => {
    const startOfThisWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
    const startOfNextWeek = addWeeks(startOfThisWeek, 1);

    return [
      // This week
      events?.filter(({ startAt }) =>
        isBefore(new Date(startAt), startOfNextWeek),
      ),

      // Next week
      events?.filter(
        ({ startAt }) =>
          isAfter(new Date(startAt), startOfNextWeek) ||
          isEqual(new Date(startAt), startOfNextWeek),
      ),

      // Today
      events?.filter(
        ({ startAt }) =>
          (isAfter(new Date(startAt), startOfToday()) ||
            isEqual(new Date(startAt), startOfToday())) &&
          isBefore(new Date(startAt), startOfTomorrow()),
      ),

      // Tomorrow
      events?.filter(
        ({ startAt }) =>
          (isAfter(new Date(startAt), startOfTomorrow()) ||
            isEqual(new Date(startAt), startOfTomorrow())) &&
          isBefore(new Date(startAt), addDays(startOfTomorrow(), 1)),
      ),
    ];
  }, [events]);

  const total = useMemo(
    () => (thisWeek?.length || 0) + (nextWeek?.length || 0),
    [thisWeek?.length, nextWeek?.length],
  );

  return (
    <Typography {...props}>
      You have{' '}
      <Link to={_schedule.path} className={classes.link}>
        {total} upcoming{' '}
        {pluralise({ singular: 'shift', plural: 'shifts', quantity: total })}
      </Link>
      .
      {total > 0 && (
        <>
          {' '}
          {intersperse({
            array: [
              !!thisWeek?.length ? `${thisWeek.length} this week` : undefined,
              !!nextWeek?.length ? `${nextWeek.length} next week` : undefined,
              !!today?.length ? `${today.length} today` : undefined,
              !!tomorrow?.length ? `${tomorrow.length} tomorrow` : undefined,
            ].filter(Boolean),
          })}
          .
        </>
      )}
    </Typography>
  );
};

export default StartUpcomingShifts;

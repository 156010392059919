import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { ClientContactRelationsInput } from '@timed/client';
import {
  addServerErrors,
  Checkbox,
  EmailInput,
  formatPersonName,
  FormModal,
  ModalProps,
  PersonNameInput,
  PhoneInput,
  TextField,
} from '@timed/common';
import {
  Client,
  CreateClientContactDocument,
  CreateClientContactInput,
  PersonNamesPartialFragment,
  useCreateClientContactMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type ClientCreateContactFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  client: Pick<Client, 'id'> & PersonNamesPartialFragment;
};

type FormData = Omit<CreateClientContactInput, 'client'>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputs: {
      flex: '1 0 auto',
      overflowY: 'auto',
      display: 'grid',
      gridAutoFlow: 'row',
      gridAutoRows: 'max-content',
      gap: theme.spacing(4),
    },
  }),
);

const ClientCreateContactFormModal = ({
  client,
  onClose,
  ...modalProps
}: ClientCreateContactFormModalProps) => {
  const classes = useStyles();

  const [createEntity, response] = useCreateClientContactMutation();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<FormData>({ defaultValues: { relations: [] } });

  useLoadingEffect(response.loading);

  const onSubmit = (values: FormData) => {
    createEntity({
      variables: {
        input: {
          client: { id: client.id },
          ...values,
        },
      },
    }).catch((e) => {});
  };

  const onSuccess = () => {
    const cache = response.client.cache;

    cache.modify({
      fields: {
        clientContacts(existing = []) {
          return [
            ...existing,
            cache.writeQuery({
              data: response.data,
              query: CreateClientContactDocument,
            }),
          ];
        },
      },
    });
  };

  /**
   * Display error messages from server response
   */
  useEffect(() => {
    if (response.error) addServerErrors(response.error, setError);
  }, [response.error, setError]);

  return (
    <FormModal
      modalProps={modalProps}
      title="Create Contact Person"
      description={`For ${formatPersonName(client, {
        lastNameFirst: true,
        capitaliseLastName: true,
      })}`}
      saveText="Save"
      loading={response.loading}
      success={!!response.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.inputs}>
        <PersonNameInput
          required
          name="name"
          variant="outlined"
          size="small"
          label="Name"
          control={control}
          error={!!errors.name}
          helperText={errors.name?.message}
        />
        <EmailInput
          name="email"
          variant="outlined"
          size="small"
          label="Email Address"
          control={control}
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        <PhoneInput
          name="phone"
          variant="outlined"
          size="small"
          label="Phone Number"
          control={control}
          error={!!errors.phone}
          helperText={errors.phone?.message}
        />
        <ClientContactRelationsInput
          name="relations"
          control={control}
          error={!!errors.relations}
          helperText={errors.relations?.message}
          formControlProps={{ size: 'small', variant: 'outlined' }}
        />
        <TextField
          name="customRelation"
          variant="outlined"
          size="small"
          label="Additional relations"
          type="text"
          validation={{ maxLength: { value: 255, message: 'Too long' } }}
          control={control}
          error={!!errors.customRelation}
          helperText={errors.customRelation?.message}
        />
        <Checkbox
          name="emergencyContact"
          size="small"
          label="Emergency contact"
          defaultChecked={false}
          control={control}
          error={!!errors.emergencyContact}
          helperText={errors.emergencyContact?.message}
        />
        <Checkbox
          name="preferred"
          size="small"
          label="This is the preferred contact"
          defaultChecked={false}
          control={control}
          error={!!errors.preferred}
          helperText={errors.preferred?.message}
        />
      </Box>
    </FormModal>
  );
};

export default ClientCreateContactFormModal;

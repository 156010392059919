import {
  Box,
  createStyles,
  Divider,
  makeStyles,
  styled,
} from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import {
  DefaultizedPieValueType,
  pieArcLabelClasses,
  PieChart,
  useDrawingArea,
} from '@mui/x-charts';
import { useAuth } from '@timed/auth';
import {
  Button,
  ButtonAsync,
  Checkbox,
  DateInput,
  formatPersonName,
  JSONInput,
  roundNumber,
  Select,
  SelectMenuItem,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from '@timed/common';
import { formatCurrency } from '@timed/common/utils/formatCurrency';
import { AccountedEvent, accountedEventWithShifts } from '@timed/event';
import {
  EntityState,
  OrderBy,
  ReportShiftProfitLossGetHolidaysEventsQuery,
  useReportShiftProfitLoss2GetFixedExpensesLazyQuery,
  useReportShiftProfitLoss2GetHolidaysEventsLazyQuery,
  useReportShiftProfitLoss2GetPayRatesLazyQuery,
  useReportShiftProfitLoss2GetRatesClientsMembersLazyQuery,
  useUpdateOrgFixedExpensesMutation,
} from '@timed/gql';
import { ReportProfitAndLossStatementExportModal } from '@timed/report';
import { formatFixedExpenses } from '@timed/report/helpers';
import { formatEmployeeExpenses } from '@timed/report/helpers/formatEmployeeExpenses';
import {
  addMonths,
  eachDayOfInterval,
  format,
  isEqual,
  isValid,
  setDate,
  setMonth,
  startOfToday,
  startOfWeek,
  startOfYear,
  subDays,
  subMilliseconds,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';
import addDays from 'date-fns/addDays';
import { useModal } from 'mui-modal-provider';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

export type ExpenseRate<T> = {
  effectiveDate: T;
  cost: number;
  freq?: 'week' | 'month' | 'quarter' | 'annual';
};

export type ExpenseBranchAllocation<T> = {
  branch: string;
  percent?: T extends string ? number | undefined : number;
};

export type ExpenseBranch<T> = {
  effectiveDate: T;
  allocations: ExpenseBranchAllocation<T>[];
};

/**
 * A description of a cost incurred by a business.
 */
export type Expense<T = string> = {
  /**
   * Description of the expense.
   */
  name: string;
  /**
   * Type of the expense. Director, Support Coordinator, Office Administrator, Rent etc.
   */
  type: string;
  /**
   * Effective dates of the expense.
   */
  effectiveDates: {
    /**
     * Effective-from date.
     */
    start: T;
    /**
     * Effective-to date (optional).
     */
    end?: T;
  };
  /**
   * Rates of the expense.
   */
  rates: ExpenseRate<T>[];
  /**
   * Branch allocation data of the expense.
   */
  branches: ExpenseBranch<T>[];
};

/**
 * A calculated cost incurred by a business.
 */
export type ComputedExpense = Expense<Date> & {
  /**
   * Totals for each relevant branch.
   */
  total: number;
};

type TotalItem = {
  name: string;
  total: number;
};

type Total = {
  branchId?: string;
  revenue: {
    items: TotalItem[];
    total: number;
  };
  cos: {
    items: TotalItem[];
    total: number;
  };
  expenses: {
    fixed: {
      items: TotalItem[];
      total: number;
    };
    payroll: {
      employeeClassifications: {
        name: string;
        items: TotalItem[];
        total: number;
      }[];
      superannuations: {
        items: TotalItem[];
        total: number;
      };
      taxes: {
        items: TotalItem[];
        total: number;
      };
      workerCompensationInsurances: {
        items: TotalItem[];
        total: number;
      };
      total: number;
    };
    total: number;
  };
};

type Stats = {
  clientCount: number;
  memberCount: number;
  kms: number;
  hours: {
    active: number;
    travel: number;
    passive: number;
  };
};

type FormData = {
  clients: string[];
  members: string[];
  period:
    | 'previous'
    | 'previous2'
    | 'current'
    | 'custom'
    | 'month'
    | '3months'
    | '6months'
    | '12months'
    | 'this-financial-year'
    | 'last-financial-year'
    | undefined;
  from?: Date;
  to?: Date;
  includeFixedExpenses: boolean;
  fixedExpenses?: string;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    textarea: {
      width: 800,
      backgroundColor: theme.palette.background.default,
    },
    row: {
      display: 'flex',
      alignItems: 'start',
      gap: theme.spacing(4),
    },
    codeInput: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    codeInputButtons: {
      display: 'flex',
      gap: theme.spacing(4),
      '& .MuiButton-root': {
        flexGrow: 1,
      },
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    dataWrapper: {
      display: 'flex',
      gap: theme.spacing(4),
      alignItems: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        flexFlow: 'column',
      },
    },
    stats: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      alignItems: 'flex-start',
    },
  }),
);

const ReportProfitAndLossStatement = () => {
  const classes = useStyles();

  const { showModal } = useModal();

  const [showSupportWorkerStats, setShowSupportWorkerStats] =
    useState<boolean>(false);

  const [showParticipantStats, setShowParticipantStats] =
    useState<boolean>(false);

  const { branch, branches, member } = useAuth();

  const WEEK_STARTS_ON = 1; // MONDAY

  const periods = useMemo<SelectMenuItem[]>(
    () => [
      { label: 'Most recently ended payroll period', value: 'previous' },
      { label: 'Current payroll period', value: 'current' },
      { label: 'Payroll period two weeks ago', value: 'previous2' },
      { label: '1 month', value: 'month' },
      { label: '3 months', value: '3months' },
      { label: '6 months', value: '6months' },
      { label: '12 months', value: '12months' },
      { label: 'This financial year-to-date', value: 'this-financial-year' },
      { label: 'Last financial year', value: 'last-financial-year' },
      { label: 'Custom date', value: 'custom' },
    ],
    [],
  );

  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      period: 'previous',
      clients: [],
      members: [],
      includeFixedExpenses: false,
    },
  });

  const clients = watch('clients');
  const members = watch('members');
  const period = watch('period');
  const from = watch('from');
  const to = watch('to');
  const fixedExpenses = watch('fixedExpenses');
  const includeFixedExpenses = watch('includeFixedExpenses');

  const [saveFixedExpenses, saveFixedExpensesResponse] =
    useUpdateOrgFixedExpensesMutation();

  const [getInitialData, initialResponse] =
    useReportShiftProfitLoss2GetRatesClientsMembersLazyQuery({
      fetchPolicy: 'network-only',
      refetchWritePolicy: 'overwrite',
    });

  const [getSubsequentData, subsequentResponse] =
    useReportShiftProfitLoss2GetHolidaysEventsLazyQuery({
      fetchPolicy: 'network-only',
      refetchWritePolicy: 'overwrite',
    });

  const [getPayRateData, payRateDataResponse] =
    useReportShiftProfitLoss2GetPayRatesLazyQuery({
      fetchPolicy: 'network-only',
      refetchWritePolicy: 'overwrite',
    });

  const [getFixedExpensesData, fixedExpensesResponse] =
    useReportShiftProfitLoss2GetFixedExpensesLazyQuery({
      fetchPolicy: 'network-only',
      refetchWritePolicy: 'overwrite',
    });

  /** Apply default value to fixedExpenses input */
  useEffect(() => {
    if (initialResponse.data?.me.member?.org.fixedExpenses)
      setValue(
        'fixedExpenses',
        JSON.stringify(
          JSON.parse(initialResponse.data?.me.member?.org.fixedExpenses),
          undefined,
          2,
        ),
      );
  }, [initialResponse.data?.me.member?.org.fixedExpenses, setValue]);

  /**
   * Expenses relevant to the specified time period and branch.
   */
  const computedExpenses = useMemo<ComputedExpense[]>(() => {
    if (
      includeFixedExpenses &&
      !!fixedExpenses &&
      !!from &&
      isValid(from) &&
      !!to &&
      isValid(to)
    ) {
      try {
        return formatFixedExpenses({
          from,
          to: addDays(to, 1), // End of date
          branch,
          expenses: JSON.parse(fixedExpenses),
        });
      } catch (e) {
        return [];
      }
    }
    return [];
  }, [includeFixedExpenses, fixedExpenses, from, to, branch]);

  useEffect(() => {
    if (period) {
      switch (period) {
        case 'current':
          setValue(
            'from',
            startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
          );
          setValue(
            'to',
            addDays(
              startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
              6,
            ),
          );
          break;
        case 'previous':
          setValue(
            'from',
            subWeeks(
              startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
              1,
            ),
          );
          setValue(
            'to',
            subDays(
              startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
              1,
            ),
          );
          break;
        case 'previous2':
          setValue(
            'from',
            subWeeks(
              startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
              2,
            ),
          );
          setValue(
            'to',
            subDays(
              startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
              8,
            ),
          );
          break;
        case 'month':
          setValue('from', subMonths(startOfToday(), 1));
          setValue('to', subDays(startOfToday(), 1));
          break;
        case '3months':
          setValue('from', subMonths(startOfToday(), 3));
          setValue('to', subDays(startOfToday(), 1));
          break;
        case '6months':
          setValue('from', subMonths(startOfToday(), 6));
          setValue('to', subDays(startOfToday(), 1));
          break;
        case '12months':
          setValue('from', subMonths(startOfToday(), 12));
          setValue('to', subDays(startOfToday(), 1));
          break;
        case 'this-financial-year':
          setValue(
            'from',
            setMonth(setDate(subYears(startOfYear(new Date()), 1), 1), 6),
          );
          setValue('to', subDays(startOfToday(), 1));
          break;
        case 'last-financial-year':
          setValue(
            'from',
            setMonth(setDate(subYears(startOfYear(new Date()), 2), 1), 6),
          );
          setValue(
            'to',
            setMonth(setDate(subYears(startOfYear(new Date()), 1), 30), 5),
          );
          break;
      }
    }
  }, [period, setValue]);

  useEffect(() => {
    if (!!from && !!to) {
      if (
        isEqual(
          from,
          startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
        ) &&
        isEqual(
          to,
          addDays(startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }), 6),
        )
      ) {
        setValue('period', 'current');
      } else if (
        isEqual(
          from,
          subWeeks(
            startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
            1,
          ),
        ) &&
        isEqual(
          to,
          subDays(startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }), 1),
        )
      ) {
        setValue('period', 'previous');
      } else {
        setValue('period', 'custom');
      }
    }
  }, [from, to, setValue]);

  /**
   * Fetch initial data.
   */
  useEffect(() => {
    if (!!from && isValid(from) && !!to && isValid(to))
      getInitialData({
        variables: {
          clientsInput: {
            where: branch ? { branch: { id: { _eq: branch.id } } } : undefined,
            orderBy: [
              { lastName: OrderBy.ASC },
              { firstName: OrderBy.ASC_NULLS_FIRST },
            ],
            entityStates: [EntityState.ARCHIVED, EntityState.NORMAL],
          },
          membersInput: {
            where: {
              branchMembers: branch
                ? { branch: { id: { _eq: branch.id } } }
                : undefined,
              events: {
                startAt: { _lte: addDays(to, 1) },
                endAt: { _gte: from },
              },
            },
            orderBy: [
              { lastName: OrderBy.ASC },
              { firstName: OrderBy.ASC_NULLS_FIRST },
            ],
            entityStates: [EntityState.ARCHIVED, EntityState.NORMAL],
          },
          memberPayRatesInput: {
            where: {
              type: {
                _eq: 1,
              },
              // member: !!branches.length
              //   ? {
              //       branchMembers: {
              //         branch: {
              //           id: !!branch
              //             ? { _eq: branch.id }
              //             : { _in: branches.map(({ id }) => id) },
              //         },
              //       },
              //     }
              //   : undefined,
            },
          },
          memberBranchAllocationsInput: {
            where: {
              deletedAt: null,
              // member: !!branches.length
              //   ? {
              //       branchMembers: {
              //         branch: {
              //           id: !!branch
              //             ? { _eq: branch.id }
              //             : { _in: branches.map(({ id }) => id) },
              //         },
              //       },
              //     }
              //   : undefined,
            },
          },
        },
      });
  }, [getInitialData, branch, to, from, branches]);

  /**
   * Fetch fixed expenses.
   */
  useEffect(() => {
    if (
      !!initialResponse.data?.memberBasePayRates.length &&
      !!initialResponse.data.memberBranchAllocations.length
    )
      getFixedExpensesData({
        variables: {
          membersInput: {
            where: {
              id: {
                _in: [
                  ...new Set([
                    ...initialResponse.data.memberBasePayRates.map(
                      ({ member }) => member.id,
                    ),
                    ...initialResponse.data.memberBranchAllocations.map(
                      ({ member }) => member.id,
                    ),
                  ]),
                ],
              },
            },
            orderBy: [
              { lastName: OrderBy.ASC },
              { firstName: OrderBy.ASC_NULLS_FIRST },
            ],
            entityStates: [EntityState.ARCHIVED, EntityState.NORMAL],
          },
        },
      });
  }, [
    getFixedExpensesData,
    initialResponse.data?.memberBranchAllocations,
    initialResponse.data?.memberBasePayRates,
  ]);

  const fixedEmployees = useMemo(
    () =>
      // Filter out employess which lack:
      // 1) An employment start date, or
      // 2) Pay rate data, or
      // 3) Branch allocation data
      fixedExpensesResponse.data?.members
        .filter(
          ({ id, employmentStartDate }) =>
            !!employmentStartDate &&
            initialResponse.data?.memberBranchAllocations
              .map(({ member }) => member.id)
              .includes(id) &&
            initialResponse.data?.memberBasePayRates
              .map(({ member }) => member.id)
              .includes(id),
        )
        .map((member) => ({
          ...member,
          payRates: initialResponse.data?.memberBasePayRates.filter(
            ({ member: { id } }) => id === member.id,
          ),
          branchAllocations:
            initialResponse.data?.memberBranchAllocations.filter(
              ({ member: { id } }) => id === member.id,
            ),
        })),
    [
      initialResponse.data?.memberBranchAllocations,
      initialResponse.data?.memberBasePayRates,
      fixedExpensesResponse.data?.members,
    ],
  );

  /**
   * Employee expenses relevant to the specified time period and branch.
   */
  const computedEmployeeExpenses = useMemo<ComputedExpense[]>(() => {
    if (
      includeFixedExpenses &&
      !!fixedEmployees &&
      !!from &&
      isValid(from) &&
      !!to &&
      isValid(to)
    ) {
      try {
        return formatEmployeeExpenses({
          from,
          to: addDays(to, 1), // End of date
          branch,
          employees: fixedEmployees,
        });
      } catch (e) {
        return [];
      }
    }
    return [];
  }, [includeFixedExpenses, fixedEmployees, from, to, branch]);

  /**
   * Run report.
   */
  const runReport = useCallback(() => {
    if (
      !!from &&
      isValid(from) &&
      !!to &&
      isValid(to) &&
      (!!clients.length || !!members.length)
    )
      getSubsequentData({
        variables: {
          eventsInput: {
            where: {
              startAt: { _lte: addDays(to, 1) },
              endAt: { _gte: from },
              client:
                !!branch || !!clients.length
                  ? {
                      branch: branch ? { id: { _eq: branch.id } } : undefined,
                      id: clients.length ? { _in: clients } : undefined,
                    }
                  : undefined,
              member: members.length
                ? { id: { _in: members } }
                : { id: { _ne: null } },
              _or: [{ billable: { _eq: true } }, { payable: { _eq: true } }],
            },
            orderBy: [{ startAt: OrderBy.ASC }],
          },
          publicHolidaysInput: {
            where: {
              date: {
                _gte: format(from, 'yyyy-MM-dd'),
                _lte: format(to, 'yyyy-MM-dd'),
              },
            },
            orderBy: [{ id: OrderBy.ASC }],
          },
        },
      });
  }, [getSubsequentData, from, to, clients, members, branch]);

  useEffect(() => {
    if (
      subsequentResponse.data?.eventsNoCache.length &&
      !!from &&
      isValid(from) &&
      !!to &&
      isValid(to)
    )
      getPayRateData({
        variables: {
          membersInput: {
            where: {
              id: {
                _in: [
                  ...new Set(
                    subsequentResponse.data.eventsNoCache.map(
                      ({ member }) => member!.id,
                    ),
                  ),
                ],
              },
            },
            entityStates: [EntityState.NORMAL, EntityState.ARCHIVED],
          },
          memberBasePayRatesInput: {
            where: {
              type: {
                _eq: 0,
              },
              member: {
                id: {
                  _in: [
                    ...new Set(
                      subsequentResponse.data.eventsNoCache.map(
                        ({ member }) => member!.id,
                      ),
                    ),
                  ],
                },
              },
              // date: {
              //   _gte: jsDateToLocalISO8601DateString(from),
              //   _lte: jsDateToLocalISO8601DateString(to),
              // },
            },
            orderBy: [{ date: OrderBy.ASC }],
          },
        },
      });
  }, [getPayRateData, subsequentResponse.data, from, to]);

  const accountedEvents = useMemo<
    AccountedEvent<ReportShiftProfitLossGetHolidaysEventsQuery['events'][0]>[]
  >(
    () =>
      !from ||
      !to ||
      (!members.length && !clients.length) ||
      !initialResponse.data?.clients ||
      !initialResponse.data?.members ||
      !initialResponse.data.me.member ||
      !subsequentResponse.data?.eventsNoCache ||
      !subsequentResponse.data.publicHolidays ||
      !payRateDataResponse.data
        ? []
        : subsequentResponse.data.eventsNoCache.map((event) => {
            const eachDayOfEvent = eachDayOfInterval({
              start: new Date(event.startAt),
              end: subMilliseconds(new Date(event.endAt), 1),
            }).map((date) => format(date, 'yyyy-MM-dd'));

            const occursDuringPublicHoliday =
              !!event.publicHoliday ||
              subsequentResponse.data!.publicHolidays.some(
                ({ region, date }) =>
                  eachDayOfEvent.includes(date) &&
                  (!region || region === event.region),
              );

            return accountedEventWithShifts({
              occursDuringPublicHoliday,
              dates: { from, to: addDays(to, 1) },
              orgPayRates: initialResponse.data!.me.member!.org,
              memberPayRates: payRateDataResponse.data!.members,
              event: {
                ...event,
                member: !!event.member
                  ? {
                      ...event.member,
                      bonusEligibleStartAt:
                        payRateDataResponse.data?.members.find(
                          ({ id }) => id === event.member?.id,
                        )?.bonusEligibleStartAt ?? null,
                    }
                  : undefined,
              },
            });
          }),
    [
      initialResponse.data,
      subsequentResponse.data,
      payRateDataResponse.data,
      from,
      to,
      members,
      clients,
    ],
  );

  const total = useMemo<Total>(() => {
    // return (!branch ? branches : [branch]).map((branch) => {

    const ndisSupportNumbers = [
      ...new Set(
        accountedEvents.flatMap(({ revenue }) =>
          revenue.map(({ billingRate }) => billingRate.supportItemNumber),
        ),
      ),
    ].sort((a, b) => a.localeCompare(b));

    const payrollCategories = [
      ...new Set(
        accountedEvents.flatMap(({ expenses }) =>
          expenses.map(({ category }) => category),
        ),
      ),
    ].sort((a, b) => a.localeCompare(b));

    const revenueItems = ndisSupportNumbers
      .map((name) => ({
        name: name.toString(),
        total: !!accountedEvents.length
          ? accountedEvents
              .flatMap(({ revenue }) =>
                revenue
                  .filter(
                    ({ billingRate }) => billingRate.supportItemNumber === name,
                  )
                  .map(({ revenue }) => revenue),
              )
              .reduce((a, b) => a + b, 0)
          : 0,
      }))
      .sort((a, b) => a.total - b.total);

    const revenue = {
      items: revenueItems,
      total: !!revenueItems.length
        ? revenueItems.map(({ total }) => total).reduce((a, b) => a + b)
        : 0,
    };

    const expenseFixedItems = computedExpenses
      .map(({ name, total }) => ({ name: name.toString(), total }))
      .sort((a, b) => a.total - b.total);

    const expenseFixedTotal = {
      items: expenseFixedItems,
      total: !!expenseFixedItems.length
        ? expenseFixedItems.map(({ total }) => total).reduce((a, b) => a + b)
        : 0,
    };

    const expensePayrollOfficeWorkerItems = computedEmployeeExpenses.map(
      ({ name, total }) => ({ name: name.toString(), total }),
    );
    // .sort((a, b) => a.total - b.total);

    const expensePayrollSupportWorkerWagesItems = payrollCategories
      .filter((name) => name.toString() !== 'Km allowance')
      .map((name) => ({
        name: name.toString(),
        total: accountedEvents
          .flatMap(({ expenses }) =>
            expenses
              .filter(({ category }) => category === name)
              .map(({ expense }) => expense),
          )
          .reduce((a, b) => a + b, 0),
      }))
      .sort((a, b) => a.total - b.total);

    const expensePayrollEmployeeClassifications = [
      {
        name: 'Support Worker Wages',
        items: expensePayrollSupportWorkerWagesItems,
        total: !!expensePayrollSupportWorkerWagesItems.length
          ? expensePayrollSupportWorkerWagesItems
              .map(({ total }) => total)
              .reduce((a, b) => a + b)
          : 0,
      },
      {
        name: 'Office Staff Salaries',
        items: expensePayrollOfficeWorkerItems,
        total: !!expensePayrollOfficeWorkerItems.length
          ? expensePayrollOfficeWorkerItems
              .map(({ total }) => total)
              .reduce((a, b) => a + b)
          : 0,
      },
    ];

    const expensePayrollTaxesItems = [
      {
        name: '5.45%',
        total:
          expensePayrollEmployeeClassifications
            .map(({ total }) => total)
            .reduce((a, b) => a + b) *
          (5.45 / 100),
      },
    ];

    const expensePayrollTaxes = {
      items: expensePayrollTaxesItems,
      total: !!expensePayrollTaxesItems.length
        ? expensePayrollTaxesItems
            .map(({ total }) => total)
            .reduce((a, b) => a + b)
        : 0,
    };

    const expensePayrollSuperItems = [
      {
        name: '11%',
        total:
          expensePayrollEmployeeClassifications
            .map(({ total }) => total)
            .reduce((a, b) => a + b) *
          (11 / 100),
      },
    ];

    const expensePayrollSuper = {
      items: expensePayrollSuperItems,
      total: !!expensePayrollSuperItems.length
        ? expensePayrollSuperItems
            .map(({ total }) => total)
            .reduce((a, b) => a + b)
        : 0,
    };

    const expensePayrollWCIItems = [
      {
        name: '5.09%',
        total:
          expensePayrollEmployeeClassifications
            .map(({ total }) => total)
            .reduce((a, b) => a + b) *
          (5.09 / 100),
      },
    ];

    const expensePayrollWCI = {
      items: expensePayrollWCIItems,
      total: !!expensePayrollWCIItems.length
        ? expensePayrollWCIItems
            .map(({ total }) => total)
            .reduce((a, b) => a + b)
        : 0,
    };

    const expensePayrollTotel =
      expensePayrollEmployeeClassifications
        .map(({ total }) => total)
        .reduce((a, b) => a + b) +
      expensePayrollTaxes.total +
      expensePayrollSuper.total +
      expensePayrollWCI.total;

    const expenses = {
      fixed: expenseFixedTotal,
      payroll: {
        employeeClassifications: expensePayrollEmployeeClassifications,
        taxes: expensePayrollTaxes,
        superannuations: expensePayrollSuper,
        workerCompensationInsurances: expensePayrollWCI,
        total: expensePayrollTotel,
      },
      total: expenseFixedTotal.total + expensePayrollTotel,
    };

    const cosItems = payrollCategories
      .filter((name) => name.toString() === 'Km allowance')
      .map((name) => ({
        name: name.toString(),
        total: accountedEvents
          .flatMap(({ expenses }) =>
            expenses
              .filter(({ category }) => category.toString() === 'Km allowance')
              .map(({ expense }) => expense),
          )
          .reduce((a, b) => a + b, 0),
      }))
      .sort((a, b) => a.total - b.total);

    const cos = {
      items: cosItems,
      total: !!cosItems.length
        ? cosItems.map(({ total }) => total).reduce((a, b) => a + b)
        : 0,
    };

    return {
      branchId: branch?.id,
      revenue,
      cos,
      expenses,
    };
    // });
  }, [branch, computedExpenses, computedEmployeeExpenses, accountedEvents]);

  const stats = useMemo<Stats>(
    () => ({
      clientCount: [...new Set(accountedEvents.map(({ client }) => client.id))]
        .length,
      memberCount: [
        ...new Set(
          accountedEvents.map(({ member }) => member?.id).filter(Boolean),
        ),
      ].length,
      kms:
        !!accountedEvents.length &&
        accountedEvents.some(({ travelDistance }) => !!travelDistance)
          ? roundNumber(
              (accountedEvents
                .filter(({ travelDistance }) => !!travelDistance)
                .map(({ travelDistance }) => travelDistance)
                .reduce((a, b) => a! + b!) as number) / 1000,
              2,
            )
          : 0,
      hours: {
        active: !!accountedEvents.length
          ? roundNumber(
              accountedEvents
                .flatMap(({ expenses }) =>
                  expenses
                    .filter(({ shift }) => !!shift && !shift.passive)
                    .map(({ shift }) => shift!.duration),
                )
                .reduce((a, b) => a + b, 0) / 60,
              2,
            )
          : 0,
        passive: !!accountedEvents.length
          ? roundNumber(
              accountedEvents
                .flatMap(({ expenses }) =>
                  expenses
                    .filter(({ shift }) => !!shift && shift.passive)
                    .map(({ shift }) => shift!.duration),
                )
                .reduce((a, b) => a + b, 0) / 60,
              2,
            )
          : 0,
        travel: !!accountedEvents.length
          ? roundNumber(
              accountedEvents
                .filter(({ travelTime }) => !!travelTime)
                .map(({ travelTime }) => travelTime!)
                .reduce((a, b) => a + b, 0) / 60,
              2,
            )
          : 0,
      },
    }),
    [accountedEvents],
  );

  useEffect(() => {
    setValue('members', []);
    setValue('clients', []);
  }, [branch, setValue]);

  const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 18,
  }));

  function PieCenterLabel({ children }: { children: React.ReactNode }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
    );
  }

  const table = useMemo(
    () => (
      <Table
        inline
        enableRowHighlighting
        persistentRowHighlighting
        loading={subsequentResponse.loading}
        border="line"
      >
        <TableHeader style={{ width: 'auto' }}>
          Profit & Loss Statement
        </TableHeader>
        <TableHeader align="right" style={{ paddingRight: 128 }}></TableHeader>
        <TableHeader align="right"></TableHeader>
        <TableHeader align="right"></TableHeader>
        <TableHeader align="right"></TableHeader>

        <TableRow>
          <TableCell colSpan={5} className={classes.bold}>
            Revenue
          </TableCell>
        </TableRow>
        {!!total.revenue.total && (
          <TableRow>
            <TableCell indent={1} colSpan={5} className={classes.bold}>
              Billing Revenue
            </TableCell>
          </TableRow>
        )}
        {total.revenue.items.map(({ name, total }) => (
          <TableRow>
            <TableCell indent={2}>{name}</TableCell>
            <TableCell>{formatCurrency(total / 100)}</TableCell>
            <TableCell colSpan={3}></TableCell>
          </TableRow>
        ))}
        {!!total.revenue.total && (
          <TableRow>
            <TableCell indent={1} colSpan={3}>
              Total Billing Revenue
            </TableCell>
            <TableCell>{formatCurrency(total.revenue.total / 100)}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell colSpan={4}>Total Revenue</TableCell>
          <TableCell>{formatCurrency(total.revenue.total / 100)}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell colSpan={5} className={classes.bold}>
            Cost of Sales
          </TableCell>
        </TableRow>
        {!!total.cos.total && (
          <TableRow>
            <TableCell indent={1} colSpan={5} className={classes.bold}>
              Support Worker Reimbursements
            </TableCell>
          </TableRow>
        )}
        {total.cos.items.map(({ name, total }) => (
          <TableRow>
            <TableCell indent={2}>{name}</TableCell>
            <TableCell>{formatCurrency(total)}</TableCell>
            <TableCell colSpan={3}></TableCell>
          </TableRow>
        ))}
        {!!total.cos.total && (
          <TableRow>
            <TableCell indent={1} colSpan={3}>
              Total Support Worker Reimbursements
            </TableCell>
            <TableCell>
              {formatCurrency(
                total.cos.items
                  .map(({ total }) => total)
                  .reduce((a, b) => a + b),
              )}
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell colSpan={4}>Total Cost of Sales</TableCell>
          <TableCell>{formatCurrency(total.cos.total)}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell colSpan={5} className={classes.bold}>
            Expenses
          </TableCell>
        </TableRow>
        {!!total.expenses.fixed.items.length && (
          <>
            <TableRow>
              <TableCell indent={1} colSpan={5} className={classes.bold}>
                Fixed Expenses
              </TableCell>
            </TableRow>
            {total.expenses.fixed.items.map(({ name, total }, i) => (
              <TableRow key={i}>
                <TableCell indent={2}>{name}</TableCell>
                <TableCell>{formatCurrency(total)}</TableCell>
                <TableCell colSpan={3}></TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell indent={1} colSpan={3}>
                Total Fixed Expenses
              </TableCell>
              <TableCell>
                {formatCurrency(total.expenses.fixed.total)}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </>
        )}
        {(!!total.expenses.payroll.superannuations.total ||
          !!total.expenses.payroll.taxes.total ||
          !!total.expenses.payroll.workerCompensationInsurances.total ||
          total.expenses.payroll.employeeClassifications
            .map(({ total }) => total)
            .reduce((a, b) => a + b)) && (
          <TableRow>
            <TableCell indent={1} colSpan={5} className={classes.bold}>
              Payroll Expenses
            </TableCell>
          </TableRow>
        )}

        {!!total.expenses.payroll.employeeClassifications
          .map(({ total }) => total)
          .reduce((a, b) => a + b) &&
          total.expenses.payroll.employeeClassifications.map(
            ({ name, items, total }) =>
              !!items.length ? (
                <>
                  <TableRow>
                    <TableCell indent={2} colSpan={5} className={classes.bold}>
                      {name} (PAYG inclusive)
                    </TableCell>
                  </TableRow>

                  {items.map(({ name, total }) => (
                    <TableRow>
                      <TableCell indent={3}>{name}</TableCell>
                      <TableCell>{formatCurrency(total)}</TableCell>
                      <TableCell colSpan={3}></TableCell>
                    </TableRow>
                  ))}
                  {total && (
                    <TableRow>
                      <TableCell indent={2} colSpan={2}>
                        Total {name}
                      </TableCell>
                      <TableCell>{formatCurrency(total)}</TableCell>
                      <TableCell colSpan={2}></TableCell>
                    </TableRow>
                  )}
                </>
              ) : null,
          )}

        {(!!total.expenses.payroll.superannuations.total ||
          !!total.expenses.payroll.taxes.total ||
          !!total.expenses.payroll.workerCompensationInsurances.total) && (
          <TableRow>
            <TableCell indent={2} colSpan={5} className={classes.bold}>
              Other Payroll Expenses
            </TableCell>
          </TableRow>
        )}
        {!!total.expenses.payroll.superannuations.total &&
          total.expenses.payroll.superannuations.items.map(
            ({ name, total }) => (
              <TableRow>
                <TableCell indent={3}>Superannuation ({name})</TableCell>
                <TableCell>{formatCurrency(total)}</TableCell>
                <TableCell colSpan={3}></TableCell>
              </TableRow>
            ),
          )}
        {!!total.expenses.payroll.taxes.total &&
          total.expenses.payroll.taxes.items.map(({ name, total }) => (
            <TableRow>
              <TableCell indent={3}>Payroll tax ({name})</TableCell>
              <TableCell>{formatCurrency(total)}</TableCell>
              <TableCell colSpan={3}></TableCell>
            </TableRow>
          ))}
        {!!total.expenses.payroll.workerCompensationInsurances.total &&
          total.expenses.payroll.workerCompensationInsurances.items.map(
            ({ name, total }) => (
              <TableRow>
                <TableCell indent={3}>
                  Workers compensation insurance ({name})
                </TableCell>
                <TableCell>{formatCurrency(total)}</TableCell>
                <TableCell colSpan={3}></TableCell>
              </TableRow>
            ),
          )}
        {(!!total.expenses.payroll.superannuations.total ||
          !!total.expenses.payroll.taxes.total ||
          !!total.expenses.payroll.workerCompensationInsurances.total) && (
          <TableRow>
            <TableCell indent={2} colSpan={2}>
              Total Other Payroll Expenses
            </TableCell>
            <TableCell>
              {formatCurrency(
                total.expenses.payroll.superannuations.total +
                  total.expenses.payroll.taxes.total +
                  total.expenses.payroll.workerCompensationInsurances.total,
              )}
            </TableCell>
            <TableCell colSpan={2}></TableCell>
          </TableRow>
        )}

        {(!!total.expenses.payroll.superannuations.total ||
          !!total.expenses.payroll.taxes.total ||
          !!total.expenses.payroll.workerCompensationInsurances.total ||
          total.expenses.payroll.employeeClassifications
            .map(({ total }) => total)
            .reduce((a, b) => a + b)) && (
          <TableRow>
            <TableCell colSpan={3} indent={1}>
              Total Payroll Expenses
            </TableCell>
            <TableCell>
              {formatCurrency(total.expenses.payroll.total)}
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell colSpan={4}>Total Expenses</TableCell>
          <TableCell>{formatCurrency(total.expenses.total)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={4} className={classes.bold}>
            Net Profit
          </TableCell>
          <TableCell>
            {formatCurrency(
              total.revenue.total / 100 -
                total.cos.total -
                total.expenses.total,
            )}
          </TableCell>
        </TableRow>
      </Table>
    ),
    [total, classes.bold, subsequentResponse.loading],
  );

  const handleOpenPrintModal = () => {
    const modal: { hide: () => void } = showModal(
      ReportProfitAndLossStatementExportModal,
      {
        onClose: () => modal.hide(),
        report: table,
        stats: statsTable,
        clients: initialResponse.data?.clients
          .filter(({ id }) =>
            [
              ...new Set(accountedEvents.map(({ client }) => client.id)),
            ].includes(id),
          )
          .sort((a, b) =>
            (a!.lastName || a!.firstName).localeCompare(
              b!.lastName || b!.firstName,
            ),
          ),
        members: [
          ...new Set(
            accountedEvents
              .filter(({ member }) => !!member)
              .map(({ member }) => member!),
          ),
        ].sort((a, b) =>
          (a!.lastName || a!.firstName).localeCompare(
            b!.lastName || b!.firstName,
          ),
        ),
        from: from!,
        to: to!,
        branch,
      },
    );
  };

  const statsTable = (
    <Table
      inline
      enableRowHighlighting
      persistentRowHighlighting
      backgroundColor={yellow[50]}
    >
      <TableHeader style={{ width: 'auto' }}>Stats</TableHeader>
      <TableHeader align="right"></TableHeader>
      <TableRow>
        <TableCell>Active hours</TableCell>
        <TableCell>{stats.hours.active.toLocaleString()}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Night-time sleepover hours</TableCell>
        <TableCell>{stats.hours.passive.toLocaleString()}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Travel hours</TableCell>
        <TableCell>{stats.hours.travel.toLocaleString()}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Kilometres travelled</TableCell>
        <TableCell>{stats.kms.toLocaleString()}</TableCell>
      </TableRow>
      <TableRow
        onClick={() => {
          setShowParticipantStats(!showParticipantStats);
        }}
      >
        <TableCell>Participants</TableCell>
        <TableCell>{stats.clientCount.toLocaleString()}</TableCell>
      </TableRow>
      <TableRow
        onClick={() => {
          setShowSupportWorkerStats(!showSupportWorkerStats);
        }}
      >
        <TableCell>Support workers</TableCell>
        <TableCell>{stats.memberCount.toLocaleString()}</TableCell>
      </TableRow>
    </Table>
  );

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.row}>
        <Select
          control={control}
          name="period"
          label="Reporting period"
          items={periods}
          formControlProps={{ variant: 'outlined', size: 'small' }}
          renderValue={(value) => {
            if (!from || !to) return null;

            if (
              isEqual(
                from,
                startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
              ) &&
              isEqual(
                to,
                addDays(
                  startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
                  6,
                ),
              )
            ) {
              return 'Current payroll period';
            } else if (
              isEqual(
                from,
                subWeeks(
                  startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
                  1,
                ),
              ) &&
              isEqual(
                to,
                subDays(
                  startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
                  1,
                ),
              )
            ) {
              return 'Most recently ended payroll period';
            } else if (
              isEqual(
                from,
                subWeeks(
                  startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
                  2,
                ),
              ) &&
              isEqual(
                to,
                subDays(
                  startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
                  8,
                ),
              )
            ) {
              return 'Payroll period two weeks ago';
            } else if (
              isEqual(
                from,
                setMonth(setDate(subYears(startOfYear(new Date()), 1), 1), 6),
              ) &&
              isEqual(to, subDays(startOfToday(), 1))
            ) {
              return 'This financial year-to-date';
            } else if (
              isEqual(
                from,
                setMonth(setDate(subYears(startOfYear(new Date()), 2), 1), 6),
              ) &&
              isEqual(
                to,
                setMonth(setDate(subYears(startOfYear(new Date()), 1), 30), 5),
              )
            ) {
              return 'Last financial year';
            } else if (isEqual(to, subDays(addMonths(from, 1), 1))) {
              return '1 Month';
            } else if (isEqual(to, subDays(addMonths(from, 3), 1))) {
              return '3 Months';
            } else if (isEqual(to, subDays(addMonths(from, 6), 1))) {
              return '6 Months';
            } else if (isEqual(to, subDays(addMonths(from, 12), 1))) {
              return '12 Months';
            } else {
              return 'Custom dates';
            }
          }}
        />
        {period && (
          <Box className={classes.row}>
            <DateInput
              autoOk
              keyboard
              control={control}
              name="from"
              label="From"
              inputVariant="outlined"
              size="small"
              onClick={(event) => (event.target as HTMLInputElement).select()}
            />
            <DateInput
              autoOk
              keyboard
              control={control}
              name="to"
              label="To"
              inputVariant="outlined"
              size="small"
              onClick={(event) => (event.target as HTMLInputElement).select()}
            />
          </Box>
        )}
      </Box>

      {!!period && !!initialResponse.data?.clients.length && (
        <Box className={classes.row}>
          <Select
            multiple
            control={control}
            name="clients"
            label="Participants"
            items={initialResponse.data.clients.map(({ id, ...client }) => ({
              label: formatPersonName(client, { lastNameFirst: true }),
              value: id,
            }))}
            formControlProps={{
              variant: 'outlined',
              size: 'small',
              style: { width: 200 },
            }}
            renderValue={(values) =>
              !!(values as string[]).length
                ? (values as string[]).length + ' selected'
                : 'No one'
            }
          />
          <Button
            variant="contained"
            disabled={clients.length === initialResponse.data.clients.length}
            onClick={() => {
              setValue(
                'clients',
                initialResponse.data?.clients.map(({ id }) => id) || [],
              );
            }}
          >
            Select All
          </Button>
          <Button
            variant="contained"
            disabled={!clients.length}
            onClick={() => {
              setValue('clients', []);
            }}
          >
            Deselect All
          </Button>
        </Box>
      )}

      <Box className={classes.row}>
        <Select
          multiple
          control={control}
          name="members"
          label="Employees"
          items={initialResponse.data?.members.map(({ id, ...member }) => ({
            label: formatPersonName(member, { lastNameFirst: true }),
            value: id,
          }))}
          formControlProps={{
            variant: 'outlined',
            size: 'small',
            style: { width: 200 },
          }}
          renderValue={(values) =>
            !!(values as string[]).length
              ? (values as string[]).length + ' selected'
              : 'No one'
          }
        />
        <Button
          variant="contained"
          disabled={members.length === initialResponse.data?.members.length}
          onClick={() => {
            setValue(
              'members',
              initialResponse.data?.members.map(({ id }) => id) || [],
            );
          }}
        >
          Select All
        </Button>
        <Button
          variant="contained"
          disabled={!members.length}
          onClick={() => {
            setValue('members', []);
          }}
        >
          Deselect All
        </Button>
      </Box>

      <Box className={classes.row}>
        <Checkbox
          control={control}
          defaultChecked={false}
          name="includeFixedExpenses"
          label="Include fixed expenses in report"
        />
      </Box>
      <Box className={classes.row}>
        <Button
          variant="contained"
          color="primary"
          disabled={(!members.length && !clients.length) || !from || !to}
          onClick={runReport}
        >
          Run report
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={(!members.length && !clients.length) || !from || !to}
          onClick={() => {
            handleOpenPrintModal();
          }}
        >
          Print Statement
        </Button>
      </Box>

      {!!subsequentResponse.error?.networkError && (
        <Box className={classes.row}>
          <div style={{ color: 'red', fontWeight: 'bold' }}>
            Server crashed!
          </div>
        </Box>
      )}

      <Divider />
      <div className={classes.dataWrapper}>
        <Box className={classes.row}>
          <div className={classes.codeInput}>
            <JSONInput
              control={control}
              name="fixedExpenses"
              label="Fixed Expenses JSON"
              error={!!errors.fixedExpenses}
              helperText={errors.fixedExpenses?.message}
              inputProps={{ minWidth: '400px', maxHeight: '800px' }}
            />
            <div className={classes.codeInputButtons}>
              <Button
                disabled={!!errors.fixedExpenses}
                variant="contained"
                onClick={() => {
                  setValue(
                    'fixedExpenses',
                    JSON.stringify(
                      JSON.parse(getValues('fixedExpenses') ?? ''),
                      undefined,
                      2,
                    ),
                  );
                }}
              >
                Format
              </Button>
              <ButtonAsync
                disabled={!!errors.fixedExpenses}
                variant="contained"
                loading={saveFixedExpensesResponse.loading}
                success={!saveFixedExpensesResponse.error}
                onClick={() =>
                  saveFixedExpenses({
                    variables: {
                      input: {
                        patch: {
                          fixedExpenses: !fixedExpenses
                            ? null
                            : JSON.stringify(JSON.parse(fixedExpenses)),
                        },
                      },
                    },
                  })
                }
              >
                Save
              </ButtonAsync>
            </div>
          </div>
        </Box>

        {!!total && table}

        <div className={classes.stats}>
          {(!!clients.length || !!members.length) && !!stats && statsTable}
          {showParticipantStats &&
            !![...new Set(accountedEvents.map(({ client }) => client.id))]
              .length && (
              <Table
                inline
                enableRowHighlighting
                persistentRowHighlighting
                backgroundColor={yellow[50]}
              >
                <TableHeader style={{ width: 'auto' }}>
                  Participants
                </TableHeader>
                <TableHeader align="center">Hours</TableHeader>
                {initialResponse.data?.clients
                  .filter(({ id }) =>
                    [
                      ...new Set(
                        accountedEvents.map(({ client }) => client.id),
                      ),
                    ].includes(id),
                  )

                  .sort((a, b) =>
                    (a!.lastName || a!.firstName).localeCompare(
                      b!.lastName || b!.firstName,
                    ),
                  )
                  .map((client) => (
                    <TableRow>
                      <TableCell>
                        {formatPersonName(client, {
                          lastNameFirst: true,
                          capitaliseLastName: true,
                        })}
                      </TableCell>
                      <TableCell>
                        {accountedEvents.length
                          ? roundNumber(
                              accountedEvents
                                .filter(
                                  (event) => event.client.id === client.id,
                                )
                                .flatMap(({ expenses }) =>
                                  expenses
                                    .filter(
                                      ({ shift }) => !!shift && !shift.passive,
                                    )
                                    .map(({ shift }) => shift!.duration),
                                )
                                .reduce((a, b) => a + b, 0) / 60,
                              2,
                            )
                          : 0}
                      </TableCell>
                    </TableRow>
                  ))}
              </Table>
            )}
          {showSupportWorkerStats &&
            !![...new Set(accountedEvents.map(({ member }) => member!.id))]
              .length && (
              <Table
                inline
                enableRowHighlighting
                persistentRowHighlighting
                backgroundColor={yellow[50]}
              >
                <TableHeader style={{ width: 'auto' }}>
                  Support Workers
                </TableHeader>
                <TableHeader align="center">Hours</TableHeader>
                {[
                  ...new Set(
                    accountedEvents.map(({ member }) => member).filter(Boolean),
                  ),
                ]
                  .sort((a, b) =>
                    (a!.lastName || a!.firstName).localeCompare(
                      b!.lastName || b!.firstName,
                    ),
                  )
                  .map((member) => (
                    <TableRow>
                      <TableCell>
                        {formatPersonName(member!, {
                          lastNameFirst: true,
                          capitaliseLastName: true,
                        })}
                      </TableCell>
                      <TableCell>
                        {accountedEvents.length
                          ? roundNumber(
                              accountedEvents
                                .filter(
                                  (event) => event.member!.id === member!.id,
                                )
                                .flatMap(({ expenses }) =>
                                  expenses
                                    .filter(
                                      ({ shift }) => !!shift && !shift.passive,
                                    )
                                    .map(({ shift }) => shift!.duration),
                                )
                                .reduce((a, b) => a + b, 0) / 60,
                              2,
                            )
                          : 0}
                      </TableCell>
                    </TableRow>
                  ))}
              </Table>
            )}
          {!!total && (
            <Box style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
              <PieChart
                series={[
                  {
                    data: [
                      {
                        id: 0,
                        value: roundNumber(
                          (total.cos.total ?? 0) + (total.expenses.total ?? 0),
                          1,
                        ),
                        label: 'Expenses + COS',
                        color: '#FFBB28',
                      },
                      {
                        id: 1,
                        value: roundNumber(
                          (total.revenue.total ?? 0) / 100 -
                            (total.cos.total ?? 0) -
                            (total.expenses.total ?? 0),
                          1,
                        ),
                        label: 'Profit',
                        color: '#0088FE',
                      },
                    ],
                    arcLabel: (params: DefaultizedPieValueType) => {
                      const percent =
                        !!total.revenue.total &&
                        (!!total.expenses.total || !!total.cos.total)
                          ? params.value /
                            ((total.expenses.total ?? 0) +
                              (total.cos.total ?? 0) +
                              ((total.revenue.total ?? 0) / 100 -
                                (total.cos.total ?? 0) -
                                (total.expenses.total ?? 0)))
                          : 0;
                      return `${(percent * 100).toFixed(1)}%`;
                    },
                    valueFormatter: ({ value }) =>
                      formatCurrency(value, { decimals: 0 }),
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    innerRadius: 70,
                  },
                ]}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fill: 'white',
                    fontSize: 16,
                  },

                  flexGrow: 0,
                }}
                width={700}
                height={300}
              >
                <PieCenterLabel>Profit / Loss</PieCenterLabel>
              </PieChart>
              <PieChart
                series={[
                  {
                    data: total.revenue.items.map((item, i) => ({
                      id: i,
                      value: item.total,
                      label: item.name,
                    })),
                    arcLabel: (params: DefaultizedPieValueType) => {
                      const percent = !!total.revenue.total
                        ? params.value / total.revenue.total
                        : 0;
                      return `${(percent * 100).toFixed(0)}%`;
                    },
                    valueFormatter: ({ value }) =>
                      formatCurrency(value, { decimals: 0 }),
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    innerRadius: 70,
                  },
                ]}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fill: 'white',
                    fontSize: 16,
                  },

                  flexGrow: 0,
                }}
                width={700}
                height={300}
              >
                <PieCenterLabel>Revenue</PieCenterLabel>
              </PieChart>
              <PieChart
                series={[
                  {
                    data: [
                      ...total.expenses.payroll.employeeClassifications.map(
                        ({ name, total }, i) => ({
                          id: i,
                          label: name,
                          value: total,
                        }),
                      ),
                      {
                        id: total.expenses.payroll.employeeClassifications
                          .length,
                        label: 'Superannuation',
                        value: total.expenses.payroll.superannuations.total,
                      },
                      {
                        id:
                          total.expenses.payroll.employeeClassifications
                            .length + 1,
                        label: 'Workers Comp Insur.',
                        value:
                          total.expenses.payroll.workerCompensationInsurances
                            .total,
                      },
                      {
                        id:
                          total.expenses.payroll.employeeClassifications
                            .length + 2,
                        label: 'Payroll Taxes',
                        value: total.expenses.payroll.taxes.total,
                      },
                      {
                        id:
                          total.expenses.payroll.employeeClassifications
                            .length + 3,
                        label: 'Fixed Expenses',
                        value: total.expenses.fixed.total,
                      },
                    ].filter(({ value }) => value > 0),

                    arcLabel: (params: DefaultizedPieValueType) => {
                      const percent = !!total.expenses.total
                        ? params.value / total.expenses.total
                        : 0;
                      return `${(percent * 100).toFixed(0)}%`;
                    },
                    valueFormatter: ({ value }) =>
                      formatCurrency(value, { decimals: 0 }),
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    innerRadius: 70,
                  },
                ]}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fill: 'white',
                    fontSize: 16,
                  },

                  flexGrow: 0,
                }}
                width={700}
                height={300}
              >
                <PieCenterLabel>Expenses</PieCenterLabel>
              </PieChart>
            </Box>
          )}
        </div>
      </div>
    </Box>
  );
};

export default ReportProfitAndLossStatement;

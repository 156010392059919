import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useAlert } from '@timed/alert';
import { useAuth } from '@timed/auth';
import {
  BankAccountNameInput,
  BankAccountNumberInput,
  BankBsbInput,
  FormModal,
  ModalProps,
  ProfileInput,
} from '@timed/common';
import {
  OrderBy,
  Permission,
  QueryByIdInput,
  SetMemberBankInput,
  useGetMemberBankDetailsLazyQuery,
  useGetMemberBankDetailsSelfLazyQuery,
  useUpdateMemberBankDetailsSelfMutation,
  useUpdateMembersBankDetailsMutation,
} from '@timed/gql';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type UploadBankDetailsFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
};

type FormData = QueryByIdInput & {
  patch: {
    bank?: SetMemberBankInput | null;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    numbers: {
      display: 'grid',
      gridTemplateColumns: '85px auto',
      gap: theme.spacing(4),
    },
    group: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    select: {
      '&.MuiInputBase-root': {
        minWidth: 120,
      },
    },
  }),
);

const UploadBankDetailsFormModal = ({
  onClose,
  ...modalProps
}: UploadBankDetailsFormModalProps) => {
  const classes = useStyles();

  const alert = useAlert();

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>();

  const { permissible } = useAuth();

  const [getExistingValues, existingValuesResponse] =
    useGetMemberBankDetailsLazyQuery();

  const [getExistingValuesSelf, existingValuesSelfResponse] =
    useGetMemberBankDetailsSelfLazyQuery();

  const [updateOther, updateOtherResponse] =
    useUpdateMembersBankDetailsMutation();

  const [updateSelf, updateSelfResponse] =
    useUpdateMemberBankDetailsSelfMutation();

  const memberId = watch('id');

  useEffect(() => {
    if (permissible({ admin: true })) {
      if (!!memberId) getExistingValues({ variables: { id: memberId } });
    } else {
      getExistingValuesSelf();
    }
  }, [permissible, memberId, getExistingValues, getExistingValuesSelf]);

  useEffect(() => {
    if (!!existingValuesResponse.data) {
      setValue(
        'patch.bank.bsb',
        !!existingValuesResponse.data.memberById.bank?.bsb
          ? existingValuesResponse.data.memberById.bank.bsb.slice(0, 3) +
              '-' +
              existingValuesResponse.data.memberById.bank.bsb.slice(3, 6)
          : '',
      );

      setValue(
        'patch.bank.accountNumber',
        existingValuesResponse.data.memberById.bank?.accountNumber ?? '',
      );

      setValue(
        'patch.bank.accountName',
        existingValuesResponse.data.memberById.bank?.accountName ?? '',
      );
    }
  }, [existingValuesResponse.data, setValue]);

  useEffect(() => {
    if (!!existingValuesSelfResponse.data) {
      setValue(
        'patch.bank.bsb',
        !!existingValuesSelfResponse.data.me.member?.bank?.bsb
          ? existingValuesSelfResponse.data.me.member.bank.bsb.slice(0, 3) +
              '-' +
              existingValuesSelfResponse.data.me.member.bank.bsb.slice(3, 6)
          : '',
      );

      setValue(
        'patch.bank.accountNumber',
        existingValuesSelfResponse.data.me.member?.bank?.accountNumber ?? '',
      );

      setValue(
        'patch.bank.accountName',
        existingValuesSelfResponse.data.me.member?.bank?.accountName ?? '',
      );
    }
  }, [existingValuesSelfResponse.data, setValue]);

  const onSuccess = () => {
    alert.push({
      message: 'Successfully set bank details',
      severity: 'success',
    });
  };

  const onSubmit = ({ patch: { bank } }: FormData) => {
    memberId
      ? updateOther({
          variables: {
            input: {
              ids: [memberId],
              patch: {
                bank: {
                  ...bank,
                  bsb: !!bank?.bsb ? bank.bsb.replace('-', '') : undefined,
                },
              },
            },
          },
        })
      : updateSelf({
          variables: {
            input: {
              patch: {
                bank: {
                  ...bank,
                  bsb: !!bank?.bsb ? bank.bsb.replace('-', '') : undefined,
                },
              },
            },
          },
        });
  };

  return (
    <FormModal
      modalProps={modalProps}
      title="Bank Details"
      loading={updateOtherResponse.loading || updateSelfResponse.loading}
      success={!!updateOtherResponse.data || !!updateSelfResponse.data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        {permissible({ permissions: Permission.MEMBER_WRITE }) && (
          <Box className={classes.group}>
            <ProfileInput
              required
              control={control}
              formControlProps={{ size: 'small', variant: 'outlined' }}
              watch={watch}
              type="member"
              name="id"
              label="Employee"
              className={classes.select}
              orderBy={[{ lastName: OrderBy.ASC }]}
            />
          </Box>
        )}

        {(!permissible({ permissions: Permission.MEMBER_WRITE }) ||
          (permissible({ permissions: Permission.MEMBER_WRITE }) &&
            memberId &&
            existingValuesResponse.data)) && (
          <>
            <Box className={classes.numbers}>
              <BankBsbInput
                required
                name="patch.bank.bsb"
                variant="outlined"
                size="small"
                label="BSB"
                control={control}
                error={!!errors.patch?.bank?.bsb}
                helperText={errors.patch?.bank?.bsb?.message}
              />
              <BankAccountNumberInput
                required
                name="patch.bank.accountNumber"
                variant="outlined"
                size="small"
                label="Account Number"
                control={control}
                error={!!errors.patch?.bank?.accountNumber}
                helperText={errors.patch?.bank?.accountNumber?.message}
              />
            </Box>
            <BankAccountNameInput
              required
              name="patch.bank.accountName"
              variant="outlined"
              size="small"
              label="Account Name"
              control={control}
              error={!!errors.patch?.bank?.accountName}
              helperText={errors.patch?.bank?.accountName?.message}
            />
          </>
        )}
      </Box>
    </FormModal>
  );
};

export default UploadBankDetailsFormModal;

import { Grid, Typography } from '@material-ui/core';
import { addServerErrors, Block, IconButtonMulti } from '@timed/common';
import {
  Member,
  Permission,
  useUpdateMembersPermissionsMutation,
} from '@timed/gql';
import { MemberAdminInput, MemberPermissionsInput } from '@timed/member';
import _, { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type MemberUpdatePermissionsFormProps = {
  member: Pick<Member, 'id' | 'admin' | 'permissions'>;
};

type FormData = { admin: boolean };

const MemberUpdatePermissionsForm = ({
  member,
}: MemberUpdatePermissionsFormProps) => {
  const [editing, setEditing] = useState<boolean>(false);

  const [updateMember, response] = useUpdateMembersPermissionsMutation();

  const defaultValues: FormData = { admin: member.admin };

  const { handleSubmit, watch, control, setError, reset } = useForm<FormData>({
    defaultValues,
  });

  const currentValues = watch();

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = (values: FormData) => {
    updateMember({
      variables: {
        input: {
          patch: {
            admin: values.admin,
            permissions:
              Object.entries<Permission>(_.omit(values, ['admin']))
                .map(([k, v]) => !!v && (k as Permission))
                .filter(Boolean) ?? null,
          },
          ids: [member.id],
        },
      },
    }).catch((e) => {});
    reset(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block
        title="Permissions"
        topRight={
          <IconButtonMulti
            size="small"
            enabled={editing}
            changed={!isEqual(currentValues, defaultValues)}
            setEditing={setEditing}
            loading={response.loading}
            success={!!response.data}
          />
        }
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={8} md={9} lg={10}>
            <MemberAdminInput
              control={control}
              disabled={!editing}
              admin={member.admin}
            />
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <MemberPermissionsInput
              control={control}
              disabled={!editing}
              permissions={member.permissions}
            />
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>Write permissions include read permissions.</Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              Schedulers require the following permissions:
              <br />
              &#8226; EMPLOYEE_READ
              <br />
              &#8226; PARTICIPANT_READ
              <br />
              &#8226; SCHEDULE_WRITE
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} lg={10}>
            <Typography>
              Reports require the following permissions:
              <br />
              &#8226; EMPLOYEE_READ
              <br />
              &#8226; PARTICIPANT_READ
              <br />
              &#8226; PLANMANAGER_READ
              <br />
              &#8226; SCHEDULE_READ
            </Typography>
          </Grid>
        </Grid>
      </Block>
    </form>
  );
};

export default MemberUpdatePermissionsForm;

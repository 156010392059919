import {
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { green, grey } from '@material-ui/core/colors';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import { _schedule } from '@timed/app';
import { useAuth } from '@timed/auth';
import {
  Button,
  formatPersonName,
  formatTimeDuration,
  Snackbar,
  TabPanels,
  Tabs,
  useRouter,
} from '@timed/common';
import {
  ClaimEventDocument,
  Client,
  Event,
  OrderBy,
  Permission,
  useClaimEventMutation,
  useDismissEventClaimMutation,
  useGetClaimableShiftsLazyQuery,
  useGetOldClaimedEventsLazyQuery,
  useUpdateEventAttendeeMutation,
} from '@timed/gql';
import { setProfile } from '@timed/schedule';
import { ShiftsProcessClaims } from '@timed/shifts/components/ProcessClaims';
import clsx from 'clsx';
import { differenceInMinutes, format, isAfter, startOfWeek } from 'date-fns';
import { useCallback, useEffect, useReducer, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    title: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    shifts: {
      display: 'flex',
      flexWrap: 'wrap',
      [theme.breakpoints.down('md')]: {
        gap: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
      },
    },
    shift: {
      display: 'grid',
      gridTemplateColumns: '48px auto',
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
      '&:hover': {
        cursor: 'pointer',
        '& div:first-child': {
          backgroundColor: green[300],
        },
        '& div:last-child': {
          backgroundColor: green[100],
        },
      },
    },
    date: {
      backgroundColor: theme.palette.divider,
      color: theme.palette.common.white,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0),
      alignItems: 'center',
      justifyItems: 'start',
      '& .MuiTypography-root': {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium,
      },
      '& .MuiTypography-root:first-child': {
        fontSize: 20,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    info: {
      padding: theme.spacing(1),
      backgroundColor: grey[100],
    },
    infoLine: {
      display: 'grid',
      gridTemplateColumns: 'min-content auto',
      gap: theme.spacing(2),
    },
    claims: {
      display: 'inline-grid',
      gridTemplateColumns: 'repeat(8, max-content)',
    },
    oldClaims: {
      display: 'inline-grid',
      gridTemplateColumns: 'repeat(9, max-content)',
    },
    actions: {
      display: 'flex',
      gap: theme.spacing(2),
      marginLeft: theme.spacing(2),
      '& .MuiButton-root': {
        height: 34,
        alignSelf: 'center',
      },
    },
    cell: {
      padding: theme.spacing(1, 4),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0),
      justifyContent: 'space-around',
      '& .MuiTypography-root': {
        fontSize: 10,
      },
      '& .MuiTypography-root:first-child': {
        fontSize: 14,
      },
    },
    claimsWrapper: {
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(4),
    },
    eventTitle: {
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(1),
    },
  }),
);

type TabState = 'pending' | 'processed';

const ShiftsClaim = () => {
  const classes = useStyles();

  const theme = useTheme();

  const [tab, setTab] = useState<TabState>('pending');

  const { permissible, branch } = useAuth();

  const {
    navigate,
    search: [searchParams],
  } = useRouter();

  const [getEvents, { data, loading }] = useGetClaimableShiftsLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [claimEvent, claimEventResponse] = useClaimEventMutation();

  const [updateEvent, updateEventResponse] = useUpdateEventAttendeeMutation();

  const [dismissEvent] = useDismissEventClaimMutation();

  useEffect(() => {
    if (claimEventResponse.data) {
      const cache = claimEventResponse.client.cache;

      cache.modify({
        fields: {
          eventClaims(existing = []) {
            const claims = cache.writeQuery({
              data: claimEventResponse.data,
              query: ClaimEventDocument,
            });
            return [...existing, claims];
          },
        },
      });
    }
  }, [claimEventResponse.data, claimEventResponse.client.cache]);

  // const [getExistingClaims, existingClaimsResponse] =
  //   useGetClaimedEventsLazyQuery({ fetchPolicy: 'network-only' });

  const [getOldClaims, oldClaimsResponse] = useGetOldClaimedEventsLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [claimedEvents, changeClaimedEvent] = useReducer(
    (existingIds: string[], newId: string) => [...existingIds, newId],
    [],
  );

  const [acceptedClaims, addAcceptedClaims] = useReducer(
    (existingIds: string[], newId: string) => [...existingIds, newId],
    [],
  );

  // const existingClaimedEvents = useMemo(() => {
  //   return _.uniq(
  //     existingClaimsResponse.data?.eventClaims.map(({ event }) => event),
  //   ).sort((a, b) =>
  //     differenceInMinutes(new Date(a.startAt), new Date(b.startAt)),
  //   );
  // }, [existingClaimsResponse.data]);

  const handleNavigate = useCallback(
    (event: Pick<Event, 'startAt'> & { client: Pick<Client, 'id'> }) => {
      setProfile('client', searchParams, event.client.id);
      setProfile('member', searchParams, undefined);

      const formattedDate = format(
        startOfWeek(new Date(event.startAt), { weekStartsOn: 1 }),
        'ddMMyyyy',
      );

      if (searchParams.get('f') !== formattedDate) {
        searchParams.set('f', formattedDate);
      }

      navigate(_schedule.path + '?' + searchParams);
    },
    [navigate, searchParams],
  );

  // Fetch events
  useEffect(() => {
    getEvents();
  }, [getEvents, branch]);

  const fetchData = useCallback(() => {
    const existingClaimsInput = {
      where: {
        dismissedAt: { _eq: null },
        event: {
          startAt: { _gt: new Date() },
          member: {
            id: { _eq: null },
          },
          client: branch ? { branch: { id: { _eq: branch.id } } } : undefined,
        },
        member: {
          schedulable: { _eq: true },
        },
      },
      orderBy: [
        { event: { startAt: OrderBy.ASC } },
        { event: { client: { lastName: OrderBy.ASC } } },
        { event: { client: { firstName: OrderBy.ASC } } },
        { createdAt: OrderBy.ASC },
      ],
    };

    const oldClaimsInput = {
      limit: 100,
      where: {
        event: branch
          ? { client: { branch: { id: { _eq: branch.id } } } }
          : undefined,
        _or: [
          {
            dismissedAt: { _eq: null },
          },
          {
            event: {
              startAt: { _lte: new Date() },
            },
          },
          {
            event: {
              member: {
                id: { _eq: null },
              },
            },
          },
          {
            member: {
              schedulable: { _eq: true },
            },
          },
        ],
      },
      orderBy: [
        { event: { startAt: OrderBy.DESC } },
        { event: { client: { lastName: OrderBy.ASC } } },
        { event: { client: { firstName: OrderBy.ASC } } },
        { createdAt: OrderBy.ASC },
      ],
    };
  }, [branch]);

  useEffect(() => {
    if (permissible({ permissions: Permission.EVENT_WRITE })) fetchData();
  }, [fetchData, permissible, branch]);

  useEffect(() => {
    if (!updateEventResponse.error && !!updateEventResponse.data) {
      addAcceptedClaims(updateEventResponse.data.updateEvent.id);
    }
  }, [updateEventResponse.data, updateEventResponse.error]);

  const reasonForOldClaim = (claimId: string) => {
    const claim = oldClaimsResponse.data?.eventClaims.find(
      ({ id }) => id === claimId,
    );

    let message: JSX.Element;

    if (claim?.member.id === claim?.event.member?.id)
      message = <span>Person assigned to shift</span>;
    else if (!!claim?.event.member?.id)
      message = <span>A different person was assigned to shift</span>;
    else if (!!claim?.dismissedAt)
      message = (
        <span>
          Dismissed by{' '}
          {!claim.dismissedBy
            ? 'Unknown'
            : formatPersonName(claim.dismissedBy!, {
                lastNameFirst: true,
                capitaliseLastName: true,
              })}
          <br />
          On {format(new Date(claim.dismissedAt), "dd/MM/yyyy 'at' HH:mm")}
        </span>
      );
    else if (!claim?.member.schedulable)
      message = <span>Support worker is unschedulable</span>;
    else if (isAfter(new Date(claim?.event.startAt), new Date()))
      message = <span>Shift occured in the past</span>;
    else message = <span>Unknown</span>;

    return <Typography className={classes.cell}>{message}</Typography>;
  };

  const OldClaims = () => (
    <>
      <div className={classes.oldClaims} hidden={tab !== 'pending'}>
        <Typography className={clsx(classes.bold, classes.cell)}>
          Requested By
        </Typography>
        <Typography className={clsx(classes.bold, classes.cell)}>
          Requested At
        </Typography>
        <Typography className={clsx(classes.bold, classes.cell)}>
          Participant
        </Typography>
        <Typography className={clsx(classes.bold, classes.cell)}>
          Shift Start
        </Typography>
        <Typography className={clsx(classes.bold, classes.cell)}>
          Shift End
        </Typography>
        <Typography className={clsx(classes.bold, classes.cell)}>
          Duration
        </Typography>
        <Typography className={clsx(classes.bold, classes.cell)}>
          Requirements
        </Typography>
        <Typography className={clsx(classes.bold, classes.cell)}>
          Result
        </Typography>
        <Typography></Typography>
        {oldClaimsResponse.data?.eventClaims.map((claim) => (
          <>
            <div className={classes.cell}>
              <Typography>
                {formatPersonName(claim.member, {
                  capitaliseLastName: true,
                  lastNameFirst: true,
                })}
              </Typography>
              {/* <Typography>On preferred list</Typography>
                <Typography color="error">Does not have a car</Typography>
                <Typography color="error">Working too many hours</Typography>
                <Typography color="error">Already scheduled with JAGGER, Lorraine</Typography> */}
            </div>
            <div className={classes.cell}>
              <Typography>
                {format(new Date(claim.createdAt), 'EEEE')}
              </Typography>
              <Typography>
                {format(new Date(claim.createdAt), 'dd/MM/yyyy HH:mm')}
              </Typography>
            </div>
            <div className={classes.cell}>
              <Typography>
                {formatPersonName(claim.event.client, {
                  capitaliseLastName: true,
                  lastNameFirst: true,
                  preferredAndLast: true,
                })}
              </Typography>
              <Typography>
                {claim.event.locality + ', ' + claim.event.region}
              </Typography>
            </div>
            <div className={classes.cell}>
              <Typography>
                {format(new Date(claim.event.startAt), 'EEEE')}
              </Typography>
              <Typography>
                {format(new Date(claim.event.startAt), 'dd/MM/yyyy HH:mm')}
              </Typography>
            </div>
            <div className={classes.cell}>
              <Typography>
                {format(new Date(claim.event.endAt), 'EEEE')}
              </Typography>
              <Typography>
                {format(new Date(claim.event.endAt), 'dd/MM/yyyy HH:mm')}
              </Typography>
            </div>
            <Typography className={classes.cell}>
              {differenceInMinutes(
                new Date(claim.event.endAt),
                new Date(claim.event.startAt),
              ) / 60}{' '}
              hours
            </Typography>
            <Typography className={classes.cell}>
              {claim.event.requireCar ? 'Requires a car' : 'None'}
            </Typography>
            {reasonForOldClaim(claim.id)}
            <div className={classes.actions}>
              <Button
                variant="contained"
                onClick={() => {
                  handleNavigate(claim.event);
                }}
              >
                Go To Shift
              </Button>
            </div>
          </>
        ))}
      </div>
    </>
  );

  return loading ? (
    <Typography>Loading...</Typography>
  ) : (
    <div className={classes.wrapper}>
      <Snackbar
        open={!!claimEventResponse.data}
        severity={claimEventResponse.data?.claimEvent.id ? 'success' : 'error'}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {claimEventResponse.data?.claimEvent.id
          ? 'Request received.'
          : 'Could not claim this shift at this time.'}
      </Snackbar>

      {!permissible({ admin: true }) &&
      (!data?.eventsClaimable?.length ||
        !(data?.eventsClaimable?.length - claimedEvents.length)) ? (
        <Typography variant="body1">
          There are no claimable shifts at this time.
        </Typography>
      ) : (
        <>
          <Typography variant="h2">Grab Shifts</Typography>
          <Typography>Click the shifts you would like to grab.</Typography>
          <div className={classes.shifts}>
            {data?.eventsClaimable
              .filter(({ id }) => !claimedEvents.includes(id))
              .map((event) => {
                return (
                  <div
                    className={classes.shift}
                    key={event.id}
                    onClick={() =>
                      claimEvent({
                        variables: { id: event.id },
                        onCompleted: (data) => {
                          changeClaimedEvent(data.claimEvent.event.id);
                        },
                      })
                    }
                  >
                    <div className={classes.date}>
                      <Typography>
                        {format(new Date(event.startAt), 'd')}
                      </Typography>
                      <Typography>
                        {format(new Date(event.startAt), 'MMM')}
                      </Typography>
                      <Typography>
                        {format(new Date(event.startAt), 'EEE')}
                      </Typography>
                    </div>
                    <div className={classes.info}>
                      <span className={classes.infoLine}>
                        <ScheduleRoundedIcon fontSize="small" />
                        <Typography variant="body2">
                          {formatTimeDuration({
                            start: new Date(event.startAt),
                            end: new Date(event.endAt),
                            options: { militaryTime: true },
                          })}{' '}
                          (
                          {differenceInMinutes(
                            new Date(event.endAt),
                            new Date(event.startAt),
                          ) / 60}{' '}
                          hours)
                        </Typography>
                      </span>
                      <span className={classes.infoLine}>
                        <PersonOutlineOutlinedIcon fontSize="small" />
                        <Typography variant="body2">
                          {formatPersonName(event.client, {
                            capitaliseLastName: true,
                            lastNameFirst: true,
                            preferredAndLast: true,
                          })}
                        </Typography>
                      </span>
                      <span className={classes.infoLine}>
                        <HomeOutlinedIcon fontSize="small" />
                        <Typography variant="body2">
                          {event.locality + ' ' + event.region}
                        </Typography>
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      )}

      {permissible({ permissions: Permission.EVENT_WRITE }) && (
        <>
          <div>
            <Tabs<TabState>
              name="event-claims-tabs"
              state={[tab, setTab]}
              items={[
                {
                  label: 'Pending',
                  value: 'pending',
                  permissions: Permission.EVENT_WRITE,
                },
                { label: 'Processed', value: 'processed', admin: true },
              ]}
            />
            <Divider />
          </div>
          <TabPanels<TabState>
            name={'event-claims-tabpanel'}
            currentTab={tab}
            panels={[
              {
                value: 'pending',
                content: <ShiftsProcessClaims />,
              },
              {
                value: 'processed',
                content: (
                  <>
                    {!oldClaimsResponse.data?.eventClaims.filter(
                      ({ dismissedAt }) => !dismissedAt,
                    ).length ? (
                      <Typography>
                        There have been no processed shift claims.
                      </Typography>
                    ) : (
                      <OldClaims />
                    )}
                  </>
                ),
              },
            ]}
          />
        </>
      )}
    </div>
  );
};

export default ShiftsClaim;

import { Checkbox, CheckboxProps } from '@timed/common';

const MemberTrackFilesCheckbox = (props: CheckboxProps) => (
  <Checkbox
    defaultChecked={true}
    label="Enable documentation tracking"
    {...props}
  />
);

export default MemberTrackFilesCheckbox;

import { ModuleType } from '@timed/gql';
import {
  ModuleContext,
  ModuleContextType,
  ModuleRedirectPath,
} from '@timed/module';
import { useCallback, useReducer, useState } from 'react';

const ModuleProvider: React.FC = ({ children }) => {
  const defaultModule = ModuleType.CS;

  const [activeModule, setActiveModule] = useState<ModuleType>(() => {
    const moduleFromStorage = sessionStorage.getItem('module') as ModuleType;

    // If module is unset or invalid, set to default module.
    if (
      !moduleFromStorage ||
      !Object.values(ModuleType).includes(moduleFromStorage)
    ) {
      sessionStorage.setItem('module', defaultModule);
      return defaultModule;
    }

    return moduleFromStorage;
  });

  const [redirectPaths, setRedirectPath] = useReducer(
    (
      state: ModuleContextType['redirectPaths'],
      newState: ModuleRedirectPath,
    ) => [
      ...state.filter(({ module }) => module !== newState.module),
      newState,
    ],
    [],
  );

  const handleSetActiveModule = useCallback((module: ModuleType) => {
    sessionStorage.setItem('module', module);
    setActiveModule(module);
  }, []);

  return (
    <ModuleContext.Provider
      value={{
        activeModule,
        setActiveModule: handleSetActiveModule,
        redirectPaths,
        setRedirectPath,
      }}
    >
      {children}
    </ModuleContext.Provider>
  );
};

export default ModuleProvider;

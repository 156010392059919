import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { getScrollBarDimensions } from '@timed/common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.paper,
      lineHeight: 0,
      marginBottom: 2, // Height of bottom border of calendar
    },
    indent: {
      flexBasis: 82, // Combined height of date labels and borders
      width: 40,
      [theme.breakpoints.down('sm')]: {
        flexBasis: 66, // Combined height of date labels and borders
        width: 'initial',
      },
    },
    times: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: getScrollBarDimensions().height,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      justifyContent: 'space-evenly',
      [theme.breakpoints.up('md')]: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightBold,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
      },
    },
  }),
);

const ScheduleCalendarTimes = () => {
  const classes = useStyles();

  const times = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
  ];

  return (
    <div className={classes.wrapper}>
      <div className={classes.indent}></div>
      <div className={classes.times}>
        {times.map((time) => (
          <span>{time}</span>
        ))}
      </div>
    </div>
  );
};

export default ScheduleCalendarTimes;

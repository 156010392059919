import {
  Box,
  Grid,
  IconButton,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Protected } from '@timed/auth';
import {
  Block,
  IconButtonMulti,
  TextField,
  addServerErrors,
  roundNumber,
} from '@timed/common';
import {
  HistoryRestorable,
  Member,
  Permission,
  UpdateMembersInput,
  useGetMemberScheduleSettingsQuery,
  useUpdateMembersScheduleMaxTimeLimitDefaultsMutation,
} from '@timed/gql';
import { MemberCreateScheduleTimeLimitFormModal } from '@timed/member';
import { MemberUpdateScheduleTimeLimitsForm } from '@timed/member/components/UpdateScheduleTimeLimitsForm';
import _, { isEqual } from 'lodash';
import { useModal } from 'mui-modal-provider';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

type MemberUpdateScheduleSettingsFormProps = {
  member: Pick<Member, 'id'> & {
    archive?: Pick<HistoryRestorable, 'id'> | null;
  };
};

type FormData = {
  patch: Pick<
    UpdateMembersInput['patch'],
    'scheduleMaxDayTimeLimitDefault' | 'scheduleMaxWeekTimeLimitDefault'
  >;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    wrapper: {
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(4),
    },
    alternateLimitsWrapper: {
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(2),
    },
    alternateLimitsControls: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      '& .MuiTypography-root': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  }),
);

const MemberUpdateScheduleSettingsForm = ({
  member,
}: MemberUpdateScheduleSettingsFormProps) => {
  const classes = useStyles();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { showModal } = useModal();

  const [editing, setEditing] = useState<boolean>(false);

  const [updateDefaultLimits, response] =
    useUpdateMembersScheduleMaxTimeLimitDefaultsMutation();

  const { data } = useGetMemberScheduleSettingsQuery({
    variables: {
      id: member.id,
    },
  });

  const { handleSubmit, watch, control, setError, setValue, reset } =
    useForm<FormData>({
      defaultValues: {
        patch: {
          scheduleMaxDayTimeLimitDefault: null,
          scheduleMaxWeekTimeLimitDefault: null,
        },
      },
    });

  const defaultValues = useMemo<Pick<
    Member,
    'scheduleMaxDayTimeLimitDefault' | 'scheduleMaxWeekTimeLimitDefault'
  > | null>(
    () =>
      !!data
        ? _.pick(data?.memberById, [
            'scheduleMaxDayTimeLimitDefault',
            'scheduleMaxWeekTimeLimitDefault',
          ])
        : null,
    [data],
  );

  // Assign default values
  useEffect(() => {
    if (data) {
      setValue(
        'patch.scheduleMaxDayTimeLimitDefault',
        !!data.memberById.scheduleMaxDayTimeLimitDefault
          ? roundNumber(data.memberById.scheduleMaxDayTimeLimitDefault / 60, 2)
          : undefined,
      );
      setValue(
        'patch.scheduleMaxWeekTimeLimitDefault',
        !!data.memberById.scheduleMaxWeekTimeLimitDefault
          ? roundNumber(data.memberById.scheduleMaxWeekTimeLimitDefault / 60, 2)
          : undefined,
      );
    }
  }, [data, setValue]);

  const currentValues = watch();

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = ({ patch }: FormData) => {
    updateDefaultLimits({
      variables: {
        input: {
          patch: {
            scheduleMaxDayTimeLimitDefault:
              !!patch.scheduleMaxDayTimeLimitDefault
                ? Number(patch.scheduleMaxDayTimeLimitDefault) * 60
                : null,
            scheduleMaxWeekTimeLimitDefault:
              !!patch.scheduleMaxWeekTimeLimitDefault
                ? Number(patch.scheduleMaxWeekTimeLimitDefault) * 60
                : null,
          },
          ids: [member.id],
        },
      },
    }).catch((e) => {});

    reset({ patch: { ...patch } });
  };

  const handleOpenCreateLimitFormModal = () => {
    const modal: { hide: () => void } = showModal(
      MemberCreateScheduleTimeLimitFormModal,
      {
        member,
        onClose: () => {
          modal.hide();
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block
        title="Schedule Settings"
        description={
          <Typography>
            Current allowed hours per week:{' '}
            {roundNumber(
              (data?.memberById.scheduleMaxWeekTimeLimitCurrent ?? 0) / 60,
              1,
            )}
          </Typography>
        }
        topRight={
          !member.archive ? (
            <Protected permissions={Permission.MEMBER_WRITE}>
              <IconButtonMulti
                size="small"
                enabled={editing}
                changed={!isEqual(currentValues, defaultValues)}
                setEditing={setEditing}
                loading={response.loading}
                success={!!response.data}
              />
            </Protected>
          ) : undefined
        }
      >
        <Box className={classes.wrapper}>
          <Grid container spacing={4} alignItems="center">
            {/* <Grid item xs={4} md={3} lg={2}>
              <Typography>Default max schedulable minutes per day</Typography>
            </Grid>
            <Grid item xs={8} md={9} lg={10}>
              <Typography>
                {!editing ? (
                  data?.memberById.scheduleMaxDayTimeLimitDefault ??
                  `Not set, using org default (${
                    data?.memberById.org.scheduleMaxDayTimeLimitDefault ?? 1440
                  }).`
                ) : (
                  <TextField
                    control={control}
                    name="patch.scheduleMaxDayTimeLimitDefault"
                    type="number"
                    variant="outlined"
                    size="small"
                    inputProps={{ min: 0, max: 1440, step: 1 }}
                    transform={transformNumberToFixedFloat(0)}
                    onClick={(event) => {
                      (event.target as HTMLInputElement).select();
                    }}
                  />
                )}
              </Typography>
            </Grid> */}
            <Grid item xs={4} md={3} lg={2}>
              <Typography>Default max schedulable hours per week</Typography>
            </Grid>
            <Grid item xs={8} md={9} lg={10}>
              <Typography>
                {!editing ? (
                  !!data?.memberById.scheduleMaxWeekTimeLimitDefault ? (
                    roundNumber(
                      data?.memberById.scheduleMaxWeekTimeLimitDefault / 60,
                      1,
                    )
                  ) : (
                    `Not set, using org default (${
                      !!data?.memberById.org.scheduleMaxWeekTimeLimitDefault
                        ? roundNumber(
                            data?.memberById.org
                              .scheduleMaxWeekTimeLimitDefault / 60,
                            1,
                          )
                        : 'No limit'
                    })`
                  )
                ) : (
                  <TextField
                    control={control}
                    name="patch.scheduleMaxWeekTimeLimitDefault"
                    variant="outlined"
                    size="small"
                    onClick={(event) => {
                      (event.target as HTMLInputElement).select();
                    }}
                  />
                )}
              </Typography>
            </Grid>
          </Grid>
          <Box className={classes.alternateLimitsWrapper}>
            <Box className={classes.alternateLimitsControls}>
              <Typography>Alternative Weekly Max Time Limits</Typography>
              <IconButton
                aria-label="menu"
                color="inherit"
                size={smDown ? 'small' : 'medium'}
                onClick={handleOpenCreateLimitFormModal}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </Box>
            <MemberUpdateScheduleTimeLimitsForm member={member} />
          </Box>
        </Box>
      </Block>
    </form>
  );
};

export default MemberUpdateScheduleSettingsForm;

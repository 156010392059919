import { isAfter, isBefore, isEqual, setHours } from 'date-fns';

/**
 * Print a time period from a date.
 * E.g: morning, afternoon, evening.
 */
export const printTimePeriod = (date: Date) => {
  const afternoon = setHours(date, 12);
  const evening = setHours(date, 18);

  if (isEqual(date, evening) || isAfter(date, evening)) return 'evening';
  else if (isBefore(date, afternoon)) return 'morning';
  else return 'afternoon';
};

import { ClientContactRelation } from '@timed/gql';

export const clientContactRelationsMetadata: {
  id: ClientContactRelation;
  name: string;
}[] = [
  {
    id: ClientContactRelation.BROTHER,
    name: 'Brother',
  },
  {
    id: ClientContactRelation.CARER,
    name: 'Carer',
  },
  {
    id: ClientContactRelation.DAUGHTER,
    name: 'Daughter',
  },
  {
    id: ClientContactRelation.FATHER,
    name: 'Father',
  },
  {
    id: ClientContactRelation.GUARDIAN,
    name: 'Guardian',
  },
  {
    id: ClientContactRelation.HUSBAND,
    name: 'Husband',
  },
  {
    id: ClientContactRelation.MOTHER,
    name: 'Mother',
  },
  {
    id: ClientContactRelation.NDIS_NOMINEE,
    name: 'NDIS Nominee',
  },
  {
    id: ClientContactRelation.NEIGHBOUR,
    name: 'Neighbour',
  },
  {
    id: ClientContactRelation.PARENT,
    name: 'Parent',
  },
  {
    id: ClientContactRelation.PARTNER,
    name: 'Partner',
  },
  {
    id: ClientContactRelation.POWER_OF_ATTORNEY,
    name: 'P.o.A',
  },
  {
    id: ClientContactRelation.SIBLING,
    name: 'Sibling',
  },
  {
    id: ClientContactRelation.SISTER,
    name: 'Sister',
  },
  {
    id: ClientContactRelation.SON,
    name: 'Son',
  },
  {
    id: ClientContactRelation.SUPPORT_COORDINATOR,
    name: 'Support Coordinator',
  },
  {
    id: ClientContactRelation.WIFE,
    name: 'Wife',
  },
];

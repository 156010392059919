import { Person } from '@timed/gql';

/**
 * Options object for formatPersonName() function
 */
type FormatPersonName = {
  /**
   * Render last name before first name
   */
  lastNameFirst?: boolean;

  /**
   * Capitalise last name
   */
  capitaliseLastName?: boolean;

  /**
   * Return last name and preferred name, if one exists.
   */
  preferredAndLast?: boolean;

  /**
   * Return preferred name, if exists. If not, fallback to usual formatter.
   */
  preferred?: boolean;

  /**
   * Include middle name if one exists.
   */
  middle?: boolean;

  /**
   * Include post-nominal letters if they exist.
   */
  postNominals?: boolean;

  /**
   * Return initials only.
   */
  initials?: boolean;
};

/**
 * Format a person's name
 */
export const formatPersonName = (
  person: Pick<
    Person,
    'firstName' | 'middleName' | 'lastName' | 'preferredName'
  > & { postNominals?: string | null },
  {
    lastNameFirst = false,
    capitaliseLastName = false,
    preferredAndLast = false,
    preferred = false,
    middle = false,
    postNominals = false,
    initials = false,
  }: FormatPersonName = {},
): string => {
  if (initials) return person.firstName.charAt(0) + person.lastName?.charAt(0);

  if (preferred && person.preferredName) return person.preferredName;

  let formattedName = '';

  if (preferredAndLast && person.preferredName && person.lastName) {
    formattedName = lastNameFirst
      ? (capitaliseLastName ? person.lastName.toUpperCase() : person.lastName) +
        ', ' +
        person.preferredName +
        (middle && person.middleName ? ' ' + person.middleName : '')
      : person.preferredName +
        (middle && person.middleName ? ' ' + person.middleName : '') +
        (person.lastName
          ? ' ' +
            (capitaliseLastName
              ? person.lastName.toUpperCase()
              : person.lastName)
          : '');
  } else if (lastNameFirst && person.lastName) {
    formattedName =
      (capitaliseLastName ? person.lastName.toUpperCase() : person.lastName) +
      ', ' +
      person.firstName +
      (middle && person.middleName ? ' ' + person.middleName : '');
  } else {
    formattedName =
      person.firstName +
      (middle && person.middleName ? ' ' + person.middleName : '') +
      (person.lastName
        ? ' ' +
          (capitaliseLastName ? person.lastName.toUpperCase() : person.lastName)
        : '');
  }

  if (postNominals && person.postNominals)
    formattedName += `, ${person.postNominals}`;

  return formattedName;
};

import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useAlert } from '@timed/alert';
import { useAuth } from '@timed/auth';
import {
  addServerErrors,
  Block,
  FormModal,
  ModalProps,
  roundNumber,
  Textarea,
  TextField,
  transformNumberToFixedFloat,
} from '@timed/common';
import {
  Event,
  Permission,
  useUpdateEventAttendedMutation,
  useUpdateEventMutation,
} from '@timed/gql';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

type EventUpdateTravelFormModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
  event: Pick<
    Event,
    | 'id'
    | 'travelDistance'
    | 'travelTime'
    | 'travelDistanceNotes'
    | 'travelTimeNotes'
  >;
};

type FormData = {
  travelDistance: Event['travelDistance'];
  travelDistanceNotes: Event['travelDistanceNotes'];
  travelTime: Event['travelTime'];
  travelTimeNotes: Event['travelTimeNotes'];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '16px',
      fontWeight: 'bold',
      display: 'grid',
      gridTemplateColumns: 'auto min-content',
      alignItems: 'center',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
      },
    },
    block: {
      backgroundColor: theme.palette.background.paper2,
      '& .MuiInputBase-root': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    inputs: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(2),
      },
    },
    textarea: {
      margin: 0,
      padding: theme.spacing(2),
      overflowY: 'auto',
      outline: 'none',
      resize: 'none',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      border: '1px solid ' + theme.palette.text.disabled,
      borderRadius: theme.shape.borderRadius,
      fontFamily: 'inherit',
      WebkitTextFillColor: theme.palette.text.primary, // required for iOS
      opacity: 1, // required for iOS
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  }),
);

const EventUpdateTravelFormModal = ({
  onClose,
  event,
  ...modalProps
}: EventUpdateTravelFormModalProps) => {
  const classes = useStyles();

  const alert = useAlert();

  const { permissible } = useAuth();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      travelDistance: (event.travelDistance ?? 0) / 1000,
      travelTime: event.travelTime,
      travelDistanceNotes: event.travelDistanceNotes,
      travelTimeNotes: event.travelTimeNotes,
    },
  });

  const [updateAttendedEventNotes, responseUpdateAttended] =
    useUpdateEventAttendedMutation();

  const [updateEventNotes, responseUpdate] = useUpdateEventMutation();

  const [data, loading, error] = useMemo(() => {
    return [
      permissible({ permissions: [Permission.EVENT_WRITE] })
        ? responseUpdate.data
        : responseUpdateAttended.data,
      responseUpdate.loading || responseUpdateAttended.loading,
      responseUpdate.error || responseUpdateAttended.error,
    ];
  }, [permissible, responseUpdate, responseUpdateAttended]);

  useEffect(() => error && addServerErrors(error, setError), [error, setError]);

  const onSuccess = () => {
    alert.push({
      message: 'Successfully updated event travel',
      severity: 'success',
    });
  };

  const onSubmit = ({ travelDistance, ...patch }: FormData) => {
    const variables = {
      input: {
        id: event.id,
        patch: {
          ...patch,
          travelDistance: !!travelDistance
            ? roundNumber(travelDistance * 1000, 0)
            : travelDistance,
        },
      },
    };

    permissible({ permissions: [Permission.EVENT_WRITE] })
      ? updateEventNotes({ variables })
      : updateAttendedEventNotes({ variables });
  };

  return (
    <FormModal
      modalProps={modalProps}
      title="Edit travel"
      loading={loading}
      success={!!data}
      onSubmit={handleSubmit(onSubmit)}
      onSuccess={onSuccess}
      onClose={onClose}
    >
      <Box className={classes.wrapper}>
        <Block
          paperProps={{ className: classes.block }}
          title="Travel distance"
          titleProps={{ variant: 'h3' }}
          description={
            <>
              The distance in kilometers traversed{' '}
              <span className={classes.bold}>with the Participant</span>{' '}
              throughout the course of the shift.
            </>
          }
        >
          <Box className={classes.inputs}>
            <TextField
              required
              name="travelDistance"
              control={control}
              error={!!errors.travelDistance}
              helperText={errors.travelDistance?.message}
              label="Kilometers"
              variant="outlined"
              size="small"
              type="number"
              transform={transformNumberToFixedFloat(3, 0)}
            />
            <Textarea
              name="travelDistanceNotes"
              control={control}
              minRows={5}
              placeholder="Notes"
              className={classes.textarea}
              style={{ height: '', overflow: '' }}
            />
          </Box>
        </Block>
        <Block
          paperProps={{ className: classes.block }}
          title="Travel Time"
          titleProps={{ variant: 'h3' }}
          description={
            <>
              The quantity of minutes spent travelling{' '}
              <span className={classes.bold}>between participants</span>. A cap
              of 30 minutes applies.
            </>
          }
        >
          <Box className={classes.inputs}>
            <TextField
              required
              name="travelTime"
              control={control}
              error={!!errors.travelTime}
              helperText={errors.travelTime?.message}
              variant="outlined"
              size="small"
              label="Minutes spent travelling to participant's address"
              type="number"
              transform={transformNumberToFixedFloat(2, 0, 30)}
            />
            <Textarea
              name="travelTimeNotes"
              control={control}
              minRows={5}
              placeholder="Notes"
              className={classes.textarea}
              style={{ height: '', overflow: '' }}
            />
          </Box>
        </Block>
      </Box>
    </FormModal>
  );
};

export default EventUpdateTravelFormModal;
